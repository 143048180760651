import { client, request, apiHeaders } from "./_base";

const endpoint = "producerfinance";

export type ProducerFinanceType = {
  id?: number;
  producer_id: string;
  purchaseterms: number;
  dealtype: number;
  commission: number;
  payment: number;
  terms_id: number;
  created_at?: Date;
  updated_at?: Date;
};

export const producerfinance = client(endpoint, (item) => ({
  ...item,
}));

export const producerfinanceByProducerId = async (id) => {
  const result = await request(`${endpoint}/getbyproducerid/${id}`, {
    headers: apiHeaders(),
    method: "GET",
  });
  if (result.status == 500) {
    const resp = await result.json();
    throw resp;
  }
  return result.json();
};

export const producerfinanceByProducerCode = async (code) => {
  const result = await request(`${endpoint}/getbyproducercode/${code}`, {
    headers: apiHeaders(),
    method: "GET",
  });
  if (result.status == 500) {
    const resp = await result.json();
    throw resp;
  }
  return result.json();
};

export type ProducerCommissionType = {
  id: number;
  amount_percent: number;
  amount_per_carton: number;
  producer_id: number;
  commodity_id: string;
};

export const producerCommissionByProducerId = async (producer_id: number): Promise<ProducerCommissionType[]> => {
  const result = await request(`${endpoint}/getProducerCommissionByProducerId/${producer_id}`, {
    headers: apiHeaders(),
    method: "GET",
  });
  const resp = await result.json();
  if (result.status == 500) {
    throw resp;
  }
  return resp.data;
};
