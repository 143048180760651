import React from "react";
import CustomAutoCompleteFilter from "../../lib/components/customdatagridfilter/CustomFilter";
import { DateFormatter, FormatterNumber3Decimals, RightAlignColumnHeader } from "../../lib/components/Formatters";

const cellWidthSmallBig = 140;

const defaultColumnProperties = {
  resizable: true,
  sortable: true,
};

let currentSpace = true;

export const GridColumns = (data, filters) => {
  let postValue = "";

  if (data) {
    if (currentSpace) {
      postValue = " ";
    }
    currentSpace = !currentSpace;
  }

  return [
    {
      key: "date",
      name: "Date" + postValue,
      width: cellWidthSmallBig,
      formatter: DateFormatter,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"date"} data={data} section={"exchangegridsetup"} />;
      },
    },
    {
      key: "USD",
      name: "USD" + postValue,
      width: cellWidthSmallBig,
      formatter: FormatterNumber3Decimals,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"USD"} data={data} section={"exchangegridsetup"} />;
      },
      headerRenderer: RightAlignColumnHeader,
    },
    {
      key: "CAD",
      name: "CAD" + postValue,
      width: cellWidthSmallBig,
      formatter: FormatterNumber3Decimals,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"CAD"} data={data} section={"exchangegridsetup"} />;
      },
      headerRenderer: RightAlignColumnHeader,
    },
    {
      key: "EUR",
      name: "EUR" + postValue,
      width: cellWidthSmallBig,
      formatter: FormatterNumber3Decimals,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"EUR"} data={data} section={"exchangegridsetup"} />;
      },
      headerRenderer: RightAlignColumnHeader,
    },
    {
      key: "GBP",
      name: "GBP" + postValue,
      width: cellWidthSmallBig,
      formatter: FormatterNumber3Decimals,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"GBP"} data={data} section={"exchangegridsetup"} />;
      },
      headerRenderer: RightAlignColumnHeader,
    },
  ].map((c) => ({ ...c, ...defaultColumnProperties }));
};
