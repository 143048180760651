import React, { FC, useEffect, useState } from "react";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";

import { Field, Form } from "react-final-form";
import { TextField } from "final-form-material-ui";

import LinearProgress from "@material-ui/core/LinearProgress";
import Button from "@material-ui/core/Button";

import { SellingTermsApi, SellingTermsType } from "../../lib/api/dealtype";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1),
      width: "100%",
      height: "100%",
      position: "relative",
    },
    tableCellLabel: {
      width: "100px",
      borderBottom: "none",
      height: "50px",
      textAlign: "right",
      paddingTop: "5px",
    },
    container: {
      paddingTop: "10px",
    },
    actions: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-end",
      gap: "10px",
    },
  });

type SellingTermsFormProps = {
  id: string | number;
  handleProcess(values: SellingTermsType): Promise<void>;
  handleClose(): void;
} & WithStyles<typeof styles>;

const SellingTermsFormUnstyled: FC<SellingTermsFormProps> = ({ classes, id, handleClose, handleProcess }) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<SellingTermsType>({ id: 0, code: "", description: "" });

  const loadData = async (): Promise<void> => {
    setLoading(true);
    if (id) {
      const result = await SellingTermsApi.single(id);
      setData(result);
    }
    setLoading(false);
  };

  useEffect(() => {
    loadData();
  }, [id]);

  return (
    <div className={classes.root}>
      {loading ? (
        <LinearProgress color="secondary" />
      ) : (
        <Form
          initialValues={{ ...data }}
          onSubmit={(values: any) => handleProcess(values)}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit} style={{ gap: "10px" }}>
              <div className={classes.container}>
                <TableFieldText classes={classes} field="code" title="Code" disabled={false} />
                <TableFieldText classes={classes} field="description" title="Description" disabled={false} />
              </div>
              <div className={classes.actions}>
                <Button name="submit" type="submit" variant="contained" color="primary" disabled={false}>
                  Save And Close
                </Button>
                <Button name="close" variant="contained" color="secondary" disabled={false} onClick={handleClose}>
                  Close
                </Button>
              </div>
            </form>
          )}
        />
      )}
    </div>
  );
};

const TableFieldText: React.FunctionComponent<{ field: string; title: string; disabled: boolean } & WithStyles<typeof styles>> = (props) => {
  const { classes, field, title, disabled } = props;
  return (
    <div style={{ display: "grid", gridTemplateColumns: "100px 300px", gridGap: "10px" }}>
      <div className={classes.tableCellLabel}>{title}:</div>
      <Field name={field} component={TextField} type="text" fullWidth={true} disabled={disabled} />
    </div>
  );
};

export default withStyles(styles)(SellingTermsFormUnstyled);
