import React from "react";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";

import { history } from "../history";

// import Button from "@material-ui/core/Button";
import { DialogInformation } from "../components/dialoginformation";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1),
      paddingBottom: 0,
    },
  });

type ErrorBoundaryProps = {} & WithStyles<typeof styles>;

export class ErrorBoundaryUnstyled extends React.Component<ErrorBoundaryProps, any> {
  state = {
    classes: undefined,
    children: undefined,
    error: undefined,
  };

  constructor(props) {
    super(props);
    this.state.classes = props.classes;
    this.state.children = props.children;
  }

  componentDidCatch(error, errorInfo) {
    // this.setState({ error: error });
    // alert("Error Occured.")
    // alert(error);
    history.push("/");
  }

  handleErrorClose = () => {
    this.setState({ error: undefined });
    history.push("/login");
  };

  render() {
    return (
      <div className={this.state.classes.root}>
        {this.state.error && (
          <DialogInformation isOpen={true} handleClose={this.handleErrorClose} handleOK={this.handleErrorClose} title={"Error!"} body={`${this.state.error}`} showinput={false} />
        )}
        {/* {this.state.error && 
          <div style={{
            marginTop: "100px", 
            width: "100%",
            height: "400px",
            // top: "100px",
            // right: "0",
            // zIndex:-1,
          }}>
            ERROR Occured!!!
            <Button
              style={{marginRight: "10px"}}
              color="secondary"
              variant="outlined"
              onClick={this.returnHome}
            >
              return home
            </Button>
          </div>
        } */}
        {this.state.children}
      </div>
    );
  }
}

export default withStyles(styles)(ErrorBoundaryUnstyled);
