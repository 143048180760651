import { config } from "../../config";
import { client, apiHeaders, request } from "./_base";

const endpoint = "labtestattachment";

export type LabTestAttachmentType = {
  id?: number;
  labtest_id: number;
  name: string;
  document: string;
};
export const labtestattachment = client(endpoint, (item) => ({
  ...item,
}));

export const labtestattachmentByLoadoutID = async (id) => {
  return request(`${endpoint}/getByLabTestId/${id}/`, {
    method: "GET",
  })
    .then(async (result) => {
      if (result.status == 500) {
        const resp = await result.json();
        throw resp;
      }
      return result.json();
    })
    .then(({ data }) => {
      return data;
    });
};
