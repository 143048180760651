import React, { useEffect } from "react";

import { Field, Form } from "react-final-form";
import { TextField, Select } from "final-form-material-ui";

import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";

import { getCurrencyAllSortedMappedforCombo } from "../../../lib/api/currency";
import { getProducerReadyForCombo } from "../../../lib/api/producer";
import { getWeeksReadyForComboMappedForComboValue } from "../../../lib/api/week";
import { GreenButton, RedButton } from "../../../lib/components/ColorButtons";
import { SnackContext } from "../../../lib/context/SnackContext";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1),
      width: "600px",
      height: "100%",
    },
    container: {
      display: "grid",
      gridTemplateColumns: "repeat(1, 150px 350px)",
      gridTemplateRows: "repeat(4, 40px)",
      gridTemplateAreas: `
      "details"
      `,
      gridGap: "10px",
      marginTop: "10px",
    },
    tableCellLabel: {
      width: "150px",
      borderBottom: "none",
      height: "50px",
      textAlign: "right",
      paddingTop: "5px",
    },
    tableCellLabelValue: {
      width: "350px",
      borderBottom: "none",
      height: "50px",
      textAlign: "left",
      paddingTop: "5px",
    },
    tableCellDetail: {
      width: "350px",
      borderBottom: "none",
      height: "50px",
    },
    tableCellsValueSelect: {
      textAlign: "left",
      width: "350px",
      borderBottom: "none",
      height: "50px",
      padding: 0,
      marginTop: "-14px",
    },
    detailTable: {
      width: "900px",
    },
    detailTableRowNormal: {
      backgroundColor: "white",
      padding: "0px",
    },
  });

type ProducerFinalAdHocProps = {
  producerID: string;
  initialData: any;
  handleClose: () => void;
  handleProcessAdHoc: (values) => void;
} & WithStyles<typeof styles>;

const ProducerFinalAdHocUnstyled: React.FC<ProducerFinalAdHocProps> = ({ classes, initialData, producerID, handleClose, handleProcessAdHoc }) => {
  const [defaultProducer, setDefaultProducer] = React.useState(0);

  const [loading, setLoading] = React.useState(true);
  const [currencyComboData, setCurrencyComboData] = React.useState([]);
  const [producerComboData, setProducerComboData] = React.useState([]);
  const [weekComboData, setWeekComboData] = React.useState([]);
  const { updateSnack } = React.useContext(SnackContext);

  useEffect(() => {
    Promise.all([
      getCurrencyAllSortedMappedforCombo(),
      getProducerReadyForCombo(),
      getWeeksReadyForComboMappedForComboValue(),
    ])
      .then((result) => {
        setCurrencyComboData(result[0]);
        setProducerComboData(result[1]);
        setWeekComboData(result[2]);
        setLoading(false);
      })
      .catch((error) => {
        updateSnack({ show: true, message: "could not load", color: "red" });
      });
  }, [initialData]);

  useEffect(() => {
    if (producerComboData.length > 0) {
      const selectedProducer = producerID || "";
      const producer = producerComboData.find((producer) => producer.data.code === selectedProducer);
      setDefaultProducer(producer.value);
    }
  }, [producerID, producerComboData]);

  return (
    <div className={classes.root}>
      <Form
        initialValues={{ producer_id: defaultProducer }}
        onSubmit={(values) => handleProcessAdHoc(values)}
      >
        {({ handleSubmit, form }) =>
          <form onSubmit={handleSubmit}>
            <div>
              <GreenButton type="submit" variant="contained">
                make adhoc payment
              </GreenButton>
              <RedButton type="button" variant="contained" onClick={handleClose}>
                close
              </RedButton>
            </div>
            <div className={classes.container}>
              <TableFieldCombo
                classes={classes}
                form={form}
                field="producer_id"
                title="Producer"
                data={producerComboData}
                addEmptyValue={undefined}
              />
              <TableFieldCombo classes={classes} form={form} field="week_id" title="Week" data={weekComboData} addEmptyValue={undefined} />
              <TableFieldCombo
                classes={classes}
                form={form}
                field="currency_id"
                title="Currency"
                data={currencyComboData}
                addEmptyValue={undefined}
              />
              <TableFieldText classes={classes} field="paidamount" title="Pay" disabled={false} />
              <TableFieldText classes={classes} field="note" title="Note" disabled={false} />
            </div>
          </form>
        }
      </Form>
    </div>
  )
}

export default withStyles(styles)(ProducerFinalAdHocUnstyled);

const TableFieldText: React.FunctionComponent<{ field: string; title: string; disabled: boolean } & WithStyles<typeof styles>> = (props) => {
  const { classes, field, title, disabled } = props;
  return (
    <>
      <span className={classes.tableCellLabel}>{`${title}:`}</span>
      <span className={classes.tableCellDetail}>
        <Field name={field} component={TextField} type="text" fullWidth={true} disabled={disabled} />
      </span>
    </>
  );
};

const TableFieldCombo: React.FunctionComponent<
  { classes: any; form: any; title: string; field: string; data: any; addEmptyValue: string; } & WithStyles<typeof styles>
> = (props) => {
  const { classes, form, title, field, data, addEmptyValue } = props;
  return (
    <>
      <span className={classes.tableCellLabel}>{`${title}:`}</span>
      <span className={classes.tableCellDetail}>
        <Field
          name={field}
          component={Select}
          formControlProps={{ className: classes.tableCellsValueSelect }}
        >
          {addEmptyValue && (
            <MenuItem key={`emptyvalueMenuItem`} value={-1}>
              {addEmptyValue}
            </MenuItem>
          )}
          {data &&
            data.map((item, index) => {
              return (
                <MenuItem key={`${item.value}${index}`} value={item.value}>
                  {item.display}
                </MenuItem>
              );
            })}
        </Field>
      </span>
    </>
  );
};
