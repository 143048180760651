import React, { useEffect } from "react";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";

import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";

import IconClose from "@material-ui/icons/Close";

import InstructionEdit from "./instruction";

import { getOrdersByGroupNumber, removeWithInstructions } from "../lib/api/piorder";

import { copyOrder } from "../orders/_shared";

import Confirmation from "../lib/components/confirmation";

const styles = (theme: Theme) =>
  createStyles({
    rootMain: {
      // padding: "0px",
      // padding: theme.spacing(1),
    },
  });

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <Typography component="div" role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      <Box p={3}>{children}</Box>
    </Typography>
  );
};

const InstructionTabbedUnstyled: React.FunctionComponent<any> = (props) => {
  const { classes } = props;
  const [groupNumber, setGroupNumber] = React.useState(props.match.params.id);
  const [tabValue, setTabValue] = React.useState(0);
  const [instructions, setInstructions] = React.useState([]);
  const [maxSequence, setMaxSequence] = React.useState(0);
  const [removeInstructionId, setRemoveInstructionId] = React.useState(undefined);

  useEffect(() => {
    loadGroup(groupNumber);
  }, [groupNumber]);

  const loadGroup = (groupnumber) => {
    return new Promise((resolve, reject) => {
      getOrdersByGroupNumber(groupnumber).then((result) => {
        const instructionsArr = [];
        result.data.map((item, index) => {
          setMaxSequence(item.groupsequence);
          instructionsArr.push({
            component: (
              <div key={item.id} style={{ padding: "0px" }}>
                <InstructionEdit id={item.id} refreshTabs={instructionTabSave} groupMaster={index == 0 ? true : false} />
              </div>
            ),
            order: item,
          });
        });

        setInstructions(instructionsArr);
        resolve("ok");
      });
    });
  };

  const instructionTabSave = () => {
    loadGroup(groupNumber);
  };

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    if (newValue == instructions.length) {
      copyOrder(instructions[0].order.id, maxSequence, true)
        .then(() => {
          loadGroup(groupNumber).then(() => {
            setTabValue(instructions.length);
          });
        })
        .catch((err) => {});
      return;
    }
    setTabValue(newValue);
  };

  const handleRemoveChildInstruction = (id) => {
    setRemoveInstructionId(id);
  };

  const handleRemoveChildInstructionClose = () => {
    setRemoveInstructionId(undefined);
  };

  const handleRemoveChildInstructionConfirm = () => {
    removeWithInstructions(removeInstructionId)
      .then(() => {
        setRemoveInstructionId(undefined);
        setTabValue(0);
        loadGroup(groupNumber);
      })
      .catch(() => {
        setRemoveInstructionId(undefined);
      });
  };

  return (
    <div className={classes.rootMain}>
      <AppBar position="static">
        <Tabs value={tabValue} onChange={handleChange} aria-label="simple tabs example">
          {instructions.map((ins, index) => {
            return (
              <Tab
                // label={ins.order.ordernum}
                label={
                  <div>
                    {ins.order.ordernum}
                    {index != 0 && (
                      <IconButton
                        onClick={(e) => {
                          handleRemoveChildInstruction(ins.order.id);
                        }}
                      >
                        <IconClose style={{ color: "white" }} />
                      </IconButton>
                    )}
                  </div>
                }
              />
            );
          })}
          <Tab label={"+ ADD NEW +"} />;
        </Tabs>
      </AppBar>
      {instructions.map((ins, index) => {
        return (
          <TabPanel value={tabValue} index={index}>
            {instructions[index].component}
          </TabPanel>
        );
      })}
      {removeInstructionId && (
        <Confirmation
          isOpen={true}
          handleClose={handleRemoveChildInstructionClose}
          handleConfirm={handleRemoveChildInstructionConfirm}
          title="Remove selected child instruction?"
          body="Are you sure you want to REMOVE the selected child instruction?"
        ></Confirmation>
      )}
    </div>
  );
};

export default withStyles(styles)(InstructionTabbedUnstyled);
