import { client, request, apiHeaders } from "./_base";

const endpoint = "producersfull";

export type ProducersFullType = {
  bar_id: number;
  barcode: string;
  bar_CommodityCode: string;
  bar_VarietyCode: string;
  bar_GradeCode: string;
  bar_CountCode: string;
  bar_PackCode: string;
  bar_MarkCode: string;
  bar_InventoryCode: string;
  bar_NoCartons: string;
  bar_PalletSize: string;
  bar_LocationCode: string;
  bar_FarmNumber: string;
  bar_TargetMarket: string;
  bar_TargetCountry: string;
  bar_TargetRegion: string;
  bar_intakewaybill: string;
  bar_originalIntakewaybill: string;
  producer_id: number;
  producer_name: string;
  bar_ProducerID: string;
  bar_ColdDate: Date;
  weekcold_id: number;
  weekcold_week: string;
  wkcolddue_week: string;
  accold_id: number;
  accold_cad: number;
  accold_eur: number;
  accold_gbp: number;
  accold_usd: number;
  accold_zar: number;
  coldCartonValueZAR: number;
  coldCartonValueCAD: number;
  coldCartonValueEUR: number;
  coldCartonValueGBP: number;
  coldCartonValueUSD: number;
  accold_payoutLeadNumber: number;
  bar_OrigIntakeDate: Date;
  weekintake_id: number;
  weekintake_week: string;
  wkintakedue_week: string;
  acintake_id: number;
  acintake_cad: number;
  acintake_eur: number;
  acintake_gbp: number;
  acintake_usd: number;
  acintake_zar: number;
  intakeCartonValueZAR: number;
  intakeCartonValueCAD: number;
  intakeCartonValueEUR: number;
  intakeCartonValueGBP: number;
  intakeCartonValueUSD: number;
  acintake_payoutLeadNumber: number;
  sale_id: number;
  sale_invoicenumber: string;
  sale_date: Date;
  saledetail_sellingprice: string;
  clients_name: string;
  weekshipping_id: number;
  weekshipping_week: string;
  wkshippingdue_week: string;
  acshipping_id: number;
  acshipping_cad: number;
  acshipping_eur: number;
  acshipping_gbp: number;
  acshipping_usd: number;
  acshipping_zar: number;
  shippingCartonValueZAR: number;
  shippingCartonValueCAD: number;
  shippingCartonValueEUR: number;
  shippingCartonValueGBP: number;
  shippingCartonValueUSD: number;
  acshipping_payoutLeadNumber: number;
  produceradvancepaid_id: number;
  produceradvancepaidhead_id: number;
  produceradvancepaidhead_ident: string;
  produceradvancepaidhead_makeanote: string;
  produceradvancepaidhead_adhoc: number;
  paid_advancecontractsid: number;
  paid_zarOpen: number;
  paid_zar: number;
  paid_cad: number;
  paid_eur: number;
  paid_gbp: number;
  paid_usd: number;
  paid_currency: string;
  dispatch_currencyid: number;
  dispatch_currencycode: string;
  dispatch_containerno: string;
  loadout_eta: Date;
  loadout_etd: Date;
  vessel_code: string;
  barcode_targetMarketCurr: string;
  piorder_id: number;
  piorder_farm: string;
  piorder_commission: number;
  piins_fob: number;
  uniqueIdent: string;
  portDischarge_description: string;
  portDischarge_code: string;
  vessel_description: string;
  loadout_voyageNumber: string;
  shipMarket: string;
  targetFinal_market: string;
  wkcolddue_id: number;
  wkintakedue_id: number;
  wkshippingdue_id: number;
};

export const producersfull = client(endpoint, (item) => ({
  ...item,
}));

export const getProducersFullByProducerId = (
  producerid: string,
  weekduecold: string,
  weekdueintake: string,
  weekdueshipping: string,
  currencycode: string,
  identnumber: number,
) => {
  return request(`${endpoint}/ex/byProducerID/${producerid}/${weekduecold}/${weekdueintake}/${weekdueshipping}/${currencycode}/${identnumber}`, {
    method: "GET",
  })
    .then(async (result) => {
      if (result.status == 500) {
        const resp = await result.json();
        throw resp;
      }
      return result.json();
    })
    .then(({ data }) => {
      return data;
    });
};

export const getProducersFullByUniqueIdent = (producer: string, uniqueIidentnumber: string) => {
  return request(`${endpoint}/ex/byUniqueIdent/${producer}/${uniqueIidentnumber}`, {
    method: "GET",
  })
    .then(async (result) => {
      if (result.status == 500) {
        const resp = await result.json();
        throw resp;
      }
      return result.json();
    })
    .then(({ data }) => {
      return data;
    });
};

export type ProducersFullGroupedType = {
  producer_id: string;
  producer_name: string;
  dispatch_currencycode: string;
  wkcolddue_week: string;
  wkintakedue_week: string;
  wkshippingdue_week: string;
  sum_coldCartonValueZAR: number;
  sum_intakeCartonValueZAR: number;
  sum_shippingCartonValueZAR: number;
  sum_paidzarOpen: number;
  sum_paidzar: number;
  sum_paidcad: number;
  sum_paideur: number;
  sum_paidgbp: number;
  sum_paidusd: number;
  bar_NoCartons: number;
  bar_PalletSize: number;
  sum_paidcurrency: string;
  sum_paidcurrencyValue: number;
  sum_paidcurrencyValueTotal: number;
  paid_currency: number | string;
  produceradvancepaidhead_id: number;
  produceradvancepaidhead_ident: number;
  produceradvancepaidhead_makeanote: string;
  produceradvancepaidhead_posteddate: Date;
  produceradvancepaidhead_week_id: number;
  paphweek_week: string;
  adhocdetail_remainingamount: number;
  adhoc_id: number;
  wkcolddue_id: number;
  wkintakedue_id: number;
  wkshippingdue_id: number;
  uniqueIdent: string;
  producerId: number;
  produceradvancepaidadhoc_note: string;
};
export const getProducersFullGroupedByProducer = async (producerid: string, financial_year: string): Promise<ProducersFullGroupedType[]> => {
  const result = await request(`${endpoint}/ex/GroupedbyProducer/${producerid}/${financial_year}`, {
    method: "GET",
  });

  if (result.status == 500) {
    throw new Error(result.statusText);
  }

  const resp = await result.json();
  return resp.data;
};

export type ProducersFullGroupedOnlyType = {
  producer_id: string;
  producer_name: string;
  bar_NoCartons: number;
  bar_PalletSize: number;
  paid_zar: number;
  paid_cad: number;
  paid_eur: number;
  paid_gbp: number;
  paid_usd: number;
};
export const getProducersFullGroupedByProducerOnly = (financial_year: string) => {
  return request(`${endpoint}/ex/GroupedbyProducerOnly/${financial_year}`, {
    method: "GET",
  })
    .then((result) => {
      if (result.status == 500) {
        throw result.statusText;
      }
      return result.json();
    })
    .then(({ data }) => {
      return data;
    });
};

export type ProducersPIOrderLinkedType = {
  barcode: string;
  bar_CommodityCode: string;
  bar_VarietyCode: string;
  bar_GradeCode: string;
  bar_CountCode: string;
  bar_PackCode: string;
  piorder_id: number;
  piorder_farm: string;
  piorder_commission: number;
  piins_fob: number;
};
export const getProducersPIOrderLinked = (sale_id) => {
  return request(`${endpoint}/ex/piorderlinked/${sale_id}`, {
    method: "GET",
  })
    .then((result) => {
      if (result.status == 500) {
        throw result.statusText;
      }
      return result.json();
    })
    .then(({ data }) => {
      return data;
    });
};

export const getProducersFullGroupByIdent = async (ident: number) => {
  return request(`${endpoint}/ex/producersFullGroupByIdent/${ident}`, {
    method: "GET",
  })
    .then((result) => {
      if (result.status == 500) {
        throw result.statusText;
      }
      return result.json();
    })
    .then(({ data }) => {
      return data;
    });
};

export const getProducerIntakeAndShipments = async (producerid: string, financial_year: string) => {
  return await request(`${endpoint}/ex/producerIntakeAndShipments?producerid=${producerid}&financial_year=${financial_year}`, {
    method: "GET",
  })
    .then((result) => {
      if (result.status == 500) {
        throw result.statusText;
      }
      return result.json();
    })
    .then(({ data }) => {
      return data;
    });
};

export const exportProducerIntakeAndShipments = async (producerid: string, financial_year: string, shouldIncludeShipments: boolean, filename: string) => {
  const result = await request(`${endpoint}/ex/exportProducerIntakeAndShipments`, {
    method: "POST",
    body: JSON.stringify({
      producerid,
      financial_year,
      shouldIncludeShipments,
    }),
  });
  const blob = await result.blob();
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = url;
  a.download = `${filename}.xlsx`;
  a.click();
};

export const exportProducerPaymentsSummaryReport = async (producerid: string, financial_year: number, filename: string) => {
  return request(`${endpoint}/ext/exportProducerPaymentsSummary?producerid=${producerid}&financial_year=${financial_year}&filename=${filename}`, {
    method: "GET",
  }).then((result) => {
    result.blob().then((blob) => {
      let url = window.URL.createObjectURL(blob);
      let a = document.createElement("a");
      a.href = url;
      a.download = `${filename}.xlsx`;
      a.click();
    });
  });
};
