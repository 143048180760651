import React, { FC, useEffect, useState } from "react";
import { withStyles, createStyles, Theme, WithStyles, LinearProgress } from "@material-ui/core";

import { Form, Field } from "react-final-form";
import { TextField, Select } from "final-form-material-ui";

import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";

import { commoditiesAllSortedMappedforCombo } from "../lib/api/commodity";
import { classesAllSortedMappedforCombo } from "../lib/api/classes";
import { brandsAllSortedMappedforCombo } from "../lib/api/brand";
import { inventoriesAllSortedMappedforCombo } from "../lib/api/inventory";
import { VarietiesByCommodityMappedForComboValue } from "../lib/api/variety";
import { distinctCodeByCommodity } from "../lib/api/counts";
import { getProducerReadyForComboMapped } from "../lib/api/producer";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      height: "100%",
      width: "100%",
    },
    formRow: {
      display: "grid",
      gap: "5px",
    },
    container: {
      display: "grid",
      gridTemplateColumns: "120px 250px",
      gridTemplateRows: "50px",
      gridGap: "10px",
    },
    tableCellLabel: {
      width: "120px",
      borderBottom: "none",
      height: "50px",
      textAlign: "right",
      paddingTop: "5px",
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
    },
    tableCellDetail: {
      width: "100%",
      borderBottom: "none",
      height: "50px",
      display: "flex",
      alignItems: "center",
    },
    tableCellsValueSelect: {
      width: "100%",
      height: "50px",
      display: "flex",
      justifyContent: "center",
      "& .MuiInput-formControl": {
        marginTop: "0",
      },
    },
    formActions: {
      paddingTop: "15px",
      paddingBottom: "5px",
      display: "flex",
      justifyContent: "flex-end",
    },
  });

type DispatchAddNewBarcodeFormProps = {
  reload(): void;
  handleClose(): void;
  handleProcess(values: any): Promise<void>;
} & WithStyles<typeof styles>;

const DispatchAddNewBarcodeForm: FC<DispatchAddNewBarcodeFormProps> = ({ classes, handleClose, handleProcess, reload }) => {
  const [loading, setLoading] = useState<Boolean>(false);

  return (
    <div className={classes.root}>
      {loading ? <LinearProgress color="secondary" /> : <div style={{ height: "4px" }}></div>}
      <Form
        validate={(values: any) => {
          let errors = {};

          if (!values.barcode) {
            errors = { ...errors, barcode: "Required" };
          }

          return errors;
        }}
        onSubmit={async (values) => {
          setLoading(true);
          await handleProcess(values);
          setLoading(false);
          reload();
        }}
        render={({ handleSubmit, form }) => <FormDetail classes={classes} form={form} handleSubmit={handleSubmit} handleClose={handleClose} />}
      />
    </div>
  );
};

type FormDetailProps = {
  form: any;
  handleClose(): void;
  handleSubmit(): void;
} & WithStyles<typeof styles>;

const FormDetail: FC<FormDetailProps> = ({ classes, form, handleSubmit, handleClose }) => {
  const [commoditiesCombo, setCommoditiesCombo] = useState<any[]>([]);
  const [varietiesCombo, setVarietiesCombo] = useState<any[]>([]);
  const [gradesCombo, setGradesCombo] = useState<any[]>([]);
  const [countsCombo, setCountsCombo] = useState<any[]>([]);
  const [markCombo, setMarkCombo] = useState<any[]>([]);
  const [inventoryCombo, setInventoryCombo] = useState<any[]>([]);
  const [producerCombo, setProducerCombo] = useState<any[]>([]);

  const loadData = async () => {
    const result = await Promise.all([
      commoditiesAllSortedMappedforCombo(),
      classesAllSortedMappedforCombo(),
      brandsAllSortedMappedforCombo(),
      inventoriesAllSortedMappedforCombo(),
      getProducerReadyForComboMapped(),
    ]);

    const grades = result[1].map((item) => ({ ...item, display: item.value }));
    const marks = result[2].map((item) => ({ ...item, display: item.value }));
    const inventorycodes = result[3].map((item) => ({ ...item, display: item.code, value: item.code }));

    setCommoditiesCombo(result[0]);
    setGradesCombo(grades);
    setMarkCombo(marks);
    setInventoryCombo(inventorycodes);
    setProducerCombo(result[4]);
  };

  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    const commodity = form.getState().values.commodityCode;
    Promise.all([VarietiesByCommodityMappedForComboValue(commodity), distinctCodeByCommodity(commodity)]).then((result) => {
      const counts = result[1].data.map((item) => ({ ...item, display: item.code, value: item.code }));

      setCountsCombo(counts);
      setVarietiesCombo(result[0]);
    });
  }, [form.getState().values.commodityCode]);

  return (
    <form onSubmit={handleSubmit} className={classes.formRow}>
      <TableFieldText classes={classes} field="barcode" title="Barcode" disabled={false} />
      <TableFieldCombo classes={classes} data={commoditiesCombo} field="commodityCode" title="Commodity" addEmptyValue={""} />
      <TableFieldCombo classes={classes} data={varietiesCombo} field="varietyCode" title="Variety" addEmptyValue={""} />
      <TableFieldCombo classes={classes} data={gradesCombo} field="gradeCode" title="Grade" addEmptyValue={""} />
      <TableFieldCombo classes={classes} data={countsCombo} field="countCode" title="Count" addEmptyValue={""} />
      <TableFieldText classes={classes} field="packCode" title="Pack" disabled={false} />
      <TableFieldCombo classes={classes} data={markCombo} field="markCode" title="Mark" addEmptyValue={""} />
      <TableFieldCombo classes={classes} data={inventoryCombo} field="inventoryCode" title="Inventory Code" addEmptyValue={""} />
      <TableFieldCombo classes={classes} data={producerCombo} field="producerId" title="Producer" addEmptyValue={""} />
      <TableFieldText classes={classes} field="noCartons" title="Cartons" disabled={false} />
      <TableFieldText classes={classes} field="palletSize" title="Pallet Size" disabled={false} />
      <TableFieldText classes={classes} field="farmNumber" title="Farm Number" disabled={false} />
      <TableFieldText classes={classes} field="sequenceNumber" title="Sequence Number" disabled={false} />
      <div className={classes.formActions}>
        <Button color="default" variant="contained" type="button" style={{ marginRight: "10px" }} onClick={handleClose}>
          Cancel
        </Button>
        <Button color="primary" variant="contained" type="submit">
          Create
        </Button>
      </div>
    </form>
  );
};

const TableFieldText: React.FunctionComponent<{ field: string; title: string; disabled: boolean } & WithStyles<typeof styles>> = (props) => {
  const { classes, field, title, disabled } = props;
  return (
    <div className={classes.container}>
      <span className={classes.tableCellLabel}>{`${title}:`}</span>
      <span className={classes.tableCellDetail}>
        <Field name={field} component={TextField} type="text" fullWidth={true} disabled={disabled} />
      </span>
    </div>
  );
};

const TableFieldCombo: React.FunctionComponent<{ classes: any; title: string; field: string; data: any; addEmptyValue: string } & WithStyles<typeof styles>> = (props) => {
  const { classes, title, field, data, addEmptyValue } = props;
  return (
    <div className={classes.container}>
      <span className={classes.tableCellLabel}>{`${title}:`}</span>
      <span className={classes.tableCellDetail}>
        <Field name={field} component={Select} formControlProps={{ className: classes.tableCellsValueSelect }}>
          {data &&
            data.map((item, index) => {
              return (
                <MenuItem key={`${item.value}${index}`} value={item.value}>
                  {item.display}
                </MenuItem>
              );
            })}
        </Field>
      </span>
    </div>
  );
};

export default withStyles(styles)(DispatchAddNewBarcodeForm);
