import React from "react";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";

import { Row } from "react-data-grid";

import { GridColumns } from "./selectadhocgridsetup";
import Grid from "../../../lib/components/grid";
import { ProducerAdvancePaidAdHocGetAdHocRemainingType } from "../../../lib/api/produceradvancepaidadhoc";
import { ProducersFullGroupedType } from "../../../lib/api/producersfull";
import { SnackContext } from "../../../lib/context/SnackContext";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1),
      width: "100%",
      height: "100%",
    },
    redRow: {
      color: "red",
    },
    normalRow: {
      color: "black",
    },
  });

type SelectAdhocGridProps = {
  data: ProducerAdvancePaidAdHocGetAdHocRemainingType[];
  selectedData: ProducerAdvancePaidAdHocGetAdHocRemainingType[];
  selected: ProducersFullGroupedType;
  handleSelect(selected: ProducerAdvancePaidAdHocGetAdHocRemainingType): void;
} & WithStyles<typeof styles>;

class SelectAdhocGridUnstyled extends React.Component<SelectAdhocGridProps, {}> {
  constructor(props) {
    super(props);
  }

  static contextType = SnackContext;
  context!: React.ContextType<typeof SnackContext>;

  state = {
    loading: true,
    data: [],
  };

  componentDidMount = () => {
    this.loadData();
  };

  sortFn = (data: ProducerAdvancePaidAdHocGetAdHocRemainingType[]) => {
    const selectedProducerAdvancePaidHeadId = this.props.selected.produceradvancepaidhead_id;

    return data.reduce((arr, row) => {
      if (row.produceradvancepaidhead_id && row.produceradvancepaidhead_id != selectedProducerAdvancePaidHeadId) {
        arr.push(row);
      } else {
        arr.splice(1, 0, row);
      }
      return arr;
    }, []);
  };

  loadData = async () => {
    try {
      const sorted = this.sortFn(this.props.data);
      this.setState({ data: sorted });
    } catch (error) {
      this.context.updateSnack({ show: true, message: error, color: "red" });
    }
  };

  GridColumnsOverride = (data: any, filters: any, columnArrangement?: any, columnsWidth?: any) => {
    return GridColumns(data, filters, columnArrangement, columnsWidth, this.props.selectedData, this.props.handleSelect, this.props.selected.produceradvancepaidhead_id);
  };

  rowRenderer = (props, classes) => {
    const { row } = props;
    const selectedProducerAdvancePaidHeadId = this.props.selected.produceradvancepaidhead_id;
    const colorStyle = row.produceradvancepaidhead_id && row.produceradvancepaidhead_id != selectedProducerAdvancePaidHeadId ? classes.redRow : classes.normalRow;

    return <Row {...props} className={colorStyle} />;
  };

  render() {
    const { classes } = this.props;

    return (
      <div style={{ width: "900px" }}>
        <Grid
          forceHeight={700}
          data={this.state.data}
          clearFilters={"selectadhocgrid"}
          GridColumns={this.GridColumnsOverride}
          rowRenderer={(props) => this.rowRenderer(props, classes)}
        />
      </div>
    );
  }
}

export default withStyles(styles)(SelectAdhocGridUnstyled);
