import { client, apiHeaders, request } from "./_base";
import { config } from "../../config";

const endpoint = "producerphdetail";

export type ProducerPHDetailType = {
  id?: number;
  producerph_id: number;
  commoditycode: string;
  exportmarket: string;
  mrl: string;
  activeingredient: string;
  product: string;
  waterapplication100: string;
  waxapplication100: string;
  comments: string;
};
export const producerphdetail = client(endpoint, (item) => ({
  ...item,
}));

export const getProducerPHDetailFullById = (producerphid: number) =>
  request(`${endpoint}/ext/fullbyid/${producerphid}`, {
    headers: apiHeaders(),
  })
    .then(async (result) => {
      if (result.status == 500) {
        const resp = await result.json();
        throw resp;
      }
      return result.json();
    })
    .then(({ data }) => {
      return data;
    });
