import React from "react";

import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";

import CustomAutoCompleteFilter from "../../lib/components/customdatagridfilter/CustomFilter";
import { CellActionsFormatter } from "../../lib/helpers/CellActionsFormatter";

const cellWidthTiny = 40;
const cellWidthXtraSmall = 70;
const cellWidthSmall = 80;
const cellWidthSmallBig = 140;
const cellWidthMedium = 200;
// const cellWidthLarge = 250;
const cellWidthXtrLarge = 350;

const defaultColumnProperties = {
  resizable: true,
  sortable: true,
};

let currentSpace = true;
export const GridColumns = (data, filters, handleRemove, handleEdit) => {
  let postValue = "";

  if (data) {
    if (currentSpace) {
      postValue = " ";
    }
    currentSpace = !currentSpace;
  }

  return [
    {
      key: "actions",
      name: "Actions",
      width: cellWidthSmall,
      formatter: ({ row }) => {
        const actions = cellActions(row, handleRemove, handleEdit);
        return (
          <div style={{ display: "flex", justifyContent: "center", gap: "5px" }}>
            <CellActionsFormatter actions={actions} />
          </div>
        );
      },
    },
    {
      key: "Commodity ID",
      name: "Commodity ID" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Commodity ID"} data={data} section={"packgridsetup"} />;
      },
    },
    {
      key: "code",
      name: "Pack Code" + postValue,
      width: cellWidthSmallBig,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"code"} data={data} section={"packgridsetup"} />;
      },
    },
    {
      key: "Description",
      name: "Pack Description" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Description"} data={data} section={"packgridsetup"} />;
      },
    },
    {
      key: "No Cartons",
      name: "No Cartons" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"No Cartons"} data={data} section={"packgridsetup"} />;
      },
    },
    {
      key: "Standard Carton",
      name: "Standard Cartons" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Standard Carton"} data={data} section={"packgridsetup"} />;
      },
    },
    // {
    //   key: "Size Table",
    //   name: "Size Table" + postValue,
    //   width: cellWidthMedium,
    //   filterable: true,
    //   filterRenderer: (e) => {
    //     return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Size Table"} data={data} section={"packgridsetup"} />;
    //   },
    // },
    {
      key: "packagingweight",
      name: "Packaging W" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"packagingweight"} data={data} section={"packgridsetup"} />;
      },
    },
    {
      key: "Gross Weight",
      name: "Gross Weight" + postValue,
      width: cellWidthSmallBig,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Gross Weight"} data={data} section={"packgridsetup"} />;
      },
    },
    {
      key: "Nett Weight",
      name: "Nett Weight" + postValue,
      width: cellWidthSmallBig,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Nett Weight"} data={data} section={"packgridsetup"} />;
      },
    },
    {
      key: "Height",
      name: "Height" + postValue,
      width: cellWidthSmallBig,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Height"} data={data} section={"packgridsetup"} />;
      },
    },
    {
      key: "Width",
      name: "Width" + postValue,
      width: cellWidthSmallBig,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Width"} data={data} section={"packgridsetup"} />;
      },
    },
    {
      key: "Depth",
      name: "Depth" + postValue,
      width: cellWidthSmallBig,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Depth"} data={data} section={"packgridsetup"} />;
      },
    },
  ].map((c) => ({ ...c, ...defaultColumnProperties }));
};

const cellActions = (order, handleRemove, handleEdit) => {
  return [
    {
      icon: (
        <span title="Edit">
          <EditIcon style={{ cursor: "pointer", color: "orange" }} />
        </span>
      ),
      callback: (e) => {
        handleEdit(order.id);
      },
    },
    {
      icon: (
        <span title="Remove">
          <DeleteIcon style={{ cursor: "pointer", color: "red" }} />
        </span>
      ),
      callback: (e) => {
        handleRemove(order.id);
      },
    },
  ];
};
