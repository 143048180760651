import { apiHeaders, client, request } from "./_base";

export type ProducerAdvancePaidAdHocDetailType = {
  id?: number;
  produceradvancepaidadhoc_id: number;
  produceradvancepaidhead_id: number;
  amount: number;
  created_at?: Date;
};

const endpoint = "produceradvancepaidadhocdetail";

export const produceradvancepaidadhocdetail = client(endpoint, (item) => ({
  ...item,
}));

export type ProducerAdvancePaidAdHocDetailGetByAdHocIDType = {
  ident: string;
  makeanote: string;
  id: number;
  produceradvancepaidadhoc_id: number;
  produceradvancepaidhead_id: number;
  amount: string;
  week: string;
};
export const produceradvancepaidadhocdetailGetByAdHocID = async (adhoc_id: number): Promise<ProducerAdvancePaidAdHocDetailGetByAdHocIDType[]> => {
  const result = await request(`${endpoint}/ext/GetByAdHocID/${adhoc_id}`, {
    headers: apiHeaders(),
    method: "GET",
  });
  const resp = await result.json();
  if (result.status == 500) {
    throw new Error(resp);
  }
  return resp.data;
};

export const produceradvancepaidadhocdetailRemoveByAdvanceHeadID = async (head_id: number): Promise<void> => {
  const result = await request(`${endpoint}/ext/RemoveByHeadID/${head_id}`, {
    headers: apiHeaders(),
    method: "DELETE",
  });
  if (result.status == 500) {
    const resp = await result.json();
    throw new Error(resp);
  }
};

export type AdhocDetail = {
  produceradvancepaidadhoc_id: number;
  amount: number;
  code: string;
  note: string;
};

export const adhocdetailByPaidHeadId = async (head_id: number): Promise<AdhocDetail[]> => {
  const result = await request(`${endpoint}/ext/adhocdetailByPaidHeadId/${head_id}`, {
    headers: apiHeaders(),
    method: "GET",
  });
  const resp = await result.json();
  if (result.status == 500) {
    throw new Error(resp);
  }
  return resp.data;
};
