import { handleResponse } from "../helpers/responseHelper";
import { client, request } from "./_base";
import { getFinancialYearSelected } from "./week";

const endpoint = "adhocInvoice";

export interface IAdhocInvoice {
  id?: number;
  date: Date;
  invoice_number: string;
  reference: string;
  client_orderno: string;
  clients_id: number;
  duedate: Date;
}

export const adhocInvoice = client(endpoint, (item) => ({
  ...item,
}));

export const getAdhocInvoiceById = async (adhoc_invoice_id: number): Promise<{ data: IAdhocInvoice[] }> => {
  const result = await request(`${endpoint}/ext/adhocInvoiceById/${adhoc_invoice_id}`);
  return handleResponse(result);
};

export const getAdhocInvoiceFull = async (): Promise<{ data: IAdhocInvoice[] }> => {
  const result = await request(`${endpoint}/ext/adhocInvoiceFull?financial_year=${getFinancialYearSelected()}`);
  return handleResponse(result);
};
