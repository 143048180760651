import React from "react";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";

import Grid from "../../lib/components/grid";
import Confirmation from "../../lib/components/confirmation";
import { creditorsgroup } from "../../lib/api/creditorsgroup";
import { creditorsgroupsub, creditorsgroupsubByCreditorsGroupID } from "../../lib/api/creditorsgroupsub";

import Tooltip from "@material-ui/core/Tooltip";
import Button from "@material-ui/core/Button";

import { GridColumns } from "./creditorsgroupgridsetup";
import { GridColumnsSub } from "./creditorsgroupsubgridsetup";

import CreditorsGroupEditForm from "./creditorsgroupeditform";
import CreditorsGroupSubEditForm from "./creditorsgroupsubeditform";

import { SnackContext } from "../../lib/context/SnackContext";
import { Row } from "react-data-grid";

import { GenerateErrorMessage } from "../../lib/helpers/string_methods";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1),
      width: "100%",
      height: "100%",
      position: "relative",
    },
    group: {
      float: "left",
      width: "600px",
    },
    groupsub: {
      float: "left",
      marginLeft: `${theme.spacing(1) * 2}px`,
      flexGrow: 1,
      width: "600px",
    },
    inlinegroup: {
      display: "inline",
      position: "absolute",
      marginTop: "7px",
      marginLeft: "7px",
      width: "200px",
    },
    inlinegroupsub: {
      display: "inline",
      position: "absolute",
      marginTop: "7px",
      marginLeft: "70px",
      width: "200px",
    },
    boldRow: {
      fontWeight: "bold",
    },
  });

type BrandsProps = {} & WithStyles<typeof styles>;

export class BrandsUnstyled extends React.Component<BrandsProps, any> {
  state = {
    classes: undefined,
    data: [],
    datasub: [],
    dataselectedid: undefined,
    confirmRemoveID: undefined,
    confirmRemoveSubID: undefined,
    confirmEditID: undefined,
    confirmEditSubID: undefined,
    loading: true,
  };

  constructor(props) {
    super(props);
    this.state.classes = props.classes;
  }

  static contextType = SnackContext;
  context!: React.ContextType<typeof SnackContext>;

  componentDidMount() {
    this.loadData();
  }

  loadData = () => {
    creditorsgroup
      .all()
      .then((data) => {
        const dataSorted = data.sort((a, b) => {
          if (a.code > b.code) return 1;
          if (a.code < b.code) return -1;
          return 1;
        });
        this.setState({ loading: false, data: dataSorted });
      })
      .catch((error) => {
        const err = GenerateErrorMessage(error, "failed to retrieve data");
        this.context.updateSnack({ show: true, color: "red", message: err });
      });
    this.loadSelectedSubGroups();
  };

  loadSelectedSubGroups = () => {
    if (this.state.dataselectedid) {
      creditorsgroupsubByCreditorsGroupID(this.state.dataselectedid).then((result) => {
        if (result.data) {
          this.setState({ datasub: result.data });
        }
      });
    }
  };

  // GROUP
  handleCreate = () => {
    this.setState({ confirmEditID: "0" });
  };

  handleEdit = (id) => {
    this.setState({ confirmEditID: id });
  };

  handleEditClose = () => {
    this.setState({ confirmEditID: undefined });
  };

  handleEditConfirm = (item) => {
    const id = item["id"];
    delete item["id"];

    if (id == "0") {
      creditorsgroup.create({ data: { ...item } }).then(() => this.loadData());
    } else {
      creditorsgroup.update(id, { data: { ...item } }).then(() => this.loadData());
    }
    this.handleEditClose();
  };

  handleRemove = async (id) => {
    this.setState({ confirmRemoveID: id });
  };

  handleRemoveClose = () => {
    this.setState({ confirmRemoveID: undefined });
  };

  handleRemoveConfirm = async () => {
    try {
      await creditorsgroup.remove(this.state.confirmRemoveID);
      this.loadData();
    } catch (error) {
      const err = GenerateErrorMessage(error, "Cannot delete - Creditor subgroup exists");
      this.context.updateSnack({ show: true, color: "red", message: err });
    }
    this.handleRemoveClose();
  };

  // SUBGROUP
  handleCreateSub = () => {
    this.setState({ confirmEditSubID: "0" });
  };

  handleEditSub = (id) => {
    this.setState({ confirmEditSubID: id });
  };

  handleEditCloseSub = () => {
    this.setState({ confirmEditSubID: undefined });
  };

  handleEditConfirmSub = (item) => {
    const id = item["id"];
    delete item["id"];

    item["creditorsgroup_id"] = this.state.dataselectedid;

    if (id == "0") {
      creditorsgroupsub.create({ data: { ...item } }).then(() => this.loadData());
    } else {
      creditorsgroupsub.update(id, { data: { ...item } }).then(() => this.loadData());
    }

    this.handleEditCloseSub();
  };

  handleRemoveSub = async (id) => {
    this.setState({ confirmRemoveSubID: id });
  };

  handleRemoveCloseSub = () => {
    this.setState({ confirmRemoveSubID: undefined });
  };

  handleRemoveConfirmSub = async () => {
    try {
      await creditorsgroupsub.remove(this.state.confirmRemoveSubID);
      this.loadData();
    } catch (error) {
      this.context.updateSnack({ show: true, color: "red", message: "Cannot delete - Linked to an existing Creditor(s)" });
    }
    this.handleRemoveCloseSub();
  };

  handleRowClick = (data) => {
    this.setState({ dataselectedid: data.id }, () => {
      this.loadSelectedSubGroups();
    });
  };

  GridColumnsOverride = (data, filters, arrangement, columnsWidth) => {
    return GridColumns(data, filters, arrangement, columnsWidth, this.handleRemove, this.handleEdit);
  };

  GridColumnsOverrideSub = (data, filters, arrangement, columnsWidth) => {
    return GridColumnsSub(data, filters, arrangement, columnsWidth, this.handleRemoveSub, this.handleEditSub);
  };

  rowRenderer = (props, classes) => {
    const { row } = props;

    const fontWeight = row.id == this.state.dataselectedid && classes.boldRow;

    return <Row {...props} onDoubleClickCapture={() => this.handleRowClick(row)} className={fontWeight} />;
  };

  render() {
    const { classes } = this.state;

    return (
      <div className={classes.root}>
        <div className={classes.inlinegroup}>
          <Tooltip style={{ zIndex: 0 }} title="Create new Creditors Group">
            <div className={classes.inlinegroup}>
              <Button variant="contained" color="primary" onClick={this.handleCreate} style={{ marginBottom: "10px" }}>
                {`Add Group`}
              </Button>
            </div>
          </Tooltip>
        </div>
        {this.state.dataselectedid && (
          <div className={classes.inlinegroupsub}>
            <Tooltip style={{ zIndex: 0 }} title="Create new Creditors SUB-Group">
              <div className={classes.inlinegroupsub}>
                <Button variant="contained" color="primary" onClick={this.handleCreateSub} style={{ marginBottom: "10px" }}>
                  {`Add SUB-Group`}
                </Button>
              </div>
            </Tooltip>
          </div>
        )}
        <div className={classes.group}>
          <Grid
            loading={this.state.loading}
            data={this.state.data}
            GridColumns={this.GridColumnsOverride}
            clearFilters={"creditorsgroupgridfilters"}
            rowRenderer={(props) => this.rowRenderer(props, classes)}
          />
        </div>
        <div className={classes.groupsub}>
          <Grid loading={this.state.loading} data={this.state.datasub} GridColumns={this.GridColumnsOverrideSub} clearFilters={"creditorsgroup"} />
        </div>
        {this.state.confirmRemoveID && (
          <Confirmation
            isOpen={this.state.confirmRemoveID ? true : false}
            handleClose={this.handleRemoveClose}
            handleConfirm={this.handleRemoveConfirm}
            title="Remove selected Group?"
            body="Are you sure you want to REMOVE the selected Group Record?"
          ></Confirmation>
        )}
        {this.state.confirmEditID && (
          <Confirmation
            isOpen={this.state.confirmEditID ? true : false}
            handleClose={this.handleEditClose}
            handleConfirm={() => {}}
            title={this.state.confirmEditID == "0" ? "Creating Group" : "Editing Group"}
            body={undefined}
          >
            <CreditorsGroupEditForm onSubmit={this.handleEditConfirm} onClose={this.handleEditClose} id={this.state.confirmEditID} />
          </Confirmation>
        )}
        {this.state.confirmRemoveSubID && (
          <Confirmation
            isOpen={this.state.confirmRemoveSubID ? true : false}
            handleClose={this.handleRemoveCloseSub}
            handleConfirm={this.handleRemoveConfirmSub}
            title="Remove selected SUB-Group?"
            body="Are you sure you want to REMOVE the selected SUB-Group Record?"
          ></Confirmation>
        )}
        {this.state.confirmEditSubID && (
          <Confirmation
            isOpen={this.state.confirmEditSubID ? true : false}
            handleClose={this.handleEditCloseSub}
            handleConfirm={() => {}}
            title={this.state.confirmEditSubID == "0" ? "Creating Sub Group" : "Editing Sub Group"}
            body={undefined}
          >
            <CreditorsGroupSubEditForm onSubmit={this.handleEditConfirmSub} onClose={this.handleEditCloseSub} id={this.state.confirmEditSubID} />
          </Confirmation>
        )}
      </div>
    );
  }
}

export default withStyles(styles)(BrandsUnstyled);
