
import React from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <Typography component="div" role="tabpanel" hidden={value !== index} id={`vertical-tabpanel-${index}`} aria-labelledby={`vertical-tab-${index}`} {...other}>
      <Box p={3} style={{ padding: 0, marginLeft: "40px", marginTop: "3px" }}>{children}</Box>
    </Typography>
  );
};

export default TabPanel;