export const VatTypes = {
  ZERO: "0",
  "VAT 15": "15",
  EXEMPT: "-1",
};

export const getVatTypesReadyForCombo = () => {
  const result = [];
  Object.keys(VatTypes).map((vt) => {
    result.push({ id: VatTypes[vt], value: vt });
  });
  return result;
};
