import React from "react";

import CustomAutoCompleteFilter from "../../../lib/components/customdatagridfilter/CustomFilter";
import { SummaryNumber2Decimal, SummaryTotal } from "../../../lib/components/SummaryFormatter";
import { handleColumnsArrangement, handleColumnsWidth } from "../../../lib/helpers/grid";
import { isNullOrUndef } from "../../../lib/helpers/isNullOrUndef";

const cellWidthTiny = 40;
const cellWidthSmall = 80;
const cellWidthMedium = 100;
const cellWidthLarge = 200;

const defaultColumnProperties = {
  resizable: true,
  sortable: true,
};

let currentSpace = true;

export const GridColumns = (data, filters, arrangement, columnsWidth) => {
  let postValue = "";

  if (data) {
    if (currentSpace) {
      postValue = " ";
    }
    currentSpace = !currentSpace;
  }

  const columns: any = [
    {
      key: "ident",
      name: "Advance" + postValue,
      width: cellWidthMedium,
      filterable: true,
      formatter: ({ row, column }) => {
        let reconIdent = "";
        if (!isNullOrUndef(row.ident)) {
          reconIdent = "000000" + row.ident;
          reconIdent = reconIdent.slice(reconIdent.length - 6);
          reconIdent = "PR" + reconIdent;
        }
        return reconIdent;
      },
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname="ident" data={data} section="produceradvancepayhistory" />;
      },
    },
    {
      key: "week_week",
      name: "Week" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname="week_week" data={data} section="produceradvancepayhistory" />;
      },
    },
    {
      key: "currency_code",
      name: "Currency" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname="currency_code" data={data} section="produceradvancepayhistory" />;
      },
    },
    {
      key: "amount",
      name: "Amount" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname="amount" data={data} section="produceradvancepayhistory" />;
      },
      summaryFormatter: SummaryNumber2Decimal,
    },
    {
      key: "adhoc",
      name: "Adhoc" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname="adhoc" data={data} section="produceradvancepayhistory" />;
      },
    },
    {
      key: "makeanote",
      name: "Note" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname="makeanote" data={data} section="produceradvancepayhistory" />;
      },
    },
  ].map((c) => ({ ...c, ...defaultColumnProperties }));

  let columnsArranged = [];
  columnsArranged = handleColumnsArrangement(columnsArranged, arrangement, columns);
  columnsArranged = handleColumnsWidth(columnsArranged, columnsWidth, columns);

  return columnsArranged;
};
