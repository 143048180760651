import React from "react";
import CustomAutoCompleteFilter from "../lib/components/customdatagridfilter/CustomFilter";

import Tooltip from "@material-ui/core/Tooltip";
import IconBrightness from "@material-ui/icons/Brightness1";

import { DispatcheddocsFullType } from "../lib/api/dispatcheddocs";
import { StatusTypes } from "../lib/types";
import { DateFormatter, FormatterNumber, FormatterNumber2Decimals, RightAlignColumnHeader } from "../lib/components/Formatters";
import { SummaryNumber, SummaryNumber2Decimal, SummaryTotal } from "../lib/components/SummaryFormatter";
import { EnquiryLink, Selector } from "../lib/components/EnquiryLink";
import { handleColumnsArrangement, handleColumnsWidth } from "../lib/helpers/grid";

const cellWidthTiny = 40;
const cellWidthSmallX = 80;
const cellWidthSmall = 115;
const cellWidthMedium = 150;
const cellWidthLarge = 180;

const StatusFormatter = ({ row, column }) => {
  const data = row[column.key];
  const datarow: DispatcheddocsFullType = { ...row };

  if (data == StatusTypes.INVOICED) {
    return (
      <div>
        <Tooltip title={`${StatusTypes.INVOICED} ${datarow.loadout_reference}`}>
          <IconBrightness style={{ color: "orangered", display: "block", margin: "auto", border: "1px solid #333", borderRadius: "100%" }} />
        </Tooltip>
      </div>
    );
  }
  if (data == StatusTypes.DISPATCHED) {
    return (
      <div>
        <Tooltip title={`CONFIRMED ${datarow.loadout_reference}`}>
          <IconBrightness style={{ color: "yellowgreen", display: "block", margin: "auto", border: "1px solid #333", borderRadius: "100%" }} />
        </Tooltip>
      </div>
    );
  }
  if (data == StatusTypes.LOADED) {
    return (
      <div>
        <Tooltip title={`${StatusTypes.LOADED} ${datarow.loadout_reference}`}>
          <IconBrightness style={{ color: "yellow", display: "block", margin: "auto", border: "1px solid #333", borderRadius: "100%" }} />
        </Tooltip>
      </div>
    );
  }

  if (data == StatusTypes.TRANSFERS) {
    return (
      <div>
        <Tooltip title={`${StatusTypes.TRANSFERS} ${datarow.loadout_reference}`}>
          <IconBrightness style={{ color: "#4761e6", display: "block", margin: "auto", border: "1px solid #333", borderRadius: "100%" }} />
        </Tooltip>
      </div>
    );
  }
  return (
    <div>
      <Tooltip title={`NEW ${datarow.loadout_reference}`}>
        <IconBrightness style={{ color: "orange", display: "block", margin: "auto", border: "1px solid #333", borderRadius: "100%" }} />
      </Tooltip>
    </div>
  );
};

const defaultColumnProperties = {
  resizable: true,
  sortable: true,
};

let currentSpace = true;
export const GridColumnsDispatches = (data, filters, arrangement, columnsWidth) => {
  let postValue = "";

  if (data) {
    if (currentSpace) {
      postValue = " ";
    }
    currentSpace = !currentSpace;
  }

  const columns = [
    {
      key: "status",
      name: "#A" + postValue,
      width: cellWidthSmallX,
      filterable: true,
      formatter: StatusFormatter,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"status"} data={data} section={"dispatch"} />;
      },
      summaryFormatter: SummaryTotal,
    },
    {
      key: "shipment_week",
      name: "Shipment Week" + postValue,
      width: cellWidthSmallX,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"shipment_week"} data={data} section={"dispatch"} />;
      },
    },
    {
      key: "FileName",
      name: "FileName" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"FileName"} data={data} section={"dispatch"} />;
      },
    },
    {
      key: "Time",
      name: "Date" + postValue,
      width: cellWidthSmall,
      filterable: true,
      formatter: DateFormatter,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Time"} data={data} section={"dispatch"} />;
      },
    },
    {
      key: "loadout_reference",
      name: "Loadout Ref" + postValue,
      width: cellWidthSmallX,
      filterable: true,
      filterRenderer: (e) => {
        return (
          <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"loadout_reference"} data={data} section={"dispatch"} includeempty={true} />
        );
      },
      summaryFormatter: SummaryNumber,
    },
    {
      key: "Dispatch Doc Ref",
      name: "Dispatch Doc Ref" + postValue,
      width: cellWidthSmall,
      filterable: true,
      formatter: (props) => <EnquiryLink {...props} selector={Selector.InvoiceNumber} />,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Dispatch Doc Ref"} data={data} section={"dispatch"} />;
      },
    },
    {
      key: "Forwarding Agent Ref",
      name: "Forwarding Agent Ref" + postValue,
      width: cellWidthSmall,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Forwarding Agent Ref"} data={data} section={"dispatch"} />;
      },
    },
    {
      key: "Export Notification No",
      name: "Export Notification No" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Export Notification No"} data={data} section={"dispatch"} />;
      },
    },
    {
      key: "Total Cartons",
      name: "Total Cartons" + postValue,
      width: cellWidthSmall,
      filterable: true,
      formatter: FormatterNumber2Decimals,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Total Cartons"} data={data} section={"dispatch"} />;
      },
      summaryFormatter: SummaryNumber,
      headerRenderer: RightAlignColumnHeader,
    },
    {
      key: "Total Pallets",
      name: "Total Pallets" + postValue,
      width: cellWidthSmall,
      filterable: true,
      formatter: FormatterNumber,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Total Pallets"} data={data} section={"dispatch"} />;
      },
      summaryFormatter: SummaryNumber,
      headerRenderer: RightAlignColumnHeader,
    },
    {
      key: "No Entries",
      name: "No Entries" + postValue,
      width: cellWidthSmallX,
      filterable: true,
      formatter: FormatterNumber,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"No Entries"} data={data} section={"dispatch"} />;
      },
      headerRenderer: RightAlignColumnHeader,
    },
    {
      key: "nettweight",
      name: "Nett Weight" + postValue,
      width: cellWidthSmall,
      filterable: true,
      formatter: FormatterNumber2Decimals,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"nettweight"} data={data} section={"dispatch"} />;
      },
      headerRenderer: RightAlignColumnHeader,
      summaryFormatter: SummaryNumber2Decimal,
    },
    {
      key: "grossweight",
      name: "Gross Weight" + postValue,
      width: cellWidthSmall,
      filterable: true,
      formatter: FormatterNumber2Decimals,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"grossweight"} data={data} section={"dispatch"} />;
      },
      headerRenderer: RightAlignColumnHeader,
      summaryFormatter: SummaryNumber2Decimal,
    },
    {
      key: "POL",
      name: "POL" + postValue,
      width: cellWidthSmall,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"POL"} data={data} section={"dispatch"} />;
      },
    },
    {
      key: "POD",
      name: "POD" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"POD"} data={data} section={"dispatch"} />;
      },
    },
    {
      key: "ETD",
      name: "ETD" + postValue,
      width: cellWidthSmall,
      filterable: true,
      formatter: DateFormatter,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"ETD"} data={data} section={"dispatch"} />;
      },
    },
    {
      key: "ETA",
      name: "ETA" + postValue,
      width: cellWidthSmall,
      filterable: true,
      formatter: DateFormatter,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"ETA"} data={data} section={"dispatch"} />;
      },
    },
    {
      key: "Vessel",
      name: "Vessel" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Vessel"} data={data} section={"dispatch"} />;
      },
    },
    {
      key: "Voyage Number",
      name: "Voyage Number" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Voyage Number"} data={data} section={"dispatch"} />;
      },
    },
    {
      key: "Container No",
      name: "Container No" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Container No"} data={data} section={"dispatch"} />;
      },
    },
    {
      key: "Seal Number",
      name: "Seal Number" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Seal Number"} data={data} section={"dispatch"} />;
      },
    },
    {
      key: "Client",
      name: "Client" + postValue,
      width: cellWidthLarge,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Client"} data={data} section={"dispatch"} />;
      },
    },
    {
      key: "Consignee",
      name: "Consignee" + postValue,
      width: cellWidthLarge,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Consignee"} data={data} section={"dispatch"} />;
      },
    },
    {
      key: "client_po",
      name: "Client Order No" + postValue,
      width: cellWidthLarge,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"client_po"} data={data} section={"dispatchspecial"} />;
      },
    },
    {
      key: "ucrno",
      name: "UCR No" + postValue,
      width: cellWidthLarge,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"ucrno"} data={data} section={"dispatch"} />;
      },
    },
    {
      key: "Location Code",
      name: "Location Code" + postValue,
      width: cellWidthSmall,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Location Code"} data={data} section={"dispatch"} />;
      },
    },
    {
      key: "Load Date",
      name: "Load Date" + postValue,
      width: cellWidthSmall,
      filterable: true,
      formatter: DateFormatter,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Load Date"} data={data} section={"dispatch"} />;
      },
    },
    {
      key: "Temperature Code",
      name: "Temperature Code" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Temperature Code"} data={data} section={"dispatch"} />;
      },
    },
    {
      key: "Forwarding Agent",
      name: "Forwarding Agent" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Forwarding Agent"} data={data} section={"dispatch"} />;
      },
    },
    {
      key: "Receiver",
      name: "Receiver" + postValue,
      width: cellWidthSmall,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Receiver"} data={data} section={"dispatch"} />;
      },
    },
    {
      key: "Carrier",
      name: "Carrier" + postValue,
      width: cellWidthSmall,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Carrier"} data={data} section={"dispatch"} />;
      },
    },
    {
      key: "Truck Reg No",
      name: "Truck Reg No" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Truck Reg No"} data={data} section={"dispatch"} />;
      },
    },
    {
      key: "driver_name",
      name: "Driver Name" + postValue,
      width: cellWidthSmall,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"driver_name"} data={data} section={"dispatch"} includeempty={true} />;
      },
    },
    {
      key: "driver_code",
      name: "Driver Code" + postValue,
      width: cellWidthSmall,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"driver_code"} data={data} section={"dispatch"} includeempty={true} />;
      },
    },
    {
      key: "Dispatch ID",
      name: "Dispatch ID" + postValue,
      width: cellWidthSmall,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Dispatch ID"} data={data} section={"dispatch"} />;
      },
    },
    {
      key: "Dispatch User",
      name: "Dispatch User" + postValue,
      width: cellWidthSmall,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Dispatch User"} data={data} section={"dispatch"} />;
      },
    },
    {
      key: "Clearing Agent",
      name: "Clearing Agent" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Clearing Agent"} data={data} section={"dispatch"} />;
      },
    },
    {
      key: "Final Destination",
      name: "Final Destination" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Final Destination"} data={data} section={"dispatch"} />;
      },
    },
    {
      key: "Location",
      name: "Location" + postValue,
      width: cellWidthSmall,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Location"} data={data} section={"dispatch"} />;
      },
    },
    {
      key: "Client PO",
      name: "Client PO" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Client PO"} data={data} section={"dispatch"} />;
      },
    },
    {
      key: "Selling Terms",
      name: "Selling Terms" + postValue,
      width: cellWidthSmall,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Selling Terms"} data={data} section={"dispatch"} />;
      },
    },
    {
      key: "Destination",
      name: "Destination" + postValue,
      width: cellWidthSmall,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Destination"} data={data} section={"dispatch"} />;
      },
    },
    {
      key: "Shipping No",
      name: "Shipping No" + postValue,
      width: cellWidthSmall,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Shipping No"} data={data} section={"dispatch"} />;
      },
    },
    {
      key: "Consec No",
      name: "Consec No" + postValue,
      width: cellWidthSmall,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Consec No"} data={data} section={"dispatch"} />;
      },
    },
    {
      key: "Inspector Name",
      name: "Inspector Name" + postValue,
      width: cellWidthSmall,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Inspector Name"} data={data} section={"dispatch"} />;
      },
    },
    {
      key: "Temperature",
      name: "Temperature" + postValue,
      width: cellWidthSmall,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Temperature"} data={data} section={"dispatch"} />;
      },
    },
    {
      key: "Organization",
      name: "Organization" + postValue,
      width: cellWidthSmallX,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Organization"} data={data} section={"dispatch"} />;
      },
    },
    {
      key: "Channel",
      name: "Channel" + postValue,
      width: cellWidthSmallX,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Channel"} data={data} section={"dispatch"} />;
      },
    },
    {
      key: "Transport",
      name: "Transport" + postValue,
      width: cellWidthSmallX,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Transport"} data={data} section={"dispatch"} />;
      },
    },
    {
      key: "Load Type",
      name: "Load Type" + postValue,
      width: cellWidthSmallX,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Load Type"} data={data} section={"dispatch"} />;
      },
    },
    {
      key: "Revision No",
      name: "Revision No" + postValue,
      width: cellWidthSmallX,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Revision No"} data={data} section={"dispatch"} />;
      },
    },
    {
      key: "Order No",
      name: "Order No" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Order No"} data={data} section={"dispatch"} />;
      },
    },
    {
      key: "Last Edit Date",
      name: "Last Edit Date" + postValue,
      width: cellWidthMedium,
      filterable: true,
      formatter: DateFormatter,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Last Edit Date"} data={data} section={"dispatch"} />;
      },
    },
    {
      key: "Pallet Base Type",
      name: "Pallet Base Type" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Pallet Base Type"} data={data} section={"dispatch"} />;
      },
    },
    {
      key: "Temp Serial No",
      name: "Temp Serial No" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Temp Serial No"} data={data} section={"dispatch"} />;
      },
    },
    {
      key: "Week",
      name: "Week" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Week"} data={data} section={"dispatch"} />;
      },
    },
    {
      key: "Ship Sender",
      name: "Ship Sender" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Ship Sender"} data={data} section={"dispatch"} />;
      },
    },
    {
      key: "Ship Agent",
      name: "Ship Agent" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Ship Agent"} data={data} section={"dispatch"} />;
      },
    },
    {
      key: "Custom Field 1",
      name: "Custom Field 1" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Custom Field 1"} data={data} section={"dispatch"} />;
      },
    },
    {
      key: "CTO No",
      name: "CTO No" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"CTO No"} data={data} section={"dispatch"} />;
      },
    },
    {
      key: "PO File No",
      name: "PO File No" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"PO File No"} data={data} section={"dispatch"} />;
      },
    },
    {
      key: "Intake ID",
      name: "Intake ID" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Intake ID"} data={data} section={"dispatch"} />;
      },
    },
    {
      key: "Consignment Type",
      name: "Consignment Type" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Consignment Type"} data={data} section={"dispatch"} />;
      },
    },

    {
      key: "Consignee ID",
      name: "Consignee ID" + postValue,
      width: cellWidthSmall,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Consignee ID"} data={data} section={"dispatch"} />;
      },
    },
    {
      key: "Trader ID",
      name: "Trader ID" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Trader ID"} data={data} section={"dispatch"} />;
      },
    },
    {
      key: "Shipping Line",
      name: "Shipping Line" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Shipping Line"} data={data} section={"dispatch"} />;
      },
    },
    {
      key: "Cost Type",
      name: "Cost Type" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Cost Type"} data={data} section={"dispatch"} />;
      },
    },
    {
      key: "Currency",
      name: "Currency" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Currency"} data={data} section={"dispatch"} />;
      },
    },
    {
      key: "UserName",
      name: "UserName" + postValue,
      width: cellWidthSmallX,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"UserName"} data={data} section={"dispatch"} />;
      },
    },
    {
      key: "Port Of Discharge",
      name: "Port Of Discharge" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Port Of Discharge"} data={data} section={"dispatch"} />;
      },
    },
    {
      key: "Port Of Loading",
      name: "Port Of Loading" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Port Of Loading"} data={data} section={"dispatch"} />;
      },
    },
    {
      key: "Transaction Date",
      name: "Transaction Date" + postValue,
      width: cellWidthMedium,
      filterable: true,
      formatter: DateFormatter,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Transaction Date"} data={data} section={"dispatch"} />;
      },
    },
    {
      key: "Notify Party",
      name: "Notify Party" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Notify Party"} data={data} section={"dispatch"} />;
      },
    },
    {
      key: "Orig Consignment Ref",
      name: "Orig Consignment Ref" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Orig Consignment Ref"} data={data} section={"dispatch"} />;
      },
    },
    {
      key: "Sale ID",
      name: "Sale ID" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Sale ID"} data={data} section={"dispatch"} />;
      },
    },
    {
      key: "Payment Option",
      name: "Payment Option" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Payment Option"} data={data} section={"dispatch"} />;
      },
    },
    {
      key: "Container Type",
      name: "Container Type" + postValue,
      width: cellWidthSmallX,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Container Type"} data={data} section={"dispatch"} />;
      },
    },
    {
      key: "inspection_points",
      name: "Inspection Point(s)" + postValue,
      width: cellWidthSmallX,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"inspection_points"} data={data} section={"dispatch"} />;
      },
    },
    {
      key: "Shipping Booking Ref",
      name: "Shipping Booking Ref" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Shipping Booking Ref"} data={data} section={"dispatch"} />;
      },
    },
    {
      key: "Dispatch Type",
      name: "Dispatch Type" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Dispatch Type"} data={data} section={"dispatch"} />;
      },
    },
    {
      key: "Principal",
      name: "Principal" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Principal"} data={data} section={"dispatch"} />;
      },
    },
    {
      key: "ContainerRef",
      name: "ContainerRef" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"ContainerRef"} data={data} section={"dispatch"} />;
      },
    },
    {
      key: "Deal Type",
      name: "Deal Type" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Deal Type"} data={data} section={"dispatch"} />;
      },
    },
    {
      key: "BH Transaction Date",
      name: "BH Transaction Date" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"BH Transaction Date"} data={data} section={"dispatch"} />;
      },
    },
    {
      key: "Shipping Line Ref",
      name: "Shipping Line Ref" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Shipping Line Ref"} data={data} section={"dispatch"} />;
      },
    },
    {
      key: "Custom Shipping No",
      name: "Custom Shipping No" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Custom Shipping No"} data={data} section={"dispatch"} />;
      },
    },
    {
      key: "Tran Date",
      name: "Tran Date" + postValue,
      width: cellWidthMedium,
      filterable: true,
      formatter: DateFormatter,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Tran Date"} data={data} section={"dispatch"} />;
      },
    },
    {
      key: "Tran User",
      name: "Tran User" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Tran User"} data={data} section={"dispatch"} />;
      },
    },
    {
      key: "Tran Edit Date",
      name: "Tran Edit Date" + postValue,
      width: cellWidthMedium,
      filterable: true,
      formatter: DateFormatter,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Tran Edit Date"} data={data} section={"dispatch"} />;
      },
    },
    {
      key: "Tran Edit User",
      name: "Tran Edit User" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Tran Edit User"} data={data} section={"dispatch"} />;
      },
    },
    {
      key: "Verification Method",
      name: "Verification Method" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Verification Method"} data={data} section={"dispatch"} />;
      },
    },
    {
      key: "SmartFormID",
      name: "SmartFormID" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"SmartFormID"} data={data} section={"dispatch"} />;
      },
    },
    {
      key: "Destination Type",
      name: "Destination Type" + postValue,
      width: cellWidthSmallX,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Destination Type"} data={data} section={"dispatch"} />;
      },
    },
  ].map((c) => ({ ...c, ...defaultColumnProperties }));

  let columnsArranged = [];
  columnsArranged = handleColumnsArrangement(columnsArranged, arrangement, columns);
  columnsArranged = handleColumnsWidth(columnsArranged, columnsWidth, columns);
  return columnsArranged;
};
