import React from "react";
import DatePicker from "react-datepicker";
import format from "date-fns/format";

export class DateEditor extends React.Component<any> {
  constructor(props) {
    super(props);
    this.state.Date = props.value;
  }

  state = {
    Date: undefined,
  };

  getValue() {
    return { [this.props.column.key]: this.state.Date };
  }

  getInputNode() {
    return document.getElementsByTagName("input")[0];
  }

  handleChangeComplete = (newDate: Date) => {
    const formattedDate = format(newDate, "yyyy-MM-dd'T'HH:mm:ss.SSS");
    this.setState({ Date: formattedDate }, () => this.props.onCommit());
  };

  render() {
    return <DatePicker selected={this.state.Date} onChange={(date) => this.handleChangeComplete(date)} />;
  }
}
