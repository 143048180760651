import React from "react";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";

import Tooltip from "@material-ui/core/Tooltip";
import Button from "@material-ui/core/Button";

import IconAdd from "@material-ui/icons/Add";

import Grid from "../../lib/components/grid";
import Confirmation from "../../lib/components/confirmation";

import { labtest, LabTestType, labtestFull, LabTestFullType } from "../../lib/api/labtest";
import { labtestattachment } from "../../lib/api/labtestattachment";

import { GridColumns } from "./labtestsgridsetup";
import LabTestEditForm from "./labtestseditform";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1),
      width: "100%",
      height: "100%",
      position: "relative",
    },
    inline: {
      display: "inline",
      position: "absolute",
      marginTop: "7px",
      marginLeft: "7px",
    },
  });

type LabTestProps = {} & WithStyles<typeof styles>;

export class LabTestUnstyled extends React.Component<LabTestProps, any> {
  state = {
    classes: undefined,
    data: [],
    confirmRemoveID: undefined,
    confirmEditID: undefined,
    loading: true,
  };

  constructor(props) {
    super(props);
    this.state.classes = props.classes;
  }

  componentDidMount() {
    this.loadData();
  }

  loadData = () => {
    labtestFull().then((result: { data: [LabTestFullType] }) => {
      const dataSorted = result.data.sort((a, b) => {
        if (a.sampledate > b.sampledate) return 1;
        if (a.sampledate < b.sampledate) return -1;
        return 1;
      });
      this.setState({ loading: false, data: dataSorted });
    });
  };

  handleCreate = () => {
    this.setState({ confirmEditID: "0" });
  };

  handleEdit = (id) => {
    this.setState({ confirmEditID: id });
  };

  handleEditClose = () => {
    this.setState({ confirmEditID: undefined });
  };

  handleEditConfirm = async (item: LabTestType, files) => {
    let id = item.id;

    const itemdata: LabTestType = {
      sampledate: item.sampledate,
      sampledateend: item.sampledateend,
      lab: item.lab,
      producer_id: item.producer_id != 0 ? item.producer_id : null,
      puc_id: item.puc_id != 0 ? item.puc_id : null,
      orchard_id: item.orchard_id != 0 ? item.orchard_id : null,
      variety_id: item.variety_id != 0 ? item.variety_id : null,
      labtesttype_id: item.labtesttype_id != 0 ? item.labtesttype_id : null,
      approved: item.approved,
    };

    if (id == 0) {
      await labtest.create({ data: { ...itemdata } }).then((result) => {
        id = result[0];
        this.loadData();
      });
    } else {
      await labtest.update(id, { data: { ...itemdata } }).then(() => this.loadData());
    }

    if (files && files.length > 0) {
      files.map((file) => {
        if (!file.id) {
          labtestattachment.create({ data: { ...file, labtest_id: id } });
        }
      });
    }

    this.handleEditClose();
  };

  handleRemove = async (id) => {
    this.setState({ confirmRemoveID: id });
  };

  handleRemoveClose = () => {
    this.setState({ confirmRemoveID: undefined });
  };

  handleRemoveConfirm = async () => {
    await labtest.remove(this.state.confirmRemoveID).then(() => {
      this.handleRemoveClose();
      this.loadData();
    });
  };

  GridColumnsOverride = (column, row) => {
    return GridColumns(column, row, this.handleRemove, this.handleEdit);
  };

  render() {
    const { classes } = this.state;

    return (
      <div className={classes.root}>
        <div>
          <div className={classes.inline}>
            <Tooltip title="Create new Lab Test">
              <div className={classes.inline}>
                <Button variant="contained" color="primary" onClick={this.handleCreate} style={{ marginBottom: "10px" }}>
                  <IconAdd />
                </Button>
              </div>
            </Tooltip>
          </div>
          <Grid loading={this.state.loading} data={this.state.data} GridColumns={this.GridColumnsOverride} clearFilters={"labtestsgridsetup"} />
          {this.state.confirmRemoveID && (
            <Confirmation
              isOpen={this.state.confirmRemoveID ? true : false}
              handleClose={this.handleRemoveClose}
              handleConfirm={this.handleRemoveConfirm}
              title="Remove selected Lab Test?"
              body="Are you sure you want to REMOVE the selected Lab Test Record?"
            ></Confirmation>
          )}
          {this.state.confirmEditID && (
            <Confirmation
              isOpen={this.state.confirmEditID ? true : false}
              handleClose={this.handleEditClose}
              handleConfirm={() => {}}
              title={this.state.confirmEditID == "0" ? "Creating Lab Test" : "Editing Lab Test"}
              body={undefined}
            >
              <LabTestEditForm onSubmit={this.handleEditConfirm} onClose={this.handleEditClose} id={this.state.confirmEditID} />
            </Confirmation>
          )}
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(LabTestUnstyled);
