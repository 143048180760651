import React from "react";
import CustomAutoCompleteFilter from "../../lib/components/customdatagridfilter/CustomFilter";

const cellWidthSmallX = 90;
const cellWidthSmall = 115;
const cellWidthMedium = 150;
const cellWidthLarge = 220;

const defaultColumnProperties = {
  resizable: true,
  sortable: true,
  filterable: true,
};

let currentSpace = true;

export const GridColumns = (data, filters, arrangement, columnsWidth) => {
  let postValue = "";

  if (data) {
    if (currentSpace) {
      postValue = " ";
    }
    currentSpace = !currentSpace;
  }

  const columns = [
    {
      key: "filename",
      name: "Filename" + postValue,
      width: cellWidthLarge,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"filename"} data={data} section={"failedimportsgrid"} />;
      },
    },
    {
      key: "",
      name: "Redirect" + postValue,
      width: cellWidthLarge,
      formatter: ({ row }) => (
        <div>
          <a href={`/edi/fileImports/${row.id}`} target="__blank">
            View
          </a>
        </div>
      ),
    },
  ].map((c) => ({ ...c, ...defaultColumnProperties }));

  if (columnsWidth && columnsWidth.length > 0) {
    for (let index = 0; index < columns.length; index++) {
      const element = columns[index];
      const colItem = columnsWidth.find((colwitem) => colwitem.name.trim() == element.name.trim());
      if (colItem) {
        element.width = colItem.width;
      }
    }
  }

  return columns;
};
