import React from "react";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";

import Check from "@material-ui/icons/Check";

import Button from "@material-ui/core/Button";
import Input from "@material-ui/core/Input";

import { ucr } from "../../lib/api/ucrsetup";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1),
      width: "500px",
    },
    tableRow: {
      paddingTop: theme.spacing(1),
      paddingRight: theme.spacing(1) * 2,
      marginTop: theme.spacing(1),
      textAlign: "right",
    },
    codeInput: {
      width: "100%",
      float: "left",
    },
    tableStyle: {
      marginLeft: "15%",
    },
  });

type ClassesEditFormProps = {
  onSubmit: any;
  onClose: any;
  id: number;
} & WithStyles<typeof styles>;

class ClassesEditFormUnstyled extends React.Component<ClassesEditFormProps, any> {
  state = {
    classes: undefined,
    item: { id: 0, clientcode: "", swiftcountrycode: "", year: 0 },
    onSubmit: undefined,
    onClose: undefined,
    id: 0,
  };

  constructor(props) {
    super(props);
    this.state.classes = props.classes;
    this.state.onSubmit = props.onSubmit;
    this.state.onClose = props.onClose;
    if (props.id) {
      this.state.id = props.id;
    }
  }

  componentDidMount() {
    if (this.state.id && this.state.id != 0) {
      this.getItem(this.state.id);
    }
  }

  componentWillReceiveProps(props) {
    this.setState({ id: props.id }, () => {
      this.getItem(this.state.id);
    });
  }

  getItem = (id) => {
    if (id) {
      ucr.single(id).then((data) => {
        if (data) {
          this.setState({ item: data });
        }
      });
    }
  };

  handleFocus = (event) => {
    event.target.select();
  };

  handleSubmit = (e) => {
    this.state.onSubmit(e, this.state.item);
  };

  render() {
    const { classes } = this.state;

    return (
      <div className={classes.root}>
        <form
          id={this.state.id.toString()}
          onSubmit={(e) => {
            this.handleSubmit(e);
          }}
        >
          <table className={classes.tableStyle}>
            <tbody>
              <tr className={classes.tableRow}>
                <td className={classes.tableRow}>
                  <span>Client Code:</span>
                </td>
                <td className={classes.tableRow}>
                  <Input
                    className={classes.codeInput}
                    onFocus={this.handleFocus}
                    key={"body_value_clientcode"}
                    name={"clientcode"}
                    value={this.state.item.clientcode}
                    onChange={(e) => {
                      this.setState({
                        item: { ...this.state.item, clientcode: e.target.value },
                      });
                    }}
                  />
                </td>
              </tr>
              <tr className={classes.tableRow}>
                <td className={classes.tableRow}>
                  <span>Swift Country Code:</span>
                </td>
                <td className={classes.tableRow}>
                  <Input
                    className={classes.codeInput}
                    onFocus={this.handleFocus}
                    key={"body_value_swiftcountrycode"}
                    name={"swiftcountrycode"}
                    value={this.state.item.swiftcountrycode}
                    onChange={(e) => {
                      this.setState({
                        item: { ...this.state.item, swiftcountrycode: e.target.value },
                      });
                    }}
                  />
                </td>
              </tr>
              <tr className={classes.tableRow}>
                <td className={classes.tableRow}>
                  <span>Year:</span>
                </td>
                <td className={classes.tableRow}>
                  <Input
                    className={classes.codeInput}
                    onFocus={this.handleFocus}
                    key={"body_value_year"}
                    name={"year"}
                    value={this.state.item.year}
                    onChange={(e) => {
                      this.setState({
                        item: { ...this.state.item, year: e.target.value },
                      });
                    }}
                  />
                </td>
              </tr>
            </tbody>
          </table>
          <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "22px", marginRight: "15px" }}>
            <Button type="submit" color="primary" variant="outlined" onClick={this.handleSubmit}>
              <Check />
            </Button>
          </div>
        </form>
      </div>
    );
  }
}

export default withStyles(styles)(ClassesEditFormUnstyled);
