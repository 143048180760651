import React from "react";
import Button from "@material-ui/core/Button";

export type MicrosoftLoginButtonTheme = "dark_short" | "light_short" | "dark" | "light";

interface MicrosoftLoginButtonProps {
  buttonTheme: MicrosoftLoginButtonTheme;
  buttonClassName?: string;
  onClick?: any;
}

export const MicrosoftLoginButton: React.FunctionComponent<MicrosoftLoginButtonProps> = ({ buttonTheme, buttonClassName, onClick }) => {
  const [bg, short] = buttonTheme.split("_"),
    buttonStyle = {
      padding: "9px 10px",
    },
    svgStyle = {
      marginTop: "-10px",
      marginLeft: "-10px",
    };

  if (short) {
    return (
      <Button color="primary" variant="contained" href="/api/auth/microsoft" style={buttonStyle}>
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="31" href="/api/auth/microsoft" style={svgStyle} className={buttonClassName} onClick={onClick}>
          <path fill="#f25022" d="M13 11h9v9h-9z" />
          <path fill="#00a4ef" d="M13 21h9v9h-9z" />
          <path fill="#7fba00" d="M23 11h9v9h-9z" />
          <path fill="#ffb900" d="M23 21h9v9h-9z" />
        </svg>
        SIGN IN WITH MICROSOFT
      </Button>
    );
  }
  return (
    <Button color="primary" variant="contained" href="/api/auth/microsoft" style={buttonStyle}>
      <svg xmlns="http://www.w3.org/2000/svg" width="40" height="31" href="/api/auth/microsoft" style={svgStyle} className={buttonClassName} onClick={onClick}>
        <path fill="#f25022" d="M13 11h9v9h-9z" />
        <path fill="#00a4ef" d="M13 21h9v9h-9z" />
        <path fill="#7fba00" d="M23 11h9v9h-9z" />
        <path fill="#ffb900" d="M23 21h9v9h-9z" />
      </svg>
      SIGN IN WITH MICROSOFT
    </Button>
  );
};
