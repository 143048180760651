import React, { useEffect, useMemo, useState } from "react";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";

import Button from "@material-ui/core/Button";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";

import IconDelete from "@material-ui/icons/Delete";

import { estimatedcostsFull } from "../../../lib/api/estimatedcost";
import { VarietiesByCommodity } from "../../../lib/api/variety";
import { estimatedcostsetups, estimatedcostsetupsFull } from "../../../lib/api/estimatedcostsetup";
import { estimatedcostitemsAllSortedMappedforCombo } from "../../../lib/api/estimatedcostitem";
import { getPacksByCommodityCodeFullCombo } from "../../../lib/api/pack";

import Confirmation from "../../../lib/components/confirmation";

import numeral from "numeral";

import { MaterialSelectNativeComponentNonField } from "../../../lib/helpers/materialcomponents";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1),
      marginTop: "50px",
    },
    varietySelect: {
      width: "100%",
    },
    tableCell: {
      borderWidth: "1px",
      paddingLeft: "2px",
      paddingRight: "2px",
      borderColor: "black",
      borderStyle: "solid",
      width: "200px",
    },
    tableCellValue: {
      borderWidth: "1px",
      paddingLeft: "2px",
      paddingRight: "2px",
      borderColor: "black",
      borderStyle: "solid",
      width: "80px",
      textAlign: "right",
    },
  });

type EstimatedCostsSetupDetailProps = {
  estimatedcost_id: number;
  packspec_id: number;
  submitSetupDetail(packSpecId: number | string, detail: any[]): Promise<void>;
} & WithStyles<typeof styles>;

type EstimatedCostsSetupDetailEditingProps = {
  estimatedcostid: number;
  packspec_id: number;
};

const EstimatedCostsSetupDetailUnstyled: React.FunctionComponent<EstimatedCostsSetupDetailProps> = ({ classes, estimatedcost_id, packspec_id, submitSetupDetail }) => {
  const [currentDetail, setCurrentDetail] = useState({});
  const [editingDetail, setEditingDetail] = useState<EstimatedCostsSetupDetailEditingProps>();
  const [estimatedCostItems, setEstimatedCostItems] = useState([]);
  const [estCostSetupDetail, setEstCostSetupDetail] = useState([]);

  useEffect(() => {
    loadData();
  }, [estimatedcost_id, packspec_id]);

  const loadData = async () => {
    const result = await estimatedcostsetupsFull(estimatedcost_id, packspec_id);
    const formatted = result.reduce((acc, curr) => {
      acc[curr.packspec_id] = [...(acc[curr.packspec_id] || []), curr];
      return acc;
    }, {});
    setCurrentDetail(formatted);
    setEstCostSetupDetail(result);
  };

  useEffect(() => {
    estimatedcostitemsAllSortedMappedforCombo().then((result) => {
      setEstimatedCostItems(result);
    });
  }, []);

  const handleEdit = (psid) => {
    setEditingDetail({ estimatedcostid: estimatedcost_id, packspec_id: psid });
  };

  const handleEditClose = () => {
    setEditingDetail(undefined);
  };

  const handleEditConfirm = async (newPackSpecId: number | string, detail: any[]) => {
    await submitSetupDetail(newPackSpecId, detail);
    handleEditClose();
    // loadData();
  };

  const totals = useMemo(() => {
    return estCostSetupDetail.reduce((obj, row) => {
      obj["usd"] = (obj["usd"] || 0) + parseFloat(row.usd);
      obj["eur"] = (obj["eur"] || 0) + parseFloat(row.eur);
      obj["gbp"] = (obj["gbp"] || 0) + parseFloat(row.gbp);
      obj["cad"] = (obj["cad"] || 0) + parseFloat(row.cad);
      obj["value"] = (obj["value"] || 0) + parseFloat(row.value);
      return obj;
    }, {});
  }, [estCostSetupDetail]);

  return (
    <div className={classes.root}>
      {Object.keys(currentDetail).map((pskey) => {
        return (
          <div style={{ float: "left", marginRight: "10px" }}>
            <span style={{ fontWeight: "bold" }}>
              Pallet Spec: ({currentDetail[pskey][0].palletspec_code}) {currentDetail[pskey][0].palletspec_carton}
            </span>
            <table>
              <tbody>
                <tr>
                  <td className={classes.tableCell}>Group</td>
                  <td className={classes.tableCell}>Description</td>
                  <td className={classes.tableCellValue}>USD</td>
                  <td className={classes.tableCellValue}>EUR</td>
                  <td className={classes.tableCellValue}>GBP</td>
                  <td className={classes.tableCellValue}>CAD</td>
                  <td className={classes.tableCellValue}>ZAR</td>
                  <td className={classes.tableCell}>Varieties</td>
                </tr>
                {currentDetail[pskey].map((di) => {
                  if (di.estimatedcostitem_type == 0) {
                    return (
                      <tr key={`row0${di.id}`}>
                        <td className={classes.tableCell}>{di.group_name}</td>
                        <td className={classes.tableCell}>{di.estimatedcostitem_costdescription}</td>
                        <td className={classes.tableCellValue}>{numeral(di.usd).format("0.00")}</td>
                        <td className={classes.tableCellValue}>{numeral(di.eur).format("0.00")}</td>
                        <td className={classes.tableCellValue}>{numeral(di.gbp).format("0.00")}</td>
                        <td className={classes.tableCellValue}>{numeral(di.cad).format("0.00")}</td>
                        <td className={classes.tableCellValue}>{numeral(di.value).format("0.00")}</td>
                        <td className={classes.tableCell}>{di.variety ? di.variety : "ALL"}</td>
                      </tr>
                    );
                  }
                })}
                <tr>
                  <td colSpan={2}>Total Producer Estimated Local Costs</td>
                  <td className={classes.tableCellValue}>{numeral(totals.usd).format("0.00")}</td>
                  <td className={classes.tableCellValue}>{numeral(totals.eur).format("0.00")}</td>
                  <td className={classes.tableCellValue}>{numeral(totals.gbp).format("0.00")}</td>
                  <td className={classes.tableCellValue}>{numeral(totals.cad).format("0.00")}</td>
                  <td className={classes.tableCellValue}>{numeral(totals.value).format("0.00")}</td>
                </tr>
                {currentDetail[pskey].map((di) => {
                  if (di.estimatedcostitem_type == 1) {
                    return (
                      <tr key={`row1${di.id}`}>
                        <td className={classes.tableCell}>{di.group_name}</td>
                        <td className={classes.tableCell}>{di.estimatedcostitem_costdescription}</td>
                        <td className={classes.tableCellValue}>{numeral(di.usd).format("0.00")}</td>
                        <td className={classes.tableCellValue}>{numeral(di.eur).format("0.00")}</td>
                        <td className={classes.tableCellValue}>{numeral(di.gbp).format("0.00")}</td>
                        <td className={classes.tableCellValue}>{numeral(di.cad).format("0.00")}</td>
                        <td className={classes.tableCellValue}>{numeral(di.value).format("0.00")}</td>
                        <td className={classes.tableCell}>{di.variety ? di.variety : "ALL"}</td>
                      </tr>
                    );
                  }
                })}
                {currentDetail[pskey].map((di) => {
                  if (di.estimatedcostitem_type == 2) {
                    return (
                      <tr key={`row2${di.id}`}>
                        <td className={classes.tableCell}>{di.group_name}</td>
                        <td className={classes.tableCell}>{di.estimatedcostitem_costdescription}</td>
                        <td className={classes.tableCellValue}>{numeral(di.usd).format("0.00")} %</td>
                        <td className={classes.tableCellValue}>{numeral(di.eur).format("0.00")} %</td>
                        <td className={classes.tableCellValue}>{numeral(di.gbp).format("0.00")} %</td>
                        <td className={classes.tableCellValue}>{numeral(di.cad).format("0.00")} %</td>
                        <td className={classes.tableCellValue}>{numeral(di.value).format("0.00")} %</td>
                        <td className={classes.tableCell}>{di.variety ? di.variety : "ALL"}</td>
                      </tr>
                    );
                  }
                })}
              </tbody>
            </table>
            <div style={{ paddingTop: "5px", float: "right", marginRight: "-2px" }}>
              <Button
                variant="contained"
                color="primary"
                style={{ margin: "2px", width: "100px" }}
                onClick={() => {
                  handleEdit(pskey);
                }}
              >
                EDIT
              </Button>
            </div>
          </div>
        );
      })}
      <Confirmation isOpen={editingDetail ? true : false} handleClose={handleEditClose} handleConfirm={handleEditClose} title={"Estimated Costs Details"} body={undefined}>
        <CostEstimationSetup editingDetail={editingDetail} estimatedCostItems={estimatedCostItems} handleSubmit={handleEditConfirm} cancel={handleEditClose} classes={classes} />
      </Confirmation>
    </div>
  );
};

export default withStyles(styles)(EstimatedCostsSetupDetailUnstyled);

const CostEstimationSetup: React.FunctionComponent<any> = ({ classes, editingDetail, estimatedCostItems, handleSubmit, cancel }) => {
  const [comboVarietyOpen, setComboVarietyOpen] = useState<string | undefined>(undefined);
  const [detail, setDetail] = useState(editingDetail);
  const [costEstimationDetail, setCostEstimationDetail] = useState([]);
  const [varieties, setVarieties] = useState(undefined);
  const [packSpecs, setPackSpecs] = useState([]);
  const [estimatedCostHead, setEstimatedCostHead] = useState({
    cartons: undefined,
    commodity: undefined,
    commodity_full: undefined,
    created_at: undefined,
    exchange_usd: undefined,
    exchange_eur: undefined,
    exchange_gbp: undefined,
    exchange_cad: undefined,
    exchange_zar: undefined,
    id: undefined,
    market: undefined,
    pack: undefined,
    season: undefined,
    updated_at: undefined,
    region: undefined,
    country: undefined,
  });

  useEffect(() => {
    loadData();
  }, [editingDetail]);

  const loadData = async () => {
    if (detail) {
      const [estCostSetupFull, [estimatedCostData]] = await Promise.all([
        estimatedcostsetupsFull(detail.estimatedcostid, detail.packspec_id),
        estimatedcostsFull(detail.estimatedcostid),
      ]);

      const [varietiesByComm, packsResult] = await Promise.all([VarietiesByCommodity(estimatedCostData.commodity), getPacksByCommodityCodeFullCombo(estimatedCostData.commodity)]);

      const reMappedEstimations = estCostSetupFull.map((item) => ({
        ...item,
        value: numeral(item.value).format("0.00"),
        usd: calculateRate(item.value, estimatedCostData.exchange_usd),
        cad: calculateRate(item.value, estimatedCostData.exchange_cad),
        gbp: calculateRate(item.value, estimatedCostData.exchange_gbp),
        eur: calculateRate(item.value, estimatedCostData.exchange_eur),
      }));

      setCostEstimationDetail(reMappedEstimations);
      setEstimatedCostHead(estimatedCostData);
      setPackSpecs(packsResult);
      setVarieties(varietiesByComm.data);
    }
  };

  const handleChangedDetail = (id: number, field: string, value: any) => {
    const idx = costEstimationDetail.findIndex((item) => item.id == id);
    const detailChange = [...costEstimationDetail];
    detailChange[idx][field] = !value ? "" : field == "variety" ? (value.includes("ALL") ? "ALL" : value.join(",")) : value;
    setCostEstimationDetail([...detailChange]);
  };

  const calculateRate = (value, exchange) => {
    return (value / exchange).toFixed(5);
  };

  const currencies = {
    eur: estimatedCostHead.exchange_eur,
    usd: estimatedCostHead.exchange_usd,
    gbp: estimatedCostHead.exchange_gbp,
    cad: estimatedCostHead.exchange_cad,
  };

  const handleUpdateCurrency = (id, field) => {
    const idx = costEstimationDetail.findIndex((item) => {
      return item.id == id;
    });

    const detailChange = [...costEstimationDetail];

    if (field != "ZAR") {
      detailChange[idx].value = (detailChange[idx][field] * currencies[field]).toFixed(2);
    }

    JSON.parse(JSON.stringify(Object.keys(currencies)))
      .filter((currency) => currency != field)
      .map((key) => {
        detailChange[idx][key] = calculateRate(detailChange[idx].value, currencies[key]);
      });
    setCostEstimationDetail([...detailChange]);
  };

  const handleSave = () => {
    handleSubmit(detail.packspec_id, costEstimationDetail);
  };

  const handleAddLine = () => {
    const createData = {
      data: {
        estimatedcost_id: detail.estimatedcostid,
        packspec_id: detail.packspec_id,
        estimatedcostitem_id: 1,
        value: 1,
      },
    };
    estimatedcostsetups.create(createData).then((result) => {
      const id = result[0];
      const detailChange = [...costEstimationDetail];
      detailChange.push({ ...createData.data, id: id });
      setCostEstimationDetail([...detailChange]);
    });
  };

  const handleRemoveLine = async (id) => {
    await estimatedcostsetups.remove(id);
    loadData();
  };

  const handleChanges = (field, value) => {
    console.log(field, value);
    setDetail({ ...detail, [field]: value });
  };

  const handleVarietyCombo = (id: string) => {
    setComboVarietyOpen(id);
  };

  const totals = useMemo(() => {
    return costEstimationDetail.reduce((obj, row) => {
      if (row.estimatedcostitem_type != 2) {
        obj["usd"] = (obj["usd"] || 0) + parseFloat(row.usd);
        obj["eur"] = (obj["eur"] || 0) + parseFloat(row.eur);
        obj["gbp"] = (obj["gbp"] || 0) + parseFloat(row.gbp);
        obj["cad"] = (obj["cad"] || 0) + parseFloat(row.cad);
      }
      obj["value"] = (obj["value"] || 0) + parseFloat(row.value);
      return obj;
    }, {});
  }, [costEstimationDetail]);

  return (
    <div>
      {detail && (
        <div>
          <div>
            <table>
              <tbody>
                <tr>
                  <td style={{ fontWeight: "bold", width: "150px" }}>Season </td>
                  <td style={{ width: "10px" }}> : </td>
                  <td style={{ width: "200px" }}> {estimatedCostHead.season}</td>
                </tr>
                <tr>
                  <td style={{ fontWeight: "bold", width: "150px" }}>Commodity </td>
                  <td style={{ width: "10px" }}> : </td>
                  <td style={{ width: "200px" }}>{` ${estimatedCostHead.commodity_full} (${estimatedCostHead.commodity})`}</td>
                </tr>
                <tr>
                  <td style={{ fontWeight: "bold", width: "150px" }}>Region </td>
                  <td style={{ width: "10px" }}> : </td>
                  <td style={{ width: "200px" }}> {estimatedCostHead.region}</td>
                </tr>
                <tr>
                  <td style={{ fontWeight: "bold", width: "150px" }}>Market </td>
                  <td style={{ width: "10px" }}> : </td>
                  <td style={{ width: "200px" }}> {estimatedCostHead.market}</td>
                </tr>
                <tr>
                  <td style={{ fontWeight: "bold", width: "150px" }}>Country </td>
                  <td style={{ width: "10px" }}> : </td>
                  <td style={{ width: "200px" }}> {estimatedCostHead.country}</td>
                </tr>
                <tr>
                  <td style={{ fontWeight: "bold", width: "150px" }}>Pack </td>
                  <td style={{ width: "10px" }}> : </td>
                  <td style={{ width: "200px" }}> {estimatedCostHead.pack}</td>
                </tr>
                <tr>
                  <td style={{ fontWeight: "bold", width: "150px" }}>Cartons per Pallet </td>
                  <td style={{ width: "10px" }}> : </td>
                  <td style={{ width: "200px" }}> {estimatedCostHead.cartons}</td>
                </tr>
                <tr>
                  <td style={{ fontWeight: "bold", width: "150px" }}>Pallet Spec </td>
                  <td style={{ width: "10px" }}> : </td>
                  <td style={{ width: "200px" }}>
                    <MaterialSelectNativeComponentNonField
                      name={"packspec_id"}
                      onChange={(e) => handleChanges("packspec_id", e.target.value)}
                      value={detail.packspec_id}
                      addNone={false}
                      childdata={packSpecs}
                      nonedisabled={true}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
            <br />
            <br />
            <table>
              <tbody>
                <tr>
                  <td style={{ width: "100px", fontWeight: "bold" }}></td>
                  <td style={{ width: "300px", fontWeight: "bold" }}></td>
                  <td style={{ width: "100px", fontWeight: "bold" }}>USD</td>
                  <td style={{ width: "100px", fontWeight: "bold" }}>EUR</td>
                  <td style={{ width: "100px", fontWeight: "bold" }}>GBP</td>
                  <td style={{ width: "100px", fontWeight: "bold" }}>CAD</td>
                  <td style={{ width: "100px", fontWeight: "bold" }}>ZAR</td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td style={{ width: "100px", fontWeight: "bold" }}>Group</td>
                  <td style={{ width: "300px", fontWeight: "bold" }}>Item</td>
                  <td style={{ width: "100px", fontWeight: "bold" }}>{numeral(estimatedCostHead.exchange_usd).format("0.00")}</td>
                  <td style={{ width: "100px", fontWeight: "bold" }}>{numeral(estimatedCostHead.exchange_eur).format("0.00")}</td>
                  <td style={{ width: "100px", fontWeight: "bold" }}>{numeral(estimatedCostHead.exchange_gbp).format("0.00")}</td>
                  <td style={{ width: "100px", fontWeight: "bold" }}>{numeral(estimatedCostHead.exchange_cad).format("0.00")}</td>
                  <td style={{ width: "100px", fontWeight: "bold" }}>{numeral(estimatedCostHead.exchange_zar).format("0.00")}</td>
                  <td style={{ width: "300px", fontWeight: "bold" }}>Varieties</td>
                  <td></td>
                </tr>
                {costEstimationDetail.map((ced, index) => {
                  return (
                    <tr key={`row${ced.id}${index}`}>
                      <td key={`group${ced.id}${index}`}>{ced.group_name}</td>
                      <td key={`item${ced.id}${index}`}>
                        <MaterialSelectNativeComponentNonField
                          name={`item${ced.id}`}
                          onChange={(e) => handleChangedDetail(ced.id, "estimatedcostitem_id", e.target.value)}
                          value={ced.estimatedcostitem_id}
                          addNone={false}
                          childdata={estimatedCostItems}
                          nonedisabled={true}
                        />
                      </td>

                      {/* USD */}
                      <td key={`USD${ced.id}${index}`} style={{ width: "100px" }}>
                        {ced.estimatedcostitem_type != 2 && (
                          <TextField
                            name={`USD${ced.id}${index}`}
                            onBlur={(e) => handleUpdateCurrency(ced.id, "usd")}
                            onChange={(e) => handleChangedDetail(ced.id, "usd", e.target.value)}
                            value={ced.usd || 0.0}
                          />
                        )}
                      </td>

                      {/* EUR */}
                      <td key={`EUR${ced.id}${index}`} style={{ width: "100px" }}>
                        {ced.estimatedcostitem_type != 2 && (
                          <TextField
                            name={`EUR${ced.id}${index}`}
                            onBlur={(e) => handleUpdateCurrency(ced.id, "eur")}
                            onChange={(e) => handleChangedDetail(ced.id, "eur", e.target.value)}
                            value={ced.eur || 0.0}
                          />
                        )}
                      </td>

                      {/* GBP */}
                      <td key={`GBP${ced.id}${index}`} style={{ width: "100px" }}>
                        {ced.estimatedcostitem_type != 2 && (
                          <TextField
                            name={`GBP${ced.id}${index}`}
                            onBlur={(e) => handleUpdateCurrency(ced.id, "gbp")}
                            onChange={(e) => handleChangedDetail(ced.id, "gbp", e.target.value)}
                            value={ced.gbp || 0.0}
                          />
                        )}
                      </td>

                      {/* CAD */}
                      <td key={`CAD${ced.id}${index}`} style={{ width: "100px" }}>
                        {ced.estimatedcostitem_type != 2 && (
                          <TextField
                            name={`CAD${ced.id}${index}`}
                            onBlur={(e) => handleUpdateCurrency(ced.id, "cad")}
                            onChange={(e) => handleChangedDetail(ced.id, "cad", e.target.value)}
                            value={ced.cad || 0.0}
                          />
                        )}
                      </td>

                      {/* ZAR */}
                      <td key={`ZAR${ced.id}${index}`}>
                        <TextField
                          name={`ZAR${ced.id}${index}`}
                          onBlur={(e) => handleUpdateCurrency(ced.id, "ZAR")}
                          onChange={(e) => handleChangedDetail(ced.id, "value", e.target.value)}
                          value={ced.value || 0.0}
                          style={{ width: "100%" }}
                        />
                      </td>

                      <td key={`col2${ced.id}${index}${ced.variety}`}>
                        <Select
                          multiple
                          displayEmpty
                          className={classes.varietySelect}
                          disabled={ced.estimatedcostitem_type == 0}
                          value={(ced.variety && ced.variety.split(",")) || []}
                          open={comboVarietyOpen == ced.id}
                          onOpen={(e) => handleVarietyCombo(ced.id)}
                          onClose={(e) => handleVarietyCombo(undefined)}
                          onChange={(e) => handleChangedDetail(ced.id, "variety", e.target.value)}
                          renderValue={(value) => {
                            if (!value) return undefined;
                            const selected = JSON.parse(JSON.stringify(value));
                            if (!selected[0] || selected[0].length === 0) {
                              // Purposely allowing different selected varieties to
                              // show in case there are any(instead of hiding and
                              // polluting the db)
                              return ced.estimatedcostitem_type != 0 ? <em>Please make a selection</em> : <em>ALL</em>;
                            }
                            if (selected) {
                              return <em>{`${selected.join(", ")}`}</em>;
                            }
                          }}
                        >
                          <MenuItem disabled value="">
                            <em>Please make a selection</em>
                          </MenuItem>
                          <MenuItem value={"ALL"}>ALL</MenuItem>
                          {(varieties || []).map((variety) => (
                            <MenuItem key={variety.code} value={variety.code}>
                              {`${variety.code} (${variety.name})`}
                            </MenuItem>
                          ))}
                        </Select>
                      </td>
                      <td>
                        <IconButton color="secondary" onClick={() => handleRemoveLine(ced.id)}>
                          <IconDelete />
                        </IconButton>
                      </td>
                    </tr>
                  );
                })}
                <tr>
                  <td style={{ width: "100px", fontWeight: "bold", fontSize: "16px" }}>TOTALS</td>
                  <td style={{ width: "300px", fontWeight: "bold", fontSize: "16px" }}></td>
                  <td style={{ width: "100px", fontWeight: "bold", fontSize: "16px" }}>{numeral(totals.usd).format("0.00000")}</td>
                  <td style={{ width: "100px", fontWeight: "bold", fontSize: "16px" }}>{numeral(totals.eur).format("0.00000")}</td>
                  <td style={{ width: "100px", fontWeight: "bold", fontSize: "16px" }}>{numeral(totals.gbp).format("0.00000")}</td>
                  <td style={{ width: "100px", fontWeight: "bold", fontSize: "16px" }}>{numeral(totals.cad).format("0.00000")}</td>
                  <td style={{ width: "100px", fontWeight: "bold", fontSize: "16px" }}>{numeral(totals.value).format("0.00000")}</td>
                  <td style={{ width: "300px", fontWeight: "bold", fontSize: "16px" }}></td>
                  <td></td>
                </tr>
              </tbody>
            </table>
            <div style={{ paddingTop: "5px", float: "left" }}>
              <Button variant="contained" color="primary" style={{ margin: "2px", width: "100px" }} onClick={() => handleAddLine()}>
                add line
              </Button>
              <Button variant="contained" color="secondary" style={{ margin: "2px", width: "100px" }} onClick={() => cancel()}>
                cancel
              </Button>
              <Button variant="contained" color="primary" style={{ margin: "2px", width: "100px" }} onClick={() => handleSave()}>
                save
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
