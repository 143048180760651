import { request } from "./_base";

const endpoint = "systemstability";

export const systemstabilityloadoutdetail = () =>
  request(`${endpoint}/ext/loadoutdetail`, {
    method: "GET",
  })
    .then(async (result) => {
      if (result.status == 500) {
        const resp = await result.json();
        throw resp;
      }
      return result.json();
    })
    .then(({ data }) => {
      return data;
    });

export const systemstabilitybarcode = () =>
  request(`${endpoint}/ext/barcode`, {
    method: "GET",
  })
    .then(async (result) => {
      if (result.status == 500) {
        const resp = await result.json();
        throw resp;
      }
      return result.json();
    })
    .then(({ data }) => {
      return data;
    });

export const systemstabilitystockdetail = () =>
  request(`${endpoint}/ext/stockdetail`, {
    method: "GET",
  })
    .then(async (result) => {
      if (result.status == 500) {
        const resp = await result.json();
        throw resp;
      }
      return result.json();
    })
    .then(({ data }) => {
      return data;
    });

export const systemstabilityfileimportheaderinfo = () =>
  request(`${endpoint}/ext/fileimportheaderinfo`, {
    method: "GET",
  })
    .then(async (result) => {
      if (result.status == 500) {
        const resp = await result.json();
        throw resp;
      }
      return result.json();
    })
    .then(({ data }) => {
      return data;
    });

export const systemstabilitybarcodepalletsize = () =>
  request(`${endpoint}/ext/barcodepalletsize`, {
    method: "GET",
  })
    .then(async (result) => {
      if (result.status == 500) {
        const resp = await result.json();
        throw resp;
      }
      return result.json();
    })
    .then(({ data }) => {
      return data;
    });

export const systemstabilitystockdetailduplication = () =>
  request(`${endpoint}/ext/stockdetailduplication`, {
    method: "GET",
  })
    .then(async (result) => {
      if (result.status == 500) {
        const resp = await result.json();
        throw resp;
      }
      return result.json();
    })
    .then(({ data }) => {
      return data;
    });

export const systemstabilityconsolidation = () =>
  request(`${endpoint}/ext/consolidation`, {
    method: "GET",
  })
    .then(async (result) => {
      if (result.status == 500) {
        const resp = await result.json();
        throw resp;
      }
      return result.json();
    })
    .then(({ data }) => {
      return data;
    });

export const systemstabilityinvoicevsdetails = () =>
  request(`${endpoint}/ext/invoicevsdetails`, {
    method: "GET",
  })
    .then(async (result) => {
      if (result.status == 500) {
        const resp = await result.json();
        throw resp;
      }
      return result.json();
    })
    .then(({ data }) => {
      return data;
    });
