import React, { createRef } from "react";
import { Redirect } from "react-router-dom";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";

import AppBar from "@material-ui/core/AppBar";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import Tooltip from "@material-ui/core/Tooltip";
import CircularProgress from "@material-ui/core/CircularProgress";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import LinearProgress from "@material-ui/core/LinearProgress";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";

import { Form } from "react-final-form";

import numeral from "numeral";

import { DialogInformation } from "../../lib/components/dialoginformation";
import { isNullOrUndef } from "../../lib/helpers/isNullOrUndef";

import { SaleBarcodesByDispatchIDType } from "../../lib/api/sale";
import {
  BarcodeInfoDetail,
  creditorsInfoBarcodeDetail,
  creditorsinvoice,
  creditorsInvoiceExistsByInvoiceNumber,
  getLastCreditorsInvoiceDate,
  getSingleExtend,
} from "../../lib/api/creditorsinvoice";
import { creditorsinvoicedetailClearByInvoice, creditorsinvoicedetailFullByInvoice, createBatchDetail } from "../../lib/api/creditorsinvoicedetail";
import {
  creditorsinvoicedetailitemsClearByInvoice,
  creditorsinvoicedetailitemsFullByInvoiceId,
  CreditorsInvoiceDetailItemType,
  createBatchDetailItems,
} from "../../lib/api/creditorsinvoicedetailitems";
import { VatTypes } from "../../lib/api/vattypes";
import Confirmation from "../../lib/components/confirmation";
import { creditorsinvoicedetailitemstemplatedetailByTemplateID, CreditorsInvoiceDetailItemTemplateDetailType } from "../../lib/api/creditorsinvoicedetailitemstemplatedetail";

import InfoHead from "./creditorsinfohead";
import InfoDetail from "./creditorsinfodetail";
import InfoDetailItems from "./creditorsinfodetailitems";
import CreditorsInfoPreview from "./creditorsinfopreview";

import Splitter from "m-react-splitters";
import "m-react-splitters/lib/splitters.css";
import BigNumber from "bignumber.js";
import { getCreditorsImportTemplatesByCreditorId } from "../../lib/api/creditorsimporttemplatedetail";

import { BarcodeMissingGrid } from "./barcodemissinggrid";
import { getFinancialYearSelected } from "../../lib/api/week";
import ExcelToJson from "../../lib/components/ExcelToJson";
import { SnackContext } from "../../lib/context/SnackContext";
import { getCreditorByCode } from "../../lib/api/creditors";
import { GenerateErrorMessage } from "../../lib/helpers/string_methods";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1),
      width: "100%",
      height: "calc(100% - 65px)",
      display: "grid",
      gridTemplateRows: "min-content 1fr",
    },
    gridContainer: {
      display: "grid",
      gridTemplateAreas: `"form-wrapper content-container"`,
      gridTemplateColumns: "450px 1fr",
      height: "100%",
    },
    formWrapper: {
      gridArea: "form-wrapper",
    },
    formContainer: {
      display: "grid",
      gridTemplateRows: "1fr",
      width: "max-content",
    },
    formButtons: {
      display: "flex",
      justifyContent: "flex-start",
      gap: "5px",
    },
    formHeaderButtonContainer: {
      position: "absolute",
      right: "50px",
      top: "85px",
      display: "flex",
      gap: "5px",
      zIndex: 1100,
    },
    contentContainer: {
      gridArea: "content-container",
      display: "grid",
      height: "100%",
    },
    tabpanel: {
      height: "100%",
      "& > :first-child": {
        height: "100%",
        padding: "5px",
        "& > :first-child": {
          height: "100%",
        },
      },
    },
    previewtabpanel: {
      height: "100%",
      overflow: "hidden",
      position: "relative",
      "& > :first-child": {
        padding: "5px",
        "& > :first-child": {
          height: "100%",
        },
      },
    },
    importTemplateWrapper: {
      display: "flex",
      flexDirection: "row",
      alignItems: "flex-end",
      justifyContent: "flex-start",
      padding: "0px 8px",
      gap: "10px",
    },
    importTemplateSelect: {
      width: "150px",
      maxWidth: "150px",
    },
  });

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && (
        <Box p={3}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

type CreditorsInvoiceInfoProps = {
  location: any;
  history: any;
} & WithStyles<typeof styles>;

const FormatterNumber2Decimals: any = (value) => {
  return numeral(value).format("0.00");
};

const FormatterNumber3Decimals: any = (value) => {
  return numeral(value).format("0.000");
};
export class CreditorsInvoiceInfoUnstyled extends React.Component<CreditorsInvoiceInfoProps, any> {
  state = {
    classes: undefined,
    id: undefined,
    loading: true,
    message: undefined,
    navigateback: undefined,
    navigateReload: undefined,
    dataHead: undefined,
    dataDetailItems: [],
    // group: 0,
    tabValue: 0,
    containerRef: undefined,
    grouped: true,
    tabPanelRef: undefined,
    minGridHeight: undefined,
    minGridHeightSecond: undefined,
    createErrorMsg: undefined,
    selectedDetailRecords: [],
    mappingFileData: [],
    barcodesDetail: [],
    importTemplateDetails: undefined,
    importTemplateDetailsFormValues: undefined,
    isDirty: false,
    matchCreditor: undefined,
    importTemplateOptions: [],
    importTemplateId: undefined,
    missingBarcodeMappings: [],
    creditorLoading: true,
    barcodeDetailLoading: true,
    selectedSequence: 0,
  };

  static contextType = SnackContext;
  context!: React.ContextType<typeof SnackContext>;

  constructor(props) {
    super(props);
    this.state.classes = props.classes;
    this.state.id = props.match.params.id;
    if (props.match.params.creditor) this.state.matchCreditor = props.match.params.creditor;
    this.state.containerRef = createRef();
    this.state.tabPanelRef = createRef();
  }

  componentDidMount() {
    this.loadData();
  }

  loadingForImport = (barcodeDetailLoading: boolean = false): boolean => {
    return barcodeDetailLoading && this.state.importTemplateOptions.length > 0;
  };

  hasExistingCI = (): boolean => {
    return this.state.dataHead && !isNullOrUndef(this.state.dataHead.id) && this.state.dataHead.id != 0;
  };

  loadCreditor = async () => {
    this.setState({ creditorLoading: true });

    const [result] = await getSingleExtend(this.state.id);

    if (result) {
      this.setState({ dataHead: result, creditorLoading: false });
      return result.creditors_id;
    } else {
      const data = await getLastCreditorsInvoiceDate();
      const urlSearchParams = new URLSearchParams(window.location.search);
      const params = Object.fromEntries(urlSearchParams.entries());

      if (params.creditors_code) {
        const [creditor] = await getCreditorByCode(params.creditors_code);
        this.setState({
          dataHead: {
            id: 0,
            invoicedate: data[0].invoicedate,
            creditors_id: creditor.id,
            currency_id: creditor.currency_id,
            roe: 1,
            group: creditor.group,
            subgroup: creditor.subgroup,
            allocated_year: getFinancialYearSelected(),
          },
          creditorLoading: false,
        });
      } else {
        this.setState({
          dataHead: { id: 0, invoicedate: data[0].invoicedate, creditors_id: 0, currency_id: 1, roe: 1, allocated_year: getFinancialYearSelected() },
          creditorLoading: false,
        });
      }
    }

    return null;
  };

  loadBarcodeDetail = async () => {
    this.setState({ barcodeDetailLoading: true });
    const barcodesDetail: BarcodeInfoDetail[] = await creditorsInfoBarcodeDetail(getFinancialYearSelected());
    this.setState({ barcodesDetail, barcodeDetailLoading: false });
  };

  loadData = async () => {
    this.setState({ loading: true });
    const creditor_id = await this.loadCreditor();

    if (creditor_id) {
      const importTemplateOptions = await getCreditorsImportTemplatesByCreditorId(creditor_id);

      const data = await creditorsinvoicedetailitemsFullByInvoiceId(this.state.id);
      const totalled = this.calculateTotalsData(data);

      if (importTemplateOptions.length > 0) {
        this.setState({ importTemplateId: importTemplateOptions[0].id });
      }
      this.setState({ dataDetailItems: totalled, importTemplateOptions: importTemplateOptions });
    }
    const selectedDetailRecords = await creditorsinvoicedetailFullByInvoice(this.state.id);
    this.setState({ loading: false, selectedDetailRecords });
    await this.loadBarcodeDetail();
  };

  calculateTotalsData = (data) => {
    const totalled = data.map((item) => {
      const vatV = Number(item["vat"]) == -1 ? 0 : Number(item["vat"]) / 100;
      const rateV = Number(item["rate"]);
      const unitV = Number(item["units"]);

      let vatvalue = rateV * unitV * vatV;
      if (isNaN(vatvalue)) {
        vatvalue = 0;
      }
      item["vatamount"] = vatvalue;

      let totalvalue = rateV * unitV * vatV + rateV * unitV;
      if (isNaN(totalvalue)) {
        totalvalue = 0;
      }
      item["total"] = totalvalue;
      item["totalexclvat"] = rateV * unitV;

      return item;
    });

    return totalled;
  };

  handleBack = () => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());

    if (params.creditors_code) {
      this.props.history.push(`/creditors/invoice/${params.creditors_code}`);
    } else {
      this.props.history.push(`/creditors/summary`);
    }
  };

  handleInformationClose = () => {
    this.setState({ message: undefined });
  };

  handleCreateErrorClose = () => {
    this.setState({ createErrorMsg: undefined });
  };

  handleSubmit = async (formvalues?, reloadnew: boolean = false) => {
    this.setState({ loading: true });

    let headId = this.state.dataHead.id;

    if (formvalues) {
      const invoiceData = {
        data: {
          creditors_id: formvalues.creditors_id,
          invoicenumber: formvalues.invoicenumber,
          currency_id: formvalues.currency_id,
          roe: formvalues.roe,
          validate_totalexcl: parseFloat((formvalues.validate_totalexcl || "").replace(/[, ]+/g, "")),
          validate_vat: parseFloat((formvalues.validate_vat || "").replace(/[, ]+/g, "")),
          invoicedate: isNullOrUndef(formvalues.invoicedate) ? new Date() : new Date(formvalues.invoicedate),
          due_date: isNullOrUndef(formvalues.due_date) ? new Date() : new Date(formvalues.due_date),
          consolidationapply: formvalues.consolidationapply ? formvalues.consolidationapply : 1,
          allocated_year: formvalues.allocated_year,
        },
      };

      try {
        if (this.state.dataHead.id == 0) {
          const invoice = await creditorsInvoiceExistsByInvoiceNumber(invoiceData.data);

          if (invoice.length > 0) {
            this.setState({ createErrorMsg: "An invoice with this number exists for this creditor." });
            return;
          }

          const result = await creditorsinvoice.create(invoiceData);
          headId = result[0];
        } else {
          await creditorsinvoice.update(headId, invoiceData);
        }
      } catch (error) {
        const err = GenerateErrorMessage(error, "Cannot exceed limit for field");
        this.context.updateSnack({ show: true, color: "red", message: err });
      }
    }

    if (!headId) return;

    await creditorsinvoicedetailClearByInvoice(headId);
    await creditorsinvoicedetailitemsClearByInvoice(headId);

    const creditorsinvoicedetailArr = [];
    this.state.selectedDetailRecords.map(async (detailItem) => {
      const detailToCreate = {
        data: {
          creditorsinvoice_id: headId,
          group: detailItem.group,
          barcode_id: detailItem.barcode_id,
        },
      };
      creditorsinvoicedetailArr.push(detailToCreate);
    });
    await createBatchDetail(creditorsinvoicedetailArr);

    const dataDetailItemsArr = [];
    this.state.dataDetailItems.map(async (detailItem) => {
      const detailToCreate: { data: CreditorsInvoiceDetailItemType } = {
        data: {
          sequence: detailItem.sequence,
          group: detailItem.group,
          code: detailItem.code,
          chargedescription: detailItem.chargedescription,
          rate: detailItem.rate,
          units: detailItem.units,
          vat: detailItem.vat,
          creditorsinvoice_id: parseInt(headId),
        },
      };
      dataDetailItemsArr.push(detailToCreate);
    });
    await createBatchDetailItems(dataDetailItemsArr);

    if (reloadnew) {
      this.setState({ navigateReload: { head: "0" } });
    }
    if (this.state.dataHead.id == "0") {
      this.setState({ navigateReload: { head: headId } });
    }

    this.loadData();
  };

  handleDetailChange = (detail: [SaleBarcodesByDispatchIDType]) => {
    this.setState({ selectedDetailRecords: detail });
  };

  handleDetailItemAddRow = () => {
    const maxSequence = Math.max.apply(
      Math,
      this.state.dataDetailItems.map((item) => item.sequence),
    );

    const newRow = {
      id: maxSequence && !isNaN(maxSequence) && Math.abs(maxSequence) != Infinity ? maxSequence + 1 : 1,
      creditorsinvoice_id: this.state.id,
      sequence: maxSequence && !isNaN(maxSequence) && Math.abs(maxSequence) != Infinity ? maxSequence + 1 : 1,
      group: maxSequence && !isNaN(maxSequence) && Math.abs(maxSequence) != Infinity ? maxSequence + 1 : 1,
      code: null,
      chargedescription: null,
      rate: null,
      units: 1,
      vat: null,
      vatamount: 0,
      totalexclvat: 0,
      total: 0,
    };
    this.setState({ dataDetailItems: [...this.state.dataDetailItems, newRow] });
  };

  handleCopy = (row) => {
    const currentDetailItems = [...this.state.dataDetailItems];
    const rowIdx = currentDetailItems.findIndex((item) => item.id == row.id);
    const maxSequence = Math.max.apply(
      Math,
      currentDetailItems.map((item) => item.sequence),
    );
    // insert new record into index
    currentDetailItems.splice(rowIdx, 0, { ...row, id: maxSequence && !isNaN(maxSequence) && Math.abs(maxSequence) != Infinity ? maxSequence + 1 : 1 });
    // set correct sequence
    const sequenced = currentDetailItems.map((item, indx) => ({ ...item, sequence: indx + 1 }));
    this.setState({ dataDetailItems: sequenced });
  };

  handleDetailItemRowChange = (property: string, values: any[]) => {
    const formattedValues = values.map((item) => ({
      ...item,
      group: parseInt(item.group),
      vat: item.vat ? (isNaN(item.vat) ? parseFloat(VatTypes[item.vat]) : parseFloat(item.vat)) : null,
      rate: parseFloat(item.rate),
    }));
    const totalled = this.calculateTotalsData(formattedValues);
    this.setState({ dataDetailItems: totalled });
  };

  handleChange = (_, newValue) => {
    this.setState({ tabValue: newValue });
  };

  handleInfoDetailRemoveConfirm = (id: number) => {
    const selected = this.state.dataDetailItems.find((item) => item.id == id);
    const filtered = this.state.dataDetailItems.filter((item) => item.id != id);

    if (selected) {
      const filteredDetail = this.state.selectedDetailRecords.filter((item) => item.group != selected.sequence);
      this.setState({ selectedDetailRecords: filteredDetail });
    }

    this.setState({ dataDetailItems: filtered });
  };

  handleDetailItemsTemplateSelected = async (id: number) => {
    const result: CreditorsInvoiceDetailItemTemplateDetailType[] = await creditorsinvoicedetailitemstemplatedetailByTemplateID(id);
    const rowsToAdd = result.map((tempItem: CreditorsInvoiceDetailItemTemplateDetailType) => {
      return {
        id: tempItem.sequence,
        creditorsinvoice_id: this.state.id,
        sequence: tempItem.sequence,
        group: tempItem.sequence,
        code: tempItem.code,
        chargedescription: tempItem.chargedescription,
        rate: tempItem.rate,
        units: tempItem.units,
        vat: tempItem.vat,
      };
    });

    const totalled = this.calculateTotalsData(rowsToAdd);
    this.setState({ dataDetailItems: [...this.state.dataDetailItems, ...totalled] });
  };

  handleChangeGrouped = (value: boolean) => {
    this.setState({ grouped: value });
  };

  setGridHeights = () => {
    const primarysplit = document.getElementsByClassName("splitter horizontal");
    if (primarysplit.length > 0) {
      this.setState({ minGridHeight: primarysplit[0].children[0].clientHeight - 100, minGridHeightSecond: primarysplit[0].children[2].clientHeight - 100 });
    }
  };

  handleClearCreditorsDetailItems = () => {
    this.setState({ dataDetailItems: [], selectedDetailRecords: [] });
  };

  handleSetMappingData = (data: any[]) => {
    this.setState({ mappingFileData: data });
  };

  handleSetImportTemplateDetails = (data: any[], formValues) => {
    this.setState({
      importTemplateDetails: data,
      importTemplateDetailsFormValues: formValues.getState().values,
    });
  };

  handleCloseMappingConfirmation = () => {
    this.setState({ mappingFileData: [], importTemplateDetails: undefined });
  };

  handleDetailItemAddRowMapping = (dataRow: { description: string; group: number; rate: string; units: number }) => {
    const maxSequence = Math.max.apply(
      Math,
      this.state.dataDetailItems.map((item) => item.sequence),
    );

    const rate = dataRow.rate.replace(/[^0-9.]/g, ""); // remove all non numbers or period

    const newRow = {
      id: maxSequence && !isNaN(maxSequence) && Math.abs(maxSequence) != Infinity ? maxSequence + 1 : 1,
      creditorsinvoice_id: this.state.id,
      sequence: maxSequence && !isNaN(maxSequence) && Math.abs(maxSequence) != Infinity ? maxSequence + 1 : 1,
      group: dataRow.group,
      code: dataRow.description,
      chargedescription: dataRow.description,
      rate: rate,
      units: dataRow.units,
      vat: null,
      vatamount: 0,
      total: new BigNumber(rate).times(new BigNumber(dataRow.units)).decimalPlaces(2).toNumber(),
    };
    return newRow;
  };

  formatMappingData = (data: any[]) => {
    const hasHeader = this.state.importTemplateDetails[0].hasheader;
    const groupedBarcodeDetailByContainer = {};

    const colKeys: { barcode: number; storage: number; handling: number; container: number } = this.state.importTemplateDetails.reduce((obj, item) => {
      if (item.colname == "Pallet") {
        obj["barcode"] = parseInt(item.colposition) - 1;
      } else {
        obj[item.colname.toLowerCase()] = parseInt(item.colposition) - 1;
      }
      return obj;
    }, {});

    if (colKeys.container) {
      this.state.barcodesDetail.forEach((row) => {
        groupedBarcodeDetailByContainer[row.dispatch_containerno] = [...(groupedBarcodeDetailByContainer[row.dispatch_containerno] || []), row.loadoutdetail_barcode];
      });
    }

    const result = data.reduce((obj, row, index) => {
      if (hasHeader == 1 && index == 0) {
        return obj;
      }

      const barcodes = [];
      const storage = row[colKeys.storage];
      const handling = row[colKeys.handling];
      const container = row[colKeys.container];

      if (colKeys.container) {
        barcodes.push(...groupedBarcodeDetailByContainer[container]);
      } else {
        barcodes.push(row[colKeys.barcode]);
      }

      if (handling) {
        const existingBarcodes = (obj[handling] || { barcodes: [] })["barcodes"];
        obj[handling] = {
          amount: handling,
          description: "Handling",
          barcodes: [...existingBarcodes, ...barcodes],
          container: container ? (obj[handling] || { container: 0 }).container + 1 : 0,
        };
      }

      if (storage) {
        const existingBarcodes = (obj[storage] || { barcodes: [] })["barcodes"];
        obj[storage] = {
          amount: storage,
          description: "Storage",
          barcodes: [...existingBarcodes, ...barcodes],
          container: container ? (obj[handling] || { container: 0 }).container + 1 : 0,
        };
      }

      return obj;
    }, {});
    return result;
  };

  handleConfirmMappingConfirmation = async () => {
    const formattedMappingData: { [index: string]: { amount: string; description: string; barcodes: string[]; container: number } } = this.formatMappingData(
      this.state.mappingFileData,
    );
    const keys = Object.keys(formattedMappingData);

    const groupedBarcodesDetail = this.state.barcodesDetail.reduce((obj, row) => {
      obj[row.loadoutdetail_barcode] = {
        barcode: row.loadoutdetail_barcode,
        ids: [...(obj[row.loadoutdetail_barcode] || { ids: [] }).ids, row.barcode_id],
      };
      return obj;
    }, {});

    const selectedDetailRecords = [...this.state.selectedDetailRecords],
      newDetailItemRows = [],
      missingBarcodeMappings = [];

    // loop over grouped amounts from sheet
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      const dataRow = formattedMappingData[key];
      const barcodes = dataRow.barcodes;
      const group = i + 1;

      // creditors invoice detail (top grid)
      const { barcodemapping, groupedBarcode } = barcodes.reduce(
        ({ barcodemapping, groupedBarcode }, barcode) => {
          const row = groupedBarcodesDetail[barcode];
          if (row) {
            barcodemapping.push({ ...row, group });
            groupedBarcode[barcode] = { ...row, group };
          } else {
            missingBarcodeMappings.push({ barcode: barcode.trim(), group: group, amount: dataRow.amount });
          }
          return { barcodemapping, groupedBarcode };
        },
        {
          barcodemapping: [],
          groupedBarcode: {},
        },
      );

      // creditors invoice detail barcodes (bottom grid)
      const barcodeDetail = Object.keys(groupedBarcode).reduce((arr, k) => {
        const row = groupedBarcode[k];
        const barcodes = row.ids.map((id) => ({ group: row.group, barcode_id: id }));
        arr.push(...barcodes);
        return arr;
      }, []);

      const newRow = this.handleDetailItemAddRowMapping({
        group: group,
        rate: dataRow.amount,
        units: dataRow.container > 0 ? dataRow.container : barcodemapping.length,
        description: dataRow.description,
      });

      newDetailItemRows.push(newRow); // detail items
      selectedDetailRecords.push(...barcodeDetail);
    }

    this.setState({
      mappingFileData: [],
      dataDetailItems: [...this.state.dataDetailItems, ...newDetailItemRows],
      selectedDetailRecords: selectedDetailRecords,
      importTemplateDetails: undefined,
      missingBarcodeMappings: missingBarcodeMappings,
    });

    if (missingBarcodeMappings.length === 0) {
      this.handleSubmit(this.state.importTemplateDetailsFormValues).then(() => {
        this.setState({ importTemplateDetailsFormValues: undefined });
      });
    }
  };

  handleSetDefaultTemplateItems = (data: any[]) => {
    const rowsToAdd = data.map((item) => ({
      id: item.sequence,
      creditorsinvoice_id: this.state.id,
      sequence: item.sequence,
      group: 1,
      code: item.code,
      chargedescription: item.chargedescription,
      rate: item.rate,
      units: item.units,
      vat: item.vat,
    }));

    const totalled = this.calculateTotalsData(rowsToAdd);
    this.setState({ dataDetailItems: totalled });
  };

  handleCloseCreditorsImportTemplateError = () => {
    this.setState({ importTemplateDetails: undefined });
  };

  handleNewDocument = async (values) => {
    await this.handleSubmit(values, true);
  };

  handleImportTemplateChange = (event) => {
    this.setState({ importTemplateId: event.target.value });
  };

  handleCloseMissingBarcodesDialog = () => {
    this.setState({
      mappingFileData: [],
      missingBarcodeMappings: [],
      importTemplateDetails: undefined,
    });
  };

  handleConfirmMissingBarcodesDialog = () => {
    this.handleSubmit(this.state.importTemplateDetailsFormValues).then(() => {
      this.setState({ importTemplateDetailsFormValues: undefined });
    });
  };

  render() {
    const { classes } = this.state;
    const previewGridHeight = this.state.tabPanelRef.current ? this.state.tabPanelRef.current.clientHeight - 280 : 300;

    return (
      <div className={classes.root}>
        {this.state.navigateReload && <Redirect to={`/creditors/info/${this.state.navigateReload.head}/${this.state.navigateReload.creditor || ""}${location.search}`} />}
        <AppBar position="static">
          <Tabs value={this.state.tabValue} onChange={this.handleChange} aria-label="simple tabs example">
            <Tab label="Detail" />
            <Tab label="Preview" />
          </Tabs>
        </AppBar>
        <TabPanel value={this.state.tabValue} index={0} className={classes.tabpanel}>
          {this.state.loading && <CircularProgress size={24} />}
          <Form
            initialValues={{
              ...this.state.dataHead,
              roe: this.state.dataHead ? FormatterNumber3Decimals(this.state.dataHead.roe) : 0,
              validate_totalexcl: this.state.dataHead ? FormatterNumber2Decimals(this.state.dataHead.validate_totalexcl) : 0,
              validate_vat: this.state.dataHead ? FormatterNumber2Decimals(this.state.dataHead.validate_vat) : 0,
            }}
            onSubmit={(values) => {
              const submitData = { ...values };
              this.handleSubmit(submitData);
            }}
            validate={(values) => {
              let errors = {};
              if (!values["currency_id"] || values["currency_id"] === 0) {
                errors = { ...errors, currency_id: "Please select a currency." };
              }
              if (!values["invoicenumber"] || values["invoicenumber"].length === 0) {
                errors = { ...errors, invoicenumber: "Please enter an invoice number." };
              }
              if (!values["creditors_id"] || values["creditors_id"].length === 0) {
                errors = { ...errors, creditors_id: "Please select a Creditor." };
              }
              return errors;
            }}
            render={(props: any) => {
              const { handleSubmit, form } = props;
              return (
                <div ref={this.state.tabPanelRef} className={classes.gridContainer}>
                  <div className={classes.formWrapper}>
                    <form onSubmit={handleSubmit}>
                      <div className={classes.formContainer}>
                        {this.state.creditorLoading ? <LinearProgress color="secondary" /> : <div style={{ height: "5px" }}></div>}
                        <div className={classes.formButtons}>
                          <Tooltip style={{ zIndex: 0 }} title="Close">
                            <Button variant="contained" onClick={this.handleBack} style={{ marginBottom: "10px", marginRight: "5px" }}>
                              close
                            </Button>
                          </Tooltip>
                          <Tooltip style={{ zIndex: 0 }} title="Save and Create new document">
                            <Button
                              variant="contained"
                              style={{ marginBottom: "10px", backgroundColor: "green", color: "white" }}
                              onClick={() => this.handleNewDocument(undefined)}
                            >
                              new
                            </Button>
                          </Tooltip>
                          <Tooltip style={{ zIndex: 0 }} title="Save">
                            <Button color="secondary" variant="contained" type="submit" style={{ marginBottom: "10px", marginLeft: "5px" }}>
                              {parseFloat(this.state.id) ? "Save" : "Create"}
                            </Button>
                          </Tooltip>
                        </div>
                        <InfoHead handleSetDefaultTemplateItems={this.handleSetDefaultTemplateItems} values={this.state.dataHead} form={form} />
                        {this.loadingForImport(this.state.barcodeDetailLoading) && <LinearProgress className={classes.floatingProgress} />}
                        {this.loadingForImport(!this.state.barcodeDetailLoading) && this.hasExistingCI() && (
                          <div className={classes.importTemplateWrapper}>
                            <FormControl>
                              <InputLabel id="import-template-select">Import Template</InputLabel>
                              <Select
                                id="import-template-select"
                                value={this.state.importTemplateId}
                                onChange={this.handleImportTemplateChange}
                                className={classes.importTemplateSelect}
                              >
                                {this.state.importTemplateOptions.map((item) => (
                                  <MenuItem key={item.id} value={item.id}>
                                    {item.templatename}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                            <ExcelToJson
                              templateId={this.state.importTemplateId}
                              setData={this.handleSetMappingData}
                              handleSetImportTemplateDetails={(data) => this.handleSetImportTemplateDetails(data, form)}
                            />
                          </div>
                        )}
                      </div>
                    </form>
                  </div>
                  {this.state.id != 0 && !this.state.loading && (
                    <div ref={this.state.containerRef} id="splitter-wrapper" className={classes.contentContainer}>
                      <Splitter
                        position="horizontal"
                        primaryPaneMaxHeight="calc(100% - 150px)"
                        primaryPaneHeight="calc(100% - 500px)"
                        primaryPaneWidth="100%"
                        dispatchResize={true}
                        postPoned={true}
                        onDragFinished={(e) => {
                          this.setGridHeights();
                        }}
                      >
                        <InfoDetailItems
                          invoiceid={this.state.id}
                          grouped={this.state.grouped}
                          data={this.state.dataDetailItems}
                          group={this.state.selectedSequence}
                          gridHeight={this.state.minGridHeight}
                          groupId={this.state.selectedSequence}
                          loading={this.state.loading}
                          barcodesDetail={this.state.barcodesDetail}
                          selectedDetailRecords={this.state.selectedDetailRecords}
                          handleCopy={this.handleCopy}
                          setGridHeights={this.setGridHeights}
                          handleAddRow={this.handleDetailItemAddRow}
                          handleDetailChange={this.handleDetailChange}
                          handleChange={this.handleDetailItemRowChange}
                          handleGroupedChanged={this.handleChangeGrouped}
                          handleClearAll={this.handleClearCreditorsDetailItems}
                          handleRemoveConfirm={this.handleInfoDetailRemoveConfirm}
                          handleTemplateSelected={this.handleDetailItemsTemplateSelected}
                        />
                        {!this.state.loading && this.state.selectedDetailRecords.length > 0 ? (
                          <InfoDetail
                            creditorsinvoiceid={this.state.id}
                            gridHeight={this.state.minGridHeightSecond}
                            dataDetailItems={this.state.dataDetailItems}
                            selectedDetailRecords={this.state.selectedDetailRecords}
                            setGridHeights={this.setGridHeights}
                            handleDetailChange={this.handleDetailChange}
                            handleGroupedChanged={this.handleChangeGrouped}
                          />
                        ) : (
                          <></>
                        )}
                      </Splitter>
                    </div>
                  )}
                </div>
              );
            }}
          />
        </TabPanel>
        <TabPanel value={this.state.tabValue} index={1} className={classes.previewtabpanel}>
          <CreditorsInfoPreview gridHeight={previewGridHeight} creditorInvoice={this.state.dataHead} creditorInvoiceItems={this.state.dataDetailItems} />
        </TabPanel>
        {this.state.missingBarcodeMappings.length > 0 && (
          <Confirmation isOpen={true} handleClose={this.handleCloseMissingBarcodesDialog} handleConfirm={() => {}} body={""} title={"Missing mappings"} disableBackdropClick={true}>
            <BarcodeMissingGrid
              data={this.state.missingBarcodeMappings}
              handleClose={this.handleCloseMissingBarcodesDialog}
              handleConfirm={this.handleConfirmMissingBarcodesDialog}
            />
          </Confirmation>
        )}
        {this.state.mappingFileData.length > 0 && (
          <Confirmation
            isOpen={true}
            fullscreen={false}
            title={"Apply mapping(s)"}
            body={"Would you like to apply the mapping(s)?"}
            handleClose={this.handleCloseMappingConfirmation}
            handleConfirm={this.handleConfirmMappingConfirmation}
          />
        )}
        {this.state.message && (
          <DialogInformation
            isOpen={true}
            handleClose={this.handleInformationClose}
            handleOK={this.handleInformationClose}
            title={"Feedback"}
            body={`${this.state.message}`}
            showinput={false}
          />
        )}
        {this.state.createErrorMsg && (
          <DialogInformation
            isOpen={true}
            title={"Error creating creditors invoice"}
            showinput={false}
            body={this.state.createErrorMsg}
            handleClose={this.handleCreateErrorClose}
            handleOK={this.handleCreateErrorClose}
          />
        )}
        {this.state.importTemplateDetails && this.state.importTemplateDetails.length === 0 && (
          <DialogInformation
            isOpen={true}
            showinput={false}
            title={"Mapping Failed"}
            body={"There is no mapping import template for this creditor."}
            handleClose={this.handleCloseCreditorsImportTemplateError}
            handleOK={this.handleCloseCreditorsImportTemplateError}
          />
        )}
      </div>
    );
  }
}

export default withStyles(styles)(CreditorsInvoiceInfoUnstyled);
