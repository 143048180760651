export enum Reports {
  Invoice = "COMMERCIAL_INVOICE_CLIENT",
  InvoiceExcel = "COMMERCIAL_INVOICE_CLIENT_EXCEL",
  ConsigneeInvoice = "COMMERCIAL_INVOICE_CONSIGNEE",
  ConsigneeInvoiceExcel = "COMMERCIAL_INVOICE_CONSIGNEE_EXCEL",
  ClientAndConsigneeInvoice = "COMMERCIAL_INVOICE_CLIENT_AND_CONSIGNEE",
  ClientAndConsigneeInvoiceExcel = "COMMERCIAL_INVOICE_CLIENT_AND_CONSIGNEE_EXCEL",
  PackingListPDF = "PACKING_LIST PDF",
  PackingListExcel = "PACKING_LIST_CLIENT EXCEL",
  PackingListConsigneePDF = "PACKING_LIST_CONSIGNEE PDF",
  PackingListConsigneeExcel = "PACKING_LIST_CONSIGNEE EXCEL",
  PackingListClientAndConsigneePDF = "PACKING_LIST_CLIENT_AND_CONSIGNEE PDF",
  PackingListClientAndConsigneeConsigneeExcel = "PACKING_LIST_CLIENT_AND_CONSIGNEE EXCEL",
  Addendum = "ADDENDUM",
  CreditNote = "CREDIT_NOTE",
  DebitNote = "DEBIT_NOTE",
  ProForma = "PRO_FORMA_INVOICE",
  AddendumExcel = "ADDENDUM_EXCEL",
  CreditNoteExcel = "CREDIT_NOTE_EXCEL",
  CreditNoteConsignee = "CREDIT_NOTE_CONSIGNEE",
  CreditNoteConsigneeExcel = "CREDIT_NOTE_CONSIGNEE_EXCEL",
  CreditNoteClientAndConsignee = "CREDIT_NOTE_CLIENT_AND_CONSIGNEE",
  CreditNoteClientAndConsigneeExcel = "CREDIT_NOTE_CLIENT_AND_CONSIGNEE_EXCEL",
  DebitNoteExcel = "DEBIT_NOTE_EXCEL",
  DebitNoteConsignee = "DEBIT_NOTE_CONSIGNEE",
  DebitNoteConsigneeExcel = "DEBIT_NOTE_CONSIGNEE_EXCEL",
  DebitNoteClientAndConsignee = "DEBIT_NOTE_CLIENT_AND_CONSIGNEE",
  DebitNoteClientAndConsigneeExcel = "DEBIT_NOTE_CLIENT_AND_CONSIGNEE_EXCEL",
  DebtorsAdjustmentCreditAdhoc = "ADHOC CREDIT NOTE",
  DebtorsAdjustmentDebitAdhoc = "ADHOC DEBIT NOTE",
}

export enum ArrivalStatus {
  New = "NEW",
  InProgress = "IN PROGRESS",
  Complete = "COMPLETE",
}

export enum StatusTypes {
  INVOICED = "INVOICED",
  DISPATCHED = "DISPATCHED",
  LOADED = "LOADED",
  TRANSFERS = "TRANSFERS",
  NEW = "NEW",
  PROCESSED = "PROCESSED",
}
