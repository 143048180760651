import React from "react";

import Tooltip from "@material-ui/core/Tooltip";
import IconBrightness from "@material-ui/icons/Brightness1";

import CustomAutoCompleteFilter from "../../../lib/components/customdatagridfilter/CustomFilter";
import { DateFormatter, FormatterNumber, FormatterNumber2Decimals } from "../../../lib/components/Formatters";
import { SummaryNumber, SummaryNumber2Decimal, SummaryTotal } from "../../../lib/components/SummaryFormatter";
import { EnquiryLink, Selector } from "../../../lib/components/EnquiryLink";
import { handleColumnsArrangement, handleColumnsWidth } from "../../../lib/helpers/grid";

const cellWidthTiny = 40;
const cellWidthSmall = 80;
const cellWidthMedium = 120;
const cellWidthLarge = 200;

const defaultColumnProperties = {
  resizable: true,
  sortable: true,
};

const FormatterStatus: React.FC<any> = ({ row, column }) => {
  const data = { ...row };

  if (data.paid_currency && data.paid_currency.length > 0) {
    return (
      <div>
        <Tooltip title={data.paid_currency}>
          <IconBrightness style={{ color: "green", display: "block", margin: "auto", border: "1px solid #333", borderRadius: "100%" }} />
        </Tooltip>
      </div>
    );
  } else {
    return (
      <div>
        <Tooltip title={data.paid_currency}>
          <IconBrightness style={{ color: "orange", display: "block", margin: "auto", border: "1px solid #333", borderRadius: "100%" }} />
        </Tooltip>
      </div>
    );
  }
};

const colsToRender = [
  // { col: "bar_id", name: "", width: cellWidthMedium },
  { col: "status", name: "#", width: cellWidthTiny, formatter: FormatterStatus },
  {
    col: "sale_invoicenumber",
    name: "Invoice Number",
    width: cellWidthMedium,
    summaryFormatter: SummaryTotal,
    formatter: (props) => <EnquiryLink {...props} selector={Selector.InvoiceNumber} />,
  },
  {
    col: "bar_intakewaybill",
    name: "Intake Waybill",
    width: cellWidthMedium,
    formatter: (props) => <EnquiryLink {...props} selector={Selector.IntakeWaybill} />,
  },
  {
    col: "bar_originalIntakewaybill",
    name: "Org Intake Waybill",
    width: cellWidthMedium,
    formatter: (props) => <EnquiryLink {...props} selector={Selector.IntakeWaybill} />,
  },
  { col: "barcode", name: "Barcode", width: cellWidthLarge, formatter: (props) => <EnquiryLink {...props} selector={Selector.Barcode} /> },
  { col: "bar_CommodityCode", name: "Commodity", width: cellWidthMedium },
  { col: "bar_VarietyCode", name: "Variety", width: cellWidthMedium },
  { col: "bar_GradeCode", name: "Grade", width: cellWidthMedium },
  { col: "bar_CountCode", name: "Count", width: cellWidthMedium },
  { col: "bar_PackCode", name: "Pack", width: cellWidthMedium },
  { col: "bar_MarkCode", name: "Mark", width: cellWidthMedium },
  { col: "bar_InventoryCode", name: "Inventory", width: cellWidthMedium },
  { col: "bar_NoCartons", name: "No Cartons", width: cellWidthMedium, formatter: FormatterNumber, summaryFormatter: SummaryNumber },
  { col: "bar_PalletSize", name: "Pallet Size", width: cellWidthMedium, formatter: FormatterNumber2Decimals, summaryFormatter: SummaryNumber2Decimal },
  { col: "bar_LocationCode", name: "Location", width: cellWidthMedium },
  { col: "bar_FarmNumber", name: "Farm", width: cellWidthMedium },
  { col: "bar_TargetMarket", name: "TM", width: cellWidthMedium },
  { col: "bar_TargetCountry", name: "TC", width: cellWidthMedium },
  { col: "bar_TargetRegion", name: "TR", width: cellWidthMedium },
  // { col: "producer_id", name: "Producer", width: cellWidthMedium },
  { col: "producer_name", name: "Producer Name", width: cellWidthMedium },
  { col: "bar_ProducerID", name: "Producer Code", width: cellWidthMedium },
  { col: "bar_ColdDate", name: "Cold Date", width: cellWidthMedium, formatter: DateFormatter },
  // { col: "weekcold_id", name: "", width: cellWidthMedium},
  { col: "weekcold_week", name: "Cold Week", width: cellWidthMedium },
  { col: "wkcolddue_week", name: "Cold Week Due", width: cellWidthMedium },
  // { col: "accold_id", name: "", width: cellWidthMedium},
  // { col: "accold_payoutLeadNumber", name: "", width: cellWidthMedium},
  { col: "coldCartonValueZAR", name: "Cold ZAR", width: cellWidthMedium, formatter: FormatterNumber2Decimals, summaryFormatter: SummaryNumber2Decimal },
  { col: "coldCartonValueCAD", name: "Cold CAD", width: cellWidthMedium, formatter: FormatterNumber2Decimals, summaryFormatter: SummaryNumber2Decimal },
  { col: "coldCartonValueEUR", name: "Cold EUR", width: cellWidthMedium, formatter: FormatterNumber2Decimals, summaryFormatter: SummaryNumber2Decimal },
  { col: "coldCartonValueGBP", name: "Cold GBP", width: cellWidthMedium, formatter: FormatterNumber2Decimals, summaryFormatter: SummaryNumber2Decimal },
  { col: "coldCartonValueUSD", name: "Cold USD", width: cellWidthMedium, formatter: FormatterNumber2Decimals, summaryFormatter: SummaryNumber2Decimal },
  { col: "bar_OrigIntakeDate", name: "Intake Date", width: cellWidthMedium, formatter: DateFormatter },
  // { col: "weekintake_id", name: "", width: cellWidthMedium},
  { col: "weekintake_week", name: "Intake Week", width: cellWidthMedium },
  { col: "wkintakedue_week", name: "Intake Week Due", width: cellWidthMedium },
  // { col: "acintake_id", name: "", width: cellWidthMedium},
  // { col: "acintake_payoutLeadNumber", name: "", width: cellWidthMedium},
  { col: "intakeCartonValueZAR", name: "Intake ZAR", width: cellWidthMedium, formatter: FormatterNumber2Decimals, summaryFormatter: SummaryNumber2Decimal },
  { col: "intakeCartonValueCAD", name: "Intake CAD", width: cellWidthMedium, formatter: FormatterNumber2Decimals, summaryFormatter: SummaryNumber2Decimal },
  { col: "intakeCartonValueEUR", name: "Intake EUR", width: cellWidthMedium, formatter: FormatterNumber2Decimals, summaryFormatter: SummaryNumber2Decimal },
  { col: "intakeCartonValueGBP", name: "Intake GBP", width: cellWidthMedium, formatter: FormatterNumber2Decimals, summaryFormatter: SummaryNumber2Decimal },
  { col: "intakeCartonValueUSD", name: "Intake USD", width: cellWidthMedium, formatter: FormatterNumber2Decimals, summaryFormatter: SummaryNumber2Decimal },
  { col: "sale_date", name: "Shipment Date", width: cellWidthMedium, formatter: DateFormatter },
  // { col: "weekshipping_id", name: "", width: cellWidthMedium},
  { col: "weekshipping_week", name: "Ship Week", width: cellWidthMedium },
  { col: "wkshippingdue_week", name: "Ship Week Due", width: cellWidthMedium },
  // { col: "acshipping_id", name: "", width: cellWidthMedium},
  // { col: "acshipping_payoutLeadNumber", name: "", width: cellWidthMedium},
  { col: "shippingCartonValueZAR", name: "Ship ZAR", width: cellWidthMedium, formatter: FormatterNumber2Decimals, summaryFormatter: SummaryNumber2Decimal },
  { col: "shippingCartonValueCAD", name: "Ship CAD", width: cellWidthMedium, formatter: FormatterNumber2Decimals, summaryFormatter: SummaryNumber2Decimal },
  { col: "shippingCartonValueEUR", name: "Ship EUR", width: cellWidthMedium, formatter: FormatterNumber2Decimals, summaryFormatter: SummaryNumber2Decimal },
  { col: "shippingCartonValueGBP", name: "Ship GBP", width: cellWidthMedium, formatter: FormatterNumber2Decimals, summaryFormatter: SummaryNumber2Decimal },
  { col: "shippingCartonValueUSD", name: "Ship USD", width: cellWidthMedium, formatter: FormatterNumber2Decimals, summaryFormatter: SummaryNumber2Decimal },
  // { col: "paid_advancecontractsid", name: "", width: cellWidthMedium},
  { col: "paid_zar", name: "Paid ZAR", width: cellWidthMedium, formatter: FormatterNumber2Decimals, summaryFormatter: SummaryNumber2Decimal },
  { col: "paid_cad", name: "Paid CAD", width: cellWidthMedium, formatter: FormatterNumber2Decimals, summaryFormatter: SummaryNumber2Decimal },
  { col: "paid_eur", name: "Paid EUR", width: cellWidthMedium, formatter: FormatterNumber2Decimals, summaryFormatter: SummaryNumber2Decimal },
  { col: "paid_gbp", name: "Paid GBP", width: cellWidthMedium, formatter: FormatterNumber2Decimals, summaryFormatter: SummaryNumber2Decimal },
  { col: "paid_usd", name: "Paid USD", width: cellWidthMedium, formatter: FormatterNumber2Decimals, summaryFormatter: SummaryNumber2Decimal },
  { col: "paid_currency", name: "Paid Curr", width: cellWidthMedium },
];

let currentSpace = true;
export const GridColumnsDetail = (data, filters, arrangement = undefined, columnsWidth = undefined) => {
  let postValue = "";

  if (data) {
    if (currentSpace) {
      postValue = " ";
    }
    currentSpace = !currentSpace;
  }

  const collArr = [];
  // const collArr = [{ key: "actions", name: "", width: cellWidthTiny }];

  colsToRender.map((item) => {
    collArr.push(generateColl(postValue, item, filters, data));
  });

  const columns = collArr.map((c) => ({ ...c, ...defaultColumnProperties }));

  let columnsArranged = [];
  columnsArranged = handleColumnsArrangement(columnsArranged, arrangement, columns);
  columnsArranged = handleColumnsWidth(columnsArranged, columnsWidth, columns);

  return columnsArranged;
};

const generateColl = (postValue: string, colData: any, filters: any, data: any) => {
  return {
    key: `${colData.col}`,
    name: `${colData.name}` + postValue,
    width: colData.width,
    filterable: true,
    formatter: colData.formatter,
    summaryFormatter: colData.summaryFormatter,
    filterRenderer: (e) => {
      return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={`${colData.col}`} data={data} section="producersfullgroupeddetailpmnt" />;
    },
  };
};
