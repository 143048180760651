import React from "react";
import * as XLSX from "xlsx";
import Papa from "papaparse";

import Button from "@material-ui/core/Button";

import { findCreditorsImportTemplateDetailByTemplateId } from "../api/creditorsimporttemplatedetail";
import CircularProgress from "@material-ui/core/CircularProgress";

type Props = {
  templateId: number;
  setData(data: any[]): void;
  handleSetImportTemplateDetails(data: any[]): void;
};

class ExcelToJson extends React.Component<Props, {}> {
  constructor(props) {
    super(props);

    this.state.importBtn = React.createRef();
  }

  state = {
    importBtn: undefined,
    loading: false,
  };

  handleFileChange = (event) => {
    event.stopPropagation();
    event.preventDefault();

    const file = event.target.files[0];
    this.readFile(file);
    event.target.value = "";
  };

  readFile(file) {
    const reader = new FileReader();

    reader.onload = (event) => {
      const bstr = event.target.result;
      const wb = XLSX.read(bstr, { type: "binary" });

      const wsname = wb.SheetNames[0]; // Get first worksheet
      const ws = wb.Sheets[wsname];
      const data = XLSX.utils.sheet_to_csv(ws);

      const json = this.convertToJson(data);
      this.props.setData(json);
    };

    reader.readAsBinaryString(file);
  }

  convertToJson = (csv) => {
    const parsed = Papa.parse(csv, { delimiter: "," });
    return parsed.data;
  };

  handleCreditorsImportTemplateExists = async () => {
    this.setState({ loading: true });
    const templateDetails = await findCreditorsImportTemplateDetailByTemplateId(this.props.templateId);
    this.props.handleSetImportTemplateDetails(templateDetails);
    this.setState({ loading: false });

    if (templateDetails.length > 0) {
      this.state.importBtn.click();
    }
  };

  render() {
    return (
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <input ref={(input) => (this.state.importBtn = input)} type="file" onChange={this.handleFileChange} hidden />
        <Button color="primary" variant="contained" onClick={this.handleCreditorsImportTemplateExists} disabled={this.state.loading}>
          Import EXCEL {this.state.loading && <CircularProgress style={{ marginLeft: "5px" }} size={15} color="secondary" />}
        </Button>
      </div>
    );
  }
}

export default ExcelToJson;
