import React, { useEffect, useMemo } from "react";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";

import Check from "@material-ui/icons/Check";
import Close from "@material-ui/icons/Close";

import Button from "@material-ui/core/Button";

import { Field, Form } from "react-final-form";

import { TextField, Select } from "final-form-material-ui";

import { orchards } from "../../lib/api/producerorchard";
import { varieties, VarietiesByCommodityMappedForCombo } from "../../lib/api/variety";
import { commoditiesAllSortedMappedforCombo } from "../../lib/api/commodity";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1),
    },
    tableRow: {
      padding: theme.spacing(1),
      textAlign: "right",
    },
    formInputs: {
      marginTop: "-5px !important",
    },
    formDropDowns: {
      marginTop: "-5px !important",
    },
    formWrapper: {
      display: "grid",
      gap: "10px",
    },
    formRow: {
      display: "grid",
      gridTemplateColumns: "120px 300px",
      gap: "10px",
      padding: theme.spacing(1),
    },
    formRowLabel: {
      textAlign: "right",
    },
  });

type OrchardsEditFormProps = {
  onSubmit: any;
  onClose: any;
  id: any;
  allOrchards: any[];
  availablePucs: any[];
  handleRefresh: () => void;
} & WithStyles<typeof styles>;

const OrchardsEditFormUnstyled: React.FunctionComponent<OrchardsEditFormProps> = (props) => {
  const { classes, onSubmit, onClose, id, allOrchards, availablePucs } = props;

  const [item, setItem] = React.useState({ id: 0, code: "", commodity: "", variety_id: 0, ha: "", puc_id: 0 });
  const [varietiesData, setVarietiesData] = React.useState([]);
  const [varietiesDataFiltered, setVarietiesDataFiltered] = React.useState([]);
  const [commoditiesData, setCommoditiesData] = React.useState([]);
  const [commodity, setCommodity] = React.useState();

  useEffect(() => {
    getItem();
  }, [id]);

  const getItem = async () => {
    const commResult = await commoditiesAllSortedMappedforCombo();
    setCommoditiesData(commResult);

    const varResult = await varieties.all();
    setVarietiesData(varResult);

    if (id && id != 0) {
      orchards.single(id).then((data) => {
        // data.commodity = commResult[0].value;
        if (data.variety_id) {
          const varFindResult = varResult.find((varItem) => varItem.id.toString() == data.variety_id.toString());
          if (varFindResult) {
            data.commodity = varFindResult.commodity_id;
          }
        }
        handleSetVarieties(data.commodity);
        setItem({ ...data });
      });
    }
  };

  const handleSetVarieties = async (value) => {
    const varResultFilter = await VarietiesByCommodityMappedForCombo(value);
    setVarietiesDataFiltered(varResultFilter);
  };

  const handleChangeCommodity = async (value, form) => {
    if (!value || value === "") {
      form.change("variety_id", "");
    } else {
      const varResultFilter = await VarietiesByCommodityMappedForCombo(value);

      if (varResultFilter.length > 0) {
        form.change("variety_id", varResultFilter[0].id);
      }

      setVarietiesDataFiltered(varResultFilter);
    }
  };

  return (
    <div className={classes.root}>
      <Form
        initialValues={item}
        onSubmit={(value) => {
          onSubmit(value);
          props.handleRefresh();
        }}
        validate={(values) => {
          let errors = {};

          if (values["ha"] && values["ha"].length > 0 && !Number(values["ha"])) {
            errors = { ...errors, ha: "Invalid value: Hectare should be a number" };
          }
          if (!values["puc_id"]) {
            errors = { ...errors, puc_id: "Invalid value: Please select a PUC" };
          }

          return errors;
        }}
        render={({ handleSubmit, form, values }: any) => (
          <SubmitForm
            values={values}
            classes={classes}
            handleSubmit={handleSubmit}
            handleChangeCommodity={handleChangeCommodity}
            setCommodity={setCommodity}
            form={form}
            commoditiesData={commoditiesData}
            varietiesDataFiltered={varietiesDataFiltered}
            onClose={onClose}
            allOrchards={allOrchards}
            availablePucs={availablePucs}
          />
        )}
      />
    </div>
  );
};

type SubmitFormProps = { values; classes; handleSubmit; handleChangeCommodity; setCommodity; form; commoditiesData; varietiesDataFiltered; onClose; allOrchards; availablePucs };

const SubmitForm: React.FC<SubmitFormProps> = ({
  values,
  classes,
  handleSubmit,
  handleChangeCommodity,
  setCommodity,
  form,
  commoditiesData,
  varietiesDataFiltered,
  onClose,
  allOrchards,
  availablePucs,
}) => {
  const rowExists = useMemo(() => {
    const exists = allOrchards.find(
      (orchard) => orchard.id != values.id && orchard.code == values.code && (orchard.ha || 0) == values.ha && (orchard.variety_id || 0) == values.variety_id,
    );
    return !!exists;
  }, [allOrchards, values]);

  return (
    <form onSubmit={handleSubmit}>
      <div className={classes.formWrapper}>
        <div className={classes.formRow}>
          <span className={classes.formRowLabel}>
            <label>Puc:</label>
          </span>
          <Field name="puc_id" native component={Select} className={classes.formDropDowns}>
            <option key="puc-none" value={0} disabled>
              Please select a PUC
            </option>
            {availablePucs.map((puc, index) => (
              <option key={`puc${index}`} value={puc.id}>
                {puc.code}
              </option>
            ))}
          </Field>
        </div>
        <div className={classes.formRow}>
          <span className={classes.formRowLabel}>
            <label>Code:</label>
          </span>
          <Field name="code" component={TextField} fullWidth key="body_value_code" className={classes.formInputs} />
        </div>
        <div className={classes.formRow}>
          <span className={classes.formRowLabel}>
            <label>Commodity:</label>
          </span>
          <Field
            name="commodity"
            native
            component={Select}
            className={classes.formDropDowns}
            parse={(value) => {
              handleChangeCommodity(value, form);
              setCommodity(commoditiesData.find((commod) => commod.value === value));
              return value;
            }}
          >
            <option key={`comm_none`} value={""}>
              NONE
            </option>
            {commoditiesData.map((comm, index) => {
              return (
                <option key={`comm${index}`} value={comm.value}>
                  {comm.display}
                </option>
              );
            })}
          </Field>
        </div>
        <div className={classes.formRow}>
          <span className={classes.formRowLabel}>
            <label>Variety:</label>
          </span>
          <Field name="variety_id" native component={Select} className={classes.formDropDowns}>
            <option key={`var_none`} value={0}>
              NONE
            </option>
            {varietiesDataFiltered.map((vd, index) => {
              return (
                <option key={`var${index}`} value={vd.id}>
                  {vd.display}
                </option>
              );
            })}
          </Field>
        </div>
        <div className={classes.formRow}>
          <span className={classes.formRowLabel}>
            <label>Hectare:</label>
          </span>
          <Field name="ha" component={TextField} fullWidth className={classes.formInputs} />
        </div>
      </div>
      {rowExists && <p style={{ color: "red" }}>A record with this code already exists.</p>}
      <div style={{ display: "flex", justifyContent: "flex-end", margin: "10px 0 -5px 0" }}>
        <Button style={{ marginRight: "10px" }} color="secondary" variant="outlined" onClick={onClose}>
          <Close />
        </Button>
        <Button type="submit" color="primary" variant="outlined" disabled={rowExists}>
          <Check />
        </Button>
      </div>
    </form>
  );
};

export default withStyles(styles)(OrchardsEditFormUnstyled);
