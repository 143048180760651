import React from "react";

import CustomAutoCompleteFilter from "../lib/components/customdatagridfilter/CustomFilter";

import Tooltip from "@material-ui/core/Tooltip";
import IconBrightness from "@material-ui/icons/Brightness1";
import IconLock from "@material-ui/icons/Lock";
import { DateFormatter } from "../lib/components/Formatters";
import { handleColumnsArrangement, handleColumnsWidth } from "../lib/helpers/grid";

const cellWidthTiny = 40;
const cellWidthSmall = 80;
const cellWidthMedium = 120;
const cellWidthLarge = 200;

const defaultColumnProperties = {
  resizable: true,
  sortable: true,
};

let currentSpace = true;

const StatusFormatter = ({ row }) => {
  return (
    <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
      <Tooltip title={row.camend_status > 2 ? "PROCESSED" : "UNAPPROVED"}>
        <IconBrightness style={{ color: row.camend_status > 2 ? "purple" : "orange", display: "block", margin: "auto", border: "1px solid #333", borderRadius: "100%" }} />
      </Tooltip>
      {row.proccessed ? (
        <Tooltip title={"PAID IN FULL"}>
          <IconLock style={{ color: "green", display: "block", margin: "auto" }} />
        </Tooltip>
      ) : (
        <></>
      )}
    </div>
  );
};

export const GridColumns = (data, filters, arrangement = undefined, columnsWidth = undefined) => {
  let postValue = "";

  if (data) {
    if (currentSpace) {
      postValue = " ";
    }
    currentSpace = !currentSpace;
  }

  const columns = [
    {
      key: "camend_status",
      name: "Finalized" + postValue,
      width: cellWidthSmall,
      filterable: true,
      formatter: StatusFormatter,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"camend_status"} data={data} section="consolidationselected" />;
      },
    },
    {
      key: "shipment_week",
      name: "Ship Week" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"shipment_week"} data={data} section="financegridfull" />;
      },
    },
    {
      key: "sale_invoicenumber",
      name: "Invoice No" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"sale_invoicenumber"} data={data} section="financegridfull" />;
      },
    },
    {
      key: "clients_code",
      name: "Client Code" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"clients_code"} data={data} section="financegridfull" />;
      },
    },
    {
      key: "clients_name",
      name: "Client Name" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"clients_name"} data={data} section="financegridfull" />;
      },
    },
    {
      key: "loadout_etd",
      name: "ETD" + postValue,
      width: cellWidthMedium,
      filterable: true,
      formatter: DateFormatter,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"loadout_etd"} data={data} section="financegridfull" />;
      },
    },
    {
      key: "loadout_eta",
      name: "ETA" + postValue,
      width: cellWidthMedium,
      filterable: true,
      formatter: DateFormatter,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"loadout_eta"} data={data} section="financegridfull" />;
      },
    },
    {
      key: "sale_ata",
      name: "ATA" + postValue,
      width: cellWidthMedium,
      filterable: true,
      formatter: DateFormatter,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"sale_ata"} data={data} section="financegridfull" />;
      },
    },
    {
      key: "portdistcharge_description",
      name: "POD" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"portdistcharge_description"} data={data} section="financegridfull" />;
      },
    },
    {
      key: "portfinal_description",
      name: "Final Dest" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"portfinal_description"} data={data} section="financegridfull" />;
      },
    },
  ].map((c) => ({ ...c, ...defaultColumnProperties }));

  let columnsArranged = [];
  columnsArranged = handleColumnsArrangement(columnsArranged, arrangement, columns);
  columnsArranged = handleColumnsWidth(columnsArranged, columnsWidth, columns);

  return columnsArranged;
};
