import { client, request, apiHeaders } from "./_base";

const endpoint = "dispatcheddocs";

export const dispatcheddocs = client(endpoint, (item) => ({
  ...item,
}));

export const dispatcheddocsFull = (financialyear) =>
  request(`${endpoint}/ext/full/${financialyear}`, {
    headers: apiHeaders(),
  }).then((result) => {
    if (result.status == 500) {
      throw result.statusText;
    }
    return result.json();
  });

export const updateDispatchedDoc = async (data) =>
  await request(`${endpoint}/ext/update`, {
    method: "POST",
    body: JSON.stringify(data),
  });

export const removeDispatchedDoc = async (id) =>
  await request(`${endpoint}/ext/deleteByDispatchID/${id}`, {
    method: "DELETE",
  });

export type DispatcheddocsFullType = {
  "Dispatch ID": number;
  "Dispatch Doc Ref": string;
  "Dispatch User": string;
  Receiver: string;
  Dispatched: string;
  Date: string;
  Time: string;
  Destination: string;
  Transportation: string;
  "Ref/Reg No": string;
  "Shipping No": string;
  Vessel: string;
  "Container No": string;
  "Seal Number": string;
  "Consec No": string;
  "Total Palettes": string;
  "Total Cartons": string;
  Inspected: string;
  "Inspector Name": string;
  Accepted: string;
  Temperature: string;
  Remarks: string;
  Processing: string;
  PalTrak: string;
  Organization: string;
  Channel: string;
  Bay: string;
  Transport: string;
  "Load Type": string;
  "Destination Type": string;
  Carrier: string;
  "Revision No": string;
  "No Rejected Cartons": string;
  "No Inspected Cartons": string;
  "Order No": string;
  "Last Edit Date": string;
  "Pallet Base Type": string;
  "Temp Serial No": string;
  "Rate Structure": string;
  Week: string;
  "Inspection Report": string;
  "Reject Reason": string;
  "Ship Sender": string;
  "Ship Agent": string;
  "Total Weight": string;
  "Booking No": string;
  "Custom Field 1": string;
  "Custom Field 2": string;
  "Custom Field 3": string;
  "No Entries": string;
  "CTO No": string;
  "Vehicle Arrival Time": string;
  "Vehicle Depart Time": string;
  "Start Loading Time": string;
  "Finish Loading Time": string;
  "PO File No": string;
  Mates: string;
  "Market Code": string;
  "QX Done": string;
  "Location Code": string;
  "Intake ID": string;
  "Consignment Type": string;
  Processed: string;
  "Consignee ID": string;
  ETA: string;
  ETD: string;
  "Trader ID": string;
  "Shipping Line": string;
  "Load Date": Date;
  "Cost Type": string;
  Currency: string;
  UserName: string;
  Freight: string;
  "Nett Weight": string;
  "Gross Weight": string;
  FileName: string;
  "Port Of Discharge": string;
  "Port Of Loading": string;
  Transfer: string;
  "Confirm User": string;
  Confirmed: string;
  "Disregard Invoice": string;
  "FruitTrak Dispatch": string;
  "Main Dispatch Order": string;
  "Transaction Date": string;
  sap_no: string;
  "Notify Party": string;
  "Orig Consignment Ref": string;
  "Client Ref": string;
  Finalized: string;
  "Purchase Order No": string;
  "Order ID": string;
  "Sale ID": string;
  "Contract ID": string;
  "Payment Option": string;
  "Container Type": string;
  "Temperature Code": string;
  "CoLoad Dispatch ID": string;
  BAFF: string;
  "Inspection Point": string;
  "Usage Instruction": string;
  "Office Code": string;
  "EDI Received": string;
  "Shipping Booking Ref": string;
  "PPECB Cert No": string;
  "Invalid LoadOut": string;
  PPECB: string;
  PHYTO: string;
  "BILL OF LADING": string;
  "CERT OF ORIGIN": string;
  "TRACKING NOTE": string;
  SGS: string;
  "TELEX RELEASE": string;
  "DHL TD": string;
  "DHL TA": string;
  INSURANCE: string;
  ADDENDUM: string;
  Printed: string;
  TRANSHIPMENT: string;
  "Pallet Status": string;
  ATD: string;
  Validated: string;
  "Dispatch Type": string;
  ATA: string;
  "Translation Field 1": string;
  Selected: string;
  "PO File Created": string;
  "EXCON Ref": string;
  "UCR No": string;
  "GTIN Validation": string;
  Cycle: string;
  "Outside Packhouse": string;
  "PackHouse Code": string;
  Principal: string;
  "Max Weigt": string;
  "BOL No": string;
  "SOB Date": string;
  "Max Weight": string;
  "Driver Code": string;
  "Driver Name": string;
  "Custom Field 4": string;
  "Custom Field 5": string;
  ContainerRef: string;
  "Container Size": string;
  "MT File No": string;
  "ContainerBooking ID": string;
  "Voyage Number": string;
  "Deal Type": string;
  "Dead Freight": string;
  "Free Pallets": string;
  "Transport Rebate Type": string;
  "Production ID": string;
  "PHC Location Code": string;
  "Ignore Stock on Sale Create": string;
  "BH Transaction Date": string;
  "Clearing Agent": string;
  "Cooler ID": string;
  "Multiple Loads": string;
  "Shipping Line Ref": string;
  "Valid EDI": string;
  "Custom Shipping No": string;
  "Freight Contract ID": string;
  "VOB Number": string;
  "Pack House Delivery Note": string;
  "Truck Delivery Note": string;
  Archived: string;
  "Archived User": string;
  "Archived Date": string;
  "PPECB Certification Status": string;
  "Logistical Service Provider": string;
  "Forwarding Agent": string;
  "Tran Date": string;
  "Tran User": string;
  "Tran Edit Date": string;
  "Tran Edit User": string;
  "Last Revision Date": string;
  "Custom Revision No": string;
  Trailer1: string;
  Trailer2: string;
  "Custom Date Field 1": string;
  "Custom Date Field 2": string;
  "Custom Date Field 3": string;
  "Custom Date Field 4": string;
  "Custom Date Field 5": string;
  "Verification Method": string;
  "Documentation Method": string;
  "Dual Load Consolidated Dispatch": string;
  "Custom Field 6": string;
  "Source Entity": string;
  "Steri Destination": string;
  SmartFormID: string;
  "Loadout Status": string;
  "Confirmed Load Date": string;
  "Dispatch Doc Ref Archive Backup": string;
  "Orig Consignment Ref Archive Backup": string;
  "PR3 EDI Dispatch Done": string;
  dispatch_id: number;
  loadout_eta: Date;
  loadout_etd: Date;
  loadout_coldroom_id: null;
  agent_id: number;
  agent_agentname: string;
  vessel_id: number;
  vessel_code: string;
  portloading_id: number;
  portloading_code: string;
  portloading_description: string;
  portdistcharge_id: number;
  portdistcharge_code: string;
  portdistcharge_description: string;
  portfinal_id: number;
  portfinal_code: string;
  portfinal_description: string;
  loadout_consignee: string;
  loadout_reference: string;
  sale_id: number;
  sale_type: number;
  stockdetail_po: string;
  dispatch_forwardagentref: string;
  dispatch_consignee_id: string;
  status: string;
  temptail: string;
  recorder_position: string;
  dispatch_invoicedate: Date;
  dispatch_loaddate: Date;
};
