import React, { useState, useCallback } from "react";
import { createStyles, Theme } from "@material-ui/core/styles";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import FormControl from "@material-ui/core/FormControl";
import makeStyles from "@material-ui/core/styles/makeStyles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

import { validatePassword, validateUsername } from "../user-management/utils/user";
import { register } from "../lib/api/identities";
import { SnackContext } from "../lib/context/SnackContext";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    form: {
      width: "400px",
      display: "flex",
      justifySelf: "center",
    },
    footerButtons: {
      display: "flex",
      justifyContent: "flex-end",
      gap: theme.spacing(1),
    },
    input: {
      color: "#3f51b5",
      fontSize: "20px",
    },
    container: {
      display: "flex",
      width: "100%",
    },
    dialogContainer: {
      display: "flex",
      width: "350px",
      flexDirection: "column",
    },
  }),
);

type Props = {
  handleClose(): void;
};

// TODO: use formik with validation instead of state management

const IdentityRegisterDialog: React.FC<Props> = ({ handleClose }) => {
  const { updateSnack } = React.useContext(SnackContext);
  const classes = useStyles();

  const [loading, setLoading] = useState(false);

  const [username, setUsername] = useState("");
  const [enteredPassword, setEnteredPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [passwordIsStrong, setPasswordIsStrong] = useState(false);

  const [usernameMessage, setUsernameMessage] = useState("");
  const [passwordMessage, setPasswordMessage] = useState("");
  const [confirmPasswordMessage, setConfirmPasswordMessage] = useState("");
  const [showPassword, toggleShowPassword] = useState(false);

  const handleResetPassword = (e) => {
    e.preventDefault();
  };

  const handleResetRequest = (enteredPassword, confirmPassword, passwordIsStrong) => {
    if (enteredPassword == confirmPassword && passwordIsStrong) {
      handleClearFieldValidation();
      handleRegister();
    } else {
      if (enteredPassword == "" || confirmPassword == "") {
        setPasswordMessage("Please enter a valid password");
      } else if (passwordMessage === "") {
        setPasswordMessage("Entered passwords need to match");
      }
    }
  };

  const handleClearFieldValidation = () => {
    setUsernameMessage("");
    setPasswordMessage("");
    setConfirmPasswordMessage("");
  };

  const handleValidatePassword = useCallback(
    (enteredPassword: string) => {
      const [isValid, message] = validatePassword(enteredPassword);
      setConfirmPasswordMessage("");
      setPasswordMessage(message);
      setPasswordIsStrong(isValid);
    },
    [enteredPassword],
  );

  const handleValidateUsername = useCallback((username: string) => {
    const [_, message] = validateUsername(username);
    setUsernameMessage(message);
  }, []);

  const validateConfirmPassword = useCallback(
    (enteredConfirm: string, enteredPassword: string) => {
      // check if password is validate before showing mismatch error
      if (passwordIsStrong) {
        if (enteredConfirm != enteredPassword) {
          setConfirmPasswordMessage("Entered passwords need to match");
          setPasswordMessage("");
        } else {
          setConfirmPasswordMessage("");
        }
      }
    },
    [confirmPassword, passwordMessage],
  );

  const handleRegister = async () => {
    setLoading(true);
    try {
      await register({ data: { username, password: confirmPassword, admin: 0 } });
      handleClose();
      updateSnack({ show: true, color: "green", message: "User successfully registered" });
    } catch (error) {
      updateSnack({ show: true, color: "red", message: "Error registering user" });
    }
    setLoading(false);
  };

  return (
    <form onSubmit={handleResetPassword}>
      <Dialog open onClose={() => handleClose()} PaperProps={{}}>
        <DialogContent>
          <div className={classes.dialogContainer}>
            <div>
              <h1>REGISTER</h1>
            </div>
            <div>
              <FormControl fullWidth>
                <TextField
                  name="register-user"
                  type={"text"}
                  variant="outlined"
                  InputProps={{ className: classes.input }}
                  label="Enter username"
                  margin="dense"
                  id="standard-adornment-amount"
                  helperText={usernameMessage}
                  error={usernameMessage && usernameMessage !== ""}
                  disabled={loading}
                  onChange={(e) => {
                    setUsername(e.target.value);
                    handleValidateUsername(e.target.value);
                  }}
                />
              </FormControl>
            </div>
            <div>
              <FormControl fullWidth>
                <TextField
                  name="register-password"
                  type={showPassword ? "text" : "password"}
                  variant="outlined"
                  InputProps={{ className: classes.input }}
                  label="Enter password"
                  margin="dense"
                  id="standard-adornment-amount"
                  helperText={passwordMessage}
                  error={passwordMessage && passwordMessage !== ""}
                  disabled={loading}
                  onChange={(e) => {
                    setEnteredPassword(e.target.value);
                    handleValidatePassword(e.target.value);
                  }}
                />
              </FormControl>
            </div>
            <div>
              <FormControl fullWidth>
                <TextField
                  name="register-confirmpassword"
                  type={showPassword ? "text" : "password"}
                  variant="outlined"
                  InputProps={{ className: classes.input }}
                  label="Confirm password"
                  margin="dense"
                  helperText={confirmPasswordMessage}
                  error={confirmPasswordMessage && confirmPasswordMessage !== ""}
                  disabled={loading}
                  onChange={(e) => {
                    setConfirmPassword(e.target.value);
                    validateConfirmPassword(e.target.value, enteredPassword);
                  }}
                />
              </FormControl>
            </div>
            <div>
              <FormControlLabel control={<Checkbox checked={showPassword} onChange={() => toggleShowPassword(!showPassword)} name="show-password" />} label="Show password" />
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <div className={classes.footerButtons}>
            <Button variant="contained" color="secondary" onClick={() => handleClose()} disabled={loading}>
              Close
            </Button>
            <Button variant="contained" color="primary" onClick={() => handleResetRequest(enteredPassword, confirmPassword, passwordIsStrong)} disabled={loading}>
              Confirm
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    </form>
  );
};

export default IdentityRegisterDialog;
