import React from "react";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";

import { Row } from "react-data-grid";
import "react-splitter-layout/lib/index";
import "./custom/splitter.css";

import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";

import Grid from "../lib/components/grid";
import { DialogInformation } from "../lib/components/dialoginformation";
import Confirmation from "../lib/components/confirmation";

import { SnackContext } from "../lib/context/SnackContext";

import { GridColumns } from "./loadouttablesetup";
import { GridColumnsSelected } from "./loadouttabledetailsetup";

import { handleExportShipmentsData } from "../lib/api/loadout";
import { coldroomsAllSortedMappedforCombo } from "../lib/api/coldroom";
import { PortReadyForComboIdValue, getPortReadyForComboIdValue } from "../lib/api/port";
import { getShippingLineReadyForComboIdValue } from "../lib/api/shippingline";
import { getVesselReadyForComboIdValue } from "../lib/api/vessel";

import LoadoutTableActionDialog from "./loadouttableActionDialog";

import Splitter from "m-react-splitters";
import "m-react-splitters/lib/splitters.css";
import classNames from "classnames";
import { getClientBillTypesReadyForCombo } from "../lib/api/clientbilltypes";
import { getSaleDetailByReference, sale, updateSaleJoinedRecord } from "../lib/api/sale";

import { GenerateErrorMessage } from "../lib/helpers/string_methods";
import { ClientSelector } from "./clientselector";
import { getClientsAllSortedMappedforComboCode } from "../lib/api/clients";
import { getTemperatureReadyForCombo } from "../lib/api/temperaturecodes";
import { CircularProgress } from "@material-ui/core";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      height: "100%",
      width: "100%",
    },
    blackRow: {
      color: "black",
    },
    redRow: {
      color: "red",
    },
    boldRow: {
      fontWeight: "bold",
    },
    toolbar: {
      zIndex: 999,
      position: "absolute",
      float: "left",
      left: "100px",
    },
  });

const groupBy = (data: any[], key: string, valueKey: string) => {
  return data.reduce((obj, row) => {
    obj[row[key]] = row[valueKey];
    return obj;
  }, {});
};

type StockAllocationTableProps = {
  data: {}[];
  onReload: any;
  handleAttachment: (id) => void;
} & WithStyles<typeof styles>;

class StockAllocationTableUnstyled extends React.Component<StockAllocationTableProps, {}> {
  state = {
    data: [],
    dataChanged: false,
    loading: false,
    loadingSelected: false,
    onReload: undefined,
    selectedRows: [],
    selectedLoadout: [],
    clearedPhrase: false,
    dialoginformation: undefined,
    minGridHeight: 0,
    minGridHeightSecond: 0,
    selectedRecord: undefined,
    handleAttachment: undefined,
    coldstoreoptions: undefined,
    portoptions: undefined,
    portoptionslocal: undefined,
    shippinglines: undefined,
    vesseloptions: undefined,
    billtypesData: [],
    clientDialog: false,
    clientDialogLoading: false,
    clientsoptions: [],
    groupedPortOptions: {},
    groupedClientOptions: {},
    tempcodes: [],
    submitting: false,
  };

  constructor(props) {
    super(props);
    this.state.data = props.data;
    this.state.onReload = props.onReload;
    this.state.handleAttachment = props.handleAttachment;
  }

  static contextType = SnackContext;
  context!: React.ContextType<typeof SnackContext>;

  loadData = async () => {
    const [billtypesData, coldstoreoptions, portoptions, portoptionslocal, shippinglines, vesseloptions, clientsoptions, tempcodes] = await Promise.all([
      getClientBillTypesReadyForCombo(),
      coldroomsAllSortedMappedforCombo(),
      getPortReadyForComboIdValue(),
      getPortReadyForComboIdValue("ZA"),
      getShippingLineReadyForComboIdValue(),
      getVesselReadyForComboIdValue(),
      getClientsAllSortedMappedforComboCode(),
      getTemperatureReadyForCombo(),
    ]);
    const groupedPortOptions = groupBy(portoptions, "code", "description");
    const groupedClientOptions = groupBy(clientsoptions, "value", "display");
    this.setState({
      billtypesData,
      coldstoreoptions,
      portoptions,
      portoptionslocal,
      shippinglines,
      vesseloptions,
      clientsoptions,
      groupedPortOptions,
      groupedClientOptions,
      tempcodes,
    });
  };

  componentDidMount() {
    this.loadData();
    this.setGridHeights();
  }

  handleAvailableLoadoutDoubleClick = async (row) => {
    this.setState({ loadingSelected: true });
    const data = await getSaleDetailByReference(row.loadout_reference);
    this.setState({ selectedLoadout: [{ ...row }], selectedRows: [...data], loadingSelected: false });
  };

  handleSelectedStockDoubleClick = (row: any) => {
    this.setState({ selectedRecord: row });
  };

  handleSelectedRecordClose = async () => {
    const promiseArr = [];

    this.state.selectedLoadout.map((lo) => {
      promiseArr.push(
        new Promise((resolve, reject) => {
          getSaleDetailByReference(lo.loadout_reference).then((data) => {
            resolve(data);
          });
        }),
      );
    });

    const selectedRows = [];
    Promise.all(promiseArr).then((result) => {
      result.map((resultItem) => {
        selectedRows.push(...resultItem);
      });
      this.setState({ selectedRows }, () => {});
    });
    this.setState({ selectedRecord: undefined }); //, unallocateConfirm: undefined
  };

  selectedClear = () => {
    this.setState({ selectedRows: [], selectedLoadout: [] });
  };

  setGridHeights = () => {
    const primarysplit = document.getElementsByClassName("splitter horizontal");
    this.setState({
      minGridHeight: primarysplit[0].children[0].clientHeight - 80,
      minGridHeightSecond: primarysplit[0].children[2].clientHeight - 80,
    });
  };

  handleInfoClose = () => {
    this.setState({ dialoginformation: undefined });
  };

  handleChange = async (property: string, value: any) => {
    const dirtyRows = [];
    value.map((item) => {
      if (item.rowDirty && item.rowDirty == 1) {
        dirtyRows.push(item);
      }
    });
    this.setState({ dataChanged: dirtyRows, data: value });
  };

  handleChangeSave = async () => {
    this.setState({ submitting: true });
    try {
      const updatedata = this.state.data.filter((ditem) => ditem.rowDirty == 1);
      const { sale, loadout, dispatch } = updatedata.reduce(
        ({ sale, loadout, dispatch }: any, row) => {
          if (row.sale_id) {
            sale.push({
              id: row.sale_id,
              date: row.sale_date ? row.sale_date : new Date(),
              ata: !row.sale_ata || row.sale_ata == "" ? null : row.sale_ata,
              arrived: row.sale_arrived ? row.sale_arrived : 0,
              collected: row.sale_collected ? row.sale_collected : 0,
              clientadvpd: row.sale_clientadvpd ? row.sale_clientadvpd : 0,
              freightpd: row.sale_freightpd ? row.sale_freightpd : 0,
              copydox: row.sale_copydox ? row.sale_copydox : 0,
              orgnldox: row.sale_orgnldox ? row.sale_orgnldox : 0,
              billtype: row.sale_billtype ? row.sale_billtype : "",
              clientfinpd: row.sale_clientfinpd ? row.sale_clientfinpd : 0,
              telex: row.sale_telex ? row.sale_telex : 0,
              accsalereceived: row.sale_accsalereceived ? row.sale_accsalereceived : 0,
            });
          }

          if (row.dispatch_id) {
            dispatch.push({
              id: row.dispatch_id,
              forwardagentref: row.forwardagentref,
            });
          }

          const { coldstoreoptions, portoptions, shippinglines, vesseloptions } = this.state;

          const coldroom_id = coldstoreoptions.find((citem) => citem.value == row.loadout_coldroomcode);
          const portLoading_id = portoptions.find((pitem: PortReadyForComboIdValue) => pitem.code == row.portloading_code);
          const portDischarge_id = portoptions.find((pitem: PortReadyForComboIdValue) => pitem.code == row.portdistcharge_code);
          const portFinal_id = portoptions.find((pitem: PortReadyForComboIdValue) => pitem.code == row.portfinal_code);
          const shipping_id = shippinglines.find((sitem) => sitem.code == row.shippingCode);
          const vessel_id = vesseloptions.find((vitem) => vitem.code == row.vessel_code);

          const loadoutData: any = {
            id: row.loadout_id,
            spec: row.loadout_spec == 1 ? 1 : 0,
            loadingdocs: row.loadout_loadingdocs == 1 ? 1 : 0,
            exportNotificationReference: row.loadout_exportNotificationReference,
            dateLoading: row.dateLoading,
            eta: row.loadout_eta,
            etd: row.loadout_etd,
            updated_eta: !row.loadout_updatedeta || row.loadout_updatedeta == "" ? null : row.loadout_updatedeta,
            voyageNumber: row.loadout_voyageNumber,
            collected: row.collected,
            notes: row.notes,
            consignee: row.clients_code,
            tempcode: row.tempcode,
            po: row.client_po,
          };

          if (coldroom_id) loadoutData.coldroom_id = coldroom_id.id;
          if (portLoading_id) loadoutData.portLoading_id = portLoading_id.id;
          if (portDischarge_id) loadoutData.portDischarge_id = portDischarge_id.id;
          if (portFinal_id) loadoutData.portFinal_id = portFinal_id.id;
          if (vessel_id) loadoutData.vessel_id = vessel_id.id;
          if (shipping_id) loadoutData.shipping_id = shipping_id.id;

          loadout.push(loadoutData);
          return { sale, loadout, dispatch };
        },
        {
          sale: [],
          loadout: [],
          dispatch: [],
        },
      );

      await updateSaleJoinedRecord({ sale, loadout, dispatch });

      this.state.onReload();
    } catch (error) {
      const err = GenerateErrorMessage(error, "Error updating Shipment");
      this.context.updateSnack({ show: true, color: "red", message: err });
    }
    this.setState({ submitting: false });
  };

  rowRenderer = (props, classes) => {
    const { row } = props;
    const fontWeight = this.state.selectedLoadout.find((frow) => frow.loadout_reference == row.loadout_reference) && classes.boldRow;
    const fontColor = row.rowDirty == 1 ? classes.redRow : classes.blackRow;

    return <Row {...props} onDoubleClickCapture={() => this.handleAvailableLoadoutDoubleClick(row)} className={classNames(fontWeight, fontColor)} />;
  };

  selectedRowRenderer = (props, classes) => {
    const { row } = props;
    const color = row["producer_solasmethod2"] && (Number(row["weight"]) == 0 || Number(row["grossWeight"]) == 0) && classes.redRow;

    return <Row {...props} onDoubleClickCapture={() => this.handleSelectedStockDoubleClick(row)} className={color} />;
  };

  handleGridRefresh = () => {
    this.setState({ loading: true }, () => {
      this.state.onReload().then(() => {
        this.setState({ loading: false });
      });
    });
  };

  menuItems = {
    1: {
      title: "Shipments",
      action: (data, filters) => {
        handleExportShipmentsData(data);
      },
    },
    2: {
      title: "Client Shipment Report",
      action: () => {
        this.setState({ clientDialog: true });
      },
    },
  };

  handlePrint = (is_loading: boolean) => {
    this.setState({ clientDialogLoading: is_loading });
  };

  handleCloseClientDialog = () => {
    this.setState({ clientDialog: false });
  };

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        {this.state.dialoginformation && (
          <DialogInformation
            isOpen={true}
            handleClose={this.handleInfoClose}
            handleOK={this.handleInfoClose}
            title={this.state.dialoginformation.title}
            body={`${this.state.dialoginformation.body}`}
            showinput={false}
          />
        )}
        {this.state.selectedRecord && (
          <Confirmation
            isOpen={true}
            handleClose={this.handleSelectedRecordClose}
            handleConfirm={this.handleSelectedRecordClose}
            title={`Selected Record (${this.state.selectedRecord.barcode}) Action`}
            body={undefined}
          >
            <LoadoutTableActionDialog row={this.state.selectedRecord} handleClose={this.handleSelectedRecordClose} />
          </Confirmation>
        )}
        {this.state.clientDialog && (
          <Confirmation
            isOpen={true}
            handleClose={this.handleCloseClientDialog}
            handleConfirm={this.handleCloseClientDialog}
            title={"Client Shipment Report - Select a client"}
            body={undefined}
            disableBackdropClick={this.state.clientDialogLoading}
          >
            <ClientSelector handlePrint={this.handlePrint} />
          </Confirmation>
        )}
        <div id="main_splitter_div" style={{ height: "100%", width: "100%", display: "grid" }}>
          <Toolbar className={classes.toolbar}>
            {this.state.dataChanged && (
              <Button variant="contained" color="primary" onClick={() => this.handleChangeSave()} style={{ marginRight: "10px" }} disabled={this.state.submitting}>
                save
              </Button>
            )}
            {this.state.submitting && <CircularProgress color="secondary" size={25} />}
          </Toolbar>
          <Splitter
            position="horizontal"
            primaryPaneMaxHeight="calc(100% - 150px)"
            primaryPaneHeight="calc(100% - 500px)"
            primaryPaneWidth="100%"
            dispatchResize={true}
            postPoned={true}
            onDragFinished={(e) => this.setGridHeights()}
          >
            <div id="primary_grid" style={{ marginLeft: "1px" }}>
              <Grid
                fill
                forceHeight={this.state.minGridHeight}
                loading={this.state.loading}
                data={this.state.data}
                GridColumns={(data, filters, columnArrangement, columnsWidth) =>
                  GridColumns(
                    data,
                    filters,
                    columnArrangement,
                    columnsWidth,
                    this.state.handleAttachment,
                    this.state.coldstoreoptions,
                    this.state.portoptions,
                    this.state.portoptionslocal,
                    this.state.shippinglines,
                    this.state.vesseloptions,
                    this.state.billtypesData,
                    this.state.clientsoptions,
                    this.state.groupedPortOptions,
                    this.state.groupedClientOptions,
                    this.state.tempcodes,
                  )
                }
                rowRenderer={(props) => this.rowRenderer(props, classes)}
                handleRefresh={this.handleGridRefresh}
                clearFilters={"loadoutgrid"}
                showFilterChips={true}
                handleChange={this.handleChange}
                menuItems={this.menuItems}
                totalRowColumns={["barcode_nocartons", "barcode_palletsize"]}
                countRowColumns={["loadout_reference"]}
              />
            </div>
            <div id="secondary_grid">
              <Grid
                forceHeight={this.state.minGridHeightSecond}
                loading={this.state.loadingSelected}
                data={this.state.selectedRows}
                GridColumns={(data, filters, columnArrangement, columnsWidth) => GridColumnsSelected(data, filters, columnArrangement, columnsWidth)}
                rowRenderer={(props) => this.selectedRowRenderer(props, classes)}
                handleRefresh={() => {
                  this.setState({ loading: true }, () => {
                    this.state.onReload(true, true, true).then(() => {
                      this.setState({ loading: false });
                    });
                  });
                }}
                clearFilters={"loadoutselected"}
                totalRowColumns={["noCartons", "palletSize", "grossWeight", "weight"]}
              />
            </div>
          </Splitter>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(StockAllocationTableUnstyled);
