import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { withStyles, createStyles, Theme } from "@material-ui/core/styles";

import Checkbox from "@material-ui/core/Checkbox";

import ClearIcon from "@material-ui/icons/Clear";

import { isNullOrUndef } from "../helpers/isNullOrUndef";

import format from "date-fns/format";
import parseISO from "date-fns/parseISO";

const styles = (theme: Theme) =>
  createStyles({
    datepicker: {},
    wrapper: {
      position: "relative",
      display: "flex",
      alignItems: "center",
    },
    clearIcon: {
      zIndex: 10000,
      position: "absolute",
      right: "22px",
      cursor: "pointer",
      color: "black",
    },
  });

const DatePickerUnstyled: React.FC<any> = ({ classes, row, column, onRowChange }) => {
  const [date, setDate] = useState(row[column.key]);
  const wrapperRef = useRef(null);

  useEffect(() => {
    if (!isNullOrUndef(date) && date != "") {
      const formattedDate = format(parseISO(date), "yyyy-MM-dd");
      setDate(formattedDate);
    }
  }, []);

  const handleDateChange = useCallback(
    (value) => {
      setDate(value);
      onRowChange({ ...row, [column.key]: value, rowUpdated: 1 });
    },
    [setDate, onRowChange, row, column],
  );

  const handleClearDate = useCallback(() => {
    onRowChange({ ...row, [column.key]: null, rowUpdated: 1 }, true);
  }, [onRowChange, row, column]);

  const handleOnBlur = useCallback(
    (value: any) => {
      if (value && value.trim() != "") {
        setDate(value);
      }
    },
    [setDate],
  );

  useEffect(() => {
    /* Handle user click outside of Cell */
    const handleClickOutside = (event) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        onRowChange({ ...row, [column.key]: date, rowUpdated: 1 }, true);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef, date]);

  return (
    <div className={classes.wrapper} ref={wrapperRef}>
      <ClearIcon className={classes.clearIcon} onClick={handleClearDate} />
      <input
        autoFocus
        type="date"
        style={{ width: "100%" }}
        value={row[column.key] ? date : null}
        onChange={(event) => handleDateChange(event.target.value)}
        onBlur={(event) => handleOnBlur(event.target.value)}
      />
    </div>
  );
};

export const DatePicker = withStyles(styles)(DatePickerUnstyled);

export const CheckboxEdit: React.FC<any> = ({ row, column, onRowChange, isVisible = true, disabled }) => {
  if (!isVisible) {
    return <div></div>;
  }

  try {
    const checked = useMemo(() => {
      return row[column.key] == -1 || row[column.key] == 0 ? false : true;
    }, [row, column]);

    const handleChange = useCallback(
      (event) => {
        onRowChange({ ...row, [column.key]: event.target.checked ? 1 : -1, rowUpdated: 1 });
      },
      [row, column, onRowChange],
    );

    return (
      <div style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "100%" }}>
        <Checkbox color="default" checked={checked} onChange={handleChange} inputProps={{ "aria-label": "primary checkbox" }} disabled={disabled} />
      </div>
    );
  } catch (error) {}
  return <div></div>;
};
