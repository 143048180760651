import React from "react";

import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";
import { ClassNameMap } from "@material-ui/core/styles/withStyles";

import { Row } from "react-data-grid";

import { GridColumns } from "./gridsetup";

import Grid from "../lib/components/grid";
import { GreenButton } from "../lib/components/ColorButtons";
import { IAdhocInvoice } from "../lib/api/adhoc_invoice";

import Toolbar from "@material-ui/core/Toolbar";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      height: "100%",
      position: "relative",
    },
    toolbarWrapper: {
      position: "relative",
    },
    toolbar: {
      position: "absolute",
      left: 0,
      width: "100%",
      gap: `${theme.spacing(1)}px`,
    },
    boldRow: {
      fontWeight: "bold",
    },
    normalRow: {
      fontWeight: "normal",
    },
  });

type AdhocInvoiceGrid = {
  data: IAdhocInvoice[];
  history: any;
  loading: boolean;
  gridHeight: number;
  selectedAdhocId: number;
  handleRefresh(): void;
  handleSelectRow(row: IAdhocInvoice): void;
  handleRemove(adhocinvoice_id: number): void;
} & WithStyles<typeof styles>;

class AdhocInvoiceGridUnstyled extends React.Component<AdhocInvoiceGrid, {}> {
  constructor(props) {
    super(props);
  }

  handleCreate = () => {
    this.props.history.push("/adhocInvoice/0");
  };

  handleEdit = (adhoc_invoice_id: number) => {
    this.props.history.push(`/adhocInvoice/${adhoc_invoice_id}`);
  };

  rowRenderer = (props: any, classes: ClassNameMap<"normalRow" | "boldRow">) => {
    const { row } = props;
    const boldStyle = this.props.selectedAdhocId == row.id ? classes.boldRow : classes.normalRow;
    return <Row {...props} onDoubleClickCapture={() => this.props.handleSelectRow(row)} className={boldStyle} />;
  };

  render() {
    const { classes } = this.props;

    return (
      <div>
        <Toolbar className={classes.toolbar}>
          <GreenButton variant="contained" onClick={this.handleCreate}>
            Create Invoice
          </GreenButton>
        </Toolbar>
        <Grid
          fill
          showFilterChips
          data={this.props.data}
          totalRowColumns={["total_amount"]}
          loading={this.props.loading}
          clearFilters={"adhocinvoice"}
          forceHeight={this.props.gridHeight}
          handleRefresh={this.props.handleRefresh}
          rowRenderer={(props) => this.rowRenderer(props, classes)}
          GridColumns={(data, filters, columnArrangement, columnsWidth) => GridColumns(data, filters, columnArrangement, columnsWidth, this.props.handleRemove, this.handleEdit)}
        />
      </div>
    );
  }
}

export default withStyles(styles)(AdhocInvoiceGridUnstyled);
