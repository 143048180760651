import React from "react";
import CustomAutoCompleteFilter from "../lib/components/customdatagridfilter/CustomFilter";

import IconCheck from "@material-ui/icons/Check";
import IconClose from "@material-ui/icons/Close";
import { SummaryNumber, SummaryNumber2Decimal, SummaryTotal } from "../lib/components/SummaryFormatter";
import { DateFormatter, FormatterNumber, FormatterNumber2Decimals } from "../lib/components/Formatters";
import { EnquiryLink, Selector } from "../lib/components/EnquiryLink";
import { handleColumnsArrangement, handleColumnsWidth } from "../lib/helpers/grid";

const cellWidthTiny = 50;
const cellWidthSmall = 80;
const cellWidthMedium = 110;
const cellWidthLarge = 150;

const CheckboxFormatter = ({ row, column }) => {
  const data = row[column.key];

  if (data && data == 1) {
    return (
      <div>
        <IconCheck style={{ color: "green" }} />
      </div>
    );
  }
  return (
    <div>
      <IconClose style={{ color: "red" }} />
    </div>
  );
};

const defaultColumnProperties = {
  resizable: true,
  sortable: true,
  filterable: true,
};

let currentSpace = true;

export const GridColumnsSelected = (data, filters, arrangement, columnsWidth) => {
  let postValue = "";
  if (data) {
    if (currentSpace) {
      postValue = " ";
    }
    currentSpace = !currentSpace;
  }

  const columns = [
    {
      key: "userName",
      name: "UserName" + postValue,
      width: cellWidthSmall,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"userName"} data={data} section="loadoutselected" />;
      },
    },
    {
      key: "loadout_coldroom_code",
      name: "Coldroom" + postValue,
      width: cellWidthLarge,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"loadout_coldroom_code"} data={data} section="loadoutselected" />;
      },
    },
    {
      key: "reference",
      name: "Reference" + postValue,
      width: cellWidthMedium,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"reference"} data={data} section="loadoutselected" />;
      },
      summaryFormatter: SummaryTotal,
    },
    {
      key: "barcode",
      name: "Barcode" + postValue,
      width: cellWidthLarge,
      formatter: (props) => <EnquiryLink {...props} selector={Selector.Barcode} />,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"barcode"} data={data} section="loadoutselected" />;
      },
    },
    {
      key: "commodityCode",
      name: "Commodity" + postValue,
      width: cellWidthSmall,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"commodityCode"} data={data} section="loadoutselected" />;
      },
    },
    {
      key: "varietyCode",
      name: "Variety" + postValue,
      width: cellWidthSmall,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"varietyCode"} data={data} section="loadoutselected" />;
      },
    },
    {
      key: "gradeCode",
      name: "Grade" + postValue,
      width: cellWidthSmall,
      formatter: FormatterNumber,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"gradeCode"} data={data} section="loadoutselected" />;
      },
    },
    {
      key: "countCode",
      name: "Count" + postValue,
      width: cellWidthSmall,
      formatter: FormatterNumber,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"countCode"} data={data} section="loadoutselected" />;
      },
    },
    {
      key: "packCode",
      name: "Pack" + postValue,
      width: cellWidthSmall,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"packCode"} data={data} section="loadoutselected" />;
      },
    },
    {
      key: "markCode",
      name: "Mark" + postValue,
      width: cellWidthSmall,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"markCode"} data={data} section="loadoutselected" />;
      },
    },
    {
      key: "inventoryCode",
      name: "Inv Code" + postValue,
      width: cellWidthSmall,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"inventoryCode"} data={data} section="loadoutselected" />;
      },
    },
    {
      key: "purchasePrice",
      name: "Purchase Price",
      width: cellWidthSmall,
      formatter: FormatterNumber2Decimals,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"purchasePrice"} data={data} section="loadoutselected" />;
      },
    },
    {
      key: "sellp",
      name: "Selling Price" + postValue,
      width: cellWidthSmall,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"sellp"} data={data} section="loadoutselected" />;
      },
      formatter: FormatterNumber2Decimals,
    },
    {
      key: "noCartons",
      name: "no Cartons" + postValue,
      width: cellWidthSmall,
      formatter: FormatterNumber,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"noCartons"} data={data} section="loadoutselected" />;
      },
      summaryFormatter: SummaryNumber,
    },
    {
      key: "palletSize",
      name: "PalletSize" + postValue,
      width: cellWidthSmall,
      formatter: FormatterNumber2Decimals,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"palletSize"} data={data} section="loadoutselected" />;
      },
      summaryFormatter: SummaryNumber2Decimal,
    },
    {
      key: "grossWeight",
      name: "Gross Weight",
      width: cellWidthSmall,
      formatter: FormatterNumber2Decimals,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"grossWeight"} data={data} section="loadoutselected" />;
      },
      summaryFormatter: SummaryNumber2Decimal,
    },
    {
      key: "weight",
      name: "Net Weight",
      width: cellWidthSmall,
      formatter: FormatterNumber2Decimals,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"weight"} data={data} section="loadoutselected" />;
      },
      summaryFormatter: SummaryNumber2Decimal,
    },
    {
      key: "loadout_dateLoading",
      name: "DateLoading" + postValue,
      width: cellWidthLarge,
      formatter: DateFormatter,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"loadout_dateLoading"} data={data} section="loadoutselected" />;
      },
    },
    {
      key: "loadout_shippingline",
      name: "Shipping Line" + postValue,
      width: cellWidthLarge,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"loadout_shippingline"} data={data} section="loadoutselected" />;
      },
    },
    {
      key: "loadout_voyageNumber",
      name: "Voyage" + postValue,
      width: cellWidthLarge,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"loadout_voyageNumber"} data={data} section="loadoutselected" />;
      },
    },
    {
      key: "loadout_vessel",
      name: "Vessel" + postValue,
      width: cellWidthLarge,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"loadout_vessel"} data={data} section="loadoutselected" />;
      },
    },
    {
      key: "containerno",
      name: "Container No" + postValue,
      width: cellWidthLarge,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"containerno"} data={data} section="loadoutselected" />;
      },
    },
    {
      key: "loadout_portLoading",
      name: "Port Loading" + postValue,
      width: cellWidthLarge,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"loadout_portLoading"} data={data} section="loadoutselected" />;
      },
    },
    {
      key: "loadout_portDischarge",
      name: "Port Discharge" + postValue,
      width: cellWidthLarge,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"loadout_portDischarge"} data={data} section="loadoutselected" />;
      },
    },
    {
      key: "pod",
      name: "POD" + postValue,
      width: cellWidthMedium,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"pod"} data={data} section="loadoutselected" />;
      },
    },
    {
      key: "loadout_portFinal",
      name: "Port Final" + postValue,
      width: cellWidthLarge,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"loadout_portFinal"} data={data} section="loadoutselected" />;
      },
    },
    {
      key: "tempcode",
      name: "Temp Code" + postValue,
      width: cellWidthMedium,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"tempcode"} data={data} section="loadoutselected" />;
      },
    },
    {
      key: "po",
      name: "PO Number" + postValue,
      width: cellWidthMedium,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"po"} data={data} section="loadoutselected" />;
      },
    },
    {
      key: "locationCode",
      name: "Location" + postValue,
      width: cellWidthSmall,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"locationCode"} data={data} section="loadoutselected" />;
      },
    },
    {
      key: "age",
      name: "Age" + postValue,
      width: cellWidthSmall,
      formatter: FormatterNumber,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"age"} data={data} section="loadoutselected" />;
      },
    },
    {
      key: "ageAtLocation",
      name: "Age@Loc" + postValue,
      width: cellWidthSmall,
      formatter: FormatterNumber,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"ageAtLocation"} data={data} section="loadoutselected" />;
      },
    },
    {
      key: "intakeWaybill",
      name: "Intake Waybill" + postValue,
      width: cellWidthLarge,
      formatter: (props) => <EnquiryLink {...props} selector={Selector.IntakeWaybill} />,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"intakeWaybill"} data={data} section="loadoutselected" />;
      },
    },
    {
      key: "producerId",
      name: "Producer" + postValue,
      width: cellWidthMedium,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"producerId"} data={data} section="loadoutselected" />;
      },
    },
    {
      key: "farmNumber",
      name: "PUC" + postValue,
      width: cellWidthSmall,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"farmNumber"} data={data} section="loadoutselected" />;
      },
    },
    {
      key: "phc",
      name: "phc" + postValue,
      width: cellWidthSmall,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"phc"} data={data} section="loadoutselected" />;
      },
    },
    {
      key: "coldDate",
      name: "Cold Date",
      width: cellWidthSmall,
      formatter: DateFormatter,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"coldDate"} data={data} section="loadoutselected" />;
      },
    },
    {
      key: "pickingRef",
      name: "Picking Ref" + postValue,
      width: cellWidthSmall,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"pickingRef"} data={data} section="loadoutselected" />;
      },
    },
    {
      key: "producer_solasmethod2",
      name: "Solas" + postValue,
      width: cellWidthMedium,
      formatter: CheckboxFormatter,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"producer_solasmethod2"} data={data} section="loadoutselected" />;
      },
    },
    {
      key: "consigneeId",
      name: "Consignee" + postValue,
      width: cellWidthSmall,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"consigneeId"} data={data} section="loadoutselected" />;
      },
    },
    {
      key: "consigneeName",
      name: "Consignee Name" + postValue,
      width: cellWidthSmall,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"consigneeName"} data={data} section="loadoutselected" />;
      },
    },
    {
      key: "inspectionAge",
      name: "Insp Age",
      width: cellWidthSmall,
      formatter: FormatterNumber,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"inspectionAge"} data={data} section="loadoutselected" />;
      },
    },
    {
      key: "orchard",
      name: "Orchard" + postValue,
      width: cellWidthSmall,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"orchard"} data={data} section="loadoutselected" />;
      },
    },
    {
      key: "inspectionDate",
      name: "Inspection Date",
      width: cellWidthMedium,
      formatter: DateFormatter,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"inspectionDate"} data={data} section="loadoutselected" />;
      },
    },
    {
      key: "originalIntakeDate",
      name: "Org Intake Date",
      width: cellWidthMedium,
      formatter: DateFormatter,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"originalIntakeDate"} data={data} section="loadoutselected" />;
      },
    },
    {
      key: "targetCountry",
      name: "TC" + postValue,
      width: cellWidthSmall,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"targetCountry"} data={data} section="loadoutselected" />;
      },
    },
    {
      key: "targetMarket",
      name: "TM" + postValue,
      width: cellWidthSmall,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"targetMarket"} data={data} section="loadoutselected" />;
      },
    },
    {
      key: "targetRegion",
      name: "Target Region" + postValue,
      width: cellWidthSmall,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"targetRegion"} data={data} section="loadoutselected" />;
      },
    },
    {
      key: "temperature",
      name: "Temperature",
      width: cellWidthSmall,
      formatter: FormatterNumber,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"temperature"} data={data} section="loadoutselected" />;
      },
    },
    {
      key: "traderid",
      name: "Trader" + postValue,
      width: cellWidthSmall,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"traderid"} data={data} section="loadoutselected" />;
      },
    },
    {
      key: "organization",
      name: "Org" + postValue,
      width: cellWidthSmall,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"organization"} data={data} section="loadoutselected" />;
      },
    },
    {
      key: "dateEntered",
      name: "Date Entered",
      width: cellWidthMedium,
      formatter: DateFormatter,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"dateEntered"} data={data} section="loadoutselected" />;
      },
    },
    {
      key: "loadout_exportNotificationReference",
      name: "Notification Reference" + postValue,
      width: cellWidthLarge,
      filterRenderer: (e) => {
        return (
          <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"loadout_exportNotificationReference"} data={data} section="loadoutselected" />
        );
      },
    },
  ].map((c) => ({ ...c, ...defaultColumnProperties }));

  let columnsArranged = [];
  columnsArranged = handleColumnsArrangement(columnsArranged, arrangement, columns);
  columnsArranged = handleColumnsWidth(columnsArranged, columnsWidth, columns);

  return columnsArranged;
};
