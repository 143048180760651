import { client, request } from "./_base";

const endpoint = "piinstruction";

// extended methods
export const FindByOrderId = (orderId) => {
  return request(`${endpoint}/findByOrderId/${orderId}`, {
    method: "GET",
  })
    .then(async (result) => {
      if (result.status == 500) {
        const resp = await result.json();
        throw resp;
      }
      return result.json();
    })
    .then(({ data }) => {
      return data;
    });
};

export const piinstructions = {
  ...client(endpoint, (item) => ({
    ...item,
  })),
  FindByOrderId: FindByOrderId,
};
