import React from "react";

import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import Clear from "@material-ui/icons/Clear";
import Done from "@material-ui/icons/Done";

import CustomAutoCompleteFilter from "../../lib/components/customdatagridfilter/CustomFilter";

import parseISO from "date-fns/parseISO";
import { format } from "date-fns";
import { CellActionsFormatter } from "../../lib/helpers/CellActionsFormatter";

const cellWidthTiny = 40;
const cellWidthXtraSmall = 70;
const cellWidthSmall = 80;
const cellWidthSmallBig = 140;
const cellWidthMedium = 200;
// const cellWidthLarge = 250;
const cellWidthXtrLarge = 350;

const defaultColumnProperties = {
  resizable: true,
  sortable: true,
};

const dateFormatterSampleDate = ({ row, column }) => {
  const data = row[column.key];
  return <div>{`${format(parseISO(data), "dd-MMM-yyyy")}`}</div>;
};

const dateFormatterSampleDateEnd = ({ row, column }) => {
  const data = row[column.key];
  return <div>{`${format(parseISO(data), "dd-MMM-yyyy")}`}</div>;
};

const approvedFormatter = ({ row, column }) => {
  const value = row[column.key];
  if (value) {
    return <Done />;
  }
  return <Clear />;
};

let currentSpace = true;
export const GridColumns = (data, filters, handleRemove, handleEdit) => {
  let postValue = "";

  if (data) {
    if (currentSpace) {
      postValue = " ";
    }
    currentSpace = !currentSpace;
  }

  return [
    {
      key: "actions",
      name: "Actions",
      width: cellWidthSmall,
      formatter: ({ row }) => {
        const actions = cellActions(row, handleRemove, handleEdit);
        return (
          <div style={{ display: "flex", justifyContent: "center", gap: "5px" }}>
            <CellActionsFormatter actions={actions} />
          </div>
        );
      },
    },
    {
      key: "approved",
      name: "Approved" + postValue,
      width: cellWidthSmall,
      filterable: true,
      formatter: approvedFormatter,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiselection={true} currentFilters={filters} fieldname={"approved"} data={data} section={"labtestsgridsetup"} />;
      },
    },
    {
      key: "sampledate",
      name: "Sample Date" + postValue,
      width: cellWidthSmallBig,
      formatter: dateFormatterSampleDate,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiselection={true} currentFilters={filters} fieldname={"sampledate"} data={data} section={"labtestsgridsetup"} />;
      },
    },
    {
      key: "sampledateend",
      name: "Sample Date End" + postValue,
      width: cellWidthSmallBig,
      formatter: dateFormatterSampleDateEnd,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiselection={true} currentFilters={filters} fieldname={"sampledateend"} data={data} section={"labtestsgridsetup"} />;
      },
    },
    {
      key: "lab",
      name: "Lab" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiselection={true} currentFilters={filters} fieldname={"lab"} data={data} section={"labtestsgridsetup"} />;
      },
    },
    {
      key: "producer_code",
      name: "Producer Code" + postValue,
      width: cellWidthSmallBig,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiselection={true} currentFilters={filters} fieldname={"producer_code"} data={data} section={"labtestsgridsetup"} />;
      },
    },
    {
      key: "producer_name",
      name: "Producer Name" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiselection={true} currentFilters={filters} fieldname={"producer_name"} data={data} section={"labtestsgridsetup"} />;
      },
    },
    {
      key: "producerpuc_code",
      name: "PUC" + postValue,
      width: cellWidthSmall,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiselection={true} currentFilters={filters} fieldname={"producerpuc_code"} data={data} section={"labtestsgridsetup"} />;
      },
    },
    {
      key: "producerorchard_code",
      name: "Orchard" + postValue,
      width: cellWidthSmall,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiselection={true} currentFilters={filters} fieldname={"producerorchard_code"} data={data} section={"labtestsgridsetup"} />;
      },
    },
    {
      key: "variety_code",
      name: "Variety" + postValue,
      width: cellWidthSmall,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiselection={true} currentFilters={filters} fieldname={"variety_code"} data={data} section={"labtestsgridsetup"} />;
      },
    },
    {
      key: "variety_name",
      name: "Variety Name" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiselection={true} currentFilters={filters} fieldname={"variety_name"} data={data} section={"labtestsgridsetup"} />;
      },
    },
    {
      key: "labtesttype_detail",
      name: "Test Type" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiselection={true} currentFilters={filters} fieldname={"labtesttype_detail"} data={data} section={"labtestsgridsetup"} />;
      },
    },
  ].map((c) => ({ ...c, ...defaultColumnProperties }));
};

const cellActions = (order, handleRemove, handleEdit) => {
  return [
    {
      icon: (
        <span title="Edit">
          <EditIcon style={{ cursor: "pointer", color: "orange" }} />
        </span>
      ),
      callback: (e) => {
        handleEdit(order.id);
      },
    },
    {
      icon: (
        <span title="Remove">
          <DeleteIcon style={{ cursor: "pointer", color: "red" }} />
        </span>
      ),
      callback: (e) => {
        handleRemove(order.id);
      },
    },
  ];
};
