import { client } from "./_base";

const endpoint = "shippingline";
export const shippingline = client(endpoint, (item) => ({
  ...item,
}));

export const getShippingLineReadyForCombo = () => {
  return shippingline.all().then((result) => {
    const ready = result
      .sort((a, b) => {
        if (a.description > b.description) return 1;
        if (a.description < b.description) return -1;
        return 1;
      })
      .map((ship) => {
        return { value: ship.id, display: ship.description + " (" + ship.code + ")", data: ship };
      });
    return ready;
  });
};

export const getShippingLineReadyForComboIdValue = async () => {
  const result = await shippingline.all();
  return result
    .sort((a, b) => {
      if (a.description > b.description) return 1;
      if (a.description < b.description) return -1;
      return 1;
    })
    .map((ship) => ({ id: ship.id, value: ship.description + " (" + ship.code + ")", code: ship.code, data: ship }));
};
