import React, { useEffect } from "react";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";

import { Form, Field } from "react-final-form";
import { TextField, Checkbox, Radio, Select } from "final-form-material-ui";

import Button from "@material-ui/core/Button";

import { arrivals } from "../../lib/api/arrival";

import { SnackContext } from "../../lib/context/SnackContext";
import { GenerateErrorMessage } from "../../lib/helpers/string_methods";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1),
    },
  });

type ArrivalActionDialogProps = {
  arrivalid: number;
  handleClose: () => void;
} & WithStyles<typeof styles>;

const ArrivalActionDialogUnstyled: React.FunctionComponent<ArrivalActionDialogProps> = (props) => {
  const { classes, arrivalid, handleClose } = props;

  const [arrivalData, setArrivalData] = React.useState([]);
  const { updateSnack } = React.useContext(SnackContext);

  useEffect(() => {
    loadData(arrivalid);
  }, [arrivalid]);

  const loadData = (arrivalid) => {
    arrivals
      .single(arrivalid)
      .then((data) => {
        setArrivalData({ ...data });
      })
      .catch((error) => {
        const err = GenerateErrorMessage(error, "failed to retrieve data");
        updateSnack({ show: true, color: "red", message: err });
      });
  };

  const handleConfirm = (values) => {
    const updateData = {
      data: {
        note: values.note,
      },
    };
    arrivals.update(arrivalid, { ...updateData }).then(() => {
      handleClose();
    });
  };

  return (
    <div className={classes.root}>
      <Form
        initialValues={{ ...arrivalData }}
        onSubmit={(values) => {
          handleConfirm({ ...values });
        }}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <table>
              <tbody>
                <tr style={{ width: "500px" }}>
                  <td style={{ width: "500px" }}>
                    <div style={{ paddingTop: "10px" }}>
                      <Field name="note" component={TextField} label="Note" type="text" fullWidth={true} multiline />
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <div style={{ paddingTop: "20px", paddingBottom: "10px", float: "right" }}>
              <Button name="submit" type="submit" variant="contained" color="primary" style={{ margin: "2px" }}>
                Confirm
              </Button>
              <Button name="close" variant="contained" color="secondary" style={{ margin: "2px" }} onClick={handleClose}>
                Close
              </Button>
            </div>
          </form>
        )}
      />
    </div>
  );
};

export default withStyles(styles)(ArrivalActionDialogUnstyled);
