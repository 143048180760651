import { client, request, apiHeaders } from "./_base";

const table = "consignee";

export type ConsigneeType = {
  id: number;
  code: string;
  name: string;
  physical_address: string;
  postal_address: string;
  telephone: string;
  mobile: string;
  email: string;
  target_id: number;
  active: number;
  destinationtype: number;
  created_at: Date;
  updated_at: Date;
};
export const consignee = client(table, (item) => ({
  ...item,
}));

export const getConsigneeReadyForCombo = (clientID, clientCode) => {
  return ConsigneeforClient(clientID, clientCode).then((result: [ConsigneeForClientType]) => {
    const ready = result
      .sort((a, b) => {
        if (a.code > b.code) return 1;
        if (a.code < b.code) return -1;
        return 1;
      })
      .map((mapitem) => {
        return { value: mapitem.id, display: mapitem.name, data: mapitem };
      });
    return ready;
  });
};

export type ConsigneeForClientType = {
  id: number;
  code: string;
  name: string;
  physical_address: string;
  postal_address: string;
  telephone: string;
  mobile: string;
  email: string;
  target_id: number;
  active: number;
  destinationtype: number;
  created_at: Date;
  updated_at: Date;
  clientsconsignee_id: number;
  clearingAgents: number;
};
export const ConsigneeforClient = (clientId, clientCode) => {
  return request(`${table}/ext/forClient/${clientId}/${clientCode ? clientCode : undefined}`, {
    method: "GET",
  })
    .then(async (result) => {
      if (result.status == 500) {
        const resp = await result.json();
        throw resp;
      }
      return result.json();
    })
    .then(({ data }) => {
      return data;
    });
};

export const ConsigneeAvailableToClient = (clientId) => {
  return request(`${table}/ext/availableToClient/${clientId}`, {
    method: "GET",
  })
    .then(async (result) => {
      if (result.status == 500) {
        const resp = await result.json();
        throw resp;
      }
      return result.json();
    })
    .then(({ data }) => {
      return data;
    });
};

export const withConcatenatedClearingAgents = () => {
  return request(`${table}/extend/withConcatenatedClearingAgents`, {
    headers: apiHeaders(),
  }).then(async (result) => {
    if (result.status == 500) {
      const resp = await result.json();
      throw resp;
    }
    return result.json();
  });
};
