import React, { FC } from "react";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";

import Check from "@material-ui/icons/Check";
import Close from "@material-ui/icons/Close";

import Button from "@material-ui/core/Button";

import { Field, Form } from "react-final-form";
import { TextField } from "final-form-material-ui";

import { classes } from "../../lib/api/classes";
import { MaterialCheckBoxComponent } from "../../lib/helpers/materialcomponents";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1),
    },
    formField: {
      display: "grid",
      gridTemplateColumns: "120px 250px",
      gap: `${theme.spacing(2)}px`,
      "& label": {
        textAlign: "right",
        alignSelf: "center",
      },
    },
    formBody: {
      display: "flex",
      flexDirection: "column",
      gap: `${theme.spacing(1)}px`,
    },
    actionButtons: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-end",
      gap: `${theme.spacing(1)}px`,
      marginTop: `${theme.spacing(2)}px`,
    },
  });

type ClassesEditFormProps = {
  onSubmit: any;
  onClose: any;
  id: any;
} & WithStyles<typeof styles>;

class ClassesEditFormUnstyled extends React.Component<ClassesEditFormProps, any> {
  state = {
    classes: undefined,
    item: { id: "0", code: "", active: false },
    onSubmit: undefined,
    onClose: undefined,
    id: 0,
  };

  constructor(props) {
    super(props);
    this.state.classes = props.classes;
    this.state.onSubmit = props.onSubmit;
    this.state.onClose = props.onClose;
    this.state.id = props.id;
  }

  componentDidMount() {
    if (this.state.id != 0) {
      this.getItem(this.state.id);
    }
  }

  getItem = (id) => {
    classes.single(id).then((data) => {
      this.setState({ item: data });
    });
  };

  handleFocus = (event) => {
    event.target.select();
  };

  render() {
    const { classes } = this.state;

    return (
      <div className={classes.root}>
        <Form
          initialValues={{ ...this.state.item }}
          onSubmit={(values) => this.state.onSubmit(values)}
          validate={(values) => {
            let errors = {};
            if (values.code === "" || !values.code) {
              errors["code"] = "Grade Code required";
            }
            if (values.description === "" || !values.description) {
              errors["description"] = "Grade Description required";
            }
            return errors;
          }}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <div className={classes.formBody}>
                <TableFieldCheckbox classes={classes} title="Active" field="active" />
                <TableFieldText classes={classes} title="Code" field="code" />
                <TableFieldText classes={classes} title="Description" field="description" />
              </div>
              <div className={classes.actionButtons}>
                <Button color="secondary" variant="outlined" onClick={this.state.onClose}>
                  <Close />
                </Button>
                <Button type="submit" color="primary" variant="outlined">
                  <Check />
                </Button>
              </div>
            </form>
          )}
        />
      </div>
    );
  }
}

type TableFieldTextProps = {
  title: string;
  field: string;
  disabled?: boolean;
} & WithStyles<typeof styles>;

const TableFieldText: FC<TableFieldTextProps> = (props) => {
  const { classes, field, title, disabled } = props;
  return (
    <div className={classes.formField}>
      <label>{title}:</label>
      <div>
        <Field fullWidth name={field} component={TextField} type="text" disabled={disabled} />
      </div>
    </div>
  );
};

type TableFieldCheckboxProps = {
  title: string;
  field: string;
} & WithStyles<typeof styles>;

const TableFieldCheckbox: FC<TableFieldCheckboxProps> = ({ classes, title, field }) => (
  <div className={classes.formField}>
    <label>{title}:</label>
    <div>
      <Field name={field} component={MaterialCheckBoxComponent as any} type="checkbox" style={{ margin: 0, padding: 0, marginLeft: "-18px" }} />
    </div>
  </div>
);

export default withStyles(styles)(ClassesEditFormUnstyled);
