import React, { FC, useContext, useState } from "react";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";

import Toolbar from "@material-ui/core/Toolbar";

import { PinkButton } from "../../../../lib/components/ColorButtons";
import Confirmation from "../../../../lib/components/confirmation";
import { AllocationList, SaleFundsAllocation } from "../../../../lib/api/salefundsallocation";
import { GenerateErrorMessage } from "../../../../lib/helpers/string_methods";
import { SnackContext } from "../../../../lib/context/SnackContext";
import { AllocationGridTable } from "./grid";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1),
      position: "relative",
      height: "100%",
    },
    toolbar: {
      position: "absolute",
      left: 0,
      top: 0,
      height: "60px",
      width: "100%",
      display: "flex",
      flexDirection: "row",
      gap: "10px",
    },
  });

type Props = {
  clients_code: string;
  allocationList: AllocationList[];
  handleRefresh(): void;
  setAllocationList(allocations: AllocationList[]): void;
} & WithStyles<typeof styles>;

const AllocationsGridUnstyled: FC<Props> = ({ classes, allocationList, clients_code, setAllocationList, handleRefresh }) => {
  const { updateSnack } = useContext(SnackContext);

  const [removeAllocationIds, setRemoveAllocationIds] = useState<number[]>([]);
  const [confirmRemoveAllocations, setConfirmRemoveAllocations] = useState(false);

  const handleRemoveSelectedAllocations = () => {
    setConfirmRemoveAllocations(true);
  };

  const handleRemoveFundsAllocationConfirm = async (bool: boolean) => {
    if (bool) {
      const promiseArr = removeAllocationIds.map((id) => SaleFundsAllocation.remove(id));
      await Promise.all(promiseArr);
      setRemoveAllocationIds([]);
      handleRefresh();
    }
    setConfirmRemoveAllocations(false);
  };

  const handleSelectRemoveAllocation = (rowId: number) => {
    if (removeAllocationIds.includes(rowId)) {
      setRemoveAllocationIds(removeAllocationIds.filter((id) => id != rowId));
    } else {
      setRemoveAllocationIds([...removeAllocationIds, rowId]);
    }
  };

  const handleChange = async (property: string, value: any[]) => {
    try {
      const updatedRecords: any[] = value.reduce((arr, row) => {
        if (row.rowUpdated === 1 || row.rowDirty === 1) {
          arr.push(
            SaleFundsAllocation.update(row.id, {
              data: {
                amount: row.amount,
                makeanote: row.makeanote,
              },
            }),
          );
        }
        return arr;
      }, []);

      const updatedAllocationList = allocationList.reduce((arr, row) => {
        const exists = value.find((item) => (item.rowUpdated === 1 || item.rowDirty === 1) && item.id == row.id);
        if (exists) {
          arr.push({ ...row, amount: exists.amount });
        } else {
          arr.push(row);
        }
        return arr;
      }, []);

      setAllocationList(updatedAllocationList);
      await Promise.all(updatedRecords);
      handleRefresh();
    } catch (error) {
      const err = GenerateErrorMessage(error, "Error updating Allocation");
      updateSnack({ show: true, color: "red", message: err });
    }
  };

  return (
    <div style={{ height: "400px", width: "900px", position: "relative" }}>
      {confirmRemoveAllocations && (
        <Confirmation
          isOpen
          handleClose={() => handleRemoveFundsAllocationConfirm(false)}
          handleConfirm={() => handleRemoveFundsAllocationConfirm(true)}
          title={"Remove selected Allocation(s)"}
          body={"Are you sure you want to remove the selected allocations?"}
        />
      )}
      <Toolbar className={classes.toolbar}>
        <h3>ALLOCATIONS</h3>
        <PinkButton variant="contained" onClick={() => handleRemoveSelectedAllocations()} disabled={removeAllocationIds.length === 0}>
          Remove Selected
        </PinkButton>
      </Toolbar>
      <AllocationGridTable
        data={allocationList}
        clients_code={clients_code}
        removeAllocationIds={removeAllocationIds}
        handleChange={handleChange}
        handleSelectRemoveAllocation={handleSelectRemoveAllocation}
      />
    </div>
  );
};

export default withStyles(styles)(AllocationsGridUnstyled);
