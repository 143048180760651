import React from "react";

import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";

import Grid from "../../lib/components/grid";
import { GridColumns } from "./psfilelistgridsetup";
import { getPSFileList } from "../../lib/api/edifilecomparison";

import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";
import { Row } from "react-data-grid";
import format from "date-fns/format";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1),
      width: "800px",
      height: "700px",
    },
    gridWrapper: {
      position: "relative",
    },
    toolbar: {
      position: "absolute",
      left: 0,
      top: 0,
      height: "60px",
      width: "100%",
      display: "flex",
      flexDirection: "row",
      gap: "10px",
    },
    boldRow: {
      fontWeight: "bold",
    },
    normalRow: {
      fontWeight: "normal",
    },
  });

type Props = {
  compareSelectedFile(fileImportId: number): void;
} & WithStyles<typeof styles>;

class PSFileListUnstyled extends React.Component<Props, {}> {
  constructor(props) {
    super(props);
  }

  state = {
    loading: true,
    data: [],
    selectedRow: undefined,
  };

  componentDidMount() {
    this.loadData();
  }

  loadData = async () => {
    this.setState({ loading: true });
    const result = await getPSFileList();
    this.setState({ loading: false, data: result.data.map((row) => ({ ...row, formattedDate: format(new Date(row.timestamp), "yyyy-MM-dd") })) });
  };

  handleCompareSelected = () => {
    this.props.compareSelectedFile(this.state.selectedRow.id);
  };

  handleRowSelect = (row) => {
    this.setState({ selectedRow: row });
  };

  rowRenderer = (props, classes) => {
    const { row } = props;
    const boldStyle = this.state.selectedRow && this.state.selectedRow.id == row.id ? classes.boldRow : classes.normalRow;

    return <Row {...props} className={boldStyle} onDoubleClick={() => this.handleRowSelect(row)} />;
  };

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <div className={classes.gridWrapper}>
          <Toolbar className={classes.toolbar}>
            <Button color="primary" variant="contained" disabled={!this.state.selectedRow} onClick={this.handleCompareSelected}>
              Compare selected
            </Button>
          </Toolbar>
          <Grid
            forceHeight={600}
            data={this.state.data}
            loading={this.state.loading}
            GridColumns={GridColumns}
            clearFilters={"psfilelist"}
            rowRenderer={(props) => this.rowRenderer(props, classes)}
          />
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(PSFileListUnstyled);
