import React from "react";

import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";

import CustomAutoCompleteFilter from "../../lib/components/customdatagridfilter/CustomFilter";
import { CellActionsFormatter } from "../../lib/helpers/CellActionsFormatter";

const cellWidthTiny = 40;
const cellWidthXtraSmall = 70;
const cellWidthSmall = 80;
const cellWidthSmallBig = 140;
const cellWidthMedium = 200;
// const cellWidthLarge = 250;
const cellWidthXtrLarge = 350;

const defaultColumnProperties = {
  resizable: true,
  sortable: true,
};

let currentSpace = true;
export const GridColumns = (data, filters, handleRemove, handleEdit) => {
  let postValue = "";

  if (data) {
    if (currentSpace) {
      postValue = " ";
    }
    currentSpace = !currentSpace;
  }

  return [
    {
      key: "actions",
      name: "Actions",
      width: cellWidthSmall,
      formatter: ({ row }) => {
        const actions = cellActions(row, handleRemove, handleEdit);
        return (
          <div style={{ display: "flex", justifyContent: "center", gap: "5px" }}>
            <CellActionsFormatter actions={actions} />
          </div>
        );
      },
    },
    {
      key: "commoditycode",
      name: "Commodity" + postValue,
      width: cellWidthSmallBig,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"commoditycode"} data={data} section={"producerphdetailgridsetup"} />;
      },
    },
    {
      key: "exportmarket",
      name: "Export Market" + postValue,
      width: cellWidthSmallBig,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"exportmarket"} data={data} section={"producerphdetailgridsetup"} />;
      },
    },
    {
      key: "mrl",
      name: "MRL" + postValue,
      width: cellWidthSmallBig,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"mrl"} data={data} section={"producerphdetailgridsetup"} />;
      },
    },
    {
      key: "activeingredient",
      name: "Active Ingredient" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"activeingredient"} data={data} section={"producerphdetailgridsetup"} />;
      },
    },
    {
      key: "product",
      name: "Product" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"product"} data={data} section={"producerphdetailgridsetup"} />;
      },
    },
    {
      key: "waterapplication100",
      name: "Water Appl / 100ml" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return (
          <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"waterapplication100"} data={data} section={"producerphdetailgridsetup"} />
        );
      },
    },
    {
      key: "waxapplication100",
      name: "Wax Appl / 100ml" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"waxapplication100"} data={data} section={"producerphdetailgridsetup"} />;
      },
    },
    {
      key: "comments",
      name: "Comment" + postValue,
      width: cellWidthSmallBig,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"comments"} data={data} section={"producerphdetailgridsetup"} />;
      },
    },
  ].map((c) => ({ ...c, ...defaultColumnProperties }));
};

const cellActions = (row, handleRemove, handleEdit) => {
  return [
    {
      icon: (
        <span title="Edit">
          <EditIcon style={{ cursor: "pointer", color: "orange" }} />
        </span>
      ),
      callback: (e) => {
        handleEdit(row.id);
      },
    },
    {
      icon: (
        <span title="Remove">
          <DeleteIcon style={{ cursor: "pointer", color: "red" }} />
        </span>
      ),
      callback: (e) => {
        handleRemove(row.id);
      },
    },
  ];
};
