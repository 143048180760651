import React, { ReactNode, useEffect, useState, useMemo } from "react";
import { withStyles, createStyles, WithStyles, Theme, fade } from "@material-ui/core/styles";
import classNames from "classnames";

import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import InputBase from "@material-ui/core/InputBase";

import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import LockIcon from "@material-ui/icons/Lock";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";

import { Title } from "./Title";
import { MainListItems } from "./listitems";

import { history } from "../lib/history";
import { logout, whoami } from "../lib/api/_base";

import { SnackContext } from "../lib/context/SnackContext";

import Enquiry from "../enquiry/enquiry";
import { EnquiryContext } from "../lib/context/EnquiryContext";
import { clearStickyFilters } from "../lib/helpers/stickyfilters";
import { userProfileRemoveByUserID } from "../lib/api/userProfiles";
import CustomFilter from "../lib/components/customfilter/CustomFilters";

import Confirmation from "../lib/components/confirmation";
import { applyDefaultFilters } from "../lib/default-filters";

const drawerWidth = 300;

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      padding: "0px",
      zIndex: 10000,
    },
    searchBar: {
      marginRight: "92px",
      position: "relative",
      borderRadius: theme.shape.borderRadius,
      backgroundColor: fade(theme.palette.common.white, 0.15),
    },
    HighlightOffIcon: {
      width: theme.spacing(7),
      height: "100%",
      position: "absolute",
      pointerEvents: "none",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    toolbar: {
      paddingRight: "24px", // keep right padding when drawer closed
    },
    toolbarIcon: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      padding: "0 8px",
      ...theme.mixins.toolbar,
      marginTop: "-8px",
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth + 8}px)`,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    menuButton: {
      marginLeft: 12,
      marginRight: 36,
    },
    menuButtonHidden: {
      display: "none",
    },
    title: {
      flexGrow: 1,
    },
    drawerPaper: {
      position: "relative",
      whiteSpace: "nowrap",
      width: drawerWidth,
      height: "98vh",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerPaperClose: {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(1) * 7,
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(1) * 9,
      },
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
      flexGrow: 1,
      paddingLeft: theme.spacing(1) * 3,
      paddingRight: theme.spacing(1) * 3,
      height: "98vh",
      overflowY: "auto",
    },
    chartContainer: {
      marginLeft: -22,
    },
    tableContainer: {
      height: 320,
    },
    h5: {
      marginBottom: theme.spacing(1) * 2,
    },
    yearText: {
      color: "#fff",
    },
    yearWrapperText: {
      color: "#f4f4f4",
      marginRight: "20px",
      marginLeft: "20px",
      fontSize: "16px",
    },
  });

type DashboardProps = {
  classes: any;
  children?: ReactNode;
} & WithStyles<typeof styles>;

const DashboardUnstyled: React.FunctionComponent<DashboardProps> = ({ classes, children }) => {
  const { snack, updateSnack } = React.useContext(SnackContext);
  const { enquiryOpen, toggleEnquiry } = React.useContext(EnquiryContext);

  const [open, setOpen] = useState(false);
  const [user, setUser] = useState({
    id: 0,
    name: "",
    admin: undefined,
    active: undefined,
  });
  const [filter, setFilter] = useState("");
  const [enquiryDrawerWidth, setEnquiryDrawerWidth] = useState<number>(1400);
  const [showClearCacheDialog, toggleClearCacheDialog] = React.useState(false);

  useEffect(() => {
    whoami()
      .then((user) => {
        setUser({
          id: user.id,
          name: user.username,
          admin: user.admin,
          active: user.active,
        });
      })
      .catch((e) => {
        history.push("/login");
      });
  }, []);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleLogout = () => {
    logout();
    window.localStorage.setItem("loggedIn", "false");
    history.push("/login");
  };

  const handleFilterChange = (value) => {
    setFilter(value);
  };

  const handleClearSearchFilter = (value) => {
    setFilter("");
  };

  const closeEnquiryDrawer = () => {
    toggleEnquiry(false);
  };

  const openEnquiryDrawer = () => {
    toggleEnquiry(true);
  };

  const openSystemInfo = () => {
    history.push("/systemstability");
  };

  const handleDrawerResize = (ref) => {
    if (ref.current) {
      const paneWrapperWidth = (ref.current as any).paneWrapper.clientWidth;
      const paneNotPrimaryWidth = (ref.current as any).paneNotPrimary.div.clientWidth;
      setEnquiryDrawerWidth(paneWrapperWidth - paneNotPrimaryWidth);
    }
  };

  const handleClearStorage = (clearFilters: boolean) => {
    clearStickyFilters(clearFilters);
    userProfileRemoveByUserID(user.id).then(() => {
      updateSnack({ show: true, color: "green", message: "Cache has been cleared" });
    });
    toggleClearCacheDialog(false);
    // apply default filters
    applyDefaultFilters();
  };

  const handleOpenClearCacheDialog = () => {
    toggleClearCacheDialog(true);
  };

  const handleConfirmClearCacheDialog = () => {
    handleClearStorage(true);
  };

  const handleCloseClearCacheDialog = () => {
    handleClearStorage(false);
  };

  const selectedFinancialYear = useMemo(() => JSON.parse(localStorage.getItem(`financialyear`)), []);

  useEffect(() => {
    const checkFinancialYearChanged = () => {
      const financialyear = JSON.parse(localStorage.getItem("financialyear"));
      if (financialyear !== selectedFinancialYear) {
        alert("Financial year changed. Refresh browser for updated data.");
      }
    };

    window.addEventListener("storage", checkFinancialYearChanged);

    return () => {
      window.removeEventListener("storage", checkFinancialYearChanged);
    };
  }, []);

  return (
    <div className={classes.root} id="mainContent">
      {showClearCacheDialog && (
        <Confirmation
          isOpen={true}
          disableBackdropClick={true}
          handleClose={handleCloseClearCacheDialog}
          handleConfirm={handleConfirmClearCacheDialog}
          title={"Clear grid layout?"}
          body={"Do you want to clear the grid layouts?"}
        ></Confirmation>
      )}
      <CustomFilter />
      <CssBaseline />
      <AppBar position="absolute" className={classNames(classes.appBar, open && classes.appBarShift)}>
        <Toolbar disableGutters={!open} className={classes.toolbar}>
          <IconButton color="inherit" aria-label="Open drawer" onClick={handleDrawerOpen} className={classNames(classes.menuButton, open && classes.menuButtonHidden)}>
            <MenuIcon />
          </IconButton>
          <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
            <Title />
          </Typography>
          <p className={classes.yearWrapperText}>
            Year: <span className={classes.yearText}>{selectedFinancialYear}</span>
          </p>
          {user.name.toLowerCase().includes("impala") && (
            <Button color="secondary" variant="contained" style={{ marginRight: "10px" }} onClick={() => openSystemInfo()}>
              info
            </Button>
          )}
          <Button color="secondary" variant="contained" style={{ marginRight: "10px" }} onClick={() => openEnquiryDrawer()}>
            Search
          </Button>
          <IconButton color="inherit" onClick={handleLogout}>
            <span style={{ marginRight: "5px" }}>{`${user.name}`}</span>
            <LockIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Enquiry enquiryOpen={enquiryOpen} closeEnquiryDrawer={closeEnquiryDrawer} enquiryDrawerWidth={enquiryDrawerWidth} handleDrawerResize={handleDrawerResize} />
      <Drawer
        variant="permanent"
        classes={{
          paper: classNames(classes.drawerPaper, !open && classes.drawerPaperClose),
        }}
        open={open}
      >
        <div className={classes.searchBar}>
          <InputBase
            fullWidth
            onChange={(e) => {
              handleFilterChange(e.target.value.toLocaleLowerCase());
            }}
            value={filter}
            placeholder="Search"
          ></InputBase>
          <IconButton onClick={handleClearSearchFilter}>
            <HighlightOffIcon />
          </IconButton>
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Divider />
        <List>
          <MainListItems handleDrawerOpen={handleDrawerOpen} handleDrawerClose={handleDrawerClose} drawerState={open} isAdmin={user.admin} filterValue={filter} />
        </List>
        {open && (
          <div style={{ position: "relative", bottom: "5px", left: "5px" }}>
            <Button variant="contained" onClick={handleOpenClearCacheDialog}>
              clear cache
            </Button>
          </div>
        )}
      </Drawer>

      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        {children}
      </main>
    </div>
  );
};

export default withStyles(styles)(DashboardUnstyled);
