import React from "react";

import CustomAutoCompleteFilter from "../lib/components/customdatagridfilter/CustomFilter";
import { FormatterNumber, FormatterNumber2Decimals } from "../lib/components/Formatters";
import { SummaryNumber, SummaryNumber2Decimal, SummaryTotal } from "../lib/components/SummaryFormatter";
import { handleColumnsArrangement, handleColumnsWidth } from "../lib/helpers/grid";

import Tooltip from "@material-ui/core/Tooltip";
import WarningIcon from "@material-ui/icons/Warning";

const cellWidthSmallX = 80;
const cellWidthSmall = 115;
const cellWidthMedium = 140;
const cellWidthMediumX = 190;
const cellWidthLarge = 220;

const defaultColumnProperties = {
  resizable: true,
  sortable: true,
  filterable: true,
};

let currentSpaceSelected = true;

enum Selector {
  Number = 1,
  Number2Decimals = 2,
}

export const ErrorCellFormatter = (row, column) => {
  const isError = row.errors || [];

  if (isError.length > 0) {
    return (
      <Tooltip title={isError.join(", ")}>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <WarningIcon style={{ color: "red" }} />
        </div>
      </Tooltip>
    );
  }

  return <div></div>;
};

const CustomCell = (props) => {
  const { row, column, selector = undefined } = props;
  const isError = (row.errors || []).includes(column.key);

  if (selector == Selector.Number) {
    return <FormatterNumber {...props} style={{ color: isError ? "red" : "unset", fontWeight: isError ? "bold" : "normal" }} />;
  } else if (selector == Selector.Number2Decimals) {
    return <FormatterNumber2Decimals {...props} style={{ color: isError ? "red" : "unset", fontWeight: isError ? "bold" : "normal" }} />;
  }
  return <div style={{ color: isError ? "red" : "unset", fontWeight: isError ? "bold" : "normal" }}>{row[column.key]}</div>;
};

export const GridColumnsEdiDetail = (data, filters, arrangement, columnsWidth, section) => {
  let postValue = "";

  if (data) {
    if (currentSpaceSelected) {
      postValue = " ";
    }
    currentSpaceSelected = !currentSpaceSelected;
  }

  const columns = [
    {
      key: "edicomparisonerror",
      name: "#E" + postValue,
      width: cellWidthSmallX,
      formatter: ({ row, column }) => ErrorCellFormatter(row, column),
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"edicomparisonerror"} data={data} section={"edifilecomparisoncurrent"} />;
      },
    },
    {
      key: "Barcode",
      name: "Barcode" + postValue,
      width: cellWidthMediumX,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Barcode"} data={data} section={section} />;
      },
      formatter: CustomCell,
      summaryFormatter: SummaryTotal,
    },
    {
      key: "NoCartons",
      name: "No Cartons" + postValue,
      width: cellWidthSmall,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"NoCartons"} data={data} section={section} />;
      },
      formatter: ({ row, column }) => <CustomCell row={row} column={column} selector={Selector.Number} />,
      summaryFormatter: SummaryNumber,
    },
    {
      key: "PalletSize",
      name: "Pallet Size" + postValue,
      width: cellWidthSmall,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"PalletSize"} data={data} section={section} />;
      },
      formatter: ({ row, column }) => <CustomCell row={row} column={column} selector={Selector.Number2Decimals} />,
      summaryFormatter: SummaryNumber2Decimal,
    },
    {
      key: "CommodityCode",
      name: "Commodity Code" + postValue,
      width: cellWidthMedium,
      formatter: CustomCell,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"CommodityCode"} data={data} section={section} />;
      },
    },
    {
      key: "VarietyCode",
      name: "Variety Code" + postValue,
      width: cellWidthSmall,
      formatter: CustomCell,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"VarietyCode"} data={data} section={section} />;
      },
    },
    {
      key: "PackCode",
      name: "Pack Code" + postValue,
      width: cellWidthSmall,
      formatter: CustomCell,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"PackCode"} data={data} section={section} />;
      },
    },
    {
      key: "MarkCode",
      name: "Mark Code" + postValue,
      width: cellWidthSmall,
      formatter: CustomCell,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"MarkCode"} data={data} section={section} />;
      },
    },
    {
      key: "GradeCode",
      name: "Grade Code" + postValue,
      width: cellWidthSmall,
      formatter: CustomCell,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"GradeCode"} data={data} section={section} />;
      },
    },
    {
      key: "CountCode",
      name: "Count Code" + postValue,
      width: cellWidthSmall,
      formatter: CustomCell,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"CountCode"} data={data} section={section} />;
      },
    },
    {
      key: "InventoryCode",
      name: "Inventory Code" + postValue,
      width: cellWidthMedium,
      formatter: CustomCell,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"InventoryCode"} data={data} section={section} />;
      },
    },
    {
      key: "PickingRef",
      name: "Picking Ref" + postValue,
      width: cellWidthMedium,
      formatter: CustomCell,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"PickingRef"} data={data} section={section} />;
      },
    },
    {
      key: "Waybill",
      name: "Waybill" + postValue,
      width: cellWidthMedium,
      filterable: true,
      formatter: CustomCell,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Waybill"} data={data} section={section} />;
      },
    },
  ].map((c) => ({ ...c, ...defaultColumnProperties }));

  let columnsArranged = [];
  columnsArranged = handleColumnsArrangement(columnsArranged, arrangement, columns);
  columnsArranged = handleColumnsWidth(columnsArranged, columnsWidth, columns);
  return columnsArranged;
};
