import { piorder, getNextAvailableOrderNumber } from "../lib/api/piorder";
import { piinstructions, FindByOrderId } from "../lib/api/piinstruction";

// REFACTORRRRRRR!!!!!!
export const copyOrder = async (copyid, maxsequence = 0, skipdetail = false, groupnumber = undefined) => {
  const id = copyid;
  //copy order
  return new Promise((resolve, reject) => {
    piorder.single(id).then((data) => {
      data = CleanUpOrderObject(data);

      const exchange = JSON.parse(data.exchange);
      const orderData = {
        data: {
          ...data,
          revision: 1,
          locked: false,
          exchange: exchange,
          groupnumber: groupnumber ? groupnumber : data.groupnumber,
          groupsequence: !groupnumber && data.groupnumber && data.groupnumber.length > 0 ? maxsequence + 1 : 0,
        },
      };

      orderData.data.exchange = JSON.stringify(orderData.data.exchange);

      piorder.create(orderData).then((resultCreate) => {
        if (resultCreate.name && resultCreate.name.toLowerCase() == "error") {
          reject("Order could not be copied");
        } else {
          const maxid = resultCreate[0];
          if (skipdetail) {
            resolve(maxid);
            return;
          }
          FindByOrderId(id).then((data) => {
            const promissesArr = [];

            data.map((item) => {
              promissesArr.push(
                new Promise((resolve, reject) => {
                  item["piorder_id"] = maxid;
                  delete item["id"];
                  item = CleanUpInstructionObject(item);

                  piinstructions.create({ data: { ...item } }).then(() => {
                    resolve("ok");
                  });
                }),
              );
            });
            Promise.all(promissesArr).then((result) => {
              resolve(maxid);
              return;
            });
          });
        }
      });
    });
  });
};

export const getNextOrderNumber = async (weeks, varieties, f, w, v, orderid) => {
  // add order number
  let ordernum = f.toString().toUpperCase().replace("BASE", "");

  // update the order number according to specs
  const resultWeek = weeks.find((wk) => wk.id == w);
  if (resultWeek) {
    ordernum = resultWeek.week.replace("2019", "19").replace(" ", "") + ordernum;
  }

  const varietyExist = varieties.find((vary) => vary.id == v);
  if (varietyExist) {
    ordernum += varietyExist.code;
  }

  await getNextAvailableOrderNumber(ordernum).then((orders) => {
    if (orders.data && orders.data.length > 0) {
      const currentNumber = Number(orders.data[0].ordernum.slice(-1)) + (orderid && orderid == orders.data[0].id ? 0 : 1);
      ordernum += currentNumber.toString();
    } else {
      ordernum += "1";
    }
  });

  return ordernum;
};

// // update the order number according to specs
// const farmFirst3 = orderData.data.farm.toString().substring(0, 3);
// const resultWeek = this.state.weeks.find((wk) => wk.id == orderData.data.week);
// const varietyExist = this.state.varieties.find((vary) => vary.id == orderData.data.variety);
// if (farmFirst3 && resultWeek && varietyExist) {
//   const newOrderNumber = resultWeek.week.replace("2019", "19").replace(" ", "") + farmFirst3 + varietyExist.code + orderData.data.revision;
//   orderData.data.ordernum = newOrderNumber;
// }

export const CleanUpOrderObject = (objToClean) => {
  delete objToClean["id"];
  delete objToClean["order_id"];
  delete objToClean["isDirty"];
  delete objToClean["region"];
  delete objToClean["market"];
  delete objToClean["country"];
  delete objToClean["currency"];
  delete objToClean["costestimation_id"];
  delete objToClean["description"];
  delete objToClean["printdate"];
  delete objToClean["port_id"];

  return objToClean;
};

export const CleanUpInstructionObject = (objToClean) => {
  delete objToClean["instruction_id"];
  delete objToClean["costestimation_id"];
  delete objToClean["region"];
  delete objToClean["market"];
  delete objToClean["country"];
  delete objToClean["currency"];
  delete objToClean["description"];
  delete objToClean["carton"];
  delete objToClean["code"];
  delete objToClean["palletspec_name"];
  delete objToClean["inventory_description"];
  delete objToClean["inventory_code"];
  delete objToClean["palletspec_code"];
  delete objToClean["palletspec_carton"];
  delete objToClean["port_id"];
  return objToClean;
};
