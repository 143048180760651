import { apiHeaders, client, request } from "./_base";

const endpoint = "coldroom";

export const coldrooms = client(endpoint, (item) => ({
  ...item,
}));

export const getAllColdrooms = async () => {
  const result = await request(`${endpoint}/ext/full`, {
    headers: apiHeaders(),
  });
  const resp = await result.json();
  if (result.status == 500) {
    throw resp;
  }
  return resp.data;
};

export const getAllActiveColdrooms = async () => {
  const result = await request(`${endpoint}/ext/active_coldrooms`, {
    headers: apiHeaders(),
  });
  const resp = await result.json();
  if (result.status == 500) {
    throw resp;
  }
  return resp.data;
};

export const getColdroomReadyForCombo = async () => {
  const result = await coldrooms.all();
  return result
    .sort((a, b) => {
      if (a.code > b.code) return 1;
      if (a.code < b.code) return -1;
      return 1;
    })
    .map((coldroom) => ({ value: coldroom.id, display: coldroom.code, data: coldroom }));
};

export const coldroomsAllSortedMappedforCombo = async () => {
  const result = await coldrooms.all();
  return result
    .sort((a, b) => {
      if (a.code > b.code) return 1;
      if (a.code < b.code) return -1;
      return 1;
    })
    .map((item) => ({ id: item.id, value: `${item.code}` }));
};
