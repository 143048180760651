import { client, request, apiHeaders } from "./_base";

const endpoint = "vessel";
export const vessel = client(endpoint, (item) => ({
  ...item,
}));

export const getVesselByCode = async (code: string): Promise<{ id: number }[]> => {
  const result = await request(`${endpoint}/ex/byCode/${code}`, {
    headers: apiHeaders(),
  });
  const resp = await result.json();
  if (result.status == 500) {
    throw resp;
  }
  return resp.data;
};

export const getVesselReadyForCombo = () => {
  return vessel.all().then((result) => {
    return result.sort((a, b) => a.description.localeCompare(b.description)).map((ves) => ({ value: ves.id, display: ves.description + " (" + ves.code + ")", data: ves }));
  });
};

export const getVesselReadyForComboCodeOnly = () => {
  return vessel.all().then((result) => {
    return result.sort((a, b) => a.description.localeCompare(b.description)).map((ves) => ({ value: ves.id, display: ves.code, data: ves }));
  });
};

export const getVesselReadyForComboIdValue = async () => {
  const result = await vessel.all();
  return result
    .sort((a, b) => {
      if (a.description > b.description) return 1;
      if (a.description < b.description) return -1;
      return 1;
    })
    .map((ves) => ({ id: ves.id, value: `${ves.description} (${ves.code})`, code: ves.code, data: ves }));
};
