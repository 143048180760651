import React, { useEffect } from "react";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";

import CircularProgress from "@material-ui/core/CircularProgress";

import { getProducersFullGroupedByProducerOnly, ProducersFullGroupedOnlyType } from "../../../lib/api/producersfull";

import ProducersTable from "./producerstable";

import { SnackContext } from "../../../lib/context/SnackContext";
import { getFinancialYearSelected } from "../../../lib/api/week";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      overflow: "hidden",
      width: "100%",
      height: "calc(100% - 65px)",
    },
  });

type ProducersGroupedProps = { match: any } & WithStyles<typeof styles>;

const ProducersGroupedUnstyled: React.FunctionComponent<ProducersGroupedProps> = ({ children, classes, match }) => {
  const [data, setData] = React.useState([]);
  const [loading, setLoading] = React.useState(true);

  const { updateSnack } = React.useContext(SnackContext);

  useEffect(() => {
    loadData().then(() => {
      setLoading(false);
    });
  }, []);

  const loadData = () => {
    setLoading(true);
    return getProducersFullGroupedByProducerOnly(getFinancialYearSelected())
      .then((result: [ProducersFullGroupedOnlyType]) => {
        if (result) {
          const dataSorted = result.sort((a, b) => a.producer_name.localeCompare(b.producer_name));
          setData(dataSorted);
        }
      })
      .catch((err) => {
        updateSnack({ show: true, message: err, color: "red" });
      });
  };

  return (
    <div className={classes.root}>
      {loading && <CircularProgress />}
      {loading == false && (
        <ProducersTable
          data={data}
          onReload={() => {
            loadData().then(() => {
              setLoading(false);
            });
          }}
        />
      )}
    </div>
  );
};

export default withStyles(styles)(ProducersGroupedUnstyled);
