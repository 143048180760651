export const SaleAdjustmentApplyType = {
  1: "INVOICE",
  2: "CARTON",
};

export const getSaleAdjustmentApplyTypeByValue = (value: string) => {};

export const getSaleAdjustmentApplyTypeReadyForCombo = () => {
  const result = [];
  Object.keys(SaleAdjustmentApplyType).map((st) => {
    result.push({ value: st, display: SaleAdjustmentApplyType[st] });
  });
  return result;
};
