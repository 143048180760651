import React, { useEffect } from "react";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import Settings from "@material-ui/icons/Settings";
import MenuItem from "@material-ui/core/MenuItem";
import LinearProgress from "@material-ui/core/LinearProgress";
import Grid from "@material-ui/core/Grid";

import { Form, Field } from "react-final-form";
import { TextField, Select } from "final-form-material-ui";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { whoami } from "../lib/api/_base";
import { getPortReadyForCombo } from "../lib/api/port";
import { getVesselReadyForCombo } from "../lib/api/vessel";
import { getShippingLineReadyForCombo } from "../lib/api/shippingline";
import { getColdroomReadyForCombo } from "../lib/api/coldroom";
import { getAgentsReadyForCombo } from "../lib/api/agent";
import { getTemperatureReadyForCombo } from "../lib/api/temperaturecodes";

import ReactDatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import en from "date-fns/esm/locale/en-GB";
registerLocale("en-GB", en);

import parseISO from "date-fns/parseISO";
import toDate from "date-fns/toDate";
import { isNullOrUndefined } from "util";
import Confirmation from "../lib/components/confirmation";
import Vessel from "../maintenance/vessel/vessel";

const styles = (theme: Theme) =>
  createStyles({
    root: {},
    tableRows: {
      margin: theme.spacing(2),
    },
    tableCellsDesc: {
      paddingRight: theme.spacing(2),
      paddingTop: theme.spacing(2),
      height: "30px",
      width: "300px",
      textAlign: "right",
    },
    tableCellsValue: {
      width: "250px",
      textAlign: "left",
      marginTop: theme.spacing(1),
    },
    tableCellsValueText: {
      width: "250px",
      textAlign: "left",
    },
    tableCellsValueSelect: {
      textAlign: "left",
      width: "250px",
    },
    specialInstructionTable: {
      marginTop: theme.spacing(3),
    },
    error: {
      color: "#f44336",
      margin: 0,
      fontSize: "0.75rem",
      marginTop: "8px",
      minHeight: "1em",
      textAlign: "left",
      fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
      fontWeight: 400,
      lineHeight: "1em",
      letterSpacing: "0.03333em",
      paddingTop: "10px",
      marginBottom: "11px",
      borderTop: "2px solid #FF0000",
    },
  });

type AcceptAllocationDialogProps = {
  isOpen: any;
  handleClose: any;
  onSubmit: any;
  defaultData: any;
  handleChangedDispatchRequested: any;
  hasForwardedRef?: boolean;
} & WithStyles<typeof styles>;

const ZA = "ZA";
const EU = "EU";
const UK = "UK";
export class AcceptAllocationDialogUnstyled extends React.Component<AcceptAllocationDialogProps, any> {
  state = {
    isOpen: undefined,
    handleClose: undefined,
    onSubmit: undefined,
    portsZA: undefined,
    ports: undefined,
    vessels: undefined,
    shippingLines: undefined,
    coldrooms: undefined,
    agents: undefined,
    temperaturecodes: undefined,
    userDetail: undefined,
    classes: undefined,
    defaultData: undefined,
    loading: true,
    phytcleanCompulsory: false,
    handleChangedDispatchRequested: undefined,
    hasForwardedRef: false,
    formRecord: {
      reference: "",
      exportNotificationReference: "",
      portLoading_id: undefined,
      portDischarge_id: undefined,
      portFinal_id: undefined,
      shipping_id: undefined,
      vessel_id: undefined,
      voyageNumber: "",
      username: "",
      client: "",
      specialinstruction: "",
      coldroom_id: undefined,
      agent_id: undefined,
      phytclean: "",
      temp: "",
      loadout_ref: "",
    },
  };

  constructor(props) {
    super(props);
    this.state.isOpen = props.isOpen;
    this.state.handleClose = props.handleClose;
    this.state.onSubmit = props.onSubmit;
    this.state.classes = props.classes;
    this.state.defaultData = props.defaultData;
    this.state.handleChangedDispatchRequested = props.handleChangedDispatchRequested;
    this.state.hasForwardedRef = props.hasForwardedRef;

    if (this.state.defaultData.consigneeId && this.state.defaultData.consigneeId.length > 0) {
      this.state.formRecord = {
        ...this.state.formRecord,
        client: this.state.defaultData.consigneeName,
        temp: this.state.defaultData.temp,
        loadout_ref: this.state.defaultData.loadout_reference,
        vessel_id: this.state.defaultData.loadout_vessel_id,
      };
    }

    if (this.state.defaultData.loadout_id) {
      this.state.formRecord = {
        ...this.state.formRecord,
        exportNotificationReference: this.state.defaultData.loadout_exportNotificationReference,
        portLoading_id: this.state.defaultData.loadout_portLoading_id,
        portDischarge_id: this.state.defaultData.loadout_portDischarge_id,
        portFinal_id: this.state.defaultData.loadout_portFinal_id,
        shipping_id: this.state.defaultData.loadout_shippingline_id,
        vessel_id: this.state.defaultData.loadout_vessel_id,
        voyageNumber: this.state.defaultData.loadout_voyageNumber,
        specialinstruction: this.state.defaultData.loadout_specialinstruction,
        coldroom_id: this.state.defaultData.loadout_coldroom_id,
        agent_id: this.state.defaultData.loadout_agent_id == 0 ? undefined : this.state.defaultData.loadout_agent_id,
        phytclean: this.state.defaultData.loadout_phytclean,
        temp: this.state.defaultData.temp,
        loadout_ref: this.state.defaultData.loadout_reference,
      };
    }
  }

  componentDidMount() {
    this.getUser().then(() => {
      this.loadData().then(() => {
        this.setState({ loading: false });
      });
    });
  }

  getUser = async () => {
    await whoami().then((data) => {
      this.setState({ userDetail: data, formRecord: { ...this.state.formRecord, username: data.username } }, () => {});
    });
  };

  loadData = () => {
    return Promise.all([
      getPortReadyForCombo(),
      getVesselReadyForCombo(),
      getShippingLineReadyForCombo(),
      getColdroomReadyForCombo(),
      getAgentsReadyForCombo(),
      getTemperatureReadyForCombo(),
      getPortReadyForCombo(ZA),
    ]).then((result) => {
      if (isNullOrUndefined(this.state.defaultData.loadout_portDischarge_id) || this.state.defaultData.loadout_portDischarge_id == 0) {
        const portFilter = result[0].find((port) => port.data.code == this.state.defaultData.pod);
        const phytcleanCheckComp = portFilter && portFilter.data ? portFilter.data.market == EU || portFilter.data.market == UK : false;

        const newFormRecord = {
          ...this.state.formRecord,
          portDischarge_id: portFilter ? portFilter.value : undefined,
          portFinal_id: portFilter ? portFilter.value : undefined,
          phytclean: isNullOrUndefined(this.state.defaultData.loadout_id) ? (!phytcleanCheckComp ? "N/A" : "") : this.state.formRecord.phytclean,
          agent_id:
            result[4] && result[4][0]
              ? result[4][0].value
              : !this.state.defaultData.loadout_agent_id || this.state.defaultData.loadout_agent_id == 0
              ? undefined
              : this.state.defaultData.loadout_agent_id,
        };
        this.setState({ formRecord: newFormRecord, phytcleanCompulsory: phytcleanCheckComp });
      }

      this.setState(
        {
          ports: result[0],
          vessels: result[1],
          shippingLines: result[2],
          coldrooms: result[3],
          agents: result[4],
          temperaturecodes: result[5],
          portsZA: result[6],
        },
        () => {},
      );
    });
  };

  UpdateVessels = async () => {
    await getVesselReadyForCombo().then((result) => {
      this.setState({ vessels: result });
    });
  };

  render() {
    return (
      <Dialog fullScreen={false} open={this.state.isOpen} onClose={this.state.handleClose} maxWidth={false}>
        <DialogTitle id="responsive-dialog-title">
          <div>
            <span style={{ float: "left" }}>{"CREATE LOADOUT"}</span>
            <span style={{ float: "right" }}>{this.state.formRecord.username}</span>
          </div>
        </DialogTitle>
        <DialogContent style={{ marginRight: "15px" }}>
          {this.state.loading ? (
            <LinearProgress />
          ) : (
            <AcceptAllocation
              onSubmit={this.state.onSubmit}
              handleClose={this.state.handleClose}
              formRecord={this.state.formRecord}
              classes={this.state.classes}
              defaultData={this.state.defaultData}
              ports={this.state.ports}
              portsZA={this.state.portsZA}
              vessels={this.state.vessels}
              shippingLines={this.state.shippingLines}
              coldrooms={this.state.coldrooms}
              agents={this.state.agents}
              phytcleancompulsory={this.state.phytcleanCompulsory}
              temperaturecodes={this.state.temperaturecodes}
              getVessels={this.UpdateVessels}
              handleChangedDispatchRequested={this.state.handleChangedDispatchRequested}
              hasForwardedRef={this.state.hasForwardedRef}
            />
          )}
        </DialogContent>
      </Dialog>
    );
  }
}

const AcceptAllocation = ({
  onSubmit,
  handleClose,
  formRecord,
  classes,
  defaultData,
  portsZA,
  ports,
  vessels,
  getVessels,
  shippingLines,
  coldrooms,
  agents,
  phytcleancompulsory,
  temperaturecodes,
  handleChangedDispatchRequested,
  hasForwardedRef,
}) => {
  const [vesselMaintenanceOpen, setVesselMaintenanceOpen] = React.useState(false);
  const [dateLoading, setDateLoading] = React.useState(defaultData.loadout_id ? toDate(parseISO(defaultData.loadout_dateLoading)) : new Date());
  const [dateETD, setDateETD] = React.useState(defaultData.loadout_id ? toDate(parseISO(defaultData.loadout_etd)) : new Date());
  const [dateETA, setDateETA] = React.useState(defaultData.loadout_id ? toDate(parseISO(defaultData.loadout_eta)) : new Date());
  const [openDispatchRequested, setOpenDispatchRequested] = React.useState(false);
  useEffect(() => {});

  const changeDate = (value) => {
    setDateLoading(value);
  };

  const changeDateETD = (value) => {
    setDateETD(value);
  };

  const changeDateETA = (value) => {
    setDateETA(value);
  };

  const CalenderCustomInput = React.forwardRef((props: any, ref: any) => {
    return (
      <Button name="CalenderCustomInput" variant="contained" color="primary" onClick={props.onClick} style={{ marginTop: "5px", width: "250px" }}>
        {props.value}
      </Button>
    );
  });

  const handleEditDone = () => {
    const elements = document.getElementsByClassName("react-grid-Toolbar");
    for (let index = 0; index < elements.length; index++) {
      const element = elements[index] as HTMLElement;
      element.style.display = "initial";
    }

    setVesselMaintenanceOpen(false);
    getVessels();
  };

  const handleChangedDispatchOption = () => {
    setOpenDispatchRequested(!openDispatchRequested);
    handleChangedDispatchRequested();
  };

  return (
    <Form
      initialValues={{ ...formRecord }}
      onSubmit={(values) => {
        const submitData = { ...values, dateLoading: dateLoading, etd: dateETD, eta: dateETA };
        onSubmit(submitData);
      }}
      validate={(values) => {
        let errors = {};

        if (phytcleancompulsory) {
          if (!values["phytclean"] || values["phytclean"].toString().trim().length == 0) {
            errors = { ...errors, phytclean: "please add a code" };
          }
        }

        if (!values["coldroom_id"] || values["coldroom_id"] === 0) {
          errors = { ...errors, coldroom_id: "please select a coldroom" };
        }
        if (!values["portDischarge_id"] || values["portDischarge_id"] === 0) {
          errors = { ...errors, portDischarge_id: "please select a Port of Discharge" };
        }
        if (!values["portFinal_id"] || values["portFinal_id"] === 0) {
          errors = { ...errors, portFinal_id: "please select a Final Port" };
        }
        if (!values["portLoading_id"] || values["portLoading_id"] === 0) {
          errors = { ...errors, portLoading_id: "please select a Port of Loading" };
        }

        if (dateETD < dateLoading) {
          errors = { ...errors, dateETD: "ETD cannot be earlier than the Date of Loading." };
        }
        if (dateETA < dateETD) {
          errors = { ...errors, dateETA: "ETA cannot be earlier than the ETD." };
        }

        return errors;
      }}
      render={({ handleSubmit, form }) => (
        <>
          <form onSubmit={handleSubmit}>
            <table>
              <tbody>
                <tr>
                  <TableFieldLabel classes={classes} label="Client" />
                  <TableFieldText classes={classes} field="client" disabled={true} />

                  <TableFieldLabel classes={classes} label="Date of Loading" />
                  <td>
                    <ReactDatePicker
                      locale="en-GB"
                      showWeekNumbers={true}
                      selected={dateLoading}
                      onChange={(value) => {
                        changeDate(value);
                      }}
                      dateFormat="dd-MM-yyyy"
                      placeholderText="click here to select a date"
                      customInput={<CalenderCustomInput />}
                    />
                    {form.getState().submitFailed && form.getState().errors && form.getState().errors.dateLoading && (
                      <p className={classes.error}>{form.getState().errors.dateLoading}</p>
                    )}
                  </td>
                </tr>
                <tr>
                  <TableFieldLabel classes={classes} label="Load Ref" />
                  <TableFieldText classes={classes} field="loadout_ref" disabled={true} />
                  <TableFieldLabel classes={classes} label="ETD" />
                  <td>
                    <ReactDatePicker
                      locale="en-GB"
                      showWeekNumbers={true}
                      selected={dateETD}
                      onChange={(value) => {
                        changeDateETD(value);
                      }}
                      dateFormat="dd-MM-yyyy"
                      placeholderText="click here to select a date"
                      customInput={<CalenderCustomInput />}
                    />
                    {form.getState().submitFailed && form.getState().errors && form.getState().errors.dateETD && <p className={classes.error}>{form.getState().errors.dateETD}</p>}
                  </td>
                </tr>
                <tr>
                  <TableFieldLabel classes={classes} label="Export Notification Ref" />
                  <TableFieldText classes={classes} field="exportNotificationReference" disabled={false} />
                  <TableFieldLabel classes={classes} label="ETA" />
                  <td>
                    <ReactDatePicker
                      locale="en-GB"
                      showWeekNumbers={true}
                      selected={dateETA}
                      onChange={(value) => {
                        changeDateETA(value);
                      }}
                      dateFormat="dd-MM-yyyy"
                      placeholderText="click here to select a date"
                      customInput={<CalenderCustomInput />}
                    />
                    {form.getState().submitFailed && form.getState().errors && form.getState().errors.dateETA && <p className={classes.error}>{form.getState().errors.dateETA}</p>}
                  </td>
                </tr>
                <tr>
                  <TableFieldLabel classes={classes} label="Port of Loading" />
                  <TableFieldCombo classes={classes} field="portLoading_id" data={portsZA} />
                  <TableFieldLabel classes={classes} label="Coldroom" />
                  <TableFieldCombo classes={classes} field="coldroom_id" data={coldrooms} />
                </tr>
                <tr>
                  <TableFieldLabel classes={classes} label="Port of Discharge" />
                  <TableFieldCombo classes={classes} field="portDischarge_id" data={ports} />
                  <TableFieldLabel classes={classes} label="Forwarding Agent" />
                  <TableFieldCombo classes={classes} field="agent_id" data={agents} />
                </tr>
                <tr>
                  <TableFieldLabel classes={classes} label="Final Destination" />
                  <TableFieldCombo classes={classes} field="portFinal_id" data={ports} />
                  <TableFieldLabel classes={classes} label="Phytclean Code" />
                  <TableFieldText classes={classes} field="phytclean" disabled={false} />
                </tr>
                <tr>
                  <TableFieldLabel classes={classes} label="Shipping Line" />
                  <TableFieldCombo classes={classes} field="shipping_id" data={shippingLines} />
                  <TableFieldLabel classes={classes} label="Temperature" />
                  <TableFieldCombo classes={classes} field="temp" data={temperaturecodes} />
                </tr>
                <tr>
                  <TableFieldLabel classes={classes} label="Vessel" />
                  <TableFieldComboWithButton setConfirmEditID={setVesselMaintenanceOpen} classes={classes} field="vessel_id" data={vessels} />
                </tr>
                <tr>
                  <TableFieldLabel classes={classes} label="Voyage Number" />
                  <TableFieldText classes={classes} field="voyageNumber" disabled={false} />
                </tr>
              </tbody>
            </table>
            <table className={classes.specialInstructionTable}>
              <tbody>
                <tr style={{ width: "900px" }}>
                  <td style={{ width: "900px" }}>
                    <div style={{ paddingTop: "10px" }}>
                      <Field name="specialinstruction" component={TextField} label="Special Instruction" type="text" fullWidth={true} multiline />
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>

            <div style={{ paddingTop: "20px", paddingBottom: "10px", float: "right" }}>
              {!hasForwardedRef && (
                <FormControlLabel
                  label="OPEN DISPATCH"
                  labelPlacement="start"
                  style={{ marginRight: "10px" }}
                  control={<Checkbox checked={openDispatchRequested} onChange={handleChangedDispatchOption} color="primary" />}
                />
              )}

              <Button name="submit" type="submit" variant="contained" color="primary" style={{ margin: "2px" }}>
                Allocate Loadout
              </Button>
              <Button name="close" variant="contained" color="secondary" style={{ margin: "2px" }} onClick={handleClose}>
                Close
              </Button>
            </div>
            {vesselMaintenanceOpen && (
              <Confirmation
                fullscreen={true}
                isOpen={vesselMaintenanceOpen}
                handleClose={handleEditDone}
                handleConfirm={() => {}}
                title={"Loadout - Editing Vessels"}
                body={undefined}
              >
                <Vessel handleOnClose={() => handleEditDone()} />
              </Confirmation>
            )}
          </form>
        </>
      )}
    />
  );
};

export default withStyles(styles)(AcceptAllocationDialogUnstyled);

const TableFieldLabel: React.FunctionComponent<{ classes: any; label: string }> = (props) => {
  const { classes, label } = props;
  return (
    <td key={props.label} className={classes.tableCellsDesc}>
      <label>{label}: </label>
    </td>
  );
};

const TableFieldText: React.FunctionComponent<{ classes: any; field: string; disabled: boolean }> = (props) => {
  const { classes, field, disabled } = props;
  return (
    <td key={field} className={classes.tableCellsValue}>
      <div style={{ marginTop: "1em" }}>
        <Field name={field} component={TextField} type="text" fullWidth={true} disabled={disabled} />
      </div>
    </td>
  );
};

const TableFieldCombo: React.FunctionComponent<{ classes: any; field: string; data: any }> = (props) => {
  const { classes, field, data } = props;
  return (
    <td key={field} className={classes.tableCellsValue}>
      <Field name={field} component={Select} formControlProps={{ className: classes.tableCellsValueSelect }}>
        {data.map((item, index) => {
          return (
            <MenuItem key={`${item.value}${index}`} value={item.value}>
              {item.display}
            </MenuItem>
          );
        })}
      </Field>
    </td>
  );
};

const TableFieldComboWithButton: React.FunctionComponent<{ classes: any; field: string; data: any; setConfirmEditID: any }> = (props) => {
  const { classes, field, data, setConfirmEditID } = props;
  return (
    <td key={field} className={classes.tableCellsValue}>
      <Grid container spacing={1}>
        <Grid container item={true} xs={9}>
          <Field name={field} component={Select} formControlProps={{ className: classes.tableCellsValueSelect }}>
            {data.map((item, index) => {
              return (
                <MenuItem key={`${item.value}${index}`} value={item.value}>
                  {item.display}
                </MenuItem>
              );
            })}
          </Field>
        </Grid>
        <Grid container item={true} xs={3}>
          <Button
            size="small"
            color="primary"
            onClick={() => {
              setConfirmEditID(true);
            }}
          >
            <Settings />
          </Button>
        </Grid>
      </Grid>
    </td>
  );
};
