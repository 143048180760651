import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

export const IconOrder = () => (
  <SvgIcon x="0px" y="0px" viewBox="0 0 482 482">
    <g>
      <g>
        <polygon points="279.8,244.8 258.2,257.3 258.2,482 452.7,369.7 452.7,145 		" />
        <polygon points="315,43.3 240.2,0 40.3,115.4 115.2,158.7 		" />
        <polygon points="440,115.4 353.8,66.3 154,181.7 165.4,187.6 240.2,230.8 314.6,187.9 		" />
        <polygon points="138.9,264.3 103.1,245.9 103.1,188.7 29.3,146.2 29.3,369.3 222.4,480.8 222.4,257.7 138.9,209.6 		" />
      </g>
    </g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </SvgIcon>
);

export const IconBoat = () => (
  <SvgIcon x="0px" y="0px" width="434.02px" height="434.02px" viewBox="0 0 434.02 434.02">
    <g>
      <path
        d="M433.808,73.223c-0.451-1.33-1.563-2.326-2.938-2.625L262.392,33.893c-1.366-0.301-2.786,0.139-3.748,1.15l-52.703,55.392
		l-59.988-54.614c-1.244-1.132-3.066-1.364-4.555-0.582L2.138,108.453c-1.119,0.589-1.895,1.673-2.09,2.923s0.211,2.52,1.1,3.422
		l57.094,58.098l-0.039,188.633c0,1.908,1.346,3.552,3.215,3.925L235,400.144c0.262,0.051,0.523,0.076,0.785,0.076
		c0.916,0,1.813-0.313,2.535-0.906c0.928-0.76,1.465-1.895,1.465-3.094V217.047c0-1.912-1.354-3.557-3.229-3.926l-24.969-4.912
		V103.136l162.807,39.354l-120.891,68.711c-1.252,0.711-2.022,2.039-2.022,3.477v176.769c0,1.413,0.745,2.725,1.965,3.442
		c0.627,0.371,1.33,0.558,2.035,0.558c0.664,0,1.327-0.166,1.928-0.496l125.762-69.238c1.277-0.703,2.07-2.045,2.07-3.502
		l0.062-188.752l47.771-56.365C433.978,76.022,434.259,74.553,433.808,73.223z M201.111,206.145l-130.867-25.76l130.867-74.871
		V206.145z M351.026,252.156c-0.123,1.27-0.858,2.397-1.971,3.021l-10.518,5.908l-0.168,41.555c-0.006,1.404-0.767,2.695-1.99,3.384
		l-20.115,11.293c-0.592,0.332-1.25,0.498-1.908,0.498c-0.684,0-1.366-0.181-1.977-0.537c-1.195-0.703-1.928-1.99-1.922-3.377
		l0.149-37.136l-6.854,3.849c-1.496,0.84-3.371,0.602-4.609-0.59c-1.239-1.189-1.553-3.054-0.771-4.582l22.776-44.621
		c0.543-1.063,1.541-1.818,2.712-2.052c1.17-0.232,2.383,0.082,3.29,0.855l22.521,19.188
		C350.646,249.639,351.149,250.889,351.026,252.156z"
      />
    </g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </SvgIcon>
);

export const IconDispatch = () => (
  <SvgIcon x="0px" y="0px" width="612px" height="612px" viewBox="0 0 612 612">
    <g>
      <path
        d="M12.57,385.084h316.849c4.735,0,8.61-3.875,8.61-8.609V119.809c0-14.266-11.564-25.83-25.83-25.83H29.791
		c-14.266,0-25.83,11.564-25.83,25.83v256.664C3.96,381.209,7.835,385.084,12.57,385.084z M76.417,212.128
		c0-5.902,4.784-10.687,10.687-10.687h84.528v-30.118c0-9.521,11.514-14.289,18.245-7.554l72.838,72.873
		c4.171,4.173,4.171,10.938,0,15.11l-72.838,72.861c-6.732,6.734-18.245,1.967-18.245-7.555v-30.125H87.103
		c-5.902,0-10.687-4.785-10.687-10.687V212.128z M113.48,454.221H8.61c-4.736,0-8.61-3.873-8.61-8.609v-26.174
		c0-4.736,3.874-8.611,8.61-8.611h128.634C124.673,421.762,115.977,437.002,113.48,454.221z M603.39,410.828h-20.922V293.817
		c0-13.689-4.133-27.121-11.796-38.486l-52.434-77.663c-12.83-18.942-34.183-30.307-57.085-30.307h-81.278
		c-9.558,0-17.221,7.749-17.221,17.22v246.247H229.543c12.657,10.934,21.353,26.174,23.85,43.395h140.343
		c4.994-34.182,34.44-60.615,70-60.615s64.919,26.434,69.913,60.615h69.741c4.736,0,8.61-3.875,8.61-8.611v-26.174
		C612,414.703,608.126,410.828,603.39,410.828z M517.634,270.828h-107.28c-4.735,0-8.61-3.789-8.61-8.61v-59.495
		c0-4.735,3.875-8.61,8.61-8.61h65.351c2.841,0,5.424,1.377,7.06,3.616l41.931,59.582
		C528.654,262.994,524.608,270.828,517.634,270.828z M183.394,410.828c-29.532,0-53.555,24.021-53.555,53.641
		c0,29.531,24.022,53.553,53.555,53.553c29.618,0,53.554-24.021,53.554-53.553C236.947,434.85,213.012,410.828,183.394,410.828z
		 M183.394,491.246c-14.81,0-26.777-12.055-26.777-26.777c0-14.811,11.968-26.777,26.777-26.777
		c14.809,0,26.777,11.967,26.777,26.777C210.171,479.191,198.202,491.246,183.394,491.246z M463.735,410.828
		c-29.618,0-53.555,24.021-53.555,53.641c0,29.531,23.937,53.553,53.555,53.553c29.532,0,53.554-24.021,53.554-53.553
		C517.29,434.85,493.269,410.828,463.735,410.828z M463.735,491.246c-14.81,0-26.777-12.055-26.777-26.777
		c0-14.811,11.968-26.777,26.777-26.777c14.809,0,26.777,11.967,26.777,26.777C490.513,479.191,478.545,491.246,463.735,491.246z"
      />
    </g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </SvgIcon>
);
