import { client, request, apiHeaders } from "./_base";

const endpoint = "finance";

export type FinanceType = {
  sale_id: number;
  sale_dispatch_id: number;
  dispatchdocs_id: number;
  sale_date: Date;
  sale_invoicenumber: string;
  sale_ucr: string;
  sale_trader_id: number;
  users_username: string;
  sale_terms_id: number;
  terms_code: string;
  sale_ata: Date;
  sale_notifyparty_id: number;
  clearingagentnotify_name: string;
  sale_payment1amount: number;
  sale_payment1amount_remain: number;
  sale_payment1date: Date;
  sale_payment2amount: number;
  sale_payment2amount_remain: number;
  sale_payment2date: Date;
  sale_payment3amount: number;
  sale_payment3amount_remain: number;
  sale_payment3date: Date;
  sale_created_at: Date;
  sale_updated_at: Date;
  sale_exchangerate: number;
  sale_type: number;
  sale_clients_id: number;
  clients_code: string;
  clients_name: string;
  sale_total: number;
  sale_totalfinal: number;
  sale_typename: string;
  salefundsallocation_amount: number;
  total_outstanding: number;
  document_type: string;
  document_typeapply: number;
  document_sourcetotal: number;
  dispatch_currency_id: number;
  currency_code: string;
};

export const finance = client(endpoint, (item) => ({
  ...item,
}));

export const getFinanceAll = async (financial_year: number) => {
  return request(`${endpoint}/ext/getFinanceAll/${financial_year}`, {
    headers: apiHeaders(),
    method: "GET",
  }).then(async (result) => {
    if (result.status == 500) {
      const resp = await result.json();
      throw resp;
    }
    return result.json();
  });
};

export const getFinanceSummary = async (sale_invoicenumber: string, financial_year: number) => {
  return request(`${endpoint}/ext/getFinanceSummary?sale_invoicenumber=${sale_invoicenumber}&financial_year=${financial_year}`, {
    headers: apiHeaders(),
    method: "GET",
  }).then(async (result) => {
    if (result.status == 500) {
      const resp = await result.json();
      throw resp;
    }
    return result.json();
  });
};
