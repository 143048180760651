import { client, request } from "./_base";
import { SaleAdjustmentDetailType } from "./saleadjustmentdetail";

const endpoint = "saleadjustment";

export type SaleAdjustmentType = {
  id?: number;
  sale_id?: number;
  adjustdate?: Date;
  type?: number;
  typeapply?: number;
  status?: number;
  is_claim?: number;
  paymentnumber?: number;
  created_at?: Date;
  updated_at?: Date;
  ident?: number;
  user_id?: number;
};

export const saleadjustment = client(endpoint, (item) => ({
  ...item,
}));

type UpsertSaleAdjustment = {
  sale: { sale_id: number; accsalereceived: Date };
  saleAdjustment: SaleAdjustmentType;
  saleAdjustmentDetail: SaleAdjustmentDetailType[];
};

export const upsertSaleAdjustment = async (data: UpsertSaleAdjustment): Promise<void> => {
  console.log("data", data);
  const result = await request(`${endpoint}/ext/upsertSaleAdjustment`, {
    method: "POST",
    body: JSON.stringify(data),
  });
  if (result.status == 500) {
    const resp = await result.json();
    throw resp;
  }
};
