import React, { useEffect, forwardRef, useState, useRef } from "react";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";

import LinearProgress from "@material-ui/core/LinearProgress";
import Button from "@material-ui/core/Button";
import MTextField from "@material-ui/core/TextField";
import TextField from "@material-ui/core/TextField";

import { Field, Form } from "react-final-form";

import numeral from "numeral";
import ReactDatePicker, { registerLocale } from "react-datepicker";
registerLocale("en-GB", en);
import toDate from "date-fns/toDate";
import parseISO from "date-fns/parseISO";
import format from "date-fns/format";
import en from "date-fns/esm/locale/en-GB";

import { SnackContext } from "../../lib/context/SnackContext";

import { SaleFundsFullType } from "../../lib/api/salefunds";
import { getClientsAllSortedMappedforComboID } from "../../lib/api/clients";
import { clientsfinanceByClientID, ClientsFinanceType } from "../../lib/api/clientsfinance";
import { getCurrencyAllSortedMappedforCombo } from "../../lib/api/currency";

import Autocomplete from "@material-ui/lab/Autocomplete";
import BigNumber from "bignumber.js";
import { FormApi } from "final-form";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1),
      width: "600px",
      height: "100%",
    },
    divActions: {
      float: "right",
    },
    container: {
      paddingTop: "10px",
      display: "grid",
      gridTemplateColumns: "repeat(1, 150px 600px)",
      gridTemplateRows: "repeat(3, 40px)",
      gridTemplateAreas: `
      "details"
      `,
      gridGap: "10px",
    },
    tableCellLabel: {
      width: "150px",
      borderBottom: "none",
      height: "50px",
      textAlign: "right",
      paddingTop: "5px",
    },
    tableCellLabelValue: {
      width: "500px",
      borderBottom: "none",
      height: "50px",
      textAlign: "left",
      paddingTop: "5px",
      fontSize: "medium",
    },
    tableCellDetail: {
      width: "500px",
      borderBottom: "none",
      height: "50px",
    },
    tableCellsValueSelect: {
      textAlign: "left",
      width: "500px",
      borderBottom: "none",
      height: "50px",
      padding: 0,
      marginTop: "-14px",
    },
    tableCellDetailDate: {
      marginTop: "-5px",
      width: "500px",
      borderBottom: "none",
      height: "50px",
    },
  });

type FundsEditFormProps = {
  selectedRecord: SaleFundsFullType;
  handleProcess: (data) => void;
} & WithStyles<typeof styles>;

const AdjustmentUnstyled: React.FunctionComponent<FundsEditFormProps> = (props) => {
  const { classes, selectedRecord, handleProcess } = props;

  const [loading, setLoading] = useState(true);
  const [posteddate, setPosteddate] = useState(selectedRecord.id != 0 ? parseISO(selectedRecord.posteddate.toString()) : new Date());
  const [clients, setClients] = useState([]);
  const [currencies, setCurrencies] = useState([]);

  const { updateSnack } = React.useContext(SnackContext);

  const fieldAmountRef = useRef(null);
  const fieldNotesRef = useRef(null);

  const loadData = async () => {
    setLoading(true);
    const [clientsResult, currenciesResult] = await Promise.all([getClientsAllSortedMappedforComboID(), getCurrencyAllSortedMappedforCombo()]);
    setClients(clientsResult);
    setCurrencies(currenciesResult);
    setLoading(false);
  };

  useEffect(() => {
    loadData();
  }, []);

  const onParseClient = (form: FormApi, value: number) => {
    clientsfinanceByClientID(value)
      .then((result: { data: [ClientsFinanceType] }) => {
        if (result && result.data.length > 0 && !selectedRecord.salefundsadhoc_id) {
          form.change("currency_id", result.data[0].currency_id);
        }
      })
      .catch((err) => {
        updateSnack({ show: true, message: "Error getting client finances.", color: "red" });
      });
  };

  const handleFormatAmount = (value: any) => {
    if (!value) {
      return "0";
    }
    return value.toString().replace(/,/g, "");
  };

  const onParseAmount = (form: FormApi, value: string) => {
    const formattedValue = handleFormatAmount(value);
    const formattedBankCharges = handleFormatAmount(form.getState().values.bankcharges);

    const total = new BigNumber(formattedValue).minus(new BigNumber(formattedBankCharges)).toNumber();
    form.change("totalamount", numeral(total).format("0,0.00"));
    return value;
  };

  const onParseEmpty = (form: FormApi, value: string) => {
    const formattedAmount = handleFormatAmount(form.getState().values.amount);
    const formattedBankCharges = handleFormatAmount(form.getState().values.bankcharges);

    const total = new BigNumber(formattedAmount).minus(new BigNumber(formattedBankCharges)).toNumber();
    form.change("totalamount", numeral(total).format("0,0.00"));
    return value;
  };

  const onChangePostedDate = (form: FormApi, value) => {
    setPosteddate(value);
    form.change("posteddate", toDate(value));
    setPosteddate(toDate(value));
  };

  const formatOther = (value) =>
    value === undefined
      ? "" // make controlled
      : value;

  const formatPrice = (value) =>
    value === undefined
      ? "" // make controlled
      : numeral(value).format("0,0.00");

  const handleKeyDown = (event) => (ref) => {
    if (event.key == "Enter") {
      event.preventDefault();
      try {
        ref.current.children[0].children[0].focus();
      } catch (error) {
        updateSnack({ show: true, message: "Error selecting next field. Please try again", color: "red" });
      }
    }
  };

  return (
    <div className={classes.root}>
      {loading ? (
        <LinearProgress />
      ) : (
        <div>
          <Form
            initialValues={{
              salefundsadhoc_id: selectedRecord.salefundsadhoc_id,
              clients_id: selectedRecord.clients_id,
              amount: selectedRecord.amount,
              currency_id: selectedRecord.currency_id,
              makeanote: selectedRecord.makeanote,
              posteddate: selectedRecord.posteddate ? format(parseISO(selectedRecord.posteddate.toString()), "dd-MMM-yyyy") : new Date(),
            }}
            onSubmit={(values) => {
              handleProcess({ ...values, posteddate });
            }}
            render={({ handleSubmit, form }) => (
              <form onSubmit={handleSubmit}>
                <div style={{ paddingBottom: "10px" }}>
                  <Button name="submit" type="submit" variant="contained" color="primary" style={{ margin: "2px" }} disabled={false}>
                    Process
                  </Button>
                  <Button name="close" variant="contained" color="secondary" style={{ margin: "2px" }} disabled={false} onClick={() => handleProcess(undefined)}>
                    Close
                  </Button>
                </div>
                <div className={classes.container}>
                  <TableFieldCombo classes={classes} form={form} field="clients_id" title="Client" data={clients} addEmptyValue={undefined} onParse={onParseClient} />
                  <TableFieldDate classes={classes} field="posteddate" dateValue={posteddate} title="Date Received" changeDate={(value) => onChangePostedDate(form, value)} />
                  <TableFieldCombo classes={classes} form={form} field="currency_id" title="Currency" data={currencies} addEmptyValue={undefined} onParse={() => {}} />
                  <TableFieldText
                    classes={classes}
                    form={form}
                    field="amount"
                    title="Amount Paid"
                    disabled={false}
                    onParse={onParseAmount}
                    formatOnBlur={formatPrice}
                    ref={fieldAmountRef}
                    onKeyDown={(event) => handleKeyDown(event)(fieldNotesRef)}
                  />
                  <TableFieldText
                    classes={classes}
                    form={form}
                    field="makeanote"
                    title="Note"
                    disabled={false}
                    onParse={onParseEmpty}
                    formatOnBlur={formatOther}
                    ref={fieldNotesRef}
                  />
                </div>
                <div className={classes.divActions}></div>
              </form>
            )}
          />
        </div>
      )}
    </div>
  );
};

export default withStyles(styles)(AdjustmentUnstyled);

const CalenderCustomInput = forwardRef((props: any, ref: any) => {
  return (
    <Button name="CalenderCustomInput" variant="contained" color="primary" onClick={props.onClick} style={{ marginTop: "5px", width: "250px" }}>
      {props.value}
    </Button>
  );
});

const TableFieldDate: React.FunctionComponent<{ field: string; dateValue: Date; title: string; changeDate: (value) => void } & WithStyles<typeof styles>> = (props) => {
  const { classes, field, dateValue, title, changeDate } = props;
  return (
    <>
      <span className={classes.tableCellLabel}>{`${title}:`}</span>
      <span className={classes.tableCellDetailDate}>
        <Field
          name={field}
          label={title}
          render={(props) => (
            <ReactDatePicker
              {...props}
              locale="en-GB"
              showWeekNumbers={true}
              selected={toDate(dateValue)}
              onChange={(value) => changeDate(value)}
              dateFormat="dd-MM-yyyy"
              placeholderText="click here to select a date"
              customInput={<CalenderCustomInput />}
            />
          )}
        />
      </span>
    </>
  );
};

type TableFieldTextProps = {
  field: string;
  form: any;
  title: string;
  disabled: boolean;
  onParse: (form, value) => any;
  formatOnBlur: any;
  onKeyDown?(event: any): void;
} & WithStyles<typeof styles>;

const TableFieldText = forwardRef((props: TableFieldTextProps, ref: any) => {
  const { classes, form, field, title, disabled, onParse, formatOnBlur, onKeyDown } = props;
  return (
    <>
      <span className={classes.tableCellLabel}>{`${title}:`}</span>
      <span className={classes.tableCellDetail}>
        <Field
          name={field}
          type="text"
          parse={(value) => onParse(form, value)}
          formatOnBlur={true}
          render={({ input, meta }) => (
            <>
              <TextField fullWidth {...input} ref={ref} onKeyDown={onKeyDown} variant="standard" disabled={disabled} onBlur={formatOnBlur} error={meta.error && meta.touched} />
              {meta.error && meta.touched ? <span style={{ color: "red" }}>{meta.error}</span> : <></>}
            </>
          )}
        />
      </span>
    </>
  );
});

const TableFieldCombo: React.FunctionComponent<
  { classes: any; form: any; title: string; field: string; data: any; addEmptyValue: string; onParse: (form, value) => any; disabled?: boolean } & WithStyles<typeof styles>
> = (props) => {
  const { classes, form, title, field, data, onParse, disabled = false } = props;
  const [selectedValue, setSelectedValue] = useState({ display: "", value: "" });

  useEffect(() => {
    const value = form.getState().values[field];
    const selectedValue = (data || []).find((item) => item.value == value);
    if (selectedValue) {
      setSelectedValue(selectedValue);
      onParse(form, value);
    }
  }, [form.getState().values[field]]);

  return (
    <>
      <span className={classes.tableCellLabel}>{title}:</span>
      <span className={classes.tableCellDetail}>
        <Field
          name={field}
          label={title}
          render={(props) => (
            <Autocomplete
              {...props}
              id={field}
              fullWidth
              value={selectedValue}
              onChange={(_, data) => {
                const value = data ? data.value : "";
                form.change(field, value);
              }}
              options={data}
              getOptionLabel={(option: any) => option.display}
              renderInput={(params) => <MTextField {...params} variant="standard" />}
              disabled={disabled}
            />
          )}
        />
      </span>
    </>
  );
};
