import React from "react";

import IconDelete from "@material-ui/icons/Delete";
import IconEdit from "@material-ui/icons/Edit";
import IconClear from "@material-ui/icons/Clear";
import IconDone from "@material-ui/icons/Done";

import CustomAutoCompleteFilter from "../../lib/components/customdatagridfilter/CustomFilter";
import { CellActionsFormatter } from "../../lib/helpers/CellActionsFormatter";
import { handleColumnsArrangement, handleColumnsWidth } from "../../lib/helpers/grid";

const cellWidthXtraSmall = 70;
const cellWidthSmall = 80;
const cellWidthSmallBig = 140;
const cellWidthMedium = 200;

const activecheck = ({ row, column }) => {
  const value = row[column.key];
  if (value) {
    return <IconDone style={{ color: "green" }} />;
  }
  return <IconClear style={{ color: "red" }} />;
};

const defaultColumnProperties = {
  resizable: true,
  sortable: true,
};

let currentSpace = true;
export const GridColumns = (data, filters, arrangement = undefined, columnsWidth = undefined, handleRemove, handleEdit, noDelete) => {
  let postValue = "";

  if (data) {
    if (currentSpace) {
      postValue = " ";
    }
    currentSpace = !currentSpace;
  }

  const columns = [
    {
      key: "actions",
      name: "Actions",
      width: cellWidthSmall,
      formatter: ({ row }) => {
        const actions = cellActions(row, handleRemove, handleEdit, noDelete);
        return (
          <div style={{ display: "flex", justifyContent: "center", gap: "5px" }}>
            <CellActionsFormatter actions={actions} />
          </div>
        );
      },
    },
    {
      key: "code",
      name: "Clearing Agent Code" + postValue,
      width: cellWidthSmall,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"code"} data={data} section="clearingagent" />;
      },
    },
    {
      key: "name",
      name: "Clearing Agent Name" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"name"} data={data} section="clearingagent" />;
      },
    },
    {
      key: "physical_address",
      name: "Physical Address" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"physical_address"} data={data} section="clearingagent" />;
      },
    },
    {
      key: "postal_address",
      name: "Postal Address" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"postal_address"} data={data} section="clearingagent" />;
      },
    },
    {
      key: "telephone",
      name: "Telephone" + postValue,
      width: cellWidthSmallBig,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"telephone"} data={data} section="clearingagent" />;
      },
    },
    {
      key: "mobile",
      name: "Mobile" + postValue,
      width: cellWidthSmallBig,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"mobile"} data={data} section="clearingagent" />;
      },
    },
    {
      key: "email",
      name: "Email" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"email"} data={data} section="clearingagent" />;
      },
    },
    {
      key: "active",
      name: "Active",
      width: cellWidthXtraSmall,
      formatter: activecheck,
      filterable: true,
    },
    {
      key: "consignees_codes",
      name: "Consignees Codes" + postValue,
      width: cellWidthMedium,
      filterable: true,
    },
    {
      key: "consignees_names",
      name: "Consignees Names" + postValue,
      width: cellWidthMedium,
      filterable: true,
    },
  ].map((c) => ({ ...c, ...defaultColumnProperties }));

  let columnsArranged = [];
  columnsArranged = handleColumnsArrangement(columnsArranged, arrangement, columns);
  columnsArranged = handleColumnsWidth(columnsArranged, columnsWidth, columns);

  return columnsArranged;
};

const cellActions = (order, handleRemove, handleEdit, noDelete) => {
  return [
    {
      icon: (
        <span title="Edit">
          <IconEdit style={{ cursor: "pointer", color: "orange" }} />
        </span>
      ),
      callback: (e) => {
        handleEdit(order.id);
      },
    },
    !noDelete && {
      icon: (
        <span title="Remove">
          <IconDelete style={{ cursor: "pointer", color: "red" }} />
        </span>
      ),
      callback: (e) => {
        handleRemove(order.id);
      },
    },
  ];
};
