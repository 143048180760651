import React, { Fragment, useEffect } from "react";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";

import CircularProgress from "@material-ui/core/CircularProgress";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Button from "@material-ui/core/Button";

import Confirmation from "../../../lib/components/confirmation";

import { getProducersFullGroupedByProducer, ProducersFullGroupedType, ProducersFullType } from "../../../lib/api/producersfull";

import ProducersTable from "./producerstable";
import ProducersFixedTable from "../paymentsfixed/producersfixedtable";
import { SnackContext } from "../../../lib/context/SnackContext";
import { isNullOrUndef } from "../../../lib/helpers/isNullOrUndef";

import ProducerAdvancePayEdit from "../pay/produceradvancepayedit";
import ProducerAdvanceAdHoc from "../adhoc/produceradvanceadhoc";

import { ProducerAdvancePaidType } from "../../../lib/api/produceradvancepaid";
import { produceradvancepaidhead, ProducerAdvancePaidHeadType, produceradvancepaidheadGetLatestIdent } from "../../../lib/api/produceradvancepaidhead";
import {
  produceradvancepaidadhoc,
  ProducerAdvancePaidAdHocType,
  processAdhocDetail,
  ProducerAdvancePaidAdHocGetAdHocRemainingType,
} from "../../../lib/api/produceradvancepaidadhoc";

import ProducersFinalTable from "../final/final";

import { Link } from "react-router-dom";
import { getFinancialYearSelected } from "../../../lib/api/week";

import IntakesAndShipments from "../intakesandshipments/index";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      overflow: "hidden",
      width: "100%",
      height: "calc(100% - 65px)",
    },
    appBar: {
      position: "absolute",
      height: "50px",
      width: "calc(100% - 135px)",
    },
    mainTabbed: {
      height: "100%",
      width: "100%",
      paddingTop: "50px",
    },
    backButton: {
      position: "absolute",
      right: "10px",
      top: "7px",
      zIndex: 1000,
    },
  });

type ProducersGroupedProps = { match: any } & WithStyles<typeof styles>;

type FormData = {
  adhoc: string;
  adhocRemaining: ProducerAdvancePaidAdHocGetAdHocRemainingType[];
  currency: number;
  currencySuggested: string;
  makeanote: string;
  pay_amount: string;
  pay_amountcalculated: string;
  paymentdate: Date;
  paymentident: number;
  week_id: number;
  produceradvancepaidhead_id: number;
};

export type SelectedInvoices = {
  bar_OrigIntakeDate: Date;
  clients_name: string;
  dispatch_containerno: string;
  dispatch_currencycode: string;
  intakeWeek: string;
  loadout_eta: Date;
  loadout_etd: Date;
  originalIntakeWaybill: string;
  sale_invoicenumber: string;
  totalValueCAD: number;
  totalValueEUR: number;
  totalValueGBP: number;
  totalValueShow: number;
  totalValueUSD: number;
  totalValueZAR: number;
  total_cartons: number;
  total_palletsize: number;
  vessel_code: string;
  weekcold_week: string;
};

const ProducersGroupedUnstyled: React.FunctionComponent<ProducersGroupedProps> = ({ children, classes, match }) => {
  const [data, setData] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [confirmPayment, setConfirmPayment] = React.useState<{ selected: ProducersFullGroupedType[]; detail: ProducersFullType[]; paymentident: number }>(undefined);
  const [editPayment, setEditPayment] = React.useState<{ selected: ProducersFullGroupedType[]; detail: ProducersFullType[] }>(undefined);
  const [confirmRemovePaymentAll, setConfirmRemovePaymentAll] = React.useState(undefined);
  const [adHoc, setAdHoc] = React.useState(undefined);
  const [editAdHoc, setEditAdHoc] = React.useState(undefined);
  const [tabIndex, setTabIndex] = React.useState(0);
  const { updateSnack } = React.useContext(SnackContext);

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    setLoading(true);
    try {
      const result = await getProducersFullGroupedByProducer(match.params.producerid, getFinancialYearSelected());
      setData(result);
    } catch (error) {
      updateSnack({ show: true, message: error, color: "red" });
    }
    setLoading(false);
  };

  const handleOnPay = async (advanceselected: ProducersFullGroupedType[], data: ProducersFullType[]) => {
    let ident = 1;
    const resultMaxIdent = await produceradvancepaidheadGetLatestIdent();
    if (resultMaxIdent && resultMaxIdent.data && resultMaxIdent.data.length > 0) {
      ident = resultMaxIdent.data[0].maxident;
    }
    setConfirmPayment({ selected: advanceselected, detail: data, paymentident: ident });
  };

  const handleEditPayment = async (advanceselected: ProducersFullGroupedType[], data: ProducersFullType[]) => {
    setEditPayment({ selected: advanceselected, detail: data });
  };

  const handleProcessPay = async (data: FormData, invoicesSelected: SelectedInvoices[], isIntake: boolean) => {
    if (data) {
      const paidHead: ProducerAdvancePaidHeadType = {
        id: data.produceradvancepaidhead_id,
        ident: data.paymentident,
        makeanote: data.makeanote,
        amount: parseFloat(data.pay_amount),
        adhoc: parseFloat(data.adhoc.replaceAll(",", "")),
        currency_id: data.currency,
        week_id: data.week_id,
        posteddate: data.paymentdate,
      };

      const adhocRemainingDetail = data.adhocRemaining.map((adhoc) => ({
        produceradvancepaidhead_id: data.produceradvancepaidhead_id,
        produceradvancepaidadhoc_id: adhoc.adhoc_id, //id of the adhoc entry to assign
        amount: adhoc.paidamount,
      }));

      const advanceDetail = confirmPayment && (confirmPayment.detail || []).length > 0 ? confirmPayment.detail : editPayment.detail;

      const advancePaidDetail: ProducerAdvancePaidType[] = (advanceDetail || []).reduce((arr, item) => {
        if (
          (!isIntake && invoicesSelected.filter((checkeditem) => checkeditem.sale_invoicenumber == item.sale_invoicenumber).length > 0) ||
          (isIntake && invoicesSelected.filter((checkeditem) => checkeditem.sale_invoicenumber == item.bar_intakewaybill).length > 0)
        ) {
          const advancecontracts_id = isNullOrUndef(item.accold_id) ? (isNullOrUndef(item.acshipping_id) ? item.acintake_id : item.acshipping_id) : item.accold_id;
          const advancecontracts_cad = isNullOrUndef(item.accold_cad) ? (isNullOrUndef(item.acshipping_cad) ? item.acintake_cad : item.acshipping_cad) : item.accold_cad;
          const advancecontracts_eur = isNullOrUndef(item.accold_eur) ? (isNullOrUndef(item.acshipping_eur) ? item.acintake_eur : item.acshipping_eur) : item.accold_eur;
          const advancecontracts_gbp = isNullOrUndef(item.accold_gbp) ? (isNullOrUndef(item.acshipping_gbp) ? item.acintake_gbp : item.acshipping_gbp) : item.accold_gbp;
          const advancecontracts_usd = isNullOrUndef(item.accold_usd) ? (isNullOrUndef(item.acshipping_usd) ? item.acintake_usd : item.acshipping_usd) : item.accold_usd;
          const advancecontracts_zar = isNullOrUndef(item.accold_zar) ? (isNullOrUndef(item.acshipping_zar) ? item.acintake_zar : item.acshipping_zar) : item.accold_zar;

          arr.push({
            produceradvancepaidhead_id: data.produceradvancepaidhead_id,
            barcode_id: item.bar_id,
            advancecontracts_id: advancecontracts_id,
            currency_id: data.currency,
            cad: advancecontracts_cad,
            eur: advancecontracts_eur,
            gbp: advancecontracts_gbp,
            usd: advancecontracts_usd,
            zar: advancecontracts_zar,
          });
        }
        return arr;
      }, []);

      await processAdhocDetail(paidHead, adhocRemainingDetail, advancePaidDetail);

      setEditPayment(undefined);
      setConfirmPayment(undefined);
      await loadData();
    } else {
      setEditPayment(undefined);
      setConfirmPayment(undefined);
      await loadData();
    }
  };

  const handleRemovePaymentAll = (data: [ProducersFullType]) => {
    setConfirmRemovePaymentAll(data);
  };

  const handleRemovePaymentAllConfirm = (accept: boolean) => {
    if (accept) {
      const promiseArr = [];

      const uniqueAdvanceHead = [];
      confirmRemovePaymentAll.map((item: ProducersFullType) => {
        if (uniqueAdvanceHead.indexOf(item.produceradvancepaidhead_id) === -1) {
          if (!isNullOrUndef(item.produceradvancepaidhead_id)) uniqueAdvanceHead.push(item.produceradvancepaidhead_id);
        }
      });

      uniqueAdvanceHead.map((headid) => {
        promiseArr.push(
          new Promise((res, rej) => {
            produceradvancepaidhead
              .remove(headid)
              .then(() => {
                res("ok");
              })
              .catch((err) => {
                rej(err);
              });
          }),
        );
      });

      Promise.all(promiseArr)
        .then(() => {
          loadData().then(() => {
            setLoading(false);
          });
        })
        .catch((err) => {
          updateSnack({ show: true, color: "red", message: err });
        });
    }
    setConfirmRemovePaymentAll(undefined);
  };

  const handleOnAddAdHoc = (record) => {
    if (record) {
      produceradvancepaidadhoc.single(record.adhoc_id).then((result) => {
        setEditAdHoc(result);
      });
    } else {
      setEditAdHoc(undefined);
    }
    setAdHoc(true);
  };

  const handleOnAddAdHocConfirm = (data) => {
    if (data) {
      if (data.id && data.id != 0) {
        const dataToConfirm: { data: ProducerAdvancePaidAdHocType } = {
          data: {
            paidamount: data.paidamount,
            note: data.note,
          },
        };
        produceradvancepaidadhoc.update(data.id, dataToConfirm);
      } else {
        const dataToConfirm: { data: ProducerAdvancePaidAdHocType } = {
          data: {
            producer_id: data.producer_id,
            week_id: data.week_id,
            note: data.note,
            currency_id: data.currency_id,
            paidamount: data.paidamount,
          },
        };
        produceradvancepaidadhoc.create(dataToConfirm);
      }
    }
    setAdHoc(undefined);
    loadData().then(() => {
      setLoading(false);
    });
  };

  const handleTabChange = (event: React.ChangeEvent<{}>, newTabIndexValue: number) => {
    setTabIndex(newTabIndexValue);
  };

  const a11yProps = (index: any) => {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  };

  return (
    <div className={classes.root}>
      {loading && <CircularProgress style={{ position: "absolute", marginTop: "50px" }} />}
      <AppBar position="static" className={classes.appBar}>
        <Link to="/producers/groupedonly">
          <Button variant="contained" color="secondary" className={classes.backButton}>
            Back
          </Button>
        </Link>
        <Tabs value={tabIndex} onChange={handleTabChange} aria-label="Stock Allocation">
          <Tab label="All Intakes and Shipments" {...a11yProps(0)} />
          <Tab label="MGP Advances" {...a11yProps(1)} />
          <Tab label="MGP Final" {...a11yProps(2)} />
          <Tab label="FIXED" {...a11yProps(3)} />
        </Tabs>
      </AppBar>
      <div id="main_tabbed_div" className={classes.mainTabbed}>
        {tabIndex == 0 && <IntakesAndShipments match={match} />}
        {tabIndex == 1 && (
          <Fragment>
            {loading == false && !confirmPayment && !adHoc && !editPayment && (
              <ProducersTable
                data={data}
                onReload={() => {
                  loadData().then(() => {
                    setLoading(false);
                  });
                }}
                onPay={handleOnPay}
                editPay={handleEditPayment}
                onRemovePaymentAll={handleRemovePaymentAll}
                onAddAdHoc={handleOnAddAdHoc}
              />
            )}
            {confirmPayment && (
              <ProducerAdvancePayEdit
                data={confirmPayment.detail}
                advances={confirmPayment.selected}
                handleProcessPay={handleProcessPay}
                paymentident={confirmPayment.paymentident}
                paymentdate={confirmPayment.selected[0].produceradvancepaidhead_posteddate}
              />
            )}
            {editPayment && (
              <ProducerAdvancePayEdit
                data={editPayment.detail}
                advances={editPayment.selected}
                handleProcessPay={handleProcessPay}
                paymentident={undefined}
                paymentdate={editPayment.selected[0].produceradvancepaidhead_posteddate}
              />
            )}
            {adHoc && <ProducerAdvanceAdHoc history={match} initialData={editAdHoc} handleProcessAdHoc={handleOnAddAdHocConfirm} />}
            {confirmRemovePaymentAll && (
              <Confirmation
                isOpen={true}
                handleClose={() => handleRemovePaymentAllConfirm(false)}
                handleConfirm={() => {
                  handleRemovePaymentAllConfirm(true);
                }}
                title={"Remove Payments made"}
                body={`Are you sure you would like to reverse payment made?`}
              />
            )}
          </Fragment>
        )}
        {tabIndex == 2 && (
          <Fragment>
            <ProducersFinalTable producer={match.params.producerid} />
          </Fragment>
        )}
        {tabIndex == 3 && (
          <Fragment>
            <ProducersFixedTable producer={match.params.producerid} />
          </Fragment>
        )}
      </div>
    </div>
  );
};

export default withStyles(styles)(ProducersGroupedUnstyled);
