import React, { FC, useState } from "react";
import { withStyles } from "@material-ui/core/styles";

import Button from "@material-ui/core/Button";
import Menu, { MenuProps } from "@material-ui/core/Menu";

import ListAlt from "@material-ui/icons/ListAlt";

import { MenuList } from "../components/MenuList";

export type MenuItemsType = { title: string; icon?: DocumentsType; action?: () => void; children?: MenuItemsType[]; options?: MenuItemsType[]; disabled?: boolean };

type DocumentsButtonProps = {
  menuItems: MenuItemsType[];
  disabled: boolean;
  title?: string;
};

export const DocumentsButton: FC<DocumentsButtonProps> = ({ menuItems, disabled = false, title = "Documents" }) => {
  const [anchorEl, setAnchorEl] = useState(undefined);

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(undefined);
  };

  return (
    <>
      <Button color="primary" variant="contained" onClick={handleOpen} disabled={disabled} style={{ marginLeft: "10px", marginRight: "10px" }}>
        {title}
        <ListAlt style={{ marginLeft: "5px" }} />
      </Button>
      <StyledMenu id="customized-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
        {menuItems.map((menuItem, index) => (
          <MenuList menuItem={menuItem} index={index} key={index} />
        ))}
      </StyledMenu>
    </>
  );
};

export const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
  },
})((props: MenuProps) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

export enum DocumentsType {
  PDF = "pdf",
  EXCEL = "excel",
  DOCUMENT = "document",
}
