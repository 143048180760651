import { client, request } from "./_base";
import { getFinancialYearSelected } from "./week";

const endpoint = "arrivaldata";

export const arrivaldata = client(`${endpoint}`, (item) => ({
  ...item,
}));

export const arrivaldataGroupedContainer = async () => {
  const result = await request(`${endpoint}/ext/groupedcontainer/${getFinancialYearSelected()}`, {
    method: "GET",
  });
  const resp = await result.json();
  if (result.status == 500) {
    throw resp;
  }
  return resp.data;
};

export const arrivaldataByContainerProducerSalesRef = (container: string, producerid, salesref) => {
  const validateContainer = container && !container.includes("/") ? container : "0";
  return request(`${endpoint}/ext/bycontainerproducersalesref/${validateContainer}/${producerid}/${salesref}`, {
    method: "GET",
  })
    .then(async (result) => {
      if (result.status == 500) {
        const resp = await result.json();
        throw resp;
      }
      return result.json();
    })
    .then(({ data }) => {
      return data;
    });
};
