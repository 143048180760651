import React from "react";
import { withStyles, Theme } from "@material-ui/core/styles";

import { green, orange, red, grey, pink } from "@material-ui/core/colors";
import Button from "@material-ui/core/Button";

export const GreenButton = withStyles((theme: Theme) => ({
  root: {
    marginRight: "10px",
    color: "white",
    backgroundColor: green[700],
    "&:hover": {
      backgroundColor: green[800],
    },
    "&:disabled": {
      backgroundColor: "#e0e0e0",
    },
  },
}))(Button);

export const LightGreenButton = withStyles((theme: Theme) => ({
  root: {
    marginRight: "10px",
    color: "white",
    backgroundColor: green[600],
    "&:hover": {
      backgroundColor: green[700],
    },
    "&:disabled": {
      backgroundColor: "#e0e0e0",
    },
  },
}))(Button);

export const OrangeButton = withStyles((theme: Theme) => ({
  root: {
    marginRight: "10px",
    color: "white",
    backgroundColor: orange[400],
    "&:hover": {
      backgroundColor: orange[500],
    },
    "&:disabled": {
      backgroundColor: "#e0e0e0",
    },
  },
}))(Button);

export const RedButton = withStyles((theme: Theme) => ({
  root: {
    marginRight: "10px",
    color: "white",
    backgroundColor: red[500],
    "&:hover": {
      backgroundColor: red[600],
    },
    "&:disabled": {
      backgroundColor: "#e0e0e0",
    },
  },
}))(Button);

export const GreyButton = withStyles((theme: Theme) => ({
  root: {
    marginRight: "10px",
    color: "white",
    backgroundColor: grey[500],
    "&:hover": {
      backgroundColor: grey[600],
    },
    "&:disabled": {
      backgroundColor: "#e0e0e0",
    },
  },
}))(Button);

export const PinkButton = withStyles((theme: Theme) => ({
  root: {
    marginRight: "10px",
    color: "white",
    backgroundColor: pink[500],
    "&:hover": {
      backgroundColor: pink[600],
    },
    "&:disabled": {
      backgroundColor: "#e0e0e0",
    },
  },
}))(Button);
