import React, { FC, useEffect, useMemo, useState } from "react";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";

import Check from "@material-ui/icons/Check";
import Close from "@material-ui/icons/Close";
import Button from "@material-ui/core/Button";
import LinearProgress from "@material-ui/core/LinearProgress";

import { Field, Form } from "react-final-form";
import { TextField } from "final-form-material-ui";

import { brands } from "../../lib/api/brand";
import { MaterialCheckBoxComponent } from "../../lib/helpers/materialcomponents";
import { GenerateErrorMessage } from "../../lib/helpers/string_methods";
import { SnackContext } from "../../lib/context/SnackContext";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1),
      width: "270px",
    },
    formContainer: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(1),
    },
    actionContainer: {
      display: "flex",
      justifyContent: "flex-end",
      marginTop: theme.spacing(1),
      gap: theme.spacing(1),
    },
    fieldContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignContent: "center",
      gap: theme.spacing(1),
      "& > span": {
        width: "100px",
        textAlign: "right",
      },
      "& > div": {
        textAlign: "left",
        width: "300px",
      },
      "& > label": {
        width: "300px",
        textAlign: "left",
        justifyContent: "flex-end",
      },
    },
  });

type BrandEditFormProps = {
  onSubmit: any;
  onClose: any;
  id: any;
  brandsFinYear: { brand: string }[];
} & WithStyles<typeof styles>;

const BrandEditFormUnstyled: FC<BrandEditFormProps> = ({ classes, onClose, onSubmit, id, brandsFinYear }) => {
  const { updateSnack } = React.useContext(SnackContext);

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({ id: 0, code: "", active: 1 });

  const loadData = async () => {
    setLoading(true);
    try {
      const brand = await brands.single(id);
      setData(brand);
    } catch (error) {
      const err = GenerateErrorMessage(error, "Error retrieving data");
      updateSnack({ show: true, color: "red", message: err });
    }
    setLoading(false);
  };

  useEffect(() => {
    if (id && id != "0") {
      loadData();
    }
  }, [id]);

  const disabled = useMemo(() => !!(brandsFinYear || []).find((brand) => brand.brand === data.code), [data, brandsFinYear]);

  return (
    <div>
      {loading ? <LinearProgress color="secondary" style={{ marginBottom: "10px" }} /> : <div style={{ height: "10px" }}></div>}
      <Form
        initialValues={{ ...data }}
        onSubmit={onSubmit}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <div className={classes.formContainer}>
              <FormCheckbox classes={classes} field="active" label="Active" disabled={disabled || loading} />
              <FormTextField classes={classes} field="code" label="Brand Code" />
              <FormTextAreaField classes={classes} field="description" label="Description" />
            </div>
            <div className={classes.actionContainer}>
              <Button color="secondary" variant="outlined" onClick={onClose} type="button">
                <Close />
              </Button>
              <Button type="submit" color="primary" variant="outlined">
                <Check />
              </Button>
            </div>
          </form>
        )}
      />
    </div>
  );
};

type FormTextFieldProps = {
  label: string;
  field: string;
} & WithStyles<typeof styles>;

const FormTextField: FC<FormTextFieldProps> = ({ classes, label, field }) => {
  return (
    <div className={classes.fieldContainer}>
      <span>{label}:</span>
      <Field required fullWidth name={field} component={TextField} />
    </div>
  );
};

type FormTextAreaProps = {
  label: string;
  field: string;
} & WithStyles<typeof styles>;

const FormTextAreaField: FC<FormTextAreaProps> = ({ classes, label, field }) => {
  return (
    <div className={classes.fieldContainer}>
      <span>{label}:</span>
      <Field allowNull fullWidth name={field} component={TextField} />
    </div>
  );
};

type FormCheckboxProps = {
  label: string;
  field: string;
  disabled: boolean;
} & WithStyles<typeof styles>;

const FormCheckbox: FC<FormCheckboxProps> = ({ classes, label, field, disabled }) => {
  return (
    <div className={classes.fieldContainer}>
      <span>{label}:</span>
      <Field name={field} component={MaterialCheckBoxComponent} disabled={disabled} type="checkbox" style={{ margin: 0, padding: 0, marginLeft: "-18px" }} />
    </div>
  );
};

export default withStyles(styles)(BrandEditFormUnstyled);
