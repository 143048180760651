import React from "react";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";

import Grid from "../../lib/components/grid";
import Confirmation from "../../lib/components/confirmation";

import { packhouse } from "../../lib/api/producerpackhouse";
import { GridColumns } from "./packhousegridsetup";

import Tooltip from "@material-ui/core/Tooltip";
import Button from "@material-ui/core/Button";

import IconAdd from "@material-ui/icons/Add";

import PackhouseEditForm from "./packhouseeditform";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1),
      width: "700px",
      height: "100%",
      position: "relative",
      paddingTop: 0,
      marginTop: "-3px",
    },
    inline: {
      display: "inline",
      position: "absolute",
      marginTop: "7px",
      marginLeft: "7px",
    },
    grid: {
      // marginTop: "-10px",
      width: "100%",
      float: "left",
    },
  });

type PackhouseProps = {
  producerid: any;
  handleRefresh: () => void;
} & WithStyles<typeof styles>;

export class PackhouseUnstyled extends React.Component<PackhouseProps, any> {
  state = {
    classes: undefined,
    data: [],
    confirmRemoveID: undefined,
    confirmEditID: undefined,
    producerid: undefined,
    loading: true,
  };

  constructor(props) {
    super(props);
    this.state.classes = props.classes;

    if (props.producerid) {
      this.state.producerid = props.producerid;
    }
  }

  componentDidMount() {
    this.loadData();
  }

  componentWillReceiveProps(props) {
    this.setState({ producerid: props.producerid }, () => {
      this.loadData();
    });
  }

  loadData = () => {
    packhouse.all().then((data) => {
      const dataSorted = data.sort((a, b) => {
        if (a.code > b.code) return 1;
        if (a.code < b.code) return -1;
        return 1;
      });

      if (!this.state.producerid) {
        this.setState({ data: [] });
      } else {
        const dataFiltered = dataSorted.filter((item) => item.producer_id == this.state.producerid);
        this.setState({ data: dataFiltered });
      }
      this.setState({ loading: false });
    });
  };

  handleCreate = () => {
    this.setState({ confirmEditID: "0" });
  };

  handleEdit = (id) => {
    this.setState({ confirmEditID: id });
  };

  handleEditClose = () => {
    this.setState({ confirmEditID: undefined });
  };

  handleEditConfirm = (e, item) => {
    e.preventDefault();

    const id = item["id"];
    delete item["id"];

    item.producer_id = this.state.producerid;

    if (id == "0") {
      packhouse.create({ data: { ...item } }).then(() => this.loadData());
    } else {
      packhouse.update(id, { data: { ...item } }).then(() => this.loadData());
    }
    this.handleEditClose();
    this.props.handleRefresh();
  };

  handleRemove = async (id) => {
    this.setState({ confirmRemoveID: id });
  };

  handleRemoveClose = () => {
    this.setState({ confirmRemoveID: undefined });
  };

  handleRemoveConfirm = async () => {
    await packhouse.remove(this.state.confirmRemoveID).then(() => {
      this.handleRemoveClose();
      this.loadData();
    });
  };

  GridColumnsOverride = (column, row) => {
    return GridColumns(column, row, this.handleRemove, this.handleEdit);
  };

  render() {
    const { classes } = this.state;

    return (
      <div className={classes.root}>
        {this.state.producerid && this.state.producerid == "0" ? (
          <div>New Producer. Cannot add Packhouse</div>
        ) : (
          <div>
            <div className={classes.inline}>
              <Tooltip title="Create new Packhouse">
                <div className={classes.inline}>
                  <Button variant="contained" color="primary" onClick={this.handleCreate} style={{ marginBottom: "10px" }}>
                    <IconAdd />
                  </Button>
                </div>
              </Tooltip>
            </div>
            <div style={{ width: "600px" }}>
              <Grid loading={this.state.loading} data={this.state.data} GridColumns={this.GridColumnsOverride} clearFilters={"packhousegridsetup"} />
            </div>
            {this.state.confirmRemoveID && (
              <Confirmation
                isOpen={this.state.confirmRemoveID ? true : false}
                handleClose={this.handleRemoveClose}
                handleConfirm={this.handleRemoveConfirm}
                title="Remove selected Packhouse?"
                body="Are you sure you want to REMOVE the selected Packhouse Record?"
              ></Confirmation>
            )}
            {this.state.confirmEditID && (
              <Confirmation
                isOpen={this.state.confirmEditID ? true : false}
                handleClose={this.handleEditClose}
                handleConfirm={() => {}}
                title={this.state.confirmEditID == "0" ? "Creating Packhouse" : "Editing Packhouse"}
                body={undefined}
              >
                <PackhouseEditForm onSubmit={this.handleEditConfirm} onClose={this.handleEditClose} id={this.state.confirmEditID} />
              </Confirmation>
            )}
          </div>
        )}
      </div>
    );
  }
}

export default withStyles(styles)(PackhouseUnstyled);
