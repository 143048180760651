import { client, request } from "./_base";

const endpoint = "advancecontracts";

export type AdvanceContractsType = {
  id?: number;
  // commodityid: number;
  // commoditycode?: string;
  // commodityname?: string;
  // commodityfull?: string;
  cad: number;
  eur: number;
  gbp: number;
  usd: number;
  zar: number;
  // gradeid: number;
  // gradecode?: string;
  weekfromid?: number;
  weekfrom: string;
  weektoid?: number;
  weekto: string;
  producer_id?: number;
  payoutSource: number;
  payoutLeadNumber: number;
};

export const advanceContracts = client(endpoint, (item) => ({
  ...item,
}));

export const advancecontractsFull = (advancecontractsid = undefined, producerid = undefined) => {
  return request(`${endpoint}/ext/full/${advancecontractsid}/${producerid}`, {
    method: "GET",
  })
    .then(async (result) => {
      if (result.status == 500) {
        const resp = await result.json();
        throw resp;
      }
      return result.json();
    })
    .then(({ data }) => {
      return data;
    });
};

export const advancecontractsCopy = (advancecontractsid) => {
  return request(`${endpoint}/ext/copy/${advancecontractsid}`, {
    method: "GET",
  })
    .then(async (result) => {
      if (result.status == 500) {
        const resp = await result.json();
        throw resp;
      }
      return result.json();
    })
    .then(({ data }) => {
      return data;
    });
};

export const advanceContractsPaidLink = () => {
  return request(`${endpoint}/ext/advanceContractsPaidLink`)
    .then(async (result) => {
      if (result.status == 500) {
        const resp = await result.json();
        throw resp;
      }
      return result.json();
    })
    .then(({ data }) => {
      return data;
    });
};

type UpsertAdvanceContractType = {
  contract: {
    id: number;
    weekfrom: number;
    weekto: number;
    cad: number;
    eur: number;
    gbp: number;
    usd: number;
    zar: number;
    producer_id: number;
    payoutSource: number;
    payoutLeadNumber: number;
  };
  commodities: number[];
  varieties: any[];
  grades: any[];
  packCodes: any[];
};

export const upsertAdvanceContract = async (data: UpsertAdvanceContractType): Promise<void> => {
  const result = await request(`${endpoint}/ext/upsertAdvanceContract`, { method: "POST", body: JSON.stringify(data) });
  if (result.status == 500) {
    const resp = await result.json();
    throw resp;
  }
};
