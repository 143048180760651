import numeral from "numeral";
import format from "date-fns/format";

import { config } from "../../currencyconfig";

const getCurrencyLive = async (curr: string) => {
  const result = await fetch(`https://apilayer.net/api/live?access_key=${config.CURRENCY_API_KEY}&source=${curr}&currencies=ZAR`);
  const resp = await result.json();
  if (result.status == 500) {
    throw resp;
  }
  return resp;
};

const getCurrencyHistory = async (curr: string, date: string) => {
  const result = await fetch(`https://apilayer.net/api/historical?access_key=${config.CURRENCY_API_KEY}&source=${curr}&currencies=ZAR&date=${date}`);
  const resp = await result.json();
  if (result.status == 500) {
    throw resp;
  }
  return resp;
};

const getCurrencyTimeframe = async (curr: string, start_date: string, end_date: string) => {
  const result = await fetch(
    `https://apilayer.net/api/timeframe?access_key=${config.CURRENCY_API_KEY}&source=${curr}&currencies=ZAR&start_date=${start_date}&end_date=${end_date}`,
  );
  const resp = await result.json();
  if (result.status == 500) {
    throw resp;
  }
  return resp;
};

export const GetCurrentExchangeRate = async () => {
  const currencies = ["USD", "CAD", "EUR", "GBP"];
  const result = await Promise.all(currencies.map((curr) => getCurrencyLive(curr)));
  return {
    base: "ZAR",
    date: "",
    rates: result.reduce((rates, curr) => {
      rates[curr.source] = curr.quotes[`${curr.source}ZAR`];
      return rates;
    }, {}),
  };
};

export const forDate = async (date: string) => {
  const currencies = ["USD", "CAD", "EUR", "GBP"];
  const result = await Promise.all(currencies.map((curr) => getCurrencyHistory(curr, date)));
  return {
    base: "ZAR",
    end_at: date,
    start_at: date,
    rates: {
      [date]: result.reduce((rates, curr) => {
        rates[curr.source] = curr.quotes[`${curr.source}ZAR`];
        return rates;
      }, {}),
    },
  };
};

export const getExchangeRateByDateAndCurrency = async (currency: string, date: string): Promise<string> => {
  const data = await forDate(date);
  if (data.rates.length != 0) {
    const keys = Object.keys(data.rates);
    const rate = numeral(data.rates[keys[0]][currency ? currency : "ZAR"]).format("0.000");
    return rate;
  }
  return "1.0";
};

export const getExchangeRateFromLast30Days = async (start_date?: Date) => {
  const currencies = ["USD", "CAD", "EUR", "GBP"];
  if (start_date) {
    const monthFromSelectedDate = new Date(start_date.getFullYear(), start_date.getMonth() + 1, 0).getMonth();
    return await Promise.all(
      currencies.map((curr) =>
        getCurrencyTimeframe(
          curr,
          format(new Date(start_date.getFullYear(), start_date.getMonth(), 1), "yyyy-MM-dd"),
          new Date().getMonth() === monthFromSelectedDate
            ? format(new Date(), "yyyy-MM-dd")
            : format(new Date(start_date.getFullYear(), start_date.getMonth() + 1, 0), "yyyy-MM-dd"),
        ),
      ),
    );
  }
  const today = format(new Date(), "yyyy-MM-dd");
  const priorDate = format(new Date(new Date().setDate(new Date().getDate() - 30)), "yyyy-MM-dd");
  return await Promise.all(currencies.map((curr) => getCurrencyTimeframe(curr, priorDate, today)));
};
