import { apiHeaders, client, request } from "./_base";

const endpoint = "clientscontracts";

export const clientsContracts = client("clientscontracts", (item) => ({
  ...item,
}));

export const clientscontractsFull = async () => {
  const result = await request(`${endpoint}/ext/full`, {
    method: "GET",
  });
  const resp = await result.json();
  if (result.status == 500) {
    throw resp;
  }
  return resp.data;
};

export type ClientsContractsFullType = {
  id: string;
  clients_id: number;
  shipmentweekfrom: number;
  shipmentweekto: number;
  sellingprice: number;
  commodity_id: number;
  currency_id: number;
  variety: number[];
  grade: number[];
  count: string[];
  pack_id: number;
};

export const batchInsertOrCreateClientContracts = async (data: ClientsContractsFullType) => {
  const content = JSON.stringify(data);
  const result = await request(`${endpoint}/ext/batchInsertOrCreateClientContracts`, {
    method: "POST",
    headers: apiHeaders(content),
    body: content,
  });
  const resp = await result.json();
  if (result.status == 500) {
    throw resp;
  }
  return resp;
};
