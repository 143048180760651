import React, { useEffect } from "react";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";

import { ReactSortable } from "react-sortablejs";

import { ConsigneeAvailableToClient, ConsigneeforClient } from "../../lib/api/consignee";
import { clientsConsignee } from "../../lib/api/clientsconsignee";
import TextField from "@material-ui/core/TextField";

const styles = (theme: Theme) =>
  createStyles({
    root: {},
    container: {
      display: "grid",
      gridTemplateRows: "350px 350px",
      gap: theme.spacing(2),
    },
    Item: {
      background: "#e6e9f7",
      borderRadius: "5px",
      padding: "5px",
      margin: "5px",
      width: "500px",
      "&:hover": {
        cursor: "move",
      },
    },
    activeShow: {
      height: "10px",
      width: "10px",
      display: "inline-block",
      borderRadius: "100%",
      margin: "1px",
      marginRight: "4px",
    },
    header: {
      whiteSpace: "nowrap",
      margin: 0,
    },
    headerContainer: {
      display: "flex",
      flexDirection: "row",
      gap: "10px",
      alignItems: "flex-end",
    },
    section: {
      display: "flex",
      flexDirection: "column",
      gap: "5px",
    },
    detail: {
      overflowY: "auto",
      height: "100%",
    },
  });

type ClientEditConsigneeFormProps = { clientid: number; TopDown?: boolean; forceReload?: boolean; dataChangedEvent?: any } & WithStyles<typeof styles>;

const ClientEditConsigneeFormUnstyled: React.FunctionComponent<ClientEditConsigneeFormProps> = (props) => {
  const { classes, clientid, TopDown, forceReload, dataChangedEvent } = props;

  const [consigneesForClient, setConsigneesForClient] = React.useState([]);
  const [availableConsignees, setAvailableConsignees] = React.useState([]);
  const [availableConsigneesOrg, setAvailableConsigneesOrg] = React.useState([]);
  const [availableDropZone, setAvailableDropZone] = React.useState(false);
  const [consigneesDropZone, setConsigneesDropZone] = React.useState(false);
  const [search, setSearch] = React.useState("");

  useEffect(() => {
    reload();
  }, [clientid, forceReload]);

  useEffect(() => {
    const availableList = availableConsigneesOrg.filter((al) => {
      if (!consigneesForClient.find((ca) => ca.id == al.id)) {
        return al;
      }
    });
    const result = availableList.filter((clearAgent) => clearAgent.name.toLowerCase().includes(search));
    if (consigneesForClient && consigneesForClient.length > 0) {
      const resultFiltered = result.filter((item) => consigneesForClient.find((con) => con.id != item.id));
      setAvailableConsignees(resultFiltered);
    } else {
      setAvailableConsignees(result);
    }
  }, [search]);

  const reload = async () => {
    const [consigneeResult, consigneeAvailable] = await Promise.all([ConsigneeforClient(clientid, undefined), ConsigneeAvailableToClient(clientid)]);
    const consigneeSorted = consigneeResult.sort((a, b) => a.name.localeCompare(b.name));
    const availableConsigneeSorted = consigneeAvailable.sort((a, b) => a.name.localeCompare(b.name));
    setConsigneesForClient(consigneeSorted);
    setAvailableConsignees(availableConsigneeSorted);
    setAvailableConsigneesOrg(availableConsigneeSorted);
  };

  const handleOnEnd = async (event) => {
    setAvailableDropZone(false);
    setConsigneesDropZone(false);

    const dataid = event.item.getAttribute("data-id").split("_");
    const itemid = dataid[2];
    const key = dataid[0];

    await onSelectingConsignee(event, key, itemid);
    dataChangedEvent(!forceReload);
  };

  const onSelectingConsignee = async (event, key, itemid) => {
    if (event.to !== event.from) {
      if (key.includes("Available-Consignees")) {
        const newData = {
          consignee_id: itemid,
          clients_id: clientid,
        };
        await clientsConsignee.create({ data: { ...newData } });
      }
      if (key.includes("Consignees-For-Clients")) {
        await clientsConsignee.remove(itemid);
      }
    }
  };

  const green = "#00c400";
  const red = "#e61f1f";

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <div className={classes.section}>
          <div>
            <h4 className={classes.header}>Consignees for this Client: {`${consigneesForClient.length}`}</h4>
          </div>
          <div className={classes.detail}>
            <ReactSortable
              animation={150}
              key="Client-Consignee-List"
              style={{
                minHeight: "110px",
                border: !consigneesDropZone ? "0" : "2px dashed #3f51b5",
                borderRadius: "5px",
              }}
              list={consigneesForClient}
              setList={setConsigneesForClient}
              onEnd={handleOnEnd}
              onStart={() => setAvailableDropZone(true)}
              group="consignees-group-name"
            >
              {(consigneesForClient || []).map((item, idx) => (
                <div className={classes.Item} id={`Consignees-For-Clients_${idx}_${item.clientsconsignee_id}`} key={`Consignees-For-Clients_${idx}_${item.clientsconsignee_id}`}>
                  <span style={{ background: `${item.active ? green : red}` }} className={classes.activeShow}></span>
                  {item.name}
                </div>
              ))}
            </ReactSortable>
          </div>
        </div>
        <div className={classes.section}>
          <div className={classes.headerContainer}>
            <h4 className={classes.header}>Available Consignees: {`${availableConsignees.length}`}</h4>
            <TextField fullWidth id="standard-search" label="Search..." type="search" onChange={(e) => setSearch(e.target.value.toLowerCase())} style={{ width: "300px" }} />
          </div>
          <div className={classes.detail}>
            <ReactSortable
              animation={150}
              key="Available-List"
              style={{
                minHeight: "110px",
                border: !availableDropZone ? "0" : "2px dashed #3f51b5",
                borderRadius: "5px",
              }}
              onEnd={handleOnEnd}
              list={availableConsignees}
              setList={setAvailableConsignees}
              onStart={() => setConsigneesDropZone(true)}
              group="consignees-group-name"
            >
              {(availableConsignees || []).map((item, idx) => (
                <div className={classes.Item} id={`Available-Consignees_${idx}_${item.id}`} key={`Available-Consignees_${idx}_${item.id}`}>
                  <span style={{ background: `${item.active ? green : red}` }} className={classes.activeShow}></span>
                  {item.name}
                </div>
              ))}
            </ReactSortable>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withStyles(styles)(ClientEditConsigneeFormUnstyled);
