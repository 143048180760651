import React, { useEffect, useContext } from "react";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import LinearProgress from "@material-ui/core/LinearProgress";
import Badge from "@material-ui/core/Badge";
import IconButton from "@material-ui/core/IconButton";

import IconClose from "@material-ui/icons/Close";

import Typography from "@material-ui/core/Typography";

import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";

import { Form } from "react-final-form";
import Confirmation from "../lib/components/confirmation";

import { loadout } from "../lib/api/loadout";
import { loadoutdetail } from "../lib/api/loadoutdetail";
import { findByDispatchIDBarcodeCompare, FindByDispatchIDBarcodeCompareType } from "../lib/api/edistockpalettesdispatched";
import { DispatcheddocsFullType } from "../lib/api/dispatcheddocs";
import { ContainerTypes } from "../lib/api/containertypes";

import DispatchAcceptDialogForm from "./dispatchAcceptDialogForm";
import DispatchAcceptDialogCompare from "./dispatchAcceptDialogFormCompare";
import { agent } from "../lib/api/agent";
import { Redirect } from "react-router";
import { getDispatchInvoiceNumbers } from "../lib/api/dispatch";
import { FormState } from "./dispatch";
import { SnackContext } from "../lib/context/SnackContext";

const styles = (theme: Theme) =>
  createStyles({
    root: {},
    table: {
      width: "900px",
    },
    tableRows: {
      margin: theme.spacing(2),
    },
    tableCellsDesc: {
      paddingRight: theme.spacing(2),
      paddingTop: theme.spacing(2),
      height: "30px",
      width: "200px",
      textAlign: "right",
    },
    tableCellsValue: {
      width: "250px",
      textAlign: "left",
      marginTop: theme.spacing(1),
    },
    tableCellsValueText: {
      width: "250px",
      textAlign: "left",
    },
    tableCellsValueSelect: {
      textAlign: "left",
      width: "250px",
    },
    specialInstructionTable: {
      marginTop: theme.spacing(3),
    },
    buttonContainer: {
      paddingTop: "20px",
      paddingBottom: "10px",
      float: "right",
    },
    tableRowNormal: {
      backgroundColor: "white",
      padding: "0px",
    },
    tableRowError: {
      backgroundColor: "yellow",
      padding: "0px",
    },
  });

type AcceptDispatchDialogProps = {
  isOpen: any;
  handleClose: any;
  onSubmit: any;
  defaultData: DispatcheddocsFullType;
  agentdefault: any;
  selectedDispatch: any;
  handleShowConfirmation(): void;
} & WithStyles<typeof styles>;

export class AcceptDispatchDialogUnstyled extends React.Component<AcceptDispatchDialogProps, any> {
  state = {
    isOpen: undefined,
    classes: undefined,
    handleClose: undefined,
    onSubmit: undefined,
    loading: true,
    agentdefault: undefined,
    handleShowConfirmation: undefined,
    selectedDispatch: undefined,
  };

  constructor(props) {
    super(props);
    this.state.isOpen = props.isOpen;
    this.state.handleClose = props.handleClose;
    this.state.onSubmit = props.onSubmit;
    this.state.classes = props.classes;
    this.state.agentdefault = props.agentdefault;
    this.state.handleShowConfirmation = props.handleShowConfirmation;
    this.state.selectedDispatch = props.selectedDispatch;
  }

  componentDidMount() {
    this.setState({ loading: false });
  }

  dispatchHeader() {
    let text = "";

    if (this.props.defaultData) {
      const loadout_reference = this.props.selectedDispatch.loadout_reference.trim();

      if (loadout_reference && loadout_reference.length > 0) {
        text = ` - ${loadout_reference}`;
      }
    }
    return text;
  }

  render() {
    return (
      <Dialog fullScreen={true} open={this.state.isOpen} onClose={this.state.handleClose} maxWidth={false}>
        <DialogTitle id="responsive-dialog-title">
          <div>
            <span style={{ float: "left" }}>
              Dispatch Selected
              <span>{this.dispatchHeader()}</span>
            </span>
            <IconButton aria-label="close" color="secondary" style={{ float: "right" }} onClick={this.state.handleClose}>
              <IconClose />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent>
          {this.state.loading ? (
            <LinearProgress />
          ) : (
            <AcceptDispatch
              onSubmit={this.state.onSubmit}
              handleClose={this.state.handleClose}
              classes={this.state.classes}
              defaultData={this.props.defaultData}
              agentdefault={this.state.agentdefault}
              selectedDispatch={this.state.selectedDispatch}
              handleShowConfirmation={this.state.handleShowConfirmation}
            />
          )}
        </DialogContent>
      </Dialog>
    );
  }
}

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}
function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <Typography role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

const AcceptDispatch: React.FunctionComponent<{
  onSubmit: any;
  handleClose: any;
  classes: any;
  defaultData: any;
  agentdefault: any;
  selectedDispatch: any;
  handleShowConfirmation(): void;
}> = (props) => {
  const { classes, onSubmit, handleClose, defaultData, agentdefault, handleShowConfirmation, selectedDispatch } = props;

  const { updateSnack } = useContext(SnackContext);

  const [incompleteLoadout, setIncompleteLoadout] = React.useState(false);
  const [dispatchPallets, setDispatchPallets] = React.useState<FindByDispatchIDBarcodeCompareType[]>([]);
  const [dispatchPalletsLoadouts, setDispatchPalletsLoadouts] = React.useState<{ loadout_id: number; loadout_reference: string }[]>([]);
  const [tabValue, setTabValue] = React.useState(1);
  const [confirmLoadoutPlaceholder, setConfirmLoadoutPlaceHolder] = React.useState(false);
  const [compareIssuesCount, setCompareIssuesCount] = React.useState(0);
  const [redirectInvoice, setRedirectInvoice] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const [dispatchInvoiceNumbers, setDispatchInvoiceNumbers] = React.useState<string[]>([]);

  const getAllDispatchInvoiceNumbers = async () => {
    const dispatchInvoiceNumbersResult = await getDispatchInvoiceNumbers();
    setDispatchInvoiceNumbers(dispatchInvoiceNumbersResult.data);
  };

  useEffect(() => {
    loadData();
  }, [defaultData["Dispatch ID"]]);

  useEffect(() => {
    getAllDispatchInvoiceNumbers();
  }, []);

  const loadData = async () => {
    const result = await findByDispatchIDBarcodeCompare(defaultData["Dispatch ID"]);
    if (result && result.data) {
      setDispatchPallets(result.data);

      const dispatchPalletsLoadoutsArr = [];
      result.data.map((ediItem: FindByDispatchIDBarcodeCompareType) => {
        if (!dispatchPalletsLoadoutsArr.find((dpl) => dpl.loadout_id == ediItem.loadout_id)) {
          dispatchPalletsLoadoutsArr.push({ loadout_id: ediItem.loadout_id, loadout_reference: ediItem.loadout_reference });
        }
      });

      defaultData["loadout_reference"] = dispatchPalletsLoadoutsArr
        .reduce((acc, curr) => {
          acc.push(curr.loadout_reference);
          return acc;
        }, [])
        .join(",");

      setDispatchPalletsLoadouts(dispatchPalletsLoadoutsArr);
      if (dispatchPalletsLoadoutsArr.length == 0) {
        setConfirmLoadoutPlaceHolder(true);
      }
    }
  };

  const handleConfirmLoadoutPlaceHolder = async () => {
    const agents = await agent.all();
    const defaultAgent = agents.find((agent) => agent.default === 1);

    setConfirmLoadoutPlaceHolder(false);
    const loadoutdata = {
      data: {
        reference: defaultData["Dispatch ID"],
        agent_id: defaultData.agent_id || defaultAgent ? defaultAgent.id : null,
      },
    };

    const result = await loadout.create(loadoutdata);

    for (let index = 0; index < dispatchPallets.length; index++) {
      const element = dispatchPallets[index];
      const loadoutdetaildata = {
        data: {
          loadout_id: result[0],
          barcode: element.bar_Barcode,
        },
      };
      await loadoutdetail.create(loadoutdetaildata);
    }
  };

  const handleConfirmLoadoutPlaceHolderCancel = () => {
    setConfirmLoadoutPlaceHolder(false);
  };

  const handleForceReload = async () => {
    await loadData();
  };

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabValue(newValue);
  };

  const handleIssuesCount = (issuescount) => {
    setCompareIssuesCount(issuescount);
  };

  const handleCreateInvoice = () => {
    setRedirectInvoice(true);
  };

  if (redirectInvoice) return <Redirect to={`/invoice/${defaultData["loadout_id"]}`} />;

  return (
    <div>
      {confirmLoadoutPlaceholder && (
        <Confirmation
          isOpen
          handleClose={handleConfirmLoadoutPlaceHolderCancel}
          handleConfirm={handleConfirmLoadoutPlaceHolder}
          title={`Problems Found`}
          body={`No Loadout found for barcodes on EDI`}
        />
      )}
      <AppBar position="static">
        <Tabs value={tabValue} onChange={handleTabChange} aria-label="Dispatch Allocation">
          <Tab label="Dispatch Details" {...a11yProps(0)} />
          <Tab
            label={
              <Badge badgeContent={compareIssuesCount} color="secondary" style={{}}>
                EDI vs Loadout
              </Badge>
            }
            {...a11yProps(3)}
          />
        </Tabs>
      </AppBar>
      {loading ? <LinearProgress color="secondary" style={{ marginTop: "10px", height: "5px" }} /> : <div style={{ height: "15px" }}></div>}
      <div style={{ height: "calc(100vh - 150px)", overflow: "auto" }}>
        <Form
          initialValues={{
            ...defaultData,
            dispatch_containertype: defaultData.dispatch_containerno && defaultData.dispatch_containerno.length > 0 ? ContainerTypes.C12 : ContainerTypes.BB,
            agent_id: defaultData.agent_id ? defaultData.agent_id : agentdefault ? agentdefault.id : defaultData.agent_id,
            stockdgrouped_po: defaultData.stockdgrouped_po === "" || !defaultData.stockdgrouped_po ? selectedDispatch.client_po : defaultData.stockdgrouped_po,
          }}
          onSubmit={async (values: any) => {
            setLoading(true);
            const submitData = { ...values };
            await onSubmit(submitData, dispatchPallets, dispatchPalletsLoadouts);
            setLoading(false);

            switch (values.close) {
              case FormState.ShowConfirmation:
                handleShowConfirmation();
                handleClose();
                break;
              case FormState.CreateInvoice:
                handleCreateInvoice();
                break;
              case FormState.Close:
                handleClose();
                break;
              case FormState.Save:
                updateSnack({ show: true, color: "green", message: "Dispatch saved sucessfully" });
                break;
              default:
                handleClose();
                break;
            }
          }}
          validate={(values: any) => {
            let errors = {};
            if (dispatchInvoiceNumbers.find((dispatch: any) => dispatch.invoicenumber == values.dispatch_invoicenumber && defaultData.dispatch_id != dispatch.id)) {
              errors = { dispatch_invoicenumber: "Invoice Number already exists" };
            }
            return errors;
          }}
          render={({ handleSubmit, form, values }: any) => (
            <form onSubmit={handleSubmit} id="dispatchForm">
              <TabPanel value={tabValue} index={0}>
                <DispatchAcceptDialogForm form={form} values={values} loading={loading} handleClose={handleClose} dispatchDisabled={incompleteLoadout} />
              </TabPanel>
            </form>
          )}
        />
        <TabPanel value={tabValue} index={1}>
          {dispatchPallets && dispatchPallets.length > 0 && (
            <DispatchAcceptDialogCompare
              selectedDispatch={selectedDispatch}
              dispatchPallets={dispatchPallets}
              dispatchPalletsLoadouts={dispatchPalletsLoadouts}
              handleForceReload={handleForceReload}
              handleIssuesCount={handleIssuesCount}
            />
          )}
        </TabPanel>
      </div>
    </div>
  );
};

export default withStyles(styles)(AcceptDispatchDialogUnstyled);
