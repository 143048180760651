export const validatePassword = (enteredPassword: string): [boolean, string] => {
  const uppercaseRegExp = /(?=.*?[A-Z])/;
  const lowercaseRegExp = /(?=.*?[a-z])/;
  const digitsRegExp = /(?=.*?[0-9])/;
  const specialCharRegExp = /(?=.*?[#?!@$%^&*-])/;
  const minLengthRegExp = /.{8,}/;

  let message = "";
  let isValid = false;

  if (enteredPassword.length === 0) {
    message = "Password is empty";
  } else if (!uppercaseRegExp.test(enteredPassword)) {
    message = "Password needs at least one Uppercase";
  } else if (!lowercaseRegExp.test(enteredPassword)) {
    message = "Password needs at least one Lowercase";
  } else if (!digitsRegExp.test(enteredPassword)) {
    message = "Password needs at least one digit";
  } else if (!specialCharRegExp.test(enteredPassword)) {
    message = "Password needs at least one Special Character";
  } else if (!minLengthRegExp.test(enteredPassword)) {
    message = "Password needs minumum 8 characters";
  } else {
    message = "";
    isValid = true;
  }

  return [isValid, message];
};

export const validateUsername = (enteredUsername: string): [boolean, string] => {
  const minLengthRegExp = /.{3,}/;

  let message = "";
  let isValid = false;

  if (enteredUsername.length === 0) {
    message = "Username is empty";
  } else if (!minLengthRegExp.test(enteredUsername)) {
    message = "Username needs minumum 3 characters";
  } else {
    message = "";
    isValid = true;
  }

  return [isValid, message];
};
