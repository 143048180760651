import React from "react";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";

import LinearProgress from "@material-ui/core/LinearProgress";
import IconButton from "@material-ui/core/IconButton";

import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Toolbar from "@material-ui/core/Toolbar";
import orange from "@material-ui/core/colors/orange";

import IconClose from "@material-ui/icons/Close";

import { Form } from "react-final-form";

import DispatchAcceptForm from "./invoiceAcceptForm";

import { SaleDispatchJoinedType, saleBarcodesByDispatchID, SaleBarcodesByDispatchIDType } from "../lib/api/sale";
import Confirmation from "../lib/components/confirmation";

import { GridColumnsPallets } from "./invoicetablesetuppallets";
import { GridColumnsGroupedFruitSpec } from "./invoiceTableSetupGroupedFruitSpec";

import numeral from "numeral";

import { isNullOrUndef } from "../lib/helpers/isNullOrUndef";
import { isEquivalentObject } from "../lib/helpers/string_methods";
import { getSellingTermsReadyForCombo } from "../lib/api/producersellingterms";

import Grid from "../lib/components/grid";

import ReportMenu from "./reportsMenu";
import BigNumber from "bignumber.js";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      height: "100%",
    },
    container: {
      display: "grid",
      gridTemplateColumns: "100%",
      gridTemplateRows: "320px auto 40px 45px",
      gridTemplateAreas: `
      "header"
      "details"
      "totals"
      "actions"
      `,
      gridGap: "10px",
      height: "calc(100vh - 180px)",
    },
    divHeader: {
      gridArea: "header",
    },
    divDetails: {
      gridArea: "details",
      paddingLeft: "5px",
      width: "calc(100vw - 200px)",
      position: "relative",
    },
    divTotals: {
      gridArea: "totals",
      width: "calc(100vw - 194px)",
    },
    divActions: {
      gridArea: "actions",
    },
    printButton: {
      margin: "2px",
      color: "white",
      backgroundColor: orange[700],
      "&:hover": {
        backgroundColor: orange[900],
      },
    },
    sellingPriceCell: {
      "& > .rdg-cell:nth-child(11)": {
        textAlign: "right",
      },
    },
    sellingPriceCellGrouped: {
      "& > .rdg-cell:last-child": {
        textAlign: "right",
      },
    },
    toolbar: {
      position: "absolute",
      left: 0,
      top: 0,
      height: "60px",
      width: "100%",
      display: "flex",
      flexDirection: "row",
      gap: "10px",
    },
  });

type AcceptProps = {
  handleClose: any;
  onSubmit: any;
  defaultData: SaleDispatchJoinedType;
  submitting: boolean;
} & WithStyles<typeof styles>;

export class AcceptUnstyled extends React.Component<AcceptProps, any> {
  state = {
    handleClose: undefined,
    onSubmit: undefined,
    defaultData: undefined,
    loading: true,
    saleDetails: [{}],
    saleDetailsGrouped: [{}],
    gridHeight: 250,
    rowSelectedIdx: undefined,
    cellSelected: undefined,
    invoiceTotal: 0,
    updateFruitSpecSelling: false,
    dirty: false,
    confirmDirty: false,
    hideLoaded: false,
    sellingTerms: [],
  };

  constructor(props) {
    super(props);
    this.state.handleClose = props.handleClose;
    this.state.onSubmit = props.onSubmit;
    this.state.defaultData = props.defaultData;
  }

  componentDidMount() {
    this.loadSaleDetails().then(() => {
      const mainClientheight = document.getElementById("divDetails");
      this.setState({ gridHeight: mainClientheight && mainClientheight.clientHeight && mainClientheight.clientHeight > 0 ? mainClientheight.clientHeight - 80 : 250 });
    });
  }

  loadSaleDetails = async () => {
    this.setState({ loading: true });

    await saleBarcodesByDispatchID(this.state.defaultData.dispatch_id).then((data) => {
      const totalled = data.map((dataItem: SaleBarcodesByDispatchIDType) => {
        return { ...dataItem, total: dataItem.barcode_nocartons * (dataItem.stockdetail_sellingprice ? dataItem.stockdetail_sellingprice : 0) };
      });
      const invoiceTotal = totalled.reduce((a, b) => a + b["total"], 0);
      const grouped = this.getGroupedFruitSpec(totalled);
      const sellingTerms = getSellingTermsReadyForCombo();
      this.setState({ saleDetails: totalled, saleDetailsGrouped: grouped, invoiceTotal: invoiceTotal, loading: false, sellingTerms }, () => {});
    });
  };

  getGroupedFruitSpec = (arr) => {
    const keyProps = ["barcode_commoditycode", "barcode_varietycode", "barcode_packcode", "barcode_markcode", "barcode_gradecode", "barcode_countcode", "barcode_inventorycode"];
    const kvArray = arr.map((entry) => {
      const key = keyProps.map((k) => entry[k]).join("|");
      return [key, entry];
    });

    const map = new Map(kvArray);
    const mapArr = Array.from(map.values());
    const mapArrNew = mapArr.map((item) => {
      item["new_palletsize"] = arr.reduce((acc, curr) => {
        if (
          curr["barcode_commoditycode"] == item["barcode_commoditycode"] &&
          curr["barcode_varietycode"] == item["barcode_varietycode"] &&
          curr["barcode_packcode"] == item["barcode_packcode"] &&
          curr["barcode_markcode"] == item["barcode_markcode"] &&
          curr["barcode_gradecode"] == item["barcode_gradecode"] &&
          curr["barcode_countcode"] == item["barcode_countcode"] &&
          curr["barcode_inventorycode"] == item["barcode_inventorycode"]
        ) {
          acc += Number(curr["barcode_palletsize"]);
        }
        return acc;
      }, 0);
      item["new_nocartons"] = arr.reduce((acc, curr) => {
        if (
          curr["barcode_commoditycode"] == item["barcode_commoditycode"] &&
          curr["barcode_varietycode"] == item["barcode_varietycode"] &&
          curr["barcode_packcode"] == item["barcode_packcode"] &&
          curr["barcode_markcode"] == item["barcode_markcode"] &&
          curr["barcode_gradecode"] == item["barcode_gradecode"] &&
          curr["barcode_countcode"] == item["barcode_countcode"] &&
          curr["barcode_inventorycode"] == item["barcode_inventorycode"]
        ) {
          acc += Number(curr["barcode_nocartons"]);
        }
        return acc;
      }, 0);
      return item;
    });
    return mapArrNew;
  };

  handleCloseClick = () => {
    if (this.state.dirty) {
      this.setState({ confirmDirty: true });
    } else {
      this.state.handleClose();
    }
  };

  handleCloseClickConfirm = () => {
    this.setState({ confirmDirty: false, dirty: false }, () => {
      this.state.handleClose();
    });
  };

  handleCloseClickClose = () => {
    this.setState({ confirmDirty: false }, () => {});
  };

  handleFruitSpecSellingUpdate = () => {
    this.setState({ updateFruitSpecSelling: !this.state.updateFruitSpecSelling }, () => {});
  };

  handleKeyDown = (event, gridRef) => {
    const enterKey = 13;
    const tabKey = 9;

    if (event.which === enterKey || event.which === tabKey) {
      event.preventDefault();

      if (!this.state.updateFruitSpecSelling) {
        setTimeout(() => {
          gridRef.selectCell({ idx: 15, rowIdx: this.state.rowSelectedIdx }, true);
        }, 200);
      } else {
        setTimeout(() => {
          gridRef.selectCell({ idx: 9, rowIdx: this.state.rowSelectedIdx }, true);
        }, 200);
      }
      this.setState({ rowSelectedIdx: this.state.rowSelectedIdx + 1 });
    }
  };

  handleRowsUpdated = (grid: any, rows: any, rowsChangedData: any) => {
    if (rowsChangedData.column.key === "purchase_deal_type") {
      const index = rowsChangedData.indexes[0];
      const editedRow = rows[index];

      const newRows = this.state.saleDetails.map((curr: any) => ({
        ...curr,
        purchase_deal_type: curr.producer_code == editedRow.producer_code ? editedRow.purchase_deal_type : curr.purchase_deal_type,
      }));
      this.setState({ saleDetails: newRows });
    } else {
      const newRows = this.state.saleDetails.map((row) => {
        const exists = rows.find((dataRow) => {
          if (this.state.updateFruitSpecSelling) {
            if (
              row["barcode_commoditycode"] == dataRow.barcode_commoditycode &&
              row["barcode_varietycode"] == dataRow.barcode_varietycode &&
              row["barcode_packcode"] == dataRow.barcode_packcode &&
              row["barcode_markcode"] == dataRow.barcode_markcode &&
              row["barcode_gradecode"] == dataRow.barcode_gradecode &&
              row["barcode_countcode"] == dataRow.barcode_countcode &&
              row["barcode_inventorycode"] == dataRow.barcode_inventorycode
            ) {
              return { ...row, stockdetail_sellingprice: dataRow.stockdetail_sellingprice };
            }
          } else {
            if (row["barcode_id"] == dataRow.barcode_id) {
              return { ...row, stockdetail_sellingprice: dataRow.stockdetail_sellingprice };
            }
          }
        });
        if (exists) {
          return {
            ...row,
            stockdetail_sellingprice: exists.stockdetail_sellingprice,
          };
        }
        return row;
      });
      const totalled = newRows.map((dataItem: SaleBarcodesByDispatchIDType) => {
        return {
          ...dataItem,
          total: new BigNumber(dataItem.barcode_nocartons)
            .times(new BigNumber(dataItem.stockdetail_sellingprice || 0))
            .decimalPlaces(2)
            .toNumber(),
        };
      });
      const invoiceTotal = totalled.reduce((a, b) => a + b["total"], 0);
      const grouped = this.getGroupedFruitSpec(totalled);
      this.setState({ saleDetails: totalled, saleDetailsGrouped: grouped, invoiceTotal: invoiceTotal });
    }
  };

  render() {
    const { classes } = this.props;
    const totalRows = ["barcode_nocartons", "barcode_palletsize", "stockdetail_sellingprice", "total"];
    const totalRowsGrouped = ["stockdetail_sellingprice", "new_palletsize", "new_nocartons"];

    return (
      <div className={classes.root}>
        <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
          <h3 style={{ flexGrow: 98 }}>Generate Invoice</h3>
          <div style={{ flexGrow: 1, height: "39px", marginTop: "10px" }}></div>
          <IconButton aria-label="close" color="secondary" style={{ flexGrow: 1 }} onClick={this.handleCloseClick}>
            <IconClose />
          </IconButton>
        </div>
        {this.props.submitting && <LinearProgress />}
        {this.state.loading ? (
          <LinearProgress />
        ) : (
          <div style={{ textAlign: "left" }}>
            <div>
              <Form
                initialValues={{
                  ...this.state.defaultData,
                  sale_terms_id: isNullOrUndef(this.state.defaultData.sale_terms_id) ? -1 : this.state.defaultData.sale_terms_id,
                }}
                onSubmit={(values) => {
                  const submitData = { ...values };
                  this.state.onSubmit(submitData, this.state.saleDetails);
                }}
                validate={(values) => {
                  let errors = {};
                  if (
                    !isEquivalentObject(
                      { ...values },
                      { ...this.state.defaultData, sale_terms_id: isNullOrUndef(this.state.defaultData.sale_terms_id) ? -1 : this.state.defaultData.sale_terms_id },
                    )
                  ) {
                    if (!this.state.dirty) {
                      this.setState({ dirty: true });
                    }
                  }
                  return errors;
                }}
                render={(props: any) => {
                  const { handleSubmit, form, submitting, pristine, values } = props;
                  return (
                    // <form onSubmit={handleSubmit} onKeyPress={this.handleKeyDown}>
                    <div className={classes.container}>
                      <div className={classes.divHeader}>
                        <DispatchAcceptForm values={values} form={form} />
                      </div>
                      <div id="divDetails" className={classes.divDetails}>
                        <Toolbar className={classes.toolbar}></Toolbar>
                        {!this.state.updateFruitSpecSelling && (
                          <Grid
                            loading={false}
                            data={this.state.saleDetails}
                            GridColumns={(data, filters, columnArrangement, columnsWidth) =>
                              GridColumnsPallets(data, filters, columnArrangement, columnsWidth, this.state.sellingTerms)
                            }
                            forceHeight={this.state.gridHeight}
                            handleChange={this.handleRowsUpdated}
                            rowHeight={30}
                            clearFilters={"invoicepallets"}
                            handleGridKeyDown={this.handleKeyDown}
                            selectedRowChange={(selectedRow: any, colums: { selectedColumn: number; columnsLength: number }) => {
                              this.setState({ rowSelectedIdx: selectedRow.rowIdx, cellSelected: colums.selectedColumn });
                            }}
                            totalRowColumns={totalRows}
                            fill={true}
                          />
                        )}
                        {this.state.updateFruitSpecSelling && (
                          <Grid
                            loading={false}
                            data={this.state.saleDetailsGrouped}
                            GridColumns={(data, filters, columnArrangement, columnsWidth) => GridColumnsGroupedFruitSpec(data, filters, columnArrangement, columnsWidth)}
                            forceHeight={this.state.gridHeight}
                            handleChange={this.handleRowsUpdated}
                            rowHeight={30}
                            clearFilters={"invoicepalletsgrouped"}
                            handleGridKeyDown={this.handleKeyDown}
                            selectedRowChange={(selectedRow: any, colums: { selectedColumn: number; columnsLength: number }) =>
                              this.setState({ rowSelectedIdx: selectedRow.rowIdx, cellSelected: colums.selectedColumn })
                            }
                            totalRowColumns={totalRowsGrouped}
                            fill={true}
                          />
                        )}
                      </div>
                      <div className={classes.divTotals}>
                        <div style={{ float: "left" }}>
                          <Typography component="div">
                            <Box fontWeight="fontWeightBold" m={1} fontSize={24}>
                              {`Total: ${isNullOrUndef(this.state.defaultData.currency_code) ? "ZAR" : this.state.defaultData.currency_code} ${numeral(
                                this.state.invoiceTotal,
                              ).format("0,0.00")}`}
                            </Box>
                          </Typography>
                        </div>
                        <div style={{ float: "right" }}>
                          <FormControlLabel
                            control={<Checkbox checked={this.state.updateFruitSpecSelling} onChange={this.handleFruitSpecSellingUpdate} name="checkedB" color="primary" />}
                            label="SHOW GROUPED FRUITSPEC GRID"
                          />
                        </div>
                      </div>
                      <div className={classes.divActions}>
                        <div style={{ float: "right", marginRight: "50px" }}>
                          <ReportMenu printHandler={(reportTypeRequested) => this.state.onSubmit({ ...values }, this.state.saleDetails, true, reportTypeRequested)} />
                          <Button name="submit" type="submit" variant="contained" color="primary" style={{ margin: "2px" }} disabled={false} onClick={handleSubmit}>
                            Save And Close
                          </Button>
                          <Button name="close" variant="contained" color="secondary" style={{ margin: "2px" }} onClick={this.handleCloseClick}>
                            Back
                          </Button>
                        </div>
                      </div>
                    </div>
                    // </form>
                  );
                }}
              />
            </div>
          </div>
        )}
        {this.state.confirmDirty && (
          <Confirmation
            isOpen={true}
            handleClose={this.handleCloseClickClose}
            handleConfirm={this.handleCloseClickConfirm}
            title="Close with saving?"
            body="Are you sure you want to close without saving?"
          ></Confirmation>
        )}
      </div>
    );
  }
}

export default withStyles(styles)(AcceptUnstyled);
