import React, { FC } from "react";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";

import Grid from "../../lib/components/grid";
import { GridColumns } from "./errorsgridsetup";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1),
      width: "550px",
    },
    gridWrapper: {
      position: "relative",
    },
    toolbar: {
      position: "absolute",
      left: 0,
      top: 0,
      height: "60px",
      width: "100%",
    },
  });

type ErrorsGridProps = {
  data: { client: string; error: string }[];
  handleClose(): void;
} & WithStyles<typeof styles>;

const ErrorsGridUnstyled: FC<ErrorsGridProps> = ({ classes, data, handleClose }) => {
  return (
    <div className={classes.root}>
      <h3>Errors were identified for the specified client and were not included in the export.</h3>
      <div className={classes.gridWrapper}>
        <Toolbar className={classes.toolbar}>
          <Button color="secondary" variant="contained" onClick={handleClose}>
            Close
          </Button>
        </Toolbar>
        <Grid loading={false} data={data} GridColumns={GridColumns} clearFilters="errorsgrid" />
      </div>
    </div>
  );
};

export const ErrorsGrid = withStyles(styles)(ErrorsGridUnstyled);
