import React from "react";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";

import Tooltip from "@material-ui/core/Tooltip";
import Button from "@material-ui/core/Button";

import IconAdd from "@material-ui/icons/Add";

import Grid from "../../lib/components/grid";
import Confirmation from "../../lib/components/confirmation";

import { createPack, pack } from "../../lib/api/pack";
import { GridColumns } from "./packgridsetup";

import PackEditForm from "./packform";

import { SnackContext } from "../../lib/context/SnackContext";
import { GenerateErrorMessage } from "../../lib/helpers/string_methods";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1),
      width: "100%",
      height: "100%",
      position: "relative",
    },
    inline: {
      display: "inline",
      position: "absolute",
      marginTop: "7px",
      marginLeft: "7px",
    },
  });

type PackProps = {} & WithStyles<typeof styles>;

export class PackUnstyled extends React.Component<PackProps, any> {
  state = {
    classes: undefined,
    data: [],
    confirmRemoveID: undefined,
    confirmEditID: undefined,
    loading: true,
  };

  constructor(props) {
    super(props);
    this.state.classes = props.classes;
  }

  static contextType = SnackContext;
  context!: React.ContextType<typeof SnackContext>;

  componentDidMount() {
    this.loadData();
  }

  loadData = () => {
    pack.all().then((data) => {
      const dataSorted = data.sort((a, b) => {
        if (a.code > b.code) return 1;
        if (a.code < b.code) return -1;
        return 1;
      });
      this.setState({ loading: false, data: dataSorted }, () => {});
    });
  };

  handleCreate = () => {
    this.setState({ confirmEditID: "0" });
  };

  handleEdit = (id) => {
    this.setState({ confirmEditID: id });
  };

  handleEditClose = () => {
    this.setState({ confirmEditID: undefined });
  };

  handleEditConfirm = (e, item) => {
    e.preventDefault();

    const id = item["id"];
    delete item["id"];

    if (id == "0") {
      createPack(item)
        .then(() => this.loadData())
        .catch((error) => {
          const err = GenerateErrorMessage(error, "failed to retrieve data");
          this.context.updateSnack({ show: true, color: "red", message: err });
        });
    } else {
      pack.update(id, { data: { ...item } }).then(() => this.loadData());
    }
    this.handleEditClose();
  };

  handleRemove = async (id) => {
    this.setState({ confirmRemoveID: id });
  };

  handleRemoveClose = () => {
    this.setState({ confirmRemoveID: undefined });
  };

  handleRemoveConfirm = async () => {
    await pack
      .remove(this.state.confirmRemoveID)
      .then(() => {
        this.handleRemoveClose();
        this.loadData();
      })
      .catch((error) => {
        const err = GenerateErrorMessage(error, "failed to retrieve data");
        this.context.updateSnack({ show: true, color: "red", message: err });
      });
  };

  GridColumnsOverride = (column, row) => {
    return GridColumns(column, row, this.handleRemove, this.handleEdit);
  };

  render() {
    const { classes } = this.state;

    return (
      <div className={classes.root}>
        <div className={classes.inline}>
          <Tooltip title="Create new Pack">
            <div className={classes.inline}>
              <Button variant="contained" color="primary" onClick={this.handleCreate} style={{ marginBottom: "10px" }}>
                <IconAdd />
              </Button>
            </div>
          </Tooltip>
        </div>
        <Grid loading={this.state.loading} data={this.state.data} GridColumns={this.GridColumnsOverride} clearFilters={"packgridsetup"} />
        {this.state.confirmRemoveID && (
          <Confirmation
            isOpen={this.state.confirmRemoveID ? true : false}
            handleClose={this.handleRemoveClose}
            handleConfirm={this.handleRemoveConfirm}
            title="Remove selected Pack?"
            body="Are you sure you want to REMOVE the selected Pack Record?"
          ></Confirmation>
        )}
        {this.state.confirmEditID && (
          <Confirmation
            isOpen={this.state.confirmEditID ? true : false}
            handleClose={this.handleEditClose}
            handleConfirm={() => {}}
            title={this.state.confirmEditID == "0" ? "Creating Pack" : "Editing Pack"}
            body={undefined}
          >
            <PackEditForm onSubmit={this.handleEditConfirm} onClose={this.handleEditClose} id={this.state.confirmEditID} />
          </Confirmation>
        )}
      </div>
    );
  }
}

export default withStyles(styles)(PackUnstyled);
