import React from "react";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";

import Button from "@material-ui/core/Button";

import Grid from "../../lib/components/grid";
import { GridColumns } from "./linkedclientsgridsetup";
import TermsEditForm from "./termseditform";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1),
      width: "100%",
      height: "100%",
      position: "relative",
    },
    gridWrapper: {
      width: "600px",
    },
    actionButtons: {
      display: "flex",
      alignContent: "center",
      justifyContent: "flex-start",
    },
  });

type LinkedClientsProps = {
  data: any[];
  showLinkedClients: number;
  onClose(): void;
} & WithStyles<typeof styles>;

export class LinkedClientsUnstyled extends React.Component<LinkedClientsProps, any> {
  state = {
    data: [],
  };

  componentDidMount() {
    const filtered = this.props.data.filter((item) => item.terms_id === this.props.showLinkedClients);
    this.setState({ data: filtered });
  }

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <div className={classes.gridWrapper}>
          <div className={classes.actionButtons}>
            <Button variant="contained" color="secondary" onClick={this.props.onClose}>
              Close
            </Button>
          </div>
          <Grid
            loading={false}
            data={this.state.data}
            clearFilters={"linkedclientsgrid"}
            GridColumns={(data, filters, arrangement, columnsWidth) => GridColumns(data, filters, arrangement, columnsWidth)}
            forceHeight={200}
          />
          <TermsEditForm id={this.props.showLinkedClients} viewOnly />
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(LinkedClientsUnstyled);
