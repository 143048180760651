import { apiHeaders, client, request } from "./_base";

export type ProducerType = {
  id: number | string;
  code: string;
  name: string;
  created_at: Date;
  updated_at: Date;
  physical_address: string;
  postal_address: string;
  sales_person: string;
  sales_email: string;
  quality_person: string;
  quality_email: string;
  finance_person: string;
  finance_email: string;
  producer_telephone: string;
  producer_mobile: string;
  sales_phone: string;
  quality_phone: string;
  finance_phone: string;
  solasmethod2: number;
  fax_no: string;
  vat_no: string;
  active: 0 | 1 | null;
  last_updated_user: string;
};

const endpoint = "producer";

export const producers = client(endpoint, (item) => ({
  ...item,
}));

export const getProducerReadyForCombo = (puc = undefined) => {
  if (!puc) {
    return producers.all().then((result: [ProducerType]) => {
      const ready = result
        .sort((a, b) => {
          if (a.name > b.name) return 1;
          if (a.name < b.name) return -1;
          return 1;
        })
        .map((prod) => {
          return { value: prod.id, display: `${prod.code} - ${prod.name}`, data: prod };
        });
      return ready;
    });
  } else {
    return request(`${endpoint}/getbypuccode/${puc}`, {
      headers: apiHeaders(),
      method: "GET",
    })
      .then(async (result) => {
        if (result.status == 500) {
          const resp = await result.json();
          throw resp;
        }
        return result.json();
      })
      .then((result) => {
        const ready = result.data
          .sort((a, b) => a.name.localeCompare(b.name))
          .map((prod) => {
            return { value: prod.id, display: `${prod.code} - ${prod.name}`, data: prod };
          });
        return ready;
      });
  }
};

export const getProducerReadyForComboMappedForComboValue = async (puc) => {
  return request(`${endpoint}/getbypuccode/${puc}`, {
    headers: apiHeaders(),
    method: "GET",
  })
    .then(async (result) => {
      if (result.status == 500) {
        const resp = await result.json();
        throw resp;
      }
      return result.json();
    })
    .then((result) => {
      const ready = result.data
        .sort((a, b) => a.name.localeCompare(b.name))
        .map((prod) => {
          return { id: prod.code, value: prod.code, data: prod };
        });
      return ready;
    });
};

export const getProducerReadyForComboMapped = async () => {
  return producers.all().then((result: [ProducerType]) => {
    return result
      .sort((a, b) => {
        if (a.name > b.name) return 1;
        if (a.name < b.name) return -1;
        return 1;
      })
      .map((prod) => ({ value: prod.code, display: `${prod.code} - ${prod.name}`, data: prod }));
  });
};

export const getProducerByProducerCode = (code: string) =>
  request(`${endpoint}/getProducerByProducerCode/${code}`, {
    headers: apiHeaders(),
    method: "GET",
  }).then(async (result) => {
    if (result.status == 500) {
      const resp = await result.json();
      throw resp;
    }
    return result.json();
  });

export const getProducerByProducerId = async (id: number): Promise<{ data: ProducerType[] }> => {
  const result = await request(`${endpoint}/getProducerByProducerId/${id}`, {
    headers: apiHeaders(),
    method: "GET",
  });
  if (result.status == 500) {
    const resp = await result.json();
    throw resp;
  }
  return result.json();
};

export const getAllProducers = async () => {
  const result = await request(`${endpoint}/ext/getAllProducers`, {
    headers: apiHeaders(),
    method: "GET",
  });
  if (result.status == 500) {
    const resp = await result.json();
    throw resp;
  }
  return result.json();
};

export const getActiveProducers = async (): Promise<ProducerType[]> => {
  const result = await request(`${endpoint}/ext/getActiveProducers`, {
    headers: apiHeaders(),
    method: "GET",
  });
  const resp = await result.json();
  if (result.status == 500) {
    throw resp;
  }
  return resp.data;
};

export const getActiveProducersReadyForCombo = async (): Promise<{ value: string; display: string; data: ProducerType }[]> => {
  const producers = await getActiveProducers();
  return producers
    .sort((a, b) => {
      if (a.name > b.name) return 1;
      if (a.name < b.name) return -1;
      return 1;
    })
    .map((prod) => ({ value: prod.code, display: `${prod.code} - ${prod.name}`, data: prod }));
};

export const upsertProducer = async (item: Partial<ProducerType>) => {
  const content = JSON.stringify(item);
  const result = await request(`${endpoint}/ext/upsertProducer`, {
    method: "POST",
    headers: apiHeaders(content),
    body: content,
  });
  const resp = await result.json();
  if (result.status == 500) {
    throw resp;
  }
  return resp.data;
};
