import React from "react";

import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";

import CustomAutoCompleteFilter from "../../lib/components/customdatagridfilter/CustomFilter";
import { CellActionsFormatter } from "../../lib/helpers/CellActionsFormatter";
import { DateFormatter } from "../../lib/components/Formatters";
import { SellingTerms } from "../../lib/api/sellingterms";
import { DealType } from "../../lib/api/dealtype";
import { handleColumnsWidth, handleColumnsArrangement } from "../../lib/helpers/grid";

const cellWidthTiny = 40;
const cellWidthXtraSmall = 70;
const cellWidthSmall = 80;
const cellWidthSmallBig = 140;
const cellWidthMedium = 200;
// const cellWidthLarge = 250;
const cellWidthXtrLarge = 350;

const defaultColumnProperties = {
  resizable: true,
  sortable: true,
};

let currentSpace = true;
export const GridColumns = (data, filters, arrangement, columnsWidth, handleRemove, handleEdit) => {
  let postValue = "";

  if (data) {
    if (currentSpace) {
      postValue = " ";
    }
    currentSpace = !currentSpace;
  }

  const columns = [
    {
      key: "actions",
      name: "Actions",
      width: cellWidthSmall,
      formatter: ({ row }) => {
        const actions = clientActions(row, handleRemove, handleEdit);
        return (
          <div style={{ display: "flex", justifyContent: "center", gap: "5px" }}>
            <CellActionsFormatter actions={actions} />
          </div>
        );
      },
    },
    {
      key: "active",
      name: "Active" + postValue,
      width: cellWidthTiny,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"active"} data={data} section={"clientsgridsetup"} />;
      },
      formatter: ({ row }) => (
        <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
          {row.active === "Active" ? <CheckIcon style={{ color: "green" }} /> : <CloseIcon color="error" />}
        </div>
      ),
    },
    {
      key: "code",
      name: "Client Code" + postValue,
      width: cellWidthSmallBig,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"code"} data={data} section="clientsgridsetup" />;
      },
    },
    {
      key: "name",
      name: "Client Name" + postValue,
      width: cellWidthXtrLarge,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"name"} data={data} section="clientsgridsetup" />;
      },
    },
    {
      key: "consignees_codes",
      name: "Consignees Codes" + postValue,
      width: cellWidthSmallBig,
      filterable: false,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"finance_email"} data={data} section="clientsgridsetup" />;
      },
    },
    {
      key: "consignees_names",
      name: "Consignees Names" + postValue,
      width: cellWidthSmallBig,
      filterable: false,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"finance_email"} data={data} section="clientsgridsetup" />;
      },
    },
    {
      key: "registered_country",
      name: "Registered Country" + postValue,
      width: cellWidthSmallBig,
      filterable: false,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"registered_country"} data={data} section="clientsgridsetup" />;
      },
    },
    {
      key: "region",
      name: "Region" + postValue,
      width: cellWidthTiny,
      filterable: false,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"region"} data={data} section="clientsgridsetup" />;
      },
    },
    {
      key: "market",
      name: "Market" + postValue,
      width: cellWidthTiny,
      filterable: false,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"market"} data={data} section="clientsgridsetup" />;
      },
    },
    {
      key: "country",
      name: "Country" + postValue,
      width: cellWidthTiny,
      filterable: false,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"country"} data={data} section="clientsgridsetup" />;
      },
    },
    {
      key: "port_discharge",
      name: "Port DIS" + postValue,
      width: cellWidthSmallBig,
      filterable: false,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"port_discharge"} data={data} section="clientsgridsetup" />;
      },
    },
    {
      key: "port_final",
      name: "Port FIN" + postValue,
      width: cellWidthSmallBig,
      filterable: false,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"port_final"} data={data} section="clientsgridsetup" />;
      },
    },
    {
      key: "clearingagent_name",
      name: "Clearing Agent" + postValue,
      width: cellWidthSmallBig,
      filterable: false,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"clearingagent_name"} data={data} section="clientsgridsetup" />;
      },
    },
    {
      key: "payment_terms",
      name: "Payment Terms" + postValue,
      width: cellWidthSmallBig,
      filterable: false,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"payment_terms"} data={data} section="clientsgridsetup" />;
      },
    },
    {
      key: "trader",
      name: "Trader" + postValue,
      width: cellWidthTiny,
      filterable: false,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"trader"} data={data} section="clientsgridsetup" />;
      },
    },
    {
      key: "dealtype",
      name: "Deal Type" + postValue,
      width: cellWidthTiny,
      filterable: false,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"dealtype"} data={data} section="clientsgridsetup" />;
      },
      formatter: ({ row }) => <div>{DealType[row.dealtype]}</div>,
    },
    {
      key: "sellingterms",
      name: "Terms" + postValue,
      width: cellWidthTiny,
      filterable: false,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"sellingterms"} data={data} section="clientsgridsetup" />;
      },
      formatter: ({ row }) => <div>{SellingTerms[row.sellingterms]}</div>,
    },
    {
      key: "currency",
      name: "Currency" + postValue,
      width: cellWidthTiny,
      filterable: false,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"currency"} data={data} section="clientsgridsetup" />;
      },
    },
    {
      key: "bank",
      name: "Bank" + postValue,
      width: cellWidthSmallBig,
      filterable: false,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"bank"} data={data} section="clientsgridsetup" />;
      },
    },
    {
      key: "credit_limit",
      name: "Credit Limit" + postValue,
      width: cellWidthSmallBig,
      filterable: false,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"credit_limit"} data={data} section="clientsgridsetup" />;
      },
    },
    {
      key: "credit_limit_exp",
      name: "Credit Limit Exp" + postValue,
      width: cellWidthSmallBig,
      filterable: false,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"credit_limit_exp"} data={data} section="clientsgridsetup" />;
      },
      formatter: DateFormatter,
    },
    {
      key: "physical_address",
      name: "Address" + postValue,
      width: cellWidthXtrLarge,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"physical_address"} data={data} section="clientsgridsetup" />;
      },
    },
    {
      key: "postal_address",
      name: "Postal" + postValue,
      width: cellWidthXtrLarge,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"postal_address"} data={data} section="clientsgridsetup" />;
      },
    },
    {
      key: "client_telephone",
      name: "Telephone" + postValue,
      width: cellWidthSmallBig,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"client_telephone"} data={data} section="clientsgridsetup" />;
      },
    },
    {
      key: "client_mobile",
      name: "Mobile" + postValue,
      width: cellWidthSmallBig,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"client_mobile"} data={data} section="clientsgridsetup" />;
      },
    },
    {
      key: "sales_person",
      name: "Sales person" + postValue,
      width: cellWidthSmallBig,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"sales_person"} data={data} section="clientsgridsetup" />;
      },
    },
    {
      key: "sales_phone",
      name: "Sales phone" + postValue,
      width: cellWidthSmallBig,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"sales_phone"} data={data} section="clientsgridsetup" />;
      },
    },
    {
      key: "sales_email",
      name: "Sales email" + postValue,
      width: cellWidthSmallBig,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"sales_email"} data={data} section="clientsgridsetup" />;
      },
    },
    {
      key: "logistics_person",
      name: "Logistics person" + postValue,
      width: cellWidthSmallBig,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"logistics_person"} data={data} section="clientsgridsetup" />;
      },
    },
    {
      key: "logistics_phone",
      name: "Logistics phone" + postValue,
      width: cellWidthSmallBig,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"logistics_phone"} data={data} section="clientsgridsetup" />;
      },
    },
    {
      key: "logistics_email",
      name: "Logistics email" + postValue,
      width: cellWidthSmallBig,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"logistics_email"} data={data} section="clientsgridsetup" />;
      },
    },
    {
      key: "quality_person",
      name: "Quality person" + postValue,
      width: cellWidthSmallBig,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"quality_person"} data={data} section="clientsgridsetup" />;
      },
    },
    {
      key: "quality_phone",
      name: "Quality phone" + postValue,
      width: cellWidthSmallBig,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"quality_phone"} data={data} section="clientsgridsetup" />;
      },
    },
    {
      key: "quality_email",
      name: "Quality email" + postValue,
      width: cellWidthSmallBig,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"quality_email"} data={data} section="clientsgridsetup" />;
      },
    },
    {
      key: "finance_person",
      name: "Finance person" + postValue,
      width: cellWidthSmallBig,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"finance_person"} data={data} section="clientsgridsetup" />;
      },
    },
    {
      key: "finance_phone",
      name: "Finance phone" + postValue,
      width: cellWidthSmallBig,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"finance_phone"} data={data} section="clientsgridsetup" />;
      },
    },
    {
      key: "finance_email",
      name: "Finance email" + postValue,
      width: cellWidthSmallBig,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"finance_email"} data={data} section="clientsgridsetup" />;
      },
    },
    {
      key: "created_by",
      name: "Created By" + postValue,
      width: cellWidthSmall,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"created_by"} data={data} section="clientsgridsetup" />;
      },
    },
    {
      key: "edited_by",
      name: "Edited By" + postValue,
      width: cellWidthSmall,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"edited_by"} data={data} section="clientsgridsetup" />;
      },
    },
  ].map((c) => ({ ...c, ...defaultColumnProperties }));

  let columnsArranged = [];

  columnsArranged = handleColumnsArrangement(columnsArranged, arrangement, columns);
  columnsArranged = handleColumnsWidth(columnsArranged, columnsWidth, columns);

  return columnsArranged;
};

const clientActions = (client, handleRemove, handleEdit) => {
  return [
    {
      icon: (
        <span title="Edit Client">
          <EditIcon style={{ cursor: "pointer", color: "orange" }} />
        </span>
      ),
      callback: (e) => {
        handleEdit(client.id);
      },
    },
    {
      icon: (
        <span title="Remove Client">
          <DeleteIcon style={{ cursor: "pointer", color: "red" }} />
        </span>
      ),
      callback: (e) => {
        handleRemove(client.id);
      },
    },
  ];
};

export default function getCellActions(column, row, handleRemove, handleEdit) {
  if (column.key != "actions") {
    return null;
  }
  const cellActions = {
    actions: clientActions(row, handleRemove, handleEdit),
  };
  return cellActions[column.key];
}
