import { config } from "../../config";
import { client, apiHeaders, request } from "./_base";
import { LoadoutType } from "./loadout";

import { getFinancialYearSelected } from "./week";

const endpoint = "stock";

export const stock = client(endpoint, (item) => ({
  ...item,
}));

type DispatchStock = {
  "Ref No"?: string;
  "Consignee ID"?: string;
  userName?: string;
  Allocated?: number;
  "Pre Allocated"?: number;
  "Location Code"?: string;
  "Temp Tail"?: string;
};

export const updateStockByBarcodeID = async (barcode_id: number, item: DispatchStock) => {
  const content = JSON.stringify(item);
  const result = await request(`${endpoint}/updateByBarcode/${barcode_id}`, {
    method: "PUT",
    headers: apiHeaders(content),
    body: content,
  });
  if (result.status == 500) {
    const resp = await result.json();
    throw resp;
  }
  return result.json();
};

export const allocateStock = async (loadout: Partial<LoadoutType>, batchData: any[], loadoutDetail: any[]): Promise<void> => {
  const content = JSON.stringify({ loadout, batchData, loadoutDetail });
  const result = await request(`${endpoint}/ext/allocateStock`, {
    method: "PUT",
    headers: apiHeaders(content),
    body: content,
  });
  if (result.status == 500) {
    const resp = await result.json();
    throw resp.message;
  }
};

export const updateBarcodeDataByBarcodeId = async (dataToUpdate) => {
  const content = JSON.stringify(dataToUpdate);
  return await request(`${endpoint}/ext/updateBarcodeDataByBarcodeId`, {
    method: "PUT",
    headers: apiHeaders(content),
    body: content,
  }).then(async (result) => {
    return result;
  });
};

export const updateStockWeightByBarcodeID = async (barcode_id, item) => {
  const content = JSON.stringify(item);
  return request(`${endpoint}/updateWeightByBarcode/${barcode_id}`, {
    method: "PUT",
    headers: apiHeaders(content),
    body: content,
  }).then(async (result) => {
    if (result.status == 500) {
      const resp = await result.json();
      throw resp;
    }
    return result.json();
  });
};

export const getStockGroupedByReference = async (reflist) => {
  const content = JSON.stringify(reflist);
  return request(`${endpoint}/ext/getStockGroupedByReference`, {
    method: "PUT",
    headers: apiHeaders(content),
    body: content,
  })
    .then(async (result) => {
      if (result.status == 500) {
        const resp = await result.json();
        throw resp;
      }
      return result.json();
    })
    .then((data) => {
      return data;
    });
};

export const getStockAll = () =>
  request(`${endpoint}/ext/all`, {
    headers: apiHeaders(),
  })
    .then(async (result) => {
      if (result.status == 500) {
        const resp = await result.json();
        throw resp;
      }
      return result.json();
    })
    .then(({ data }) => {
      return data;
    });

export const getStockAvailableForLoadout = () =>
  request(`${endpoint}/ext/getStockAvailableForLoadout`, {
    headers: apiHeaders(),
  })
    .then(async (result) => {
      if (result.status == 500) {
        const resp = await result.json();
        throw resp;
      }
      return result.json();
    })
    .then(({ data }) => {
      return data;
    });

export const getStockGroupedByLoadout = (includeNew, includeLoaded, includeDispatched) =>
  request(`${endpoint}/ext/getStockGroupedByLoadout/${includeNew}/${includeLoaded}/${includeDispatched}`, {
    headers: apiHeaders(),
  })
    .then(async (result) => {
      if (result.status == 500) {
        const resp = await result.json();
        throw resp;
      }
      return result.json();
    })
    .then(({ data }) => {
      return data;
    });

export const getStockByReference = (reference, loaded) =>
  request(`${endpoint}/ext/getStockByReference/${reference}/${loaded}`, {
    headers: apiHeaders(),
  })
    .then(async (result) => {
      if (result.status == 500) {
        const resp = await result.json();
        throw resp;
      }
      return result.json();
    })
    .then(({ data }) => {
      return data;
    });

export const getStockPreviousPrices = async (fruitspec) => {
  const content = JSON.stringify(fruitspec);
  return request(`${endpoint}/ext/GetStockPreviousPrices/${getFinancialYearSelected()}`, {
    method: "PUT",
    headers: apiHeaders(content),
    body: content,
  }).then((result) => result.json());
};

export type StockType = {
  tempcode: string;
  temp: string;
  po: string;
  pod: string;
  poddescription: string;
  sellp: string;
  allocated: string;
  preAllocated: string;
  locationCode: string;
  age: string;
  ageAtLocation: string;
  commodityCode: string;
  varietyCode: string;
  targetMarket: string;
  targetCountry: string;
  producerId: string;
  farmNumber: string;
  gradeCode: string;
  countCode: string;
  packCode: string;
  markCode: string;
  noCartons: string;
  palletSize: string;
  inventoryCode: string;
  barcode: string;
  reference: string;
  consigneeId: string;
  clientName: string;
  pickingRef: string;
  intakeWaybill: string;
  phc: string;
  coldDate: string;
  inspectionAge: string;
  orchard: string;
  grossWeight: string;
  weight: string;
  inspectionDate: string;
  organization: string;
  originalIntakeDate: string;
  purchasePrice: string;
  targetRegion: string;
  temperature: string;
  userName: string;
  dateEntered: string;
  traderid: string;
  loadout_reference: string;
  loadout_exportNotificationReference: string;
  loadout_dateLoading: string;
  loadout_voyageNumber: string;
  loadout_portLoading: string;
  loadout_portLoading_id: string;
  loadout_portDischarge: string;
  loadout_portDischarge_id: string;
  loadout_portFinal: string;
  loadout_portFinal_id: string;
  loadout_shippingline: string;
  loadout_shippingline_id: string;
  loadout_vessel: string;
  loadout_vessel_id: string;
  stockdetail_barcode: string;
  loadout_barcode: string;
  loadout_specialinstruction: string;
  loadout_coldroom_id: string;
  loadout_coldroom_code: string;
  loadout_agent_id: string;
  loadout_agent_name: string;
  loadout_phytclean: string;
  stockdetail_id: string;
  loadout_id: number;
  dispatchid: string;
  qx_deleted: string;
  clientfinance_dealtype: number;
  barcode_id: number;
};

export const getAllShippingData = (financial_year: any) =>
  request(`${endpoint}/ext/getAllShippingData/${financial_year}`, {
    headers: apiHeaders(),
  })
    .then(async (result) => {
      if (result.status == 500) {
        const resp = await result.json();
        throw resp;
      }
      return result.json();
    })
    .then(({ data }) => {
      return data;
    });
