import React from "react";
import CustomAutoCompleteFilter from "../../lib/components/customdatagridfilter/CustomFilter";
import { FormatterNumber, FormatterNumber2Decimals } from "../../lib/components/Formatters";

const cellWidthTiny = 50;
const cellWidthSmall = 115;
const cellWidthMedium = 150;
const cellWidthLarge = 220;

const defaultColumnProperties = {
  resizable: true,
  sortable: true,
};

let currentSpace = true;

export const GridColumns = (data, filters, columnsWidth, arrangement) => {
  let postValue = "";

  if (data) {
    if (currentSpace) {
      postValue = " ";
    }
    currentSpace = !currentSpace;
  }

  const columns = [
    {
      key: "invoicenumber",
      name: "Invoice Number" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"invoicenumber"} data={data} section="systemstabilityinvoicevsdetails" />;
      },
    },
    {
      key: "totalcartons",
      name: "Total Cartons" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"totalcartons"} data={data} section="systemstabilityinvoicevsdetails" />;
      },
      formatter: FormatterNumber,
    },
    {
      key: "totalpallets",
      name: "Total Pallets" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"totalpallets"} data={data} section="systemstabilityinvoicevsdetails" />;
      },
      formatter: FormatterNumber2Decimals,
    },
    {
      key: "totalsale",
      name: "Total Sale" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"totalsale"} data={data} section="systemstabilityinvoicevsdetails" />;
      },
      formatter: FormatterNumber2Decimals,
    },
    {
      key: "detail_invoice",
      name: "Detail Invoice Number" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return (
          <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"detail_invoice"} data={data} section="systemstabilityinvoicevsdetails" />
        );
      },
    },
    {
      key: "detail_totalcartons",
      name: "Detail Total Cartons" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return (
          <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"detail_totalcartons"} data={data} section="systemstabilityinvoicevsdetails" />
        );
      },
      formatter: FormatterNumber,
    },
    {
      key: "detail_totalpallets",
      name: "Detail Total Pallets" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return (
          <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"detail_totalpallets"} data={data} section="systemstabilityinvoicevsdetails" />
        );
      },
      formatter: FormatterNumber2Decimals,
    },
    {
      key: "detail_totalsale",
      name: "Detail Total Sale" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return (
          <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"detail_totalsale"} data={data} section="systemstabilityinvoicevsdetails" />
        );
      },
      formatter: FormatterNumber2Decimals,
    },
  ].map((c) => ({ ...c, ...defaultColumnProperties }));

  const columnsArranged = [];

  if (arrangement && arrangement.length > 0) {
    arrangement.map((item) => {
      const indexOfItem = columns.indexOf(columns.find((col) => col.name.trim() == item.toString().trim()));
      if (indexOfItem != -1) {
        columnsArranged.push(columns[indexOfItem]);
      }
    });
  } else {
    columns.map((colItem) => {
      columnsArranged.push(colItem);
    });
  }

  if (columnsWidth && columnsWidth.length > 0) {
    for (let index = 0; index < columnsArranged.length; index++) {
      const element = columnsArranged[index];
      const colItem = columnsWidth.find((colwitem) => colwitem.name.trim() == element.name.trim());
      if (colItem) {
        element.width = colItem.width;
      }
    }
  }

  return columnsArranged;
};
