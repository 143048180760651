import React from "react";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";

import Grid from "../../lib/components/grid";
import { GridColumns } from "./failedimportsgridsetup";

import Button from "@material-ui/core/Button";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      height: "100%",
      width: "550px",
    },
    buttonWrapper: {
      display: "flex",
      justifyContent: "flex-end",
      paddingTop: "10px",
    },
  });

type FailedImportTypes = {
  classes: any;
  data: any[];
  handleClose(): void;
};

class FailedImportsUnstyled extends React.Component<FailedImportTypes, {}> {
  constructor(props) {
    super(props);
  }

  state = {};

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <Grid data={this.props.data} loading={false} clearFilters={"failedimportsgrid"} GridColumns={GridColumns} />
        <div className={classes.buttonWrapper}>
          <Button variant="contained" color="primary" onClick={this.props.handleClose}>
            Close
          </Button>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(FailedImportsUnstyled);
