import React from "react";

import CustomAutoCompleteFilter from "../../lib/components/customdatagridfilter/CustomFilter";
import { handleColumnsArrangement, handleColumnsWidth } from "../../lib/helpers/grid";

import Checkbox from "@material-ui/core/Checkbox";

const cellWidthSmall = 80;
const cellWidthMedium = 200;

const defaultColumnProperties = {
  resizable: true,
  sortable: true,
};

let currentSpace = true;
export const GridColumns = (data, filters, arrangement, columnsWidth, handleChangeIncludeValue) => {
  let postValue = "";

  if (data) {
    if (currentSpace) {
      postValue = " ";
    }
    currentSpace = !currentSpace;
  }

  const columns = [
    {
      key: "name",
      name: "Estimated Cost Group" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return (
          <CustomAutoCompleteFilter
            {...e}
            multiSelection={true}
            currentFilters={filters}
            fieldname={"producerMappingCode"}
            data={data}
            section={"producerfinanceestimatedcostgroups"}
          />
        );
      },
    },
    {
      key: "include",
      name: "Include" + postValue,
      width: cellWidthSmall,
      filterable: true,
      filterRenderer: (e) => {
        return (
          <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"currencyCode"} data={data} section={"producerfinanceestimatedcostgroups"} />
        );
      },
      formatter: ({ row, column }) => (
        <div>
          <Checkbox checked={row[column.key]} onChange={(e) => handleChangeIncludeValue(row.id, e.target.checked)} style={{ padding: 0 }} />
        </div>
      ),
    },
  ].map((c) => ({ ...c, ...defaultColumnProperties }));

  let columnsArranged = [];
  columnsArranged = handleColumnsArrangement(columnsArranged, arrangement, columns);
  columnsArranged = handleColumnsWidth(columnsArranged, columnsWidth, columns);

  return columnsArranged;
};
