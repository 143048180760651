import { ucr, UCRSetupType } from "../api/ucrsetup";

export const ucrNumberGenerator = (type, invoicenumber) => {
  return ucr.all().then((result) => {
    if (result && result.length > 0) {
      return (result[0].year || 0).toString().substring(3, 4) + result[0].swiftcountrycode + result[0].clientcode + "C" + type + invoicenumber + "S";
    }
    return "C" + "ZA" + "00000000" + "C" + type + invoicenumber + "S";
  });
  // set @result = (select '0' + swiftcountrycode + clientcode + 'C' + @type + @invoicenumber + 'S' from ucrsetup)
};
