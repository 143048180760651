import React from "react";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";

import { Row } from "react-data-grid";

import Button from "@material-ui/core/Button";
import Toolbar from "@material-ui/core/Toolbar";
import { Data } from "react-data-grid-addons";

import { GridColumns } from "./stockpricingupdategridsetup";
import { GridColumnsPrevious } from "./stockpricingupdategridpreviouspricessetup";

import { getStockPreviousPrices } from "../lib/api/stock";
import { clearFiltersForColumns } from "../lib/helpers/stickyfilters";
import Confirmation from "../lib/components/confirmation";
import Grid from "../lib/components/grid";

const styles = (theme: Theme) =>
  createStyles({
    root: {},
    container: {
      height: "100%",
      width: "100%",
    },
    actions: {
      marginRight: "-9px",
      marginTop: "-2px",
    },
    sellingPriceCell: {
      "& > .rdg-cell:last-child": {
        textAlign: "right",
      },
    },
    redRow: {
      color: "red",
    },
    normalRow: {
      color: "black",
    },
    toolbar: {
      position: "absolute",
      float: "right",
      right: "450px",
    },
  });

type StockPricingUpdateTableProps = {
  updateData: {}[];
  onAcceptPricing: any;
  onCancelPricing: any;
  onBack: any;
  setContractSellingPrices: any;
  minGridHeightSecond: number;
} & WithStyles<typeof styles>;

class StockPricingUpdateTableUnstyled extends React.Component<StockPricingUpdateTableProps, {}> {
  state = {
    updateData: [],
    onAcceptPricing: undefined,
    onCancelPricing: undefined,
    onBack: undefined,
    totalFeedback: undefined,
    gridRef: undefined,
    cellSelected: undefined,
    sorting: {},
    filters: {},
    groupBy: [],
    setContractSellingPrices: undefined,
    refresh: false,
    rowSelectedIdx: 0,
    showPreviousPrices: false,
    previouspricesData: [],
    changeApplied: false,
  };

  constructor(props) {
    super(props);
    this.state.updateData = props.updateData ? props.updateData : [{}];
    this.state.onAcceptPricing = props.onAcceptPricing;
    this.state.onCancelPricing = props.onCancelPricing;
    this.state.onBack = props.onBack;
    this.state.setContractSellingPrices = props.setContractSellingPrices;
  }

  componentDidMount() {
    if (this.state.updateData && this.state.updateData.length > 0) {
      const result = {
        cartons: 0,
        pallets: 0,
      };
      this.state.updateData.map((item) => {
        result.cartons += Number(item["sumcartons"]);
        result.pallets += Number(item["palletsize"]);
      });
      if (result.cartons > 0 || result.pallets > 0) {
        this.setState({ totalFeedback: result }, () => {});
      }
    }
  }

  handleChange = (grid, updated: any[]) => {
    const newRows = this.state.updateData.map((row) => {
      const exists = updated.find(
        (item) =>
          row.commodityCode == item.commodityCode &&
          row.countCode == item.countCode &&
          row.inventoryCode == item.inventoryCode &&
          row.gradeCode == item.gradeCode &&
          row.markCode == item.markCode &&
          row.packCode == item.packCode &&
          row.targetCountry == item.targetCountry &&
          row.targetMarket == item.targetMarket &&
          row.varietyCode == item.varietyCode &&
          row.palletsize == item.palletsize &&
          row.producerId == item.producerId &&
          row.sumcartons == item.sumcartons &&
          row.loadout_vessel == item.loadout_vessel &&
          row.currency_code == item.currency_code,
      );
      if (exists) return exists;
      return row;
    });
    this.setState({ updateData: newRows, changeApplied: true }, () => {});
  };

  handleKeyDown = (event, gridRef) => {
    if (event.which === 13 || event.which === 9) {
      event.preventDefault();

      const tagName = event.target.tagName && event.target.tagName.toLowerCase();

      if (tagName != "div") {
        gridRef.selectCell({ idx: this.state.cellSelected, rowIdx: this.state.rowSelectedIdx }, true);
      } else {
        this.setState({ rowSelectedIdx: this.state.rowSelectedIdx + 1 });
        setTimeout(() => {
          gridRef.selectCell({ idx: 16, rowIdx: this.state.rowSelectedIdx }, true);
        }, 200);
      }
    }
  };

  selectors = Data.Selectors;

  applyContractPricing = () => {
    const data = this.state.setContractSellingPrices();
    this.setState({ updateData: data, refresh: true });
  };

  clearFilters = (columnToClear = undefined) => {
    const keys = Object.keys(this.state.filters);
    clearFiltersForColumns(keys, "stockpricing");

    this.setState({ filters: {} });
  };

  handlePreviousPriceClick = (data) => {
    const newData = {
      consigneeId: data.consigneeId,
      commodityCode: data.commodityCode,
      varietyCode: data.varietyCode,
      gradeCode: data.gradeCode,
      countCode: data.countCode,
      packCode: data.packCode,
      markCode: data.markCode,
    };

    getStockPreviousPrices(newData).then((result) => {
      this.setState({ previouspricesData: result.data, showPreviousPrices: true });
    });
  };

  handlePreviousPriceClickConfirm = () => {
    this.setState({ showPreviousPrices: false });
  };

  handleCopyPreviousPrice = () => {
    const updated = this.state.updateData.map((item) => ({ ...item, sellingprice: item.previousprice }));
    this.setState({ updateData: updated }, () => {});
  };

  rowRenderer = (props, classes) => {
    const { row } = props;
    const dirty = row.rowDirty ? classes.redRow : classes.normalRow;
    return <Row {...props} className={dirty} />;
  };

  handleSelectedRowChange = (row, column) => {
    this.setState({ rowSelectedIdx: row.rowIdx, cellSelected: column.selectedColumn, columnsLength: column.columnsLength - 1 }); // minus one because index starts at 0
  };

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.container}>
        {this.state.showPreviousPrices && (
          <Confirmation
            isOpen={true}
            handleClose={this.handlePreviousPriceClickConfirm}
            handleConfirm={this.handlePreviousPriceClickConfirm}
            title={`All Previous Prices`}
            body={undefined}
          >
            <div style={{ width: "1200px" }}>
              <Grid
                loading={false}
                data={this.state.previouspricesData}
                GridColumns={GridColumnsPrevious}
                getCellActions={() => {
                  return null;
                }}
                rowHeight={25}
                forceHeight={600}
                clearFilters={"stockpricingprevious"}
              />
            </div>
          </Confirmation>
        )}
        <div>
          <Toolbar className={classes.toolbar}>
            <div className={classes.actions}>
              {this.state.totalFeedback && (
                <span style={{ marginRight: "10px" }}>{`Pallets: ${this.state.totalFeedback.pallets} / Cartons: ${this.state.totalFeedback.cartons}`}</span>
              )}
              {this.state.onBack && (
                <Button variant="contained" color="secondary" onClick={this.state.onBack} style={{ marginRight: "10px" }}>
                  back
                </Button>
              )}
              <Button variant="contained" color="primary" onClick={this.handleCopyPreviousPrice} style={{ marginRight: "5px" }} disabled={this.state.updateData.length === 0}>
                Copy Previous Price
              </Button>
              {!this.state.onBack && (
                <Button variant="contained" color="secondary" onClick={this.state.onCancelPricing} style={{ marginRight: "5px" }}>
                  cancel
                </Button>
              )}
              <Button
                disabled={
                  this.state.updateData.filter((item) => item.contractSellPrice != 0 && item.contractSellPrice != "" && item.sellingprice != item.contractSellPrice).length == 0
                }
                color={"primary"}
                style={{ marginRight: "5px" }}
                variant="contained"
                onClick={this.applyContractPricing}
              >
                Apply Contract Pricing
              </Button>
              <Button
                variant="contained"
                color="primary"
                style={{ backgroundColor: this.state.changeApplied && "green" }}
                onClick={() => this.state.onAcceptPricing(this.state.updateData)}
              >
                Accept Pricing
              </Button>
            </div>
          </Toolbar>
          <Grid
            fill
            data={this.state.updateData}
            clearFilters={"stockpricing"}
            GridColumns={(data, filters, columnsArrangement, columnsWidth) => GridColumns(data, filters, columnsArrangement, columnsWidth, this.handlePreviousPriceClick)}
            handleChange={this.handleChange}
            forceHeight={this.props.minGridHeightSecond}
            selectedRowChange={this.handleSelectedRowChange}
            handleGridKeyDown={this.handleKeyDown}
            rowRenderer={(props) => this.rowRenderer(props, classes)}
            totalRowColumns={["palletsize", "sumcartons"]}
          />
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(StockPricingUpdateTableUnstyled);
