import React from "react";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";

import Button from "@material-ui/core/Button";

import dateFormat from "date-fns/format";
import { Row } from "react-data-grid";
import Toolbar from "@material-ui/core/Toolbar";
import "react-splitter-layout/lib/index";
import "../lib/helpers/splitter.css";

import { DialogInformation } from "../lib/components/dialoginformation";
import { GridColumnsSales } from "./invoicetablesetupsales";

import { handlePrintPackingListPDF, handlePrintInvoice } from "../reports/printing";

import { GridColumnsPallets } from "./invoicetablesetuppallets";

import { SaleDispatchJoinedType, saleBarcodesByDispatchID, exportShipmentsSummary } from "../lib/api/sale";
import { getClientBillTypesReadyForCombo } from "../lib/api/clientbilltypes";

import Splitter from "m-react-splitters";
import "m-react-splitters/lib/splitters.css";
import { isNullOrUndef } from "../lib/helpers/isNullOrUndef";

import { SnackContext } from "../lib/context/SnackContext";

import classNames from "classnames";
import Grid from "../lib/components/grid";

import { GenerateErrorMessage } from "../lib/helpers/string_methods";
import { Reports } from "../lib/types";
import { DocumentsButton, DocumentsType } from "../lib/components/DocumentsButton";
import { GreenButton } from "../lib/components/ColorButtons";
import Confirmation from "../lib/components/confirmation";
import { CombinedInvoice } from "./combined";
import { GridColumnsProforma } from "./proformagridsetup";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      height: "100%",
      width: "100%",
    },
    mainSplitterDiv: {
      height: "100%",
      width: "100%",
      display: "grid",
    },
    extendedIconNormal: {
      marginRight: theme.spacing(1),
    },
    extendedIconRotate: {
      marginRight: theme.spacing(1),
      transform: "rotate(180deg)",
    },
    scrollToBottom: {
      position: "absolute",
      bottom: "20px",
      right: "20px",
      zIndex: 1000,
      opacity: "0.5",
      "&:hover": {
        opacity: "1",
      },
    },
    boldRow: {
      fontWeight: "bold",
    },
    greenRow: {
      color: "green",
    },
    redRow: {
      color: "red",
    },
    toolbar: {
      position: "absolute",
      float: "right",
      right: "500px",
    },
  });

type InvoiceTableProps = {
  data: {}[];
  onReload: any;
  onAccept: any;
  onRelease: any;
  isProforma: any;
} & WithStyles<typeof styles>;

class InvoiceTableUnstyled extends React.Component<InvoiceTableProps, {}> {
  state = {
    data: [{}],
    onReload: undefined,
    onAccept: undefined,
    onRelease: undefined,
    selectedRows: [],
    selectedSales: {},
    dialoginformation: undefined,
    minGridHeight: 0,
    minGridHeightSecond: 0,
    dataChanged: undefined,
    billtypesData: undefined,
    selectedCheckRows: [],
    isProforma: false,
    detailsLoading: false,
    showCombinedInvoice: false,
  };

  constructor(props) {
    super(props);
    this.state.data = props.data;
    this.state.onReload = props.onReload;
    this.state.onAccept = props.onAccept;
    this.state.onRelease = props.onRelease;
    this.state.isProforma = props.isProforma;
    this.loadData();
  }

  static contextType = SnackContext;
  context!: React.ContextType<typeof SnackContext>;

  loadData() {
    getClientBillTypesReadyForCombo().then((result) => {
      this.setState({ billtypesData: result });
    });
  }

  componentDidMount() {
    this.setGridHeights();
  }

  handleSelectedSalesOnAccept = () => {
    this.state.onAccept(this.state.selectedRows, this.state.selectedSales);
  };

  handleAvailableSalesDoubleClick = async (row: SaleDispatchJoinedType) => {
    // select row via checkbox
    this.handleRowClick(row);

    this.setState({ detailsLoading: true });
    try {
      const data = await saleBarcodesByDispatchID(row.dispatch_id.toString());
      this.setState({ selectedSales: row, selectedRows: data });
      if (row.camend_status != 0) {
        this.context.updateSnack({ show: true, color: "orange", message: "Invoice has been processed. Cannot be changed." });
      }
    } catch (error) {
      const err = GenerateErrorMessage(error, "failed to retrieve data");
      this.context.updateSnack({ show: true, color: "red", message: err });
    }
    this.setState({ detailsLoading: false });
  };

  selectedClear = () => {
    this.setState({ selectedRows: [], selectedSales: {} });
  };

  setGridHeights = () => {
    const primarysplit = document.getElementsByClassName("splitter horizontal");
    this.setState({ minGridHeight: primarysplit[0].children[0].clientHeight - 110, minGridHeightSecond: primarysplit[0].children[2].clientHeight - 110 });
  };

  handleInfoClose = () => {
    this.setState({ dialoginformation: undefined });
  };

  selectedRowRenderer = (props) => {
    return <Row {...props} onDoubleClickCapture={this.handleSelectedSalesOnAccept} />;
  };

  rowRenderer = (props, classes) => {
    const { row } = props;

    const color = row.rowDirty == 1 ? classes.redRow : row.sale_id && classes.greenRow;
    const fontWeight = this.state.selectedSales && this.state.selectedSales["dispatch_id"] == row["dispatch_id"] && classes.boldRow;

    return <Row {...props} onClickCapture={() => this.handleAvailableSalesDoubleClick(row)} className={classNames(color, fontWeight)} />;
  };

  filteredRows = [];
  handleFilteredRows = (rows: any[]) => {
    this.filteredRows = rows;
  };

  filteredRowsPallets = [];
  handleFilteredRowsPallets = (rows: any[]) => {
    this.filteredRowsPallets = rows;
  };

  handleChange = async (property: string, value: any) => {
    // check which specific row(s) has been updated
    let updatedRecords = value.filter((row) => row.rowUpdated === 1);

    // if updatedRecords is zero, that means the data has been updated using the `fill` procedure
    if (updatedRecords.length === 0) {
      updatedRecords = value;
    }

    const updated = this.state.data.reduce((arr: any[], row: any) => {
      const updatedRow = updatedRecords.find((item) => item.sale_invoicenumber == row.sale_invoicenumber);
      if (updatedRow) {
        arr.push(updatedRow);
      } else {
        arr.push(row);
      }
      return arr;
    }, []);
    this.setState({ data: updated, dataChanged: true });
  };

  handlePrint = (reportTypeRequested) => {
    const dispatchidSelected = [];
    if (this.state.isProforma) {
      if (this.state.selectedCheckRows && this.state.selectedCheckRows.length > 0) {
        for (let index = 0; index < this.state.selectedCheckRows.length; index++) {
          const element = this.state.selectedCheckRows[index];
          if (!dispatchidSelected.find((check) => check == element.dispatch_id)) {
            dispatchidSelected.push(element.dispatch_id);
          }
        });
      }
    } else {
      dispatchidSelected.push(this.state.selectedSales["dispatch_id"]);
    }

    const isProforma = this.state.isProforma;

    if ([Reports.Invoice, Reports.ConsigneeInvoice, Reports.ClientAndConsigneeInvoice].includes(reportTypeRequested)) {
      handlePrintInvoice(dispatchidSelected.join(","), reportTypeRequested, isProforma).catch((error) => {
        const err = GenerateErrorMessage(error, "Error printing Invoice");
        this.context.updateSnack({ show: true, color: "red", message: err });
      });
    } //
    else if ([Reports.PackingListPDF, Reports.PackingListConsigneePDF, Reports.PackingListClientAndConsigneePDF].includes(reportTypeRequested)) {
      handlePrintPackingListPDF(dispatchidSelected.join(","), reportTypeRequested, false, isProforma).catch((error) => {
        const err = GenerateErrorMessage(error, "Error printing Invoice");
        this.context.updateSnack({ show: true, color: "red", message: err });
      });
    } //
    else if ([Reports.InvoiceExcel, Reports.ConsigneeInvoiceExcel, Reports.ClientAndConsigneeInvoiceExcel].includes(reportTypeRequested)) {
      window.location.href = `/api/sale/ext/exportSaleInvoiceExcel/${dispatchidSelected.join(",")}?reportType=${reportTypeRequested}&isProforma=${isProforma}`;
    } //
    else if ([Reports.PackingListExcel, Reports.PackingListConsigneeExcel, Reports.PackingListClientAndConsigneeConsigneeExcel].includes(reportTypeRequested)) {
      window.location.href = `/api/sale/ext/exportPackingList/${dispatchidSelected.join(",")}?reportType=${reportTypeRequested}&isProforma=${isProforma}`;
    } //
    else if ([Reports.AddendumExcel].includes(reportTypeRequested)) {
      window.location.href = `/api/edistockpalletsdispatched/ext/printAddendumExcel/${dispatchidSelected[0]}`;
    }
  };

  handleRowClick = async (rowData) => {
    const exists = this.state.selectedCheckRows.find((selected) => selected.sale_invoicenumber == rowData.sale_invoicenumber);

    if (exists) {
      const filteredRows = this.state.selectedCheckRows.filter((sr) => sr.sale_invoicenumber != rowData.sale_invoicenumber);
      this.setState({ selectedCheckRows: filteredRows }, () => {});
    } else {
      this.setState({ selectedCheckRows: [...this.state.selectedCheckRows, rowData] }, () => {});
    }
  };

  // handlePrintExcel = () => {
  //   const dispatch_ids = this.filteredRows.map((item: any) => item.dispatch_id);
  //   window.location.href = `/api/sale/ext/clientShippingReport/${encodeURI(JSON.stringify(dispatch_ids.join(",")))}`;
  // };

  generateExportName = () => {
    if (Object.keys(this.state.selectedSales).length == 0) {
      return "";
    }
    let filename = "";
    try {
      const { sale_invoicenumber, dispatch_forwardagentref }: any = this.state.selectedSales;
      filename = `${sale_invoicenumber}_${dispatch_forwardagentref || "N/A"}_PACKING_LIST_DATA.xlsx`;
    } catch (error) {
      this.context.updateSnack({ show: true, color: "orange", message: error || "Filename error" });
    }
    return filename;
  };

  menuItems = [
    {
      title: "INVOICE",
      children: [
        {
          icon: DocumentsType.PDF,
          title: "CLIENT",
          options: [
            {
              title: "CLIENT INVOICE PDF",
              icon: DocumentsType.PDF,
              action: () => this.handlePrint(Reports.Invoice),
            },
            {
              title: "CLIENT INVOICE EXCEL",
              icon: DocumentsType.EXCEL,
              action: () => this.handlePrint(Reports.InvoiceExcel),
            },
          ],
        },
        {
          icon: DocumentsType.PDF,
          title: "CONSIGNEE",
          options: [
            {
              title: "CONSIGNEE INVOICE PDF",
              icon: DocumentsType.PDF,
              action: () => this.handlePrint(Reports.ConsigneeInvoice),
            },
            {
              title: "CONSIGNEE INVOICE EXCEL",
              icon: DocumentsType.EXCEL,
              action: () => this.handlePrint(Reports.ConsigneeInvoiceExcel),
            },
          ],
        },
        {
          icon: DocumentsType.PDF,
          title: "CLIENT & CONSIGNEE",
          options: [
            {
              title: "CLIENT & CONSIGNEE INVOICE PDF",
              icon: DocumentsType.PDF,
              action: () => this.handlePrint(Reports.ClientAndConsigneeInvoice),
            },
            {
              title: "CLIENT & CONSIGNEE INVOICE EXCEL",
              icon: DocumentsType.EXCEL,
              action: () => this.handlePrint(Reports.ClientAndConsigneeInvoiceExcel),
            },
          ],
        },
      ],
    },
    ...(!this.props.isProforma
      ? [
          {
            title: "PACKING LIST",
            children: [
              {
                icon: DocumentsType.PDF,
                title: "CLIENT",
                options: [
                  {
                    title: "CLIENT PACKING LIST PDF",
                    icon: DocumentsType.PDF,
                    action: () => this.handlePrint(Reports.PackingListPDF),
                  },
                  {
                    title: "CLIENT PACKING LIST EXCEL",
                    icon: DocumentsType.EXCEL,
                    action: () => this.handlePrint(Reports.PackingListExcel),
                  },
                ],
              },
              {
                icon: DocumentsType.PDF,
                title: "CONSIGNEE",
                options: [
                  {
                    title: "CONSIGNEE PACKING LIST PDF",
                    icon: DocumentsType.PDF,
                    action: () => this.handlePrint(Reports.PackingListConsigneePDF),
                  },
                  {
                    title: "CONSIGNEE PACKING LIST EXCEL",
                    icon: DocumentsType.EXCEL,
                    action: () => this.handlePrint(Reports.PackingListConsigneeExcel),
                  },
                ],
              },
              {
                icon: DocumentsType.PDF,
                title: "CLIENT & CONSIGNEE",
                options: [
                  {
                    title: "CLIENT & CONSIGNEE PACKING LIST INVOICE PDF",
                    icon: DocumentsType.PDF,
                    action: () => this.handlePrint(Reports.PackingListClientAndConsigneePDF),
                  },
                  {
                    title: "CLIENT & CONSIGNEE PACKING LIST INVOICE EXCEL",
                    icon: DocumentsType.EXCEL,
                    action: () => this.handlePrint(Reports.PackingListClientAndConsigneeConsigneeExcel),
                  },
                ],
              },
            ],
          },
          {
            title: "ADDENDUM",
            action: () => this.handlePrint(Reports.AddendumExcel),
            icon: DocumentsType.EXCEL,
          },
        ]
      : []),
  ];

  menuItemsArr = {
    1: {
      title: "Shipments Summary",
      action: async (data, filters) => {
        try {
          await exportShipmentsSummary(data);
        } catch (error) {
          const err = GenerateErrorMessage(error, "Error exporting report.");
          this.context.updateSnack({ show: true, color: "red", message: err });
        }
      },
    },
  };

  handleCombineInvoices = () => {
    this.setState({ showCombinedInvoice: !this.state.showCombinedInvoice });
  };

  handleProformaDump = () => {
    return `${dateFormat(new Date(), "yyyyMMdd")} Proforma Invoices`;
  };

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        {this.state.dialoginformation && (
          <DialogInformation
            isOpen
            handleClose={this.handleInfoClose}
            handleOK={this.handleInfoClose}
            title={this.state.dialoginformation.title}
            body={`${this.state.dialoginformation.body}`}
            showinput={false}
          />
        )}
        {this.state.showCombinedInvoice && (
          <Confirmation isOpen handleClose={() => this.handleCombineInvoices()} handleConfirm={() => this.handleCombineInvoices()} title={"COMBINE INVOICES"} body={undefined}>
            <CombinedInvoice />
          </Confirmation>
        )}
        <div id="main_splitter_div" className={classes.mainSplitterDiv}>
          <Splitter
            postPoned
            dispatchResize
            position="horizontal"
            primaryPaneMaxHeight="calc(100% - 150px)"
            primaryPaneHeight="calc(100% - 350px)"
            primaryPaneWidth="100%"
            onDragFinished={(e) => this.setGridHeights()}
          >
            <div id="primary_grid" style={{ marginLeft: "1px", position: "relative" }}>
              <Toolbar className={classes.toolbar}>
                <GreenButton variant="contained" onClick={this.handleCombineInvoices}>
                  Combine Invoices
                </GreenButton>
              </Toolbar>
              <Grid
                fill
                showFilterChips
                data={this.state.data}
                loading={false}
                forceHeight={this.state.minGridHeight}
                clearFilters={this.state.isProforma ? "invoiceProForma" : "invoice"}
                handleFilteredRows={this.handleFilteredRows}
                handleRefresh={this.state.onReload}
                rowRenderer={(props) => this.rowRenderer(props, classes)}
                GridColumns={(data, filters, columnArrangement, columnsWidth) =>
                  this.props.isProforma
                    ? GridColumnsProforma(data, filters, columnArrangement, columnsWidth, this.state.selectedCheckRows, this.handleRowClick)
                    : GridColumnsSales(
                        data,
                        filters,
                        columnArrangement,
                        columnsWidth,
                        this.state.billtypesData,
                        this.state.selectedCheckRows,
                        this.handleRowClick,
                        this.state.isProforma,
                      )
                }
                totalRowColumns={["barcode_nocartons", "barcode_palletsize", "total"]}
                handleChange={this.handleChange}
                menuItems={this.state.isProforma ? {} : this.menuItemsArr}
                exportName={this.handleProformaDump()}
                countRowColumns={["sale_invoicenumber"]}
              />
            </div>
            <div id="secondary_grid">
              <Toolbar className={classes.toolbar}>
                <DocumentsButton
                  menuItems={this.menuItems}
                  disabled={!this.state.selectedSales["sale_id"] || (this.state.isProforma && this.state.selectedCheckRows.length === 0)}
                />
                {this.state.selectedRows.length > 0 && !isNullOrUndef(this.state.selectedSales["sale_id"]) && (
                  <Button variant="contained" color="primary" onClick={() => this.selectedClear()} disabled={this.state.selectedRows.length == 0} style={{ marginRight: "10px" }}>
                    clear selected
                  </Button>
                )}
                {this.state.selectedRows.length > 0 && !isNullOrUndef(this.state.selectedSales["sale_id"]) && this.state.selectedSales["camend_status"] === 0 && (
                  <Button variant="contained" color="secondary" style={{ marginRight: "10px" }} onClick={() => this.state.onRelease(this.state.selectedSales)}>
                    REMOVE INVOICE
                  </Button>
                )}
                {this.state.selectedRows.length > 0 && this.state.selectedSales["camend_status"] === 0 && (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => this.state.onAccept(this.state.selectedRows, this.state.selectedSales)}
                    style={{ backgroundColor: "green" }}
                  >
                    {isNullOrUndef(this.state.selectedSales["sale_id"]) ? "create invoice" : "edit invoice"}
                  </Button>
                )}
              </Toolbar>
              <Grid
                fill={true}
                data={this.state.selectedRows}
                loading={this.state.detailsLoading}
                forceHeight={this.state.minGridHeightSecond}
                clearFilters={"invoicepallets"}
                handleFilteredRows={this.handleFilteredRowsPallets}
                rowRenderer={(props) => this.selectedRowRenderer(props)}
                GridColumns={(data, filters, columnArrangement, columnsWidth) => GridColumnsPallets(data, filters, columnArrangement, columnsWidth)}
                totalRowColumns={["barcode_nocartons", "barcode_palletsize", "stockdetail_sellingprice", "total", "grossWeight", "weight"]}
                exportName={this.generateExportName()}
              />
            </div>
          </Splitter>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(InvoiceTableUnstyled);
