import React from "react";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";

import Toolbar from "@material-ui/core/Toolbar";

import Grid from "../../lib/components/grid";
import { getSaleFundsAllocationBySaleFundsId } from "../../lib/api/salefundsallocation";

import { GridColumns } from "./allocationtablegridsetup";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1),
      width: "100%",
      position: "relative",
    },
    toolbar: {
      position: "absolute",
      left: 0,
      top: "8px",
      height: "60px",
      width: "100%",
      display: "flex",
      flexDirection: "row",
      gap: "10px",
      alignItems: "center",
    },
  });

type AllocationTableProps = {
  salefunds_id: number;
  gridHeight: number;
} & WithStyles<typeof styles>;

export class AllocationTableUnstyled extends React.Component<AllocationTableProps, any> {
  constructor(props) {
    super(props);
  }

  state = {
    allocationList: [],
    loading: true,
  };

  componentDidMount() {
    this.loadData(this.props.salefunds_id);
  }

  componentDidUpdate(prevProps: Readonly<AllocationTableProps>): void {
    if (prevProps.salefunds_id != this.props.salefunds_id) {
      this.loadData(this.props.salefunds_id);
    }
  }

  loadData = async (saleFundsId: number) => {
    this.setState({ loading: true });
    const allocationList = await getSaleFundsAllocationBySaleFundsId(saleFundsId);
    this.setState({ allocationList: allocationList.data, loading: false });
  };

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <Toolbar className={classes.toolbar}>
          <h3>ALLOCATIONS</h3>
        </Toolbar>
        <Grid
          rowHeight={30}
          GridColumns={GridColumns}
          loading={this.state.loading}
          data={this.state.allocationList}
          forceHeight={this.props.gridHeight}
          clearFilters={"fundsallocationlist"}
          totalRowColumns={["amount", "amountCredit", "amountDebit"]}
        />
      </div>
    );
  }
}

export default withStyles(styles)(AllocationTableUnstyled);
