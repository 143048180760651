import React from "react";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";

import Tooltip from "@material-ui/core/Tooltip";
import Button from "@material-ui/core/Button";

import Close from "@material-ui/icons/Close";
import IconAdd from "@material-ui/icons/Add";

import Grid from "../../lib/components/grid";
import Confirmation from "../../lib/components/confirmation";

import { coldrooms, getAllColdrooms } from "../../lib/api/coldroom";
import { GridColumns } from "./coldroomsgridsetup";

import ColdroomEditForm from "./coldroomseditform";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1),
      width: "100%",
      height: "100%",
      position: "relative",
    },
    inline: {
      display: "inline",
      position: "absolute",
      marginTop: "7px",
      marginLeft: "7px",
    },
    closeIcon: {
      display: "flex",
      justifyContent: "flex-end",
      margin: "10px 0px -5px",
      position: "fixed",
      top: "10px",
      right: "15px",
    },
  });

type ColdroomProps = { handleOnClose?: any } & WithStyles<typeof styles>;

export class ColdroomUnstyled extends React.Component<ColdroomProps, any> {
  state = {
    classes: undefined,
    data: [],
    confirmRemoveID: undefined,
    confirmEditID: undefined,
    loading: true,
    handleOnClose: undefined,
  };

  constructor(props) {
    super(props);
    this.state.classes = props.classes;
    this.state.handleOnClose = props.handleOnClose;
  }

  componentDidMount() {
    this.loadData();
  }

  loadData = async () => {
    const data = await getAllColdrooms();
    const dataSorted = data.sort((a, b) => {
      if (a.code > b.code) return 1;
      if (a.code < b.code) return -1;
      return 1;
    });
    this.setState({ loading: false, data: dataSorted });
  };

  handleCreate = () => {
    this.setState({ confirmEditID: "0" });
  };

  handleEdit = (id) => {
    this.setState({ confirmEditID: id });
  };

  handleEditClose = () => {
    this.setState({ confirmEditID: undefined });
  };

  handleEditConfirm = async (values) => {
    const id = values["id"];
    delete values["id"];

    if (id == "0") {
      await coldrooms.create({ data: { ...values, port_id: values.port_id || null } });
    } else {
      await coldrooms.update(id, { data: { ...values, port_id: values.port_id || null } });
    }
    this.loadData();
    this.handleEditClose();
  };

  handleRemove = async (id) => {
    this.setState({ confirmRemoveID: id });
  };

  handleRemoveClose = () => {
    this.setState({ confirmRemoveID: undefined });
  };

  handleRemoveConfirm = async () => {
    await coldrooms.remove(this.state.confirmRemoveID).then(() => {
      this.handleRemoveClose();
      this.loadData();
    });
  };

  GridColumnsOverride = (column, row) => {
    return GridColumns(column, row, this.handleRemove, this.handleEdit);
  };

  render() {
    const { classes } = this.state;

    return (
      <div className={classes.root}>
        <div className={classes.inline}>
          {this.state.handleOnClose && (
            <div className={classes.closeIcon}>
              <Button style={{ marginRight: "10px", backgroundColor: "white" }} color="secondary" variant="outlined" onClick={this.state.handleOnClose}>
                <Close />
              </Button>
            </div>
          )}
          <Tooltip title="Create new Coldroom">
            <div className={classes.inline}>
              <Button variant="contained" color="primary" onClick={this.handleCreate} style={{ marginBottom: "10px" }}>
                <IconAdd />
              </Button>
            </div>
          </Tooltip>
        </div>
        <Grid loading={this.state.loading} data={this.state.data} GridColumns={this.GridColumnsOverride} clearFilters={"coldroomsgridsetup"} />
        {this.state.confirmRemoveID && (
          <Confirmation
            isOpen={this.state.confirmRemoveID ? true : false}
            handleClose={this.handleRemoveClose}
            handleConfirm={this.handleRemoveConfirm}
            title="Remove selected Coldroom?"
            body="Are you sure you want to REMOVE the selected Coldroom Record?"
          ></Confirmation>
        )}
        {this.state.confirmEditID && (
          <Confirmation
            isOpen={this.state.confirmEditID ? true : false}
            handleClose={this.handleEditClose}
            handleConfirm={() => {}}
            title={this.state.confirmEditID == "0" ? "Creating Coldroom" : "Editing Coldroom"}
            body={undefined}
          >
            <ColdroomEditForm onSubmit={this.handleEditConfirm} onClose={this.handleEditClose} id={this.state.confirmEditID} />
          </Confirmation>
        )}
      </div>
    );
  }
}

export default withStyles(styles)(ColdroomUnstyled);
