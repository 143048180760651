import React from "react";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";

import Grid from "../lib/components/grid";

import Toolbar from "@material-ui/core/Toolbar";
import { Row } from "react-data-grid";
import Button from "@material-ui/core/Button";

import { ContextMenu, MenuItem as ContextMenuItem, ContextMenuTrigger } from "react-contextmenu";
import { createPortal } from "react-dom";
import format from "date-fns/format";

import { createBarcodeByDetail, removeBarcodeByDetail } from "../lib/api/edistockpalettesdispatched";
import { GridColumnsPallets } from "./dispatchtablesetuppallets";
import Confirmation from "../lib/components/confirmation";
import DispatchAddNewBarcodeForm from "./dispatchaddnewlineform";
import { GreenButton } from "../lib/components/ColorButtons";

const styles = (theme: Theme) =>
  createStyles({
    toolbarContainer: {
      position: "relative",
    },
    toolbar: {
      position: "absolute",
      left: 0,
      width: "80%",
      gap: `${theme.spacing(1)}px`,
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-end",
    },
    contextMenu: {
      backgroundColor: "#fff",
      backgroundClip: "padding-box",
      border: "1px solid rgba(0, 0, 0, 0.15)",
      borderRadius: "0.25rem",
      color: "#373a3c",
      fontSize: "16px",
      margin: "2px 0 0",
      minWidth: "160px",
      outline: "none",
      opacity: 0,
      padding: "5px 0",
      pointerEvents: "none",
      textAlign: "left",
      transition: "opacity 250ms ease !important",
    },
    contextMenuItem: {
      background: "0 0",
      border: 0,
      color: "#373a3c",
      cursor: "pointer",
      fontWeight: 400,
      lineHeight: 1.5,
      padding: "3px 20px",
      textAlign: "inherit",
      whiteSpace: "nowrap",
    },
  });

type DispatchTableGridProps = {
  data: any[];
  loading: boolean;
  gridHeight: number;
  selectedRows: any[];
  selectedDispatch: any;
  selectedClear(): void;
  onRelease(selectedRow: any): void;
  onRemoveDispath(dispatch_id: number): void;
  handleAvailableDispatchDoubleClick(row: any): void;
  onAccept(selectedRows: any[], selectedDispatch: any): void;
} & WithStyles<typeof styles>;

class DispatchTableGridUnstyled extends React.Component<DispatchTableGridProps, {}> {
  constructor(props) {
    super(props);
  }

  state = {
    selectedDetailRow: undefined,
    addNewBarcodeDetail: undefined,
  };

  rowRenderer = (props) => {
    const { row } = props;

    if (
      this.props.selectedRows &&
      this.props.selectedDispatch["Dispatch ID"] &&
      this.props.selectedDispatch["status"] != "DISPATCHED" &&
      this.props.selectedDispatch["status"] != "INVOICED"
    ) {
      return (
        <ContextMenuTrigger id="dispatch-context-menu" collect={() => ({ row })}>
          <Row {...props} />
        </ContextMenuTrigger>
      );
    }

    return <Row {...props} />;
  };

  handleSelectDeleteRow = (row) => {
    this.setState({ selectedDetailRow: row });
  };

  handleCloseRemoveConfirmation = () => {
    this.setState({ selectedDetailRow: undefined });
  };

  handleReloadDetail = () => {
    if (this.props.selectedDispatch) {
      this.props.handleAvailableDispatchDoubleClick(this.props.selectedDispatch);
    }
  };

  handleConfirmRemoveConfirmation = async () => {
    // this.setState({ selectedRows: [] });

    const dataToRemove = {
      ["Dispatch ID"]: this.state.selectedDetailRow["Dispatch ID"],
      ["Barcode"]: this.state.selectedDetailRow["Barcode"],
      ["Pallet ID"]: this.state.selectedDetailRow["Pallet ID"],
      ["Sequence Number"]: this.state.selectedDetailRow["Sequence Number"],
    };
    await removeBarcodeByDetail(dataToRemove);

    this.handleReloadDetail();
    this.handleCloseRemoveConfirmation();
  };

  handleCloseAddNewBarcode = () => {
    this.setState({ addNewBarcodeDetail: false });
  };

  handleOpenAddNewBarcode = () => {
    this.setState({ addNewBarcodeDetail: true });
  };

  handleProcessAddNewLine = async (values: any) => {
    await createBarcodeByDetail({ ...values, dispatch_id: this.props.selectedDispatch["Dispatch ID"] });
    this.handleReloadDetail();
    this.handleCloseAddNewBarcode();
  };

  render() {
    const { classes } = this.props;

    return (
      <>
        {this.state.selectedDetailRow && (
          <Confirmation
            isOpen={true}
            handleClose={this.handleCloseRemoveConfirmation}
            handleConfirm={this.handleConfirmRemoveConfirmation}
            title="Remove Barcode Record"
            body={"Are you sure you want to remove the selected record?"}
          />
        )}
        {this.state.addNewBarcodeDetail && (
          <Confirmation isOpen={true} handleClose={this.handleCloseAddNewBarcode} handleConfirm={() => {}} title="Add new barcode line" body={undefined}>
            <DispatchAddNewBarcodeForm handleClose={this.handleCloseAddNewBarcode} handleProcess={this.handleProcessAddNewLine} reload={this.handleCloseAddNewBarcode} />
          </Confirmation>
        )}
        <div className={classes.toolbarContainer}>
          <Toolbar className={classes.toolbar}>
            <div>
              {this.props.selectedDispatch && this.props.selectedDispatch.sale_id == 0 && (
                <>
                  {this.props.selectedRows &&
                    this.props.selectedDispatch["Dispatch ID"] &&
                    this.props.selectedDispatch["status"] != "DISPATCHED" &&
                    this.props.selectedDispatch["status"] != "INVOICED" && (
                      <GreenButton variant="contained" onClick={this.handleOpenAddNewBarcode} style={{ marginLeft: "10px" }}>
                        add new line
                      </GreenButton>
                    )}
                  {this.props.selectedRows &&
                    this.props.selectedDispatch["Dispatch ID"] &&
                    this.props.selectedDispatch["status"] != "DISPATCHED" &&
                    this.props.selectedDispatch["status"] != "INVOICED" && (
                      <Button variant="contained" color="secondary" onClick={() => this.props.onRemoveDispath(this.props.selectedDispatch["Dispatch ID"])}>
                        remove
                      </Button>
                    )}
                  {this.props.selectedRows && this.props.selectedDispatch["Dispatch ID"] && (
                    <Button variant="contained" color="secondary" style={{ marginLeft: "10px" }} onClick={() => this.props.onRelease(this.props.selectedDispatch)}>
                      release
                    </Button>
                  )}

                  {this.props.selectedRows && (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => this.props.onAccept(this.props.selectedRows, this.props.selectedDispatch)}
                      style={{ backgroundColor: "green", marginLeft: "10px" }}
                    >
                      UPDATE
                    </Button>
                  )}
                  <Button variant="contained" color="primary" onClick={() => this.props.selectedClear()} style={{ marginLeft: "10px" }}>
                    clear selected
                  </Button>
                </>
              )}
            </div>
          </Toolbar>
          <Grid
            fill
            showFilterChips
            data={this.props.data}
            loading={this.props.loading}
            clearFilters={"dispatchselected"}
            forceHeight={this.props.gridHeight}
            // handleRefresh={() => this.props.handleRefresh()}
            exportName={
              this.props.selectedDispatch && (this.props.selectedRows || []).length > 0
                ? `${format(new Date(), "yyMMdd")} ${this.props.selectedDispatch["Dispatch Doc Ref"]} ${this.props.selectedRows[0].loadout_ref || ""} DISPATCH DETAIL.xlsx`
                : "DISPATCH DETAIL.xlsx"
            }
            rowRenderer={(props) => this.rowRenderer(props)}
            totalRowColumns={["No Cartons", "Pallet Size", "Weight", "Gross Weight", "stock_grossweight", "stock_weight"]}
            GridColumns={(data, filters, columnArrangement, columnsWidth) => GridColumnsPallets(data, filters, columnArrangement, columnsWidth)}
          />
          {createPortal(
            <ContextMenu id="dispatch-context-menu" className={classes.contextMenu}>
              <ContextMenuItem className={classes.contextMenuItem} onClick={(_, data: any) => this.handleSelectDeleteRow(data.row)}>
                Remove
              </ContextMenuItem>
            </ContextMenu>,
            document.body,
          )}
        </div>
      </>
    );
  }
}

export default withStyles(styles)(DispatchTableGridUnstyled);
