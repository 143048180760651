import React, { useState, forwardRef } from "react";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";

import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import RadioGroup from "@material-ui/core/RadioGroup";

import { Field, Form } from "react-final-form";
import { TextField, Radio } from "final-form-material-ui";

import toDate from "date-fns/toDate";

import ReactDatePicker from "react-datepicker";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1),
      width: "600px",
      height: "100%",
    },
    divActions: {
      float: "right",
    },
    container: {
      display: "grid",
      gridTemplateColumns: "repeat(1, 150px 350px)",
      gridTemplateRows: "repeat(4, 40px)",
      gridTemplateAreas: `
      "details"
      `,
      gridGap: "10px",
    },
    tableCellLabel: {
      width: "150px",
      borderBottom: "none",
      height: "50px",
      textAlign: "right",
      paddingTop: "5px",
    },
    tableCellLabelValue: {
      width: "350px",
      borderBottom: "none",
      height: "50px",
      textAlign: "left",
      paddingTop: "5px",
    },
    tableCellDetail: {
      width: "350px",
      borderBottom: "none",
      height: "50px",
    },
    tableCellsValueSelect: {
      textAlign: "left",
      width: "350px",
      borderBottom: "none",
      height: "50px",
      padding: 0,
      marginTop: "-14px",
    },
    tableCellDetailDate: {
      marginTop: "-5px",
      width: "350px",
      borderBottom: "none",
      height: "50px",
    },
  });

type CreditorsInvoiceAdjustmentProps = {
  adjustRecord: any;
  loading: boolean;
  handleProcess: (data) => void;
} & WithStyles<typeof styles>;

const CreditorsInvoiceAdjustmentUnstyled: React.FunctionComponent<CreditorsInvoiceAdjustmentProps> = (props) => {
  const { classes, adjustRecord: values, handleProcess, loading: submitting } = props;

  const [adjustDate, setAdjustDate] = useState(values.adjustdate ? new Date(values.adjustdate) : new Date());

  const changeAdjustDate = (value, form) => {
    setAdjustDate(new Date(value));
    form.change("adjustdate", toDate(value));
  };

  return (
    <div className={classes.root}>
      <div>
        <Form
          initialValues={{
            ...values,
            type: "INVOICE",
            validate_totalexcl: values.creditorsinvoiceadjustment_id ? values.validate_totalexcl : 0,
            validate_vat: 0,
            creditors_code: `${values.creditors_code} - ${values.creditors_name}`,
            creditorsinvoiceadjustment_type: values.creditorsinvoiceadjustment_type == 0 ? "debit" : "credit",
          }}
          onSubmit={(values: any) => {
            handleProcess({
              ...values,
              amount: values.validate_totalexcl,
              vat: values.validate_vat,
              adjustdate: values.adjustdate ? values.adjustdate : toDate(new Date()),
              creditorsinvoiceadjustment_type: values.creditorsinvoiceadjustment_type == "debit" ? 0 : 1,
            });
          }}
          render={({ handleSubmit, form }) => (
            <form onSubmit={handleSubmit}>
              <div style={{ paddingBottom: "10px" }}>
                <Button name="submit" type="submit" variant="contained" color="primary" style={{ margin: "2px" }} disabled={submitting}>
                  Save And Close
                </Button>
                <Button
                  name="close"
                  variant="contained"
                  color="secondary"
                  style={{ margin: "2px" }}
                  disabled={submitting}
                  onClick={() => {
                    handleProcess(undefined);
                  }}
                >
                  Close
                </Button>
              </div>
              <AdjustmentTypeGroup />
              <div style={{ display: "grid", gridTemplateRows: "1fr", gridTemplateColumns: "repeat(2, 1fr)" }}>
                <div className={classes.container}>
                  <TableFieldText classes={classes} field="creditorinvoice_totalexecl" title="Total" disabled={true} />
                  <TableFieldText classes={classes} field="type" title="Type" disabled={true} />
                  <TableFieldText classes={classes} field="validate_totalexcl" title="Amount" disabled={false} />
                  <TableFieldText classes={classes} field="validate_vat" title="VAT" disabled={false} />
                  <TableFieldText classes={classes} field="creditors_code" title="Creditor" disabled={true} />
                </div>
                <div className={classes.container}>
                  <TableFieldDate classes={classes} dateValue={adjustDate} title="Adjust Date" changeDate={(value) => changeAdjustDate(value, form)} />
                  <TableFieldText classes={classes} field="invoicenumber" title="Invoice Number" disabled={true} />
                  <TableFieldText classes={classes} field="credit_note" title="Creditors Ref" disabled={false} />
                </div>
              </div>
              <div className={classes.divActions}></div>
            </form>
          )}
        />
      </div>
    </div>
  );
};

export default withStyles(styles)(CreditorsInvoiceAdjustmentUnstyled);

const CalenderCustomInput = forwardRef((props: any, ref: any) => {
  return (
    <Button name="CalenderCustomInput" variant="contained" color="primary" onClick={props.onClick} style={{ marginTop: "5px", width: "250px" }}>
      {props.value}
    </Button>
  );
});

const TableFieldDate: React.FunctionComponent<{ dateValue: Date; title: string; changeDate: (value) => void } & WithStyles<typeof styles>> = (props) => {
  const { classes, dateValue, title, changeDate } = props;
  return (
    <>
      <span className={classes.tableCellLabel}>{`${title}:`}</span>
      <span className={classes.tableCellDetailDate}>
        <ReactDatePicker
          locale="en-GB"
          showWeekNumbers={true}
          selected={toDate(dateValue)}
          onChange={(value) => {
            changeDate(value);
          }}
          dateFormat="dd-MM-yyyy"
          placeholderText="click here to select a date"
          customInput={<CalenderCustomInput />}
        />
      </span>
    </>
  );
};

const TableFieldText: React.FunctionComponent<{ field: string; title: string; disabled: boolean } & WithStyles<typeof styles>> = (props) => {
  const { classes, field, title, disabled } = props;
  return (
    <>
      <span className={classes.tableCellLabel}>{`${title}:`}</span>
      <span className={classes.tableCellDetail}>
        <Field name={field} component={TextField} type="text" fullWidth={true} disabled={disabled} />
      </span>
    </>
  );
};

const AdjustmentTypeGroup = () => (
  <FormControl style={{ float: "left" }}>
    <RadioGroup>
      <FormControlLabel control={<Field type="radio" name="creditorsinvoiceadjustment_type" value={"debit"} component={Radio} />} label="Debit" />
      <FormControlLabel control={<Field type="radio" name="creditorsinvoiceadjustment_type" value={"credit"} component={Radio} />} label="Credit" />
    </RadioGroup>
  </FormControl>
);
