import { config } from "../../config";
import { client, apiHeaders, request } from "./_base";
import { getFinancialYearSelected } from "./week";

const endpoint = "stockdetail";

export const stockdetail = client(endpoint, (item) => ({
  ...item,
}));

export const stockDetailUpdateByBarcodeID = async (barcode_id, item) => {
  const content = JSON.stringify(item);
  return await request(`${endpoint}/updateByBarcode/${barcode_id}`, {
    method: "PUT",
    headers: apiHeaders(content),
    body: content,
  })
    .then(async (result) => {
      if (result.status == 500) {
        const resp = await result.json();
        throw resp;
      }
      return result.json();
    })
    .then(({ data }) => {});
};

export const stockDetailInsertByBarcodeID = async (item) => {
  const content = JSON.stringify(item);
  return await request(`${endpoint}/insertByBarcode`, {
    method: "POST",
    headers: apiHeaders(content),
    body: content,
  })
    .then(async (result) => {
      if (result.status == 500) {
        const resp = await result.json();
        throw resp;
      }
      return result.json();
    })
    .then(({ data }) => {});
};

export const stockDetailUpdatePricingByReferenceAnd = async (data) => {
  const content = JSON.stringify(data);
  return request(`${endpoint}/extend/updatePricingByReferenceAnd`, {
    method: "PUT",
    headers: apiHeaders(content),
    body: content,
  })
    .then(async (result) => {
      if (result.status == 500) {
        const resp = await result.json();
        throw resp;
      }
      return result.json();
    })
    .then(({ data }) => {});
};

export const stockDetailDeleteByBarcode = async (barcode: string): Promise<void> => {
  const result = await request(`${endpoint}/deleteByBarcode/${barcode}`, {
    method: "GET",
  });
  if (result.status == 500) {
    const resp = await result.json();
    throw resp;
  }
};

export const stockDetaildistinctReferences = () => {
  return request(`${endpoint}/extend/distinctReferences/${getFinancialYearSelected()}`, {
    method: "GET",
  })
    .then(async (result) => {
      if (result.status == 500) {
        const resp = await result.json();
        throw resp;
      }
      return result.json();
    })
    .then(({ data }) => {
      return data;
    });
};

export const stockDetaildistinctReferencesSellingNull = () => {
  return request(`${endpoint}/extend/distinctReferencesSellingNull/${getFinancialYearSelected()}`, {
    method: "GET",
  })
    .then(async (result) => {
      if (result.status == 500) {
        const resp = await result.json();
        throw resp;
      }
      return result.json();
    })
    .then(({ data }) => {
      return data;
    });
};

export const batchStockDataUpdatePricingByReference = async (data) => {
  const content = JSON.stringify(data);
  return request(`${endpoint}/extend/batchUpdatePricingByReference`, {
    method: "PUT",
    headers: apiHeaders(content),
    body: content,
  })
    .then(async (result) => {
      if (result.status == 500) {
        const resp = await result.json();
        throw resp;
      }
      return result.json();
    })
    .then(({ data }) => {});
};

export const unallocateSelectedStock = async (data: { barcode: string; barcode_id: number }): Promise<void> => {
  const content = JSON.stringify(data);
  const result = await request(`${endpoint}/ext/unallocateSelectedStock`, {
    method: "POST",
    headers: apiHeaders(content),
    body: content,
  });
  if (result.status == 500) {
    const resp = await result.json();
    throw resp;
  }
};

export const countStockDetaildistinctReferencesSellingNull = async () => {
  const result = await request(`${endpoint}/extend/countDistinctReferencesSellingNull`, {
    method: "GET",
  });
  const resp = await result.json();
  if (result.status == 500) {
    throw resp;
  }
  return resp.data;
};
