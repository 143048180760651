import React from "react";
import Grid from "../../lib/components/grid";
import { GridColumns } from "./barcodemissinggridsetup";

import Button from "@material-ui/core/Button";

type DataType = { barcode: string; group: number; amount: string };

type BarcodeMissingGridProps = {
  data: DataType[];
  handleClose(): void;
  handleConfirm(): void;
};

export const BarcodeMissingGrid: React.FC<BarcodeMissingGridProps> = ({ data, handleConfirm, handleClose }) => {
  return (
    <div style={{ display: "flex", flexDirection: "column", rowGap: "10px" }}>
      <div style={{ color: "#333" }}>
        <h3>The following barcodes are missing on the invoice and data will be incorrect.</h3>
        <h3>Do you want to continue?</h3>
      </div>
      <Grid GridColumns={GridColumns} data={data} clearFilters="creditorsmissingbarcodes" />
      <div style={{ display: "flex", justifyContent: "flex-end", width: "100%", flexDirection: "row", columnGap: "10px" }}>
        <Button onClick={handleClose} color="secondary" variant="contained" autoFocus={true}>
          No
        </Button>
        <Button onClick={handleConfirm} color="primary" variant="contained">
          Yes
        </Button>
      </div>
    </div>
  );
};
