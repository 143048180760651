import { client, apiHeaders, request } from "./_base";
import { config } from "../../config";

export const producerMapping = client("producerMapping", (item) => ({
  ...item,
}));

// extended methods
const endpoint = "producermapping";
export const FindByProducerId = (producerId) => {
  return request(`${endpoint}/findByProducerId/${producerId}`, {
    headers: apiHeaders(),
  }).then(async (result) => {
    if (result.status == 500) {
      const resp = await result.json();
      throw resp;
    }
    return result.json();
  });
};

export const producerMappingSingleExtended = (id) => {
  return request(`${endpoint}/producerMappingSingleExtended/${id}`, {
    headers: apiHeaders(),
  }).then(async (result) => {
    if (result.status == 500) {
      const resp = await result.json();
      throw resp;
    }
    return result.json();
  });
};

export const getProducerCurrenciesByCode = (code) => {
  return request(`${endpoint}/ext/getProducerCurrenciesByCode/${code}`, {
    headers: apiHeaders(),
  })
    .then(async (result) => {
      if (result.status == 500) {
        const resp = await result.json();
        throw resp;
      }
      return result.json();
    })
    .then(({ data }) => data);
};
