export const FileImportTemplateDetailColumns = [
  "id",
  "FileImport_id",
  "PTIntakesFO",
  "Barcode",
  "CustomerID",
  "Date",
  "IntakeDocRef",
  "Account",
  "Channel",
  "Season",
  "InspectionDate",
  "InspectionPoint",
  "InspectorName",
  "RejectRep",
  "RejectReason",
  "CommodityCode",
  "Country",
  "VarietyCode",
  "ActVariety",
  "PackCode",
  "GradeCode",
  "ActGrad",
  "MarkCode",
  "TargetMarket",
  "InventoryCode",
  "PickingRef",
  "CountCode",
  "NoCartons",
  "CartonsRejected",
  "CartonsEvaluated",
  "PaletteSize",
  "FarmNumber",
  "IntakeType",
  "CoolerID",
  "TimeEntered",
  "PalTrak",
  "Processing",
  "IntakeUser",
  "Inspection",
  "Accepted",
  "Imported",
  "RegNo",
  "Carrier",
  "Organization",
  "Transport",
  "LoadType",
  "PalletBaseType",
  "OrderNo",
  "LocationCode",
  "Destination",
  "ShippingNo",
  "Vessel",
  "ContainerNo",
  "SealNumber",
  "ConsecNo",
  "Temperature",
  "DestinationType",
  "FileType",
  "SequenceNumber",
  "WaybillDocument",
  "Time",
  "InspectionTime",
  "PalletSize",
  "ColdDate",
  "DispatchDocRef",
  "Receiver",
  "Sender",
  "Remarks",
  "OrigIntakeDate",
  "OrigIntakeWaybill",
  "DateEntered",
  "DateDispatched",
  "PalletID",
  "ProducerID",
  "IntakeID",
  "DispatchID",
  "TimeDispatched",
  "Selected",
  "Price",
  "Allocated",
  "Allocation",
  "RefNo",
  "IntakeWaybill",
  "IntakePalletID",
  "Bay",
  "LastInvoice",
  "LastInvoiceDate",
  "Invoicing",
  "Exported",
  "Rebuilt",
  "LastEditDate",
  "PalletMovement",
  "AllocatedStock",
  "Rejected",
  "Transfer",
  "MiniPallet",
  "SV",
  "ProductGroup",
  "ProductChar",
  "MixedInd",
  "Weight",
  "StockPool",
  "SSCC",
  "PHC",
  "SellBy",
  "StockValue",
  "OldBarcode",
  "TraderID",
  "ConsigneeID",
  "InspectionReport",
  "UserName",
  "DispatchCustomerId",
  "TempTail",
  "Batch",
  "Orchard",
  "FileName",
  "RevisionNo",
  "InternalBarcode",
  "TotalPallets",
  "Week",
  "EstimateNo",
  "PackWeek",
  "ArriveWeek",
  "SalesWeek",
  "Units",
  "ETD",
  "PortOfLoading",
  "PortOfDischarge",
  "QCFlag",
  "QCUser",
  "QCBlocked",
  "OrderID",
  "21",
  "22",
  "23",
  "24",
  "25",
  "26",
  "27",
  "28",
  "29",
  "30",
  "BatchNumber",
  "Hatch",
  "Deck",
  "ProducerName",
  "PaymentType",
  "ProductionID",
  "ConsignmentRef",
  "SubSaleRef",
  "Currency",
  "Amount",
  "ExchangeRate",
  "RandValue",
  "Quantity",
  "ShipAgent",
  "OrigLocationCode",
  "Tax",
  "ActivityType",
  "ETA",
  "InvalidBarcode",
  "FromLocationCode",
  "NewBarcode",
  "ActivityDate",
  "Verify",
  "ImportDate",
  "InvoiceNumber",
  "CreditorID",
  "Description",
  "Total",
  "SaleID",
  "CostItem",
  "RemittanceID",
  "DocumentType",
  "ClientRef",
  "CTONo",
  "ToLocationCode",
  "TempSerialNo",
  "ShippingLine",
  "PalletOnIntake",
  "PalletOnStock",
  "FruitTrakInternalPackCode",
  "TranType",
  "InvoiceID",
  "NoOfItems",
  "FPrice",
  "FAmount",
  "CostPerItem",
  "ReportOption",
  "PurchasePrice",
  "Depot",
  "AdvancePrice",
  "LoadDate",
  "StartLoadingTime",
  "LocalFOB",
  "PreAllocated",
  "InsuranceValue",
  "PurchaseOrderNo",
  "DepotStockID",
  "Rebate",
  "ToSequenceNumber",
  "TransactionDate",
  "InnerPack",
  "IntakeWeek",
  "EqvCartons",
  "TempType",
  "Qnty",
  "InnerCartons",
  "PalletStatus",
  "ContractID",
  "TargetPrice",
  "BHTransactionDate",
  "DepotFiletype",
  "GrowingMethod",
  "MustGO",
  "Cycle",
  "AdvancePurchase",
  "PackHouseCode",
  "Principal",
  "VarietyGroup",
  "CommodityGroup",
  "VarietyGroupDescription",
  "VarietyDescription",
  "ShippingWeek",
  "Balance",
  "RateStructure",
  "ATA",
  "ATD",
  "TargetRegion",
  "TargetCountry",
  "QCSelected",
  "QCUserName",
  "RawCartons",
  "RawPallets",
  "EqvPallets",
  "SpecialOrder",
  "DestinationTypeCN",
  "ContainerRef",
  "NotifyParty",
  "BestBefore",
  "DisplayUntil",
  "QCCode",
  "ClaimType",
  "AdditionalDataPath",
  "AdditionalPhotoPath",
  "QCType",
  "PUC",
  "PackingDate",
  "InspectorCode",
  "Labeled",
  "QCPassed",
  "ExpiryCode",
  "OrigPHCLocationCode",
  "PHCLocationCode",
  "EstimateReturn",
  "PurchasePalletID",
  "EDIProvider",
  "GTINID",
  "Row",
  "Position",
  "CustomField1",
  "OrigFarmNumber",
  "UCRNo",
  "GTIN",
  "Percentage",
  "Complexity",
  "IntakeStockValue",
  "GrossWeight",
  "VoyageNumber",
  "OrigInspectionDate",
  "UPN",
  "ActualVariety",
  "PalletTreatmentID",
  "PoolingPackagingFactor",
  "ProducerDealType",
  "Trailer1",
  "Trailer2",
  "VerificationMethod",
  "AccreditationNumber",
  "AccreditationNumberContainerLevel",
  "ContainerTareWeight",
  "ContainerGrossWeight",
  "WeighingLocation",
  "WeighingDateTime",
  "ResponsibleParty",
  "VitraxUID",
  "VitraxTargetUID",
  "FruittrakIntakeID",
  "PreIntakeStock",
  "PhytoVerification",
  "InnerCartonWeight",
  "VATCode",
  "StartWeek",
  "EndWeek",
  "PQINumber",
  "PricingSheetAppliedDate",
  "OwnCost",
  "PackDate",
  "StuffDate",
  "TempSet",
  "ContainerAgent",
  "LotNo",
  "Waybill",
];
