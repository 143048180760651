import React from "react";
import CustomAutoCompleteFilter from "../lib/components/customdatagridfilter/CustomFilter";

import IconAttachFile from "@material-ui/icons/AttachFile";
import IconBrightness from "@material-ui/icons/Brightness1";
import Tooltip from "@material-ui/core/Tooltip";

const IconExcel = require("../excel.png");

import { DateFormatter, FormatterNumber, FormatterNumber2Decimals, RightAlignColumnHeader } from "../lib/components/Formatters";
import { SummaryNumber, SummaryNumber2Decimal, SummaryTotal } from "../lib/components/SummaryFormatter";

import { DatePicker, CheckboxEdit } from "../lib/components/gridcustomedit";
import DropdownEditor from "../lib/components/DropdownEditor";
import TextEditor from "../lib/components/TextEditor";

import { CellActionsFormatter } from "../lib/helpers/CellActionsFormatter";
import { handleColumnsArrangement, handleColumnsWidth } from "../lib/helpers/grid";
import { isNullOrUndef } from "../lib/helpers/isNullOrUndef";
import { StatusTypes } from "../lib/types";
import { SaleDispatchJoinedType } from "../lib/api/sale";
import { printLoadoutReport } from "../lib/api/loadout";

const cellWidthTiny = 50;
const cellWidthSmallX = 80;
const cellWidthSmall = 115;
const cellWidthMedium = 150;
const cellWidthLarge = 220;

const StatusFormatter = ({ row }) => {
  const datarow: SaleDispatchJoinedType = { ...row };

  if (datarow.sale_type == 1) {
    return (
      <div>
        <Tooltip title={StatusTypes.LOADED}>
          <IconBrightness style={{ color: "yellow", display: "block", margin: "auto", border: "1px solid #333", borderRadius: "100%" }} />
        </Tooltip>
      </div>
    );
  } else if (datarow.sale_id) {
    return (
      <div>
        <Tooltip title={StatusTypes.INVOICED}>
          <IconBrightness style={{ color: "red", display: "block", margin: "auto", border: "1px solid #333", borderRadius: "100%" }} />
        </Tooltip>
      </div>
    );
  } else if (datarow.dispatch_id) {
    return (
      <div>
        <Tooltip title={StatusTypes.DISPATCHED}>
          <IconBrightness style={{ color: "green", display: "block", margin: "auto", border: "1px solid #333", borderRadius: "100%" }} />
        </Tooltip>
      </div>
    );
  } else {
    return (
      <div>
        <Tooltip title={StatusTypes.LOADED}>
          <IconBrightness style={{ color: "yellow", display: "block", margin: "auto", border: "1px solid #333", borderRadius: "100%" }} />
        </Tooltip>
      </div>
    );
  }
};

const defaultColumnProperties = {
  resizable: true,
  sortable: true,
};

let currentSpace = true;

const canEdit = (row) => !row.dispatch_id;

export const GridColumns = (
  data,
  filters,
  arrangement,
  columnsWidth,
  handleAttach,
  coldstoreoptions,
  portoptions,
  portoptionslocal,
  shippinglines,
  vesseldata,
  billtypes,
  clientsoptions,
  groupedPortOptions,
  groupedClientOptions,
  tempcodes,
) => {
  let postValue = "";

  if (data) {
    if (currentSpace) {
      postValue = " ";
    }
    currentSpace = !currentSpace;
  }

  const columns = [
    {
      key: "actions",
      name: "#A",
      width: cellWidthSmall,
      formatter: ({ row: loadout }) => {
        const actions = [
          {
            icon: (
              <span title="Export EXCEL" style={{ paddingLeft: "20px" }}>
                <img src={IconExcel} width="22px" height="22px" style={{ cursor: "pointer" }} />
              </span>
            ),
            callback: (e) => {
              printLoadoutReport([loadout.loadout_id], data);
            },
          },
          {
            icon: (
              <span title="Manage Attachments" style={{ paddingLeft: "5px" }}>
                <IconAttachFile style={{ cursor: "pointer", transform: "rotate(40deg)", width: "35px", color: "#4a9de2" }} />
              </span>
            ),
            callback: (e) => {
              handleAttach(loadout.loadout_id);
            },
          },
        ];
        return <CellActionsFormatter actions={actions} />;
      },
      summaryFormatter: SummaryTotal,
    },
    {
      key: "loadout_status",
      name: "#S" + postValue,
      width: cellWidthSmall,
      formatter: StatusFormatter,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"loadout_status"} data={data} section="loadoutgrid" />;
      },
    },
    {
      key: "loadout_reference",
      name: "Load Ref" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"loadout_reference"} data={data} section="loadoutgrid" />;
      },
      summaryFormatter: SummaryNumber,
    },
    {
      key: "dateLoading",
      name: "Load Date" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"dateLoading"} data={data} section="loadoutgrid" />;
      },
      formatter: DateFormatter,
      editor: DatePicker,
      editable: canEdit,
    },
    {
      key: "tempcode",
      name: "Temperature Code" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"tempcode"} data={data} section="loadoutgrid" />;
      },
      editor: (props) => <DropdownEditor {...props} options={tempcodes} valueKey="value" displayKey={"display"} />,
      editable: canEdit,
    },
    {
      key: "loadout_spec",
      name: "SPEC" + postValue,
      width: cellWidthTiny,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"loadout_spec"} data={data} section="loadoutgrid" />;
      },
      filterable: true,
      formatter: CheckboxEdit,
    },
    {
      key: "loadout_loadingdocs",
      name: "L Docs" + postValue,
      width: cellWidthTiny,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"loadout_loadingdocs"} data={data} section="loadoutgrid" />;
      },
      filterable: true,
      formatter: CheckboxEdit,
    },
    {
      key: "sale_invoicenumber",
      name: "Invoice Number" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"sale_invoicenumber"} data={data} section="loadoutgrid" />;
      },
    },
    {
      key: "forwardagentref",
      name: "Forward Agent Ref" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"forwardagentref"} data={data} section="loadoutgrid" />;
      },
      editor: TextEditor,
      editable: canEdit,
    },
    {
      key: "clients_name",
      name: "Client Name" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => <CustomAutoCompleteFilter {...e} multiSelection currentFilters={filters} fieldname={"clients_name"} data={data} section="loadoutgrid" />,
      editable: canEdit,
      editor: (props) => (
        <DropdownEditor
          {...props}
          onRowChange={(ev) => {
            const clients_code = ev.clients_name;
            props.onRowChange({ ...props.row, clients_name: groupedClientOptions[clients_code], clients_code });
          }}
          options={clientsoptions}
          valueKey="value"
          displayKey="display"
        />
      ),
    },
    {
      key: "consignee_name",
      name: "Consignee Name" + postValue,
      width: cellWidthMedium,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"consignee_name"} data={data} section="loadoutgrid" />;
      },
      filterable: true,
    },
    {
      key: "loadout_etd",
      name: "ETD" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"loadout_etd"} data={data} section="loadoutgrid" />;
      },
      formatter: DateFormatter,
      editor: DatePicker,
      editable: canEdit,
    },
    {
      key: "loadout_eta",
      name: "ETA" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"loadout_eta"} data={data} section="loadoutgrid" />;
      },
      formatter: DateFormatter,
      editor: DatePicker,
      editable: canEdit,
    },
    {
      key: "loadout_updatedeta",
      name: "Updated ETA" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"loadout_updatedeta"} data={data} section="loadoutgrid" />;
      },
      formatter: DateFormatter,
      editor: DatePicker,
      editable: true,
    },
    {
      key: "collected",
      name: "Collected" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"collected"} data={data} section="loadoutgrid" />;
      },
      formatter: (props) => {
        const { row, column } = props;
        if (row[column.key]) {
          return <DateFormatter {...props} />;
        }
        return <div></div>;
      },
      editor: DatePicker,
      editable: true,
    },
    {
      key: "sale_ata",
      name: "ATA" + postValue,
      width: cellWidthMedium,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"sale_ata"} data={data} section="loadoutgrid" />;
      },
      formatter: DateFormatter,
      filterable: true,
      editor: DatePicker,
      editable: (row) => !isNullOrUndef(row.sale_id),
    },
    {
      key: "shippingCode",
      name: "Shipping Line" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"shippingCode"} data={data} section="loadoutgrid" />;
      },
      editor: (props) => <DropdownEditor {...props} options={shippinglines} valueKey="code" displayKey={"value"} />,
      editable: canEdit,
    },
    {
      key: "vessel_code",
      name: "Vessel" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"vessel_code"} data={data} section="loadoutgrid" />;
      },
      editor: (props) => <DropdownEditor {...props} options={vesseldata} valueKey="code" displayKey={"value"} />,
      editable: canEdit,
      formatter: ({ row }) => ((vesseldata || []).find((vs) => vs.code === row.vessel_code) || { data: { description: "" } }).data.description,
    },
    {
      key: "client_po",
      name: "Client Order No" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"client_po"} data={data} section="loadoutgrid" />;
      },
      editor: TextEditor,
      editable: canEdit,
    },
    {
      key: "containerno",
      name: "Container No" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"containerno"} data={data} section="loadoutgrid" />;
      },
    },
    {
      key: "sale_date",
      name: "Invoice Date" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"sale_date"} data={data} section="loadoutgrid" />;
      },
      formatter: DateFormatter,
      editor: DatePicker,
      editable: (row) => !isNullOrUndef(row.sale_id),
    },
    {
      key: "sale_arrived",
      name: "Arrived" + postValue,
      width: cellWidthSmallX,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"sale_arrived"} data={data} section="loadoutgrid" />;
      },
      filterable: true,
      formatter: (props) => <CheckboxEdit {...props} isVisible={props.row.sale_id} />,
    },
    {
      key: "sale_collected",
      name: "Sale Collected" + postValue,
      width: cellWidthSmallX,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"sale_collected"} data={data} section="loadoutgrid" />;
      },
      filterable: true,
      formatter: (props) => <CheckboxEdit {...props} isVisible={props.row.sale_id} />,
    },
    {
      key: "sale_clientadvpd",
      name: "C Adv Pd" + postValue,
      width: cellWidthSmallX,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"sale_clientadvpd"} data={data} section="loadoutgrid" />;
      },
      filterable: true,
      formatter: (props) => <CheckboxEdit {...props} isVisible={props.row.sale_id} />,
    },
    {
      key: "sale_freightpd",
      name: "Freight Pd" + postValue,
      width: cellWidthSmallX,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"sale_freightpd"} data={data} section="loadoutgrid" />;
      },
      filterable: true,
      formatter: (props) => <CheckboxEdit {...props} isVisible={props.row.sale_id} />,
    },
    {
      key: "sale_copydox",
      name: "Cpy Dox" + postValue,
      width: cellWidthSmallX,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"sale_copydox"} data={data} section="loadoutgrid" />;
      },
      filterable: true,
      formatter: (props) => <CheckboxEdit {...props} isVisible={props.row.sale_id} />,
    },
    {
      key: "sale_orgnldox",
      name: "Org Dox" + postValue,
      width: cellWidthSmallX,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"sale_orgnldox"} data={data} section="loadoutgrid" />;
      },
      filterable: true,
      formatter: (props) => <CheckboxEdit {...props} isVisible={props.row.sale_id} />,
    },
    {
      key: "sale_clientfinpd",
      name: "Fin Pd" + postValue,
      width: cellWidthSmallX,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"sale_clientfinpd"} data={data} section="loadoutgrid" />;
      },
      filterable: true,
      formatter: (props) => <CheckboxEdit {...props} isVisible={props.row.sale_id} />,
    },
    {
      key: "sale_telex",
      name: "Telex" + postValue,
      width: cellWidthSmallX,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"sale_telex"} data={data} section="loadoutgrid" />;
      },
      filterable: true,
      formatter: (props) => <CheckboxEdit {...props} isVisible={props.row.sale_id} />,
    },
    {
      key: "sale_accsalereceived",
      name: "A/S Rec" + postValue,
      width: cellWidthSmallX,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"sale_accsalereceived"} data={data} section="loadoutgrid" />;
      },
      filterable: true,
      formatter: (data) =>
        data.row.clients_sellingterms != 2 && data.row.sale_id ? <CheckboxEdit {...data} /> : <div style={{ fontWeight: "bold", textAlign: "center" }}>{"N/A"}</div>,
    },
    {
      key: "clients_payment_terms",
      name: "Client Payment Terms" + postValue,
      width: cellWidthMedium,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"clients_payment_terms"} data={data} section="loadoutgrid" />;
      },
      filterable: true,
    },
    {
      key: "sale_billtype",
      name: "Bill Type" + postValue,
      width: cellWidthSmallX,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"sale_billtype"} data={data} section="loadoutgrid" />;
      },
      filterable: true,
      editor: (props) => <DropdownEditor {...props} options={billtypes} valueKey="value" />,
      editable: (row) => !isNullOrUndef(row.sale_id),
    },
    {
      key: "loadout_exportNotificationReference",
      name: "Export Notification" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return (
          <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"loadout_exportNotificationReference"} data={data} section="loadoutgrid" />
        );
      },
      editor: TextEditor,
      editable: canEdit,
    },
    {
      key: "loadout_coldroomcode",
      name: "Coldroom" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"loadout_coldroomcode"} data={data} section="loadoutgrid" />;
      },
      editor: (props) => <DropdownEditor {...props} options={coldstoreoptions} valueKey="value" />,
      editable: true,
    },
    {
      key: "shipment_week",
      name: "Shipment Week" + postValue,
      width: cellWidthMedium,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"shipment_week"} data={data} section="loadoutgrid" />;
      },
      filterable: true,
    },
    {
      key: "portloading_code",
      name: "Port Loading" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"portloading_code"} data={data} section="loadoutgrid" />;
      },
      formatter: ({ row, column }) => <div>{groupedPortOptions[row[column.key]]}</div>,
      editor: (props) => <DropdownEditor {...props} options={portoptionslocal} valueKey="code" displayKey={"display"} />,
      editable: canEdit,
    },
    {
      key: "portdistcharge_code",
      name: "Port Discharge" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"portdistcharge_code"} data={data} section="loadoutgrid" />;
      },
      formatter: ({ row, column }) => <div>{groupedPortOptions[row[column.key]]}</div>,
      editor: (props) => <DropdownEditor {...props} options={portoptions} valueKey="code" displayKey={"display"} />,
      editable: canEdit,
    },
    {
      key: "portfinal_code",
      name: "Final Destination" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"portfinal_code"} data={data} section="loadoutgrid" />;
      },
      formatter: ({ row, column }) => <div>{groupedPortOptions[row[column.key]]}</div>,
      editor: (props) => <DropdownEditor {...props} options={portoptions} valueKey="code" displayKey={"display"} />,
      editable: canEdit,
    },
    {
      key: "loadout_voyageNumber",
      name: "Voyage" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"loadout_voyageNumber"} data={data} section="loadoutgrid" />;
      },
      editor: TextEditor,
      editable: canEdit,
    },
    {
      key: "created_at",
      name: "Creation Date" + postValue,
      width: cellWidthMedium,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"created_at"} data={data} section="loadoutgrid" />;
      },
      formatter: DateFormatter,
      filterable: true,
    },
    {
      key: "barcode_nocartons",
      name: "No Cartons" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"barcode_nocartons"} data={data} section="loadoutgrid" />;
      },
      formatter: FormatterNumber,
      summaryFormatter: SummaryNumber,
      headerRenderer: RightAlignColumnHeader,
    },
    {
      key: "barcode_palletsize",
      name: "Pallet Size" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"barcode_palletsize"} data={data} section="loadoutgrid" />;
      },
      formatter: FormatterNumber2Decimals,
      summaryFormatter: SummaryNumber2Decimal,
      headerRenderer: RightAlignColumnHeader,
    },
    {
      key: "notes",
      name: "Notes" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"notes"} data={data} section="loadoutgrid" />;
      },
      editor: TextEditor,
      editable: true,
    },
  ].map((c) => ({ ...c, ...defaultColumnProperties }));

  let columnsArranged = [];
  columnsArranged = handleColumnsArrangement(columnsArranged, arrangement, columns);
  columnsArranged = handleColumnsWidth(columnsArranged, columnsWidth, columns);

  return columnsArranged;
};
