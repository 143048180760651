import React, { useState, useEffect } from "react";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";

import IconAdd from "@material-ui/icons/Add";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

import { notifyFull, notifyFullByIdStatus } from "../lib/api/notify";
import { notify } from "../lib/api/notify";

import { UserContext } from "../lib/context/UserContext";
import { WSSContext } from "../lib/context/WSSContext";
import Confirmation from "../lib/components/confirmation";

import NotificationForm from "./notificationsaddnewform";
import NotificationCard from "./notificationscard";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      width: "800px",
      display: "flex",
      flexDirection: "row",
    },
    comments: {
      padding: "10px",
      width: "600px",
    },
    gridPaper: {
      width: "100%",
      margin: "0",
    },
    tableColTopLeft: {
      verticalAlign: "top",
      textAlign: "left",
      marginTop: "8px",
      marginRight: "10px",
    },
  });

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

type NotificationsProps = {} & WithStyles<typeof styles>;
const NotificationsUnstyled: React.SFC<NotificationsProps> = (props) => {
  const { classes } = props;
  const [data, setData] = useState([]);
  const [markComplete, setMarkComplete] = useState(0);

  const [open, setOpen] = React.useState(false);

  const { user } = React.useContext(UserContext);
  const { wss } = React.useContext(WSSContext);

  const [tabIndex, setTabIndex] = React.useState(0);
  const [showAll, setShowAll] = React.useState(false);

  useEffect(() => {
    if (user && user.username.length != 0) {
      loadData();
    }
  }, [user, wss, tabIndex, showAll]);

  const loadData = async () => {
    switch (tabIndex) {
      case 2:
        notifyFull().then((result) => {
          setData([...result]);
        });
        break;
      case 1:
        notifyFullByIdStatus(0, user.id, showAll ? -1 : 0).then((result) => {
          setData([...result]);
        });
        break;
      default:
        notifyFullByIdStatus(user.id, 0, showAll ? -1 : 0).then((result) => {
          setData([...result]);
        });
        break;
    }
  };

  const handleCreateNew = () => {
    setOpen(true);
  };

  const handleCreateNewClose = () => {
    setOpen(false);
  };

  const handleCreateNewSubmit = (e, item) => {
    e.preventDefault();
    notify.create({
      data: item,
    });
    setOpen(false);
  };

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabIndex(newValue);
  };

  const handleShowAll = () => {
    setShowAll(!showAll);
  };

  return (
    <div className={classes.root}>
      <div>
        <Tabs variant="fullWidth" value={tabIndex} onChange={handleTabChange} aria-label="Notifications Tabs">
          <Tab label="Own" {...a11yProps(0)} />
          <Tab label="Sent" {...a11yProps(0)} />
          <Tab label="All" {...a11yProps(0)} />
        </Tabs>

        <Grid className={classes.comments} container spacing={2}>
          {data.map((item) => {
            // if (item.status == markComplete) {
            return (
              <Grid key={item.id} item xs={12}>
                <Paper>
                  <div className={classes.gridPaper}>
                    <NotificationCard notification={item} reloadData={loadData} />
                  </div>
                </Paper>
              </Grid>
            );
            // }
          })}
        </Grid>
        {open && (
          <Confirmation isOpen={open} handleClose={handleCreateNewClose} handleConfirm={() => {}} title={"Creating Notification"} body={undefined}>
            <NotificationForm reloadData={loadData} onClose={handleCreateNewClose} onSubmit={handleCreateNewSubmit} locked={false} />
          </Confirmation>
        )}
      </div>
      <div style={{ display: "flex", height: "3.75em" }}>
        {markComplete == 0 && (
          <Button title="Add New" variant="contained" onClick={handleCreateNew} style={{ marginRight: "1em" }}>
            <IconAdd />
          </Button>
        )}
        {tabIndex != 2 && <FormControlLabel control={<Checkbox checked={showAll} onChange={handleShowAll} value="checkedB" color="primary" />} label="Show All" />}
      </div>
    </div>
  );
};

export default withStyles(styles)(NotificationsUnstyled);
