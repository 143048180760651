import { client, request } from "./_base";

export type CreditorsInvoiceAdjustmentType = {
  id: number;
  creditorsinvoice_id: number;
  adjustdate: any;
  type: number;
  status: number;
  created_at: Date;
  updated_at: Date;
  amount: number;
  vat: number;
};

const endpoint = "creditorsinvoiceadjustment";

export const creditorsinvoiceadjustment = client(`${endpoint}`, (item) => ({
  ...item,
}));
