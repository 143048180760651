import React from "react";

import CustomAutoCompleteFilter from "../../lib/components/customdatagridfilter/CustomFilter";
import { FormatterNumber, FormatterNumber2Decimals, FormatterNumber3Decimals } from "../../lib/components/Formatters";
import { SummaryTotal, SummaryNumber, SummaryNumber2Decimal, SummaryNumber3Decimal } from "../../lib/components/SummaryFormatter";
import { EnquiryLink, Selector } from "../../lib/components/EnquiryLink";
import { handleColumnsArrangement, handleColumnsWidth } from "../../lib/helpers/grid";

const cellWidthTiny = 40;
const cellWidthSmall = 80;
const cellWidthMedium = 120;
const cellWidthLarge = 200;

const defaultColumnProperties = {
  resizable: true,
  sortable: true,
};

let currentSpace = true;
export const GridColumnsSelected = (data, filters, arrangement = undefined, columnsWidth = undefined) => {
  let postValue = "";

  if (data) {
    if (currentSpace) {
      postValue = " ";
    }
    currentSpace = !currentSpace;
  }

  const columns = [
    {
      key: "invoicenumber",
      name: "Invoice Number" + postValue,
      width: cellWidthMedium,
      filterable: true,
      formatter: (props) => <EnquiryLink {...props} selector={Selector.InvoiceNumber} />,
      filterRenderer: (e) => {
        return (
          <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"invoicenumber"} data={data} section="creditorsinfoselecteddetailfilter" />
        );
      },
      summaryFormatter: SummaryTotal,
    },
    {
      key: "barcode",
      name: "Barcode" + postValue,
      width: cellWidthLarge,
      filterable: true,
      formatter: (props) => <EnquiryLink {...props} selector={Selector.Barcode} />,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"barcode"} data={data} section="creditorsinfoselecteddetailfilter" />;
      },
    },
    {
      key: "pack_code",
      name: "Pack Code" + postValue,
      width: cellWidthSmall,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"pack_code"} data={data} section="creditorsinfoselecteddetailfilter" />;
      },
    },
    {
      key: "grade_code",
      name: "Grade Code" + postValue,
      width: cellWidthSmall,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"grade_code"} data={data} section="creditorsinfoselecteddetailfilter" />;
      },
    },
    {
      key: "mark_code",
      name: "Mark Code" + postValue,
      width: cellWidthSmall,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"mark_code"} data={data} section="creditorsinfoselecteddetailfilter" />;
      },
    },
    {
      key: "commodity_code",
      name: "Commodity Code" + postValue,
      width: cellWidthSmall,
      filterable: true,
      filterRenderer: (e) => {
        return (
          <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"commodity_code"} data={data} section="creditorsinfoselecteddetailfilter" />
        );
      },
    },
    {
      key: "variety_code",
      name: "Variety Code" + postValue,
      width: cellWidthSmall,
      filterable: true,
      filterRenderer: (e) => {
        return (
          <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"variety_code"} data={data} section="creditorsinfoselecteddetailfilter" />
        );
      },
    },
    {
      key: "count_code",
      name: "Count Code" + postValue,
      width: cellWidthSmall,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"count_code"} data={data} section="creditorsinfoselecteddetailfilter" />;
      },
    },
    {
      key: "inventory_code",
      name: "Inventory Code" + postValue,
      width: cellWidthSmall,
      filterable: true,
      filterRenderer: (e) => {
        return (
          <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"inventory_code"} data={data} section="creditorsinfoselecteddetailfilter" />
        );
      },
    },
    {
      key: "no_cartons",
      name: "No Cartons" + postValue,
      width: cellWidthSmall,
      filterable: true,
      formatter: FormatterNumber,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"no_cartons"} data={data} section="creditorsinfoselecteddetailfilter" />;
      },
      summaryFormatter: SummaryNumber,
    },
    {
      key: "pallet_size",
      name: "Pallet Size" + postValue,
      width: cellWidthSmall,
      filterable: true,
      formatter: FormatterNumber2Decimals,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"pallet_size"} data={data} section="creditorsinfoselecteddetailfilter" />;
      },
      summaryFormatter: SummaryNumber2Decimal,
    },
    {
      key: "puc",
      name: "PUC" + postValue,
      width: cellWidthSmall,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"puc"} data={data} section="creditorsinfoselecteddetailfilter" />;
      },
    },
    {
      key: "producer_code",
      name: "Producer" + postValue,
      width: cellWidthSmall,
      filterable: true,
      filterRenderer: (e) => {
        return (
          <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"producer_code"} data={data} section="creditorsinfoselecteddetailfilter" />
        );
      },
    },
    {
      key: "intake_waybill",
      name: "Intake Waybill" + postValue,
      width: cellWidthSmall,
      filterable: true,
      filterRenderer: (e) => {
        return (
          <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"intake_waybill"} data={data} section="creditorsinfoselecteddetailfilter" />
        );
      },
    },
    {
      key: "totalpercarton_exvat",
      name: "TTL / CTN EX VAT" + postValue,
      width: cellWidthSmall,
      filterable: true,
      formatter: FormatterNumber3Decimals,
      filterRenderer: (e) => {
        return (
          <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"totalpercarton_exvat"} data={data} section="creditorsinfoselecteddetailfilter" />
        );
      },
      summaryFormatter: ({ row, column }) => <SummaryNumber3Decimal row={row} column={{ ...column, key: "totalperpallet_exvat" }} />,
    },
    {
      key: "totalperpallet_exvat",
      name: "TTL / PLT EX VAT" + postValue,
      width: cellWidthSmall,
      filterable: true,
      formatter: FormatterNumber3Decimals,
      filterRenderer: (e) => {
        return (
          <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"totalperpallet_exvat"} data={data} section="creditorsinfoselecteddetailfilter" />
        );
      },
      summaryFormatter: SummaryNumber3Decimal,
    },
  ].map((c) => ({ ...c, ...defaultColumnProperties }));

  let columnsArranged = [];
  columnsArranged = handleColumnsArrangement(columnsArranged, arrangement, columns);
  columnsArranged = handleColumnsWidth(columnsArranged, columnsWidth, columns);

  return columnsArranged;
};
