import React from "react"
import "./style.css"

export const Triangle = () => {
  return (
    <>
      <div className="spacer"></div>
      <div className="arrow-up"></div>
      <div className="arrow-down"></div>
      <div className="arrow-left"></div>
      <div className="arrow-right"></div>
    </>
  )
}