export const SellingTerms = {
  0: "FIXED",
  1: "MGP",
  2: "CONSIGNMENT",
};

export const getSellingTermsByValue = (value: string) => {};

export const getSellingTermsReadyForCombo = () => {
  const result = [];
  Object.keys(SellingTerms).map((st) => {
    result.push({ value: st, display: SellingTerms[st] });
  });
  return result;
};
