export const PurchaseTerms = {
  0: "FIXED",
  1: "MGP",
  2: "CONSIGNMENT",
};

export const getPurchaseTermsReadyForCombo = () => {
  return Object.keys(PurchaseTerms).reduce((arr, st) => {
    arr.push({ value: st, display: PurchaseTerms[st] });
    return arr;
  }, []);
};
