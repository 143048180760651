import React from "react";

import IconLaunch from "@material-ui/icons/Launch";
import IconPDF from "@material-ui/icons/PictureAsPdf";
import IconDelete from "@material-ui/icons/Delete";
import IconEdit from "@material-ui/icons/Edit";
import IconMarket from "@material-ui/icons/StoreMallDirectory";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";

import { format } from "date-fns";
import parseISO from "date-fns/parseISO";
import toDate from "date-fns/toDate";

import CustomAutoCompleteFilter from "../lib/components/customdatagridfilter/CustomFilter";
import { handlePrint } from "../reports/inspectionPDF";
import { CellActionsFormatter } from "../lib/helpers/CellActionsFormatter";
import { handleColumnsArrangement, handleColumnsWidth } from "../lib/helpers/grid";

const cellWidthTiniest = 50;
const cellWidthTiny = 80;
const cellWidthSmall = 120;
const cellWidthMedium = 140;
const cellWidthMediumBigger = 150;
const cellWidthMediumLarge = 190;
const cellWidthLarge = 210;

const defaultColumnProperties = {
  resizable: true,
  sortable: true,
};

let currentSpace = true;

const inspectionDateFormatter = (data) => {
  return <div> {format(toDate(Number(data.row.inspectiondate)), "dd-MMM-yyyy HH:mm a")} </div>;
};
const completedDateFormatter = (data) => {
  if (!data || !data.row) return <></>;
  return <div> {format(parseISO(data.row.created_at.replace("Z", "")), "dd MMM yyyy HH:mm a")} </div>;
};
const checkMarkFormatter = (data) => {
  const { row, column } = data;

  return (
    <div>
      {" "}
      {row[column.key] == 1 ? (
        <div>
          <CheckIcon style={{ color: "green" }} />{" "}
        </div>
      ) : (
        <div>
          <CloseIcon style={{ color: "red" }} />{" "}
        </div>
      )}
    </div>
  );
};

export const GridColumns = (data, filters, arrangement, columnsWidth, handleView, handleRemove, handleShowMarketImages, handleEdit) => {
  let postValue = "";

  if (data) {
    data.map((item) => {
      if (item.packedcountry === null) {
        item.packedcountry = "";
      }
    });

    if (currentSpace) {
      postValue = " ";
    }
    currentSpace = !currentSpace;
  }

  const columns = [
    {
      key: "actions",
      name: "#A",
      width: cellWidthMediumLarge,
      formatter: ({ row }) => {
        const actions = getActions(row, handleView, handleRemove, handleShowMarketImages, handleEdit);
        return (
          <div style={{ display: "flex", justifyContent: "left", gap: "5px" }}>
            <CellActionsFormatter actions={actions} />
          </div>
        );
      },
    },
    {
      key: "username",
      name: "Username" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"username"} data={data} section={"inspectionsgridsetup"} />;
      },
    },
    {
      key: "week_week",
      name: "Week" + postValue,
      width: cellWidthTiny,
      filterable: true,
      filterRenderer: (e) => {
        return (
          <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"week_week"} data={data} section={"inspectionsgridsetup"} orderDescending />
        );
      },
    },
    {
      key: "producer_code",
      name: "Producer Code" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"producer_code"} data={data} section={"inspectionsgridsetup"} />;
      },
    },
    {
      key: "producer_name",
      name: "Producer Name" + postValue,
      width: cellWidthLarge,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"producer_name"} data={data} section={"inspectionsgridsetup"} />;
      },
    },
    {
      key: "puc_code",
      name: "PUC" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"puc_code"} data={data} section={"inspectionsgridsetup"} />;
      },
    },
    {
      key: "orchard_code",
      name: "Orchard" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"orchard_code"} data={data} section={"inspectionsgridsetup"} />;
      },
    },
    {
      key: "pickref",
      name: "Pick Ref." + postValue,
      width: cellWidthTiny,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"pickref"} data={data} section={"inspectionsgridsetup"} />;
      },
    },
    {
      key: "commodity",
      name: "Commodity" + postValue,
      width: cellWidthTiny,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"commodity"} data={data} section={"inspectionsgridsetup"} />;
      },
    },
    {
      key: "variety_code",
      name: "Variety" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"variety_code"} data={data} section={"inspectionsgridsetup"} />;
      },
    },
    {
      key: "class",
      name: "Grade" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"class"} data={data} section={"inspectionsgridsetup"} />;
      },
    },
    {
      key: "count",
      name: "Count" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"count"} data={data} section={"inspectionsgridsetup"} />;
      },
    },
    {
      key: "pack",
      name: "Pack" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"pack"} data={data} section={"inspectionsgridsetup"} />;
      },
    },
    {
      key: "inventory_code",
      name: "Inventory Code" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"inventory_code"} data={data} section={"inspectionsgridsetup"} />;
      },
    },
    {
      key: "cartons",
      name: "Cartons" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"cartons"} data={data} section={"inspectionsgridsetup"} />;
      },
    },
    {
      key: "specialmarket",
      name: "Special Market" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"specialmarket"} data={data} section={"inspectionsgridsetup"} />;
      },
      formatter: checkMarkFormatter,
    },
    {
      key: "packedmarket",
      name: "Packed Market" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"packedmarket"} data={data} section={"inspectionsgridsetup"} />;
      },
    },
    {
      key: "packedcountry",
      name: "Packed Country" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"packedcountry"} data={data} section={"inspectionsgridsetup"} />;
      },
    },
    {
      key: "internals",
      name: "Internals" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"internals"} data={data} section={"inspectionsgridsetup"} />;
      },
    },
    {
      key: "juice",
      name: "Juice" + postValue,
      width: cellWidthSmall,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"juice"} data={data} section={"inspectionsgridsetup"} />;
      },
      formatter: (data) => <div>{`${data.row.juice} %`}</div>,
    },
    {
      key: "fruitspec",
      name: "Fruitspec" + postValue,
      width: cellWidthSmall,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"fruitspec"} data={data} section={"inspectionsgridsetup"} />;
      },
      formatter: checkMarkFormatter,
    },
    {
      key: "number",
      name: "Inspection Number" + postValue,
      width: cellWidthSmall,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"number"} data={data} section={"inspectionsgridsetup"} />;
      },
    },
    {
      key: "general",
      name: "General" + postValue,
      width: cellWidthSmall,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"general"} data={data} section={"inspectionsgridsetup"} />;
      },
      formatter: checkMarkFormatter,
    },
    {
      key: "insp_weight",
      name: "Weight" + postValue,
      width: cellWidthSmall,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"insp_weight"} data={data} section={"inspectionsgridsetup"} />;
      },
      formatter: checkMarkFormatter,
    },
    {
      key: "insp_firmness",
      name: "Firmness" + postValue,
      width: cellWidthSmall,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"insp_firmness"} data={data} section={"inspectionsgridsetup"} />;
      },
      formatter: checkMarkFormatter,
    },
    {
      key: "insp_buttons",
      name: "Buttons" + postValue,
      width: cellWidthSmall,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"insp_buttons"} data={data} section={"inspectionsgridsetup"} />;
      },
      formatter: checkMarkFormatter,
    },
    {
      key: "color",
      name: "Colour" + postValue,
      width: cellWidthSmall,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"color"} data={data} section={"inspectionsgridsetup"} />;
      },
    },
    {
      key: "blemish",
      name: "Blemish" + postValue,
      width: cellWidthSmall,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"blemish"} data={data} section={"inspectionsgridsetup"} />;
      },
    },
    {
      key: "skintexture",
      name: "Texture" + postValue,
      width: cellWidthSmall,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"skintexture"} data={data} section={"inspectionsgridsetup"} />;
      },
    },
    {
      key: "granulation",
      name: "Granulation" + postValue,
      width: cellWidthSmall,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"granulation"} data={data} section={"inspectionsgridsetup"} />;
      },
    },
    {
      key: "grading",
      name: "Grading" + postValue,
      width: cellWidthSmall,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"grading"} data={data} section={"inspectionsgridsetup"} />;
      },
      formatter: checkMarkFormatter,
    },
    {
      key: "sizing",
      name: "Sizing" + postValue,
      width: cellWidthSmall,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"sizing"} data={data} section={"inspectionsgridsetup"} />;
      },
      formatter: checkMarkFormatter,
    },
    {
      key: "facility",
      name: "Facility" + postValue,
      width: cellWidthSmall,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"facility"} data={data} section={"inspectionsgridsetup"} />;
      },
      formatter: checkMarkFormatter,
    },
    {
      key: "postharvestlabel",
      name: "PH Labels" + postValue,
      width: cellWidthSmall,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"postharvestlabel"} data={data} section={"inspectionsgridsetup"} />;
      },
      formatter: checkMarkFormatter,
    },
    {
      key: "labelconsignee",
      name: "Consignee Label" + postValue,
      width: cellWidthSmall,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"labelconsignee"} data={data} section={"inspectionsgridsetup"} />;
      },
    },
    {
      key: "labelcountry",
      name: "Country Label" + postValue,
      width: cellWidthSmall,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"labelcountry"} data={data} section={"inspectionsgridsetup"} />;
      },
    },
    {
      key: "labtest",
      name: "Lab Test" + postValue,
      width: cellWidthSmall,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"labtest"} data={data} section={"inspectionsgridsetup"} />;
      },
    },
    {
      key: "degreen",
      name: "Degreen" + postValue,
      width: cellWidthSmall,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"degreen"} data={data} section={"inspectionsgridsetup"} />;
      },
    },
    {
      key: "piorder_ordernum",
      name: "Order Number" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"piorder_ordernum"} data={data} section={"inspectionsgridsetup"} />;
      },
    },
    { key: "inspectiondate", name: "Inspected", width: cellWidthLarge, formatter: inspectionDateFormatter },
    { key: "created_at", name: "Completed", width: cellWidthLarge, formatter: completedDateFormatter },
    {
      key: "number",
      name: "Inspection Number" + postValue,
      width: cellWidthLarge,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"number"} data={data} section={"inspectionsgridsetup"} />;
      },
    },
    {
      key: "groupnumberfull",
      name: "Group Number" + postValue,
      width: cellWidthLarge,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"groupnumberfull"} data={data} section={"inspectionsgridsetup"} />;
      },
    },
  ].map((c) => ({ ...c, ...defaultColumnProperties }));
  let columnsArranged = [];
  columnsArranged = handleColumnsArrangement(columnsArranged, arrangement, columns);
  columnsArranged = handleColumnsWidth(columnsArranged, columnsWidth, columns);

  return columnsArranged;
};

const getActions = (inspection, handleView, handleRemove, handleShowMarketImages, handleEdit) => {
  const actionsArr = [
    {
      icon: (
        <span style={{ float: "left" }} title="QC SUMMARY">
          <IconLaunch style={{ cursor: "pointer", color: "orange" }} />
        </span>
      ),
      callback: (e) => handleView(inspection),
    },
    {
      icon: (
        <span style={{ float: "left" }} title="DOWNLOAD">
          <IconPDF style={{ cursor: "pointer", color: "orange" }} />
        </span>
      ),
      callback: (e) => handlePrintPDF(inspection),
    },
  ];

  if (inspection.groupnumbercount > 0) {
    actionsArr.push({
      icon: (
        <span style={{ float: "left" }} title="PDF GROUP">
          <IconPDF style={{ cursor: "pointer", color: "blue" }} />
        </span>
      ),
      callback: (e) => handlePrintPDF(inspection, true),
    });
  }

  actionsArr.push(
    {
      icon: (
        <span style={{ float: "left" }} title="REMOVE INSPECTION">
          <IconDelete style={{ cursor: "pointer", color: "red" }} />
        </span>
      ),
      callback: (e) => handleRemove(inspection),
    },
    {
      icon: (
        <span style={{ float: "left" }} title="VIEW MARKETING IMAGES">
          <IconMarket style={{ cursor: "pointer", color: "orange" }} />
        </span>
      ),
      callback: (e) => handleShowMarketImages(inspection),
    },
    {
      icon: (
        <span style={{ float: "left" }} title="ADD ADDITIONAL PHOTO'S AND COMMENTS">
          <IconEdit style={{ cursor: "pointer", color: "orange" }} />
        </span>
      ),
      callback: (e) => handleEdit(inspection),
    },
  );

  return actionsArr;
};

const handlePrintPDF = async (inspection, printgroup = false) => {
  handlePrint(inspection, printgroup);
};
