import React from "react";

import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";
import { ClassNameMap } from "@material-ui/core/styles/withStyles";

import { Row } from "react-data-grid";

import { GridColumns } from "./detailgridsetup";
import Grid from "../../lib/components/grid";

import Button from "@material-ui/core/Button";
import Toolbar from "@material-ui/core/Toolbar";
import { adhocinvoicedetail, IAdhocInvoiceDetail } from "../../lib/api/adhoc_invoice_detail";
import { DocumentsButton, DocumentsType } from "../../lib/components/DocumentsButton";
import { handlePrintPDF } from "../../reports/adhocPDF";
import { companyDetails } from "../../lib/api/companydetails";
import { companyDetailsBanking } from "../../lib/api/companydetailsbanking";
import { exportAdhocInvoice } from "../../lib/helpers/Files";
import { clientsfinanceByClientID } from "../../lib/api/clientsfinance";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      height: "100%",
      position: "relative",
    },
    toolbarWrapper: {
      position: "relative",
      // width: "100%",
    },
    toolbar: {
      position: "absolute",
      left: 0,
      width: "100%",
      gap: `${theme.spacing(1)}px`,
    },
  });

export enum DetailGridType {
  GenerateReport,
  AddLine,
}

type AdhocInvoiceDetailProps = {
  selectedDetail: IAdhocInvoiceDetail[];
  documentDetail: any;
  gridType: DetailGridType;
  gridHeight: number;
  handleAddRow?(): void;
  handleClearRow?(): void;
  handleRowChange(data: IAdhocInvoiceDetail[]): void;
} & WithStyles<typeof styles>;

class AdhocInvoiceDetailUnstyled extends React.Component<AdhocInvoiceDetailProps, {}> {
  constructor(props) {
    super(props);
  }

  state = {
    selectedDetail: [],
  };

  loadData = () => {
    this.setState({ selectedDetail: this.props.selectedDetail });
  };

  handleRefresh = () => {
    this.loadData();
  };

  handleRemove = async (adhocDetailId: number) => {
    await adhocinvoicedetail.remove(adhocDetailId);
    const newSelected = this.props.selectedDetail.filter((detail) => detail.id != adhocDetailId);
    this.props.handleRowChange(newSelected);
  };

  rowRenderer = (props: any, classes: ClassNameMap<"root">) => {
    return <Row {...props} />;
  };

  clearSelected = () => {
    this.setState({ selectedDetail: [] });
  };

  handlePrintExcel = async (documentDetail, selectedDetail) => {
    const selectedAdhocInvoice = documentDetail.find((detail) => detail.id == selectedDetail[0].adhoc_invoice_id);
    await exportAdhocInvoice(selectedAdhocInvoice, selectedDetail);
  };

  getMenuItems = () => {
    return [
      {
        icon: DocumentsType.PDF,
        title: "AD HOC INVOICE",
        action: () => handlePrintPDF(this.props.documentDetail, this.props.selectedDetail),
      },
      {
        icon: DocumentsType.EXCEL,
        title: "AD HOC INVOICE",
        action: () => this.handlePrintExcel(this.props.documentDetail, this.props.selectedDetail),
      },
    ];
  };

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <div className={classes.toolbarWrapper}>
          <Toolbar className={classes.toolbar}>
            {this.props.gridType == DetailGridType.AddLine && (
              <Button color="primary" variant="contained" onClick={this.props.handleAddRow}>
                Add line
              </Button>
            )}
            {this.props.gridType == DetailGridType.GenerateReport && this.props.selectedDetail.length > 0 && (
              <>
                <DocumentsButton menuItems={this.getMenuItems()} disabled={false} />
                <Button variant="contained" color="primary" onClick={this.props.handleClearRow} disabled={false} style={{ marginRight: "10px" }}>
                  clear selected
                </Button>
              </>
            )}
          </Toolbar>
          <Grid
            fill
            showFilterChips
            loading={false}
            clearFilters={"adhoc_invoice"}
            data={this.props.selectedDetail}
            handleRefresh={this.handleRefresh}
            forceHeight={this.props.gridHeight} // fix this
            rowRenderer={(props) => this.rowRenderer(props, classes)}
            GridColumns={(data, filters, columnArrangement, columnsWidth) => GridColumns(data, filters, columnArrangement, columnsWidth, this.handleRemove)}
            handleChange={(grid, rows) => this.props.handleRowChange(rows)}
            totalRowColumns={["sub_total"]}
          />
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(AdhocInvoiceDetailUnstyled);
