import React from "react";
import CustomAutoCompleteFilter from "../../lib/components/customdatagridfilter/CustomFilter";

const cellWidthTiny = 50;
const cellWidthSmall = 115;
const cellWidthMedium = 150;
const cellWidthLarge = 220;

const defaultColumnProperties = {
  resizable: true,
  sortable: true,
};

let currentSpace = true;

export const GridColumns = (data, filters, columnsWidth, arrangement) => {
  let postValue = "";

  if (data) {
    if (currentSpace) {
      postValue = " ";
    }
    currentSpace = !currentSpace;
  }

  const columns = [
    {
      key: "id",
      name: "Id" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"id"} data={data} section="systemstabilityloadoutdetail" />;
      },
    },
    {
      key: "barcode",
      name: "Barcode" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"barcode"} data={data} section="systemstabilityloadoutdetail" />;
      },
    },
  ].map((c) => ({ ...c, ...defaultColumnProperties }));

  const columnsArranged = [];

  if (arrangement && arrangement.length > 0) {
    arrangement.map((item) => {
      const indexOfItem = columns.indexOf(columns.find((col) => col.name.trim() == item.toString().trim()));
      if (indexOfItem != -1) {
        columnsArranged.push(columns[indexOfItem]);
      }
    });
  } else {
    columns.map((colItem) => {
      columnsArranged.push(colItem);
    });
  }

  if (columnsWidth && columnsWidth.length > 0) {
    for (let index = 0; index < columnsArranged.length; index++) {
      const element = columnsArranged[index];
      const colItem = columnsWidth.find((colwitem) => colwitem.name.trim() == element.name.trim());
      if (colItem) {
        element.width = colItem.width;
      }
    }
  }

  return columnsArranged;
};
