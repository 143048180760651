import React from "react";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";

import Grid from "../../lib/components/grid";

import Confirmation from "../../lib/components/confirmation";

import { upsertProducer, getAllProducers, producers, ProducerType } from "../../lib/api/producer";
import { GridColumns } from "./producersgridsetup";

import Tooltip from "@material-ui/core/Tooltip";
import Button from "@material-ui/core/Button";

import IconAdd from "@material-ui/icons/Add";

import ProducersEditForm from "./producerseditform";
import { SnackContext } from "../../lib/context/SnackContext";
import { GenerateErrorMessage } from "../../lib/helpers/string_methods";
import { whoami } from "../../lib/api/_base";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1),
      width: "100%",
      height: "100%",
      position: "relative",
    },
    inline: {
      display: "inline",
      position: "absolute",
      marginTop: "7px",
      marginLeft: "7px",
    },
  });

type ProducersProps = {} & WithStyles<typeof styles>;

export class ProducersUnstyled extends React.Component<ProducersProps, any> {
  state = {
    classes: undefined,
    data: [],
    confirmRemoveID: undefined,
    confirmEditID: undefined,
    loading: true,
    layoutItem: undefined,
  };

  constructor(props) {
    super(props);
    this.state.classes = props.classes;
    this.state.layoutItem = "producerstablelayout";
  }

  static contextType = SnackContext;
  context!: React.ContextType<typeof SnackContext>;

  componentDidMount() {
    this.loadData();
  }

  loadData = async () => {
    const data = await getAllProducers();
    this.setState({ loading: false, data: data.data });
  };

  handleCreate = () => {
    this.setState({ confirmEditID: "0" });
  };

  handleEdit = (id) => {
    this.setState({ confirmEditID: id });
  };

  handleEditClose = () => {
    this.setState({ confirmEditID: undefined });
  };

  handleEditConfirm = async (item: ProducerType) => {
    try {
      await upsertProducer(item);
      this.loadData();
    } catch (error) {
      const err = GenerateErrorMessage(error, "Failed to create Producer");
      this.context.updateSnack({ show: true, color: "red", message: err });
    }
    this.handleEditClose();
  };

  handleRemove = async (id) => {
    this.setState({ confirmRemoveID: id });
  };

  handleRemoveClose = () => {
    this.setState({ confirmRemoveID: undefined });
  };

  handleRemoveConfirm = async () => {
    await producers
      .remove(this.state.confirmRemoveID)
      .then(() => {
        this.handleRemoveClose();
        this.loadData();
      })
      .then((error: any) => {
        const err = GenerateErrorMessage(error, "failed to retrieve data");
        this.context.updateSnack({ show: true, color: "red", message: err });
      });
  };

  GridColumnsOverride = (column, row, columnArrangement, columnsWidth) => {
    return GridColumns(column, row, columnArrangement, columnsWidth, this.handleRemove, this.handleEdit);
  };

  render() {
    const { classes } = this.state;

    return (
      <div className={classes.root}>
        <div className={classes.inline}>
          <Tooltip title="Create new Producer">
            <div className={classes.inline}>
              <Button variant="contained" color="primary" onClick={this.handleCreate} style={{ marginBottom: "10px" }}>
                <IconAdd />
              </Button>
            </div>
          </Tooltip>
        </div>
        <Grid loading={this.state.loading} data={this.state.data} GridColumns={this.GridColumnsOverride} clearFilters={"maintproducers"} />
        {this.state.confirmRemoveID && (
          <Confirmation
            isOpen={this.state.confirmRemoveID ? true : false}
            handleClose={this.handleRemoveClose}
            handleConfirm={this.handleRemoveConfirm}
            title="Remove selected Producer?"
            body="Are you sure you want to REMOVE the selected Producer Record?"
          ></Confirmation>
        )}
        {this.state.confirmEditID && (
          <Confirmation
            isOpen={this.state.confirmEditID ? true : false}
            handleClose={this.handleEditClose}
            handleConfirm={() => {}}
            title={this.state.confirmEditID == "0" ? "Creating Producer" : "Editing Producer - " + this.state.data.find((dataitem) => dataitem.id == this.state.confirmEditID).name}
            body={undefined}
            fullscreen={false}
          >
            <ProducersEditForm onSubmit={this.handleEditConfirm} onClose={this.handleEditClose} id={this.state.confirmEditID} />
          </Confirmation>
        )}
      </div>
    );
  }
}

export default withStyles(styles)(ProducersUnstyled);
