import { client } from "./_base";

const endpoint = "agent";

export type Agent = {
  id: number;
  agentname: string;
  note: string;
  default: number;
};

export const agent = client(endpoint, (item) => ({
  ...item,
}));

export const getAgentsReadyForCombo = () => {
  return agent.all().then((result) => {
    const ready = result
      .sort((a, b) => {
        if (a.agentname > b.agentname) return 1;
        if (a.agentname < b.agentname) return -1;
        return 1;
      })
      .map((ag) => {
        return { value: ag.id, display: ag.agentname, data: ag };
      });
    return ready;
  });
};

export const getAgentByDefault = async (agent_default: number) => {
  const agentsAll: Agent[] = await agent.all();
  return agentsAll.find((agent) => agent.default === agent_default);
};
