import { client, apiHeaders, request } from "./_base";
import { DispatchType } from "./dispatch";
import { DispatcheddocsFullType } from "./dispatcheddocs";
import { LoadoutType } from "./loadout";
import { SaleDetailType } from "./saledetail";
import { getFinancialYearSelected } from "./week";

import format from "date-fns/format";

const endpoint = "sale";

export type SaleType = {
  id: number;
  dispatch_id: number;
  date: Date;
  invoicenumber: string;
  ucr: string;
  terms_id: number;
  ata: Date;
  notifyparty_id: number;
  payment1amount: number;
  payment1date: Date;
  payment2amount: number;
  payment2date: Date;
  payment3amount: number;
  payment3date: Date;
  exchangerate: number;
  type?: number;
  clients_id: number;
  created_at?: Date;
  updated_at?: Date;
  trader_id: number;
  invoicenumber_short: string;
  purchaseterm_id: number;
};

export const sale = client(endpoint, (item) => ({
  ...item,
}));

export type SaleDispatchJoinedType = {
  dispatch_id: number;
  dispatch_dispatchdocs_id: string;
  dispatch_loaddate: Date;
  sale_id: number;
  sale_date: Date;
  sale_dispatch_id: number;
  sale_exchangerate: number;
  sale_invoicenumber: string;
  sale_ucr: string;
  sale_trader_id: number;
  sale_terms_id: number;
  sale_created_at: Date;
  sale_updated_at: Date;
  sale_ata: Date;
  sale_notifyparty_id: number;
  loadout_consignee: string;
  dispatch_dealtype: number;
  dispatch_sellingterm: number;
  dispatch_currency_id: number;
  terms_description: string;
  currency_code: string;
  clients_name: string;
  users_username: string;
  agent_id: number;
  agent_agentname: string;
  loadout_id: number;
  loadout_exportNotificationReference: string;
  loadout_voyageNumber: string;
  vessel_id: number;
  vessel_code: string;
  dispatch_containerno: string;
  dispatch_containertype: string;
  dispatch_sealnumber: string;
  dispatch_consignee_id: number;
  dispatch_clearingagent_id: number;
  portloading_id: number;
  portloading_code: string;
  portloading_description: string;
  portdistcharge_id: number;
  portdistcharge_code: string;
  portdistcharge_description: string;
  portfinal_id: number;
  portfinal_code: string;
  portfinal_description: string;
  loadout_etd: Date;
  loadout_eta: Date;
  sale_payment1amount: number;
  sale_payment1date: Date;
  sale_payment2amount: number;
  sale_payment2date: Date;
  sale_payment3amount: number;
  sale_payment3date: Date;
  sale_type: number;
  dispatch_forwardagentref: string;
  producer_code: string;
  camend_status: number;
  camend_status_text: number;
  dispatch_remarks: string;
  client_po: string;
  producersale_id: number;
  produceradvancepaidhead_id: number;
  loadout_updated_eta: Date;
  sale_purchaseterm_id: number;
};

export const saleDispatchJoined = async (financialyear = "0") => {
  const result = await request(`${endpoint}/ext/saleDispatchJoined/${financialyear}`, {
    headers: apiHeaders(),
  });
  const resp = await result.json();
  if (result.status == 500) {
    throw resp;
  }
  return resp.data;
};

export type SaleBarcodesByDispatchIDType = {
  id: number;
  dispatch_id: number;
  sale_id: number;
  sale_invoicenumber: string;
  loadout_exportNotificationReference: string;
  loadout_reference: string;
  loadoutdetail_barcode: string;
  barcode_nocartons: number;
  barcode_commoditycode: string;
  barcode_varietycode: string;
  barcode_packcode: string;
  barcode_markcode: string;
  barcode_gradecode: string;
  barcode_countcode: string;
  barcode_inventorycode: string;
  barcode_farm: string;
  barcode_palletsize: string;
  barcode_id: number;
  currency: string;
  comment: string;
  producer_code: string;
  stockdetail_sellingprice: number;
  total: number;
  group?: number;
  barcode?: string;
  dispatch_containerno: string;
  dispatch_containertype: string;
  dispatch_sealnumber: string;
  dispatch_consignee_id: string;
  dispatch_clearingagent_id: string;
  camend_status: number;
  purchase_deal_type: number;
  producer_id: number;
};
export const saleBarcodesByDispatchID = async (dispatchid: any, producercode: string = "0") =>
  await request(`${endpoint}/ext/saleBarcodesByDispatchID/${dispatchid}/${producercode}`, {
    headers: apiHeaders(),
  })
    .then(async (result) => {
      if (result.status == 500) {
        const resp = await result.json();
        throw resp;
      }
      return result.json();
    })
    .then(({ data }) => {
      return data;
    });

export const salePackingListSummaryByDispatchID = async (dispatchid: any) =>
  await request(`${endpoint}/ext/salePackingListSummaryByDispatchID/${dispatchid}`, {
    headers: apiHeaders(),
  })
    .then(async (result) => {
      if (result.status == 500) {
        const resp = await result.json();
        throw resp;
      }
      return result.json();
    })
    .then(({ data }) => {
      return data;
    });

export type SaleHeaderType = {
  sale_date: Date;
  dispatch_date: Date;
  invoicenumber: string;
  vessel: string;
  vessel_description: string;
  creditnoteno: string;
  clientname: string;
  clientaddress: string;
  clientstelephone: string;
  clientsemail: string;
  sales_person: string;
  shippingRef: string;
  ucrno: string;
  containerno: string;
  portloading: string;
  portdischarge: string;
  portfinal: string;
  eta: string;
  etd: string;
  termid: number;
  grossweight: number;
  nettweight: number;
  tempcode: string;
  sale_id: number;
  temptail: string;
  recorder_position: string;
  dispatch_invoicedate: string;
  forwardagentref: string;
  dispatch_remarks: string;
  client_dealtype: string;
  dispatch_sealnumber: string;
  consignee_name: string;
  consignee_address: string;
  consignee_email: string;
  consignee_telephone: string;
  saleadjustment_ident: string;
  orderno: string;
  statement_account_id: number;
};

export const saleHeader = async (dispatchid: any): Promise<SaleHeaderType[]> => {
  const result = await request(`${endpoint}/ext/saleHeader/${dispatchid}`, {
    headers: apiHeaders(),
  });
  const resp = await result.json();
  if (result.status == 500) {
    throw resp;
  }
  return resp.data;
};

export type SaleDispatchType = {
  barcode_nocartons: number;
  barcode_commoditycode: string;
  barcode_commodityname: string;
  barcode_varietycode: string;
  barcode_varietyname: string;
  barcode_packcode: string;
  barcode_markcode: string;
  barcode_gradecode: string;
  barcode_countcode: string;
  barcode_id: number;
  currency: string;
  barcode_inventorycode: string;
  barcode_palletsize: number;
  stockdetail_sellingprice: number;
  total: number;
  sale_type: number;
};

export const saleByDispatchId = async (dispatchid: string): Promise<SaleDispatchType[]> => {
  const result = await request(`${endpoint}/ext/saleByDispatchId/${dispatchid}`, {
    headers: apiHeaders(),
  });
  const resp = await result.json();
  if (result.status == 500) {
    throw resp;
  }
  return resp.data;
};

export const saleCreateProForma = async (loadoutid: number) =>
  await request(`${endpoint}/ext/createProForma/${loadoutid}`, {
    headers: apiHeaders(),
  })
    .then(async (result) => {
      if (result.status == 500) {
        const resp = await result.json();
        throw resp;
      }
      return result.json();
    })
    .then(({ data }) => {
      return data;
    });

export const updateQX = async (invoicenumber, data) => {
  const content = JSON.stringify(data);
  return request(`${endpoint}/ext/updateQX/${invoicenumber}`, {
    method: "PUT",
    headers: apiHeaders(content),
    body: content,
  }).then(async (result) => {
    if (result.status == 500) {
      const resp = await result.json();
      throw resp;
    }
    return result.json();
  });
};

export const getSaleDetailByReference = (reference: string) =>
  request(`${endpoint}/ext/getSaleDetailByReference?load_ref=${reference}&financial_year=${getFinancialYearSelected()}`, {
    headers: apiHeaders(),
  })
    .then(async (result) => {
      if (result.status == 500) {
        const resp = await result.json();
        throw resp;
      }
      return result.json();
    })
    .then(({ data }) => {
      return data;
    });

export const upsertMappingInfo = async (sale_id: number, mappingGroup: any[]) => {
  const content = JSON.stringify(mappingGroup);
  return request(`${endpoint}/ext/upsertMappingInfo/${sale_id}`, {
    method: "POST",
    headers: apiHeaders(content),
    body: content,
  }).then(async (result) => {
    if (result.status == 500) {
      const resp = await result.json();
      throw resp;
    }
    return result.json();
  });
};

export const getInvoiceNumbersForIntegration = async (financial_year: number): Promise<PIInvoiceNumbers[]> => {
  const result = await request(`${endpoint}/ext/invoiceNumbersForIntegration/${financial_year}`, {
    headers: apiHeaders(),
  });
  const resp = await result.json();
  if (result.status == 500) {
    throw resp;
  }
  return resp.data;
};

export const getInvoiceIntegrationByInvoice = async (invoicenumber: string): Promise<PIInvoiceLines[]> => {
  const result = await request(`${endpoint}/ext/invoiceIntegrationByInvoice/${invoicenumber}`, {
    headers: apiHeaders(),
  });
  const resp = await result.json();
  if (result.status == 500) {
    throw new Error(resp);
  }
  return resp.data;
};

export const exportShipmentsSummary = async (allocationData: any[]) => {
  const fileName = `${format(new Date(), "yyMMdd")} Commercial Invoice Shipment Export.xlsx`;
  const content = JSON.stringify({ allocationData, fileName });

  const result = await request(`${endpoint}/ext/exportShipmentsSummary`, {
    method: "POST",
    headers: apiHeaders(content),
    body: content,
  });
  const blob = await result.blob();

  let url = window.URL.createObjectURL(blob);
  let a = document.createElement("a");
  a.href = url;
  a.download = fileName;
  a.click();
};

export type PIInvoiceNumbers = {
  id: number;
  sale_invoicenumber: string;
  dispatch_id: number;
  sale_date: Date;
  accepted: number;
  merged: number;
  producer_id: string;
};

export type PIInvoiceLines = {
  POD: string;
  accepted: number;
  audid: number;
  barcode: string;
  clientsName: string;
  coldDate: string;
  commodity: string;
  consigneeId: string;
  countCode: string;
  destination: string;
  dip: string;
  farmNumber: string;
  fob: string;
  gradeCode: string;
  intakeDate: string;
  intakeId: null;
  intakeWaybill: string;
  inventoryCode: string;
  invoiceId: number;
  invoiceNumber: string;
  markCode: string;
  match: string;
  mbrand: string;
  mcarton: string;
  mclass: string;
  mcount: string;
  merged: number;
  mgp: string;
  mweek: string;
  noCartons: number;
  packCode: string;
  palletId: number;
  producerId: string;
  saleId: number;
  sequenceNumber: number;
  shippingMarket: string;
  target: number;
  targetMarket: string;
  targetMarketIntake: string;
  varietyCode: string;
  week: string;
  week_id: number;
  barcode_id: number;
};

export const checkPurchaseTermLockedSale = async (sale_id: number, purchase_term: number): Promise<{ sale_id: number }[]> => {
  const result = await request(`${endpoint}/ext/purchaseTermLockedSale?sale_id=${sale_id}&purchase_term=${purchase_term}`, {
    headers: apiHeaders(),
  });
  const resp = await result.json();
  if (result.status == 500) {
    throw resp;
  }
  return resp.data;
};

export const proformaExistsForPallets = async (barcode_ids: number[], sale_id: number): Promise<{ sale_id: number }[]> => {
  const result = await request(`${endpoint}/ext/proformaExistsForPallets`, {
    headers: apiHeaders(),
    method: "POST",
    body: JSON.stringify({ barcode_ids, sale_id }),
  });
  const resp = await result.json();
  if (result.status == 500) {
    throw resp;
  }
  return resp.data;
};

type UpsertSaleRecord = {
  sale: SaleType;
  dispatchData: Partial<DispatchType>;
  loadoutData: Partial<LoadoutType>;
  saleDetail: Partial<SaleDetailType & { client: string; po: string; producer_id: number }>[];
  dispatchDocsData: Partial<DispatcheddocsFullType>;
};

export const upsertSale = async (data: UpsertSaleRecord): Promise<void> => {
  const result = await request(`${endpoint}/ext/upsertSale`, {
    headers: apiHeaders(),
    method: "POST",
    body: JSON.stringify(data),
  });
  if (result.status == 500) {
    const resp = await result.json();
    throw resp;
  }
};

type UpdateSaleJoinedRecord = {
  sale: SaleType;
  loadout: Partial<LoadoutType>;
  dispatch: Partial<DispatchType>;
};

export const updateSaleJoinedRecord = async (data: UpdateSaleJoinedRecord): Promise<void> => {
  const result = await request(`${endpoint}/ext/updateSaleJoinedRecord`, {
    headers: apiHeaders(),
    method: "POST",
    body: JSON.stringify(data),
  });
  if (result.status == 500) {
    const resp = await result.json();
    throw resp;
  }
};

export type InvoicesForCombinedType = {
  sale_invoicenumber: string;
  final_destination: string;
  pod: string;
  payment_terms: string;
  client: string;
  vessel: string;
};

export const getInvoicesForCombined = async (): Promise<InvoicesForCombinedType[]> => {
  const result = await request(`${endpoint}/ext/getInvoicesForCombined/${getFinancialYearSelected()}`, {
    headers: apiHeaders(),
    method: "GET",
  });
  const resp = await result.json();
  if (result.status == 500) {
    throw resp;
  }
  return resp.data;
};

export type SaleHeaderForCombinedInvoicesType = {
  sale_id: number;
  dispatch_id: number;
  invoicenumber: string;
  sale_date: Date;
  orderno: string;
  forwardagentref: string;
  vessel_description: string;
  shippingRef: string;
  portdischarge: string;
  portfinal: string;
  eta: Date;
  etd: Date;
  containerno: string;
  sealnumber: string;
  currency: string;
  clients_name: string;
  clientaddress: string;
  clientstelephone: string;
  clientsemail: string;
  sales_person: string;
  statement_account_id: number;
  dispatch_remarks: string;
  client_dealtype: number;
};

export const getSaleHeaderForCombinedInvoices = async (invoicenumbers: string[]): Promise<SaleHeaderForCombinedInvoicesType[]> => {
  const result = await request(`${endpoint}/ext/getSaleHeaderForCombinedInvoices?invoices=${invoicenumbers.join(",")}`, {
    headers: apiHeaders(),
    method: "GET",
  });
  const resp = await result.json();
  if (result.status == 500) {
    throw resp;
  }
  return resp.data;
};
