import React from "react";
import { NavLink } from "react-router-dom";

import { CheckboxSelect } from "../../../lib/components/CheckboxSelect";
import CustomAutoCompleteFilter from "../../../lib/components/customdatagridfilter/CustomFilter";
import { EnquiryLink, Selector } from "../../../lib/components/EnquiryLink";
import { DateFormatter, FormatterNumber, FormatterNumber2Decimals } from "../../../lib/components/Formatters";
import { SummaryNumber, SummaryNumber2Decimal, SummaryTotal } from "../../../lib/components/SummaryFormatter";
import { handleColumnsArrangement, handleColumnsWidth } from "../../../lib/helpers/grid";

const cellWidthTiny = 40;
const cellWidthSmall = 80;
const cellWidthMedium = 120;
const cellWidthLarge = 200;

const defaultColumnProperties = {
  resizable: true,
  sortable: true,
};

let currentSpace = true;

export const DetailGridColumns = (data, filters, arrangement, columnsWidth, selectedRows, handleSelect) => {
  let postValue = "";

  if (data) {
    if (currentSpace) {
      postValue = " ";
    }
    currentSpace = !currentSpace;
  }

  const columns = [
    {
      key: "",
      name: "#C" + postValue,
      width: cellWidthTiny,
      formatter: ({ row }) => !row.producerfinalpaidhead_id && <CheckboxSelect row={row} selectedRows={selectedRows} handleCheckboxSelect={handleSelect} valueKey={"barcode_id"} />,
    },
    {
      key: "invoicenumber",
      name: "Invoice Number" + postValue,
      width: cellWidthMedium,
      filterable: true,
      summaryFormatter: SummaryTotal,
      formatter: (props) => <EnquiryLink {...props} selector={Selector.InvoiceNumber} />,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"invoicenumber"} data={data} section="producerfinalgriddetail" />;
      },
    },
    {
      key: "dispatch_id",
      name: "Dispatch ID" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"dispatch_id"} data={data} section="producerfinalgriddetail" />;
      },
    },
    {
      key: "ata",
      name: "ATA" + postValue,
      width: cellWidthMedium,
      filterable: true,
      formatter: DateFormatter,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"ata"} data={data} section="producerfinalgriddetail" />;
      },
    },
    {
      key: "barcode",
      name: "Barcode" + postValue,
      width: cellWidthMedium,
      filterable: true,
      formatter: (props) => <EnquiryLink {...props} selector={Selector.Barcode} />,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"barcode"} data={data} section="producerfinalgriddetail" />;
      },
    },
    {
      key: "IntakeWaybill",
      name: "Intake Waybill" + postValue,
      width: cellWidthMedium,
      filterable: true,
      formatter: (props) => <EnquiryLink {...props} selector={Selector.IntakeWaybill} />,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"IntakeWaybill"} data={data} section="producerfinalgriddetail" />;
      },
    },
    {
      key: "producerid",
      name: "Producer ID" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"producerid"} data={data} section="producerfinalgriddetail" />;
      },
    },
    {
      key: "FarmNumber",
      name: "Farm Number" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"FarmNumber"} data={data} section="producerfinalgriddetail" />;
      },
    },
    {
      key: "TargetMarket",
      name: "Target Market" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"TargetMarket"} data={data} section="producerfinalgriddetail" />;
      },
    },
    {
      key: "VarietyCode",
      name: "Variety Code" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"VarietyCode"} data={data} section="producerfinalgriddetail" />;
      },
    },
    {
      key: "GradeCode",
      name: "Grade Code" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"GradeCode"} data={data} section="producerfinalgriddetail" />;
      },
    },
    {
      key: "PackCode",
      name: "Pack Code" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"PackCode"} data={data} section="producerfinalgriddetail" />;
      },
    },
    {
      key: "CountCode",
      name: "Count Code" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"CountCode"} data={data} section="producerfinalgriddetail" />;
      },
    },
    {
      key: "MarkCode",
      name: "Mark Code" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"MarkCode"} data={data} section="producerfinalgriddetail" />;
      },
    },
    {
      key: "palletSize",
      name: "Pallet Size" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"palletSize"} data={data} section="producerfinalgriddetail" />;
      },
      formatter: FormatterNumber2Decimals,
      summaryFormatter: SummaryNumber2Decimal,
    },
    {
      key: "NoCartons",
      name: "No Cartons" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"NoCartons"} data={data} section="producerfinalgriddetail" />;
      },
      formatter: FormatterNumber,
      summaryFormatter: SummaryNumber,
    },
    {
      key: "currency",
      name: "Currency" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"currency"} data={data} section="producerfinalgriddetail" />;
      },
    },
    {
      key: "sellingprice",
      name: "Selling Price" + postValue,
      width: cellWidthMedium,
      filterable: true,
      formatter: FormatterNumber2Decimals,
      summaryFormatter: SummaryNumber2Decimal,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"sellingprice"} data={data} section="producerfinalgriddetail" />;
      },
    },
    {
      key: "sale_credit",
      name: "Credit/Debit" + postValue,
      width: cellWidthMedium,
      filterable: true,
      formatter: FormatterNumber2Decimals,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"sale_credit"} data={data} section="producerfinalgriddetail" />;
      },
    },
    {
      key: "sellingprice_final",
      name: "Selling Price Final" + postValue,
      width: cellWidthMedium,
      filterable: true,
      formatter: FormatterNumber2Decimals,
      summaryFormatter: SummaryNumber2Decimal,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"sellingprice_final"} data={data} section="producerfinalgriddetail" />;
      },
    },
    {
      key: "foreigncost",
      name: "Foreign Cost" + postValue,
      width: cellWidthMedium,
      filterable: true,
      formatter: FormatterNumber2Decimals,
      summaryFormatter: SummaryNumber2Decimal,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"foreigncost"} data={data} section="producerfinalgriddetail" />;
      },
    },
    {
      key: "adjustment",
      name: "Adjustment" + postValue,
      width: cellWidthMedium,
      filterable: true,
      formatter: (props) => {
        return <FormatterNumber2Decimals {...props} style={{ color: "green", fontWeight: "bold" }} />;
      },
      summaryFormatter: SummaryNumber2Decimal,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"adjustment"} data={data} section="producerfinalgriddetail" />;
      },
    },
    {
      key: "nettsellprice",
      name: "FOB Sell Price" + postValue,
      width: cellWidthMedium,
      filterable: true,
      formatter: FormatterNumber2Decimals,
      summaryFormatter: SummaryNumber2Decimal,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"nettsellprice"} data={data} section="producerfinalgriddetail" />;
      },
    },
    {
      key: "ld_fob",
      name: "PI FOB" + postValue,
      width: cellWidthMedium,
      filterable: true,
      formatter: (props) => {
        const { row } = props;
        if (Number(row.ld_fob) > 0) {
          return (
            <NavLink to={`/instruction/${row.pio_id}/edit`} activeClassName="selected" target={"_blank"}>
              <FormatterNumber2Decimals {...props} />;
            </NavLink>
          );
        }
        return <FormatterNumber2Decimals {...props} />;
      },
      summaryFormatter: SummaryNumber2Decimal,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"ld_fob"} data={data} section="producerfinalgriddetail" />;
      },
    },
    {
      key: "roe_eta",
      name: "ROE ETA" + postValue,
      width: cellWidthMedium,
      filterable: true,
      formatter: FormatterNumber2Decimals,
      summaryFormatter: SummaryNumber2Decimal,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"roe_eta"} data={data} section="producerfinalgriddetail" />;
      },
    },
    {
      key: "roe_etd",
      name: "ROE ETD" + postValue,
      width: cellWidthMedium,
      filterable: true,
      formatter: FormatterNumber2Decimals,
      summaryFormatter: SummaryNumber2Decimal,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"roe_etd"} data={data} section="producerfinalgriddetail" />;
      },
    },
    {
      key: "exchangerate",
      name: "Exchange Rate" + postValue,
      width: cellWidthMedium,
      filterable: true,
      formatter: (props) => {
        return <FormatterNumber2Decimals {...props} style={{ color: "green", fontWeight: "bold" }} />;
      },
      summaryFormatter: SummaryNumber2Decimal,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"exchangerate"} data={data} section="producerfinalgriddetail" />;
      },
    },
    {
      key: "sellpricezar",
      name: "FOB Price ZAR" + postValue,
      width: cellWidthMedium,
      filterable: true,
      formatter: FormatterNumber2Decimals,
      summaryFormatter: SummaryNumber2Decimal,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"sellpricezar"} data={data} section="producerfinalgriddetail" />;
      },
    },
    {
      key: "fobcost",
      name: "Fob Cost" + postValue,
      width: cellWidthMedium,
      filterable: true,
      formatter: FormatterNumber2Decimals,
      summaryFormatter: SummaryNumber2Decimal,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"fobcost"} data={data} section="producerfinalgriddetail" />;
      },
    },
    {
      key: "fobcost_est",
      name: "Fob Cost Estimate" + postValue,
      width: cellWidthMedium,
      filterable: true,
      formatter: FormatterNumber2Decimals,
      summaryFormatter: SummaryNumber2Decimal,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"fobcost_est"} data={data} section="producerfinalgriddetail" />;
      },
    },
    {
      key: "fobcost_final",
      name: "Fob Cost Used" + postValue,
      width: cellWidthMedium,
      filterable: true,
      formatter: (props) => {
        return <FormatterNumber2Decimals {...props} style={{ color: "green", fontWeight: "bold" }} />;
      },
      summaryFormatter: SummaryNumber2Decimal,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"fobcost_final"} data={data} section="producerfinalgriddetail" />;
      },
    },
    {
      key: "coldcost",
      name: "Cold Cost" + postValue,
      width: cellWidthMedium,
      filterable: true,
      formatter: FormatterNumber2Decimals,
      summaryFormatter: SummaryNumber2Decimal,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"coldcost"} data={data} section="producerfinalgriddetail" />;
      },
    },
    {
      key: "coldcost_est",
      name: "Cold Cost Estimate" + postValue,
      width: cellWidthMedium,
      filterable: true,
      formatter: FormatterNumber2Decimals,
      summaryFormatter: SummaryNumber2Decimal,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"coldcost_est"} data={data} section="producerfinalgriddetail" />;
      },
    },
    {
      key: "coldcost_final",
      name: "Cold Cost Used" + postValue,
      width: cellWidthMedium,
      filterable: true,
      formatter: (props) => {
        return <FormatterNumber2Decimals {...props} style={{ color: "green", fontWeight: "bold" }} />;
      },
      summaryFormatter: SummaryNumber2Decimal,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"coldcost_final"} data={data} section="producerfinalgriddetail" />;
      },
    },
    {
      key: "margin",
      name: "Margin" + postValue,
      width: cellWidthMedium,
      filterable: true,
      formatter: FormatterNumber2Decimals,
      summaryFormatter: SummaryNumber2Decimal,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"margin"} data={data} section="producerfinalgriddetail" />;
      },
    },
    {
      key: "totalcost",
      name: "Total Cost" + postValue,
      width: cellWidthMedium,
      filterable: true,
      formatter: FormatterNumber2Decimals,
      summaryFormatter: SummaryNumber2Decimal,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"totalcost"} data={data} section="producerfinalgriddetail" />;
      },
    },
    {
      key: "rtgc",
      name: "RTGC" + postValue,
      width: cellWidthMedium,
      filterable: true,
      formatter: FormatterNumber2Decimals,
      summaryFormatter: SummaryNumber2Decimal,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"rtgc"} data={data} section="producerfinalgriddetail" />;
      },
    },
    {
      key: "ld_dip",
      name: "PI DIP" + postValue,
      width: cellWidthMedium,
      filterable: true,
      formatter: (props) => {
        const { row } = props;
        if (Number(row.ld_fob) > 0) {
          return (
            <NavLink to={`/instruction/${row.pio_id}/edit`} activeClassName="selected" target={"_blank"}>
              <FormatterNumber2Decimals {...props} />;
            </NavLink>
          );
        }
        return <FormatterNumber2Decimals {...props} />;
      },
      summaryFormatter: SummaryNumber2Decimal,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"ld_dip"} data={data} section="producerfinalgriddetail" />;
      },
    },
    {
      key: "rtgp",
      name: "RTGP" + postValue,
      width: cellWidthMedium,
      filterable: true,
      formatter: FormatterNumber2Decimals,
      summaryFormatter: SummaryNumber2Decimal,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"rtgp"} data={data} section="producerfinalgriddetail" />;
      },
    },
    {
      key: "advance",
      name: "Advance" + postValue,
      width: cellWidthMedium,
      filterable: true,
      formatter: FormatterNumber2Decimals,
      summaryFormatter: SummaryNumber2Decimal,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"advance"} data={data} section="producerfinalgriddetail" />;
      },
    },
    {
      key: "final",
      name: "Final" + postValue,
      width: cellWidthMedium,
      filterable: true,
      formatter: (props) => {
        return <FormatterNumber2Decimals {...props} style={{ color: "green", fontWeight: "bold" }} />;
      },
      summaryFormatter: SummaryNumber2Decimal,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"final"} data={data} section="producerfinalgriddetail" />;
      },
    },
  ].map((c) => ({ ...c, ...defaultColumnProperties }));

  let columnsArranged = [];
  columnsArranged = handleColumnsArrangement(columnsArranged, arrangement, columns);
  columnsArranged = handleColumnsWidth(columnsArranged, columnsWidth, columns);

  return columnsArranged;
};

export function getCellActions(column, row) {
  return null;
}
