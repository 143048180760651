import React, { useContext, useEffect, useRef, useState } from "react";

import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";

import "../lib/custom/splitter.css";
import "react-splitter-layout/lib/index";
import Splitter from "m-react-splitters";
import "m-react-splitters/lib/splitters.css";

import AppBar from "@material-ui/core/AppBar";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

import CloseIcon from "@material-ui/icons/Close";
import LockIcon from "@material-ui/icons/Lock";

import Grid from "../lib/components/grid";

import { EnquiryEdiHeaderGridColumns } from "./enquiryediheadergridsetup";
import { EnquiryBarcodeGridColumns } from "./enquirybarcodegridsetup";
import { EnquiryBarcodeHistoryGridColumns } from "./enquirybarcodehistorygridsetup";
import { EnquirySaleGridColumns } from "./enquirysalegridsetup";
import { EnquirySaleBarcodesGridColumns } from "./enquirysalebarcodesgridsetup";
import { getEnquiryDataByBarcode } from "../lib/api/enquiry";
import { EnquiryContext } from "../lib/context/EnquiryContext";
import { EnquiryProducersFullGridColumns } from "./enquiryproducersfullgridsetup";
import { GenerateErrorMessage } from "../lib/helpers/string_methods";
import { SnackContext } from "../lib/context/SnackContext";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: "grid",
      position: "absolute",
      right: 0,
      top: 0,
      width: "100%",
      height: "100%",
      maxWidth: "100vw",
    },
    enquiryHeader: {
      paddingLeft: 10,
      paddingRight: 10,
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
    },
    enquiryHeaderText: {
      fontWeight: "lighter",
      marginLeft: "10px",
      fontSize: "1.2rem",
    },
    resizeHandle: {
      position: "absolute",
      left: 0,
      top: 0,
      height: "100%",
      width: "5px",
      backgroundColor: "#333",
      cursor: "e-resize",
    },
    splitterLocked: {
      maxWidth: "100vw",
      "& .pane.vertical.primary": {
        zIndex: -10,
      },
      "& .handle-bar.vertical": {
        zIndex: 1260,
      },
      "& .pane.vertical": {
        background: "white",
        zIndex: 1250,
      },
    },
    splitterUnlocked: {
      maxWidth: "100vw",
      "& .pane.vertical.primary": {
        backgroundColor: "transparent",
        zIndex: 1250,
      },
      "& .handle-bar.vertical": {
        zIndex: 1260,
      },
      "& .pane.vertical": {
        background: "white",
        zIndex: 1250,
      },
    },
    enquiryDrawerWrapper: {
      height: "100%",
    },
    loadingWrapper: {
      position: "relative",
      height: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
    },
    container: {
      paddingLeft: "10px",
      paddingRight: "10px",
      position: "relative",
      height: "calc(100% - 70px)",
    },
    searchWrapper: {
      display: "flex",
      marginBottom: "10px",
    },
    tabPanel: {
      height: "100%",
      "& > :first-child": {
        padding: 0,
        height: "100%",
        "& > :first-child": {
          height: "100%",
        },
      },
    },
    totalRowStyle: {
      background: "#ddd",
      textAlign: "right",
      fontWeight: "bold",
      color: "black",
    },
    totalRowStyleFixed: {
      position: "sticky",
      bottom: 0,
      background: "#ddd",
      textAlign: "right",
      fontWeight: "bold",
      color: "black",
    },
    lastRowStyle: {
      display: "flex",
      flexDirection: "column-reverse",
      color: "black",
    },
  });

type EnquiryProps = {
  classes: any;
  enquiryOpen: boolean;
  enquiryDrawerWidth: number;
  closeEnquiryDrawer(): void;
  handleDrawerResize(ref: any): void;
} & WithStyles<typeof styles>;

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && (
        <Box p={3}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
};

const a11yProps = (index) => {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
};

enum Selector {
  Barcode = 1,
  InvoiceNumber = 2,
  IntakeWaybill = 3,
}

const EnquiryUnstyled: React.FunctionComponent<EnquiryProps> = (props) => {
  const { classes, enquiryOpen, enquiryDrawerWidth, handleDrawerResize, closeEnquiryDrawer } = props;
  const { enquiryValue, enquirySelectValue, setEnquirySearchValue, setEnquirySelectSearchValue } = useContext(EnquiryContext);
  const { updateSnack } = React.useContext(SnackContext);

  const [loading, setLoading] = useState<boolean>(false);

  const [ediHeaderInfoData, setEdiHeaderInfoData] = useState<any[]>([]);
  const [barcodeData, setBarcodeData] = useState<any[]>([]);
  const [barcodeHistoryData, setBarcodeHistoryData] = useState<any[]>([]);
  const [saleData, setSaleData] = useState<any[]>([]);
  const [saleBarcodesData, setSaleBarcodesData] = useState<any[]>([]);
  const [producersFullData, setProducersFullData] = useState<any[]>([]);

  const [saleGridMainHeight, setSaleGridMainHeight] = useState<number>(0);
  const [saleGridSecondHeight, setSaleGridSecondHeight] = useState<number>(0);

  const [gridHeight, setGridHeight] = useState<number>(500);
  const [locked, setLocked] = useState<boolean>(false);
  const [tabValue, setTabValue] = React.useState(0);

  const enquiryRef = useRef();

  const handleChangeTab = (event, newValue) => {
    setTabValue(newValue);
  };

  const loadData = async () => {
    setLoading(true);

    try {
      const result = await getEnquiryDataByBarcode({ value: enquiryValue, selector: enquirySelectValue });
      const ediHeaderInfo = result.data.ediHeaderInfoData
        .sort((a, b) => (b.Barcode || "").localeCompare(a.Barcode || ""))
        .map((item: { Date: string }) => ({
          ...item,
          Date: item.Date ? item.Date.replace("Z", "") : undefined,
        }));
      const barcodeData = result.data.barcodeData.sort((a, b) => (b.Barcode || "").localeCompare(a.Barcode || ""));
      const barcodeHistoryData = result.data.barcodeHistoryData.sort((a, b) => (b.Barcode || "").localeCompare(a.Barcode || ""));
      const saleDataSorted = result.data.saleData.sort((a, b) => (b.invoicenumber || "").localeCompare(a.invoicenumber || ""));
      const saleBarcodes = result.data.saleBarcodes.sort((a, b) => (b.sale_invoicenumber || "").localeCompare(a.sale_invoicenumber || ""));
      const producersFullDataSorted = result.data.producersFullData.sort((a, b) => (b.sale_invoicenumber || "").localeCompare(a.sale_invoicenumber || ""));

      setEdiHeaderInfoData(ediHeaderInfo);
      setBarcodeData(barcodeData);
      setBarcodeHistoryData(barcodeHistoryData);
      setSaleData(saleDataSorted);
      setSaleBarcodesData(saleBarcodes);
      setProducersFullData(producersFullDataSorted);
    } catch (error) {
      const err = GenerateErrorMessage(error, "Failed to retrieve data.");
      updateSnack({ show: true, color: "red", message: err });
    }

    setLoading(false);
  };

  const handleSetGridHeight = () => {
    const el = document.getElementById("main_splitter_div");
    setGridHeight(el.clientHeight - 250);
  };

  useEffect(() => {
    handleDrawerResize(enquiryRef);
  }, [enquiryRef]);

  useEffect(() => {
    if (enquiryOpen) {
      handleSetGridHeight();
      setSaleGridHeights();
    }
  }, [enquiryOpen]);

  useEffect(() => {
    setSaleGridHeights();
  }, [tabValue]);

  const handleLockScreen = () => {
    setLocked(!locked);
  };
  ``;

  const setSaleGridHeights = () => {
    const primarysplit = document.getElementsByClassName("sale_tab_grid");
    if (primarysplit && primarysplit.length > 0) {
      setSaleGridMainHeight(primarysplit[0].children[0].clientHeight - 80);
      setSaleGridSecondHeight(primarysplit[0].children[2].clientHeight - 180);
    }
  };

  const handleSelectorChange = (ev) => {
    setEnquirySelectSearchValue(parseInt(ev.target.value));
  };

  useEffect(() => {
    if (enquiryOpen && enquiryValue.length > 0) {
      loadData();
    }
  }, [enquiryValue, enquiryOpen]);

  if (!enquiryOpen) {
    return null;
  }

  return (
    <div className={classes.root}>
      <div id="main_splitter_div">
        <Splitter
          ref={enquiryRef}
          primaryPaneMinWidth="1px"
          postPoned={true}
          allowResize={true}
          position="vertical"
          dispatchResize={true}
          primaryPaneWidth={enquiryDrawerWidth}
          onDragFinished={() => handleDrawerResize(enquiryRef)}
          className={locked ? classes.splitterLocked : classes.splitterUnlocked}
        >
          <div onClick={closeEnquiryDrawer} style={{ height: "100%" }}></div>
          <div className={classes.enquiryDrawerWrapper}>
            <div className={classes.enquiryHeader}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <IconButton color="inherit" onClick={() => closeEnquiryDrawer()} style={{ fontSize: "1.2rem" }}>
                  <CloseIcon />
                </IconButton>
                <h3 className={classes.enquiryHeaderText}>Enquiry</h3>
              </div>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <IconButton onClick={handleLockScreen} style={{ color: locked ? "orange" : "black" }}>
                  <LockIcon />
                </IconButton>
              </div>
            </div>
            <div className={classes.container}>
              <div className={classes.searchWrapper}>
                <TextField
                  fullWidth
                  placeholder="Enter search value..."
                  onChange={(e) => setEnquirySearchValue(e.target.value)}
                  value={enquiryValue}
                  variant="filled"
                  inputProps={{ style: { padding: "10px 12px 10px" } }}
                  InputLabelProps={{ style: { transform: "translate(12px, 12px) scale(1)" } }}
                />
                <Select
                  id="input-select"
                  variant="filled"
                  value={enquirySelectValue}
                  style={{ width: "300px" }}
                  onChange={(event: any) => handleSelectorChange(event)}
                  SelectDisplayProps={{ style: { padding: "10px 12px 10px" } }}
                >
                  <MenuItem value={Selector.Barcode}>Barcode</MenuItem>
                  <MenuItem value={Selector.InvoiceNumber}>Invoice No</MenuItem>
                  <MenuItem value={Selector.IntakeWaybill}>Intake Waybill</MenuItem>
                </Select>
                <Button type="search" onClick={loadData} color="secondary" variant="contained" style={{ width: "150px" }}>
                  Search
                </Button>
              </div>
              <div style={{ height: "100%" }}>
                <AppBar position="static">
                  <Tabs value={tabValue} onChange={handleChangeTab} aria-label="grids">
                    <Tab label="EDI" {...a11yProps(0)} />
                    <Tab label="Barcode" {...a11yProps(1)} />
                    <Tab label="Barcode history" {...a11yProps(2)} />
                    <Tab label="Sale" {...a11yProps(3)} />
                    <Tab label="Producers Full" {...a11yProps(4)} />
                  </Tabs>
                </AppBar>
                {loading ? (
                  <div className={classes.loadingWrapper}>
                    <CircularProgress color="secondary" />
                    <p>Loading</p>
                  </div>
                ) : (
                  <>
                    <TabPanel value={tabValue} index={0} className={classes.tabPanel}>
                      <Grid
                        showFilterChips={false}
                        loading={loading}
                        data={ediHeaderInfoData}
                        GridColumns={EnquiryEdiHeaderGridColumns}
                        clearFilters={"enquiryediheadergrid"}
                        forceHeight={gridHeight}
                      />
                    </TabPanel>
                    <TabPanel value={tabValue} index={1} className={classes.tabPanel}>
                      <Grid
                        showFilterChips={false}
                        loading={loading}
                        data={barcodeData}
                        GridColumns={EnquiryBarcodeGridColumns}
                        clearFilters={"enquirybarcodegrid"}
                        forceHeight={gridHeight}
                      />
                    </TabPanel>
                    <TabPanel value={tabValue} index={2} className={classes.tabPanel}>
                      <Grid
                        showFilterChips={false}
                        loading={loading}
                        data={barcodeHistoryData}
                        GridColumns={EnquiryBarcodeHistoryGridColumns}
                        clearFilters={"enquirybarcodehistorygrid"}
                        forceHeight={gridHeight}
                      />
                    </TabPanel>
                    <TabPanel value={tabValue} index={3} className={classes.tabPanel}>
                      <div id="main_splitter_div_sale_grid" style={{ position: "relative", height: "100%", width: "100%", display: "grid" }}>
                        <Splitter
                          position="horizontal"
                          primaryPaneHeight="50%"
                          primaryPaneWidth="100%"
                          dispatchResize={true}
                          postPoned={true}
                          className={"sale_tab_grid"}
                          onDragFinished={(e) => {
                            setSaleGridHeights();
                          }}
                        >
                          <Grid
                            showFilterChips={false}
                            loading={loading}
                            data={saleData}
                            GridColumns={EnquirySaleGridColumns}
                            clearFilters={"enquirysalegrid"}
                            forceHeight={saleGridMainHeight}
                          />
                          <Grid
                            showFilterChips={false}
                            loading={loading}
                            data={saleBarcodesData}
                            GridColumns={EnquirySaleBarcodesGridColumns}
                            clearFilters={"enquirysalebarcodesgrid"}
                            forceHeight={saleGridSecondHeight}
                          />
                        </Splitter>
                      </div>
                    </TabPanel>
                    <TabPanel value={tabValue} index={4} className={classes.tabPanel}>
                      <Grid
                        showFilterChips={false}
                        loading={loading}
                        data={producersFullData}
                        GridColumns={EnquiryProducersFullGridColumns}
                        clearFilters={"enquiryproducersfullgrid"}
                        forceHeight={gridHeight}
                      />
                    </TabPanel>
                  </>
                )}
              </div>
            </div>
          </div>
        </Splitter>
      </div>
    </div>
  );
};

export default withStyles(styles)(EnquiryUnstyled);
