import React, { useEffect } from "react";

import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";

import arrayMove from "array-move";
import LZString from "lz-string";
import Resizer from "react-image-file-resizer";
import { SortableContainer, SortableElement } from "react-sortable-hoc";

import IconButton from "@material-ui/core/Button";
import LinearProgress from "@material-ui/core/LinearProgress";

import Confirmation from "../../lib/components/confirmation";

import IconRotate from "@material-ui/icons/Rotate90DegreesCcw";
import IconDelete from "@material-ui/icons/Delete";

import { Dropzone } from "../../lib/helpers/Dropzone";

import { arrivals } from "../../lib/api/arrival";

import ArrivalsdetailImageActions from "./arrivalsdetailImageActions";

const styles = (theme: Theme) =>
  createStyles({
    root: {},
    parentdiv: {
      width: "inherit",
      height: "inherit",
      position: "relative",
      display: "grid",
      gridTemplateAreas: "1fr 9fr",
    },
    btnSwitch: {
      width: "100%",
      height: "100%",
      position: "relative",
      backgroundColor: "#fff",
      textAlign: "center",
      paddingBottom: "1em",
    },
    clearAllBtn: {
      width: "inherit",
      height: "inherit",
      position: "relative",
      padding: "1.7vh",
    },
    floatingClear: {
      display: "flex",
      position: "absolute",
      width: "100%",
      height: "60px",
      background: "white",
      opacity: 1,
      borderRadius: "0 0 5px 0",
      textAlign: "center",
    },
    floatingProgress: {
      position: "absolute",
      width: "300px",
      height: "3px",
      textAlign: "center",
      fontSize: "20px",
      background: "white",
      opacity: 1,
      borderRadius: "0 0 5px 0",
    },
  });

type ImageListDropzoneProps = {
  arrival_id: Number;
  handleChange: (property, value) => void;
} & WithStyles<typeof styles>;

const ImageListDropzone: React.SFC<ImageListDropzoneProps> = (props) => {
  const { classes, arrival_id, handleChange } = props;

  const [images, setImages] = React.useState<any>([]);
  const [loading, setLoading] = React.useState(true);
  const [clearConfirmationToggled, setClearConfirmationToggled] = React.useState(false);
  const [actionsToggled, setActionsToggled] = React.useState(false);

  const [deleteConfirmationToggled, setDeleteConfirmationToggled] = React.useState(false);
  const [imageToDelete, setImageToDelete] = React.useState(undefined);

  useEffect(() => {
    // setImages(images);
    loadData();
  }, [arrival_id]);

  const loadData = () => {
    arrivals.single(arrival_id).then((result) => {
      if (result) {
        if (result.fotos) {
          const fotosArr = JSON.parse(result.fotos).map((foto) => {
            if (!foto.image) {
              return { image: foto, comment: "" };
            }
            return foto;
          });
          setImages(fotosArr);
          handleChange("fotosload", fotosArr);
        }
      }
      setLoading(false);
    });
  };

  const base64 = (file) => {
    return new Promise((resolve, reject) => {
      Resizer.imageFileResizer(
        file,
        800,
        800,
        "JPEG",
        60,
        0,
        (uri) => {
          resolve(LZString.compress(uri));
        },
        "base64",
      );
    });
  };

  const onDrop = React.useCallback(
    (acceptedFiles) => {
      setLoading(true);
      Promise.all(
        Array.from(acceptedFiles).map((file) => {
          return base64(file);
        }),
      ).then((newFiles) => {
        const newArr = [];
        newFiles.map((image: any) => {
          const imageObj = image.image ? image : { image: image, comment: "" };
          newArr.push(imageObj);
        });
        // const filesArr = [...images, ...newFiles];
        const fotosArr = [...images, ...newArr];
        setImages(fotosArr);
        handleChange("fotos", fotosArr);
        setLoading(false);
      });
    },
    [images],
  );

  const rotateBase64Image = async (base64data, idx) => {
    // create an off-screen canvas
    const offScreenCanvas = document.createElement("canvas");
    const offScreenCanvasCtx = offScreenCanvas.getContext("2d");

    // create Image
    var img = new Image();
    img.src = base64data;

    // set its dimension to rotated size
    offScreenCanvas.height = img.width;
    offScreenCanvas.width = img.height;

    // rotate and draw source image into the off-screen canvas:
    offScreenCanvasCtx.rotate((90 * Math.PI) / 180);
    offScreenCanvasCtx.translate(0, -offScreenCanvas.width);

    offScreenCanvasCtx.drawImage(img, 0, 0);
    const convertedB64String = LZString.compress(offScreenCanvas.toDataURL("image/jpeg", 100));

    const imagesArr = [...images];

    imagesArr[idx].image = convertedB64String;

    setImages(imagesArr);
    handleChange("fotos", imagesArr);
  };

  const confirmDelete = (index) => {
    setImageToDelete(index);
    // setImageToDelete(LZString.compress(image));
    setDeleteConfirmationToggled(true);
  };

  const deleteCancelled = () => {
    setImageToDelete(undefined);
    setDeleteConfirmationToggled(false);
  };

  const deleteConfirmed = () => {
    const imagesArr = [...images];

    imagesArr.splice(imageToDelete, 1);
    const imagesArrNew = [...imagesArr];
    handleChange("fotos", imagesArrNew);
    setImages(imagesArrNew);

    setImageToDelete(undefined);
    setDeleteConfirmationToggled(false);
    // setActionsToggled(false);
  };

  const executeActionToggle = () => {
    setActionsToggled(!actionsToggled);
  };

  const confirmClearAll = () => {
    setClearConfirmationToggled(true);
  };

  const deleteAllConfirmed = () => {
    setImages([]);
    handleChange("fotos", []);
    setClearConfirmationToggled(false);
    setActionsToggled(false);
  };

  const deleteAllCancelled = () => {
    setClearConfirmationToggled(false);
  };

  const changeComment = (comment, index) => {
    const imagesArr = [...images];
    imagesArr[index].comment = comment;

    handleChange("fotos", imagesArr);
    setImages(imagesArr);
  };

  const onSortEnd = ({ oldIndex, newIndex }) => {
    const imagesArrMoved = arrayMove(images, oldIndex, newIndex);
    setImages(imagesArrMoved);
    handleChange("fotos", imagesArrMoved);
  };

  return (
    <div className={classes.parentdiv}>
      {loading && <LinearProgress className={classes.floatingProgress} />}
      <div className={classes.btnSwitch}>
        {actionsToggled ? (
          <IconButton
            title="Clear All"
            className={classes.clearAllBtn}
            style={{ height: "63px" }}
            onClick={() => {
              confirmClearAll();
            }}
          >
            CLEAR ALL
            <IconDelete style={{ color: "red" }} />
          </IconButton>
        ) : (
          <Dropzone multiple onDrop={onDrop} file={images} />
        )}
      </div>
      <ImgContainer
        images={images}
        actionsToggled={actionsToggled}
        rotateBase64Image={rotateBase64Image}
        confirmDelete={confirmDelete}
        executeActionToggle={executeActionToggle}
        distance={1}
        onSortEnd={onSortEnd}
        changeComment={changeComment}
      />
      {deleteConfirmationToggled && (
        <div>
          <Confirmation
            isOpen={deleteConfirmationToggled}
            handleClose={deleteCancelled}
            handleConfirm={deleteConfirmed}
            title={`Delete confirmation.`}
            body={`Are you certain you want to remove this image?`}
            fullscreen={false}
          />
        </div>
      )}
      {clearConfirmationToggled && (
        <div>
          <Confirmation
            isOpen={clearConfirmationToggled}
            handleClose={deleteAllCancelled}
            handleConfirm={deleteAllConfirmed}
            title={`Delete confirmation.`}
            body={`Are you certain you want to clear all?`}
            fullscreen={false}
          />
        </div>
      )}
    </div>
  );
};

export default withStyles(styles)(ImageListDropzone);

type ImgContainerProps = {
  images: [];
  actionsToggled: any;
  rotateBase64Image: any;
  confirmDelete: any;
  executeActionToggle: any;
  changeComment: (comment, index) => void;
};
const ImgContainer = SortableContainer<ImgContainerProps>((props) => {
  const { images, actionsToggled, rotateBase64Image, confirmDelete, executeActionToggle, changeComment } = props;

  return (
    <div style={{ height: "1034px", marginLeft: "16px", width: "inherit", overflowY: "scroll" }}>
      {images.map((image: any, idx: number) => {
        return (
          <SortableImageContainer
            key={`${image.image}${idx}`}
            index={idx}
            idx={idx}
            image={image}
            actionsToggled={actionsToggled}
            rotateBase64Image={rotateBase64Image}
            confirmDelete={confirmDelete}
            executeActionToggle={executeActionToggle}
            changeComment={changeComment}
          />
        );
      })}
    </div>
  );
});

type SortableImageContainerProps = {
  image: any;
  idx: any;
  actionsToggled: any;
  rotateBase64Image: any;
  confirmDelete: any;
  executeActionToggle: any;
  changeComment: any;
};

const SortableImageContainer = SortableElement<SortableImageContainerProps>((props) => {
  const { image, idx, actionsToggled, rotateBase64Image, confirmDelete, executeActionToggle, changeComment } = props;
  return (
    <div
      style={{
        zIndex: 99999999,
      }}
    >
      {actionsToggled && (
        <div
          style={{
            width: "120px",
            height: "36px",
            marginBottom: "-36px",
            background: "white",
            opacity: 0.8,
            borderRadius: "0 0 5px 0",
          }}
        >
          <ArrivalsdetailImageActions image={image} index={idx} rotateBase64Image={rotateBase64Image} confirmDelete={confirmDelete} changeComment={changeComment} />
        </div>
      )}
      {!actionsToggled && image.comment && image.comment.length > 0 && (
        <div
          style={{
            width: "250px",
            height: "30px",
            marginBottom: "-30px",
            background: "white",
            opacity: 0.5,
            borderRadius: "0 0 5px 0",
          }}
        >
          <span>{image.comment}</span>
        </div>
      )}
      <img
        style={{
          width: "auto",
          height: "auto",
          maxHeight: "250px",
          maxWidth: "100%",
          cursor: "pointer",
          marginBottom: "5px",
          float: "left",
          marginLeft: "10px",
        }}
        onClick={executeActionToggle}
        key={image.image ? LZString.decompress(image.image) : LZString.decompress(image)}
        src={image.image ? LZString.decompress(image.image) : LZString.decompress(image)}
      />
    </div>
  );
});
