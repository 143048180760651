import { client, apiHeaders, request } from "./_base";

const endpoint = "producerorchard";

export type ProducerOrchardType = {
  id?: number;
  puc_id: number;
  code: string;
  created_at?: Date;
  updated_at?: Date;
  variety_id: number;
  ha: number;
};

export const orchards = client(endpoint, (item) => ({
  ...item,
}));

export const orchardByPucId = (pucid: number) => {
  return orchards.all().then((result: [ProducerOrchardType]) => {
    return result.filter((item: ProducerOrchardType) => item.puc_id == pucid);
  });
};

export const getOrchardsByPucReadyForCombo = async (pucid: number) => {
  return await orchardByPucId(pucid).then((result: [ProducerOrchardType]) => {
    const ready = result
      .sort((a, b) => {
        if (a.code > b.code) return 1;
        if (a.code < b.code) return -1;
        return 1;
      })
      .map((orchard) => {
        return { value: orchard.id, display: orchard.code, data: orchard };
      });
    return ready;
  });
};

export const getFullOrchardsByPucId = (pucid: number) =>
  request(`${endpoint}/ext/full/${pucid}`, {
    headers: apiHeaders(),
  })
    .then(async (result) => {
      if (result.status == 500) {
        const resp = await result.json();
        throw resp;
      }
      return result.json();
    })
    .then(({ data }) => data);
