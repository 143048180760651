import React from "react";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";

import { Form, Field } from "react-final-form";
import { TextField, Select } from "final-form-material-ui";

import Check from "@material-ui/icons/Check";
import Close from "@material-ui/icons/Close";

import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";

import { terms } from "../../lib/api/terms";
import { getTermsValueTypeReadyForCombo } from "../../lib/api/termsvaluetype";
import { getTermsDateTypeReadyForCombo } from "../../lib/api/termsdatetype";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1),
      paddingTop: theme.spacing(2),
    },
    tableRowFieldTitle: {
      paddingTop: theme.spacing(1),
      paddingRight: theme.spacing(1) * 2,
      marginTop: theme.spacing(1),
      textAlign: "right",
      minWidth: "175px",
    },
    tableRowFieldData: {
      paddingTop: theme.spacing(0.5),
      textAlign: "left",
      width: "400px",
    },
    tableRowFieldDataCombo: {
      textAlign: "left",
      width: "400px",
    },
    tableFieldHeaderRow: {
      backgroundColor: "#303f9f",
      textTransform: "uppercase",
      color: "#fff",
    },
    tableFieldHeaderText: {
      padding: "5px",
    },
    tableFieldEmptyRowText: {
      padding: "10px",
    },
  });

type TermsEditFormProps = {
  onSubmit?: any;
  onClose?: any;
  viewOnly?: boolean;
  id: any;
} & WithStyles<typeof styles>;

class TermsEditFormUnstyled extends React.Component<TermsEditFormProps, any> {
  state = {
    classes: undefined,
    item: {
      id: "0",
      code: "",
      description: "",
      details: "",
      valuetype1: "",
      value1: "",
      datetype1: "",
      days1: "0",
      valuetype2: "",
      value2: "",
      datetype2: "",
      days2: "0",
      valuetype3: "",
      value3: "",
      datetype3: "",
      days3: "0",
    },
    onSubmit: undefined,
    onClose: undefined,
    id: 0,
    termsValueTypes: [],
    termsDateTypes: [],
  };

  constructor(props) {
    super(props);
    this.state.classes = props.classes;
    this.state.onSubmit = props.onSubmit;
    this.state.onClose = props.onClose;
    this.state.id = props.id;
  }

  componentWillMount() {
    if (this.state.id != 0) {
      this.getItem(this.state.id);
    }
    this.setState({ termsValueTypes: getTermsValueTypeReadyForCombo(), termsDateTypes: getTermsDateTypeReadyForCombo() }, () => {});
  }

  getItem = (id) => {
    terms.single(id).then((data) => {
      this.setState({ item: data });
    });
  };

  handleFocus = (event) => {
    event.target.select();
  };

  render() {
    const { classes } = this.state;

    return (
      <div className={classes.root}>
        <Form
          initialValues={{ ...this.state.item }}
          onSubmit={(values) => this.state.onSubmit(values)}
          validate={(values) => {
            let errors = {};
            return errors;
          }}
          render={({ handleSubmit, form }) => (
            <form id={this.state.item.id} onSubmit={handleSubmit}>
              <table style={{ marginLeft: "15px" }}>
                <tbody>
                  <TableFieldText classes={classes} field="code" title="Code" disabled={this.props.viewOnly} multiline={false} required={false} isnumber={false} />
                  <TableFieldText classes={classes} field="description" title="Description" disabled={this.props.viewOnly} multiline={false} required={false} isnumber={false} />
                  <TableFieldText classes={classes} field="details" title="Details" disabled={this.props.viewOnly} multiline={true} required={false} isnumber={false} />

                  <EmptyRow classes={classes} />
                  <TableFieldHeader classes={classes} title={"Payment Term 1 (Advance 1)"} />
                  <EmptyRow classes={classes} />

                  <TableFieldText classes={classes} field="value1" title="Value 1" disabled={this.props.viewOnly} multiline={false} required={false} isnumber={true} />
                  <TableFieldCombo classes={classes} field="valuetype1" title="Value Type 1" data={this.state.termsValueTypes} disabled={this.props.viewOnly} />
                  <TableFieldText classes={classes} field="days1" title="Days 1" disabled={this.props.viewOnly} multiline={false} required={false} isnumber={true} />
                  <TableFieldCombo classes={classes} field="datetype1" title="Date Type 1" data={this.state.termsDateTypes} disabled={this.props.viewOnly} />

                  <EmptyRow classes={classes} />
                  <TableFieldHeader classes={classes} title={"Payment Term 2 (Advance 2 / Final 1)"} />
                  <EmptyRow classes={classes} />

                  <TableFieldText classes={classes} field="value2" title="Value 2" disabled={this.props.viewOnly} multiline={false} required={false} isnumber={true} />
                  <TableFieldCombo classes={classes} field="valuetype2" title="Value Type 2" data={this.state.termsValueTypes} disabled={this.props.viewOnly} />
                  <TableFieldText classes={classes} field="days2" title="Days 2" disabled={this.props.viewOnly} multiline={false} required={false} isnumber={true} />
                  <TableFieldCombo classes={classes} field="datetype2" title="Date Type 2" data={this.state.termsDateTypes} disabled={this.props.viewOnly} />

                  <EmptyRow classes={classes} />
                  <TableFieldHeader classes={classes} title={"Payment Term 3 (Final 2)"} />
                  <EmptyRow classes={classes} />

                  <TableFieldText classes={classes} field="value3" title="Value 3" disabled={this.props.viewOnly} multiline={false} required={false} isnumber={true} />
                  <TableFieldCombo classes={classes} field="valuetype3" title="Value Type 3" data={this.state.termsValueTypes} disabled={this.props.viewOnly} />
                  <TableFieldText classes={classes} field="days3" title="Days 3" disabled={this.props.viewOnly} multiline={false} required={false} isnumber={true} />
                  <TableFieldCombo classes={classes} field="datetype3" title="Date Type 3" data={this.state.termsDateTypes} disabled={this.props.viewOnly} />
                </tbody>
              </table>
              {!this.props.viewOnly && (
                <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "22px", marginRight: "15px" }}>
                  <Button style={{ marginRight: "10px" }} color="secondary" variant="outlined" onClick={this.state.onClose}>
                    <Close />
                  </Button>
                  <Button type="submit" color="primary" variant="outlined">
                    <Check />
                  </Button>
                </div>
              )}
            </form>
          )}
        />
      </div>
    );
  }
}

export default withStyles(styles)(TermsEditFormUnstyled);

export const TableFieldText: React.FunctionComponent<
  { field: string; title: string; disabled: boolean; multiline: boolean; required: boolean; isnumber: boolean } & WithStyles<typeof styles>
> = (props) => {
  const { classes, title, field, disabled, multiline, required, isnumber } = props;

  return (
    <tr>
      <td className={classes.tableRowFieldTitle} style={{ verticalAlign: "top", paddingTop: "13px" }}>
        <span>{`${title}: `}</span>
      </td>
      <td className={classes.tableRowFieldData}>
        <Field
          name={field}
          key={`body_value_${field}`}
          component={TextField}
          type={isnumber ? "number" : "text"}
          className={classes.tableRowFieldData}
          multiline={multiline}
          rows={multiline ? "4" : "1"}
          required={required}
          disabled={disabled}
        />
      </td>
    </tr>
  );
};

export const TableFieldCombo: React.FunctionComponent<{ classes: any; title: string; field: string; data: any; disabled?: boolean } & WithStyles<typeof styles>> = (props) => {
  const { classes, title, field, data, disabled } = props;
  return (
    <tr>
      <td className={classes.tableRowFieldTitle}>{`${title}:`}</td>
      <td>
        <Field name={field} component={Select as any} formControlProps={{ className: classes.tableRowFieldDataCombo }} disabled={disabled}>
          {(data || []).map((item, index) => (
            <MenuItem key={`${item.value}${index}`} value={item.value}>
              {item.display}
            </MenuItem>
          ))}
        </Field>
      </td>
    </tr>
  );
};

export const TableFieldHeader: React.FC<{ classes: any; title: string }> = ({ classes, title }) => (
  <tr className={classes.tableFieldHeaderRow}>
    <td className={classes.tableFieldHeaderText} colSpan={2}>
      {title}
    </td>
  </tr>
);

export const EmptyRow: React.FC<{ classes: any }> = ({ classes }) => (
  <tr>
    <td colSpan={2} className={classes.tableFieldEmptyRowText}>
      {" "}
    </td>
  </tr>
);
