import React from "react";
import CustomAutoCompleteFilter from "../../../lib/components/customdatagridfilter/CustomFilter";
import { FormatterNumber, FormatterNumber2Decimals } from "../../../lib/components/Formatters";
import { SummaryNumber, SummaryNumber2Decimal, SummaryTotal } from "../../../lib/components/SummaryFormatter";
import TextEditor from "../../../lib/components/TextEditor";

const cellWidthSmallX = 90;
const cellWidthSmall = 115;
const cellWidthMedium = 150;
const cellWidthLarge = 220;

const defaultColumnProperties = {
  filterable: true,
  resizable: true,
  sortable: true,
};

let currentSpace = true;

// const FormatCell = ({ row, column }) => {
//   return <div style={{ color: "#555" }}>{row[column.key]}</div>;
// };
export const GridColumnsAdjustment = (data, filters, arrangement, columnsWidth, editable) => {
  let postValue = "";

  if (data) {
    if (currentSpace) {
      postValue = " ";
    }
    currentSpace = !currentSpace;
  }

  const columns = [
    {
      key: "producer_code",
      name: "Producer Code" + postValue,
      width: cellWidthSmall,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"producer_code"} data={data} section="adjustmentgrid" />;
      },
    },
    {
      key: "producer_pucs",
      name: "PUC" + postValue,
      width: cellWidthSmall,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"producer_pucs"} data={data} section="adjustmentgrid" />;
      },
    },
    {
      key: "saledetail_barcode",
      name: "Barcode" + postValue,
      width: cellWidthLarge,
      filterRenderer: (e) => {
        return (
          <CustomAutoCompleteFilter {...e} multiSelection={true} option={data} currentFilters={filters} fieldname={"saledetail_barcode"} data={data} section="adjustmentgrid" />
        );
      },
      summaryFormatter: SummaryTotal,
    },

    {
      key: "commodityCode",
      name: "Commodity" + postValue,
      width: cellWidthSmallX,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"commodityCode"} data={data} section="adjustmentgrid" />;
      },
    },
    {
      key: "varietyCode",
      name: "Variety" + postValue,
      width: cellWidthSmallX,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"varietyCode"} data={data} section="adjustmentgrid" />;
      },
    },
    {
      key: "packCode",
      name: "Pack" + postValue,
      width: cellWidthSmallX,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"packCode"} data={data} section="adjustmentgrid" />;
      },
    },
    {
      key: "markCode",
      name: "Mark" + postValue,
      width: cellWidthSmallX,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"markCode"} data={data} section="adjustmentgrid" />;
      },
    },
    {
      key: "gradeCode",
      name: "Grade" + postValue,
      width: cellWidthSmallX,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"gradeCode"} data={data} section="adjustmentgrid" />;
      },
    },
    {
      key: "countCode",
      name: "Count" + postValue,
      width: cellWidthSmallX,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"countCode"} data={data} section="adjustmentgrid" />;
      },
    },
    {
      key: "barcode_palletsize",
      name: "Pallet Size" + postValue,
      width: cellWidthSmallX,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"barcode_palletsize"} data={data} section="adjustmentgrid" />;
      },
      formatter: FormatterNumber2Decimals,
      summaryFormatter: SummaryNumber2Decimal,
    },
    {
      key: "barcode_cartons",
      name: "No Cartons" + postValue,
      width: cellWidthSmallX,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"barcode_cartons"} data={data} section="adjustmentgrid" />;
      },
      formatter: FormatterNumber,
      summaryFormatter: SummaryNumber,
    },
    {
      key: "saleadjustmentdetail_cartons",
      name: "Cartons" + postValue,
      width: cellWidthSmall,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"saleadjustmentdetail_cartons"} data={data} section="adjustmentgrid" />;
      },
      formatter: FormatterNumber,
      editable: editable,
      editor: (props) => <TextEditor {...props} align="right" />,
      summaryFormatter: SummaryNumber,
    },
    {
      key: "saleadjustmentdetail_amount",
      name: "Amount/ctn" + postValue,
      width: cellWidthSmall,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"saleadjustmentdetail_amount"} data={data} section="adjustmentgrid" />;
      },
      formatter: FormatterNumber2Decimals,
      editable: editable,
      editor: (props) => <TextEditor {...props} align="right" />,
    },
    {
      key: "total",
      name: "Total" + postValue,
      width: cellWidthSmall,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"saleadjustmentdetail_amount"} data={data} section="adjustmentgrid" />;
      },
      formatter: FormatterNumber2Decimals,
      summaryFormatter: SummaryNumber2Decimal,
    },
  ].map((c) => ({
    ...c,
    ...defaultColumnProperties,
  }));

  const columnsArranged = [];

  if (arrangement && arrangement.length > 0) {
    arrangement.map((item) => {
      const indexOfItem = columns.indexOf(columns.find((col) => col.name.trim() == item.toString().trim()));
      if (indexOfItem != -1) {
        columnsArranged.push(columns[indexOfItem]);
      }
    });
  } else {
    columns.map((colItem) => {
      columnsArranged.push(colItem);
    });
  }

  if (columnsWidth && columnsWidth.length > 0) {
    for (let index = 0; index < columnsArranged.length; index++) {
      const element = columnsArranged[index];
      const colItem = columnsWidth.find((colwitem) => colwitem.name.trim() == element.name.trim());
      if (colItem) {
        element.width = colItem.width;
      }
    }
  }

  return columnsArranged;
};
