import React from "react";

import CustomAutoCompleteFilter from "../../lib/components/customdatagridfilter/CustomFilter";
import { DateFormatter, FormatterNumber2Decimals, TextFormatter } from "../../lib/components/Formatters";
import RightAlignHeaderColumn from "../../lib/components/gridhelper/RightAlignHeaderColumn";
import { SummaryNumber, SummaryNumber2Decimal } from "../../lib/components/SummaryFormatter";
import { dynamicWidthsSummary } from "../../lib/helpers/DynamicWidth";
import { handleColumnsArrangement, handleColumnsWidth } from "../../lib/helpers/grid";

import ListAlt from "@material-ui/icons/ListAlt";
import { DocumentsType } from "../../lib/components/DocumentsButton";
import { CellActionsFormatter } from "../../lib/helpers/CellActionsFormatter";
import { SaleFundsSummaryType } from "../../lib/api/salefunds";

const cellWidthTiny = 50;
const cellWidthSmallX = 90;
const cellWidthSmall = 115;
const cellWidthMedium = 150;
const cellWidthLarge = 220;

const defaultColumnProperties = {
  filterable: true,
  resizable: true,
  sortable: true,
};

let currentSpace = true;
let calculatedWidths = {};

export const GridColumnsSummary = (data, filters, arrangement = undefined, columnsWidth = undefined, totalRowColumns, handlePrintPDF, handlePrintPurchaseAndHistory) => {
  let postValue = "";

  if (data) {
    if (currentSpace) {
      postValue = " ";
    }
    currentSpace = !currentSpace;
    calculatedWidths = dynamicWidthsSummary(data, totalRowColumns);
  }

  const columns = [
    {
      key: "actions",
      name: "#A",
      width: cellWidthTiny,
      formatter: ({ row }) => {
        const actions = cellActions(row, handlePrintPDF, handlePrintPurchaseAndHistory);
        return (
          <div style={{ display: "flex", justifyContent: "center", gap: "5px" }}>
            <CellActionsFormatter actions={actions} />
          </div>
        );
      },
    },
    {
      key: "clients_code",
      name: "Code" + postValue,
      width: cellWidthMedium,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} option={data} currentFilters={filters} fieldname={"clients_code"} data={data} section="debtorssummary" />;
      },
      summaryFormatter: SummaryNumber,
    },
    {
      key: "clients_name",
      name: "Name" + postValue,
      width: cellWidthMedium,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} option={data} currentFilters={filters} fieldname={"clients_name"} data={data} section="debtorssummary" />;
      },
    },
    {
      key: "currency_code",
      name: "Currency" + postValue,
      width: cellWidthTiny,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} option={data} currentFilters={filters} fieldname={"currency_code"} data={data} section="debtorssummary" />;
      },
      formatter: ({ row, column, style = { textAlign: "center" } }) => TextFormatter({ row, column, style }),
    },
    {
      key: "salesTotal",
      name: "Sales" + postValue,
      width: calculatedWidths["salesTotal"] || cellWidthSmall,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} option={data} currentFilters={filters} fieldname={"salesTotal"} data={data} section="debtorssummary" />;
      },
      formatter: FormatterNumber2Decimals,
      summaryFormatter: SummaryNumber2Decimal,
      headerRenderer: RightAlignHeaderColumn,
    },
    {
      key: "amountPaidTotal",
      name: "Received" + postValue,
      width: calculatedWidths["amountPaidTotal"] || cellWidthSmallX,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} option={data} currentFilters={filters} fieldname={"amountPaidTotal"} data={data} section="debtorssummary" />;
      },
      formatter: FormatterNumber2Decimals,
      summaryFormatter: SummaryNumber2Decimal,
      headerRenderer: RightAlignHeaderColumn,
    },
    {
      key: "totalOutstanding",
      name: "Outstanding" + postValue,
      width: calculatedWidths["totalOutstanding"] || cellWidthSmallX,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} option={data} currentFilters={filters} fieldname={"totalOutstanding"} data={data} section="debtorssummary" />;
      },
      formatter: FormatterNumber2Decimals,
      summaryFormatter: SummaryNumber2Decimal,
      headerRenderer: RightAlignHeaderColumn,
    },
    {
      key: "bankchargesTotal",
      name: "Foreign Charges" + postValue,
      width: calculatedWidths["bankchargesTotal"] || cellWidthSmallX,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} option={data} currentFilters={filters} fieldname={"bankchargesTotal"} data={data} section="debtorssummary" />;
      },
      formatter: FormatterNumber2Decimals,
      summaryFormatter: SummaryNumber2Decimal,
      headerRenderer: RightAlignHeaderColumn,
    },
    {
      key: "totalAmount",
      name: "Total Received" + postValue,
      width: calculatedWidths["totalAmount"] || cellWidthSmallX,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} option={data} currentFilters={filters} fieldname={"totalAmount"} data={data} section="debtorssummary" />;
      },
      formatter: FormatterNumber2Decimals,
      summaryFormatter: SummaryNumber2Decimal,
      headerRenderer: RightAlignHeaderColumn,
    },
    {
      key: "availableTotal",
      name: "Available For Allocation" + postValue,
      width: calculatedWidths["availableTotal"] || cellWidthSmallX,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} option={data} currentFilters={filters} fieldname={"availableTotal"} data={data} section="debtorssummary" />;
      },
      formatter: FormatterNumber2Decimals,
      summaryFormatter: SummaryNumber2Decimal,
      headerRenderer: RightAlignHeaderColumn,
    },
    {
      key: "current_period",
      name: "Current" + postValue,
      width: calculatedWidths["current_period"] || cellWidthSmallX,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} option={data} currentFilters={filters} fieldname={"current_period"} data={data} section="debtorssummary" />;
      },
      formatter: FormatterNumber2Decimals,
      summaryFormatter: SummaryNumber2Decimal,
      headerRenderer: RightAlignHeaderColumn,
    },
    {
      key: "thirty_days",
      name: "1-30 days" + postValue,
      width: calculatedWidths["thirty_days"] || cellWidthSmallX,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} option={data} currentFilters={filters} fieldname={"thirty_days"} data={data} section="debtorssummary" />;
      },
      formatter: FormatterNumber2Decimals,
      summaryFormatter: SummaryNumber2Decimal,
      headerRenderer: RightAlignHeaderColumn,
    },
    {
      key: "sixty_days",
      name: "31-60 days" + postValue,
      width: calculatedWidths["sixty_days"] || cellWidthSmallX,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} option={data} currentFilters={filters} fieldname={"sixty_days"} data={data} section="debtorssummary" />;
      },
      formatter: FormatterNumber2Decimals,
      summaryFormatter: SummaryNumber2Decimal,
      headerRenderer: RightAlignHeaderColumn,
    },
    {
      key: "ninety_days",
      name: "61-90 days" + postValue,
      width: calculatedWidths["ninety_days"] || cellWidthSmallX,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} option={data} currentFilters={filters} fieldname={"ninety_days"} data={data} section="debtorssummary" />;
      },
      formatter: FormatterNumber2Decimals,
      summaryFormatter: SummaryNumber2Decimal,
      headerRenderer: RightAlignHeaderColumn,
    },
    {
      key: "ninetyplus_days",
      name: "91+ days" + postValue,
      width: calculatedWidths["ninetyplus_days"] || cellWidthSmallX,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} option={data} currentFilters={filters} fieldname={"ninetyplus_days"} data={data} section="debtorssummary" />;
      },
      formatter: FormatterNumber2Decimals,
      summaryFormatter: SummaryNumber2Decimal,
      headerRenderer: RightAlignHeaderColumn,
    },
    {
      key: "amountOverpaid",
      name: "Overpaid" + postValue,
      width: calculatedWidths["amountOverpaid"] || cellWidthSmallX,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} option={data} currentFilters={filters} fieldname={"amountOverpaid"} data={data} section="debtorssummary" />;
      },
      formatter: FormatterNumber2Decimals,
      summaryFormatter: SummaryNumber2Decimal,
      headerRenderer: RightAlignHeaderColumn,
    },
    {
      key: "creditLimit",
      name: "Credit Limit" + postValue,
      width: cellWidthSmallX,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} option={data} currentFilters={filters} fieldname={"creditLimit"} data={data} section="debtorssummary" />;
      },
      formatter: FormatterNumber2Decimals,
      headerRenderer: RightAlignHeaderColumn,
    },
    {
      key: "creditLimit_exp",
      name: "Credit Limit Expiry" + postValue,
      width: cellWidthMedium,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} option={data} currentFilters={filters} fieldname={"creditLimit_exp"} data={data} section="debtorssummary" />;
      },
      formatter: ({ row, column, style = { textAlign: "center" }, includeTime = false }) => DateFormatter({ row, column, style, includeTime }),
      headerRenderer: RightAlignHeaderColumn,
    },
  ].map((c) => ({ ...c, ...defaultColumnProperties }));

  let columnsArranged = [];
  columnsArranged = handleColumnsArrangement(columnsArranged, arrangement, columns);
  columnsArranged = handleColumnsWidth(columnsArranged, columnsWidth, columns);

  return columnsArranged;
};

const cellActions = (row: SaleFundsSummaryType, handlePrintPDF: (row: SaleFundsSummaryType) => void, handlePrintPurchaseAndHistory: (clients_code: string) => void) => {
  return [
    {
      icon: (
        <span title="Documents">
          <ListAlt style={{ cursor: "pointer", color: "#4a9de2", width: "35px" }} />
        </span>
      ),
      actions: [
        {
          text: "STATEMENT",
          icon: [DocumentsType.PDF, DocumentsType.EXCEL],
          callback: () => handlePrintPDF(row),
        },
        {
          text: "PURCHASE AND PAYMENT HISTORY",
          icon: DocumentsType.EXCEL,
          callback: () => handlePrintPurchaseAndHistory(row.clients_code),
        },
      ],
    },
  ];
};
