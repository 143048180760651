import React from "react";
import { Theme, createStyles, WithStyles, withStyles } from "@material-ui/core/styles";
import { useDropzone } from "react-dropzone";
import Typography from "@material-ui/core/Typography";
import CloudDownload from "@material-ui/icons/CloudDownload";
import Folder from "@material-ui/icons/Folder";

const dropzoneStyles = (theme: Theme) =>
  createStyles({
    root: {
      position: "relative",
      padding: "5px",
      border: "dashed 3px #888",
      borderRadius: 5,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      cursor: "pointer",
    },
    icon: {
      padding: theme.spacing(1),
    },
  });

export type DropzoneProps = {
  file: any;
  onDrop: any;
  multiple?: boolean;
} & React.HTMLAttributes<HTMLDivElement> &
  WithStyles<typeof dropzoneStyles>;

const DropzoneUnstlyed: React.SFC<DropzoneProps> = ({ classes, children, className, file, onDrop, multiple }) => {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <div className={classes.root} {...getRootProps()}>
      <input multiple={multiple} {...getInputProps()} />
      {file ? <Folder /> : <CloudDownload />}
      {file && (isDragActive ? <Typography>Drop the files here ...</Typography> : <Typography>Drag 'n' drop a file here, or click to select file</Typography>)}
    </div>
  );
};

export const Dropzone = withStyles(dropzoneStyles)(DropzoneUnstlyed);
