import React from "react";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";
import Grid from "../../lib/components/grid";
import Confirmation from "../../lib/components/confirmation";
import { GridColumns } from "./clientbilltypesgridsetup";
import Tooltip from "@material-ui/core/Tooltip";
import Button from "@material-ui/core/Button";
import IconAdd from "@material-ui/icons/Add";
import ClientBillTypesForm from "./clientbilltypesform";
import { clientbilltypes } from "../../lib/api/clientbilltypes";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1),
      width: "100%",
      height: "100%",
      position: "relative",
    },
    inline: {
      display: "inline",
      position: "absolute",
      marginTop: "7px",
      marginLeft: "7px",
    },
  });

type AgentProps = {} & WithStyles<typeof styles>;

export class AgentUnstyled extends React.Component<AgentProps, any> {
  state = {
    classes: undefined,
    data: [],
    confirmRemoveID: undefined,
    confirmEditID: undefined,
    loading: true,
  };

  constructor(props) {
    super(props);
    this.state.classes = props.classes;
  }

  componentDidMount() {
    this.loadData();
  }

  loadData = () => {
    clientbilltypes.all().then((result) => {
      this.setState({ loading: false, data: result });
    });
  };

  handleCreate = () => {
    this.setState({ confirmEditID: "0" });
  };

  handleEdit = (id) => {
    this.setState({ confirmEditID: id });
  };

  handleEditClose = () => {
    this.setState({ confirmEditID: undefined });
  };

  handleEditConfirm = (item) => {
    const id = item["id"];
    delete item["id"];

    if (id == "0") {
      clientbilltypes.create({ data: { ...item } }).then(() => this.loadData());
    } else {
      clientbilltypes.update(id, { data: { ...item } }).then(() => this.loadData());
    }
    this.handleEditClose();
  };

  handleRemove = async (id) => {
    this.setState({ confirmRemoveID: id });
  };

  handleRemoveClose = () => {
    this.setState({ confirmRemoveID: undefined });
  };

  handleRemoveConfirm = async () => {
    await clientbilltypes.remove(this.state.confirmRemoveID).then(() => {
      this.handleRemoveClose();
      this.loadData();
    });
  };

  GridColumnsOverride = (column, row, arrangement, columnsWidth) => {
    return GridColumns(column, row, arrangement, columnsWidth, this.handleRemove, this.handleEdit);
  };

  render() {
    const { classes } = this.state;
    return (
      <div className={classes.root}>
        <div className={classes.inline}>
          <Tooltip style={{ zIndex: 0 }} title="Create new Bill Type">
            <div className={classes.inline}>
              <Button variant="contained" color="primary" onClick={this.handleCreate} style={{ marginBottom: "10px" }}>
                <IconAdd />
              </Button>
            </div>
          </Tooltip>
        </div>
        <Grid loading={this.state.loading} data={this.state.data} GridColumns={this.GridColumnsOverride} clearFilters={"agentgridsetup"} />
        {this.state.confirmRemoveID && (
          <Confirmation
            isOpen={this.state.confirmRemoveID ? true : false}
            handleClose={this.handleRemoveClose}
            handleConfirm={this.handleRemoveConfirm}
            title="Remove selected Bill Type?"
            body="Are you sure you want to REMOVE the selected Bill Type Record?"
          ></Confirmation>
        )}
        {this.state.confirmEditID && (
          <Confirmation
            isOpen={this.state.confirmEditID ? true : false}
            handleClose={this.handleEditClose}
            handleConfirm={() => {}}
            title={this.state.confirmEditID == "0" ? "Bill Type" : "Bill Type"}
            body={undefined}
          >
            <ClientBillTypesForm onSubmit={this.handleEditConfirm} onClose={this.handleEditClose} id={this.state.confirmEditID} />
          </Confirmation>
        )}
      </div>
    );
  }
}

export default withStyles(styles)(AgentUnstyled);
