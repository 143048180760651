import React from "react";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";

import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";

import { GridColumnsDispatches1, getCellActionsDispatch1 } from "./dispatchmergegridsetup1";
import { GridColumnsDispatches2, getCellActionsDispatch2 } from "./dispatchmergegridsetup2";

import { SnackContext } from "../../lib/context/SnackContext";
import Confirmation from "../../lib/components/confirmation";
import Grid from "../../lib/components/grid";
import { StatusTypes } from "../../lib/types";

import { dispatcheddocsFull } from "../../lib/api/dispatcheddocs";
import { getFinancialYearSelected } from "../../lib/api/week";
import { dispatchMergeInto } from "../../lib/api/dispatch";
import { Row } from "react-data-grid";

import { GenerateErrorMessage } from "../../lib/helpers/string_methods";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: "grid",
      width: "calc(100vw-310px)",
      height: "calc(100vh - 100px)",
    },
    container: {
      display: "grid",
      gridTemplateColumns: "auto auto",
      gridTemplateRows: `
        50px
        auto
      `,
    },
    boldRow: {
      fontWeight: "bold",
    },
  });

type DispatchMergeProps = {} & WithStyles<typeof styles>;

export class DispatchMergeUnstyled extends React.Component<DispatchMergeProps, any> {
  state = {
    loading: false,
    confirmMerge: undefined,
    data: [],
    selectRow1: undefined,
    selectRow2: undefined,
  };

  constructor(props) {
    super(props);
  }

  static contextType = SnackContext;
  context!: React.ContextType<typeof SnackContext>;

  componentDidMount = () => {
    this.loadData();
  };

  loadData = async () => {
    this.setState({ loading: true });
    const result = await dispatcheddocsFull(getFinancialYearSelected()).catch((error) => {
      const err = GenerateErrorMessage(error, "failed to retrieve data");
      this.context.updateSnack({ show: true, color: "red", message: err });
    });
    this.setState({ loading: false, data: result.data.filter((filtItem) => filtItem.status != StatusTypes.INVOICED && filtItem.status != StatusTypes.DISPATCHED) });
  };

  handleMerge = () => {
    this.setState({ confirmMerge: true });
  };

  handleMergeConfirm = async (process: boolean) => {
    try {
      if (process) {
        await dispatchMergeInto(this.state.selectRow1["Dispatch ID"], this.state.selectRow2["Dispatch ID"]);
        this.loadData();
      }
    } catch (error) {
      const err = GenerateErrorMessage(error, "Error while merging dispatches");
      this.context.updateSnack({ show: true, color: "red", message: err });
    }
    this.setState({ confirmMerge: undefined });
  };

  getCellActions1 = (column, row) => {
    return getCellActionsDispatch1(column, row, undefined);
  };

  getCellActions2 = (column, row) => {
    return getCellActionsDispatch2(column, row, undefined);
  };

  selectedRowChange1 = (selectedRow: any) => {
    this.setState({ selectRow1: selectedRow });
  };

  selectedRowChange2 = (selectedRow: any) => {
    this.setState({ selectRow2: selectedRow });
  };

  rowRenderer1 = (props, classes) => {
    const { row } = props;
    const bold = this.state.selectRow1 && this.state.selectRow1["Dispatch ID"] == row["Dispatch ID"] && classes.boldRow;
    return <Row {...props} className={bold} onDoubleClickCapture={() => this.selectedRowChange1(row)} />;
  };

  rowRenderer2 = (props, classes) => {
    const { row } = props;
    const bold = this.state.selectRow2 && this.state.selectRow2["Dispatch ID"] == row["Dispatch ID"] && classes.boldRow;
    return <Row {...props} className={bold} onDoubleClickCapture={() => this.selectedRowChange2(row)} />;
  };

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        {this.state.confirmMerge && (
          <Confirmation
            isOpen={true}
            handleClose={() => this.handleMergeConfirm(false)}
            handleConfirm={() => this.handleMergeConfirm(true)}
            title="Merge Dispatched?"
            body="Are you sure you want to MERGE the selected Dispatches?"
          ></Confirmation>
        )}
        <div className={classes.container}>
          <div>
            <Button
              name="btnmerge"
              variant="contained"
              color="primary"
              style={{ margin: "2px" }}
              onClick={this.handleMerge}
              disabled={!this.state.selectRow1 || !this.state.selectRow2}
            >
              Merge Dispatches
            </Button>
          </div>
          <div></div>
          <div>
            <Grid
              loading={this.state.loading}
              data={this.state.data}
              GridColumns={GridColumnsDispatches1}
              clearFilters={"dispatchMerge1"}
              title={"MASTER DISPATCH"}
              rowRenderer={(props) => this.rowRenderer1(props, classes)}
            />
          </div>
          <div>
            <Grid
              loading={this.state.loading}
              data={this.state.data}
              GridColumns={GridColumnsDispatches2}
              clearFilters={"dispatchMerge2"}
              title={"MERGE WITH SELECTED MASTER DISPATCH"}
              rowRenderer={(props) => this.rowRenderer2(props, classes)}
            />
          </div>
        </div>
      </div>
    );
  }
}
export default withStyles(styles)(DispatchMergeUnstyled);
