import { client, request } from "./_base";
import { getFinancialYearSelected } from "./week";

export type Brand = {
  id: number;
  code: string;
  active: number;
};

type BrandCombo = {
  id: number;
  value: string;
};

const endpoint = "brand";

export const brands = client(endpoint, (item) => ({
  ...item,
}));

export const brandsAllSortedMappedforCombo = async (): Promise<BrandCombo[]> => {
  const data = await brands.all();
  return data.sort((a, b) => (a.code > b.code ? 1 : a.code < b.code ? -1 : 1)).map((item) => ({ id: item.id, value: item.code }));
};

export const getActiveBrands = async (): Promise<Brand[]> => {
  const result = await request(`${endpoint}/ext/getActiveBrands`, {
    method: "GET",
  });
  const resp = await result.json();
  if (result.status == 500) {
    throw resp;
  }
  return resp.data;
};

export const getBrandsFinYear = async (): Promise<{ brand: string }[]> => {
  const result = await request(`${endpoint}/ext/getBrandsFinYear/${getFinancialYearSelected()}`, {
    method: "GET",
  });
  const resp = await result.json();
  if (result.status == 500) {
    throw resp;
  }
  return resp.data;
};
