export function generateGridFilteredRows(rows, filters) {
  const filterKeys = Object.keys(filters);
  return rows.filter((rowitem) => {
    const pass = [];
    filterKeys.map((filtercol, index) => {
      if (rowitem[filtercol] !== 0 && !rowitem[filtercol]) {
        rowitem[filtercol] = "";
      }
      for (let idx = 0; idx < filters[filtercol].filterTerm.length; idx++) {
        const filteritem = filters[filtercol].filterTerm[idx];
        if (
          (filtercol == "producer_codes" ||
            filtercol == "saleinvoicenumbers" ||
            filtercol == "edivalidationerrors" ||
            filtercol == "edicomparisonerror" ||
            filtercol == "paymentcolumn" ||
            filtercol == "portdistcharge_code_special") &&
          filteritem.label !== "BLANK"
        ) {
          if (rowitem[filtercol].toString().trim().includes(filteritem.value.toString())) {
            pass.push(true);
            return;
          }
        } else if (filtercol == "ata" && filteritem.label == "BLANK") {
          if (rowitem[filtercol].toString().trim() == filteritem.value.toString() || rowitem[filtercol].toString().includes("1900-01-01T00:00:00.000Z")) {
            pass.push(true);
            return;
          }
        } else {
          if (rowitem[filtercol].toString().trim() == filteritem.value.toString()) {
            pass.push(true);
            return;
          }
        }
      }
    });
    if (pass.length == filterKeys.length) {
      return rowitem;
    }
  });
}
