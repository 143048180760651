import React from "react";
import classnames from "classnames";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";
import MuiTextField from "@material-ui/core/TextField";
import { FieldRenderProps } from "react-final-form";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
    },
  });

export type InputProps = {} & FieldRenderProps & WithStyles<typeof styles>;

const TextFieldUnstyled: React.FC<InputProps> = ({ classes, input: { name, onChange, value, ...restInput }, meta, ...props }) => (
  <MuiTextField
    {...props}
    variant="filled"
    className={classnames(classes.root)}
    name={name}
    helperText={meta.touched ? meta.error || meta.submitError : undefined}
    error={(meta.error || meta.submitError) && meta.touched}
    inputProps={restInput}
    onChange={onChange}
    value={value}
  />
);

export const TextField = withStyles(styles)(TextFieldUnstyled);
