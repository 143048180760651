import { config } from "../../config";
import { client, apiHeaders, request } from "./_base";

const endpoint = "saleadjustmentdetail";

export type SaleAdjustmentDetailType = {
  id?: number;
  saleadjustment_id?: number;
  saledetail_id: number;
  amount: number;
  cartons: number;
  created_at?: Date;
  updated_at?: Date;
};

export const saleadjustmentdetail = client(endpoint, (item) => ({
  ...item,
}));

export type getSaleAdjustmentBySaleIdType = {
  saleadjustmentdetail_id?: number;
  saleadjustmentdetail_amount: number;
  saleadjustmentdetail_cartons: number;
  saledetail_barcode: string;
  commodityCode: string;
  commodityName: string;
  varietyCode: string;
  gradeCode: string;
  countCode: string;
  packCode: string;
  markCode: string;
  barcode_cartons: number;
  barcode_palletsize: number;
  salesdetail_id: number;
  total: number;
  currency_code: string;
  saledetail_sellingprice: number;
  sale_type: number;
  barcode_id: number;
  inventory_code: string;
  variety_name: string;
};

export const getSaleAdjustmentBySaleId = async (adjustmentId: number): Promise<getSaleAdjustmentBySaleIdType[]> => {
  const result = await request(`${endpoint}/ex/detailBySaleIdAdjustmentId/${adjustmentId}`, {
    method: "GET",
  });
  const resp = await result.json();

  if (result.status == 500) {
    throw new Error(resp);
  }

  return resp.data;
};

export const adjustmentDetailBySaleId = async (saleid: number): Promise<getSaleAdjustmentBySaleIdType[]> => {
  const result = await request(`${endpoint}/ex/adjustmentDetailBySaleId/${saleid}`, {
    method: "GET",
  });
  const resp = await result.json();

  if (result.status == 500) {
    throw new Error(resp);
  }

  return resp.data;
};
