import React, { createContext, useState, useMemo } from "react";
import { whoami } from "../api/_base";

import { User } from "../api/users"; //userConfig, whoami, updateUser

const defaultUser = {
  id: 0,
  username: "",
  admin: false,
  active: true,
  stockalloc_permission: false,
  consolidation_lock_permission: false,
};

type Loading = {
  loading: boolean;
  loaded: boolean;
  error?: Error;
  updated_at?: number;
};

export type UserContextState = {
  user: User;
  update(user: Partial<User>);
  loading: Loading;
  hydrate();
};

export const UserContext = createContext<UserContextState>({
  user: defaultUser,
  loading: { loading: false, loaded: false, error: null, updated_at: undefined },
  hydrate: () => {},
  update: () => {},
});

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState<User>(defaultUser);
  const [loading, setLoading] = useState<Loading>({ loading: false, loaded: false, error: null, updated_at: undefined });
  const value = useMemo(
    () => ({
      user,
      loading,
      hydrate: async () => {
        try {
          setLoading({
            loading: true,
            loaded: false,
            error: null,
          });
          const user = await whoami();
          setUser(user);
          setLoading({
            loading: false,
            loaded: true,
            error: null,
          });
        } catch (e) {
          setLoading({
            loading: false,
            loaded: false,
            error: e,
          });
        }
      },
      update: async (user) => {
        try {
          // await updateUser(user);
          setUser(user);
          setLoading({
            loading: false,
            loaded: true,
            updated_at: Date.now(),
          });
        } catch (e) {
          setLoading({
            loading: false,
            loaded: false,
            error: e,
          });
        }
      },
      setUser,
    }),
    [user, loading],
  );

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};
