import { apiHeaders, request } from "./_base";
import { FileImport, FileImportHeaderType, FileImportHeaderInfoType, FileImportDataRow } from "../models/FileImport";
import { getFinancialYearSelected } from "./week";

const endpoint = "fileimport";

export const getFileImport = async (): Promise<{ data: FileImport[] }> => {
  const result = await request(`${endpoint}/extend/all?financial_year=${getFinancialYearSelected()}`, {
    headers: apiHeaders(),
  });
  if (result.status == 500) {
    const resp = await result.json();
    throw resp;
  }
  return result.json();
};

export const getFileImportData = (id: number): Promise<{ data: FileImportDataRow[] }> =>
  request(`${endpoint}/data/${id}`, {
    headers: apiHeaders(),
  }).then(async (result) => {
    if (result.status == 500) {
      const resp = await result.json();
      throw resp;
    }
    return result.json();
  });

export const getFileImportHeader = (id: number): Promise<{ data: FileImportHeaderType[] }> =>
  request(`${endpoint}/header/${id}`, {
    headers: apiHeaders(),
  }).then(async (result) => {
    if (result.status == 500) {
      const resp = await result.json();
      throw resp;
    }
    return result.json();
  });

export const getFileImportHeaderInfo = (id: number): Promise<{ data: FileImportHeaderInfoType[] }> =>
  request(`${endpoint}/headerinfo/${id}`, {
    headers: apiHeaders(),
  }).then(async (result) => {
    if (result.status == 500) {
      const resp = await result.json();
      throw resp;
    }
    return result.json();
  });

export const execFileImport = async (id: number) => {
  const result = await request(`${endpoint}/exec/${id}`, {
    headers: apiHeaders(),
  });
  const resp = await result.json();

  if (result.status > 399) {
    if (resp.message.originalError) {
      throw resp.message.originalError.info.message;
    }
    throw resp.message;
  }

  return resp;
};

export const execAutoProcessFileImport = async () => {
  const result = await request(`${endpoint}/ext/exec/autoProcessFileImport`, {
    headers: apiHeaders(),
  });

  if (result.status > 399) {
    const resp = await result.json();
    if (resp.message.originalError) {
      throw resp.message.originalError.info.message;
    }
    throw resp.message;
  }

  const resp = await result.json();
  return resp;
};

export const execFileReImport = async (fileImportId: number, templateId: number) => {
  const result = await request(`${endpoint}/extend/exec-file-reimport?fileImportId=${fileImportId}${templateId > 0 ? `&templateId=${templateId}` : ``}`, {
    headers: apiHeaders(),
  });
  const resp = await result.json();

  if (result.status > 399) {
    if (resp.message.originalError) {
      throw resp.message.originalError.info.message;
    }
    throw resp.message;
  }

  return resp;
};

export const execRejectFileImport = async (id: number): Promise<void> => {
  const result = await request(`${endpoint}/execreject/${id}`, {
    headers: apiHeaders(),
  });
  if (result.status == 500) {
    const resp = await result.json();
    throw resp;
  }
};

export const execDeleteFileImport = async (id: number): Promise<void> => {
  const result = await request(`${endpoint}/execdelete/${id}`, {
    headers: apiHeaders(),
    method: "DELETE",
  });
  if (result.status == 500) {
    const resp = await result.json();
    throw resp;
  }
};

export const updateFileImportHeaderInfo = (data: any[]): Promise<any> =>
  request(`${endpoint}/extend/update-header-info`, {
    headers: apiHeaders(),
    method: "PUT",
    body: JSON.stringify(data),
  });

type ValidationHeaderInfo = {
  rowId: number;
  column: string;
};

export const getFileImportHeaderInfoValidation = (id: number): Promise<{ data: ValidationHeaderInfo[] }> =>
  request(`${endpoint}/headerinfo-validate/${id}`, {
    headers: apiHeaders(),
  }).then(async (result) => {
    if (result.status == 500) {
      const resp = await result.json();
      throw resp;
    }
    return result.json();
  });

export const getFileImportMappingTypes = (): Promise<{ data: any }> =>
  request(`${endpoint}/extend/mapping-templates`, {
    headers: apiHeaders(),
  }).then(async (result) => {
    if (result.status == 500) {
      const resp = await result.json();
      throw resp;
    }
    return result.json();
  });

export const execFileImportTemplates = (fileImportId: number, templateId: number) =>
  request(`${endpoint}/extend/exec-template?fileImportId=${fileImportId}&templateId=${templateId}`, {
    headers: apiHeaders(),
  });

export const execProcessIntake = async (fileImportId: number): Promise<void> => {
  const result = await request(`${endpoint}/extend/execProcessIntake?fileImportId=${fileImportId}`, {
    headers: apiHeaders(),
  });
  if (result.status == 500) {
    const resp = await result.json();
    throw resp;
  }
};

export const execProcessDispatch = async (fileImportId: number): Promise<void> => {
  const result = await request(`${endpoint}/extend/execProcessDispatch?fileImportId=${fileImportId}`, {
    headers: apiHeaders(),
  });
  if (result.status == 500) {
    const resp = await result.json();
    throw resp;
  }
};

export const getFailedEdiImports = () =>
  request(`${endpoint}/extend/failedediimports`, {
    headers: apiHeaders(),
  }).then(async (result) => {
    if (result.status == 500) {
      const resp = await result.json();
      throw resp;
    }
    return result.json();
  });

export const getEdiFilesImported = () =>
  request(`${endpoint}/extend/getEdiFilesImported`, {
    headers: apiHeaders(),
  }).then(async (result) => {
    if (result.status == 500) {
      const resp = await result.json();
      throw resp;
    }
    return result.json();
  });
