import React from "react";

import CustomAutoCompleteFilter from "../lib/components/customdatagridfilter/CustomFilter";
import { CheckboxSelect } from "../lib/components/CheckboxSelect";
import { PIInvoiceLines, PIInvoiceNumbers } from "../lib/api/sale";
import { ColumnFiltersType, ColumnsWidthType } from "../lib/types/grid";

import IconLock from "@material-ui/icons/Lock";

import format from "date-fns/format";
import { InvoiceLinesStatus } from "../lib/api/invoiceItemsAudit";

enum CellWidth {
  Tiny = 30,
  TinyX = 50,
}

const defaultColumnProperties = {
  resizable: true,
  sortable: true,
  filterable: true,
};

let currentSpace = true;

const shouldRenderLock = (row: PIInvoiceNumbers): boolean => row.merged == InvoiceLinesStatus.Merged || row.accepted == InvoiceLinesStatus.Accepted;

export const GridColumns = (
  data: PIInvoiceLines[],
  filters: ColumnFiltersType,
  arrangement: string[],
  columnsWidth: ColumnsWidthType[],
  selectedRows: PIInvoiceLines[],
  handleCheckboxSelect: (row: PIInvoiceNumbers) => void,
) => {
  let postValueInfo = "";

  if (data) {
    if (currentSpace) {
      postValueInfo = " ";
    }
    currentSpace = !currentSpace;
  }

  const columns = [
    {
      key: "merged",
      name: "#C" + postValueInfo,
      width: CellWidth.Tiny,
      formatter: ({ row }) => {
        return (
          <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "flex-start", marginTop: "-8px" }}>
            <CheckboxSelect row={row} selectedRows={selectedRows} handleCheckboxSelect={handleCheckboxSelect} valueKey={"sale_invoicenumber"} />
          </div>
        );
      },
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"merged"} data={data} section={"mgpintegration"} />;
      },
    },
    {
      key: "mergedFormatted",
      name: "Locked" + postValueInfo,
      width: CellWidth.Tiny,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"mergedFormatted"} data={data} section={"mgpintegration"} />;
      },
      formatter: ({ row }) => {
        return <div style={{ display: "flex", justifyContent: "center" }}>{shouldRenderLock(row) ? <IconLock /> : <></>}</div>;
      },
    },
    {
      key: "sale_invoicenumber",
      name: "Invoice Number" + postValueInfo,
      width: CellWidth.TinyX,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"sale_invoicenumber"} data={data} section={"mgpintegration"} />;
      },
    },
    {
      key: "sale_date",
      name: "Sale Month" + postValueInfo,
      width: CellWidth.TinyX,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"sale_date"} data={data} section={"mgpintegration"} />;
      },
    },
    {
      key: "producer_codes",
      name: "Producer" + postValueInfo,
      width: CellWidth.TinyX,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"producer_codes"} data={data} section={"mgpintegration"} />;
      },
    },
  ].map((c) => ({ ...c, ...defaultColumnProperties }));

  const columnsArranged = [];

  if (arrangement && arrangement.length > 0) {
    arrangement.map((item) => {
      const indexOfItem = columns.indexOf(columns.find((col) => col.name.trim() == item.toString().trim()));
      if (indexOfItem != -1) {
        columnsArranged.push(columns[indexOfItem]);
      }
    });
  } else {
    columns.map((colItem) => {
      columnsArranged.push(colItem);
    });
  }

  if (columnsWidth && columnsWidth.length > 0) {
    for (let index = 0; index < columnsArranged.length; index++) {
      const element = columnsArranged[index];
      const colItem = columnsWidth.find((colwitem) => colwitem.name.trim() == element.name.trim());
      if (colItem) {
        element.width = colItem.width;
      }
    }
  }

  return columnsArranged;
};
