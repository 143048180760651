import React, { useState, FC, useEffect } from "react";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";

import Tooltip from "@material-ui/core/Tooltip";
import Button from "@material-ui/core/Button";

import IconAdd from "@material-ui/icons/Add";

import Grid from "../../lib/components/grid";
import Confirmation from "../../lib/components/confirmation";

import { clearingagent, ClearingAgentForConsignee, AvailableToConsignee, allClearingAgentWithConsignee } from "../../lib/api/clearingagent";
import { consigneeclearingagent } from "../../lib/api/consigneeclearingagent";

import { GridColumns } from "./clearingagentgridsetup";

import ClearingAgentEditForm from "./clearingagenteditform";

const styles = (theme: Theme) => {
  return createStyles({
    root: {
      padding: theme.spacing(1),
      width: "100%",
      height: "100%",
      position: "relative",
    },
    inline: {
      display: "inline",
      position: "absolute",
      marginTop: "7px",
      marginLeft: "7px",
    },
  });
};

type ClearingAgentProps = {
  reload?: boolean;
  consigneeId?: number;
  consigneeIdToExclude?: number;
  selectedClearingAgent?: any;
  noDelete?: boolean;
} & WithStyles<typeof styles>;

const sortFn = (data: any[]) =>
  data.sort((a, b) => {
    if (a.code > b.code) return 1;
    if (a.code < b.code) return -1;
    return 1;
  });

const ClearingAgentUnstyled: FC<ClearingAgentProps> = (props) => {
  const { classes, reload, consigneeId, selectedClearingAgent, noDelete, consigneeIdToExclude } = props;

  const [data, setData] = useState([]);
  const [confirmRemoveID, setConfirmRemoveID] = useState(undefined);
  const [confirmEditID, setConfirmEditID] = useState(undefined);
  const [loading, setloading] = useState(true);

  useEffect(() => {
    loadData();
  }, [reload]);

  const loadData = async () => {
    if (consigneeIdToExclude) {
      const data = await AvailableToConsignee(consigneeIdToExclude);
      setData(sortFn(data));
    } else if (consigneeId) {
      const data = await ClearingAgentForConsignee(consigneeId);
      setData(sortFn(data));
    } else {
      const data = await allClearingAgentWithConsignee();
      setData(sortFn(data));
    }
    setloading(false);
  };

  const handleCreate = () => {
    setConfirmEditID("0");
  };

  const handleEdit = (id) => {
    setConfirmEditID(id);
  };

  const handleEditClose = () => {
    setConfirmEditID(undefined);
  };

  const handleEditConfirm = (item) => {
    const id = item["id"];
    delete item["id"];

    if (id == "0") {
      clearingagent
        .create({ data: { ...item } })
        .then((newId) => {
          if (consigneeId) {
            const newData = {
              consignee_id: consigneeId,
              clearingagent_id: newId[0],
            };
            consigneeclearingagent.create({ data: newData });
          }
        })
        .then(() => loadData());
    } else {
      clearingagent.update(id, { data: { ...item } }).then(() => loadData());
    }
    handleEditClose();
  };

  const handleRemove = async (id) => {
    setConfirmRemoveID(id);
  };

  const handleRemoveClose = () => {
    setConfirmRemoveID(undefined);
  };

  const handleRemoveConfirm = async () => {
    await clearingagent.remove(confirmRemoveID);
    handleRemoveClose();
    loadData();
  };

  return (
    <div className={classes.root}>
      <div className={classes.inline}>
        <Tooltip title="Create new Clearing Agent">
          <div className={classes.inline}>
            <Button variant="contained" color="primary" onClick={handleCreate} style={{ marginBottom: "10px" }}>
              <IconAdd />
            </Button>
          </div>
        </Tooltip>
      </div>
      <Grid
        loading={loading}
        data={data}
        GridColumns={(column, row, arrangement, columnsWidth) => GridColumns(column, row, arrangement, columnsWidth, handleRemove, handleEdit, noDelete)}
        selectedRowChange={selectedClearingAgent}
        clearFilters={"clearingagent"}
      />
      {confirmRemoveID && (
        <Confirmation
          isOpen={confirmRemoveID ? true : false}
          handleClose={handleRemoveClose}
          handleConfirm={handleRemoveConfirm}
          title="Remove selected Clearing Agent?"
          body="Are you sure you want to REMOVE the selected Clearing Agent Record?"
        ></Confirmation>
      )}
      {confirmEditID && (
        <Confirmation
          isOpen={confirmEditID ? true : false}
          handleClose={handleEditClose}
          handleConfirm={() => {}}
          title={confirmEditID == "0" ? "Creating Clearing Agent" : "Editing Clearing Agent"}
          body={undefined}
        >
          <ClearingAgentEditForm onSubmit={handleEditConfirm} onClose={handleEditClose} id={confirmEditID} />
        </Confirmation>
      )}
    </div>
  );
};

export default withStyles(styles)(ClearingAgentUnstyled);
