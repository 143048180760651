import React, { useEffect, forwardRef } from "react";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";

import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Tooltip from "@material-ui/core/Tooltip";

import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";

import Settings from "@material-ui/icons/Settings";

import ReactDatePicker, { registerLocale } from "react-datepicker";
import { format } from "date-fns";
import en from "date-fns/esm/locale/en-GB";
registerLocale("en-GB", en);
import { Field } from "react-final-form";
import { TextField, Select } from "final-form-material-ui";

import numeral from "numeral";

import parseISO from "date-fns/parseISO";
import toDate from "date-fns/toDate";
import { isNullOrUndef } from "../../../lib/helpers/isNullOrUndef";

import { getCurrencyAllSortedMappedforCombo } from "../../../lib/api/currency";
import { getTermsAllSortedMappedforCombo } from "../../../lib/api/terms";
import { GetCurrentExchangeRate, forDate } from "../../../lib/api/exchangerates";
import { producerfinanceByProducerCode } from "../../../lib/api/producerfinance";
import { ProducerSaleDispatchJoinedType } from "../../../lib/api/producersale";
import Confirmation from "../../../lib/components/confirmation";
import PaymentTerms from "../../../maintenance/terms/terms";
import { getProducerCurrenciesByCode } from "../../../lib/api/producerMapping";

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <Typography hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1),
    },
    container: {
      display: "grid",
      gridTemplateColumns: "450px 450px 300px 300px",
      gridTemplateRows: "repeat(3, 50px)",
      gridTemplateAreas: `
      "details"
      `,
      gridRowGap: "60px",
      gridColumnGap: "40px",
    },
    tableCellLabel: {
      borderBottom: "none",
      height: "50px",
      textAlign: "right",
      paddingTop: "5px",
    },
    tableCellLabelValue: {
      borderBottom: "none",
      height: "50px",
      textAlign: "left",
      paddingTop: "5px",
    },
    tableCellDetail: {
      width: "350px",
      borderBottom: "none",
      height: "50px",
    },
    tableCellDetailDate: {
      marginTop: "-5px",
      width: "150px",
      borderBottom: "none",
      height: "50px",
    },
    tableCellsValueSelect: {
      textAlign: "left",
      borderBottom: "none",
      height: "50px",
      padding: 0,
      marginTop: "-14px",
    },
    tableContainerTwo: {
      display: "grid",
      gridTemplateColumns: "150px 350px",
      gridGap: "10px",
      height: "50px",
    },
    tableContainerThree: {
      display: "grid",
      gridTemplateColumns: "150px 300px 50px",
      gridGap: "10px",
      height: "50px",
    },
    tableCellLabelCombo: {
      display: "flex",
      flexDirection: "row-reverse",
      marginTop: "7px",
    },
  });

type InvoiceAcceptFormProps = { values: any; form: any } & WithStyles<typeof styles>;

const InvoiceAcceptFormUnstyled: React.FunctionComponent<InvoiceAcceptFormProps> = (props) => {
  const { classes, values, form } = props;

  const valuesTyped: ProducerSaleDispatchJoinedType = { ...values };

  const [currencies, setCurrencies] = React.useState([]);
  const [terms, setTerms] = React.useState([]);
  const [dateInvoice, setDateInvoice] = React.useState(valuesTyped.sale_date ? parseISO(values.sale_date) : new Date());
  const [exchanges, setExchanges] = React.useState({});
  const [pushRateValue, setPushRateValue] = React.useState(null);
  const [termsMaintenanceOpen, setTermsMaintenanceOpen] = React.useState(false);

  const [value, setValue] = React.useState(0);

  useEffect(() => {
    getProducerCurrenciesByCode(valuesTyped.producer_code).then((producerCurrencies) => {
      getCurrencyAllSortedMappedforCombo().then((currenciesResult) => {
        let currenciesList = currenciesResult;

        if (producerCurrencies.length > 0) {
          currenciesList = currenciesList.filter((currency) => producerCurrencies.find((prodCurrency) => prodCurrency.currency_id == currency.value));
        }

        setCurrencies(currenciesList);
        if (!form.getState().values.sale_date) {
          GetCurrentExchangeRate().then((latestExchanges) => {
            setExchanges(latestExchanges);
            setExchangePushDataRates(currenciesList, latestExchanges["rates"]);
          });
        } else {
          updateExchangePushDataRates(currenciesList);
        }
      });
    });

    getTermsAllSortedMappedforCombo(1).then((result) => {
      setTerms(result);
    });

    if (isNullOrUndef(valuesTyped.sale_terms_id)) {
      producerfinanceByProducerCode(valuesTyped.producer_code).then((result) => {});
    }
  }, [values.dispatch_id]);

  const changeDateInvoice = (value) => {
    setDateInvoice(value);
    form.change("sale_date", toDate(value));
    updateExchangePushDataRates(undefined, true);
  };

  const setExchangePushDataRates = (currs = undefined, XCRates, updateExchangeRequested = false) => {
    const curr = currs ? currs : currencies;
    const rates = XCRates ? XCRates : exchanges["rates"];
    setPushRateValue(0);
    const rate = curr.find((currency) => form.getState().values.salecurr_id == currency.value);
    if (rate) {
      const val = numeral(rates[rate.display]).format("0.000").toString();
      setPushRateValue(val);
      if (updateExchangeRequested || !form.getState().values.sale_exchangerate) {
        form.change("sale_exchangerate", val);
      }
    }
  };

  const changeExchangeRateValue = (value = undefined, exchange = undefined) => {
    const currencyId = value ? value : form.getState().values.salecurr_id;
    const rate = currencies.find((currency) => currencyId == currency.value);
    if (rate) {
      const rateValue = numeral(exchanges["rates"][rate.display]).format("0.000").toString();
      const final = exchange ? exchange : rateValue;
      setPushRateValue(final);
      form.change("sale_exchangerate", final);
    }
  };

  const updateExchangePushDataRates = (currencyList = undefined, updateExchange = false) => {
    const currs = currencyList ? currencyList : currencies;
    const date = format(new Date(form.getState().values.sale_date), "yyyy-MM-dd");
    forDate(date).then((data) => {
      if (Object.keys(data.rates).length == 0) {
        GetCurrentExchangeRate().then((data) => {
          setExchanges(data);
          setExchangePushDataRates(currs, data.rates, updateExchange);
        });
      } else {
        setExchanges({ rates: data.rates[Object.keys(data.rates)[0]] });
        setExchangePushDataRates(currs, data.rates[Object.keys(data.rates)[0]], updateExchange);
      }
    });
  };

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  const handleEditDone = () => {
    // The header / toolbar just above the grid with buttons dissappears
    // for %$%^$ knows why when vessel maintenance is closed...this fixes that.
    //HACK START
    const elements = document.getElementsByClassName("react-grid-Toolbar");
    for (let index = 0; index < elements.length; index++) {
      const element = elements[index] as HTMLElement;
      element.style.display = "initial";
    }
    //HACK END

    setTermsMaintenanceOpen(false);
    getTermsAllSortedMappedforCombo(1).then((result) => {
      setTerms(result);
    });
  };

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Tabs value={value} onChange={handleTabChange} aria-label="Invoice Header">
          <Tab label="Invoice Details" {...a11yProps(0)} />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <div className={classes.container}>
          <div>
            <TableFieldLabel
              classes={classes}
              field={`${form.getState().values["producer_code"].toString()} - ${form.getState().values["producer_name"].toString()}`}
              title="Producer"
            />
            <TableFieldDate classes={classes} dateValue={dateInvoice} title="Invoice Date" changeDate={changeDateInvoice} />
            <TableFieldText classes={classes} field="sale_invoicenumber" title="Producer Invoice" disabled={false} />
          </div>
          <div>
            <CurrencyComboTableField
              classes={classes}
              field="salecurr_id"
              title="Currency"
              data={currencies}
              addEmptyValue={undefined}
              pushExchangeRate={changeExchangeRateValue}
              pushRateValue={pushRateValue}
            />
            <TableFieldComboWithButton classes={classes} field="sale_terms_id" title="Payment Terms" data={terms} setConfirmEditID={setTermsMaintenanceOpen} />
            <TableFieldLabel classes={classes} field={form.getState().values["dispatch_invoicenumber"]} title={"Dispatch Invoice"} />
          </div>
          <div>
            <TableFieldLabel classes={classes} field={form.getState().values["vessel_code"]} title={"Vessel"} />
            <TableFieldLabel classes={classes} field={form.getState().values["dispatch_containerno"]} title={"Container"} />
            <TableFieldLabel classes={classes} field={format(new Date(form.getState().values["loadout_etd"]), "yyyy-MM-dd")} title={"ETD"} />
          </div>

          <div>
            <TableFieldLabel classes={classes} field={form.getState().values["portdistcharge_code"]} title={"Port of discharge"} />
            <TableFieldLabel classes={classes} field={form.getState().values["portfinal_code"]} title={"Destination"} />
            <TableFieldLabel classes={classes} field={format(new Date(form.getState().values["loadout_eta"]), "yyyy-MM-dd")} title={"ETA"} />
          </div>
          {termsMaintenanceOpen && (
            <Confirmation fullscreen={true} isOpen={termsMaintenanceOpen} handleClose={handleEditDone} handleConfirm={() => {}} title={"Loadout - Editing Terms"} body={undefined}>
              <PaymentTerms termstype={1} handleOnClose={() => handleEditDone()} />
            </Confirmation>
          )}
        </div>
      </TabPanel>
    </div>
  );
};

export default withStyles(styles)(InvoiceAcceptFormUnstyled);

const TableFieldLabel: React.FunctionComponent<{ field: string; title: string } & WithStyles<typeof styles>> = (props) => {
  const { classes, field, title } = props;
  return (
    <div className={classes.tableContainerTwo}>
      <span className={classes.tableCellLabel}>{title}:</span>
      <span className={classes.tableCellLabelValue}>{`${field}`}</span>
    </div>
  );
};

const TableFieldText: React.FunctionComponent<{ field: string; title: string; disabled: boolean } & WithStyles<typeof styles>> = (props) => {
  const { classes, field, title, disabled } = props;
  return (
    <div className={classes.tableContainerTwo}>
      <span className={classes.tableCellLabel}>{title}:</span>
      <span className={classes.tableCellDetail}>
        <Field name={field} component={TextField} type="text" fullWidth={true} disabled={disabled} />
      </span>
    </div>
  );
};

const CalenderCustomInput = forwardRef((props: any, ref: any) => {
  return (
    <Button name="CalenderCustomInput" variant="contained" color="primary" onClick={props.onClick} style={{ marginTop: "5px", width: "250px" }}>
      {props.value}
    </Button>
  );
});

const TableFieldDate: React.FunctionComponent<{ dateValue: Date; title: string; changeDate: (value) => void } & WithStyles<typeof styles>> = (props) => {
  const { classes, dateValue, title, changeDate } = props;
  return (
    <div className={classes.tableContainerTwo}>
      <span className={classes.tableCellLabel}>{title}:</span>
      <span className={classes.tableCellDetailDate}>
        <ReactDatePicker
          locale="en-GB"
          showWeekNumbers={true}
          selected={toDate(dateValue)}
          onChange={(value) => changeDate(value)}
          dateFormat="dd-MM-yyyy"
          placeholderText="click here to select a date"
          customInput={<CalenderCustomInput />}
        />
      </span>
    </div>
  );
};

const CurrencyComboTableField: React.FunctionComponent<
  { classes: any; title: string; field: string; data: any; addEmptyValue: string; pushExchangeRate: any; pushRateValue: number } & WithStyles<typeof styles>
> = (props) => {
  const { classes, title, field, data, addEmptyValue, pushExchangeRate, pushRateValue } = props;
  return (
    <div className={classes.tableContainerThree}>
      <span className={classes.tableCellLabel}>{title}:</span>
      <div>
        <Field
          fullWidth
          name={field}
          formControlProps={{
            className: classes.tableCellsValueSelect,
            style: { width: "300px", marginRight: "7.5px" },
          }}
          component={Select as any}
          parse={(value) => {
            pushExchangeRate(value);
            return value;
          }}
        >
          {addEmptyValue && (
            <MenuItem key={`emptyvalueMenuItem`} value={-1}>
              {addEmptyValue}
            </MenuItem>
          )}
          {(data || []).map((item, index) => (
            <MenuItem key={`${item.value}${index}`} value={item.value}>
              {item.display}
            </MenuItem>
          ))}
        </Field>
        <Tooltip placement="top" title={`${pushRateValue}`}>
          <span>
            <Button style={{ marginRight: "7.5px", width: "30px" }} onClick={() => pushExchangeRate(undefined, pushRateValue)}>
              {" "}
            </Button>
          </span>
        </Tooltip>
      </div>
      <span style={{ marginLeft: "20px" }}>
        <Field name={"sale_exchangerate"} component={TextField} type="text" fullWidth={true} style={{ width: "60px", marginTop: "2px" }} />
      </span>
    </div>
  );
};

const TableFieldComboWithButton: React.FunctionComponent<{ classes: any; field: string; data: any; setConfirmEditID: any; title: string }> = (props) => {
  const { classes, field, data, setConfirmEditID, title } = props;
  return (
    <div className={classes.tableContainerThree}>
      <span className={classes.tableCellLabelCombo}>{title}:</span>
      <div>
        <Field
          fullWidth
          name={field}
          component={Select as any}
          formControlProps={{
            className: classes.tableCellsValueSelect,
            style: { width: "300px" },
          }}
        >
          {(data || []).map((item, index) => (
            <MenuItem key={`${item.value}${index}`} value={item.value}>
              {item.display}
            </MenuItem>
          ))}
        </Field>
      </div>
      <span>
        <Button size="small" color="primary" onClick={() => setConfirmEditID(true)}>
          <Settings />
        </Button>
      </span>
    </div>
  );
};
