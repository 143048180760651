import React from "react";

import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import FileCopyIcon from "@material-ui/icons/FileCopy";

import CustomAutoCompleteFilter from "../../lib/components/customdatagridfilter/CustomFilter";
import { CellActionsFormatter } from "../../lib/helpers/CellActionsFormatter";

import numeral from "numeral";

import { handleColumnsArrangement, handleColumnsWidth } from "../../lib/helpers/grid";

const cellWidthSmall = 100;
// const cellWidthSmallBig = 140;
const cellWidthBig = 185;

const defaultColumnProperties = {
  resizable: true,
  sortable: true,
};

const formatterDecimal = ({ row, column }) => {
  const data = row[column.key];
  return <div>{`${numeral(data).format("0,0.00")}`}</div>;
};

const allFormatter = ({ row, column }) => {
  const data = row[column.key];
  const value = !data ? "ALL" : data;
  return <div>{`${value}`}</div>;
};

let currentSpace = true;

export const GridColumns = (data, filters, arrangement = undefined, columnsWidth = undefined, handleRemove, handleEdit, handleDuplicate) => {
  let postValue = "";

  if (data) {
    if (currentSpace) {
      postValue = " ";
    }
    currentSpace = !currentSpace;
  }

  const columns = [
    {
      key: "actions",
      name: "Actions",
      width: cellWidthSmall,
      formatter: ({ row }) => {
        const actions = clientActions(row, handleRemove, handleEdit, handleDuplicate);
        return (
          <div style={{ display: "flex", justifyContent: "center", gap: "5px" }}>
            <CellActionsFormatter actions={actions} />
          </div>
        );
      },
    },
    {
      key: "clients_name",
      name: "Clients" + postValue,
      width: cellWidthBig,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"clients_name"} data={data} section={"clientscontractsgridsetup"} />;
      },
    },
    {
      key: "weekfrom",
      name: "From Shipment Week" + postValue,
      width: cellWidthBig,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"weekfrom"} data={data} section={"clientscontractsgridsetup"} />;
      },
    },
    {
      key: "weekto",
      name: "To Shipment Week" + postValue,
      width: cellWidthBig,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"weekto"} data={data} section={"clientscontractsgridsetup"} />;
      },
    },
    {
      key: "commodity_name",
      name: "Commodity" + postValue,
      width: cellWidthBig,
      filterable: true,
      formatter: (event) => allFormatter(event),
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"commodity_name"} data={data} section={"clientscontractsgridsetup"} />;
      },
    },
    {
      key: "varieties",
      name: "Variety" + postValue,
      width: cellWidthBig,
      filterable: true,
      formatter: (event) => allFormatter(event),
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"varieties"} data={data} section={"clientscontractsgridsetup"} />;
      },
    },
    {
      key: "grades",
      name: "Grade" + postValue,
      width: cellWidthBig,
      filterable: true,
      formatter: (event) => allFormatter(event),
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"grades"} data={data} section={"clientscontractsgridsetup"} />;
      },
    },
    {
      key: "counts",
      name: "Count" + postValue,
      width: cellWidthBig,
      filterable: true,
      formatter: (event) => allFormatter(event),
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"counts"} data={data} section={"clientscontractsgridsetup"} />;
      },
    },
    {
      key: "pack_code",
      name: "Pack Code" + postValue,
      width: cellWidthBig,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"pack_code"} data={data} section={"clientscontractsgridsetup"} />;
      },
    },
    {
      key: "currency_code",
      name: "Currency" + postValue,
      width: cellWidthBig,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"currency_code"} data={data} section={"clientscontractsgridsetup"} />;
      },
    },
    {
      key: "sellingprice",
      name: "Selling Price" + postValue,
      width: cellWidthBig,
      filterable: true,
      formatter: formatterDecimal,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"sellingprice"} data={data} section={"clientscontractsgridsetup"} />;
      },
    },
  ].map((c) => ({ ...c, ...defaultColumnProperties }));

  let columnsArranged = [];
  columnsArranged = handleColumnsArrangement(columnsArranged, arrangement, columns);
  columnsArranged = handleColumnsWidth(columnsArranged, columnsWidth, columns);

  return columnsArranged;
};

const clientActions = (client, handleRemove, handleEdit, handleDuplicate) => {
  return [
    {
      icon: (
        <span title="Edit Contract">
          <EditIcon style={{ cursor: "pointer", color: "orange" }} />
        </span>
      ),
      callback: (e) => handleEdit(client.id),
    },
    {
      icon: (
        <span title="Remove Contract">
          <DeleteIcon style={{ cursor: "pointer", color: "red" }} />
        </span>
      ),
      callback: (e) => handleRemove(client.id),
    },
    {
      icon: (
        <span title="Copy Contract">
          <FileCopyIcon style={{ cursor: "pointer", color: "#4a9de2" }} />
        </span>
      ),
      callback: (e) => handleDuplicate(client.id),
    },
  ];
};

export default function getCellActions(column, row, handleRemove, handleEdit, handleDuplicate) {
  if (column.key != "actions") {
    return undefined;
  }
  const cellActions = {
    actions: clientActions(row, handleRemove, handleEdit, handleDuplicate),
  };
  return cellActions[column.key];
}
