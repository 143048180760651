import { client, request, apiHeaders } from "./_base";

const endpoint = "producersfullinvoice";

export type ProducersFullInvoiceType = {
  sale_id: number;
  sale_dispatch_id: number;
  dispatchdocs_id: number;
  sale_date: Date;
  sale_invoicenumber: string;
  dispatch_invoicenumber: string;
  sale_ucr: string;
  sale_trader_id: number;
  users_username: string;
  sale_terms_id: number;
  terms_code: string;
  sale_ata: Date;
  sale_notifyparty_id: number;
  clearingagentnotify_name: string;
  sale_payment1amount: number;
  sale_payment1amount_remain: number;
  sale_payment1date: Date;
  sale_payment2amount: number;
  sale_payment2amount_remain: number;
  sale_payment2date: Date;
  sale_payment3amount: number;
  sale_payment3amount_remain: number;
  sale_payment3date: Date;
  sale_created_at: Date;
  sale_updated_at: Date;
  sale_exchangerate: number;
  sale_type: number;
  sale_clients_id: number;
  clients_code: string;
  clients_name: string;
  sale_total: number;
  sale_totalfinal: number;
  sale_typename: string;
  salefundsallocation_amount: number;
  total_outstanding: number;
  document_type: string;
  document_typeapply: number;
  document_sourcetotal: number;
  dispatch_currency_id: number;
  currency_code: string;
  amountCredit: number;
  amountDebit: number;
  producercode: string;
};

export const producersFullInvoice = client(endpoint, (item) => ({
  ...item,
}));

export type ProducersFullInvoiceByProducerType = {
  sale_id: number;
  sale_dispatch_id: number;
  dispatchdocs_id: number;
  sale_date: number;
  sale_invoicenumber: string;
  dispatch_invoicenumber: string;
  sale_terms_id: number;
  terms_code: string;
  sale_payment1amount: number;
  sale_payment1amount_remain: number;
  sale_payment1date: Date;
  sale_payment2amount: number;
  sale_payment2amount_remain: number;
  sale_payment2date: Date;
  sale_payment3amount: number;
  sale_payment3amount_remain: number;
  sale_payment3date: Date;
  sale_total: number;
  sale_totalfinal: number;
  sale_created_at: Date;
  sale_updated_at: Date;
  sale_exchangerate: number;
  sale_type: string;
  sale_typename: string;
  document_type: number;
  document_typeapply: number;
  document_sourcetotal: number;
  producercode: string;
  dispatch_currency_id: number;
  currency_code: string;
  dispatch_containerno;
  vessel_id: number;
  vessel_code: string;
  loadout_etd: Date;
  loadout_eta: Date;
  amountCredit: number;
  amountDebit: number;
  paidAdjustments1_ident: string;
  paidAdjustments1_makeanote: string;
  paidAdjustments1_amount: number;
  paidAdjustments1_head_id: number;
  paidAdjustments1_paid_id: number;
  paidAdjustments2_ident: string;
  paidAdjustments2_makeanote: string;
  paidAdjustments2_amount: number;
  paidAdjustments2_head_id: number;
  paidAdjustments2_paid_id: number;
  paidAdjustments3_ident: string;
  paidAdjustments3_makeanote: string;
  paidAdjustments3_amount: number;
  paidAdjustments3_head_id: number;
  paidAdjustments3_paid_id: number;
  paidAdjustments1_payment_date: Date;
  paidAdjustments2_payment_date: Date;
  paidAdjustments3_payment_date: Date;
  paidAdjustments1_id: number;
  paidAdjustments2_id: number;
  paidAdjustments3_id: number;
  sale_total_sum: number;
  terms1Total: number;
  terms1TotalFin: number;
  terms2Total: number;
  terms2TotalFin: number;
  terms3Total: number;
  terms3TotalFin: number;
};

export const getProducersFullInvoiceByProducer = (producerid: string, financial_year: string) => {
  return request(`${endpoint}/ex/byProducer/${producerid}/${financial_year}`, {
    method: "GET",
  })
    .then(async (result) => {
      if (result.status == 500) {
        const resp = await result.json();
        throw resp;
      }
      return result.json();
    })
    .then(({ data }) => {
      return data;
    });
};

export const getProducersFullInvoice = (financial_year: string) => {
  return request(`${endpoint}/ex/full/${financial_year}`, {
    method: "GET",
  })
    .then(async (result) => {
      if (result.status == 500) {
        const resp = await result.json();
        throw resp;
      }
      return result.json();
    })
    .then(({ data }) => {
      return data;
    });
};

export const getProducersFullByProducerCode = async (financial_year: string, producerCode: string) => {
  return await request(`${endpoint}/ex/getFullByProducerCode?financial_year=${financial_year}&producercode=${producerCode}`, {
    method: "GET",
  })
    .then(async (result) => {
      if (result.status == 500) {
        const resp = await result.json();
        throw resp;
      }
      return result.json();
    })
    .then(({ data }) => {
      return data;
    });
};

export const producerAdjustmentSummary = async (financial_year: string) => {
  return await request(`${endpoint}/ex/adjustmentSummary/${financial_year}`, {
    method: "GET",
  })
    .then(async (result) => {
      if (result.status == 500) {
        const resp = await result.json();
        throw resp;
      }
      return result.json();
    })
    .then(({ data }) => {
      return data;
    });
};
