import React from "react";

import CustomAutoCompleteFilter from "../../lib/components/customdatagridfilter/CustomFilter";

import Tooltip from "@material-ui/core/Tooltip";
import WarningIcon from "@material-ui/icons/Warning";

import format from "date-fns/format";
import DropdownEditor from "../../lib/components/DropdownEditor";
import TextEditor from "../../lib/components/TextEditor";

import { EnquiryLink, Selector } from "../../lib/components/EnquiryLink";
import { DatePicker } from "../../lib/components/gridcustomedit";
import { SummaryNumber, SummaryNumber2Decimal } from "../../lib/components/SummaryFormatter";
import { FormatterNumber, FormatterNumber3Decimals } from "../../lib/components/Formatters";
import { handleColumnsArrangement, handleColumnsWidth } from "../../lib/helpers/grid";

const cellWidthTiny = 50;
const cellWidthSmall = 115;
const cellWidthMedium = 150;
const cellWidthLarge = 200;

const defaultColumnProperties = {
  resizable: true,
  sortable: true,
};

let currentSpace = true;

const CellFormatter = (row, column, validationData) => {
  const exists = validationData.find((row) => row.rowId === row.id && column === row.column);
  return <div style={{ color: exists ? "red" : "black", fontWeight: exists ? "bold" : "initial" }}>{row[column]}</div>;
};

const ErrorCellFormatter = (row) => {
  return row.edivalidationerrors.length > 0 ? (
    <Tooltip title={row.edivalidationerrors}>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <WarningIcon color="error" />
      </div>
    </Tooltip>
  ) : (
    <div></div>
  );
};

const formatDate = (date: Date, time: boolean) => (time ? format(date, "dd MMM yyyy HH:mm") : format(date, "dd MMM yyyy"));

const DateFormatter = (date: string, time = false) => {
  const newDate = date && date.length > 0 ? formatDate(new Date(date.replace("Z", "")), time) : "";
  return <div>{newDate}</div>;
};

export const ColumnsFileImportHeaderInfo = (data, filters, arrangement, columnsWidth, commoditiesCombo, varietiesCombo, validationData, producersCombo) => {
  let postValueInfo = "";

  if (data) {
    if (currentSpace) {
      postValueInfo = " ";
    }
    currentSpace = !currentSpace;
  }

  const columns = [
    {
      key: "edivalidationerrors",
      filterable: true,
      name: "#E" + postValueInfo,
      width: cellWidthTiny,
      tooltip: "HELLO",
      formatter: ({ row }) => ErrorCellFormatter(row),
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"edivalidationerrors"} data={data} section={"fileimportinfodetail"} />;
      },
      summaryFormatter: SummaryNumber,
    },
    {
      key: "loadout_reference",
      name: "Load Ref" + postValueInfo,
      filterable: true,
      width: cellWidthMedium,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"loadout_reference"} data={data} section={"fileimportinfodetail"} />;
      },
    },
    {
      key: "WaybillDocument",
      filterable: true,
      name: "Waybill Document" + postValueInfo,
      width: cellWidthMedium,
      editable: true,
      editor: (props) => <TextEditor {...props} />,
      formatter: ({ row }) => CellFormatter(row, "WaybillDocument", validationData),
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"WaybillDocument"} data={data} section={"fileimportinfodetail"} />;
      },
    },
    {
      key: "IntakeDocRef",
      filterable: true,
      name: "Intake Doc Ref" + postValueInfo,
      width: cellWidthMedium,
      editable: true,
      editor: (props) => <TextEditor {...props} />,
      formatter: ({ row }) => CellFormatter(row, "IntakeDocRef", validationData),
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"IntakeDocRef"} data={data} section={"fileimportinfodetail"} />;
      },
    },
    {
      key: "NoCartons",
      filterable: true,
      name: "No Cartons" + postValueInfo,
      width: cellWidthMedium,
      editable: true,
      editor: (props) => <TextEditor {...props} align="right" />,
      formatter: FormatterNumber,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"NoCartons"} data={data} section={"fileimportinfodetail"} />;
      },
      summaryFormatter: SummaryNumber2Decimal,
    },
    {
      key: "PalletSize",
      filterable: true,
      name: "Pallet Size" + postValueInfo,
      width: cellWidthMedium,
      editable: true,
      editor: (props) => <TextEditor {...props} align="right" />,
      formatter: FormatterNumber3Decimals,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"PalletSize"} data={data} section={"fileimportinfodetail"} />;
      },
      summaryFormatter: SummaryNumber2Decimal,
    },
    {
      key: "ProducerID",
      filterable: true,
      name: "Producer ID" + postValueInfo,
      width: cellWidthMedium,
      editable: true,
      editor: (props) => <DropdownEditor {...props} options={producersCombo} valueKey={"value"} />,
      formatter: ({ row }) => CellFormatter(row, "ProducerID", validationData),
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"ProducerID"} data={data} section={"fileimportinfodetail"} />;
      },
    },
    {
      key: "FarmNumber",
      filterable: true,
      name: "PUC" + postValueInfo,
      width: cellWidthMedium,
      editable: true,
      editor: (props) => <TextEditor {...props} />,
      formatter: ({ row }) => CellFormatter(row, "FarmNumber", validationData),
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"FarmNumber"} data={data} section={"fileimportinfodetail"} />;
      },
    },
    {
      key: "Barcode",
      filterable: true,
      name: "Barcode" + postValueInfo,
      width: cellWidthLarge,
      editable: true,
      editor: (props) => <TextEditor {...props} />,
      formatter: (props) => <EnquiryLink {...props} selector={Selector.Barcode} />,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Barcode"} data={data} section={"fileimportinfodetail"} />;
      },
    },
    {
      key: "CommodityCode",
      filterable: true,
      name: "Commodity Code" + postValueInfo,
      width: cellWidthMedium,
      editable: true,
      editor: (props) => <DropdownEditor {...props} options={commoditiesCombo} valueKey={"value"} />,
      formatter: ({ row }) => CellFormatter(row, "CommodityCode", validationData),
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"CommodityCode"} data={data} section={"fileimportinfodetail"} />;
      },
    },
    {
      key: "VarietyCode",
      filterable: true,
      name: "Variety Code" + postValueInfo,
      width: cellWidthMedium,
      editor: (props) => <DropdownEditor {...props} options={varietiesCombo} valueKey={"value"} />,
      formatter: ({ row }) => CellFormatter(row, "VarietyCode", validationData),
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"VarietyCode"} data={data} section={"fileimportinfodetail"} />;
      },
    },
    {
      key: "GradeCode",
      filterable: true,
      name: "Grade Code" + postValueInfo,
      width: cellWidthMedium,
      editable: true,
      editor: (props) => <TextEditor {...props} />,
      formatter: ({ row }) => CellFormatter(row, "GradeCode", validationData),
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"GradeCode"} data={data} section={"fileimportinfodetail"} />;
      },
    },
    {
      key: "CountCode",
      filterable: true,
      name: "Count Code" + postValueInfo,
      width: cellWidthMedium,
      editable: true,
      editor: (props) => <TextEditor {...props} />,
      formatter: ({ row }) => CellFormatter(row, "CountCode", validationData),
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"CountCode"} data={data} section={"fileimportinfodetail"} />;
      },
    },
    {
      key: "PackCode",
      filterable: true,
      name: "Pack Code" + postValueInfo,
      width: cellWidthMedium,
      editable: true,
      editor: (props) => <TextEditor {...props} />,
      formatter: ({ row }) => CellFormatter(row, "PackCode", validationData),
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"PackCode"} data={data} section={"fileimportinfodetail"} />;
      },
    },
    {
      key: "MarkCode",
      filterable: true,
      name: "Mark Code" + postValueInfo,
      width: cellWidthMedium,
      editable: true,
      editor: (props) => <TextEditor {...props} />,
      formatter: ({ row }) => CellFormatter(row, "MarkCode", validationData),
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"MarkCode"} data={data} section={"fileimportinfodetail"} />;
      },
    },
    {
      key: "InventoryCode",
      filterable: true,
      name: "Inventory Code" + postValueInfo,
      width: cellWidthMedium,
      editable: true,
      editor: (props) => <TextEditor {...props} />,
      formatter: ({ row }) => CellFormatter(row, "InventoryCode", validationData),
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"InventoryCode"} data={data} section={"fileimportinfodetail"} />;
      },
    },
    {
      key: "Weight",
      filterable: true,
      name: "Weight" + postValueInfo,
      width: cellWidthMedium,
      editable: true,
      editor: (props) => <TextEditor {...props} />,
      formatter: ({ row }) => CellFormatter(row, "Weight", validationData),
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Weight"} data={data} section={"fileimportinfodetail"} />;
      },
      summaryFormatter: SummaryNumber2Decimal,
    },
    {
      key: "GrossWeight",
      filterable: true,
      name: "Gross Weight" + postValueInfo,
      width: cellWidthMedium,
      editable: true,
      editor: (props) => <TextEditor {...props} />,
      formatter: ({ row }) => CellFormatter(row, "GrossWeight", validationData),
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"GrossWeight"} data={data} section={"fileimportinfodetail"} />;
      },
      summaryFormatter: SummaryNumber2Decimal,
    },
    {
      key: "TargetRegion",
      filterable: true,
      name: "Target Region" + postValueInfo,
      width: cellWidthMedium,
      editable: true,
      editor: (props) => <TextEditor {...props} />,
      formatter: ({ row }) => CellFormatter(row, "TargetRegion", validationData),
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"TargetRegion"} data={data} section={"fileimportinfodetail"} />;
      },
    },
    {
      key: "TargetMarket",
      filterable: true,
      name: "Target Market" + postValueInfo,
      width: cellWidthMedium,
      editable: true,
      editor: (props) => <TextEditor {...props} />,
      formatter: ({ row }) => CellFormatter(row, "TargetMarket", validationData),
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"TargetMarket"} data={data} section={"fileimportinfodetail"} />;
      },
    },

    {
      key: "TargetCountry",
      filterable: true,
      name: "Target Country" + postValueInfo,
      width: cellWidthMedium,
      editable: true,
      editor: (props) => <TextEditor {...props} />,
      formatter: ({ row }) => CellFormatter(row, "TargetCountry", validationData),
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"TargetCountry"} data={data} section={"fileimportinfodetail"} />;
      },
    },
    {
      key: "InspectionPoint",
      filterable: true,
      name: "Inspection Point" + postValueInfo,
      width: cellWidthMedium,
      editable: true,
      editor: (props) => <TextEditor {...props} />,
      formatter: ({ row }) => CellFormatter(row, "InspectionPoint", validationData),
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"InspectionPoint"} data={data} section={"fileimportinfodetail"} />;
      },
    },
    {
      key: "InspectionDate",
      filterable: true,
      name: "Inspection Date" + postValueInfo,
      width: cellWidthMedium,
      editor: DatePicker,
      formatter: ({ row }) => DateFormatter(row.InspectionDate),
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"InspectionDate"} data={data} section={"fileimportinfodetail"} />;
      },
    },
    {
      key: "InspectorName",
      filterable: true,
      name: "Inspector Name" + postValueInfo,
      width: cellWidthMedium,
      editable: true,
      editor: (props) => <TextEditor {...props} />,
      formatter: ({ row }) => CellFormatter(row, "InspectorName", validationData),
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"InspectorName"} data={data} section={"fileimportinfodetail"} />;
      },
    },
    {
      key: "SequenceNumber",
      filterable: true,
      name: "Sequence Number" + postValueInfo,
      width: cellWidthMedium,
      editable: true,
      editor: (props) => <TextEditor {...props} />,
      formatter: ({ row }) => CellFormatter(row, "SequenceNumber", validationData),
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"SequenceNumber"} data={data} section={"fileimportinfodetail"} />;
      },
    },
    {
      key: "PHC",
      filterable: true,
      name: "PHC" + postValueInfo,
      width: cellWidthMedium,
      editable: true,
      editor: (props) => <TextEditor {...props} />,
      formatter: ({ row }) => CellFormatter(row, "PHC", validationData),
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"PHC"} data={data} section={"fileimportinfodetail"} />;
      },
    },
    {
      key: "ProducerName",
      filterable: true,
      name: "Producer Name" + postValueInfo,
      width: cellWidthLarge,
      editable: false,
      formatter: ({ row }) => CellFormatter(row, "ProducerName", validationData),
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"ProducerName"} data={data} section={"fileimportinfodetail"} />;
      },
    },
    {
      key: "DocumentType",
      filterable: true,
      name: "Document Type" + postValueInfo,
      width: cellWidthMedium,
      editable: true,
      editor: (props) => <TextEditor {...props} />,
      formatter: ({ row }) => CellFormatter(row, "DocumentType", validationData),
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"DocumentType"} data={data} section={"fileimportinfodetail"} />;
      },
    },
    {
      key: "WeighingLocation",
      filterable: true,
      name: "Weighing Location" + postValueInfo,
      width: cellWidthMedium,
      editable: true,
      editor: (props) => <TextEditor {...props} />,
      formatter: ({ row }) => CellFormatter(row, "WeighingLocation", validationData),
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"WeighingLocation"} data={data} section={"fileimportinfodetail"} />;
      },
    },
    {
      key: "WeighingDateTime",
      filterable: true,
      name: "Weighing Date Time" + postValueInfo,
      width: cellWidthMedium,
      editor: DatePicker,
      formatter: ({ row }) => DateFormatter(row.WeighingDateTime, true),
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"WeighingDateTime"} data={data} section={"fileimportinfodetail"} />;
      },
    },
    {
      key: "PhytoVerification",
      filterable: true,
      name: "Phyto Verification" + postValueInfo,
      width: cellWidthMedium,
      editable: true,
      editor: (props) => <TextEditor {...props} />,
      formatter: ({ row }) => CellFormatter(row, "PhytoVerification", validationData),
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"PhytoVerification"} data={data} section={"fileimportinfodetail"} />;
      },
    },
    {
      key: "PTIntakesFO",
      filterable: true,
      name: "PTIntakesFO" + postValueInfo,
      width: cellWidthMedium,
      editable: true,
      editor: (props) => <TextEditor {...props} />,
      formatter: ({ row }) => CellFormatter(row, "PTIntakesFO", validationData),
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"PTIntakesFO"} data={data} section={"fileimportinfodetail"} />;
      },
    },
    {
      key: "CustomerID",
      filterable: true,
      name: "Customer ID" + postValueInfo,
      width: cellWidthMedium,
      editable: true,
      editor: (props) => <TextEditor {...props} />,
      formatter: ({ row }) => CellFormatter(row, "CustomerID", validationData),
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"CustomerID"} data={data} section={"fileimportinfodetail"} />;
      },
    },
    {
      key: "Date",
      filterable: true,
      name: "Date" + postValueInfo,
      width: cellWidthMedium,
      editor: DatePicker,
      formatter: ({ row }) => DateFormatter(row.Date),
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Date"} data={data} section={"fileimportinfodetail"} />;
      },
    },
    {
      key: "Account",
      filterable: true,
      name: "Account" + postValueInfo,
      width: cellWidthMedium,
      editable: true,
      editor: (props) => <TextEditor {...props} />,
      formatter: ({ row }) => CellFormatter(row, "Account", validationData),
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Account"} data={data} section={"fileimportinfodetail"} />;
      },
    },
    {
      key: "Channel",
      filterable: true,
      name: "Channel" + postValueInfo,
      width: cellWidthMedium,
      editable: true,
      editor: (props) => <TextEditor {...props} />,
      formatter: ({ row }) => CellFormatter(row, "Channel", validationData),
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Channel"} data={data} section={"fileimportinfodetail"} />;
      },
    },
    {
      key: "Season",
      filterable: true,
      name: "Season" + postValueInfo,
      width: cellWidthMedium,
      editable: true,
      editor: (props) => <TextEditor {...props} />,
      formatter: ({ row }) => CellFormatter(row, "Season", validationData),
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Season"} data={data} section={"fileimportinfodetail"} />;
      },
    },
    {
      key: "RejectRep",
      filterable: true,
      name: "RejectRep" + postValueInfo,
      width: cellWidthMedium,
      editable: true,
      editor: (props) => <TextEditor {...props} />,
      formatter: ({ row }) => CellFormatter(row, "RejectRep", validationData),
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"RejectRep"} data={data} section={"fileimportinfodetail"} />;
      },
    },
    {
      key: "RejectReason",
      filterable: true,
      name: "Reject Reason" + postValueInfo,
      width: cellWidthMedium,
      editable: true,
      editor: (props) => <TextEditor {...props} />,
      formatter: ({ row }) => CellFormatter(row, "RejectReason", validationData),
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"RejectReason"} data={data} section={"fileimportinfodetail"} />;
      },
    },
    {
      key: "Country",
      filterable: true,
      name: "Country" + postValueInfo,
      width: cellWidthMedium,
      editable: true,
      editor: (props) => <TextEditor {...props} />,
      formatter: ({ row }) => CellFormatter(row, "Country", validationData),
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Country"} data={data} section={"fileimportinfodetail"} />;
      },
    },
    {
      key: "ActVariety",
      filterable: true,
      name: "ActVariety" + postValueInfo,
      width: cellWidthMedium,
      editable: true,
      editor: (props) => <TextEditor {...props} />,
      formatter: ({ row }) => CellFormatter(row, "ActVariety", validationData),
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"ActVariety"} data={data} section={"fileimportinfodetail"} />;
      },
    },
    {
      key: "ActGrad",
      filterable: true,
      name: "ActGrad" + postValueInfo,
      width: cellWidthMedium,
      editable: true,
      editor: (props) => <TextEditor {...props} />,
      formatter: ({ row }) => CellFormatter(row, "ActGrad", validationData),
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"ActGrad"} data={data} section={"fileimportinfodetail"} />;
      },
    },
    {
      key: "PickingRef",
      filterable: true,
      name: "Picking Ref" + postValueInfo,
      width: cellWidthMedium,
      editable: true,
      editor: (props) => <TextEditor {...props} />,
      formatter: ({ row }) => CellFormatter(row, "PickingRef", validationData),
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"PickingRef"} data={data} section={"fileimportinfodetail"} />;
      },
    },
    {
      key: "CartonsRejected",
      filterable: true,
      name: "Cartons Rejected" + postValueInfo,
      width: cellWidthMedium,
      editable: true,
      editor: (props) => <TextEditor {...props} />,
      formatter: ({ row }) => CellFormatter(row, "CartonsRejected", validationData),
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"CartonsRejected"} data={data} section={"fileimportinfodetail"} />;
      },
    },
    {
      key: "CartonsEvaluated",
      filterable: true,
      name: "Cartons Evaluated" + postValueInfo,
      width: cellWidthMedium,
      editable: true,
      editor: (props) => <TextEditor {...props} />,
      formatter: ({ row }) => CellFormatter(row, "CartonsEvaluated", validationData),
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"CartonsEvaluated"} data={data} section={"fileimportinfodetail"} />;
      },
    },
    {
      key: "PaletteSize",
      filterable: true,
      name: "Palette Size" + postValueInfo,
      width: cellWidthMedium,
      editable: true,
      editor: (props) => <TextEditor {...props} />,
      formatter: ({ row }) => CellFormatter(row, "PaletteSize", validationData),
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"PaletteSize"} data={data} section={"fileimportinfodetail"} />;
      },
    },
    {
      key: "IntakeType",
      filterable: true,
      name: "Intake Type" + postValueInfo,
      width: cellWidthMedium,
      editable: true,
      editor: (props) => <TextEditor {...props} />,
      formatter: ({ row }) => CellFormatter(row, "IntakeType", validationData),
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"IntakeType"} data={data} section={"fileimportinfodetail"} />;
      },
    },
    {
      key: "CoolerID",
      filterable: true,
      name: "Cooler ID" + postValueInfo,
      width: cellWidthMedium,
      editable: true,
      editor: (props) => <TextEditor {...props} />,
      formatter: ({ row }) => CellFormatter(row, "CoolerID", validationData),
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"CoolerID"} data={data} section={"fileimportinfodetail"} />;
      },
    },
    {
      key: "TimeEntered",
      filterable: true,
      name: "Time Entered" + postValueInfo,
      width: cellWidthMedium,
      formatter: ({ row }) => CellFormatter(row, "TimeEntered", validationData),
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"TimeEntered"} data={data} section={"fileimportinfodetail"} />;
      },
    },
    // {
    //   key: "PalTrak",
    //   filterable: true,
    //   name: "PalTrak" + postValueInfo,
    //   width: cellWidthMedium,
    //   filterRenderer: (e) => {
    //     return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"PalTrak"} data={data} section={"fileimportinfodetail"} />;
    //   },
    // },
    {
      key: "Processing",
      filterable: true,
      name: "Processing" + postValueInfo,
      width: cellWidthSmall,
      editable: true,
      editor: (props) => <TextEditor {...props} />,
      formatter: ({ row }) => CellFormatter(row, "Processing", validationData),
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Processing"} data={data} section={"fileimportinfodetail"} />;
      },
    },
    {
      key: "IntakeUser",
      filterable: true,
      name: "Intake User" + postValueInfo,
      width: cellWidthMedium,
      editable: true,
      editor: (props) => <TextEditor {...props} />,
      formatter: ({ row }) => CellFormatter(row, "IntakeUser", validationData),
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"IntakeUser"} data={data} section={"fileimportinfodetail"} />;
      },
    },
    {
      key: "Inspection",
      filterable: true,
      name: "Inspection" + postValueInfo,
      width: cellWidthSmall,
      editable: true,
      editor: (props) => <TextEditor {...props} />,
      formatter: ({ row }) => CellFormatter(row, "Inspection", validationData),
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Inspection"} data={data} section={"fileimportinfodetail"} />;
      },
    },
    {
      key: "Accepted",
      filterable: true,
      name: "Accepted" + postValueInfo,
      width: cellWidthSmall,
      editable: true,
      editor: (props) => <TextEditor {...props} />,
      formatter: ({ row }) => CellFormatter(row, "Accepted", validationData),
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Accepted"} data={data} section={"fileimportinfodetail"} />;
      },
    },
    {
      key: "Imported",
      filterable: true,
      name: "Imported" + postValueInfo,
      width: cellWidthSmall,
      editable: true,
      editor: (props) => <TextEditor {...props} />,
      formatter: ({ row }) => CellFormatter(row, "Imported", validationData),
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Imported"} data={data} section={"fileimportinfodetail"} />;
      },
    },
    {
      key: "RegNo",
      filterable: true,
      name: "Ref/Reg No" + postValueInfo,
      width: cellWidthMedium,
      editable: true,
      editor: (props) => <TextEditor {...props} />,
      formatter: ({ row }) => CellFormatter(row, "RegNo", validationData),
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"RegNo"} data={data} section={"fileimportinfodetail"} />;
      },
    },
    {
      key: "Carrier",
      filterable: true,
      name: "Carrier" + postValueInfo,
      width: cellWidthSmall,
      editable: true,
      editor: (props) => <TextEditor {...props} />,
      formatter: ({ row }) => CellFormatter(row, "Carrier", validationData),
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Carrier"} data={data} section={"fileimportinfodetail"} />;
      },
    },
    {
      key: "Organization",
      filterable: true,
      name: "Organization" + postValueInfo,
      width: cellWidthMedium,
      editable: true,
      editor: (props) => <TextEditor {...props} />,
      formatter: ({ row }) => CellFormatter(row, "Organization", validationData),
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Organization"} data={data} section={"fileimportinfodetail"} />;
      },
    },
    {
      key: "Transport",
      filterable: true,
      name: "Transport" + postValueInfo,
      width: cellWidthSmall,
      editable: true,
      editor: (props) => <TextEditor {...props} />,
      formatter: ({ row }) => CellFormatter(row, "Transport", validationData),
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Transport"} data={data} section={"fileimportinfodetail"} />;
      },
    },
    {
      key: "LoadType",
      filterable: true,
      name: "Load Type" + postValueInfo,
      width: cellWidthSmall,
      editable: true,
      editor: (props) => <TextEditor {...props} />,
      formatter: ({ row }) => CellFormatter(row, "LoadType", validationData),
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"LoadType"} data={data} section={"fileimportinfodetail"} />;
      },
    },
    {
      key: "PalletBaseType",
      filterable: true,
      name: "Pallet Base Type" + postValueInfo,
      width: cellWidthMedium,
      editable: true,
      editor: (props) => <TextEditor {...props} />,
      formatter: ({ row }) => CellFormatter(row, "PalletBaseType", validationData),
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"PalletBaseType"} data={data} section={"fileimportinfodetail"} />;
      },
    },
    {
      key: "OrderNo",
      filterable: true,
      name: "Order No" + postValueInfo,
      width: cellWidthMedium,
      editable: true,
      editor: (props) => <TextEditor {...props} />,
      formatter: ({ row }) => CellFormatter(row, "OrderNo", validationData),
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"OrderNo"} data={data} section={"fileimportinfodetail"} />;
      },
    },
    {
      key: "LocationCode",
      filterable: true,
      name: "Location Code" + postValueInfo,
      width: cellWidthMedium,
      editable: true,
      editor: (props) => <TextEditor {...props} />,
      formatter: ({ row }) => CellFormatter(row, "LocationCode", validationData),
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"LocationCode"} data={data} section={"fileimportinfodetail"} />;
      },
    },
    {
      key: "Destination",
      filterable: true,
      name: "Destination" + postValueInfo,
      width: cellWidthMedium,
      editable: true,
      editor: (props) => <TextEditor {...props} />,
      formatter: ({ row }) => CellFormatter(row, "Destination", validationData),
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Destination"} data={data} section={"fileimportinfodetail"} />;
      },
    },
    {
      key: "ShippingNo",
      filterable: true,
      name: "Shipping No" + postValueInfo,
      width: cellWidthMedium,
      editable: true,
      editor: (props) => <TextEditor {...props} />,
      formatter: ({ row }) => CellFormatter(row, "ShippingNo", validationData),
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"ShippingNo"} data={data} section={"fileimportinfodetail"} />;
      },
    },
    {
      key: "Vessel",
      filterable: true,
      name: "Vessel" + postValueInfo,
      width: cellWidthMedium,
      editable: true,
      editor: (props) => <TextEditor {...props} />,
      formatter: ({ row }) => CellFormatter(row, "Vessel", validationData),
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Vessel"} data={data} section={"fileimportinfodetail"} />;
      },
    },
    {
      key: "ContainerNo",
      filterable: true,
      name: "Container No" + postValueInfo,
      width: cellWidthMedium,
      editable: true,
      editor: (props) => <TextEditor {...props} />,
      formatter: ({ row }) => CellFormatter(row, "ContainerNo", validationData),
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"ContainerNo"} data={data} section={"fileimportinfodetail"} />;
      },
    },
    {
      key: "SealNumber",
      filterable: true,
      name: "Seal Number" + postValueInfo,
      width: cellWidthMedium,
      editable: true,
      editor: (props) => <TextEditor {...props} />,
      formatter: ({ row }) => CellFormatter(row, "SealNumber", validationData),
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"SealNumber"} data={data} section={"fileimportinfodetail"} />;
      },
    },
    {
      key: "ConsecNo",
      filterable: true,
      name: "Consec No" + postValueInfo,
      width: cellWidthMedium,
      editable: true,
      editor: (props) => <TextEditor {...props} />,
      formatter: ({ row }) => CellFormatter(row, "ConsecNo", validationData),
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"ConsecNo"} data={data} section={"fileimportinfodetail"} />;
      },
    },
    {
      key: "Temperature",
      filterable: true,
      name: "Temperature" + postValueInfo,
      width: cellWidthMedium,
      editable: true,
      editor: (props) => <TextEditor {...props} />,
      formatter: ({ row }) => CellFormatter(row, "Temperature", validationData),
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Temperature"} data={data} section={"fileimportinfodetail"} />;
      },
    },
    {
      key: "DestinationType",
      filterable: true,
      name: "Destination Type" + postValueInfo,
      width: cellWidthMedium,
      editable: true,
      editor: (props) => <TextEditor {...props} />,
      formatter: ({ row }) => CellFormatter(row, "DestinationType", validationData),
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"DestinationType"} data={data} section={"fileimportinfodetail"} />;
      },
    },
    {
      key: "FileType",
      filterable: true,
      name: "File Type" + postValueInfo,
      width: cellWidthMedium,
      editable: true,
      editor: (props) => <TextEditor {...props} />,
      formatter: ({ row }) => CellFormatter(row, "FileType", validationData),
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"FileType"} data={data} section={"fileimportinfodetail"} />;
      },
    },
    {
      key: "Time",
      filterable: true,
      name: "Time" + postValueInfo,
      width: cellWidthMedium,
      editable: true,
      editor: (props) => <TextEditor {...props} />,
      formatter: ({ row }) => CellFormatter(row, "Time", validationData),
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Time"} data={data} section={"fileimportinfodetail"} />;
      },
    },
    {
      key: "InspectionTime",
      filterable: true,
      name: "Inspection Time" + postValueInfo,
      width: cellWidthMedium,
      editable: true,
      editor: (props) => <TextEditor {...props} />,
      formatter: ({ row }) => CellFormatter(row, "InspectionTime", validationData),
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"InspectionTime"} data={data} section={"fileimportinfodetail"} />;
      },
    },
    {
      key: "ColdDate",
      filterable: true,
      name: "Cold Date" + postValueInfo,
      width: cellWidthMedium,
      editor: DatePicker,
      formatter: ({ row }) => DateFormatter(row.ColdDate),
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"ColdDate"} data={data} section={"fileimportinfodetail"} />;
      },
    },
    {
      key: "DispatchDocRef",
      filterable: true,
      name: "Dispatch Doc Ref" + postValueInfo,
      width: cellWidthMedium,
      editable: true,
      editor: (props) => <TextEditor {...props} />,
      formatter: ({ row }) => CellFormatter(row, "DispatchDocRef", validationData),
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"DispatchDocRef"} data={data} section={"fileimportinfodetail"} />;
      },
    },
    {
      key: "Receiver",
      filterable: true,
      name: "Receiver" + postValueInfo,
      width: cellWidthMedium,
      editable: true,
      editor: (props) => <TextEditor {...props} />,
      formatter: ({ row }) => CellFormatter(row, "Receiver", validationData),
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Receiver"} data={data} section={"fileimportinfodetail"} />;
      },
    },
    {
      key: "Sender",
      filterable: true,
      name: "Sender" + postValueInfo,
      width: cellWidthMedium,
      editable: true,
      editor: (props) => <TextEditor {...props} />,
      formatter: ({ row }) => CellFormatter(row, "Sender", validationData),
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Sender"} data={data} section={"fileimportinfodetail"} />;
      },
    },
    {
      key: "Remarks",
      filterable: true,
      name: "Remarks" + postValueInfo,
      width: cellWidthMedium,
      editable: true,
      editor: (props) => <TextEditor {...props} />,
      formatter: ({ row }) => CellFormatter(row, "Remarks", validationData),
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Remarks"} data={data} section={"fileimportinfodetail"} />;
      },
    },
    {
      key: "OrigIntakeDate",
      filterable: true,
      name: "Orig Intake Date" + postValueInfo,
      width: cellWidthMedium,
      editor: DatePicker,
      formatter: ({ row }) => DateFormatter(row.OrigIntakeDate),
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"OrigIntakeDate"} data={data} section={"fileimportinfodetail"} />;
      },
    },
    {
      key: "OrigIntakeWaybill",
      filterable: true,
      name: "Orig Intake Waybill" + postValueInfo,
      width: cellWidthMedium,
      editable: true,
      editor: (props) => <TextEditor {...props} />,
      formatter: ({ row }) => CellFormatter(row, "OrigIntakeWaybill", validationData),
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"OrigIntakeWaybill"} data={data} section={"fileimportinfodetail"} />;
      },
    },
    {
      key: "DateEntered",
      filterable: true,
      name: "Date Entered" + postValueInfo,
      width: cellWidthMedium,
      editor: DatePicker,
      formatter: ({ row }) => DateFormatter(row.DateEntered),
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"DateEntered"} data={data} section={"fileimportinfodetail"} />;
      },
    },
    {
      key: "DateDispatched",
      filterable: true,
      name: "Date Dispatched" + postValueInfo,
      width: cellWidthMedium,
      editor: DatePicker,
      formatter: ({ row }) => DateFormatter(row.DateDispatched),
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"DateDispatched"} data={data} section={"fileimportinfodetail"} />;
      },
    },
    {
      key: "PalletID",
      filterable: true,
      name: "Pallet ID" + postValueInfo,
      width: cellWidthMedium,
      editable: true,
      editor: (props) => <TextEditor {...props} />,
      formatter: ({ row }) => CellFormatter(row, "PalletID", validationData),
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"PalletID"} data={data} section={"fileimportinfodetail"} />;
      },
    },
    {
      key: "IntakeID",
      filterable: true,
      name: "Intake ID" + postValueInfo,
      width: cellWidthMedium,
      editable: true,
      editor: (props) => <TextEditor {...props} />,
      formatter: ({ row }) => CellFormatter(row, "IntakeID", validationData),
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"IntakeID"} data={data} section={"fileimportinfodetail"} />;
      },
    },
    {
      key: "DispatchID",
      filterable: true,
      name: "Dispatch ID" + postValueInfo,
      width: cellWidthMedium,
      editable: true,
      editor: (props) => <TextEditor {...props} />,
      formatter: ({ row }) => CellFormatter(row, "DispatchID", validationData),
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"DispatchID"} data={data} section={"fileimportinfodetail"} />;
      },
    },
    {
      key: "TimeDispatched",
      filterable: true,
      name: "Time Dispatched" + postValueInfo,
      width: cellWidthMedium,
      editable: true,
      editor: (props) => <TextEditor {...props} />,
      formatter: ({ row }) => CellFormatter(row, "TimeDispatched", validationData),
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"TimeDispatched"} data={data} section={"fileimportinfodetail"} />;
      },
    },
    // {
    //   key: "Selected",
    //   filterable: true,
    //   name: "Selected" + postValueInfo,
    //   width: cellWidthMedium,
    //   filterRenderer: (e) => {
    //     return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Selected"} data={data} section={"fileimportinfodetail"} />;
    //   },
    // },
    // {
    //   key: "Price",
    //   filterable: true,
    //   name: "Price" + postValueInfo,
    //   width: cellWidthMedium,
    //   filterRenderer: (e) => {
    //     return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Price"} data={data} section={"fileimportinfodetail"} />;
    //   },
    // },
    // {
    //   key: "Allocated",
    //   filterable: true,
    //   name: "Allocated" + postValueInfo,
    //   width: cellWidthMedium,
    //   filterRenderer: (e) => {
    //     return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Allocated"} data={data} section={"fileimportinfodetail"} />;
    //   },
    // },
    // {
    //   key: "Allocation",
    //   filterable: true,
    //   name: "Allocation" + postValueInfo,
    //   width: cellWidthMedium,
    //   filterRenderer: (e) => {
    //     return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Allocation"} data={data} section={"fileimportinfodetail"} />;
    //   },
    // },
    {
      key: "RefNo",
      filterable: true,
      name: "Ref No" + postValueInfo,
      width: cellWidthMedium,
      editable: true,
      editor: (props) => <TextEditor {...props} />,
      formatter: ({ row }) => CellFormatter(row, "RefNo", validationData),
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"RefNo"} data={data} section={"fileimportinfodetail"} />;
      },
    },
    {
      key: "IntakeWaybill",
      filterable: true,
      name: "Intake Waybill" + postValueInfo,
      width: cellWidthMedium,
      editable: true,
      editor: (props) => <TextEditor {...props} />,
      formatter: ({ row }) => CellFormatter(row, "IntakeWaybill", validationData),
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"IntakeWaybill"} data={data} section={"fileimportinfodetail"} />;
      },
    },
    {
      key: "IntakePalletID",
      filterable: true,
      name: "Intake Pallet ID" + postValueInfo,
      width: cellWidthMedium,
      editable: true,
      editor: (props) => <TextEditor {...props} />,
      formatter: ({ row }) => CellFormatter(row, "IntakePalletID", validationData),
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"IntakePalletID"} data={data} section={"fileimportinfodetail"} />;
      },
    },
    // {
    //   key: "Bay",
    //   filterable: true,
    //   name: "Bay" + postValueInfo,
    //   width: cellWidthMedium,
    //   filterRenderer: (e) => {
    //     return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Bay"} data={data} section={"fileimportinfodetail"} />;
    //   },
    // },
    // {
    //   key: "LastInvoice",
    //   filterable: true,
    //   name: "Last Invoice" + postValueInfo,
    //   width: cellWidthMedium,
    //   filterRenderer: (e) => {
    //     return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"LastInvoice"} data={data} section={"fileimportinfodetail"} />;
    //   },
    // },
    // {
    //   key: "LastInvoiceDate",
    //   filterable: true,
    //   name: "Last Invoice Date" + postValueInfo,
    //   width: cellWidthMedium,
    //   formatter: ({ row }) => DateFormatter(row.LastInvoiceDate),
    //   filterRenderer: (e) => {
    //     return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"LastInvoiceDate"} data={data} section={"fileimportinfodetail"} />;
    //   },
    // },
    // {
    //   key: "Invoicing",
    //   filterable: true,
    //   name: "Invoicing" + postValueInfo,
    //   width: cellWidthMedium,
    //   filterRenderer: (e) => {
    //     return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Invoicing"} data={data} section={"fileimportinfodetail"} />;
    //   },
    // },
    // {
    //   key: "Exported",
    //   filterable: true,
    //   name: "Exported" + postValueInfo,
    //   width: cellWidthMedium,
    //   filterRenderer: (e) => {
    //     return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Exported"} data={data} section={"fileimportinfodetail"} />;
    //   },
    // },
    // {
    //   key: "Rebuilt",
    //   filterable: true,
    //   name: "Rebuilt" + postValueInfo,
    //   width: cellWidthMedium,
    //   filterRenderer: (e) => {
    //     return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Rebuilt"} data={data} section={"fileimportinfodetail"} />;
    //   },
    // },
    // {
    //   key: "LastEditDate",
    //   filterable: true,
    //   name: "Last Edit Date" + postValueInfo,
    //   width: cellWidthMedium,
    //   formatter: ({ row }) => DateFormatter(row.LastEditDate),
    //   filterRenderer: (e) => {
    //     return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"LastEditDate"} data={data} section={"fileimportinfodetail"} />;
    //   },
    // },
    // {
    //   key: "PalletMovement",
    //   filterable: true,
    //   name: "Pallet Movement" + postValueInfo,
    //   width: cellWidthMedium,
    //   filterRenderer: (e) => {
    //     return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"PalletMovement"} data={data} section={"fileimportinfodetail"} />;
    //   },
    // },
    // {
    //   key: "AllocatedStock",
    //   filterable: true,
    //   name: "Allocated Stock" + postValueInfo,
    //   width: cellWidthMedium,
    //   filterRenderer: (e) => {
    //     return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"AllocatedStock"} data={data} section={"fileimportinfodetail"} />;
    //   },
    // },
    // {
    //   key: "Rejected",
    //   filterable: true,
    //   name: "Rejected" + postValueInfo,
    //   width: cellWidthMedium,
    //   filterRenderer: (e) => {
    //     return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Rejected"} data={data} section={"fileimportinfodetail"} />;
    //   },
    // },
    // {
    //   key: "Transfer",
    //   filterable: true,
    //   name: "Transfer" + postValueInfo,
    //   width: cellWidthMedium,
    //   filterRenderer: (e) => {
    //     return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Transfer"} data={data} section={"fileimportinfodetail"} />;
    //   },
    // },
    // {
    //   key: "MiniPallet",
    //   filterable: true,
    //   name: "Mini Pallet" + postValueInfo,
    //   width: cellWidthMedium,
    //   filterRenderer: (e) => {
    //     return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"MiniPallet"} data={data} section={"fileimportinfodetail"} />;
    //   },
    // },
    // {
    //   key: "SV",
    //   filterable: true,
    //   name: "SV" + postValueInfo,
    //   width: cellWidthMedium,
    //   filterRenderer: (e) => {
    //     return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"SV"} data={data} section={"fileimportinfodetail"} />;
    //   },
    // },
    // {
    //   key: "ProductGroup",
    //   filterable: true,
    //   name: "Product Group" + postValueInfo,
    //   width: cellWidthMedium,
    //   filterRenderer: (e) => {
    //     return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"ProductGroup"} data={data} section={"fileimportinfodetail"} />;
    //   },
    // },
    // {
    //   key: "ProductChar",
    //   filterable: true,
    //   name: "Product Char" + postValueInfo,
    //   width: cellWidthMedium,
    //   filterRenderer: (e) => {
    //     return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"ProductChar"} data={data} section={"fileimportinfodetail"} />;
    //   },
    // },
    // {
    //   key: "MixedInd",
    //   filterable: true,
    //   name: "Mixed Ind" + postValueInfo,
    //   width: cellWidthMedium,
    //   filterRenderer: (e) => {
    //     return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"MixedInd"} data={data} section={"fileimportinfodetail"} />;
    //   },
    // },
    // {
    //   key: "StockPool",
    //   filterable: true,
    //   name: "Stock Pool" + postValueInfo,
    //   width: cellWidthMedium,
    //   filterRenderer: (e) => {
    //     return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"StockPool"} data={data} section={"fileimportinfodetail"} />;
    //   },
    // },
    {
      key: "SSCC",
      filterable: true,
      name: "SSCC" + postValueInfo,
      width: cellWidthLarge,
      editable: true,
      editor: (props) => <TextEditor {...props} />,
      formatter: ({ row }) => CellFormatter(row, "SSCC", validationData),
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"SSCC"} data={data} section={"fileimportinfodetail"} />;
      },
    },
    // {
    //   key: "SellBy",
    //   filterable: true,
    //   name: "Sell By" + postValueInfo,
    //   width: cellWidthMedium,
    //   filterRenderer: (e) => {
    //     return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"SellBy"} data={data} section={"fileimportinfodetail"} />;
    //   },
    // },
    // {
    //   key: "StockValue",
    //   filterable: true,
    //   name: "Stock Value" + postValueInfo,
    //   width: cellWidthMedium,
    //   filterRenderer: (e) => {
    //     return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"StockValue"} data={data} section={"fileimportinfodetail"} />;
    //   },
    // },
    {
      key: "OldBarcode",
      filterable: true,
      name: "Old Barcode" + postValueInfo,
      width: cellWidthMedium,
      editable: true,
      editor: (props) => <TextEditor {...props} />,
      formatter: ({ row }) => CellFormatter(row, "OldBarcode", validationData),
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"OldBarcode"} data={data} section={"fileimportinfodetail"} />;
      },
    },
    {
      key: "TraderID",
      filterable: true,
      name: "Trader ID" + postValueInfo,
      width: cellWidthMedium,
      editable: true,
      editor: (props) => <TextEditor {...props} />,
      formatter: ({ row }) => CellFormatter(row, "TraderID", validationData),
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"TraderID"} data={data} section={"fileimportinfodetail"} />;
      },
    },
    {
      key: "ConsigneeID",
      filterable: true,
      name: "Consignee ID" + postValueInfo,
      width: cellWidthMedium,
      editable: true,
      editor: (props) => <TextEditor {...props} />,
      formatter: ({ row }) => CellFormatter(row, "ConsigneeID", validationData),
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"ConsigneeID"} data={data} section={"fileimportinfodetail"} />;
      },
    },
    {
      key: "InspectionReport",
      filterable: true,
      name: "Inspection Report" + postValueInfo,
      width: cellWidthMedium,
      editable: true,
      editor: (props) => <TextEditor {...props} />,
      formatter: ({ row }) => CellFormatter(row, "InspectionReport", validationData),
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"InspectionReport"} data={data} section={"fileimportinfodetail"} />;
      },
    },
    {
      key: "UserName",
      filterable: true,
      name: "UserName" + postValueInfo,
      width: cellWidthMedium,
      editable: true,
      editor: (props) => <TextEditor {...props} />,
      formatter: ({ row }) => CellFormatter(row, "UserName", validationData),
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"UserName"} data={data} section={"fileimportinfodetail"} />;
      },
    },
    {
      key: "DispatchCustomerId",
      filterable: true,
      name: "dispatch customer id" + postValueInfo,
      width: cellWidthMedium,
      editable: true,
      editor: (props) => <TextEditor {...props} />,
      formatter: ({ row }) => CellFormatter(row, "DispatchCustomerId", validationData),
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"DispatchCustomerId"} data={data} section={"fileimportinfodetail"} />;
      },
    },
    {
      key: "TempTail",
      filterable: true,
      name: "Temp Tail" + postValueInfo,
      width: cellWidthMedium,
      editable: true,
      editor: (props) => <TextEditor {...props} />,
      formatter: ({ row }) => CellFormatter(row, "TempTail", validationData),
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"TempTail"} data={data} section={"fileimportinfodetail"} />;
      },
    },
    {
      key: "Batch",
      filterable: true,
      name: "Batch" + postValueInfo,
      width: cellWidthMedium,
      editable: true,
      editor: (props) => <TextEditor {...props} />,
      formatter: ({ row }) => CellFormatter(row, "Batch", validationData),
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Batch"} data={data} section={"fileimportinfodetail"} />;
      },
    },
    {
      key: "Orchard",
      filterable: true,
      name: "Orchard" + postValueInfo,
      width: cellWidthMedium,
      editable: true,
      editor: (props) => <TextEditor {...props} />,
      formatter: ({ row }) => CellFormatter(row, "Orchard", validationData),
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Orchard"} data={data} section={"fileimportinfodetail"} />;
      },
    },
    {
      key: "FileName",
      filterable: true,
      name: "FileName" + postValueInfo,
      width: cellWidthMedium,
      editable: true,
      editor: (props) => <TextEditor {...props} />,
      formatter: ({ row }) => CellFormatter(row, "FileName", validationData),
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"FileName"} data={data} section={"fileimportinfodetail"} />;
      },
    },
    {
      key: "RevisionNo",
      filterable: true,
      name: "Revision No" + postValueInfo,
      width: cellWidthMedium,
      editable: true,
      editor: (props) => <TextEditor {...props} />,
      formatter: ({ row }) => CellFormatter(row, "RevisionNo", validationData),
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"RevisionNo"} data={data} section={"fileimportinfodetail"} />;
      },
    },
    {
      key: "InternalBarcode",
      filterable: true,
      name: "Internal Barcode" + postValueInfo,
      width: cellWidthMedium,
      editable: true,
      editor: (props) => <TextEditor {...props} />,
      formatter: ({ row }) => CellFormatter(row, "InternalBarcode", validationData),
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"InternalBarcode"} data={data} section={"fileimportinfodetail"} />;
      },
    },
    {
      key: "TotalPallets",
      filterable: true,
      name: "Total Pallets" + postValueInfo,
      width: cellWidthMedium,
      editable: true,
      editor: (props) => <TextEditor {...props} />,
      formatter: ({ row }) => CellFormatter(row, "TotalPallets", validationData),
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"TotalPallets"} data={data} section={"fileimportinfodetail"} />;
      },
    },
    {
      key: "Week",
      filterable: true,
      name: "Week" + postValueInfo,
      width: cellWidthMedium,
      editable: true,
      editor: (props) => <TextEditor {...props} />,
      formatter: ({ row }) => CellFormatter(row, "Week", validationData),
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Week"} data={data} section={"fileimportinfodetail"} />;
      },
    },
    // {
    //   key: "EstimateNo",
    //   filterable: true,
    //   name: "Estimate No" + postValueInfo,
    //   width: cellWidthMedium,
    //   filterRenderer: (e) => {
    //     return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"EstimateNo"} data={data} section={"fileimportinfodetail"} />;
    //   },
    // },
    {
      key: "PackWeek",
      filterable: true,
      name: "Pack Week" + postValueInfo,
      width: cellWidthMedium,
      editable: true,
      editor: (props) => <TextEditor {...props} />,
      formatter: ({ row }) => CellFormatter(row, "PackWeek", validationData),
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"PackWeek"} data={data} section={"fileimportinfodetail"} />;
      },
    },
    {
      key: "ArriveWeek",
      filterable: true,
      name: "Arrive Week" + postValueInfo,
      width: cellWidthMedium,
      editable: true,
      editor: (props) => <TextEditor {...props} />,
      formatter: ({ row }) => CellFormatter(row, "ArriveWeek", validationData),
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"ArriveWeek"} data={data} section={"fileimportinfodetail"} />;
      },
    },
    {
      key: "SalesWeek",
      filterable: true,
      name: "Sales Week" + postValueInfo,
      width: cellWidthMedium,
      editable: true,
      editor: (props) => <TextEditor {...props} />,
      formatter: ({ row }) => CellFormatter(row, "SalesWeek", validationData),
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"SalesWeek"} data={data} section={"fileimportinfodetail"} />;
      },
    },
    // {
    //   key: "Units",
    //   filterable: true,
    //   name: "Units" + postValueInfo,
    //   width: cellWidthMedium,
    //   filterRenderer: (e) => {
    //     return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Units"} data={data} section={"fileimportinfodetail"} />;
    //   },
    // },
    {
      key: "ETD",
      filterable: true,
      name: "ETD" + postValueInfo,
      width: cellWidthMedium,
      editable: true,
      editor: (props) => <TextEditor {...props} />,
      formatter: ({ row }) => CellFormatter(row, "ETD", validationData),
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"ETD"} data={data} section={"fileimportinfodetail"} />;
      },
    },
    {
      key: "PortOfLoading",
      filterable: true,
      name: "Port of Loading" + postValueInfo,
      width: cellWidthMedium,
      editable: true,
      editor: (props) => <TextEditor {...props} />,
      formatter: ({ row }) => CellFormatter(row, "PortOfLoading", validationData),
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"PortOfLoading"} data={data} section={"fileimportinfodetail"} />;
      },
    },
    {
      key: "PortOfDischarge",
      filterable: true,
      name: "Port of Discharge" + postValueInfo,
      width: cellWidthMedium,
      editable: true,
      editor: (props) => <TextEditor {...props} />,
      formatter: ({ row }) => CellFormatter(row, "PortOfDischarge", validationData),
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"PortOfDischarge"} data={data} section={"fileimportinfodetail"} />;
      },
    },
    {
      key: "QCFlag",
      filterable: true,
      name: "QC Flag" + postValueInfo,
      width: cellWidthMedium,
      editable: true,
      editor: (props) => <TextEditor {...props} />,
      formatter: ({ row }) => CellFormatter(row, "QCFlag", validationData),
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"QCFlag"} data={data} section={"fileimportinfodetail"} />;
      },
    },
    {
      key: "QCUser",
      filterable: true,
      name: "QC User" + postValueInfo,
      width: cellWidthMedium,
      editable: true,
      editor: (props) => <TextEditor {...props} />,
      formatter: ({ row }) => CellFormatter(row, "QCUser", validationData),
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"QCUser"} data={data} section={"fileimportinfodetail"} />;
      },
    },
    {
      key: "QCBlocked",
      filterable: true,
      name: "QC Blocked" + postValueInfo,
      width: cellWidthMedium,
      editable: true,
      editor: (props) => <TextEditor {...props} />,
      formatter: ({ row }) => CellFormatter(row, "QCBlocked", validationData),
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"QCBlocked"} data={data} section={"fileimportinfodetail"} />;
      },
    },
    // {
    //   key: "OrderID",
    //   filterable: true,
    //   name: "Order ID" + postValueInfo,
    //   width: cellWidthMedium,
    //   filterRenderer: (e) => {
    //     return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"OrderID"} data={data} section={"fileimportinfodetail"} />;
    //   },
    // },
    // {
    //   key: "21",
    //   filterable: true,
    //   name: "21" + postValueInfo,
    //   width: cellWidthMedium,
    //   filterRenderer: (e) => {
    //     return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"21"} data={data} section={"fileimportinfodetail"} />;
    //   },
    // },
    // {
    //   key: "22",
    //   filterable: true,
    //   name: "22" + postValueInfo,
    //   width: cellWidthMedium,
    //   filterRenderer: (e) => {
    //     return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"22"} data={data} section={"fileimportinfodetail"} />;
    //   },
    // },
    // {
    //   key: "23",
    //   filterable: true,
    //   name: "23" + postValueInfo,
    //   width: cellWidthMedium,
    //   filterRenderer: (e) => {
    //     return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"23"} data={data} section={"fileimportinfodetail"} />;
    //   },
    // },
    // {
    //   key: "24",
    //   filterable: true,
    //   name: "24" + postValueInfo,
    //   width: cellWidthMedium,
    //   filterRenderer: (e) => {
    //     return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"24"} data={data} section={"fileimportinfodetail"} />;
    //   },
    // },
    // {
    //   key: "25",
    //   filterable: true,
    //   name: "25" + postValueInfo,
    //   width: cellWidthMedium,
    //   filterRenderer: (e) => {
    //     return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"25"} data={data} section={"fileimportinfodetail"} />;
    //   },
    // },
    // {
    //   key: "26",
    //   filterable: true,
    //   name: "26" + postValueInfo,
    //   width: cellWidthMedium,
    //   filterRenderer: (e) => {
    //     return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"26"} data={data} section={"fileimportinfodetail"} />;
    //   },
    // },
    // {
    //   key: "27",
    //   filterable: true,
    //   name: "27" + postValueInfo,
    //   width: cellWidthMedium,
    //   filterRenderer: (e) => {
    //     return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"27"} data={data} section={"fileimportinfodetail"} />;
    //   },
    // },
    // {
    //   key: "28",
    //   filterable: true,
    //   name: "28" + postValueInfo,
    //   width: cellWidthMedium,
    //   filterRenderer: (e) => {
    //     return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"28"} data={data} section={"fileimportinfodetail"} />;
    //   },
    // },
    // {
    //   key: "29",
    //   filterable: true,
    //   name: "29" + postValueInfo,
    //   width: cellWidthMedium,
    //   filterRenderer: (e) => {
    //     return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"29"} data={data} section={"fileimportinfodetail"} />;
    //   },
    // },
    // {
    //   key: "30",
    //   filterable: true,
    //   name: "30" + postValueInfo,
    //   width: cellWidthMedium,
    //   filterRenderer: (e) => {
    //     return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"30"} data={data} section={"fileimportinfodetail"} />;
    //   },
    // },
    // {
    //   key: "BatchNumber",
    //   filterable: true,
    //   name: "Batch Number" + postValueInfo,
    //   width: cellWidthMedium,
    //   filterRenderer: (e) => {
    //     return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"BatchNumber"} data={data} section={"fileimportinfodetail"} />;
    //   },
    // },
    // {
    //   key: "Hatch",
    //   filterable: true,
    //   name: "Hatch" + postValueInfo,
    //   width: cellWidthMedium,
    //   filterRenderer: (e) => {
    //     return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Hatch"} data={data} section={"fileimportinfodetail"} />;
    //   },
    // },
    // {
    //   key: "Deck",
    //   filterable: true,
    //   name: "Deck" + postValueInfo,
    //   width: cellWidthMedium,
    //   filterRenderer: (e) => {
    //     return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Deck"} data={data} section={"fileimportinfodetail"} />;
    //   },
    // },
    // {
    //   key: "PaymentType",
    //   filterable: true,
    //   name: "Payment Type" + postValueInfo,
    //   width: cellWidthMedium,
    //   filterRenderer: (e) => {
    //     return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"PaymentType"} data={data} section={"fileimportinfodetail"} />;
    //   },
    // },
    {
      key: "ProductionID",
      filterable: true,
      name: "Production ID" + postValueInfo,
      width: cellWidthMedium,
      editable: true,
      editor: (props) => <TextEditor {...props} />,
      formatter: ({ row }) => CellFormatter(row, "ProductionID", validationData),
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"ProductionID"} data={data} section={"fileimportinfodetail"} />;
      },
    },
    {
      key: "ConsignmentRef",
      filterable: true,
      name: "Consignment Ref" + postValueInfo,
      width: cellWidthMedium,
      editable: true,
      editor: (props) => <TextEditor {...props} />,
      formatter: ({ row }) => CellFormatter(row, "ConsignmentRef", validationData),
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"ConsignmentRef"} data={data} section={"fileimportinfodetail"} />;
      },
    },
    {
      key: "SubSaleRef",
      filterable: true,
      name: "SubSale Ref" + postValueInfo,
      width: cellWidthMedium,
      editable: true,
      editor: (props) => <TextEditor {...props} />,
      formatter: ({ row }) => CellFormatter(row, "SubSaleRef", validationData),
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"SubSaleRef"} data={data} section={"fileimportinfodetail"} />;
      },
    },
    // {
    //   key: "Currency",
    //   filterable: true,
    //   name: "Currency" + postValueInfo,
    //   width: cellWidthMedium,
    //   filterRenderer: (e) => {
    //     return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Currency"} data={data} section={"fileimportinfodetail"} />;
    //   },
    // },
    // {
    //   key: "Amount",
    //   filterable: true,
    //   name: "Amount" + postValueInfo,
    //   width: cellWidthMedium,
    //   filterRenderer: (e) => {
    //     return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Amount"} data={data} section={"fileimportinfodetail"} />;
    //   },
    // },
    // {
    //   key: "ExchangeRate",
    //   filterable: true,
    //   name: "Exchange Rate" + postValueInfo,
    //   width: cellWidthMedium,
    //   filterRenderer: (e) => {
    //     return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"ExchangeRate"} data={data} section={"fileimportinfodetail"} />;
    //   },
    // },
    // {
    //   key: "RandValue",
    //   filterable: true,
    //   name: "Rand Value" + postValueInfo,
    //   width: cellWidthMedium,
    //   filterRenderer: (e) => {
    //     return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"RandValue"} data={data} section={"fileimportinfodetail"} />;
    //   },
    // },
    // {
    //   key: "Quantity",
    //   filterable: true,
    //   name: "Quantity" + postValueInfo,
    //   width: cellWidthMedium,
    //   filterRenderer: (e) => {
    //     return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Quantity"} data={data} section={"fileimportinfodetail"} />;
    //   },
    // },
    {
      key: "ShipAgent",
      filterable: true,
      name: "Ship Agent" + postValueInfo,
      width: cellWidthMedium,
      editable: true,
      editor: (props) => <TextEditor {...props} />,
      formatter: ({ row }) => CellFormatter(row, "ShipAgent", validationData),
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"ShipAgent"} data={data} section={"fileimportinfodetail"} />;
      },
    },
    {
      key: "OrigLocationCode",
      filterable: true,
      name: "Orig Location Code" + postValueInfo,
      width: cellWidthMedium,
      editable: true,
      editor: (props) => <TextEditor {...props} />,
      formatter: ({ row }) => CellFormatter(row, "OrigLocationCode", validationData),
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"OrigLocationCode"} data={data} section={"fileimportinfodetail"} />;
      },
    },
    // {
    //   key: "Tax",
    //   filterable: true,
    //   name: "Tax" + postValueInfo,
    //   width: cellWidthMedium,
    //   filterRenderer: (e) => {
    //     return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Tax"} data={data} section={"fileimportinfodetail"} />;
    //   },
    // },
    // {
    //   key: "ActivityType",
    //   filterable: true,
    //   name: "Activity Type" + postValueInfo,
    //   width: cellWidthMedium,
    //   filterRenderer: (e) => {
    //     return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"ActivityType"} data={data} section={"fileimportinfodetail"} />;
    //   },
    // },
    {
      key: "ETA",
      filterable: true,
      name: "ETA" + postValueInfo,
      width: cellWidthMedium,
      editable: true,
      editor: (props) => <TextEditor {...props} />,
      formatter: ({ row }) => CellFormatter(row, "ETA", validationData),
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"ETA"} data={data} section={"fileimportinfodetail"} />;
      },
    },
    // {
    //   key: "InvalidBarcode",
    //   filterable: true,
    //   name: "Invalid Barcode" + postValueInfo,
    //   width: cellWidthMedium,
    //   filterRenderer: (e) => {
    //     return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"InvalidBarcode"} data={data} section={"fileimportinfodetail"} />;
    //   },
    // },
    {
      key: "FromLocationCode",
      filterable: true,
      name: "From Location Code" + postValueInfo,
      width: cellWidthMedium,
      editable: true,
      editor: (props) => <TextEditor {...props} />,
      formatter: ({ row }) => CellFormatter(row, "FromLocationCode", validationData),
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"FromLocationCode"} data={data} section={"fileimportinfodetail"} />;
      },
    },
    {
      key: "NewBarcode",
      filterable: true,
      name: "New Barcode" + postValueInfo,
      width: cellWidthMedium,
      editable: true,
      editor: (props) => <TextEditor {...props} />,
      formatter: ({ row }) => CellFormatter(row, "NewBarcode", validationData),
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"NewBarcode"} data={data} section={"fileimportinfodetail"} />;
      },
    },
    // {
    //   key: "ActivityDate",
    //   filterable: true,
    //   name: "Activity Date" + postValueInfo,
    //   width: cellWidthMedium,
    //   formatter: ({ row }) => DateFormatter(row.ActivityDate),
    //   filterRenderer: (e) => {
    //     return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"ActivityDate"} data={data} section={"fileimportinfodetail"} />;
    //   },
    // },
    // {
    //   key: "Verify",
    //   filterable: true,
    //   name: "Verify" + postValueInfo,
    //   width: cellWidthMedium,
    //   filterRenderer: (e) => {
    //     return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Verify"} data={data} section={"fileimportinfodetail"} />;
    //   },
    // },
    // {
    //   key: "ImportDate",
    //   filterable: true,
    //   name: "Import Date" + postValueInfo,
    //   width: cellWidthMedium,
    //   formatter: ({ row }) => DateFormatter(row.ImportDate),
    //   filterRenderer: (e) => {
    //     return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"ImportDate"} data={data} section={"fileimportinfodetail"} />;
    //   },
    // },
    // {
    //   key: "InvoiceNumber",
    //   filterable: true,
    //   name: "Invoice Number" + postValueInfo,
    //   width: cellWidthMedium,
    //   filterRenderer: (e) => {
    //     return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"InvoiceNumber"} data={data} section={"fileimportinfodetail"} />;
    //   },
    // },
    // {
    //   key: "CreditorID",
    //   filterable: true,
    //   name: "Creditor ID" + postValueInfo,
    //   width: cellWidthMedium,
    //   filterRenderer: (e) => {
    //     return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"CreditorID"} data={data} section={"fileimportinfodetail"} />;
    //   },
    // },
    // {
    //   key: "Description",
    //   filterable: true,
    //   name: "Description" + postValueInfo,
    //   width: cellWidthMedium,
    //   filterRenderer: (e) => {
    //     return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Description"} data={data} section={"fileimportinfodetail"} />;
    //   },
    // },
    // {
    //   key: "Total",
    //   filterable: true,
    //   name: "Total" + postValueInfo,
    //   width: cellWidthMedium,
    //   filterRenderer: (e) => {
    //     return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Total"} data={data} section={"fileimportinfodetail"} />;
    //   },
    // },
    // {
    //   key: "SaleID",
    //   filterable: true,
    //   name: "Sale ID" + postValueInfo,
    //   width: cellWidthMedium,
    //   filterRenderer: (e) => {
    //     return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"SaleID"} data={data} section={"fileimportinfodetail"} />;
    //   },
    // },
    // {
    //   key: "CostItem",
    //   filterable: true,
    //   name: "Cost Item" + postValueInfo,
    //   width: cellWidthMedium,
    //   filterRenderer: (e) => {
    //     return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"CostItem"} data={data} section={"fileimportinfodetail"} />;
    //   },
    // },
    // {
    //   key: "RemittanceID",
    //   filterable: true,
    //   name: "Remittance ID" + postValueInfo,
    //   width: cellWidthMedium,
    //   filterRenderer: (e) => {
    //     return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"RemittanceID"} data={data} section={"fileimportinfodetail"} />;
    //   },
    // },
    {
      key: "ClientRef",
      filterable: true,
      name: "Client Ref" + postValueInfo,
      width: cellWidthMedium,
      editable: true,
      editor: (props) => <TextEditor {...props} />,
      formatter: ({ row }) => CellFormatter(row, "ClientRef", validationData),
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"ClientRef"} data={data} section={"fileimportinfodetail"} />;
      },
    },
    // {
    //   key: "CTONo",
    //   filterable: true,
    //   name: "CTO No" + postValueInfo,
    //   width: cellWidthMedium,
    //   filterRenderer: (e) => {
    //     return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"CTONo"} data={data} section={"fileimportinfodetail"} />;
    //   },
    // },
    {
      key: "ToLocationCode",
      filterable: true,
      name: "To Location Code" + postValueInfo,
      width: cellWidthMedium,
      editable: true,
      editor: (props) => <TextEditor {...props} />,
      formatter: ({ row }) => CellFormatter(row, "ToLocationCode", validationData),
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"ToLocationCode"} data={data} section={"fileimportinfodetail"} />;
      },
    },
    {
      key: "TempSerialNo",
      filterable: true,
      name: "Temp Serial No" + postValueInfo,
      width: cellWidthMedium,
      editable: true,
      editor: (props) => <TextEditor {...props} />,
      formatter: ({ row }) => CellFormatter(row, "TempSerialNo", validationData),
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"TempSerialNo"} data={data} section={"fileimportinfodetail"} />;
      },
    },
    {
      key: "ShippingLine",
      filterable: true,
      name: "Shipping Line" + postValueInfo,
      width: cellWidthMedium,
      editable: true,
      editor: (props) => <TextEditor {...props} />,
      formatter: ({ row }) => CellFormatter(row, "ShippingLine", validationData),
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"ShippingLine"} data={data} section={"fileimportinfodetail"} />;
      },
    },
    // {
    //   key: "PalletOnIntake",
    //   filterable: true,
    //   name: "Pallet On Intake" + postValueInfo,
    //   width: cellWidthMedium,
    //   filterRenderer: (e) => {
    //     return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"PalletOnIntake"} data={data} section={"fileimportinfodetail"} />;
    //   },
    // },
    // {
    //   key: "PalletOnStock",
    //   filterable: true,
    //   name: "Pallet On Stock" + postValueInfo,
    //   width: cellWidthMedium,
    //   filterRenderer: (e) => {
    //     return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"PalletOnStock"} data={data} section={"fileimportinfodetail"} />;
    //   },
    // },
    // {
    //   key: "FruitTrakInternalPackCode",
    //   filterable: true,
    //   name: "FruitTrak Internal Pack Code" + postValueInfo,
    //   width: cellWidthMedium,
    //   filterRenderer: (e) => {
    //     return (
    //       <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"FruitTrakInternalPackCode"} data={data} section={"fileimportinfodetail"} />
    //     );
    //   },
    // },
    // {
    //   key: "TranType",
    //   filterable: true,
    //   name: "Tran Type" + postValueInfo,
    //   width: cellWidthMedium,
    //   filterRenderer: (e) => {
    //     return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"TranType"} data={data} section={"fileimportinfodetail"} />;
    //   },
    // },
    // {
    //   key: "InvoiceID",
    //   filterable: true,
    //   name: "Invoice ID" + postValueInfo,
    //   width: cellWidthMedium,
    //   filterRenderer: (e) => {
    //     return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"InvoiceID"} data={data} section={"fileimportinfodetail"} />;
    //   },
    // },
    // {
    //   key: "NoOfItems",
    //   filterable: true,
    //   name: "No Of Items" + postValueInfo,
    //   width: cellWidthMedium,
    //   filterRenderer: (e) => {
    //     return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"NoOfItems"} data={data} section={"fileimportinfodetail"} />;
    //   },
    // },
    // {
    //   key: "FPrice",
    //   filterable: true,
    //   name: "FPrice" + postValueInfo,
    //   width: cellWidthMedium,
    //   filterRenderer: (e) => {
    //     return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"FPrice"} data={data} section={"fileimportinfodetail"} />;
    //   },
    // },
    // {
    //   key: "FAmount",
    //   filterable: true,
    //   name: "FAmount" + postValueInfo,
    //   width: cellWidthMedium,
    //   filterRenderer: (e) => {
    //     return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"FAmount"} data={data} section={"fileimportinfodetail"} />;
    //   },
    // },
    // {
    //   key: "CostPerItem",
    //   filterable: true,
    //   name: "Cost Per Item" + postValueInfo,
    //   width: cellWidthMedium,
    //   filterRenderer: (e) => {
    //     return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"CostPerItem"} data={data} section={"fileimportinfodetail"} />;
    //   },
    // },
    // {
    //   key: "ReportOption",
    //   filterable: true,
    //   name: "Report Option" + postValueInfo,
    //   width: cellWidthMedium,
    //   filterRenderer: (e) => {
    //     return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"ReportOption"} data={data} section={"fileimportinfodetail"} />;
    //   },
    // },
    // {
    //   key: "PurchasePrice",
    //   filterable: true,
    //   name: "Purchase Price" + postValueInfo,
    //   width: cellWidthMedium,
    //   filterRenderer: (e) => {
    //     return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"PurchasePrice"} data={data} section={"fileimportinfodetail"} />;
    //   },
    // },
    // {
    //   key: "Depot",
    //   filterable: true,
    //   name: "Depot" + postValueInfo,
    //   width: cellWidthMedium,
    //   filterRenderer: (e) => {
    //     return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Depot"} data={data} section={"fileimportinfodetail"} />;
    //   },
    // },
    // {
    //   key: "AdvancePrice",
    //   filterable: true,
    //   name: "Advance Price" + postValueInfo,
    //   width: cellWidthMedium,
    //   filterRenderer: (e) => {
    //     return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"AdvancePrice"} data={data} section={"fileimportinfodetail"} />;
    //   },
    // },
    {
      key: "LoadDate",
      filterable: true,
      name: "Load Date" + postValueInfo,
      width: cellWidthMedium,
      editor: DatePicker,
      formatter: ({ row }) => DateFormatter(row.LoadDate),
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"LoadDate"} data={data} section={"fileimportinfodetail"} />;
      },
    },
    // {
    //   key: "StartLoadingTime",
    //   filterable: true,
    //   name: "Start Loading Time" + postValueInfo,
    //   width: cellWidthMedium,
    //   filterRenderer: (e) => {
    //     return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"StartLoadingTime"} data={data} section={"fileimportinfodetail"} />;
    //   },
    // },
    {
      key: "LocalFOB",
      filterable: true,
      name: "Local FOB" + postValueInfo,
      width: cellWidthMedium,
      editable: true,
      editor: (props) => <TextEditor {...props} />,
      formatter: ({ row }) => CellFormatter(row, "LocalFOB", validationData),
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"LocalFOB"} data={data} section={"fileimportinfodetail"} />;
      },
    },
    {
      key: "PreAllocated",
      filterable: true,
      name: "Pre Allocated" + postValueInfo,
      width: cellWidthMedium,
      editable: true,
      editor: (props) => <TextEditor {...props} />,
      formatter: ({ row }) => CellFormatter(row, "PreAllocated", validationData),
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"PreAllocated"} data={data} section={"fileimportinfodetail"} />;
      },
    },
    // {
    //   key: "InsuranceValue",
    //   filterable: true,
    //   name: "Insurance Value" + postValueInfo,
    //   width: cellWidthMedium,
    //   filterRenderer: (e) => {
    //     return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"InsuranceValue"} data={data} section={"fileimportinfodetail"} />;
    //   },
    // },
    // {
    //   key: "PurchaseOrderNo",
    //   filterable: true,
    //   name: "Purchase Order No" + postValueInfo,
    //   width: cellWidthMedium,
    //   filterRenderer: (e) => {
    //     return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"PurchaseOrderNo"} data={data} section={"fileimportinfodetail"} />;
    //   },
    // },
    // {
    //   key: "DepotStockID",
    //   filterable: true,
    //   name: "Depot Stock ID" + postValueInfo,
    //   width: cellWidthMedium,
    //   filterRenderer: (e) => {
    //     return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"DepotStockID"} data={data} section={"fileimportinfodetail"} />;
    //   },
    // },
    // {
    //   key: "Rebate",
    //   filterable: true,
    //   name: "Rebate" + postValueInfo,
    //   width: cellWidthMedium,
    //   filterRenderer: (e) => {
    //     return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Rebate"} data={data} section={"fileimportinfodetail"} />;
    //   },
    // },
    // {
    //   key: "ToSequenceNumber",
    //   filterable: true,
    //   name: "To Sequence Number" + postValueInfo,
    //   width: cellWidthMedium,
    //   filterRenderer: (e) => {
    //     return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"ToSequenceNumber"} data={data} section={"fileimportinfodetail"} />;
    //   },
    // },
    {
      key: "TransactionDate",
      filterable: true,
      name: "Transaction Date" + postValueInfo,
      width: cellWidthMedium,
      editor: DatePicker,
      formatter: ({ row }) => DateFormatter(row.TransactionDate),
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"TransactionDate"} data={data} section={"fileimportinfodetail"} />;
      },
    },
    // {
    //   key: "InnerPack",
    //   filterable: true,
    //   name: "Inner Pack" + postValueInfo,
    //   width: cellWidthMedium,
    //   filterRenderer: (e) => {
    //     return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"InnerPack"} data={data} section={"fileimportinfodetail"} />;
    //   },
    // },
    // {
    //   key: "IntakeWeek",
    //   filterable: true,
    //   name: "Intake Week" + postValueInfo,
    //   width: cellWidthMedium,
    //   filterRenderer: (e) => {
    //     return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"IntakeWeek"} data={data} section={"fileimportinfodetail"} />;
    //   },
    // },
    // {
    //   key: "EqvCartons",
    //   filterable: true,
    //   name: "Eqv Cartons" + postValueInfo,
    //   width: cellWidthMedium,
    //   filterRenderer: (e) => {
    //     return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"EqvCartons"} data={data} section={"fileimportinfodetail"} />;
    //   },
    // },
    {
      key: "TempType",
      filterable: true,
      name: "Temp Type" + postValueInfo,
      width: cellWidthMedium,
      editable: true,
      editor: (props) => <TextEditor {...props} />,
      formatter: ({ row }) => CellFormatter(row, "TempType", validationData),
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"TempType"} data={data} section={"fileimportinfodetail"} />;
      },
    },
    {
      key: "Qnty",
      filterable: true,
      name: "Qnty" + postValueInfo,
      width: cellWidthMedium,
      editable: true,
      editor: (props) => <TextEditor {...props} />,
      formatter: ({ row }) => CellFormatter(row, "Qnty", validationData),
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Qnty"} data={data} section={"fileimportinfodetail"} />;
      },
    },
    // {
    //   key: "InnerCartons",
    //   filterable: true,
    //   name: "Inner Cartons" + postValueInfo,
    //   width: cellWidthMedium,
    //   filterRenderer: (e) => {
    //     return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"InnerCartons"} data={data} section={"fileimportinfodetail"} />;
    //   },
    // },
    {
      key: "PalletStatus",
      filterable: true,
      name: "Pallet Status" + postValueInfo,
      width: cellWidthMedium,
      editable: true,
      editor: (props) => <TextEditor {...props} />,
      formatter: ({ row }) => CellFormatter(row, "PalletStatus", validationData),
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"PalletStatus"} data={data} section={"fileimportinfodetail"} />;
      },
    },
    // {
    //   key: "ContractID",
    //   filterable: true,
    //   name: "Contract ID" + postValueInfo,
    //   width: cellWidthMedium,
    //   filterRenderer: (e) => {
    //     return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"ContractID"} data={data} section={"fileimportinfodetail"} />;
    //   },
    // },
    // {
    //   key: "TargetPrice",
    //   filterable: true,
    //   name: "Target Price" + postValueInfo,
    //   width: cellWidthMedium,
    //   filterRenderer: (e) => {
    //     return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"TargetPrice"} data={data} section={"fileimportinfodetail"} />;
    //   },
    // },
    {
      key: "BHTransactionDate",
      filterable: true,
      name: "BH Transaction Date" + postValueInfo,
      width: cellWidthMedium,
      editor: DatePicker,
      formatter: ({ row }) => DateFormatter(row.BHTransactionDate),
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"BHTransactionDate"} data={data} section={"fileimportinfodetail"} />;
      },
    },
    // {
    //   key: "DepotFiletype",
    //   filterable: true,
    //   name: "Depot File type" + postValueInfo,
    //   width: cellWidthMedium,
    //   filterRenderer: (e) => {
    //     return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"DepotFiletype"} data={data} section={"fileimportinfodetail"} />;
    //   },
    // },
    // {
    //   key: "GrowingMethod",
    //   filterable: true,
    //   name: "Growing Method" + postValueInfo,
    //   width: cellWidthMedium,
    //   filterRenderer: (e) => {
    //     return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"GrowingMethod"} data={data} section={"fileimportinfodetail"} />;
    //   },
    // },
    // {
    //   key: "MustGO",
    //   filterable: true,
    //   name: "Must GO" + postValueInfo,
    //   width: cellWidthMedium,
    //   filterRenderer: (e) => {
    //     return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"MustGO"} data={data} section={"fileimportinfodetail"} />;
    //   },
    // },
    // {
    //   key: "Cycle",
    //   filterable: true,
    //   name: "Cycle" + postValueInfo,
    //   width: cellWidthMedium,
    //   filterRenderer: (e) => {
    //     return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Cycle"} data={data} section={"fileimportinfodetail"} />;
    //   },
    // },
    // {
    //   key: "AdvancePurchase",
    //   filterable: true,
    //   name: "Advance Purchase" + postValueInfo,
    //   width: cellWidthMedium,
    //   filterRenderer: (e) => {
    //     return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"AdvancePurchase"} data={data} section={"fileimportinfodetail"} />;
    //   },
    // },
    {
      key: "PackHouseCode",
      filterable: true,
      name: "PackHouse Code" + postValueInfo,
      width: cellWidthMedium,
      editable: true,
      editor: (props) => <TextEditor {...props} />,
      formatter: ({ row }) => CellFormatter(row, "PackHouseCode", validationData),
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"PackHouseCode"} data={data} section={"fileimportinfodetail"} />;
      },
    },
    {
      key: "Principal",
      filterable: true,
      name: "Principal" + postValueInfo,
      width: cellWidthMedium,
      editable: true,
      editor: (props) => <TextEditor {...props} />,
      formatter: ({ row }) => CellFormatter(row, "Principal", validationData),
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Principal"} data={data} section={"fileimportinfodetail"} />;
      },
    },
    {
      key: "VarietyGroup",
      filterable: true,
      name: "Variety Group" + postValueInfo,
      width: cellWidthMedium,
      editable: true,
      editor: (props) => <TextEditor {...props} />,
      formatter: ({ row }) => CellFormatter(row, "VarietyGroup", validationData),
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"VarietyGroup"} data={data} section={"fileimportinfodetail"} />;
      },
    },
    {
      key: "CommodityGroup",
      filterable: true,
      name: "Commodity Group" + postValueInfo,
      width: cellWidthMedium,
      editable: true,
      editor: (props) => <TextEditor {...props} />,
      formatter: ({ row }) => CellFormatter(row, "CommodityGroup", validationData),
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"CommodityGroup"} data={data} section={"fileimportinfodetail"} />;
      },
    },
    {
      key: "VarietyGroupDescription",
      filterable: true,
      name: "Variety Group Description" + postValueInfo,
      width: cellWidthMedium,
      editable: true,
      editor: (props) => <TextEditor {...props} />,
      formatter: ({ row }) => CellFormatter(row, "VarietyGroupDescription", validationData),
      filterRenderer: (e) => {
        return (
          <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"VarietyGroupDescription"} data={data} section={"fileimportinfodetail"} />
        );
      },
    },
    {
      key: "VarietyDescription",
      filterable: true,
      name: "Variety Description" + postValueInfo,
      width: cellWidthMedium,
      editable: true,
      editor: (props) => <TextEditor {...props} />,
      formatter: ({ row }) => CellFormatter(row, "VarietyDescription", validationData),
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"VarietyDescription"} data={data} section={"fileimportinfodetail"} />;
      },
    },
    {
      key: "ShippingWeek",
      filterable: true,
      name: "Shipping Week" + postValueInfo,
      width: cellWidthMedium,
      editable: true,
      editor: (props) => <TextEditor {...props} />,
      formatter: ({ row }) => CellFormatter(row, "ShippingWeek", validationData),
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"ShippingWeek"} data={data} section={"fileimportinfodetail"} />;
      },
    },
    // {
    //   key: "Balance",
    //   filterable: true,
    //   name: "Balance" + postValueInfo,
    //   width: cellWidthMedium,
    //   filterRenderer: (e) => {
    //     return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Balance"} data={data} section={"fileimportinfodetail"} />;
    //   },
    // },
    // {
    //   key: "RateStructure",
    //   filterable: true,
    //   name: "Rate Structure" + postValueInfo,
    //   width: cellWidthMedium,
    //   filterRenderer: (e) => {
    //     return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"RateStructure"} data={data} section={"fileimportinfodetail"} />;
    //   },
    // },
    {
      key: "ATA",
      filterable: true,
      name: "ATA" + postValueInfo,
      width: cellWidthMedium,
      editable: true,
      editor: (props) => <TextEditor {...props} />,
      formatter: ({ row }) => CellFormatter(row, "ATA", validationData),
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"ATA"} data={data} section={"fileimportinfodetail"} />;
      },
    },
    {
      key: "ATD",
      filterable: true,
      name: "ATD" + postValueInfo,
      width: cellWidthMedium,
      editable: true,
      editor: (props) => <TextEditor {...props} />,
      formatter: ({ row }) => CellFormatter(row, "ATD", validationData),
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"ATD"} data={data} section={"fileimportinfodetail"} />;
      },
    },
    {
      key: "QCSelected",
      filterable: true,
      name: "QC Selected" + postValueInfo,
      width: cellWidthMedium,
      editable: true,
      editor: (props) => <TextEditor {...props} />,
      formatter: ({ row }) => CellFormatter(row, "QCSelected", validationData),
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"QCSelected"} data={data} section={"fileimportinfodetail"} />;
      },
    },
    {
      key: "QCUserName",
      filterable: true,
      name: "QC UserName" + postValueInfo,
      width: cellWidthMedium,
      editable: true,
      editor: (props) => <TextEditor {...props} />,
      formatter: ({ row }) => CellFormatter(row, "QCUserName", validationData),
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"QCUserName"} data={data} section={"fileimportinfodetail"} />;
      },
    },
    // {
    //   key: "RawCartons",
    //   filterable: true,
    //   name: "Raw Cartons" + postValueInfo,
    //   width: cellWidthMedium,
    //   filterRenderer: (e) => {
    //     return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"RawCartons"} data={data} section={"fileimportinfodetail"} />;
    //   },
    // },
    // {
    //   key: "RawPallets",
    //   filterable: true,
    //   name: "Raw Pallets" + postValueInfo,
    //   width: cellWidthMedium,
    //   filterRenderer: (e) => {
    //     return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"RawPallets"} data={data} section={"fileimportinfodetail"} />;
    //   },
    // },
    // {
    //   key: "EqvPallets",
    //   filterable: true,
    //   name: "Eqv Pallets" + postValueInfo,
    //   width: cellWidthMedium,
    //   filterRenderer: (e) => {
    //     return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"EqvPallets"} data={data} section={"fileimportinfodetail"} />;
    //   },
    // },
    // {
    //   key: "SpecialOrder",
    //   filterable: true,
    //   name: "Special Order" + postValueInfo,
    //   width: cellWidthMedium,
    //   filterRenderer: (e) => {
    //     return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"SpecialOrder"} data={data} section={"fileimportinfodetail"} />;
    //   },
    // },
    {
      key: "DestinationTypeCN",
      filterable: true,
      name: "Destination Type CN" + postValueInfo,
      width: cellWidthMedium,
      editable: true,
      editor: (props) => <TextEditor {...props} />,
      formatter: ({ row }) => CellFormatter(row, "DestinationTypeCN", validationData),
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"DestinationTypeCN"} data={data} section={"fileimportinfodetail"} />;
      },
    },
    {
      key: "ContainerRef",
      filterable: true,
      name: "ContainerRef" + postValueInfo,
      width: cellWidthMedium,
      editable: true,
      editor: (props) => <TextEditor {...props} />,
      formatter: ({ row }) => CellFormatter(row, "ContainerRef", validationData),
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"ContainerRef"} data={data} section={"fileimportinfodetail"} />;
      },
    },
    {
      key: "NotifyParty",
      filterable: true,
      name: "Notify Party" + postValueInfo,
      width: cellWidthMedium,
      editable: true,
      editor: (props) => <TextEditor {...props} />,
      formatter: ({ row }) => CellFormatter(row, "NotifyParty", validationData),
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"NotifyParty"} data={data} section={"fileimportinfodetail"} />;
      },
    },
    // {
    //   key: "BestBefore",
    //   filterable: true,
    //   name: "Best Before" + postValueInfo,
    //   width: cellWidthMedium,
    //   filterRenderer: (e) => {
    //     return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"BestBefore"} data={data} section={"fileimportinfodetail"} />;
    //   },
    // },
    // {
    //   key: "DisplayUntil",
    //   filterable: true,
    //   name: "Display Until" + postValueInfo,
    //   width: cellWidthMedium,
    //   filterRenderer: (e) => {
    //     return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"DisplayUntil"} data={data} section={"fileimportinfodetail"} />;
    //   },
    // },
    {
      key: "QCCode",
      filterable: true,
      name: "QC Code" + postValueInfo,
      width: cellWidthMedium,
      editable: true,
      editor: (props) => <TextEditor {...props} />,
      formatter: ({ row }) => CellFormatter(row, "QCCode", validationData),
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"QCCode"} data={data} section={"fileimportinfodetail"} />;
      },
    },
    // {
    //   key: "ClaimType",
    //   filterable: true,
    //   name: "Claim Type" + postValueInfo,
    //   width: cellWidthMedium,
    //   filterRenderer: (e) => {
    //     return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"ClaimType"} data={data} section={"fileimportinfodetail"} />;
    //   },
    // },
    // {
    //   key: "AdditionalDataPath",
    //   filterable: true,
    //   name: "Additional Data Path" + postValueInfo,
    //   width: cellWidthMedium,
    //   filterRenderer: (e) => {
    //     return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"AdditionalDataPath"} data={data} section={"fileimportinfodetail"} />;
    //   },
    // },
    // {
    //   key: "AdditionalPhotoPath",
    //   filterable: true,
    //   name: "Additional Photo Path" + postValueInfo,
    //   width: cellWidthMedium,
    //   filterRenderer: (e) => {
    //     return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"AdditionalPhotoPath"} data={data} section={"fileimportinfodetail"} />;
    //   },
    // },
    {
      key: "QCType",
      filterable: true,
      name: "QC Type" + postValueInfo,
      width: cellWidthMedium,
      editable: true,
      editor: (props) => <TextEditor {...props} />,
      formatter: ({ row }) => CellFormatter(row, "QCType", validationData),
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"QCType"} data={data} section={"fileimportinfodetail"} />;
      },
    },
    // {
    //   key: "PUC",
    //   filterable: true,
    //   name: "PUC" + postValueInfo,
    //   width: cellWidthMedium,
    //   editable: true,
    //   filterRenderer: (e) => {
    //     return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"PUC"} data={data} section={"fileimportinfodetail"} />;
    //   },
    // },
    {
      key: "PackingDate",
      filterable: true,
      name: "Packing Date" + postValueInfo,
      width: cellWidthMedium,
      editor: DatePicker,
      formatter: ({ row }) => DateFormatter(row.PackingDate),
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"PackingDate"} data={data} section={"fileimportinfodetail"} />;
      },
    },
    {
      key: "InspectorCode",
      filterable: true,
      name: "Inspector Code" + postValueInfo,
      width: cellWidthMedium,
      editable: true,
      editor: (props) => <TextEditor {...props} />,
      formatter: ({ row }) => CellFormatter(row, "InspectorCode", validationData),
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"InspectorCode"} data={data} section={"fileimportinfodetail"} />;
      },
    },
    // {
    //   key: "Labeled",
    //   filterable: true,
    //   name: "Labeled" + postValueInfo,
    //   width: cellWidthMedium,
    //   filterRenderer: (e) => {
    //     return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Labeled"} data={data} section={"fileimportinfodetail"} />;
    //   },
    // },
    // {
    //   key: "QCPassed",
    //   filterable: true,
    //   name: "QC Passed" + postValueInfo,
    //   width: cellWidthMedium,
    //   filterRenderer: (e) => {
    //     return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"QCPassed"} data={data} section={"fileimportinfodetail"} />;
    //   },
    // },
    // {
    //   key: "ExpiryCode",
    //   filterable: true,
    //   name: "Expiry Code" + postValueInfo,
    //   width: cellWidthMedium,
    //   filterRenderer: (e) => {
    //     return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"ExpiryCode"} data={data} section={"fileimportinfodetail"} />;
    //   },
    // },
    {
      key: "OrigPHCLocationCode",
      filterable: true,
      name: "Orig PHC Location Code" + postValueInfo,
      width: cellWidthMedium,
      editable: true,
      editor: (props) => <TextEditor {...props} />,
      formatter: ({ row }) => CellFormatter(row, "OrigPHCLocationCode", validationData),
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"OrigPHCLocationCode"} data={data} section={"fileimportinfodetail"} />;
      },
    },
    {
      key: "PHCLocationCode",
      filterable: true,
      name: "PHC Location Code" + postValueInfo,
      width: cellWidthMedium,
      editable: true,
      editor: (props) => <TextEditor {...props} />,
      formatter: ({ row }) => CellFormatter(row, "PHCLocationCode", validationData),
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"PHCLocationCode"} data={data} section={"fileimportinfodetail"} />;
      },
    },
    // {
    //   key: "EstimateReturn",
    //   filterable: true,
    //   name: "Estimate Return" + postValueInfo,
    //   width: cellWidthMedium,
    //   filterRenderer: (e) => {
    //     return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"EstimateReturn"} data={data} section={"fileimportinfodetail"} />;
    //   },
    // },
    // {
    //   key: "PurchasePalletID",
    //   filterable: true,
    //   name: "Purchase Pallet ID" + postValueInfo,
    //   width: cellWidthMedium,
    //   filterRenderer: (e) => {
    //     return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"PurchasePalletID"} data={data} section={"fileimportinfodetail"} />;
    //   },
    // },
    // {
    //   key: "EDIProvider",
    //   filterable: true,
    //   name: "EDI Provider" + postValueInfo,
    //   width: cellWidthMedium,
    //   filterRenderer: (e) => {
    //     return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"EDIProvider"} data={data} section={"fileimportinfodetail"} />;
    //   },
    // },
    // {
    //   key: "GTINID",
    //   filterable: true,
    //   name: "GTIN ID" + postValueInfo,
    //   width: cellWidthMedium,
    //   filterRenderer: (e) => {
    //     return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"GTINID"} data={data} section={"fileimportinfodetail"} />;
    //   },
    // },
    // {
    //   key: "Row",
    //   filterable: true,
    //   name: "Row" + postValueInfo,
    //   width: cellWidthMedium,
    //   filterRenderer: (e) => {
    //     return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Row"} data={data} section={"fileimportinfodetail"} />;
    //   },
    // },
    // {
    //   key: "Position",
    //   filterable: true,
    //   name: "Position" + postValueInfo,
    //   width: cellWidthMedium,
    //   filterRenderer: (e) => {
    //     return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Position"} data={data} section={"fileimportinfodetail"} />;
    //   },
    // },
    // {
    //   key: "CustomField1",
    //   filterable: true,
    //   name: "Custom Field 1" + postValueInfo,
    //   width: cellWidthMedium,
    //   filterRenderer: (e) => {
    //     return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"CustomField1"} data={data} section={"fileimportinfodetail"} />;
    //   },
    // },
    {
      key: "OrigFarmNumber",
      filterable: true,
      name: "Orig Farm Number" + postValueInfo,
      width: cellWidthMedium,
      editable: true,
      editor: (props) => <TextEditor {...props} />,
      formatter: ({ row }) => CellFormatter(row, "OrigFarmNumber", validationData),
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"OrigFarmNumber"} data={data} section={"fileimportinfodetail"} />;
      },
    },
    {
      key: "UCRNo",
      filterable: true,
      name: "UCR No" + postValueInfo,
      width: cellWidthMedium,
      editable: true,
      editor: (props) => <TextEditor {...props} />,
      formatter: ({ row }) => CellFormatter(row, "UCRNo", validationData),
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"UCRNo"} data={data} section={"fileimportinfodetail"} />;
      },
    },
    {
      key: "GTIN",
      filterable: true,
      name: "GTIN" + postValueInfo,
      width: cellWidthMedium,
      editable: true,
      editor: (props) => <TextEditor {...props} />,
      formatter: ({ row }) => CellFormatter(row, "GTIN", validationData),
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"GTIN"} data={data} section={"fileimportinfodetail"} />;
      },
    },
    // {
    //   key: "Percentage",
    //   filterable: true,
    //   name: "Percentage" + postValueInfo,
    //   width: cellWidthMedium,
    //   filterRenderer: (e) => {
    //     return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Percentage"} data={data} section={"fileimportinfodetail"} />;
    //   },
    // },
    // {
    //   key: "Complexity",
    //   filterable: true,
    //   name: "Complexity" + postValueInfo,
    //   width: cellWidthMedium,
    //   filterRenderer: (e) => {
    //     return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Complexity"} data={data} section={"fileimportinfodetail"} />;
    //   },
    // },
    // {
    //   key: "IntakeStockValue",
    //   filterable: true,
    //   name: "Intake Stock Value" + postValueInfo,
    //   width: cellWidthMedium,
    //   filterRenderer: (e) => {
    //     return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"IntakeStockValue"} data={data} section={"fileimportinfodetail"} />;
    //   },
    // },
    {
      key: "VoyageNumber",
      filterable: true,
      name: "Voyage Number" + postValueInfo,
      width: cellWidthMedium,
      editable: true,
      editor: (props) => <TextEditor {...props} />,
      formatter: ({ row }) => CellFormatter(row, "VoyageNumber", validationData),
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"VoyageNumber"} data={data} section={"fileimportinfodetail"} />;
      },
    },
    {
      key: "OrigInspectionDate",
      filterable: true,
      name: "Orig Inspection Date" + postValueInfo,
      width: cellWidthMedium,
      editor: DatePicker,
      formatter: ({ row }) => DateFormatter(row.OrigInspectionDate),
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"OrigInspectionDate"} data={data} section={"fileimportinfodetail"} />;
      },
    },
    {
      key: "UPN",
      filterable: true,
      name: "UPN" + postValueInfo,
      width: cellWidthMedium,
      editable: true,
      editor: (props) => <TextEditor {...props} />,
      formatter: ({ row }) => CellFormatter(row, "UPN", validationData),
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"UPN"} data={data} section={"fileimportinfodetail"} />;
      },
    },
    {
      key: "ActualVariety",
      filterable: true,
      name: "Actual Variety" + postValueInfo,
      width: cellWidthMedium,
      editable: true,
      editor: (props) => <TextEditor {...props} />,
      formatter: ({ row }) => CellFormatter(row, "ActualVariety", validationData),
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"ActualVariety"} data={data} section={"fileimportinfodetail"} />;
      },
    },
    {
      key: "PalletTreatmentID",
      filterable: true,
      name: "PalletTreatment ID" + postValueInfo,
      width: cellWidthMedium,
      editable: true,
      editor: (props) => <TextEditor {...props} />,
      formatter: ({ row }) => CellFormatter(row, "PalletTreatmentID", validationData),
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"PalletTreatmentID"} data={data} section={"fileimportinfodetail"} />;
      },
    },
    // {
    //   key: "PoolingPackagingFactor",
    //   filterable: true,
    //   name: "Pooling Packaging Factor" + postValueInfo,
    //   width: cellWidthMedium,
    //   filterRenderer: (e) => {
    //     return (
    //       <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"PoolingPackagingFactor"} data={data} section={"fileimportinfodetail"} />
    //     );
    //   },
    // },
    // {
    //   key: "ProducerDealType",
    //   filterable: true,
    //   name: "Producer Deal Type" + postValueInfo,
    //   width: cellWidthMedium,
    //   filterRenderer: (e) => {
    //     return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"ProducerDealType"} data={data} section={"fileimportinfodetail"} />;
    //   },
    // },
    {
      key: "Trailer1",
      filterable: true,
      name: "Trailer1" + postValueInfo,
      width: cellWidthMedium,
      editable: true,
      editor: (props) => <TextEditor {...props} />,
      formatter: ({ row }) => CellFormatter(row, "Trailer1", validationData),
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Trailer1"} data={data} section={"fileimportinfodetail"} />;
      },
    },
    {
      key: "Trailer2",
      filterable: true,
      name: "Trailer2" + postValueInfo,
      width: cellWidthMedium,
      editable: true,
      editor: (props) => <TextEditor {...props} />,
      formatter: ({ row }) => CellFormatter(row, "Trailer2", validationData),
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Trailer2"} data={data} section={"fileimportinfodetail"} />;
      },
    },
    {
      key: "VerificationMethod",
      filterable: true,
      name: "Verification Method" + postValueInfo,
      width: cellWidthMedium,
      editable: true,
      editor: (props) => <TextEditor {...props} />,
      formatter: ({ row }) => CellFormatter(row, "VerificationMethod", validationData),
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"VerificationMethod"} data={data} section={"fileimportinfodetail"} />;
      },
    },
    // {
    //   key: "AccreditationNumber",
    //   filterable: true,
    //   name: "Accreditation Number" + postValueInfo,
    //   width: cellWidthMedium,
    //   filterRenderer: (e) => {
    //     return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"AccreditationNumber"} data={data} section={"fileimportinfodetail"} />;
    //   },
    // },
    // {
    //   key: "AccreditationNumberContainerLevel",
    //   filterable: true,
    //   name: "Accreditation Number Container Level" + postValueInfo,
    //   width: cellWidthMedium,
    //   filterRenderer: (e) => {
    //     return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"AccreditationNumberContainerLevel"} data={data} section={"fileimportinfodetail"} />;
    //   },
    // },
    // {
    //   key: "ContainerTareWeight",
    //   filterable: true,
    //   name: "Container Tare Weight" + postValueInfo,
    //   width: cellWidthMedium,
    //   filterRenderer: (e) => {
    //     return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"ContainerTareWeight"} data={data} section={"fileimportinfodetail"} />;
    //   },
    // },
    // {
    //   key: "ContainerGrossWeight",
    //   filterable: true,
    //   name: "Container Gross Weight" + postValueInfo,
    //   width: cellWidthMedium,
    //   filterRenderer: (e) => {
    //     return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"ContainerGrossWeight"} data={data} section={"fileimportinfodetail"} />;
    //   },
    // },
    // {
    //   key: "ResponsibleParty",
    //   filterable: true,
    //   name: "Responsible Party" + postValueInfo,
    //   width: cellWidthMedium,
    //   filterRenderer: (e) => {
    //     return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"ResponsibleParty"} data={data} section={"fileimportinfodetail"} />;
    //   },
    // },
    // {
    //   key: "VitraxUID",
    //   filterable: true,
    //   name: "Vitrax UID" + postValueInfo,
    //   width: cellWidthMedium,
    //   filterRenderer: (e) => {
    //     return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"VitraxUID"} data={data} section={"fileimportinfodetail"} />;
    //   },
    // },
    // {
    //   key: "VitraxTargetUID",
    //   filterable: true,
    //   name: "Vitrax Target UID" + postValueInfo,
    //   width: cellWidthMedium,
    //   filterRenderer: (e) => {
    //     return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"VitraxTargetUID"} data={data} section={"fileimportinfodetail"} />;
    //   },
    // },
    // {
    //   key: "FruittrakIntakeID",
    //   filterable: true,
    //   name: "Fruittrak Intake ID" + postValueInfo,
    //   width: cellWidthMedium,
    //   filterRenderer: (e) => {
    //     return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"FruittrakIntakeID"} data={data} section={"fileimportinfodetail"} />;
    //   },
    // },
    // {
    //   key: "PreIntakeStock",
    //   filterable: true,
    //   name: "PreIntakeStock" + postValueInfo,
    //   width: cellWidthMedium,
    //   filterRenderer: (e) => {
    //     return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"PreIntakeStock"} data={data} section={"fileimportinfodetail"} />;
    //   },
    // },
    // {
    //   key: "InnerCartonWeight",
    //   filterable: true,
    //   name: "Inner Carton Weight" + postValueInfo,
    //   width: cellWidthMedium,
    //   filterRenderer: (e) => {
    //     return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"InnerCartonWeight"} data={data} section={"fileimportinfodetail"} />;
    //   },
    // },
    // {
    //   key: "VATCode",
    //   filterable: true,
    //   name: "VAT Code" + postValueInfo,
    //   width: cellWidthMedium,
    //   filterRenderer: (e) => {
    //     return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"VATCode"} data={data} section={"fileimportinfodetail"} />;
    //   },
    // },
    {
      key: "StartWeek",
      filterable: true,
      name: "Start Week" + postValueInfo,
      width: cellWidthMedium,
      editable: true,
      editor: (props) => <TextEditor {...props} />,
      formatter: ({ row }) => CellFormatter(row, "StartWeek", validationData),
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"StartWeek"} data={data} section={"fileimportinfodetail"} />;
      },
    },
    {
      key: "EndWeek",
      filterable: true,
      name: "End Week" + postValueInfo,
      width: cellWidthMedium,
      editable: true,
      editor: (props) => <TextEditor {...props} />,
      formatter: ({ row }) => CellFormatter(row, "EndWeek", validationData),
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"EndWeek"} data={data} section={"fileimportinfodetail"} />;
      },
    },
    // {
    //   key: "PQINumber",
    //   filterable: true,
    //   name: "PQI Number" + postValueInfo,
    //   width: cellWidthMedium,
    //   filterRenderer: (e) => {
    //     return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"PQINumber"} data={data} section={"fileimportinfodetail"} />;
    //   },
    // },
    // {
    //   key: "PricingSheetAppliedDate",
    //   filterable: true,
    //   name: "Pricing Sheet Applied Date" + postValueInfo,
    //   width: cellWidthMedium,
    //   formatter: ({ row }) => DateFormatter(row.PricingSheetAppliedDate),
    //   filterRenderer: (e) => {
    //     return (
    //       <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"PricingSheetAppliedDate"} data={data} section={"fileimportinfodetail"} />
    //     );
    //   },
    // },
    // {
    //   key: "OwnCost",
    //   filterable: true,
    //   name: "Own Cost" + postValueInfo,
    //   width: cellWidthMedium,
    //   filterRenderer: (e) => {
    //     return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"OwnCost"} data={data} section={"fileimportinfodetail"} />;
    //   },
    // },
    // {
    //   key: "PackDate",
    //   filterable: true,
    //   name: "Pack Date" + postValueInfo,
    //   width: cellWidthMedium,
    //   formatter: ({ row }) => DateFormatter(row.PackDate),
    //   filterRenderer: (e) => {
    //     return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"PackDate"} data={data} section={"fileimportinfodetail"} />;
    //   },
    // },
    // {
    //   key: "StuffDate",
    //   filterable: true,
    //   name: "Stuff Date" + postValueInfo,
    //   width: cellWidthMedium,
    //   formatter: ({ row }) => DateFormatter(row.StuffDate),
    //   filterRenderer: (e) => {
    //     return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"StuffDate"} data={data} section={"fileimportinfodetail"} />;
    //   },
    // },
    {
      key: "TempSet",
      filterable: true,
      name: "Temp Set" + postValueInfo,
      width: cellWidthMedium,
      editable: true,
      editor: (props) => <TextEditor {...props} />,
      formatter: ({ row }) => CellFormatter(row, "TempSet", validationData),
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"TempSet"} data={data} section={"fileimportinfodetail"} />;
      },
    },
    {
      key: "ContainerAgent",
      filterable: true,
      name: "Container Agent" + postValueInfo,
      width: cellWidthMedium,
      editable: true,
      editor: (props) => <TextEditor {...props} />,
      formatter: ({ row }) => CellFormatter(row, "ContainerAgent", validationData),
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"ContainerAgent"} data={data} section={"fileimportinfodetail"} />;
      },
    },
    {
      key: "LotNo",
      filterable: true,
      name: "Lot No" + postValueInfo,
      width: cellWidthMedium,
      editable: true,
      editor: (props) => <TextEditor {...props} />,
      formatter: ({ row }) => CellFormatter(row, "LotNo", validationData),
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"LotNo"} data={data} section={"fileimportinfodetail"} />;
      },
    },
    {
      key: "Waybill",
      filterable: true,
      name: "Waybill" + postValueInfo,
      width: cellWidthMedium,
      editable: true,
      editor: (props) => <TextEditor {...props} />,
      formatter: ({ row }) => CellFormatter(row, "Waybill", validationData),
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Waybill"} data={data} section={"fileimportinfodetail"} />;
      },
    },
  ].map((c) => ({ ...c, ...defaultColumnProperties }));

  let columnsArranged = [];
  columnsArranged = handleColumnsArrangement(columnsArranged, arrangement, columns);
  columnsArranged = handleColumnsWidth(columnsArranged, columnsWidth, columns);
  return columnsArranged;
};
