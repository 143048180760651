import { apiHeaders, client, request } from "./_base";

const endpoint = "estimatedcostitemgroups";

export const estimatedcostitemgroups = client("estimatedcostitemgroups", (item) => ({
  ...item,
}));

export const estimatedcostitemgroupsAllSortedMappedforCombo = async () => {
  const result = await estimatedcostitemgroups.all().then((data) => {
    return data
      .sort((a, b) => {
        if (a.name > b.name) return 1;
        if (a.name < b.name) return -1;
        return 1;
      })
      .map((item) => {
        return { id: item.id, value: item.id, display: `${item.name}` };
      });
  });
  return result;
};

export const upsertEstimatedCostItemGroups = async (data) => {
  const content = JSON.stringify(data);
  return request(`${endpoint}/ext/upsertEstimatedCostItemGroups`, {
    method: "POST",
    headers: apiHeaders(content),
    body: content,
  }).then(async (result) => {
    if (result.status == 500) {
      const resp = await result.json();
      throw resp;
    }
    return result.json();
  });
};

export const getProducerFinanceEstimatedCostItemGroups = (producer_id: number) =>
  request(`${endpoint}/ext/producerFinanceEstimatedCostItemGroups/${producer_id}`, {
    headers: apiHeaders(),
  })
    .then(async (result) => {
      if (result.status == 500) {
        const resp = await result.json();
        throw resp;
      }
      return result.json();
    })
    .then((data) => data.data);

export const updateProducerFinanceEstimatedCostItemGroups = async (data) => {
  const content = JSON.stringify(data);
  return request(`${endpoint}/ext/updateProducerFinanceEstimatedCostItemGroups`, {
    method: "POST",
    headers: apiHeaders(content),
    body: content,
  }).then(async (result) => {
    if (result.status == 500) {
      const resp = await result.json();
      throw resp;
    }
    return result.json();
  });
};
