import React from "react";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";

import LinearProgress from "@material-ui/core/LinearProgress";
import CircularProgress from "@material-ui/core/CircularProgress";

import {
  saleDispatchJoined,
  SaleDispatchJoinedType,
  sale,
  SaleBarcodesByDispatchIDType,
  saleBarcodesByDispatchID,
  checkPurchaseTermLockedSale,
  upsertSale,
  proformaExistsForPallets,
} from "../lib/api/sale";

import InvoiceTable from "./invoicetable";
import Accept from "./invoiceAccept";
import { handlePrintPackingListPDF, handlePrintInvoice } from "../reports/printing";
import { dispatch } from "../lib/api/dispatch";
import { loadout } from "../lib/api/loadout";
import { clientsByCode } from "../lib/api/clients";
import { SnackMessage } from "../lib/helpers/snackmessage";
import Confirmation from "../lib/components/confirmation";
import { isNullOrUndef } from "../lib/helpers/isNullOrUndef";
import { Reports } from "../lib/types";
import { getFinancialYearSelected } from "../lib/api/week";
import { SnackContext } from "../lib/context/SnackContext";
import { GenerateErrorMessage } from "../lib/helpers/string_methods";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      overflow: "hidden",
      width: "100%",
      height: "calc(100% - 65px)",
    },
  });

type InvoiceProps = {} & WithStyles<typeof styles>;

class InvoiceUnstyled extends React.Component<InvoiceProps> {
  state = {
    sales: undefined,
    loading: true,
    selectedSale: undefined,
    selectedData: undefined,
    classes: undefined,
    snackshow: undefined,
    snackmessage: undefined,
    snackcolor: undefined,
    releaseData: undefined,
    loadoutid: undefined,
    proforma: false,
    submitting: false,
    proformaInvoiceExists: [],
    sale: {},
  };

  constructor(props) {
    super(props);
    this.state.classes = props.classes;

    if (props.match.params.loadoutid) {
      this.state.loadoutid = props.match.params.loadoutid;
    }
    if (props.proforma) {
      this.state.proforma = props.proforma;
    }
  }

  static contextType = SnackContext;
  context!: React.ContextType<typeof SnackContext>;

  componentDidMount() {
    this.loadSales();
  }

  loadSales = async () => {
    this.setState({ loading: true });
    const result = await saleDispatchJoined(getFinancialYearSelected()).catch((error) => {
      const err = GenerateErrorMessage(error, "failed to retrieve data");
      this.context.updateSnack({ show: true, color: "red", message: err });
    });
    if (result) {
      if (this.state.proforma) {
        const proformaRecords = result.filter((itemProForma) => itemProForma.dispatch_dispatchdocs_id == "PRO-FORMA");
        if (proformaRecords) {
          this.setState({ sales: proformaRecords, loading: false });
        }
      } else {
        const proformaRecords = result.filter((itemProForma) => itemProForma.dispatch_dispatchdocs_id != "PRO-FORMA");
        this.setState({ sales: proformaRecords, loading: false });
      }

      if (this.state.loadoutid) {
        const findSale = result.find((itemfind) => itemfind.loadout_id == this.state.loadoutid);
        if (findSale) {
          saleBarcodesByDispatchID(findSale.dispatch_id)
            .then((data) => {
              this.setState({ selectedData: data, selectedSale: findSale, loadoutid: undefined });
            })
            .catch((error) => {
              const err = GenerateErrorMessage(error, "failed to retrieve data");
              this.context.updateSnack({ show: true, color: "red", message: err });
            });
        }
      }
    }
  };

  onReload = async () => {
    this.setState({ loading: true });
    return await this.loadSales();
  };

  onAccept = (data, saleData) => {
    if (data.length > 0) {
      this.setState({ selectedData: data, selectedSale: saleData });
    }
  };

  onAcceptProcess = async (
    saledata: SaleDispatchJoinedType,
    saleDetails: SaleBarcodesByDispatchIDType[],
    printRequested: Boolean = false,
    ReportType: Reports = undefined,
    checkProforma = true,
  ) => {
    this.setState({ submitting: true });
    try {
      const purchaseTermLocked = await checkPurchaseTermLockedSale(saledata.sale_id, saledata.sale_purchaseterm_id);
      if (purchaseTermLocked.length > 0) {
        throw { data: "Cannot change Purchase Term. Producer invoice/advance created." };
      }

      /*
        Check if any of the pallets are connected to a Proforma Invoice.
        If yes, then prompt the user to remove the Proforma Invoice otherwise they cannot continue with creating the invoice.
      */
      if (checkProforma) {
        const proformaInvoiceExists = await proformaExistsForPallets(
          saleDetails.map((detail) => detail.barcode_id),
          saledata.sale_id,
        );
        if (proformaInvoiceExists.length > 0) {
          this.setState({ submitting: false, proformaInvoiceExists, sale: { saledata, saleDetails, printRequested, ReportType } });
          return;
        }
      }

      const clientsResult = await clientsByCode(saledata.loadout_consignee);
      const formattedSaleDetail = saleDetails.map((sditem) => ({
        id: sditem.id,
        producer_id: sditem.producer_id,
        barcode: sditem.loadoutdetail_barcode,
        barcode_id: sditem.barcode_id,
        commodityCode: sditem.barcode_commoditycode,
        varietyCode: sditem.barcode_varietycode,
        gradeCode: sditem.barcode_gradecode,
        countCode: sditem.barcode_countcode,
        packCode: sditem.barcode_packcode,
        markCode: sditem.barcode_markcode,
        sellingprice: parseFloat(sditem.stockdetail_sellingprice.toString()),
        client: clientsResult && clientsResult.data && clientsResult.data.length > 0 ? clientsResult.data[0].code : saledata.loadout_consignee,
        po: saledata.client_po,
      }));

      // Knex Transaction to insert/update sale record
      const sale = {
        sale: {
          id: saledata.sale_id,
          dispatch_id: saledata.dispatch_id,
          date: saledata.sale_date ? saledata.sale_date : new Date(),
          invoicenumber: saledata.sale_invoicenumber,
          invoicenumber_short: saledata.sale_invoicenumber.substring(0, 2),
          exchangerate: saledata.sale_exchangerate,
          ucr: saledata.sale_ucr,
          trader_id: saledata.sale_trader_id,
          terms_id: saledata.sale_terms_id == -1 ? null : saledata.sale_terms_id,
          ata: saledata.sale_ata,
          notifyparty_id: saledata.sale_notifyparty_id,
          payment1amount: saledata.sale_terms_id == -1 ? saledata.sale_payment1amount : null,
          payment1date: saledata.sale_terms_id == -1 ? (isNullOrUndef(saledata.sale_payment1date) ? new Date() : saledata.sale_payment1date) : null,
          payment2amount: saledata.sale_terms_id == -1 ? saledata.sale_payment2amount : null,
          payment2date: saledata.sale_terms_id == -1 ? (isNullOrUndef(saledata.sale_payment2date) ? new Date() : saledata.sale_payment2date) : null,
          payment3amount: saledata.sale_terms_id == -1 ? saledata.sale_payment3amount : null,
          payment3date: saledata.sale_terms_id == -1 ? (isNullOrUndef(saledata.sale_payment3date) ? new Date() : saledata.sale_payment3date) : null,
          clients_id: clientsResult && clientsResult.data && clientsResult.data.length > 0 ? clientsResult.data[0].id : saledata.dispatch_consignee_id,
          purchaseterm_id: saledata.sale_purchaseterm_id,
        },
        dispatchData: {
          id: saledata.dispatch_id,
          currency_id: saledata.dispatch_currency_id,
          dealtype: saledata.dispatch_dealtype,
          sellingterm: saledata.dispatch_sellingterm,
          containerno: saledata.dispatch_containerno,
          containertype: saledata.dispatch_containertype,
          sealnumber: saledata.dispatch_sealnumber,
          consignee_id: saledata.dispatch_consignee_id,
          clearingagent_id: saledata.dispatch_clearingagent_id,
          forwardagentref: saledata.dispatch_forwardagentref,
          invoicenumber: saledata.sale_invoicenumber,
          remarks: saledata.dispatch_remarks,
        },
        loadoutData: {
          id: saledata.loadout_id,
          agent_id: saledata.agent_id,
          exportNotificationReference: saledata.loadout_exportNotificationReference,
          vessel_id: saledata.vessel_id,
          voyageNumber: saledata.loadout_voyageNumber,
          portLoading_id: saledata.portloading_id,
          portDischarge_id: saledata.portdistcharge_id,
          portFinal_id: saledata.portfinal_id,
          eta: saledata.loadout_eta,
          etd: saledata.loadout_etd,
          updated_eta: saledata.loadout_updated_eta,
          consignee: clientsResult && clientsResult.data && clientsResult.data.length > 0 ? clientsResult.data[0].code : saledata.loadout_consignee,
        },
        saleDetail: formattedSaleDetail,
        dispatchDocsData: {
          dispatch_id: saledata["dispatch_id"],
          ["Load Date"]: saledata["dispatch_loaddate"],
          ["Dispatch Doc Ref"]: saledata.sale_invoicenumber,
        },
      };
      this.setState({ sale: {}, proformaInvoiceExists: [] });
      await upsertSale(sale);

      if (printRequested) {
        switch (ReportType) {
          case Reports.PackingListPDF:
            handlePrintPackingListPDF(`${saledata.dispatch_id}`, Reports.PackingListPDF).catch((error) => {
              const err = GenerateErrorMessage(error, "Error printing Invoice");
              this.context.updateSnack({ show: true, color: "red", message: err });
            });
            break;
          case Reports.InvoiceExcel:
            window.location.href = `/api/sale/ext/exportSaleInvoiceExcel/${saledata.dispatch_id}`;
            break;
          case Reports.Invoice:
            handlePrintInvoice(`${saledata.dispatch_id}`).catch((error) => {
              const err = GenerateErrorMessage(error, "Error printing Invoice");
              this.context.updateSnack({ show: true, color: "red", message: err });
            });
            break;
          case Reports.PackingListExcel:
            window.location.href = `/api/sale/ext/exportPackingList/${saledata.dispatch_id}`;
          case "ADDENDUM_EXCEL":
            window.location.href = `/api/edistockpalletsdispatched/ext/printAddendumExcel/${saledata.dispatch_id}`;
            break;
          default:
            break;
        }
      } else {
        await this.setState({
          sale: {},
          proformaInvoiceExists: [],
          selectedData: undefined,
          selectedSale: undefined,
          snackshow: true,
          snackmessage: "Invoice complete",
          snackcolor: "green",
        });
        this.onReload();
      }
    } catch (error) {
      const err = GenerateErrorMessage(error, "Error saving sale record");
      this.context.updateSnack({ show: true, color: "red", message: err });
    }
    this.setState({ submitting: false, sale: {}, proformaInvoiceExists: [] });
    // insert consolidation data queue entry
    // await addConsolidationData(saledata.sale_invoicenumber);
    // addConsolidationDataStartJob();
  };

  onAcceptCancel = async () => {
    this.setState({ selectedData: undefined, selectedSale: undefined }, () => {
      this.onReload();
    });
  };

  onSnackClose = () => {
    this.setState({ snackshow: undefined, snackmessage: undefined });
  };

  onRelease = (selectedSale: SaleDispatchJoinedType) => {
    this.setState({ releaseData: selectedSale });
  };

  onReleaseClose = () => {
    this.setState({ releaseData: undefined });
  };

  onReleaseConfirm = async () => {
    try {
      await sale.remove(this.state.releaseData.sale_id);
      if (this.state.releaseData.sale_type && this.state.releaseData.sale_type == "1") {
        if (this.state.releaseData.loadout_id && this.state.releaseData.loadout_id != 0) {
          await loadout.update(this.state.releaseData.loadout_id, { data: { dispatch_id: null } });
        }
        if (this.state.releaseData.dispatch_id && this.state.releaseData.dispatch_id != 0) {
          await dispatch.remove(this.state.releaseData.dispatch_id);
        }
      }
    } catch (error) {
      const err = GenerateErrorMessage(error, "Error Removing data");
      this.context.updateSnack({ show: true, color: "red", message: err });
    }
    this.setState({ releaseData: undefined });
    this.onReload();
  };

  closeProformaInvoiceDialog = () => {
    this.setState({ proformaInvoiceExists: [] });
  };

  confirmProformaInvoiceCleanup = async () => {
    const { saledata, saleDetails, printRequested, ReportType }: any = this.state.sale;
    await this.onAcceptProcess(saledata, saleDetails, printRequested, ReportType, false);
  };

  render() {
    return (
      <div className={this.state.classes.root}>
        {this.state.loading && <CircularProgress />}
        {this.state.snackshow && (
          <div>
            <SnackMessage isOpen={this.state.snackshow} message={this.state.snackmessage} color={this.state.snackcolor} handleClose={this.onSnackClose} />
          </div>
        )}
        {this.state.loading && (
          <div>
            <LinearProgress />
          </div>
        )}
        {this.state.releaseData && (
          <Confirmation
            isOpen={true}
            handleClose={this.onReleaseClose}
            handleConfirm={this.onReleaseConfirm}
            title="Remove selected Invoice?"
            body="Are you sure you want to REMOVE the selected Invoice Record?"
          ></Confirmation>
        )}
        {this.state.proformaInvoiceExists.length > 0 && (
          <Confirmation
            isOpen
            handleClose={this.closeProformaInvoiceDialog}
            handleConfirm={this.confirmProformaInvoiceCleanup}
            title="Proforma Invoice Found"
            body="Proforma Invoice found. Do you want to copy the selling price and remove Proforma Invoice?"
          ></Confirmation>
        )}
        {this.state.selectedData && (
          <Accept handleClose={this.onAcceptCancel} onSubmit={this.onAcceptProcess} defaultData={this.state.selectedSale} submitting={this.state.submitting} />
        )}
        {!this.state.loading && !this.state.selectedData && (
          <InvoiceTable data={this.state.sales} onReload={this.onReload} onAccept={this.onAccept} onRelease={this.onRelease} isProforma={this.state.proforma} />
        )}
      </div>
    );
  }
}

export default withStyles(styles)(InvoiceUnstyled);
