import React from "react";

import CustomAutoCompleteFilter from "../lib/components/customdatagridfilter/CustomFilter";
import { FormatterNumber, FormatterNumber2Decimals, FormatterTypes } from "../lib/components/Formatters";
import { SummaryNumber, SummaryNumber2Decimal, SummaryTotal } from "../lib/components/SummaryFormatter";
import TextEditor from "../lib/components/TextEditor";
import numeral from "numeral";
import { handleColumnsArrangement, handleColumnsWidth } from "../lib/helpers/grid";

const cellWidthSmall = 80;
const cellWidthMedium = 150;

const FormatterHyperlink = ({ row, column }, previouspriceclick) => {
  // return <FormatterNumber2Decimals row={row} column={column} onDoubleClickHandler={() => previouspriceclick(row)} />;
  const data = row[column.key];
  return (
    <div
      id="formatter2decimal"
      style={{ textAlign: "right" }}
      onClick={() => {
        previouspriceclick(row);
      }}
    >
      {data ? numeral(data).format("0,0.00") : 0}
    </div>
  );
};

const defaultColumnProperties = {
  resizable: true,
  sortable: true,
  filterable: true,
};

let currentSpace = true;

export const GridColumns = (data, filters, arrangement, columnsWidth = undefined, handlePreviousPriceClick) => {
  let postValue = "";

  if (data) {
    if (currentSpace) {
      postValue = " ";
    }
    currentSpace = !currentSpace;
  }

  const columns = [
    {
      key: "consigneeId",
      name: "Consignee" + postValue,
      filterable: true,
      sortable: true,
      resizable: true,
      width: cellWidthSmall,
      filterRenderer: (e) => {
        return (
          <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"consigneeId"} data={data} section="stockpricing" enableAutoFilter={true} />
        );
      },
      summaryFormatter: SummaryTotal,
    },
    {
      key: "producerId",
      name: "Producer" + postValue,
      filterable: true,
      sortable: true,
      resizable: true,
      width: cellWidthSmall,
      filterRenderer: (e) => {
        return (
          <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"producerId"} data={data} section="stockpricing" enableAutoFilter={true} />
        );
      },
    },
    {
      key: "commodityCode",
      name: "Commodity" + postValue,
      filterable: true,
      sortable: true,
      resizable: true,
      width: cellWidthSmall,
      filterRenderer: (e) => {
        return (
          <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"commodityCode"} data={data} section="stockpricing" enableAutoFilter={true} />
        );
      },
    },
    {
      key: "varietyCode",
      name: "Variety" + postValue,
      filterable: true,
      sortable: true,
      resizable: true,
      width: cellWidthSmall,
      filterRenderer: (e) => {
        return (
          <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"varietyCode"} data={data} section="stockpricing" enableAutoFilter={true} />
        );
      },
    },
    {
      key: "gradeCode",
      name: "Grade" + postValue,
      filterable: true,
      sortable: true,
      resizable: true,
      width: cellWidthSmall,
      filterRenderer: (e) => {
        return (
          <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"gradeCode"} data={data} section="stockpricing" enableAutoFilter={true} />
        );
      },
    },
    {
      key: "countCode",
      name: "Count" + postValue,
      filterable: true,
      sortable: true,
      resizable: true,
      width: cellWidthSmall,
      filterRenderer: (e) => {
        return (
          <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"countCode"} data={data} section="stockpricing" enableAutoFilter={true} />
        );
      },
    },
    {
      key: "packCode",
      name: "Pack" + postValue,
      filterable: true,
      sortable: true,
      resizable: true,
      width: cellWidthSmall,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"packCode"} data={data} section="stockpricing" enableAutoFilter={true} />;
      },
    },
    {
      key: "markCode",
      name: "Mark" + postValue,
      filterable: true,
      sortable: true,
      resizable: true,
      width: cellWidthSmall,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"markCode"} data={data} section="stockpricing" enableAutoFilter={true} />;
      },
    },
    {
      key: "inventoryCode",
      name: "Inventory" + postValue,
      filterable: true,
      sortable: true,
      resizable: true,
      width: cellWidthSmall,
      filterRenderer: (e) => {
        return (
          <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"inventoryCode"} data={data} section="stockpricing" enableAutoFilter={true} />
        );
      },
    },
    {
      key: "targetMarket",
      name: "TM" + postValue,
      filterable: true,
      sortable: true,
      resizable: true,
      width: cellWidthSmall,
      filterRenderer: (e) => {
        return (
          <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"targetMarket"} data={data} section="stockpricing" enableAutoFilter={true} />
        );
      },
    },
    {
      key: "targetCountry",
      name: "TC" + postValue,
      filterable: true,
      sortable: true,
      resizable: true,
      width: cellWidthSmall,
      filterRenderer: (e) => {
        return (
          <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"targetCountry"} data={data} section="stockpricing" enableAutoFilter={true} />
        );
      },
    },
    {
      key: "loadout_vessel",
      name: "Vessel" + postValue,
      filterable: true,
      sortable: true,
      resizable: true,
      width: cellWidthSmall,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"loadout_vessel"} data={data} section="stockpricing" />;
      },
    },
    {
      key: "palletsize",
      name: "No Pallets" + postValue,
      filterable: true,
      sortable: true,
      resizable: true,
      width: cellWidthSmall,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"palletsize"} data={data} section="stockpricing" />;
      },
      formatter: FormatterNumber2Decimals,
      summaryFormatter: SummaryNumber2Decimal,
    },
    {
      key: "sumcartons",
      name: "No Cartons" + postValue,
      filterable: true,
      sortable: true,
      resizable: true,
      width: cellWidthSmall,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"sumcartons"} data={data} section="stockpricing" />;
      },
      formatter: FormatterNumber,
      summaryFormatter: SummaryNumber,
    },
    {
      key: "currency_code",
      name: "Currency" + postValue,
      filterable: true,
      sortable: true,
      resizable: true,
      width: cellWidthSmall,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"currency_code"} data={data} section="stockpricing" />;
      },
    },
    {
      key: "previousprice",
      name: "Previous Price" + postValue,
      filterable: true,
      sortable: true,
      resizable: true,
      width: cellWidthSmall,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"previousprice"} data={data} section="stockpricing" />;
      },
      formatter: (data) => FormatterHyperlink(data, handlePreviousPriceClick),
    },
    {
      key: "sellingprice",
      name: "Selling Price" + postValue,
      filterable: true,
      sortable: true,
      resizable: true,
      width: cellWidthSmall,
      editable: true,
      editor: (props) => <TextEditor {...props} align="right" />,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"sellingprice"} data={data} section="stockpricing" />;
      },
      formatter: FormatterNumber2Decimals,
    },
    {
      key: "comment",
      name: "Comment" + postValue,
      filterable: true,
      sortable: true,
      resizable: true,
      width: cellWidthSmall,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"comment"} data={data} section="stockpricing" />;
      },
      editable: true,
      editor: (props) => <TextEditor {...props} align="right" />,
    },
  ].map((c) => ({ ...c, ...defaultColumnProperties }));

  let columnsArranged = [];
  columnsArranged = handleColumnsArrangement(columnsArranged, arrangement, columns);
  columnsArranged = handleColumnsWidth(columnsArranged, columnsWidth, columns);

  return columnsArranged;
};
