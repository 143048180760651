export const creditorsPaymentTerms = [
  { id: 1, name: "30 days Nett", description: "invoice date + 30 days" },
  { id: 2, name: "30 days Statement", description: "invoice date + 30, if it falls in the next month, it is due last day of that month" },
  { id: 3, name: "7 days", description: "invoice date + 7 days" },
  { id: 4, name: "10 days", description: "invoice date + 10 days" },
];

export const getcreditorsPaymentTermsCombo = async () => {
  return creditorsPaymentTerms.sort((a, b) => a.name.localeCompare(b.name)).map((term) => ({ id: term.id, value: term.id, display: term.name }));
};
