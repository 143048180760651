import React from "react";

import CustomAutoCompleteFilter from "../../../lib/components/customdatagridfilter/CustomFilter";
import { DateFormatter, FormatterNumber2Decimals } from "../../../lib/components/Formatters";
import { EnquiryLink, Selector } from "../../../lib/components/EnquiryLink";
import { CheckboxSelect } from "../../../lib/components/CheckboxSelect";
import { handleColumnsArrangement, handleColumnsWidth } from "../../../lib/helpers/grid";

const cellWidthAction = 50;
const cellWidthMedium = 120;
const cellWidthLarge = 200;

const defaultColumnProperties = {
  resizable: true,
  sortable: true,
};

let currentSpace = true;

export const PaymentOverviewGridColumns = (data, filters, arrangement, columnsWidth, selectedRows, handleSelect) => {
  let postValue = "";

  if (data) {
    if (currentSpace) {
      postValue = " ";
    }
    currentSpace = !currentSpace;
  }

  const columns = [
    {
      key: "",
      name: "#C" + postValue,
      width: cellWidthAction,
      formatter: ({ row }) => <CheckboxSelect row={row} selectedRows={selectedRows} handleCheckboxSelect={handleSelect} valueKey={"ident"} />,
    },
    {
      key: "ident",
      name: "Ident" + postValue,
      width: cellWidthMedium,
      filterable: true,
      formatter: (props) => <EnquiryLink {...props} selector={Selector.InvoiceNumber} />,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"ident"} data={data} section="finalpaymentoverviewgrid" />;
      },
    },
    {
      key: "invoicenumbers",
      name: "Invoice Number(s)" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"invoicenumbers"} data={data} section="finalpaymentoverviewgrid" />;
      },
    },
    {
      key: "amount",
      name: "Amount" + postValue,
      width: cellWidthMedium,
      filterable: true,
      formatter: FormatterNumber2Decimals,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"amount"} data={data} section="finalpaymentoverviewgrid" />;
      },
    },
    {
      key: "week",
      name: "Week" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"week"} data={data} section="finalpaymentoverviewgrid" />;
      },
    },
    {
      key: "currency",
      name: "Currency Code" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"currency"} data={data} section="finalpaymentoverviewgrid" />;
      },
    },
    {
      key: "makeanote",
      name: "Note" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"makeanote"} data={data} section="finalpaymentoverviewgrid" />;
      },
    },
    {
      key: "payment_date",
      name: "Date" + postValue,
      width: cellWidthMedium,
      filterable: true,
      formatter: DateFormatter,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"payment_date"} data={data} section="finalpaymentoverviewgrid" />;
      },
    },
  ].map((c) => ({ ...c, ...defaultColumnProperties }));

  let columnsArranged = [];
  columnsArranged = handleColumnsArrangement(columnsArranged, arrangement, columns);
  columnsArranged = handleColumnsWidth(columnsArranged, columnsWidth, columns);

  return columnsArranged;
};
