import React from "react";

import IconEdit from "@material-ui/icons/Edit";
import IconRemove from "@material-ui/icons/Remove";
import IconCategory from "@material-ui/icons/Category";
import DeleteIcon from "@material-ui/icons/Delete";

import CustomAutoCompleteFilter from "../../lib/components/customdatagridfilter/CustomFilter";
import { CreditorsInvoiceFullType } from "../../lib/api/creditorsinvoice";
import { CellActionsFormatter } from "../../lib/helpers/CellActionsFormatter";
import { DateFormatter, FormatterNumber2Decimals } from "../../lib/components/Formatters";
import { handleColumnsArrangement, handleColumnsWidth } from "../../lib/helpers/grid";
import { SummaryNumber2Decimal } from "../../lib/components/SummaryFormatter";
import RightAlignHeaderColumn from "../../lib/components/gridhelper/RightAlignHeaderColumn";

const cellWidthSmall = 90;
const cellWidthSmallX = 120;
const cellWidthMedium = 150;
const cellWidthLarge = 200;

const defaultColumnProperties = {
  resizable: true,
  sortable: true,
};
let currentSpace = true;
export const GridColumns = (data, filters, arrangement, columnsWidth, handleEdit, handleRemove, handleAdjust) => {
  let postValue = "";

  if (data) {
    if (currentSpace) {
      postValue = " ";
    }
    currentSpace = !currentSpace;
  }

  const columns = [
    {
      key: "actions",
      name: "#A",
      width: cellWidthSmallX,
      formatter: ({ row }) => {
        const actions = cellActions(row, handleEdit, handleRemove, handleAdjust);
        return (
          <div style={{ display: "flex", justifyContent: "left", gap: "5px" }}>
            <CellActionsFormatter actions={actions} />
          </div>
        );
      },
    },
    {
      key: "creditorsinvoice_type",
      name: "Type" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return (
          <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"creditorsinvoice_type"} data={data} section="creditorsinvoicegridsetup" />
        );
      },
    },
    {
      key: "invoicedate",
      name: "Invoice Date" + postValue,
      width: cellWidthMedium,
      filterable: true,
      formatter: DateFormatter,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"invoicedate"} data={data} section="creditorsinvoicegridsetup" />;
      },
    },
    {
      key: "creditors_code",
      name: "Creditor Code" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"creditors_code"} data={data} section="creditorsinvoicegridsetup" />;
      },
    },
    {
      key: "creditors_name",
      name: "Creditor Name" + postValue,
      width: cellWidthLarge,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"creditors_name"} data={data} section="creditorsinvoicegridsetup" />;
      },
    },
    {
      key: "invoicenumber",
      name: "Invoice Number" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"invoicenumber"} data={data} section="creditorsinvoicegridsetup" />;
      },
    },
    {
      key: "currency_code",
      name: "Currency" + postValue,
      width: cellWidthSmall,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"currency_code"} data={data} section="creditorsinvoicegridsetup" />;
      },
    },
    {
      key: "validate_totalexcl",
      name: "Total (excl)" + postValue,
      width: cellWidthMedium,
      filterable: true,
      formatter: FormatterNumber2Decimals,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"validate_totalexcl"} data={data} section="creditorsinvoicegridsetup" />;
      },
      summaryFormatter: SummaryNumber2Decimal,
      headerRenderer: RightAlignHeaderColumn,
    },
    {
      key: "validate_vat",
      name: "VAT" + postValue,
      width: cellWidthMedium,
      filterable: true,
      formatter: FormatterNumber2Decimals,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"validate_vat"} data={data} section="creditorsinvoicegridsetup" />;
      },
      summaryFormatter: SummaryNumber2Decimal,
      headerRenderer: RightAlignHeaderColumn,
    },
    {
      key: "validate_total",
      name: "Total" + postValue,
      width: cellWidthMedium,
      filterable: true,
      formatter: FormatterNumber2Decimals,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"validate_total"} data={data} section="creditorsinvoicegridsetup" />;
      },
      summaryFormatter: SummaryNumber2Decimal,
      headerRenderer: RightAlignHeaderColumn,
    },
    {
      key: "saleinvoicenumbers",
      name: "Sales Invoice Numbers" + postValue,
      width: cellWidthLarge,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"saleinvoicenumbers"} data={data} section="creditorsinvoicegridsetup" />;
      },
    },
    {
      key: "due_date",
      name: "Due Date" + postValue,
      width: cellWidthMedium,
      filterable: true,
      formatter: DateFormatter,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"due_date"} data={data} section="creditorsinvoicegridsetup" />;
      },
    },
    {
      key: "adjustdate",
      name: "Adjust Date" + postValue,
      width: cellWidthMedium,
      filterable: true,
      formatter: DateFormatter,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"adjustdate"} data={data} section="creditorsinvoicegridsetup" />;
      },
    },
  ].map((c) => ({ ...c, ...defaultColumnProperties }));

  let columnsArranged = [];
  columnsArranged = handleColumnsArrangement(columnsArranged, arrangement, columns);
  columnsArranged = handleColumnsWidth(columnsArranged, columnsWidth, columns);

  return columns;
};

const cellActions = (row: CreditorsInvoiceFullType, handleEdit, handleRemove, handleAdjust) => {
  // if (row.camend_status != 0) {
  //   return [];
  // }

  const actions = [
    {
      icon: (
        <span title="Remove">
          <DeleteIcon style={{ cursor: "pointer", marginTop: "5px", color: "red" }} />
        </span>
      ),
      callback: () => {
        handleRemove(row);
      },
    },
  ];
  if (row.document_type == "1") {
    actions.push({
      icon: (
        <span title="Adjust">
          <IconCategory style={{ cursor: "pointer", marginTop: "5px", color: "#4a9de2" }} />
        </span>
      ),
      callback: () => {
        handleAdjust(row);
      },
    });
  }
  actions.push({
    icon: (
      <span title="Edit">
        <IconEdit style={{ cursor: "pointer", marginTop: "5px", color: "orange" }} />
      </span>
    ),
    callback: () => {
      handleEdit(row);
    },
  });
  return actions;
};
