import React from "react";

import CustomAutoCompleteFilter from "../lib/components/customdatagridfilter/CustomFilter";
import { DateFormatter, FormatterNumber, FormatterNumber2Decimals } from "../lib/components/Formatters";

const cellWidthSmall = 65;
const cellWidthSmallX = 90;
const cellWidthMedium = 100;
const cellWidthLarge = 170;

const defaultColumnProperties = {
  filterable: true,
  resizable: true,
  sortable: true,
};

let currentSpace = true;

export const EnquiryProducersFullGridColumns = (data, filters, arrangement, columnsWidth) => {
  let postValue = "";

  if (data) {
    if (currentSpace) {
      postValue = " ";
    }
    currentSpace = !currentSpace;
  }

  const columns = [
    {
      key: "sale_invoicenumber",
      name: "Invoice Number" + postValue,
      width: cellWidthMedium,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"sale_invoicenumber"} data={data} section="enquiryproducersfullgrid" />;
      },
    },
    {
      key: "dispatch_currencycode",
      name: "Currency" + postValue,
      width: cellWidthMedium,
      filterRenderer: (e) => {
        return (
          <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"dispatch_currencycode"} data={data} section="enquiryproducersfullgrid" />
        );
      },
    },
    {
      key: "bar_intakewaybill",
      name: "Intake Waybill" + postValue,
      width: cellWidthMedium,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"bar_intakewaybill"} data={data} section="enquiryproducersfullgrid" />;
      },
    },
    {
      key: "barcode",
      name: "Barcode" + postValue,
      width: cellWidthMedium,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"barcode"} data={data} section="enquiryproducersfullgrid" />;
      },
    },
    {
      key: "bar_CommodityCode",
      name: "Commodity Code" + postValue,
      width: cellWidthMedium,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"bar_CommodityCode"} data={data} section="enquiryproducersfullgrid" />;
      },
    },
    {
      key: "bar_VarietyCode",
      name: "Variety Code" + postValue,
      width: cellWidthMedium,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"bar_VarietyCode"} data={data} section="enquiryproducersfullgrid" />;
      },
    },
    {
      key: "bar_GradeCode",
      name: "Grade Code" + postValue,
      width: cellWidthMedium,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"bar_GradeCode"} data={data} section="enquiryproducersfullgrid" />;
      },
    },
    {
      key: "bar_CountCode",
      name: "Count Code" + postValue,
      width: cellWidthMedium,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"bar_CountCode"} data={data} section="enquiryproducersfullgrid" />;
      },
    },
    {
      key: "bar_PackCode",
      name: "Pack Code" + postValue,
      width: cellWidthMedium,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"bar_PackCode"} data={data} section="enquiryproducersfullgrid" />;
      },
    },
    {
      key: "bar_MarkCode",
      name: "Mark Code" + postValue,
      width: cellWidthMedium,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"bar_MarkCode"} data={data} section="enquiryproducersfullgrid" />;
      },
    },
    {
      key: "bar_InventoryCode",
      name: "Inventory Code" + postValue,
      width: cellWidthMedium,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"bar_InventoryCode"} data={data} section="enquiryproducersfullgrid" />;
      },
    },
    {
      key: "bar_NoCartons",
      name: "No Cartons" + postValue,
      width: cellWidthMedium,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"bar_NoCartons"} data={data} section="enquiryproducersfullgrid" />;
      },
      formatter: FormatterNumber,
    },
    {
      key: "bar_PalletSize",
      name: "Pallet Size" + postValue,
      width: cellWidthMedium,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"bar_PalletSize"} data={data} section="enquiryproducersfullgrid" />;
      },
      formatter: FormatterNumber2Decimals,
    },
    {
      key: "bar_LocationCode",
      name: "Location Code" + postValue,
      width: cellWidthMedium,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"bar_LocationCode"} data={data} section="enquiryproducersfullgrid" />;
      },
    },
    {
      key: "bar_FarmNumber",
      name: "Farm Number" + postValue,
      width: cellWidthMedium,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"bar_FarmNumber"} data={data} section="enquiryproducersfullgrid" />;
      },
    },
    {
      key: "bar_TargetMarket",
      name: "Target Market" + postValue,
      width: cellWidthMedium,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"bar_TargetMarket"} data={data} section="enquiryproducersfullgrid" />;
      },
    },
    {
      key: "bar_TargetCountry",
      name: "Target Country" + postValue,
      width: cellWidthMedium,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"bar_TargetCountry"} data={data} section="enquiryproducersfullgrid" />;
      },
    },
    {
      key: "bar_TargetRegion",
      name: "Target Region" + postValue,
      width: cellWidthMedium,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"bar_TargetRegion"} data={data} section="enquiryproducersfullgrid" />;
      },
    },
    {
      key: "producer_name",
      name: "Producer Name" + postValue,
      width: cellWidthMedium,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"producer_name"} data={data} section="enquiryproducersfullgrid" />;
      },
    },
    {
      key: "bar_ProducerID",
      name: "Producer ID" + postValue,
      width: cellWidthMedium,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"bar_ProducerID"} data={data} section="enquiryproducersfullgrid" />;
      },
    },
    {
      key: "bar_ColdDate",
      name: "Cold Date" + postValue,
      width: cellWidthMedium,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"bar_ColdDate"} data={data} section="enquiryproducersfullgrid" />;
      },
      formatter: DateFormatter,
    },
    {
      key: "weekcold_week",
      name: "Cold Week" + postValue,
      width: cellWidthMedium,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"weekcold_week"} data={data} section="enquiryproducersfullgrid" />;
      },
    },
    {
      key: "wkcolddue_week",
      name: "Cold Week Due" + postValue,
      width: cellWidthMedium,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"wkcolddue_week"} data={data} section="enquiryproducersfullgrid" />;
      },
    },
    {
      key: "coldCartonValueZAR",
      name: "Cold Carton Value ZAR" + postValue,
      width: cellWidthMedium,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"coldCartonValueZAR"} data={data} section="enquiryproducersfullgrid" />;
      },
      formatter: FormatterNumber2Decimals,
    },
    {
      key: "coldCartonValueCAD",
      name: "Cold Carton Value CAD" + postValue,
      width: cellWidthMedium,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"coldCartonValueCAD"} data={data} section="enquiryproducersfullgrid" />;
      },
      formatter: FormatterNumber2Decimals,
    },
    {
      key: "coldCartonValueEUR",
      name: "Cold Carton Value EUR" + postValue,
      width: cellWidthMedium,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"coldCartonValueEUR"} data={data} section="enquiryproducersfullgrid" />;
      },
      formatter: FormatterNumber2Decimals,
    },
    {
      key: "coldCartonValueGBP",
      name: "Cold Carton Value GBP" + postValue,
      width: cellWidthMedium,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"coldCartonValueGBP"} data={data} section="enquiryproducersfullgrid" />;
      },
      formatter: FormatterNumber2Decimals,
    },
    {
      key: "coldCartonValueUSD",
      name: "Cold Carton Value USD" + postValue,
      width: cellWidthMedium,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"coldCartonValueUSD"} data={data} section="enquiryproducersfullgrid" />;
      },
      formatter: FormatterNumber2Decimals,
    },
    {
      key: "bar_OrigIntakeDate",
      name: "Orig Intake Date" + postValue,
      width: cellWidthMedium,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"bar_OrigIntakeDate"} data={data} section="enquiryproducersfullgrid" />;
      },
      formatter: DateFormatter,
    },
    {
      key: "weekintake_week",
      name: "Intake Week" + postValue,
      width: cellWidthMedium,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"weekintake_week"} data={data} section="enquiryproducersfullgrid" />;
      },
    },
    {
      key: "wkintakedue_week",
      name: "Intake Week Due" + postValue,
      width: cellWidthMedium,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"wkintakedue_week"} data={data} section="enquiryproducersfullgrid" />;
      },
    },
    {
      key: "intakeCartonValueZAR",
      name: "Intake Carton Value ZAR" + postValue,
      width: cellWidthMedium,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"intakeCartonValueZAR"} data={data} section="enquiryproducersfullgrid" />;
      },
      formatter: FormatterNumber2Decimals,
    },
    {
      key: "intakeCartonValueCAD",
      name: "Intake Carton Value CAD" + postValue,
      width: cellWidthMedium,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"intakeCartonValueCAD"} data={data} section="enquiryproducersfullgrid" />;
      },
      formatter: FormatterNumber2Decimals,
    },
    {
      key: "intakeCartonValueEUR",
      name: "Intake Carton Value EUR" + postValue,
      width: cellWidthMedium,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"intakeCartonValueEUR"} data={data} section="enquiryproducersfullgrid" />;
      },
      formatter: FormatterNumber2Decimals,
    },
    {
      key: "intakeCartonValueGBP",
      name: "Intake Carton Value GBP" + postValue,
      width: cellWidthMedium,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"intakeCartonValueGBP"} data={data} section="enquiryproducersfullgrid" />;
      },
      formatter: FormatterNumber2Decimals,
    },
    {
      key: "intakeCartonValueUSD",
      name: "Intake Carton Value USD" + postValue,
      width: cellWidthMedium,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"intakeCartonValueUSD"} data={data} section="enquiryproducersfullgrid" />;
      },
      formatter: FormatterNumber2Decimals,
    },
    {
      key: "sale_date",
      name: "Sale Date" + postValue,
      width: cellWidthMedium,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"sale_date"} data={data} section="enquiryproducersfullgrid" />;
      },
      formatter: DateFormatter,
    },
    {
      key: "weekshipping_week",
      name: "Shipping Week" + postValue,
      width: cellWidthMedium,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"weekshipping_week"} data={data} section="enquiryproducersfullgrid" />;
      },
    },
    {
      key: "wkshippingdue_week",
      name: "Shipping Week Due" + postValue,
      width: cellWidthMedium,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"wkshippingdue_week"} data={data} section="enquiryproducersfullgrid" />;
      },
    },
    {
      key: "shippingCartonValueZAR",
      name: "Shipping Carton Value ZAR" + postValue,
      width: cellWidthMedium,
      filterRenderer: (e) => {
        return (
          <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"shippingCartonValueZAR"} data={data} section="enquiryproducersfullgrid" />
        );
      },
      formatter: FormatterNumber2Decimals,
    },
    {
      key: "shippingCartonValueCAD",
      name: "Shipping Carton Value CAD" + postValue,
      width: cellWidthMedium,
      filterRenderer: (e) => {
        return (
          <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"shippingCartonValueCAD"} data={data} section="enquiryproducersfullgrid" />
        );
      },
      formatter: FormatterNumber2Decimals,
    },
    {
      key: "shippingCartonValueEUR",
      name: "Shipping Carton Value EUR" + postValue,
      width: cellWidthMedium,
      filterRenderer: (e) => {
        return (
          <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"shippingCartonValueEUR"} data={data} section="enquiryproducersfullgrid" />
        );
      },
      formatter: FormatterNumber2Decimals,
    },
    {
      key: "shippingCartonValueGBP",
      name: "Shipping Carton Value GBP" + postValue,
      width: cellWidthMedium,
      filterRenderer: (e) => {
        return (
          <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"shippingCartonValueGBP"} data={data} section="enquiryproducersfullgrid" />
        );
      },
      formatter: FormatterNumber2Decimals,
    },
    {
      key: "shippingCartonValueUSD",
      name: "Shipping Carton Value USD" + postValue,
      width: cellWidthMedium,
      filterRenderer: (e) => {
        return (
          <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"shippingCartonValueUSD"} data={data} section="enquiryproducersfullgrid" />
        );
      },
      formatter: FormatterNumber2Decimals,
    },
    {
      key: "paid_zar",
      name: "PAID ZAR" + postValue,
      width: cellWidthMedium,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"paid_zar"} data={data} section="enquiryproducersfullgrid" />;
      },
      formatter: FormatterNumber2Decimals,
    },
    {
      key: "paid_cad",
      name: "PAID CAD" + postValue,
      width: cellWidthMedium,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"paid_cad"} data={data} section="enquiryproducersfullgrid" />;
      },
      formatter: FormatterNumber2Decimals,
    },
    {
      key: "paid_eur",
      name: "PAID EUR" + postValue,
      width: cellWidthMedium,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"paid_eur"} data={data} section="enquiryproducersfullgrid" />;
      },
      formatter: FormatterNumber2Decimals,
    },
    {
      key: "paid_gbp",
      name: "PAID GBP" + postValue,
      width: cellWidthMedium,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"paid_gbp"} data={data} section="enquiryproducersfullgrid" />;
      },
      formatter: FormatterNumber2Decimals,
    },
    {
      key: "paid_usd",
      name: "PAID USD" + postValue,
      width: cellWidthMedium,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"paid_usd"} data={data} section="enquiryproducersfullgrid" />;
      },
      formatter: FormatterNumber2Decimals,
    },
    {
      key: "paid_currency",
      name: "PAID Currency" + postValue,
      width: cellWidthMedium,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"paid_currency"} data={data} section="enquiryproducersfullgrid" />;
      },
    },
  ].map((c) => ({ ...c, ...defaultColumnProperties }));

  const columnsArranged = [];
  if (arrangement && arrangement.length > 0) {
    arrangement.map((item) => {
      const indexOfItem = columns.indexOf(columns.find((col) => col.name.trim() == item.toString().trim()));
      if (indexOfItem != -1) {
        columnsArranged.push(columns[indexOfItem]);
      }
    });
  } else {
    columns.map((colItem) => {
      columnsArranged.push(colItem);
    });
  }

  if (columnsWidth && columnsWidth.length > 0) {
    for (let index = 0; index < columnsArranged.length; index++) {
      const element = columnsArranged[index];
      const colItem = columnsWidth.find((colwitem) => colwitem.name.trim() == element.name.trim());
      if (colItem) {
        element.width = colItem.width;
      }
    }
  }

  return columnsArranged;
};
