import { apiHeaders, client, request } from "./_base";

export type ProducerFinalPaidHeadType = {
  id?: number;
  ident: number;
  makeanote: string;
  amount: number;
  currency_id: number;
  created_at?: Date;
  updated_at?: Date;
  payment_date: Date;
  week_id: number;
};

const endpoint = "producerfinalpaidhead";

export const producerfinalpaidhead = client(endpoint, (item) => ({
  ...item,
}));

export const producerfinalpaidheadGetLatestIdent = async () => {
  const result = await request(`${endpoint}/ext/getlatestident`, {
    headers: apiHeaders(),
    method: "GET",
  });
  const resp = await result.json();
  if (result.status == 500) {
    throw resp;
  }
  return resp;
};

export const getPaymentOverviewByProducer = async (producer: string, financial_year: number): Promise<ProducerFinalProducerGroupedProducer[]> => {
  const result = await request(`${endpoint}/ext/getPaymentOverviewByProducer?producer=${producer}&financial_year=${financial_year}`, {
    headers: apiHeaders(),
    method: "GET",
  });
  const resp = await result.json();
  if (result.status == 500) {
    throw resp;
  }
  return resp.data;
};

export type ProducerFinalProducerGroupedProducer = {
  paidheadid: number;
  ident: string;
  amount: number;
  week: string;
  currency: string;
  makeanote: string;
  payment_date: Date;
  invoicenumbers: string;
};
