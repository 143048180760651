import React, { FC, useCallback, useEffect, useMemo, useState, useContext } from "react";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";

import { Dropzone } from "../lib/helpers/Dropzone";
import { getInspectionNotes, upsertInspectionNote } from "../lib/api/inspection";

import Resizer from "react-image-file-resizer";
import LZString from "lz-string";

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { LinearProgress } from "@material-ui/core";

import IconDelete from "@material-ui/icons/Delete";
import { SnackContext } from "../lib/context/SnackContext";
import { GenerateErrorMessage } from "../lib/helpers/string_methods";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      height: "700px",
      width: "600px",
      display: "flex",
      flexDirection: "column",
      gap: "1rem",
    },
    imageWrapper: {
      maxHeight: "600px",
      overflowY: "auto",
      display: "flex",
      flexWrap: "wrap",
      gap: "1rem",
    },
    imageContainer: {
      position: "relative",
      width: "fit-content",
      maxHeight: "250px",
      height: "250px",
      cursor: "pointer",
    },
    imageActions: {
      position: "absolute",
      top: "1px",
      left: "1px",
      transform: "translate(-1px, -1px)",
      display: "flex",
      justifyContent: "flex-end",
      width: "100%",
      backgroundColor: "white",
      padding: "1rem",
      opacity: 0.9,
      height: "50px",
    },
    inspectionActions: {
      display: "flex",
      flexDirection: "row",
      gap: "1rem",
      justifyContent: "flex-end",
    },
    commentContainer: {
      display: "flex",
      flexDirection: "column",
      gap: "0.25rem",
      "& p": {
        lineHeight: "0",
        fontSize: "15px",
      },
    },
    image: {
      width: "auto",
      height: "auto",
      maxHeight: "250px",
      maxWidth: "100%",
      float: "left",
    },
  });

type InspectionEditProps = {
  inspectionId: number;
  handleClose(): void;
} & WithStyles<typeof styles>;

export const InspectionEditUnstyled: FC<InspectionEditProps> = ({ inspectionId, classes, handleClose }) => {
  const { updateSnack } = useContext(SnackContext);

  const [loading, setLoading] = useState(true);
  const [inspectionNote, setInspectionNote] = useState({ comment: "", images: [], inspection_id: inspectionId });

  const loadData = async () => {
    setLoading(true);
    try {
      const inspectionnote = await getInspectionNotes(inspectionId);
      if (inspectionnote) {
        setInspectionNote({ ...inspectionnote, images: JSON.parse(inspectionnote.images).map((image) => ({ image })) });
      }
    } catch (error) {
      const err = GenerateErrorMessage(error, "Error loading data");
      updateSnack({ show: true, color: "red", message: err });
    }
    setLoading(false);
  };

  const images = useMemo(() => (loading ? [] : inspectionNote.images || []), [inspectionNote, loading]);

  const base64 = async (file: File) => {
    return new Promise((res, rej) => Resizer.imageFileResizer(file, 800, 800, "JPEG", 60, 0, (uri: string) => res(LZString.compress(uri)), "base64"));
  };

  const onDrop = useCallback(
    async (acceptedFiles) => {
      setLoading(true);
      const newFiles = await Promise.all(Array.from(acceptedFiles).map((file: File) => base64(file)));
      const newArr = newFiles.map((image: any) => (image.image ? { image: image.image } : { image: image }));
      const fotosArr = [...images, ...newArr];
      setInspectionNote({ ...inspectionNote, images: fotosArr });
      setLoading(false);
    },
    [images, setLoading],
  );

  const handleSave = async () => {
    setLoading(true);
    try {
      const newInspection = { ...inspectionNote, images: JSON.stringify(inspectionNote.images.map((image) => image.image)) };
      await upsertInspectionNote(newInspection);
      await loadData();
      updateSnack({ show: true, color: "green", message: "Sucessfully submitted Inspection Note" });
    } catch (error) {
      const err = GenerateErrorMessage(error, "Error submitting Inspection Note");
      updateSnack({ show: true, color: "red", message: err });
    }
    setLoading(false);
  };

  const handleSetComment = (comment: string) => {
    setInspectionNote({ ...inspectionNote, comment });
  };

  const handleRemoveImage = (imageIndex: number) => {
    const inspectionImages = inspectionNote.images;
    const _ = inspectionImages.splice(imageIndex, 1);
    setInspectionNote({ ...inspectionNote, images: inspectionImages });
  };

  useEffect(() => {
    loadData();
  }, [inspectionId]);

  return (
    <>
      {loading ? <LinearProgress color="secondary" /> : <div style={{ height: "5px" }} />}
      <div className={classes.root}>
        <InspectionActions classes={classes} handleClose={handleClose} handleSave={handleSave} />
        <Comments classes={classes} setComment={handleSetComment} comment={inspectionNote.comment} />
        <Dropzone multiple onDrop={onDrop} file={images} />
        <ImagesContainer images={images} classes={classes} handleRemoveImage={handleRemoveImage} />
      </div>
    </>
  );
};

type CommentProps = {
  comment: string;
  setComment(comment: string): void;
} & WithStyles<typeof styles>;

const Comments: FC<CommentProps> = ({ classes, setComment, comment }) => {
  const handleChange = (e) => {
    setComment(e.target.value);
  };

  return (
    <div className={classes.commentContainer}>
      <p>Comment:</p>
      <TextField fullWidth multiline style={{ padding: 1 }} name="comment" variant="outlined" placeholder="Add comment here" defaultValue={comment} onBlur={handleChange} />
    </div>
  );
};

type ImagesContainerProps = {
  images: { image: string }[];
  handleRemoveImage(imageIndex: number): void;
} & WithStyles<typeof styles>;

const ImagesContainer: FC<ImagesContainerProps> = ({ images, classes, handleRemoveImage }) => {
  const [showActions, toggleActions] = useState<number>(undefined);

  const handleToggle = (imageIndex: number) => {
    toggleActions(showActions == imageIndex ? undefined : imageIndex);
  };

  return (
    <div className={classes.imageWrapper}>
      {images.map((image, indx) => (
        <div key={`container-${indx}`} className={classes.imageContainer} onClick={() => handleToggle(indx)}>
          {indx === showActions && (
            <div key={`actions-${indx}`} className={classes.imageActions}>
              <IconDelete style={{ cursor: "pointer", color: "red" }} onClick={() => handleRemoveImage(indx)} />
            </div>
          )}
          <img key={`image-${indx}`} src={LZString.decompress(image.image)} className={classes.image} />
        </div>
      ))}
    </div>
  );
};

type InspectionActionsProps = {
  handleSave(): void;
  handleClose(): void;
} & WithStyles<typeof styles>;

const InspectionActions: FC<InspectionActionsProps> = ({ classes, handleSave, handleClose }) => {
  return (
    <div className={classes.inspectionActions}>
      <Button type="button" variant="contained" color="secondary" onClick={handleClose}>
        Close
      </Button>
      <Button type="button" variant="contained" color="primary" onClick={handleSave}>
        Save
      </Button>
    </div>
  );
};

export const InspectionEdit = withStyles(styles)(InspectionEditUnstyled);
