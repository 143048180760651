import { apiHeaders, client, request } from "./_base";

export type ProducerFinalPaidAdHocDetailType = {
  id?: number;
  producerfinalpaidadhoc_id: number;
  producerfinalpaidhead_id: number;
  amount: number;
  created_at?: Date;
};

const endpoint = "producerfinalpaidadhocdetail";

export const producerfinalpaidadhocdetail = client(endpoint, (item) => ({
  ...item,
}));

export const adhocdetailByPaidHeadId = async (paidHeadId: number): Promise<any[]> =>
  request(`${endpoint}/ext/adhocdetailByPaidHeadId/${paidHeadId}`, {
    headers: apiHeaders(),
    method: "GET",
  })
    .then(async (result) => {
      if (result.status == 500) {
        const resp = await result.json();
        throw resp;
      }
      return result.json();
    })
    .then((result) => {
      return result.data;
    });
