import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Slide from "@material-ui/core/Slide";
import React from "react";
import { resetPassword } from "../../lib/api/users";
import { SnackContext } from "../../lib/context/SnackContext";
import { GenerateErrorMessage } from "../../lib/helpers/string_methods";
import { SetBooleanFunc, User } from "./Types";

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

type ResetPasswordConfirmProps = {
  enteredPassword: string;
  showConfirmDialog: boolean;
  setShowConfirmDialog: SetBooleanFunc;
  setHandleResetPwd: SetBooleanFunc;
  user: User;
};

export const ResetPasswordConfirm = ({ user, enteredPassword, showConfirmDialog, setShowConfirmDialog, setHandleResetPwd }: ResetPasswordConfirmProps) => {
  const { updateSnack } = React.useContext(SnackContext);

  const changePassword = async (enteredPassword: string) => {
    try {
      await resetPassword(user, enteredPassword);
      setShowConfirmDialog(false);
      setHandleResetPwd(false);
    } catch (error) {
      const err = GenerateErrorMessage(error, "Error updating password");
      updateSnack({ show: true, color: "red", message: err });
      return;
    }

    updateSnack({ show: true, color: "green", message: "Succesfully updated password!" });
  };

  const handleClose = (event: {}, reason: "backdropClick" | "escapeKeyDown"): void => {
    setShowConfirmDialog(false);
  };

  return (
    <Dialog TransitionComponent={Transition} maxWidth="sm" open={showConfirmDialog} onClose={handleClose}>
      <DialogContent>Are you sure you want to reset the password?</DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            setShowConfirmDialog(false);
          }}
          color="primary"
        >
          Close
        </Button>
        <Button onClick={() => changePassword(enteredPassword)} color="primary">
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};
