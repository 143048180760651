import React from "react";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";

import Button from "@material-ui/core/Button";
import LinearProgress from "@material-ui/core/LinearProgress";
import Toolbar from "@material-ui/core/Toolbar";
import InputLabel from "@material-ui/core/InputLabel";
import MTextField from "@material-ui/core/TextField";

import { Field, Form } from "react-final-form";
import { Row } from "react-data-grid";

import Grid from "../../lib/components/grid";
import Confirmation from "../../lib/components/confirmation";
import { SnackContext } from "../../lib/context/SnackContext";
import { stockadjustmentFull, updateStockAdjustment, deleteSelectedBarcodes, reverseSelectedBarcodes } from "../../lib/api/stockadjustment";
import { GreenButton, GreyButton, OrangeButton, RedButton } from "../../lib/components/ColorButtons";
import { getFinancialYearSelected } from "../../lib/api/week";
import { GridColumns } from "./stockadjustmentsetup";
import { getActiveProducersReadyForCombo } from "../../lib/api/producer";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1),
      width: "100%",
      height: "100%",
      position: "relative",
    },
    inline: {
      display: "inline",
      position: "absolute",
      marginTop: "7px",
      marginLeft: "7px",
    },
    toolbar: {
      position: "absolute",
      left: 0,
      top: 0,
      height: "60px",
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
      // gap: "15px",
    },
    disabledRow: {
      color: "orange",
    },
    normalRow: {
      color: "black",
    },
    greenRow: {
      color: "green",
    },
    redRow: {
      color: "red",
    },
  });

type StockAdjustmentProps = {} & WithStyles<typeof styles>;

export class StockAdjustmentUnstyled extends React.Component<StockAdjustmentProps, any> {
  state = {
    classes: undefined,
    data: [],
    dataChanged: [],
    confirmRemoveID: undefined,
    confirmEditID: undefined,
    loading: true,
    submitting: false,
    selected: {},
    confirmDelete: false,
    confirmReverse: false,
    producersList: [],
  };

  constructor(props) {
    super(props);
    this.state.classes = props.classes;
  }

  static contextType = SnackContext;
  context!: React.ContextType<typeof SnackContext>;

  componentDidMount() {
    this.loadData();
  }

  loadData = async () => {
    this.setState({ loading: true });
    const [data, producersList] = await Promise.all([stockadjustmentFull(getFinancialYearSelected()), getActiveProducersReadyForCombo()]);
    this.setState({ loading: false, data, producersList });
  };

  handleChange = async (property: string, value: any[], { column }) => {
    const { updated, data } = this.state.data.reduce(
      (obj, row) => {
        let update = value.find((item) => item.rowDirty && item.barcode_id == row.barcode_id && item["Sequence Number"] == row["Sequence Number"]);

        if (update) {
          if (column.key === "Producer ID") {
            const producer_id = (this.state.producersList.find((prod) => prod.value === update["Producer ID"]) || { data: { id: row.producer_id } }).data.id;
            update["producer_id"] = producer_id;
          }
          obj.updated = obj.updated.filter((item) => !(item.barcode_id == row.barcode_id && item["Sequence Number"] == row["Sequence Number"]));
          obj.updated.push(update);
          obj.data.push(update);
        } else {
          obj.data.push(row);
        }
        return obj;
      },
      {
        updated: this.state.dataChanged || [],
        data: [],
      },
    );
    this.setState({ dataChanged: updated, data: data });
  };

  handleGridSave = async () => {
    this.setState({ submitting: true });
    try {
      await updateStockAdjustment(this.state.dataChanged);
      this.setState({ dataChanged: [] });
      this.loadData();
    } catch (error) {
      this.context.updateSnack({ show: true, color: "red", message: "Updating Stock has failed." });
    }
    this.setState({ submitting: false });
  };

  rowRenderer = (props, classes) => {
    const { row } = props;
    const colorStyle = row.editable
      ? this.state.dataChanged.find((item) => item.barcode_id == row.barcode_id)
        ? classes.redRow
        : row.adjustment
        ? classes.greenRow
        : classes.normalRow
      : classes.disabledRow;

    return <Row {...props} className={colorStyle} />;
  };

  handleToggleCheckbox = (value, row) => {
    if (value) {
      this.setState({ selected: { ...this.state.selected, [row.Barcode]: row } });
    } else {
      const selectedRows = this.state.selected;
      delete selectedRows[row.Barcode];
      this.setState({ selected: selectedRows });
    }
  };

  handleClearSelected = () => {
    this.setState({ selected: {} });
  };

  handleDeleteConfirm = () => {
    this.setState({ confirmDelete: true });
  };

  handleCloseConfirm = () => {
    this.setState({ confirmDelete: false });
  };

  handleSubmitConfirm = async (values: any) => {
    try {
      const barcodes = Object.keys(this.state.selected);
      await deleteSelectedBarcodes({ barcodes: barcodes, reject_note: values.reject_note || "" });
    } catch (error) {
      this.context.updateSnack({ show: true, color: "red", message: error.message || "Error deleting barcodes" });
    } finally {
      this.loadData();
      this.setState({ confirmDelete: false, selected: [] });
    }
  };

  handleReverseConfirm = async () => {
    try {
      const barcodes = Object.keys(this.state.selected);
      await reverseSelectedBarcodes(barcodes);
    } catch (error) {
      this.context.updateSnack({ show: true, color: "red", message: error.message.toString() || "Error reversing barcodes" });
    } finally {
      this.loadData();
      this.setState({ confirmReverse: false, selected: [] });
    }
  };

  handleReverseSelected = () => {
    this.setState({ confirmReverse: true });
  };

  getSelectedCount = (state = 0) => {
    const rows = Object.keys(this.state.selected).map((barcode) => this.state.selected[barcode]) || [];
    return rows.filter((item) => item.editable == state).length;
  };

  filteredRows = [];
  handleFilteredRows = (rows: any[]) => {
    this.filteredRows = rows;
  };

  handleSelectAll = () => {
    const selected = this.filteredRows.reduce((obj, curr) => {
      obj[curr.Barcode] = curr;
      return obj;
    }, {});
    this.setState({ selected: { ...this.state.selected, ...selected } });
  };

  render() {
    const { classes } = this.state;

    return (
      <div className={classes.root}>
        {this.state.submitting && <LinearProgress color="secondary" />}
        {this.state.confirmReverse && (
          <Confirmation
            isOpen={true}
            handleClose={this.handleCloseConfirm}
            handleConfirm={this.handleReverseConfirm}
            title={"REVERSE selected"}
            body={`Are you sure you want to reverse the selected records? `}
          />
        )}
        {this.state.confirmDelete && (
          <Confirmation
            isOpen={true}
            handleClose={this.handleCloseConfirm}
            handleConfirm={() => {}}
            title={"REJECT selected"}
            body={`Are you sure you want to reject the selected records? `}
          >
            <RejectForm handleSubmit={this.handleSubmitConfirm} handleClose={this.handleCloseConfirm} />
          </Confirmation>
        )}
        <div style={{ position: "relative" }}>
          <Toolbar className={classes.toolbar}>
            <Button variant="contained" color="secondary" onClick={this.handleGridSave} disabled={this.state.dataChanged.length === 0} style={{ marginRight: "10px" }}>
              Save
            </Button>
            <GreenButton variant="contained" onClick={this.handleSelectAll} disabled={this.state.data.length === 0}>
              Select All
            </GreenButton>
            <GreyButton variant="contained" onClick={this.handleReverseSelected} disabled={this.getSelectedCount() === 0}>
              Reverse Selected
            </GreyButton>
            <OrangeButton variant="contained" onClick={this.handleClearSelected} disabled={Object.keys(this.state.selected).length === 0}>
              Clear Selected
            </OrangeButton>
            <RedButton variant="contained" onClick={this.handleDeleteConfirm} disabled={this.getSelectedCount(1) === 0}>
              REJECT Selected
            </RedButton>
          </Toolbar>
          <Grid
            fill
            loading={this.state.loading}
            data={this.state.data}
            GridColumns={(data, filters, columnArrangement, columnsWidth) =>
              GridColumns(data, filters, columnArrangement, columnsWidth, this.state.selected, this.handleToggleCheckbox, this.state.producersList)
            }
            handleChange={this.handleChange}
            clearFilters="stockadjustment"
            showFilterChips={true}
            totalRowColumns={["No Cartons", "Pallet Size", "Gross Weight", "Weight"]}
            rowRenderer={(props) => this.rowRenderer(props, classes)}
            handleFilteredRows={this.handleFilteredRows}
          />
        </div>
      </div>
    );
  }
}

const RejectForm = ({ handleSubmit, handleClose }) => {
  const [loading, setLoading] = React.useState(false);

  return (
    <Form
      onSubmit={async (values) => {
        setLoading(true);
        await handleSubmit(values);
        setLoading(false);
      }}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <div style={{ padding: "10px", display: "flex", flexDirection: "column", paddingTop: "0", gap: "10px" }}>
            <InputLabel>Enter reason for rejecting selected barcodes:</InputLabel>
            <Field name="reject_note" type="text" fullWidth={true}>
              {({ input }) => <MTextField {...input} rows={6} multiline style={{ marginTop: "0px", backgroundColor: "#f7f7f7" }} />}
            </Field>
          </div>
          <div style={{ display: "flex", justifyContent: "flex-end", flexDirection: "row", gap: "10px" }}>
            <Button type="button" color="secondary" variant="outlined" onClick={handleClose}>
              Cancel
            </Button>
            <Button type="submit" color="primary" variant="outlined" disabled={loading}>
              Ok
            </Button>
          </div>
        </form>
      )}
    />
  );
};

export default withStyles(styles)(StockAdjustmentUnstyled);
