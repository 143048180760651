import { client, request } from "./_base";
import { getFinancialYearSelected } from "./week";

const endpoint = "terms";

export type TermsType = {
  id: number;
  code: string;
  description: string;
  details: string;
  valuetype1: string;
  value1: string;
  datetype1: string;
  days1: number;
  valuetype2: string;
  value2: string;
  datetype2: string;
  days2: number;
  valuetype3: string;
  value3: string;
  datetype3: string;
  days3: number;
  type: number;
};
export const terms = client(endpoint, (item) => ({
  ...item,
}));

export const getTermsAllSortedMappedforCombo = async (type: number) => {
  const result = await terms.all();
  return result
    .sort((a, b) => {
      if (a.description > b.description) return 1;
      if (a.description < b.description) return -1;
      return 1;
    })
    .reduce((arr, item: TermsType) => {
      if (item.type == type) {
        arr.push({ value: item.id, display: `${item.code} - ${item.description}` });
      }
      return arr;
    }, []);
};

// extended methods
export const termsBySaleId = async (saleid: number) => {
  const result = await request(`${endpoint}/ex/termsBySaleId/${saleid}`, {
    method: "GET",
  });
  const resp = await result.json();
  if (result.status == 500) {
    throw resp;
  }
  return resp.data;
};

export const getTermsDataLinkedById = async (termId: number) => {
  const result = await request(`${endpoint}/ex/getTermsDataLinkedById/${termId}`, {
    method: "GET",
  });
  const resp = await result.json();
  if (result.status == 500) {
    throw resp;
  }
  return resp.data;
};

export const getPaymentTermsAssignedToClients = async () => {
  const result = await request(`${endpoint}/ex/paymentTermsAssignedToClients`, {
    method: "GET",
  });
  const resp = await result.json();
  if (result.status == 500) {
    throw resp;
  }
  return resp.data;
};

export const getProducerShipmentInfo = async (producer_id: number) => {
  const result = await request(`${endpoint}/ex/getProducerShipmentInfo?producer_id=${producer_id}&fin_year=${getFinancialYearSelected()}`, {
    method: "GET",
  });
  const resp = await result.json();
  if (result.status == 500) {
    throw resp;
  }
  return resp.data;
};
