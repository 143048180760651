import React, { useEffect } from "react";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";

import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import CircularProgress from "@material-ui/core/CircularProgress";

import { arrivals } from "../../lib/api/arrival";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1),
      height: "100%",
      width: "100%",
    },
    legend: {
      padding: theme.spacing(1) * 2,
      float: "left",
      marginLeft: "-10px",
    },
    comments: {
      height: "100%",
      float: "left",
      width: theme.spacing(1) * 60,
      padding: theme.spacing(1) * 2,
      marginLeft: "16px",
    },
  });

type ArrivalsDetailCommentsProps = {
  arrival_id: Number;
  handleChange: (property, value) => void;
} & WithStyles<typeof styles>;

const ArrivalsDetailCommentsUnstyled: React.SFC<ArrivalsDetailCommentsProps> = (props) => {
  const { classes, arrival_id, handleChange } = props;
  const [comment, setComment] = React.useState("");
  const [isDirty, setIsDirty] = React.useState(false);
  const [loading, setLoading] = React.useState(true);

  useEffect(() => {
    loadData();
  }, [arrival_id]);

  const loadData = () => {
    arrivals.single(arrival_id).then((result) => {
      if (result) {
        setComment(result.comments);
      }
      setLoading(false);
    });
  };

  const handleChangeLocal = (value) => {
    setComment(value);
    setIsDirty(true);
  };

  return (
    <div className={classes.root}>
      <table>
        <tbody>
          <tr>
            <td>
              <Paper className={classes.legend}>{qualityScoreTable()}</Paper>
            </td>
            <td align="left" style={{ alignContent: "top", verticalAlign: "top" }}>
              {loading && <CircularProgress />}
              <div className={classes.comments}>
                <TextField
                  id="outlined-textarea"
                  label="Comment"
                  placeholder="Comment"
                  multiline
                  margin="normal"
                  variant="outlined"
                  fullWidth={true}
                  value={comment ? comment : ""}
                  rows={5}
                  onChange={(e) => {
                    handleChangeLocal(e.target.value);
                  }}
                  onBlur={() => {
                    if (isDirty) {
                      handleChange("comments", comment);
                    }
                  }}
                />
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default withStyles(styles)(ArrivalsDetailCommentsUnstyled);

export const qualityScoreTable = () => {
  const qualityScoreTableRow = (valueCol1: string, valueCol2: string, valueCol3: string, color: string) => {
    const commentFontWeight = valueCol3.toLowerCase() == "comment" ? "bold" : "normal";
    return (
      <tr>
        <td style={{ width: "60px", border: "1px solid black", padding: "3px", backgroundColor: "lightgray", fontWeight: "bold" }}>{`${valueCol1}`}</td>
        <td style={{ width: "60px", border: "1px solid black", padding: "3px", backgroundColor: "lightgray", fontWeight: "bold" }}>{`${valueCol2}`}</td>
        <td style={{ width: "200px", border: "1px solid black", padding: "3px", backgroundColor: color, fontWeight: commentFontWeight }}>{`${valueCol3}`}</td>
      </tr>
    );
  };

  return (
    <table>
      <tbody>
        <tr>
          <th colSpan={3} style={{ fontWeight: "bold" }}>
            QUALITY SCORE
          </th>
        </tr>
        {qualityScoreTableRow("score", "colour", "comment", "lightgray")}
        {qualityScoreTableRow("1", "red", "total loss", "red")}
        {qualityScoreTableRow("2", "orange", "definite claim", "orange")}
        {qualityScoreTableRow("3", "green", "accepted with deviations - possible claim", "green")}
        {qualityScoreTableRow("4", "white", "good arrival", "white")}
      </tbody>
    </table>
  );
};
