import React from "react";

import CustomAutoCompleteFilter from "../../lib/components/customdatagridfilter/CustomFilter";
import { handleColumnsArrangement, handleColumnsWidth } from "../../lib/helpers/grid";

import Checkbox from "@material-ui/core/Checkbox";

const cellWidthSmall = 80;
const cellWidthSmallBig = 140;
const cellWidthMedium = 220;
const cellWidthLarge = 350;

const defaultColumnProperties = {
  resizable: true,
  sortable: true,
};

let currentSpace = true;

export const GridColumns = (data, filters, arrangement = undefined, columnsWidth = undefined, selectedInvoices, setSelectedInvoices) => {
  let postValue = "";

  if (data) {
    if (currentSpace) {
      postValue = " ";
    }
    currentSpace = !currentSpace;
  }

  const columns = [
    {
      key: "actions",
      name: "Select",
      width: cellWidthSmall,
      formatter: ({ row }) => (
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%", cursor: "pointer" }}>
          <Checkbox style={{ padding: 0 }} checked={selectedInvoices.includes(row.sale_invoicenumber)} onChange={() => setSelectedInvoices(row.sale_invoicenumber)} />
        </div>
      ),
    },
    {
      key: "client",
      name: "Client" + postValue,
      width: cellWidthSmallBig,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"client"} data={data} section={"combinedinvoicesgrid"} />;
      },
    },
    {
      key: "sale_invoicenumber",
      name: "Invoice Number" + postValue,
      width: cellWidthSmallBig,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"sale_invoicenumber"} data={data} section={"combinedinvoicesgrid"} />;
      },
    },
    {
      key: "Forwarding Agent Ref",
      name: "Forwarding Agent Ref" + postValue,
      width: cellWidthSmallBig,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Forwarding Agent Ref"} data={data} section={"combinedinvoicesgrid"} />;
      },
    },
    {
      key: "vessel",
      name: "Vessel" + postValue,
      width: cellWidthSmallBig,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"vessel"} data={data} section={"combinedinvoicesgrid"} />;
      },
    },
    {
      key: "pod",
      name: "POD" + postValue,
      width: cellWidthSmallBig,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"pod"} data={data} section={"combinedinvoicesgrid"} />;
      },
    },
    {
      key: "final_destination",
      name: "Final Destination" + postValue,
      width: cellWidthSmallBig,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"final_destination"} data={data} section={"combinedinvoicesgrid"} />;
      },
    },
    {
      key: "payment_terms",
      name: "Client Payment Terms" + postValue,
      width: cellWidthSmallBig,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"payment_terms"} data={data} section={"combinedinvoicesgrid"} />;
      },
    },
  ].map((c) => ({ ...c, ...defaultColumnProperties }));

  let columnsArranged = [];
  columnsArranged = handleColumnsArrangement(columnsArranged, arrangement, columns);
  columnsArranged = handleColumnsWidth(columnsArranged, columnsWidth, columns);

  return columnsArranged;
};
