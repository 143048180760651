import React, { useEffect } from "react";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";

import Grid from "../../lib/components/grid";
import { DialogInformation } from "../../lib/components/dialoginformation";
// import { isNullOrUndef } from "../../lib/helpers/isNullOrUndef";

import { getFileImportHeaderInfo } from "../../lib/api/FileImport";

import { getCellActionsDataGrid, ColumnsEDISearch } from "./searchgridsetup";

import { SnackContext } from "../../lib/context/SnackContext";
import { FileImportHeaderInfoType } from "../../lib/models/FileImport";
import { Row } from "react-data-grid";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1),
      height: "calc(100vh - 100px)",
      width: "100%",
      position: "relative",
    },
    inline: {
      display: "inline",
      position: "absolute",
      marginTop: "7px",
      marginLeft: "7px",
    },
  });

type SearchProps = {} & WithStyles<typeof styles>;

// export class SearchUnstyled extends React.Component<SearchProps, any> {
const SearchUnstyled: React.FunctionComponent<SearchProps> = ({ children, classes }) => {
  const [data, setData] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [message, setMessage] = React.useState(undefined);

  const { updateSnack } = React.useContext(SnackContext);

  useEffect(() => {
    loadData().then((result: { data: [FileImportHeaderInfoType] }) => {
      setData(result.data);
      setLoading(false);
    });
  }, []);

  const loadData = () => {
    setLoading(true);
    return getFileImportHeaderInfo(0).catch((err) => {
      updateSnack({ show: true, message: err, color: "red" });
    });
  };

  const getGridCellActions = (column, row) => {
    return getCellActionsDataGrid(column, row);
  };

  const handleInformationClose = () => {
    setMessage(undefined);
  };

  const RowRenderer = (props) => {
    return <Row {...props} />;
  };

  return (
    <div className={classes.root}>
      <Grid
        loading={loading}
        data={data}
        getCellActions={(col, row) => {
          getGridCellActions(col, row);
        }}
        GridColumns={ColumnsEDISearch}
        handleRefresh={() => {
          setLoading(true);
          loadData().then(() => {
            setLoading(false);
          });
        }}
        rowRenderer={RowRenderer}
        clearFilters={"edisearch"}
      />
      {message && (
        <DialogInformation isOpen={true} handleClose={handleInformationClose} handleOK={handleInformationClose} title={"Feedback"} body={`${message}`} showinput={false} />
      )}
    </div>
  );
};

export default withStyles(styles)(SearchUnstyled);
