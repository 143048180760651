import React from "react";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";

import Grid from "../../lib/components/grid";
import Confirmation from "../../lib/components/confirmation";

import { getPaymentTermsAssignedToClients, getTermsDataLinkedById, terms, TermsType } from "../../lib/api/terms";
import { GridColumns } from "./termsgridsetup";

import Tooltip from "@material-ui/core/Tooltip";
import Button from "@material-ui/core/Button";

import IconAdd from "@material-ui/icons/Add";
import Close from "@material-ui/icons/Close";

import TermsEditForm from "./termseditform";
import LinkedClients from "./linkedclientsgrid";

import { DebtorsLinkedGridColumns } from "./debtorslinkedgridsetup";
import { DialogInformation } from "../../lib/components/dialoginformation";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1),
      width: "100%",
      height: "100%",
      position: "relative",
    },
    inline: {
      display: "inline",
      position: "absolute",
      marginTop: "7px",
      marginLeft: "7px",
    },
    Close: {
      display: "flex",
      justifyContent: "flex-end",
      margin: "10px 0px -5px",
      position: "fixed",
      top: "10px",
      right: "15px",
    },
    errorMessageGridWrapper: {
      width: "700px",
    },
    errorMsg: {
      color: "red",
    },
  });

type TermsProps = {
  termstype: number;
  handleOnClose?: any;
  forceHeight?: number;
} & WithStyles<typeof styles>;

export class TermsUnstyled extends React.Component<TermsProps, any> {
  state = {
    classes: undefined,
    data: [],
    confirmRemoveID: undefined,
    confirmEditID: undefined,
    loading: true,
    termstype: undefined,
    handleOnClose: undefined,
    termsDataLinked: [],
    showError: undefined,
    forceHeight: undefined,
    showLinkedClients: undefined,
    paymentTermsAssigned: [],
  };

  constructor(props) {
    super(props);
    this.state.classes = props.classes;
    this.state.termstype = props.termstype;
    this.state.handleOnClose = props.handleOnClose;
    this.state.forceHeight = props.forceHeight;
  }

  componentDidMount() {
    this.loadData();
  }

  loadData = async () => {
    this.setState({ loading: true });

    const data = await terms.all();
    const paymentTermsAssigned = await getPaymentTermsAssignedToClients();

    const dataSorted = data
      .filter((item: TermsType) => item.type == this.state.termstype)
      .sort((a, b) => {
        if (a.description > b.description) return 1;
        if (a.description < b.description) return -1;
        return 1;
      });

    this.setState({ loading: false, data: dataSorted, paymentTermsAssigned });
  };

  handleCreate = () => {
    this.setState({ confirmEditID: "0" });
  };

  handleEdit = (id) => {
    this.setState({ confirmEditID: id });
  };

  handleEditClose = () => {
    this.setState({ confirmEditID: undefined });
  };

  handleEditConfirm = (item) => {
    const id = item["id"];
    delete item["id"];

    item["type"] = this.state.termstype;

    Object.keys(item).map((i) => {
      if (item[i] == "" || item[i].length == 0) {
        item[i] = "0";
      }
    });

    if (id == "0") {
      terms.create({ data: { ...item } }).then(() => this.loadData());
    } else {
      terms.update(id, { data: { ...item } }).then(() => this.loadData());
    }
    this.handleEditClose();
  };

  handleRemove = async (id) => {
    this.setState({ confirmRemoveID: id });
  };

  handleRemoveClose = () => {
    this.setState({ confirmRemoveID: undefined });
  };

  handleRemoveConfirm = async () => {
    try {
      await terms.remove(this.state.confirmRemoveID);
      this.handleRemoveClose();
      this.loadData();
    } catch (error) {
      const message = error.data || "Error deleting term.";
      if (typeof message == "string" && message.includes("REFERENCE constraint")) {
        getTermsDataLinkedById(this.state.confirmRemoveID).then((data) => {
          this.setState({ showError: "Deleting the payment term is not allowed as it is linked to the below records.", termsDataLinked: data, confirmRemoveID: false });
        });
      }
    }
  };

  handleShowErrorClose = () => {
    this.setState({ showError: undefined });
  };

  handleView = (row) => {
    this.setState({ showLinkedClients: row.id });
  };

  render() {
    const { classes } = this.state;

    if (this.state.loading) {
      return <div>Loading...</div>;
    }

    return (
      <div className={classes.root}>
        {this.state.showLinkedClients && (
          <Confirmation isOpen={true} handleClose={() => this.handleView(false)} handleConfirm={() => {}} title={"Linked Clients"} body={undefined}>
            <LinkedClients data={this.state.paymentTermsAssigned} showLinkedClients={this.state.showLinkedClients} onClose={() => this.handleView(false)} />
          </Confirmation>
        )}
        <div className={classes.inline}>
          {this.state.handleOnClose && (
            <div className={classes.Close}>
              <Button style={{ marginRight: "10px", backgroundColor: "white" }} color="secondary" variant="outlined" onClick={this.state.handleOnClose}>
                <Close />
              </Button>
            </div>
          )}
          <Tooltip style={{ zIndex: 0 }} title="Create new Term">
            <div className={classes.inline}>
              <Button variant="contained" color="primary" onClick={this.handleCreate} style={{ marginBottom: "10px" }}>
                <IconAdd />
              </Button>
            </div>
          </Tooltip>
        </div>
        <Grid
          loading={this.state.loading}
          data={this.state.data}
          clearFilters={"paymentterms"}
          GridColumns={(data, filters, arrangement, columnsWidth) =>
            GridColumns(data, filters, arrangement, columnsWidth, this.handleRemove, this.handleEdit, this.handleView, this.state.paymentTermsAssigned)
          }
          forceHeight={this.state.forceHeight}
        />
        {this.state.confirmRemoveID && (
          <Confirmation
            isOpen={this.state.confirmRemoveID ? true : false}
            handleClose={this.handleRemoveClose}
            handleConfirm={this.handleRemoveConfirm}
            title="Remove selected Term?"
            body="Are you sure you want to REMOVE the selected Term Record?"
          ></Confirmation>
        )}
        {this.state.confirmEditID && (
          <Confirmation
            isOpen={this.state.confirmEditID ? true : false}
            handleClose={this.handleEditClose}
            handleConfirm={() => {}}
            title={this.state.confirmEditID == "0" ? "Creating Client Payment Term" : "Editing Client Payment Term"}
            body={undefined}
          >
            <TermsEditForm onSubmit={this.handleEditConfirm} onClose={this.handleEditClose} id={this.state.confirmEditID} />
          </Confirmation>
        )}
        {this.state.showError && (
          <DialogInformation
            isOpen={true}
            handleClose={this.handleShowErrorClose}
            handleOK={this.handleShowErrorClose}
            title={"Error deleting term"}
            body={
              <div className={classes.errorMessageGridWrapper}>
                <p className={classes.errorMsg}>{this.state.showError}</p>
                <Grid
                  forceHeight={500}
                  clearFilters="debtorslinkedgridfilters"
                  loading={this.state.loading}
                  data={this.state.termsDataLinked}
                  GridColumns={DebtorsLinkedGridColumns}
                />
              </div>
            }
            showinput={false}
          />
        )}
      </div>
    );
  }
}

export default withStyles(styles)(TermsUnstyled);
