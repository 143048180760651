import React, { useEffect, useState } from "react";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";

import Check from "@material-ui/icons/Check";
import Close from "@material-ui/icons/Close";

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";

import { findBy } from "../lib/api/inspection";
import { users } from "../lib/api/users";

import { ComboSelection } from "../lib/components/ComboSelection";

import { UserContext } from "../lib/context/UserContext";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1),
    },
    tableRow: {
      paddingTop: theme.spacing(1),
      paddingRight: theme.spacing(2),
      marginTop: theme.spacing(1),
      textAlign: "right",
    },
    dropDowns: {
      width: "80px",
    },
    titles: {
      width: "180px",
      paddingRight: theme.spacing(1),
    },
  });

const notificationTypes = [
  { type: 0, name: "Comment" },
  { type: 1, name: "Inspection" },
  { type: 2, name: "Feedback" },
];

type NotificationProps = {
  onSubmit: (e, item) => void;
  onClose: () => void;
  reloadData: () => void;
  locked: boolean;
} & WithStyles<typeof styles>;

const NotificationUnstyled: React.SFC<NotificationProps> = (props) => {
  const { classes, onSubmit, onClose, reloadData, locked } = props;

  const [item, setItem] = React.useState({
    user_id: 0,
    comment: "",
    type: 0,
    typeref: undefined,
    status: undefined,
    fromuser_id: 0,
    created_at: undefined,
    updated_at: undefined,
  });
  const [inspectionsData, setInspectionsData] = React.useState([]);
  const [userData, setUserData] = React.useState([]);

  const { user } = React.useContext(UserContext);

  useEffect(() => {
    loadData();
  }, [user]);

  const loadData = async () => {
    setItem({ ...item, user_id: user.id, fromuser_id: user.id, status: 0, created_at: new Date(), updated_at: new Date() });
    users.all().then((result) => {
      setUserData(result);
    });
  };

  const onChangeItem = async (field, value) => {
    const newItem = { ...item, [field]: value };
    if (field == "type") {
      if (value == 1) {
        findBy().then((result) => {
          setInspectionsData(result);
        });
      }
    }
    setItem(newItem);
  };

  return (
    <div className={classes.root}>
      <form
        id={`newNotificationItem`}
        onSubmit={(e) => {
          onSubmit(e, item);
          reloadData();
        }}
      >
        <table style={{ marginTop: "-35px" }}>
          <tbody>
            {/* <tr>
              <td className={classes.tableRow}>
                <span className={classes.titles}>Comment:</span>
              </td>
              <td>
                <input
                  key={"body_value_comment"}
                  name={"comment"}
                  value={item.comment}
                  onChange={e => {
                    onChangeItem("comment", e.target.value);
                  }}
                />
              </td>
            </tr> */}
            <tr>
              <td colSpan={4}>
                <TextField
                  style={{ width: "100%" }}
                  id="standard-multiline-flexible"
                  label="Comments"
                  multiline
                  rowsMax="5"
                  value={item.comment}
                  onChange={(e) => {
                    onChangeItem("comment", e.target.value);
                  }}
                  margin="normal"
                />
              </td>
            </tr>
            <tr>
              <td className={classes.titles} align="right">
                <span>Send To:</span>
              </td>
              <td>
                <ComboSelection
                  classes={classes}
                  name="User To"
                  selected={item.user_id}
                  setSelected={(e) => {
                    onChangeItem("user_id", e);
                  }}
                  data={userData.map((item) => {
                    return { id: item.id, code: item.id, name: item.username };
                  })}
                  compact={true}
                />
              </td>
            </tr>
            {!locked && (
              <>
                <tr>
                  <td className={classes.titles} align="right">
                    <span>Type:</span>
                  </td>
                  <td>
                    <ComboSelection
                      addNone={false}
                      classes={classes}
                      name="Inspection Type"
                      selected={item.type}
                      setSelected={(e) => {
                        onChangeItem("type", e);
                      }}
                      data={notificationTypes.map((item) => {
                        return { id: item.type, code: item.type, name: item.name };
                      })}
                      compact={true}
                    />
                  </td>
                </tr>
                {item.type == 1 && (
                  <>
                    <tr>
                      <td className={classes.titles} align="right">
                        <span>Inspections Reference:</span>
                      </td>
                      <td>
                        <ComboSelection
                          classes={classes}
                          name="Inspection TypeRef"
                          selected={item.typeref}
                          setSelected={(e) => {
                            onChangeItem("typeref", e);
                          }}
                          data={inspectionsData.map((item) => {
                            return { id: item.number, code: item.number, name: `${item.piorder_ordernum} (${item.pickref})` };
                          })}
                          compact={true}
                        />
                      </td>
                    </tr>
                  </>
                )}
              </>
            )}
          </tbody>
        </table>
        <div style={{ display: "flex", justifyContent: "flex-end", margin: "10px 0 -5px 0" }}>
          <Button style={{ marginRight: "10px" }} color="secondary" variant="outlined" onClick={onClose}>
            <Close />
          </Button>
          <Button type="submit" color="primary" variant="outlined">
            <Check />
          </Button>
        </div>
      </form>
    </div>
  );
};

export default withStyles(styles)(NotificationUnstyled);
