import React from "react";

import numeral from "numeral";

export enum SummaryFormatterTypes {
  Total = 0,
  Number = 1,
  Number2Decimal = 2,
}

export const SummaryTotal = () => {
  return <div style={{ textAlign: "left", fontWeight: "bold" }}>TOTALS</div>;
};

export const SummaryNumber = ({ row, column }) => {
  return <div style={{ textAlign: "right", fontWeight: "bold" }}>{numeral(row[column.key]) ? numeral(row[column.key]).format("0,0") : "0"}</div>;
};

export const SummaryNumber2Decimal = ({ row, column }) => {
  return <div style={{ textAlign: "right", fontWeight: "bold" }}>{numeral(row[column.key]) ? numeral(row[column.key]).format("0,0.00") : "0.00"}</div>;
};

export const SummaryNumber3Decimal = ({ row, column }) => {
  return <div style={{ textAlign: "right", fontWeight: "bold" }}>{numeral(row[column.key]) ? numeral(row[column.key]).format("0,0.000") : "0.000"}</div>;
};

export const SummaryEmpty = ({ row, column }) => {
  return <div style={{ textAlign: "right", fontWeight: "bold" }}></div>;
};
