import React from "react";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";
import Check from "@material-ui/icons/Check";
import Close from "@material-ui/icons/Close";
import Button from "@material-ui/core/Button";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Input from "@material-ui/core/Input";

import { costEstimatedTypeMap } from "./estimatedcostitemgridsetup";

import { SnackContext } from "../../lib/context/SnackContext";

import { estimatedcostitems } from "../../lib/api/estimatedcostitem";
import { estimatedcostitemgroupsAllSortedMappedforCombo } from "../../lib/api/estimatedcostitemgroups";

import { GenerateErrorMessage } from "../../lib/helpers/string_methods";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1),
    },
    tableRow: {
      paddingTop: theme.spacing(1),
      paddingRight: theme.spacing(1) * 2,
      marginTop: theme.spacing(1),
      textAlign: "right",
    },
    tableColDescription: {
      paddingTop: theme.spacing(1),
      paddingRight: theme.spacing(1) * 2,
      marginTop: theme.spacing(1),
      textAlign: "right",
    },
    tableColInput: {
      paddingTop: theme.spacing(1),
      paddingRight: theme.spacing(1) * 2,
      marginTop: theme.spacing(1),
      textAlign: "left",
      minWidth: "100%",
    },
    select: {
      minWidth: "100%",
    },
  });

type EstimatedCostEditFormProps = {
  onSubmit: any;
  onClose: any;
  id: any;
} & WithStyles<typeof styles>;

class EstimatedCostEditFormUnstyled extends React.Component<EstimatedCostEditFormProps, any> {
  state = {
    classes: undefined,
    item: { id: "0" },
    onSubmit: undefined,
    onClose: undefined,
    id: 0,
    costItems: [{ id: 0, display: "" }],
    groups: [],
  };

  constructor(props) {
    super(props);
    this.state.classes = props.classes;
    this.state.onSubmit = props.onSubmit;
    this.state.onClose = props.onClose;
    this.state.id = props.id;
  }

  static contextType = SnackContext;
  context!: React.ContextType<typeof SnackContext>;

  componentWillMount() {
    if (this.state.id != 0) {
      this.getItem(this.state.id);
    }
  }

  getItem = (id) => {
    estimatedcostitems
      .single(id)
      .then((data) => {
        this.setState({ item: data });
      })
      .catch((error) => {
        const err = GenerateErrorMessage(error, "failed to retrieve data");
        this.context.updateSnack({ show: true, color: "red", message: err });
      });
    estimatedcostitemgroupsAllSortedMappedforCombo()
      .then((result) => {
        this.setState({ groups: result });
      })
      .catch((error) => {
        const err = GenerateErrorMessage(error, "failed to retrieve data");
        this.context.updateSnack({ show: true, color: "red", message: err });
      });
  };

  handleFocus = (event) => {
    event.target.select();
  };

  handleSubmit = (e) => {
    this.state.onSubmit(e, this.state.item);
  };

  render() {
    const { classes } = this.state;

    return (
      <div className={classes.root}>
        <form
          id={this.state.item.id}
          onSubmit={(e) => {
            this.handleSubmit(e);
          }}
        >
          <table style={{ marginLeft: "15px" }}>
            <tbody>
              <tr className={classes.tableRow}>
                <td className={classes.tableColDescription}>
                  <span>Description:</span>
                </td>
                <td className={classes.tableColInput}>
                  <Input
                    name={"costestimatationitem-description"}
                    onFocus={this.handleFocus}
                    onChange={(e) => {
                      this.setState({
                        item: { ...this.state.item, costdescription: e.target.value },
                      });
                    }}
                    value={(this.state.item as any).costdescription}
                  />
                </td>
              </tr>
              <tr className={classes.tableRow}>
                <td className={classes.tableColDescription}>
                  <span>Type:</span>
                </td>
                <td className={classes.tableColInput}>
                  <Select
                    name={"costestimatationitem-type"}
                    className={classes.select}
                    onChange={(e) => {
                      this.setState({
                        item: { ...this.state.item, type: e.target.value },
                      });
                    }}
                    value={Number((this.state.item as any).type)}
                  >
                    {Object.keys(costEstimatedTypeMap).map((item) => {
                      return (
                        <MenuItem key={item} value={item}>
                          {`${costEstimatedTypeMap[item]} (${item})`}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </td>
              </tr>
              <tr className={classes.tableRow}>
                <td className={classes.tableColDescription}>
                  <span>Group:</span>
                </td>
                <td className={classes.tableColInput}>
                  <Select
                    name={"costestimatationitem-group"}
                    className={classes.select}
                    onChange={(e) => {
                      this.setState({
                        item: { ...this.state.item, estimatedcostitemgroups_id: e.target.value },
                      });
                    }}
                    value={Number((this.state.item as any).estimatedcostitemgroups_id)}
                  >
                    {this.state.groups.map((item) => {
                      return (
                        <MenuItem key={item} value={item.value}>
                          {`${item.display}`}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </td>
              </tr>
            </tbody>
          </table>
          <div style={{ display: "flex", justifyContent: "flex-end", margin: "10px 0 -5px 0" }}>
            <Button style={{ marginRight: "10px" }} color="secondary" variant="outlined" onClick={this.state.onClose}>
              <Close />
            </Button>
            <Button type="submit" color="primary" variant="outlined" onClick={this.handleSubmit}>
              <Check />
            </Button>
          </div>
        </form>
      </div>
    );
  }
}

export default withStyles(styles)(EstimatedCostEditFormUnstyled);
