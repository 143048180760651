import React from "react";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";

import CompanyDetailsEditForm from "./companydetailseditform";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1),
      width: "100%",
      height: "100%",
      position: "relative",
    },
  });

type CompanyDetailsProps = {} & WithStyles<typeof styles>;

export class CompanyDetailsUnstyled extends React.Component<CompanyDetailsProps, any> {
  state = {
    classes: undefined,
    confirmEditID: 0,
  };

  constructor(props) {
    super(props);
    this.state.classes = props.classes;
  }

  render() {
    const { classes } = this.state;

    return (
      <div className={classes.root}>
        <CompanyDetailsEditForm />
      </div>
    );
  }
}

export default withStyles(styles)(CompanyDetailsUnstyled);
