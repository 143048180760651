import React from "react";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";
import { Redirect } from "react-router-dom";

import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import orange from "@material-ui/core/colors/orange";

import { Row } from "react-data-grid";
import "react-splitter-layout/lib/index";
import "../../lib/custom/splitter.css";

import { DialogInformation } from "../../lib/components/dialoginformation";
import { ComboSelection } from "../../lib/components/ComboSelection";

import Confirmation from "../../lib/components/confirmation";

import { FileImportGrid } from "./importgridsetup";

import {
  execFileImport,
  execFileImportTemplates,
  execRejectFileImport,
  execDeleteFileImport,
  execFileReImport,
  execAutoProcessFileImport,
  getFileImportHeader,
} from "../../lib/api/FileImport";

import Splitter from "m-react-splitters";
import "m-react-splitters/lib/splitters.css";
import { SnackContext } from "../../lib/context/SnackContext";
import { LoadingDialog } from "../../lib/components/LoadingDialog";

import FailedImports from "./failedimports";
import Grid from "../../lib/components/grid";

import format from "date-fns/format";
import { ColumnsFileImportHeader } from "../importinfo/importinfogridsetup";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      height: "100%",
      width: "100%",
    },
    issueFilesText: {
      fontWeight: "bold",
      fontSize: "16px",
      color: "orange",
      marginRight: "15px",
    },
    rejectButton: {
      marginRight: "10px",
      color: "white",
      backgroundColor: orange[700],
      "&:hover": {
        backgroundColor: orange[900],
      },
    },
    toolbar: {
      display: "flex",
      height: "70px",
      alignItems: "center",
      padding: "10px",
      position: "absolute",
      left: 0,
      top: 0,
      width: "100%",
    },
    boldRow: {
      fontWeight: "bold",
    },
    normalRow: {
      fontWeight: "normal",
    },
  });

type ImportGridProps = {
  data: {}[];
  handleReload: any;
  mappingTemplates: any;
} & WithStyles<typeof styles>;

enum FileImportStatus {
  New = "New",
  Imported = "Imported",
  Processed = "Processed",
  Rejected = "Rejected",
}
class ImportGridUnstyled extends React.Component<ImportGridProps, {}> {
  state = {
    data: [],
    dataDetail: [],
    handleReload: undefined,
    handleReImport: undefined,
    handleReject: undefined,
    handleOpen: undefined,
    selectedFileImport: undefined,
    clearedPhrase: false,
    dialoginformation: undefined,
    minGridHeight: 0,
    minGridHeightSecond: 0,
    redirectImportId: undefined,
    rejectLoading: false,
    selectedMappingTemplate: "0",
    mappingTemplates: [],
    deleteConfirmation: false,
    reImportConfirmation: false,
    fileUploadDialogOpen: false,
    fileUploadDialogTitle: undefined,
    fileUploadDialogLoading: false,
    fileUploadDialogError: undefined,
    fileUploadDialogSuccess: undefined,
    autoImportNew: false,
    comparisonRedirectId: undefined,
    showFailedImports: false,
    failedImportsList: [],
    coldroomInactive: false,
  };

  constructor(props) {
    super(props);
    this.state.data = props.data;
    this.state.handleReload = props.handleReload;
    this.state.handleReImport = props.handleReImport;
    this.state.handleReject = props.handleReject;
    this.state.handleOpen = props.handleOpen;
    this.state.mappingTemplates = props.mappingTemplates;
  }

  static contextType = SnackContext;
  context!: React.ContextType<typeof SnackContext>;

  componentDidMount() {
    this.setGridHeights();
  }

  handleFileImportDoubleClick = async (row) => {
    const result = await getFileImportHeader(row.id);
    const dataDetail = result.data.map((row) => {
      const newDate = new Date(row["Date"].replace("Z", ""));
      return { ...row, FormattedDate: format(newDate, "dd MMM yyyy") };
    });
    this.setState({ selectedFileImport: row, dataDetail });
    this.setGridHeights();
  };

  setGridHeights = () => {
    const primarysplit = document.getElementsByClassName("splitter horizontal");
    this.setState({
      minGridHeight: primarysplit[0].children[0].clientHeight - 100,
      minGridHeightSecond: primarysplit[0].children[2].clientHeight - 100,
    });
  };

  handleInfoClose = () => {
    this.setState({ dialoginformation: undefined });
    if (this.state.dialoginformation.callback) {
      this.state.dialoginformation.callback();
    }
  };

  handleOpen = async () => {
    if (!this.state.selectedFileImport.is_active) {
      this.setState({ coldroomInactive: true });
      return;
    }
    try {
      this.setState({ fileUploadDialogOpen: true, fileUploadDialogLoading: true, fileUploadDialogTitle: "Uploading file. Please wait" });
      if (this.state.selectedFileImport) {
        const fileImportStatus = this.state.selectedFileImport.status;
        if (fileImportStatus === FileImportStatus.New) {
          if (parseInt(this.state.selectedMappingTemplate) > 0) {
            await execFileImportTemplates(parseInt(this.state.selectedFileImport.id), parseInt(this.state.selectedMappingTemplate));
            this.setState({ redirectImportId: this.state.selectedFileImport.id });
          } else {
            const result = await execFileImport(this.state.selectedFileImport.id);

            if (this.state.selectedFileImport.filename.substr(0, 2) === "PS") {
              this.setState({
                dialoginformation: {
                  title: "Import Completed",
                  body: "Redirecting to comparison screen.",
                  callback: () => {
                    this.setState({ comparisonRedirectId: this.state.selectedFileImport.id });
                  },
                },
              });
            } else {
              if (result.data > 0) {
                this.setState({
                  dialoginformation: {
                    title: "Import failed",
                    body: "Validation errors found. Fix errors and process manually.",
                    callback: () => {
                      this.setState({ redirectImportId: this.state.selectedFileImport.id });
                    },
                  },
                });
              } else if (result.data <= 0) {
                this.setState({
                  dialoginformation: {
                    title: "Import successful",
                    body: "Importing and processing successful",
                    callback: () => {
                      this.state.handleReload();
                    },
                  },
                });
              }
            }
          }
        } else if (fileImportStatus === FileImportStatus.Imported || fileImportStatus === FileImportStatus.Processed) {
          if (this.state.selectedFileImport.filename.substr(0, 2) === "PS") {
            this.setState({ comparisonRedirectId: this.state.selectedFileImport.id });
          } else {
            this.setState({ redirectImportId: this.state.selectedFileImport.id });
          }
        }
      }
    } catch (error) {
      this.setState({ dialoginformation: { title: "Error import file", body: error } });
    } finally {
      this.setState({ fileUploadDialogOpen: false, fileUploadDialogTitle: undefined });
    }
  };

  handleReject = async () => {
    this.setState({ rejectLoading: true });
    try {
      await execRejectFileImport(this.state.selectedFileImport.id);
      await this.props.handleReload();
    } catch (error) {
      this.context.updateSnack({ show: true, color: "red", message: error });
    }
    this.setState({ rejectLoading: false });
  };

  handleConfirmDelete = () => {
    this.setState({ deleteConfirmation: true });
  };

  handleCloseDelete = () => {
    this.setState({ deleteConfirmation: false });
  };

  handleDeleteFileImport = async () => {
    try {
      await execDeleteFileImport(this.state.selectedFileImport.id);
      this.props.handleReload();
      this.setState({ selectedFileImport: undefined, dataDetail: [] });
    } catch (error) {
      this.context.updateSnack({ show: true, color: "red", message: error });
    }
    this.setState({ deleteConfirmation: false });
  };

  handleConfirmReimport = () => {
    this.setState({ reImportConfirmation: true });
  };

  handleCloseReImport = () => {
    this.setState({ reImportConfirmation: false });
  };

  handleFileReImport = async () => {
    try {
      const result = await execFileReImport(this.state.selectedFileImport.id, parseInt(this.state.selectedMappingTemplate) || 0);

      if (parseInt(this.state.selectedMappingTemplate) > 0) {
        this.setState({ reImportConfirmation: false, redirectImportId: this.state.selectedFileImport.id }, () => {});
      } else {
        if (this.state.selectedFileImport.filename.substr(0, 2) === "PS") {
          this.setState({
            dialoginformation: {
              title: "Import Completed",
              body: "Redirecting to comparison screen.",
              callback: () => {
                this.setState({ comparisonRedirectId: this.state.selectedFileImport.id });
              },
            },
          });
        } else {
          if (result.data > 0) {
            this.setState({
              reImportConfirmation: false,
              dialoginformation: {
                title: "Import failed",
                body: "Validation errors found. Fix errors and process manually.",
                callback: () => {
                  this.setState({ redirectImportId: this.state.selectedFileImport.id });
                },
              },
            });
          } else if (result.data <= 0) {
            this.setState({
              reImportConfirmation: false,
              dialoginformation: {
                title: "Import successful",
                body: "Importing and processing successful",
                callback: () => {
                  this.state.handleReload();
                },
              },
            });
          }
        }
      }
    } catch (error) {
      this.setState({ dialoginformation: { title: "Error re-import file", body: error } });
    }
  };

  handleMappingTypeChange = (templateId) => {
    this.setState({ selectedMappingTemplate: templateId });
  };

  handleImportAllFiles = async () => {
    this.setState({
      autoImportNew: false,
      fileUploadDialogOpen: true,
      fileUploadDialogLoading: true,
      fileUploadDialogTitle: "Uploading files. Please wait",
    });

    try {
      const result = await execAutoProcessFileImport();
      const validationErrors = result.data && result.data.length > 0;

      if (validationErrors) {
        this.setState({
          fileUploadDialogLoading: false,
          fileUploadDialogSuccess: "Upload completed with failed imports.",
          failedImportsList: result.data,
        });
      } else {
        this.setState({
          fileUploadDialogLoading: false,
          fileUploadDialogSuccess: "Uploading has completed successfully.",
          failedImportsList: [],
        });
      }
    } catch (error) {
      this.setState({
        fileUploadDialogLoading: false,
        fileUploadDialogError: "Upload not completed. Please review and make neccessary changes",
      });
    }
  };

  handleCloseUploadDialog = () => {
    this.setState({ fileUploadDialogOpen: false });
    if (this.state.failedImportsList.length > 0) {
      this.setState({ showFailedImports: true });
    } else {
      this.state.handleReload();
    }
  };

  handleCloseAutoImportNew = () => {
    this.setState({ autoImportNew: false });
  };

  handleOpenAutoImportNew = () => {
    this.setState({ autoImportNew: true });
  };

  handleCloseFailedImports = () => {
    this.setState({ showFailedImports: false, failedImportsList: [] });
    this.state.handleReload();
  };

  rowRenderer = (props, classes) => {
    const { row } = props;
    const bold = this.state.selectedFileImport && this.state.selectedFileImport.id == row.id ? classes.boldRow : classes.normalRow;

    return <Row {...props} onDoubleClickCapture={() => this.handleFileImportDoubleClick(row)} className={bold} />;
  };

  filterTerms = (filterObj, term = "filterTerm") => {
    return Object.keys(filterObj).reduce((val, key) => (val += filterObj[key][term].length), 0);
  };

  handleColdroomInactiveClose = () => {
    this.setState({ coldroomInactive: false });
  };

  render() {
    const { classes } = this.props;

    if (this.state.redirectImportId) return <Redirect to={`/edi/fileImports/${this.state.redirectImportId}`} />;
    if (this.state.comparisonRedirectId) return <Redirect to={`/maintenance/edifilecomparison/${this.state.comparisonRedirectId}`} />;

    return (
      <div className={classes.root}>
        {this.state.autoImportNew && (
          <Confirmation
            isOpen={true}
            handleClose={this.handleCloseReImport}
            handleConfirm={this.handleImportAllFiles}
            title={`Confirm auto import`}
            body={`Are you sure you want to import edi imports with status 'New'?`}
          />
        )}
        {this.state.fileUploadDialogOpen && (
          <LoadingDialog
            isOpen={true}
            handleOK={this.handleCloseUploadDialog}
            title={this.state.fileUploadDialogTitle}
            loading={this.state.fileUploadDialogLoading}
            errorMsg={this.state.fileUploadDialogError}
            successMsg={this.state.fileUploadDialogSuccess}
          />
        )}
        {this.state.deleteConfirmation && (
          <Confirmation
            isOpen={true}
            handleClose={this.handleCloseDelete}
            handleConfirm={this.handleDeleteFileImport}
            title={`Confirm delete`}
            body={`Are you sure you want to delete this file import?`}
          />
        )}
        {this.state.reImportConfirmation && (
          <Confirmation
            isOpen={true}
            handleClose={this.handleCloseReImport}
            handleConfirm={this.handleFileReImport}
            title={`Confirm re-import`}
            body={`Are you sure you want to re-import this file import?`}
          />
        )}
        {this.state.dialoginformation && (
          <DialogInformation
            isOpen={true}
            handleClose={this.handleInfoClose}
            handleOK={this.handleInfoClose}
            title={this.state.dialoginformation.title}
            body={`${this.state.dialoginformation.body}`}
            showinput={false}
          />
        )}
        {this.state.coldroomInactive && (
          <DialogInformation
            isOpen
            showinput={false}
            title={"Unable to open"}
            body={"The coldroom is inactive. Please reactivate the coldroom to open."}
            handleClose={this.handleColdroomInactiveClose}
            handleOK={this.handleColdroomInactiveClose}
          />
        )}
        {this.state.showFailedImports && (
          <Confirmation isOpen={true} handleClose={this.handleCloseFailedImports} handleConfirm={this.handleCloseFailedImports} title={"Failed file import(s)"} body={undefined}>
            <FailedImports data={this.state.failedImportsList} handleClose={this.handleCloseFailedImports} />
          </Confirmation>
        )}
        <div id="main_splitter_div" style={{ height: "100%", width: "100%", display: "grid" }}>
          <Splitter
            postPoned
            dispatchResize
            position="horizontal"
            primaryPaneWidth="100%"
            primaryPaneHeight="calc(100% - 300px)"
            primaryPaneMaxHeight="calc(100% - 150px)"
            onDragFinished={() => this.setGridHeights()}
          >
            <div id="primary_grid" style={{ position: "relative" }}>
              <div className={classes.toolbar}>
                {(this.state.data || []).filter((item) => item.status === FileImportStatus.Imported).length > 0 && (
                  <p className={classes.issueFilesText}>{this.state.data.filter((item) => item.status === FileImportStatus.Imported).length} files with issues.</p>
                )}
                <div>
                  <Button
                    variant="contained"
                    className={classes.rejectButton}
                    onClick={this.handleOpenAutoImportNew}
                    disabled={(this.state.data || []).filter((item) => item.status === FileImportStatus.New).length === 0}
                  >
                    Import All New
                  </Button>
                </div>
              </div>
              <Grid
                showFilterChips
                data={this.state.data}
                clearFilters={"importfile"}
                GridColumns={FileImportGrid}
                forceHeight={this.state.minGridHeight}
                rowRenderer={(props) => this.rowRenderer(props, classes)}
              />
            </div>
            <div id="secondary_grid" style={{ position: "relative" }}>
              <div className={classes.toolbar}>
                <ComboSelection
                  addNone={false}
                  classes={classes}
                  name="mapping template"
                  setSelected={this.handleMappingTypeChange}
                  selected={this.state.selectedMappingTemplate}
                  data={this.state.mappingTemplates.map((item) => ({ id: item.id, code: item.id, name: item.templatename }))}
                />
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={this.handleConfirmDelete}
                  disabled={!this.state.selectedFileImport}
                  style={{ marginRight: "10px", marginLeft: "10px" }}
                >
                  Delete
                </Button>
                <Button disabled={!this.state.selectedFileImport} className={classes.rejectButton} variant="contained" onClick={this.handleReject}>
                  reject
                  {this.state.rejectLoading && <CircularProgress size={15} color="primary" style={{ marginLeft: "10px" }} />}
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  style={{ marginRight: "10px" }}
                  onClick={this.handleConfirmReimport}
                  disabled={!(this.state.selectedFileImport || { is_active: false }).is_active} //{!this.state.selectedFileImport || this.state.selectedFileImport.status !== FileImportStatus.Imported}
                >
                  reimport
                </Button>
                <Button variant="contained" color="primary" onClick={this.handleOpen} style={{ marginRight: "10px" }} disabled={!this.state.selectedFileImport}>
                  open
                </Button>
              </div>
              <Grid
                showFilterChips
                data={this.state.dataDetail}
                clearFilters={"importfiledetail"}
                GridColumns={ColumnsFileImportHeader}
                forceHeight={this.state.minGridHeightSecond}
                totalRowColumns={["TotalCartons", "TotalPallets"]}
                countRowColumns={["FormattedDate"]}
              />
            </div>
          </Splitter>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(ImportGridUnstyled);
