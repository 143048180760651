import React from "react";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";

import LinearProgress from "@material-ui/core/LinearProgress";
import CircularProgress from "@material-ui/core/CircularProgress";

import {
  producersale,
  ProducerSaleType,
  producerSaleDispatchJoined,
  ProducerSaleDispatchJoinedType,
  producerSaleBarcodesByDispatchID,
  ProducerSaleBarcodesByDispatchIDType,
} from "../../lib/api/producersale";
import { producersaleDetailInsertByBarcode, ProducerSaleDetailType } from "../../lib/api/producersaledetail";
import { getFinancialYearSelected } from "../../lib/api/week";

import InvoiceTable from "./invoicetable";
import Accept from "./accept/invoiceAccept";

import { SnackMessage } from "../../lib/helpers/snackmessage";
import Confirmation from "../../lib/components/confirmation";
import { isNullOrUndef } from "../../lib/helpers/isNullOrUndef";

import { Reports } from "../../lib/types";
import { SnackContext } from "../../lib/context/SnackContext";
import { GenerateErrorMessage } from "../../lib/helpers/string_methods";
const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      overflow: "hidden",
      width: "100%",
      height: "calc(100% - 65px)",
    },
  });

type ProducerInvoiceProps = { match: any; history: any } & WithStyles<typeof styles>;

class ProducerInvoiceUnstyled extends React.Component<ProducerInvoiceProps> {
  state = {
    sales: undefined,
    loading: true,
    selectedSale: undefined,
    selectedData: undefined,
    classes: undefined,
    snackshow: undefined,
    snackmessage: undefined,
    snackcolor: undefined,
    releaseData: undefined,
    producer_code: "",
  };

  constructor(props) {
    super(props);
    this.state.classes = props.classes;
    this.state.producer_code = props.match.params.producercode;
  }

  static contextType = SnackContext;
  context!: React.ContextType<typeof SnackContext>;

  componentDidMount() {
    this.loadSales().then(() => {});
  }

  loadSales = async () => {
    this.setState({ loading: true });
    const result = await producerSaleDispatchJoined(getFinancialYearSelected(), this.state.producer_code).catch((error) => {
      const err = GenerateErrorMessage(error, "failed to retrieve data");
      this.context.updateSnack({ show: true, color: "red", message: err });
    });
    if (result) {
      const notproformaRecords = result.filter((itemProForma) => itemProForma.dispatch_dispatchdocs_id != "PRO-FORMA");
      await this.setState({ sales: notproformaRecords, loading: false });
    }
  };

  onReload = async () => {
    this.setState({ loading: true });
    return await this.loadSales();
  };

  onAccept = (data, saleData) => {
    if (data.length > 0) {
      this.setState({ selectedData: data, selectedSale: saleData });
    }
  };

  onAcceptProcess = async (saledata: ProducerSaleDispatchJoinedType, saleDetails: [ProducerSaleBarcodesByDispatchIDType]) => {
    const salesData = {
      data: {
        dispatch_id: saledata.dispatch_id,
        date: saledata.sale_date ? saledata.sale_date : new Date(),
        invoicenumber: saledata.sale_invoicenumber,
        exchangerate: saledata.sale_exchangerate,
        terms_id: saledata.sale_terms_id == -1 ? null : saledata.sale_terms_id,
        currency_id: saledata.salecurr_id,
        producercode: saledata.producer_code,
      },
    };
    const saleid =
      this.state.selectedSale && isNullOrUndef(saledata.sale_id)
        ? await producersale.create(salesData)
        : await producersale.update(saledata.sale_id, salesData).then(() => [saledata.sale_id]);

    saleDetails.map(async (sditem) => {
      const dataToSend: ProducerSaleDetailType = {
        id: undefined,
        producersale_id: saleid[0],
        barcode_id: sditem.barcode_id,
        barcode: sditem.loadoutdetail_barcode,
        commodityCode: sditem.barcode_commoditycode,
        varietyCode: sditem.barcode_varietycode,
        gradeCode: sditem.barcode_gradecode,
        countCode: sditem.barcode_countcode,
        packCode: sditem.barcode_packcode,
        markCode: sditem.barcode_markcode,
        sellingprice: sditem.stockdetail_sellingprice,
        producerprice: sditem.stockdetail_producerprice,
      };
      await producersaleDetailInsertByBarcode(dataToSend);
    });
    await this.setState({ selectedData: undefined, selectedSale: undefined, snackshow: true, snackmessage: "Invoice complete", snackcolor: "green" });
    this.onReload();
  };

  onAcceptCancel = async () => {
    this.setState({ selectedData: undefined, selectedSale: undefined }, () => {
      this.onReload();
    });
  };

  onSnackClose = () => {
    this.setState({ snackshow: undefined, snackmessage: undefined });
  };

  onRelease = (selectedSale: ProducerSaleDispatchJoinedType) => {
    this.setState({ releaseData: selectedSale });
  };

  onReleaseClose = () => {
    this.setState({ releaseData: undefined });
  };

  onReleaseConfirm = async () => {
    try {
      await producersale.remove(this.state.releaseData.sale_id);
    } catch (error) {
      this.context.updateSnack({ show: true, color: "red", message: error });
    }
    this.setState({ releaseData: undefined });
    this.onReload();
  };

  render() {
    return (
      <div className={this.state.classes.root}>
        {this.state.loading && <CircularProgress />}
        {this.state.snackshow && (
          <div>
            <SnackMessage isOpen={this.state.snackshow} message={this.state.snackmessage} color={this.state.snackcolor} handleClose={this.onSnackClose} />
          </div>
        )}
        {this.state.loading && (
          <div>
            <LinearProgress />
          </div>
        )}
        {this.state.releaseData && (
          <Confirmation
            isOpen={true}
            handleClose={this.onReleaseClose}
            handleConfirm={this.onReleaseConfirm}
            title="Remove selected Invoice?"
            body="Are you sure you want to REMOVE the selected Invoice Record?"
          ></Confirmation>
        )}
        {this.state.selectedData && <Accept handleClose={this.onAcceptCancel} onSubmit={this.onAcceptProcess} defaultData={this.state.selectedSale} />}
        {!this.state.loading && !this.state.selectedData && (
          <InvoiceTable history={this.props.history} data={this.state.sales} onReload={this.onReload} onAccept={this.onAccept} onRelease={this.onRelease} />
        )}
      </div>
    );
  }
}

export default withStyles(styles)(ProducerInvoiceUnstyled);
