import jspdf from "jspdf";
import numeral from "numeral";
import format from "date-fns/format";
import autoTable from "jspdf-autotable";

import { PTSans } from "./elements/font";
import { get } from "./helpers";
import { image as logoImage } from "./elements/logo";
import { CompanyDetailsType } from "../lib/api/companydetails";
import { Reports } from "../lib/types";
import { SaleAdjustmentAdhocFullType } from "../lib/api/saleadjustmentadhoc";
import { CompanyDetailsBankingType } from "../lib/api/companydetailsbanking";

let fullWidth = 0,
  fullHeight = 0,
  page = 0,
  pages = [],
  fromTop = 0,
  fromLeft = 0,
  pdf,
  startBankingLine = { startPos: 0, endPos: 0 };

const initPDF = () => {
  pdf = new jspdf({
    orientation: "portrait",
    unit: "cm",
  });
  fullWidth = pdf.internal.pageSize.getWidth();
  fullHeight = pdf.internal.pageSize.getHeight();
  page = 1;
  pages = [page];
  fromTop = 0;
  fromLeft = 1;

  pdf.addFileToVFS("PTSans.ttf", PTSans);
  pdf.addFont("PTSans.ttf", "PTSans", "normal");
  pdf.setFont("PTSans");
  pdf.setLineWidth(0.01);
};

const setFontBold = () => {
  pdf.setFontStyle("bold");
  pdf.setFont("helvetica"); // set font
};

const setFontNormal = () => {
  pdf.setFontStyle("normal");
  pdf.setFont("PTSans"); // set font
};

const newLine = (howFar: number = 0.5) => {
  fromTop = fromTop + howFar;
};

const headerColumn1 = () => {
  return 3.4;
};
const headerColumn2 = () => {
  return fullWidth / 2 - 1.5;
};
const headerColumn3 = () => {
  return fullWidth - 3.6;
};

const addImage = (imageData: string, format: string, fromLeft: number, fromTop: number, width: number, height: number, alias?, compression?, rotation?) => {
  pdf.addImage(imageData, format, fromLeft, fromTop, width, height, alias, compression, rotation);
};

const printLine = (left: number, top: number, label: string, value: string, boldValue = false) => {
  setFontBold();
  pdf.text(label, left, top, "right");
  if (!boldValue) {
    setFontNormal();
  }
  pdf.text(value, left + 0.2, top, "left");
};

const newPage = () => {
  pdf.addPage();

  page++;
  pages.push(page);
  pdf.setPage(page);
};

const printHeader = (companyDetails: CompanyDetailsType, report: Reports) => {
  fromTop = 1;
  fromLeft = 1;

  const imageWidth = 5;
  const imageHeight = 1.5;

  addImage(logoImage, "PNG", (fullWidth - imageWidth) / 2 - 1, fromTop + 1, imageWidth, imageHeight);
  pdf.setFontSize(11);
  setFontBold();
  pdf.text(get("name", companyDetails, ""), fromLeft, fromTop, "left");

  pdf.setFontSize(12);
  setFontBold();
  pdf.text("", fullWidth / 2 - 1, fromTop + 2.5, "center");

  setFontNormal();
  pdf.setFontSize(8);

  newLine(0.4);
  pdf.text(get("address1", companyDetails, ""), fromLeft, fromTop, "left");

  newLine(0.4);
  pdf.text(get("address2", companyDetails, ""), fromLeft, fromTop, "left");

  newLine(0.4);
  pdf.text(get("address3", companyDetails, ""), fromLeft, fromTop, "left");

  newLine(0.4);
  pdf.text(get("address4", companyDetails, ""), fromLeft, fromTop, "left");

  newLine(0.4);
  pdf.text(get("address5", companyDetails, ""), fromLeft, fromTop, "left");

  fromLeft = headerColumn3() - 1;
  fromTop = 1;

  pdf.text("Tel No:", fromLeft, fromTop, "right");
  pdf.text(get("telephoneNumber", companyDetails, ""), fromLeft + 0.2, fromTop, "left");

  newLine(0.4);
  pdf.text("Fax No:", fromLeft, fromTop, "right");
  pdf.text(get("faxNumber", companyDetails, ""), fromLeft + 0.2, fromTop, "left");

  newLine(0.4);
  pdf.text("E-mail:", fromLeft, fromTop, "right");
  pdf.text(get("email", companyDetails, ""), fromLeft + 0.2, fromTop, "left");

  newLine(0.4);
  pdf.text("Vat No:", fromLeft, fromTop, "right");
  pdf.text(get("vatno", companyDetails, ""), fromLeft + 0.2, fromTop, "left");

  newLine(0.4);
  pdf.text("Reg No:", fromLeft, fromTop, "right");
  pdf.text(get("regno", companyDetails, ""), fromLeft + 0.2, fromTop, "left");

  newLine(0.4);
  pdf.text("Customs Code:", fromLeft, fromTop, "right");
  pdf.text(get("customscode", companyDetails, ""), fromLeft + 0.2, fromTop, "left");

  // title of PDF
  newLine(0.5);
  setFontBold();
  pdf.setFontSize(12);
  pdf.text(`${report}`, fullWidth / 2 - 3, fromTop + 0.5, "left");

  pdf.setFontSize(8);
};

const printSummary = (selectedAdhoc: SaleAdjustmentAdhocFullType) => {
  newLine(1);

  const startRect = fromTop;
  pdf.setFontSize(8);

  // Line 1
  newLine(0.4);
  printLine(headerColumn1(), fromTop, "Date:", format(new Date(get("date", selectedAdhoc, "")), "dd MMM yyyy"));
  // Line 2
  newLine(0.4);
  printLine(headerColumn1(), fromTop, "Client:", get("clients_name", selectedAdhoc, ""));
  // Line 3
  newLine(0.4);
  printLine(headerColumn1(), fromTop, selectedAdhoc.type == "Debit" ? "Debit Note No:" : "Credit Note No:", get("document_no", selectedAdhoc, ""));
  // Line 4
  newLine(0.4);
  printLine(headerColumn1(), fromTop, "Description:", get("description", selectedAdhoc, ""));

  pdf.setLineWidth(0.01);
  pdf.rect(1, startRect, fullWidth - 2, fromTop - startRect + 0.25, "S");
};

const headers = [
  { dataKey: "description", header: "Description" },
  { dataKey: "amount", header: "Amount" },
];

const printDetail = (selectedAdhoc: SaleAdjustmentAdhocFullType) => {
  newLine(1);
  fromLeft = 1;

  setFontBold();
  pdf.setFontSize(12);
  pdf.text("Summary", fromLeft + 0.15, fromTop, "left");

  setFontNormal();
  pdf.setFontSize(8);

  newLine(0.25);

  const startRect = fromTop;
  const formattedData = new Array(1).fill({ description: selectedAdhoc.description, amount: numeral(selectedAdhoc.amount).format("0,0.00") });

  autoTable(pdf, {
    theme: "plain",
    margin: [1, 1, 1, 1],
    tableLineColor: [0, 0, 0],
    tableLineWidth: 0.01,
    startY: startRect,
    columns: headers,
    body: formattedData,
    showFoot: "lastPage",
    styles: { fontSize: 8, fillColor: [255, 255, 255], textColor: [0, 0, 0], cellPadding: [0.15, 0.15, 0.15, 0.15] },
    columnStyles: {
      description: { halign: "left" },
      amount: { halign: "right" },
    },
    headStyles: { fontStyle: "bold", cellPadding: [0.15, 0.15, 0.15, 0.15] },
    bodyStyles: { fontSize: 8 },
    alternateRowStyles: { fillColor: [255, 255, 255] },
    didDrawPage: (data) => {
      fromTop = data.cursor.y;
    },
    willDrawCell: (data) => {
      data.row.height = 0.5;

      // right align "Amount" column
      if (data.section === "head" && data.cell.raw.toString().toLowerCase() === "amount") {
        data.cell.styles = { ...data.cell.styles, halign: "right" };
      }
    },
  });
};

const printTermsConditions = () => {
  const heightOfPage = pdf.internal.pageSize.getHeight();
  fromLeft = 1.2;
  newLine(1.5);

  if (fromTop > heightOfPage - 3) {
    newPage();
    fromTop = 2;
  }

  // The rectangle around the text
  pdf.setLineWidth(0.01);
  pdf.rect(1, fromTop - 0.5, fullWidth - 2, 2.25, "S");

  setFontBold();
  pdf.setFontSize(9);

  pdf.text("* Any quality deviation must be reported by e-mail within 48 hours of collecting the container.", fromLeft, fromTop, "left");

  newLine();
  pdf.text("* Claims will not be entertained if notice is given after 48 hours.", fromLeft, fromTop, "left");

  newLine();
  pdf.text("* Claims will only be entertained if the Impala Citrus claims procedure is adhered to, as set out in Trade Agreement.", fromLeft, fromTop, "left");

  newLine();
  pdf.text("* Interest at a rate of 2% per month will be charged on overdue invoices.", fromLeft, fromTop, "left");
};

const bankDetailNewLine = () => {
  fromLeft = 5.5;
  newLine();
};

const bankDetailPrintText = (text: string, position: string, fromLeftAdd: number) => {
  if (position === "right") {
    bankDetailNewLine();
    setFontBold();
    pdf.text(text, fromLeft + fromLeftAdd, fromTop, position);
  } else {
    setFontNormal();
    pdf.text(text, fromLeft + fromLeftAdd + 0.2, fromTop, position);
  }
};

const printBankingDetails = (bankDetails) => {
  fromLeft = 1;
  fromTop += 0.25;

  if (fromTop >= 24) {
    newPage();
    fromTop = 2;
  }

  startBankingLine = { startPos: fromTop, endPos: 0 };

  fromTop += 0.5;

  pdf.setFontSize(10);
  newLine();
  setFontBold();
  pdf.text("BANKING DETAILS ", fromLeft + 0.25, fromTop, "left");
  pdf.rect(fromLeft + 0.25, fromTop + 0.1, pdf.getTextDimensions("BANKING DETAILS").w, 0.01, "S");

  fromTop += 0.25;
  const fromTopStart = fromTop;

  pdf.setFontSize(8);

  bankDetailPrintText("ACCOUNT HOLDER: ", "right", 0);
  bankDetailPrintText(!bankDetails || !bankDetails.accountholder ? "" : bankDetails.accountholder.toString(), "left", 0);

  bankDetailPrintText("ACCOUNT HOLDER ADDRESS: ", "right", 0);
  bankDetailPrintText(!bankDetails || !bankDetails.accountholderaddress ? "" : bankDetails.accountholderaddress.toString(), "left", 0);

  bankDetailPrintText("BANK: ", "right", 0);
  bankDetailPrintText(!bankDetails || !bankDetails.bank ? "" : bankDetails.bank.toString(), "left", 0);

  bankDetailPrintText("BANK ADDRESS: ", "right", 0);
  bankDetailPrintText(!bankDetails || !bankDetails.bankaddress ? "" : bankDetails.bankaddress.toString(), "left", 0);

  fromTop = fromTopStart;
  bankDetailPrintText("BRANCH CODE: ", "right", 11);
  bankDetailPrintText(!bankDetails || !bankDetails.branchcode ? "" : bankDetails.branchcode.toString(), "left", 11);

  bankDetailPrintText("ACCOUNT TYPE: ", "right", 11);
  bankDetailPrintText(!bankDetails || !bankDetails.accounttype ? "" : bankDetails.accounttype.toString(), "left", 11);

  bankDetailPrintText("ACCOUNT NUMBER: ", "right", 11);
  bankDetailPrintText(!bankDetails || !bankDetails.accountnumber ? "" : bankDetails.accountnumber.toString(), "left", 11);

  bankDetailPrintText("SWIFT CODE: ", "right", 11);
  bankDetailPrintText(!bankDetails || !bankDetails.swiftcode ? "" : bankDetails.swiftcode.toString(), "left", 11);

  fromLeft = 1;
  startBankingLine = { ...startBankingLine, endPos: fromTop - startBankingLine.startPos };

  pdf.rect(1, startBankingLine.startPos + 0.25, fullWidth - 2, startBankingLine.endPos + 0.25, "S");
};

const printFooter = () => {
  const pageCount = pdf.internal.getNumberOfPages();

  for (let index = 0; index < pageCount; index++) {
    pdf.setPage(index + 1);
    pdf.text(`Page ${index + 1} of ${pageCount}`, fullWidth - 1, fullHeight - 1, "right");
  }
};

export const printAdhocInvoice = (companyDetails: CompanyDetailsType, report: Reports, selectedAdhoc: SaleAdjustmentAdhocFullType, bankingDetails: CompanyDetailsBankingType) => {
  initPDF();

  printHeader(companyDetails, report);
  printSummary(selectedAdhoc);
  printDetail(selectedAdhoc);
  printBankingDetails(bankingDetails);
  printTermsConditions();
  printFooter();

  let filename = "DEBTORS_ADJUSTMENT_ADHOC.pdf";
  pdf.save(filename);
};
