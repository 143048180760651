import React from "react";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";

import Tooltip from "@material-ui/core/Tooltip";
import Button from "@material-ui/core/Button";

import Close from "@material-ui/icons/Close";
import IconAdd from "@material-ui/icons/Add";

import Grid from "../../lib/components/grid";
import Confirmation from "../../lib/components/confirmation";
import { creditors, creditorsFull } from "../../lib/api/creditors";

import { GridColumns } from "./creditorsgridsetup";

import CreditorsEditForm from "./creditorseditform";
import { SnackContext } from "../../lib/context/SnackContext";
import { GenerateErrorMessage } from "../../lib/helpers/string_methods";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1),
      width: "100%",
      height: "100%",
      position: "relative",
    },
    inline: {
      display: "inline",
      position: "absolute",
      marginTop: "7px",
      marginLeft: "7px",
    },
    closeIcon: {
      display: "flex",
      justifyContent: "flex-end",
      margin: "10px 0px -5px",
      position: "fixed",
      top: "10px",
      right: "15px",
    },
  });

type CreditorsProps = { handleOnClose?: any } & WithStyles<typeof styles>;

export class CreditorsUnstyled extends React.Component<CreditorsProps, any> {
  state = {
    classes: undefined,
    data: [],
    confirmRemoveID: undefined,
    confirmEditID: undefined,
    loading: true,
    handleOnClose: undefined,
  };

  constructor(props) {
    super(props);
    this.state.classes = props.classes;
    this.state.handleOnClose = props.handleOnClose;
  }

  static contextType = SnackContext;
  context!: React.ContextType<typeof SnackContext>;

  componentDidMount() {
    this.loadData();
  }

  loadData = () => {
    creditorsFull().then((data) => {
      const dataSorted = data.sort((a, b) => {
        if (a.code > b.code) return 1;
        if (a.code < b.code) return -1;
        return 1;
      });
      this.setState({ loading: false, data: dataSorted });
    });
  };

  handleCreate = () => {
    this.setState({ confirmEditID: "0" });
  };

  handleEdit = (id) => {
    this.setState({ confirmEditID: id });
  };

  handleEditClose = () => {
    this.setState({ confirmEditID: undefined });
  };

  handleEditConfirm = async (item) => {
    try {
      const id = item["id"];

      const creditorData = {
        data: {
          code: item.code,
          contact_person: item.contact_person,
          currency_id: item.currency_id,
          email: item.email,
          creditorsgroupsub_id: item.creditorsgroupsub_id,
          name: item.name,
          physical_address: item.physical_address,
          postal_address: item.postal_address,
          telephone: item.telephone,
          vat_number: parseInt(item["vat_number"]),
          payment_term_id: parseInt(item.payment_term_id),
          consolidationapply: item.consolidationapply ? item.consolidationapply : 1,
          active: item.active,
        },
      };

      if (id == "0") {
        await creditors.create(creditorData);
      } else {
        await creditors.update(id, creditorData);
      }
      this.loadData();
    } catch (error) {
      const err = GenerateErrorMessage(error, "Error creating creditor");
      this.context.updateSnack({ show: true, color: "red", message: err });
    }
    this.handleEditClose();
  };

  handleRemove = async (id) => {
    this.setState({ confirmRemoveID: id });
  };

  handleRemoveClose = () => {
    this.setState({ confirmRemoveID: undefined });
  };

  handleRemoveConfirm = async () => {
    try {
      await creditors.remove(this.state.confirmRemoveID);
      this.loadData();
    } catch (error) {
      const err = GenerateErrorMessage(error, "Error removing creditor.");
      this.context.updateSnack({ show: true, color: "red", message: err });
    }
    this.handleRemoveClose();
  };

  render() {
    const { classes } = this.state;

    return (
      <div className={classes.root}>
        <div className={classes.inline}>
          {this.state.handleOnClose && (
            <div className={classes.closeIcon}>
              <Button style={{ marginRight: "10px", backgroundColor: "white" }} color="secondary" variant="outlined" onClick={this.state.handleOnClose}>
                <Close />
              </Button>
            </div>
          )}
          <Tooltip title="Create new Creditors">
            <div className={classes.inline}>
              <Button variant="contained" color="primary" onClick={this.handleCreate} style={{ marginBottom: "10px" }}>
                <IconAdd />
              </Button>
            </div>
          </Tooltip>
        </div>
        <Grid
          loading={this.state.loading}
          data={this.state.data}
          GridColumns={(data, filters, columnArrangement, columnsWidth) => GridColumns(data, filters, columnArrangement, columnsWidth, this.handleRemove, this.handleEdit)}
          clearFilters={"maintenancecreditors"}
        />
        {this.state.confirmRemoveID && (
          <Confirmation
            isOpen={this.state.confirmRemoveID ? true : false}
            handleClose={this.handleRemoveClose}
            handleConfirm={this.handleRemoveConfirm}
            title="Remove selected Creditors?"
            body="Are you sure you want to REMOVE the selected Creditors Record?"
          ></Confirmation>
        )}
        <div style={{ width: "600px" }}>
          {this.state.confirmEditID && (
            <Confirmation
              isOpen={this.state.confirmEditID ? true : false}
              handleClose={this.handleEditClose}
              handleConfirm={() => {}}
              title={this.state.confirmEditID == "0" ? "Creating Creditors" : "Editing Creditors"}
              body={undefined}
            >
              <CreditorsEditForm onSubmit={this.handleEditConfirm} onClose={this.handleEditClose} id={this.state.confirmEditID} />
            </Confirmation>
          )}
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(CreditorsUnstyled);
