import React, { useState, useContext } from "react";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";

import Button from "@material-ui/core/Button";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

import ReactDatePicker from "react-datepicker";
import toDate from "date-fns/toDate";
import format from "date-fns/format";

import { SnackContext } from "../../../lib/context/SnackContext";
import { getDebtorsFullOustandingByClientsCodeDateRange } from "../../../lib/api/debtorsfull";
import { handlePrintStatement } from "../../../reports/printing";

import { GenerateErrorMessage } from "../../../lib/helpers/string_methods";
import { ExcelIcon, PDFIcon } from "../../../lib/components/MenuListItem";
import { getFinancialYearSelected } from "../../../lib/api/week";
import { LinearProgress, Modal } from "@material-ui/core";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
      gap: "0.5rem",
    },
    header: {
      display: "flex",
      flexDirection: "row",
      gap: "1rem",
    },
    actions: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-end",
      gap: "1rem",
    },
    datePickerWrapper: {
      display: "flex",
      flexDirection: "row",
      gap: "1rem",
      alignItems: "center",
    },
    modalWrapper: {
      position: "absolute",
      left: "45%",
      top: "50%",
      transform: "translate(-50%, -50%)",
    },
  });

type StatementProps = {
  clientscode: string;
  onClose: (accept) => void;
} & WithStyles<typeof styles>;

const StatementUnstyled: React.FunctionComponent<StatementProps> = (props) => {
  const { classes, clientscode, onClose } = props;

  const { updateSnack } = useContext(SnackContext);

  const [loading, setLoading] = useState(false);
  const [dateFrom, setDateFrom] = useState(new Date(new Date(`1 Feb ${getFinancialYearSelected()}`).toDateString()));
  const [dateTo, setDateTo] = useState(new Date(new Date().toDateString()));
  const [printExcludeZero, toggleExcludeZero] = useState(false);

  const changeAdjustDate = (value: Date, type: string = "from") => {
    if (type == "from") {
      setDateFrom(new Date(value.toDateString()));
    } else {
      setDateTo(new Date(value.toDateString()));
    }
  };

  const handlePrintPdf = async () => {
    setLoading(true);
    try {
      const data = await getDebtorsFullOustandingByClientsCodeDateRange(clientscode, { dateFrom: format(dateFrom, "yyyy-MM-dd"), dateTo: format(dateTo, "yyyy-MM-dd") });
      handlePrintStatement(data, dateTo, dateFrom, clientscode, printExcludeZero);
    } catch (error) {
      const err = GenerateErrorMessage(error, "Error printing Invoice");
      updateSnack({ show: true, color: "red", message: err });
    }
    setLoading(false);
  };

  const handlePrintExcel = () => {
    window.location.href = `/api/debtorsfull/ext/printExcelStatement?excludeZeroBalances=${printExcludeZero ? 1 : 0}&dateTo=${format(dateTo, "yyyy-MM-dd")}&dateFrom=${format(
      dateFrom,
      "yyyy-MM-dd",
    )}&clientsCode=${clientscode}&financial_year=${getFinancialYearSelected()}`;
  };

  return (
    <div className={classes.root}>
      {loading ? <LinearProgress /> : <div style={{ height: "5px" }}></div>}
      <div className={classes.header}>
        <TableFieldDate classes={classes} dateValue={dateFrom} title="From" changeDate={(value) => changeAdjustDate(value, "from")} disabled={loading} />
        <TableFieldDate classes={classes} dateValue={dateTo} title="To" changeDate={(value) => changeAdjustDate(value, "to")} disabled={loading} />
      </div>
      <div>
        <FormControlLabel
          control={<Checkbox checked={printExcludeZero} disabled={loading} onChange={(event) => toggleExcludeZero(event.target.checked)} name="exclude" color="primary" />}
          label="Exclude Zero Balances"
        />
      </div>
      <div className={classes.actions}>
        <Button variant="contained" onClick={handlePrintPdf} disabled={loading}>
          <PDFIcon /> <span style={{ marginLeft: "1rem" }}>PDF EXPORT</span>
        </Button>
        <Button variant="contained" onClick={handlePrintExcel} disabled={loading}>
          <ExcelIcon /> <span style={{ marginLeft: "1rem" }}>EXCEL EXPORT</span>
        </Button>
      </div>
    </div>
  );
};

export default withStyles(styles)(StatementUnstyled);

const TableFieldDate: React.FunctionComponent<{ dateValue: Date; title: string; changeDate: (value) => void; disabled: boolean } & WithStyles<typeof styles>> = (props) => {
  const { classes, dateValue, title, changeDate, disabled } = props;
  const [open, setOpen] = useState(false);

  const handleChange = (value: Date) => {
    changeDate(value);
    setOpen(!open);
  };

  return (
    <div className={classes.datePickerWrapper}>
      <label>{title}:</label>
      <div>
        <Button onClick={() => setOpen(!open)} disabled={disabled} variant="contained" color="primary" style={{ marginTop: "5px", width: "250px" }}>
          {format(dateValue, "dd-MM-yyyy")}
        </Button>
      </div>
      <Modal open={open} onClick={() => setOpen(false)}>
        <div className={classes.modalWrapper}>
          <ReactDatePicker
            open
            locale="en-GB"
            showWeekNumbers
            customInput={<></>}
            disabled={disabled}
            showPopperArrow={false}
            dateFormat="dd-MM-yyyy"
            placeholderText="click here to select a date"
            onChange={handleChange}
            selected={toDate(dateValue)}
          />
        </div>
      </Modal>
    </div>
  );
};
