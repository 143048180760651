import { client, apiHeaders, request } from "./_base";

export type EstimatedCostsData = {
  id: number;
  original_id: number;
  pack_id: number;
  cartons: string;
  commodity: string;
  commodity_full: string;
  market: string;
  pack: string;
  season: string;
  variety: string;
  status: number;
  updated_at: string;
  created_at: string;
  exchange_cad: number;
  exchange_eur: number;
  exchange_gbp: number;
  exchange_usd: number;
  exchange_zar: number;
  totalproducercost_cad: number;
  totalproducercost_eur: number;
  totalproducercost_gbp: number;
  totalproducercost_usd: number;
  totalproducercost_zar: number;
  container: number;
  conventional: number;
  port_id: number;
  port: string;
  countries: string;
  country: string;
  region: string;
};

const endpoint = "estimatedcost";

export const estimatedcosts = client(endpoint, (item) => ({
  ...item,
}));

export const estimatedcostsFull = async (estimatedcost_id: number = 0) => {
  const result = await request(`${endpoint}/ext/full/${estimatedcost_id}`, {
    headers: apiHeaders(),
  });
  const resp = await result.json();
  if (result.status == 500) {
    throw resp;
  }
  return resp.data;
};

export const estimatedcostsDistinctCountries = async () => {
  const result = await request(`${endpoint}/ext/distinctCountry`, {
    headers: apiHeaders(),
  });
  if (result.status == 500) {
    const resp = await result.json();
    throw resp;
  }
  return result.json();
};

export const execRefreshConsolidationCache = async (): Promise<void> => {
  const result = await request(`${endpoint}/ext/execRefreshConsolidationCache`, {
    headers: apiHeaders(),
  });
  if (result.status == 500) {
    const resp = await result.json();
    throw resp;
  }
};

export const upsertEstimatedCostPorts = async (data: { estimatedcost_id: number; ports: number[] }): Promise<void> => {
  const result = await request(`${endpoint}/ext/upsertEstimatedCostPorts`, {
    headers: apiHeaders(),
    method: "POST",
    body: JSON.stringify(data),
  });
  if (result.status == 500) {
    const resp = await result.json();
    throw resp;
  }
};

export const upsertEstimatedCostTargets = async (data: { estimatedcost_id: number; targets: number[] }): Promise<void> => {
  const result = await request(`${endpoint}/ext/upsertEstimatedCostTargets`, {
    headers: apiHeaders(),
    method: "POST",
    body: JSON.stringify(data),
  });
  if (result.status == 500) {
    const resp = await result.json();
    throw resp;
  }
};
