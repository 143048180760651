import { client, request } from "./_base";

const endpoint = "incomingfundidents";

export const IncomingFundIdents = client(endpoint, (item) => ({
  ...item,
}));

export const getMaxIncomingFundId = async () => {
  const result = await request(`${endpoint}/ext/getMaxIncomingFundId`, {
    method: "GET",
  });
  const resp = await result.json();
  if (result.status == 500) {
    throw resp;
  }
  return resp.data;
};
