import React, { useState, useEffect } from "react";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";

import Button from "@material-ui/core/Button";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import Dialog from "@material-ui/core/Dialog";
import LinearProgress from "@material-ui/core/LinearProgress";
import Snackbar from "@material-ui/core/Snackbar";

import EditUser from "./EditUser";
import NotAuth from "./NotAuth";

import { DialogInformation } from "../lib/components/dialoginformation";
import Confirmation from "../lib/components/confirmation";

import { clearStickyFilters } from "../lib/helpers/stickyfilters";

import { users } from "../lib/api/users";
import { userProfileRemoveByUserID } from "../lib/api/userProfiles";

import { UserContext } from "../lib/context/UserContext";
import Grid from "../lib/components/grid";
import { GridColumns } from "./usermanagementgridsetup";
import { GenerateErrorMessage } from "../lib/helpers/string_methods";
import { SnackContext } from "../lib/context/SnackContext";
import { logout } from "../lib/api/_base";
import { history } from "../lib/history";
import { Redirect } from "react-router-dom";
import Toolbar from "@material-ui/core/Toolbar";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1),
      width: "100%",
    },
    buttonCancel: {
      marginLeft: `${theme.spacing(1) * 2}px`,
    },
    buttonContainer: {
      marginTop: `${theme.spacing(1) * 4}px`,
      display: "flex",
    },
    dialog: {
      padding: `${theme.spacing(1) * 4}px`,
    },
    table: {
      width: "500px",
    },
    inline: {
      display: "inline",
    },
    toolbar: {
      position: "absolute",
      left: 0,
      width: "100%",
    },
    wrapper: {
      position: "relative",
    },
  });

type UserManagementProps = {} & WithStyles<typeof styles>;

const UserManagementUnstyled: React.SFC<UserManagementProps> = (props) => {
  const { classes } = props;

  const { user } = React.useContext(UserContext);
  const { updateSnack } = React.useContext(SnackContext);

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [snackbarState, setSnackbarState] = useState({ message: "", isOpen: false });
  const [refresh, setRefresh] = useState(false);
  const [dialogOpen, setDialogOpen] = useState({ isOpen: false, id: null, name: "" });
  const [dialoginformation, setDialoginformation] = useState(false);
  const [showClearCacheDialog, toggleClearCacheDialog] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [permissionChanged, setPermissionChanged] = useState(false);
  const [showLogoutDialog, setShowLogoutDialog] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [showInactiveUsers, toggleInactiveUsers] = useState(false);

  const fetchData = async () => {
    try {
      const resultUsers = await users.all();

      setData(resultUsers);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchData();
  }, [refresh]);

  const handleInformationOk = () => {
    setDialoginformation(false);
  };

  const handleClearStorage = (clearFilters: boolean) => {
    clearStickyFilters(clearFilters);
    userProfileRemoveByUserID(user.id);
    toggleClearCacheDialog(false);
    setDialoginformation(true);
  };

  const handleConfirmClearCacheDialog = () => {
    handleClearStorage(true);
  };

  const handleCloseClearCacheDialog = () => {
    handleClearStorage(false);
  };

  if (loading) return <LinearProgress />;

  if (!user.admin) return <NotAuth />;

  const handleRemove = (row) => setDialogOpen({ isOpen: true, id: row.id, name: row.username });

  const handleEdit = (row) => setSelectedUserId(row.id);

  if (redirect) {
    return <Redirect to="/users" />;
  }

  const handleSubmit = async (fields) => {
    setLoading(true);
    try {
      await users.update(selectedUserId, { data: fields });
      setSnackbarState({ isOpen: true, message: `${fields.username} was edited` });
    } catch (error) {
      setSnackbarState({ isOpen: true, message: "Failed to update user" });
    }
    setLoading(false);
    setRefresh(!refresh);
    setSelectedUserId(null);
    if (permissionChanged) {
      setSelectedUserId(null);
      setShowLogoutDialog(!showLogoutDialog);
    } else {
      setRedirect(true);
    }
  };

  const handleLogout = () => {
    logout();
    window.localStorage.setItem("loggedIn", "false");
    history.push("/login");
  };

  return (
    <div className={classes.root}>
      <div className={classes.wrapper}>
        <Toolbar className={classes.toolbar}>
          <FormControlLabel
            control={<Switch inputProps={{ "aria-label": "primary checkbox" }} checked={showInactiveUsers} onChange={() => toggleInactiveUsers(!showInactiveUsers)} />}
            label="Show Inactive Users"
          />
        </Toolbar>
        <Grid
          data={data.filter((user) => showInactiveUsers == !user.active)}
          GridColumns={(data, filters, arrangement, columnsWidth) => GridColumns(data, filters, arrangement, columnsWidth, handleRemove, handleEdit, user)}
          loading={false}
          clearFilters={"usermanagementgridsetup"}
          handleRefresh={fetchData}
        />
      </div>
      <Dialog onClose={() => setDialogOpen({ isOpen: false, id: null, name: "" })} aria-labelledby="simple-dialog-title" open={dialogOpen.isOpen}>
        <div className={classes.dialog}>
          Are you sure you want to delete {dialogOpen.name}?
          <div className={classes.buttonContainer}>
            <Button
              variant="contained"
              color="primary"
              onClick={async () => {
                try {
                  await users.remove(dialogOpen.id);
                  setSnackbarState({ message: `${dialogOpen.name} has been deleted`, isOpen: true });
                  setDialogOpen({ isOpen: false, id: null, name: "" });
                  setRefresh(!refresh);
                } catch (error) {
                  setDialogOpen({ isOpen: false, id: null, name: "" });
                  const err = GenerateErrorMessage(error, "Error deleting user");
                  updateSnack({ show: true, color: "red", message: err });
                  setRefresh(!refresh);
                }
              }}
            >
              <CheckIcon />
            </Button>

            <Button className={classes.buttonCancel} variant="contained" color="secondary" onClick={() => setDialogOpen({ isOpen: false, id: null, name: "" })}>
              <CloseIcon />
            </Button>
          </div>
        </div>
      </Dialog>

      <Snackbar
        autoHideDuration={6000}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={snackbarState.isOpen}
        onClose={() => setSnackbarState({ isOpen: false, message: "" })}
        ContentProps={{
          "aria-describedby": "message-id",
        }}
        message={<span id="message-id">{snackbarState.message}</span>}
      />
      {showLogoutDialog && (
        <Confirmation
          isOpen
          handleClose={() => {
            setSelectedUserId(null);
            setShowLogoutDialog(false);
            setRefresh(!refresh);
          }}
          handleConfirm={() => handleLogout()}
          title={"Permission updated"}
          body={"Logout and log back in to apply changes. Logout now?"}
          buttonCaptionObject={{ no: "No", yes: "Yes" }}
        />
      )}
      {selectedUserId && (
        <Confirmation
          isOpen={true}
          handleClose={() => setSelectedUserId(null)}
          handleConfirm={null}
          title={"Edit User"}
          body={undefined}
          handleBackdropClick={() => {
            setSelectedUserId(null);
          }}
        >
          <EditUser id={selectedUserId} setSelectedUserId={setSelectedUserId} handleSubmit={handleSubmit} setPermissionChanged={setPermissionChanged} />
        </Confirmation>
      )}
      {dialoginformation && (
        <DialogInformation
          isOpen={dialoginformation}
          handleClose={handleInformationOk}
          handleOK={handleInformationOk}
          title={`Local Storage`}
          body={`Local storage on this Computer has been cleared!`}
          showinput={false}
        />
      )}
      {showClearCacheDialog && (
        <Confirmation
          isOpen={true}
          disableBackdropClick={true}
          handleClose={handleCloseClearCacheDialog}
          handleConfirm={handleConfirmClearCacheDialog}
          title={"Clear grid layout?"}
          body={"Do you want to clear the grid layouts?"}
        ></Confirmation>
      )}
    </div>
  );
};

export default withStyles(styles)(UserManagementUnstyled);
