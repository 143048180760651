import React, { useEffect, useState, FC, useMemo, forwardRef, useCallback } from "react";

import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";

import { FormApi } from "final-form";
import numeral from "numeral";
import { Field, Form } from "react-final-form";
import { TextField, Radio } from "final-form-material-ui";
import BigNumber from "bignumber.js";
import ReactDatePicker from "react-datepicker";
import toDate from "date-fns/toDate";

import MTextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import LinearProgress from "@material-ui/core/LinearProgress";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";

import Autocomplete from "@material-ui/lab/Autocomplete";

import { getClientsAllSortedMappedforComboID } from "../../../lib/api/clients";
import {
  createSaleAdjustmentAdhoc,
  generateNewDocNumber,
  getSaleAdjustmentAdhocById,
  SaleAdjustmentAdhocSaleType,
  SaleAdjustmentAdhocType,
} from "../../../lib/api/saleadjustmentadhoc";
import { GreenButton, RedButton } from "../../../lib/components/ColorButtons";
import { GenerateErrorMessage } from "../../../lib/helpers/string_methods";
import { SnackContext } from "../../../lib/context/SnackContext";
import { LinkedShipments } from "./linkedshipments";
import { DebtorsLinked, getDebtorsLinked, getDebtorsLinkedByAdhocId } from "../../../lib/api/debtorsfull";
import { getFinancialYearSelected } from "../../../lib/api/week";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(2),
      height: "calc(100vh - 100px)",
      width: "100%",
      position: "relative",
    },
    form: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(2),
      fontSize: theme.typography.body2.fontSize,
    },
    formRow: {
      display: "flex",
      flexDirection: "row",
      gap: theme.spacing(2),
      "& label": {
        display: "flex",
        width: "150px",
        justifyContent: "right",
        alignItems: "center",
      },
      "& div:first-child": {
        width: "350px",
      },
    },
    dateRow: {
      display: "flex",
      flexDirection: "row",
      gap: theme.spacing(2),
      "& label": {
        display: "flex",
        width: "150px",
        justifyContent: "right",
        alignItems: "center",
      },
    },
  });

type DebtorsFullAdhocProps = {
  history: any;
  match: any;
} & WithStyles<typeof styles>;

const numFmt = (number: string | number) => parseFloat(numeral(number).format("0.00"));

const DebtorsFullAdhocUnstyled: FC<DebtorsFullAdhocProps> = ({ history, match, classes }) => {
  const { updateSnack } = React.useContext(SnackContext);

  const [loading, setLoading] = useState(true);
  const [clientsList, setClientsList] = useState<{ value: number; display: string }[]>([]);
  const [currentAdhoc, setCurrentAdhoc] = useState<SaleAdjustmentAdhocType>();
  const [documentNumber, setDocumentNumber] = useState<number>(0);
  const [linkedShipments, setLinkedShipments] = useState<DebtorsLinked[]>([]);

  const adhocId = useMemo(() => match.params.adhoc_id, [match.params]);

  const loadData = async () => {
    setLoading(true);
    try {
      const [clients, newDocNumber] = await Promise.all([getClientsAllSortedMappedforComboID(), generateNewDocNumber()]);
      setDocumentNumber((newDocNumber[0] || { document_no: 1 }).document_no);

      if (parseInt(adhocId)) {
        const [result] = await getSaleAdjustmentAdhocById(adhocId);
        setCurrentAdhoc({
          ...result,
          type: result.type === SaleAdjustmentAdhocSaleType.Credit ? "credit" : "debit",
        });
      }

      setClientsList(clients);
    } catch (error) {
      const err = GenerateErrorMessage(error, "Error getting Adhoc");
      updateSnack({ show: true, color: "red", message: err });
    }
    setLoading(false);
  };

  const handleClose = () => {
    history.push("/debtors/full");
  };

  const handleSubmit = async (values: SaleAdjustmentAdhocType, linkedShipments: DebtorsLinked[]) => {
    setLoading(true);
    try {
      const formValues: SaleAdjustmentAdhocType = {
        id: parseInt(adhocId),
        clients_id: values.clients_id,
        description: values.description,
        type: values.type === "credit" ? SaleAdjustmentAdhocSaleType.Credit : SaleAdjustmentAdhocSaleType.Debit,
        document_no: parseInt(values.document_no.toString().slice(-3)),
        amount: parseFloat(values.amount.toString().replaceAll(",", "")),
        link_to_shipment: values.link_to_shipment,
        duedate: values.duedate || new Date(),
        date: values.date || new Date(),
      };
      const formattedLinkedShipments = linkedShipments.reduce((arr, row) => {
        if (values.link_to_shipment && (row.rowDirty || row.adhoc_adjustment)) {
          arr.push({
            sale_id: row.sale_id,
            amount: row.adhoc_adjustment,
          });
        }
        return arr;
      }, []);
      await createSaleAdjustmentAdhoc({ formValues, linkedShipments: formattedLinkedShipments });
      handleClose();
    } catch (error) {
      const err = GenerateErrorMessage(error, "Error creating Adhoc");
      updateSnack({ show: true, color: "red", message: err });
    }
    setLoading(false);
  };

  useEffect(() => {
    loadData();
  }, []);

  const handleGridUpdate = (form: FormApi) => (_: any, updated: DebtorsLinked[]) => {
    const adhocTotal = numFmt(form.getState().values["amount"]);
    const gridTotal = updated.reduce((tot, row) => (tot += numFmt(row.adhoc_adjustment)), 0);

    if (gridTotal > adhocTotal) {
      updateSnack({ show: true, color: "red", message: "Total cannot be more than the available amount." });
    } else {
      const data = linkedShipments.map((row) => {
        const updatedRow = updated.find((item) => item.sale_id == row.sale_id);
        if (updatedRow) {
          return updatedRow;
        }
        return row;
      });
      setLinkedShipments(data);
    }
  };

  const handleAmountFormat = (form: FormApi) => (value: string) => {
    const formatted = numeral(value).format("0,0.00");
    form.change("amount", formatted);
    return formatted;
  };

  if (loading) return <LinearProgress color="secondary" />;

  return (
    <div className={classes.root}>
      <Form
        initialValues={{
          ...currentAdhoc,
          document_no: currentAdhoc ? currentAdhoc.document_no : documentNumber,
          type: currentAdhoc ? currentAdhoc.type : "credit",
        }}
        onSubmit={(values: any) => handleSubmit(values, linkedShipments)}
        render={({ handleSubmit, form }) => (
          <FormDetail
            form={form}
            classes={classes}
            adhocId={adhocId}
            clientsList={clientsList}
            handleClose={handleClose}
            handleSubmit={handleSubmit}
            linkedShipments={linkedShipments}
            setLinkedShipments={setLinkedShipments}
            handleGridUpdate={handleGridUpdate}
            handleAmountFormat={handleAmountFormat}
          />
        )}
      />
    </div>
  );
};

type FormDetailProps = {
  form: FormApi;
  adhocId: string;
  linkedShipments: DebtorsLinked[];
  clientsList: { value: number; display: string }[];
  handleClose(): void;
  handleSubmit(event: any): void;
  setLinkedShipments(linkedShipments: DebtorsLinked[]): void;
  handleAmountFormat: (form: FormApi) => (value: string) => string;
  handleGridUpdate: (form: FormApi) => (_: any, updated: DebtorsLinked[]) => void;
} & WithStyles<typeof styles>;

const FormDetail: FC<FormDetailProps> = ({
  classes,
  form,
  adhocId,
  linkedShipments,
  clientsList,
  handleClose,
  handleSubmit,
  setLinkedShipments,
  handleAmountFormat,
  handleGridUpdate,
}) => {
  useEffect(() => {
    const adhocTotal = numFmt(form.getState().values["amount"]);
    const gridTotal = linkedShipments.reduce((tot, row) => (tot += numFmt(row.adhoc_adjustment)), 0);
    const available = new BigNumber(adhocTotal).minus(new BigNumber(gridTotal)).toNumber();

    form.change("available", numeral(available).format("0,0.00"));
  }, [form.getState().values["amount"], linkedShipments]);

  return (
    <form onSubmit={handleSubmit} className={classes.form}>
      <div>
        <RedButton variant="contained" type="button" onClick={handleClose}>
          Close
        </RedButton>
        <GreenButton variant="contained" type="submit">
          Save and Close
        </GreenButton>
      </div>
      <div>
        <FormControl>
          <RadioGroup style={{ display: "flex", flexDirection: "row" }}>
            <FormControlLabel control={<Field type="radio" name="type" value={"debit"} component={Radio} />} label="Debit" />
            <FormControlLabel control={<Field type="radio" name="type" value={"credit"} component={Radio} />} label="Credit" />
          </RadioGroup>
        </FormControl>
      </div>
      <CustomSelect classes={classes} form={form} title="Client" field="clients_id" data={clientsList} />
      <FormDateField classes={classes} title="Date" field="date" form={form} isClearable={false} />
      <FormDateField classes={classes} title="Due Date" field="duedate" form={form} isClearable={false} />
      <FormTextField classes={classes} title="Amount" field="amount" handleParse={handleAmountFormat(form)} />
      <FormTextField classes={classes} title="Document Number" field="document_no" disabled />
      <FormTextField classes={classes} title="Description" field="description" />
      <FormTextField classes={classes} title="Available" field="available" disabled />
      <CheckboxField classes={classes} title="Link to shipment" field="link_to_shipment" form={form} />
      {form.getState().values["clients_id"] && form.getState().values["link_to_shipment"] && (
        <LinkedShipmentsWrapper
          form={form}
          linkedShipments={linkedShipments}
          setLinkedShipments={setLinkedShipments}
          handleGridUpdate={handleGridUpdate(form)}
          adhocId={parseInt(adhocId)}
        />
      )}
    </form>
  );
};

type FormTextFieldProps = {
  title: string;
  field: string;
  disabled?: boolean;
  handleParse?(values: any): string;
} & WithStyles<typeof styles>;

const FormTextField: FC<FormTextFieldProps> = ({ title, field, classes, handleParse, disabled = false }) => {
  return (
    <div className={classes.formRow}>
      <label>{title}:</label>
      <div>
        <Field name={field} type="text" fullWidth={true} component={TextField} disabled={disabled} format={handleParse} formatOnBlur={!!handleParse} />
      </div>
    </div>
  );
};

type SelectType = { display: string; value: any };
type CustomSelectProps = {
  form: FormApi;
  field: string;
  title: string;
  data: SelectType[];
  disabled?: boolean;
  onParse?(value: any): void;
} & WithStyles<typeof styles>;

const CustomSelect: React.FC<CustomSelectProps> = (props) => {
  const { classes, field, data, title, form, onParse, disabled = false } = props;

  const selected = useMemo(() => data.find((item) => item.value === form.getState().values[field]), [data, form, field]);

  const handleItemSelect = (_: unknown, data: any) => {
    const value = data.value;
    form.change(field, value);
    if (onParse) {
      onParse(value);
    }
  };

  return (
    <div className={classes.formRow}>
      <label>{title}:</label>
      <div>
        <Field
          name={field}
          label={title}
          render={(props) => (
            <Autocomplete
              {...props}
              id={field}
              fullWidth
              options={data}
              value={selected || undefined}
              disabled={disabled}
              onChange={handleItemSelect}
              getOptionLabel={(option: any) => option.display}
              renderInput={(params) => <MTextField {...params} variant="standard" />}
            />
          )}
        />
      </div>
    </div>
  );
};

type CheckboxFieldProps = {
  title: string;
  field: string;
  form: FormApi;
} & WithStyles<typeof styles>;

const CheckboxField: FC<CheckboxFieldProps> = ({ classes, title, field, form }) => {
  const checked = useMemo(() => form.getState().values[field], [form, field]);

  return (
    <div className={classes.formRow}>
      <label>{title}:</label>
      <Field name={field} render={({ input }) => <Checkbox {...input} checked={checked} style={{ padding: 0 }} />} />
    </div>
  );
};

type LinkedShipmentsWrapper = {
  form: FormApi;
  adhocId: number;
  linkedShipments: any[];
  setLinkedShipments(data: any[]): void;
  handleGridUpdate(grid: string, data: any[]): void;
};

const LinkedShipmentsWrapper: FC<LinkedShipmentsWrapper> = ({ form, linkedShipments, setLinkedShipments, handleGridUpdate, adhocId }) => {
  const [loading, setLoading] = useState(true);

  const clientsID = useMemo(() => form.getState().values["clients_id"], [form]);

  const loadData = async () => {
    setLoading(true);
    if (adhocId) {
      const data = await getDebtorsLinkedByAdhocId(adhocId);
      setLinkedShipments(data);
    } else {
      const data = await getDebtorsLinked(clientsID, getFinancialYearSelected());
      setLinkedShipments(data);
    }
    setLoading(false);
  };

  useEffect(() => {
    loadData();
  }, [clientsID]);

  if (loading) return <LinearProgress color="secondary" />;

  return <LinkedShipments data={linkedShipments} handleGridUpdate={handleGridUpdate} adhocId={adhocId} />;
};

const CalenderCustomInput = forwardRef((props: any, ref: any) => {
  return (
    <Button name="CalenderCustomInput" variant="contained" color="primary" onClick={props.onClick} style={{ marginTop: "5px", width: "300px", minHeight: "36px" }}>
      {props.value}
    </Button>
  );
});

const FormDateField: React.FunctionComponent<{ field: string; title: string; isClearable: boolean; form: any } & WithStyles<typeof styles>> = (props) => {
  const { classes, field, title, isClearable, form } = props;

  const handleChangeDate = useCallback((value) => form.change(field, value), [field, form]);
  const dateValue = useMemo(() => (form.getState().values[field] ? new Date(form.getState().values[field]) : new Date()), [form, field]);

  return (
    <div className={classes.dateRow}>
      <label>{title}:</label>
      <div>
        <ReactDatePicker
          isClearable={isClearable}
          locale="en-GB"
          showWeekNumbers={true}
          selected={dateValue ? toDate(dateValue) : new Date()}
          onChange={handleChangeDate}
          dateFormat="dd-MM-yyyy"
          placeholderText="click here to select a date"
          customInput={<CalenderCustomInput />}
        />
      </div>
    </div>
  );
};

export default withStyles(styles)(DebtorsFullAdhocUnstyled);
