import React from "react";

import CustomAutoCompleteFilter from "../../lib/components/customdatagridfilter/CustomFilter";
import { DateFormatter, FormatterNumber2Decimals } from "../../lib/components/Formatters";
import { handleColumnsArrangement, handleColumnsWidth } from "../../lib/helpers/grid";
import { SummaryNumber2Decimal } from "../../lib/components/SummaryFormatter";
import RightAlignHeaderColumn from "../../lib/components/gridhelper/RightAlignHeaderColumn";

const cellWidthSmall = 90;
const cellWidthSmallX = 120;
const cellWidthMedium = 150;
const cellWidthLarge = 200;

const defaultColumnProperties = {
  resizable: true,
  sortable: true,
};

let currentSpace = true;

export const GridColumns = (data, filters, arrangement, columnsWidth) => {
  let postValue = "";

  if (data) {
    if (currentSpace) {
      postValue = " ";
    }
    currentSpace = !currentSpace;
  }

  const columns = [
    {
      key: "creditorsinvoice_type",
      name: "Type" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"creditorsinvoice_type"} data={data} section="creditorsinvoices" />;
      },
    },
    {
      key: "invoicedate",
      name: "Invoice Date" + postValue,
      width: cellWidthMedium,
      filterable: true,
      formatter: DateFormatter,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"invoicedate"} data={data} section="creditorsinvoices" />;
      },
    },
    {
      key: "creditors_code",
      name: "Creditor Code" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"creditors_code"} data={data} section="creditorsinvoices" />;
      },
    },
    {
      key: "creditors_name",
      name: "Creditor Name" + postValue,
      width: cellWidthLarge,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"creditors_name"} data={data} section="creditorsinvoices" />;
      },
    },
    {
      key: "invoicenumber",
      name: "Invoice Number" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"invoicenumber"} data={data} section="creditorsinvoices" />;
      },
    },
    {
      key: "currency_code",
      name: "Currency" + postValue,
      width: cellWidthSmall,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"currency_code"} data={data} section="creditorsinvoices" />;
      },
    },
    {
      key: "validate_totalexcl",
      name: "Total (excl)" + postValue,
      width: cellWidthMedium,
      filterable: true,
      formatter: FormatterNumber2Decimals,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"validate_totalexcl"} data={data} section="creditorsinvoices" />;
      },
      summaryFormatter: SummaryNumber2Decimal,
      headerRenderer: RightAlignHeaderColumn,
    },
    {
      key: "validate_vat",
      name: "VAT" + postValue,
      width: cellWidthMedium,
      filterable: true,
      formatter: FormatterNumber2Decimals,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"validate_vat"} data={data} section="creditorsinvoices" />;
      },
      summaryFormatter: SummaryNumber2Decimal,
      headerRenderer: RightAlignHeaderColumn,
    },
    {
      key: "validate_total",
      name: "Total" + postValue,
      width: cellWidthMedium,
      filterable: true,
      formatter: FormatterNumber2Decimals,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"validate_total"} data={data} section="creditorsinvoices" />;
      },
      summaryFormatter: SummaryNumber2Decimal,
      headerRenderer: RightAlignHeaderColumn,
    },
    {
      key: "saleinvoicenumbers",
      name: "Sales Invoice Numbers" + postValue,
      width: cellWidthLarge,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"saleinvoicenumbers"} data={data} section="creditorsinvoices" />;
      },
    },
    {
      key: "due_date",
      name: "Due Date" + postValue,
      width: cellWidthMedium,
      filterable: true,
      formatter: DateFormatter,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"due_date"} data={data} section="creditorsinvoices" />;
      },
    },
    {
      key: "adjustdate",
      name: "Adjust Date" + postValue,
      width: cellWidthMedium,
      filterable: true,
      formatter: DateFormatter,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"adjustdate"} data={data} section="creditorsinvoices" />;
      },
    },
    {
      key: "consolidationapply",
      name: "Consolidation Apply" + postValue,
      width: cellWidthMedium,
      filterRenderer: (e) => {
        return (
          <CustomAutoCompleteFilter
            {...e}
            multiSelection={true}
            option={data}
            currentFilters={filters}
            fieldname={"consolidationapply"}
            data={data}
            section="creditorsinvoicesummary"
          />
        );
      },
    },
    {
      key: "subgroup",
      name: "Sub Group" + postValue,
      width: cellWidthMedium,
      filterRenderer: (e) => {
        return (
          <CustomAutoCompleteFilter {...e} multiSelection={true} option={data} currentFilters={filters} fieldname={"subgroup"} data={data} section="creditorsinvoicesummary" />
        );
      },
    },
  ].map((c) => ({ ...c, ...defaultColumnProperties }));

  let columnsArranged = [];
  columnsArranged = handleColumnsArrangement(columnsArranged, arrangement, columns);
  columnsArranged = handleColumnsWidth(columnsArranged, columnsWidth, columns);

  return columns;
};
