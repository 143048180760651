import React from "react";

import CustomAutoCompleteFilter from "../lib/components/customdatagridfilter/CustomFilter";
import { DateFormatter } from "../lib/components/Formatters";

import { FormatterNumber, FormatterNumber2Decimals, FormatterNumber3Decimals } from "../lib/components/Formatters";
import Checkbox from "@material-ui/core/Checkbox";
import { DealType } from "../lib/api/dealtype";
import { isNullOrUndef } from "../lib/helpers/isNullOrUndef";
import { handleColumnsArrangement, handleColumnsWidth } from "../lib/helpers/grid";

const cellWidthSmall = 100;
const cellWidthMedium = 150;

// const formatterDealType = (data) => {
//   return <div>{data.value ? DealType[data.value] : ""}</div>;
// };

// const formatterDate = (data) => {
//   return <div>{data.value ? format(parseISO(data.value), "dd-MMM-yyyy") : ""}</div>;
// };

type CheckboxSelectProps = {
  row: any;
  selectedRows: any[];
  handleCheckboxSelect: (row: any) => void;
};

const CheckboxSelect: React.FC<CheckboxSelectProps> = ({ row, selectedRows, handleCheckboxSelect }) => {
  const checked = React.useMemo(() => {
    return Boolean(selectedRows.find((item) => item["reference"] == row["reference"]));
  }, [selectedRows]);

  return (
    <div style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "100%" }}>
      <Checkbox color="default" checked={checked} onChange={() => handleCheckboxSelect(row)} inputProps={{ "aria-label": "primary checkbox" }} />
    </div>
  );
};

const defaultColumnProperties = {
  resizable: true,
  sortable: true,
  filterable: true,
};

let currentSpace = true;
export const ReferencesColumns = (data, filters, arrangement = undefined, columnsWidth = undefined, handleCheckboxSelect, selectedRows) => {
  let postValue = "";

  if (data) {
    if (currentSpace) {
      postValue = " ";
    }
    currentSpace = !currentSpace;
  }

  const columns = [
    {
      key: "Checkbox",
      name: "#C" + postValue,
      width: 50,
      formatter: ({ row }) => {
        return <CheckboxSelect row={row} selectedRows={selectedRows} handleCheckboxSelect={handleCheckboxSelect} />;
      },
    },
    {
      key: "reference",
      name: "Load Ref" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"reference"} data={data} section="stockpricingreference" />;
      },
    },
    {
      key: "locations",
      name: "Location Codes" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"locations"} data={data} section="stockpricingreference" />;
      },
    },
    {
      key: "trader",
      name: "Trader" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"trader"} data={data} section="stockpricingreference" />;
      },
    },
    {
      key: "consigneeId",
      name: "Client" + postValue,
      width: cellWidthSmall,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"consigneeId"} data={data} section="stockpricingreference" />;
      },
    },
    {
      key: "dealtype",
      name: "Deal Type" + postValue,
      width: cellWidthSmall,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"dealtype"} data={data} section="stockpricingreference" />;
      },
      formatter: ({ row, column }) => <div>{!isNullOrUndef(row[column.key]) ? DealType[row[column.key]] : ""}</div>,
    },
    {
      key: "loadout_vessel",
      name: "Vessel",
      width: cellWidthSmall,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"loadout_vessel"} data={data} section="stockpricingreference" />;
      },
    },
    {
      key: "tempcode",
      name: "Temp Code" + postValue,
      width: cellWidthSmall,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"tempcode"} data={data} section="stockpricingreference" />;
      },
    },
    {
      key: "po",
      name: "PO" + postValue,
      width: cellWidthMedium,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"po"} data={data} section="stockpricingreference" />;
      },
    },
    {
      key: "loadout_dateLoading",
      name: "Load Date" + postValue,
      width: cellWidthSmall,
      filterable: true,
      formatter: DateFormatter,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"loadout_dateLoading"} data={data} section="stockpricingreference" />;
      },
    },
    {
      key: "loadout_etd",
      name: "ETD" + postValue,
      width: cellWidthSmall,
      filterable: true,
      formatter: DateFormatter,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"loadout_etd"} data={data} section="stockpricingreference" />;
      },
    },
    {
      key: "pod",
      name: "POD" + postValue,
      width: cellWidthSmall,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"pod"} data={data} section="stockpricingreference" />;
      },
    },
  ].map((c) => ({ ...c, ...defaultColumnProperties }));

  let columnsArranged = [];
  columnsArranged = handleColumnsArrangement(columnsArranged, arrangement, columns);
  columnsArranged = handleColumnsWidth(columnsArranged, columnsWidth, columns);

  return columnsArranged;
};
