import React from "react";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";

import Close from "@material-ui/icons/Close";
import Check from "@material-ui/icons/Check";
import Button from "@material-ui/core/Button";

import { Form, Field } from "react-final-form";
import { TextField } from "final-form-material-ui";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1),
      width: "350px",
    },
    tableRowFieldTitle: {
      paddingTop: theme.spacing(1),
      paddingRight: theme.spacing(1) * 2,
      marginTop: theme.spacing(1),
      textAlign: "right",
    },
    tableRowFieldData: {
      paddingTop: theme.spacing(1),
      paddingRight: 0,
      marginTop: theme.spacing(1),
      textAlign: "left",
    },
    tableRowFieldInput: {
      width: "195px",
    },
  });

type ImportYearFormProps = {
  onSubmit: any;
  onClose: any;
} & WithStyles<typeof styles>;

class ImportYearFormUnstyled extends React.Component<ImportYearFormProps, any> {
  state = {
    classes: undefined,
    item: { year: undefined },
    onSubmit: undefined,
    onClose: undefined,
  };

  constructor(props) {
    super(props);
    this.state.classes = props.classes;
    this.state.onSubmit = props.onSubmit;
    this.state.onClose = props.onClose;
  }

  render() {
    const { classes } = this.state;

    return (
      <div className={classes.root}>
        <Form
          initialValues={{ ...this.state.item }}
          onSubmit={this.state.onSubmit}
          render={({ handleSubmit, form }) => (
            <form
              id={this.state.item.year}
              onSubmit={(e) => {
                handleSubmit(e);
              }}
            >
              <table>
                <tbody>
                  <tr>
                    <td className={classes.tableRowFieldTitle}>
                      <span style={{ marginLeft: "-4px" }}>Import year:</span>
                    </td>
                    <td className={classes.tableRowFieldData}>
                      <div className={classes.inline}>
                        <Field name={"year"} component={TextField} fullWidth className={classes.tableRowFieldInput} key={"body_value_code"} value={this.state.item.year || ""} />
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>

              <div style={{ display: "flex", justifyContent: "flex-end", margin: "29px 0 -5px 0" }}>
                <Button style={{ marginRight: "10px" }} color="secondary" variant="outlined" onClick={this.state.onClose}>
                  <Close />
                </Button>
                <Button type="submit" color="primary" variant="outlined">
                  <Check />
                </Button>
              </div>
            </form>
          )}
        />
      </div>
    );
  }
}

export default withStyles(styles)(ImportYearFormUnstyled);
