import React from "react";

import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";
import ReactDataGrid from "react-data-grid";

import Button from "@material-ui/core/Button";

import { TemplatesDetailColumns } from "./templatesDetailSetup";
import { handleGridFill } from "../../lib/helpers/grid";
import { Position } from "../../lib/components/customdatagridfilter/types";

const styles = (theme: Theme) =>
  createStyles({
    root: {},
  });

type TemplatesDetailProps = {
  maintenancemode: boolean;
  data: any;
  targets: any;
  counts: any;
  classesArr: any;
  packspecs: any;
  coldrooms: any;
  brands: any;
  inventories: any;
  mgps: any;
  handleRemove: any;
  handleAdd: any;
  handleSave: any;
  handleTemplateDetailSelectedAdd: any;
} & WithStyles<typeof styles>;

class TemplatesDetailUnstyled extends React.Component<TemplatesDetailProps, any> {
  state = {
    maintenancemode: false,
    rows: undefined,
    targets: undefined,
    targetsRegion: [],
    targetsCountry: [],
    targetsMarket: [],
    counts: [],
    classesArr: [],
    packspecs: [],
    coldrooms: [],
    brands: [],
    inventories: [],
    mgps: [],
    handleRemove: undefined,
    handleAdd: undefined,
    handleSave: undefined,
    handleTemplateDetailSelectedAdd: undefined,
    gridRef: undefined,
    selectedIndexes: [],
    selectedRows: new Set<React.Key>(),
  };

  constructor(props) {
    super(props);
    this.state.maintenancemode = props.maintenancemode;
    this.state.handleRemove = props.handleRemove;
    this.state.handleAdd = props.handleAdd;
    this.state.handleSave = props.handleSave;
    this.state.handleTemplateDetailSelectedAdd = props.handleTemplateDetailSelectedAdd;
    this.state.gridRef = React.createRef();

    this.state.rows = props.data;
    this.state.counts = props.counts;
    this.state.brands = props.brands;
    this.state.coldrooms = props.coldrooms;
    this.state.packspecs = props.packspecs;
    this.state.classesArr = props.classesArr;
    this.state.inventories = props.inventories;
    this.state.mgps = props.mgps;
    // targets
    this.state.targets = props.targets;
    props.targets.map((target) => {
      const targetrexist = this.state.targetsRegion.filter((region) => {
        if (region.value == target.region) {
          return region;
        }
      });
      if (targetrexist.length == 0) {
        this.state.targetsRegion.push({ id: this.state.targetsRegion.length, value: target.region });
      }
    });
    this.state.targetsRegion = this.state.targetsRegion.sort((a, b) => {
      if (a.value < b.value) return -1;
      return 1;
    });
  }

  componentDidMount = async () => {
    await this.filterTargets(undefined, undefined);
    // this.setState({ loading: false, data: [] })
    // if (this.state.gridRef && this.state.gridRef.openCellEditor) {
    //   this.state.gridRef.openCellEditor(this.state.rows.length -1, 1);
    // }
  };

  componentWillReceiveProps = async (props) => {
    this.setState({ rows: props.data }, () => {});
  };

  filterTargets = async (region, market) => {
    // this.setState({ loading: true })
    let targets = this.state.targets;
    if (market) {
      targets = targets.filter((target) => {
        if (target.market == market) {
          return target;
        }
      });
    } else if (region) {
      targets = targets.filter((target) => {
        if (target.region == region) {
          return target;
        }
      });
    }
    // use targets to get distinct target entries
    const targetsM = [];
    const targetsC = [];

    targets.map((target: any) => {
      const indexC = targetsC.filter((item) => {
        return item.value == target.country;
      });
      const indexM = targetsM.filter((item) => {
        return item.value == target.market;
      });

      if (indexC.length === 0) {
        targetsC.push({ id: targetsC.length, value: target.country });
      }
      if (indexM.length === 0) {
        targetsM.push({ id: targetsM.length, value: target.market });
      }
    });

    await this.setState({
      targetsCountry: [{ id: 0, value: "CLEAR" }].concat(
        targetsC.sort((a, b) => {
          if (a.value < b.value) return -1;
          return 1;
        }),
      ),
      targetsMarket: targetsM.sort((a, b) => {
        if (a.value < b.value) return -1;
        return 1;
      }),
      // loading: false,
    });
  };

  onGridRowsUpdated = (rows, data) => {
    const updatedData = rows.map((item, indx) => {
      if (!data.indexes.includes(indx)) {
        return item;
      }

      let updated = { ...item };
      updated.isDirty = 1;

      if (data.column.key == "region") {
        updated = { ...updated, market: "", country: "" };
      }

      if (data.column.key == "count" && updated.count.indexOf("/") == 0) {
        updated.count = updated.count.substring(1);
      }

      if (data.column.key == "country" && updated.country != "CLEAR") {
        const targetIdentified = this.state.targets.find((target) => target.country == updated.country);
        if (targetIdentified) {
          updated = { ...updated, market: targetIdentified.market, region: targetIdentified.region };
        }
      }
      if (data.column.key == "country" && updated.country === "CLEAR") {
        this.filterTargets(undefined, undefined);
        updated = { ...updated, market: "", country: "", region: "" };
      } else {
        this.filterTargets(updated.region, updated.market);
      }

      if (data.column.key == "country") {
        const target = this.state.targets.filter((tar) => {
          if (tar.country == updated.country) {
            return tar;
          }
        });
        if (target.length > 0) {
          updated.target_id = target[0].id;
        }
      }

      if (data.column.key == "pack_name") {
        const packspec = this.state.packspecs.find((plt) => plt.name == updated.pack_name);
        updated = { ...updated, pack_id: packspec.id };
      }

      if (data.column.key == "inventory_description") {
        const inventory = this.state.inventories.find((inv) => inv.value == updated.inventory_description);
        updated = { ...updated, inventory: inventory.id };
      }

      return updated;
    });

    this.setState({ rows: updatedData });
  };

  onCellSelected = (position: Position) => {
    this.setState({ currentColumnSelected: position.idx });
  };

  onRowsSelected = (rows) => {
    this.setState(
      {
        selectedIndexes: this.state.selectedIndexes.concat(rows.map((r) => r.rowIdx)),
      },
      () => {},
    );
  };

  onRowsDeselected = (rows) => {
    let rowIndexes = rows.map((r) => r.rowIdx);
    this.setState({
      selectedIndexes: this.state.selectedIndexes.filter((i) => rowIndexes.indexOf(i) === -1),
    });
  };

  handleAddNewTemplateLine = async () => {
    await this.handleSaveDirtyDetails();
    this.state.handleAdd();
  };

  handleSaveDirtyDetails = async () => {
    const dirtyRows = [];
    this.state.rows.map((row) => {
      if (row.isDirty == 1) {
        dirtyRows.push(row);
      }
    });
    this.state.handleSave(dirtyRows);
  };

  handleSelectTemplateDetailLines = () => {
    const rowsSelected = [];

    this.state.selectedIndexes.map((idx) => {
      rowsSelected.push(this.state.rows[idx]);
    });

    this.state.handleTemplateDetailSelectedAdd(rowsSelected);
  };

  onRowSelected = (rows: Set<React.Key>) => {
    const keys = new Set<React.Key>();
    const indexes = [];

    rows.forEach((i) => {
      keys.add(i);
      indexes.push(i);
    });

    this.setState({ selectedRows: keys, selectedIndexes: indexes });
  };

  render() {
    return (
      <div>
        {this.state.maintenancemode ? (
          <>
            <Button type="submit" variant="contained" color="primary" style={{ margin: "2px" }} onClick={this.handleSaveDirtyDetails}>
              save changes
            </Button>
            <Button type="submit" variant="contained" color="primary" style={{ backgroundColor: "green", margin: "2px" }} onClick={() => this.handleAddNewTemplateLine()}>
              new template line
            </Button>
          </>
        ) : (
          <Button type="submit" variant="contained" color="primary" style={{ margin: "2px" }} onClick={this.handleSelectTemplateDetailLines}>
            add selected
          </Button>
        )}
        <ReactDataGrid
          className="rdg-light"
          ref={(input) => (this.state.gridRef = input)}
          columns={TemplatesDetailColumns(
            this.state.maintenancemode,
            this.state.targetsRegion,
            this.state.targetsMarket,
            this.state.targetsCountry,
            this.state.counts,
            this.state.classesArr,
            this.state.packspecs,
            this.state.coldrooms,
            this.state.brands,
            this.state.inventories,
            this.state.mgps,
            this.state.handleRemove,
          )}
          rows={this.state.rows}
          rowKeyGetter={(row) => row.rowIdx}
          onRowsChange={this.onGridRowsUpdated}
          onSelectedCellChange={this.onCellSelected}
          onFill={handleGridFill}
          selectedRows={this.state.selectedRows}
          onSelectedRowsChange={this.onRowSelected}
          style={{ height: document.getElementById("templatesDetailMain") != null ? document.getElementById("templatesDetailMain").clientHeight - 50 : 250 }}
        />
      </div>
    );
  }
}

export default withStyles(styles)(TemplatesDetailUnstyled);
