import { image as logoImage } from "./elements/logo";

import { PTSans } from "./elements/font";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import numeral from "numeral";
import format from "date-fns/format";

import { get } from "./helpers";

let pdf;
let pages;
let page;
let fromTop;
let fromLeft;
let lastLinewritten = 0;
let currentLine;
let fullWidth;
let fullHeight;

const initialisePDF = () => {
  pdf = new jsPDF({
    orientation: "portrait",
    unit: "cm",
  });
  fullWidth = pdf.internal.pageSize.getWidth();
  fullHeight = pdf.internal.pageSize.getHeight();
  page = 1;
  pages = [page];
  fromTop = 0;
  fromLeft = 1;
};

const newLine = (howFar: number = 0.5) => {
  fromTop = fromTop + howFar;
};

const addImage = (imageData: string, format: string, fromLeft: number, fromTop: number, width: number, height: number, alias?, compression?, rotation?) => {
  pdf.addImage(imageData, format, fromLeft, fromTop, width, height, alias, compression, rotation);
};

const setFontBold = () => {
  pdf.setFontStyle("bold");
  pdf.setFont("helvetica"); // set font
};

const setFontNormal = () => {
  pdf.setFontStyle("normal");
  pdf.setFont("PTSans"); // set font
};

const headerColumn1 = () => {
  return 3.4;
};
const headerColumn2 = () => {
  return fullWidth / 2 - 1.5;
};
const headerColumn3 = () => {
  return fullWidth - 3.6;
};

export const exportPrintPDF = (companyDetails, data) => {
  initialisePDF();

  pdf.addFileToVFS("PTSans.ttf", PTSans);
  pdf.addFont("PTSans.ttf", "PTSans", "normal");
  pdf.setFont("PTSans");
  pdf.setLineWidth(0.01);

  printHeader(companyDetails);

  printDetail(data);

  printFooter();

  let filename = `${format(new Date(), "yyMMdd")}_DEBTORS_AGE_ANALYSIS.pdf`;
  pdf.save(filename);
};

const printFooter = () => {
  const pageCount = pdf.internal.getNumberOfPages();

  for (let index = 0; index < pageCount; index++) {
    pdf.setPage(index + 1);
    pdf.text(`Page ${index + 1} of ${pageCount}`, fullWidth - 1, fullHeight - 1, "right");
  }
};

const printHeader = (companyDetails) => {
  fromTop = 1;
  fromLeft = 1;

  const imageWidth = 5;
  const imageHeight = 1.5;

  addImage(logoImage, "PNG", (fullWidth - imageWidth) / 2 - 1, fromTop + 0.5, imageWidth, imageHeight);
  pdf.setFontSize(11);
  setFontBold();
  pdf.text(get("name", companyDetails, ""), fromLeft, fromTop, "left");

  pdf.setFontSize(12);
  setFontBold();
  pdf.text("DEBTORS AGE ANALYSIS", fullWidth / 2 - 1, fromTop + 2.5, "center");
  pdf.text(`STATEMENT DATE: ${format(new Date(), "yyyy-MM-dd")}`, fullWidth / 2 - 1, fromTop + 3, "center");

  setFontNormal();
  pdf.setFontSize(8);

  newLine(0.4);
  pdf.text(get("address1", companyDetails, ""), fromLeft, fromTop, "left");

  newLine(0.4);
  pdf.text(get("address2", companyDetails, ""), fromLeft, fromTop, "left");

  newLine(0.4);
  pdf.text(get("address3", companyDetails, ""), fromLeft, fromTop, "left");

  newLine(0.4);
  pdf.text(get("address4", companyDetails, ""), fromLeft, fromTop, "left");

  newLine(0.4);
  pdf.text(get("address5", companyDetails, ""), fromLeft, fromTop, "left");

  fromLeft = headerColumn3() - 1;
  fromTop = 1;

  pdf.text("Tel No:", fromLeft, fromTop, "right");
  pdf.text(get("telephoneNumber", companyDetails, ""), fromLeft + 0.2, fromTop, "left");

  newLine(0.4);
  pdf.text("Fax No:", fromLeft, fromTop, "right");
  pdf.text(get("faxNumber", companyDetails, ""), fromLeft + 0.2, fromTop, "left");

  newLine(0.4);
  pdf.text("E-mail:", fromLeft, fromTop, "right");
  pdf.text(get("email", companyDetails, ""), fromLeft + 0.2, fromTop, "left");

  newLine(0.4);
  pdf.text("Vat No:", fromLeft, fromTop, "right");
  pdf.text(get("vatno", companyDetails, ""), fromLeft + 0.2, fromTop, "left");

  newLine(0.4);
  pdf.text("Reg No:", fromLeft, fromTop, "right");
  pdf.text(get("regno", companyDetails, ""), fromLeft + 0.2, fromTop, "left");

  newLine(0.4);
  pdf.text("Customs Code:", fromLeft, fromTop, "right");
  pdf.text(get("customscode", companyDetails, ""), fromLeft + 0.2, fromTop, "left");

  lastLinewritten = fromTop + 0.5;
};

const printDetail = (data) => {
  const headers = [
    { dataKey: "clients_code", header: "Code" },
    { dataKey: "clients_name", header: "Name" },
    { dataKey: "currency_code", header: "Curr" },
    { dataKey: "totalOutstanding", header: "Outstanding" },
    { dataKey: "creditLimit", header: "Credit" },
    { dataKey: "current_period", header: "Current" },
    { dataKey: "thirty_days", header: "1-30 Days" },
    { dataKey: "sixty_days", header: "31-60 Days" },
    { dataKey: "ninety_days", header: "61-90 Days" },
    { dataKey: "ninetyplus_days", header: "91+ Days" },
    { dataKey: "amountOverpaid", header: "Overpaid" },
  ];

  const groupedByCurrency = data.reduce((obj, row) => {
    obj[row.currency_code] = [...(obj[row.currency_code] || []), row];
    return obj;
  }, {});

  const clients = Object.keys(groupedByCurrency);

  for (let i = 0; i < clients.length; i++) {
    lastLinewritten += 1;

    const key = clients[i];
    const clientsData = groupedByCurrency[key];

    const { totalOutstandingTotal, current_periodTotal, thirty_daysTotal, sixty_daysTotal, ninety_daysTotal, ninetyplus_daysTotal, amountOverpaidTotal } = clientsData.reduce(
      (obj, row) => {
        obj["totalOutstandingTotal"] = parseFloat(obj["totalOutstandingTotal"]) + parseFloat(row.totalOutstanding);
        obj["current_periodTotal"] = parseFloat(obj["current_periodTotal"]) + parseFloat(row.current_period);
        obj["thirty_daysTotal"] = parseFloat(obj["thirty_daysTotal"]) + parseFloat(row.thirty_days);
        obj["sixty_daysTotal"] = parseFloat(obj["sixty_daysTotal"]) + parseFloat(row.sixty_days);
        obj["ninety_daysTotal"] = parseFloat(obj["ninety_daysTotal"]) + parseFloat(row.ninety_days);
        obj["ninetyplus_daysTotal"] = parseFloat(obj["ninetyplus_daysTotal"]) + parseFloat(row.ninetyplus_days);
        obj["amountOverpaidTotal"] = parseFloat(obj["amountOverpaidTotal"]) + parseFloat(row.amountOverpaid);

        return obj;
      },
      {
        totalOutstandingTotal: 0,
        current_periodTotal: 0,
        thirty_daysTotal: 0,
        sixty_daysTotal: 0,
        ninety_daysTotal: 0,
        ninetyplus_daysTotal: 0,
        amountOverpaidTotal: 0,
      },
    );

    const formattedData = clientsData.map((item) => ({
      ...item,
      totalOutstanding: numeral(item.totalOutstanding).format("0,0.00"),
      creditLimit: numeral(item.creditLimit).format("0,0.00"),
      current_period: numeral(item.current_period).format("0,0.00"),
      thirty_days: numeral(item.thirty_days).format("0,0.00"),
      sixty_days: numeral(item.sixty_days).format("0,0.00"),
      ninety_days: numeral(item.ninety_days).format("0,0.00"),
      ninetyplus_days: numeral(item.ninetyplus_days).format("0,0.00"),
      amountOverpaid: numeral(item.amountOverpaid).format("0,0.00"),
    }));

    autoTable(pdf, {
      theme: "plain",
      margin: [1, 1, 1, 1],
      tableLineColor: [0, 0, 0],
      tableLineWidth: 0.01,
      startY: lastLinewritten,
      columns: headers,
      body: formattedData,
      showFoot: "lastPage",
      styles: { fontSize: 8, fillColor: [255, 255, 255], textColor: [0, 0, 0], cellPadding: [0.15, 0.025, 0.15, 0.025] },
      columnStyles: {
        clients_code: { halign: "center" },
        clients_name: { halign: "center", cellWidth: 3, overflow: "ellipsize" },
        currency_code: { halign: "center" },
        totalOutstanding: { halign: "center" },
        creditLimit: { halign: "center" },
        current_period: { halign: "center" },
        thirty_days: { halign: "center" },
        sixty_days: { halign: "center" },
        ninety_days: { halign: "center" },
        ninetyplus_days: { halign: "center" },
        amountOverpaid: { halign: "center" },
      },
      headStyles: { fontStyle: "bold", cellPadding: [0.15, 0.025, 0.15, 0.025], halign: "center" },
      bodyStyles: { fontSize: 8 },
      alternateRowStyles: { fillColor: [255, 255, 255] },
      footStyles: { textColor: [0, 0, 0], fontStyle: "bold", halign: "center", cellPadding: [0.15, 0.025, 0.15, 0.025] },
      foot: [
        {
          totalOutstanding: numeral(totalOutstandingTotal).format("0,0.00"),
          current_period: numeral(current_periodTotal).format("0,0.00"),
          thirty_days: numeral(thirty_daysTotal).format("0,0.00"),
          sixty_days: numeral(sixty_daysTotal).format("0,0.00"),
          ninety_days: numeral(ninety_daysTotal).format("0,0.00"),
          ninetyplus_days: numeral(ninetyplus_daysTotal).format("0,0.00"),
          amountOverpaid: numeral(amountOverpaidTotal).format("0,0.00"),
        },
      ],
      didDrawPage: (data) => {
        lastLinewritten = data.cursor.y;
        fromTop = data.cursor.y;
      },
      willDrawCell: (data) => {
        data.row.height = 0.5;
      },
    });

    pdf.setFillColor("#000");
    pdf.setLineWidth(0.001);
    pdf.rect(1, lastLinewritten - 0.45, fullWidth - 2, 0.001, "S");
  }
};
