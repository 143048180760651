import React from "react";

import CustomAutoCompleteFilter from "../../../lib/components/customdatagridfilter/CustomFilter";
import { FormatterNumber2Decimals, TextFormatter } from "../../../lib/components/Formatters";
import RightAlignHeaderColumn from "../../../lib/components/gridhelper/RightAlignHeaderColumn";
import { SummaryNumber, SummaryNumber2Decimal } from "../../../lib/components/SummaryFormatter";
import { dynamicWidthsSummary } from "../../../lib/helpers/DynamicWidth";
import { handleColumnsArrangement, handleColumnsWidth } from "../../../lib/helpers/grid";

const cellWidthTiny = 50;
const cellWidthSmallX = 90;
const cellWidthSmall = 115;
const cellWidthMedium = 150;
const cellWidthLarge = 220;

const defaultColumnProperties = {
  filterable: true,
  resizable: true,
  sortable: true,
};

let currentSpace = true;
let calculatedWidths = {};

export const GridColumnsSummary = (data, filters, arrangement, columnsWidth, totalRowColumns) => {
  let postValue = "";

  if (data) {
    if (currentSpace) {
      postValue = " ";
    }
    currentSpace = !currentSpace;
    calculatedWidths = dynamicWidthsSummary(data, totalRowColumns);
  }

  const columns = [
    {
      key: "clients_code",
      name: "Code" + postValue,
      width: calculatedWidths["clients_code"] || cellWidthSmall,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} option={data} currentFilters={filters} fieldname={"clients_code"} data={data} section="debtorfundssummary" />;
      },
      summaryFormatter: SummaryNumber,
    },
    {
      key: "clients_name",
      name: "Name" + postValue,
      width: calculatedWidths["clients_name"] - 80 || cellWidthMedium,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} option={data} currentFilters={filters} fieldname={"clients_name"} data={data} section="debtorfundssummary" />;
      },
    },
    {
      key: "currency_code",
      name: "Currency" + postValue,
      width: calculatedWidths["currency_code"] || cellWidthTiny,
      filterRenderer: (e) => {
        return (
          <CustomAutoCompleteFilter {...e} multiSelection={true} option={data} currentFilters={filters} fieldname={"currency_code"} data={data} section="debtorfundssummary" />
        );
      },
      formatter: ({ row, column, style = { textAlign: "center" } }) => TextFormatter({ row, column, style }),
    },
    {
      key: "salesTotal",
      name: "Sales" + postValue,
      width: calculatedWidths["salesTotal"] || cellWidthMedium,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} option={data} currentFilters={filters} fieldname={"salesTotal"} data={data} section="debtorfundssummary" />;
      },
      formatter: FormatterNumber2Decimals,
      summaryFormatter: SummaryNumber2Decimal,
      headerRenderer: RightAlignHeaderColumn,
    },
    {
      key: "amountPaidTotal",
      name: "Received" + postValue,
      width: cellWidthMedium,
      filterRenderer: (e) => {
        return (
          <CustomAutoCompleteFilter {...e} multiSelection={true} option={data} currentFilters={filters} fieldname={"amountPaidTotal"} data={data} section="debtorfundssummary" />
        );
      },
      formatter: FormatterNumber2Decimals,
      summaryFormatter: SummaryNumber2Decimal,
      headerRenderer: RightAlignHeaderColumn,
    },
    {
      key: "totalOutstanding",
      name: "Outstanding" + postValue,
      width: cellWidthMedium,
      filterRenderer: (e) => {
        return (
          <CustomAutoCompleteFilter {...e} multiSelection={true} option={data} currentFilters={filters} fieldname={"totalOutstanding"} data={data} section="debtorfundssummary" />
        );
      },
      formatter: FormatterNumber2Decimals,
      summaryFormatter: SummaryNumber2Decimal,
      headerRenderer: RightAlignHeaderColumn,
    },
    {
      key: "bankchargesTotal",
      name: "Foreign Charges" + postValue,
      width: cellWidthMedium,
      filterRenderer: (e) => {
        return (
          <CustomAutoCompleteFilter {...e} multiSelection={true} option={data} currentFilters={filters} fieldname={"bankchargesTotal"} data={data} section="debtorfundssummary" />
        );
      },
      formatter: FormatterNumber2Decimals,
      summaryFormatter: SummaryNumber2Decimal,
      headerRenderer: RightAlignHeaderColumn,
    },
    {
      key: "totalAmount",
      name: "Total Received" + postValue,
      width: cellWidthMedium,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} option={data} currentFilters={filters} fieldname={"totalAmount"} data={data} section="debtorfundssummary" />;
      },
      formatter: FormatterNumber2Decimals,
      summaryFormatter: SummaryNumber2Decimal,
      headerRenderer: RightAlignHeaderColumn,
    },
    {
      key: "availableTotal",
      name: "Available For Allocation" + postValue,
      width: cellWidthLarge,
      filterRenderer: (e) => {
        return (
          <CustomAutoCompleteFilter {...e} multiSelection={true} option={data} currentFilters={filters} fieldname={"availableTotal"} data={data} section="debtorfundssummary" />
        );
      },
      formatter: FormatterNumber2Decimals,
      summaryFormatter: SummaryNumber2Decimal,
      headerRenderer: RightAlignHeaderColumn,
    },
    {
      key: "amountOverpaid",
      name: "Overpaid" + postValue,
      width: cellWidthSmall,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} option={data} currentFilters={filters} fieldname={"amountOverpaid"} data={data} section="debtorssummary" />;
      },
      formatter: FormatterNumber2Decimals,
      summaryFormatter: SummaryNumber2Decimal,
      headerRenderer: RightAlignHeaderColumn,
    },
  ].map((c) => ({ ...c, ...defaultColumnProperties }));

  let columnsArranged = [];
  columnsArranged = handleColumnsArrangement(columnsArranged, arrangement, columns);
  columnsArranged = handleColumnsWidth(columnsArranged, columnsWidth, columns);

  return columnsArranged;
};
