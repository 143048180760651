import React from "react";
import { NavLink } from "react-router-dom";

import CustomAutoCompleteFilter from "../../lib/components/customdatagridfilter/CustomFilter";
import { EnquiryLink, Selector } from "../../lib/components/EnquiryLink";
import { FormatterNumber, FormatterNumber2Decimals, FormatterNumber3Decimals } from "../../lib/components/Formatters";
import { SummaryNumber, SummaryNumber2Decimal, SummaryTotal } from "../../lib/components/SummaryFormatter";
import TextEditor from "../../lib/components/TextEditor";

import Checkbox from "@material-ui/core/Checkbox";
import { handleColumnsArrangement, handleColumnsWidth } from "../../lib/helpers/grid";
import { Status } from "./consolidation";

const cellWidthAction = 50;
const cellWidthTiny = 40;
const cellWidthSmall = 80;
const cellWidthMedium = 120;
const cellWidthLarge = 200;

const defaultColumnProperties = {
  resizable: true,
  sortable: true,
};

type CheckboxSelectProps = {
  row: any;
  selectedRows: any[];
  handleCheckboxSelect: (row: any) => void;
};

const CheckboxSelect: React.FC<CheckboxSelectProps> = ({ row, selectedRows, handleCheckboxSelect }) => {
  const checked = React.useMemo(() => {
    return Boolean(
      selectedRows.find(
        (item) =>
          item["invoicenumber"] == row["invoicenumber"] &&
          item["VarietyCode"] == row["VarietyCode"] &&
          item["GradeCode"] == row["GradeCode"] &&
          item["CountCode"] == row["CountCode"] &&
          item["PackCode"] == row["PackCode"] &&
          item["MarkCode"] == row["MarkCode"] &&
          item["InventoryCode"] == row["InventoryCode"] &&
          item["TargetMarket"] == row["TargetMarket"] &&
          item["target_shipping"] == row["target_shipping"] &&
          item["wk_weekcold"] == row["wk_weekcold"] &&
          item["ld_fob"] == row["ld_fob"],
      ),
    );
  }, [selectedRows]);

  return (
    <div style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "100%" }}>
      <Checkbox color="default" checked={checked} onChange={() => handleCheckboxSelect(row)} inputProps={{ "aria-label": "primary checkbox" }} />
    </div>
  );
};

let currentSpace = true;

export const GroupedUnapprovedGridColumns = (data, filters, arrangement, columnsWidth, handleCheckboxSelect, selectedRows, section) => {
  let postValue = "";

  if (data) {
    if (currentSpace) {
      postValue = " ";
    }
    currentSpace = !currentSpace;
  }

  const columns = [
    {
      key: "Checkbox",
      name: "#C" + postValue,
      width: cellWidthAction,
      formatter: ({ row }) => {
        return <CheckboxSelect row={row} selectedRows={selectedRows} handleCheckboxSelect={handleCheckboxSelect} />;
      },
    },
    {
      key: "invoicenumber",
      name: "Invoice Number" + postValue,
      width: cellWidthSmall,
      filterable: true,
      formatter: (props) => <EnquiryLink {...props} selector={Selector.InvoiceNumber} />,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"invoicenumber"} data={data} section={section} />;
      },
    },
    {
      key: "wk_weekcold",
      name: "Cold Week" + postValue,
      width: cellWidthSmall,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"wk_weekcold"} data={data} section={section} />;
      },
    },
    {
      key: "producerid",
      name: "Producer ID" + postValue,
      width: cellWidthSmall,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"producerid"} data={data} section={section} />;
      },
    },
    {
      key: "arrivaldetail_qualityscore",
      name: "Quality" + postValue,
      width: cellWidthSmall,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"arrivaldetail_qualityscore"} data={data} section={section} />;
      },
    },
    {
      key: "consignee",
      name: "Client" + postValue,
      width: cellWidthSmall,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"consignee"} data={data} section="consolidationselected" />;
      },
    },
    {
      key: "sales_week",
      name: "Sales Week" + postValue,
      width: cellWidthSmall,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"sales_week"} data={data} section="consolidationselected" />;
      },
    },
    {
      key: "vessel_code",
      name: "Vessel" + postValue,
      width: cellWidthSmall,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"vessel_code"} data={data} section={section} />;
      },
    },
    {
      key: "portdist_code",
      name: "POD" + postValue,
      width: cellWidthSmall,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"portdist_code"} data={data} section={section} />;
      },
    },
    {
      key: "VarietyCode",
      name: "Variety Code" + postValue,
      width: cellWidthSmall,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"VarietyCode"} data={data} section={section} />;
      },
    },
    {
      key: "GradeCode",
      name: "Grade Code" + postValue,
      width: cellWidthSmall,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"GradeCode"} data={data} section={section} />;
      },
    },
    {
      key: "CountCode",
      name: "Count Code" + postValue,
      width: cellWidthSmall,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"CountCode"} data={data} section={section} />;
      },
    },
    {
      key: "PackCode",
      name: "Pack Code" + postValue,
      width: cellWidthSmall,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"PackCode"} data={data} section={section} />;
      },
    },
    {
      key: "MarkCode",
      name: "Mark Code" + postValue,
      width: cellWidthSmall,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"MarkCode"} data={data} section={section} />;
      },
    },
    {
      key: "NoCartons",
      name: "No Cartons" + postValue,
      width: cellWidthSmall,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"NoCartons"} data={data} section={section} />;
      },
      formatter: FormatterNumber,
      summaryFormatter: SummaryNumber,
    },
    {
      key: "palletSize",
      name: "Pallet Size" + postValue,
      width: cellWidthSmall,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"palletSize"} data={data} section={section} />;
      },
      formatter: FormatterNumber2Decimals,
      summaryFormatter: SummaryNumber2Decimal,
    },
    {
      key: "currency",
      name: "Currency" + postValue,
      width: cellWidthSmall,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"currency"} data={data} section={section} />;
      },
    },
    {
      key: "roe_etd",
      name: "ROE ETD" + postValue,
      width: cellWidthSmall,
      filterable: true,
      formatter: FormatterNumber2Decimals,
      summaryFormatter: SummaryNumber2Decimal,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"roe_etd"} data={data} section={section} />;
      },
    },
    {
      key: "exchangerate",
      name: "ROE" + postValue,
      width: cellWidthSmall,
      filterable: true,
      formatter: (props) => {
        const { row } = props;
        return <FormatterNumber2Decimals {...props} style={{ color: row.camend_status != Status.Processed ? "green" : "black" }} />;
      },
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"exchangerate"} data={data} section="consolidationselected" />;
      },
      editor: (props) => <TextEditor {...props} align="right" />,
      editable: true,
      summaryFormatter: SummaryNumber2Decimal,
    },
    {
      key: "sellingprice",
      name: "Selling Price" + postValue,
      width: cellWidthSmall,
      filterable: true,
      formatter: FormatterNumber2Decimals,
      summaryFormatter: SummaryNumber2Decimal,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"sellingprice"} data={data} section={section} />;
      },
    },
    {
      key: "sale_debit",
      name: "Debit" + postValue,
      width: cellWidthSmall,
      filterable: true,
      formatter: FormatterNumber2Decimals,
      summaryFormatter: SummaryNumber2Decimal,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"sale_debit"} data={data} section={section} />;
      },
    },
    {
      key: "sale_credit",
      name: "Credit" + postValue,
      width: cellWidthSmall,
      filterable: true,
      formatter: FormatterNumber2Decimals,
      summaryFormatter: SummaryNumber2Decimal,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"sale_credit"} data={data} section={section} />;
      },
    },
    // {
    //   key: "sellingprice_final",
    //   name: "Selling Price Final" + postValue,
    //   width: cellWidthSmall,
    //   filterable: true,
    //   formatter: FormatterNumber2Decimals,
    //   summaryFormatter: SummaryNumber2Decimal,
    //   filterRenderer: (e) => {
    //     return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"sellingprice_final"} data={data} section={section} />;
    //   },
    // },
    {
      key: "foreigncost",
      name: "Foreign Cost" + postValue,
      width: cellWidthSmall,
      filterable: true,
      formatter: FormatterNumber2Decimals,
      summaryFormatter: SummaryNumber2Decimal,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"foreigncost"} data={data} section={section} />;
      },
    },
    {
      key: "nettsellprice",
      name: "FOB Sell Price" + postValue,
      width: cellWidthSmall,
      filterable: true,
      formatter: FormatterNumber2Decimals,
      summaryFormatter: SummaryNumber2Decimal,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"nettsellprice"} data={data} section={section} />;
      },
    },
    {
      key: "ld_fob",
      name: "PI FOB" + postValue,
      width: cellWidthSmall,
      filterable: true,
      formatter: (props) => {
        const { row } = props;
        if (Number(row.ld_fob) > 0) {
          const match = (row.ld_match || "").match(/\[(.*?)\]/);
          if (!match) {
            return <FormatterNumber2Decimals {...props} />;
          }
          return (
            <NavLink to={`/instruction/${match[1]}/edit`} activeClassName="selected" target={"_blank"}>
              <FormatterNumber2Decimals {...props} />;
            </NavLink>
          );
        }
        return <FormatterNumber2Decimals {...props} />;
      },
      summaryFormatter: SummaryNumber2Decimal,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"ld_fob"} data={data} section={section} />;
      },
    },
    {
      key: "rtgc",
      name: "RTGC" + postValue,
      width: cellWidthSmall,
      filterable: true,
      formatter: FormatterNumber2Decimals,
      summaryFormatter: SummaryNumber2Decimal,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"rtgc"} data={data} section={section} />;
      },
    },
    {
      key: "rtgczar",
      name: "RTGC ZAR" + postValue,
      width: cellWidthSmall,
      filterable: true,
      formatter: FormatterNumber2Decimals,
      summaryFormatter: SummaryNumber2Decimal,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"rtgczar"} data={data} section={section} />;
      },
    },

    {
      key: "ld_dip",
      name: "PI DIP" + postValue,
      width: cellWidthSmall,
      filterable: true,
      formatter: (props) => {
        const { row } = props;
        if (Number(row.ld_fob) > 0) {
          const match = (row.ld_match || "").match(/\[(.*?)\]/);
          if (!match) {
            return <FormatterNumber2Decimals {...props} />;
          }
          return (
            <NavLink to={`/instruction/${match[1]}/edit`} activeClassName="selected" target={"_blank"}>
              <FormatterNumber2Decimals {...props} />;
            </NavLink>
          );
        }
        return <FormatterNumber2Decimals {...props} />;
      },
      summaryFormatter: SummaryNumber2Decimal,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"ld_dip"} data={data} section={section} />;
      },
    },
    {
      key: "diff_rtg_dip",
      name: "DIFF RTG VS DIP" + postValue,
      width: cellWidthSmall,
      filterable: true,
      formatter: FormatterNumber2Decimals,
      summaryFormatter: SummaryNumber2Decimal,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"diff_rtg_dip"} data={data} section="consolidationselected" />;
      },
    },
    {
      key: "adjustment",
      name: "PROD ADJ" + postValue,
      width: cellWidthSmall,
      filterable: true,
      formatter: (props) => {
        return <FormatterNumber2Decimals {...props} style={{ color: "green", fontWeight: "bold" }} />;
      },
      summaryFormatter: SummaryNumber2Decimal,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"adjustment"} data={data} section={section} />;
      },
      editor: (props) => <TextEditor {...props} align="right" />,
      editable: true,
    },
    {
      key: "comment",
      name: "Comment" + postValue,
      width: cellWidthSmall,
      filterable: false,
      formatter: ({ column, row }) => {
        return <div style={{ color: row.camend_status < Status.Processed ? "green" : "black" }}>{row[column.key]}</div>;
      },
      editor: (props) => <TextEditor {...props} maxTextLength={255} />,
      editable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"comment"} data={data} section={section} />;
      },
    },
    {
      key: "fobcost_final",
      name: "Fob Cost Used" + postValue,
      width: cellWidthSmall,
      filterable: true,
      formatter: (props) => {
        return <FormatterNumber3Decimals {...props} style={{ color: "green", fontWeight: "bold" }} />;
      },
      summaryFormatter: SummaryNumber2Decimal,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"fobcost_final"} data={data} section={section} />;
      },
      editor: (props) => <TextEditor {...props} align="right" />,
      editable: true,
    },
    {
      key: "coldcost_final",
      name: "Cold Cost Used" + postValue,
      width: cellWidthSmall,
      filterable: true,
      formatter: (props) => {
        return <FormatterNumber3Decimals {...props} style={{ color: "green", fontWeight: "bold" }} />;
      },
      summaryFormatter: SummaryNumber2Decimal,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"coldcost_final"} data={data} section={section} />;
      },
      editor: (props) => <TextEditor {...props} align="right" />,
      editable: true,
    },
    {
      key: "ppecb_final",
      name: "PPECB Cost Used" + postValue,
      width: cellWidthSmall,
      filterable: true,
      formatter: (props) => {
        return <FormatterNumber3Decimals {...props} style={{ color: "green", fontWeight: "bold" }} />;
      },
      summaryFormatter: SummaryNumber2Decimal,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"ppecb_final"} data={data} section={section} />;
      },
      editor: (props) => <TextEditor {...props} align="right" />,
      editable: true,
    },
    {
      key: "cga_final",
      name: "CGA Cost Used" + postValue,
      width: cellWidthSmall,
      filterable: true,
      formatter: (props) => {
        return <FormatterNumber3Decimals {...props} style={{ color: "green", fontWeight: "bold" }} />;
      },
      summaryFormatter: SummaryNumber2Decimal,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"cga_final"} data={data} section={section} />;
      },
      editor: (props) => <TextEditor {...props} align="right" />,
      editable: true,
    },
    {
      key: "margin",
      name: "Margin" + postValue,
      width: cellWidthSmall,
      filterable: true,
      formatter: FormatterNumber2Decimals,
      summaryFormatter: SummaryNumber2Decimal,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"margin"} data={data} section={section} />;
      },
    },
    {
      key: "totalcost",
      name: "Total Local Cost" + postValue,
      width: cellWidthSmall,
      filterable: true,
      formatter: FormatterNumber2Decimals,
      summaryFormatter: SummaryNumber2Decimal,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"totalcost"} data={data} section={section} />;
      },
    },
  ].map((c) => ({ ...c, ...defaultColumnProperties }));

  let columnsArranged = [];
  columnsArranged = handleColumnsArrangement(columnsArranged, arrangement, columns);
  columnsArranged = handleColumnsWidth(columnsArranged, columnsWidth, columns);

  return columnsArranged;
};
