import React, { useEffect, forwardRef } from "react";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";

import LinearProgress from "@material-ui/core/LinearProgress";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";

import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";

import { Field, Form } from "react-final-form";
import { TextField, Select } from "final-form-material-ui";

import { getCurrencyAllSortedMappedforCombo } from "../../../lib/api/currency";
import { getProducerReadyForCombo } from "../../../lib/api/producer";
import { getWeeksReadyForComboMappedForComboValue } from "../../../lib/api/week";
import { produceradvancepaidadhocdetailGetByAdHocID } from "../../../lib/api/produceradvancepaidadhocdetail";
import { produceradvancepaidadhoc } from "../../../lib/api/produceradvancepaidadhoc";

import Confirmation from "../../../lib/components/confirmation";
import { SnackContext } from "../../../lib/context/SnackContext";
import { isNullOrUndef } from "../../../lib/helpers/isNullOrUndef";
import { GenerateErrorMessage } from "../../../lib/helpers/string_methods";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1),
      width: "600px",
      height: "100%",
    },
    container: {
      display: "grid",
      gridTemplateColumns: "repeat(1, 150px 350px)",
      gridTemplateRows: "repeat(4, 40px)",
      gridTemplateAreas: `
      "details"
      `,
      gridGap: "10px",
    },
    tableCellLabel: {
      width: "150px",
      borderBottom: "none",
      height: "50px",
      textAlign: "right",
      paddingTop: "5px",
    },
    tableCellLabelValue: {
      width: "350px",
      borderBottom: "none",
      height: "50px",
      textAlign: "left",
      paddingTop: "5px",
    },
    tableCellDetail: {
      width: "350px",
      borderBottom: "none",
      height: "50px",
    },
    tableCellsValueSelect: {
      textAlign: "left",
      width: "350px",
      borderBottom: "none",
      height: "50px",
      padding: 0,
      marginTop: "-14px",
    },
    detailTable: {
      width: "900px",
    },
    detailTableRowNormal: {
      backgroundColor: "white",
      padding: "0px",
    },
  });

type ProducerAdvancePayProps = {
  history: any;
  initialData: any;
  handleProcessAdHoc: (values) => void;
} & WithStyles<typeof styles>;

const ProducerAdvancePayUnstyled: React.FunctionComponent<ProducerAdvancePayProps> = (props) => {
  const { classes, initialData, handleProcessAdHoc, history } = props;

  const [defaultProducer, setDefaultProducer] = React.useState(0);

  const [loading, setLoading] = React.useState(true);
  const [currencyComboData, setCurrencyComboData] = React.useState([]);
  const [producerComboData, setProducerComboData] = React.useState([]);
  const [weekComboData, setWeekComboData] = React.useState([]);
  const [adHocDetailData, setAdHocDetailData] = React.useState([]);
  const { updateSnack } = React.useContext(SnackContext);

  const [confirmRemove, setConfirmRemove] = React.useState(false);

  useEffect(() => {
    Promise.all([
      getCurrencyAllSortedMappedforCombo(),
      getProducerReadyForCombo(),
      getWeeksReadyForComboMappedForComboValue(),
      produceradvancepaidadhocdetailGetByAdHocID(initialData && initialData.id ? initialData.id : 0),
    ])
      .then((result) => {
        setCurrencyComboData(result[0]);
        setProducerComboData(result[1]);
        setWeekComboData(result[2]);
        setAdHocDetailData(result[3]);
        setLoading(false);
      })
      .catch((error) => {
        updateSnack({ show: true, message: "could not load", color: "red" });
      });
  }, [initialData]);

  // handleProcessAdHoc(undefined);
  const removeAddHoc = () => {
    setConfirmRemove(true);
  };

  const removeAddHocConfirm = (accept: boolean) => {
    if (accept) {
      produceradvancepaidadhoc
        .remove(initialData.id)
        .then(() => {
          handleProcessAdHoc(undefined);
        })
        .catch((error) => {
          const err = GenerateErrorMessage(error, "failed to remove");
          updateSnack({ show: true, color: "red", message: err });
        });
    }
    setConfirmRemove(false);
  };

  const emptyParse = (form, value) => {};

  useEffect(() => {
    if (producerComboData.length > 0) {
      const selectedProducer = history.params.producerid || "";
      const producer = producerComboData.find((producer) => producer.data.code === selectedProducer);
      setDefaultProducer(producer.value);
    }
  }, [history.params.producerid, producerComboData]);

  return (
    <div className={classes.root}>
      {confirmRemove && (
        <Confirmation
          isOpen={true}
          handleClose={() => removeAddHocConfirm(false)}
          handleConfirm={() => {
            removeAddHocConfirm(true);
          }}
          title={"Remove Add Hoc Payment"}
          body={`Are you sure you would like to reverse the Add Hoc payment made?`}
        />
      )}
      {loading ? (
        <LinearProgress />
      ) : (
        <div>
          <Form
            initialValues={{ ...initialData, producer_id: defaultProducer }}
            onSubmit={(values) => {
              handleProcessAdHoc({ ...values });
            }}
            validate={(values) => {
              let errors = {};
              return errors;
            }}
            render={({ handleSubmit, form }) => (
              <form onSubmit={handleSubmit}>
                <div style={{ paddingBottom: "10px" }}>
                  <Button name="submit" type="submit" variant="contained" color="primary" style={{ margin: "2px" }} disabled={false}>
                    {initialData && initialData.id && initialData.id != 0 ? "Accept Changes" : "Make AdHoc Payment"}
                  </Button>
                  {initialData && initialData.id && initialData.id != 0 && (
                    <Button name="submit" variant="contained" color="secondary" style={{ margin: "2px" }} disabled={false} onClick={removeAddHoc}>
                      Remove AddHoc Payment
                    </Button>
                  )}
                  <Button
                    name="close"
                    variant="contained"
                    color="secondary"
                    style={{ margin: "2px" }}
                    disabled={false}
                    onClick={() => {
                      handleProcessAdHoc(undefined);
                    }}
                  >
                    Close
                  </Button>
                </div>
                <div style={{ display: "grid", gridTemplateRows: "1fr", gridTemplateColumns: "repeat(2, 1fr)" }}>
                  <div className={classes.container}>
                    {initialData && initialData.id && initialData.id != 0 ? (
                      <TableFieldLabel
                        classes={classes}
                        field={producerComboData.find((prod) => prod.value == initialData.producer_id).display}
                        title="Producer"
                        disabled={false}
                      />
                    ) : (
                      <TableFieldCombo
                        classes={classes}
                        form={form}
                        field="producer_id"
                        title="Producer"
                        data={producerComboData}
                        addEmptyValue={undefined}
                        handleParse={emptyParse}
                      />
                    )}
                    {initialData && initialData.id && initialData.id != 0 ? (
                      <TableFieldLabel classes={classes} field={weekComboData.find((wk) => wk.value == initialData.week_id).display} title="Payment Week" disabled={false} />
                    ) : (
                      <TableFieldCombo classes={classes} form={form} field="week_id" title="Payment Week" data={weekComboData} addEmptyValue={undefined} handleParse={emptyParse} />
                    )}
                    {initialData && initialData.id && initialData.id != 0 ? (
                      <TableFieldLabel
                        classes={classes}
                        field={currencyComboData.find((curr) => curr.value == initialData.currency_id).display}
                        title="Currency"
                        disabled={false}
                      />
                    ) : (
                      <TableFieldCombo
                        classes={classes}
                        form={form}
                        field="currency_id"
                        title="Currency"
                        data={currencyComboData}
                        addEmptyValue={undefined}
                        handleParse={emptyParse}
                      />
                    )}
                    <TableFieldText classes={classes} field="paidamount" title="Amount" disabled={false} />
                    <TableFieldText classes={classes} field="note" title="Note" disabled={false} />
                  </div>
                </div>
              </form>
            )}
          />

          {adHocDetailData.length > 0 && (
            <div>
              <Table className={classes.detailTable}>
                <TableHead>
                  <TableRow>
                    <TableCell>ADVANCE</TableCell>
                    <TableCell>WEEK</TableCell>
                    <TableCell>NOTE</TableCell>
                    <TableCell>AMOUNT</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {adHocDetailData.map((item, idx) => {
                    let reconIdent = "";
                    if (!isNullOrUndef(item.ident)) {
                      reconIdent = "000000" + item.ident;
                      reconIdent = reconIdent.slice(reconIdent.length - 6);
                      reconIdent = "PR" + reconIdent;
                    }
                    return (
                      <TableRow key={`advancedetail${idx}`} className={classes.detailTableRowNormal}>
                        <TableCell>{reconIdent}</TableCell>
                        <TableCell>{item.week}</TableCell>
                        <TableCell>{item.makeanote}</TableCell>
                        <TableCell>{item.amount}</TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default withStyles(styles)(ProducerAdvancePayUnstyled);

const TableFieldLabel: React.FunctionComponent<{ field: string; title: string; disabled: boolean } & WithStyles<typeof styles>> = (props) => {
  const { classes, field, title, disabled } = props;
  return (
    <>
      <span className={classes.tableCellLabel}>{`${title}:`}</span>
      <span className={classes.tableCellLabelValue}>{`${field}`}</span>
    </>
  );
};

const TableFieldText: React.FunctionComponent<{ field: string; title: string; disabled: boolean } & WithStyles<typeof styles>> = (props) => {
  const { classes, field, title, disabled } = props;
  return (
    <>
      <span className={classes.tableCellLabel}>{`${title}:`}</span>
      <span className={classes.tableCellDetail}>
        <Field name={field} component={TextField} type="text" fullWidth={true} disabled={disabled} />
      </span>
    </>
  );
};

const TableFieldCombo: React.FunctionComponent<
  { classes: any; form: any; title: string; field: string; data: any; addEmptyValue: string; handleParse: (form, value) => void } & WithStyles<typeof styles>
> = (props) => {
  const { classes, form, title, field, data, addEmptyValue, handleParse } = props;
  return (
    <>
      <span className={classes.tableCellLabel}>{`${title}:`}</span>
      <span className={classes.tableCellDetail}>
        <Field
          name={field}
          component={Select}
          formControlProps={{ className: classes.tableCellsValueSelect }}
          parse={(value) => {
            if (handleParse) {
              handleParse(form, value);
            }
            return value;
          }}
        >
          {addEmptyValue && (
            <MenuItem key={`emptyvalueMenuItem`} value={-1}>
              {addEmptyValue}
            </MenuItem>
          )}
          {data &&
            data.map((item, index) => {
              return (
                <MenuItem key={`${item.value}${index}`} value={item.value}>
                  {item.display}
                </MenuItem>
              );
            })}
        </Field>
      </span>
    </>
  );
};
