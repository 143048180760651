import { apiHeaders, client, request } from "./_base";

const endpoint = "invoiceItemsAudit";

export type InvoiceItemsAuditType = {
  id?: number;
  invoiceid: number;
  match: string;
  fob: number;
  mgp: number;
  barcode_bak?: string;
  accepted?: number;
  merged?: number;
  producerId: string;
  commodity: string;
  varietyCode: string;
  gradeCode: string;
  packCode: string;
  countCode: string;
  markCode: string;
  intakeId: number;
  sequenceNumber: number;
  barcode: string;
  barcode_id: number;
};

export enum InvoiceLinesStatus {
  Accepted = 1,
  Merged = 1,
}

// invoice api exports
export const invoiceItemsAudit = client(endpoint, (item) => ({
  ...item,
}));

export const handleBatchRemoveInvoiceAudit = async (ids: number[]): Promise<void> => {
  const content = JSON.stringify(ids);
  const result = await request(`${endpoint}/ext/batchRemoveInvoiceAudit`, {
    method: "POST",
    headers: apiHeaders(content),
    body: content,
  });
  if (result.status == 500) {
    const resp = await result.json();
    throw new Error(resp);
  }
};

export const handleBatchAcceptInvoiceAudit = async (rows: any[]): Promise<void> => {
  const content = JSON.stringify(rows);
  const result = await request(`${endpoint}/ext/batchAcceptInvoiceAudit`, {
    method: "POST",
    headers: apiHeaders(content),
    body: content,
  });
  if (result.status == 500) {
    const resp = await result.json();
    throw new Error(resp);
  }
};

export const updateSalePalletPrices = async (data: InvoiceItemsAuditType[]): Promise<void> => {
  const content = JSON.stringify(data);
  const result = await request(`${endpoint}/ext/updateSalePalletPrices`, {
    method: "POST",
    headers: apiHeaders(content),
    body: content,
  });
  if (result.status == 500) {
    const resp = await result.json();
    throw new Error(resp);
  }
};
