import React from "react";
import { withStyles, createStyles, Theme } from "@material-ui/core/styles";

import CustomAutoCompleteFilter from "../../lib/components/customdatagridfilter/CustomFilter";

import format from "date-fns/format";

import { FormatterNumber, FormatterNumber2Decimals } from "../../lib/components/Formatters";
import TextEditor from "../../lib/components/TextEditor";
import { SummaryNumber, SummaryNumber2Decimal } from "../../lib/components/SummaryFormatter";
import { handleColumnsArrangement, handleColumnsWidth } from "../../lib/helpers/grid";

const cellWidthSmallX = 90;
const cellWidthSmall = 115;
const cellWidthMedium = 150;
const cellWidthLarge = 220;

const defaultColumnProperties = {
  resizable: true,
  sortable: true,
  filterable: true,
};

let currentSpace = true;

const DateFormatter = (date: string, time = false) => {
  const newDate = new Date(date.replace("Z", ""));
  return <div>{time ? format(newDate, "dd MMM yyyy HH:mm") : format(newDate, "dd MMM yyyy")}</div>;
};

const styles = (theme: Theme) =>
  createStyles({
    datepicker: {},
  });

const DatePickerUnstyled: React.FC<any> = ({ row, column, onRowChange }) => {
  return <input autoFocus type="date" value={format(new Date(row[column.key]), "yyyy-MM-dd")} onChange={(event) => onRowChange({ ...row, [column.key]: event.target.value })} />;
};

const DatePicker = withStyles(styles)(DatePickerUnstyled);

export const ColumnsFileImportHeader = (data, filters, arrangement, columnsWidth) => {
  let postValue = "";

  if (data) {
    if (currentSpace) {
      postValue = " ";
    }
    currentSpace = !currentSpace;
  }

  const columns = [
    {
      key: "FormattedDate",
      name: "Date" + postValue,
      width: cellWidthSmall,
      editable: true,
      editor: DatePicker,
      formatter: ({ row }) => DateFormatter(row.Date),
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"FormattedDate"} data={data} section={"fileimportinfo"} />;
      },
      summaryFormatter: SummaryNumber,
    },
    {
      key: "Organization",
      name: "Organization" + postValue,
      width: cellWidthMedium,
      editable: true,
      editor: (props) => <TextEditor {...props} />,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Organization"} data={data} section={"fileimportinfo"} />;
      },
    },
    {
      key: "FileName",
      name: "FileName" + postValue,
      width: cellWidthMedium,
      editable: true,
      editor: (props) => <TextEditor {...props} />,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"FileName"} data={data} section={"fileimportinfo"} />;
      },
    },
    {
      key: "FileType",
      name: "File Type" + postValue,
      width: cellWidthSmall,
      editable: true,
      editor: (props) => <TextEditor {...props} />,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"FileType"} data={data} section={"fileimportinfo"} />;
      },
    },
    {
      key: "LocationCode",
      name: "Location Code" + postValue,
      width: cellWidthMedium,
      editable: true,
      editor: (props) => <TextEditor {...props} />,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"LocationCode"} data={data} section={"fileimportinfo"} />;
      },
    },
    {
      key: "WaybillDocument",
      name: "Waybill Document" + postValue,
      width: cellWidthMedium,
      editable: true,
      editor: (props) => <TextEditor {...props} />,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"WaybillDocument"} data={data} section={"fileimportinfo"} />;
      },
    },
    {
      key: "DispatchDocRef",
      name: "Dispatch Doc Ref" + postValue,
      width: cellWidthMedium,
      editable: true,
      editor: (props) => <TextEditor {...props} />,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"DispatchDocRef"} data={data} section={"fileimportinfo"} />;
      },
    },
    {
      key: "ConsecNo",
      name: "Consec No" + postValue,
      width: cellWidthMedium,
      editable: true,
      editor: (props) => <TextEditor {...props} />,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"ConsecNo"} data={data} section={"fileimportinfo"} />;
      },
    },
    {
      key: "TotalCartons",
      name: "Total Cartons" + postValue,
      width: cellWidthMedium,
      editable: true,
      editor: (props) => <TextEditor {...props} align="right" />,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"TotalCartons"} data={data} section={"fileimportinfo"} />;
      },
      formatter: FormatterNumber,
      summaryFormatter: SummaryNumber,
    },
    {
      key: "TotalPallets",
      name: "Total Pallets" + postValue,
      width: cellWidthMedium,
      editable: true,
      editor: (props) => <TextEditor {...props} align="right" />,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"TotalPallets"} data={data} section={"fileimportinfo"} />;
      },
      formatter: FormatterNumber2Decimals,
      summaryFormatter: SummaryNumber2Decimal,
    },
    {
      key: "NoEntries",
      name: "No Entries" + postValue,
      width: cellWidthMedium,
      editable: true,
      editor: (props) => <TextEditor {...props} align="right" />,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"NoEntries"} data={data} section={"fileimportinfo"} />;
      },
      formatter: FormatterNumber,
    },
    {
      key: "Receiver",
      name: "Receiver" + postValue,
      width: cellWidthMedium,
      editable: true,
      editor: (props) => <TextEditor {...props} />,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Receiver"} data={data} section={"fileimportinfo"} />;
      },
    },
    {
      key: "RegNo",
      name: "Ref/Reg No" + postValue,
      width: cellWidthMedium,
      editable: true,
      editor: (props) => <TextEditor {...props} />,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"RegNo"} data={data} section={"fileimportinfo"} />;
      },
    },
    {
      key: "Carrier",
      name: "Carrier" + postValue,
      width: cellWidthSmall,
      editable: true,
      editor: (props) => <TextEditor {...props} />,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Carrier"} data={data} section={"fileimportinfo"} />;
      },
    },
    {
      key: "Vessel",
      name: "Vessel" + postValue,
      width: cellWidthMedium,
      editable: true,
      editor: (props) => <TextEditor {...props} />,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Vessel"} data={data} section={"fileimportinfo"} />;
      },
    },
    {
      key: "ShippingNo",
      name: "Shipping No" + postValue,
      width: cellWidthMedium,
      editable: true,
      editor: (props) => <TextEditor {...props} />,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"ShippingNo"} data={data} section={"fileimportinfo"} />;
      },
    },
    {
      key: "ContainerNo",
      name: "Container No" + postValue,
      width: cellWidthMedium,
      editable: true,
      editor: (props) => <TextEditor {...props} />,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"ContainerNo"} data={data} section={"fileimportinfo"} />;
      },
    },
    {
      key: "SealNumber",
      name: "Seal Number" + postValue,
      width: cellWidthMedium,
      editable: true,
      editor: (props) => <TextEditor {...props} />,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"SealNumber"} data={data} section={"fileimportinfo"} />;
      },
    },
    {
      key: "Destination",
      name: "Destination" + postValue,
      width: cellWidthMedium,
      editable: true,
      editor: (props) => <TextEditor {...props} />,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Destination"} data={data} section={"fileimportinfo"} />;
      },
    },
    {
      key: "DestinationType",
      name: "Destination Type" + postValue,
      width: cellWidthMedium,
      editable: true,
      editor: (props) => <TextEditor {...props} />,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"DestinationType"} data={data} section={"fileimportinfo"} />;
      },
    },
    {
      key: "Channel",
      name: "Channel" + postValue,
      width: cellWidthMedium,
      editable: true,
      editor: (props) => <TextEditor {...props} />,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Channel"} data={data} section={"fileimportinfo"} />;
      },
    },
    {
      key: "Transport",
      name: "Transport" + postValue,
      width: cellWidthMedium,
      editable: true,
      editor: (props) => <TextEditor {...props} />,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Transport"} data={data} section={"fileimportinfo"} />;
      },
    },
    {
      key: "LoadType",
      name: "Load Type" + postValue,
      width: cellWidthMedium,
      editable: true,
      editor: (props) => <TextEditor {...props} />,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"LoadType"} data={data} section={"fileimportinfo"} />;
      },
    },
    {
      key: "RevisionNO",
      name: "Revision NO" + postValue,
      width: cellWidthMedium,
      editable: true,
      editor: (props) => <TextEditor {...props} align="right" />,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"RevisionNO"} data={data} section={"fileimportinfo"} />;
      },
      formatter: FormatterNumber,
    },
    {
      key: "OrderNo",
      name: "Order No" + postValue,
      width: cellWidthMedium,
      editable: true,
      editor: (props) => <TextEditor {...props} />,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"OrderNo"} data={data} section={"fileimportinfo"} />;
      },
    },
    {
      key: "UserName",
      name: "UserName" + postValue,
      width: cellWidthSmall,
      editable: true,
      editor: (props) => <TextEditor {...props} />,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"UserName"} data={data} section={"fileimportinfo"} />;
      },
    },
  ].map((c) => ({ ...c, ...defaultColumnProperties }));

  let columnsArranged = [];
  columnsArranged = handleColumnsArrangement(columnsArranged, arrangement, columns);
  columnsArranged = handleColumnsWidth(columnsArranged, columnsWidth, columns);
  return columnsArranged;
};
