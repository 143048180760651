import { client, request, apiHeaders } from "./_base";

const endpoint = "companyDetailsBanking";

export type CompanyDetailsBankingType = {
  id?: number;
  companydetails_id: number;
  currency_id: number;
  accountholder?: string;
  accountholderaddress?: string;
  bank?: string;
  bankaddress?: string;
  branchcode?: string;
  accounttype?: string;
  accountnumber?: string;
  swiftcode?: string;
};

export const companyDetailsBanking = client(endpoint, (item) => ({
  ...item,
}));

export const bankDetailsByCompanyAndCurrency = async (companydetails_id: number, currency_code: string) => {
  const result = await request(`${endpoint}/ext/bankDetailsByCompanyAndCurrency/${companydetails_id}/${currency_code}`, {
    headers: apiHeaders(),
  });
  if (result.status == 500) {
    const resp = await result.json();
    throw resp;
  }
  return result.json();
};

export const bankingDetailsCombo = async () => {
  return await request(`${endpoint}/ext/bankingDetailsCombo`, {
    headers: apiHeaders(),
  }).then(async (result) => {
    if (result.status == 500) {
      const resp = await result.json();
      throw resp;
    }
    return result.json();
  });
};

export const getCompanyBankingDetailById = async (detail_id: number): Promise<{ data: CompanyDetailsBankingType[] }> => {
  const result = await request(`${endpoint}/ext/companyBankingDetailById/${detail_id}`, {
    headers: apiHeaders(),
  });
  if (result.status == 500) {
    const resp = await result.json();
    throw resp;
  }
  return result.json();
};
