import React from "react";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";

import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import Badge from "@material-ui/core/Badge";

import { Toolbar } from "react-data-grid-addons";

import GetApp from "@material-ui/icons/GetApp";

import ReactDataGrid, { Row } from "react-data-grid";
import "react-splitter-layout/lib/index";
import "../../lib/helpers/splitter.css";

import { generateSortFn } from "../../lib/helpers/generateSortFN";
import { DownloadCSV, LoadoutHeader } from "../../lib/helpers/Files";
import { clearFiltersForColumns, removeFromSticky } from "../../lib/helpers/stickyfilters";

import { DialogInformation } from "../../lib/components/dialoginformation";
import { GridColumnsSales } from "./invoicetablesetupsales";
import Confirmation from "../../lib/components/confirmation";
import GridColumnLayout from "../../lib/components/gridlayout";
import InvoiceFilterChips from "../../lib/components/filterchips";

import { GridColumnsPallets } from "./invoicetablesetuppallets";
import { generateGridFilteredRows } from "../../lib/helpers/generateGridFilteredRows";

import { producerSaleBarcodesByDispatchID, ProducerSaleDispatchJoinedType } from "../../lib/api/producersale";

import Splitter from "m-react-splitters";
import "m-react-splitters/lib/splitters.css";
import { isNullOrUndef } from "../../lib/helpers/isNullOrUndef";

import { updateUserProfile } from "../../lib/helpers/stickyfilters";

import { summaryRows, handleFilterChange, handleSortChange } from "../../lib/helpers/grid";
import classNames from "classnames";

import Refresh from "@material-ui/icons/Refresh";
import LayoutIcon from "@material-ui/icons/ViewColumn";
import { ClearFilterIcon } from "../../icons/icons";

import format from "date-fns/format";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      height: "100%",
      width: "100%",
    },
    control: {
      paddingTop: theme.spacing(1),
      float: "right",
    },
    controlFloatLeft: {
      paddingTop: theme.spacing(1),
      float: "left",
    },
    controlButton: {
      paddingLeft: "10px",
      paddingBottom: "10px",
    },
    selectedHeader: {
      color: "white",
      backgroundColor: theme.palette.primary.main,
    },
    mainSplitterDiv: {
      height: "100%",
      width: "100%",
      marginTop: "60px",
      display: "grid",
    },
    rowActions: {
      "& .rdg-cell-action-menu": {
        position: "absolute",
        top: "calc(-60px - 24px)",
      },
    },
    greenRow: {
      color: "green",
    },
    selectedRow: {
      fontWeight: "bold",
    },
  });

// enum FormatterTypes {
//   Text = 0,
//   Number = 1,
//   Number2Decimals = 2,
//   Date = 3,
//   Terms = 4,
//   Status = 5,
//   Actions = 6,
// }

type InvoiceTableProps = {
  data: {}[];
  onReload: any;
  onAccept: any;
  onRelease: any;
  history: any;
} & WithStyles<typeof styles>;

class InvoiceTableUnstyled extends React.Component<InvoiceTableProps, {}> {
  state = {
    data: [{}],
    filters: {},
    filtersSelected: {},
    sorting: {},
    sortingPallets: {},
    hideLoaded: false,
    onReload: undefined,
    onAccept: undefined,
    onRelease: undefined,
    selectedRows: [],
    selectedSales: [],
    dialoginformation: undefined,
    gridRef: undefined,
    gridRefSelected: undefined,
    minGridHeight: 0,
    minGridHeightSecond: 0,
    clearedPhrase: false,
    layoutItem: "producerinvoice",
    layoutItemSelected: "producerinvoicepalletsmain",
    widthItem: "producerinvoicewidth",
    widthItemSelected: "producerinvoicewidthselected",
    sortItem: "producerinvoicesort",
    columnsArranged: [],
    columnsArrangedSelected: [],
    columnsWidth: [],
    columnsWidthSelected: [],
    tableLayout: false,
    currentSortColumn: "",
    currentSortDirection: "",
    currentSortColumnSelected: "",
    currentSortDirectionSelected: "",
  };

  constructor(props) {
    super(props);
    this.state.data = props.data;
    this.state.onReload = props.onReload;
    this.state.onAccept = props.onAccept;
    this.state.onRelease = props.onRelease;
    this.state.gridRef = React.createRef();
  }

  componentDidMount() {
    const invoicetablelayout = localStorage.getItem(this.state.layoutItem);
    if (invoicetablelayout) {
      this.setState({ columnsArranged: JSON.parse(invoicetablelayout) }, () => {});
      updateUserProfile(JSON.parse(invoicetablelayout), this.state.layoutItem, "general");
    } else {
      const columnsToRender = GridColumnsSales(undefined, undefined, undefined, undefined);
      const columnsArr = columnsToRender.map((column) => column.name);

      this.setState({ columnsArranged: columnsArr }, () => {});
      updateUserProfile(columnsArr, this.state.layoutItem, "general");
    }

    const invoicetablelayoutselected = localStorage.getItem(this.state.layoutItemSelected);
    if (invoicetablelayoutselected) {
      this.setState({ columnsArrangedSelected: JSON.parse(invoicetablelayoutselected) }, () => {});
      updateUserProfile(JSON.parse(invoicetablelayoutselected), this.state.layoutItemSelected, "general");
    } else {
      const columnsToRender = GridColumnsPallets(undefined, undefined, undefined, undefined, false, undefined);
      const columnsArr = columnsToRender.map((column) => column.name);

      this.setState({ columnsArrangedSelected: columnsArr }, () => {});
      updateUserProfile(columnsArr, this.state.layoutItemSelected, "general");
    }

    const invoicetablewidth = localStorage.getItem(this.state.widthItem);
    if (invoicetablewidth) {
      this.setState({ columnsWidth: JSON.parse(invoicetablewidth) }, () => {});
      updateUserProfile(JSON.parse(invoicetablewidth), this.state.widthItem, "general");
    } else {
      const columnsToRender = GridColumnsSales(undefined, undefined, undefined, undefined);
      const columnsArr = columnsToRender.map((column) => {
        return { name: column.name, width: column.width };
      });

      localStorage.setItem(this.state.widthItem, JSON.stringify(columnsArr));
      this.setState({ columnsWidth: columnsArr }, () => {});
      updateUserProfile(columnsArr, this.state.widthItem, "general");
    }

    const invoicetablewidthselected = localStorage.getItem(this.state.widthItemSelected);
    if (invoicetablewidthselected) {
      this.setState({ columnsWidthSelected: JSON.parse(invoicetablewidthselected) }, () => {});
      updateUserProfile(JSON.parse(invoicetablewidthselected), this.state.widthItemSelected, "general");
    } else {
      const columnsToRender = GridColumnsPallets(undefined, undefined, undefined, undefined, false, undefined);
      const columnsArr = columnsToRender.map((column) => {
        return { name: column.name, width: column.width };
      });

      localStorage.setItem(this.state.widthItemSelected, JSON.stringify(columnsArr));
      this.setState({ columnsWidthSelected: columnsArr }, () => {});
      updateUserProfile(columnsArr, this.state.widthItemSelected, "general");
    }

    const invoicetablesorting = localStorage.getItem(this.state.sortItem);
    if (invoicetablesorting) {
      let sortingV = JSON.parse(invoicetablesorting);

      this.setState({ sorting: sortingV }, () => {});
    }
    this.setGridHeights();
  }

  getDetail(list, largeNumber = false) {
    const text = {
      __html: `
      <span style="${largeNumber ? "font-size: 21px" : ""}">${list.length}</span> Record(s) 
      <span style="font-size: 28px; line-height:0; "></span> 
      `,
    };
    return <span dangerouslySetInnerHTML={text} />;
  }

  getDetailSelected(list, largeNumber = false) {
    const text = {
      __html: `
        <span style="${largeNumber ? "font-size: 21px" : ""}">${list.length}</span> Record(s) <span style="font-size: 28px; line-height:0; ">|</span> 
        `,
    };

    return <span dangerouslySetInnerHTML={text} />;
  }

  handleFilterChange = (filter) => {
    const newFilters = handleFilterChange(filter, this.state.filters);
    this.setState({ filters: newFilters }, () => {
      this.setGridHeights();
    });
  };

  handleFilterSelectedChange = (filter) => {
    const newFilters = handleFilterChange(filter, this.state.filtersSelected);
    this.setState({ filtersSelected: newFilters }, () => {
      this.setGridHeights();
    });
  };

  getRows = (rows, filters) => {
    const result = generateGridFilteredRows(rows, filters).filter((item) => {
      if (this.state.hideLoaded) {
        if (item.loadout_reference.length == 0) {
          return item;
        }
      } else {
        return item;
      }
    });

    const fieldSorterData = Object.keys(this.state.sorting).map((item) => {
      return { name: item, reverse: this.state.sorting[item] == "ASC" ? false : true };
    });

    result.sort(generateSortFn(fieldSorterData));

    return result;
  };

  getPalletRows = (rows, filters) => {
    const result = generateGridFilteredRows(rows, filters).filter((item) => {
      if (this.state.hideLoaded) {
        if (item.loadout_reference.length == 0) {
          return item;
        }
      } else {
        return item;
      }
    });

    const fieldSorterData = Object.keys(this.state.sortingPallets).map((item) => {
      return { name: item, reverse: this.state.sortingPallets[item] == "ASC" ? false : true };
    });

    result.sort(generateSortFn(fieldSorterData));

    return result;
  };

  handleAvailableSalesDoubleClick = (row: ProducerSaleDispatchJoinedType) => {
    producerSaleBarcodesByDispatchID(row.dispatch_id, row.producer_code).then((data) => {
      this.setState({ selectedSales: { ...row }, selectedRows: [...data] }, () => {});
    });
  };

  selectedClear = () => {
    this.setState({ selectedRows: [], selectedSales: [] });
  };

  handleSortPallets = (sortColumn, sortDirection) => {
    const sortingState = handleSortChange(this.state.sortingPallets, sortColumn, sortDirection);

    this.setState({ sortingPallets: sortingState, currentSortColumnSelected: sortColumn, currentSortDirectionSelected: sortDirection }, () => {
      this.setGridHeights();
    });
  };

  handleSort = (sortColumn, sortDirection) => {
    const sortingState = handleSortChange(this.state.sorting, sortColumn, sortDirection);

    this.setState({ sorting: sortingState, currentSortColumn: sortColumn, currentSortDirection: sortDirection }, () => {
      localStorage.setItem(this.state.sortItem, JSON.stringify(sortingState));
      updateUserProfile(sortingState, this.state.sortItem, "general");
      this.setGridHeights();
    });
  };

  handleRemoveSortEntry = (field) => {
    const collection = document.getElementsByClassName("pull-right");
    let i = 0;
    for (i = 0; i < collection.length; i++) {
      if (collection[i].innerHTML.length < 1) {
        continue;
      }
      (collection[i] as HTMLElement).innerHTML = "";
      break;
    }
    const sortingState = this.state.sorting;
    delete sortingState[field];
    this.setState({ sorting: sortingState }, () => {
      localStorage.setItem(this.state.sortItem, JSON.stringify(sortingState));
      updateUserProfile(sortingState, this.state.sortItem, "general");
      this.setGridHeights();
    });
  };

  handleRemoveFilterEntry = (key, value) => {
    removeFromSticky(key, value, "invoice");

    this.setState({ filters: {} }, () => {
      this.setGridHeights();
    });
  };

  clearFilters = () => {
    const keys = Object.keys(this.state.filters);
    clearFiltersForColumns(keys, "producerinvoice");

    this.setState({ filters: {} });
  };

  clearFiltersSelected = () => {
    const keys = Object.keys(this.state.filtersSelected);
    clearFiltersForColumns(keys, "producerinvoicepalletsmain");

    this.setState({ filtersSelected: {} });
  };

  handleExport = () => {
    const rows = this.getRows(this.state.data, this.state.filters);
    const headerColumns = GridColumnsSales(rows, this.state.filters, this.state.columnsArranged, this.state.columnsWidth);
    const sortedColumns = headerColumns.reduce((arr, curr) => {
      if (LoadoutHeader[curr.key]) {
        arr.push(curr);
      }
      return arr;
    }, []);
    DownloadCSV(rows, `${format(new Date(), "yyMMdd")}_FIXED_PRODUCER_INVOICE`, sortedColumns);
  };

  handleExportDetail = () => {
    const rows = this.getPalletRows(this.state.selectedRows, this.state.filtersSelected);
    const headerColumns = GridColumnsPallets(
      rows,
      this.state.filtersSelected,
      this.state.columnsArrangedSelected,
      this.state.columnsWidthSelected,
      false,
      "producerinvoicepalletsmain",
    );
    DownloadCSV(rows, `${format(new Date(), "yyMMdd")}_FIXED_PRODUCER_INVOICE_DETAIL`, headerColumns);
  };

  getChipsHeight = () => {
    return (Object.keys(this.state.sorting).length > 0 ? 42 : 0) + (Object.keys(this.state.filters).length > 0 ? 42 * Object.keys(this.state.filters).length : 0);
  };

  setGridHeights = () => {
    const primarysplit = document.getElementsByClassName("splitter horizontal");
    this.setState({ minGridHeight: primarysplit[0].children[0].clientHeight - (10 + this.getChipsHeight()), minGridHeightSecond: primarysplit[0].children[2].clientHeight - 210 });
  };

  handleInfoClose = () => {
    this.setState({ dialoginformation: undefined });
  };

  handleColumnResize = (index, width) => {
    const columnName = this.state.columnsArranged[index];
    const newArr = [...this.state.columnsWidth];
    const colwidthRecord = this.state.columnsWidth.findIndex((item) => item.name.trim() == columnName.trim());
    if (colwidthRecord >= 0) {
      newArr[colwidthRecord].width = width;
      localStorage.setItem(this.state.widthItem, JSON.stringify(newArr));
      updateUserProfile(newArr, this.state.widthItem, "general");
      this.setState({ columnsWidth: newArr });
    }
  };

  handleColumnResizeSelected = (index, width) => {
    const columnName = this.state.columnsArrangedSelected[index];
    const newArr = [...this.state.columnsWidthSelected];
    const colwidthRecord = this.state.columnsWidthSelected.findIndex((item) => item.name.trim() == columnName.trim());
    if (colwidthRecord >= 0) {
      newArr[colwidthRecord].width = width;
      localStorage.setItem(this.state.widthItemSelected, JSON.stringify(newArr));
      updateUserProfile(newArr, this.state.widthItemSelected, "general");
      this.setState({ columnsWidthSelected: newArr });
    }
  };

  handleLayout = () => {
    this.setState({ tableLayout: true });
  };

  handleLayoutClose = () => {
    if (!this.phrase || this.phrase.length === 0) {
      this.setState({ tableLayout: false });
    } else {
      this.clearPhrase();
    }
  };

  handleLayoutConfirm = (columns) => {
    localStorage.setItem(this.state.layoutItem, JSON.stringify(columns));
    this.setState({ tableLayout: false, columnsArranged: columns });
  };

  phrase = "";
  setSearchPhrase = (phrase) => {
    this.phrase = phrase;
  };
  clearPhrase = () => {
    this.setSearchPhrase("");
    this.setState({ clearedPhrase: !this.state.clearedPhrase });
  };

  rowRenderer = (props, classes) => {
    const { row } = props;
    const selectedSales = this.state.selectedSales;

    const color = selectedSales && row.sale_id && classes.greenRow;
    const fontWeight = selectedSales && selectedSales["dispatch_id"] == row["dispatch_id"] && classes.selectedRow;

    return <Row {...props} className={classNames(color, fontWeight)} onDoubleClick={() => this.handleAvailableSalesDoubleClick(row)} />;
  };

  selectedRowRenderer = (props) => {
    return <Row {...props} onDoubleClickCapture={() => this.state.onAccept(this.state.selectedRows, this.state.selectedSales)} />;
  };

  handleBack = () => {
    this.props.history.push("/producers/invoice/summary");
  };

  filterTerms = (filterObj, term = "filterTerm") => {
    return Object.keys(filterObj).reduce((val, key) => (val += filterObj[key][term].length), 0);
  };

  render() {
    const { classes } = this.props;
    const filteredRows = this.getRows(this.state.data, this.state.filters);
    const palletRows = this.getPalletRows(this.state.selectedRows, this.state.filtersSelected);
    const columnsToRender = GridColumnsSales(this.state.data, this.state.filters, this.state.columnsArranged, this.state.columnsWidth);
    const columnsToRenderSelected = GridColumnsPallets(
      palletRows,
      this.state.filtersSelected,
      this.state.columnsArrangedSelected,
      this.state.columnsWidthSelected,
      false,
      "producerinvoicepalletsmain",
    );

    const totalRowColumns = ["barcode_nocartons", "barcode_palletsize", "total"];
    const totalRowColumnsSelected = ["barcode_nocartons", "barcode_palletsize", "stockdetail_sellingprice", "stockdetail_producerprice", "total"];

    return (
      <div className={classes.root}>
        {this.state.tableLayout && (
          <Confirmation isOpen={this.state.tableLayout} handleClose={this.handleLayoutClose} handleConfirm={() => {}} title="Editing Grid Layout" body={undefined}>
            <GridColumnLayout
              layoutItem={this.state.layoutItem}
              onSubmit={this.handleLayoutConfirm}
              onClose={this.handleLayoutClose}
              columns={this.state.columnsArranged}
              setSearchPhrase={this.setSearchPhrase}
              clearPhrase={this.state.clearedPhrase}
            />
          </Confirmation>
        )}
        {this.state.dialoginformation && (
          <DialogInformation
            isOpen={true}
            handleClose={this.handleInfoClose}
            handleOK={this.handleInfoClose}
            title={this.state.dialoginformation.title}
            body={`${this.state.dialoginformation.body}`}
            showinput={false}
          />
        )}
        <span className={classes.controlFloatLeft}>
          <Button color="secondary" variant="contained" onClick={this.handleBack} style={{ marginRight: "10px" }}>
            Back
          </Button>
          <span style={{ color: "orange", fontWeight: "bold" }}>{this.getDetail(filteredRows)}</span>
        </span>
        <div className={classes.control}>
          <Button variant="contained" color="primary" style={{ marginRight: "10px" }} onClick={this.handleExport} disabled={filteredRows.length <= 0}>
            <GetApp />
          </Button>
          <Tooltip title="Clear Filters">
            <Button
              variant="contained"
              color="primary"
              onClick={() => this.clearFilters()}
              style={{ marginRight: "10px" }}
              disabled={Object.entries(this.state.filters).length === 0 && this.state.filters.constructor === Object}
            >
              <Badge badgeContent={this.filterTerms(this.state.filters)} color="secondary">
                <ClearFilterIcon />
              </Badge>
            </Button>
          </Tooltip>
          <Tooltip title="Refresh Data">
            <Button variant="contained" color="primary" onClick={this.state.onReload} style={{ marginRight: "10px" }}>
              <Refresh />
            </Button>
          </Tooltip>
          <Tooltip title="Grid Layout">
            <Button variant="contained" color="primary" onClick={this.handleLayout} style={{ marginRight: "10px" }}>
              <LayoutIcon />
            </Button>
          </Tooltip>
        </div>
        <div id="main_splitter_div" className={classes.mainSplitterDiv}>
          <Splitter
            position="horizontal"
            primaryPaneMaxHeight="calc(100% - 150px)"
            primaryPaneHeight="calc(100% - 350px)"
            primaryPaneWidth="100%"
            dispatchResize={true}
            postPoned={true}
            onDragFinished={(e) => {
              this.setGridHeights();
            }}
          >
            <div id="primary_grid" style={{ marginLeft: "1px" }}>
              <ReactDataGrid
                className="rdg-light"
                ref={(input) => (this.state.gridRef = input)}
                columns={columnsToRender}
                rows={filteredRows}
                enableFilterRow={true}
                rowHeight={30}
                headerRowHeight={35}
                style={{ position: "relative", height: this.state.minGridHeight, marginTop: "10px" }}
                onFiltersChange={(filter) => this.handleFilterChange(filter)}
                onSort={(sortColumn, sortDirection) => this.handleSort(sortColumn, sortDirection)}
                sortColumn={this.state.currentSortColumn}
                sortDirection={this.state.currentSortDirection as any}
                onColumnResize={this.handleColumnResize}
                summaryRows={summaryRows(filteredRows, totalRowColumns)}
                rowRenderer={(props) => this.rowRenderer(props, classes)}
              />
              <div>
                <InvoiceFilterChips
                  columns={columnsToRender}
                  filterData={this.state.filters}
                  handleFilterClear={null}
                  sortingData={this.state.sorting}
                  removesortingentry={this.handleRemoveSortEntry}
                  removeFilterEntry={this.handleRemoveFilterEntry}
                />
              </div>
            </div>
            <div id="secondary_grid">
              <div className={classes.selectedHeader}>
                <Typography style={{ margin: "20px" }}>{this.getDetailSelected(this.state.selectedRows, true)}</Typography>
              </div>
              <Toolbar enableFilter={false}>
                <Button variant="contained" color="primary" style={{ marginRight: "10px" }} onClick={this.handleExportDetail} disabled={palletRows.length <= 0}>
                  <GetApp />
                </Button>
                <Button variant="contained" color="primary" onClick={() => this.selectedClear()} style={{ marginRight: "10px" }} disabled={this.state.selectedRows.length == 0}>
                  clear selected
                </Button>
                {this.state.selectedSales["camend_status"] == 0 && (
                  <>
                    {palletRows.length > 0 && !isNullOrUndef(this.state.selectedSales["sale_id"]) && (
                      <Button variant="contained" color="secondary" onClick={() => this.state.onRelease(this.state.selectedSales)}>
                        REMOVE INVOICE
                      </Button>
                    )}
                    {palletRows.length > 0 && (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => this.state.onAccept(palletRows, this.state.selectedSales)}
                        style={{ backgroundColor: "green", marginLeft: "10px" }}
                      >
                        {isNullOrUndef(this.state.selectedSales["sale_id"]) ? "create invoice" : "edit invoice"}
                      </Button>
                    )}
                  </>
                )}
                <Tooltip title="Clear Filters">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => this.clearFiltersSelected()}
                    disabled={Object.entries(this.state.filtersSelected).length === 0 && this.state.filtersSelected.constructor === Object}
                  >
                    <Badge badgeContent={this.filterTerms(this.state.filtersSelected)} color="secondary">
                      <ClearFilterIcon />
                    </Badge>
                  </Button>
                </Tooltip>
              </Toolbar>
              <ReactDataGrid
                className="rdg-light"
                ref={(input) => (this.state.gridRefSelected = input)}
                columns={columnsToRenderSelected}
                rows={palletRows}
                enableFilterRow={true}
                rowHeight={30}
                headerRowHeight={35}
                style={{ position: "relative", height: this.state.minGridHeightSecond, marginTop: "10px" }}
                onFiltersChange={(filter) => this.handleFilterSelectedChange(filter)}
                onSort={(sortColumn, sortDirection) => this.handleSortPallets(sortColumn, sortDirection)}
                sortColumn={this.state.currentSortColumnSelected}
                sortDirection={this.state.currentSortDirectionSelected as any}
                onColumnResize={this.handleColumnResizeSelected}
                summaryRows={summaryRows(palletRows, totalRowColumnsSelected)}
                rowRenderer={this.selectedRowRenderer}
              />
            </div>
          </Splitter>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(InvoiceTableUnstyled);
