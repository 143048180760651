import { client, request, apiHeaders } from "./_base";

const endpoint = "loadoutdetail";
export const loadoutdetail = client(endpoint, (item) => ({
  ...item,
}));

export const updateLoadoutByBarcode = async (item) => {
  const content = JSON.stringify(item);
  return request(`${endpoint}/updateByBarcode/:${content["barcode"]}`, {
    method: "PUT",
    headers: apiHeaders(content),
    body: content,
  })
    .then(async (result) => {
      if (result.status == 500) {
        const resp = await result.json();
        throw resp;
      }
      return result.json();
    })
    .then(({ data }) => {});
};

export const deleteByBarcode = async (barcode) => {
  await request(`${endpoint}/deleteByBarcode/${barcode}`, {
    method: "GET",
  })
    .then(async (result) => {
      if (result.status == 500) {
        const resp = await result.json();
        throw resp;
      }
      return result.json();
    })
    .then(({ data }) => {});
};

export const clearByLoadoutID = async (loadoutid) => {
  await request(`${endpoint}/clearByLoadoutID/${loadoutid}`, {
    method: "GET",
  })
    .then(async (result) => {
      if (result.status == 500) {
        const resp = await result.json();
        throw resp;
      }
      return result.json();
    })
    .then(({ data }) => {});
};

export type loadoutDetailFullType = {
  id: number;
  loadout_id: number;
  reference: string;
  barcode: string;
  noCartons: string;
  commodityCode: string;
  varietyCode: string;
  packCode: string;
  markCode: string;
  gradeCode: string;
  countCode: string;
  inventoryCode: string;
  palletSize: string;
  wayBill: string;
};
export const loadoutDetailFull = async (loadoutid) => {
  return request(`${endpoint}/full/${loadoutid}`, {
    method: "GET",
  })
    .then(async (result) => {
      if (result.status == 500) {
        const resp = await result.json();
        throw resp;
      }
      return result.json();
    })
    .then((data) => {
      return data;
    });
};
