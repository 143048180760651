import { apiHeaders, client, request } from "./_base";

export type CreditorsInvoiceType = {
  id: number;
  creditors_id: number;
  invoicenumber: string;
  applytype: number;
  currency_id: number;
  roe: number;
  validate_totalexcl: number;
  validate_vat: number;
  invoicedate: Date;
  consolidationapply: number;
  create_at: Date;
  updated_at: Date;
};

const endpoint = "creditorsinvoice";

export const creditorsinvoice = client(`${endpoint}`, (item) => ({
  ...item,
}));

export type CreditorsInvoiceFullType = {
  creditorsinvoice_id: number;
  id: number;
  creditors_id: number;
  creditors_code: string;
  creditors_name: string;
  invoicenumber: string;
  // applytype: number;
  currency_id: number;
  currency_code: string;
  roe: number;
  validate_totalexcl: number;
  validate_vat: number;
  validate_total: number;
  invoicedate: Date;
  created_at: Date;
  updated_at: Date;
  due_date: Date;
  creditorsinvoice_type: string;
  document_type: string;
  adjustdate: Date;
  creditorsinvoiceadjustment_id: number;
  creditorinvoice_totalexecl: number;
  creditorsinvoiceadjustment_type: string;
  camend_status: number;
  consolidationapply: number;
  sale_invoicenumber: string;
  credit_note: string;
};

export type CreditorsInvoiceExtendedType = {
  id: number;
  creditors_id: number;
  invoicenumber: string;
  roe: number;
  currency_id: number;
  validate_totalexcl: number;
  validate_vat: number;
  invoicedate: Date;
  created_at: Date;
  updated_at: Date;
  due_date: Date;
  consolidationapply: number;
  subgroup: string;
  group: string;
};

export type CreditorsInvoiceSummaryType = {
  creditor_code: string;
  creditor_name: string;
  totalExcl: number;
  totalVat: number;
  total: number;
};

export const creditorsinvoiceFull = async (financial_year: number) => {
  return await request(`${endpoint}/ext/full?financial_year=${financial_year}`)
    .then(async (result) => {
      if (result.status == 500) {
        const resp = await result.json();
        throw resp;
      }
      return result.json();
    })
    .then(({ data }) => data);
};

export const creditorsinvoiceFullByCreditor = async (financial_year: number, creditor_code?: string): Promise<CreditorsInvoiceFullType[]> => {
  const result = await request(`${endpoint}/ext/fullByCreditorsCode?creditor_code=${encodeURIComponent(creditor_code)}&financial_year=${encodeURIComponent(financial_year)}`);
  const resp = await result.json();

  if (result.status == 500) {
    throw new Error(resp);
  }

  return resp.data;
};

export const creditorsInvoiceExistsByInvoiceNumber = async (invoicedata: any) => {
  return await request(`${endpoint}/ext/exists`, { method: "POST", body: JSON.stringify(invoicedata) })
    .then(async (result) => {
      if (result.status == 500) {
        const resp = await result.json();
        throw resp;
      }
      return result.json();
    })
    .then(({ data }) => data);
};

export const getLastCreditorsInvoiceDate = async () => {
  return await request(`${endpoint}/ext/lastinvoicedate`)
    .then(async (result) => {
      if (result.status == 500) {
        const resp = await result.json();
        throw resp;
      }
      return result.json();
    })
    .then(({ data }) => data);
};

export const getSingleExtend = async (id: number): Promise<CreditorsInvoiceExtendedType[]> => {
  const result = await request(`${endpoint}/ext/singleExtend/${id}`);

  const resp = await result.json();
  if (result.status == 500) {
    throw resp;
  }
  return resp.data;
};

export const getCreditorsFullSummary = async (financial_year: number) => {
  const result = await request(`${endpoint}/ext/creditorsFullSummary/${financial_year}`);
  const resp = await result.json();
  if (result.status == 500) {
    throw resp;
  }
  return resp.data;
};

export type BarcodeInfoDetail = {
  sale_invoicenumber: string;
  loadoutdetail_barcode: string;
  barcode_nocartons: number;
  barcode_commoditycode: string;
  barcode_varietycode: string;
  barcode_packcode: string;
  barcode_markcode: string;
  barcode_gradecode: string;
  barcode_countcode: string;
  barcode_inventorycode: string;
  barcode_farm: string;
  barcode_palletsize: number;
  barcode_id: number;
  dispatch_id: number;
  dispatch_containerno: string;
  dispatch_containertype: string;
  dispatch_sealnumber: string;
  dispatch_consignee_id: number;
  dispatch_clearingagent_id: number;
  sale_id: number;
  loadout_exportNotificationReference: string;
  loadout_reference: string;
  currency: string;
  comment: string;
  producer_code: string;
  stockdetail_sellingprice: string;
  dispatch_forwardagentref: string;
  total: string;
  puc: string;
  intake_waybill: string;
};

export const creditorsInfoBarcodeDetail = async (financial_year) => {
  return await request(`${endpoint}/ext/creditorsInfoBarcodeDetail/${financial_year}`)
    .then(async (result) => {
      if (result.status == 500) {
        const resp = await result.json();
        throw resp;
      }
      return result.json();
    })
    .then(({ data }) => data);
};

export enum CreditorsInvoiceDocumentType {
  Invoice = "INVOICE",
  Debit = "DEBIT/INV",
  Credit = "CREDIT/INV",
}
