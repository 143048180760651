import React from "react";

import CustomAutoCompleteFilter from "../../lib/components/customdatagridfilter/CustomFilter";

import { FormatterNumber, FormatterNumber2Decimals, FormatterNumber3Decimals } from "../../lib/components/Formatters";
import { SummaryNumber, SummaryNumber2Decimal, SummaryTotal } from "../../lib/components/SummaryFormatter";
import { handleColumnsArrangement, handleColumnsWidth } from "../../lib/helpers/grid";

const cellWidthTiny = 40;
const cellWidthSmall = 80;
const cellWidthMedium = 120;
const cellWidthLarge = 150;
const cellWidthExtraLarge = 300;

const VatTypeFormatter = ({ row, column }) => {
  const data = row[column.key];
  return <div>{data == -1 ? "EX" : data}</div>;
};

const defaultColumnProperties = {
  resizable: true,
  sortable: true,
};

let currentSpace = true;
export const GridColumns = (data, filters, arrangement = undefined, columnsWidth = undefined) => {
  let postValue = "";

  if (data) {
    if (currentSpace) {
      postValue = " ";
    }
    currentSpace = !currentSpace;
  }

  const columns = [
    {
      key: "group",
      name: "Group" + postValue,
      width: cellWidthSmall,
      filterable: true,
      formatter: FormatterNumber,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"group"} data={data} section="creditorsInfoPreview" />;
      },
      summaryFormatter: SummaryTotal,
    },
    {
      key: "code",
      name: "Code" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"code"} data={data} section="creditorsInfoPreview" />;
      },
    },
    {
      key: "chargedescription",
      name: "Charge Description" + postValue,
      width: cellWidthExtraLarge,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"chargedescription"} data={data} section="creditorsInfoPreview" />;
      },
    },
    {
      key: "rate",
      name: "Rate" + postValue,
      width: cellWidthMedium,
      filterable: true,
      formatter: FormatterNumber2Decimals,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"rate"} data={data} section="creditorsInfoPreview" />;
      },
      summaryFormatter: SummaryNumber2Decimal,
    },
    {
      key: "units",
      name: "Units" + postValue,
      width: cellWidthMedium,
      filterable: true,
      formatter: FormatterNumber2Decimals,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"units"} data={data} section="creditorsInfoPreview" />;
      },
      summaryFormatter: SummaryNumber2Decimal,
    },
    {
      key: "vat",
      name: "VAT" + postValue,
      width: cellWidthMedium,
      filterable: true,
      formatter: VatTypeFormatter,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"vat"} data={data} section="creditorsInfoPreview" />;
      },
    },
    {
      key: "totalexcl",
      name: "Total EXCL" + postValue,
      width: cellWidthLarge,
      filterable: true,
      formatter: FormatterNumber2Decimals,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"totalexcl"} data={data} section="creditorsInfoPreview" />;
      },
      summaryFormatter: SummaryNumber2Decimal,
    },
    {
      key: "totalvat",
      name: "Total VAT" + postValue,
      width: cellWidthLarge,
      filterable: true,
      formatter: FormatterNumber2Decimals,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"totalvat"} data={data} section="creditorsInfoPreview" />;
      },
      summaryFormatter: SummaryNumber2Decimal,
    },
    {
      key: "totalincl",
      name: "Total INCL" + postValue,
      width: cellWidthLarge,
      filterable: true,
      formatter: FormatterNumber2Decimals,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"totalincl"} data={data} section="creditorsInfoPreview" />;
      },
      summaryFormatter: SummaryNumber2Decimal,
    },
    {
      key: "ROE",
      name: "ROE" + postValue,
      width: cellWidthMedium,
      filterable: true,
      formatter: FormatterNumber3Decimals,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"ROE"} data={data} section="creditorsInfoPreview" />;
      },
    },
    {
      key: "totalexclROE",
      name: "Total EXCL ROE" + postValue,
      width: cellWidthLarge,
      filterable: true,
      formatter: FormatterNumber2Decimals,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"totalexclROE"} data={data} section="creditorsInfoPreview" />;
      },
      summaryFormatter: SummaryNumber2Decimal,
    },
    {
      key: "totalvatROE",
      name: "Total VAT ROE" + postValue,
      width: cellWidthLarge,
      filterable: true,
      formatter: FormatterNumber2Decimals,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"totalvatROE"} data={data} section="creditorsInfoPreview" />;
      },
      summaryFormatter: SummaryNumber2Decimal,
    },
    {
      key: "totalinclROE",
      name: "Total INCL ROE" + postValue,
      width: cellWidthLarge,
      filterable: true,
      formatter: FormatterNumber2Decimals,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"totalinclROE"} data={data} section="creditorsInfoPreview" />;
      },
      summaryFormatter: SummaryNumber2Decimal,
    },
  ].map((c) => ({ ...c, ...defaultColumnProperties }));

  let columnsArranged = [];
  columnsArranged = handleColumnsArrangement(columnsArranged, arrangement, columns);
  columnsArranged = handleColumnsWidth(columnsArranged, columnsWidth, columns);

  return columnsArranged;
};

export function CellActions(column, row) {
  return null;
}
