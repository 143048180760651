export const TermsDateType = {
  0: "ETA",
  1: "ETD",
  2: "NETT",
  3: "STATEMENT",
  4: "INVOICE DATE",
  5: "PROFORMA INVOICE",
  6: "UPDATED ETA",
};

export const getTermsDateTypeReadyForCombo = () => {
  const result = [];
  Object.keys(TermsDateType).map((st) => {
    result.push({ value: st, display: TermsDateType[st] });
  });
  return result;
};
