import React from "react";

import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";

import Clear from "@material-ui/icons/Clear";
import Done from "@material-ui/icons/Done";

import CustomAutoCompleteFilter from "../../lib/components/customdatagridfilter/CustomFilter";
import { CellActionsFormatter } from "../../lib/helpers/CellActionsFormatter";

const cellWidthTiny = 40;
const cellWidthXtraSmall = 80;
const cellWidthSmall = 115;
const cellWidthSmallBig = 140;
const cellWidthMedium = 200;
// const cellWidthLarge = 250;
const cellWidthXtrLarge = 350;

const lockedcheck = ({ row, column }) => {
  const value = row[column.key];
  if (value) {
    return <Done />;
  }
  return <Clear />;
};

const defaultColumnProperties = {
  resizable: true,
  sortable: true,
};

let currentSpace = true;
export const GridColumns = (data, filters, handleRemove, handleEdit) => {
  let postValue = "";

  if (data) {
    if (currentSpace) {
      postValue = " ";
    }
    currentSpace = !currentSpace;
  }

  return [
    {
      key: "actions",
      name: "Actions",
      width: cellWidthXtraSmall,
      formatter: ({ row }) => {
        const actions = cellActions(row, handleRemove, handleEdit);
        return (
          <div style={{ display: "flex", justifyContent: "center", gap: "5px" }}>
            <CellActionsFormatter actions={actions} />
          </div>
        );
      },
    },
    {
      key: "revision",
      name: "Rev" + postValue,
      width: cellWidthXtraSmall,
      filterable: false,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"revision"} data={data} section={"ordersgridsetup"} />;
      },
    },
    {
      key: "farm",
      name: "Farm" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"farm"} data={data} section={"ordersgridsetup"} />;
      },
    },
    {
      key: "variety",
      name: "Variety" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"variety"} data={data} section={"ordersgridsetup"} />;
      },
    },
    {
      key: "week",
      name: "Week" + postValue,
      width: cellWidthSmallBig,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"week"} data={data} section={"ordersgridsetup"} />;
      },
    },
    {
      key: "locked",
      name: "Locked",
      width: cellWidthXtraSmall,
      formatter: lockedcheck,
    },
  ].map((c) => ({ ...c, ...defaultColumnProperties }));
};

const cellActions = (order, handleRemove, handleEdit) => {
  return [
    {
      icon: (
        <span title="Edit">
          <EditIcon style={{ cursor: "pointer", color: "orange" }} />
        </span>
      ),
      callback: (e) => {
        handleEdit(order.id);
      },
    },
  ];
};
