import React, { useEffect, useState, FC, forwardRef, useMemo } from "react";

import { withStyles, createStyles, Theme, WithStyles } from "@material-ui/core/styles";

import Button from "@material-ui/core/Button";
import LinearProgress from "@material-ui/core/LinearProgress";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";

import BigNumber from "bignumber.js";
import { TextField } from "final-form-material-ui";
import { Field, Form } from "react-final-form";
import ReactDatePicker from "react-datepicker";
import numeral from "numeral";

import format from "date-fns/format";
import toDate from "date-fns/toDate";

import { getWeekByDate } from "../../../lib/api/week";
import { producerfinalpaidheadGetLatestIdent } from "../../../lib/api/producerfinalpaidhead";
import { producerfinalpaidadhocGetAdHocRemainingValue } from "../../../lib/api/producerfinalpaidadhoc";
import { getCurrencyAllSortedMappedforCombo } from "../../../lib/api/currency";
import { TableFieldText } from "../../../lib/components/FormTableComponents";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      height: "100%",
      width: "100%",
      position: "relative",
    },
    container: {
      display: "grid",
      gridTemplateColumns: "repeat(1, 150px 350px)",
      gridTemplateRows: "repeat(4, 40px)",
      gridTemplateAreas: `
      "details"
      `,
      gridGap: "10px",
    },
    tableCellLabel: {
      width: "150px",
      borderBottom: "none",
      height: "50px",
      textAlign: "right",
      paddingTop: "5px",
    },
    tableCellLabelValue: {
      width: "350px",
      borderBottom: "none",
      height: "50px",
      textAlign: "left",
      paddingTop: "5px",
    },
    tableCellDetail: {
      width: "350px",
      borderBottom: "none",
      height: "50px",
    },
  });

type FormProps = {
  classes: any;
  producerId: string;
  selectedRowsDetail: any[];
  toggleClose(): void;
  handleReload(): Promise<void>;
  handleMakePayment(values: any, selectedAdHocs: any[]): Promise<void>;
};

const FormUnstyled: FC<FormProps> = ({ classes, selectedRowsDetail, toggleClose, handleMakePayment, handleReload, producerId }) => {
  const [paymentDate, setPaymentDate] = useState(new Date());
  const [ident, setIdent] = useState<string>("10");
  const [loading, setLoading] = useState<boolean>(false);
  const [addHocComboData, setAddHocComboData] = React.useState([]);
  const [_, setCurrencyComboData] = React.useState([]);
  const [selectedAdHocs, setSelectedAdHocs] = React.useState([]);

  const loadData = async () => {
    const res = await producerfinalpaidheadGetLatestIdent();
    const currencies = await getCurrencyAllSortedMappedforCombo();
    const adhocs = await producerfinalpaidadhocGetAdHocRemainingValue(producerId);

    const maxident = res.data[0].maxident;

    const comboD = adhocs.reduce((arr, item) => {
      if (item.remaining != 0) {
        const currencyCode = currencies.find((currency) => currency.value == item.currency_id).display;
        const display = `${numeral(item.remaining.toString()).format("0,0.00")} - ${currencyCode} - ${item.note || "N/A"}`;
        arr.push({ value: item.id, display, data: item });
      }
      return arr;
    }, []);

    setCurrencyComboData(currencies);
    setIdent(`PPF${maxident}`);
    setAddHocComboData(comboD.filter((combo) => combo));
  };

  useEffect(() => {
    loadData();
  }, []);

  const currency = useMemo(() => selectedRowsDetail[0].currency, [selectedRowsDetail]);

  const amount = useMemo(() => {
    return selectedRowsDetail.reduce((tot, row) => {
      tot = new BigNumber(tot).plus(new BigNumber(row.final)).toNumber();
      return tot;
    }, 0);
  }, [selectedRowsDetail]);

  return (
    <div className={classes.root}>
      {loading && <LinearProgress color={"secondary"} style={{ marginTop: "10px" }} />}
      <Form
        initialValues={{
          ident,
          amount,
          makeanote: "",
          currency_code: currency,
        }}
        onSubmit={async (values) => {
          setLoading(true);
          await handleMakePayment({ ...values, payment_date: paymentDate }, selectedAdHocs);
          setLoading(false);
          toggleClose();
          handleReload();
        }}
        render={({ handleSubmit, form }) => (
          <FormHead
            classes={classes}
            form={form}
            handleSubmit={handleSubmit}
            setPaymentDate={setPaymentDate}
            addHocComboData={addHocComboData}
            setSelectedAdHocs={setSelectedAdHocs}
            selectedAdHocs={selectedAdHocs}
            paymentDate={paymentDate}
            toggleClose={toggleClose}
          />
        )}
      />
    </div>
  );
};

const FormHead = ({ classes, handleSubmit, form, setPaymentDate, addHocComboData, setSelectedAdHocs, selectedAdHocs, paymentDate, toggleClose }) => {
  const handleChangeAdHoc = (event) => {
    const adhoc_ids = event.target.value; // returns an array of ids
    const selected = adhoc_ids.map((id) => addHocComboData.find((adhoc) => adhoc.value == id));
    setSelectedAdHocs(selected);
  };

  const handleParseAdHoc = (form, value) => {
    const calcAmount = parseInt(form.getState().values["pay_amount"]) - parseInt(value);
    form.change("pay_amountcalculated", numeral(calcAmount.toString()).format("0,0.00"));
    return value;
  };

  const changePaymentDate = (value, form) => {
    setPaymentDate(value);
  };

  useEffect(() => {
    const { adhoc, adhocRemainingValue } = selectedAdHocs.reduce(
      (obj, item) => {
        obj.adhoc = new BigNumber(obj.adhoc).plus(new BigNumber(item.data.remaining)).toNumber();
        obj.adhocRemainingValue = new BigNumber(obj.adhocRemainingValue).plus(new BigNumber(item.data.remaining)).toNumber();
        return obj;
      },
      { adhoc: 0, adhocRemainingValue: 0 },
    );
    form.change("adhoc", numeral(adhoc).format("0,0.00"));
    form.change("adhocRemainingValue", numeral(adhocRemainingValue).format("0,0.00"));
  }, [selectedAdHocs]);

  useEffect(() => {
    const values = form.getState().values;
    const amountCalculated = new BigNumber((values.adhoc || "").replaceAll(",", "")).plus(new BigNumber(values.amount)).toNumber();
    form.change("pay_amountcalculated", numeral(amountCalculated).format("0,0.00"));
  }, [form.getState().values.adhoc]);

  return (
    <form onSubmit={handleSubmit}>
      <div style={{ paddingBottom: "10px", paddingTop: "10px" }}>
        <Button name="submit" type="submit" variant="contained" color="primary" style={{ margin: "2px" }} disabled={false}>
          Make Payment
        </Button>
        <Button name="close" variant="contained" color="secondary" style={{ margin: "2px" }} disabled={false} onClick={toggleClose}>
          Close
        </Button>
      </div>
      <div className={classes.container}>
        <TableFieldText form={form} classes={classes} field="ident" title="Ident" disabled={false} handleParse={undefined} />
        <TableFieldLabel classes={classes} field={form.getState().values["currency_code"]} title="Currency" disabled={false} />
        <TableFieldCurrencyLabel classes={classes} field={form.getState().values["amount"]} title="Amount" disabled={false} />
        <MultiSelectCombo classes={classes} title={"Add Hoc Remaining"} data={addHocComboData} handleChange={handleChangeAdHoc} value={selectedAdHocs} />
        <TableFieldText form={form} classes={classes} field="adhoc" title="AdHoc Repayment" disabled={selectedAdHocs.length === 0} handleParse={handleParseAdHoc} />
        <TableFieldLabel classes={classes} field={form.getState().values["pay_amountcalculated"]} title="Actual Amount" disabled={true} />
        <TableFieldText form={form} classes={classes} field="makeanote" title="Note" disabled={false} handleParse={undefined} />
        <TableFieldDate classes={classes} form={form} dateValue={paymentDate} changeDate={changePaymentDate} title="Payment Date" />
        <TableFieldPaymentWeek classes={classes} title="Payment Week" date={paymentDate} />
      </div>
    </form>
  )
}

export default withStyles(styles)(FormUnstyled);

const TableFieldLabel: React.FunctionComponent<{ field: string; title: string; disabled: boolean } & WithStyles<typeof styles>> = (props) => {
  const { classes, field, title, disabled } = props;
  return (
    <>
      <span className={classes.tableCellLabel}>{`${title}:`}</span>
      <span className={classes.tableCellLabelValue}>{`${field}`}</span>
    </>
  );
};

const TableFieldCurrencyLabel: React.FunctionComponent<{ field: string; title: string; disabled: boolean } & WithStyles<typeof styles>> = (props) => {
  const { classes, field, title, disabled } = props;
  return (
    <>
      <span className={classes.tableCellLabel}>{`${title}:`}</span>
      <span className={classes.tableCellLabelValue} style={{ fontWeight: "bold" }}>{`${numeral(field).format("0,0.00")}`}</span>
    </>
  );
};

const TableFieldPaymentWeek: React.FunctionComponent<{ title: string; date: Date } & WithStyles<typeof styles>> = (props) => {
  const { classes, title, date } = props;

  const [paymentWeek, setPaymentWeek] = React.useState("");

  useEffect(() => {
    const formattedDate = format(date, "yyyy-MM-dd");

    getWeekByDate(formattedDate).then((weeks) => {
      if (weeks.length > 0) {
        setPaymentWeek(weeks[0].week);
      }
    });
  }, [date]);

  return (
    <>
      <span className={classes.tableCellLabel}>{`${title}:`}</span>
      <span className={classes.tableCellLabelValue}>{`${paymentWeek}`}</span>
    </>
  );
};

// const TableFieldText: React.FunctionComponent<{ field: string; title: string; disabled: boolean } & WithStyles<typeof styles>> = (props) => {
//   const { classes, field, title, disabled } = props;
//   return (
//     <>
//       <span className={classes.tableCellLabel}>{`${title}:`}</span>
//       <span className={classes.tableCellDetail}>
//         <Field name={field} component={TextField} type="text" fullWidth={true} disabled={disabled} />
//       </span>
//     </>
//   );
// };

const CalenderCustomInput = forwardRef((props: any, ref: any) => {
  return (
    <Button name="CalenderCustomInput" variant="contained" color="primary" onClick={props.onClick} style={{ marginTop: "5px", width: "250px" }}>
      {props.value}
    </Button>
  );
});

const TableFieldDate: React.FC<{ dateValue: Date; title: string; form: any; changeDate: (value, form) => void } & WithStyles<typeof styles>> = (props) => {
  const { classes, dateValue, title, changeDate, form } = props;
  return (
    <>
      <span className={classes.tableCellLabel}>{`${title}:`}</span>
      <span className={classes.tableCellDetail}>
        <ReactDatePicker
          locale="en-GB"
          showWeekNumbers={true}
          selected={toDate(dateValue)}
          onChange={(value) => {
            changeDate(value, form);
          }}
          dateFormat="dd-MM-yyyy"
          placeholderText="click here to select a date"
          customInput={<CalenderCustomInput />}
        />
      </span>
    </>
  );
};

const MultiSelectCombo = ({ classes, title, data, handleChange, value }) => {
  return (
    <>
      <span className={classes.tableCellLabel}>{title}:</span>
      <span className={classes.tableCellDetail}>
        <Select fullWidth multiple value={(value || []).map((item) => item.value)} onChange={handleChange}>
          {data &&
            data.map((item, index) => {
              return (
                <MenuItem key={`${item.value}${index}`} value={item.value}>
                  {item.display}
                </MenuItem>
              );
            })}
        </Select>
      </span>
    </>
  );
};