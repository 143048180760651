import React from "react";

import PrintMenu from "./instructionordersdocumentmenu";
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";

import { Link } from "react-router-dom";

import Button from "@material-ui/core/Button";
import Save from "@material-ui/icons/Save";
import Redeem from "@material-ui/icons/Redeem";
import Group from "@material-ui/icons/Group";
import ArrowBack from "@material-ui/icons/ArrowBack";
import ArrowDownward from "@material-ui/icons/ArrowDownward";

import { isNullOrUndef } from "../lib/helpers/isNullOrUndef";

export const OrderActions = withStyles((theme: Theme) =>
  createStyles({
    button: {
      margin: theme.spacing(1) / 2,
    },
    buttonTemplate: {
      margin: theme.spacing(1) / 2,
      backgroundColor: "orange",
      color: "white",
      '&:hover': {
        backgroundColor: "orange",
      },
    },
    rightIcon: {
      marginLeft: theme.spacing(1),
    },
  }),
)(({ handleTemplateOpen, handleCreateGroup, handleTogglePIHead, showPIHead, order, classes }: any) => {
  return (
    <div>
      <Button type="submit" variant="contained" color="primary" className={classes.button}>
        save
        <Save className={classes.rightIcon} />
      </Button>
      <Button variant="contained" color="primary" onClick={handleTemplateOpen} className={classes.button}>
        template
        <Redeem className={classes.rightIcon} />
      </Button>

      <PrintMenu orderId={order.id} />
      {(order.groupnumber == 0 || isNullOrUndef(order.groupnumber)) && (
        <Button variant="contained" color="primary" onClick={handleCreateGroup} className={classes.button}>
          create group
          <Group className={classes.rightIcon} />
        </Button>
      )}
      <Link to="/orders" style={{ textDecoration: "none" }}>
        <Button color="secondary" className={classes.button} variant="contained">
          back
          <ArrowBack className={classes.rightIcon} />
        </Button>
      </Link>

      <Button className={classes.buttonTemplate} variant="contained" onClick={handleTogglePIHead}>
        {showPIHead ?
          "hide form" : "show form"
        }
        <ArrowDownward className={classes.rightIcon} />
      </Button>
    </div>
  );
});
