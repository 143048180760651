import { client } from "./_base";

export type ProducerPHTypeType = {
  id: number;
  detail: string;
};

const endpoint = "producerphtype";

export const producersphtype = client(endpoint, (item) => ({
  ...item,
}));

export const getProducerPHTypeReadyForCombo = () => {
  return producersphtype.all().then((result: [ProducerPHTypeType]) => {
    const ready = result
      .sort((a, b) => {
        if (a.detail > b.detail) return 1;
        if (a.detail < b.detail) return -1;
        return 1;
      })
      .map((item) => {
        return { value: item.id, display: item.detail, data: item };
      });
    return ready;
  });
};
