import { read } from "fs";
import { CreditorsType } from "./creditors";
import { apiHeaders, client, request } from "./_base";

export type CreditorsInvoiceDetailItemTemplateType = {
  id?: number;
  creditors_id: number;
  templatedesc: string;
  default: number;
};

const endpoint = "creditorsinvoicedetailitemstemplate";

export const creditorsinvoicedetailitemstemplate = client(`${endpoint}`, (item) => ({
  ...item,
}));

export const creditorsinvoicedetailitemstemplateByCredDesc = async (creditors_id, desc) => {
  return await request(`${endpoint}/ext/byCreditorDesc/${creditors_id}/${desc}`)
    .then(async (result) => {
      if (result.status == 500) {
        const resp = await result.json();
        throw resp;
      }
      return result.json();
    })
    .then(({ data }) => data);
};

export const creditorsinvoicedetailitemstemplateAll = async () => {
  return await request(`${endpoint}/ext/full`)
    .then(async (result) => {
      if (result.status == 500) {
        const resp = await result.json();
        throw resp;
      }
      return result.json();
    })
    .then(({ data }) => data);
};

export const getDefaultCreditorsInvoiceDetailItemsTemplate = async (creditors_id) => {
  return await request(`${endpoint}/ext/getDefaultTemplateItemsByCreditor/${creditors_id}`)
    .then(async (result) => {
      if (result.status == 500) {
        const resp = await result.json();
        throw resp;
      }
      return result.json();
    })
    .then(({ data }) => data);
};

export const getItemsTemplateDescComboRead = async (creditors_id) => {
  return creditorsinvoicedetailitemstemplate.all().then((result: [CreditorsInvoiceDetailItemTemplateType]) => {
    const ready = [];
    result
      .sort((a, b) => a.templatedesc.localeCompare(b.templatedesc))
      .map((tempItem: CreditorsInvoiceDetailItemTemplateType) => {
        if (tempItem.creditors_id == creditors_id) {
          ready.push({ id: tempItem.id, value: tempItem.templatedesc, display: tempItem.templatedesc, data: tempItem });
        }
      });
    return ready;
  });
};

export const creditorsinvoicedetailtemplatesummary = async () => {
  return await request(`${endpoint}/ext/summary`)
    .then(async (result) => {
      if (result.status == 500) {
        const resp = await result.json();
        throw resp;
      }
      return result.json();
    })
    .then(({ data }) => data);
};

export const copycreditorsinvoicedetailtemplate = async (copyid) => {
  return await request(`${endpoint}/ext/copy/${copyid}`, {
    method: "POST",
  })
    .then(async (result) => {
      if (result.status == 500) {
        const resp = await result.json();
        throw resp;
      }
      return result.json();
    })
    .then(({ data }) => data);
};
