import { client, request, apiHeaders } from "./_base";

const endpoint = "debtorsfull";

export type DebtorsFullType = {
  forwardagentref: string;
  sale_id: number;
  sale_dispatch_id: number;
  dispatchdocs_id: number;
  sale_date: Date;
  sale_invoicenumber: string;
  sale_ucr: string;
  sale_trader_id: number;
  users_username: string;
  sale_terms_id: number;
  terms_code: string;
  adjustment_id: number;
  adjustment_paymentnumber: number;
  adjustment_total: number;
  sale_ata: Date;
  sale_notifyparty_id: number;
  clearingagentnotify_name: string;
  sale_payment1amount: number;
  sale_payment1amount_remain: number;
  sale_payment1date: Date;
  sale_payment1ValueType: string;
  sale_payment2amount: number;
  sale_payment2amount_remain: number;
  sale_payment2date: Date;
  sale_payment2ValueType: string;
  sale_payment3amount: number;
  sale_payment3amount_remain: number;
  sale_payment3date: Date;
  sale_payment3ValueType: string;
  sale_created_at: Date;
  sale_updated_at: Date;
  sale_exchangerate: number;
  sale_type: number;
  sale_clients_id: number;
  clients_code: string;
  clients_name: string;
  clients_sellingterms: number;
  sale_total: number;
  sale_totalfinal: number;
  sale_typename: string;
  salefundsallocation_amount: number;
  total_outstanding: number;
  document_type: string;
  document_typeapply: number;
  document_sourcetotal: number;
  dispatch_currency_id: number;
  currency_code: string;
  amountCredit: number;
  amountDebit: number;
  is_claim: number;
  dispatch_containerno: string;
  vessel_code: string;
  vessel_description: string;
  camend_id: number;
  camend_status: number;
  loadout_eta: string;
  loadout_updated_eta: string;
  loadout_etd: string;
  portdistcharge_id: number;
  portdistcharge_code: string;
  portdistcharge_description: string;
  accsalereceived: number;
  total_outstandingwithadjustments: number;
  adhoc_payment: number;
  payment_description: string;
  unallocated_payment: number;
  overpaid: number;
  salefundsoverpaid_id: number;
  overpaid_amount: number;
  sale_terms_value1: number;
  sale_terms_value2: number;
  sale_terms_value3: number;
  paymentnumber?: number;
  terms_received1: number;
  terms_received2: number;
  terms_received3: number;
  amountRemaining: number;
  salefunds_id: string;
  total_allocated: number;
  allocatedoverpaidsales_amount: number;
  paymentcolumn?: string;
  payment_type?: string;
};

export const debtorsfull = client(endpoint, (item) => ({
  ...item,
}));

export const getDebtorsFullByFinancialYear = (financialyear) => {
  return request(`${endpoint}/ex/fullByFinancialYear/${financialyear}`, {
    method: "GET",
  })
    .then(async (result) => {
      if (result.status == 500) {
        const resp = await result.json();
        throw resp;
      }
      return result.json();
    })
    .then(({ data }) => {
      return data;
    });
};

export const getDebtorsFullOustandingByClientsId = async (clientsid: number, financial_year: number) => {
  const result = await request(`${endpoint}/ex/oustandingByClientsID?clientsid=${clientsid}&financial_year=${financial_year}`, {
    method: "GET",
  });
  const resp = await result.json();
  if (result.status == 500) {
    throw resp;
  }
  return resp.data;
};

export const getDebtorsFullOustandingByClientsCodeDateRange = (clientscode: string, data): Promise<DebtorsFullType[]> => {
  const content = JSON.stringify(data);
  return request(`${endpoint}/ex/oustandingByClientsCodeDateRange/${clientscode}`, {
    method: "POST",
    headers: apiHeaders(content),
    body: content,
  })
    .then(async (result) => {
      if (result.status == 500) {
        const resp = await result.json();
        throw resp;
      }
      return result.json();
    })
    .then(({ data }) => {
      return data;
    });
};

export const getDebtorFullOustanding = async (financial_year: string) => {
  return await request(`${endpoint}/ex/getDebtorFullOustanding/${financial_year}`, {
    method: "GET",
  })
    .then(async (result) => {
      if (result.status == 500) {
        const resp = await result.json();
        throw resp;
      }
      return result.json();
    })
    .then(({ data }) => {
      return data;
    });
};

export type UnallocatedFundsType = {
  clients_code: string;
  sale_date: Date;
  sale_amount: number;
  payment_description: string;
  available: number;
};

export const getUnallocatedFunds = async (clients_id: number, dateFrom: string, dateTo: string): Promise<UnallocatedFundsType[]> => {
  const result = await request(`${endpoint}/ext/getUnallocatedFunds?clients_id=${clients_id}&dateFrom=${dateFrom}&dateTo=${dateTo}`, {
    method: "GET",
  });
  const resp = await result.json();
  if (result.status == 500) {
    throw resp;
  }
  return resp.data;
};

export const downloadDebtorFundsAllocation = async (allocationData: any[], fileName: string, clientcode: string) => {
  const content = JSON.stringify({ allocationData, fileName, clientcode });
  const result = await request(`${endpoint}/ext/downloadDebtorFundsAllocation`, {
    method: "POST",
    headers: apiHeaders(content),
    body: content,
  });

  if (result.status == 500) {
    const resp = await result.json();
    throw resp;
  }

  const blob = await result.blob();
  let url = window.URL.createObjectURL(blob);
  let a = document.createElement("a");
  a.href = url;
  a.download = fileName;
  a.click();
};

export type DebtorsLinked = {
  sale_id: number;
  sale_invoicenumber: string;
  forwardagentref: string;
  currency_code: string;
  sale_total: number;
  amountCredit: number;
  amountDebit: number;
  sale_payment1date: Date;
  sale_payment1amount: number;
  sale_payment1amount_remain: number;
  sale_payment2date: Date;
  sale_payment2amount: number;
  sale_payment2amount_remain: number;
  sale_payment3date: Date;
  sale_payment3amount: number;
  sale_payment3amount_remain: number;
  salefundsallocation_amount: number;
  total_outstandingwithadjustments: number;
  overpaid: number;
  adhoc_adjustment: number;
  rowDirty?: number;
  saleadjustmentadhoc_id: number;
  noCartons: number;
  palletSize: number;
};

export const getDebtorsLinked = async (clientsid: number, financial_year: number): Promise<DebtorsLinked[]> => {
  const result = await request(`${endpoint}/ex/debtorsLinked?clientsid=${clientsid}&financial_year=${financial_year}`, {
    method: "GET",
  });
  const resp = await result.json();
  if (result.status == 500) {
    throw resp;
  }
  return resp.data;
};

export const getDebtorsLinkedByAdhocId = async (adhoc_id: number): Promise<DebtorsLinked[]> => {
  const result = await request(`${endpoint}/ex/debtorsLinkedByAdhocId?adhoc_id=${adhoc_id}`, {
    method: "GET",
  });
  const resp = await result.json();
  if (result.status == 500) {
    throw resp;
  }
  return resp.data;
};
