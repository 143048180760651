import { image as logoImage } from "./elements/logo";
import { PTSans } from "./elements/font";

import { get } from "./helpers";

import jsPDF from "jspdf";
import numeral from "numeral";
import autoTable from "jspdf-autotable";
import format from "date-fns/format";

import { companyDetails } from "../lib/api/companydetails";
import { getCompanyBankingDetailById } from "../lib/api/companydetailsbanking";
import { clientsfinanceByClientID } from "../lib/api/clientsfinance";

let pdf;
let page = 1;
let pages: number[];

let fromTop = 1;
let lastLineWritten = fromTop;
let fromLeft = 9.0;
let fullWidth = 0;

const lineHeight = 0.4;

let headerColumn1 = 0,
  headerColumn2 = 0,
  headerColumn3 = 0;

const updateHeaderCols = () => {
  headerColumn1 = 1.2;
  headerColumn2 = fullWidth / 2 - 1;
  headerColumn3 = fullWidth - 3.6;
};

const InitialisePDF = () => {
  pdf = new jsPDF({
    orientation: "portrait",
    unit: "cm",
  });

  pdf.addFileToVFS("PTSans.ttf", PTSans);
  pdf.addFont("PTSans.ttf", "PTSans", "normal");

  fullWidth = pdf.internal.pageSize.getWidth();
  updateHeaderCols();

  pdf.setFont("PTSans");
  page = 1;

  fromTop = 1;
  fromLeft = headerColumn1;

  pdf.setLineWidth(0.01);
  pages = [];
};

const newPage = () => {
  pdf.addPage();
  if (page == 1) {
    pages = [1];
  }
  page = page + 1;
  pages.push(page);
  pdf.setPage(page);

  fromTop = 1;
  fromLeft = headerColumn1;
};

const newLine = (howFar: number = 0.5) => {
  fromTop += howFar;
};

const printLine = (label, value, currentX, currentY) => {
  wrapBold(() => pdf.text(currentX, currentY, label, null, null, "right"));
  pdf.text(currentX, currentY, value ? value.toString() : "", null, null, "left");
};

const setFontBold = () => {
  pdf.setFontStyle("bold");
  pdf.setFont("helvetica"); // set font
};

const setFontNormal = () => {
  pdf.setFontStyle("normal");
  pdf.setFont("PTSans"); // set font
};

export const handlePrintPDF = async (documentDetail, adhocSummary) => {
  InitialisePDF();

  const detail = documentDetail.find((detail) => detail.id == adhocSummary[0].adhoc_invoice_id || "");
  const [details, clientsFinance] = await Promise.all([companyDetails.all(), clientsfinanceByClientID(detail.clients_id)]);
  const finance = (clientsFinance.data || []).length > 0 ? clientsFinance.data[0] : undefined;

  if (!finance || !finance.statement_account_id) {
    throw { data: "No banking detail attached to client" };
  }

  const bankResult = await getCompanyBankingDetailById(finance.statement_account_id);

  if ((bankResult.data || []).length === 0) {
    throw { data: "Banking detail not found" };
  }

  printDocumentDetail(details[0]);
  wrapBorder(() => printAdhocSummary(detail), "");
  wrapBorder(() => printAdhocDetails(adhocSummary), "");
  wrapBorder(() => printBankDetails(bankResult.data[0]), "");

  pdf.save(`${detail.invoice_number}_ADHOC_CLIENT_INVOICE.pdf`);
  // return pdf.output("datauristring");
};

const printDocumentDetail = (companyDetails) => {
  printHeader(companyDetails);
  printHeading("AD HOC INVOICE");
};

const printHeading = (headingText) => {
  fromLeft = headerColumn2 - 1.8;
  pdf.setFontSize(15);
  wrapBold(() => pdf.text(fromLeft, fromTop, headingText, "left"));
  pdf.setFontSize(9);
};

const printHeader = (companyDetails) => {
  const setAddressLine = (property, companyDetails) => {
    newLine(0.4);
    pdf.text(get(property, companyDetails, ""), fromLeft, fromTop);
  };
  fromTop = 1;
  fromLeft = headerColumn1;

  pdf.setFontSize(11);
  wrapBold(() => pdf.text(get("name", companyDetails, ""), fromLeft, fromTop, "left"));
  pdf.setFontSize(8);

  setAddressLine("address2", companyDetails);
  setAddressLine("address3", companyDetails);
  setAddressLine("address4", companyDetails);
  setAddressLine("address5", companyDetails);

  fromLeft = headerColumn2;
  const imageWidth = 5;
  const imageHeight = 1.5;
  pdf.addImage(logoImage, fromLeft - 2, 1, imageWidth, imageHeight);
  fromLeft = headerColumn3 - 1;
  fromTop = 1;

  wrapBold(() => pdf.text("Tel No:", fromLeft, fromTop, "right"));
  pdf.text(get("telephoneNumber", companyDetails, ""), fromLeft + 0.2, fromTop, "left");

  newLine(lineHeight);
  wrapBold(() => pdf.text("Fax No:", fromLeft, fromTop, "right"));
  pdf.text(get("faxNumber", companyDetails, ""), fromLeft + 0.2, fromTop, "left");

  newLine(lineHeight);
  wrapBold(() => pdf.text("E-mail:", fromLeft, fromTop, "right"));
  pdf.text(get("email", companyDetails, ""), fromLeft + 0.2, fromTop, "left");

  newLine(lineHeight);
  wrapBold(() => pdf.text("Vat No:", fromLeft, fromTop, "right"));
  pdf.text(get("vatno", companyDetails, ""), fromLeft + 0.2, fromTop, "left");

  newLine(lineHeight);
  wrapBold(() => pdf.text("Reg No:", fromLeft, fromTop, "right"));
  pdf.text(get("regno", companyDetails, ""), fromLeft + 0.2, fromTop, "left");

  newLine(lineHeight);
  wrapBold(() => pdf.text("Customs Code:", fromLeft, fromTop, "right"));
  pdf.text(get("customscode", companyDetails, ""), fromLeft + 0.2, fromTop, "left");
};

const wrapBold = (fn, ...args) => {
  setFontBold();
  fn(...args);
  setFontNormal();
};

const wrapBorder = (fn, ...args) => {
  const startRect = fromTop;
  fromTop += lineHeight * 2;
  fn(...args);
  pdf.rect(1, startRect + 0.6, fullWidth - 2, fromTop - startRect - 0.85, "S");
};

const printAdhocSummary = (detail) => {
  fromLeft = headerColumn1 + 4;
  newLine(0.25);

  const START_Y = fromTop;
  printLine("Date: ", format(new Date(detail.date), "dd MMM yyyy") || "", fromLeft, fromTop);
  newLine(lineHeight);
  printLine("Invoice No: ", detail.invoice_number || "", fromLeft, fromTop);
  newLine(lineHeight);
  printLine("Client Order No: ", detail.client_orderno || "", fromLeft, fromTop);
  newLine(lineHeight);
  printLine("Reference: ", detail.reference || "", fromLeft, fromTop);

  fromLeft = headerColumn2 + 2;
  fromTop = START_Y;

  printLine("Issued To: ", detail.clients_name || "", fromLeft, fromTop);
  newLine(lineHeight);
  for (let addressDetail of detail.clients_address.split(", ")) {
    printLine("", addressDetail || "", fromLeft, fromTop);
    newLine(lineHeight);
  }

  printLine("Tel No: ", detail.clients_telephone || "", fromLeft, fromTop);
  newLine(lineHeight);
  printLine("Email: ", detail.clients_email || "", fromLeft, fromTop);

  newLine();
};

const printAdhocDetails = (adhocSummary) => {
  lastLineWritten = fromTop;

  const statementHeaders = [
    { dataKey: "description", header: "DESCRIPTION" },
    { dataKey: "unit_price", header: "UNIT PRICE" },
    { dataKey: "quantity", header: "QUANTITY" },
    { dataKey: "currency_code", header: "" },
    { dataKey: "sub_total", header: "SUBTOTAL" },
  ];

  const formattedData = adhocSummary.map((item) => ({
    ...item,
    unit_price: numeral(item.unit_price).format("0,0.00"),
    sub_total: numeral(item.sub_total).format("0,0.00"),
    currency_code: "",
  }));

  const totals = adhocSummary.reduce(
    (acc, curr) => {
      acc.unit_price += curr.unit_price;
      acc.quantity += curr.quantity;
      acc.sub_total += curr.sub_total;
      acc.currency_code = curr.currency_code;
      return acc;
    },
    {
      unit_price: 0,
      quantity: 0,
      sub_total: 0,
      currency_code: "",
    },
  );

  autoTable(pdf, {
    theme: "plain",
    margin: [1, 1, 1, 1],
    startY: lastLineWritten,
    columns: statementHeaders,
    body: formattedData,
    showFoot: "lastPage",
    tableLineColor: [0, 0, 0],
    styles: { fontSize: 8, textColor: [0, 0, 0] },
    columnStyles: {
      description: { halign: "left", cellWidth: 11 },
      unit_price: { halign: "right" },
      quantity: { halign: "center" },
      currency_code: { halign: "center" },
      sub_total: { halign: "right" },
    },
    headStyles: { fontStyle: "bold", fontSize: 9 },
    bodyStyles: { lineColor: [0, 0, 0], fontSize: 9 },
    footStyles: { textColor: [0, 0, 0], fontSize: 9, fontStyle: "bold" },
    foot: [
      {
        description: "TOTALS",
        unit_price: numeral(totals.unit_price).format("0,0.00"),
        quantity: totals.quantity,
        currency_code: totals.currency_code,
        sub_total: numeral(totals.sub_total).format("0,0.00"),
      },
    ],
    didDrawPage: (data) => {
      lastLineWritten = data.cursor.y;
      fromTop = data.cursor.y;

      if (data.pageNumber > 1) {
        page = data.pageNumber;
        pages.push(data.pageNumber);
        pdf.setPage(data.pageNumber);
      }
    },
    willDrawCell: (data) => {
      // align columns
      if (data.column.dataKey == "sub_total" || data.column.dataKey == "unit_price") {
        data.cell.styles.halign = "right";
      } else if (data.column.dataKey == "quantity" || data.column.dataKey == "currency_code") {
        data.cell.styles.halign = "center";
      }

      // underline column name
      if (data.section === "head" && data.column.dataKey !== "currency_code") {
        const { x, y, raw, width, height } = data.cell;
        const textWidth = pdf.getTextDimensions(raw).w;

        pdf.setLineWidth(0.01);
        pdf.setDrawColor(0, 0, 0);

        if (data.column.dataKey == "description") {
          pdf.line(x + 0.15, y + (height - 0.15), x + textWidth + 0.25, y + (height - 0.15), "S");
        } else if (data.column.dataKey == "quantity") {
          pdf.line(x + (width - textWidth) - 0.4, y + (height - 0.15), x + (width - textWidth) + textWidth - 0.3, y + (height - 0.15), "S");
        } else {
          pdf.line(x + (width - textWidth) - 0.2, y + (height - 0.15), x + (width - textWidth) + textWidth - 0.1, y + (height - 0.15), "S");
        }
      }

      if (data.row.section === "foot") {
        data.row.height = 0.45;
        data.cell.y += 0.075;
      } else {
        data.row.height = 0.5;
      }
    },
  });

  // Line above total line
  pdf.setFillColor("#000");
  pdf.setLineWidth(0.001);
  pdf.rect(1, fromTop - 0.35, fullWidth - 2, 0.001, "S");

  newLine();
};

const bankDetailNewLine = () => {
  fromLeft = 5.5;
  newLine();
};

const bankDetailPrintText = (text, position, fromLeftAdd) => {
  if (position === "right") {
    bankDetailNewLine();
    setFontBold();
    pdf.text(text, fromLeft + fromLeftAdd, fromTop, position);
  } else {
    setFontNormal();
    pdf.text(text, fromLeft + fromLeftAdd + 0.2, fromTop, position);
  }
};

const printBankDetails = (bankDetails) => {
  fromLeft = 1;
  lastLineWritten = fromTop;

  if (lastLineWritten >= 24) {
    newPage();
    fromTop = 2;
  } else {
    fromTop = lastLineWritten;
  }

  fromTop += 0.25;

  pdf.setFontSize(10);
  setFontBold();
  pdf.text("BANKING DETAILS ", fromLeft + 0.25, fromTop, "left");
  pdf.rect(fromLeft + 0.25, fromTop + 0.1, pdf.getTextDimensions("BANKING DETAILS").w, 0.01, "S");
  pdf.setFontSize(8);

  bankDetailPrintText("ACCOUNT HOLDER: ", "right", 0);
  bankDetailPrintText(!bankDetails || !bankDetails.accountholder ? "" : bankDetails.accountholder.toString(), "left", 0);

  bankDetailPrintText("ACCOUNT HOLDER ADDRESS: ", "right", 0);
  bankDetailPrintText(!bankDetails || !bankDetails.accountholderaddress ? "" : bankDetails.accountholderaddress.toString(), "left", 0);

  bankDetailPrintText("BANK: ", "right", 0);
  bankDetailPrintText(!bankDetails || !bankDetails.bank ? "" : bankDetails.bank.toString(), "left", 0);

  bankDetailPrintText("BANK ADDRESS: ", "right", 0);
  bankDetailPrintText(!bankDetails || !bankDetails.bankaddress ? "" : bankDetails.bankaddress.toString(), "left", 0);

  bankDetailPrintText("BRANCH CODE: ", "right", 0);
  bankDetailPrintText(!bankDetails || !bankDetails.branchcode ? "" : bankDetails.branchcode.toString(), "left", 0);

  bankDetailPrintText("ACCOUNT TYPE: ", "right", 0);
  bankDetailPrintText(!bankDetails || !bankDetails.accounttype ? "" : bankDetails.accounttype.toString(), "left", 0);

  bankDetailPrintText("ACCOUNT NUMBER: ", "right", 0);
  bankDetailPrintText(!bankDetails || !bankDetails.accountnumber ? "" : bankDetails.accountnumber.toString(), "left", 0);

  bankDetailPrintText("SWIFT CODE: ", "right", 0);
  bankDetailPrintText(!bankDetails || !bankDetails.swiftcode ? "" : bankDetails.swiftcode.toString(), "left", 0);

  fromLeft = 1;
  lastLineWritten = fromTop;
  newLine();
};
