import { client, request } from "./_base";

const endpoint = "clientsContractsCounts";

export const clientsContractsCounts = client(endpoint, (item) => ({
  ...item,
}));

export const clientscontractsCountsFull = (clientscontractid) => {
  return request(`${endpoint}/ext/full/${clientscontractid}`, {
    method: "GET",
  })
    .then(async (result) => {
      if (result.status == 500) {
        const resp = await result.json();
        throw resp;
      }
      return result.json();
    })
    .then(({ data }) => {
      return data;
    });
};
