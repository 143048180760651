import React, { useEffect } from "react";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";

import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import CircularProgress from "@material-ui/core/CircularProgress";

import { format } from "date-fns";
import parseISO from "date-fns/parseISO";

import { arrivaldataByContainerProducerSalesRef } from "../../lib/api/arrivaldata";

import { SnackContext } from "../../lib/context/SnackContext";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1),
      width: "830px",
      height: "flex",
      marginTop: "-10px",
    },
    header: {
      height: "250px",
      width: "830px",
      marginLeft: "-8px",
      padding: theme.spacing(1) * 2,
    },
  });

type ArrivalsDetailHeaderProps = {
  container: string;
  producerid: string;
  salesref: string;
} & WithStyles<typeof styles>;

const ArrivalsDetailHeaderUnstyled: React.SFC<ArrivalsDetailHeaderProps> = (props) => {
  const { classes, container, producerid, salesref } = props;
  const [arrivalData, setArrivalData] = React.useState({
    producer: "",
    portofdischargedesc: "",
    farmnumber: "",
    producerid: "",
    vessel: "",
    salesref: "",
    ata: "",
    phc: "",
    farmnumberfull: "",
  });
  const [loading, setLoading] = React.useState(false);
  const { updateSnack } = React.useContext(SnackContext);

  useEffect(() => {
    loadData();
  }, [container, producerid]);

  const loadData = () => {
    setLoading(true);
    arrivaldataByContainerProducerSalesRef(container ? container : "0", producerid, salesref)
      .then((result) => {
        const dataSorted = result.sort((a, b) => {
          if (a.intakepalletid > b.intakepalletid) return 1;
          if (a.intakepalletid < b.intakepalletid) return -1;
          return 1;
        });
        if (dataSorted.length > 0) {
          setArrivalData(dataSorted[0]);
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        const message = err.data || "Error loading Data.";
        updateSnack({ show: true, color: "red", message: err });
      });
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.header}>
        {loading && <CircularProgress />}
        {!loading && (
          <table>
            <tbody>
              {headerRowDetail("grower", arrivalData.producer)}
              {headerRowDetail("arrival date", arrivalData.ata ? format(parseISO(arrivalData.ata), "dd MMM yyyy") : "N/A")}
              {headerRowDetail("packhouse code", arrivalData.phc)}
              {headerRowDetail("farm number", arrivalData.farmnumberfull)}
              {headerRowDetail("destination", arrivalData.portofdischargedesc)}
              {headerRowDetail("vessel", arrivalData.vessel)}
              {headerRowDetail("container number", container)}
              {headerRowDetail("consignment ref", arrivalData.salesref)}
            </tbody>
          </table>
        )}
      </Paper>
    </div>
  );
};

export default withStyles(styles)(ArrivalsDetailHeaderUnstyled);

const headerRowDetail = (label: string, value: string) => {
  return (
    <tr>
      <td align="right" style={{ paddingRight: "10px", verticalAlign: "right" }}>
        <Typography variant="subtitle1" style={{ fontWeight: "bold", fontSize: "1.15em" }}>
          {`${label.toUpperCase()}: `}{" "}
        </Typography>
      </td>
      <td>
        <Typography variant="subtitle2">{value}</Typography>
      </td>
    </tr>
  );
};
