import React from "react";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";

import Tooltip from "@material-ui/core/Tooltip";
import Button from "@material-ui/core/Button";

import Close from "@material-ui/icons/Close";
import IconAdd from "@material-ui/icons/Add";

import CreditorsItemsTemplate from "../template/creditorsitemtemplate";

import Confirmation from "../../../lib/components/confirmation";
import Grid from "../../../lib/components/grid";

import {
  creditorsinvoicedetailitemstemplate,
  creditorsinvoicedetailtemplatesummary,
  copycreditorsinvoicedetailtemplate,
} from "../../../lib/api/creditorsinvoicedetailitemstemplate";

import { GridColumns } from "./creditorstemplatesummarysetup";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1),
      width: "100%",
      height: "100%",
      position: "relative",
    },
    inline: {
      display: "inline",
      position: "absolute",
      marginTop: "7px",
      marginLeft: "7px",
    },
    closeIcon: {
      display: "flex",
      justifyContent: "flex-end",
      margin: "10px 0px -5px",
      position: "fixed",
      top: "10px",
      right: "15px",
    },
  });

type CreditorsTemplateSummaryProps = { handleOnClose?: any } & WithStyles<typeof styles>;

export class CreditorsTemplateSummaryUnstyled extends React.Component<CreditorsTemplateSummaryProps, any> {
  state = {
    classes: undefined,
    data: [],
    confirmRemoveID: undefined,
    loading: true,
    handleOnClose: undefined,
    redirectCreate: false,
    confirmTemplateId: undefined,
    confirmCopyId: undefined,
  };

  constructor(props) {
    super(props);
    this.state.classes = props.classes;
    this.state.handleOnClose = props.handleOnClose;
  }

  componentDidMount() {
    this.loadData();
  }

  loadData = () => {
    creditorsinvoicedetailtemplatesummary().then((data) => {
      const dataSorted = data.sort((a, b) => {
        if (a.creditorcode > b.creditorcode) return 1;
        if (a.creditorcode < b.creditorcode) return -1;
        return 1;
      });
      this.setState({ loading: false, data: dataSorted });
    });
  };

  handleRemove = async (id) => {
    this.setState({ confirmRemoveID: id });
  };

  handleRemoveClose = () => {
    this.setState({ confirmRemoveID: undefined });
  };

  handleRemoveConfirm = async () => {
    await creditorsinvoicedetailitemstemplate.remove(this.state.confirmRemoveID).then(() => {
      this.handleRemoveClose();
      this.loadData();
    });
  };

  handleCreate = () => {
    this.setState({ redirectCreate: true });
  };

  handleEdit = (selectedId: number) => {
    this.setState({ confirmTemplateId: selectedId });
  };

  handleEditClose = () => {
    this.setState({ confirmTemplateId: undefined, redirectCreate: false }, () => {
      this.loadData();
    });
  };

  handleCopy = (id) => {
    this.setState({ confirmCopyId: id });
  };

  handleCopyClose = (confirm: boolean = false) => {
    if (confirm) {
      copycreditorsinvoicedetailtemplate(this.state.confirmCopyId).then(() => {
        this.loadData();
      });
    }
    this.setState({ confirmCopyId: undefined });
  };

  render() {
    const { classes } = this.state;

    return (
      <div className={classes.root}>
        {this.state.confirmCopyId && (
          <Confirmation
            isOpen={true}
            handleClose={() => {
              this.handleCopyClose(false);
            }}
            handleConfirm={() => {
              this.handleCopyClose(true);
            }}
            title={`Copy Template`}
            body={`Are you sure you want to copy the template`}
          ></Confirmation>
        )}
        {this.state.redirectCreate && (
          <div>
            {/* <div style={{ position: "absolute", right: "10px", top: "10px" }}>
              <Button color="secondary" variant="outlined" onClick={this.handleEditClose}>
                Back
              </Button>
            </div> */}
            <CreditorsItemsTemplate handleClose={this.handleEditClose} />
          </div>
        )}
        {this.state.confirmTemplateId && (
          <div>
            {/* <div style={{ position: "absolute", right: "10px", top: "10px" }}>
              <Button color="secondary" variant="outlined" onClick={this.handleEditClose}>
                Back
              </Button>
            </div> */}
            <CreditorsItemsTemplate id={this.state.confirmTemplateId} handleClose={this.handleEditClose} />
          </div>
        )}
        {!this.state.confirmTemplateId && !this.state.redirectCreate && (
          <>
            <div className={classes.inline}>
              {this.state.handleOnClose && (
                <div className={classes.closeIcon}>
                  <Button style={{ marginRight: "10px", backgroundColor: "white" }} color="secondary" variant="outlined" onClick={this.state.handleOnClose}>
                    <Close />
                  </Button>
                </div>
              )}
              <Tooltip title="Create new Creditors">
                <div className={classes.inline}>
                  <Button variant="contained" color="primary" onClick={this.handleCreate} style={{ marginBottom: "10px" }}>
                    <IconAdd />
                  </Button>
                </div>
              </Tooltip>
            </div>
            <Grid
              loading={this.state.loading}
              data={this.state.data}
              clearFilters={"creditorstemplatesummary"}
              GridColumns={(data, filters, arrangement, columnsWidth) => GridColumns(data, filters, arrangement, columnsWidth, this.handleRemove, this.handleEdit, this.handleCopy)}
            />
          </>
        )}
        {this.state.confirmRemoveID && (
          <Confirmation
            isOpen={this.state.confirmRemoveID ? true : false}
            handleClose={this.handleRemoveClose}
            handleConfirm={this.handleRemoveConfirm}
            title="Remove selected Template?"
            body="Are you sure you want to REMOVE the selected Template Record?"
          ></Confirmation>
        )}
      </div>
    );
  }
}

export default withStyles(styles)(CreditorsTemplateSummaryUnstyled);
