import { config } from "../../config";

export const apiHeaders = (content: { length: number } = { length: 0 }, options: {} = {}) =>
  new Headers({
    Accept: "application/json",
    "Content-Length": content.length + "",
    "Content-Type": "application/json",
    "X-csrf-validation": "-",
    ...options,
  });

export const request = (path: string, options: RequestInit = {}) => {
  return fetch(`${config.API_ADDRESS}/api/${path}`, {
    headers: apiHeaders((options.body as any) || ""),
    ...options,
    credentials: "include",
  });
};

export const all =
  <T = any>(endpoint: String, transform = (item: T) => item) =>
  async (): Promise<T[]> => {
    const result = await request(`${endpoint}`, {
      headers: apiHeaders(),
    });
    const resp = await result.json();
    if (result.status == 500) {
      throw resp;
    }
    return resp.data;
  };

export const single =
  <T = any>(endpoint: String, transform = (item: T) => item) =>
  async (id: any): Promise<T> => {
    const result = await request(`${endpoint}/${id}`, {
      method: "GET",
    });
    const resp = await result.json();
    if (result.status == 500) {
      throw resp;
    }
    return resp.data;
  };

export const create =
  <T = any>(endpoint: String, transform = (item: T) => item) =>
  async (item: DataObject<T>) => {
    const content = JSON.stringify(item);
    const result = await request(`${endpoint}`, {
      method: "POST",
      headers: apiHeaders(content),
      body: content,
    });
    const resp = await result.json();
    if (result.status == 500) {
      throw resp;
    }
    return resp.data;
  };

type DataObject<T> = {
  data: T;
};

export const update =
  <T = any>(endpoint: String, transform = (item: T) => item) =>
  async (id: any, item: DataObject<T>) => {
    const content = JSON.stringify(item);
    const result = await request(`${endpoint}/${id}`, {
      method: "PUT",
      headers: apiHeaders(content),
      body: content,
    });
    const resp = await result.json();
    if (result.status == 500) {
      throw resp;
    }
    return resp.data;
  };

export const remove =
  <T = any>(endpoint: String, transform = (item: T) => item) =>
  async (id: any) => {
    const result = await request(`${endpoint}/${id}`, {
      method: "DELETE",
    });
    const resp = await result.json();
    if (result.status == 500) {
      throw resp;
    }
    return resp.data;
  };

export const client = <T = any>(endpoint: string, transform = (item: T) => item) => ({
  all: all<T>(endpoint, transform),
  single: single<T>(endpoint, transform),
  update: update<T>(endpoint, transform),
  create: create<T>(endpoint, transform),
  remove: remove<T>(endpoint, transform),
});

export const login = async (fields: { username: string; password: string }) => {
  const data = new FormData();
  data.set("username", fields.username);
  data.set("password", fields.password);
  const response = await request(`login`, {
    method: "POST",
    body: JSON.stringify(fields),
  });
  return response.json();
};

export const logout = async () => {
  const response = await request("logout", {
    method: "GET",
  });
  return response.json();
};

export const whoami = async () => {
  const response = await request("whoami", {
    method: "GET",
  });
  return response.json();
};

export const useApiGet = async <T>(url: string): Promise<T> => {
  try {
    const result = await request(url, {
      method: "GET",
    });
    const resp = await result.json();
    if (result.status == 500) {
      throw new Error(resp);
    }
    return resp.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};
