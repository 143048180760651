import React from "react";

import { createStyles, withStyles, Theme, WithStyles } from "@material-ui/core/styles";

import MaintenanceContent from "../layout/MaintenanceContent";

const drawerWidth = 180;

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      overflow: "hidden",
      width: "100%",
    },
    menu: {
      width: drawerWidth,
      float: "left",
    },
    content: {
      paddingLeft: theme.spacing(1) * 2,
      overflowY: "hidden",
      width: "100%",
    },
  });

class Maintenance extends React.Component<WithStyles<typeof styles>> {
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <div className={classes.content}>
          <MaintenanceContent />
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(Maintenance);
