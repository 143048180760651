import React, { useEffect, forwardRef, useState } from "react";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";

import Checkbox from "@material-ui/core/Checkbox";
import LinearProgress from "@material-ui/core/LinearProgress";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import MenuItem from "@material-ui/core/MenuItem";
import RadioGroup from "@material-ui/core/RadioGroup";

import { DebtorsFullType } from "../../../lib/api/debtorsfull";
import { getSaleAdjustmentApplyTypeReadyForCombo } from "../../../lib/api/saleadjustmenttypeapply";
import { adjustmentDetailBySaleId, getSaleAdjustmentBySaleId, getSaleAdjustmentBySaleIdType } from "../../../lib/api/saleadjustmentdetail";

import AdjustmentGrid from "./adjustmentgrid";

import { Field, Form } from "react-final-form";
import { TextField, Select, Radio } from "final-form-material-ui";

import numeral from "numeral";
import toDate from "date-fns/toDate";

import ReactDatePicker from "react-datepicker";
import BigNumber from "bignumber.js";
import { isNullOrUndef } from "../../../lib/helpers/isNullOrUndef";
import { SnackContext } from "../../../lib/context/SnackContext";
import { GenerateErrorMessage } from "../../../lib/helpers/string_methods";
import Confirmation from "../../../lib/components/confirmation";
import { FormApi } from "final-form";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1),
      width: "600px",
      height: "100%",
    },
    divActions: {
      float: "right",
    },
    container: {
      display: "grid",
      gridTemplateColumns: "repeat(1, 150px 350px)",
      gridTemplateRows: "repeat(4, 40px)",
      gridTemplateAreas: `
      "details"
      `,
      gridGap: "10px",
    },
    tableCellLabel: {
      width: "150px",
      borderBottom: "none",
      height: "50px",
      textAlign: "right",
      paddingTop: "5px",
    },
    tableCellLabelValue: {
      width: "350px",
      borderBottom: "none",
      height: "50px",
      textAlign: "left",
      paddingTop: "5px",
    },
    tableCellDetail: {
      width: "350px",
      borderBottom: "none",
      height: "50px",
    },
    tableCellsValueSelect: {
      textAlign: "left",
      width: "350px",
      borderBottom: "none",
      height: "50px",
      padding: 0,
      marginTop: "-14px",
    },
    tableCellDetailDate: {
      marginTop: "-5px",
      width: "350px",
      borderBottom: "none",
      height: "50px",
    },
  });

enum DocumentType {
  "DebitCredit" = -99,
}

type AdjustmentProps = {
  selectedRecord: DebtorsFullType;
  handleProcess: (data, detail) => Promise<void>;
} & WithStyles<typeof styles>;

const AdjustmentUnstyled: React.FunctionComponent<AdjustmentProps> = (props) => {
  const { classes, selectedRecord, handleProcess } = props;

  const [loading, setLoading] = useState(true);
  const [applyType, setApplyType] = useState(getSaleAdjustmentApplyTypeReadyForCombo());
  const [saleDate, setSaleDate] = useState(selectedRecord.dispatchdocs_id == -99 ? new Date(selectedRecord.sale_date) : new Date());
  const [detail, setDetail] = useState([]);
  const [detailGrouped, setDetailGrouped] = useState([]);
  const [isClaim, setIsClaim] = useState<boolean>(selectedRecord.is_claim == 1);
  const [isAccountSale, setIsAccountSale] = useState<boolean>(selectedRecord.accsalereceived == 1);
  const [exitConfirmation, setExitConfirmation] = useState<boolean>(false);

  const { updateSnack } = React.useContext(SnackContext);

  const getGroupedFruitSpec = (arr) => {
    const keyProps = ["commodityCode", "varietyCode", "packCode", "markCode", "gradeCode", "countCode"];
    const kvArray = arr.map((entry) => {
      const key = keyProps.map((k) => entry[k]).join("|");
      return [key, entry];
    });

    const map = new Map(kvArray);
    const mapArr = Array.from(map.values());
    const mapArrNew = mapArr.map((item) => {
      item["new_palletsize"] = arr.reduce((acc, curr) => {
        if (
          curr["commodityCode"] == item["commodityCode"] &&
          curr["varietyCode"] == item["varietyCode"] &&
          curr["packCode"] == item["packCode"] &&
          curr["markCode"] == item["markCode"] &&
          curr["gradeCode"] == item["gradeCode"] &&
          curr["countCode"] == item["countCode"]
        ) {
          acc += Number(curr["barcode_palletsize"]);
        }
        return acc;
      }, 0);
      item["new_nocartons"] = arr.reduce((acc, curr) => {
        if (
          curr["commodityCode"] == item["commodityCode"] &&
          curr["varietyCode"] == item["varietyCode"] &&
          curr["packCode"] == item["packCode"] &&
          curr["markCode"] == item["markCode"] &&
          curr["gradeCode"] == item["gradeCode"] &&
          curr["countCode"] == item["countCode"]
        ) {
          acc += Number(curr["saleadjustmentdetail_cartons"]);
        }
        return acc;
      }, 0);
      return item;
    });
    return mapArrNew;
  };

  const loadAdjustmentDetail = async (record: DebtorsFullType) => {
    if (record.dispatchdocs_id == DocumentType.DebitCredit) {
      return await getSaleAdjustmentBySaleId(record.sale_dispatch_id);
    }
    return await adjustmentDetailBySaleId(record.sale_id);
  };

  const loadData = async (record: DebtorsFullType) => {
    setLoading(true);

    try {
      const data = await loadAdjustmentDetail(record);
      const totalled = data.map((item) => ({
        ...item,
        total: new BigNumber(item.saleadjustmentdetail_cartons).times(new BigNumber(item.saleadjustmentdetail_amount)).decimalPlaces(5).toNumber(),
      }));
      const groupedData = getGroupedFruitSpec(data).map((item: any) => ({
        ...item,
        total: new BigNumber(item.new_nocartons).times(new BigNumber(item.saleadjustmentdetail_amount)).decimalPlaces(5).toNumber(),
      }));
      setDetail(totalled);
      setDetailGrouped(groupedData);
    } catch (error) {
      const err = GenerateErrorMessage(error, "Error retrieving data");
      updateSnack({ show: true, color: "red", message: err });
    }

    setLoading(false);
  };

  useEffect(() => {
    loadData(selectedRecord);
  }, [selectedRecord]);

  const changeDateInvoice = (value, form) => {
    setSaleDate(toDate(value));
    form.change("sale_date", toDate(value));
  };

  const handleDetailUpdate = (data) => {
    const totalled = data.map((item) => ({
      ...item,
      total: new BigNumber(item.saleadjustmentdetail_cartons).times(new BigNumber(item.saleadjustmentdetail_amount)).decimalPlaces(5).toNumber(),
    }));
    const groupedData = getGroupedFruitSpec(data).map((item: any) => ({
      ...item,
      total: new BigNumber(item.new_nocartons).times(new BigNumber(item.saleadjustmentdetail_amount)).decimalPlaces(5).toNumber(),
    }));

    setDetail(totalled);
    setDetailGrouped(groupedData);
  };

  const clearGridValues = (form) => {
    const data = detail.map((rowItem) => {
      rowItem.saleadjustmentdetail_cartons = 0;
      rowItem.saleadjustmentdetail_amount = 0;
      rowItem.total = 0;
      return rowItem;
    });

    const dataGrouped = detailGrouped.map((rowItem) => {
      rowItem.saleadjustmentdetail_cartons = 0;
      rowItem.new_nocartons = 0;
      rowItem.saleadjustmentdetail_amount = 0;
      rowItem.total = 0;
      return rowItem;
    });

    setDetail(data);
    setDetailGrouped(dataGrouped);
    form.change("saleadjustment_amount", "0.00");
  };

  const handleUpdateGridAmount = (newSaleAdjustmentAmount) => {
    let proportionalAmount;

    const result = detail.reduce((prev, current) => {
      if (!current) return 0;
      return new BigNumber(prev).plus(new BigNumber(current.saleadjustmentdetail_cartons)).toNumber();
    }, 0);

    proportionalAmount = new BigNumber(newSaleAdjustmentAmount).dividedBy(new BigNumber(result)).decimalPlaces(5).toNumber();
    const newDetail = detail.map((ditem: getSaleAdjustmentBySaleIdType) => ({
      ...ditem,
      saleadjustmentdetail_cartons: isNullOrUndef(proportionalAmount) ? ditem.saleadjustmentdetail_cartons : ditem.barcode_cartons,
      saleadjustmentdetail_amount: isNullOrUndef(proportionalAmount) ? ditem.saleadjustmentdetail_amount : proportionalAmount,
    }));

    handleDetailUpdate(newDetail);
  };

  const isTouched = (form: FormApi) => Object.values(form.getState().touched).find((item) => item == true);

  return (
    <div id="debtoradjustmentroot" className={classes.root}>
      {loading && <LinearProgress />}
      <div>
        <Form
          initialValues={{
            ...selectedRecord,
            client: `(${selectedRecord.clients_code}) ${selectedRecord.clients_name}`,
            saleadjustment_type: selectedRecord.document_type == "2" ? "debit" : "credit",
            saleadjustment_typeapply: selectedRecord.document_typeapply,
            document_sourcetotal: numeral(selectedRecord.document_sourcetotal).format("0,0.00"),
            saleadjustment_amount: numeral(selectedRecord.document_type != "1" ? selectedRecord.sale_total : 0).format("0.00"),
          }}
          onSubmit={async (values) => {
            setLoading(true);
            await handleProcess(
              { ...values, saleadjustment_type: values["saleadjustment_type"] == "debit" ? 0 : 1, is_claim: isClaim ? 1 : 0, accsalereceived: isAccountSale ? 1 : 0 },
              detail,
            );
            setLoading(false);
          }}
          validateOnBlur
          render={({ handleSubmit, form }: { handleSubmit: any; form: FormApi }) => (
            <form onSubmit={handleSubmit}>
              <div style={{ paddingBottom: "10px" }}>
                <Button name="clearvalues" variant="contained" color="primary" style={{ marginRight: "10px" }} disabled={loading} onClick={() => clearGridValues(form)}>
                  clear values
                </Button>
                <Button name="submit" type="submit" variant="contained" color="primary" style={{ marginRight: "10px" }} disabled={loading}>
                  Save And Close
                </Button>
                <Button
                  name="close"
                  variant="contained"
                  color="secondary"
                  disabled={loading}
                  onClick={() => (isTouched(form) ? setExitConfirmation(true) : handleProcess(undefined, undefined))}
                >
                  Exit
                </Button>
              </div>
              <div style={{ display: "grid", float: "left" }}>
                <FormControlLabel control={<Checkbox checked={isClaim} onChange={(event) => setIsClaim(event.target.checked)} name="isClaim" />} label="Is Claim?" />
                {selectedRecord.clients_sellingterms != 2 && (
                  <div>
                    <FormControlLabel
                      control={<Checkbox checked={isAccountSale} onChange={(event) => setIsAccountSale(event.target.checked)} name="isAccountSale" />}
                      label="Account Sale?"
                    />
                  </div>
                )}
                <AdjustmentTypeGroup />
              </div>
              <AdjustmentForm
                classes={classes}
                saleDate={saleDate}
                changeDateInvoice={changeDateInvoice}
                form={form}
                applyType={applyType}
                selectedRecord={selectedRecord}
                handleUpdateGridAmount={handleUpdateGridAmount}
              />
              {!loading && (
                <div>
                  <AdjustmentGrid
                    adjustmentDetail={detail}
                    adjustmentDetailGrouped={detailGrouped}
                    isCarton={form.getState().values.saleadjustment_typeapply == 2}
                    handleDetailUpdate={(detail) => {
                      const newTotal = detail.reduce((prev, current) => {
                        if (!current) return 0;
                        return prev + current.saleadjustmentdetail_cartons * current.saleadjustmentdetail_amount;
                      }, 0.0);
                      const formattedTotal = numeral(newTotal).format("0,0.000");
                      form.change("saleadjustment_amount", formattedTotal);
                      handleDetailUpdate(detail);
                    }}
                  />
                </div>
              )}
            </form>
          )}
        />
      </div>
      {exitConfirmation && (
        <Confirmation
          isOpen={true}
          handleClose={() => setExitConfirmation(false)}
          handleConfirm={() => {
            handleProcess(undefined, undefined);
            setExitConfirmation(false);
          }}
          title={`Exit`}
          body={`Are you sure you want to exit without saving?`}
        />
      )}
    </div>
  );
};

const AdjustmentForm: React.FC<any> = ({ classes, saleDate, changeDateInvoice, form, applyType, selectedRecord, handleUpdateGridAmount }) => {
  const [saleETA, setSaleETA] = useState(selectedRecord.loadout_eta ? new Date(selectedRecord.loadout_eta) : new Date());
  const [saleETD, setSaleETD] = useState(selectedRecord.loadout_etd ? new Date(selectedRecord.loadout_etd) : new Date());

  return (
    <div style={{ display: "grid", gridTemplateRows: "1fr", gridTemplateColumns: "repeat(3, 1fr)" }}>
      <div className={classes.container}>
        <TableFieldDate classes={classes} dateValue={saleDate} title="Adjust Date" changeDate={(value) => changeDateInvoice(value, form)} disabled={false} />
        <TableFieldText classes={classes} field="client" title="Client" disabled={true} />
        <TableFieldCombo
          classes={classes}
          field="saleadjustment_typeapply"
          title="Type"
          data={applyType}
          addEmptyValue={undefined}
          currentFormValue={form.getState().values.saleadjustment_typeapply}
        />
        <TableFieldAmount
          classes={classes}
          field="saleadjustment_amount"
          title="Amount"
          disabled={form.getState().values.saleadjustment_typeapply != 1}
          handleUpdateGridAmount={handleUpdateGridAmount}
        />
      </div>
      <div className={classes.container}>
        <TableFieldText classes={classes} field="sale_invoicenumber" title="Invoice Number" disabled={true} />
        <TableFieldText classes={classes} field="document_sourcetotal" title="Sale Total" disabled={true} />
        <TableFieldText classes={classes} field="vessel_code" title="Vessel" disabled={true} />
        <TableFieldText classes={classes} field="dispatch_containerno" title="Container Number" disabled={true} />
      </div>
      <div className={classes.container}>
        <TableFieldText classes={classes} field="saleadjustment_ident" title="Credit/Debit Note No" disabled={true} />
        <TableFieldDate classes={classes} dateValue={saleETD} title="ETD" changeDate={() => {}} disabled={true} />
        <TableFieldDate classes={classes} dateValue={saleETA} title="ETA" changeDate={() => {}} disabled={true} />
      </div>
    </div>
  );
};

export default withStyles(styles)(AdjustmentUnstyled);

const CalenderCustomInput = forwardRef((props: any, ref: any) => {
  return (
    <Button name="CalenderCustomInput" variant="contained" color="primary" onClick={props.onClick} style={{ marginTop: "5px", width: "250px" }}>
      {props.value}
    </Button>
  );
});

const TableFieldDate: React.FunctionComponent<{ dateValue: Date; title: string; changeDate: (value) => void; disabled: boolean } & WithStyles<typeof styles>> = (props) => {
  const { classes, dateValue, title, changeDate, disabled } = props;
  return (
    <>
      <span className={classes.tableCellLabel}>{`${title}:`}</span>
      <span className={classes.tableCellDetailDate}>
        <ReactDatePicker
          locale="en-GB"
          showWeekNumbers={true}
          selected={toDate(dateValue)}
          onChange={(value) => {
            changeDate(value);
          }}
          dateFormat="dd-MM-yyyy"
          placeholderText="click here to select a date"
          customInput={<CalenderCustomInput />}
          disabled={disabled}
        />
      </span>
    </>
  );
};

const TableFieldText: React.FunctionComponent<{ field: string; title: string; disabled: boolean } & WithStyles<typeof styles>> = (props) => {
  const { classes, field, title, disabled } = props;
  return (
    <>
      <span className={classes.tableCellLabel}>{`${title}:`}</span>
      <span className={classes.tableCellDetail}>
        <Field name={field} component={TextField} type="text" fullWidth={true} disabled={disabled} />
      </span>
    </>
  );
};

const TableFieldAmount: React.FunctionComponent<
  { classes: any; field: string; title: string; disabled: boolean; handleUpdateGridAmount(newValue: number): void } & WithStyles<typeof styles>
> = (props) => {
  const { classes, field, title, disabled, handleUpdateGridAmount } = props;
  return (
    <>
      <span className={classes.tableCellLabel}>{`${title}:`}</span>
      <span className={classes.tableCellDetail}>
        <Field
          name={field}
          type="text"
          fullWidth={true}
          disabled={disabled}
          render={(props) => (
            <TextField
              {...props}
              input={{
                ...props.input,
                onChange: (event: any) => {
                  props.input.onChange(event);
                  const newValue = new BigNumber(event.target.value || 0).toNumber();
                  handleUpdateGridAmount(newValue);
                },
              }}
            />
          )}
        />
      </span>
    </>
  );
};

const TableFieldCombo: React.FunctionComponent<
  { classes: any; title: string; field: string; data: any; addEmptyValue: string; currentFormValue: any } & WithStyles<typeof styles>
> = (props) => {
  const { classes, title, field, data, addEmptyValue, currentFormValue } = props;
  return (
    <>
      <span className={classes.tableCellLabel} style={{ color: currentFormValue < 1 ? "red" : "black" }}>{`${title}:`}</span>
      <span className={classes.tableCellDetail}>
        <Field name={field} component={Select} formControlProps={{ className: classes.tableCellsValueSelect }}>
          {addEmptyValue && (
            <MenuItem key={`emptyvalueMenuItem`} value={-1}>
              {addEmptyValue}
            </MenuItem>
          )}
          {data &&
            data.map((item, index) => {
              return (
                <MenuItem key={`${item.value}${index}`} value={item.value}>
                  {item.display}
                </MenuItem>
              );
            })}
        </Field>
      </span>
    </>
  );
};

const AdjustmentTypeGroup = () => (
  <FormControl style={{ float: "left" }}>
    <RadioGroup>
      <FormControlLabel control={<Field type="radio" name="saleadjustment_type" value={"debit"} component={Radio} />} label="Debit" />
      <FormControlLabel control={<Field type="radio" name="saleadjustment_type" value={"credit"} component={Radio} />} label="Credit" />
    </RadioGroup>
  </FormControl>
);
