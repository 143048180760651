import React from "react";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";

import LinearProgress from "@material-ui/core/LinearProgress";
import CircularProgress from "@material-ui/core/CircularProgress";

import { getFileImportHeader, getFileImportHeaderInfo, getFileImportHeaderInfoValidation } from "../../lib/api/FileImport";
import { commoditiesAllSortedMappedforCombo } from "../../lib/api/commodity";

import { SnackMessage } from "../../lib/helpers/snackmessage";

import ImportGrid from "./importinfogrid";
import format from "date-fns/format";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      overflow: "hidden",
      width: "100%",
      height: "calc(100% - 65px)",
    },
  });

type ImportProps = {
  match: any;
} & WithStyles<typeof styles>;

class LoadoutUnstyled extends React.Component<ImportProps> {
  state = {
    classes: undefined,
    match: undefined,
    data: [{}],
    dataDetail: [{}],
    loading: true,
    snackshow: undefined,
    snackmessage: undefined,
    snackcolor: undefined,
    requestOpenId: undefined,
    commoditiesCombo: [],
    dataDetailValidation: [],
  };

  constructor(props) {
    super(props);
    this.state.classes = props.classes;
    this.state.match = props.match;
  }

  componentDidMount() {
    this.loadData();
  }

  loadData = async () => {
    this.setState({ loading: true });
    const id = this.state.match.params.id;

    const [header, headerinfo, commodities, headerinfoValidation] = await Promise.all([
      getFileImportHeader(id),
      getFileImportHeaderInfo(id),
      commoditiesAllSortedMappedforCombo(),
      getFileImportHeaderInfoValidation(id),
    ]);

    const formattedHeaderInfo = header.data.map((row) => ({ ...row, FormattedDate: format(new Date(row["Date"].replace("Z", "")), "dd MMM yyyy") }));

    const formattedDetailInfo = headerinfo.data.map((row) => {
      const formattedDatesObj = {};

      const errors = headerinfoValidation.data.reduce((str, vRow) => {
        if (vRow.rowId == row.id) {
          str = str != "" ? `${str}, ${vRow.column}` : vRow.column;
        }
        return str;
      }, "");

      return { ...row, ...formattedDatesObj, edivalidationerrors: errors };
    });

    this.setState({
      data: formattedHeaderInfo,
      dataDetail: formattedDetailInfo,
      loading: false,
      commoditiesCombo: commodities,
      dataDetailValidation: headerinfoValidation.data,
    });
  };

  onSnackClose = () => {
    this.setState({ snackshow: undefined, snackmessage: undefined });
  };

  onReload = async () => {
    return await this.loadData();
  };

  render() {
    return (
      <div className={this.state.classes.root}>
        {this.state.loading && <CircularProgress />}
        {this.state.snackshow && (
          <div>
            <SnackMessage isOpen={this.state.snackshow} message={this.state.snackmessage} color={this.state.snackcolor} handleClose={this.onSnackClose} />
          </div>
        )}
        {this.state.loading && (
          <div>
            <LinearProgress />
          </div>
        )}
        {!this.state.loading && (
          <ImportGrid
            data={this.state.data}
            handleReload={this.onReload}
            datadetail={this.state.dataDetail}
            commoditiesCombo={this.state.commoditiesCombo}
            dataDetailValidation={this.state.dataDetailValidation}
          />
        )}
      </div>
    );
  }
}

export default withStyles(styles)(LoadoutUnstyled);
