import React from "react";
import { NavLink } from "react-router-dom";

import CustomAutoCompleteFilter from "../lib/components/customdatagridfilter/CustomFilter";
import { EnquiryLink, Selector } from "../lib/components/EnquiryLink";
import { DateFormatter, FormatterNumber, FormatterNumber2Decimals } from "../lib/components/Formatters";
import { SummaryNumber, SummaryNumber2Decimal, SummaryTotal } from "../lib/components/SummaryFormatter";
import { handleColumnsArrangement, handleColumnsWidth } from "../lib/helpers/grid";

const cellWidthAction = 50;
const cellWidthTiny = 40;
const cellWidthSmall = 80;
const cellWidthMedium = 120;
const cellWidthLarge = 200;

const defaultColumnProperties = {
  resizable: true,
  sortable: true,
};

let currentSpace = true;

export const GridColumns = (data, filters, arrangement, columnsWidth) => {
  let postValue = "";

  if (data) {
    if (currentSpace) {
      postValue = " ";
    }
    currentSpace = !currentSpace;
  }

  const columns = [
    {
      key: "invoicenumber",
      name: "Invoice Number" + postValue,
      width: cellWidthMedium,
      filterable: true,
      summaryFormatter: SummaryTotal,
      formatter: (props) => <EnquiryLink {...props} selector={Selector.InvoiceNumber} />,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"invoicenumber"} data={data} section="consolidationselected" />;
      },
    },
    {
      key: "producerid",
      name: "Producer ID" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"producerid"} data={data} section="consolidationselected" />;
      },
    },
    {
      key: "FarmNumber",
      name: "Farm Number" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"FarmNumber"} data={data} section="consolidationselected" />;
      },
    },
    {
      key: "IntakeWaybill",
      name: "Intake Waybill" + postValue,
      width: cellWidthMedium,
      filterable: true,
      formatter: (props) => <EnquiryLink {...props} selector={Selector.IntakeWaybill} />,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"IntakeWaybill"} data={data} section="consolidationselected" />;
      },
    },
    {
      key: "barcode",
      name: "Barcode" + postValue,
      width: cellWidthMedium,
      filterable: true,
      formatter: (props) => <EnquiryLink {...props} selector={Selector.Barcode} />,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"barcode"} data={data} section="consolidationselected" />;
      },
    },
    {
      key: "TargetMarket",
      name: "Target Market" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"TargetMarket"} data={data} section="consolidationselected" />;
      },
    },
    {
      key: "target_shipping",
      name: "Shipped Market" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"target_shipping"} data={data} section="consolidationselected" />;
      },
    },
    {
      key: "CommodityCode",
      name: "Commodity Code" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"CommodityCode"} data={data} section="consolidationselected" />;
      },
    },
    {
      key: "VarietyCode",
      name: "Variety Code" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"VarietyCode"} data={data} section="consolidationselected" />;
      },
    },
    {
      key: "GradeCode",
      name: "Grade Code" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"GradeCode"} data={data} section="consolidationselected" />;
      },
    },
    {
      key: "PackCode",
      name: "Pack Code" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"PackCode"} data={data} section="consolidationselected" />;
      },
    },
    {
      key: "CountCode",
      name: "Count Code" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"CountCode"} data={data} section="consolidationselected" />;
      },
    },
    {
      key: "MarkCode",
      name: "Mark Code" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"MarkCode"} data={data} section="consolidationselected" />;
      },
    },
    {
      key: "InventoryCode",
      name: "Inventory Code" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"InventoryCode"} data={data} section="consolidationselected" />;
      },
    },
    {
      key: "palletSize",
      name: "Pallet Size" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"palletSize"} data={data} section="consolidationselected" />;
      },
      formatter: FormatterNumber2Decimals,
      summaryFormatter: SummaryNumber2Decimal,
    },
    {
      key: "NoCartons",
      name: "No Cartons" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"NoCartons"} data={data} section="consolidationselected" />;
      },
      formatter: FormatterNumber,
      summaryFormatter: SummaryNumber,
    },
    {
      key: "client_code",
      name: "Client Code" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"client_code"} data={data} section="consolidationselected" />;
      },
    },
    {
      key: "client_name",
      name: "Client Name" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"client_name"} data={data} section="consolidationselected" />;
      },
    },
    {
      key: "vessel_code",
      name: "Vessel" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"vessel_code"} data={data} section="consolidationselected" />;
      },
    },
    {
      key: "containerno",
      name: "Container No" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"containerno"} data={data} section="consolidationselected" />;
      },
    },
    {
      key: "loadout_etd",
      name: "ETD" + postValue,
      width: cellWidthMedium,
      filterable: true,
      formatter: DateFormatter,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"loadout_etd"} data={data} section="consolidationselected" />;
      },
    },
    {
      key: "loadout_eta",
      name: "ETA" + postValue,
      width: cellWidthMedium,
      filterable: true,
      formatter: DateFormatter,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"loadout_eta"} data={data} section="consolidationselected" />;
      },
    },
    {
      key: "ata",
      name: "ATA" + postValue,
      width: cellWidthMedium,
      filterable: true,
      formatter: DateFormatter,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"ata"} data={data} section="consolidationselected" />;
      },
    },
    {
      key: "portdist_code",
      name: "POD" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"portdist_code"} data={data} section="consolidationselected" />;
      },
    },
    {
      key: "portfinal_code",
      name: "Final Dest" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"portfinal_code"} data={data} section="consolidationselected" />;
      },
    },
    {
      key: "shipment_week",
      name: "Shipment Week" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"shipment_week"} data={data} section="consolidationselected" />;
      },
    },
    {
      key: "currency",
      name: "Currency" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"currency"} data={data} section="consolidationselected" />;
      },
    },
    {
      key: "sellingprice_final",
      name: "Selling Price Final" + postValue,
      width: cellWidthMedium,
      filterable: true,
      formatter: FormatterNumber2Decimals,
      summaryFormatter: SummaryNumber2Decimal,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"sellingprice_final"} data={data} section="consolidationselected" />;
      },
    },
    {
      key: "sale_credit_total",
      name: "Credit Note" + postValue,
      width: cellWidthMedium,
      filterable: true,
      formatter: FormatterNumber2Decimals,
      summaryFormatter: SummaryNumber2Decimal,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"sale_credit_total"} data={data} section="consolidationselected" />;
      },
    },
    {
      key: "sale_debit_total",
      name: "Debit Note" + postValue,
      width: cellWidthMedium,
      filterable: true,
      formatter: FormatterNumber2Decimals,
      summaryFormatter: SummaryNumber2Decimal,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"sale_debit_total"} data={data} section="consolidationselected" />;
      },
    },
    {
      key: "foreigncost",
      name: "Foreign Cost" + postValue,
      width: cellWidthMedium,
      filterable: true,
      formatter: FormatterNumber2Decimals,
      summaryFormatter: SummaryNumber2Decimal,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"foreigncost"} data={data} section="consolidationselected" />;
      },
    },
    {
      key: "adjustment",
      name: "Adjustment" + postValue,
      width: cellWidthMedium,
      filterable: true,
      formatter: (props) => {
        return <FormatterNumber2Decimals {...props} />;
      },
      summaryFormatter: SummaryNumber2Decimal,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"adjustment"} data={data} section="consolidationselected" />;
      },
    },
    {
      key: "totalcalcfobcost",
      name: "Net Sale Total" + postValue,
      width: cellWidthMedium,
      filterable: true,
      formatter: FormatterNumber2Decimals,
      summaryFormatter: SummaryNumber2Decimal,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"totalcalcfobcost"} data={data} section="consolidationselected" />;
      },
    },
    {
      key: "totalcalcnettsellprice",
      name: "FOB Sell Price" + postValue,
      width: cellWidthMedium,
      filterable: true,
      formatter: FormatterNumber2Decimals,
      summaryFormatter: SummaryNumber2Decimal,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"nettsellprice"} data={data} section="consolidationselected" />;
      },
    },
    {
      key: "ld_fob",
      name: "FOB PI Price" + postValue,
      width: cellWidthMedium,
      filterable: true,
      formatter: (props) => {
        const { row } = props;
        if (Number(row.ld_fob) > 0) {
          return (
            <NavLink to={`/instruction/${row.pio_id}/edit`} activeClassName="selected" target={"_blank"}>
              <FormatterNumber2Decimals {...props} />;
            </NavLink>
          );
        }
        return <FormatterNumber2Decimals {...props} />;
      },
      summaryFormatter: SummaryNumber2Decimal,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"ld_fob"} data={data} section="consolidationselected" />;
      },
    },
    {
      key: "roe_eta",
      name: "ROE ETA" + postValue,
      width: cellWidthMedium,
      filterable: true,
      formatter: FormatterNumber2Decimals,
      summaryFormatter: SummaryNumber2Decimal,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"roe_eta"} data={data} section="consolidationselected" />;
      },
    },
    {
      key: "roe_etd",
      name: "ROE ETD" + postValue,
      width: cellWidthMedium,
      filterable: true,
      formatter: FormatterNumber2Decimals,
      summaryFormatter: SummaryNumber2Decimal,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"roe_etd"} data={data} section="consolidationselected" />;
      },
    },
    {
      key: "local_costs",
      name: "Local Costs" + postValue,
      width: cellWidthMedium,
      filterable: true,
      formatter: FormatterNumber2Decimals,
      summaryFormatter: SummaryNumber2Decimal,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"local_costs"} data={data} section="consolidationselected" />;
      },
    },
    {
      key: "rtgp",
      name: "Available for Distribution" + postValue,
      width: cellWidthMedium,
      filterable: true,
      formatter: FormatterNumber2Decimals,
      summaryFormatter: SummaryNumber2Decimal,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"rtgp"} data={data} section="consolidationselected" />;
      },
    },
    {
      key: "advance",
      name: "Producer Advance" + postValue,
      width: cellWidthMedium,
      filterable: true,
      formatter: FormatterNumber2Decimals,
      summaryFormatter: SummaryNumber2Decimal,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"advance"} data={data} section="consolidationselected" />;
      },
    },
    {
      key: "producerFinal",
      name: "Producer Final" + postValue,
      width: cellWidthMedium,
      filterable: true,
      formatter: FormatterNumber2Decimals,
      summaryFormatter: SummaryNumber2Decimal,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"producerFinal"} data={data} section="consolidationselected" />;
      },
    },
    {
      key: "rtgc",
      name: "Producer Return/Carton" + postValue,
      width: cellWidthMedium,
      filterable: true,
      formatter: FormatterNumber2Decimals,
      summaryFormatter: SummaryNumber2Decimal,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"rtgc"} data={data} section="consolidationselected" />;
      },
    },
    {
      key: "final",
      name: "Finalized" + postValue,
      width: cellWidthMedium,
      filterable: true,
      formatter: (props) => {
        return <FormatterNumber2Decimals {...props} />;
      },
      summaryFormatter: SummaryNumber2Decimal,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"final"} data={data} section="consolidationselected" />;
      },
    },
  ].map((c) => ({ ...c, ...defaultColumnProperties }));

  let columnsArranged = [];
  columnsArranged = handleColumnsArrangement(columnsArranged, arrangement, columns);
  columnsArranged = handleColumnsWidth(columnsArranged, columnsWidth, columns);

  return columnsArranged;
};
