import React from "react";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";

import Button from "@material-ui/core/Button";
import { clearFiltersForColumns } from "../lib/helpers/stickyfilters";
import ReactDataGrid, { Row } from "react-data-grid";
import { ReferencesColumns } from "./stockpricingreferencesgridsetup";
import { generateGridFilteredRows } from "../lib/helpers/generateGridFilteredRows";
import { Toolbar } from "react-data-grid-addons";

import Grid from "../lib/components/grid";
import { DateFormatter, FormatterNumber, FormatterNumber2Decimals, FormatterTypes, TextFormatter } from "../lib/components/Formatters";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      height: "100%",
      width: "100%",
    },
    actions: {
      marginRight: "-9px",
      marginTop: "-2px",
    },
    boldRow: {
      fontWeight: "bold",
    },
    normalRow: {
      fontWeight: "normal",
      color: "black",
    },
  });

type StockPricingReferencesTableProps = {
  data: {}[];
  minGridHeight: number;
  onSelectedChanged: any;
} & WithStyles<typeof styles>;

class StockPricingReferencesTableUnstyled extends React.Component<StockPricingReferencesTableProps, {}> {
  state = {
    data: [{}],
    filters: {},
    loading: false,
    selectedRows: [],
    onSelectedChanged: undefined,
  };

  constructor(props) {
    super(props);
    this.state.data = props.data;
    this.state.onSelectedChanged = props.onSelectedChanged;
  }

  componentDidMount() {}

  handleRowSelect = (row) => {
    if (this.state.selectedRows.indexOf(row) == -1) {
      this.setState({ selectedRows: [...this.state.selectedRows, row] }, () => {
        this.state.onSelectedChanged(this.state.selectedRows);
      });
      return;
    } else {
      const selectedrowsArr = this.state.selectedRows.filter((item) => item != row);
      this.setState({ selectedRows: [...selectedrowsArr] }, () => {
        this.state.onSelectedChanged(this.state.selectedRows);
      });
      return;
    }
  };

  rowRenderer = (props, classes) => {
    const { row } = props;
    const fontWeight = this.state.selectedRows && this.state.selectedRows.find((from) => from.reference == row.reference) ? classes.boldRow : classes.normalRow;

    return <Row {...props} onDoubleClickCapture={() => this.handleRowSelect(row)} className={fontWeight} />;
  };

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <Grid
          showFilterChips={true}
          loading={this.state.loading}
          data={this.state.data}
          GridColumns={(data, filters, columnArrangement, columnsWidth) =>
            ReferencesColumns(data, filters, columnArrangement, columnsWidth, this.handleRowSelect, this.state.selectedRows)
          }
          clearFilters={"stockpricingreference"}
          rowRenderer={(props) => this.rowRenderer(props, classes)}
          forceHeight={this.props.minGridHeight}
        />
      </div>
    );
  }
}

export default withStyles(styles)(StockPricingReferencesTableUnstyled);
