import { client, request } from "./_base";

const endpoint = "ucr";

export type UCRSetupType = {
  id: number;
  clientcode: string;
  swiftcountrycode: string;
  created_at: Date;
  updated_at: Date;
};
export const ucr = client(endpoint, (item) => ({
  ...item,
}));
