import React from "react";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";

import { arrivals } from "../lib/api/arrival";
import { arrivaldetails } from "../lib/api/arrivaldetail";

import { ArrivalsGrid } from "./arrivals/arrivalgrid";
import ArrivalsDetail from "./arrivalsdetail/arrivalsdetail";

import Confirmation from "../lib/components/confirmation";
import { DialogInformation } from "../lib/components/dialoginformation";
import { handlePrint } from "../reports/arrivalPDF";

import { SnackContext } from "../lib/context/SnackContext";
import { GenerateErrorMessage } from "../lib/helpers/string_methods";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1) * 2,
    },
  });

type ArrivalProps = {} & WithStyles<typeof styles>;

type arrivaltype = {
  containernumber: string;
  producerid: string;
  salesref: string;
  comments: string;
  fotos: any;
  print_date?: any;
  status: number;
  claim: boolean;
};

let currentIntakeData = undefined;
let comments = undefined;
let pics = undefined;

const ArrivalUnstyled: React.SFC<ArrivalProps> = (props) => {
  const { classes } = props;
  const [editingReference, setEditingReference] = React.useState(undefined); //{ container: "APRU5080197", farmnumber: "D0751", arrival_id: 1 });
  const [currentData, setCurrentData] = React.useState<arrivaltype>({
    containernumber: "",
    producerid: "",
    salesref: "",
    comments: "",
    fotos: "",
    status: 0,
    claim: false,
  });

  const [isDirty, setIsDirty] = React.useState(false);
  const [confirmDirty, setConfirmDirty] = React.useState(false);
  const [error, setError] = React.useState<string>(undefined);
  const [removeID, setRemoveID] = React.useState(undefined);
  const { updateSnack } = React.useContext(SnackContext);

  const handleEdit = async (container, producerid, salesref, arrival_id, status, claim) => {
    comments = undefined;
    pics = undefined;

    const arrivalData: arrivaltype = {
      containernumber: container,
      producerid: producerid,
      salesref: salesref,
      comments: undefined,
      fotos: undefined,
      status: status,
      claim: claim,
    };

    await setConfirmDirty(false);
    await setIsDirty(false);
    await setCurrentData(arrivalData);
    await setEditingReference({
      container: container,
      producerid: producerid,
      salesref: salesref,
      arrival_id: arrival_id,
    });
  };

  const handleEditClose = () => {
    if (isDirty) {
      setConfirmDirty(true);
    } else {
      setEditingReference(undefined);
    }
  };

  const handleEditConfirm = async (exportRequested: boolean = false, status: number = 1, claim = currentData.claim) => {
    return new Promise(async (resolve, reject) => {
      const currentDataNew = { ...currentData, comments: comments, fotos: pics, claim };
      // return;
      if (currentDataNew) {
        currentDataNew.status = status;
        if (exportRequested && !currentDataNew.print_date) {
          currentDataNew.print_date = new Date();
        }
        if (editingReference.arrival_id == 0) {
          arrivals
            .create({ data: currentDataNew })
            .then((data) => {
              if (data.code == "EREQUEST") {
                setError("Could not submit the data!");
                reject("Could not submit the data!");
              }
              handleEditConfirmDetails(data[0]).then(() => {
                resolve(data[0]);
              });
            })
            .catch((error) => {
              const err = GenerateErrorMessage(error, "Error creating data");
              updateSnack({ show: true, color: "red", message: err });
              setError(err);
              reject(err);
            });
        } else {
          arrivals
            .update(editingReference.arrival_id, { data: currentDataNew })
            .then((data) => {
              if (data.code == "EREQUEST") {
                setError("Could not submit the data!");
                reject("Could not submit the data!");
              }
              handleEditConfirmDetails(editingReference.arrival_id).then(() => {
                resolve(editingReference.arrival_id);
              });
            })
            .catch((error) => {
              const err = GenerateErrorMessage(error, "Error updating data");
              updateSnack({ show: true, color: "red", message: err });
              setError(err);
              reject(err);
            });
        }
      }
    }).then((result) => {
      setConfirmDirty(false);
      setIsDirty(false);

      let editingRefNew = { ...editingReference, arrival_id: result };
      if (!exportRequested) {
        editingRefNew = undefined;
      }

      setEditingReference(editingRefNew);
      return editingRefNew;
    });
  };

  const handleEditConfirmDetails = async (arrivalid) => {
    if (!currentIntakeData) {
      return;
    }
    new Promise(async (resolve, reject) => {
      currentIntakeData.map((data) => {
        const newData = {
          arrival_id: arrivalid,
          barcode_id: data.barcode_id,
          variety: data.varietycode,
          grade: data.gradecode,
          count: data.countcode,
          brand: data.markcode,
          pickingref: data.pickingref,
          qualityscore: data.qualityscore,
          sequencenumber: data.sequencenumber,
        };
        if (data.arrivaldetail_id == 0) {
          arrivaldetails.create({ data: newData }).then(() => {
            resolve("created");
          });
        } else {
          arrivaldetails.update(data.arrivaldetail_id, { data: newData }).then(() => {
            resolve("updated");
          });
        }
      });
    })
      .then(() => {
        return;
      })
      .catch((error) => {
        const err = GenerateErrorMessage(error, "Error updating data");
        updateSnack({ show: true, color: "red", message: err });
      });
  };

  const handleExportExcel = async () => {
    await handleEditConfirm(true)
      .then((data) => {
        window.location.href = `/api/arrival/ext/exportArrival/` + `${data.arrival_id}/` + `${data.container ? data.container : "0"}/` + `${data.producerid}/` + `${data.salesref}`;
      })
      .catch((error) => {
        const err = GenerateErrorMessage(error, "Error updating data");
        updateSnack({ show: true, color: "red", message: err });
      });
  };

  const handleExportPDF = async () => {
    const newEditingRef = await handleEditConfirm(true);
    handlePrint(newEditingRef.arrival_id, newEditingRef.container, newEditingRef.producerid, newEditingRef.salesref);
  };

  const handleConfirmContinueClose = () => {
    setConfirmDirty(false);
  };

  const handleConfirmContinueConfirm = async () => {
    await setConfirmDirty(false);
    await setIsDirty(false);
    await setEditingReference(undefined);
  };

  const handleErrorClose = () => {
    setError(undefined);
  };

  const handleChange = async (property: string, value: any) => {
    if (property == "gridload") {
      currentIntakeData = value;
    } else if (property == "fotosload") {
      pics = JSON.stringify(value);
    } else {
      if (!isDirty) {
        setIsDirty(true);
      }
      if (property == "grid") {
        currentIntakeData = value;
      } else if (property == "fotos") {
        pics = JSON.stringify(value);
      } else if (property == "comments") {
        comments = value;
      }
    }
  };

  const handleRemove = (arrivalid) => {
    setRemoveID(arrivalid);
  };

  const handleRemoveClose = (arrivalid) => {
    setRemoveID(undefined);
  };

  const handleRemoveConfirm = (arrivalid) => {
    arrivals.remove(removeID).then(() => {
      setRemoveID(undefined);
    });
  };

  return (
    <div className={classes.root}>
      <ArrivalsGrid handleEdit={handleEdit} handleRemove={handleRemove} />
      {error && <DialogInformation isOpen={true} handleClose={handleErrorClose} handleOK={handleErrorClose} title={"Error!"} body={`${error}`} showinput={false} />}
      {confirmDirty && (
        <Confirmation
          isOpen={editingReference ? true : false}
          handleClose={handleConfirmContinueClose}
          handleConfirm={handleConfirmContinueConfirm}
          title={"Unsaved Changes"}
          body={`You have unsaved data. Do you want to continue?`}
        />
      )}
      {removeID && (
        <Confirmation
          isOpen={removeID ? true : false}
          handleClose={handleRemoveClose}
          handleConfirm={handleRemoveConfirm}
          title={"Remove Record"}
          body={`Are you sure you want to remove any data processed for the selected arrival?`}
        />
      )}
      {editingReference && (
        <Confirmation isOpen={editingReference ? true : false} handleClose={handleEditClose} handleConfirm={() => {}} title={"QC Arrival"} body={undefined}>
          <ArrivalsDetail
            container={editingReference.container}
            producerid={editingReference.producerid}
            salesref={editingReference.salesref}
            arrival_id={editingReference.arrival_id}
            handleClose={handleEditClose}
            handleSubmit={handleEditConfirm}
            handleExportExcel={handleExportExcel}
            handleExportPDF={handleExportPDF}
            handleChange={handleChange}
            status={currentData.status}
            claim={currentData.claim}
          />
        </Confirmation>
      )}
    </div>
  );
};

export default withStyles(styles)(ArrivalUnstyled);
