import React from "react";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";

import Grid from "../../lib/components/grid";
import Confirmation from "../../lib/components/confirmation";

import { weeksOrdered, getFinancialYearSelected, updateWeeksForYear, weeks } from "../../lib/api/week";
import { GridColumns } from "./weeksgridsetup";

import Tooltip from "@material-ui/core/Tooltip";
import Button from "@material-ui/core/Button";

import IconAdd from "@material-ui/icons/Add";
import IconImport from "@material-ui/icons/DateRange";

import WeeksEditForm from "./weekseditform";
import UpdateWeeksForm from "./weeksImportYearform";
import { DialogInformation } from "../../lib/components/dialoginformation";

import { SnackMessage } from "../../lib/helpers/snackmessage";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1),
      width: "100%",
      height: "100%",
    },
    inline: {
      display: "inline",
      position: "absolute",
      marginTop: "7px",
      marginLeft: "7px",
      width: "82px",
    },
    importButton: {
      backgroundColor: "#ffa726",
      borderColor: "#ffa726",
      "&:hover": {
        backgroundColor: "#fb8c00",
        borderColor: "#f57c00",
      },
      width: "200px",
    },
    iconButton: {
      marginLeft: "5px",
    },
  });

type WeeksProps = {} & WithStyles<typeof styles>;

export class WeeksUnstyled extends React.Component<WeeksProps, any> {
  state = {
    classes: undefined,
    data: [],
    confirmRemoveID: undefined,
    confirmEditID: undefined,
    confirmUpdate: false,
    loading: true,
    message: undefined,
    snackshow: undefined,
    snackmessage: undefined,
    snackcolor: undefined,
  };

  constructor(props) {
    super(props);
    this.state.classes = props.classes;
  }

  componentDidMount() {
    this.loadData();
  }

  loadData = () => {
    weeksOrdered("0").then((data) => {
      this.setState({ loading: false, data: data.data });
    });
  };

  handleCreate = () => {
    this.setState({ confirmEditID: "0" });
  };

  handleEdit = (id) => {
    this.setState({ confirmEditID: id });
  };

  handleEditClose = () => {
    this.setState({ confirmEditID: undefined });
  };

  handleUpdate = (id) => {
    this.setState({ confirmUpdate: true });
  };

  handleUpdateClose = () => {
    this.setState({ confirmUpdate: false });
  };

  handleUpdateConfirm = (e, item) => {
    updateWeeksForYear(item.getState().values.year)
      .then(() => {
        this.loadData();
        this.handleUpdateClose();
        this.setState({ message: `Weeks imported for ${item.getState().values.year} successfully.` });
      })
      .catch((e) => {
        this.setState({ snackshow: true, snackmessage: "Could not import weeks", snackcolor: "red" });
      });
  };

  handleEditConfirm = (item) => {
    const id = item["id"];
    delete item["id"];

    if (id == "0") {
      weeks.create({ data: { ...item } }).then(() => this.loadData());
    } else {
      weeks.update(id, { data: { ...item } }).then(() => this.loadData());
    }
    this.handleEditClose();
  };

  handleRemove = async (id) => {
    this.setState({ confirmRemoveID: id });
  };

  handleRemoveClose = () => {
    this.setState({ confirmRemoveID: undefined });
  };

  handleRemoveConfirm = async () => {
    await weeks.remove(this.state.confirmRemoveID).then(() => {
      this.handleRemoveClose();
      this.loadData();
    });
  };

  GridColumnsOverride = (column, row) => {
    return GridColumns(column, row, this.handleRemove, this.handleEdit);
  };

  handleInformationClose = () => {
    this.setState({ message: undefined });
  };

  onSnackClose = () => {
    this.setState({ snackshow: undefined, snackmessage: undefined });
  };

  render() {
    const { classes } = this.state;

    return (
      <div className={classes.root}>
        <div className={classes.inline}>
          {this.state.snackshow && (
            <div>
              <SnackMessage isOpen={this.state.snackshow} message={this.state.snackmessage} color={this.state.snackcolor} handleClose={this.onSnackClose} />
            </div>
          )}
          <div style={{ float: "left" }}>
            <Tooltip title="Create new Week">
              <div className={classes.inline}>
                <Button variant="contained" color="primary" onClick={this.handleCreate} style={{ marginBottom: "10px" }}>
                  <IconAdd />
                </Button>
              </div>
            </Tooltip>
          </div>
          <div style={{ float: "right" }}>
            <Tooltip title="Import new weeks">
              <div className={classes.inline}>
                <Button variant="contained" color="primary" onClick={this.handleUpdate} style={{ marginBottom: "10px" }} className={classes.importButton}>
                  Import by Year
                  <IconImport className={classes.iconButton} />
                </Button>
              </div>
            </Tooltip>
          </div>
        </div>
        <Grid loading={this.state.loading} data={this.state.data} GridColumns={this.GridColumnsOverride} clearFilters={"weeksgridsetup"} />
        {this.state.confirmRemoveID && (
          <Confirmation
            isOpen={this.state.confirmRemoveID ? true : false}
            handleClose={this.handleRemoveClose}
            handleConfirm={this.handleRemoveConfirm}
            title="Remove selected Week?"
            body="Are you sure you want to REMOVE the selected Week Record?"
          ></Confirmation>
        )}
        {this.state.confirmEditID && (
          <Confirmation
            isOpen={this.state.confirmEditID ? true : false}
            handleClose={this.handleEditClose}
            handleConfirm={() => {}}
            title={this.state.confirmEditID == "0" ? "Creating Week" : "Editing Week"}
            body={undefined}
          >
            <WeeksEditForm onSubmit={this.handleEditConfirm} onClose={this.handleEditClose} id={this.state.confirmEditID} />
          </Confirmation>
        )}

        {this.state.confirmUpdate && (
          <Confirmation isOpen={this.state.confirmUpdate ? true : false} handleClose={this.handleUpdateClose} handleConfirm={() => {}} title={"Import Weeks"} body={undefined}>
            <UpdateWeeksForm onSubmit={this.handleUpdateConfirm} onClose={this.handleUpdateClose} />
          </Confirmation>
        )}
        {this.state.message && (
          <DialogInformation
            isOpen={true}
            handleClose={this.handleInformationClose}
            handleOK={this.handleInformationClose}
            title={"Feedback"}
            body={`${this.state.message}`}
            showinput={false}
          />
        )}
      </div>
    );
  }
}

export default withStyles(styles)(WeeksUnstyled);
