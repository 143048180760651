import React from "react";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";

import Grid from "../../lib/components/grid";

import { GridColumns } from "./financesummarygrid";
import { getFinanceSummary } from "../../lib/api/finance";

import { Toolbar } from "@material-ui/core";

import Confirmation from "../../lib/components/confirmation";
import ForeignCostInfo from "../consolidation/foreigncostinfo/foreignCostInfo";
import ProducerAdvanceInfo from "./produceradvancegrid/index";
import ProducerFinalInfo from "./producerfinalgrid/index";

import { Row } from "react-data-grid";
import { getFinancialYearSelected } from "../../lib/api/week";

import LocalCostInfo from "./localcostinfo/index";
import ImpalaFeeInfo from "./impalafeeinfo/index";

export const columnsDict = {
  producer_id: "Producer",
  cartons: "Cartons",
  palletsize: "Pallet Size",
  currency: "Currency",
  exchangerate: "ROE",
  sale_total: "Sale Total",
  sale_debit: "Debit Note",
  sale_credit: "Credit Note",
  foreigncost: "Foreign Costs",
  nettsaletotal: "Net Sale Total",
  local_cost: "Local Costs",
  impala_fee: "Impala Fee",
  available: "Available for Distribution",
  advance: "Advance",
  advance_ident: "Advance Remittance",
  advance_date: "Advance Paid Date",
  final: "Final",
  final_ident: "Final Remittance",
  final_date: "Final Paid Date",
  total: "Processed",
};

const styles = (theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      height: "100%",
      position: "relative",
    },
    totalRowStyle: {
      background: "#ddd",
      textAlign: "right",
      fontWeight: "bold",
      color: "black",
    },
    totalRowStyleFixed: {
      position: "sticky",
      bottom: 0,
      background: "#ddd",
      textAlign: "right",
      fontWeight: "bold",
      color: "black",
    },
    lastRowStyle: {
      display: "flex",
      flexDirection: "column-reverse",
      color: "black",
    },
    toolbar: {
      position: "absolute",
      left: 0,
      width: "100%",
      gap: "20px",
    },
    toolbarWrapper: {
      position: "relative",
    },
    normalRow: {
      fontWeight: "normal",
    },
    boldRow: {
      fontWeight: "bolder",
    },
  });

type FinanceSummaryProps = { sale_invoicenumber: number; forceheight: number } & WithStyles<typeof styles>;

export class FinanceSummaryUnstyled extends React.Component<FinanceSummaryProps, any> {
  state = {
    classes: undefined,
    sale_invoicenumber: undefined,
    data: Object.keys(columnsDict).map((col) => ({ column: columnsDict[col] })),
    loading: false,
    foreignCostBarcode: undefined,
    advanceRemittanceId: undefined,
    finalRemittanceId: undefined,
    selectedInvoiceNumber: undefined,
    selectedInvoiceNumberImpalaFee: undefined,
  };

  constructor(props) {
    super(props);
    this.state.classes = props.classes;
    this.state.sale_invoicenumber = props.sale_invoicenumber;
  }

  componentDidMount() {
    this.loadData();
  }

  componentDidUpdate(previousProps, previousState) {
    if (previousProps.sale_invoicenumber !== this.props.sale_invoicenumber) {
      this.setState({ sale_invoicenumber: this.props.sale_invoicenumber }, () => {
        this.loadData();
      });
    }
  }

  loadData = async () => {
    if (this.state.sale_invoicenumber) {
      this.setState({ loading: true });
      await getFinanceSummary(this.state.sale_invoicenumber, getFinancialYearSelected())
        .then((result: any) => {
          const tableData = Object.keys(result.data[0]).reduce((acc, key) => {
            if (["barcode_id", "impala_fee_zar", "local_cost_zar", "nettsaletotal_zar", "camend_status", "invoicenumber"].includes(key)) return acc;

            let row = {
              column: columnsDict[key],
              barcode_id: undefined,
              advance_ident: undefined,
              final_ident: undefined,
              producer_id: undefined,
              zar: undefined,
              camend_status: 0,
              invoicenumber: undefined,
            };

            result.data.forEach((item, indx) => {
              row = {
                ...row,
                [`column${indx + 1}`]: item[key],
                barcode_id: item.barcode_id,
                advance_ident: item.advance_ident,
                final_ident: item.final_ident,
                producer_id: item.producer_id,
                zar: item[`${key}_zar`],
                camend_status: item.camend_status,
                invoicenumber: item.invoicenumber,
              };
            });

            acc.push(row);
            return acc;
          }, []);
          this.setState({ data: tableData, loading: false });
        })
        .catch((err) => {
          console.log("error->", err);
        });
    }
  };

  handleForeignCostInfo = (row) => {
    this.setState({ foreignCostBarcode: row ? row.barcode_id : undefined });
  };

  handleLocalCostInfo = (row) => {
    this.setState({ selectedInvoiceNumber: row ? row.invoicenumber : undefined });
  };

  handleImpalaFeeInfo = (row) => {
    this.setState({ selectedInvoiceNumberImpalaFee: row ? row.invoicenumber : undefined });
  };

  handleAdvanceRemittanceInfo = (row) => {
    this.setState({ advanceRemittanceId: row });
  };

  handleFinalRemittanceInfo = (finalRemittanceId) => {
    this.setState({ finalRemittanceId: finalRemittanceId });
  };

  handleAdvanceSelect = (row) => {
    window.open(`/producers/grouped/${row.producer_id}`);
  };

  rowRenderer = (props, classes) => {
    const { rowIdx } = props;
    const style = rowIdx === 0 ? classes.boldRow : classes.normalRow;

    return <Row {...props} className={style} />;
  };

  render() {
    const { classes } = this.state;
    const summaryColumns = [
      "cartons",
      "palletsize",
      "sale_total",
      "sale_debit",
      "sale_credit",
      "foreigncost",
      "nettsaletotal",
      "local_cost",
      "impala_fee",
      "available",
      "nettsaletotal_zar",
      "local_cost_zar",
      "impala_fee_zar",
      "available_zar",
      "producer_advance",
      "producer_final",
      "producer_total",
    ];

    return (
      <div className={classes.root}>
        <div className={classes.toolbarWrapper}>
          <Toolbar className={classes.toolbar}>
            <h2>Finance Summary</h2>
          </Toolbar>
          <Grid
            loading={this.state.loading}
            data={this.state.data}
            GridColumns={(data, filters, columnArrangement, columnsWidth) =>
              GridColumns(
                data,
                filters,
                columnArrangement,
                columnsWidth,
                this.handleForeignCostInfo,
                this.handleAdvanceRemittanceInfo,
                this.handleFinalRemittanceInfo,
                this.handleLocalCostInfo,
                this.handleImpalaFeeInfo,
                this.handleAdvanceSelect,
              )
            }
            forceHeight={this.props.forceheight}
            clearFilters={"financesummarygrid"}
            totalRowColumns={summaryColumns}
            rowRenderer={(props) => this.rowRenderer(props, classes)}
          />
        </div>
        {this.state.foreignCostBarcode && (
          <Confirmation
            title={"Foreign Cost Info"}
            isOpen={true}
            handleClose={() => this.handleForeignCostInfo(undefined)}
            handleConfirm={() => this.handleForeignCostInfo(undefined)}
            body={undefined}
          >
            <ForeignCostInfo barcodeid={this.state.foreignCostBarcode} />
          </Confirmation>
        )}
        {this.state.selectedInvoiceNumber && (
          <Confirmation
            title={"Local Cost Info"}
            isOpen={true}
            handleClose={() => this.handleLocalCostInfo(undefined)}
            handleConfirm={() => this.handleLocalCostInfo(undefined)}
            body={undefined}
          >
            <LocalCostInfo invoicenumber={this.state.selectedInvoiceNumber} />
          </Confirmation>
        )}
        {this.state.selectedInvoiceNumberImpalaFee && (
          <Confirmation
            title={"Impala Fee Info"}
            isOpen={true}
            handleClose={() => this.handleImpalaFeeInfo(undefined)}
            handleConfirm={() => this.handleImpalaFeeInfo(undefined)}
            body={undefined}
          >
            <ImpalaFeeInfo invoicenumber={this.state.selectedInvoiceNumberImpalaFee} />
          </Confirmation>
        )}
        {this.state.advanceRemittanceId && (
          <Confirmation
            title={"Producer Advance Remittance"}
            isOpen={true}
            handleClose={() => this.handleAdvanceRemittanceInfo(undefined)}
            handleConfirm={() => this.handleAdvanceRemittanceInfo(undefined)}
            body={undefined}
          >
            <ProducerAdvanceInfo produceradvancepaidhead_ident={this.state.advanceRemittanceId} />
          </Confirmation>
        )}
        {this.state.finalRemittanceId && (
          <Confirmation
            title={"Producer Final Remittance"}
            isOpen={true}
            handleClose={() => this.handleFinalRemittanceInfo(undefined)}
            handleConfirm={() => this.handleFinalRemittanceInfo(undefined)}
            body={undefined}
          >
            <ProducerFinalInfo finalRemittanceId={this.state.finalRemittanceId} />
          </Confirmation>
        )}
      </div>
    );
  }
}

export default withStyles(styles)(FinanceSummaryUnstyled);
