import React, { useEffect, useState } from "react";

import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";

import Grid from "../../lib/components/grid";
import { GridColumns } from "./barcodepalletsizegridsetup";
import { systemstabilitybarcodepalletsize } from "../../lib/api/systemstability";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1),
    },
  });

type BarcodePalletSizeProps = {} & WithStyles<typeof styles>;

const BarcodePalletSizeUnstyled: React.FC<BarcodePalletSizeProps> = () => {
  const [data, setData] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const loadData = async () => {
    setLoading(true);
    const result = await systemstabilitybarcodepalletsize();
    setData(result);
    setLoading(false);
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <div>
      <Grid data={data} loading={loading} GridColumns={GridColumns} clearFilters={"systemstabilitybarcodepalletsize"} handleRefresh={loadData} />
    </div>
  );
};

export default withStyles(styles)(BarcodePalletSizeUnstyled);
