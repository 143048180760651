import { apiHeaders, client, request } from "./_base";

const endpoint = "estimatedcostitem";
export const estimatedcostitems = client(endpoint, (item) => ({
  ...item,
}));

export const estimatedcostitemFull = () => {
  return request(`${endpoint}/ext/full`, {
    headers: apiHeaders(),
  }).then(async (result) => {
    if (result.status == 500) {
      const resp = await result.json();
      throw resp;
    }
    return result.json();
  });
};

export const estimatedcostitemsAllSortedMappedforCombo = async () => {
  const result = await estimatedcostitems.all().then((data) => {
    return data
      .sort((a, b) => {
        if (a.costdescription > b.costdescription) return 1;
        if (a.costdescription < b.costdescription) return -1;
        return 1;
      })
      .map((item) => {
        return { id: item.id, value: item.id, display: `${item.costdescription} ${item.type == 0 ? "" : item.type == 1 ? "" : ""}` };
      });
  });
  return result;
};
