import React, { useMemo } from "react";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";

import { Form, Field } from "react-final-form";
import { TextField, Select } from "final-form-material-ui";

import Tooltip from "@material-ui/core/Tooltip";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import Checkbox from "@material-ui/core/Checkbox";

import IconEdit from "@material-ui/icons/Edit";
import IconAdd from "@material-ui/icons/Add";

import Confirmation from "../../../lib/components/confirmation";
import { getCreditorsReadyForCombo } from "../../../lib/api/creditors";
import {
  creditorsinvoicedetailitemstemplate,
  getItemsTemplateDescComboRead,
  creditorsinvoicedetailitemstemplateByCredDesc,
} from "../../../lib/api/creditorsinvoicedetailitemstemplate";
import {
  creditorsinvoicedetailitemstemplatedetail,
  creditorsinvoicedetailitemstemplatedetailByTemplateID,
  creditorsinvoicedetailitemstemplatedetailDeleteByTemplateID,
  CreditorsInvoiceDetailItemTemplateDetailType,
} from "../../../lib/api/creditorsinvoicedetailitemstemplatedetail";
import { VatTypes } from "../../../lib/api/vattypes";
import TemplateForm from "./creditorsinvoicedetailitemstemplateeditform";

import CreditorsItemsTemplateItems from "./creditorsitemtemplateitems";
import { OrangeButton } from "../../../lib/components/ColorButtons";
import { LinearProgress } from "@material-ui/core";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1),
      width: "100%",
      height: "100%",
      display: "grid",
    },
    tableCellLabel: {
      width: "100px",
      borderBottom: "none",
      height: "50px",
      textAlign: "right",
      paddingTop: "5px",
      paddingRight: "5px",
    },
    tableCellDetail: {
      width: "300px",
      borderBottom: "none",
      height: "50px",
    },
    tableCellAction: {
      width: "300px",
      borderBottom: "none",
      height: "50px",
    },
    tableCellsValueSelect: {
      textAlign: "left",
      width: "100%",
      borderBottom: "none",
      height: "50px",
      padding: 0,
      marginTop: "-14px",
    },
    gridColumnsWrapper: {
      display: "grid",
      gridTemplateRows: "repeat(2, 1fr)",
      marginTop: "10px",
    },
    tableCellWrapper: {
      display: "grid",
      gridTemplateColumns: "100px 300px",
      gridColumnGap: "10px",
    },
    checkbox: {
      paddingTop: "4px",
      paddingBottom: "4px",
      paddingLeft: "0",
      paddingRight: "0",
    },
    toolbarWrapper: {
      display: "flex",
      flexDirection: "row",
      gap: `${theme.spacing(1)}px`,
    },
    descriptionWrapper: {
      display: "flex",
      flexDirection: "row",
      gap: `${theme.spacing(2)}px`,
    },
    actionButtons: {
      display: "flex",
      flexDirection: "row",
      gap: `${theme.spacing(1)}px`,
    },
  });

type CreditorsItemTemplateProps = {
  id?: number;
  handleClose(): void;
} & WithStyles<typeof styles>;

export class CreditorsItemsTemplateUnstyled extends React.Component<CreditorsItemTemplateProps, any> {
  state = {
    classes: undefined,
    creditorsDataCombo: [],
    creditorsTemplatesCombo: [],
    currentTemplate: {
      id: 0,
      creditors_id: 0,
      templatedesc: "",
      items: [],
    },
    busyAddingNew: false,
    templateId: undefined,
    allTemplates: [],
    addDescription: undefined,
    loading: true,
  };

  constructor(props) {
    super(props);
    this.state.classes = props.classes;
    this.state.templateId = props.id;

    this.loadData();
  }

  calculateItemsTotal = (data) => {
    const totalled = data.map((row) => {
      const vatV = Number(row["vat"]) == -1 ? 0 : Number(row["vat"]) / 100;
      const rateV = Number(row["rate"]);
      const unitV = Number(row["units"]);

      let vatvalue = rateV * unitV * vatV;
      if (isNaN(vatvalue)) {
        vatvalue = 0;
      }
      row["vatamount"] = vatvalue;

      let totalvalue = rateV * unitV * vatV + rateV * unitV;
      if (isNaN(totalvalue)) {
        totalvalue = 0;
      }
      row["total"] = totalvalue;

      return { ...row };
    });
    return totalled;
  };

  loadData = async () => {
    this.setState({ loading: true });
    const creditorsDataCombo = await getCreditorsReadyForCombo();
    const allTemplates = await creditorsinvoicedetailitemstemplate.all();

    this.setState({ creditorsDataCombo: creditorsDataCombo, allTemplates: allTemplates });

    if (this.state.templateId) {
      const template = await creditorsinvoicedetailitemstemplate.single(this.state.templateId);

      const result = await creditorsinvoicedetailitemstemplatedetailByTemplateID(this.state.templateId);
      const totalled = this.calculateItemsTotal(result);

      const data = await getItemsTemplateDescComboRead(template.creditors_id);

      this.setState({
        currentTemplate: { ...template, items: totalled },
        creditorsTemplatesCombo: data,
      });
    }
    this.setState({ loading: false });
  };

  handleSubmit = async (values) => {
    this.setState({ loading: true });
    if (values.creditors_id && values.templatedesc && values.templatedesc.length > 0) {
      const dataTemplate = {
        data: {
          creditors_id: values.creditors_id,
          templatedesc: values.templatedesc,
          default: values.default ? 1 : 0,
        },
      };
      let id = this.state.currentTemplate.id;
      if (this.state.currentTemplate.id == 0) {
        const result = await creditorsinvoicedetailitemstemplate.create(dataTemplate);
        id = result[0];
      } else {
        await creditorsinvoicedetailitemstemplate.update(this.state.currentTemplate.id, dataTemplate);
      }

      await creditorsinvoicedetailitemstemplatedetailDeleteByTemplateID(id);
      this.state.currentTemplate.items.map(async (item: CreditorsInvoiceDetailItemTemplateDetailType) => {
        const updateItem = {
          data: {
            creditorsinvoicedetailitemstemplate_id: id,
            sequence: item.sequence,
            code: item.code,
            chargedescription: item.chargedescription,
            rate: item.rate || 0,
            units: item.units || 0,
            vat: item.vat,
          },
        };
        await creditorsinvoicedetailitemstemplatedetail.create(updateItem);
      });

      this.setState({ currentTemplate: { ...this.state.currentTemplate, id: id, templatedesc: values.templatedesc }, templateId: id }, () => {
        this.loadData();
      });
    }
  };

  handleChangeCreditor = async (creditorsid, applyAddingNew = false) => {
    if (creditorsid) {
      await getItemsTemplateDescComboRead(creditorsid).then((result) => {
        this.setState({ creditorsTemplatesCombo: result }, () => {
          this.handleNewTemplate(creditorsid, applyAddingNew);
        });
      });
    }
  };

  handleChangeDescription = async (template) => {
    if (template) {
      this.setState({ currentTemplate: template }, () => {
        this.loadTemplate();
      });
    }
  };

  loadTemplate = async () => {
    const template = await creditorsinvoicedetailitemstemplateByCredDesc(this.state.currentTemplate.creditors_id, this.state.currentTemplate.templatedesc);
    if (template && template.length > 0) {
      this.setState(
        {
          currentTemplate: {
            ...this.state.currentTemplate,
            id: template[0].id,
            creditors_id: template[0].creditors_id,
            templatedesc: template[0].templatedesc,
            default: template[0].default,
          },
        },
        () => {
          this.loadTemplateItem();
        },
      );
    }
  };

  loadTemplateItem = async () => {
    const result = await creditorsinvoicedetailitemstemplatedetailByTemplateID(this.state.currentTemplate.id);
    if (result) {
      this.setState({ currentTemplate: { ...this.state.currentTemplate, items: result } }, () => {});
    }
  };

  handleNewTemplate = (creditorsid = 0, applyAddingNew = true) => {
    this.setState({
      busyAddingNew: applyAddingNew,
      currentTemplate: {
        id: 0,
        creditors_id: creditorsid,
        templatedesc: "",
        items: [],
      },
    });
  };

  handleAddRow = () => {
    const maxSequence = Math.max.apply(
      Math,
      this.state.currentTemplate.items.map((item) => item.sequence),
    );
    const newRow = {
      id: maxSequence && !isNaN(maxSequence) && Math.abs(maxSequence) != Infinity ? maxSequence + 1 : 1,
      creditorsinvoicedetailitemstemplate_id: this.state.currentTemplate.id,
      sequence: maxSequence && !isNaN(maxSequence) && Math.abs(maxSequence) != Infinity ? maxSequence + 1 : 1,
      code: null,
      chargedescription: null,
      rate: 0,
      units: 0,
      vat: null,
    };
    this.setState({ currentTemplate: { ...this.state.currentTemplate, items: [...this.state.currentTemplate.items, newRow] } }, () => {});
  };

  handleRemove = (idx) => {
    const filtered = this.state.currentTemplate.items.filter((item) => item.id != idx);
    this.setState({ currentTemplate: { ...this.state.currentTemplate, items: filtered } });
  };

  handleChange = (property: string, values: any[]) => {
    const formattedValues = values.map((item) => ({ ...item, vat: isNaN(item.vat) ? parseInt(VatTypes[item.vat]) : item.vat }));
    const totalled = this.calculateItemsTotal(formattedValues);
    this.setState({ currentTemplate: { ...this.state.currentTemplate, items: totalled } }, () => {});
  };

  handleCopy = (row) => {
    const rowIdx = this.state.currentTemplate.items.findIndex((item) => item.id == row.id);
    const currentTemplateItems = [...this.state.currentTemplate.items];
    const maxSequence = Math.max.apply(
      Math,
      this.state.currentTemplate.items.map((item) => item.sequence),
    );
    // insert new record into index
    currentTemplateItems.splice(rowIdx, 0, { ...row, id: maxSequence && !isNaN(maxSequence) && Math.abs(maxSequence) != Infinity ? maxSequence + 1 : 1 });
    // set correct sequence
    const sequenced = currentTemplateItems.map((item, indx) => ({ ...item, sequence: indx + 1 }));
    this.setState({ currentTemplate: { ...this.state.currentTemplate, items: sequenced } });
  };

  handleAddDescription = (values) => {
    this.setState({ addDescription: { ...values, templatedesc: "" } });
  };

  handleEditDescription = (values) => {
    this.setState({ addDescription: values });
  };

  handleCloseDescription = (id: number) => {
    this.setState({ addDescription: false });
    if (id) {
      this.setState({ templateId: id, addDescription: false });
      this.loadData();
    }
  };

  render() {
    const { classes } = this.state;

    return (
      <div className={classes.root}>
        {this.state.loading && <LinearProgress color="secondary" />}
        <div>
          <Form
            initialValues={{ ...this.state.currentTemplate }}
            onSubmit={(values) => {
              const submitData = { ...values };
              this.handleSubmit(submitData);
            }}
            validate={(values) => {
              let errors = {};
              if (this.state.currentTemplate.creditors_id != values["creditors_id"]) {
                this.handleChangeCreditor(values["creditors_id"]);
              }
              if (this.state.currentTemplate.templatedesc != values["templatedesc"]) {
                this.handleChangeDescription(values);
              }
              return errors;
            }}
            render={({ handleSubmit, form, values }: any) => (
              <form onSubmit={handleSubmit}>
                {this.state.addDescription && (
                  <Confirmation isOpen={true} handleClose={() => this.handleCloseDescription(undefined)} handleConfirm={() => {}} title={"Add Description"} body={undefined}>
                    <TemplateForm defaultData={this.state.addDescription} handleClose={this.handleCloseDescription} />
                  </Confirmation>
                )}
                <div className={classes.toolbarWrapper}>
                  <Tooltip style={{ zIndex: 0 }} title="Save">
                    <Button variant="contained" color="primary" type="submit">
                      save
                    </Button>
                  </Tooltip>
                  <Tooltip style={{ zIndex: 0 }} title="Back">
                    <Button variant="contained" color="secondary" type="button" onClick={this.props.handleClose}>
                      Back
                    </Button>
                  </Tooltip>
                </div>
                <div className={classes.gridColumnsWrapper}>
                  <TableFieldCombo classes={classes} field="creditors_id" title="Creditor" data={this.state.creditorsDataCombo} addEmptyValue={undefined} />
                  <div className={classes.descriptionWrapper}>
                    <TableFieldCombo classes={classes} field="templatedesc" title="Description" data={this.state.creditorsTemplatesCombo} addEmptyValue={undefined} />
                    <div className={classes.actionButtons}>
                      <div>
                        <Button variant="contained" color="primary" type="button" onClick={() => this.handleAddDescription(values)}>
                          <IconAdd />
                        </Button>
                      </div>
                      {values["templatedesc"] && (
                        <div>
                          <OrangeButton variant="contained" type="button" onClick={() => this.handleEditDescription(values)}>
                            <IconEdit />
                          </OrangeButton>
                        </div>
                      )}
                    </div>
                  </div>
                  <TableFieldCheckbox classes={classes} field="default" name="Default" allTemplates={this.state.allTemplates} values={values} />
                </div>
              </form>
            )}
          />
        </div>
        {this.state.currentTemplate.id != 0 && (
          <CreditorsItemsTemplateItems
            data={this.state.currentTemplate.items}
            handleCopy={this.handleCopy}
            handleChange={this.handleChange}
            handleAddRow={this.handleAddRow}
            handleRemoveConfirm={this.handleRemove}
          />
        )}
      </div>
    );
  }
}

export default withStyles(styles)(CreditorsItemsTemplateUnstyled);

const TableFieldCombo: React.FunctionComponent<
  { classes: any; title: string; field: string; data: any; addEmptyValue: string; actionHandler?: any } & WithStyles<typeof styles>
> = (props) => {
  const { classes, title, field, data, addEmptyValue, actionHandler } = props;
  return (
    <span className={classes.tableCellWrapper}>
      <span className={classes.tableCellLabel}>{`${title}:`}</span>
      <span className={classes.tableCellDetail}>
        <Field fluid name={field} component={Select} fullWidth={true} formControlProps={{ className: classes.tableCellsValueSelect }}>
          {addEmptyValue && (
            <MenuItem key={`emptyvalueMenuItem`} value={-1}>
              {addEmptyValue}
            </MenuItem>
          )}
          {data &&
            data.map((item, index) => {
              return (
                <MenuItem key={`${item.value}${index}`} value={item.value}>
                  {item.display}
                </MenuItem>
              );
            })}
        </Field>
      </span>
      {actionHandler && (
        <span className={classes.tableCellAction}>
          <IconEdit style={{ cursor: "pointer", color: "orange" }} onClick={actionHandler} />
        </span>
      )}
    </span>
  );
};

const TableFieldText: React.FunctionComponent<{ field: string; title: string; disabled: boolean } & WithStyles<typeof styles>> = (props) => {
  const { classes, field, title, disabled } = props;
  return (
    <span className={classes.tableCellWrapper}>
      <span className={classes.tableCellLabel}>{`${title}:`}</span>
      <span className={classes.tableCellDetail}>
        <Field name={field} component={TextField} type="text" fullWidth={true} disabled={disabled} />
      </span>
    </span>
  );
};

const TableFieldCheckbox: React.FunctionComponent<{ classes; field; name; allTemplates; values }> = (props) => {
  const { classes, field, name, allTemplates, values } = props;

  const disabled = useMemo(() => {
    const isDisabled = allTemplates.filter((item) => item.creditors_id == values.creditors_id && item.default == 1 && item.id != values.id);
    return isDisabled.length > 0;
  }, [allTemplates, values]);

  return (
    <span className={classes.tableCellWrapper}>
      <span className={classes.tableCellLabel}>{`${name}:`}</span>
      <span className={classes.tableCellDetail}>
        <Field name={field} type="checkbox">
          {({ input, meta }) => <Checkbox className={classes.checkbox} {...input} {...meta} disabled={disabled} />}
        </Field>
      </span>
    </span>
  );
};
