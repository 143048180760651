import { client, request } from "./_base";

export type CreditorsInvoiceDetailItemTemplateDetailType = {
  id: number;
  creditorsinvoicedetailitemstemplate_id: number;
  sequence: number;
  group: number;
  code: string;
  chargedescription: string;
  rate: number;
  units: number;
  vat: number;
};

const endpoint = "creditorsinvoicedetailitemstemplatedetail";

export const creditorsinvoicedetailitemstemplatedetail = client(`${endpoint}`, (item) => ({
  ...item,
}));

export const creditorsinvoicedetailitemstemplatedetailByTemplateID = async (templateid) => {
  return await request(`${endpoint}/ext/byTemplateID/${templateid}`)
    .then(async (result) => {
      if (result.status == 500) {
        const resp = await result.json();
        throw resp;
      }
      return result.json();
    })
    .then(({ data }) => data);
};

export const creditorsinvoicedetailitemstemplatedetailDeleteByTemplateID = async (templateid) => {
  return await request(`${endpoint}/ext/deleteByID/${templateid}`)
    .then(async (result) => {
      if (result.status == 500) {
        const resp = await result.json();
        throw resp;
      }
      return result.json();
    })
    .then(({ data }) => data);
};
