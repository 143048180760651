import React from "react";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";
import Grid from "../../../lib/components/grid";
import { GridColumns } from "./gridsetup";
import { getImpalaFeeInfo } from "../../../lib/api/consolidation";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1),
      width: "800px",
    },
  });

type Props = {
  invoicenumber: string;
} & WithStyles<typeof styles>;

class LocalCostInfoGridUnstyled extends React.Component<Props, any> {
  constructor(props) {
    super(props);
  }

  state = {
    data: [],
    loading: true,
  };

  componentDidMount() {
    this.loadData();
  }

  loadData = async () => {
    this.setState({ loading: true });
    const resp = await getImpalaFeeInfo(this.props.invoicenumber);
    this.setState({ loading: false, data: resp });
  };

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <Grid
          forceHeight={600}
          data={this.state.data}
          GridColumns={GridColumns}
          loading={this.state.loading}
          clearFilters={"financeimpalafeegrid"}
          totalRowColumns={["noCartons", "palletSize", "impalafee", "impalafee_zar", "marginperc"]}
        />
      </div>
    );
  }
}

export default withStyles(styles)(LocalCostInfoGridUnstyled);
