import React from "react";
import CustomAutoCompleteFilter from "../lib/components/customdatagridfilter/CustomFilter";

import TextEditor from "../lib/components/TextEditor";
import { SummaryNumber, SummaryNumber2Decimal, SummaryTotal } from "../lib/components/SummaryFormatter";
import { FormatterNumber, FormatterNumber2Decimals } from "../lib/components/Formatters";
import { EnquiryLink, Selector } from "../lib/components/EnquiryLink";
import { handleColumnsArrangement, handleColumnsWidth } from "../lib/helpers/grid";

import { withStyles, createStyles, Theme } from "@material-ui/core/styles";
import { SellingTerms } from "../lib/api/producersellingterms";

const cellWidthSmallX = 90;
const cellWidthSmall = 115;
const cellWidthMedium = 150;
const cellWidthLarge = 220;

const defaultColumnProperties = {
  filterable: true,
  resizable: true,
  sortable: true,
};

let currentSpace = true;

export const GridColumnsPallets = (data, filters, arrangement, columnsWidth, sellingTerms = []) => {
  let postValue = "";

  if (data) {
    if (currentSpace) {
      postValue = " ";
    }
    currentSpace = !currentSpace;
  }

  const columns = [
    {
      key: "loadoutdetail_barcode",
      name: "Barcode" + postValue,
      width: cellWidthLarge,
      formatter: (props) => <EnquiryLink {...props} selector={Selector.Barcode} />,
      filterRenderer: (e) => {
        return (
          <CustomAutoCompleteFilter
            {...e}
            multiSelection={true}
            option={data}
            currentFilters={filters}
            fieldname={"loadoutdetail_barcode"}
            data={data}
            section={"invoicepallets"}
          />
        );
      },
      summaryFormatter: SummaryTotal,
    },
    {
      key: "producer_code",
      name: "Producer" + postValue,
      width: cellWidthSmall,
      filterable: false,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"producer_code"} data={data} section={"invoicepallets"} />;
      },
    },
    {
      key: "loadout_reference",
      name: "Reference" + postValue,
      width: cellWidthSmall,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"loadout_reference"} data={data} section={"invoicepallets"} />;
      },
    },
    {
      key: "barcode_commoditycode",
      name: "Commodity" + postValue,
      width: cellWidthSmallX,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"barcode_commoditycode"} data={data} section={"invoicepallets"} />;
      },
    },
    {
      key: "barcode_varietycode",
      name: "Variety" + postValue,
      width: cellWidthSmallX,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"barcode_varietycode"} data={data} section={"invoicepallets"} />;
      },
    },
    {
      key: "barcode_packcode",
      name: "Pack" + postValue,
      width: cellWidthSmallX,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"barcode_packcode"} data={data} section={"invoicepallets"} />;
      },
    },
    {
      key: "barcode_markcode",
      name: "Mark" + postValue,
      width: cellWidthSmallX,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"barcode_markcode"} data={data} section={"invoicepallets"} />;
      },
    },
    {
      key: "barcode_gradecode",
      name: "Grade" + postValue,
      width: cellWidthSmallX,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"barcode_gradecode"} data={data} section={"invoicepallets"} />;
      },
    },
    {
      key: "barcode_countcode",
      name: "Count" + postValue,
      width: cellWidthSmallX,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"barcode_countcode"} data={data} section={"invoicepallets"} />;
      },
    },
    {
      key: "barcode_inventorycode",
      name: "Inventory" + postValue,
      width: cellWidthSmallX,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"barcode_inventorycode"} data={data} section={"invoicepallets"} />;
      },
    },
    {
      key: "barcode_palletsize",
      name: "Pallet Size" + postValue,
      width: cellWidthSmallX,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"barcode_palletsize"} data={data} section={"invoicepallets"} />;
      },
      formatter: FormatterNumber2Decimals,
      summaryFormatter: SummaryNumber2Decimal,
    },
    {
      key: "barcode_nocartons",
      name: "No Cartons" + postValue,
      width: cellWidthSmallX,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"barcode_nocartons"} data={data} section={"invoicepallets"} />;
      },
      formatter: FormatterNumber,
      summaryFormatter: SummaryNumber,
    },
    {
      key: "grossWeight",
      name: "G Weight" + postValue,
      width: cellWidthSmallX,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"grossWeight"} data={data} section={"invoicepallets"} />;
      },
      formatter: FormatterNumber2Decimals,
      summaryFormatter: SummaryNumber2Decimal,
    },
    {
      key: "weight",
      name: "N Weight" + postValue,
      width: cellWidthSmallX,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"weight"} data={data} section={"invoicepallets"} />;
      },
      formatter: FormatterNumber2Decimals,
      summaryFormatter: SummaryNumber2Decimal,
    },
    {
      key: "stockdetail_sellingprice",
      name: "Selling Price" + postValue,
      width: cellWidthSmall,
      editable: true,
      editor: (props) => <TextEditor {...props} align="right" />,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"stockdetail_sellingprice"} data={data} section={"invoicepallets"} />;
      },
      formatter: FormatterNumber2Decimals,
      summaryFormatter: SummaryNumber2Decimal,
    },
    {
      key: "total",
      name: "Total" + postValue,
      width: cellWidthSmall,
      filterable: false,
      formatter: FormatterNumber2Decimals,
      summaryFormatter: SummaryNumber2Decimal,
    },
    {
      key: "comment",
      name: "Comment" + postValue,
      width: cellWidthSmall,
      filterable: false,
    },
    {
      key: "target_market",
      name: "TM" + postValue,
      width: cellWidthSmallX,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"target_market"} data={data} section={"invoicepallets"} />;
      },
    },
    // {
    //   key: "ld_fob",
    //   name: "FOB" + postValue,
    //   width: cellWidthSmall,
    //   filterRenderer: (e) => {
    //     return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"ld_fob"} data={data} section={"invoicepallets"} />;
    //   },
    //   formatter: FormatterNumber2Decimals,
    //   summaryFormatter: SummaryNumber2Decimal,
    // },
    // {
    //   key: "ld_dip",
    //   name: "DIP" + postValue,
    //   width: cellWidthSmall,
    //   filterRenderer: (e) => {
    //     return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"ld_dip"} data={data} section={"invoicepallets"} />;
    //   },
    //   formatter: FormatterNumber2Decimals,
    //   summaryFormatter: SummaryNumber2Decimal,
    // },
  ].map((item) => ({ ...item, ...defaultColumnProperties }));

  if (sellingTerms.length > 0) {
    const purchaseDealType: any = {
      key: "purchase_deal_type",
      name: "Purchase Deal Type" + postValue,
      width: cellWidthSmall,
      filterable: false,
      editable: true,
      editor: (props) => <DropDownEditor {...props} options={sellingTerms} />,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"purchase_deal_type"} data={data} section={"invoicepallets"} />;
      },
      formatter: ({ row, column }) => SellingTerms[row[column.key]],
      ...defaultColumnProperties,
    };
    columns.splice(2, 0, purchaseDealType);
  }

  let columnsArranged = [];
  columnsArranged = handleColumnsArrangement(columnsArranged, arrangement, columns);
  columnsArranged = handleColumnsWidth(columnsArranged, columnsWidth, columns);

  return columnsArranged;
};

const styles = (theme: Theme) =>
  createStyles({
    root: {
      appearance: "none",
      boxSizing: "border-box",
      width: "100%",
      height: "100%",
      padding: "0px 6px 0 6px",
      border: "2px solid #ccc",
      verticalAlign: "top",
      color: "black",
      textAlign: "left",
      fontSize: "15px",
      "&:focus": {
        borderColor: "black",
        outline: "none",
      },
      "&::placeholder": {
        color: "#999",
        opacity: 1,
      },
    },
  });

const DropDownEditorUnstyled = ({ classes, row, column, onRowChange, options }) => {
  return (
    <select autoFocus value={row[column.key]} onChange={(event) => onRowChange({ ...row, [column.key]: event.target.value }, true)} className={classes.root}>
      <option key={""} value={""}></option>
      {options.map((option) => (
        <option key={option.value} value={option.value}>
          {option.display}
        </option>
      ))}
    </select>
  );
};

const DropDownEditor = withStyles(styles)(DropDownEditorUnstyled);
