import React from "react";
import CustomAutoCompleteFilter from "../lib/components/customdatagridfilter/CustomFilter";
import numeral from "numeral";

const cellWidthSmall = 115;
const cellWidthMedium = 150;

import { FormatterNumber2Decimals } from "../lib/components/Formatters";

let currentSpace = true;

export const GridColumnsPrevious = (data, filters) => {
  let postValue = "";

  if (data) {
    if (currentSpace) {
      postValue = " ";
    }
    currentSpace = !currentSpace;
  }

  const columns = [
    {
      key: "reference",
      name: "Reference" + postValue,
      filterable: true,
      sortable: true,
      resizable: true,
      width: cellWidthSmall,
      filterRenderer: (e) => {
        return (
          <CustomAutoCompleteFilter
            {...e}
            multiSelection={true}
            currentFilters={filters}
            fieldname={"reference"}
            data={data}
            section="stockpricingprevious"
            enableAutoFilter={true}
          />
        );
      },
    },
    {
      key: "consigneeId",
      name: "Client" + postValue,
      filterable: true,
      sortable: true,
      resizable: true,
      width: cellWidthSmall,
      filterRenderer: (e) => {
        return (
          <CustomAutoCompleteFilter
            {...e}
            multiSelection={true}
            currentFilters={filters}
            fieldname={"consigneeId"}
            data={data}
            section="stockpricingprevious"
            enableAutoFilter={true}
          />
        );
      },
    },
    {
      key: "producerId",
      name: "Producer" + postValue,
      filterable: true,
      sortable: true,
      resizable: true,
      width: cellWidthSmall,
      filterRenderer: (e) => {
        return (
          <CustomAutoCompleteFilter
            {...e}
            multiSelection={true}
            currentFilters={filters}
            fieldname={"producerId"}
            data={data}
            section="stockpricingprevious"
            enableAutoFilter={true}
          />
        );
      },
    },
    {
      key: "commodityCode",
      name: "Commodity" + postValue,
      filterable: true,
      sortable: true,
      resizable: true,
      width: cellWidthSmall,
      filterRenderer: (e) => {
        return (
          <CustomAutoCompleteFilter
            {...e}
            multiSelection={true}
            currentFilters={filters}
            fieldname={"commodityCode"}
            data={data}
            section="stockpricingprevious"
            enableAutoFilter={true}
          />
        );
      },
    },
    {
      key: "varietyCode",
      name: "Variety" + postValue,
      filterable: true,
      sortable: true,
      resizable: true,
      width: cellWidthSmall,
      filterRenderer: (e) => {
        return (
          <CustomAutoCompleteFilter
            {...e}
            multiSelection={true}
            currentFilters={filters}
            fieldname={"varietyCode"}
            data={data}
            section="stockpricingprevious"
            enableAutoFilter={true}
          />
        );
      },
    },
    {
      key: "gradeCode",
      name: "Grade" + postValue,
      filterable: true,
      sortable: true,
      resizable: true,
      width: cellWidthSmall,
      filterRenderer: (e) => {
        return (
          <CustomAutoCompleteFilter
            {...e}
            multiSelection={true}
            currentFilters={filters}
            fieldname={"gradeCode"}
            data={data}
            section="stockpricingprevious"
            enableAutoFilter={true}
          />
        );
      },
    },
    {
      key: "countCode",
      name: "Count" + postValue,
      filterable: true,
      sortable: true,
      resizable: true,
      width: cellWidthSmall,
      filterRenderer: (e) => {
        return (
          <CustomAutoCompleteFilter
            {...e}
            multiSelection={true}
            currentFilters={filters}
            fieldname={"countCode"}
            data={data}
            section="stockpricingprevious"
            enableAutoFilter={true}
          />
        );
      },
    },
    {
      key: "packCode",
      name: "Pack" + postValue,
      filterable: true,
      sortable: true,
      resizable: true,
      width: cellWidthSmall,
      filterRenderer: (e) => {
        return (
          <CustomAutoCompleteFilter
            {...e}
            multiSelection={true}
            currentFilters={filters}
            fieldname={"packCode"}
            data={data}
            section="stockpricingprevious"
            enableAutoFilter={true}
          />
        );
      },
    },
    {
      key: "markCode",
      name: "Mark" + postValue,
      filterable: true,
      sortable: true,
      resizable: true,
      width: cellWidthSmall,
      filterRenderer: (e) => {
        return (
          <CustomAutoCompleteFilter
            {...e}
            multiSelection={true}
            currentFilters={filters}
            fieldname={"markCode"}
            data={data}
            section="stockpricingprevious"
            enableAutoFilter={true}
          />
        );
      },
    },
    {
      key: "sellingprice",
      name: "Selling Price" + postValue,
      filterable: true,
      sortable: true,
      resizable: true,
      width: cellWidthSmall,
      filterRenderer: (e) => {
        return (
          <CustomAutoCompleteFilter
            {...e}
            multiSelection={true}
            currentFilters={filters}
            fieldname={"sellingprice"}
            data={data}
            section="stockpricingprevious"
            enableAutoFilter={true}
          />
        );
      },
      formatter: FormatterNumber2Decimals,
      editable: true,
    },
  ];

  return columns;
};
