import React from "react";
import CustomAutoCompleteFilter from "../../lib/components/customdatagridfilter/CustomFilter";

import Tooltip from "@material-ui/core/Tooltip";

import IconBrightness from "@material-ui/icons/Brightness1";

import { DispatcheddocsFullType } from "../../lib/api/dispatcheddocs";
import { StatusTypes } from "../../lib/types";
import { DateFormatter, FormatterNumber2Decimals } from "../../lib/components/Formatters";
import { handleColumnsArrangement, handleColumnsWidth } from "../../lib/helpers/grid";
import RightAlignHeaderColumn from "../../lib/components/gridhelper/RightAlignHeaderColumn";
import { dynamicWidths } from "../../lib/helpers/DynamicWidth";

const cellWidthTiny = 40;
const cellWidthSmallX = 80;
const cellWidthSmall = 115;
const cellWidthMedium = 130;
const cellWidthLarge = 150;

const StatusFormatter = ({ row, column }) => {
  const datarow: DispatcheddocsFullType = { ...row };
  const data = row[column.key];

  if (row["Dispatch Doc Ref"] == "Totals") {
    return <div></div>;
  }

  if (data == StatusTypes.INVOICED) {
    return (
      <div>
        <Tooltip title={`${StatusTypes.INVOICED} ${datarow.loadout_reference}`}>
          <IconBrightness style={{ color: "orangered", display: "block", margin: "auto", border: "1px solid #333", borderRadius: "100%" }} />
        </Tooltip>
      </div>
    );
  }
  if (data == StatusTypes.DISPATCHED) {
    return (
      <div>
        <Tooltip title={`CONFIRMED ${datarow.loadout_reference}`}>
          <IconBrightness style={{ color: "yellowgreen", display: "block", margin: "auto", border: "1px solid #333", borderRadius: "100%" }} />
        </Tooltip>
      </div>
    );
  }
  if (data == StatusTypes.LOADED) {
    return (
      <div>
        <Tooltip title={`${StatusTypes.LOADED} ${datarow.loadout_reference}`}>
          <IconBrightness style={{ color: "yellow", display: "block", margin: "auto", border: "1px solid #333", borderRadius: "100%" }} />
        </Tooltip>
      </div>
    );
  }

  if (data == StatusTypes.TRANSFERS) {
    return (
      <div>
        <Tooltip title={`${StatusTypes.TRANSFERS} ${datarow.loadout_reference}`}>
          <IconBrightness style={{ color: "#4761e6", display: "block", margin: "auto", border: "1px solid #333", borderRadius: "100%" }} />
        </Tooltip>
      </div>
    );
  }
  return (
    <div>
      <Tooltip title={`NEW ${datarow.loadout_reference}`}>
        <IconBrightness style={{ color: "orange", display: "block", margin: "auto", border: "1px solid #333", borderRadius: "100%" }} />
      </Tooltip>
    </div>
  );
};

const defaultColumnProperties = {
  resizable: true,
  sortable: true,
};

let currentSpace = true,
  calculatedWidths = {};

export const GridColumnsDispatches2 = (data, filters, arrangement, columnsWidth) => {
  let postValue = "";

  if (data) {
    if (currentSpace) {
      postValue = " ";
    }
    currentSpace = !currentSpace;
  }

  const cols = [
    {
      key: "Date",
      formatter: DateFormatter,
    },
    {
      key: "Location Code",
      formatter: null,
    },
    {
      key: "Dispatch ID",
      formatter: null,
    },
    {
      key: "loadout_reference",
      formatter: null,
    },
    {
      key: "Forwarding Agent Ref",
      formatter: null,
    },
    {
      key: "Dispatch Doc Ref",
      formatter: null,
    },
    {
      key: "Vessel",
      formatter: null,
    },
    {
      key: "Container No",
      formatter: null,
    },
    {
      key: "Total Cartons",
      formatter: FormatterNumber2Decimals,
    },
    {
      key: "Total Pallets",
      formatter: FormatterNumber2Decimals,
    },
  ];

  if (data.length > 0) {
    calculatedWidths = dynamicWidths(data, cols);
  }

  const columns = [
    {
      key: "status",
      name: "#S" + postValue,
      width: cellWidthSmallX,
      formatter: StatusFormatter,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"status"} data={data} section={"dispatchMerge1"} />;
      },
    },
    {
      key: "Date",
      name: "Date" + postValue,
      width: calculatedWidths["Date"] || cellWidthMedium,
      filterable: true,
      formatter: DateFormatter,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Date"} data={data} section={"dispatchMerge1"} />;
      },
    },
    {
      key: "Location Code",
      name: "Location Code" + postValue,
      width: calculatedWidths["Location Code"] || cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Location Code"} data={data} section={"dispatchMerge1"} />;
      },
    },
    {
      key: "Dispatch ID",
      name: "Dispatch ID" + postValue,
      width: calculatedWidths["Dispatch ID"] || cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Dispatch ID"} data={data} section={"dispatchMerge1"} />;
      },
    },
    {
      key: "loadout_reference",
      name: "Loadout Ref" + postValue,
      width: calculatedWidths["loadout_reference"] || cellWidthSmall,
      filterable: true,
      filterRenderer: (e) => {
        return (
          <CustomAutoCompleteFilter
            {...e}
            multiSelection={true}
            currentFilters={filters}
            fieldname={"loadout_reference"}
            data={data}
            section={"dispatchMerge1"}
            includeempty={true}
          />
        );
      },
    },
    {
      key: "Forwarding Agent Ref",
      name: "Forwarding Agent Ref" + postValue,
      width: calculatedWidths["Forwarding Agent Ref"] || cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Forwarding Agent Ref"} data={data} section={"dispatchMerge1"} />;
      },
    },
    {
      key: "Dispatch Doc Ref",
      name: "Dispatch Doc Ref" + postValue,
      width: calculatedWidths["Dispatch Doc Ref"] || cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Dispatch Doc Ref"} data={data} section={"dispatchMerge1"} />;
      },
    },
    {
      key: "Vessel",
      name: "Vessel" + postValue,
      width: calculatedWidths["Vessel"] || cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Vessel"} data={data} section={"dispatchMerge1"} />;
      },
    },
    {
      key: "Container No",
      name: "Container No" + postValue,
      width: calculatedWidths["Container No"] || cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Container No"} data={data} section={"dispatchMerge1"} />;
      },
    },
    {
      key: "Total Cartons",
      name: "Total Cartons" + postValue,
      width: calculatedWidths["Total Cartons"] || cellWidthMedium,
      filterable: true,
      formatter: FormatterNumber2Decimals,
      headerRenderer: RightAlignHeaderColumn,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Total Cartons"} data={data} section={"dispatchMerge1"} />;
      },
    },
    {
      key: "Total Pallets",
      name: "Total Pallets" + postValue,
      width: calculatedWidths["Total Pallets"] || cellWidthMedium,
      filterable: true,
      formatter: FormatterNumber2Decimals,
      headerRenderer: RightAlignHeaderColumn,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Total Pallets"} data={data} section={"dispatchMerge1"} />;
      },
    },
    // {
    //   key: "Dispatch User",
    //   name: "Dispatch User" + postValue,
    //   width: cellWidthMedium,
    //   filterable: true,
    //   filterRenderer: (e) => {
    //     return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Dispatch User"} data={data} section={"dispatchMerge2"} />;
    //   },
    // },
    // {
    //   key: "Client",
    //   name: "Client" + postValue,
    //   width: cellWidthMedium,
    //   filterable: true,
    //   filterRenderer: (e) => {
    //     return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Client"} data={data} section={"dispatchMerge2"} />;
    //   },
    // },
    // {
    //   key: "Consignee",
    //   name: "Consignee" + postValue,
    //   width: cellWidthMedium,
    //   filterable: true,
    //   filterRenderer: (e) => {
    //     return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Consignee"} data={data} section={"dispatchMerge2"} />;
    //   },
    // },
  ].map((c) => ({ ...c, ...defaultColumnProperties }));

  let columnsArranged = [];
  columnsArranged = handleColumnsArrangement(columnsArranged, arrangement, columns);
  columnsArranged = handleColumnsWidth(columnsArranged, columnsWidth, columns);
  return columnsArranged;
};

export function getCellActionsDispatch2(column, row, handleRemove) {
  return null;
}
