import { client, request, apiHeaders } from "./_base";

const endpoint = "dispatch";

export const dispatch = client(endpoint, (item) => ({
  ...item,
}));

export const dispatchedIntakeFromDispatch = (dispatchid: string, barcode: string, reference: string) =>
  request(`${endpoint}/ext/dispatchedIntakeFromDisp/${dispatchid}/${barcode}/${reference}`, {
    headers: apiHeaders(),
  }).then(async (result) => {
    if (result.status == 500) {
      const resp = await result.json();
      throw resp;
    }
    return result.json();
  });

export const dispatchedDetailByID = (id: number) =>
  request(`${endpoint}/ext/dispatchedDetailByID/${id}`, {
    headers: apiHeaders(),
  }).then(async (result) => {
    if (result.status == 500) {
      const resp = await result.json();
      throw resp;
    }
    return result.json();
  });

export const dispatchFixBarcodeEdi = async (dispatchid: any, barcode: string, acceptType: number) => {
  return await request(`${endpoint}/ext/fixBarcodeEdi/${dispatchid}/${barcode}/${acceptType}`, {
    method: "GET",
    headers: apiHeaders(),
  }).then((result) => result);
};

export const dispatchMergeInto = async (dispatchidsource: any, dispatchidmerge: any) => {
  return await request(`${endpoint}/ext/mergedispatch/${dispatchidsource}/${dispatchidmerge}`, {
    method: "GET",
    headers: apiHeaders(),
  }).then((result) => result);
};

export const getDispatchInvoiceNumbers = async () =>
  await request(`${endpoint}/ext/getDispatchInvoiceNumbers`, {
    method: "GET",
    headers: apiHeaders(),
  }).then(async (result) => {
    if (result.status == 500) {
      const resp = await result.json();
      throw resp;
    }
    return result.json();
  });

export type DispatchType = {
  id: number;
  dispatchdocs_id: number;
  carrier: string;
  truckregno: string;
  locationcode: string;
  containerno: string;
  containertype: string;
  orderno: string;
  sealnumber: string;
  receiver: string;
  clearingagent: string;
  currency_id: number;
  dealtype: number;
  terms_id: number;
  consignee_id: number;
  clearingagent_id: number;
  invoicenumber: string;
  notifyparty_id: number;
  created_at: Date;
  updated_at: Date;
  sellingterm: number;
  forwardagentref: string;
  remarks: string;
};

export type DispatchedDetailByIDType = {
  dispatch_id: number;
  dispatchdocs_id: number;
  loadout_id: number;
  barcode: string;
  barcode_producerid: string;
  barcode_farmnumber: string;
  barcode_commoditycode: string;
  barcode_varietycode: string;
  barcode_packcode: string;
  barcode_markcode: string;
  barcode_gradecode: string;
  barcode_countcode: string;
  barcode_inventorycode: string;
  barcode_nocartons: number;
  stockdetail_sellingprice: number;
};
