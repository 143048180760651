import { config } from "../../config";
import { client, apiHeaders, request } from "./_base";

const endpoint = "producersaleadjustment";

export type ProducerSaleAdjustmentType = {
  id?: number;
  producersale_id: number;
  adjustdate: Date;
  type: number;
  typeapply: number;
  status: number;
  created_at?: Date;
  updated_at?: Date;
};

export const producersaleadjustment = client(endpoint, (item) => ({
  ...item,
}));
