import { client } from "./_base";

export const inventories = client("inventory", (item) => ({
  ...item,
}));

export const inventoriesAllSortedMappedforCombo = async () => {
  const result = await inventories.all().then((data) => {
    return data
      .sort((a, b) => {
        if (a.description > b.description) return 1;
        if (a.description < b.description) return -1;
        return 1;
      })
      .map((item) => {
        return { id: item.id, value: `${item.description}`, code: item.code };
      });
  });
  return result;
};
