import React from "react";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";

import { GridColumns } from "./overpaidgridsetup";
import Grid from "../../../lib/components/grid";
import { SaleFundsOverpaidMappingType } from "../../../lib/api/salefundsoverpaid";

const styles = (theme: Theme) =>
  createStyles({
    root: {},
  });

type OverpaidGridProps = {
  loading: boolean;
  selectedSalesIds: number[];
  data: SaleFundsOverpaidMappingType[];
  handleSelectSale(selectedIds: number): void;
  handleRemoveOverpaid(salefundsallocation_id: number): void;
  handleEditOverpaid(row: SaleFundsOverpaidMappingType): void;
} & WithStyles<typeof styles>;

class OverpaidGridUnstyled extends React.Component<OverpaidGridProps, {}> {
  constructor(props) {
    super(props);
  }

  state = {};

  render() {
    return (
      <div>
        <Grid
          rowHeight={30}
          forceHeight={600}
          data={this.props.data}
          loading={this.props.loading}
          clearFilters={"overpaidgrid"}
          totalRowColumns={["overpaid_amount", "available_to_allocate"]}
          GridColumns={(data, filters, arrangement, columnsWidth) =>
            GridColumns(
              data,
              filters,
              arrangement,
              columnsWidth,
              this.props.selectedSalesIds,
              this.props.handleSelectSale,
              this.props.handleEditOverpaid,
              this.props.handleRemoveOverpaid,
            )
          }
        />
      </div>
    );
  }
}

export default withStyles(styles)(OverpaidGridUnstyled);
