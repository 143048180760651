import { client, request, apiHeaders } from "./_base";

const endpoint = "edifilecomparison";

// export const edistockpalletsdispatched = client(endpoint, (item) => ({
//   ...item,
// }));

type File = {
  id: number;
  filename: string;
};

export const getEdiFileComparisonFiles = (): Promise<{ data: File[] }> =>
  request(`${endpoint}/ext/getFilenames`, {
    headers: apiHeaders(),
  }).then((result) => result.json());

export const getCurrentBarcodeData = (fileImportId: number): Promise<{ data: any[] }> =>
  request(`${endpoint}/ext/getCurrentBarcodeData/${fileImportId}`, {
    headers: apiHeaders(),
  }).then((result) => result.json());

export const getBarcodeComparisonData = (fileImportId: number): Promise<{ data: any[] }> =>
  request(`${endpoint}/ext/getBarcodeComparisonData/${fileImportId}`, {
    headers: apiHeaders(),
  }).then((result) => result.json());

export const getStockData = (): Promise<{ data: any[] }> =>
  request(`${endpoint}/ext/getStockData`, {
    headers: apiHeaders(),
  }).then((result) => result.json());

export const getLatestComparisonByBarcodes = (barcodes: string[]): Promise<{ data: any[] }> =>
  request(`${endpoint}/ext/getLatestComparisonByBarcodes`, {
    method: "POST",
    headers: apiHeaders(),
    body: JSON.stringify(barcodes),
  }).then((result) => result.json());

export const getStockByBarcodes = (barcodes: string[]): Promise<{ data: any[] }> =>
  request(`${endpoint}/ext/getStockByBarcodes`, {
    method: "POST",
    headers: apiHeaders(),
    body: JSON.stringify(barcodes),
  }).then((result) => result.json());

export const getPSFileList = (): Promise<{ data: any[] }> =>
  request(`${endpoint}/ext/getPSFileList`, {
    headers: apiHeaders(),
  }).then((result) => result.json());
