import { handleResponse } from "../helpers/responseHelper";
import { client, request, apiHeaders } from "./_base";

const endpoint = "adhocInvoiceDetail";

export interface IAdhocInvoiceDetail {
  id?: number;
  description: string;
  quantity: number;
  unit_price: number;
  adhoc_invoice_id?: number;
}

export const adhocinvoicedetail = client(endpoint, (item) => ({
  ...item,
}));

export const getAdhocInvoiceDetailByAdhocId = async (adhocinvoiceid: number): Promise<{ data: IAdhocInvoiceDetail[] }> => {
  const result = await request(`${endpoint}/ext/detailByAdhocId/${adhocinvoiceid}`);
  return handleResponse(result);
};

export const processAdhocInvoiceAndDetailSave = async (head, formValues, adhocInvoiceDetail: IAdhocInvoiceDetail[]): Promise<void> => {
  const content = JSON.stringify({ head, formValues, adhocInvoiceDetail });
  const result = await request(`${endpoint}/ext/processAdhocInvoiceAndDetailSave`, {
    method: "POST",
    headers: apiHeaders(content),
    body: content,
  });

  return handleResponse(result);
};
