import React, { useEffect } from "react";

import { Form, Field } from "react-final-form";
import { TextField, Select } from "final-form-material-ui";

import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Check from "@material-ui/icons/Check";
import Close from "@material-ui/icons/Close";

import { shippingline } from "../../lib/api/shippingline";

import { DialogInformation } from "../../lib/components/dialoginformation";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1),
    },
    tableRowFieldTitle: {
      paddingTop: theme.spacing(1),
      paddingRight: theme.spacing(1) * 2,
      marginTop: theme.spacing(1),
      textAlign: "right",
      minWidth: "145px",
    },
    tableRowFieldData: {
      paddingTop: theme.spacing(1),
      paddingRight: theme.spacing(1) * 2,
      marginTop: theme.spacing(1),
      textAlign: "left",
    },
    tableRowFieldInput: {
      width: "100%",
    },
  });

type ShippingLineDetailProps = {
  shippingline_id: number;
  onClose: any;
  onSubmittedEvent: any;
} & WithStyles<typeof styles>;

const ShippingLineDetailUnstyled: React.SFC<ShippingLineDetailProps> = (props) => {
  const { classes, shippingline_id, onClose, onSubmittedEvent } = props;
  const [shippingLineData, setShippingLineData] = React.useState({});
  const [newShippingLineID, setNewShippingLineID] = React.useState(0);

  useEffect(() => {
    loadData();
  }, [shippingline_id]);

  const loadData = async (requestedId = undefined) => {
    const newData = {
      id: undefined,
      code: undefined,
      description: undefined,
    };

    await shippingline.single(requestedId ? requestedId : newShippingLineID != 0 ? newShippingLineID : shippingline_id).then(async (result) => {
      if (!result) {
        setShippingLineData(newData);
      } else {
        setShippingLineData(result);
      }
    });
  };

  const onSubmitted = () => {
    onSubmittedEvent();
  };

  const onSubmit = async (shipping) => {
    let id = shipping["id"];

    delete shipping["id"];

    if (id == undefined) {
      await shippingline
        .create({ data: { ...shipping } })
        .then((result) => {
          id = result[0];
          setNewShippingLineID(id);
          loadData(id);
        })
        .then(() => {
          onSubmitted();
        });
    } else {
      await shippingline.update(id, { data: { ...shipping } }).then(() => {
        onSubmitted();
        loadData();
      });
    }
    shipping.id = id;
  };

  return (
    <div style={{ marginTop: "-4px" }} className={classes.root}>
      <Form
        initialValues={{ ...shippingLineData }}
        onSubmit={onSubmit}
        render={({ handleSubmit, form }) => (
          <form onSubmit={handleSubmit}>
            <table>
              <tbody>
                <tr>
                  <td className={classes.tableRowFieldTitle}>
                    <span>Code:</span>
                  </td>
                  <td className={classes.tableRowFieldData}>
                    <Field fullWidth name="code" component={TextField} type="text" className={classes.tableRowFieldInput} />
                  </td>
                </tr>
                <tr>
                  <td className={classes.tableRowFieldTitle}>
                    <span>Description:</span>
                  </td>
                  <td className={classes.tableRowFieldData}>
                    <Field fullWidth name="description" component={TextField} type="text" className={classes.tableRowFieldInput} />
                  </td>
                </tr>
              </tbody>
            </table>
            <div style={{ display: "flex", justifyContent: "flex-end", margin: "10px 0 -5px 0", paddingTop: "5px", paddingBottom: "5px" }}>
              <Button style={{ marginRight: "10px" }} color="secondary" variant="outlined" onClick={onClose}>
                <Close />
              </Button>
              <Button disabled={!form.getState().dirty} style={{ marginRight: "16px" }} type="submit" color="primary" variant="outlined">
                <Check />
              </Button>
            </div>
          </form>
        )}
      />
    </div>
  );
};
export default withStyles(styles)(ShippingLineDetailUnstyled);
