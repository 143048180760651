import { client, request, apiHeaders } from "./_base";

const endpoint = "clientsfinance";

export type ClientsFinanceType = {
  id: number;
  clients_id: number;
  consignee_id: number;
  clearingagent_id: number;
  terms_id: number;
  trader_id: number;
  currency_id: number;
  sellingterms: number;
  dealtype: number;
  created_at: Date;
  updated_at: Date;
};

export const clientsfinance = client(endpoint, (item) => ({
  ...item,
}));

export const clientsfinanceByClientID = (clients_id: number) => {
  return request(`${endpoint}/ext/clientsFinanceByClientID/${clients_id}`, {
    headers: apiHeaders(),
  }).then(async (result) => {
    if (result.status == 500) {
      const resp = await result.json();
      throw resp;
    }
    return result.json();
  });
};

export const getClientByCurrencyId = (currency_id: number) => {
  return request(`${endpoint}/ext/getClientByCurrencyId/${currency_id}`, {
    headers: apiHeaders(),
  }).then(async (result) => {
    if (result.status == 500) {
      const resp = await result.json();
      throw resp;
    }
    return result.json();
  });
};
