import React, { useEffect } from "react";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";

import Check from "@material-ui/icons/Check";
import Close from "@material-ui/icons/Close";

import Button from "@material-ui/core/Button";

import { consignee } from "../../lib/api/consignee";
import { targetsSorted } from "../../lib/api/target";

import { Field, Form } from "react-final-form";

import { TextField, Select } from "final-form-material-ui";

import { MaterialCheckBoxComponent } from "../../lib/helpers/materialcomponents";

import Tooltip from "@material-ui/core/Tooltip";
import IconClose from "@material-ui/icons/Close";
import Settings from "@material-ui/icons/Settings";

import { getDestinationTypeReadyForCombo } from "../../lib/api/destinationtype";
import Confirmation from "../../lib/components/confirmation";

import ConsigneeClearingAgent from "./consigneeclearingagent";
import ClearingAgents from "../clearingagent/clearingagent";
import { Consignees } from "./consignee";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1),
      backgroundColor: theme.palette.background.paper,
      flexGrow: 1,
      display: "flex",
      minHeight: "650px",
      minWidth: "600px",
    },
    tabs: {
      borderRight: `1px solid ${theme.palette.divider}`,
      minWidth: "135px",
    },
    tabsLabel: {
      width: "150px",
    },
    tableRowFieldTitle: {
      paddingTop: theme.spacing(1),
      paddingRight: theme.spacing(1) * 2,
      marginTop: theme.spacing(1),
      textAlign: "right",
      minWidth: "175px",
    },
    tableRowTextAreaFieldTitle: {
      paddingRight: theme.spacing(1) * 2,
      marginTop: theme.spacing(1),
      textAlign: "right",
      minWidth: "175px",
      verticalAlign: "top",
      paddingTop: "11px",
    },
    tableRowFieldData: {
      paddingTop: theme.spacing(1),
      paddingRight: theme.spacing(1) * 2,
      marginTop: theme.spacing(1),
      textAlign: "left",
    },
    tableRowFieldInput: {
      width: "350px",
      textAlign: "left",
    },
    tabWidth: {
      height: "700px",
      width: "100%",
    },
  });

enum SelectedTab {
  Consignee = 0,
  Clearingagent = 1,
}

type ConsigneeEditFormProps = {
  onSubmit: any;
  onClose: any;
  id: any;
  updateAssignedClearingAgents: any;
  consigneesGroupedByCode: Consignees | {};
} & WithStyles<typeof styles>;

const ConsigneeEditFormUnstyled: React.FunctionComponent<ConsigneeEditFormProps> = (props) => {
  const { classes, onSubmit, id, updateAssignedClearingAgents, onClose } = props;
  const [targetsList, setTargetsList] = React.useState(undefined);
  const [targetRegions, setTargetRegions] = React.useState(undefined);
  const [targetMarkets, setTargetMarkets] = React.useState(undefined);
  const [targetCountries, setTargetCountries] = React.useState(undefined);
  const [destinationTypes, setDestinationTypes] = React.useState([]);
  const [editingConsignees, setEditingConsignees] = React.useState(false);
  const [toggleDataChanged, setToggleDataChanged] = React.useState(false);
  const [consigneeItem, setConsigneeItem] = React.useState({
    id: "0",
    code: "",
    name: "",
    physical_address: "",
    postal_address: "",
    telephone: "",
    mobile: "",
    email: "",
    region: "",
    market: "",
    target_id: undefined,
    active: false,
    destinationtype: "",
  });

  useEffect(() => {
    loadTargetData();
  }, []);

  const loadTargetData = async () => {
    const destinations = getDestinationTypeReadyForCombo();
    setDestinationTypes(destinations);

    await targetsSorted().then((result) => {
      setTargetsList(result.data);

      const regions = [];
      Promise.all(
        result.data.map((target) => {
          const targetrexist = regions.filter((region) => {
            if (region.value == target.region) {
              return region;
            }
          });
          if (targetrexist.length == 0) {
            regions.push({ id: regions.length, value: target.region });
          }
        }),
      ).then(async () => {
        const tryGetDetails = await consignee.single(id);
        const detail = tryGetDetails ? tryGetDetails : consigneeItem;
        setTargetRegions(regions);

        detail.region = "";
        detail.market = "";
        const regionFilter = result.data.filter((data) => data.id == detail.target_id);
        if (regionFilter && regionFilter.length > 0) {
          detail.region = regionFilter[0].region;
        }

        const marketFilter = result.data.filter((data) => data.id == detail.target_id);
        if (marketFilter && marketFilter.length > 0) {
          detail.market = marketFilter[0].market;
        }

        filterMarkets(detail.region, result.data);
        filterCountries(detail.market, result.data);
        setConsigneeItem({ ...detail, market: detail.market, region: detail.region, destinationtype: detail.destinationtype ? detail.destinationtype : 3 });
      });
    });
  };

  // const handleFocus = (event) => {
  //   event.target.select();
  // };

  const filterMarkets = (region, list = undefined) => {
    let targets = list ? list : targetsList;
    if (region) {
      targets = targets.filter((target) => {
        if (target.region == region) {
          return target;
        }
      });
    }

    const targetsM = [];

    targets.map((target: any) => {
      const indexM = targetsM.filter((item) => {
        return item.value == target.market;
      });
      if (indexM.length === 0) {
        targetsM.push({ id: targetsM.length, value: target.market });
      }
    });

    const markets = targetsM.sort((a, b) => {
      if (a.value < b.value) return -1;
      return 1;
    });

    setTargetCountries([]);
    setTargetMarkets(markets);
  };

  const filterCountries = (market, list = undefined) => {
    let targets = list ? list : targetsList;
    if (market) {
      targets = targets.filter((target) => {
        if (!market || target.market == market) {
          return target;
        }
      });
    }

    const targetsC = [];

    targets.map((target: any) => {
      const indexC = targetsC.filter((item) => {
        return item.value == target.country;
      });
      if (indexC.length === 0) {
        targetsC.push({ id: targetsC.length, value: target.country, market: target.market, region: target.region, target_id: target.id });
      }
    });

    const countries = targetsC.sort((a, b) => {
      if (a.value < b.value) return -1;
      return 1;
    });

    setTargetCountries(countries);
  };

  const handleCloseClearingAgentsMaintenance = () => {
    setEditingConsignees(false);
    setToggleDataChanged(!toggleDataChanged);
  };

  return (
    <div className={classes.root}>
      <ConsigneeEdit
        classes={classes}
        onSubmit={onSubmit}
        onClose={onClose}
        consigneeItem={consigneeItem}
        filterCountries={filterCountries}
        filterMarkets={filterMarkets}
        targetCountries={targetCountries}
        targetRegions={targetRegions}
        targetsList={targetsList}
        targetMarkets={targetMarkets}
        destinationTypes={destinationTypes}
        consigneesGroupedByCode={props.consigneesGroupedByCode}
        confirmEditID={props.id}
      />
      <div style={{ width: "400px" }}>
        <ConsigneeClearingAgent consignee_id={id} TopDown dataChangedEvent={toggleDataChanged} updateAssignedClearingAgents={updateAssignedClearingAgents} />
      </div>
      <div style={{ marginLeft: "-76px" }}>
        <Tooltip title="Open Clearing Agent Maintenance" placement="top-end">
          <Button variant="contained" color="primary" onClick={() => setEditingConsignees(true)}>
            <Settings />
          </Button>
        </Tooltip>
      </div>
      {
        <Confirmation
          fullscreen={true}
          isOpen={editingConsignees}
          handleClose={() => handleCloseClearingAgentsMaintenance()}
          handleConfirm={() => {}}
          title={"Consignee > Clearing Agents Maintenance"}
          body={undefined}
        >
          <ClearingAgents />

          <div style={{ position: "absolute", right: "0", top: "0", marginTop: "-50px" }}>
            <Tooltip title="Close Clearing Agent Maintenance" placement="bottom">
              <Button variant="text" style={{ color: "#FFFFFF" }} color="primary" onClick={() => handleCloseClearingAgentsMaintenance()}>
                <IconClose />
              </Button>
            </Tooltip>
          </div>
        </Confirmation>
      }
    </div>
  );
};
export default withStyles(styles)(ConsigneeEditFormUnstyled);
const ConsigneeEdit = ({
  classes,
  onSubmit,
  onClose,
  consigneeItem,
  filterMarkets,
  targetRegions,
  targetMarkets,
  filterCountries,
  targetsList,
  targetCountries,
  destinationTypes,
  consigneesGroupedByCode,
  confirmEditID,
}) => {
  return (
    <div className={classes.root}>
      <Form
        initialValues={{ ...consigneeItem }}
        onSubmit={onSubmit}
        validate={(values) => {
          let errors = {};
          if (values["code"] && consigneesGroupedByCode[values["code"].toUpperCase()] && confirmEditID == "0") {
            errors = { ...errors, code: "Code already exists" };
          }

          if (values["telephone"] && values["telephone"].length > 0 && !/^\+?(\d\d|0)[0-9]{9}/g.test(values["telephone"])) {
            errors = { ...errors, telephone: "not a valid number" };
          }
          if (values["mobile"] && values["mobile"].length > 0 && !/^\+?(\d\d|0)[0-9]{9}/g.test(values["mobile"])) {
            errors = { ...errors, mobile: "not a valid number" };
          }
          if (values["email"] && values["email"].length > 0 && !/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g.test(values["email"])) {
            errors = { ...errors, email: "not a valid email" };
          }

          if (!values["target_id"]) {
            errors = { ...errors, target_id: "Please select a country, this field is required." };
          }
          return errors;
        }}
        render={({ handleSubmit, form }) => (
          <form onSubmit={handleSubmit}>
            <table style={{ marginLeft: "15px" }}>
              <tbody>
                <tr>
                  <td className={classes.tableRowFieldTitle}>
                    <span>Code:</span>
                  </td>
                  <td className={classes.tableRowFieldData}>
                    <Field name={"code"} component={TextField} disabled={confirmEditID != "0"} fullWidth className={classes.tableRowFieldInput} key={"body_value_code"} />
                  </td>
                </tr>

                <tr>
                  <td className={classes.tableRowFieldTitle}>
                    <span>Name:</span>
                  </td>
                  <td className={classes.tableRowFieldData}>
                    <Field name={"name"} component={TextField} fullWidth className={classes.tableRowFieldInput} key={"body_value_name"} />
                  </td>
                </tr>

                <tr>
                  <td className={classes.tableRowTextAreaFieldTitle}>
                    <span>Physical Address:</span>
                  </td>
                  <td className={classes.tableRowFieldData}>
                    <Field
                      name={"physical_address"}
                      component={TextField}
                      fullWidth
                      style={{ resize: "vertical" }}
                      className={classes.tableRowFieldInput}
                      multiline={true}
                      rowsMax="4"
                      rows="4"
                      key={"body_value_physical_address"}
                      value={consigneeItem.physical_address || ""}
                    />
                  </td>
                </tr>

                <tr>
                  <td className={classes.tableRowTextAreaFieldTitle}>
                    <span>Postal Address:</span>
                  </td>
                  <td className={classes.tableRowFieldData}>
                    <Field
                      name={"postal_address"}
                      component={TextField}
                      fullWidth
                      style={{ resize: "vertical" }}
                      className={classes.tableRowFieldInput}
                      multiline={true}
                      rowsMax="4"
                      rows="4"
                      key={"body_value_postal_address"}
                      value={consigneeItem.postal_address || ""}
                    />
                  </td>
                </tr>

                <tr>
                  <td className={classes.tableRowFieldTitle}>
                    <span>Telephone:</span>
                  </td>
                  <td className={classes.tableRowFieldData}>
                    <Field fullWidth name="telephone" component={TextField} type="text" className={classes.tableRowFieldInput} />
                  </td>
                </tr>

                <tr>
                  <td className={classes.tableRowFieldTitle}>
                    <span>Mobile:</span>
                  </td>
                  <td className={classes.tableRowFieldData}>
                    <Field fullWidth name="mobile" component={TextField} type="text" className={classes.tableRowFieldInput} />
                  </td>
                </tr>

                <tr>
                  <td className={classes.tableRowFieldTitle}>
                    <span>Email:</span>
                  </td>
                  <td className={classes.tableRowFieldData}>
                    <Field fullWidth name="email" component={TextField} type="text" className={classes.tableRowFieldInput} />
                  </td>
                </tr>

                <tr>
                  <td className={classes.tableRowFieldTitle}>
                    <span>Target Region:</span>
                  </td>
                  <td>
                    <Field
                      name="region"
                      native
                      component={Select}
                      className={classes.tableRowFieldInput}
                      parse={(value) => {
                        filterMarkets(value);
                        form.change("market", undefined);
                        form.change("target_id", undefined);
                        return value;
                      }}
                    >
                      <option key={`none_1`} value={0}>
                        {`NONE`}
                      </option>
                      {!targetRegions
                        ? []
                        : targetRegions.map((region, index) => {
                            return (
                              <option key={index} value={region.value}>
                                {region.value}
                              </option>
                            );
                          })}
                    </Field>
                  </td>
                </tr>

                <tr>
                  <td className={classes.tableRowFieldTitle}>
                    <span>Target Market:</span>
                  </td>
                  <td>
                    <Field
                      name="market"
                      native
                      component={Select}
                      className={classes.tableRowFieldInput}
                      parse={(value) => {
                        filterCountries(value);
                        form.change("target_id", undefined);
                        return value;
                      }}
                    >
                      <option key={`none_1`} value={0}>
                        {`NONE`}
                      </option>
                      {!targetMarkets
                        ? []
                        : targetMarkets.map((market, index) => {
                            return (
                              <option key={index} value={market.value}>
                                {market.value}
                              </option>
                            );
                          })}
                    </Field>
                  </td>
                </tr>

                <tr>
                  <td className={classes.tableRowFieldTitle}>
                    <span>Target Country:</span>
                  </td>
                  <td>
                    <Field
                      name="target_id"
                      formControlProps={{
                        onFocus: () => {
                          if (!targetCountries || targetCountries.length == 0) {
                            if (targetMarkets[0].value) {
                              filterCountries(targetMarkets[0].value);
                            }
                          }
                        },
                      }}
                      native
                      component={Select}
                      className={classes.tableRowFieldInput}
                      parse={(value) => {
                        const target = targetsList.filter((target) => target.id == value)[0];
                        form.change("region", target.region);
                        form.change("market", target.market);
                        return value;
                      }}
                    >
                      <option key={`none_1`} value={0}>
                        {`NONE`}
                      </option>
                      {!targetCountries
                        ? []
                        : targetCountries.map((country, index) => {
                            if (
                              (!form.getState().values.region || form.getState().values.region == country.region) &&
                              (!form.getState().values.market || form.getState().values.market == country.market)
                            )
                              return (
                                <option key={index} value={country.target_id}>
                                  {country.value}
                                </option>
                              );
                          })}
                    </Field>
                  </td>
                </tr>

                <tr>
                  <td className={classes.tableRowFieldTitle}>
                    <span>Destination Type:</span>
                  </td>
                  <td>
                    <Field
                      name="destinationtype"
                      native
                      component={Select}
                      className={classes.tableRowFieldInput}
                      parse={(value) => {
                        return value;
                      }}
                    >
                      {!destinationTypes
                        ? []
                        : destinationTypes.map((type) => {
                            return (
                              <option key={type.value} value={type.value}>
                                {type.display}
                              </option>
                            );
                          })}
                    </Field>
                  </td>
                </tr>

                <tr>
                  <td className={classes.tableRowFieldTitle}>
                    <span>Active:</span>
                  </td>
                  <td className={classes.tableRowFieldData}>
                    <Field name="active" component={MaterialCheckBoxComponent} type="checkbox" style={{ margin: 0, padding: 0, marginLeft: "-18px" }} />
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td>
                    <div style={{ position: "relative", left: "200px", top: "40px", width: "150px" }}>
                      <Button style={{ marginRight: "10px" }} color="secondary" variant="outlined" onClick={onClose} tabIndex={15}>
                        <Close />
                      </Button>
                      <Button type="submit" color="primary" variant="outlined" tabIndex={16}>
                        <Check />
                      </Button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </form>
        )}
      />
    </div>
  );
};
