import React from "react";

import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";

import LinearProgress from "@material-ui/core/LinearProgress";
import CircularProgress from "@material-ui/core/CircularProgress";

import { dispatch } from "../lib/api/dispatch";
import { dispatcheddocsFull, updateDispatchedDoc, removeDispatchedDoc } from "../lib/api/dispatcheddocs";
import { loadout, releaseDispatch } from "../lib/api/loadout";
import { updateStockByBarcodeID } from "../lib/api/stock";
import { stockDetailUpdateByBarcodeID } from "../lib/api/stockdetail";

import DispatchTable from "./dispatchtable";
import AcceptDialog from "./dispatchAcceptDialog";
import { isNullOrUndef } from "../lib/helpers/isNullOrUndef";

import Confirmation from "../lib/components/confirmation";
import { DialogInformation } from "../lib/components/dialoginformation";
import { findByDispatchID } from "../lib/api/edistockpalettesdispatched";
import { getAgentByDefault } from "../lib/api/agent";
import { SellingTerms } from "../lib/api/sellingterms";
import { getFinancialYearSelected } from "../lib/api/week";

import { SnackContext } from "../lib/context/SnackContext";
import { GenerateErrorMessage } from "../lib/helpers/string_methods";
import { vessel } from "../lib/api/vessel";

import parseISO from "date-fns/parseISO";
import format from "date-fns/format";

export enum FormState {
  ShowConfirmation = 0,
  CreateInvoice = 1,
  Close = 2,
  Save = 3,
}

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      overflow: "hidden",
      width: "100%",
      height: "calc(100% - 65px)",
    },
  });

type DispatchProps = {} & WithStyles<typeof styles>;
class DispatchUnstyled extends React.Component<DispatchProps> {
  state = {
    dispatches: [],
    loading: true,
    selectedDispatch: undefined,
    selectedData: undefined,
    selectedUpdating: false,
    unloadConfirm: false,
    unallocateConfirm: false,
    classes: undefined,
    releaseData: undefined,
    includeCompleted: true,
    includeDispatches: true,
    includeTransfers: false,
    requestOpenSale: false,
    passedReference: undefined,
    confirmedDispatchDialog: false,
    agentsDefault: undefined,
    removeDispatch: undefined,
    vessels: [],
  };

  constructor(props) {
    super(props);
    this.state.classes = props.classes;

    if (props.match.params.reference) {
      this.state.passedReference = props.match.params.reference;
    }
  }

  static contextType = SnackContext;
  context!: React.ContextType<typeof SnackContext>;

  componentDidMount = async () => {
    await this.loadDispatched();
  };

  loadDispatched = async () => {
    this.setState({ loading: true });

    try {
      const [dispatchData, agentsDefault, vessels] = await Promise.all([dispatcheddocsFull(getFinancialYearSelected()), getAgentByDefault(1), vessel.all()]);

      if (dispatchData && dispatchData.data) {
        const dispatches = dispatchData.data.map((item) => ({
          ...item,
          ["Selling Terms"]: SellingTerms[item.sellingterm],
          DateTimeFormatted: item.DateTime ? format(new Date(item.DateTime), "yyyy-MM-dd") : null,
        }));
        this.setState({ dispatches, agentsDefault, vessels });
      } else {
        this.setState({ agentsDefault, vessels });
      }
    } catch (error) {
      const err = GenerateErrorMessage(error, "Failed to retrieve data");
      this.context.updateSnack({ show: true, color: "red", message: err });
    }

    this.setState({ loading: false });
  };

  onReload = async () => {
    await this.loadDispatched();
  };

  onAccept = (data, dispatch) => {
    if (data.length > 0) {
      this.setState({ selectedData: data, selectedUpdating: true, selectedDispatch: dispatch });
    }
  };

  onAcceptProcess = async (data, palletData, loadoutdata) => {
    // TODO: convert to knex transaction
    const dispatchData = {
      data: {
        dispatchdocs_id: data["Dispatch ID"],
        carrier: data.dispatch_carrier,
        truckregno: data.dispatch_regno,
        containerno: data.dispatch_containerno,
        containertype: data.dispatch_containertype,
        orderno: data.dispatch_orderno,
        sealnumber: data.dispatch_sealnumber,
        receiver: data.dispatch_receiver,
        currency_id: data.dispatch_currency,
        dealtype: data.dispatch_dealtype,
        sellingterm: data.dispatch_sellingterm,
        consignee_id: data.dispatch_consignee_id,
        clearingagent_id: data.dispatch_clearingagent_id,
        notifyparty_id: data.dispatch_notifyparty_id,
        invoicenumber: data.dispatch_invoicenumber,
        forwardagentref: data.dispatch_forwardagentref,
        invoicedate: data.dispatch_invoicedate,
        remarks: data.dispatch_remarks,
        recorder_position: data.recorder_position,
      },
    };

    const dispatchResult =
      this.state.selectedDispatch && !data.dispatch_id ? await dispatch.create(dispatchData) : await dispatch.update(data.dispatch_id, dispatchData).then(() => [data.dispatch_id]);

    if (dispatchResult[0] != 0) {
      loadoutdata.map(async (item) => {
        const updateLoadoutData = {
          data: {
            dispatch_id: dispatchResult[0],
            exportNotificationReference: data.loadout_exportNotificationReference,
            agent_id: data.agent_id,
            voyageNumber: data.loadout_voyageNumber,
            vessel_id: data.vessel_id,
            portLoading_id: data.portloading_id,
            portDischarge_id: data.portdistcharge_id,
            portFinal_id: data.portfinal_id,
            etd: data.loadout_etd ? parseISO(data.loadout_etd) : null,
            eta: data.loadout_eta ? parseISO(data.loadout_eta) : null,
            updated_eta: !isNullOrUndef(data.loadout_eta) ? parseISO(data.loadout_eta) : new Date(),
            coldroom_id: data.loadout_coldroom_id,
            consignee: data.loadout_consignee,
          },
        };
        await loadout.update(item.loadout_id, updateLoadoutData);
      });
    }

    const dispatchedDocsVessel = ((this.state.vessels || []).find((vessel) => vessel.id == data.vessel_id) || { description: data.vessel_code }).description;

    for (let index = 0; index < palletData.length; index++) {
      const item = palletData[index];
      // info references local QX values
      const updateInfo = {
        // Barcode: item.edi_Barcode,
        "Consignee ID": data.loadout_consignee,
        "Location Code": "DISP",
        "Temp Tail": data.temptail,
      };

      await updateStockByBarcodeID(item.bar_barcode_id, updateInfo);
      await stockDetailUpdateByBarcodeID(item.bar_barcode_id, { po: data.stockdgrouped_po, tempcode: data.temperaturecode });
      await updateDispatchedDoc({
        dispatch_id: data["Dispatch ID"],
        ["Load Date"]: parseISO(data["dispatch_loaddate"]),
        Vessel: !dispatchedDocsVessel || dispatchedDocsVessel == "" ? data.vessel_code : dispatchedDocsVessel,
      });
    }

    const { data: selectedDispatchUpdated } = await findByDispatchID(data["Dispatch ID"]);
    const sortedSelectedDispatchUpdated = selectedDispatchUpdated.sort((item) => (item.loadout === undefined ? 0 : 1));

    this.setState({ selectedData: sortedSelectedDispatchUpdated });
  };

  onAcceptCancel = () => {
    this.setState({ selectedData: undefined, selectedUpdating: false, selectedDispatch: undefined });
    this.onReload();
  };

  onRelease = async (dispatchData) => {
    this.setState({ releaseData: dispatchData });
  };

  handleOnReleaseConfirm = async () => {
    try {
      await releaseDispatch(this.state.releaseData.dispatch_id);
      this.context.updateSnack({ show: true, color: "green", message: "Dispatch Released" });
    } catch (error) {
      const err = GenerateErrorMessage(error, "Failed to release dispatch");
      this.context.updateSnack({ show: true, color: "red", message: err });
    }

    this.setState({ releaseData: undefined });
    this.onReload();
  };

  handleOnReleaseClose = () => {
    this.setState({ releaseData: undefined });
  };

  handleConfirmedDispatchDialog = () => {
    this.setState({ confirmedDispatchDialog: false });
  };

  handleRemoveDispatch = (id: number) => {
    this.setState({ removeDispatch: id });
  };

  handleRemoveDispatchConfirm = async () => {
    await removeDispatchedDoc(this.state.removeDispatch);
    this.onReload();
    this.setState({ removeDispatch: undefined });
  };

  handleRemoveDispatchClose = () => {
    this.setState({ removeDispatch: undefined });
  };

  handleShowConfirmedDispatchDialog = async () => {
    this.setState({ confirmedDispatchDialog: true });
  };

  render() {
    return (
      <div className={this.state.classes.root}>
        {this.state.releaseData && (
          <Confirmation
            isOpen={true}
            handleClose={this.handleOnReleaseClose}
            handleConfirm={this.handleOnReleaseConfirm}
            title={`Release Dispatch?`}
            body={`Are you sure you want to release the Dispatch?`}
          ></Confirmation>
        )}
        {this.state.removeDispatch && (
          <Confirmation
            isOpen={true}
            handleClose={this.handleRemoveDispatchClose}
            handleConfirm={this.handleRemoveDispatchConfirm}
            title={`Remove Dispatch?`}
            body={`Are you sure you want to remove the Dispatch?`}
          ></Confirmation>
        )}
        {this.state.confirmedDispatchDialog && (
          <DialogInformation
            isOpen={true}
            showinput={false}
            title={"Dispatch confirmed"}
            body={"Dispatch confirmed successfully."}
            handleOK={this.handleConfirmedDispatchDialog}
            handleClose={this.handleConfirmedDispatchDialog}
          />
        )}
        {this.state.loading && <CircularProgress />}
        {this.state.loading && (
          <div>
            <LinearProgress />
          </div>
        )}
        {this.state.selectedUpdating && (
          <AcceptDialog
            isOpen={this.state.selectedUpdating}
            handleClose={this.onAcceptCancel}
            onSubmit={this.onAcceptProcess}
            selectedDispatch={this.state.selectedDispatch}
            defaultData={this.state.selectedData[0]}
            agentdefault={this.state.agentsDefault}
            handleShowConfirmation={this.handleShowConfirmedDispatchDialog}
          />
        )}
        {this.state.loading == false && (
          <DispatchTable
            data={this.state.dispatches}
            onReload={this.onReload}
            onAccept={this.onAccept}
            onRelease={this.onRelease}
            passedReference={this.state.passedReference}
            onRemoveDispath={this.handleRemoveDispatch}
          />
        )}
      </div>
    );
  }
}

export default withStyles(styles)(DispatchUnstyled);
