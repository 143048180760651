import React, { useEffect, useState } from "react";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";

import Grid from "../../../lib/components/grid";
import { GridColumns } from "./detailgridsetup";
import { Toolbar } from "@material-ui/core";

import numeral from "numeral";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      height: "100%",
    },
    gridWrapper: {
      position: "relative",
    },
    toolbar: {
      position: "absolute",
      left: 0,
      top: 0,
      height: "60px",
      width: "100%",
    },
  });

type DetailGridUnstyledProps = {
  classes: any;
  data: any[];
  checked: any[];
  isIntake: boolean;
  form: any;
  handleInvoiceInfoClick(isIntake: boolean, value: any): void;
  handleCheckboxToggle(value: any, form: any): void;
} & WithStyles<typeof styles>;

class DetailGridUnstyled extends React.Component<DetailGridUnstyledProps, {}> {
  constructor(props) {
    super(props);
  }

  state = {
    loading: false,
    gridHeight: 700,
  };

  handleToggle = (row) => {
    this.props.handleCheckboxToggle(row, this.props.form);
  };

  GridColumnsOverride = (data: any, filters: any, columnArrangement?: any, columnsWidth?: any) => {
    return GridColumns(data, filters, columnArrangement, columnsWidth, this.props.checked, this.props.isIntake, this.handleToggle, this.props.handleInvoiceInfoClick);
  };

  getData = (propsData, propsChecked) => {
    const checked = propsChecked.filter((item) => item);
    const newData = propsData.reduce((acc, curr) => {
      if (!curr) {
        return acc;
      }

      if (!checked.find((item) => curr.sale_invoicenumber == item.sale_invoicenumber)) {
        acc.push({ ...curr, totalValueShow: 0 });
      } else {
        acc.push(curr);
      }

      return acc;
    }, []);
    return newData;
  };

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.gridWrapper}>
        <Toolbar className={classes.toolbar}>
          <Totals data={this.props.checked} />
        </Toolbar>
        <Grid
          loading={this.state.loading}
          data={this.getData(this.props.data, this.props.checked)}
          GridColumns={this.GridColumnsOverride}
          clearFilters={"produceradvancepaydetail"}
          forceHeight={this.state.gridHeight}
          totalRowColumns={["totalValueShow", "total_cartons", "total_palletsize"]}
        />
      </div>
    );
  }
}

const Totals = ({ data }) => {
  const [total, setTotal] = useState(0);
  const [cartonsTotal, setCartonsTotal] = useState(0);
  const [palletSizeTotal, setPalletSizeTotal] = useState(0);

  useEffect(() => {
    const { tot, cartonTot, palletSizeTot } = data.reduce(
      (acc, curr) => {
        acc.tot += parseFloat(curr.totalValueShow || 0);
        acc.cartonTot += parseFloat(curr.total_cartons || 0);
        acc.palletSizeTot += parseFloat(curr.total_palletsize || 0);
        return acc;
      },
      {
        tot: 0,
        cartonTot: 0,
        palletSizeTot: 0,
      },
    );
    setTotal(tot);
    setCartonsTotal(cartonTot);
    setPalletSizeTotal(palletSizeTot);
  }, [data]);

  return (
    <div style={{ display: "flex", flexDirection: "row", gap: "5px", fontSize: "17px" }}>
      <p>
        Total: <span style={{ fontWeight: "bold", color: "orange" }}>{numeral(total).format("0,0.00")}</span>
      </p>
      <p>|</p>
      <p>
        Cartons Total:&nbsp;
        <span style={{ fontWeight: "bold", color: "orange" }}>{numeral(cartonsTotal).format("0,0")}</span>
      </p>
      <p>|</p>
      <p>
        Pallet Size Total:&nbsp;
        <span style={{ fontWeight: "bold", color: "orange" }}>{numeral(palletSizeTotal).format("0,0.00")}</span>
      </p>
    </div>
  );
};

export default withStyles(styles)(DetailGridUnstyled);
