import React from "react";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";

import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import RadioGroup from "@material-ui/core/RadioGroup";

import IconCheck from "@material-ui/icons/Check";
import IconAdd from "@material-ui/icons/Add";

import { Form, Field } from "react-final-form";
import { TextField, Radio } from "final-form-material-ui";

import { GridColumns } from "./companydetailsgridsetup";

import { DialogInformation } from "../../lib/components/dialoginformation";
import Confirmation from "../../lib/components/confirmation";
import Grid from "../../lib/components/grid";

import { companyDetails, CompanyDetailsType } from "../../lib/api/companydetails";
import { companyDetailsBanking, CompanyDetailsBankingType } from "../../lib/api/companydetailsbanking";
import { currency } from "../../lib/api/currency";

import EditFormBanking from "./companydetailseditformbanking";

import { SnackContext } from "../../lib/context/SnackContext";
import { GenerateErrorMessage } from "../../lib/helpers/string_methods";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1),
    },
    tableRowFieldTitle: {
      paddingTop: theme.spacing(1),
      paddingRight: theme.spacing(1) * 2,
      marginTop: theme.spacing(1),
      textAlign: "right",
      minWidth: "100px",
    },
    tableRowFieldDataText: {
      paddingTop: theme.spacing(0.5),
      textAlign: "left",
      width: "400px",
    },
    tableCellsValue: {
      width: "250px",
      textAlign: "left",
      marginTop: theme.spacing(1),
    },
    tableCellsValueSelect: {
      textAlign: "left",
      width: "250px",
    },
  });
type CompanyDetailsEditFormProps = {} & WithStyles<typeof styles>;

class CompanyDetailsEditFormUnstyled extends React.Component<CompanyDetailsEditFormProps, any> {
  state = {
    classes: undefined,
    loading: false,
    dialoginformation: undefined,
    companydetails: undefined,
    companydetailsbanking: [],
    editRecord: undefined,
  };

  constructor(props) {
    super(props);
    this.state.classes = props.classes;
  }

  static contextType = SnackContext;
  context!: React.ContextType<typeof SnackContext>;

  componentDidMount() {
    this.getItem();
  }

  getItem = async () => {
    try {
      const [currencies, resultComp, resultCompBanking] = await Promise.all([currency.all(), companyDetails.all(), companyDetailsBanking.all()]);
      const resultCompBankingWithCurrency = resultCompBanking.map((item) => {
        const currencyfind = currencies.find((curr) => curr.id == item.currency_id);
        return { ...item, currency_code: currencyfind ? currencyfind.code : "ZAR" };
      });
      this.setState({
        companydetails: { ...resultComp[0], consoldefault_fob: resultComp[0].consoldefault_fob.toString(), consoldefault_cold: resultComp[0].consoldefault_cold.toString() },
        companydetailsbanking: resultCompBankingWithCurrency.sort((a, b) => a.currency_code.localeCompare(b.currency_code)),
      });
    } catch (error) {
      const err = GenerateErrorMessage(error, "Error getting data");
      this.context.updateSnack({ show: true, color: "red", message: err });
    }
  };

  handleSubmit = async (values) => {
    let id = values["id"];
    try {
      const cdData: CompanyDetailsType = {
        name: values.name,
        regno: values.regno,
        vatno: values.vatno,
        telephoneNumber: values.telephoneNumber,
        email: values.email,
        ggn_gln_no: values.ggn_gln_no,
        faxNumber: values.faxNumber,
        address1: values.address1,
        address2: values.address2,
        address3: values.address3,
        address4: values.address4,
        address5: values.address5,
        customscode: values.customscode,
        consoldefault_fob: values.consoldefault_fob,
        consoldefault_cold: values.consoldefault_cold,
      };

      if (id == "0") {
        const result = await companyDetails.create({ data: { ...cdData } });
        id = result[0];
      } else {
        await companyDetails.update(id, { data: { ...cdData } });
      }
      this.context.updateSnack({ show: true, color: "green", message: "Data Saved" });
    } catch (error) {
      const err = GenerateErrorMessage(error, "Error creating/updating data");
      this.context.updateSnack({ show: true, color: "red", message: err });
    }
    this.getItem();
  };

  handleInfoClose = () => {
    this.setState({ dialoginformation: undefined });
  };

  handleRemove = (id) => {
    companyDetailsBanking.remove(id).then(() => {
      this.getItem();
    });
  };

  handleEdit = (id) => {
    this.setState({ editRecord: id });
  };

  handleEditConfirm = () => {
    this.setState({ editRecord: undefined }, () => {
      this.getItem();
    });
  };

  GridColumnsOverride = (column, row) => {
    return GridColumns(column, row, this.handleRemove, this.handleEdit);
  };

  render() {
    const { classes } = this.state;
    return (
      <div className={classes.root}>
        <Form
          initialValues={{
            ...this.state.companydetails,
          }}
          onSubmit={(values) => {
            this.handleSubmit(values);
          }}
          render={({ handleSubmit }) => (
            <form id={"1"} onSubmit={handleSubmit}>
              <table>
                <tbody>
                  <tr>
                    <td style={{ verticalAlign: "top", textAlign: "left" }}>
                      <table style={{ marginLeft: "15px", marginTop: "50px" }}>
                        <tbody>
                          <TableFieldText classes={classes} title="Name" field="name" disabled={false} multiline={false} required={true} />
                          <TableFieldText classes={classes} title="Reg No" field="regno" disabled={false} multiline={false} required={false} />
                          <TableFieldText classes={classes} title="VAT No" field="vatno" disabled={false} multiline={false} required={false} />
                          <TableFieldText classes={classes} title="Customs Code" field="customscode" disabled={false} multiline={false} required={false} />
                          <TableFieldText classes={classes} title="GGN / GLN NO" field="ggn_gln_no" disabled={false} multiline={false} required={false} />
                          <TableFieldText classes={classes} title="Address1" field="address1" disabled={false} multiline={false} required={false} />
                          <TableFieldText classes={classes} title="Address2" field="address2" disabled={false} multiline={false} required={false} />
                          <TableFieldText classes={classes} title="Address3" field="address3" disabled={false} multiline={false} required={false} />
                          <TableFieldText classes={classes} title="Address4" field="address4" disabled={false} multiline={false} required={false} />
                          <TableFieldText classes={classes} title="Address5" field="address5" disabled={false} multiline={false} required={false} />
                          <TableFieldText classes={classes} title="Telephone" field="telephoneNumber" disabled={false} multiline={false} required={false} />
                          <TableFieldText classes={classes} title="Email" field="email" disabled={false} multiline={false} required={false} />
                          <TableFieldText classes={classes} title="Fax" field="faxNumber" disabled={false} multiline={false} required={false} />
                          <TableFieldLabel classes={classes} title="Fin Consolidation" bold={true} />
                          <TableFieldRadio classes={classes} title="FOB Cost Default" field="consoldefault_fob" />
                          <TableFieldRadio classes={classes} title="COLD Cost Default" field="consoldefault_cold" />
                        </tbody>
                      </table>
                    </td>
                    <td style={{ verticalAlign: "top", textAlign: "left", paddingLeft: "15px", width: "1000px" }}>
                      <Grid loading={this.state.loading} data={this.state.companydetailsbanking} GridColumns={this.GridColumnsOverride} clearFilters={"companydetailsbanking"} />
                    </td>
                  </tr>
                </tbody>
              </table>
              {this.state.dialoginformation && (
                <DialogInformation
                  isOpen={this.state.dialoginformation}
                  handleClose={this.handleInfoClose}
                  handleOK={this.handleInfoClose}
                  showinput={false}
                  title="Success"
                  body="Successfully updated company details"
                ></DialogInformation>
              )}
              {this.state.editRecord && (
                <Confirmation isOpen={true} handleClose={() => {}} handleConfirm={() => {}} title={"Currency Backing"} body={undefined}>
                  <EditFormBanking id={this.state.editRecord} confirm={this.handleEditConfirm} />
                </Confirmation>
              )}
              <div style={{ float: "left", paddingLeft: "400px", paddingTop: "10px", clear: "both" }}>
                <Button type="submit" color="primary" variant="outlined">
                  <IconCheck />
                </Button>
                <Button
                  color="primary"
                  variant="outlined"
                  onClick={() => {
                    this.setState({ editRecord: "0" });
                  }}
                >
                  <IconAdd />
                </Button>
              </div>
            </form>
          )}
        />
      </div>
    );
  }
}
export default withStyles(styles)(CompanyDetailsEditFormUnstyled);

const TableFieldLabel: React.FunctionComponent<{ title: string; bold: boolean } & WithStyles<typeof styles>> = (props) => {
  const { classes, title, bold } = props;

  return (
    <tr>
      <td className={classes.tableRowFieldTitle} style={{ verticalAlign: "top", paddingTop: "13px", fontWeight: bold ? "bold" : "normal" }}>
        <span>{`${title}: `}</span>
      </td>
      <td className={classes.tableRowFieldDataText}>{``}</td>
    </tr>
  );
};

const TableFieldText: React.FunctionComponent<{ field: string; title: string; disabled: boolean; multiline: boolean; required: boolean } & WithStyles<typeof styles>> = (props) => {
  const { classes, title, field, disabled, multiline, required } = props;

  return (
    <tr>
      <td className={classes.tableRowFieldTitle} style={{ verticalAlign: "top", paddingTop: "13px" }}>
        <span>{`${title}: `}</span>
      </td>
      <td className={classes.tableRowFieldDataText}>
        <Field
          name={field}
          key={`body_value_${field}`}
          component={TextField}
          type="text"
          className={classes.tableRowFieldDataText}
          multiline={multiline}
          rows={multiline ? "4" : "1"}
          required={required}
          disabled={disabled}
        />
      </td>
    </tr>
  );
};

const TableFieldRadio: React.FunctionComponent<{ field: string; title: string } & WithStyles<typeof styles>> = (props) => {
  const { classes, title, field } = props;

  return (
    <tr>
      <td className={classes.tableRowFieldTitle} style={{ verticalAlign: "top", paddingTop: "13px" }}>
        <span>{`${title}: `}</span>
      </td>
      <td className={classes.tableRowFieldDataText}>
        <FormControl style={{ float: "left" }}>
          <RadioGroup>
            <FormControlLabel control={<Field type="radio" name={field} value={"1"} component={Radio} />} label="Actual" />
            <FormControlLabel control={<Field type="radio" name={field} value={"2"} component={Radio} />} label="Estimation" />
          </RadioGroup>
        </FormControl>
      </td>
    </tr>
  );
};
