import React from "react";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";

import { Row } from "react-data-grid";
import "react-splitter-layout/lib/index";
import "../../../lib/custom/splitter.css";

import { GridColumns } from "./allocationtablesetup";

import Grid from "../../../lib/components/grid";
import { DebtorsFullType, downloadDebtorFundsAllocation } from "../../../lib/api/debtorsfull";
import { history } from "../../../lib/history";

import { format } from "date-fns";
import { SaleFundsFullType } from "../../../lib/api/salefunds";
import { GenerateErrorMessage } from "../../../lib/helpers/string_methods";
import { SnackContext } from "../../../lib/context/SnackContext";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      height: "100%",
      width: "100%",
    },
    rowGreen: {
      color: "green",
    },
    rowBlack: {
      color: "black",
    },
    rowOrange: {
      color: "orange",
    },
    gridRoot: {
      "& div:first-child": {
        "& > .rdg-group-row + div:not(.rdg-group-row, .rdg-summary-row)": {
          backgroundColor: "#e9e9e9",
        },
        "& > .rdg-group-row": {
          backgroundColor: "#d4d4d4",
        },
      },
      "& form": {
        "& > div:first-child": {
          overflowY: "hidden",
          overflowX: "auto",
        },
      },
    },
  });

type FundAllocationTableProps = {
  data: any[];
  loading: boolean;
  selectedForAlloc: any[];
  groupedInvoiceDetail: any;
  record: SaleFundsFullType;
  handleViewSaleAllocations(row: any): void;
  applyAvailable: (item: DebtorsFullType, paymentnumber: number) => void;
} & WithStyles<typeof styles>;
class FundAllocationTableUnstyled extends React.Component<FundAllocationTableProps, {}> {
  constructor(props) {
    super(props);
  }

  static contextType = SnackContext;
  context!: React.ContextType<typeof SnackContext>;

  rowRenderer = (props, classes) => {
    return <Row {...props} />;
  };

  handleExport = async (data: any[]) => {
    try {
      this.setState({ loading: true });
      const clientcode = history.location.pathname.split("/")[history.location.pathname.split("/").length - 1];
      const fileName = `${format(new Date(), "yyMMdd")} ${clientcode} Debtors Funds Allocation.xlsx`;

      const exportData = data.reduce((arr, item) => {
        if (!item.header) {
          arr.push({
            sale_invoicenumber: item.sale_invoicenumber,
            forwardagentref: item.forwardagentref,
            currency_code: item.currency_code,
            document_sourcetotal: item.document_sourcetotal,
            amountCredit: item.amountCredit,
            amountDebit: item.amountDebit,
            total_outstanding: item.total_outstanding,
            sale_payment1date: item.sale_payment1date,
            sale_payment1amount: item.sale_payment1amount,
            sale_payment1amount_remain: item.sale_payment1amount_remain,
            sale_payment2date: item.sale_payment2date,
            sale_payment2amount: item.sale_payment2amount,
            sale_payment2amount_remain: item.sale_payment2amount_remain,
            sale_payment3date: item.sale_payment3date,
            sale_payment3amount: item.sale_payment3amount,
            sale_payment3amount_remain: item.sale_payment3amount_remain,
            salefundsallocation_amount: item.salefundsallocation_amount,
            overpaid: item.overpaid,
          });
        }
        return arr;
      }, []);
      await downloadDebtorFundsAllocation(exportData, fileName, clientcode);
    } catch (error) {
      const err = GenerateErrorMessage(error, "Error printing report.");
      this.context.updateSnack({ show: true, color: "red", message: err });
    } finally {
      this.setState({ loading: false });
    }
  };

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.gridRoot}>
        <Grid
          loading={this.props.loading}
          data={this.props.data}
          GridColumns={(data, filters, columnArrangement, columnsWidth) =>
            GridColumns(
              data,
              filters,
              columnArrangement,
              columnsWidth,
              this.props.applyAvailable,
              this.props.groupedInvoiceDetail,
              this.props.selectedForAlloc,
              this.props.record,
              this.props.handleViewSaleAllocations,
            )
          }
          rowRenderer={(props) => this.rowRenderer(props, classes)}
          clearFilters={"fundallocationtable"}
          handleExport={() => this.handleExport(this.props.data)}
          groupedColumns={["sale_invoicenumber"]}
          totalRowColumns={["sale_total", "amountDebit", "amountCredit", "total_outstandingwithadjustments", "overpaid", "groupedAllocated"]}
        />
      </div>
    );
  }
}

export default withStyles(styles)(FundAllocationTableUnstyled);
