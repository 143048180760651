import { apiHeaders, client, request } from "./_base";

const endpoint = "creditorsgroupsub";
export const creditorsgroupsub = client(endpoint, (item) => ({
  ...item,
}));

export const creditorsgroupsubByCreditorsGroupID = (creditorsgroupid) => {
  return request(`${endpoint}/ext/byCreditorsGroupID/${creditorsgroupid}`, {
    headers: apiHeaders(),
  }).then(async (result) => {
    if (result.status == 500) {
      const resp = await result.json();
      throw resp;
    }
    return result.json();
  });
};

export const creditorsgroupsubAllSortedMappedforCombo = async () => {
  const result = await creditorsgroupsub.all().then((data) => {
    return data
      .sort((a, b) => {
        if (a.subgroup > b.subgroup) return 1;
        if (a.subgroup <= b.subgroup) return -1;
        return 1;
      })
      .map((item) => {
        return { value: item.id, display: `${item.subgroup}` };
      });
  });
  return result;
};
