import React, { useEffect } from "react";
import { Theme, createStyles, WithStyles, withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import InputBase from "@material-ui/core/InputBase";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import IconRotate from "@material-ui/icons/Rotate90DegreesCcw";
import IconDelete from "@material-ui/icons/Delete";

import LZString from "lz-string";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: "2px 4px",
      display: "flex",
      alignItems: "center",
      width: 300,
    },
    input: {
      marginLeft: theme.spacing(1),
      flex: 1,
    },
    iconButton: {
      padding: 10,
    },
    divider: {
      height: 28,
      margin: 4,
    },
  });

type ArrivalsdetailImageActionsProps = {
  image: any;
  index: any;
  rotateBase64Image: (image, index) => void;
  confirmDelete: (image) => void;
  changeComment: (comment, index) => void;
} & WithStyles<typeof styles>;

const ArrivalsdetailImageActions: React.SFC<ArrivalsdetailImageActionsProps> = (props) => {
  const { classes, image, index, rotateBase64Image, confirmDelete, changeComment } = props;
  return (
    <Paper className={classes.root}>
      <InputBase
        className={classes.input}
        onBlur={(event) => {
          changeComment(event.target.value, index);
        }}
        placeholder={image.comment && image.comment.length > 0 ? image.comment : "Comment"}
        // value={image.comment}
        inputProps={{ "aria-label": "add comment to image" }}
      />
      <IconButton
        className={classes.iconButton}
        aria-label="search"
        title="Rotate Image"
        color="primary"
        onClick={() => {
          rotateBase64Image(LZString.decompress(image.image), index);
        }}
      >
        <IconRotate />
      </IconButton>
      <Divider className={classes.divider} orientation="vertical" />
      <IconButton
        color="primary"
        className={classes.iconButton}
        aria-label="directions"
        title="Delete Image"
        onClick={() => {
          confirmDelete(index);
          // confirmDelete(image);
        }}
      >
        <IconDelete style={{ color: "red" }} />
      </IconButton>
    </Paper>
  );
};

export default withStyles(styles)(ArrivalsdetailImageActions);
