import { config } from "../../config";
import { client, apiHeaders, request } from "./_base";

const endpoint = "stockadjustment";

export const stockadjustment = client(endpoint, (item) => ({
  ...item,
}));

export const stockadjustmentFull = async (financial_year: number) => {
  return request(`${endpoint}/ext/full/${financial_year}`, {
    method: "GET",
  })
    .then(async (result) => {
      if (result.status == 500) {
        const resp = await result.json();
        throw resp;
      }
      return result.json();
    })
    .then(({ data }) => {
      return data;
    });
};

export const updateStockAdjustment = async (data: any[]) => {
  return request(`${endpoint}/ext/update`, {
    method: "POST",
    body: JSON.stringify(data),
  })
    .then(async (result) => {
      if (result.status == 500) {
        const resp = await result.json();
        throw resp;
      }
      return result.json();
    })
    .then(({ data }) => {
      return data;
    });
};

export const deleteSelectedBarcodes = async (data: { barcodes: string[]; reject_note: string }) => {
  return request(`${endpoint}/ext/deleteSelectedBarcodes`, {
    method: "POST",
    body: JSON.stringify(data),
  }).then(async (result) => {
    if (result.status == 500) {
      const resp = await result.json();
      throw resp;
    }
    return result;
  });
};

export const reverseSelectedBarcodes = async (barcodes: string[]) => {
  return request(`${endpoint}/ext/reverseSelectedBarcodes`, {
    method: "POST",
    body: JSON.stringify(barcodes),
  }).then(async (result) => {
    if (result.status == 500) {
      const resp = await result.json();
      throw resp;
    }
    return result;
  });
};
