export const TermsValueType = {
  0: "PERCENTAGE",
  1: "AMOUNT / CTN",
  2: "TOTAL AMOUNT",
  3: "BALANCE",
  4: "TOTAL AMOUNT LESS PAYMENT TERM 2",
  5: "AMOUNT",
};

export const getTermsValueTypeReadyForCombo = () => {
  const result = [];
  Object.keys(TermsValueType).map((st) => {
    result.push({ value: st, display: TermsValueType[st] });
  });
  return result;
};
