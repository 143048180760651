import React from "react";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";

import Input from "@material-ui/core/Input";

import Check from "@material-ui/icons/Check";
import Close from "@material-ui/icons/Close";

import Button from "@material-ui/core/Button";

import { phlabel } from "../../lib/api/phlabel";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1),
    },
    tableRow: {
      paddingTop: theme.spacing(1),
      paddingRight: "8px",
      marginTop: theme.spacing(1),
      textAlign: "right",
    },
    codeInput: {
      width: "100%",
      float: "left",
    },
    mulitLineDescript: {
      paddingTop: 0,
      paddingBottom: "40px",
      paddingRight: "8px",
    },
  });

type PHTreatmentsEditFormProps = {
  onSubmit: any;
  onClose: any;
  id: any;
} & WithStyles<typeof styles>;

class PHTreatmentsEditFormUnstyled extends React.Component<PHTreatmentsEditFormProps, any> {
  state = {
    classes: undefined,
    item: {
      id: "0",
      market: "",
      item: "",
      substance: "",
      wording: "",
    },
    onSubmit: undefined,
    onClose: undefined,
  };

  constructor(props) {
    super(props);
    this.state.classes = props.classes;
    this.state.onSubmit = props.onSubmit;
    this.state.onClose = props.onClose;
    if (props.id != 0) {
      this.getItem(props.id);
    }
  }

  getItem = (id) => {
    phlabel.single(id).then((data) => {
      this.setState({ item: data });
    });
  };

  handleFocus = (event) => {
    event.target.select();
  };

  handleSubmit = (e) => {
    this.state.onSubmit(e, this.state.item);
  };

  render() {
    const { classes } = this.state;

    return (
      <div style={{ padding: "20px" }}>
        <div className={classes.root}>
          <form
            id={this.state.item.id}
            onSubmit={(e) => {
              this.handleSubmit(e);
            }}
          >
            <table style={{ marginLeft: "15px" }}>
              <tbody>
                <tr className={classes.tableRow}>
                  <td className={classes.tableRow}>
                    <span>Market:</span>
                  </td>
                  <td className={classes.tableRow}>
                    <Input
                      className={classes.codeInput}
                      onFocus={this.handleFocus}
                      key={"body_value_market"}
                      name={"market"}
                      value={this.state.item.market}
                      onChange={(e) => {
                        this.setState({
                          item: { ...this.state.item, market: e.target.value },
                        });
                      }}
                    />
                  </td>
                </tr>
                <tr className={classes.tableRow}>
                  <td className={classes.tableRow}>
                    <span>Item:</span>
                  </td>
                  <td className={classes.tableRow}>
                    <Input
                      className={classes.codeInput}
                      onFocus={this.handleFocus}
                      key={"body_value_item"}
                      name={"item"}
                      value={this.state.item.item}
                      onChange={(e) => {
                        this.setState({
                          item: { ...this.state.item, item: e.target.value },
                        });
                      }}
                    />
                  </td>
                </tr>
                <tr className={classes.tableRow}>
                  <td className={classes.tableRow}>
                    <span>Substance:</span>
                  </td>
                  <td className={classes.tableRow}>
                    <Input
                      className={classes.codeInput}
                      onFocus={this.handleFocus}
                      key={"body_value_substance"}
                      name={"substance"}
                      value={this.state.item.substance}
                      onChange={(e) => {
                        this.setState({
                          item: { ...this.state.item, substance: e.target.value },
                        });
                      }}
                    />
                  </td>
                </tr>
                <tr className={classes.tableRow}>
                  <td className={classes.mulitLineDescript}>
                    <span>Wording:</span>
                  </td>
                  <td className={classes.tableRow}>
                    <Input
                      multiline={true}
                      rowsMax={3}
                      rows={3}
                      className={classes.codeInput}
                      onFocus={this.handleFocus}
                      key={"body_value_wording"}
                      name={"wording"}
                      value={this.state.item.wording}
                      onChange={(e) => {
                        this.setState({
                          item: { ...this.state.item, wording: e.target.value },
                        });
                      }}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
            <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "15px", marginRight: "7px", marginBottom: "-22px" }}>
              <Button style={{ marginRight: "10px" }} color="secondary" variant="outlined" onClick={this.state.onClose}>
                <Close />
              </Button>
              <Button type="submit" color="primary" variant="outlined" onClick={this.handleSubmit}>
                <Check />
              </Button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(PHTreatmentsEditFormUnstyled);
