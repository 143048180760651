import React from "react";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";

import Button from "@material-ui/core/Button";
import orange from "@material-ui/core/colors/orange";
import Badge from "@material-ui/core/Badge";
import CircularProgress from "@material-ui/core/CircularProgress";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Tooltip from "@material-ui/core/Tooltip";

import ReactDataGrid, { Row } from "react-data-grid";
import { Toolbar } from "react-data-grid-addons";
import "react-splitter-layout/lib/index";

import { DialogInformation } from "../../../lib/components/dialoginformation";
import Confirmation from "../../../lib/components/confirmation";
import GridColumnLayout from "../../../lib/components/gridlayout";

import "../../../lib/helpers/splitter.css";
import { generateSortFn } from "../../../lib/helpers/generateSortFN";
import { removeFromSticky, getStickyFilters } from "../../../lib/helpers/stickyfilters";
import { updateUserProfile } from "../../../lib/helpers/stickyfilters";

import { GridColumns } from "./producerstablesetupmain";
import { GridColumnsDetail } from "./producerstablesetupdetail";

import ProducersFilterChips from "../../../lib/components/filterchips";

import { getProducersFullByUniqueIdent, ProducersFullType } from "../../../lib/api/producersfull";

import Splitter from "m-react-splitters";
import "m-react-splitters/lib/splitters.css";

import { generateGridFilteredRows } from "../../../lib/helpers/generateGridFilteredRows";

import { ProducersFullGroupedType } from "../../../lib/api/producersfull";
import { isNullOrUndef } from "../../../lib/helpers/isNullOrUndef";

import { summaryRows, handleFilterChange, handleSortChange } from "../../../lib/helpers/grid";
import { GreenButton, OrangeButton } from "../../../lib/components/ColorButtons";
import { printProducerAdvanceRemittance, printProducerAdvanceShipmentRemittance } from "../../../reports/printing";

import classnames from "classnames";

import { DocumentsButton, DocumentsType } from "../../../lib/components/DocumentsButton";

import LayoutIcon from "@material-ui/icons/ViewColumn";
import { ClearFilterIcon } from "../../../icons/icons";
import Refresh from "@material-ui/icons/Refresh";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      height: "100%",
      width: "100%",
    },
    control: {
      paddingTop: "8px",
      paddingLeft: "0px",
      float: "right",
    },
    controlFloatLeft: {
      paddingTop: theme.spacing(1),
      float: "left",
      height: "10px",
    },
    controlButton: {
      paddingLeft: "10px",
      paddingBottom: "10px",
    },
    exportButton: {
      color: "white",
      backgroundColor: orange[700],
      "&:hover": {
        backgroundColor: orange[900],
      },
    },
    selectedHeader: {
      color: "white",
      backgroundColor: theme.palette.primary.main,
    },
    mainSplitterDiv: {
      height: "100%",
      width: "100%",
      marginTop: "60px",
      display: "grid",
    },
    rowActions: {
      "& .rdg-cell-action-menu": {
        position: "absolute",
        top: "calc(-60px - 24px)",
      },
    },
    boldRow: {
      fontWeight: "bold",
    },
    normalRow: {
      fontWeight: "normal",
    },
    purpleRow: {
      color: "purple",
    },
    blackRow: {
      color: "black",
    },
    darkorangeRow: {
      color: "darkorange",
    },
    greenRow: {
      color: "green",
    },
  });

type ProducersTableProps = {
  data: {}[];
  onReload: any;
  onPay: (advanceselected: [ProducersFullGroupedType], data: [ProducersFullType], isIntake: boolean) => void;
  editPay: (advanceselected: [ProducersFullGroupedType], data: [ProducersFullType], isIntake: boolean) => void;
  onRemovePaymentAll: (data: [ProducersFullType]) => void;
  onAddAdHoc: (record) => void;
} & WithStyles<typeof styles>;

class ProducersTableUnstyled extends React.Component<ProducersTableProps, {}> {
  state = {
    data: [{}],
    filters: {},
    filtersSelected: {},
    sorting: {},
    sortingSelected: {},
    onReload: undefined,
    onPay: undefined,
    editPay: undefined,
    onRemovePaymentAll: undefined,
    onAddAdHoc: undefined,
    selectedRows: [],
    selectedProducerAdvance: [],
    dialoginformation: undefined,
    gridRef: undefined,
    gridRefSelected: undefined,
    minGridHeight: 0,
    minGridHeightSecond: 0,
    columnsArranged: [],
    columnsWidth: [],
    columnsArrangedselected: [],
    columnsWidthselected: [],
    clearedPhrase: false,
    loadingDetail: false,
    tableLayout: false,
    tableLayoutSelected: false,
    layoutItem: "producers",
    widthItem: "producerstablewidth",
    layoutItemselected: "producerstablelayoutselected",
    widthItemselected: "producerstablewidthselected",
    sortitem: "producerstablesorting",
    sortitemselected: "producerstablesortingselected",
    currentSortColumn: "",
    currentSortDirection: "",
    currentSelectedSortColumn: "",
    currentSelectedSortDirection: "",
    showPrintDialog: false,
    showAdHocPayments: false,
  };

  constructor(props) {
    super(props);
    this.state.data = props.data;
    this.state.onReload = props.onReload;
    this.state.onPay = props.onPay;
    this.state.editPay = props.editPay;
    this.state.onRemovePaymentAll = props.onRemovePaymentAll;
    this.state.onAddAdHoc = props.onAddAdHoc;
    this.state.gridRef = React.createRef();
    this.state.gridRefSelected = React.createRef();
  }

  componentDidMount() {
    this.setGridHeights();

    const producertablelayout = localStorage.getItem(this.state.layoutItem);
    if (producertablelayout) {
      this.setState({ columnsArranged: JSON.parse(producertablelayout) }, () => {});
      updateUserProfile(JSON.parse(producertablelayout), this.state.layoutItem, "general");
    } else {
      const columnsToRender = GridColumns(undefined, undefined, undefined, undefined);
      const columnsArr = columnsToRender.map((column) => {
        return column.name;
      });

      this.setState({ columnsArranged: columnsArr }, () => {});
      updateUserProfile(columnsArr, this.state.layoutItem, "general");
    }

    const producertablewidth = localStorage.getItem(this.state.widthItem);
    if (producertablewidth) {
      this.setState({ columnsWidth: JSON.parse(producertablewidth) }, () => {});
      updateUserProfile(JSON.parse(producertablewidth), this.state.widthItem, "general");
    } else {
      const columnsToRender = GridColumns(undefined, undefined, undefined, undefined);
      const columnsArr = columnsToRender.map((column) => {
        return { name: column.name, width: column.width };
      });

      localStorage.setItem(this.state.widthItem, JSON.stringify(columnsArr));
      this.setState({ columnsWidth: columnsArr }, () => {});
      updateUserProfile(columnsArr, this.state.widthItem, "general");
    }

    const producertablelayoutSelected = localStorage.getItem(this.state.layoutItemselected);
    if (producertablelayoutSelected) {
      this.setState({ columnsArrangedselected: JSON.parse(producertablelayoutSelected) }, () => {});
      updateUserProfile(JSON.parse(producertablelayoutSelected), this.state.layoutItemselected, "general");
    } else {
      const columnsToRender = GridColumnsDetail(undefined, undefined, undefined, undefined);
      const columnsArr = columnsToRender.map((column) => {
        return column.name;
      });

      this.setState({ columnsArrangedselected: columnsArr }, () => {});
      updateUserProfile(columnsArr, this.state.layoutItemselected, "general");
    }

    const dispatchtablewidthSelected = localStorage.getItem(this.state.widthItemselected);
    if (dispatchtablewidthSelected) {
      this.setState({ columnsWidthselected: JSON.parse(dispatchtablewidthSelected) }, () => {});
      updateUserProfile(JSON.parse(dispatchtablewidthSelected), this.state.widthItemselected, "general");
    } else {
      const columnsToRender = GridColumnsDetail(undefined, undefined, undefined, undefined);
      const columnsArr = columnsToRender.map((column) => {
        return { name: column.name, width: column.width };
      });

      localStorage.setItem(this.state.widthItemselected, JSON.stringify(columnsArr));
      this.setState({ columnsWidthselected: columnsArr }, () => {});
      updateUserProfile(columnsArr, this.state.widthItemselected, "general");
    }

    this.setGridHeights();

    const producertablesorting = localStorage.getItem(this.state.sortitem);
    if (producertablesorting) {
      let sortingV = JSON.parse(producertablesorting);

      this.setState({ sorting: sortingV }, () => {
        this.setGridHeights();
      });
    }
  }

  componentDidUpdate() {
    if (this.state.gridRef && this.state.gridRef.grid) {
      this.state.gridRef.setState({ canFilter: true });
    }
    if (this.state.gridRefSelected && this.state.gridRefSelected.grid) {
      this.state.gridRefSelected.setState({ canFilter: true });
    }
  }

  getDetail(count, largeNumber = false) {
    const text = {
      __html: `
      <span style="${largeNumber ? "font-size: 21px" : ""}">${count}</span> Record(s) 
      <span style="font-size: 28px; line-height:0; "></span> 
      `,
    };
    return <span dangerouslySetInnerHTML={text} />;
  }

  getCount = (list, propName) => {
    if (list.length > 0) {
      const value = list
        .map((item) => {
          return item[propName];
        })
        .reduce((acc, curr) => {
          return Number(acc) + Number(curr);
        });
      return Number.isInteger(value) ? value.toFixed(2) : value;
    } else {
      return 0;
    }
  };

  handleFilterChange = (filter) => {
    const newFilters = handleFilterChange(filter, this.state.filters);
    this.setState({ filters: newFilters }, () => {
      this.setGridHeights();
    });
  };

  handleFilterChangeSelected = (filter) => {
    const newFilters = handleFilterChange(filter, this.state.filtersSelected);
    this.setState({ filtersSelected: newFilters }, () => {
      this.setGridHeights();
    });
  };

  getRows = (rows, filters, sorting) => {
    const result = generateGridFilteredRows(rows, filters).filter((row) => {
      if (this.state.showAdHocPayments) {
        return row.uniqueIdent == "0";
      } else {
        return row.uniqueIdent != "0";
      }
    });

    const fieldSorterData = Object.keys(sorting).map((item) => {
      return { name: item, reverse: sorting[item] == "ASC" ? false : true };
    });

    result.sort(generateSortFn(fieldSorterData));

    return result;
  };

  getValidFilterValues = (rows, columnId) => {
    const filterValues = rows
      .map((r) => r[columnId])
      .filter((item, i, a) => {
        return i === a.indexOf(item);
      })
      .sort((a, b) => a > b);
    return filterValues;
  };

  handleAvailableProducersAdvancesDoubleClick = async (row: ProducersFullGroupedType) => {
    this.setState({ loadingDetail: true, selectedRows: [] });

    const selectedAdvance = [];
    if (this.state.selectedProducerAdvance.find((item) => item == row)) {
      selectedAdvance.push(...this.state.selectedProducerAdvance.filter((item) => item != row));
    } else {
      selectedAdvance.push(...this.state.selectedProducerAdvance, row);
    }

    const selectedRowsData = [];
    for (let idxAdvance = 0; idxAdvance < selectedAdvance.length; idxAdvance++) {
      const element: ProducersFullGroupedType = selectedAdvance[idxAdvance];
      await getProducersFullByUniqueIdent(element.producer_id, element.uniqueIdent).then((result) => {
        selectedRowsData.push(...result);
      });
    }
    this.setState({ loadingDetail: false, selectedProducerAdvance: selectedAdvance, selectedRows: selectedRowsData }, () => {});
  };

  selectedClear = () => {
    this.setState({ selectedRows: [], selectedProducerAdvance: [] });
  };

  handleSort = (sortColumn, sortDirection) => {
    const sortingState = handleSortChange(this.state.sorting, sortColumn, sortDirection);

    this.setState({ sorting: sortingState, currentSortColumn: sortColumn, currentSortDirection: sortDirection }, () => {
      localStorage.setItem(this.state.sortitem, JSON.stringify(sortingState));
      updateUserProfile(sortingState, this.state.sortitem, "general");
    });
  };

  handleSortSelected = (sortColumn, sortDirection) => {
    const sortingState = handleSortChange(this.state.sortingSelected, sortColumn, sortDirection);

    this.setState({ sortingSelected: sortingState, currentSelectedSortColumn: sortColumn, currentSelectedSortDirection: sortDirection }, () => {
      localStorage.setItem(this.state.sortitemselected, JSON.stringify(sortingState));
      updateUserProfile(sortingState, this.state.sortitemselected, "general");
    });
  };

  handleRemoveSortEntry = (field) => {
    const collection = document.getElementsByClassName("pull-right");
    let i = 0;
    for (i = 0; i < collection.length; i++) {
      if (collection[i].innerHTML.length < 1) {
        continue;
      }
      (collection[i] as HTMLElement).innerHTML = "";
      break;
    }
    const sortingState = this.state.sorting;
    delete sortingState[field];
    this.setState({ sorting: sortingState }, () => {
      this.setGridHeights();
      localStorage.setItem(this.state.sortitem, JSON.stringify(sortingState));
      updateUserProfile(sortingState, this.state.sortitem, "general");
    });
  };

  handleRemoveFilterEntry = (key, value) => {
    removeFromSticky(key, value, "producersfullgroupedpmnt");

    this.setState({ filters: {} }, () => {
      this.setGridHeights();
    });
  };

  clearFilters = (columnToClear = undefined) => {
    const section = "producersfullgroupedpmnt";
    const sticky = getStickyFilters(section);

    localStorage.setItem(`stickyfilters_${section}`, JSON.stringify([]));
    updateUserProfile(sticky, undefined, section);
    this.setState({ filters: {} });
  };

  clearFiltersSelected = (columnToClear = undefined) => {
    const section = "producersfullgroupeddetailpmnt";
    const sticky = getStickyFilters(section);

    localStorage.setItem(`stickyfilters_${section}`, JSON.stringify([]));
    updateUserProfile(sticky, undefined, section);
    this.setState({ filtersSelected: {} });
  };

  getChipsHeight = () => {
    return (Object.keys(this.state.sorting).length > 0 ? 42 : 0) + (Object.keys(this.state.filters).length > 0 ? 42 * Object.keys(this.state.filters).length : 0);
  };

  setGridHeights = () => {
    const primarysplit = document.getElementsByClassName("splitter horizontal");
    this.setState({ minGridHeight: primarysplit[0].children[0].clientHeight - (50 + this.getChipsHeight()), minGridHeightSecond: primarysplit[0].children[2].clientHeight - 100 });
  };

  handleInfoClose = () => {
    this.setState({ dialoginformation: undefined });
  };

  handleLayout = () => {
    this.setState({ tableLayout: true });
  };

  handleLayoutClose = () => {
    if (!this.phrase || this.phrase.length === 0) {
      this.setState({ tableLayout: false });
    } else {
      this.clearPhrase();
    }
  };

  handleLayoutConfirm = (columns) => {
    localStorage.setItem(this.state.layoutItem, JSON.stringify(columns));
    this.setState({ tableLayout: false, columnsArranged: columns }, () => {
      this.setGridHeights();
    });
  };

  handleLayoutSelected = () => {
    this.setState({ tableLayoutSelected: true });
  };

  handleLayoutCloseSelected = () => {
    this.setState({ tableLayoutSelected: false });
  };

  handleLayoutConfirmSelected = (columns) => {
    localStorage.setItem(this.state.layoutItemselected, JSON.stringify(columns));
    this.setState({ tableLayoutSelected: false, columnsArrangedselected: columns });
  };

  handleColumnResize = (index, width) => {
    const columnName = this.state.columnsArranged[index];
    const newArr = [...this.state.columnsWidth];
    const colwidthRecord = this.state.columnsWidth.findIndex((item) => item.name.trim() == columnName.trim());
    if (colwidthRecord >= 0) {
      newArr[colwidthRecord].width = width;
      localStorage.setItem(this.state.widthItem, JSON.stringify(newArr));
      updateUserProfile(newArr, this.state.widthItem, "general");
      this.setState({ columnsWidth: newArr });
    }
  };

  handleColumnResizeSelected = (index, width) => {
    const columnName = this.state.columnsArrangedselected[index];
    const newArr = [...this.state.columnsWidthselected];
    const colwidthRecord = this.state.columnsWidthselected.findIndex((item) => item.name.trim() == columnName.trim());
    if (colwidthRecord >= 0) {
      newArr[colwidthRecord].width = width;
      localStorage.setItem(this.state.widthItemselected, JSON.stringify(newArr));
      updateUserProfile(newArr, this.state.widthItemselected, "general");
      this.setState({ columnsWidthselected: newArr });
    }
  };

  filterTerms = (filterObj, term = "filterTerm") => {
    let val = 0;

    Object.keys(filterObj).map((key) => {
      val += filterObj[key][term].length;
    });

    return val;
  };

  phrase = "";
  setSearchPhrase = (phrase) => {
    this.phrase = phrase;
  };
  clearPhrase = () => {
    this.setSearchPhrase("");
    this.setState({ clearedPhrase: !this.state.clearedPhrase });
  };

  handleTabChange = (_, newValue: number) => {
    this.setState({ tabValue: newValue }, () => {
      this.setGridHeights();
    });
  };

  rowRenderer = (props, classes) => {
    const { row }: { row: ProducersFullGroupedType } = props;
    const color = isNullOrUndef(row.bar_NoCartons)
      ? classes.purpleRow
      : row.sum_coldCartonValueZAR + row.sum_intakeCartonValueZAR + row.sum_shippingCartonValueZAR == 0
      ? classes.blackRow
      : row.sum_coldCartonValueZAR + row.sum_intakeCartonValueZAR + row.sum_shippingCartonValueZAR != row.sum_paidzarOpen
      ? classes.darkorangeRow
      : classes.greenRow;

    const recordSelected = this.state.selectedProducerAdvance.find(
      (rowitem) =>
        rowitem.producer_id == row.producer_id &&
        rowitem.producer_name == row.producer_name &&
        rowitem.dispatch_currencycode == row.dispatch_currencycode &&
        rowitem.wkcolddue_week == row.wkcolddue_week &&
        rowitem.wkintakedue_week == row.wkintakedue_week &&
        rowitem.wkshippingdue_week == row.wkshippingdue_week &&
        rowitem.bar_PalletSize == row.bar_PalletSize &&
        rowitem.bar_NoCartons == row.bar_NoCartons &&
        rowitem.paid_currency == row.paid_currency &&
        rowitem.sum_paidcurrencyValue == row.sum_paidcurrencyValue &&
        rowitem.adhocdetail_remainingamount == row.adhocdetail_remainingamount &&
        rowitem.produceradvancepaidhead_ident == row.produceradvancepaidhead_ident &&
        rowitem.produceradvancepaidhead_makeanote == row.produceradvancepaidhead_makeanote &&
        rowitem.produceradvancepaidhead_posteddate == row.produceradvancepaidhead_posteddate &&
        rowitem.paphweek_week == row.paphweek_week,
    );

    const fontWeight = recordSelected ? classes.boldRow : classes.normalRow;

    return <Row {...props} className={classnames(fontWeight, color)} onDoubleClickCapture={() => this.handleAvailableProducersAdvancesDoubleClick(row)} />;
  };

  handlePrintRemittance = async () => {
    if (this.state.selectedProducerAdvance.length > 1) {
      this.setState({ showPrintDialog: true, anchorEl: null });
    } else {
      const selectedAdvance: ProducersFullGroupedType = this.state.selectedProducerAdvance[0];
      if (selectedAdvance.wkshippingdue_id) {
        await printProducerAdvanceShipmentRemittance(selectedAdvance);
      } else {
        await printProducerAdvanceRemittance(selectedAdvance);
      }
      this.setState({ anchorEl: null });
    }
  };

  handlePrintRemittanceExcel = () => {
    if (this.state.selectedProducerAdvance.length > 1) {
      this.setState({ showPrintDialog: true, anchorEl: null });
    } else {
      const selectedAdvance = this.state.selectedProducerAdvance[0];
      window.location.href = `/api/producersfull/ex/printAdvanceRemittanceExcel/${selectedAdvance.producer_id}/${selectedAdvance.uniqueIdent}`;
    }
  };

  handleClosePrintDialog = () => {
    this.setState({ showPrintDialog: false });
  };

  menuItems = [
    {
      title: "REMITTANCE",
      options: [
        {
          title: "PDF",
          action: this.handlePrintRemittance,
          icon: DocumentsType.PDF,
        },
        {
          title: "EXCEL",
          action: this.handlePrintRemittanceExcel,
          icon: DocumentsType.EXCEL,
        },
      ],
    },
  ];

  handleChangeShowAdHocPayments = () => {
    this.setState({ showAdHocPayments: !this.state.showAdHocPayments });
  };

  render() {
    const { classes } = this.props;

    const filteredRows = this.getRows(this.state.data, this.state.filters, this.state.sorting);
    const filteredRowsSelected = this.getRows(this.state.selectedRows, this.state.filtersSelected, this.state.sortingSelected);
    const columnsToRender = GridColumns(this.state.data, this.state.filters, this.state.columnsArranged, this.state.columnsWidth);
    const columnsToRenderSelected = GridColumnsDetail(this.state.selectedRows, this.state.filtersSelected, this.state.columnsArrangedselected, this.state.columnsWidthselected);

    const totalRowColumns = [
      "bar_PalletSize",
      "bar_NoCartons",
      "sum_paidcurrencyValue",
      "sum_paidcurrencyValueTotal",
      "adhocdetail_remainingamount",
      "coldCartonValueZAR",
      "coldCartonValueCAD",
      "coldCartonValueEUR",
      "coldCartonValueGBP",
      "coldCartonValueUSD",
      "intakeCartonValueZAR",
      "intakeCartonValueCAD",
      "intakeCartonValueEUR",
      "intakeCartonValueGBP",
      "intakeCartonValueUSD",
      "shippingCartonValueZAR",
      "shippingCartonValueCAD",
      "shippingCartonValueEUR",
      "shippingCartonValueGBP",
      "shippingCartonValueUSD",
      "paid_zar",
      "paid_cad",
      "paid_eur",
      "paid_gbp",
      "paid_usd",
    ];

    return (
      <div className={classes.root}>
        {this.state.tableLayout && (
          <Confirmation isOpen={this.state.tableLayout} handleClose={this.handleLayoutClose} handleConfirm={() => {}} title="Editing Grid Layout" body={undefined}>
            <GridColumnLayout
              layoutItem={this.state.layoutItem}
              onSubmit={this.handleLayoutConfirm}
              onClose={this.handleLayoutClose}
              columns={this.state.columnsArranged}
              setSearchPhrase={this.setSearchPhrase}
              clearPhrase={this.state.clearedPhrase}
            />
          </Confirmation>
        )}
        {this.state.tableLayoutSelected && (
          <Confirmation isOpen={this.state.tableLayoutSelected} handleClose={this.handleLayoutCloseSelected} handleConfirm={() => {}} title="Editing Grid Layout" body={undefined}>
            <GridColumnLayout
              layoutItem={this.state.layoutItem}
              onSubmit={this.handleLayoutConfirmSelected}
              onClose={this.handleLayoutCloseSelected}
              columns={this.state.columnsArrangedselected}
              setSearchPhrase={this.setSearchPhrase}
              clearPhrase={this.state.clearedPhrase}
            />
          </Confirmation>
        )}
        {this.state.dialoginformation && (
          <DialogInformation
            isOpen={true}
            handleClose={this.handleInfoClose}
            handleOK={this.handleInfoClose}
            title={this.state.dialoginformation.title}
            body={`${this.state.dialoginformation.body}`}
            showinput={false}
          />
        )}
        {this.state.showPrintDialog && (
          <DialogInformation
            isOpen={true}
            handleClose={this.handleClosePrintDialog}
            handleOK={this.handleClosePrintDialog}
            title={"Printing PDF"}
            body={"More than one advance is selected. You can only print a single advance."}
            showinput={false}
          />
        )}
        <span className={classes.controlFloatLeft}>
          <span style={{ color: "orange", fontWeight: "bold" }}>{this.getDetail(filteredRows.length - 1)}</span>
          <FormControlLabel
            style={{ paddingLeft: "10px" }}
            control={<Checkbox checked={this.state.showAdHocPayments} onChange={this.handleChangeShowAdHocPayments} name="minimize" color="primary" />}
            label={"Show Ad-Hoc Transactions"}
          />
        </span>
        <div className={classes.control}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              this.state.onAddAdHoc(this.state.selectedProducerAdvance.find((item) => item.adhoc_id != 0));
            }}
            style={{ marginRight: "10px", backgroundColor: "green" }}
          >
            {!this.state.selectedProducerAdvance.find((item) => item.adhoc_id != 0) ? `Create Add Hoc Transaction` : `Edit Add Hoc Transaction`}
          </Button>
          <Tooltip title="Clear Filters">
            <Button
              variant="contained"
              color="primary"
              onClick={() => this.clearFilters()}
              style={{ marginRight: "10px" }}
              disabled={Object.entries(this.state.filters).length === 0 && this.state.filters.constructor === Object}
            >
              <Badge badgeContent={this.filterTerms(this.state.filters)} color="secondary">
                <ClearFilterIcon />
              </Badge>
            </Button>
          </Tooltip>
          <Tooltip title="Refresh Data">
            <Button variant="contained" color="primary" onClick={this.state.onReload} style={{ marginRight: "10px" }}>
              <Refresh />
            </Button>
          </Tooltip>
          <Tooltip title="Grid Layout">
            <Button variant="contained" color="primary" onClick={this.handleLayout} style={{ marginRight: "10px" }}>
              <LayoutIcon />
            </Button>
          </Tooltip>
        </div>
        <div id="main_splitter_div" className={classes.mainSplitterDiv}>
          <Splitter
            position="horizontal"
            primaryPaneMaxHeight="calc(100% - 150px)"
            primaryPaneHeight="calc(100% - 350px)"
            primaryPaneWidth="100%"
            dispatchResize={true}
            postPoned={true}
            onDragFinished={(e) => {
              this.setGridHeights();
            }}
          >
            <div id="primary_grid" style={{ marginLeft: "1px" }}>
              <ReactDataGrid
                className="rdg-light"
                ref={(input) => (this.state.gridRef = input)}
                columns={columnsToRender}
                rows={filteredRows}
                enableFilterRow={true}
                rowHeight={30}
                headerRowHeight={35}
                style={{ position: "relative", height: this.state.minGridHeight, width: "100%", marginTop: "10px" }}
                onFiltersChange={(filter) => this.handleFilterChange(filter)}
                onSort={(sortColumn, sortDirection) => this.handleSort(sortColumn, sortDirection)}
                sortColumn={this.state.currentSortColumn}
                sortDirection={this.state.currentSortDirection as any}
                onColumnResize={this.handleColumnResize}
                summaryRows={summaryRows(filteredRows, totalRowColumns)}
                rowRenderer={(props) => this.rowRenderer(props, classes)}
              />
              <div>
                <ProducersFilterChips
                  columns={columnsToRender}
                  filterData={this.state.filters}
                  handleFilterClear={null}
                  sortingData={this.state.sorting}
                  removesortingentry={this.handleRemoveSortEntry}
                  removeFilterEntry={this.handleRemoveFilterEntry}
                />
              </div>
            </div>
            <div id="secondary_grid">
              {this.state.loadingDetail && <CircularProgress style={{ position: "absolute", left: "0px" }} />}
              <Toolbar enableFilter={false}>
                <DocumentsButton menuItems={this.menuItems} disabled={!(filteredRowsSelected || []).find((item) => item.produceradvancepaidhead_id)} />
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => this.selectedClear()}
                  style={{ marginLeft: "10px" }}
                  disabled={!(filteredRowsSelected && filteredRowsSelected.length > 0)}
                >
                  clear selected
                </Button>
                <GreenButton
                  variant="contained"
                  color="primary"
                  onClick={() => this.state.onPay(this.state.selectedProducerAdvance, this.state.selectedRows)}
                  style={{ marginLeft: "10px" }}
                  disabled={!(filteredRowsSelected && filteredRowsSelected.length > 0)}
                >
                  Make Payment
                </GreenButton>
                <OrangeButton
                  variant="contained"
                  disabled={!(filteredRowsSelected || []).find((item) => item.produceradvancepaidhead_id)}
                  onClick={() => this.state.editPay(this.state.selectedProducerAdvance, this.state.selectedRows)}
                >
                  Edit Payment
                </OrangeButton>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => this.state.onRemovePaymentAll(filteredRowsSelected)}
                  disabled={!(filteredRowsSelected && filteredRowsSelected.length > 0)}
                >
                  Reverse Payment
                </Button>
                <Tooltip title="Clear Filters">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={this.clearFiltersSelected}
                    style={{ marginRight: "10px", marginLeft: "10px" }}
                    disabled={Object.entries(this.state.filtersSelected).length === 0 && this.state.filtersSelected.constructor === Object}
                  >
                    <Badge badgeContent={this.filterTerms(this.state.filtersSelected)} color="secondary">
                      <ClearFilterIcon />
                    </Badge>
                  </Button>
                </Tooltip>
                <Tooltip title="Grid Layout">
                  <Button variant="contained" color="primary" onClick={this.handleLayoutSelected} style={{ marginRight: "10px" }}>
                    <LayoutIcon />
                  </Button>
                </Tooltip>
              </Toolbar>
              <ReactDataGrid
                className="rdg-light"
                ref={(input) => (this.state.gridRefSelected = input)}
                columns={columnsToRenderSelected}
                rows={filteredRowsSelected}
                enableFilterRow={true}
                rowHeight={30}
                headerRowHeight={35}
                style={{ position: "relative", height: this.state.minGridHeightSecond, width: "100%", marginTop: "10px" }}
                onFiltersChange={(filter) => this.handleFilterChangeSelected(filter)}
                onSort={(sortColumn, sortDirection) => this.handleSortSelected(sortColumn, sortDirection)}
                sortColumn={this.state.currentSelectedSortColumn}
                sortDirection={this.state.currentSelectedSortDirection as any}
                onColumnResize={this.handleColumnResize}
                summaryRows={summaryRows(filteredRowsSelected, totalRowColumns)}
              />
            </div>
          </Splitter>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(ProducersTableUnstyled);
