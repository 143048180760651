import React from "react";
import CustomAutoCompleteFilter from "../../../lib/components/customdatagridfilter/CustomFilter";

import { SummaryNumber, SummaryNumber2Decimal, SummaryTotal } from "../../../lib/components/SummaryFormatter";
import { FormatterNumber, FormatterNumber2Decimals } from "../../../lib/components/Formatters";
import { handleColumnsArrangement, handleColumnsWidth } from "../../../lib/helpers/grid";

const cellWidthSmallX = 90;
const cellWidthSmall = 115;
const cellWidthMedium = 150;
const cellWidthLarge = 220;

const defaultColumnProperties = {
  filterable: true,
  resizable: true,
  sortable: true,
};

let currentSpace = true;

export const GridColumnsDetail = (data, filters, arrangement, columnsWidth) => {
  let postValue = "";

  if (data) {
    if (currentSpace) {
      postValue = " ";
    }
    currentSpace = !currentSpace;
  }

  const columns = [
    {
      key: "loadoutdetail_barcode",
      name: "Barcode" + postValue,
      width: cellWidthLarge,
      filterRenderer: (e) => {
        return (
          <CustomAutoCompleteFilter
            {...e}
            multiSelection={true}
            option={data}
            currentFilters={filters}
            fieldname={"loadoutdetail_barcode"}
            data={data}
            section={"producerinvoicepallets"}
          />
        );
      },
      summaryFormatter: SummaryTotal,
    },
    {
      key: "loadout_reference",
      name: "Reference" + postValue,
      width: cellWidthSmall,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"loadout_reference"} data={data} section={"producerinvoicepallets"} />;
      },
    },
    {
      key: "barcode_commoditycode",
      name: "Commodity" + postValue,
      width: cellWidthSmallX,
      filterRenderer: (e) => {
        return (
          <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"barcode_commoditycode"} data={data} section={"producerinvoicepallets"} />
        );
      },
    },
    {
      key: "barcode_varietycode",
      name: "Variety" + postValue,
      width: cellWidthSmallX,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"barcode_varietycode"} data={data} section={"producerinvoicepallets"} />;
      },
    },
    {
      key: "barcode_packcode",
      name: "Pack" + postValue,
      width: cellWidthSmallX,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"barcode_packcode"} data={data} section={"producerinvoicepallets"} />;
      },
    },
    {
      key: "barcode_markcode",
      name: "Mark" + postValue,
      width: cellWidthSmallX,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"barcode_markcode"} data={data} section={"producerinvoicepallets"} />;
      },
    },
    {
      key: "barcode_gradecode",
      name: "Grade" + postValue,
      width: cellWidthSmallX,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"barcode_gradecode"} data={data} section={"producerinvoicepallets"} />;
      },
    },
    {
      key: "barcode_countcode",
      name: "Count" + postValue,
      width: cellWidthSmallX,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"barcode_countcode"} data={data} section={"producerinvoicepallets"} />;
      },
      formatter: FormatterNumber,
    },
    {
      key: "barcode_inventorycode",
      name: "Inventory" + postValue,
      width: cellWidthSmallX,
      filterRenderer: (e) => {
        return (
          <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"barcode_inventorycode"} data={data} section={"producerinvoicepallets"} />
        );
      },
    },
    {
      key: "barcode_nocartons",
      name: "No Cartons" + postValue,
      width: cellWidthSmallX,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"barcode_nocartons"} data={data} section={"producerinvoicepallets"} />;
      },
      formatter: FormatterNumber,
      summaryFormatter: SummaryNumber,
    },
    {
      key: "barcode_palletsize",
      name: "Pallet Size" + postValue,
      width: cellWidthSmallX,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"barcode_palletsize"} data={data} section={"producerinvoicepallets"} />;
      },
      formatter: FormatterNumber2Decimals,
      summaryFormatter: SummaryNumber2Decimal,
    },
    {
      key: "stockdetail_sellingprice",
      name: "Selling Price" + postValue,
      width: cellWidthSmall,
      filterRenderer: (e) => {
        return (
          <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"stockdetail_sellingprice"} data={data} section={"producerinvoicepallets"} />
        );
      },
      formatter: FormatterNumber2Decimals,
      summaryFormatter: SummaryNumber2Decimal,
    },
    {
      key: "total",
      name: "Total" + postValue,
      width: cellWidthSmall,
      filterable: false,
      formatter: FormatterNumber2Decimals,
      summaryFormatter: SummaryNumber2Decimal,
    },
    {
      key: "comment",
      name: "Comment" + postValue,
      width: cellWidthSmall,
      filterable: false,
    },
    {
      key: "producer_code",
      name: "Producer" + postValue,
      width: cellWidthSmall,
      filterable: false,
    },
  ].map((c) => ({ ...c, ...defaultColumnProperties }));

  let columnsArranged = [];
  columnsArranged = handleColumnsArrangement(columnsArranged, arrangement, columns);
  columnsArranged = handleColumnsWidth(columnsArranged, columnsWidth, columns);

  return columnsArranged;
};
