import React from "react";

import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";

import CustomAutoCompleteFilter from "../../lib/components/customdatagridfilter/CustomFilter";

const cellWidthTiny = 40;
const cellWidthXtraSmall = 70;
const cellWidthSmall = 80;
const cellWidthSmallBig = 140;
const cellWidthMedium = 200;
// const cellWidthLarge = 250;
const cellWidthXtrLarge = 350;

const defaultColumnProperties = {
  resizable: true,
  sortable: true,
};

let currentSpace = true;
export const GridColumns = (data, filters) => {
  let postValue = "";

  if (data) {
    if (currentSpace) {
      postValue = " ";
    }
    currentSpace = !currentSpace;
  }

  return [
    { key: "actions", name: "Actions", width: cellWidthSmall },
    {
      key: "clientcode",
      name: "Client Code" + postValue,
      width: cellWidthSmallBig,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"clientcode"} data={data} />;
      },
    },
    {
      key: "swiftcountrycode",
      name: "Swift Country Code" + postValue,
      width: cellWidthSmallBig,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"swiftcountrycode"} data={data} />;
      },
    },
  ].map((c) => ({ ...c, ...defaultColumnProperties }));
};

const actions = (order, handleRemove, handleEdit) => {
  return [
    {
      icon: (
        <span title="Edit">
          <EditIcon style={{ cursor: "pointer", color: "orange" }} />
        </span>
      ),
      callback: (e) => {
        handleEdit(order.id);
      },
    },
    {
      icon: (
        <span title="Remove">
          <DeleteIcon style={{ cursor: "pointer", color: "red" }} />
        </span>
      ),
      callback: (e) => {
        handleRemove(order.id);
      },
    },
  ];
};

export default function getCellActions(column, row, handleRemove, handleEdit) {
  if (column.key != "actions") {
    return null;
  }
  const cellActions = {
    actions: actions(row, handleRemove, handleEdit),
  };
  return cellActions[column.key];
}
