import React from "react";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";

import ReactDataGrid from "react-data-grid";
// import { Toolbar, Data } from "react-data-grid-addons";

import { GridColumns } from "./templatesMasterSetup";
import { file } from "jszip";
// const selectors = Data.Selectors;

const styles = (theme: Theme) =>
  createStyles({
    root: {
      height: "100%",
      width: "100%",
    },
  });

type TemplatesMasterProps = {
  maintenancemode: boolean;
  templateData: Array<any>;
  handleRemoveTemplate: (id) => void;
  handleEditTemplate: (row) => void;
  handleMasterClick: (row) => void;
} & WithStyles<typeof styles>;

export class TemplatesMasterUnstyled extends React.Component<TemplatesMasterProps, any> {
  state = {
    classes: undefined,
    gridRef: undefined,
    templateData: [],
    handleRemoveTemplate: undefined,
    handleEditTemplate: undefined,
    handleMasterClick: undefined,
    maintenancemode: false,
  };

  constructor(props) {
    super(props);
    this.state.classes = props.classes;
    this.state.gridRef = React.createRef();
    this.state.templateData = props.templateData;
    this.state.handleRemoveTemplate = props.handleRemoveTemplate;
    this.state.handleEditTemplate = props.handleEditTemplate;
    this.state.handleMasterClick = props.handleMasterClick;
    this.state.maintenancemode = props.maintenancemode;
  }

  // onGridRowsUpdated = ({ fromRow, toRow, updated }) => {
  //   this.setState((state) => {
  //     const rows = state.rows.slice();
  //     for (let i = fromRow; i <= toRow; i++) {
  //       rows[i] = { ...rows[i], ...updated };
  //     }
  //     return { rows };
  //   });
  // };

  render() {
    const { classes } = this.state;

    return (
      <div className={classes.root}>
        <ReactDataGrid
          className="rdg-light"
          ref={(input) => (this.state.gridRef = input)}
          columns={GridColumns(this.state.maintenancemode, this.state.handleRemoveTemplate, this.state.handleEditTemplate)}
          rows={this.state.templateData}
          onRowClick={(_, row) => this.state.handleMasterClick(row)}
          style={{ height: document.getElementById("templatesMasterMain") != null ? document.getElementById("templatesMasterMain").clientHeight - 50 : 250 }}
        />
      </div>
    );
  }
}

export default withStyles(styles)(TemplatesMasterUnstyled);
