import React from "react";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";

import Input from "@material-ui/core/Input";

import Check from "@material-ui/icons/Check";
import Close from "@material-ui/icons/Close";

import Button from "@material-ui/core/Button";
import { labtesttype } from "../../lib/api/labtesttype";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1),
    },
    tableRowFieldTitle: {
      paddingTop: theme.spacing(1),
      paddingRight: theme.spacing(1) * 2,
      marginTop: theme.spacing(1),
      textAlign: "right",
      minWidth: "145px",
    },
    tableRowFieldData: {
      paddingTop: theme.spacing(1),
      paddingRight: theme.spacing(1) * 2,
      marginTop: theme.spacing(1),
      textAlign: "left",
    },
    tableRowFieldInput: {
      width: "310px",
    },
  });

type LabTestT = {
  onSubmit: any;
  onClose: any;
  id: any;
} & WithStyles<typeof styles>;

class LabTestTypeUnstyled extends React.Component<LabTestT, any> {
  state = {
    classes: undefined,
    item: {
      id: "0",
      detail: "",
    },
    onSubmit: undefined,
    onClose: undefined,
    id: 0,
    labtesttypeData: undefined,
  };

  constructor(props) {
    super(props);
    this.state.classes = props.classes;
    this.state.onSubmit = props.onSubmit;
    this.state.onClose = props.onClose;
    this.state.id = props.id;
  }

  componentWillMount() {
    this.loadData().then(() => {
      if (this.state.id != 0) {
        this.getItem(this.state.id);
      }
    });
  }

  loadData = async () => {
    await Promise.all([labtesttype.all()]).then((results) => {
      this.setState({ labtesttypeData: results[0] }, () => {});
    });
  };

  getItem = (id) => {
    labtesttype.single(id).then((data) => {
      this.setState({ item: data });
    });
  };

  handleSubmit = () => {
    this.state.onSubmit(this.state.item);
  };

  render() {
    const { classes } = this.state;
    return (
      <div className={classes.root}>
        <form
          id={this.state.item.id}
          onSubmit={(e) => {
            this.handleSubmit();
          }}
        >
          <table style={{ marginLeft: "15px" }}>
            <tbody>
              <tr>
                <td className={classes.tableRowFieldTitle}>
                  <span>Detail:</span>
                </td>
                <td className={classes.tableRowFieldData}>
                  <Input
                    fullWidth
                    name={"detail"}
                    key={"detail"}
                    value={this.state.item.detail}
                    type="text"
                    className={classes.tableRowFieldInput}
                    onChange={(e) => {
                      this.setState({
                        item: { ...this.state.item, detail: e.target.value },
                      });
                    }}
                  />
                </td>
              </tr>
            </tbody>
          </table>
          <div style={{ display: "flex", justifyContent: "flex-end", margin: "10px 0 -5px 0" }}>
            <Button style={{ marginRight: "10px" }} color="secondary" variant="outlined" onClick={this.state.onClose}>
              <Close />
            </Button>
            <Button type="submit" color="primary" variant="outlined" onClick={() => this.handleSubmit()}>
              <Check />
            </Button>
          </div>
        </form>
      </div>
    );
  }
}

export default withStyles(styles)(LabTestTypeUnstyled);
