import { request, apiHeaders } from "../api/_base";
// import streamSaver from "streamsaver";

import LZString from "lz-string";
import format from "date-fns/format";
import parseISO from "date-fns/parseISO";
import numeral from "numeral";
import { adhocdetailByPaidHeadId } from "../api/producerfinalpaidadhocdetail";
import { getProducerByProducerCode, getProducerByProducerId } from "../api/producer";
import { ConsolidationFinalDetailType } from "../api/consolidation";
import { companyDetails } from "../api/companydetails";
import { DateFormatter, FormatterNumber, FormatterNumber2Decimals, FormatterNumber2DecimalsNegative, FormatterNumber3Decimals } from "../components/Formatters";

export const InvoiceDetailHeader = {
  Reference: "reference",
  Barcode: "barcode",
  "Notification Reference": "loadout_exportNotificationReference",
  DateLoading: "loadout_dateLoading",
  Voyage: "loadout_voyageNumber",
  "Port Loading": "loadout_portLoading",
  "Port Discharge": "loadout_portDischarge",
  "Port Final": "loadout_portFinal",
  "Shipping Line": "loadout_shippingline",
  Vessel: "loadout_vessel",
  Coldroom: "loadout_coldroom_code",
  "Selling Price": "sellp",
  "Temp Code": "tempcode",
  "PO Number": "po",
  POD: "pod",
  Location: "locationCode",
  Age: "age",
  "Age@Loc": "ageAtLocation",
  Commodity: "commodityCode",
  Variety: "varietyCode",
  TM: "targetMarket",
  TC: "targetCountry",
  Producer: "producerId",
  Solas: "producer_solasmethod2",
  PUC: "farmNumber",
  Grade: "gradeCode",
  Count: "countCode",
  Pack: "packCode",
  Mark: "markCode",
  "no Cartons": "noCartons",
  PalletSize: "palletSize",
  "Inv Code": "inventoryCode",
  Consignee: "consigneeId",
  "Consignee Name": "consigneeName",
  "Picking Ref": "pickingRef",
  "Intake Waybill": "intakeWaybill",
  phc: "phc",
  "Cold Date": "coldDate",
  "Insp Age": "inspectionAge",
  Orchard: "orchard",
  "Gross Weight": "grossWeight",
  "Net Weight": "weight",
  "Inspection Date": "inspectionDate",
  "Org Intake Date": "originalIntakeDate",
  "Purchase Price": "purchasePrice",
  "Target Region": "targetRegion",
  Temperature: "temperature",
  UserName: "userName",
  Trader: "traderid",
  Org: "organization",
  "Date Entered": "dateEntered",
  total: "total",
};

export const OrderHeader = {
  piinstruction_id: "Piinstruction ID",
  id: "ID",
  groupnum: "Groupnum",
  groupline: "Groupline",
  week: "Week",
  revision: "Revision",
  Farm: "Producer",
  Commodity_Name: "Commodity Name",
  Commodity_Code: "Commodity Code",
  Variety_Name: "Variety Name",
  Variety_Code: "Variety Code",
  class: "Class",
  count: "Count",
  Palletspec_Carton: "Palletspec Carton",
  Palletspec_Code: "Palletspec Code",
  brand: "Brand",
  packing: "Packing",
  countconstraint: "Count Constraint",
  farm_local: "Farm local",
  exworks: "Exworks",
  volume: "Volume",
  target_region: "Target Region",
  target_market: "Target Market",
  target_country: "Target Country",
  coldroom: "Coldroom",
  mgp: "MGP/FIXED",
  target_currency: "Target Currency",
  fob: "FOB",
  dip: "DIP",
  dipmin: "DIP Min",
  dipmax: "DIP Max",
  rangemin: "Range Min",
  rangemax: "Range Max",
  exchange: "Exchange",
  locked: "Locked",
  printdate: "Print Date",
  OrderNumber: "Order Number",
};

export const StockAllocationsHeader = {
  barcode: "Barcode",
  reference: "Load Ref",
  sellp: "Selling Price",
  tempcode: "Temp Code",
  po: "PO Number",
  pod: "POD",
  locationCode: "Location",
  sscc: "SSCC",
  age: "Age",
  ageAtLocation: "Age at Location",
  commodityCode: "Commodity",
  targetCountry: "TC",
  targetMarket: "TM",
  producerId: "Producer",
  coldDate: "Cold Date",
  consigneeId: "Consignee",
  consigneeName: "Consignee Name",
  countCode: "Count",
  dateEntered: "Date Entered",
  farmNumber: "PUC",
  gradeCode: "Grade",
  grossWeight: "Gross Weight",
  inspectionAge: "Insp Age",
  inspectionDate: "Inspection Date",
  intakeWaybill: "Intake Waybill",
  originalIntakeWaybill: "Original Intake Waybill",
  inventoryCode: "Inventory Code",
  markCode: "Mark",
  noCartons: "No Cartons",
  orchard: "Orchard",
  organization: "Org",
  originalIntakeDate: "Org Intake Date",
  packCode: "Pack",
  palletSize: "PalletSize",
  phc: "PHC",
  pickingRef: "Picking Ref",
  purchasePrice: "Purchase Price",
  targetRegion: "Target Region",
  temperature: "Temperature",
  traderid: "Trader",
  userName: "UserName",
  varietyCode: "Variety",
  weight: "Net Weight",
  phyto_verification: "Phyto Verification",
};

export const LoadoutHeader = {
  dispatch_dispatchdocs_id: "dispatch_dispatchdocs_id",
  sale_date: "sale_date",
  sale_invoicenumber: "sale_invoicenumber",
  sale_ucr: "sale_ucr",
  loadout_consignee: "loadout_consignee",
  terms_description: "terms_description",
  currency_code: "currency_code",
  users_username: "users_username",
  barcode_nocartons: "barcode_nocartons",
  barcode_palletsize: "barcode_palletsize",
  total: "total",
  orderno: "orderno",
  vessel_code: "vessel_code",
  sale_exchangerate: "sale_exchangerate",
  target_market: "target_market",
  portfinal_code: "portfinal_code",
  portdistcharge_code: "portdistcharge_code",
  loadout_voyageNumber: "loadout_voyageNumber",
  dispatch_containerno: "dispatch_containerno",
  dispatch_sealnumber: "dispatch_sealnumber",
  shippingCode: "shippingCode",
  portloading_code: "portloading_code",
};

export const makeTextFile = (text) => {
  var data = new Blob([text], { type: "text/plain" });

  return window.URL.createObjectURL(data);
};

const ignorableHeaders = {
  actions: "actions",
  groupnumberfull: "groupnumberfull",
};

export const PrettyDate = (dateString) => {
  if (isNaN(Date.parse(dateString))) return dateString;

  let val = new Date(dateString);
  var dd = val.getDate();
  var MMM = val.toLocaleString("en-za", { month: "short" }); //January is 0!
  var yyyy = val.getFullYear();
  var x = dd < 10 ? `0${dd}` : `${dd}`;
  x += "-";
  x += `${MMM}`;
  x += "-";
  x += yyyy;
  return x;
};

const replacer = function (key, value) {
  return value || "";
};

const checkboxEditFields = [
  "loadout_spec",
  "loadout_loadingdocs",
  "sale_arrived",
  "sale_collected",
  "sale_clientadvpd",
  "sale_freightpd",
  "sale_copydox",
  "sale_orgnldox",
  "sale_clientfinpd",
  "sale_telex",
  "sale_accsalereceived",
];

export const ExportDataCSV = async (pageTitle, json, gridColumns) => {
  const fields = gridColumns.filter((o) => {
    if (!Object.keys(ignorableHeaders).includes(o.key)) {
      return o;
    }
  });

  if (!json || json.length == 0) {
    return false;
  }

  let csv = json.map((row) =>
    fields
      .map((field) => {
        const fieldName = field.key;
        const formatter = field.formatter;
        let value = row[fieldName];

        if (formatter) {
          if (formatter == DateFormatter) {
            if (value != null && value != "") {
              value = format(parseISO(value), "dd-MM-yyyy");
            }
          } else if (formatter == FormatterNumber) {
            value = numeral(value).format("0,0");
          } else if ([FormatterNumber2Decimals, FormatterNumber2DecimalsNegative, FormatterNumber3Decimals].includes(formatter)) {
            // all numbers should have a decimal value for the formatter to work correctly
            // if a number has a decimal already, ignore it (keep original decimals), otherwise add two decimals after the number
            if (value - parseInt(value) == 0) {
              value = parseFloat(value).toFixed(2);
            } else {
              value = numeral(value).format("0,0.000");
            }
          }
        }

        if (checkboxEditFields.includes(fieldName)) {
          value = value == 1 ? "Y" : "N";
        }

        return JSON.stringify(value, replacer);
      })
      .join("|"),
  );
  csv.unshift(fields.map((f) => f.name).join("|")); // add header column
  await ForcedDownload(csv, pageTitle.toUpperCase());
};

export const DownloadPackInstruction = async (json) => {
  var csv = json.map(function (row) {
    return Object.keys(OrderHeader)
      .map(function (fieldName) {
        if (fieldName.toString() == "exchange") {
          const exchangeValue = JSON.parse(row[fieldName], replacer);
          const exchangeReturnV =
            "{ usd: " + exchangeValue["usd"] + " , cad: " + exchangeValue["cad"] + " , eur: " + exchangeValue["eur"] + " , gbp: " + exchangeValue["gbp"] + "}";
          return exchangeReturnV;
        } else if (fieldName == "printdate") {
          return !row[fieldName] ? JSON.stringify(row[fieldName], replacer) : JSON.stringify(row[fieldName].substring(0, 10), replacer);
        } else if (["fob", "dip", "dipmin", "dipmax"].includes(fieldName)) {
          const output = numeral(row[fieldName]).format("0,0.00");
          return JSON.stringify(output, replacer);
        } else if (fieldName == "Palletspec_Code") {
          const output = numeral(row[fieldName]).format("0,0.00");
          return output;
        } else {
          return JSON.stringify(row[fieldName], replacer);
        }
      })
      .join("|");
  });

  csv.unshift(Object.values(OrderHeader).join("|")); // add header column
  await ForcedDownload(csv, `${format(new Date(), "yyMMdd")}_Packing_Instructions_Data`);
};

export const DownloadCSV = (data, filename, columnHeaders, includeImage = false) => {
  const fields = columnHeaders.map((col) => col.name);

  const csv = data.map((row) => {
    return columnHeaders
      .map((col) => {
        let value = row[col.key];

        if (col.formatter && value !== null) {
          const formatter = col.formatter;

          if (formatter == DateFormatter) {
            value = format(parseISO(value), "dd-MM-yyyy");
          } else if (formatter == FormatterNumber2Decimals) {
            value = numeral(value).format("0,0.00");
          } else if (formatter == FormatterNumber) {
            value = numeral(value).format("0,0");
          } else if (formatter == FormatterNumber3Decimals) {
            value = numeral(value).format("0,0.000");
          }
        }

        return JSON.stringify(value, replacer).replace(",", "");
      })
      .join("|");
  });
  csv.unshift(fields.join("|")); // add header column
  ForcedDownload(csv, filename, includeImage);
};

const ForcedDownload = async (csv, filename, includeImage = false) => {
  const compressed = LZString.compress(JSON.stringify(csv));
  const content = JSON.stringify({ csv: compressed });
  const result = await request(`export/${filename}?includeImage=${includeImage}`, {
    method: "POST",
    headers: apiHeaders(content),
    body: content,
  });
  const blob = await result.blob();
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = url;
  a.download = filename;
  a.click();
  // cleanup
  window.URL.revokeObjectURL(url);
  a.remove();
};

export const detailedSeasonReportByInvoiceNumber = async (invoicenumbers, includeProducer, year) => {
  const content = JSON.stringify({ invoicenumbers, includeProducer, year });
  return request(`consolidation/ext/printDetailSummaryReport`, {
    method: "POST",
    headers: apiHeaders(content),
    body: content,
  }).then((result) => {
    result.blob().then((blob) => {
      let url = window.URL.createObjectURL(blob);
      let a = document.createElement("a");
      a.href = url;
      a.download = `${format(new Date(), "yyMMdd")} DETAILED SEASON REPORT${includeProducer ? " PRODUCER" : ""}.xlsx`;
      a.click();
    });
  });
};

export const producerFinalRemittanceExcel = async (selectedFinals: any[], detailData: ConsolidationFinalDetailType[]) => {
  const { producerfinalpaidhead_id, producerid } = detailData[0];
  const producerInfo = await getProducerByProducerCode(producerid);
  const companyDetailsResult = await companyDetails.all();
  const adhocPayments = await adhocdetailByPaidHeadId(producerfinalpaidhead_id);

  const header = producerInfo.data[0];

  const content = JSON.stringify({
    header: header,
    detail: detailData,
    selected: selectedFinals,
    companyDetails: companyDetailsResult[0],
    adhocPayments,
  });

  const { makeanote, idents, payment_date } = selectedFinals.find((item) => item.idents);

  return request(`/consolidation/ext/printFinalRemittanceExcel`, {
    method: "POST",
    headers: apiHeaders(content),
    body: content,
  }).then((result) => {
    result.blob().then((blob) => {
      let url = window.URL.createObjectURL(blob);
      let a = document.createElement("a");
      a.href = url;
      a.download = `${format(new Date(payment_date), "yyMMdd")} ${header.name || ""} ${idents.toString() || ""} ${(makeanote || "").trim()}.xlsx`;
      a.click();
    });
  });
};

export const exportSelectedConsol = async (invoicenumbers: string[]) => {
  const content = JSON.stringify({ invoicenumbers });
  return request(`consolidation/ext/downloadConsolDetails`, {
    method: "POST",
    headers: apiHeaders(content),
    body: content,
  }).then((result) => {
    result.blob().then((blob) => {
      let url = window.URL.createObjectURL(blob);
      let a = document.createElement("a");
      a.href = url;
      a.download = `Consolidation${format(new Date(), "yyMMdd")}.xlsx`;
      a.click();
    });
  });
};

export const exportAdhocInvoice = async (headerDetail, selectedDetail) => {
  const content = JSON.stringify({ headerDetail, selectedDetail });
  return request(`adhocinvoice/ext/downloadAdhocInvoice`, {
    method: "POST",
    headers: apiHeaders(content),
    body: content,
  }).then((result) => {
    result.blob().then((blob) => {
      let url = window.URL.createObjectURL(blob);
      let a = document.createElement("a");
      a.href = url;
      a.download = `${headerDetail.invoice_number}_ADHOC_CLIENT_INVOICE.xlsx`;
      a.click();
    });
  });
};
