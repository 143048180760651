import React, { useEffect, useState, forwardRef } from "react";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";

import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Tooltip from "@material-ui/core/Tooltip";

import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import MTextField from "@material-ui/core/TextField";

import ReactDatePicker, { registerLocale } from "react-datepicker";
import { format } from "date-fns";
import en from "date-fns/esm/locale/en-GB";
registerLocale("en-GB", en);
import { Field } from "react-final-form";
import { TextField, Select } from "final-form-material-ui";

import numeral from "numeral";

import parseISO from "date-fns/parseISO";
import toDate from "date-fns/toDate";
import isValid from "date-fns/isValid";

import { SaleDispatchJoinedType } from "../lib/api/sale";
import { clientsByCode, getClientsAllSortedMappedforComboCode } from "../lib/api/clients";
import { getCurrencyAllSortedMappedforCombo } from "../lib/api/currency";
import { getTermsAllSortedMappedforCombo } from "../lib/api/terms";
import { getDealTypeReadyForCombo } from "../lib/api/dealtype";
import { getSellingTermsReadyForCombo } from "../lib/api/sellingterms";
import { getContainerTypesReadyForCombo } from "../lib/api/containertypes";
import { getUsersAllSortedMappedforCombo } from "../lib/api/users";
import { getAgentsReadyForCombo } from "../lib/api/agent";
import { getVesselReadyForCombo } from "../lib/api/vessel";
import { getPortReadyForCombo } from "../lib/api/port";
import { getConsigneeReadyForCombo } from "../lib/api/consignee";
import { getClearingAgentReadyForCombo } from "../lib/api/clearingagent";
import { isNullOrUndef } from "../lib/helpers/isNullOrUndef";
import { GetCurrentExchangeRate, forDate } from "../lib/api/exchangerates";
import { ucrNumberGenerator } from "../lib/helpers/ucrnumbergenerator";
import { clientsfinanceByClientID } from "../lib/api/clientsfinance";
import { getPurchaseTermsReadyForCombo } from "../lib/api/purchaseterms";
import classNames from "classnames";

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}
function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <Typography role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1),
    },
    container: {
      display: "flex",
      flexDirection: "column",
      maxHeight: "250px",
      flexWrap: "wrap",
      gapX: "1rem",
    },
    field: {
      display: "grid",
      gridTemplateColumns: "130px 300px",
      maxWidth: "450px",
      gap: "1rem",
      height: "50px",
      alignItems: "center",
      "& label": {
        textAlign: "right",
      },
      "& .MuiInputBase-formControl": {
        marginTop: "0",
      },
    },
    fieldSub: {
      display: "grid",
      gridTemplateColumns: "100px 100px",
      gap: "1rem",
      alignItems: "center",
    },
    fieldArea: {
      display: "grid",
      gridTemplateColumns: "1fr",
      gridTemplateRows: "1fr 1fr",
      maxWidth: "300px",
      height: "130px",
      gap: "0",
      "& label": {
        height: "50px",
        textAlign: "left",
        alignItems: "center",
      },
    },
  });

type InvoiceAcceptFormProps = { values: any; form: any } & WithStyles<typeof styles>;

const InvoiceAcceptFormUnstyled: React.FunctionComponent<InvoiceAcceptFormProps> = (props) => {
  const { classes, values, form } = props;

  const valuesTyped: SaleDispatchJoinedType = { ...values };
  const [clients, setClients] = useState([]);
  const [currencies, setCurrencies] = useState([]);
  const [terms, setTerms] = useState();
  const [dealtypes, setDealtypes] = useState([]);
  const [sellingterms, setSellingterms] = useState([]);
  const [containerTypes, setContainerTypes] = useState([]);
  const [usersData, setUsersData] = useState([]);

  const [agents, setAgents] = useState([]);
  const [vessels, setVessels] = useState([]);
  const [ports, setPorts] = useState([]);

  const [dateInvoice, setDateInvoice] = useState(
    isValid(parseISO(values.sale_date)) ? parseISO(values.sale_date) : isValid(parseISO(values.dispatch_invoicedate)) ? parseISO(values.dispatch_invoicedate) : new Date(),
  );
  const [dateLoad, setDateLoad] = useState(!isNullOrUndef(valuesTyped.dispatch_loaddate) ? parseISO(values.dispatch_loaddate) : new Date());
  const [dateETA, setDateETA] = useState(!isNullOrUndef(valuesTyped.loadout_eta) ? parseISO(values.loadout_eta) : new Date());
  const [dateETD, setDateETD] = useState(!isNullOrUndef(valuesTyped.loadout_etd) ? parseISO(values.loadout_etd) : new Date());
  const [dateUpdatedETA, setDateUpdatedETA] = useState(!isNullOrUndef(valuesTyped.loadout_updated_eta) ? parseISO(values.loadout_updated_eta) : null);
  const [datePayment1, setDatePayment1] = useState(!isNullOrUndef(valuesTyped.sale_payment1date) ? toDate(values.sale_payment1date) : new Date());
  const [datePayment2, setDatePayment2] = useState(!isNullOrUndef(valuesTyped.sale_payment2date) ? toDate(values.sale_payment2date) : new Date());
  const [datePayment3, setDatePayment3] = useState(!isNullOrUndef(valuesTyped.sale_payment3date) ? toDate(values.sale_payment3date) : new Date());

  const [exchanges, setExchanges] = useState({});
  const [pushRateValue, setPushRateValue] = useState(null);
  const [consignees, setConsignees] = useState([]);
  const [clearingAgents, setClearingAgents] = useState([]);
  const [firstLoad, setFirstLoad] = useState(true);
  const [purchaseTerms, setPurchaseTerms] = useState([]);

  useEffect(() => {
    setFirstLoad(false);
  }, []);

  const [value, setValue] = useState(0);

  const changeDateInvoice = (value) => {
    setDateInvoice(value);
    form.change("sale_date", toDate(value));
    updateExchangePushDataRates(undefined, true);
  };

  const changeDateDispatchLoadDate = (value) => {
    setDateLoad(value);
    form.change("dispatch_loaddate", toDate(value));
  };

  const changeDateETA = (value) => {
    setDateETA(value);
    form.change("loadout_eta", toDate(value));
  };

  const changeDateETD = (value) => {
    setDateETD(value);
    form.change("loadout_etd", toDate(value));
  };

  const changeDateUpdatedETA = (value) => {
    setDateUpdatedETA(value);
    form.change("loadout_updated_eta", value ? toDate(value) : null);
  };

  const changeDatePayment1 = (value) => {
    setDatePayment1(value);
    form.change("sale_payment1date", toDate(value));
  };

  const changeDatePayment2 = (value) => {
    setDatePayment2(value);
    form.change("sale_payment2date", toDate(value));
  };

  const changeDatePayment3 = (value) => {
    setDatePayment3(value);
    form.change("sale_payment3date", toDate(value));
  };

  const setExchangePushDataRates = (currs = undefined, XCRates, updateExchangeRequested = false) => {
    const curr = currs ? currs : currencies;
    const rates = XCRates ? XCRates : exchanges["rates"];
    setPushRateValue(0);
    const rate = curr.find((currency) => form.getState().values.dispatch_currency_id == currency.value);
    if (rate) {
      const val = numeral(rates[rate.display]).format("0.000").toString();
      setPushRateValue(val);
      if (updateExchangeRequested || !form.getState().values.sale_exchangerate) {
        form.change("sale_exchangerate", val);
      }
    }
  };

  const changeExchangeRateValue = (value = undefined, exchange = undefined) => {
    const currencyId = value ? value : form.getState().values.dispatch_currency_id;
    const rate = currencies.find((currency) => currencyId == currency.value);
    if (rate) {
      const rateValue = numeral(exchanges["rates"][rate.display]).format("0.000").toString();
      const final = exchange ? exchange : rateValue;
      setPushRateValue(final);
      form.change("sale_exchangerate", final);
    }
  };

  const updateExchangePushDataRates = (currencyList = undefined, updateExchange = false) => {
    const currs = currencyList ? currencyList : currencies;
    const date = format(new Date(form.getState().values.dispatch_loaddate), "yyyy-MM-dd");
    forDate(date).then((data) => {
      if (Object.keys(data.rates).length == 0) {
        GetCurrentExchangeRate().then((data) => {
          setExchanges(data);
          setExchangePushDataRates(currs, data.rates, updateExchange);
        });
      } else {
        setExchanges({ rates: data.rates[Object.keys(data.rates)[0]] });
        setExchangePushDataRates(currs, data.rates[Object.keys(data.rates)[0]], updateExchange);
      }
    });
  };

  const loadData = async () => {
    const [clientsResult, currenciesResult, termsResult, usersData, agentsResult, vesselResult, portResult] = await Promise.all([
      getClientsAllSortedMappedforComboCode(),
      getCurrencyAllSortedMappedforCombo(),
      getTermsAllSortedMappedforCombo(0),
      getUsersAllSortedMappedforCombo(true),
      getAgentsReadyForCombo(),
      getVesselReadyForCombo(),
      getPortReadyForCombo(),
    ]);

    if (!form.getState().values.dispatch_loaddate) {
      GetCurrentExchangeRate().then((latestExchanges) => {
        setExchanges(latestExchanges);
        setExchangePushDataRates(currenciesResult, latestExchanges["rates"]);
      });
    } else {
      updateExchangePushDataRates(currenciesResult);
    }

    setClients(clientsResult);
    setCurrencies(currenciesResult);
    setTerms(termsResult);
    setUsersData(usersData);
    setAgents(agentsResult);
    setVessels(vesselResult);
    setPorts(portResult);
    setDealtypes(getDealTypeReadyForCombo());
    setSellingterms(getSellingTermsReadyForCombo());
    setContainerTypes(getContainerTypesReadyForCombo());
    setPurchaseTerms(getPurchaseTermsReadyForCombo());
    setDefaultValues();
  };

  useEffect(() => {
    loadData();
  }, [values.dispatch_id]);

  const setDefaultValues = () => {
    const consignee = form.getState().values.loadout_consignee;
    getConsigneeReadyForCombo(0, consignee).then((consigneeList) => {
      setConsignees(consigneeList);
      if (!values.dispatch_consignee_id) {
        if (consigneeList.length > 0) {
          form.change("dispatch_consignee_id", consigneeList[0].data.id);
        } else {
          form.change("dispatch_consignee_id", null);
        }
      }
    });
    if (!form.getState().values.dispatch_dealtype || !form.getState().values.dispatch_sellingterm) {
      clientsByCode(consignee).then((resultClientsByCode) => {
        if (resultClientsByCode && resultClientsByCode.data && resultClientsByCode.data.length > 0) {
          clientsfinanceByClientID(resultClientsByCode.data[0].id).then((resultClientsFin) => {
            if (resultClientsFin && resultClientsFin.data && resultClientsFin.data.length > 0) {
              form.change("dispatch_dealtype", resultClientsFin.data[0].dealtype);
              form.change("dispatch_sellingterm", resultClientsFin.data[0].sellingterms);
            }
          });
        }
      });
    }
  };

  const loadDefaultClientData = async () => {
    let clientId = undefined,
      currencyId = undefined,
      dealType = undefined,
      sellingTerms = undefined,
      termsId = undefined;

    await clientsByCode(form.getState().values.loadout_consignee).then((result) => {
      if (result.data && result.data.length > 0) {
        clientId = result.data[0].id;
      }
    });

    await clientsfinanceByClientID(clientId).then((result) => {
      if (result.data && result.data.length > 0) {
        currencyId = result.data[0].currency_id;
        dealType = result.data[0].dealtype;
        sellingTerms = result.data[0].sellingterms;
        termsId = result.data[0].terms_id;
      }
    });

    form.change("sale_terms_id", termsId);
    form.change("dispatch_currency_id", currencyId);
    form.change("dispatch_dealtype", dealType);
    form.change("dispatch_sellingterm", sellingTerms);

    await getConsigneeReadyForCombo(0, form.getState().values.loadout_consignee).then((result) => {
      if (!values.dispatch_consignee_id) {
        if (result.length > 0) {
          form.change("dispatch_consignee_id", result[0].value);
        } else {
          form.change("dispatch_consignee_id", undefined);
        }
      }
      setConsignees(result);
    });
  };

  useEffect(() => {
    if (!firstLoad) {
      loadDefaultClientData();
    }
  }, [form.getState().values.loadout_consignee]);

  useEffect(() => {
    getClearingAgentReadyForCombo(form.getState().values.dispatch_consignee_id).then((result) => {
      if (result.length == 0) {
        form.change("sale_notifyparty_id", undefined);
        form.change("dispatch_clearingagent_id", undefined);
      } else if (!firstLoad && result.length > 0) {
        form.change("sale_notifyparty_id", result[0].value);
        form.change("dispatch_clearingagent_id", result[0].value);
      }
      setClearingAgents(result);
    });
  }, [form.getState().values.dispatch_consignee_id]);

  useEffect(() => {
    ucrNumberGenerator("INV", form.getState().values.sale_invoicenumber).then((generated) => {
      form.change("sale_ucr", generated);
    });
  }, [form.getState().values.sale_invoicenumber]);

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Tabs value={value} onChange={handleTabChange} aria-label="Invoice Header">
          <Tab label="Invoice Details" {...a11yProps(0)} />
          <Tab label="Shipment Details" {...a11yProps(1)} />
          {form.getState().values.sale_terms_id == -1 && <Tab label="Custom Payment Terms" {...a11yProps(2)} />}
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <div className={classes.container}>
          <TableFieldDate classes={classes} dateValue={dateInvoice} title="Invoice Date" changeDate={changeDateInvoice} isClearable={false} />
          <TableFieldText classes={classes} field="sale_invoicenumber" title="Invoice Number" disabled={false} />
          <TableFieldText classes={classes} field="sale_ucr" title="UCR" disabled={false} />
          <TableFieldCombo classes={classes} field="sale_trader_id" title="Trader" data={usersData} addEmptyValue={undefined} />
          <TableFieldCombo classes={classes} field="sale_purchaseterm_id" title="Purchase Terms" data={purchaseTerms} addEmptyValue={undefined} />
          <TableFieldCombo classes={classes} field="loadout_consignee" title="Client" data={clients} addEmptyValue={undefined} />
          <TableFieldCombo classes={classes} field="dispatch_consignee_id" title="Consignee" data={consignees} addEmptyValue={undefined} />
          <TableFieldCombo classes={classes} field="sale_notifyparty_id" title="Notify Party" data={clearingAgents} addEmptyValue={undefined} />
          <TableFieldCombo classes={classes} field="dispatch_clearingagent_id" title="Clearing Agent" data={clearingAgents} addEmptyValue={undefined} />
          <TableFieldText classes={classes} field="client_po" title="Client PO" disabled={false} />
          <TableFieldCombo classes={classes} field="sale_terms_id" title="Payment Terms" data={terms} addEmptyValue={"CUSTOM"} />
          <TableFieldCombo classes={classes} field="dispatch_dealtype" title="Deal Type" data={dealtypes} addEmptyValue={undefined} />
          <TableFieldCombo classes={classes} field="dispatch_sellingterm" title="Selling Term" data={sellingterms} addEmptyValue={undefined} />
          <CurrencyComboTableField
            classes={classes}
            field="dispatch_currency_id"
            title="Currency"
            data={currencies}
            addEmptyValue={undefined}
            pushExchangeRate={changeExchangeRateValue}
            pushRateValue={pushRateValue}
          />
          <TableFieldTextArea classes={classes} field="dispatch_remarks" title="Remarks" disabled={false} multiple={6} />
        </div>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <div className={classes.container}>
          <TableFieldCombo classes={classes} field="agent_id" title="Forwarding Agent" data={agents} addEmptyValue={undefined} />
          <TableFieldText classes={classes} field="loadout_exportNotificationReference" title="Export Notification" disabled={false} />
          <TableFieldCombo classes={classes} field="portloading_id" title="POL" data={ports} addEmptyValue={undefined} />
          <TableFieldDate classes={classes} dateValue={dateLoad} title="LOAD" changeDate={changeDateDispatchLoadDate} isClearable={false} />
          <TableFieldText classes={classes} field="dispatch_forwardagentref" title="Forward Agent Ref" disabled={false} />
          <TableFieldText classes={classes} field="loadout_voyageNumber" title="Voyage Number" disabled={false} />
          <TableFieldCombo classes={classes} field="portdistcharge_id" title="POD" data={ports} addEmptyValue={undefined} />
          <TableFieldDate classes={classes} dateValue={dateETD} title="ETD" changeDate={changeDateETD} isClearable={false} />
          <TableFieldText classes={classes} field="dispatch_containerno" title="Container No" disabled={false} />
          <TableFieldCombo classes={classes} field="vessel_id" title="Vessel" data={vessels} addEmptyValue={undefined} />
          <TableFieldCombo classes={classes} field="portfinal_id" title="Final Destination" data={ports} addEmptyValue={undefined} />
          <TableFieldDate classes={classes} dateValue={dateETA} title="ETA" changeDate={changeDateETA} isClearable={false} />
          <TableFieldCombo classes={classes} field="dispatch_containertype" title="Container Type" data={containerTypes} addEmptyValue={undefined} />
          <TableFieldText classes={classes} field="dispatch_sealnumber" title="Seal No" disabled={false} />
          <TableFieldDate classes={classes} dateValue={dateUpdatedETA} title="Updated ETA" changeDate={changeDateUpdatedETA} isClearable={false} />
        </div>
      </TabPanel>
      <TabPanel value={value} index={2}>
        <div className={classes.container}>
          <TableFieldText classes={classes} field="sale_payment1amount" title="Payment 1 Amount" disabled={false} />
          <TableFieldText classes={classes} field="sale_payment2amount" title="Payment 2 Amount" disabled={false} />
          <TableFieldText classes={classes} field="sale_payment3amount" title="Payment 3 Amount" disabled={false} />
          <TableFieldDate classes={classes} dateValue={datePayment1} title="Payment 1 Date" changeDate={changeDatePayment1} isClearable={false} />
          <TableFieldDate classes={classes} dateValue={datePayment2} title="Payment 2 Date" changeDate={changeDatePayment2} isClearable={false} />
          <TableFieldDate classes={classes} dateValue={datePayment3} title="Payment 3 Date" changeDate={changeDatePayment3} isClearable={false} />
        </div>
      </TabPanel>
    </div>
  );
};

export default withStyles(styles)(InvoiceAcceptFormUnstyled);

const TableFieldText: React.FunctionComponent<{ field: string; title: string; disabled: boolean } & WithStyles<typeof styles>> = (props) => {
  const { classes, field, title, disabled } = props;
  return (
    <div className={classes.field}>
      <label>{title}:</label>
      <div>
        <Field name={field} component={TextField} type="text" fullWidth={true} disabled={disabled} />
      </div>
    </div>
  );
};

const TableFieldTextArea: React.FunctionComponent<{ field: string; title: string; disabled: boolean; multiple: number } & WithStyles<typeof styles>> = (props) => {
  const { classes, field, title, disabled, multiple } = props;
  return (
    <div className={classNames(classes.field, classes.fieldArea)}>
      <label>{title}:</label>
      <Field fullWidth name={field} disabled={disabled}>
        {(props) => (
          <MTextField
            fullWidth
            multiline
            rows={multiple}
            variant="standard"
            name={props.input.name}
            value={props.input.value}
            onChange={props.input.onChange}
            style={{ backgroundColor: "white", padding: "4px", width: "400px" }}
          />
        )}
      </Field>
    </div>
  );
};

const CalenderCustomInput = forwardRef((props: any, ref: any) => {
  return (
    <Button name="CalenderCustomInput" variant="contained" color="primary" onClick={props.onClick} style={{ marginTop: "5px", width: "250px", minHeight: "36px" }}>
      {props.value}
    </Button>
  );
});

const TableFieldDate: React.FunctionComponent<{ dateValue: Date; title: string; isClearable: boolean; changeDate: (value) => void } & WithStyles<typeof styles>> = (props) => {
  const { classes, dateValue, title, isClearable, changeDate } = props;
  return (
    <div className={classes.field}>
      <label>{title}:</label>
      <ReactDatePicker
        locale="en-GB"
        showWeekNumbers
        dateFormat="dd-MM-yyyy"
        isClearable={isClearable}
        customInput={<CalenderCustomInput />}
        onChange={(value) => changeDate(value)}
        placeholderText="click here to select a date"
        selected={isNullOrUndef(dateValue) ? null : toDate(dateValue)}
      />
    </div>
  );
};

const TableFieldCombo: React.FunctionComponent<{ classes: any; title: string; field: string; data: any; addEmptyValue: string; disabled?: boolean } & WithStyles<typeof styles>> = (
  props,
) => {
  const { classes, title, field, data, addEmptyValue, disabled = false } = props;
  return (
    <div className={classes.field}>
      <label>{title}:</label>
      <Field name={field} disabled={disabled} component={Select as any} formControlProps={{ className: classes.tableCellsValueSelect }}>
        {addEmptyValue && (
          <MenuItem key={`emptyvalueMenuItem`} value={-1}>
            {addEmptyValue}
          </MenuItem>
        )}
        {(data || []).map((item, index) => (
          <MenuItem key={`${item.value}${index}`} value={item.value}>
            {item.display}
          </MenuItem>
        ))}
      </Field>
    </div>
  );
};

const CurrencyComboTableField: React.FunctionComponent<
  { classes: any; title: string; field: string; data: any; addEmptyValue: string; pushExchangeRate: any; pushRateValue: number } & WithStyles<typeof styles>
> = (props) => {
  const { classes, title, field, data, addEmptyValue, pushExchangeRate, pushRateValue } = props;
  return (
    <div className={classes.field}>
      <label>{title}:</label>
      <div className={classes.fieldSub}>
        <Field
          name={field}
          component={Select as any}
          parse={(value) => {
            pushExchangeRate(value);
            return value;
          }}
        >
          {addEmptyValue && (
            <MenuItem key={`emptyvalueMenuItem`} value={-1}>
              {addEmptyValue}
            </MenuItem>
          )}
          {(data || []).map((item, index) => (
            <MenuItem key={`${item.value}${index}`} value={item.value}>
              {item.display}
            </MenuItem>
          ))}
        </Field>
        <Tooltip placement="top" title={`${pushRateValue}`}>
          <span>
            <Button style={{ marginRight: "7.5px", width: "30px" }} onClick={() => pushExchangeRate(undefined, pushRateValue)}>
              <Field name={"sale_exchangerate"} component={TextField} type="text" fullWidth={true} style={{ width: "60px", marginTop: "2px" }} />
            </Button>
          </span>
        </Tooltip>
      </div>
    </div>
  );
};
