import { config } from "../../config";
import { client, apiHeaders, request } from "./_base";

const endpoint = "producersaledetail";
export type ProducerSaleDetailType = {
  id: number;
  producersale_id: number;
  barcode: string;
  barcode_id: number;
  commodityCode: string;
  varietyCode: string;
  gradeCode: string;
  countCode: string;
  packCode: string;
  markCode: string;
  sellingprice: number;
  producerprice: number;
  created_at?: Date;
  updated_at?: Date;
};
export const producersaledetail = client(endpoint, (item) => ({
  ...item,
}));

export const producersaleDetailInsertByBarcode = async (item: ProducerSaleDetailType) => {
  const content = JSON.stringify(item);
  return await request(`${endpoint}/insertByBarcode`, {
    method: "POST",
    headers: apiHeaders(content),
    body: content,
  })
    .then(async (result) => {
      if (result.status == 500) {
        const resp = await result.json();
        throw resp;
      }
      return result.json();
    })
    .then(({ data }) => {});
};

export type ProducerSaleDetailBySaleIDType = {
  barcode_producerid: number;
  prod_name: string;
  barcode: string;
  barcode_palletsize: number;
  barcode_nocartons: number;
  barcode_commoditycode: string;
  barcode_varietycode: string;
  barcode_packcode: string;
  barcode_markcode: string;
  barcode_gradecode: string;
  barcode_countcode: string;
  barcode_id: number;
  currency: string;
  barcode_inventorycode: string;
  stockdetail_sellingprice: number;
  stockdetail_producerprice: number;
  total: number;
  sale_type: number;
};

export const getProducerSaleDetailBySaleId = async (saleid: number) =>
  await request(`${endpoint}/ext/getBySaleID/${saleid}`, {
    headers: apiHeaders(),
  })
    .then(async (result) => {
      if (result.status == 500) {
        const resp = await result.json();
        throw resp;
      }
      return result.json();
    })
    .then(({ data }) => {
      return data;
    });
