import React, { useEffect } from "react";
// import classnames from 'classnames';
import { withStyles, createStyles, WithStyles, Theme, makeStyles } from "@material-ui/core/styles";

import { Route, Switch } from "react-router-dom";

import Typography from "@material-ui/core/Typography";

import { getOrderDetailById } from "../lib/api/piorder";
import { getProducerByProducerCode } from "../lib/api/producer";
import { GenerateErrorMessage } from "../lib/helpers/string_methods";
import { SnackContext } from "../lib/context/SnackContext";
import { getClientNameByCode } from "../lib/api/clients";

const styles = (theme: Theme) =>
  createStyles({
    root: {},
    titleText: {
      fontWeight: "bold",
    },
  });

export type TitleProps = {} & WithStyles<typeof styles>;

type Props = {
  subtitle?: string;
};

const TitleText: React.FunctionComponent<Props> = ({ children, subtitle }) => {
  const useStyles = makeStyles({
    container: {},
    textContainer: {
      display: "flex",
      flexDirection: "row",
      maxWidth: "38vw",
      gap: "20px",
    },
    titleText: {
      maxWidth: "100%",
      whiteSpace: "nowrap",
      overflow: "visible",
      fontWeight: "bold",
    },
    subTitle: {
      maxWidth: "100%",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      fontWeight: "bold",
    },
    agileTitleText: {
      fontWeight: "bold",
      fontFamily: "Bicubik",
      fontSize: 50,
      color: "#96CBED",
    },
    centerTextContainer: {
      zIndex: -10,
      position: "absolute",
      top: "1px",
      left: "1px",
      width: "100%",
      textAlign: "center",
    },
  });

  const classes = useStyles();

  return (
    <div>
      <div className={classes.centerTextContainer}>
        <Typography className={classes.agileTitleText} variant="h5">
          AGILE
        </Typography>
      </div>
      <div className={classes.textContainer}>
        <Typography className={classes.titleText} variant="h5">
          {children}
        </Typography>
        <Typography className={classes.subTitle} variant="h5">
          {subtitle}
        </Typography>
      </div>
    </div>
  );
};

const UCFirstChar = (s: string) => {
  return s
    .toLowerCase()
    .split(" ")
    .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
    .join(" ");
};

const TitleUnstlyed: React.FunctionComponent<TitleProps> = ({ classes, children }) => (
  <div className={classes.root}>
    <Switch>
      <Route exact path="/adhocinvoice/:id" render={({ match }) => <TitleText>EDIT ADHOC INVOICE</TitleText>} />
      <Route exact path="/adhocinvoice" render={({ match }) => <TitleText>ADHOC INVOICE</TitleText>} />
      <Route exact path="/creditors" render={({ match }) => <TitleText>CREDITORS</TitleText>} />
      <Route exact path="/creditors/invoice/:any?" render={({ match }) => <TitleText>CREDITOR INVOICES</TitleText>} />
      <Route exact path="/creditors/info/:any?" render={({ match }) => <TitleText>CREDITOR INVOICE</TitleText>} />
      <Route exact path="/creditors/summary" render={({ match }) => <TitleText>CREDITOR SUMMARY</TitleText>} />
      <Route exact path="/dashboard" render={({ match }) => <TitleText>DASHBOARD</TitleText>} />
      <Route exact path="/finance/finance" render={({ match }) => <TitleText>FINANCIAL OVERVIEW</TitleText>} />
      <Route exact path="/finance/consolidation" render={({ match }) => <TitleText>FINANCIAL CONSOLIDATION</TitleText>} />
      <Route path="/dispatch" render={({ match }) => <TitleText>DISPATCHES</TitleText>} />
      <Route path="/debtors/summary" render={({ match }) => <TitleText>DEBTORS SUMMARY</TitleText>} />
      <Route path="/debtors/full" render={({ match }) => <TitleText>DEBIT & CREDIT NOTES</TitleText>} />
      <Route path="/debtors/fundssummary" render={({ match }) => <TitleText>DEBTORS INCOMING FUNDS</TitleText>} />
      <Route path="/debtors/funds/:code" component={DebtorsFundsDetail} />
      <Route path="/instruction/:id/edit" component={InstructionDetail} />
      <Route path="/instruction/create" render={() => <TitleText>INSTRUCTION DETAIL</TitleText>} />
      <Route path="/debtors/adhoc/:id" render={() => <TitleText>DEBTORS ADHOC</TitleText>} />
      <Route path="/instructiontabbed" render={({ match }) => <TitleText>INSTRUCTION GROUPED</TitleText>} />
      <Route path="/orders" render={({ match }) => <TitleText>PACK INSTRUCTIONS</TitleText>} />
      <Route path="/stockallocation" render={({ match }) => <TitleText>STOCK</TitleText>} />
      <Route path="/stockpricing" render={({ match }) => <TitleText>STOCK PRICING</TitleText>} />
      <Route path="/stockcomparison" render={({ match }) => <TitleText>STOCK COMPARISON</TitleText>} />
      <Route path="/users" render={({ match }) => <TitleText>USER MANAGEMENT</TitleText>} />
      <Route path="/shipment" render={({ match }) => <TitleText>SHIPMENTS</TitleText>} />
      <Route path="/invoice" render={({ match }) => <TitleText>COMMERCIAL INVOICES</TitleText>} />
      <Route path="/invoicing" render={({ match }) => <TitleText>PACK INSTRUCTION PRICE INTEGRATION</TitleText>} />
      <Route path="/invoiceProForma" render={({ match }) => <TitleText>PROFORMA INVOICE</TitleText>} />
      <Route path="/inspection" render={({ match }) => <TitleText>QC INSPECTIONS</TitleText>} />
      <Route path="/arrival" render={({ match }) => <TitleText>ARRIVALS</TitleText>} />
      <Route path="/edi/data" render={({ match }) => <TitleText>EDI DATA</TitleText>} />
      <Route path="/edi/audits" render={({ match }) => <TitleText>EDI AUDITS</TitleText>} />
      <Route path="/edi/fileImports" render={({ match }) => <TitleText>EDI IMPORTS</TitleText>} />
      <Route path="/edi/template" render={({ match }) => <TitleText>EDI TEMPLATE</TitleText>} />
      <Route path="/edi/search" render={({ match }) => <TitleText>EDI SEARCH</TitleText>} />
      {/* <Route path="/producers/full" render={({ match }) => <TitleText>PRODUCERS</TitleText>} /> */}
      <Route path="/producers/invoice/summary" render={({ match }) => <TitleText>FIXED PRODUCER INVOICES SUMMARY</TitleText>} />
      <Route path="/producers/invoice" render={({ match }) => <TitleText>FIXED PRODUCER INVOICES</TitleText>} />
      <Route path="/producers/groupedonly" render={({ match }) => <TitleText>PRODUCERS PAYMENTS</TitleText>} />
      <Route path="/producers/grouped" component={ProducerPaymentsGrouped} />
      <Route path="/producers/adjustments/summary" render={({ match }) => <TitleText>PRODUCERS DEBITS & CREDITS SUMMARY</TitleText>} />
      <Route path="/producers/adjustments" render={({ match }) => <TitleText>PRODUCERS DEBITS & CREDITS</TitleText>} />
      <Route path="/notifications" render={({ match }) => <TitleText>NOTIFICATIONS</TitleText>} />
      <Route exact path="/maintenance" render={({ match }) => <TitleText>MAINTENANCE</TitleText>} />
      <Route exact path="/maintenance/creditorstemplate" render={({ match }) => <TitleText>CREDITORS ITEM TEMPLATES</TitleText>} />
      <Route exact path="/maintenance/Forwarding Agent" render={({ match }) => <TitleText>MAINTENANCE - FORWARDING AGENT</TitleText>} />
      <Route exact path="/maintenance/AdvanceContracts" render={({ match }) => <TitleText>Maintenance - ADVANCE CONTRACTS</TitleText>} />
      <Route exact path="/maintenance/advanceContractPricing" render={({ match }) => <TitleText>Maintenance - ADVANCE CONTRACT PRICING</TitleText>} />
      <Route exact path="/maintenance/brands" render={({ match }) => <TitleText>Maintenance - BRANDS (MARK)</TitleText>} />
      <Route exact path="/maintenance/clients" render={({ match }) => <TitleText>Maintenance - CLIENTS</TitleText>} />
      <Route exact path="/maintenance/clientsContracts" render={({ match }) => <TitleText>Maintenance - CLIENTS CONTRACTS</TitleText>} />
      <Route exact path="/maintenance/creditors" render={({ match }) => <TitleText>Maintenance - CREDITORS</TitleText>} />
      <Route exact path="/maintenance/consignee" render={({ match }) => <TitleText>Maintenance - CONSIGNEE</TitleText>} />
      <Route exact path="/maintenance/clearingagent" render={({ match }) => <TitleText>Maintenance - CLEARING AGENTS</TitleText>} />
      <Route exact path="/maintenance/classes" render={({ match }) => <TitleText>Maintenance - CLASSES (Grades)</TitleText>} />
      <Route exact path="/maintenance/coldrooms" render={({ match }) => <TitleText>Maintenance - LOCATIONS / COLDROOMS </TitleText>} />
      <Route exact path="/maintenance/commodities" render={({ match }) => <TitleText>Maintenance - COMMODITIES</TitleText>} />
      <Route exact path="/maintenance/costEstimations" render={({ match }) => <TitleText>Maintenance - COST ESTIMATIONS</TitleText>} />
      <Route exact path="/maintenance/counts" render={({ match }) => <TitleText>Maintenance - COUNTS</TitleText>} />
      <Route exact path="/maintenance/currency" render={({ match }) => <TitleText>Maintenance - CURRENCY</TitleText>} />
      <Route exact path="/maintenance/Creditors Template" render={({ match }) => <TitleText>Maintenance - CREDITORS TEMPLATE</TitleText>} />
      <Route exact path="/maintenance/Creditors Groups" render={({ match }) => <TitleText>Maintenance - CREDITORS GROUPS</TitleText>} />
      <Route exact path="/maintenance/bill types" render={({ match }) => <TitleText>Maintenance - CLIENTS BILL TYPES</TitleText>} />
      <Route exact path="/maintenance/EstimatedCost" render={({ match }) => <TitleText>Maintenance - ESTIMATED COST</TitleText>} />
      <Route exact path="/maintenance/EstimatedCostItems" render={({ match }) => <TitleText>Maintenance - ESTIMATED COST ITEM</TitleText>} />
      <Route exact path="/maintenance/EstimatedCostGroups" render={({ match }) => <TitleText>Maintenance - ESTIMATED COST ITEM GROUPS</TitleText>} />
      <Route exact path="/maintenance/exchange" render={({ match }) => <TitleText>Maintenance - RATES OF EXCHANGE</TitleText>} />
      <Route exact path="/maintenance/inventory" render={({ match }) => <TitleText>Maintenance - INVENTORY</TitleText>} />
      <Route exact path="/maintenance/lab tests" render={({ match }) => <TitleText>Maintenance - LAB TESTS</TitleText>} />
      <Route exact path="/maintenance/lab tests type" render={({ match }) => <TitleText>Maintenance - LAB TESTS TYPE</TitleText>} />
      <Route exact path="/maintenance/Label Requirements" render={({ match }) => <TitleText>Maintenance - LABEL REQUIREMENTS</TitleText>} />
      <Route exact path="/maintenance/mgps" render={({ match }) => <TitleText>Maintenance - MGPS</TitleText>} />
      <Route exact path="/maintenance/palletbases" render={({ match }) => <TitleText>Maintenance - PALLET BASES</TitleText>} />
      <Route exact path="/maintenance/orders" render={({ match }) => <TitleText>Maintenance - ORDERS</TitleText>} />
      <Route exact path="/maintenance/palletspecs" render={({ match }) => <TitleText>Maintenance - PALLETSPECS</TitleText>} />
      <Route exact path="/maintenance/pack" render={({ match }) => <TitleText>Maintenance - PACK</TitleText>} />
      <Route exact path="/maintenance/producers" render={({ match }) => <TitleText>Maintenance - PRODUCERS</TitleText>} />
      <Route exact path="/maintenance/producer ph type" render={({ match }) => <TitleText>Maintenance - PRODUCERS PH TYPE</TitleText>} />
      <Route exact path="/maintenance/PH Label" render={({ match }) => <TitleText>Maintenance - PH LABEL</TitleText>} />
      <Route exact path="/maintenance/PH Treatment" render={({ match }) => <TitleText>Maintenance - PH TREATMENT</TitleText>} />
      <Route exact path="/maintenance/Port" render={({ match }) => <TitleText>Maintenance - PORTS</TitleText>} />
      <Route exact path="/maintenance/special market" render={({ match }) => <TitleText>Maintenance - SPECIAL MARKETS</TitleText>} />
      <Route exact path="/maintenance/shippinglines" render={({ match }) => <TitleText>Maintenance - SHIPPING LINES</TitleText>} />
      <Route exact path="/maintenance/temperatures" render={({ match }) => <TitleText>Maintenance - TEMPERATURES</TitleText>} />
      <Route exact path="/maintenance/targets" render={({ match }) => <TitleText>Maintenance - TARGETS</TitleText>} />
      <Route exact path="/maintenance/templates" render={({ match }) => <TitleText>Maintenance - TEMPLATES</TitleText>} />
      <Route exact path="/maintenance/terms" render={({ match }) => <TitleText>Maintenance - PRODUCER TERMS</TitleText>} />
      <Route exact path="/maintenance/UCR Setup" render={({ match }) => <TitleText>Maintenance - UCR SETUP</TitleText>} />
      <Route exact path="/maintenance/varieties" render={({ match }) => <TitleText>Maintenance - VARIETIES</TitleText>} />
      <Route exact path="/maintenance/vessel" render={({ match }) => <TitleText>Maintenance - VESSELS</TitleText>} />
      <Route exact path="/maintenance/weeks" render={({ match }) => <TitleText>Maintenance - WEEKS</TitleText>} />
      <Route exact path="/maintenance/payment terms" render={({ match }) => <TitleText>Maintenance - CLIENT PAYMENT TERMS</TitleText>} />
      <Route exact path="/maintenance/stock adjustment" render={({ match }) => <TitleText>Maintenance - STOCK ADJUSTMENT</TitleText>} />
      <Route exact path="/maintenance/dispatch merge" render={({ match }) => <TitleText>Maintenance - DISPATCH MERGE</TitleText>} />
      <Route exact path="/maintenance/companydetails" render={({ match }) => <TitleText>Maintenance - COMPANY DETAILS</TitleText>} />
      <Route exact path="/maintenance/edifilecomparison" render={({ match }) => <TitleText>Maintenance - EDI FILE COMPARISON</TitleText>} />
      <Route exact path="/maintenance/barcodesplit" render={({ match }) => <TitleText>Maintenance - BARCODE SPLIT</TitleText>} />
      <Route exact path="/maintenance/cost countries" render={({ match }) => <TitleText>Maintenance - COST COUNTRIES</TitleText>} />
      <Route exact path="/maintenance/creditors invoice template" render={({ match }) => <TitleText>Maintenance - CREDITORS INVOICE TEMPLATE</TitleText>} />
      <Route exact path="/maintenance/creditorsimporttemplate" render={({ match }) => <TitleText>Maintenance - CREDITORS IMPORT TEMPLATE</TitleText>} />
      <Route exact path="/maintenance/selling terms" render={({ match }) => <TitleText>Maintenance - SELLING TERMS</TitleText>} />

      <Route
        path="/:location/:rest?"
        render={({ match }) => (
          <TitleText>
            Impala - {UCFirstChar(match.params.location)} {match.params.rest}
          </TitleText>
        )}
      />
      <TitleText>Impala</TitleText>
    </Switch>
  </div>
);

const InstructionDetail: React.FC<{ history: any }> = ({ history }) => {
  const [detail, setDetail] = React.useState("");

  useEffect(() => {
    const location = history.location.pathname.split("/");
    const orderId = location[2];
    getOrderDetailById(orderId).then((result) => {
      setDetail((result.data || { detail: "" }).detail);
    });
  }, [history.location.pathname]);

  return <TitleText subtitle={detail}>INSTRUCTION DETAIL</TitleText>;
};

const ProducerPaymentsGrouped: React.FC<{ history }> = ({ history }) => {
  const [producer, setProducer] = React.useState("");
  const { updateSnack } = React.useContext(SnackContext);

  const loadProducer = async (pathname: string) => {
    try {
      if (!pathname) throw new Error("Error getting producer");

      const [_, __, ___, creditorscode] = pathname.split("/");
      const result = await getProducerByProducerCode(creditorscode);

      if (!result || !result.data) throw new Error("Error getting producer");

      setProducer((result.data || [{ name: "" }])[0].name);
    } catch (error) {
      const err = GenerateErrorMessage(error, "Error getting producer.");
      updateSnack({ show: true, color: "red", message: err });
    }
  };

  useEffect(() => {
    loadProducer(history.location.pathname);
  }, [history.location.pathname]);

  return <TitleText subtitle={producer}>PRODUCERS PAYMENTS</TitleText>;
};

const DebtorsFundsDetail: React.FC<{ history: any }> = ({ history }) => {
  const [detail, setDetail] = React.useState("");
  const { updateSnack } = React.useContext(SnackContext);

  useEffect(() => {
    const location = history.location.pathname.split("/");
    const client_code = location[3];

    getClientNameByCode(client_code)
      .then((result) => {
        setDetail(`${client_code} - ${result[0].clients_name}`);
      })
      .catch((error) => {
        const err = GenerateErrorMessage(error, "Error getting client name.");
        updateSnack({ show: true, color: "red", message: err });
      });
  }, [history.location.pathname]);

  return <TitleText subtitle={detail}>DEBTORS FUNDS ALLOCATION</TitleText>;
};

export const Title = withStyles(styles)(TitleUnstlyed);
