import React from "react";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";

import Grid from "../../lib/components/grid";
import { getProducerFinanceEstimatedCostItemGroups, updateProducerFinanceEstimatedCostItemGroups } from "../../lib/api/estimatedcostitemgroups";
import { GridColumns } from "./estimatedcostgroupssetup";

const styles = (theme: Theme) =>
  createStyles({
    root: {},
    gridWrapper: {
      position: "relative",
    },
    toolbar: {
      position: "absolute",
      left: 0,
      top: 0,
      height: "60px",
      width: "100%",
      "& > h4": {
        fontWeight: "bold",
      },
    },
  });

type Props = {
  producerid: any;
} & WithStyles<typeof styles>;

class EstimatedCostGroupUnstyled extends React.Component<Props, any> {
  constructor(props) {
    super(props);
  }

  state = {
    data: [],
    loading: true,
  };

  loadData = async () => {
    const result = await getProducerFinanceEstimatedCostItemGroups(this.props.producerid);
    this.setState({ data: result, loading: false });
  };

  componentDidMount = async () => {
    this.setState({ loading: true });
    await this.loadData();
  };

  handleChangeIncludeValue = async (rowId, checked) => {
    this.setState({ loading: true });
    await updateProducerFinanceEstimatedCostItemGroups({ id: rowId, include: checked ? 1 : 0 });
    this.loadData();
  };

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.gridWrapper}>
        <Toolbar className={classes.toolbar}>
          <h4>LOCAL COST DEDUCTIONS FOR MGP SHIPMENTS</h4>
        </Toolbar>
        <Grid
          hideDownloadAndLayout
          data={this.state.data}
          GridColumns={(data, filters, columnArrangement, columnsWidth) => GridColumns(data, filters, columnArrangement, columnsWidth, this.handleChangeIncludeValue)}
          loading={this.state.loading}
          clearFilters="producerfinanceestimatedcostgroups"
        />
      </div>
    );
  }
}

export default withStyles(styles)(EstimatedCostGroupUnstyled);
