import React from "react";

import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";

import CustomAutoCompleteFilter from "../lib/components/customdatagridfilter/CustomFilter";
import { CellActionsFormatter } from "../lib/helpers/CellActionsFormatter";
import { handleColumnsArrangement, handleColumnsWidth } from "../lib/helpers/grid";
import { DateFormatter, FormatterNumber2Decimals, RightAlignColumnHeader } from "../lib/components/Formatters";
import { SummaryNumber2Decimal } from "../lib/components/SummaryFormatter";

const cellWidthSmall = 80;
const cellWidthSmallBig = 140;
const cellWidthMedium = 220;
const cellWidthLarge = 350;

const defaultColumnProperties = {
  resizable: true,
  sortable: true,
};

let currentSpace = true;
export const GridColumns = (data, filters, arrangement = undefined, columnsWidth = undefined, handleRemove, handleEdit) => {
  let postValue = "";

  if (data) {
    if (currentSpace) {
      postValue = " ";
    }
    currentSpace = !currentSpace;
  }

  const columns = [
    {
      key: "actions",
      name: "Actions",
      width: cellWidthSmall,
      formatter: ({ row }) => {
        const actions = cellActions(row, handleRemove, handleEdit);
        return (
          <div style={{ display: "flex", justifyContent: "center", gap: "5px" }}>
            <CellActionsFormatter actions={actions} />
          </div>
        );
      },
    },
    {
      key: "date",
      name: "Date" + postValue,
      width: cellWidthSmallBig,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"date"} data={data} section={"adhocinvoice"} />;
      },
      formatter: DateFormatter,
    },
    {
      key: "duedate",
      name: "Due Date" + postValue,
      width: cellWidthSmallBig,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"duedate"} data={data} section={"adhocinvoice"} />;
      },
      formatter: DateFormatter,
    },
    {
      key: "clients_name",
      name: "Client" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"clients_name"} data={data} section={"adhocinvoice"} />;
      },
    },
    {
      key: "invoice_number",
      name: "Invoice Number" + postValue,
      width: cellWidthSmallBig,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"invoice_number"} data={data} section={"adhocinvoice"} />;
      },
    },
    {
      key: "total_amount",
      name: "Amount" + postValue,
      width: cellWidthSmallBig,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"total_amount"} data={data} section={"adhocinvoice"} />;
      },
      formatter: FormatterNumber2Decimals,
      summaryFormatter: SummaryNumber2Decimal,
      headerRenderer: RightAlignColumnHeader,
    },
    {
      key: "reference",
      name: "Reference" + postValue,
      width: cellWidthSmallBig,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"reference"} data={data} section={"adhocinvoice"} />;
      },
    },
    {
      key: "client_orderno",
      name: "Client Order No" + postValue,
      width: cellWidthSmallBig,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"client_orderno"} data={data} section={"adhocinvoice"} />;
      },
    },
  ].map((c) => ({ ...c, ...defaultColumnProperties }));

  let columnsArranged = [];
  columnsArranged = handleColumnsArrangement(columnsArranged, arrangement, columns);
  columnsArranged = handleColumnsWidth(columnsArranged, columnsWidth, columns);

  return columnsArranged;
};

const cellActions = (agentMaintenance, handleRemove, handleEdit) => {
  return [
    {
      icon: (
        <span title="Edit">
          <EditIcon style={{ cursor: "pointer", color: "orange" }} />
        </span>
      ),
      callback: (e) => {
        handleEdit(agentMaintenance.id);
      },
    },
    {
      icon: (
        <span title="Remove">
          <DeleteIcon style={{ cursor: "pointer", color: "red" }} />
        </span>
      ),
      callback: (e) => {
        handleRemove(agentMaintenance.id);
      },
    },
  ];
};
