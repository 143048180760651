import React from "react";
import CustomAutoCompleteFilter from "../lib/components/customdatagridfilter/CustomFilter";

import numeral from "numeral";
import TextEditor from "../lib/components/TextEditor";
import { handleColumnsArrangement, handleColumnsWidth } from "../lib/helpers/grid";

const cellWidthSmallX = 150;
const cellWidthSmall = 220;

const defaultColumnProperties = {
  filterable: true,
  resizable: true,
  sortable: true,
};

let currentSpace = true;

enum FormatterTypes {
  Default = 0,
  Number = 1,
  Number2Decimals = 2,
}

const FormatterNumber2Decimals = ({ data }) => {
  return <>{numeral(data).format("0.00")}</>;
};

const FormatterNumber = ({ data }) => {
  return <div style={{ textAlign: "right" }}>{numeral(data).format("0")}</div>;
};

const RowFormatter = ({ row, column, formatter = 0 }) => {
  const data = row[column.key];

  switch (formatter) {
    case FormatterTypes.Number:
      return <FormatterNumber data={data} />;
    case FormatterTypes.Number2Decimals:
      return <FormatterNumber2Decimals data={data} />;
    default:
      return <FormatterNumber data={data} />;
  }
};

const SummaryRow = ({ row, column, formatter }) => {
  switch (formatter) {
    case FormatterTypes.Number:
      return <div style={{ textAlign: "right", fontWeight: "bold" }}>{numeral(row[column.key]).format("0,0")}</div>;
    case FormatterTypes.Number2Decimals:
      return <div style={{ textAlign: "right", fontWeight: "bold" }}>{numeral(row[column.key]).format("0,0.00")}</div>;
    default:
      return <div style={{ textAlign: "right", fontWeight: "bold" }}>{numeral(row[column.key]).format("0,0")}</div>;
  }
};

export const GridColumnsGroupedFruitSpec = (data, filters, arrangement, columnsWidth) => {
  let postValue = "";

  if (data) {
    if (currentSpace) {
      postValue = " ";
    }
    currentSpace = !currentSpace;
  }

  const columns = [
    {
      key: "barcode_commoditycode",
      name: "Commodity" + postValue,
      width: cellWidthSmallX,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"barcode_commoditycode"} data={data} section={"invoicepalletsgrouped"} />;
      },
      summaryFormatter: () => {
        return <div style={{ textAlign: "right", fontWeight: "bold" }}>TOTALS</div>;
      },
    },
    {
      key: "barcode_varietycode",
      name: "Variety" + postValue,
      width: cellWidthSmallX,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"barcode_varietycode"} data={data} section={"invoicepalletsgrouped"} />;
      },
    },
    {
      key: "barcode_packcode",
      name: "Pack" + postValue,
      width: cellWidthSmallX,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"barcode_packcode"} data={data} section={"invoicepalletsgrouped"} />;
      },
    },
    {
      key: "barcode_markcode",
      name: "Mark" + postValue,
      width: cellWidthSmallX,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"barcode_markcode"} data={data} section={"invoicepalletsgrouped"} />;
      },
    },
    {
      key: "barcode_gradecode",
      name: "Grade" + postValue,
      width: cellWidthSmallX,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"barcode_gradecode"} data={data} section={"invoicepalletsgrouped"} />;
      },
    },
    {
      key: "barcode_countcode",
      name: "Count" + postValue,
      width: cellWidthSmallX,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"barcode_countcode"} data={data} section={"invoicepalletsgrouped"} />;
      },
    },
    {
      key: "barcode_inventorycode",
      name: "Inventory" + postValue,
      width: cellWidthSmallX,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"barcode_inventorycode"} data={data} section={"invoicepalletsgrouped"} />;
      },
    },
    {
      key: "new_palletsize",
      name: "Plt Size" + postValue,
      width: cellWidthSmallX,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"new_palletsize"} data={data} section={"invoicepalletsgrouped"} />;
      },
      formatter: ({ row, column }) => <RowFormatter row={row} column={column} formatter={FormatterTypes.Number2Decimals} />,
      summaryFormatter: ({ row, column }) => <SummaryRow row={row} column={column} formatter={FormatterTypes.Number2Decimals} />,
    },
    {
      key: "new_nocartons",
      name: "Cartons" + postValue,
      width: cellWidthSmallX,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"new_nocartons"} data={data} section={"invoicepalletsgrouped"} />;
      },
      formatter: ({ row, column }) => <RowFormatter row={row} column={column} formatter={FormatterTypes.Number} />,
      summaryFormatter: ({ row, column }) => <SummaryRow row={row} column={column} formatter={FormatterTypes.Number} />,
    },
    {
      key: "stockdetail_sellingprice",
      name: "Selling Price" + postValue,
      width: cellWidthSmall,
      formatter: ({ row, column }) => <RowFormatter row={row} column={column} formatter={FormatterTypes.Number2Decimals} />,
      editable: true,
      editor: (props) => <TextEditor {...props} align="right" />,
      filterRenderer: (e) => {
        return (
          <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"stockdetail_sellingprice"} data={data} section={"invoicepalletsgrouped"} />
        );
      },
      summaryFormatter: ({ row, column }) => <SummaryRow row={row} column={column} formatter={FormatterTypes.Number2Decimals} />,
    },
  ];

  let columnsArranged = [];
  columnsArranged = handleColumnsArrangement(columnsArranged, arrangement, columns);
  columnsArranged = handleColumnsWidth(columnsArranged, columnsWidth, columns);

  return columnsArranged;
};
