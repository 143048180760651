import React from "react";

import Confirmation from "../confirmation";
import { FiltersContext } from "../../context/FiltersContext";

import FiltersList from "./FiltersList";

const CustomFilter = () => {
  const { openFilters, toggleFilters } = React.useContext(FiltersContext);

  const handleCloseFilters = () => {
    toggleFilters(undefined);
  };

  if (!openFilters || openFilters.length == 0) {
    return <></>;
  }

  return (
    <Confirmation
      isOpen={true}
      handleClose={handleCloseFilters}
      handleConfirm={handleCloseFilters}
      title={`Change Filter Options`}
      body={undefined}
      buttonCaptionObject={{ no: "close", yes: "add" }}
    >
      <FiltersList />
    </Confirmation>
  );
};

export default CustomFilter;
