import React, { useRef, useState } from "react";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";

import Check from "@material-ui/icons/Check";
import Close from "@material-ui/icons/Close";
import ClearAll from "@material-ui/icons/ClearAll";

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";

import { updateUserProfile } from "../helpers/stickyfilters";

import { SortableContainer, SortableHandle, SortableElement } from "react-sortable-hoc";
import arrayMove from "array-move";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      width: "300px",
      position: "relative",
    },
    list: {
      height: "400px",
      marginLeft: "0px",
      overflowY: "scroll",
    },
    tableRow: {
      paddingTop: theme.spacing(1),
      marginTop: theme.spacing(1),
    },
  });

type GridLayoutFormProps = {
  onSubmit: any;
  onClose: any;
  columns: any;
  layoutItem: string;
  setSearchPhrase: any;
  clearPhrase: boolean;
} & WithStyles<typeof styles>;

const GridLayoutFormUnstyled: React.FunctionComponent<GridLayoutFormProps> = (props) => {
  const { classes, onSubmit, onClose, layoutItem, setSearchPhrase, clearPhrase } = props;
  const [columns, setColumns] = useState(props.columns);
  const [phrase, setPhrase] = useState("");
  const inputRef = "standard-search";

  React.useEffect(() => {
    setPhrase("");
  }, [clearPhrase]);

  const onSortEnd = ({ oldIndex, newIndex }) => {
    const column = arrayMove(columns, oldIndex, newIndex);
    setColumns(column);
    document.getElementById(inputRef).focus();
  };

  const onClearSticky = () => {
    localStorage.removeItem(layoutItem);
    onClose();
  };

  const focusOnSearch = (userSearch) => {
    columns.map((item) => {
      if (userSearch == "" || !item.toLowerCase().includes(userSearch)) {
        const otherElement = document.getElementById(item.toLowerCase());

        if (otherElement) {
          otherElement.style.background = "#ffffff";
        }
      } else if (item.toLowerCase().includes(userSearch)) {
        const searchedElement = document.getElementById(item.toLowerCase());

        if (searchedElement) {
          searchedElement.focus();
          searchedElement.style.background = "#c9d2ff";
        }
      }
    });

    const searchedElementBack = document.getElementById(inputRef);
    searchedElementBack.focus();
  };

  return (
    <div className={classes.root}>
      <TextField
        value={phrase}
        id={inputRef}
        label="Find Column..."
        type="search"
        fullWidth={true}
        autoFocus={true}
        autoComplete="off" // switches off that autocomplete popup when typing.
        onChange={(e) => {
          setPhrase(e.target.value.toLowerCase());
          setSearchPhrase(e.target.value.toLowerCase());
          focusOnSearch(e.target.value.toLowerCase());
        }}
      />
      <div className={classes.list}>
        <SortableList items={columns} onSortEnd={onSortEnd} />
      </div>
      <div style={{ display: "flex", justifyContent: "flex-end", margin: "10px 0 -5px 0" }}>
        <Button style={{ marginRight: "10px" }} color="secondary" variant="outlined" onClick={onClearSticky}>
          <ClearAll />
          clear
        </Button>
        <Button style={{ marginRight: "10px" }} color="secondary" variant="outlined" onClick={onClose}>
          <Close />
        </Button>
        <Button
          type="submit"
          color="primary"
          variant="outlined"
          onClick={() => {
            onSubmit(columns);
            updateUserProfile(columns, layoutItem, "general");
          }}
        >
          <Check />
        </Button>
      </div>
    </div>
  );
};

export default withStyles(styles)(GridLayoutFormUnstyled);

const SortableItem = SortableElement(({ value, index }) => {
  return (
    <li tabIndex={-1} id={value.toLowerCase()} key={value} style={{ zIndex: 99999999, padding: "10px" }}>
      {value}
    </li>
  );
});

const SortableList = SortableContainer(({ items }) => {
  return (
    <ul style={{ listStyle: "none", paddingLeft: "0" }}>
      {items.map((item, index) => {
        if (item && item.length > 0) {
          return <SortableItem key={`containerItem-${item}-${index}`} index={index} value={item} />;
        }
      })}
    </ul>
  );
});
