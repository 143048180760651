import React from "react";

import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";

import CustomAutoCompleteFilter from "../lib/components/customdatagridfilter/CustomFilter";
import { CellActionsFormatter } from "../lib/helpers/CellActionsFormatter";
import { handleColumnsWidth, handleColumnsArrangement } from "../lib/helpers/grid";
import { CheckboxSelect } from "../lib/components/CheckboxSelect";
import { Link } from "react-router-dom";
import Checkbox from "@material-ui/core/Checkbox";

const cellWidthTiny = 40;
const cellWidthSmall = 80;
const cellWidthSmallBig = 140;

const defaultColumnProperties = {
  resizable: true,
  sortable: true,
};

let currentSpace = true;
export const GridColumns = (data, filters, arrangement, columnsWidth, handleRemove, handleEdit, loggedInUser) => {
  let postValue = "";

  if (data) {
    // console.log(data[0]);

    if (currentSpace) {
      postValue = " ";
    }
    currentSpace = !currentSpace;
  }

  const columns = [
    {
      key: "actions",
      name: "Actions",
      width: cellWidthSmall,
      formatter: ({ row }) => {
        const actions = userActions(row, handleRemove, handleEdit, loggedInUser);
        return (
          <div style={{ display: "flex", justifyContent: "center", gap: "5px" }}>
            <CellActionsFormatter actions={actions} />
          </div>
        );
      },
    },
    {
      key: "username",
      name: "Username" + postValue,
      width: cellWidthSmallBig,
      filterable: false,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"username"} data={data} section="usermanagementgridsetup" />;
      },
    },
    {
      key: "email",
      name: "Email" + postValue,
      width: cellWidthSmallBig,
      filterable: false,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"email"} data={data} section="usermanagementgridsetup" />;
      },
    },
    {
      key: "admin",
      name: "Admin" + postValue,
      width: cellWidthSmall,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"admin"} data={data} section="usermanagementgridsetup" />;
      },
      formatter: ({ row }) => {
        return (
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: "-8px" }}>
            <Checkbox color="default" checked={row["admin"]} inputProps={{ "aria-label": "primary checkbox" }} disabled />
          </div>
        );
      },
    },
    {
      key: "active",
      name: "Active" + postValue,
      width: cellWidthTiny,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"active"} data={data} section={"usermanagementgridsetup"} />;
      },
      formatter: ({ row }) => {
        return (
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: "-8px" }}>
            <Checkbox color="default" checked={row["active"]} inputProps={{ "aria-label": "primary checkbox" }} disabled />
          </div>
        );
      },
    },
    {
      key: "can_adjust",
      name: "Stock Adjustment" + postValue,
      width: cellWidthSmallBig,
      filterable: false,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"can_adjust"} data={data} section="usermanagementgridsetup" />;
      },
      formatter: ({ row }) => {
        return (
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: "-8px" }}>
            <Checkbox color="default" checked={row["can_adjust"]} inputProps={{ "aria-label": "primary checkbox" }} disabled />
          </div>
        );
      },
    },
    {
      key: "stockalloc_permission",
      name: "Stock Allocation" + postValue,
      width: cellWidthSmallBig,
      filterable: false,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"stockalloc_permission"} data={data} section="usermanagementgridsetup" />;
      },
      formatter: ({ row }) => {
        return (
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: "-8px" }}>
            <Checkbox color="default" checked={row["stockalloc_permission"]} inputProps={{ "aria-label": "primary checkbox" }} disabled />
          </div>
        );
      },
    },
    {
      key: "consolidation_lock_permission",
      name: "Consolidation Lock" + postValue,
      width: cellWidthSmallBig,
      filterable: false,
      filterRenderer: (e) => {
        return (
          <CustomAutoCompleteFilter
            {...e}
            multiSelection={true}
            currentFilters={filters}
            fieldname={"consolidation_lock_permission"}
            data={data}
            section="usermanagementgridsetup"
          />
        );
      },
      formatter: ({ row }) => {
        return (
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: "-8px" }}>
            <Checkbox color="default" checked={row["consolidation_lock_permission"]} inputProps={{ "aria-label": "primary checkbox" }} disabled />
          </div>
        );
      },
    },
  ].map((c) => ({ ...c, ...defaultColumnProperties }));

  let columnsArranged = [];

  columnsArranged = handleColumnsArrangement(columnsArranged, arrangement, columns);
  columnsArranged = handleColumnsWidth(columnsArranged, columnsWidth, columns);

  return columnsArranged;
};

const userActions = (user, handleRemove, handleEdit, loggedInUser) => {
  return [
    {
      icon: (
        <span title="Edit User">
          <EditIcon style={{ cursor: "pointer", color: "orange" }} />
        </span>
      ),
      callback: (e) => {
        handleEdit(user);
      },
    },
    {
      icon: user.id !== loggedInUser.id && (
        <span title="Remove User">
          <DeleteIcon style={{ cursor: "pointer", color: "red" }} />
        </span>
      ),
      callback: (e) => {
        handleRemove(user);
      },
    },
  ];
};
