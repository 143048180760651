import React from "react";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";

import Check from "@material-ui/icons/Check";
import Close from "@material-ui/icons/Close";

import Button from "@material-ui/core/Button";
import Input from "@material-ui/core/Input";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import CircularProgress from "@material-ui/core/CircularProgress";

import { Field, Form } from "react-final-form";

import { creditors } from "../../lib/api/creditors";
import { getCurrencyAllSortedMappedforCombo } from "../../lib/api/currency";
import { getcreditorsPaymentTermsCombo } from "../../lib/api/creditorspaymentterms";
import { creditorsgroupsubAllSortedMappedforCombo } from "../../lib/api/creditorsgroupsub";
import { getCreditorsInvoiceTypeReadyForCombo } from "../../lib/api/creditorsinvoicetype";
import { SnackContext } from "../../lib/context/SnackContext";
import { GenerateErrorMessage } from "../../lib/helpers/string_methods";
import Checkbox from "@material-ui/core/Checkbox";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1),
      width: "100%",
    },
    tableRow: {
      paddingTop: theme.spacing(1),
      paddingRight: theme.spacing(1) * 2,
      marginTop: theme.spacing(1),
      textAlign: "right",
    },
    tableSelect: {
      paddingTop: theme.spacing(1),
      paddingRight: theme.spacing(1) * 2,
      marginTop: theme.spacing(1),
      width: "100%",
    },
    loadingWrapper: {
      height: "550px",
      width: "320px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
    },
  });

type CreditorsEditFormProps = {
  onSubmit: any;
  onClose: any;
  id: any;
} & WithStyles<typeof styles>;

const validate = (value) => (value ? undefined : "Required");
class CreditorsEditFormUnstyled extends React.Component<CreditorsEditFormProps, any> {
  state = {
    classes: undefined,
    item: {
      id: "0",
      code: "",
      name: "",
      physical_address: "",
      postal_address: "",
      telephone: "",
      email: "",
      vat_number: 0,
      creditorsgroupsub_id: undefined,
      contact_person: "",
      currency_id: undefined,
      payment_term_id: undefined,
      consolidationapply: 1,
      active: undefined,
    },
    onSubmit: undefined,
    onClose: undefined,
    id: 0,
    currencies: [],
    paymentTerms: [],
    groups: [],
    creditorsInvoiceTypeComboData: [],
    loading: true,
  };

  constructor(props) {
    super(props);
    this.state.classes = props.classes;
    this.state.onSubmit = props.onSubmit;
    this.state.onClose = props.onClose;
    this.state.id = props.id;
  }

  static contextType = SnackContext;
  context!: React.ContextType<typeof SnackContext>;

  loadData = async () => {
    this.setState({ loading: true });
    try {
      const [currencies, paymentTerms, groups, creditorsInvoiceTypeComboData, _] = await Promise.all([
        getCurrencyAllSortedMappedforCombo(),
        getcreditorsPaymentTermsCombo(),
        creditorsgroupsubAllSortedMappedforCombo(),
        getCreditorsInvoiceTypeReadyForCombo(),
        this.getItem(this.state.id),
      ]);
      this.setState({ currencies, paymentTerms, groups, creditorsInvoiceTypeComboData });
    } catch (error) {
      const err = GenerateErrorMessage(error, "Error getting creditors info.");
      this.context.updateSnack({ show: true, color: "red", message: err });
    }
    this.setState({ loading: false });
  };

  componentDidMount() {
    this.loadData();
  }

  getItem = async (id: any) => {
    if (!parseInt(id)) return;

    this.setState({ loading: true });
    try {
      const data = await creditors.single(id);
      this.setState({ item: data });
    } catch (error) {
      const err = GenerateErrorMessage(error, "Error getting creditors info.");
      this.context.updateSnack({ show: true, color: "red", message: err });
    }
    this.setState({ loading: false });
  };

  handleFocus = (event) => {
    event.target.select();
  };

  handleOnChange = (field, value) => {
    this.setState({
      item: { ...this.state.item, [field]: value },
    });
  };

  render() {
    const { classes } = this.state;

    return (
      <div className={classes.root}>
        {this.state.loading ? (
          <div className={classes.loadingWrapper}>
            <CircularProgress color="secondary" size={30} />
            <p>Loading Creditor...</p>
          </div>
        ) : (
          <Form
            initialValues={this.state.item}
            onSubmit={(fields) => {
              this.state.onSubmit(fields);
            }}
            render={({ handleSubmit, form, values }: any) => (
              <form onSubmit={handleSubmit}>
                <table style={{ marginLeft: "15px" }}>
                  <tbody>
                    <tr className={classes.tableRow}>
                      <td className={classes.tableRow}>
                        <span>Active:</span>
                      </td>
                      <td className={classes.tableRow} style={{ textAlign: "left" }}>
                        <Checkbox
                          checked={this.state.item.active}
                          onChange={(event) => this.handleOnChange("active", event.target.checked)}
                          onFocus={this.handleFocus}
                          style={{ padding: 0 }}
                        />
                      </td>
                    </tr>
                    <tr className={classes.tableRow}>
                      <td className={classes.tableRow}>
                        <span>Code:</span>
                      </td>
                      <td className={classes.tableRow}>{TableInputText("code", this.state.item.code, this.handleFocus, this.handleOnChange, this.state.id > 0, true)}</td>
                    </tr>
                    <tr className={classes.tableRow}>
                      <td className={classes.tableRow}>
                        <span>Name:</span>
                      </td>
                      <td className={classes.tableRow}>{TableInputText("name", this.state.item.name, this.handleFocus, this.handleOnChange, this.state.id > 0, true)}</td>
                    </tr>
                    <tr className={classes.tableRow}>
                      <td className={classes.tableRow}>
                        <span>Physical Address:</span>
                      </td>
                      <td className={classes.tableRow}>{TableInputText("physical_address", this.state.item.physical_address, this.handleFocus, this.handleOnChange)}</td>
                    </tr>
                    <tr className={classes.tableRow}>
                      <td className={classes.tableRow}>
                        <span>Postal Address:</span>
                      </td>
                      <td className={classes.tableRow}>{TableInputText("postal_address", this.state.item.postal_address, this.handleFocus, this.handleOnChange)}</td>
                    </tr>
                    <tr className={classes.tableRow}>
                      <td className={classes.tableRow}>
                        <span>Telephone:</span>
                      </td>
                      <td className={classes.tableRow}>{TableInputText("telephone", this.state.item.telephone, this.handleFocus, this.handleOnChange)}</td>
                    </tr>
                    <tr className={classes.tableRow}>
                      <td className={classes.tableRow}>
                        <span>Email:</span>
                      </td>
                      <td className={classes.tableRow}>{TableInputText("email", this.state.item.email, this.handleFocus, this.handleOnChange)}</td>
                    </tr>
                    <tr className={classes.tableRow}>
                      <td className={classes.tableRow}>
                        <span>Vat Number:</span>
                      </td>
                      <td className={classes.tableRow}>{TableInputText("vat_number", this.state.item.vat_number, this.handleFocus, this.handleOnChange)}</td>
                    </tr>
                    <TableFieldCombo
                      required
                      form={form}
                      values={values}
                      title="Sub Group"
                      classes={classes}
                      field="creditorsgroupsub_id"
                      data={this.state.groups}
                      disabled={this.state.id > 0}
                    />
                    <tr className={classes.tableRow}>
                      <td className={classes.tableRow}>
                        <span>Contact Person:</span>
                      </td>
                      <td className={classes.tableRow}>{TableInputText("contact_person", this.state.item.contact_person, this.handleFocus, this.handleOnChange)}</td>
                    </tr>
                    <TableFieldCombo form={form} required={true} values={values} title="Currency" classes={classes} field="currency_id" data={this.state.currencies} />
                    <TableFieldCombo form={form} values={values} title="Payment Term" required={false} classes={classes} field="payment_term_id" data={this.state.paymentTerms} />
                    <TableFieldCombo
                      required
                      form={form}
                      values={values}
                      title="Consolidation Apply"
                      classes={classes}
                      field="consolidationapply"
                      data={this.state.creditorsInvoiceTypeComboData}
                      disabled={this.state.id > 0}
                    />
                  </tbody>
                </table>
                <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "22px", marginRight: "15px" }}>
                  <Button style={{ marginRight: "10px" }} color="secondary" variant="outlined" onClick={this.state.onClose}>
                    <Close />
                  </Button>
                  <Button type="submit" color="primary" variant="outlined">
                    <Check />
                  </Button>
                </div>
              </form>
            )}
          />
        )}
      </div>
    );
  }
}

export default withStyles(styles)(CreditorsEditFormUnstyled);

const TableInputText = (
  field: string,
  value: number | string,
  handleFocus,
  handleOnChange,
  disabled: boolean = false,
  required: boolean = false,
  isMultiLine = false,
  maxRows = 0,
  rows = 0,
) => {
  return (
    <Input
      style={{ width: "100%" }}
      multiline={isMultiLine}
      rowsMax={maxRows}
      rows={rows}
      onFocus={handleFocus}
      key={`body_value_${field}`}
      name={field}
      value={value}
      disabled={disabled}
      required={required}
      onChange={(e) => handleOnChange(field, e.target.value)}
    />
  );
};

const TableFieldCombo: React.FunctionComponent<
  {
    classes: any;
    title: string;
    field: string;
    data: any;
    values: any;
    form: any;
    required: boolean;
    disabled?: boolean;
  } & WithStyles<typeof styles>
> = (props) => {
  const { classes, title, field, data, values, form, required, disabled } = props;

  const handleChange = (event) => {
    form.change(field, event.target.value);
  };

  return (
    <tr>
      <td className={classes.tableRow}>{`${title}:`}</td>
      <td className={classes.tableSelect}>
        <Field
          validate={required && validate}
          name={field}
          render={({ input, meta }) => (
            <>
              <Select {...input} id="select" value={values[field]} onChange={handleChange} style={{ width: "100%" }} disabled={disabled}>
                {data &&
                  data.map((item, index) => (
                    <MenuItem key={`${item.value}${index}`} value={item.value}>
                      {item.display}
                    </MenuItem>
                  ))}
              </Select>
              {meta.error && meta.touched && <span style={{ color: "#A52A2A" }}>{meta.error}</span>}
            </>
          )}
        />
      </td>
    </tr>
  );
};
