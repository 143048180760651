import { apiHeaders, client, request } from "./_base";

const endpoint = "saleadjustmentadhoc";

export type SaleAdjustmentAdhocType = {
  id: number;
  clients_id: number;
  description: string;
  type: string | number;
  document_no: number;
  amount: number;
  link_to_shipment: number;
  created_at?: Date;
  updated_at?: Date;
  duedate: Date;
  date: Date;
};

export type SaleAdjustmentAdhocFullType = {
  saleadjustmentadhoc_id: number;
  clients_name: string;
  clients_code: string;
  description: string;
  type: string;
  document_no: string;
  amount: number;
  created_at: Date;
  updated_at: Date;
  statement_account_id: number;
};

export type AdhocTransactionType = {
  duedate: Date;
  description: string;
  type: string;
  document_no: string;
  amount: number;
  adhoc_date: Date;
};

export const saleadjustmentadhoc = client<SaleAdjustmentAdhocType>(endpoint, (item) => ({
  ...item,
}));

export const getSaleAdjustmentAdhocById = async (adjustment_id: number) => {
  const result = await request(`${endpoint}/ext/getSaleAdjustmentAdhocById/${adjustment_id}`, {
    method: "GET",
  });
  const resp = await result.json();
  if (result.status == 500) {
    throw resp;
  }
  return resp.data;
};

export const getSaleAdjustmentAdhocFull = async (): Promise<SaleAdjustmentAdhocFullType[]> => {
  const result = await request(`${endpoint}/ext/saleAdjustmentAdhocFull`, {
    method: "GET",
  });
  const resp = await result.json();
  if (result.status == 500) {
    throw resp;
  }
  return resp.data;
};

export const getAdhocTransactionsByClient = async (client_id: number): Promise<AdhocTransactionType[]> => {
  const result = await request(`${endpoint}/ext/adhocTransactionsByClient/${client_id}`, {
    method: "GET",
  });
  const resp = await result.json();
  if (result.status == 500) {
    throw resp;
  }
  return resp.data;
};

export const generateNewDocNumber = async (): Promise<{ document_no: number }[]> => {
  const result = await request(`${endpoint}/ext/generateNewDocNumber`, {
    method: "GET",
  });
  const resp = await result.json();
  if (result.status == 500) {
    throw resp;
  }
  return resp.data;
};

export enum SaleAdjustmentAdhocSaleType {
  Debit = 0,
  Credit = 1,
}

export type SaleAdjustmentAdhocAdjustment = {
  id?: number;
  amount: number;
  sale_id: number;
  saleadjustmentadhoc_id?: number;
};

export const createSaleAdjustmentAdhoc = async (data: { formValues: SaleAdjustmentAdhocType; linkedShipments: SaleAdjustmentAdhocAdjustment[] }): Promise<void> => {
  const result = await request(`${endpoint}/ext/createSaleAdjustmentAdhoc`, {
    method: "POST",
    headers: apiHeaders(JSON.stringify(data)),
    body: JSON.stringify(data),
  });
  if (result.status == 500) {
    const resp = await result.json();
    throw resp;
  }
};
