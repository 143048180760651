import React from "react";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";

import Check from "@material-ui/icons/Check";
import Close from "@material-ui/icons/Close";

import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";

import { target, targetsAll } from "../../lib/api/target";
import { currency } from "../../lib/api/currency";
import { costestimations } from "../../lib/api/costestimation";

import { Field, Form } from "react-final-form";
import { TextField, Checkbox, Select } from "final-form-material-ui";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1),
      width: "400px",
    },
    formGrid: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(1),
    },
    formRow: {
      display: "grid",
      alignItems: "center",
      gap: theme.spacing(2),
      gridTemplateColumns: "100px 250px",
    },
    formRowLabel: {
      display: "flex",
      justifyContent: "flex-end",
      width: "100px",
    },
    tableCellsValueSelect: {
      width: "100%",
      height: "50px",
      display: "flex",
      justifyContent: "center",
      "& .MuiInput-formControl": {
        marginTop: "0",
      },
    },
  });

type TargetEditFormProps = {
  onSubmit: any;
  onClose: any;
  id: any;
} & WithStyles<typeof styles>;

const required = (value: any) => (value ? undefined : "Required");

class TargetEditFormUnstyled extends React.Component<TargetEditFormProps, any> {
  state = {
    classes: undefined,
    item: { id: "0", active: 1, region: "", market: "", country: "", currency: "", costestimation_id: "", description: "" },
    onSubmit: undefined,
    onClose: undefined,
    currencyArr: [],
    costestcountryArr: [],
    id: undefined,
  };

  constructor(props) {
    super(props);
    this.state.classes = props.classes;
    this.state.onSubmit = props.onSubmit;
    this.state.onClose = props.onClose;
    this.state.id = props.id;
  }

  loadData = async () => {
    const [currencyResult, targetsResult, costEstimationsResult] = await Promise.all([currency.all(), targetsAll(), costestimations.all()]);

    const currencyResultSorted = currencyResult.sort((a, b) => a.code.localeCompare(b.code));
    const countryresultSorted = costEstimationsResult.sort((a, b) => a.country.localeCompare(b.country));

    if (this.state.id != "0") {
      await this.getItem(this.state.id);
    }

    this.setState({ currencyArr: currencyResultSorted, costestcountryArr: countryresultSorted, loading: false });
  };

  componentDidMount() {
    this.loadData();
  }

  getItem = async (id: number) => {
    const data = await target.single(id);
    this.setState({ item: data });
  };

  render() {
    const { classes } = this.state;

    return (
      <div className={classes.root}>
        <Form
          initialValues={{ ...this.state.item }}
          onSubmit={this.state.onSubmit}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit} className={classes.formGrid}>
              <CheckboxField classes={classes} name="active" label="Active" />
              <div className={classes.formRow}>
                <label className={classes.formRowLabel}>Region: </label>
                <Field name="region" component={TextField} type="text" fullWidth={true} validate={required} />
              </div>
              <div className={classes.formRow}>
                <label className={classes.formRowLabel}>Market: </label>
                <Field name="market" component={TextField} type="text" fullWidth={true} validate={required} />
              </div>
              <div className={classes.formRow}>
                <label className={classes.formRowLabel}>Country: </label>
                <Field name="country" component={TextField} type="text" fullWidth={true} validate={required} />
              </div>
              <div className={classes.formRow}>
                <label className={classes.formRowLabel}>Description: </label>
                <Field name="description" component={TextField} type="text" fullWidth={true} validate={required} />
              </div>
              <div className={classes.formRow}>
                <label className={classes.formRowLabel}>Currency: </label>
                <Field name={"currency"} component={Select as any} formControlProps={{ className: classes.tableCellsValueSelect }} validate={required}>
                  {(this.state.currencyArr || []).map((item, index) => (
                    <MenuItem key={`${item.code}${index}`} value={item.code}>
                      {item.code}
                    </MenuItem>
                  ))}
                </Field>
              </div>
              <div className={classes.formRow}>
                <label className={classes.formRowLabel}>Cost Country: </label>
                <Field name={"costestimation_id"} component={Select as any} formControlProps={{ className: classes.tableCellsValueSelect }} validate={required}>
                  {(this.state.costestcountryArr || []).map((item, index) => (
                    <MenuItem key={`${item.id}${index}`} value={item.id}>
                      {item.country}
                    </MenuItem>
                  ))}
                </Field>
              </div>
              <div style={{ display: "flex", justifyContent: "flex-end", margin: "20px 15px 5px" }}>
                <Button style={{ marginRight: "10px" }} color="secondary" variant="outlined" onClick={this.state.onClose} type="button">
                  <Close />
                </Button>
                <Button type="submit" color="primary" variant="outlined">
                  <Check />
                </Button>
              </div>
            </form>
          )}
        />
      </div>
    );
  }
}

const CheckboxField = ({ classes, name, label }) => (
  <div className={classes.formRow}>
    <label className={classes.formRowLabel}>{label}:</label>
    <div>
      <Field name={name} component={Checkbox} type="checkbox" style={{ margin: 0, padding: 0 }} />
    </div>
  </div>
);

export default withStyles(styles)(TargetEditFormUnstyled);
