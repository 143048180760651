import React from "react";
import { withStyles, createStyles, Theme, WithStyles } from "@material-ui/core/styles";

import Grid from "../../lib/components/grid";
import { GreenButton, OrangeButton } from "../../lib/components/ColorButtons";
import { GroupedUnapprovedGridColumns } from "./groupedunapprovedgridsetup";
import { Status } from "./consolidation";
import { ConsolidationDetail } from "../../lib/api/consolidation";

import Button from "@material-ui/core/Button";
import Toolbar from "@material-ui/core/Toolbar";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import BigNumber from "bignumber.js";
import classnames from "classnames";
import { Row } from "react-data-grid";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1),
      height: "calc(100vh - 100px)",
      width: "100%",
      position: "relative",
    },
    toolbarWrapper: {
      position: "relative",
    },
    toolbar: {
      position: "absolute",
      left: 0,
      width: "100%",
    },
    redRow: {
      color: "red",
    },
    greenRow: {
      color: "red",
    },
    boldRow: {
      fontWeight: "bold",
      cursor: "pointer",
    },
    normalRow: {
      fontWeight: "normal",
      cursor: "pointer",
    },
  });

export function groupedFind(row: ConsolidationDetail, item: ConsolidationDetail) {
  return (
    item.invoicenumber == row.invoicenumber &&
    item.VarietyCode == row.VarietyCode &&
    item.GradeCode == row.GradeCode &&
    item.CountCode == row.CountCode &&
    item.PackCode == row.PackCode &&
    item.MarkCode == row.MarkCode &&
    item.InventoryCode == row.InventoryCode &&
    item.TargetMarket == row.TargetMarket &&
    item.target_shipping == row.target_shipping &&
    item.wk_weekcold == row.wk_weekcold &&
    item.arrivaldetail_qualityscore == row.arrivaldetail_qualityscore &&
    item.ld_fob == row.ld_fob &&
    item.producerid == row.producerid
  );
}

type GroupedUnapprovedTableProps = {
  data: ConsolidationDetail[];
  filter: string;
  loading: boolean;
  mainGridHeight: number;
  selectedRows: ConsolidationDetail[];
  groupChecked: boolean;
  groupOnChange(): void;
  handleUnapprovedRefresh(): void;
  handleGroupRowsSelected(rows: ConsolidationDetail[]): void;
  handleUpdateSelectedData(updated: ConsolidationDetail[]): void;
  handleProcessUnapproved(status: Status): void;
  handleClearUnapprovedSelectedRows(): void;
} & WithStyles<typeof styles>;

class GroupedUnapprovedTableUnstyled extends React.Component<GroupedUnapprovedTableProps, {}> {
  constructor(props) {
    super(props);
  }

  getGroupedUnapprovedData = (data: ConsolidationDetail[]) => {
    const groupedData = data.reduce((arr, row) => {
      const index = arr.findIndex((item) => groupedFind(row, item));

      if (index < 0) {
        arr.push({ ...row });
      } else {
        const exists = arr[index];
        arr[index] = {
          ...row,
          NoCartons: new BigNumber(exists.NoCartons).plus(new BigNumber(row.NoCartons)).toNumber(),
          palletSize: new BigNumber(exists.palletSize).plus(new BigNumber(row.palletSize)).toNumber(),
          final: new BigNumber(exists.final).plus(new BigNumber(row.final)).decimalPlaces(2).toNumber(),
        };
      }

      return arr;
    }, []);

    return groupedData;
  };

  handleGroupChanged = (grid: string, rows: ConsolidationDetail[], column: any) => {
    const selectedRows = this.props.selectedRows;

    const updated = this.props.data.map((item) => {
      const exists = rows.find((row) => groupedFind(row, item));
      if (exists) {
        const newRow = {
          ...item,
          exchangerate: exists.exchangerate,
          adjustment: exists.adjustment,
          comment: exists.comment,
          fobcost_final: exists.fobcost_final,
          coldcost_final: exists.coldcost_final,
          ppecb_final: exists.ppecb_final,
          cga_final: exists.cga_final,
          rowDirty: exists.rowDirty,
        };
        if (newRow.rowDirty) {
          selectedRows.push(newRow);
        }
        return newRow;
      }
      return item;
    });
    this.props.handleUpdateSelectedData(updated);
    this.props.handleGroupRowsSelected(selectedRows);
  };

  selectRow = (row) => {
    const selectedRows = [...this.props.selectedRows];
    const data = this.props.data.filter((item) => groupedFind(row, item));

    for (let i = 0; i < data.length; i++) {
      const item = data[i];
      const index = selectedRows.findIndex((sel) => sel.barcode_id == item.barcode_id);

      if (index < 0) {
        selectedRows.push(item);
      } else {
        selectedRows.splice(index, 1);
      }
    }
    this.props.handleGroupRowsSelected(selectedRows);
  };

  unapprovedRowRenderer = (props, classes) => {
    const { row } = props;
    const fontWeight = this.props.selectedRows && this.props.selectedRows.find((item) => groupedFind(item, row)) && classes.boldRow;
    const colorStyle = row.rowDirty == 1 ? classes.redRow : classes.blackRow;

    const doubleClick = () => {
      if (row.camend_status == Status.Unapproved) {
        this.selectRow(row);
      }
    };

    return <Row {...props} className={classnames(fontWeight, colorStyle)} onDoubleClickCapture={doubleClick} />;
  };

  unapprovedGroupedFilteredRows;
  handleUnapprovedGroupedFilteredRows = (rows: any[]) => {
    this.unapprovedGroupedFilteredRows = rows;
  };

  selectAll = () => {
    const selected = [];
    this.unapprovedGroupedFilteredRows.forEach((item) => {
      selected.push(...this.props.data.filter((row) => groupedFind(row, item)));
    });
    this.props.handleGroupRowsSelected(selected);
  };

  handleAccept = async () => {
    this.props.handleProcessUnapproved(Status.Unapproved);
  };

  handleProcess = async () => {
    this.props.handleProcessUnapproved(Status.Pending);
  };

  render() {
    const groupedData: any[] = this.getGroupedUnapprovedData(this.props.data);
    const { classes } = this.props;

    return (
      <div className={classes.toolbarWrapper}>
        <Toolbar className={classes.toolbar}>
          <Button type="button" style={{ marginRight: "10px" }} color="primary" variant="outlined" onClick={this.handleAccept}>
            Save
          </Button>
          <GreenButton type="button" color="primary" variant="contained" onClick={this.handleProcess} disabled={this.props.selectedRows.length === 0}>
            approve selected
          </GreenButton>
          <OrangeButton type="button" variant="contained" onClick={this.selectAll} disabled={this.props.data.length === 0}>
            select all
          </OrangeButton>
          <OrangeButton type="button" variant="contained" color="primary" onClick={this.props.handleClearUnapprovedSelectedRows} disabled={this.props.selectedRows.length === 0}>
            Clear Selected
          </OrangeButton>
          <FormControlLabel
            control={<Switch inputProps={{ "aria-label": "primary checkbox" }} checked={this.props.groupChecked} onChange={this.props.groupOnChange} />}
            label="Group"
          />
        </Toolbar>
        <Grid
          fill={true}
          data={groupedData}
          loading={this.props.loading}
          forceHeight={this.props.mainGridHeight}
          clearFilters={"consolidationselected"}
          handleRefresh={this.props.handleUnapprovedRefresh}
          rowRenderer={(props) => this.unapprovedRowRenderer(props, classes)}
          handleChange={this.handleGroupChanged}
          handleFilteredRows={this.handleUnapprovedGroupedFilteredRows}
          GridColumns={(data, filters, columnArrangement, columnsWidth) =>
            GroupedUnapprovedGridColumns(data, filters, columnArrangement, columnsWidth, this.selectRow, this.props.selectedRows, "consolidationselected")
          }
          totalRowColumns={["NoCartons", "palletSize"]}
          averageRowColumns={[
            "roe_etd",
            "exchangerate",
            "sellingprice",
            "sale_debit",
            "sale_credit",
            "sellingprice_final",
            "foreigncost",
            "nettsellprice",
            "ld_fob",
            "rtgc",
            "rtgczar",
            "ld_dip",
            "diff_rtg_dip",
            "adjustment",
            "comment",
            "fobcost_final",
            "coldcost_final",
            "ppecb_final",
            "cga_final",
            "margin",
            "totalcost",
          ]}
        />
      </div>
    );
  }
}

export default withStyles(styles)(GroupedUnapprovedTableUnstyled);
