import React from "react";
import CustomAutoCompleteFilter from "../lib/components/customdatagridfilter/CustomFilter";

import numeral from "numeral";
import { format } from "date-fns";
import parseISO from "date-fns/parseISO";
import { DateFormatter, FormatterNumber, FormatterNumber2Decimals, RightAlignColumnHeader } from "../lib/components/Formatters";
import { EnquiryLink, Selector } from "../lib/components/EnquiryLink";
import { SummaryNumber2Decimal } from "../lib/components/SummaryFormatter";

const cellWidthSmallX = 80;
const cellWidthSmall = 115;
const cellWidthMedium = 150;
const cellWidthMediumX = 180;
const cellWidthLarge = 220;

const defaultColumnProperties = {
  resizable: true,
  sortable: true,
  filterable: true,
};

let currentSpaceSelected = true;

export const GridColumnsPallets = (data, filters, arrangement, columnsWidth) => {
  let postValue = "";

  if (data) {
    if (currentSpaceSelected) {
      postValue = " ";
    }
    currentSpaceSelected = !currentSpaceSelected;
  }

  const columns = [
    {
      key: "Date Entered",
      name: "Date Entered" + postValue,
      width: cellWidthMedium,
      formatter: ({ row, column }) => format(parseISO(row[column.key]), "dd-MM-yyyy"),
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Date Entered"} data={data} section="dispatchselected" />;
      },
    },
    {
      key: "loadout_ref",
      name: "Loadout Ref" + postValue,
      width: cellWidthMediumX,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"loadout_ref"} data={data} section="dispatchselected" />;
      },
    },
    {
      key: "Orig Intake Waybill",
      name: "Orig Intake Waybill" + postValue,
      width: cellWidthMedium,
      formatter: (props) => <EnquiryLink {...props} selector={Selector.IntakeWaybill} />,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Orig Intake Waybill"} data={data} section="dispatchselected" />;
      },
    },
    {
      key: "Barcode",
      name: "Barcode" + postValue,
      width: cellWidthMediumX,
      formatter: (props) => <EnquiryLink {...props} selector={Selector.Barcode} />,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Barcode"} data={data} section="dispatchselected" />;
      },
      summaryFormatter: () => {
        return <div style={{ fontWeight: "bold" }}>TOTALS</div>;
      },
    },
    {
      key: "No Cartons",
      name: "No Cartons" + postValue,
      width: cellWidthMedium,
      formatter: FormatterNumber,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"No Cartons"} data={data} section="dispatchselected" />;
      },
      summaryFormatter: ({ row, column }) => {
        return <div style={{ textAlign: "right", fontWeight: "bold" }}>{numeral(row[column.key]).format("0,0")}</div>;
      },
      headerRenderer: RightAlignColumnHeader,
    },
    {
      key: "Pallet Size",
      name: "Pallet Size" + postValue,
      width: cellWidthMedium,
      formatter: FormatterNumber2Decimals,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Pallet Size"} data={data} section="dispatchselected" />;
      },
      summaryFormatter: ({ row, column }) => {
        return <div style={{ textAlign: "right", fontWeight: "bold" }}>{numeral(row[column.key]).format("0,0.00")}</div>;
      },
      headerRenderer: RightAlignColumnHeader,
    },
    {
      key: "Commodity Code",
      name: "Commodity Code" + postValue,
      width: cellWidthMedium,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Commodity Code"} data={data} section="dispatchselected" />;
      },
    },
    {
      key: "Variety Code",
      name: "Variety Code" + postValue,
      width: cellWidthSmall,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Variety Code"} data={data} section="dispatchselected" />;
      },
    },
    {
      key: "Grade Code",
      name: "Grade Code" + postValue,
      width: cellWidthSmall,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Grade Code"} data={data} section="dispatchselected" />;
      },
    },
    {
      key: "Count Code",
      name: "Count Code" + postValue,
      width: cellWidthSmall,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Count Code"} data={data} section="dispatchselected" />;
      },
    },
    {
      key: "Mark Code",
      name: "Mark Code" + postValue,
      width: cellWidthSmall,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Mark Code"} data={data} section="dispatchselected" />;
      },
    },
    {
      key: "Pack Code",
      name: "Pack Code" + postValue,
      width: cellWidthSmall,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Pack Code"} data={data} section="dispatchselected" />;
      },
    },
    {
      key: "Inventory Code",
      name: "Inventory Code" + postValue,
      width: cellWidthSmall,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Inventory Code"} data={data} section="dispatchselected" />;
      },
    },
    {
      key: "stock_weight",
      name: "Weight" + postValue,
      width: cellWidthSmall,
      formatter: FormatterNumber2Decimals,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"stock_weight"} data={data} section="dispatchselected" />;
      },
      summaryFormatter: SummaryNumber2Decimal,
      headerRenderer: RightAlignColumnHeader,
    },
    {
      key: "stock_grossweight",
      name: "Gross Weight" + postValue,
      width: cellWidthSmall,
      formatter: FormatterNumber2Decimals,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"stock_grossweight"} data={data} section="dispatchselected" />;
      },
      summaryFormatter: SummaryNumber2Decimal,
      headerRenderer: RightAlignColumnHeader,
    },
    {
      key: "Producer ID",
      name: "Producer ID" + postValue,
      width: cellWidthMedium,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Producer ID"} data={data} section="dispatchselected" />;
      },
    },
    {
      key: "Farm Number",
      name: "Farm Number" + postValue,
      width: cellWidthSmall,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Farm Number"} data={data} section="dispatchselected" />;
      },
    },
    {
      key: "Orchard",
      name: "Orchard" + postValue,
      width: cellWidthSmall,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Orchard"} data={data} section="dispatchselected" />;
      },
    },
    {
      key: "Picking Ref",
      name: "Picking Ref" + postValue,
      width: cellWidthSmall,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Picking Ref"} data={data} section="dispatchselected" />;
      },
    },
    {
      key: "PHC",
      name: "PHC" + postValue,
      width: cellWidthSmall,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"PHC"} data={data} section="dispatchselected" />;
      },
    },
    {
      key: "Inspector Name",
      name: "Inspector Name" + postValue,
      width: cellWidthSmall,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Inspector Name"} data={data} section="dispatchselected" />;
      },
    },
    {
      key: "Inspection Point",
      name: "Inspection Point" + postValue,
      width: cellWidthSmall,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Inspection Point"} data={data} section="dispatchselected" />;
      },
    },
    {
      key: "Inspection Date",
      name: "Inspection Date" + postValue,
      width: cellWidthMedium,
      formatter: ({ row, column }) => format(parseISO(row[column.key]), "dd-MM-yyyy"),
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Inspection Date"} data={data} section="dispatchselected" />;
      },
    },
    {
      key: "Target Region",
      name: "Target Region" + postValue,
      width: cellWidthSmall,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Target Region"} data={data} section="dispatchselected" />;
      },
    },
    {
      key: "Target Market",
      name: "Target Market" + postValue,
      width: cellWidthSmall,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Target Market"} data={data} section="dispatchselected" />;
      },
    },
    {
      key: "Target Country",
      name: "Target Country" + postValue,
      width: cellWidthSmall,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Target Country"} data={data} section="dispatchselected" />;
      },
    },
    {
      key: "Weighing Location",
      name: "Weighing Location" + postValue,
      width: cellWidthMedium,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Weighing Location"} data={data} section="dispatchselected" />;
      },
    },
    {
      key: "Location Code",
      name: "Location Code" + postValue,
      width: cellWidthMedium,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Location Code"} data={data} section="dispatchselected" />;
      },
    },
    {
      key: "UserName",
      name: "UserName" + postValue,
      width: cellWidthSmall,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"UserName"} data={data} section="dispatchselected" />;
      },
    },
    // {
    //   key: "Pallet ID",
    //   name: "Pallet ID" + postValue,
    //   width: cellWidthMedium,
    //   filterRenderer: (e) => {
    //     return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Pallet ID"} data={data} section="dispatchselected"/>;
    //   },
    // },
    // {
    //   key: "Intake ID",
    //   name: "Intake ID" + postValue,
    //   width: cellWidthMedium,
    //   filterRenderer: (e) => {
    //     return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Intake ID"} data={data} section="dispatchselected"/>;
    //   },
    // },
    // {
    //   key: "Time Entered",
    //   name: "Time Entered" + postValue,
    //   width: cellWidthMedium,
    //   filterRenderer: (e) => {
    //     return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Time Entered"} data={data} section="dispatchselected"/>;
    //   },
    // },
    // {
    //   key: "Date Dispatched",
    //   name: "Date Dispatched" + postValue,
    //   width: cellWidthMedium,
    //   filterRenderer: (e) => {
    //     return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Date Dispatched"} data={data} section="dispatchselected"/>;
    //   },
    // },
    // {
    //   key: "Time Dispatched",
    //   name: "Time Dispatched" + postValue,
    //   width: cellWidthMedium,
    //   filterRenderer: (e) => {
    //     return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Time Dispatched"} data={data} section="dispatchselected"/>;
    //   },
    // },
    // {
    //   key: "Processing",
    //   name: "Processing" + postValue,
    //   width: cellWidthMedium,
    //   filterRenderer: (e) => {
    //     return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Processing"} data={data} section="dispatchselected"/>;
    //   },
    // },
    {
      key: "Intake Type",
      name: "Intake Type" + postValue,
      width: cellWidthMedium,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Intake Type"} data={data} section="dispatchselected" />;
      },
    },
    {
      key: "Temperature",
      name: "Temperature" + postValue,
      width: cellWidthSmall,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Temperature"} data={data} section="dispatchselected" />;
      },
    },
    // {
    //   key: "PalTrak",
    //   name: "PalTrak" + postValue,
    //   width: cellWidthMedium,
    //   filterRenderer: (e) => {
    //     return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"PalTrak"} data={data} section="dispatchselected"/>;
    //   },
    // },
    {
      key: "Organization",
      name: "Organization" + postValue,
      width: cellWidthSmallX,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Organization"} data={data} section="dispatchselected" />;
      },
    },
    {
      key: "Channel",
      name: "Channel" + postValue,
      width: cellWidthSmallX,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Channel"} data={data} section="dispatchselected" />;
      },
    },
    {
      key: "Pallet Base Type",
      name: "Pallet Base Type" + postValue,
      width: cellWidthSmallX,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Pallet Base Type"} data={data} section="dispatchselected" />;
      },
    },
    {
      key: "Sequence Number",
      name: "Sequence Number" + postValue,
      width: cellWidthSmallX,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Sequence Number"} data={data} section="dispatchselected" />;
      },
    },
    {
      key: "Cold Date",
      name: "Cold Date" + postValue,
      width: cellWidthMedium,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Cold Date"} data={data} section="dispatchselected" />;
      },
    },
    // {
    //   key: "Product Group",
    //   name: "Product Group" + postValue,
    //   width: cellWidthMedium,
    //   filterRenderer: (e) => {
    //     return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Product Group"} data={data} section="dispatchselected"/>;
    //   },
    // },
    // {
    //   key: "Product Char",
    //   name: "Product Char" + postValue,
    //   width: cellWidthMedium,
    //   filterRenderer: (e) => {
    //     return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Product Char"} data={data} section="dispatchselected"/>;
    //   },
    // },
    // {
    //   key: "Stock Pool",
    //   name: "Stock Pool" + postValue,
    //   width: cellWidthMedium,
    //   filterRenderer: (e) => {
    //     return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Stock Pool"} data={data} section="dispatchselected"/>;
    //   },
    // },
    {
      key: "Country",
      name: "Country" + postValue,
      width: cellWidthSmallX,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Country"} data={data} section="dispatchselected" />;
      },
    },
    {
      key: "SSCC",
      name: "SSCC" + postValue,
      width: cellWidthMedium,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"SSCC"} data={data} section="dispatchselected" />;
      },
    },
    // {
    //   key: "Last Invoice Date",
    //   name: "Last Invoice Date" + postValue,
    //   width: cellWidthMedium,
    //   filterRenderer: (e) => {
    //     return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Last Invoice Date"} data={data} section="dispatchselected"/>;
    //   },
    // },
    // {
    //   key: "Orig Intake Date",
    //   name: "Orig Intake Date" + postValue,
    //   width: cellWidthMedium,
    //   filterRenderer: (e) => {
    //     return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Orig Intake Date"} data={data} section="dispatchselected"/>;
    //   },
    // },
    // {
    //   key: "Sell By",
    //   name: "Sell By" + postValue,
    //   width: cellWidthMedium,
    //   filterRenderer: (e) => {
    //     return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Sell By"} data={data} section="dispatchselected"/>;
    //   },
    // },
    {
      key: "Price",
      name: "Price" + postValue,
      width: cellWidthSmall,
      formatter: FormatterNumber2Decimals,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Price"} data={data} section="dispatchselected" />;
      },
    },
    // {
    //   key: "Allocated",
    //   name: "Allocated" + postValue,
    //   width: cellWidthMedium,
    //   filterRenderer: (e) => {
    //     return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Allocated"} data={data} section="dispatchselected"/>;
    //   },
    // },
    // {
    //   key: "Ref No",
    //   name: "Ref No" + postValue,
    //   width: cellWidthMedium,
    //   filterRenderer: (e) => {
    //     return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Ref No"} data={data} section="dispatchselected"/>;
    //   },
    // },
    // {
    //   key: "Intake Waybill",
    //   name: "Intake Waybill" + postValue,
    //   width: cellWidthMedium,
    //   filterRenderer: (e) => {
    //     return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Intake Waybill"} data={data} section="dispatchselected"/>;
    //   },
    // },
    {
      key: "Intake Pallet ID",
      name: "Intake Pallet ID" + postValue,
      width: cellWidthSmallX,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Intake Pallet ID"} data={data} section="dispatchselected" />;
      },
    },
    // {
    //   key: "Trader ID",
    //   name: "Trader ID" + postValue,
    //   width: cellWidthMedium,
    //   filterRenderer: (e) => {
    //     return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Trader ID"} data={data} section="dispatchselected"/>;
    //   },
    // },
    // {
    //   key: "Consignee ID",
    //   name: "Consignee ID" + postValue,
    //   width: cellWidthMedium,
    //   filterRenderer: (e) => {
    //     return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Consignee ID"} data={data} section="dispatchselected"/>;
    //   },
    // },
    // {
    //   key: "Inspection Time",
    //   name: "Inspection Time" + postValue,
    //   width: cellWidthMedium,
    //   filterRenderer: (e) => {
    //     return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Inspection Time"} data={data} section="dispatchselected"/>;
    //   },
    // },
    // {
    //   key: "Orig Location Code",
    //   name: "Orig Location Code" + postValue,
    //   width: cellWidthMedium,
    //   filterRenderer: (e) => {
    //     return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Orig Location Code"} data={data} section="dispatchselected"/>;
    //   },
    // },
    // {
    //   key: "Production ID",
    //   name: "Production ID" + postValue,
    //   width: cellWidthMedium,
    //   filterRenderer: (e) => {
    //     return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Production ID"} data={data} section="dispatchselected"/>;
    //   },
    // },
    // {
    //   key: "From Location Code",
    //   name: "From Location Code" + postValue,
    //   width: cellWidthMedium,
    //   filterRenderer: (e) => {
    //     return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"From Location Code"} data={data} section="dispatchselected"/>;
    //   },
    // },
    {
      key: "Seal Number",
      name: "Seal Number" + postValue,
      width: cellWidthMedium,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Seal Number"} data={data} section="dispatchselected" />;
      },
    },
    // {
    //   key: "To Location Code",
    //   name: "To Location Code" + postValue,
    //   width: cellWidthMedium,
    //   filterRenderer: (e) => {
    //     return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"To Location Code"} data={data} section="dispatchselected"/>;
    //   },
    // },
    {
      key: "Receiver",
      name: "Receiver" + postValue,
      width: cellWidthMedium,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Receiver"} data={data} section="dispatchselected" />;
      },
    },
    // {
    //   key: "Pre Allocated",
    //   name: "Pre Allocated" + postValue,
    //   width: cellWidthMedium,
    //   filterRenderer: (e) => {
    //     return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Pre Allocated"} data={data} section="dispatchselected"/>;
    //   },
    // },
    {
      key: "Principal",
      name: "Principal" + postValue,
      width: cellWidthSmallX,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Principal"} data={data} section="dispatchselected" />;
      },
    },
    {
      key: "Season",
      name: "Season" + postValue,
      width: cellWidthSmallX,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Season"} data={data} section="dispatchselected" />;
      },
    },
    {
      key: "Transaction Date",
      name: "Transaction Date" + postValue,
      width: cellWidthMedium,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Transaction Date"} data={data} section="dispatchselected" />;
      },
    },
    {
      key: "Orig Inspection Date",
      name: "Orig Inspection Date" + postValue,
      width: cellWidthMedium,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Orig Inspection Date"} data={data} section="dispatchselected" />;
      },
    },
    {
      key: "UPN",
      name: "UPN" + postValue,
      width: cellWidthMedium,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"UPN"} data={data} section="dispatchselected" />;
      },
    },
    {
      key: "Actual Variety",
      name: "Actual Variety" + postValue,
      width: cellWidthSmallX,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Actual Variety"} data={data} section="dispatchselected" />;
      },
    },
    // {
    //   key: "Tran Date",
    //   name: "Tran Date" + postValue,
    //   width: cellWidthMedium,
    //   filterRenderer: (e) => {
    //     return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Tran Date"} data={data} section="dispatchselected"/>;
    //   },
    // },
    // {
    //   key: "Tran User",
    //   name: "Tran User" + postValue,
    //   width: cellWidthMedium,
    //   filterRenderer: (e) => {
    //     return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Tran User"} data={data} section="dispatchselected"/>;
    //   },
    // },
    // {
    //   key: "Tran Edit Date",
    //   name: "Tran Edit Date" + postValue,
    //   width: cellWidthMedium,
    //   filterRenderer: (e) => {
    //     return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Tran Edit Date"} data={data} section="dispatchselected"/>;
    //   },
    // },
    // {
    //   key: "Tran Edit User",
    //   name: "Tran Edit User" + postValue,
    //   width: cellWidthMedium,
    //   filterRenderer: (e) => {
    //     return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Tran Edit User"} data={data} section="dispatchselected"/>;
    //   },
    // },
    {
      key: "Weighing Date Time",
      name: "Weighing Date Time" + postValue,
      width: cellWidthMedium,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Weighing Date Time"} data={data} section="dispatchselected" />;
      },
    },
    {
      key: "VAT Code",
      name: "VAT Code" + postValue,
      width: cellWidthSmallX,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"VAT Code"} data={data} section="dispatchselected" />;
      },
    },
    {
      key: "Fail Safe ID",
      name: "Fail Safe ID" + postValue,
      width: cellWidthSmallX,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Fail Safe ID"} data={data} section="dispatchselected" />;
      },
    },
    // {
    //   key: "Pack Date",
    //   name: "Pack Date" + postValue,
    //   width: cellWidthMedium,
    //   filterRenderer: (e) => {
    //     return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Pack Date"} data={data} section="dispatchselected"/>;
    //   },
    // },
  ].map((c) => ({ ...c, ...defaultColumnProperties }));

  const columnsArranged = [];
  if (arrangement && arrangement.length > 0) {
    arrangement.map((item) => {
      const indexOfItem = columns.indexOf(columns.find((col) => col.name.trim() == item.toString().trim()));
      if (indexOfItem != -1) {
        if (columns[indexOfItem].name.trim() != "") {
          columnsArranged.push(columns[indexOfItem]);
        }
      }
    });
  } else {
    columns.map((colItem) => {
      columnsArranged.push(colItem);
    });
  }

  if (columnsWidth && columnsWidth.length > 0) {
    for (let index = 0; index < columnsArranged.length; index++) {
      const element = columnsArranged[index];
      const colItem = columnsWidth.find((colwitem) => colwitem.name.trim() == element.name.trim());
      if (colItem && element.name.trim().length != 0) {
        element.width = colItem.width;
      } else if (element.key == "status") {
        element.width = cellWidthSmallX;
      }
    }
  }

  return columnsArranged;
};

export function getCellActionsPallets(column, row) {
  return null;
}
