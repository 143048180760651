import jsPDF from "jspdf";

import format from "date-fns/format";
import numeral from "numeral";
import BigNumber from "bignumber.js";

import { image as logo } from "./elements/logo";
import { PTSans } from "./elements/font";
import { ProducerType } from "../lib/api/producer";
import { get } from "./helpers";
import { CompanyDetailsType } from "../lib/api/companydetails";
import { ConsolidationFinalDetailType, ConsolidationGroupedProducerType } from "../lib/api/consolidation";
import { AdditionalDetails } from "./utils/Types";

let pdf,
  fromTop = 1,
  page = 1,
  fromLeft = 9.0,
  lastLinewritten = 0,
  pages = [],
  fullWidth = 0,
  fullHeight = 0;

const lineHeight = 0.5,
  grey = 210;

const setFontBold = () => {
  pdf.setFontStyle("bold");
  pdf.setFont("helvetica"); // set font
};

const setFontNormal = () => {
  pdf.setFontStyle("normal");
  pdf.setFont("PTSans"); // set font
};

const InitialisePDF = () => {
  pdf = new jsPDF({
    orientation: "portrait",
    unit: "cm",
  });

  fullWidth = pdf.internal.pageSize.getWidth();
  fullHeight = pdf.internal.pageSize.getHeight();
  page = 1;
  pages = [page];
  fromTop = 2;
  fromLeft = 1;

  pdf.addFileToVFS("PTSans.ttf", PTSans);
  pdf.addFont("PTSans.ttf", "PTSans", "normal");

  pdf.setFont("PTSans");
  page = 1;

  fromTop = 1;
  fromLeft = 1.2;

  pdf.setLineWidth(0.01);
};

const detailColumnHeaders = [
  { dataKey: "intakewaybill", heading: "Waybill", width: 1.9, alignment: "left" },
  { dataKey: "barcode", heading: "Barcode", width: 3.4, alignment: "left" },
  { dataKey: "commodityCode", heading: "Comm", width: 1, alignment: "center" },
  { dataKey: "varietyCode", heading: "Var", width: 1, alignment: "center" },
  { dataKey: "gradeCode", heading: "Grade", width: 1, alignment: "center" },
  { dataKey: "countCode", heading: "Count", width: 1, alignment: "center" },
  { dataKey: "packCode", heading: "Pack", width: 1, alignment: "center" },
  { dataKey: "markCode", heading: "Brand", width: 1.2, alignment: "center" },
  { dataKey: "NoCartons", heading: "Cartons", width: 1.2, alignment: "center" },
  { dataKey: "palletSize", heading: "Pallets", width: 1.2, alignment: "center" },
  { dataKey: "advance", heading: "Advance", width: 1.5, alignment: "center" },
  { dataKey: "final", heading: "Final", width: 1.2, alignment: "center" },
  { dataKey: "total", heading: "Nett", width: 1.5, alignment: "center" },
  { dataKey: "totalPerPallet", heading: "Nett/Plt", width: 2.25, alignment: "right" },
];

const detailRowHeaders = [...detailColumnHeaders];

const newPage = () => {
  pdf.addPage();

  page++;
  pages.push(page);
  pdf.setPage(page);
};

const headerColumn1 = () => {
  return 3.4;
};
const headerColumn2 = () => {
  return fullWidth / 2 - 1.5;
};
const headerColumn3 = () => {
  return fullWidth - 3.6;
};

const addImage = (imageData: string, format: string, fromLeft: number, fromTop: number, width: number, height: number, alias?, compression?, rotation?): void => {
  pdf.addImage(imageData, format, fromLeft, fromTop, width, height, alias, compression, rotation);
};

const newLine = (howFar: number = 0.5): void => {
  fromTop += howFar;
};

const createHeader = (companyDetails: CompanyDetailsType): void => {
  const imageWidth = 5;
  const imageHeight = 1.5;

  fromTop = 1;
  fromLeft = 1;

  addImage(logo, "PNG", (fullWidth - imageWidth) / 2 - 1, fromTop + 0.5, imageWidth, imageHeight);
  pdf.setFontSize(11);
  setFontBold();
  pdf.text(get("name", companyDetails, ""), fromLeft, fromTop, "left");

  pdf.setFontSize(12);
  setFontBold();

  pdf.text("REMITTANCE ADVICE", fullWidth / 2 - 1, fromTop + 2.5, "center");
  pdf.text("FINAL PAYMENT ON SHIPMENTS", fullWidth / 2 - 1, fromTop + 3.1, "center");

  setFontNormal();
  pdf.setFontSize(8);

  newLine(0.4);
  pdf.text(get("address1", companyDetails, ""), fromLeft, fromTop, "left");

  newLine(0.4);
  pdf.text(get("address2", companyDetails, ""), fromLeft, fromTop, "left");

  newLine(0.4);
  pdf.text(get("address3", companyDetails, ""), fromLeft, fromTop, "left");

  newLine(0.4);
  pdf.text(get("address4", companyDetails, ""), fromLeft, fromTop, "left");

  newLine(0.4);
  pdf.text(get("address5", companyDetails, ""), fromLeft, fromTop, "left");

  fromLeft = headerColumn3() - 1;

  fromTop = 1;

  pdf.text("Tel No:", fromLeft, fromTop, "right");
  pdf.text(get("telephoneNumber", companyDetails, ""), fromLeft + 0.2, fromTop, "left");

  newLine(0.4);
  pdf.text("Fax No:", fromLeft, fromTop, "right");
  pdf.text(get("faxNumber", companyDetails, ""), fromLeft + 0.2, fromTop, "left");

  newLine(0.4);
  pdf.text("E-mail:", fromLeft, fromTop, "right");
  pdf.text(get("email", companyDetails, ""), fromLeft + 0.2, fromTop, "left");

  newLine(0.4);
  pdf.text("Vat No:", fromLeft, fromTop, "right");
  pdf.text(get("vatno", companyDetails, ""), fromLeft + 0.2, fromTop, "left");

  newLine(0.4);
  pdf.text("Reg No:", fromLeft, fromTop, "right");
  pdf.text(get("regno", companyDetails, ""), fromLeft + 0.2, fromTop, "left");

  newLine(0.4);
  pdf.text("Customs Code:", fromLeft, fromTop, "right");
  pdf.text(get("customscode", companyDetails, ""), fromLeft + 0.2, fromTop, "left");
};

const addressLineArray = (address: string, arr = []): Array<string> => {
  if (!address) {
    return null;
  }

  let words = (address || "").split(" ");

  if (address.length > 42) {
    let line = "";
    let rem = address.split(" ");

    for (let i = 0; i < words.length; i++) {
      const currLine = line + " " + words[i];

      if (currLine.length >= 42) {
        break;
      } else {
        rem.splice(0, 1);
        line = currLine.trim();
      }
    }

    arr.push(line);

    if (rem.length > 0) {
      return addressLineArray(rem.join(" "), arr);
    } else {
      return arr;
    }
  } else {
    return [...arr, words.join(" ")];
  }
};

const setText = (value: string, align: string, numfmt?: string): void => {
  if (numfmt) {
    pdf.text(numeral(value).format(numfmt).toString() || "", fromLeft, fromTop, align);
  } else {
    pdf.text((value || "").toString(), fromLeft, fromTop, align);
  }
};

const printLineLeft = (label: string, value: string, posX?: number): void => {
  fromLeft = posX || 1.2;
  setFontBold();
  pdf.text(fromLeft, fromTop, label, "right");

  setFontNormal();
  pdf.text(fromLeft + 0.25, fromTop, value || "", "left");
};

const printLineRight = (label: string, value: string, posX?: number): void => {
  fromLeft = posX || 12.5;
  setFontBold();
  pdf.text(fromLeft, fromTop, label, "right");

  setFontNormal();
  pdf.text(fromLeft + 0.25, fromTop, value || "", "left");
};

const createProducerDetails = (header: ProducerType, additional_details: AdditionalDetails): void => {
  const LEFT_COL_START = 3.5;
  const { makeanote, amounts, idents, currency } = additional_details;
  const clientAddressArr = addressLineArray((header.physical_address || "").replaceAll("\n", " ")) || [];
  const outputAddress = `${clientAddressArr.length > 0 ? clientAddressArr.splice(0, 1) : ""}`.toUpperCase();

  newLine(2);
  const startRect = fromTop;

  printLineLeft("Producer :", header.name, LEFT_COL_START);
  printLineRight("Date :", format(new Date(additional_details.payment_date), "dd/MM/yyyy"));
  newLine();

  printLineLeft("", outputAddress, LEFT_COL_START);
  printLineRight("Remittance ID :", idents.toString());
  newLine();

  printLineLeft("Tel :", header.producer_telephone || "", LEFT_COL_START);
  printLineRight("Description :", makeanote.toString());
  newLine();

  printLineLeft("Fax :", header.fax_no || "", LEFT_COL_START);
  printLineRight("Amount:", `${currency} ${numeral(amounts).format("0,0.00")}`);
  newLine();
  printLineLeft("VAT No :", header.vat_no || "", LEFT_COL_START);

  pdf.setLineWidth(0.01);
  pdf.rect(1, startRect - 0.5, fullWidth - 2, fromTop - startRect + 0.75, "S");

  newLine(0.5);
  lastLinewritten = fromTop;
};

const getAdvanceValue = (row: ConsolidationFinalDetailType) => {
  return new BigNumber(row.advance || 0).dividedBy(new BigNumber(row.NoCartons || 0)).toNumber();
};

const getTotalValue = (advance: number, rtgc: number) => {
  return new BigNumber(rtgc).minus(new BigNumber(advance)).toNumber();
};

const createDetail = (detail: ConsolidationFinalDetailType[], invoiceDetail: ConsolidationGroupedProducerType): void => {
  const formattedDetail = detail.reduce((arr, item) => {
    if (item.invoicenumber == invoiceDetail.invoicenumber) {
      const rtgc = item.currency.toUpperCase() == "ZAR" ? item.rtgczar : item.rtgc;
      const advance = getAdvanceValue(item);
      arr.push({
        intakewaybill: item.IntakeWaybill,
        barcode: item.barcode,
        commodityCode: item.CommodityCode,
        varietyCode: item.VarietyCode,
        gradeCode: item.GradeCode,
        countCode: item.CountCode,
        packCode: item.PackCode,
        markCode: item.MarkCode,
        NoCartons: item.NoCartons,
        palletSize: item.palletSize,
        advance: advance,
        final: rtgc,
        total: getTotalValue(advance, rtgc),
        totalPerPallet: item.final,
        currency: item.currency,
      });
    }
    return arr;
  }, []);

  const { total_cartons, total_plt } = getCartonsAndPallets(formattedDetail);
  const heightOfPage = pdf.internal.pageSize.getHeight();

  if (lastLinewritten > heightOfPage - 5) {
    newPage();
    fromTop = 2;
  } else {
    fromTop = lastLinewritten + 1.25;
  }

  fromLeft = 12.5;
  pdf.setFontSize(10);

  printLineLeft("Vessel Name: ", invoiceDetail.vessel_name || "", 4);
  printLineRight("Sales Ref: ", invoiceDetail.invoicenumber || "", 14);
  newLine();

  printLineLeft("Container No: ", invoiceDetail.containerno || "", 4);
  printLineRight("Shipment Week: ", invoiceDetail.shipment_week || "", 14);
  newLine();

  printLineRight("Shipped Market: ", invoiceDetail.target_market || "", 14);
  newLine();

  printLineRight("Final Destination: ", invoiceDetail.final_destination || "", 14);

  fromTop += 1;
  fromLeft = 1.2;
  pdf.setFontSize(8);
  pdf.setLineWidth(0.01);

  // header columns
  for (const item of detailColumnHeaders) {
    setFontBold();

    setText(item.heading, item.alignment);
    fromLeft += item.width;
  }

  // line under header
  pdf.setFillColor(grey);
  pdf.rect(1.2, fromTop + 0.15, fullWidth - 2.5, 0.01, "DF");

  fromTop += 0.25;

  // printing detail
  for (const i in formattedDetail) {
    const item = formattedDetail[i];
    setFontNormal();
    fromLeft = 1.2;
    fromTop += 0.55;

    if (fromTop > heightOfPage - 2) {
      newPage();
      fromTop = 2;
    }

    for (const col of detailRowHeaders) {
      if (col.dataKey == "intakewaybill") {
        setFontBold();
      } else {
        setFontNormal();
      }

      if (col.dataKey == "palletSize" || col.dataKey == "advance" || col.dataKey == "total" || col.dataKey == "final" || col.dataKey == "totalPerPallet") {
        setText(item[col.dataKey], col.alignment, "0, 0.00");
      } else {
        setText(item[col.dataKey], col.alignment);
      }

      fromLeft += col.width;
    }

    // subtotals row
    if (Number(i) === formattedDetail.length - 1) {
      fromTop += 0.5;

      pdf.setLineWidth(0.01);
      pdf.setFontSize(10);
      // line above detail totals
      pdf.setFillColor(grey);
      pdf.rect(6, fromTop, fullWidth - 7.3, 0.01, "DF");

      fromTop += 0.5;
      fromLeft = 1.2;

      for (const col of detailRowHeaders) {
        setFontNormal();

        if (col.dataKey == "countCode") {
          setFontBold();
          setText("Total: ", col.alignment);
        }

        if (col.dataKey == "NoCartons") {
          setText(total_cartons + "", col.alignment, "0,0");
        }

        if (col.dataKey == "palletSize") {
          setText(total_plt + "", col.alignment, "0,0.00");
        }

        if (col.dataKey == "final") {
          setText(invoiceDetail.currency + "", col.alignment);
        }

        if (col.dataKey == "totalPerPallet") {
          setText(invoiceDetail.final + "", col.alignment, "0,0.00");
        }

        fromLeft += col.width;
      }

      // line below detail totals
      pdf.setFillColor(grey);
      pdf.rect(6, fromTop + 0.35, fullWidth - 7.3, 0.01, "DF");
    }
  }

  fromTop += 1;
  lastLinewritten = fromTop;
};

const printTotalLine = (value: string, alignment: string, left: number, bold = true): void => {
  if (bold) {
    setFontBold();
  } else {
    setFontNormal();
  }

  fromLeft = left;
  pdf.text(fromLeft, fromTop, value, alignment);
};

const createPaymentDetails = (additional_details: AdditionalDetails, adhocPayments: any[], showDetail = false): void => {
  fromTop += 1;
  fromLeft = 1;

  const heightOfPage = pdf.internal.pageSize.getHeight();
  if (fromTop > heightOfPage - 2) {
    newPage();
    fromTop = 2;
  }

  const currencyPos = fullWidth - 5;
  const { amounts, currency, total_cartons, total_plt } = additional_details;

  setFontBold();

  if (showDetail) {
    pdf.setFontSize(9);
    printTotalLine("CTNS", "left", fullWidth - 8.65);
    printTotalLine("PLTS", "left", fullWidth - 7.3);

    newLine(0.75);
    fromLeft = 1;
  }

  const startRect = fromTop;
  pdf.setFontSize(11);

  printTotalLine("TOTAL PRODUCER FINAL PAYMENT", "left", fromLeft + 0.25);
  if (showDetail) {
    printTotalLine(`${total_cartons}`, "left", fullWidth - 8.7);
    printTotalLine(`${numeral(total_plt).format("0,0.00")}`, "left", fullWidth - 7.2);
  }
  printTotalLine(`${currency}`, "right", currencyPos);
  printTotalLine(`${numeral(amounts).format("0,0.00")}`, "right", fullWidth - 2);

  adhocPayments.forEach((adhoc) => {
    fromLeft = 1;
    newLine(0.75);
    printTotalLine("AD-HOC PMT / (DEDUCTION):", "left", fromLeft + 0.25);
    printTotalLine(`${adhoc.code}`, "right", currencyPos);
    printTotalLine(`${numeral(adhoc.amount).format("0,0.00")}`, "right", fullWidth - 2);
  });

  // print line above total row
  pdf.setLineWidth(0.01);
  pdf.rect(1, fromTop + 0.2, fullWidth - 2, 0.001, "S");

  newLine(0.75);

  // final total
  printTotalLine("TOTAL PAID TO PRODUCER:", "left", 1.2);
  printTotalLine(`${currency}`, "right", currencyPos);

  const adhocTotal = adhocPayments.reduce((curr, item) => curr + item.amount, 0);
  const total = new BigNumber(adhocTotal).plus(new BigNumber(amounts)).toNumber();
  printTotalLine(`${numeral(total).format("0,0.00")}`, "right", fullWidth - 2);

  pdf.setLineWidth(0.01);
  pdf.rect(1, startRect - 0.5, fullWidth - 2, fromTop - startRect + 0.75, "S");

  lastLinewritten = fromTop;
};

const getCartonsAndPallets = (detail: ConsolidationFinalDetailType[]): { total_cartons: number; total_plt: number; total_amount: number } => {
  const { total_cartons, total_plt, total_amount } = detail.reduce(
    (acc, curr) => {
      return {
        ...acc,
        total_cartons: acc.total_cartons + curr.NoCartons,
        total_plt: acc.total_plt + curr.palletSize,
        total_amount: acc.total_amount + curr.final,
      };
    },
    { total_cartons: 0, total_plt: 0, total_amount: 0 },
  );

  return { total_cartons, total_plt, total_amount };
};

export const producerFinalRemittance = ({
  header,
  detail,
  selected,
  companyDetails,
  adhocPayments,
}: {
  header: ProducerType;
  detail: ConsolidationFinalDetailType[];
  selected: ConsolidationGroupedProducerType[];
  companyDetails: CompanyDetailsType;
  adhocPayments: any[];
}): void => {
  const { makeanote, idents, currency, payment_date } = selected.find((item) => item.idents) || {
    makeanote: "N/A",
    idents: "N/A",
    currency: "",
    payment_date: new Date(),
  };

  const { total_cartons, total_plt, total_amount } = getCartonsAndPallets(detail);

  const additional_details: AdditionalDetails = {
    makeanote,
    idents,
    currency,
    payment_date,
    total_cartons,
    total_plt,
    amounts: total_amount,
  };

  InitialisePDF();
  createHeader(companyDetails);
  createProducerDetails(header, additional_details);
  createPaymentDetails(additional_details, adhocPayments, true);

  const sortedData = selected.sort((a, b) => a.invoicenumber.localeCompare(b.invoicenumber));
  sortedData.forEach((item) => {
    createDetail(detail, item);
  });

  createPaymentDetails(additional_details, adhocPayments, true);

  const filename = `${format(new Date(payment_date), "yyMMdd")} ${header.name || ""} ${idents.toString() || ""} ${(makeanote || "").trim()}.pdf`;
  pdf.save(filename);
};
