import React from "react";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";

import { Redirect } from "react-router-dom";
import format from "date-fns/format";

import LinearProgress from "@material-ui/core/LinearProgress";
import CircularProgress from "@material-ui/core/CircularProgress";

import { getFileImport, getFileImportMappingTypes } from "../../lib/api/FileImport";
import { SnackMessage } from "../../lib/helpers/snackmessage";
import ImportGrid from "./importgrid";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      overflow: "hidden",
      width: "100%",
      height: "calc(100% - 65px)",
    },
  });

type ImportProps = {} & WithStyles<typeof styles>;

class LoadoutUnstyled extends React.Component<ImportProps> {
  state = {
    classes: undefined,
    data: [{}],
    loading: true,
    snackshow: undefined,
    snackmessage: undefined,
    snackcolor: undefined,
    requestOpenId: undefined,
    mappingTemplates: [],
  };

  constructor(props) {
    super(props);
    this.state.classes = props.classes;
  }

  componentDidMount() {
    this.loadData();
  }

  loadData = async () => {
    this.setState({ loading: true });
    const [result, templates] = await Promise.all([getFileImport(), getFileImportMappingTypes()]);
    const resultSorted = result.data.map((item) => ({ ...item, timestampFormatted: format(new Date(item.timestamp.replace("Z", "")), "yyyy-MM-dd") }));
    this.setState({
      mappingTemplates: [{ id: 0, templatename: "Default", created_at: new Date(), updated_at: new Date() }, ...templates.data],
      data: resultSorted,
      loading: false,
    });
  };

  onSnackClose = () => {
    this.setState({ snackshow: undefined, snackmessage: undefined });
  };

  onReload = async () => {
    return await this.loadData();
  };

  render() {
    if (this.state.requestOpenId) return <Redirect to={`/edi/fileImports/${this.state.requestOpenId}`} />;
    else
      return (
        <div className={this.state.classes.root}>
          {this.state.loading && <CircularProgress />}
          {this.state.snackshow && (
            <div>
              <SnackMessage isOpen={this.state.snackshow} message={this.state.snackmessage} color={this.state.snackcolor} handleClose={this.onSnackClose} />
            </div>
          )}
          {this.state.loading && (
            <div>
              <LinearProgress />
            </div>
          )}
          {!this.state.loading && <ImportGrid data={this.state.data} handleReload={this.onReload} mappingTemplates={this.state.mappingTemplates} />}
        </div>
      );
  }
}

export default withStyles(styles)(LoadoutUnstyled);
