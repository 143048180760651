import React from "react";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";
import { Redirect } from "react-router-dom";

import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import orange from "@material-ui/core/colors/orange";
import Badge from "@material-ui/core/Badge";

import ReactDataGrid, { Row } from "react-data-grid";
import { Toolbar } from "react-data-grid-addons";
import "react-splitter-layout/lib/index";
import "../../../lib/helpers/splitter.css";
import Splitter from "m-react-splitters";
import "m-react-splitters/lib/splitters.css";

import { DialogInformation } from "../../../lib/components/dialoginformation";
import Confirmation from "../../../lib/components/confirmation";
import GridColumnLayout from "../../../lib/components/gridlayout";
import ProducersFixedFilterChips from "../../../lib/components/filterchips";

import { SnackContext } from "../../../lib/context/SnackContext";

import { generateSortFn } from "../../../lib/helpers/generateSortFN";
import { removeFromSticky, getStickyFilters } from "../../../lib/helpers/stickyfilters";
import { updateUserProfile } from "../../../lib/helpers/stickyfilters";
import { generateGridFilteredRows } from "../../../lib/helpers/generateGridFilteredRows";
import { summaryRows, handleFilterChange, handleSortChange } from "../../../lib/helpers/grid";

import { GridColumns } from "./producersfixedtablesetupmain";
import { GridColumnsDetail } from "./producersfixedtablesetupdetail";

import { getProducersFullInvoiceByProducer, ProducersFullInvoiceByProducerType } from "../../../lib/api/producersfullinvoice";
import { producerSaleBarcodesByDispatchID } from "../../../lib/api/producersale";
import { producerfixedpaidhead, ProducerFixedPaidHeadType } from "../../../lib/api/producerfixedpaidhead";
import { producerfixedpaid, ProducerFixedPaidType, reverseProducerFixedPayment } from "../../../lib/api/producerfixedpaid";

import PaymentsFixedPay from "./paymentsfixedpay";
import classNames from "classnames";
import { getFinancialYearSelected } from "../../../lib/api/week";

import { GenerateErrorMessage } from "../../../lib/helpers/string_methods";
import { RedButton } from "../../../lib/components/ColorButtons";
import { BigNumber } from "bignumber.js";

import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Tooltip from "@material-ui/core/Tooltip";

import Refresh from "@material-ui/icons/Refresh";
import LayoutIcon from "@material-ui/icons/ViewColumn";
import { ClearFilterIcon } from "../../../icons/icons";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      height: "100%",
      width: "100%",
    },
    control: {
      paddingTop: "8px",
      paddingLeft: "0px",
      float: "right",
    },
    controlFloatLeft: {
      paddingTop: theme.spacing(1) * 3,
      float: "left",
      height: "10px",
    },
    controlButton: {
      paddingLeft: "10px",
      paddingBottom: "10px",
    },
    exportButton: {
      color: "white",
      backgroundColor: orange[700],
      "&:hover": {
        backgroundColor: orange[900],
      },
    },
    selectedHeader: {
      color: "white",
      backgroundColor: theme.palette.primary.main,
    },
    mainSplitterDiv: {
      height: "100%",
      width: "100%",
      marginTop: "60px",
      display: "grid",
    },
    rowActions: {
      "& .rdg-cell-action-menu": {
        position: "absolute",
        top: "calc(-60px - 24px)",
      },
    },
    greenRow: {
      color: "green",
    },
    boldRow: {
      fontWeight: "bold",
    },
    blackRow: {
      color: "black",
    },
    normalRow: {
      fontWeight: "normal",
    },
  });

type ProducersTableProps = { producer: string } & WithStyles<typeof styles>;

class ProducersTableUnstyled extends React.Component<ProducersTableProps, {}> {
  state = {
    producer: undefined,
    data: [{}],
    filters: {},
    filtersSelected: {},
    sorting: {},
    sortingSelected: {},
    selectedRows: [],
    selectedProducerFixed: undefined,
    dialoginformation: undefined,
    gridRef: undefined,
    gridRefSelected: undefined,
    minGridHeight: 0,
    minGridHeightSecond: 0,
    columnsArranged: [],
    columnsWidth: [],
    columnsArrangedselected: [],
    columnsWidthselected: [],
    clearedPhrase: false,
    loadingDetail: false,
    tableLayout: false,
    tableLayoutSelected: false,
    layoutItem: "producersfixed",
    widthItem: "producersfixedtablewidth",
    layoutItemselected: "producersfixedtablelayoutselected",
    widthItemselected: "producersfixedtablewidthselected",
    sortitem: "producersfixedtablesorting",
    sortitemselected: "producersfixedtablesortingselected",
    currentSortColumn: "",
    currentSortDirection: "",
    currentSelectedSortColumn: "",
    currentSelectedSortDirection: "",
    makePayment: undefined,
    loading: true,
    confirmReversePayment: undefined,
  };

  constructor(props) {
    super(props);
    this.state.producer = props.producer;
    this.state.gridRef = React.createRef();
    this.state.gridRefSelected = React.createRef();
  }

  static contextType = SnackContext;
  context!: React.ContextType<typeof SnackContext>;

  componentDidMount() {
    this.setGridHeights();

    this.loadData();

    const producertablelayout = localStorage.getItem(this.state.layoutItem);
    if (producertablelayout) {
      this.setState({ columnsArranged: JSON.parse(producertablelayout) }, () => {});
      updateUserProfile(JSON.parse(producertablelayout), this.state.layoutItem, "general");
    } else {
      const columnsToRender = GridColumns(undefined, undefined, undefined, undefined);
      const columnsArr = columnsToRender.map((column) => {
        return column.name;
      });

      this.setState({ columnsArranged: columnsArr }, () => {});
      updateUserProfile(columnsArr, this.state.layoutItem, "general");
    }

    const producertablewidth = localStorage.getItem(this.state.widthItem);
    if (producertablewidth) {
      this.setState({ columnsWidth: JSON.parse(producertablewidth) }, () => {});
      updateUserProfile(JSON.parse(producertablewidth), this.state.widthItem, "general");
    } else {
      const columnsToRender = GridColumns(undefined, undefined, undefined, undefined);
      const columnsArr = columnsToRender.map((column) => {
        return { name: column.name, width: column.width };
      });

      localStorage.setItem(this.state.widthItem, JSON.stringify(columnsArr));
      this.setState({ columnsWidth: columnsArr }, () => {});
      updateUserProfile(columnsArr, this.state.widthItem, "general");
    }

    const producertablelayoutSelected = localStorage.getItem(this.state.layoutItemselected);
    if (producertablelayoutSelected) {
      this.setState({ columnsArrangedselected: JSON.parse(producertablelayoutSelected) }, () => {});
      updateUserProfile(JSON.parse(producertablelayoutSelected), this.state.layoutItemselected, "general");
    } else {
      const columnsToRender = GridColumnsDetail(undefined, undefined, undefined, undefined);
      const columnsArr = columnsToRender.map((column) => {
        return column.name;
      });

      this.setState({ columnsArrangedselected: columnsArr }, () => {});
      updateUserProfile(columnsArr, this.state.layoutItemselected, "general");
    }

    const producertablewidthSelected = localStorage.getItem(this.state.widthItemselected);
    if (producertablewidthSelected) {
      this.setState({ columnsWidthselected: JSON.parse(producertablewidthSelected) }, () => {});
      updateUserProfile(JSON.parse(producertablewidthSelected), this.state.widthItemselected, "general");
    } else {
      const columnsToRender = GridColumnsDetail(undefined, undefined, undefined, undefined);
      const columnsArr = columnsToRender.map((column) => {
        return { name: column.name, width: column.width };
      });

      localStorage.setItem(this.state.widthItemselected, JSON.stringify(columnsArr));
      this.setState({ columnsWidthselected: columnsArr }, () => {});
      updateUserProfile(columnsArr, this.state.widthItemselected, "general");
    }

    this.setGridHeights();

    const producertablesorting = localStorage.getItem(this.state.sortitem);
    if (producertablesorting) {
      let sortingV = JSON.parse(producertablesorting);

      this.setState({ sorting: sortingV }, () => {
        this.setGridHeights();
      });
    }
  }

  componentDidUpdate() {
    if (this.state.gridRef && this.state.gridRef.grid) {
      this.state.gridRef.setState({ canFilter: true });
    }
    if (this.state.gridRefSelected && this.state.gridRefSelected.grid) {
      this.state.gridRefSelected.setState({ canFilter: true });
    }
  }

  calculateTotalFinalSum = (item: ProducersFullInvoiceByProducerType, payment: number) => {
    if (item[`sale_payment${payment}amount_remain`] == 0) {
      return item[`sale_payment${payment}amount`];
    }
    return new BigNumber(item.sale_total)
      .minus(item.amountCredit || 0)
      .plus(item.amountDebit || 0)
      .toNumber();
  };

  loadData() {
    this.setState({ loading: true });
    getProducersFullInvoiceByProducer(this.state.producer, getFinancialYearSelected())
      .then((result) => {
        if (result && result.length > 0) {
          const newData = [];
          result.map((item: ProducersFullInvoiceByProducerType) => {
            if (item.sale_payment1amount != 0) {
              newData.push({
                ...item,
                paymentnumber: 1,
                paymentvalue: item.sale_payment1amount,
                paymentoutstanding: item.sale_payment1amount_remain,
                paymentdue: item.sale_payment1date,
                paidAdjustments_makeanote: item.paidAdjustments1_makeanote,
                paidAdjustments_amount: item.paidAdjustments1_amount,
                paidAdjustments_ident: item.paidAdjustments1_ident,
                paidAdjustments_head_id: item.paidAdjustments1_head_id,
                paidAdjustments_payment_date: item.paidAdjustments1_payment_date,
                paidAdjustments_id: item.paidAdjustments1_id,
                amountDebit: item.sale_payment1amount_remain == 0 ? 0 : item.amountDebit,
                amountCredit: item.sale_payment1amount_remain == 0 ? 0 : item.amountCredit,
                sale_total: item.terms1Total,
                sale_totalfinal_sum: item.terms1TotalFin,
                // sale_totalfinal_sum: this.calculateTotalFinalSum(item, 1),
              });
            }
            if (item.sale_payment2amount != 0) {
              newData.push({
                ...item,
                paymentnumber: 2,
                paymentvalue: item.sale_payment2amount,
                paymentoutstanding: item.sale_payment2amount_remain,
                paymentdue: item.sale_payment2date,
                paidAdjustments_makeanote: item.paidAdjustments2_makeanote,
                paidAdjustments_amount: item.paidAdjustments2_amount,
                paidAdjustments_ident: item.paidAdjustments2_ident,
                paidAdjustments_head_id: item.paidAdjustments2_head_id,
                paidAdjustments_payment_date: item.paidAdjustments2_payment_date,
                paidAdjustments_id: item.paidAdjustments2_id,
                amountDebit: item.sale_payment1amount_remain != 0 || item.sale_payment2amount_remain == 0 ? 0 : item.amountDebit,
                amountCredit: item.sale_payment1amount_remain != 0 || item.sale_payment2amount_remain == 0 ? 0 : item.amountCredit,
                sale_total: item.terms2Total,
                sale_totalfinal_sum: item.terms2TotalFin,
                // sale_totalfinal_sum: this.calculateTotalFinalSum(item, 2),
              });
            }
            if (item.sale_payment3amount != 0) {
              newData.push({
                ...item,
                paymentnumber: 3,
                paymentvalue: item.sale_payment3amount,
                paymentoutstanding: item.sale_payment3amount_remain,
                paymentdue: item.sale_payment3date,
                paidAdjustments_makeanote: item.paidAdjustments3_makeanote,
                paidAdjustments_amount: item.paidAdjustments3_amount,
                paidAdjustments_ident: item.paidAdjustments3_ident,
                paidAdjustments_head_id: item.paidAdjustments3_head_id,
                paidAdjustments_payment_date: item.paidAdjustments3_payment_date,
                paidAdjustments_id: item.paidAdjustments3_id,
                amountDebit: item.sale_payment3amount_remain == 0 ? 0 : item.amountDebit,
                amountCredit: item.sale_payment3amount_remain == 0 ? 0 : item.amountCredit,
                sale_total: item.terms3Total,
                sale_totalfinal_sum: item.terms3TotalFin,
                // sale_totalfinal_sum: this.calculateTotalFinalSum(item, 3),
              });
            }
          });
          this.setState({ data: newData });
        }
        this.setState({ loading: false });
      })
      .catch((error) => {
        const err = GenerateErrorMessage(error, "failed to retrieve data");
        this.context.updateSnack({ show: true, color: "red", message: err });
        this.setState({ loading: false });
      });
  }

  getDetail(count, largeNumber = false) {
    const text = {
      __html: `
      <span style="${largeNumber ? "font-size: 21px" : ""}">${count}</span> Record(s) 
      <span style="font-size: 28px; line-height:0; "></span> 
      `,
    };
    return <span dangerouslySetInnerHTML={text} />;
  }

  getCount = (list, propName) => {
    if (list.length > 0) {
      const value = list
        .map((item) => {
          return item[propName];
        })
        .reduce((acc, curr) => {
          return Number(acc) + Number(curr);
        });
      return Number.isInteger(value) ? value.toFixed(2) : value;
    } else {
      return 0;
    }
  };

  handleFilterChange = (filter) => {
    const newFilters = handleFilterChange(filter, this.state.filters);
    this.setState({ filters: newFilters }, () => {
      this.setGridHeights();
    });
  };

  handleFilterChangeSelected = (filter) => {
    const newFilters = handleFilterChange(filter, this.state.filtersSelected);
    this.setState({ filtersSelected: newFilters }, () => {
      this.setGridHeights();
    });
  };

  getRows = (rows, filters, sorting) => {
    const result = generateGridFilteredRows(rows, filters);

    const fieldSorterData = Object.keys(sorting).map((item) => {
      return { name: item, reverse: sorting[item] == "ASC" ? false : true };
    });

    result.sort(generateSortFn(fieldSorterData));

    return result;
  };

  getValidFilterValues = (rows, columnId) => {
    const filterValues = rows
      .map((r) => r[columnId])
      .filter((item, i, a) => {
        return i === a.indexOf(item);
      })
      .sort((a, b) => a > b);
    return filterValues;
  };

  selectedClear = () => {
    this.setState({ selectedRows: [], selectedProducerFixed: undefined });
  };

  handleSort = (sortColumn, sortDirection) => {
    const sortingState = handleSortChange(this.state.sorting, sortColumn, sortDirection);

    this.setState({ sorting: sortingState, currentSortColumn: sortColumn, currentSortDirection: sortDirection }, () => {
      localStorage.setItem(this.state.sortitem, JSON.stringify(sortingState));
      updateUserProfile(sortingState, this.state.sortitem, "general");
    });
  };

  handleSortSelected = (sortColumn, sortDirection) => {
    const sortingState = handleSortChange(this.state.sortingSelected, sortColumn, sortDirection);

    this.setState({ sortingSelected: sortingState, currentSelectedSortColumn: sortColumn, currentSelectedSortDirection: sortDirection }, () => {
      localStorage.setItem(this.state.sortitemselected, JSON.stringify(sortingState));
      updateUserProfile(sortingState, this.state.sortitemselected, "general");
    });
  };

  handleRemoveSortEntry = (field) => {
    const collection = document.getElementsByClassName("pull-right");
    let i = 0;
    for (i = 0; i < collection.length; i++) {
      if (collection[i].innerHTML.length < 1) {
        continue;
      }
      (collection[i] as HTMLElement).innerHTML = "";
      break;
    }
    const sortingState = this.state.sorting;
    delete sortingState[field];
    this.setState({ sorting: sortingState }, () => {
      this.setGridHeights();
      localStorage.setItem(this.state.sortitem, JSON.stringify(sortingState));
      updateUserProfile(sortingState, this.state.sortitem, "general");
    });
  };

  handleRemoveFilterEntry = (key, value) => {
    removeFromSticky(key, value, "producersfixedpaymentmain");

    this.setState({ filters: {} }, () => {
      this.setGridHeights();
    });
  };

  clearFilters = (columnToClear = undefined) => {
    const section = "producersfixedpaymentmain";
    const sticky = getStickyFilters(section);

    localStorage.setItem(`stickyfilters_${section}`, JSON.stringify([]));
    updateUserProfile(sticky, undefined, section);
    this.setState({ filters: {} });
  };

  clearFiltersSelected = (columnToClear = undefined) => {
    const section = "producerinvoicepallets";
    const sticky = getStickyFilters(section);

    localStorage.setItem(`stickyfilters_${section}`, JSON.stringify([]));
    updateUserProfile(sticky, undefined, section);
    this.setState({ filtersSelected: {} });
  };

  getChipsHeight = () => {
    return (Object.keys(this.state.sorting).length > 0 ? 42 : 0) + (Object.keys(this.state.filters).length > 0 ? 42 * Object.keys(this.state.filters).length : 0);
  };

  setGridHeights = () => {
    const primarysplit = document.getElementsByClassName("splitter horizontal");
    this.setState({ minGridHeight: primarysplit[0].children[0].clientHeight - (80 + this.getChipsHeight()), minGridHeightSecond: primarysplit[0].children[2].clientHeight - 80 });
  };

  handleLayout = () => {
    this.setState({ tableLayout: true });
  };

  handleLayoutClose = () => {
    if (!this.phrase || this.phrase.length === 0) {
      this.setState({ tableLayout: false });
    } else {
      this.clearPhrase();
    }
  };

  handleLayoutConfirm = (columns) => {
    localStorage.setItem(this.state.layoutItem, JSON.stringify(columns));
    this.setState({ tableLayout: false, columnsArranged: columns }, () => {
      this.setGridHeights();
    });
  };

  handleLayoutSelected = () => {
    this.setState({ tableLayoutSelected: true });
  };

  handleLayoutCloseSelected = () => {
    this.setState({ tableLayoutSelected: false });
  };

  handleLayoutConfirmSelected = (columns) => {
    localStorage.setItem(this.state.layoutItemselected, JSON.stringify(columns));
    this.setState({ tableLayoutSelected: false, columnsArrangedselected: columns });
  };

  handleColumnResize = (index, width) => {
    const columnName = this.state.columnsArranged[index];
    const newArr = [...this.state.columnsWidth];
    const colwidthRecord = this.state.columnsWidth.findIndex((item) => item.name.trim() == columnName.trim());
    if (colwidthRecord >= 0) {
      newArr[colwidthRecord].width = width;
      localStorage.setItem(this.state.widthItem, JSON.stringify(newArr));
      updateUserProfile(newArr, this.state.widthItem, "general");
      this.setState({ columnsWidth: newArr });
    }
  };

  handleColumnResizeSelected = (index, width) => {
    const columnName = this.state.columnsArrangedselected[index];
    const newArr = [...this.state.columnsWidthselected];
    const colwidthRecord = this.state.columnsWidthselected.findIndex((item) => item.name.trim() == columnName.trim());
    if (colwidthRecord >= 0) {
      newArr[colwidthRecord].width = width;
      localStorage.setItem(this.state.widthItemselected, JSON.stringify(newArr));
      updateUserProfile(newArr, this.state.widthItemselected, "general");
      this.setState({ columnsWidthselected: newArr });
    }
  };

  filterTerms = (filterObj, term = "filterTerm") => {
    let val = 0;

    Object.keys(filterObj).map((key) => {
      val += filterObj[key][term].length;
    });

    return val;
  };

  phrase = "";
  setSearchPhrase = (phrase) => {
    this.phrase = phrase;
  };
  clearPhrase = () => {
    this.setSearchPhrase("");
    this.setState({ clearedPhrase: !this.state.clearedPhrase });
  };

  handleInfoClose = () => {
    this.setState({ dialoginformation: undefined });
  };

  handleTabChange = (_, newValue: number) => {
    this.setState({ tabValue: newValue }, () => {
      this.setGridHeights();
    });
  };

  handleAvailableProducersFixedDoubleClick = (row: ProducersFullInvoiceByProducerType) => {
    this.setState({ loadingDetail: true, selectedRows: [] });
    producerSaleBarcodesByDispatchID(row.sale_dispatch_id, this.state.producer).then((data) => {
      this.setState({ selectedRows: [...data] }, () => {});
    });
    this.setState({ loadingDetail: false, selectedProducerFixed: { ...row } }, () => {});
  };

  handleMakePayment = () => {
    this.setState({ makePayment: true });
  };

  handleMakePaymentConfirm = async (formData, selectedRows) => {
    if (formData) {
      let id = formData.id ? formData.id : 0;

      const dataHead: { data: ProducerFixedPaidHeadType } = {
        data: {
          ident: parseInt((formData.ident || "").replace("PP", "")),
          amount: formData.amount,
          makeanote: formData.makeanote,
          currency_id: formData.currency_id,
          payment_date: formData.payment_date,
        },
      };

      if (id > 0) {
        await producerfixedpaidhead.update(id, dataHead).catch((error) => {
          const err = GenerateErrorMessage(error, "Error updating head");
          this.context.updateSnack({ show: true, color: "red", message: err });
        });
      } else {
        const result = await producerfixedpaidhead.create(dataHead).catch((error) => {
          const err = GenerateErrorMessage(error, "Error creating head");
          this.context.updateSnack({ show: true, color: "red", message: err });
        });
        id = result[0];
      }

      for (let i = 0; i < selectedRows.length; i++) {
        const item: ProducersFullInvoiceByProducerType = selectedRows[i];
        const dataToSend: { data: ProducerFixedPaidType } = {
          data: {
            producerfixedpaidhead_id: id,
            producersale_id: item.sale_id,
            paymentnumber: item["paymentnumber"],
            amount: item["paymentvalue"],
            credit: item.amountCredit || 0,
            debit: item.amountDebit || 0,
          },
        };
        const paidid = item["paymentnumber"] == 1 ? item.paidAdjustments1_paid_id : item["paymentnumber"] == 2 ? item.paidAdjustments2_paid_id : item.paidAdjustments3_paid_id;
        if (paidid != 0) {
          await producerfixedpaid.update(paidid, dataToSend).catch((error) => {
            const err = GenerateErrorMessage(error, "Error updating invoice detail");
            this.context.updateSnack({ show: true, color: "red", message: err });
          });
        } else {
          await producerfixedpaid.create(dataToSend).catch((error) => {
            const err = GenerateErrorMessage(error, "Error creating invoice detail");
            this.context.updateSnack({ show: true, color: "red", message: err });
          });
        }
      }
    }

    this.setState({ makePayment: undefined });
    this.loadData();
  };

  rowRenderer = (props, classes) => {
    const { row } = props;
    const selectedrow = this.state.selectedProducerFixed;
    const color = row.paymentoutstanding != 0 ? classes.blackRow : classes.greenRow;
    const fontWeight = selectedrow && row.sale_id == selectedrow.sale_id && row.paymentnumber == selectedrow.paymentnumber ? classes.boldRow : classes.normalRow;

    return <Row {...props} onDoubleClickCapture={() => this.handleAvailableProducersFixedDoubleClick(row)} className={classNames(color, fontWeight)} />;
  };

  reversePayment = () => {
    this.setState({ confirmReversePayment: this.state.selectedProducerFixed.paidAdjustments_id });
  };

  reversePaymentConfirm = async () => {
    await reverseProducerFixedPayment(this.state.confirmReversePayment);
    this.loadData();
    this.setState({ selectedProducerFixed: undefined, confirmReversePayment: undefined });
  };

  reversePaymentClose = async () => {
    this.setState({ confirmReversePayment: undefined });
  };

  render() {
    const { classes } = this.props;

    const filteredRows = this.getRows(this.state.data, this.state.filters, this.state.sorting);
    const filteredRowsSelected = this.getRows(this.state.selectedRows, this.state.filtersSelected, this.state.sortingSelected);
    const columnsToRender = GridColumns(this.state.data, this.state.filters, this.state.columnsArranged, this.state.columnsWidth);
    const columnsToRenderSelected = GridColumnsDetail(this.state.selectedRows, this.state.filtersSelected, this.state.columnsArrangedselected, this.state.columnsWidthselected);

    const totalRowColumns = ["sale_total", "sale_totalfinal", "amountCredit", "amountDebit", "paymentvalue", "paymentoutstanding", "paidAdjustments_amount"];

    return (
      <div className={classes.root} id="producersfixedtable">
        {this.state.tableLayout && (
          <Confirmation isOpen={this.state.tableLayout} handleClose={this.handleLayoutClose} handleConfirm={() => {}} title="Editing Grid Layout" body={undefined}>
            <GridColumnLayout
              layoutItem={this.state.layoutItem}
              onSubmit={this.handleLayoutConfirm}
              onClose={this.handleLayoutClose}
              columns={this.state.columnsArranged}
              setSearchPhrase={this.setSearchPhrase}
              clearPhrase={this.state.clearedPhrase}
            />
          </Confirmation>
        )}
        {this.state.confirmReversePayment && (
          <Confirmation
            isOpen={true}
            handleClose={this.reversePaymentClose}
            handleConfirm={this.reversePaymentConfirm}
            title="Remove Payment"
            body="Are you sure you want to remove this payment?"
          />
        )}
        {this.state.tableLayoutSelected && (
          <Confirmation isOpen={this.state.tableLayoutSelected} handleClose={this.handleLayoutCloseSelected} handleConfirm={() => {}} title="Editing Grid Layout" body={undefined}>
            <GridColumnLayout
              layoutItem={this.state.layoutItem}
              onSubmit={this.handleLayoutConfirmSelected}
              onClose={this.handleLayoutCloseSelected}
              columns={this.state.columnsArrangedselected}
              setSearchPhrase={this.setSearchPhrase}
              clearPhrase={this.state.clearedPhrase}
            />
          </Confirmation>
        )}
        {this.state.dialoginformation && (
          <DialogInformation
            isOpen={true}
            handleClose={this.handleInfoClose}
            handleOK={this.handleInfoClose}
            title={this.state.dialoginformation.title}
            body={`${this.state.dialoginformation.body}`}
            showinput={false}
          />
        )}
        {this.state.makePayment && <PaymentsFixedPay selectedRow={this.state.selectedProducerFixed} gridData={filteredRows} handlePayProcess={this.handleMakePaymentConfirm} />}
        <span className={classes.controlFloatLeft}>
          <span style={{ color: "orange", fontWeight: "bold" }}>{this.getDetail(filteredRows.length - 1)}</span>
        </span>
        <div className={classes.control}>
          <RedButton variant="contained" onClick={this.reversePayment} disabled={!this.state.selectedProducerFixed || this.state.selectedProducerFixed.paidAdjustments_ident == 0}>
            Reverse Payment
          </RedButton>
          <Button variant="contained" color="primary" onClick={() => this.handleMakePayment()} style={{ marginRight: "10px", backgroundColor: "green" }}>
            {this.state.selectedProducerFixed && this.state.selectedProducerFixed.paidAdjustments_ident != 0 ? `change payment` : `make payment`}
          </Button>
          <Tooltip title="Clear Filters">
            <Button
              variant="contained"
              color="primary"
              onClick={() => this.clearFilters()}
              style={{ marginRight: "10px" }}
              disabled={Object.entries(this.state.filters).length === 0 && this.state.filters.constructor === Object}
            >
              <Badge badgeContent={this.filterTerms(this.state.filters)} color="secondary">
                <ClearFilterIcon />
              </Badge>
            </Button>
          </Tooltip>
          <Tooltip title="Refresh Data">
            <Button variant="contained" color="primary" onClick={() => this.loadData()} style={{ marginRight: "10px" }}>
              <Refresh />
            </Button>
          </Tooltip>
          <Tooltip title="Grid Layout">
            <Button variant="contained" color="primary" onClick={this.handleLayout} style={{ marginRight: "10px" }}>
              <LayoutIcon />
            </Button>
          </Tooltip>
        </div>
        <div id="main_splitter_div" className={classes.mainSplitterDiv}>
          <Splitter
            position="horizontal"
            primaryPaneMaxHeight="calc(100% - 150px)"
            primaryPaneHeight="calc(100% - 350px)"
            primaryPaneWidth="100%"
            dispatchResize={true}
            postPoned={true}
            onDragFinished={(e) => {
              this.setGridHeights();
            }}
          >
            <div id="primary_grid" style={{ marginLeft: "1px" }}>
              {this.state.loading && <CircularProgress />}
              <ReactDataGrid
                className="rdg-light"
                ref={(input) => (this.state.gridRef = input)}
                columns={columnsToRender}
                rows={filteredRows}
                enableFilterRow={true}
                rowHeight={30}
                headerRowHeight={35}
                style={{ position: "relative", height: this.state.minGridHeight, width: "100%", marginTop: "10px" }}
                onFiltersChange={(filter) => this.handleFilterChange(filter)}
                onSort={(sortColumn, sortDirection) => this.handleSort(sortColumn, sortDirection)}
                sortColumn={this.state.currentSortColumn}
                sortDirection={this.state.currentSortDirection as any}
                onColumnResize={this.handleColumnResize}
                summaryRows={summaryRows(filteredRows, totalRowColumns)}
                rowRenderer={(props) => this.rowRenderer(props, classes)}
              />
              <div>
                <ProducersFixedFilterChips
                  columns={columnsToRender}
                  filterData={this.state.filters}
                  handleFilterClear={null}
                  sortingData={this.state.sorting}
                  removesortingentry={this.handleRemoveSortEntry}
                  removeFilterEntry={this.handleRemoveFilterEntry}
                />
              </div>
            </div>
            <div id="secondary_grid">
              {this.state.loadingDetail && <CircularProgress />}
              <span className={classes.controlFloatLeft}>
                <span style={{ color: "orange", fontWeight: "bold" }}>{this.getDetail(filteredRowsSelected.length - 1)}</span>
              </span>
              <Toolbar enableFilter={false}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => this.selectedClear()}
                  style={{ marginLeft: "10px" }}
                  disabled={!(filteredRowsSelected && filteredRowsSelected.length > 0)}
                >
                  clear selected
                </Button>
                <Tooltip title="Clear Filters">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => this.clearFiltersSelected()}
                    style={{ marginRight: "10px", marginLeft: "10px" }}
                    disabled={Object.entries(this.state.filtersSelected).length === 0 && this.state.filtersSelected.constructor === Object}
                  >
                    <Badge badgeContent={this.filterTerms(this.state.filtersSelected)} color="secondary">
                      <ClearFilterIcon />
                    </Badge>
                  </Button>
                </Tooltip>
                <Tooltip title="Grid Layout">
                  <Button variant="contained" color="primary" onClick={this.handleLayoutSelected} style={{ marginRight: "10px" }}>
                    <LayoutIcon />
                  </Button>
                </Tooltip>
              </Toolbar>
              <ReactDataGrid
                className="rdg-light"
                ref={(input) => (this.state.gridRefSelected = input)}
                columns={columnsToRenderSelected}
                rows={filteredRowsSelected}
                enableFilterRow={true}
                rowHeight={30}
                headerRowHeight={35}
                style={{ position: "relative", height: this.state.minGridHeightSecond, width: "100%", marginTop: "10px" }}
                onFiltersChange={(filter) => this.handleFilterChangeSelected(filter)}
                onSort={(sortColumn, sortDirection) => this.handleSortSelected(sortColumn, sortDirection)}
                sortColumn={this.state.currentSelectedSortColumn}
                sortDirection={this.state.currentSelectedSortDirection as any}
                onColumnResize={this.handleColumnResize}
                summaryRows={summaryRows(filteredRows, totalRowColumns)}
              />
            </div>
          </Splitter>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(ProducersTableUnstyled);
