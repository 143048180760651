import { client, request } from "./_base";
import { config } from "../../config";

const endpoint = "template";

// extended methods
export const FindByFilter = (tid, cls) => {
  return request(`${endpoint}/templateFiltered/${tid}/${cls}`, {
    method: "GET"
  })
    .then(result => result.json())
    .then(({ data }) => {
      return data;
    });
};

export const AllForVariety = (varietyId) => {
  return request(`${endpoint}/allForVariety/${varietyId}`, {
    method: "GET"
  })
    .then(result => result.json())
    .then(({ data }) => {
      return data;
    });
};

// extended methods
export const templatesfull = () => {
  return request(`${endpoint}/ex/templatefull`, {
    method: "GET"
  })
    .then(result => result.json())
    .then(({ data }) => {
      return data;
    });
};

export const templatesfullbyvar = (varid) => {
  return request(`${endpoint}/ex/templatefullvar/${varid}`, {
    method: "GET"
  })
    .then(result => result.json())
    .then(({ data }) => {
      return data;
    });
};

export const templates = {
  ...client(endpoint, item => ({
    ...item,
  })),
  FindByFilter: FindByFilter
};

