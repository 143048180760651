import React from "react";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";

import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";

import IconCheck from "@material-ui/icons/Check";
import IconCancel from "@material-ui/icons/Cancel";

import { Form, Field } from "react-final-form";
import { TextField, Select } from "final-form-material-ui";

import { GridColumns, getCellActions } from "./companydetailsgridsetup";

import { DialogInformation } from "../../lib/components/dialoginformation";
import Grid from "../../lib/components/grid";

import { companyDetails, CompanyDetailsType } from "../../lib/api/companydetails";
import { companyDetailsBanking, CompanyDetailsBankingType } from "../../lib/api/companydetailsbanking";
import { currency, CurrencyType } from "../../lib/api/currency";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1),
    },
    tableRowFieldTitle: {
      paddingTop: theme.spacing(1),
      paddingRight: theme.spacing(1) * 2,
      marginTop: theme.spacing(1),
      textAlign: "right",
      minWidth: "100px",
    },
    tableRowFieldDataText: {
      paddingTop: theme.spacing(0.5),
      textAlign: "left",
      width: "400px",
    },
    tableCellsValue: {
      width: "250px",
      textAlign: "left",
      marginTop: theme.spacing(1),
    },
    tableCellsValueSelect: {
      textAlign: "left",
      width: "250px",
    },
  });
type CompanyDetailsBankingEditFormProps = { id: number; confirm: () => void } & WithStyles<typeof styles>;

class CompanyDetailsBankingEditFormUnstyled extends React.Component<CompanyDetailsBankingEditFormProps, any> {
  state = {
    id: undefined,
    classes: undefined,
    loading: false,
    dialoginformation: undefined,
    companydetailsbanking: undefined,
    currencies: [],
    currenciesCombo: [],
    handleConfirm: undefined,
  };

  constructor(props) {
    super(props);
    this.state.classes = props.classes;

    this.state.id = props.id;
    this.state.handleConfirm = props.confirm;
  }

  componentDidMount() {
    this.getItem();
  }

  getItem = async () => {
    const currencies = await currency.all();
    const currenciesCombo = currencies.map((item) => {
      return { value: item.id, display: item.code };
    });
    const resultCompBanking = await companyDetailsBanking.single(this.state.id);
    this.setState({ companydetailsbanking: resultCompBanking, currencies, currenciesCombo }, () => {});
  };

  handleSubmit = (values) => {
    let id = values["id"];
    const bankingData: CompanyDetailsBankingType = {
      companydetails_id: 1,
      currency_id: values.currency_id,
      accountholder: values.accountholder,
      accountholderaddress: values.accountholderaddress,
      bank: values.bank,
      bankaddress: values.bankaddress,
      branchcode: values.branchcode,
      accounttype: values.accounttype,
      accountnumber: values.accountnumber,
      swiftcode: values.swiftcode,
    };

    if (!id || id.toString() == "0") {
      companyDetailsBanking.create({ data: bankingData });
    } else {
      companyDetailsBanking.update(id, { data: bankingData });
    }
    this.state.handleConfirm();
  };

  handleInfoClose = () => {
    this.setState({ dialoginformation: undefined });
  };

  handleRemove = (row) => {};

  handleEdit = (id) => {};

  getCellActions = (column, row) => {
    return getCellActions(column, row, this.handleRemove, this.handleEdit);
  };

  render() {
    const { classes } = this.state;
    return (
      <div className={classes.root}>
        <Form
          initialValues={{
            ...this.state.companydetailsbanking,
          }}
          onSubmit={(values) => {
            this.handleSubmit(values);
          }}
          validate={(values) => {
            let errors = {};
            return errors;
          }}
          render={({ handleSubmit, form, values }) => (
            <form id={"1"} onSubmit={handleSubmit}>
              <table>
                <tbody>
                  <tr>
                    <td style={{ verticalAlign: "top", textAlign: "left" }}>
                      <table style={{ marginLeft: "15px", marginTop: "10px" }}>
                        <tbody>
                          <TableFieldCombo field={"currency_id"} title={"Currency"} classes={classes} data={this.state.currenciesCombo} onChange={() => {}} form={form} />
                          <TableFieldText key={`tabPanelText_bank`} classes={classes} title="Bank" field={`bank`} disabled={false} multiline={false} required={false} />
                          <TableFieldText
                            key={`tabPanelText_accountnumber`}
                            classes={classes}
                            title="Account Number"
                            field={`accountnumber`}
                            disabled={false}
                            multiline={false}
                            required={false}
                          />
                          <TableFieldText
                            key={`tabPanelText_AccountHolder`}
                            classes={classes}
                            title="Account Holder"
                            field={`accountholder`}
                            disabled={false}
                            multiline={false}
                            required={false}
                          />
                          <TableFieldText
                            key={`tabPanelText_accountholderaddress`}
                            classes={classes}
                            title="Account Address"
                            field={`accountholderaddress`}
                            disabled={false}
                            multiline={false}
                            required={false}
                          />

                          <TableFieldText
                            key={`tabPanelText_bankaddress`}
                            classes={classes}
                            title="Bank Address"
                            field={`bankaddress`}
                            disabled={false}
                            multiline={false}
                            required={false}
                          />
                          <TableFieldText
                            key={`tabPanelText_branchcode`}
                            classes={classes}
                            title="Branch Code"
                            field={`branchcode`}
                            disabled={false}
                            multiline={false}
                            required={false}
                          />
                          <TableFieldText
                            key={`tabPanelText_accountType`}
                            classes={classes}
                            title="Account Type"
                            field={`accounttype`}
                            disabled={false}
                            multiline={false}
                            required={false}
                          />

                          <TableFieldText
                            key={`tabPanelText_swiftcode`}
                            classes={classes}
                            title="Swift Code"
                            field={`swiftcode`}
                            disabled={false}
                            multiline={false}
                            required={false}
                          />
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </table>
              {this.state.dialoginformation && (
                <DialogInformation
                  isOpen={this.state.dialoginformation}
                  handleClose={this.handleInfoClose}
                  handleOK={this.handleInfoClose}
                  showinput={false}
                  title="Success"
                  body="Successfully updated company details"
                ></DialogInformation>
              )}
              <div style={{ float: "left", paddingLeft: "450px", paddingTop: "10px", clear: "both" }}>
                <Button type="submit" color="primary" variant="outlined">
                  <IconCheck />
                </Button>
                <Button
                  color="secondary"
                  variant="outlined"
                  onClick={() => {
                    this.state.handleConfirm();
                  }}
                >
                  <IconCancel />
                </Button>
              </div>
            </form>
          )}
        />
      </div>
    );
  }
}
export default withStyles(styles)(CompanyDetailsBankingEditFormUnstyled);

const TableFieldText: React.FunctionComponent<{ field: string; title: string; disabled: boolean; multiline: boolean; required: boolean } & WithStyles<typeof styles>> = (props) => {
  const { classes, title, field, disabled, multiline, required } = props;

  return (
    <tr>
      <td className={classes.tableRowFieldTitle} style={{ verticalAlign: "top", paddingTop: "13px" }}>
        <span>{`${title}: `}</span>
      </td>
      <td className={classes.tableRowFieldDataText}>
        <Field
          name={field}
          key={`body_value_${field}`}
          component={TextField}
          type="text"
          className={classes.tableRowFieldDataText}
          multiline={multiline}
          rows={multiline ? "4" : "1"}
          required={required}
          disabled={disabled}
        />
      </td>
    </tr>
  );
};

const TableFieldCombo: React.FC<{ field: string; title: string; classes: any; data: any; onChange?: any; form?: any } & WithStyles<typeof styles>> = (props) => {
  const { field, title, classes, data, onChange, form } = props;
  return (
    <tr>
      <td className={classes.tableRowFieldTitle}>
        <label>{`${title}: `}</label>
      </td>
      <td className={classes.tableCellsValue}>
        <Field
          name={field}
          component={Select}
          formControlProps={{ className: classes.tableCellsValueSelect }}
          parse={(value) => {
            if (onChange) {
              onChange(value, form);
            }
            return value;
          }}
        >
          {data.map((item, idx) => {
            return (
              <MenuItem key={`tablefiedcombo_${item.value}_${idx}`} value={item.value}>
                {item.display}
              </MenuItem>
            );
          })}
        </Field>
      </td>
    </tr>
  );
};
