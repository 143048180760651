import React, { useEffect, useState, FC } from "react";

import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";

import { getClientsWithShipmentsForCurrentYear, printClientShippingReport } from "../lib/api/loadout";
import { getFinancialYearSelected } from "../lib/api/week";

type ClientSelectorProps = {
  handlePrint(is_loading: boolean): void;
};

export const ClientSelector: FC<ClientSelectorProps> = ({ handlePrint }) => {
  const [clients, setClients] = useState([]);
  const [selectedClient, setSelectedClient] = useState("");
  const [loading, setLoading] = useState(false);

  const loadData = async () => {
    const clientResult = await getClientsWithShipmentsForCurrentYear();
    setClients(clientResult.map((item) => ({ value: item.clients_code, display: item.clients_name })));
  };

  const handleSelectClient = (event: React.ChangeEvent<{ value: string }>) => {
    setSelectedClient(event.target.value);
  };

  const handlePrintReport = async () => {
    setLoading(true);
    handlePrint(true);
    await printClientShippingReport(getFinancialYearSelected(), selectedClient);
    handlePrint(false);
    setLoading(false);
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <div style={{ display: "flex", flexDirection: "row", width: "450px", gap: "10px", justifyContent: "space-between", alignItems: "flex-end" }}>
      <FormControl style={{ width: "100%" }}>
        <InputLabel id="select-client">Client:</InputLabel>
        <Select fullWidth labelId="select-client" id="client" value={selectedClient} onChange={handleSelectClient} disabled={loading}>
          {[{ value: "ALL", display: "ALL" }, ...clients].map((client) => (
            <MenuItem value={client.value}>{client.display}</MenuItem>
          ))}
        </Select>
      </FormControl>
      <div style={{ whiteSpace: "nowrap", width: "135px" }}>
        {loading ? (
          <CircularProgress size={30} color="primary" />
        ) : (
          <Button variant="contained" color="primary" onClick={handlePrintReport} disabled={selectedClient == "" || loading}>
            Print Report
          </Button>
        )}
      </div>
    </div>
  );
};
