import { client } from "./_base";

const endpoint = "salefundsadhoc";

export type SaleFundsAdhocType = {
  id?: number;
  clients_id: number;
  currency_id: number;
  amount: number;
  makeanote: string;
  posteddate: Date;
  created_at?: Date;
  updated_at?: Date;
};

export const SaleFundsAdhoc = client(endpoint, (item) => ({
  ...item,
}));
