import React, { useEffect } from "react";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";

import { SnackContext } from "../../../lib/context/SnackContext";
import Grid from "../../../lib/components/grid";
import { getBarcodeForeignCost } from "../../../lib/api/consolidation";

import { ForeignCostInfoGridColumns } from "./foreignCostInfosetup";
import { GenerateErrorMessage } from "../../../lib/helpers/string_methods";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1),
      width: "800px",
    },
  });

type ForeignCostInfoProps = { barcodeid: number } & WithStyles<typeof styles>;

const ForeignCostInfoUnstyled: React.FunctionComponent<ForeignCostInfoProps> = (props) => {
  const { barcodeid, classes } = props;
  const { updateSnack } = React.useContext(SnackContext);

  const [data, setData] = React.useState(undefined);

  useEffect(() => {
    getBarcodeForeignCost(barcodeid)
      .then((result) => {
        setData(result);
      })
      .catch((error) => {
        const err = GenerateErrorMessage(error, "failed to retrieve data");
        updateSnack({ show: true, color: "red", message: err });
      });
  }, [barcodeid]);

  return (
    <div className={classes.root}>
      {data && data.length > 0 && <Grid loading={false} data={data} GridColumns={ForeignCostInfoGridColumns} clearFilters={"foreigncostinfo"} totalRowColumns={["items_cost", "pallets", "items_cartons", "cost_ctn", "cost_plt"]} />}
    </div>
  );
};

export default withStyles(styles)(ForeignCostInfoUnstyled);
