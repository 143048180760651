import React, { useEffect, useMemo, useState, useCallback } from "react";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";

import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";

import Check from "@material-ui/icons/Check";
import Close from "@material-ui/icons/Close";

// import { FormApi } from "final-form";
import { Form, Field } from "react-final-form";
import { TextField, Select, Checkbox } from "final-form-material-ui";
import ReactDatePicker from "react-datepicker";

import ProducerPHAttachment from "./producerphattachment";
import ProducerPHDetail from "../producersphdetail/producerphdetail";

import { producerph, ProducerPHType } from "../../lib/api/producerph";
import { getProducerPHTypeReadyForCombo } from "../../lib/api/producerphtype";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1),
      paddingTop: theme.spacing(2),
      width: "720px",
      height: "780px",
    },
    tableRowFieldTitle: {
      paddingTop: theme.spacing(1),
      paddingRight: theme.spacing(1) * 2,
      marginTop: theme.spacing(1),
      textAlign: "right",
      minWidth: "100px",
    },
    tableRowFieldData: {
      paddingTop: theme.spacing(0.5),
      textAlign: "left",
      width: "200px",
    },
    tableRowFieldCombo: {
      textAlign: "left",
      width: "200px",
    },
    tableRowFieldCalender: {
      padding: theme.spacing(0.5),
      marginTop: theme.spacing(1),
      textAlign: "left",
      width: "200px",
    },
  });

type ProducerPHEditFormProps = {
  onSubmit: any;
  onClose: any;
  id: any;
} & WithStyles<typeof styles>;

const ProducerPHEditFormUnstyled: React.FunctionComponent<ProducerPHEditFormProps> = (props) => {
  const { classes, onSubmit, onClose, id } = props;

  const [item, setItem] = React.useState<ProducerPHType>({
    id: id,
    producer_id: 0,
    validfrom: new Date(),
    validto: new Date(),
    producerphtype_id: 0,
  });

  const [files, setFiles] = React.useState([]);
  const [phtypes, setPhtypes] = useState([]);

  useEffect(() => {
    producerph.single(id).then((data: ProducerPHType) => {
      if (data) {
        setItem({ ...data, validfrom: new Date(data.validfrom), validto: new Date(data.validto) });
      }
    });
    getProducerPHTypeReadyForCombo().then((result) => {
      setPhtypes(result);
    });
  }, [id]);

  const onChangeValidDate = (start, end) => {
    setItem({ ...item, validfrom: start, validto: end });
  };

  const handleChangeAttachment = (files: []) => {
    setFiles(files);
  };

  return (
    <div className={classes.root}>
      <Form
        initialValues={{
          ...item,
        }}
        onSubmit={(values: ProducerPHType) => {
          const returnd = { ...values };
          onSubmit(returnd, files);
        }}
        validate={(values: ProducerPHType) => {
          let errors = {};
          return errors;
        }}
        render={({ handleSubmit, form, values }) => {
          return (
            <form
              id={id}
              onSubmit={(values) => {
                handleSubmit(values);
              }}
            >
              <div style={{ display: "flex", flexDirection: "row" }}>
                <div style={{ flexGrow: 1 }}>
                  <table>
                    <tbody>
                      <TableFieldDatePicker
                        classes={classes}
                        fieldStart={values.validfrom}
                        fieldEnd={values.validto}
                        title="Valid From"
                        onChange={onChangeValidDate}
                        startSelection={true}
                      />
                      <TableFieldDatePicker
                        classes={classes}
                        fieldStart={values.validfrom}
                        fieldEnd={values.validto}
                        title="Valid To"
                        onChange={onChangeValidDate}
                        startSelection={false}
                      />
                      <TableFieldCombo classes={classes} title="PH Type" field="producerphtype_id" data={phtypes} />
                    </tbody>
                  </table>
                  <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "20px", marginRight: "28px" }}>
                    <Button style={{ marginRight: "10px" }} color="secondary" variant="outlined" onClick={onClose}>
                      <Close />
                    </Button>
                    <Button type="submit" color="primary" variant="outlined">
                      {id == 0 ? "CREATE" : <Check />}
                    </Button>
                  </div>
                </div>
                <div style={{ flexGrow: 1, overflow: "auto", minWidth: "400px" }}>
                  <ProducerPHAttachment producerphid={item.id} handleChange={handleChangeAttachment} />
                </div>
              </div>
            </form>
          );
        }}
      />
      {id && id != 0 && (
        <div style={{ flexGrow: 1, overflow: "auto", minWidth: "400px" }}>
          <ProducerPHDetail producerphid={id} />
        </div>
      )}
    </div>
  );
};
export default withStyles(styles)(ProducerPHEditFormUnstyled);

export const TableFieldText: React.FunctionComponent<{ field: string; title: string; disabled: boolean; multiline: boolean; required: boolean } & WithStyles<typeof styles>> = (
  props,
) => {
  const { classes, title, field, disabled, multiline, required } = props;

  return (
    <tr>
      <td className={classes.tableRowFieldTitle}>
        <span>{`${title}: `}</span>
      </td>
      <td>
        <Field
          name={field}
          key={`body_value_${field}`}
          component={TextField}
          type="text"
          className={classes.tableRowFieldData}
          multiline={multiline}
          rows={multiline ? "4" : "1"}
          required={required}
          disabled={disabled}
        />
      </td>
    </tr>
  );
};

export const TableFieldDatePicker: React.FunctionComponent<
  { fieldStart: Date; fieldEnd: Date; title: string; onChange: any; startSelection: boolean } & WithStyles<typeof styles>
> = (props) => {
  const { classes, title, fieldStart, fieldEnd, onChange, startSelection } = props;

  return (
    <tr>
      <td className={classes.tableRowFieldTitle}>
        <span>{`${title}: `}</span>
      </td>
      <td>
        {startSelection ? (
          <ReactDatePicker
            className={classes.tableRowFieldCalender}
            selected={fieldStart}
            onChange={(value) => {
              onChange(value, fieldEnd);
            }}
            dateFormat="dd-MM-yyyy"
            startDate={fieldStart}
            endDate={fieldEnd}
            selectsStart
          />
        ) : (
          <ReactDatePicker
            className={classes.tableRowFieldCalender}
            selected={fieldEnd}
            onChange={(value) => {
              onChange(fieldStart, value);
            }}
            dateFormat="dd-MM-yyyy"
            startDate={fieldStart}
            endDate={fieldEnd}
            selectsEnd
          />
        )}
      </td>
    </tr>
  );
};

export const TableFieldCombo: React.FunctionComponent<{ classes: any; title: string; field: string; data: any } & WithStyles<typeof styles>> = (props) => {
  const { classes, title, field, data } = props;

  return (
    <tr>
      <td className={classes.tableRowFieldTitle}>{`${title}:`}</td>
      <td>
        <Field
          name={field}
          component={Select}
          formControlProps={{
            className: classes.tableRowFieldCombo,
          }}
          native
        >
          <option key={`none-1`} value={0}>
            {`NONE`}
          </option>
          {data &&
            data.length > 0 &&
            data.map((item, index) => {
              return (
                <option key={`${item.value}${index}`} value={item.value}>
                  {item.display}
                </option>
              );
            })}
        </Field>
      </td>
    </tr>
  );
};
