import React from "react";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Slide from "@material-ui/core/Slide";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";

import Draggable from "react-draggable";

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

const PaperComponent = (props) => {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
};

export const DialogInformation = ({ isOpen, handleClose, handleOK, title, body, showinput = true, defaultValue = "20", buttonCaption = "ok" }) => {
  const [x, setX] = React.useState(defaultValue);

  return (
    <Dialog
      // TransitionComponent={Transition}
      // aria-labelledby="alert-slide-dialog-title"
      // aria-describedby="alert-dialog-slide-description"
      open={isOpen}
      onClose={() => handleClose(undefined)}
      aria-labelledby="draggable-dialog-title"
      PaperComponent={PaperComponent}
      maxWidth="lg"
      // hideBackdrop
    >
      <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
        {title}
      </DialogTitle>

      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description" component="div">
          {body}
        </DialogContentText>
        {showinput && (
          <TextField
            id="outlined-number"
            // label="Number"
            value={x}
            onChange={(e) => {
              setX(e.target.value);
            }}
            type="number"
            InputLabelProps={{
              shrink: true,
            }}
            margin="normal"
            variant="outlined"
          />
        )}
      </DialogContent>

      <DialogActions>
        <Button
          // disableFocusRipple={false}
          onClick={() => {
            handleOK(x);
          }}
          color="primary"
          variant="outlined"
          autoFocus
        >
          {buttonCaption}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
