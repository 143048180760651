import React from "react";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import CircularProgress from "@material-ui/core/CircularProgress";

import CheckIcon from '@material-ui/icons/Check';
import CrossIcon from '@material-ui/icons/Close';

export const LoadingDialog = ({ isOpen, handleOK, title, loading: isLoading, errorMsg, successMsg }) => {
  const [x, setX] = React.useState(undefined);

  return (
    <>
      <Dialog
        open={isOpen}
        onClose={() => handleOK(undefined)}
        aria-labelledby="alert-slide-dialog-title"
        aria-describedby="alert-dialog-slide-description"
        disableBackdropClick={true}
      >
        <DialogTitle id="form-dialog-slide-title">{title}</DialogTitle>

        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {isLoading ? (
              <CircularProgress color="secondary" />
            ) : (
                errorMsg && errorMsg.length > 0 ? (
                  <div style={{ display: "flex", alignContent: "center" }}>
                    <CrossIcon color="secondary" style={{ marginRight: "5px" }} />
                    <span>{errorMsg}</span>
                  </div>
                ) : successMsg && successMsg.length > 0 ? (
                  <div style={{ display: "flex", alignContent: "center" }}>
                    <CheckIcon color="primary" style={{ marginRight: "5px" }} />
                    <span>{successMsg}</span>
                  </div>
                ) : (
                  <p>Upload is complete</p>
                )
              )}
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button
            onClick={() => handleOK(undefined)}
            color="primary"
            variant="outlined"
            autoFocus
            disabled={isLoading}
          >
            ok
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
