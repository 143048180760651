import React, { useEffect } from "react";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import LinearProgress from "@material-ui/core/LinearProgress";
import IconButton from "@material-ui/core/IconButton";

import IconAttachFile from "@material-ui/icons/AttachFile";
import IconDelete from "@material-ui/icons/Delete";

import { Dropzone } from "../lib/helpers/Dropzone";
import { loadoutattachment, loadoutAttachmentByLoadoutID } from "../lib/api/loadoutattachment";

import LZString from "lz-string";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1),
    },
    closebutton: {
      marginBottom: theme.spacing(1),
    },
    floatingProgress: {
      position: "absolute",
      width: "300px",
      height: "3px",
      textAlign: "center",
      fontSize: "20px",
      background: "white",
      opacity: 1,
      borderRadius: "0 0 5px 0",
    },
    fileContainer: {
      padding: theme.spacing(1),
      overflow: "auto",
    },
    fileItem: {
      margin: theme.spacing(1),
      cursor: "pointer",
    },
  });

type LoadoutAttachment = {
  attachmentid: number;
  handleClose: () => void;
  handleAccept: (files) => void;
} & WithStyles<typeof styles>;

const LoadoutAttachmentUnstyled: React.FunctionComponent<LoadoutAttachment> = (props) => {
  const { classes, attachmentid, handleClose, handleAccept } = props;
  const [loading, setLoading] = React.useState(false);
  const [files, setFiles] = React.useState([]);

  useEffect(() => {
    loadData();
  }, [attachmentid]);

  const loadData = () => {
    loadoutAttachmentByLoadoutID(attachmentid).then((result) => {
      const newFiles = [...result];
      const unsavedFiles = files.filter((file) => file.id == undefined);
      setFiles([...unsavedFiles, ...newFiles]);
    });
  };

  const onDrop = React.useCallback(
    (acceptedFiles) => {
      setLoading(true);
      const validFiles = [];
      acceptedFiles.map((file: any) => {
        if (file.type == "application/pdf" && files.find((validFile) => validFile.name == file.name) == undefined) {
          validFiles.push(file);
        }
      });

      Promise.all(
        Array.from(validFiles).map((file: any) => {
          return base64(file);
        }),
      ).then((newFiles) => {
        const filesarr = [...files, ...newFiles];
        setFiles(filesarr);
        setLoading(false);
      });
    },
    [files],
  );

  const base64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = function (event) {
        resolve({ name: file["name"], document: LZString.compress(event.target["result"]) });
      };
      reader.readAsDataURL(file);
    });
  };

  const previewFile = (file) => {
    const base64string = file.baseString;
    const arr = base64string.split(",");

    const binary = atob(arr[1]);
    const len = binary.length;
    const buffer = new ArrayBuffer(len);
    const view = new Uint8Array(buffer);
    for (var i = 0; i < len; i++) {
      view[i] = binary.charCodeAt(i);
    }
    const blob1 = new Blob([view], { type: "application/pdf" });
    const url = window.URL.createObjectURL(blob1);

    const a = document.createElement("a");
    document.body.appendChild(a);
    a.href = url;
    a.setAttribute("download", file.name);
    a.click();
    window.URL.revokeObjectURL(url);
  };

  const removeDocument = (id, name) => {
    if (id && id != 0) {
      loadoutattachment.remove(id).then(() => {
        loadData();
      });
    } else {
      const filesFiltered = files.filter((file) => file.name != name);
      setFiles(filesFiltered);
    }
  };

  return (
    <div className={classes.root}>
      {loading && <LinearProgress className={classes.floatingProgress} />}
      <Dialog open={true} onClose={handleClose} fullScreen={false} fullWidth={true}>
        <DialogContent>
          {!loading && (
            <div>
              <Dropzone multiple onDrop={onDrop} file={files} />
            </div>
          )}
          <div className={classes.fileContainer}>
            {files &&
              files.map((file) => {
                return (
                  <div className={classes.fileItem} key={`name_${file.name}_container`} style={{ display: "flex", flexDirection: "row" }}>
                    <div
                      style={{ flexGrow: 99, float: "left" }}
                      onClick={() => {
                        previewFile(file);
                      }}
                    >
                      <div style={{ display: "flex", flexDirection: "row" }}>
                        <IconAttachFile style={{ color: "red", flexGrow: 1 }} />
                        <div style={{ wordBreak: "break-all", flexGrow: 99 }}>{file.name}</div>
                      </div>
                    </div>
                    <div style={{ flexGrow: 1, padding: 0 }}>
                      <IconButton
                        style={{ padding: 0 }}
                        aria-label="delete"
                        onClick={() => {
                          removeDocument(file.id, file.name);
                        }}
                      >
                        <IconDelete />
                      </IconButton>
                    </div>
                  </div>
                );
              })}
          </div>
        </DialogContent>
        <DialogActions>
          <Button className={classes.closebutton} disableFocusRipple={false} onClick={handleClose} color="secondary" variant="outlined" autoFocus={true}>
            close
          </Button>
          <Button
            className={classes.closebutton}
            disableFocusRipple={false}
            onClick={() => {
              handleAccept(files);
            }}
            color="primary"
            variant="outlined"
            autoFocus={true}
          >
            accept
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default withStyles(styles)(LoadoutAttachmentUnstyled);
