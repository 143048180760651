import React, { useState, useRef, useEffect } from "react";

import { withStyles, createStyles, Theme } from "@material-ui/core/styles";

import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      height: "100%",
      backgroundColor: "transparent",
    },
  });

const CustomDropdownEditorUnstyled = ({ row, column, onRowChange, classes, options, filter }) => {
  const ref: any = useRef();
  const [open, setOpen] = useState(true);

  const handleChange = (event) => {
    onRowChange({ ...row, [column.key]: event.target.value }, true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  useEffect(() => {
    if (ref.current) {
      ref.current.focus();
    }
  }, []);

  const handleKeyPress = (event) => {
    // tab key press
    if (event.keyCode === 9 || event.keyCode === 27) {
      onRowChange(row, true);
    }
  };

  return (
    <Select ref={ref} open={open} onOpen={handleOpen} onClose={handleClose} value={row[column.key]} onChange={handleChange} className={classes.root}>
      {options
        .filter((item) => {
          if (filter) {
            if (item.data[filter.field] == filter.value) return item;
          } else {
            return item;
          }
        })
        .map((item, indx) => (
          <MenuItem key={indx} value={item.value} onKeyDown={handleKeyPress}>
            {item.value}
          </MenuItem>
        ))}
    </Select>
  );
};

export default withStyles(styles)(CustomDropdownEditorUnstyled);
