import React from "react";

import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";

import "../lib/custom/splitter.css";
import "react-splitter-layout/lib/index";
import Splitter from "m-react-splitters";
import "m-react-splitters/lib/splitters.css";

import Grid from "../lib/components/grid";
import { DialogInformation } from "../lib/components/dialoginformation";
import FinanceConsolidationGrid from "./financeconsolidationgrid";

import FinanceSummary from "./financesummary/financesummary";

import { GridColumns } from "./financegridsetup";

import { getFinanceAll } from "../lib/api/finance";
import { Row } from "react-data-grid";
import { consolidationFinByInvoiceNumbers } from "../lib/api/consolidation";
import { getFinancialYearSelected } from "../lib/api/week";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1),
      height: "calc(100vh - 100px)",
      width: "100%",
      position: "relative",
    },
    gridContainer: {
      position: "relative",
      height: "100%",
    },
    splitter: {
      maxWidth: "calc(100vw)",
      gridGap: "10px",
    },
    tabpanel: {
      height: "100%",
      position: "relative",
      "& > :first-child": {
        height: "100%",
        padding: "0",
        "& > :first-child": {
          height: "100%",
        },
      },
    },
    totalRowStyle: {
      background: "#ddd",
      textAlign: "right",
      fontWeight: "bold",
      color: "black",
    },
    totalRowStyleFixed: {
      position: "sticky",
      bottom: 0,
      background: "#ddd",
      textAlign: "right",
      fontWeight: "bold",
      color: "black",
    },
    lastRowStyle: {
      display: "flex",
      flexDirection: "column-reverse",
      color: "black",
    },
    boldStyle: {
      fontWeight: "bold",
    },
    normalStyle: {
      fontWeight: "normal",
    },
  });

type FinanceFullProps = {} & WithStyles<typeof styles>;

export class FinanceFullUnstyled extends React.Component<FinanceFullProps, any> {
  state = {
    classes: undefined,
    data: [],
    rowDataInvoiceNumber: undefined,
    loading: true,
    message: undefined,
    tabValue: 0,
    tabGridHeight: 400,
    mainGridHeight: 400,
    consolidationData: [],
    consolidationLoading: false,
    selectedRow: undefined,
  };

  constructor(props) {
    super(props);
    this.state.classes = props.classes;
  }

  setGridHeight = () => {
    const primarysplit = document.getElementsByClassName("splitter horizontal");
    this.setState({ mainGridHeight: primarysplit[0].children[0].clientHeight - 80, tabGridHeight: primarysplit[0].children[2].clientHeight - 70 });
  };

  componentDidMount() {
    window.addEventListener("resize", () => this.setGridHeight());
    this.loadData().then(() => {
      this.setState({ loading: false });
      this.setGridHeight();
    });
  }

  componentWillUnmount() {
    window.removeEventListener("resize", () => {});
  }

  loadData = () => {
    this.setState({ loading: true });
    return getFinanceAll(getFinancialYearSelected()).then((result) => {
      this.setState({ data: result.data });
    });
  };

  handleInformationClose = () => {
    this.setState({ message: undefined });
  };

  handleRowClick = async (rowData) => {
    this.setState({ consolidationLoading: true, rowDataInvoiceNumber: rowData.sale_invoicenumber, selectedRow: rowData });
    const result = await consolidationFinByInvoiceNumbers([rowData.sale_invoicenumber]);
    this.setState({ consolidationData: result, consolidationLoading: false });
    this.setGridHeight();
  };

  handleRefresh = () => {
    this.setState({ rowDataInvoiceNumber: undefined, loading: true }, () => {
      this.loadData().then(() => {
        this.setState({ loading: false });
      });
    });
  };

  handleTabChange = (_, newValue: number) => {
    this.setState({ tabValue: newValue }, () => {
      this.setGridHeight();
    });
  };

  rowRenderer = (props) => {
    const { row } = props;
    const { classes } = this.props;
    const boldStyle = this.state.selectedRow && this.state.selectedRow.sale_invoicenumber == row.sale_invoicenumber ? classes.boldStyle : classes.normalStyle;
    return <Row {...props} onClick={() => this.handleRowClick(row)} className={boldStyle} />;
  };

  handleConsolidationDataRefresh = async () => {
    const row = this.state.selectedRow;
    if (row) {
      this.setState({ consolidationLoading: true });
      const result = await consolidationFinByInvoiceNumbers([row.sale_invoicenumber]);
      this.setState({ consolidationData: result[0], consolidationLoading: false });
      this.setGridHeight();
    }
  };

  render() {
    const { classes } = this.state;

    return (
      <div className={classes.root}>
        <div className={classes.gridContainer}>
          <Splitter
            primaryPaneHeight="60%"
            position="horizontal"
            dispatchResize={true}
            postPoned={true}
            onDragFinished={(e) => {
              this.setGridHeight();
            }}
          >
            <div id="main_splitter_div" style={{ height: "100%", width: "100%", display: "grid" }}>
              <Splitter
                className={classes.splitter}
                primaryPaneWidth="50%"
                position="vertical"
                dispatchResize={true}
                postPoned={true}
                onDragFinished={(e) => {
                  this.setGridHeight();
                }}
              >
                <Grid
                  loading={this.state.loading}
                  data={this.state.data}
                  GridColumns={GridColumns}
                  handleRefresh={this.handleRefresh}
                  forceHeight={this.state.mainGridHeight}
                  clearFilters={"financegridfull"}
                  rowRenderer={this.rowRenderer}
                  totalRowColumns={[
                    "sale_total",
                    "amountCredit",
                    "amountDebit",
                    "sale_totalfinal",
                    "salefundsallocation_amount",
                    "sale_payment1amount",
                    "sale_payment2amount",
                    "sale_payment3amount",
                    "total_outstanding",
                    "sale_exchangerate",
                  ]}
                />
                <FinanceSummary sale_invoicenumber={this.state.rowDataInvoiceNumber} forceheight={this.state.mainGridHeight} />
              </Splitter>
            </div>
            <FinanceConsolidationGrid
              data={this.state.consolidationData}
              loading={this.state.consolidationLoading}
              gridHeight={this.state.tabGridHeight}
              handleRefresh={this.handleConsolidationDataRefresh}
            />
          </Splitter>
        </div>
        {this.state.message && (
          <DialogInformation
            isOpen={true}
            handleClose={this.handleInformationClose}
            handleOK={this.handleInformationClose}
            title={"Feedback"}
            body={`${this.state.message}`}
            showinput={false}
          />
        )}
      </div>
    );
  }
}

export default withStyles(styles)(FinanceFullUnstyled);
