import React, { useEffect } from "react";
import classnames from "classnames";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";

import { TextField } from "./lib/components/TextField";
import { Form, Field } from "react-final-form";
import { history } from "./lib/history";
import orchard from "./orchard.jpg";
import { login } from "./lib/api/_base";

import IdentityRegister from "./identities/register";
import { userProfiles } from "./lib/api/userProfiles";
import { UserContext } from "./lib/context/UserContext";
import { clearStickyFilters } from "./lib/helpers/stickyfilters";
import { getFinancialYears } from "./lib/api/week";
import { MicrosoftLoginButton } from "./lib/components/MicrosoftLoginButton";
import DividerWithText from "./lib/components/Divider";

import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Progress from "@material-ui/core/CircularProgress";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import { applyDefaultFilters } from "./lib/default-filters";

const styles = (theme: Theme) =>
  createStyles({
    container: {
      height: "100%",
      width: "100%",
      background: `url(${orchard})`,
      backgroundSize: "cover",
    },
    img: {
      height: "100vh",
      width: "100vw",
    },
    root: {
      display: "flex",
      flexDirection: "column",
      width: 400,
      height: "100vh",
      backgroundColor: "rgba(255,255, 255, 0.8)",
      alignItems: "center",
      justifyContent: "center",
    },
    buttons: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
      gap: theme.spacing(2),
    },
    header: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      "& h4": {
        fontWeight: "bold",
        fontFamily: "Bicubik",
        fontSize: "2rem",
      },
    },
    buttonRegister: {
      alignItems: "right",
      justifyContent: "right",
    },
    inputs: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
      gap: theme.spacing(1),
      "& .MuiFilledInput-root": {
        backgroundColor: "rgba(255, 255, 255, 0.8)",
      },
    },
    yearWrapper: {
      display: "flex",
      flexDirection: "row",
      width: "100%",
      alignItems: "center",
    },
    yearLabel: {
      whiteSpace: "nowrap",
      color: "#666",
      fontSize: "16px",
    },
    microsoftButton: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: "1rem",
    },
    divider: {},
    formControlFinYear: {
      width: "100%",
    },
    formWrappr: {
      marginTop: theme.spacing(1),
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(2),
    },
    wrapper: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(1),
    },
    loginWrapper: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(1),
      marginTop: theme.spacing(1),
      justifyContent: "center",
      padding: theme.spacing(1),
    },
    registerButtonWrapper: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      gap: theme.spacing(1),
      "& p": {
        margin: 0,
        lineHeight: 0,
      },
    },
    finYearSelector: {
      backgroundColor: "rgba(255, 255, 255, 0.8)",
    },
    loginError: {
      color: "red",
      lineHeight: "5px",
    },
  });

export type LoginProps = {
  errorMsg?;
} & WithStyles<typeof styles>;

const LoginUnstlyed: React.FunctionComponent<LoginProps> = ({ classes, children, errorMsg }) => {
  const [open, setOpen] = React.useState(false);
  const { hydrate } = React.useContext(UserContext);

  const [financialYears, setFinancialYears] = React.useState([]);
  const [financialYear, setFinancialYear] = React.useState(0);

  const handleOpenRegister = () => {
    setOpen(true);
  };

  const applyUserSettings = (userId) => {
    clearStickyFilters();
    userProfiles.all().then((profiles) => {
      profiles
        .filter((profile) => profile.user_id == userId)
        .map((profile) => {
          if (profile.label && profile.filter) {
            localStorage.setItem(profile.label, profile.filter);
          }
        });
    });
  };

  const getUserFinancialYear = (defaultYear: number) => {
    const finyear = JSON.parse(localStorage.getItem(`financialyear`));
    localStorage.setItem(`financialyear`, JSON.stringify(!finyear ? defaultYear : finyear));
    return !finyear ? defaultYear : finyear;
  };

  const handleFinancialYearChange = (event) => {
    const yearV = Number(event.target.value);
    localStorage.setItem(`financialyear`, JSON.stringify(yearV));
    setFinancialYear(getUserFinancialYear(yearV));
  };

  useEffect(() => {
    getFinancialYears().then((result) => {
      if (result && result.length > 0) {
        setFinancialYears(result);
        setFinancialYear(getUserFinancialYear(Number(result[0]["financial_year"])));
      }
    });
  }, []);

  return (
    <div className={classes.container}>
      <Paper className={classnames(classes.root)}>
        <Form
          onSubmit={async (fields: any) => {
            try {
              const user = await login(fields);
              if (user.message) return { login: "Username and/or password invalid" };

              applyUserSettings(user.id);
              await hydrate();

              // apply default filters
              applyDefaultFilters();

              window.localStorage.setItem("loggedIn", "true");
              history.push("/orders");
            } catch (error) {
              window.localStorage.setItem("loggedIn", "false");
              return { login: "Failure to log in" };
            }
          }}
          validate={(values) => {
            let errors = {};
            if (values.username == "" || !values.username) {
              errors = { ...errors, username: "Username is required" };
            }
            if (values.password == "" || !values.password) {
              errors = { ...errors, password: "Password is required" };
            }
            return errors;
          }}
          render={({ handleSubmit, submitting, submitErrors }) => (
            <form onSubmit={handleSubmit}>
              <div className={classes.header}>
                <Typography variant="h4" color="primary">
                  AGILE SIGN IN
                </Typography>
              </div>
              <div className={classes.formWrappr}>
                <div className={classes.yearWrapper}>
                  <FormControl variant="filled" className={classes.formControlFinYear}>
                    <InputLabel>Financial Year</InputLabel>
                    <Select value={financialYear} label="Financial Year" onChange={handleFinancialYearChange} className={classes.finYearSelector}>
                      {(financialYears || []).map((year, index) => (
                        <MenuItem key={`${year.financial_year}${index}`} value={year.financial_year}>
                          {year.financial_year}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div className={classes.loginWrapper}>
                  <div className={classes.microsoftButton}>
                    <MicrosoftLoginButton buttonTheme={"light"} />
                  </div>
                  <div className={classes.divider}>
                    <DividerWithText>or</DividerWithText>
                  </div>
                  <div>
                    <div className={classes.inputs}>
                      <Field name="username" label="Username" component={TextField} />
                      <Field name="password" label="Password" type="password" component={TextField} />
                      <div className={classes.buttons}>
                        <Button disabled={submitting} variant="contained" color="primary" type="submit" fullWidth>
                          {submitting && <Progress size={12} />}
                          SIGN IN
                        </Button>
                        {submitErrors && <p className={classes.loginError}>{submitErrors.login}</p>}
                        <div className={classes.registerButtonWrapper}>
                          <p>Don't have an account?</p>
                          <Button size="small" onClick={handleOpenRegister} color="primary" variant="contained">
                            register
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {open && <IdentityRegister handleClose={() => setOpen(false)} />}
            </form>
          )}
        />
      </Paper>
    </div>
  );
};

export const Login = withStyles(styles)(LoginUnstlyed);
