//Converts pluralised text to singular item, and adds a space if a capital letter is encountered

import { formatTableName } from "./TableNamesFormatted";

// mid word(i.e. targetMarkets => Target Market,Countries => Country e.t.c.).
export const PrettyTitleSingular = (endpoint) => {
  let x = endpoint.toString();
  if (x.toString().endsWith("ies")) {
    x = x.slice(0, -3) + "y";
  } else if (x.toString().toLowerCase() != "types" && x.toString().endsWith("es")) {
    x = x.slice(0, -2);
  } else if (x.toString().endsWith("s")) {
    x = x.slice(0, -1);
  }

  return (
    x.charAt(0).toUpperCase() +
    x
      .substr(1)
      .replace(/([A-Z]+)/g, " $1")
      .trim()
  );
};

//Converts text to item, and adds a space if a capital letter is encountered
// mid word(i.e. targetMarkets => Target Market,Countries => Country e.t.c.).
export const PrettyTitle = (endpoint) => {
  return (
    endpoint.toString().charAt(0).toUpperCase() +
    endpoint
      .toString()
      .substr(1)
      .replace(/([A-Z]+)/g, " $1")
      .trim()
  );
};

export const wrap = (s, w) => s.replace(new RegExp(`(?![^\\n]{1,${w}}$)([^\\n]{1,${w}})\\s`, "g"), "$1\n");

export const isEquivalentObject = (a, b) => {
  // Create arrays of property names
  var aProps = Object.getOwnPropertyNames(a);
  var bProps = Object.getOwnPropertyNames(b);

  // If number of properties is different,
  // objects are not equivalent
  if (aProps.length != bProps.length) {
    return false;
  }

  for (var i = 0; i < aProps.length; i++) {
    var propName = aProps[i];

    // If values of same property are not equal,
    // objects are not equivalent
    if (a[propName] !== b[propName]) {
      return false;
    }
  }

  // If we made it this far, objects
  // are considered equivalent
  return true;
};

export const GenerateErrorMessage = (error, defaultmessage) => {
  const err = error.data || defaultmessage;

  if (err.includes("REFERENCE constraint")) {
    try {
      const tableName = err.split(".")[2].split(",")[0].replace('"', "");
      const capitalizeTableName = tableName.charAt(0).toUpperCase() + tableName.slice(1);
      return `Cannot delete - ${formatTableName(capitalizeTableName)} exists`;
    } catch (error) {
      return defaultmessage || "Cannot delete because it is being used";
    }
  }

  if (err.includes("foreign key constraint")) {
    return "Cannot delete because it is being used";
  }

  if (err.includes("duplicate key row in object")) {
    return "Already exists. Cannot add duplicate";
  }

  if (err.includes("String or binary data would be truncated.")) {
    return "Cannot exceed limit for field";
  }

  return err;
};
