import React, { useState, useRef } from "react";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Paper from "@material-ui/core/Paper";

import Draggable from "react-draggable";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1),
    },
    title: {
      backgroundColor: theme.palette.primary.dark,
      color: "white",
      cursor: "pointer",
    },
  });

type ConfirmationProps = {
  children?: React.ReactNode;
  isOpen: any;
  handleClose: any;
  handleConfirm: any;
  title: any;
  body: any;
  fullscreen?: boolean;
  buttonCaptionObject?: { no: string; yes: string };
  disableBackdropClick?: boolean;
  makeHeaderUppercase?: boolean;
  handleBackdropClick?(): void;
} & WithStyles<typeof styles>;

const PaperComponent = (props) => {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
};

const ConfirmationUnstyled: React.FunctionComponent<ConfirmationProps> = (props) => {
  // export const Confirmation = ({ children = undefined, isOpen, handleClose, handleConfirm, title, body, fullscreen = false }) => {
  const {
    classes,
    children,
    isOpen,
    handleClose,
    handleConfirm,
    title,
    body,
    fullscreen = false,
    buttonCaptionObject = { no: "no", yes: "yes" },
    disableBackdropClick,
    handleBackdropClick,
    makeHeaderUppercase,
  } = props;
  const [isEditing, setEditing] = useState(false);
  const handleKeyRealease = (e) => {
    if (e.which == 89) {
      handleConfirm();
    }
    if (e.which == 78) {
      handleClose();
    }
  };
  // useEffect(() => {
  //   if (isEditing && !children) {
  //     inputRef.current.focus();
  //   } else {
  //     setEditing(!isEditing);
  //   }
  // }, [isEditing]);

  const underlineFirstChar = (text: string) => {
    return (
      <span>
        <u>{text.charAt(0)}</u>
        {text.slice(1)}
      </span>
    );
  };

  const inputRef = useRef(null);
  return (
    <div>
      <Dialog
        disableEnforceFocus={false}
        disableAutoFocus={false}
        disableRestoreFocus
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="alert-slide-dialog-title"
        aria-describedby="alert-dialog-slide-description"
        maxWidth="lg"
        fullScreen={fullscreen}
        disableBackdropClick={disableBackdropClick || !!handleBackdropClick}
        PaperComponent={PaperComponent}
        onBackdropClick={handleBackdropClick}
      >
        <DialogTitle id="draggable-dialog-title" className={classes.title}>
          {makeHeaderUppercase ? (title || "").toUpperCase() : title}
        </DialogTitle>
        {body && (
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">{body}</DialogContentText>
          </DialogContent>
        )}
        {children ? (
          <DialogContent>
            <div style={{ padding: "10px", position: "relative" }}>{children}</div>
          </DialogContent>
        ) : (
          <DialogActions>
            <Button type="button" onKeyUp={handleKeyRealease} ref={inputRef} disableFocusRipple={false} onClick={handleClose} color="secondary" variant="outlined" autoFocus={true}>
              {underlineFirstChar(buttonCaptionObject.no)}
            </Button>
            <Button onKeyUp={handleKeyRealease} onClick={handleConfirm} color="primary" variant="outlined">
              {underlineFirstChar(buttonCaptionObject.yes)}
            </Button>
          </DialogActions>
        )}
      </Dialog>
    </div>
  );
};

export default withStyles(styles)(ConfirmationUnstyled);
