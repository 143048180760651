import React from "react";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";

import LinearProgress from "@material-ui/core/LinearProgress";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

import { GridColumnsAdjustment } from "./adjustmentgridsetup";
import { GridColumnsAdjustmentGrouped } from "./adjustmentgridsetupgrouped";
import Grid from "../../../lib/components/grid";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      height: "100%",
    },
    divDetails: {
      gridArea: "details",
      paddingLeft: "5px",
      width: "calc(100vw - 200px)",
      position: "relative",
    },
    divTotals: {
      gridArea: "totals",
      width: "calc(100vw - 194px)",
    },
  });

type AdjustmentGridProps = {
  isCarton: boolean;
  adjustmentDetail: any[];
  adjustmentDetailGrouped: any[];
  handleDetailUpdate: (detail) => void;
} & WithStyles<typeof styles>;

export class AdjustmentGrid extends React.Component<AdjustmentGridProps, any> {
  state = {
    handleDetailUpdate: undefined,
    loading: true,
    filters: {},
    groupedFilters: {},
    gridHeight: 250,
    updateFruitSpec: false,
    rowSelectedIdx: undefined,
    cellSelected: undefined,
  };

  constructor(props) {
    super(props);
    this.state.handleDetailUpdate = props.handleDetailUpdate;
  }

  componentDidMount() {
    this.loadAdjustmentDetails().then(() => {
      const mainClientheight = document.getElementById("debtoradjustmentroot");
      this.setState({ gridHeight: mainClientheight && mainClientheight.clientHeight && mainClientheight.clientHeight > 0 ? mainClientheight.clientHeight - 400 : 250 });
    });
  }

  loadAdjustmentDetails = async () => {
    this.setState({ loading: false });
  };

  handleFruitSpecSellingUpdate = () => {
    this.setState({ updateFruitSpec: !this.state.updateFruitSpec }, () => {});
  };

  filterTerms = (filterObj, term = "filterTerm") => {
    let val = 0;

    Object.keys(filterObj).map((key) => {
      val += filterObj[key][term].length;
    });

    return val;
  };

  handleKeyDown = (event, gridRef, grouped = false) => {
    const enterKey = 13;
    const tabKey = 9;

    if (event.which === enterKey || event.which === tabKey) {
      event.preventDefault();

      const tagName = event.target.tagName && event.target.tagName.toLowerCase();

      if (tagName != "div") {
        gridRef.selectCell({ idx: this.state.cellSelected, rowIdx: this.state.rowSelectedIdx }, true);
      }
      //
      else {
        if (grouped) {
          gridRef.selectCell({ idx: 8, rowIdx: this.state.rowSelectedIdx + 1 }, true);
          this.setState({ rowSelectedIdx: this.state.rowSelectedIdx + 1, cellSelected: 8 });
        } else {
          if (this.state.cellSelected == 10) {
            gridRef.selectCell({ idx: 9, rowIdx: this.state.rowSelectedIdx + 1 }, true);
            this.setState({ rowSelectedIdx: this.state.rowSelectedIdx + 1, cellSelected: 9 });
          }
          //
          else {
            gridRef.selectCell({ idx: this.state.cellSelected + 1, rowIdx: this.state.rowSelectedIdx }, true);
            this.setState({ cellSelected: this.state.cellSelected + 1 });
          }
        }
      }
    }
  };

  handleRowsUpdated = (data: any[], grouped: boolean) => {
    const rows: any[] = this.props.adjustmentDetail;

    data = data.map((ditem) => {
      if (Number(ditem.saleadjustmentdetail_cartons) > Number(ditem.barcode_cartons)) {
        ditem = { ...ditem, saleadjustmentdetail_cartons: ditem.barcode_cartons };
      }
      return ditem;
    });

    const newRows = rows.map((row) => {
      const exists = data.find((dataRow) => {
        if (grouped) {
          if (
            row["commodityCode"] == dataRow["commodityCode"] &&
            row["varietyCode"] == dataRow["varietyCode"] &&
            row["packCode"] == dataRow["packCode"] &&
            row["markCode"] == dataRow["markCode"] &&
            row["gradeCode"] == dataRow["gradeCode"] &&
            row["countCode"] == dataRow["countCode"]
          ) {
            return { ...row, saleadjustmentdetail_amount: dataRow["saleadjustmentdetail_amount"] };
          }
        } else {
          if (
            row["salesdetail_id"] == dataRow["salesdetail_id"] &&
            row["saledetail_barcode"] == dataRow["saledetail_barcode"] &&
            row["commodityCode"] == dataRow["commodityCode"] &&
            row["varietyCode"] == dataRow["varietyCode"] &&
            row["packCode"] == dataRow["packCode"] &&
            row["markCode"] == dataRow["markCode"] &&
            row["gradeCode"] == dataRow["gradeCode"] &&
            row["countCode"] == dataRow["countCode"]
          ) {
            return { ...row, saleadjustmentdetail_amount: dataRow["saleadjustmentdetail_amount"] };
          }
        }
      });
      if (exists) {
        return {
          ...row,
          saleadjustmentdetail_amount: exists["saleadjustmentdetail_amount"],
          saleadjustmentdetail_cartons: grouped ? row["saleadjustmentdetail_cartons"] : exists["saleadjustmentdetail_cartons"],
        };
      }
      return row;
    });

    this.state.handleDetailUpdate(newRows);
  };

  GridColumnsOverride = (data, filters, columnArrangement, columnsWidth) => {
    return GridColumnsAdjustment(data, filters, columnArrangement, columnsWidth, this.props.isCarton);
  };

  GroupedGridColumnsOverride = (data, filters, columnArrangement, columnsWidth) => {
    return GridColumnsAdjustmentGrouped(data, filters, columnArrangement, columnsWidth, this.props.isCarton);
  };

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        {this.state.loading ? (
          <LinearProgress />
        ) : (
          <div style={{ textAlign: "left" }}>
            <div id="divDetails" className={classes.divDetails}>
              {!this.state.updateFruitSpec && (
                <>
                  <Grid
                    loading={this.state.loading}
                    data={this.props.adjustmentDetail}
                    GridColumns={this.GridColumnsOverride}
                    clearFilters={"adjustmentgrid"}
                    forceHeight={this.state.gridHeight}
                    handleChange={(columns, rows) => this.handleRowsUpdated(rows, false)}
                    handleGridKeyDown={this.handleKeyDown}
                    totalRowColumns={["barcode_cartons", "barcode_palletsize", "saleadjustmentdetail_cartons", "total"]}
                    selectedRowChange={(selectedRow: any, colums: { selectedColumn: number; columnsLength: number }) =>
                      this.setState({ rowSelectedIdx: selectedRow.rowIdx, cellSelected: colums.selectedColumn })
                    }
                    fill={true}
                  />
                </>
              )}
              {this.state.updateFruitSpec && (
                <>
                  <Grid
                    loading={this.state.loading}
                    data={this.props.adjustmentDetailGrouped}
                    GridColumns={this.GroupedGridColumnsOverride}
                    clearFilters={"adjustmentgridgrouped"}
                    forceHeight={this.state.gridHeight}
                    handleChange={(columns, rows) => this.handleRowsUpdated(rows, true)}
                    handleGridKeyDown={(ev, grid) => this.handleKeyDown(ev, grid, true)}
                    totalRowColumns={["new_palletsize", "new_nocartons", "saleadjustmentdetail_amount", "total"]}
                    selectedRowChange={(selectedRow: any, colums: { selectedColumn: number; columnsLength: number }) =>
                      this.setState({ rowSelectedIdx: selectedRow.rowIdx, cellSelected: colums.selectedColumn })
                    }
                    fill={true}
                  />
                </>
              )}
            </div>
            <div className={classes.divTotals}>
              <div style={{ float: "right" }}>
                <FormControlLabel
                  control={<Checkbox checked={this.state.updateFruitSpec} onChange={this.handleFruitSpecSellingUpdate} name="checkedB" color="primary" />}
                  label="SHOW GROUPED FRUITSPEC GRID"
                />
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default withStyles(styles)(AdjustmentGrid);
