import { whoami } from "../../lib/api/_base";
import { userProfiles } from "../../lib/api/userProfiles";
import { isNullOrUndef } from "../../lib/helpers/isNullOrUndef";

export const getStickyFilters = (section: string = "general") => {
  const filtersSection = localStorage.getItem(`stickyfilters_${section}`);

  // sticky filters should never be an object (filter context will fail because it's expecting an array)
  if (filtersSection == "{}") {
    localStorage.setItem(`stickyfilters_${section}`, "[]");
  }

  const currentStickyFilters = JSON.parse(localStorage.getItem(`stickyfilters_${section}`));
  if (!isNullOrUndef(currentStickyFilters)) {
    return currentStickyFilters;
  }
  return [];
};

export const updateUserProfile = (currentStickyFilters, label = undefined, section: string = "general") => {
  whoami().then((user) => {
    userProfiles.all().then((profiles) => {
      if (label) {
        const profile = profiles.filter((profile) => profile.user_id == user.id && label === profile.label)[0];

        if (profile && JSON.stringify(profile.filter) != JSON.stringify(currentStickyFilters)) {
          userProfiles.update(profile.id, { data: { filter: JSON.stringify(currentStickyFilters) } });
        } else if (currentStickyFilters) {
          userProfiles.create({ data: { user_id: user.id, label, filter: JSON.stringify(currentStickyFilters) } });
        }
      } else {
        label = `stickyfilters_${section}`;
        const profile = Object.create(profiles).find((p) => {
          if (p.user_id == user.id && p.label == label) return p;
        });
        if (profile && JSON.stringify(profile.filter) != JSON.stringify(currentStickyFilters)) {
          userProfiles.update(profile.id, { data: { label, filter: currentStickyFilters ? JSON.stringify(currentStickyFilters) : null } });
        } else if (currentStickyFilters) {
          userProfiles.create({ data: { user_id: user.id, label, filter: currentStickyFilters ? JSON.stringify(currentStickyFilters) : null } });
        }
      }
    });
  });
};
export const resetStickyFiltersRead = (section: string = "general") => {
  const newStickyFilters = getStickyFilters(section).map((item) => {
    return { ...item, read: 0 };
  });
  localStorage.setItem(`stickyfilters_${section}`, JSON.stringify(newStickyFilters));
};

export const addStickyFilters = (fieldname, data, section: string = "general") => {
  const currentStickyValue = getStickyFilters(section);
  const currentStickyValueArr = [];

  currentStickyValue.map((item) => {
    if (item.field !== fieldname) {
      return currentStickyValueArr.push(item);
    }
    return undefined;
  });

  currentStickyValueArr.push(data);
  localStorage.setItem(`stickyfilters_${section}`, JSON.stringify(currentStickyValueArr));
  updateUserProfile(currentStickyValueArr, undefined, section);
  return currentStickyValueArr;
};

export const clearStickyFilters = (clearLayout = false) => {
  if (clearLayout) {
    const localFinYear = localStorage.getItem("financialyear");
    localStorage.clear();
    localStorage.setItem("loggedIn", "true");
    localStorage.setItem("financialyear", localFinYear);
  } else {
    const keys = Object.keys(localStorage);
    keys.forEach((key) => {
      if (key.includes("stickyfilters_")) {
        localStorage.removeItem(key);
      }
    });
  }
};

export const removeFromSticky = (key, value, section: string = "general") => {
  const sticky = getStickyFilters(section);

  clearStickyFilters();

  sticky.map((fields, fIdx) => {
    if (fields.field !== key) {
      return false;
    }

    fields.value.map((values, vIdx) => {
      if (values.value == value && sticky[fIdx].value.length === 1) {
        sticky.splice(fIdx, 1);
      } else if (values.value == value) {
        sticky[fIdx].value.splice([vIdx], 1);
      }
    });
  });

  localStorage.setItem(`stickyfilters_${section}`, JSON.stringify(sticky));
  updateUserProfile(sticky, undefined, section);
  return sticky;
};

export const clearFiltersForColumns = (fields, section: string = "general") => {
  fields.map((x) => {
    addStickyFilters(x, { read: 1, field: x, value: [] }, section);
  });
};
