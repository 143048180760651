import React from "react";

import CustomAutoCompleteFilter from "../../lib/components/customdatagridfilter/CustomFilter";

const cellWidthTiny = 50;
const cellWidthSmall = 80;
const cellWidthMedium = 150;
const cellWidthLarge = 200;

const defaultColumnProperties = {
  resizable: true,
  sortable: true,
  filterable: true,
};

import Tooltip from "@material-ui/core/Tooltip";
import WarningIcon from "@material-ui/icons/Warning";
import { SummaryNumber, SummaryNumber2Decimal } from "../../lib/components/SummaryFormatter";
import { FormatterNumber, FormatterNumber2Decimals, TextFormatter } from "../../lib/components/Formatters";
import { CustomEnquiryRenderer } from "./edifilecomparisoncurrentgridsetup";
import { Selector } from "../../lib/components/EnquiryLink";
import { handleColumnsArrangement, handleColumnsWidth } from "../../lib/helpers/grid";

let currentSpace = true;

export const ErrorCellFormatter = (row, column) => {
  const isError = row.edicomparisonerror || "";

  if (isError != "") {
    return (
      <Tooltip title={isError}>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <WarningIcon style={{ color: isError.includes("Missing") ? "white" : "red" }} />
        </div>
      </Tooltip>
    );
  }

  return <div></div>;
};

export const ColumnsEDIFileComparison = (data, filters, arrangement, columnsWidth) => {
  let postValueInfo = "";

  if (data) {
    if (currentSpace) {
      postValueInfo = " ";
    }
    currentSpace = !currentSpace;
  }

  const columns = [
    {
      key: "edicomparisonerror",
      name: "#E" + postValueInfo,
      width: cellWidthTiny,
      formatter: ({ row, column }) => ErrorCellFormatter(row, column),
      filterRenderer: (e) => {
        return (
          <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"edicomparisonerror"} data={data} section={"edifilecomparisoncomparison"} />
        );
      },
    },
    {
      key: "Location Code",
      name: "Location Code" + postValueInfo,
      width: cellWidthSmall,
      formatter: TextFormatter,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Location Code"} data={data} section={"edifilecomparisoncomparison"} />;
      },
    },
    {
      key: "Farm Number",
      name: "Farm Number" + postValueInfo,
      width: cellWidthSmall,
      formatter: TextFormatter,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Farm Number"} data={data} section={"edifilecomparisoncomparison"} />;
      },
    },
    {
      key: "Orig Intake Waybill",
      name: "Orig Intake Waybill" + postValueInfo,
      width: cellWidthSmall,
      formatter: (props) => <CustomEnquiryRenderer {...props} selector={Selector.IntakeWaybill} />,
      filterRenderer: (e) => {
        return (
          <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Orig Intake Waybill"} data={data} section={"edifilecomparisoncomparison"} />
        );
      },
    },
    {
      key: "Intake Waybill",
      name: "Intake Waybill" + postValueInfo,
      width: cellWidthSmall,
      formatter: (props) => <CustomEnquiryRenderer {...props} selector={Selector.IntakeWaybill} />,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Intake Waybill"} data={data} section={"edifilecomparisoncomparison"} />;
      },
    },
    {
      key: "Barcode",
      name: "Barcode" + postValueInfo,
      width: cellWidthSmall,
      formatter: (props) => <CustomEnquiryRenderer {...props} selector={Selector.Barcode} />,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Barcode"} data={data} section={"edifilecomparisoncomparison"} />;
      },
    },
    {
      key: "No Cartons",
      name: "No Cartons" + postValueInfo,
      width: cellWidthSmall,
      formatter: FormatterNumber,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"No Cartons"} data={data} section={"edifilecomparisoncomparison"} />;
      },
      summaryFormatter: SummaryNumber,
    },
    {
      key: "Pallet Size",
      name: "Pallet Size" + postValueInfo,
      width: cellWidthSmall,
      formatter: FormatterNumber2Decimals,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Pallet Size"} data={data} section={"edifilecomparisoncomparison"} />;
      },
      summaryFormatter: SummaryNumber2Decimal,
    },
    {
      key: "Commodity Code",
      name: "Commodity Code" + postValueInfo,
      width: cellWidthSmall,
      formatter: TextFormatter,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Commodity Code"} data={data} section={"edifilecomparisoncomparison"} />;
      },
    },
    {
      key: "Variety Code",
      name: "Variety Code" + postValueInfo,
      width: cellWidthSmall,
      formatter: TextFormatter,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Variety Code"} data={data} section={"edifilecomparisoncomparison"} />;
      },
    },
    {
      key: "Grade Code",
      name: "Grade Code" + postValueInfo,
      width: cellWidthSmall,
      formatter: TextFormatter,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Grade Code"} data={data} section={"edifilecomparisoncomparison"} />;
      },
    },
    {
      key: "Count Code",
      name: "Count Code" + postValueInfo,
      width: cellWidthSmall,
      formatter: TextFormatter,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Count Code"} data={data} section={"edifilecomparisoncomparison"} />;
      },
    },
    {
      key: "Pack Code",
      name: "Pack Code" + postValueInfo,
      width: cellWidthSmall,
      formatter: TextFormatter,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Pack Code"} data={data} section={"edifilecomparisoncomparison"} />;
      },
    },
    {
      key: "Mark Code",
      name: "Mark Code" + postValueInfo,
      width: cellWidthSmall,
      formatter: TextFormatter,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Mark Code"} data={data} section={"edifilecomparisoncomparison"} />;
      },
    },
    {
      key: "Inventory Code",
      name: "Inventory Code" + postValueInfo,
      width: cellWidthSmall,
      formatter: TextFormatter,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Inventory Code"} data={data} section={"edifilecomparisoncomparison"} />;
      },
    },
    {
      key: "Date Entered",
      name: "Date Entered" + postValueInfo,
      width: cellWidthSmall,
      formatter: TextFormatter,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Date Entered"} data={data} section={"edifilecomparisoncomparison"} />;
      },
    },
    {
      key: "filename",
      name: "Filename" + postValueInfo,
      width: cellWidthLarge,
      formatter: TextFormatter,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"filename"} data={data} section={"edifilecomparisoncomparison"} />;
      },
    },
    {
      key: "Pallet ID",
      name: "Pallet ID" + postValueInfo,
      width: cellWidthSmall,
      formatter: TextFormatter,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Pallet ID"} data={data} section={"edifilecomparisoncomparison"} />;
      },
    },
    {
      key: "Producer ID",
      name: "Producer ID" + postValueInfo,
      width: cellWidthSmall,
      formatter: TextFormatter,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Producer ID"} data={data} section={"edifilecomparisoncomparison"} />;
      },
    },
    {
      key: "Intake ID",
      name: "Intake ID" + postValueInfo,
      width: cellWidthSmall,
      formatter: TextFormatter,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Intake ID"} data={data} section={"edifilecomparisoncomparison"} />;
      },
    },
    {
      key: "Depot",
      name: "Depot" + postValueInfo,
      width: cellWidthSmall,
      formatter: TextFormatter,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Depot"} data={data} section={"edifilecomparisoncomparison"} />;
      },
    },
    {
      key: "Dispatch ID",
      name: "Dispatch ID" + postValueInfo,
      width: cellWidthSmall,
      formatter: TextFormatter,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Dispatch ID"} data={data} section={"edifilecomparisoncomparison"} />;
      },
    },
    {
      key: "Time Entered",
      name: "Time Entered" + postValueInfo,
      width: cellWidthSmall,
      formatter: TextFormatter,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Time Entered"} data={data} section={"edifilecomparisoncomparison"} />;
      },
    },
    {
      key: "Date Dispatched",
      name: "Date Dispatched" + postValueInfo,
      width: cellWidthSmall,
      formatter: TextFormatter,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Date Dispatched"} data={data} section={"edifilecomparisoncomparison"} />;
      },
    },
    {
      key: "Time Dispatched",
      name: "Time Dispatched" + postValueInfo,
      width: cellWidthSmall,
      formatter: TextFormatter,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Time Dispatched"} data={data} section={"edifilecomparisoncomparison"} />;
      },
    },
    {
      key: "Processing",
      name: "Processing" + postValueInfo,
      width: cellWidthSmall,
      formatter: TextFormatter,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Processing"} data={data} section={"edifilecomparisoncomparison"} />;
      },
    },
    {
      key: "Selected",
      name: "Selected" + postValueInfo,
      width: cellWidthSmall,
      formatter: TextFormatter,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Selected"} data={data} section={"edifilecomparisoncomparison"} />;
      },
    },
    {
      key: "Price",
      name: "Price" + postValueInfo,
      width: cellWidthSmall,
      formatter: TextFormatter,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Price"} data={data} section={"edifilecomparisoncomparison"} />;
      },
    },
    {
      key: "Allocated",
      name: "Allocated" + postValueInfo,
      width: cellWidthSmall,
      formatter: TextFormatter,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Allocated"} data={data} section={"edifilecomparisoncomparison"} />;
      },
    },
    {
      key: "Allocation",
      name: "Allocation" + postValueInfo,
      width: cellWidthSmall,
      formatter: TextFormatter,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Allocation"} data={data} section={"edifilecomparisoncomparison"} />;
      },
    },
    {
      key: "Ref No",
      name: "Ref No" + postValueInfo,
      width: cellWidthSmall,
      formatter: TextFormatter,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Ref No"} data={data} section={"edifilecomparisoncomparison"} />;
      },
    },
    {
      key: "Temperature",
      name: "Temperature" + postValueInfo,
      width: cellWidthSmall,
      formatter: TextFormatter,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Temperature"} data={data} section={"edifilecomparisoncomparison"} />;
      },
    },
    {
      key: "Channel",
      name: "Channel" + postValueInfo,
      width: cellWidthSmall,
      formatter: TextFormatter,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Channel"} data={data} section={"edifilecomparisoncomparison"} />;
      },
    },
    {
      key: "Picking Ref",
      name: "Picking Ref" + postValueInfo,
      width: cellWidthSmall,
      formatter: TextFormatter,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Picking Ref"} data={data} section={"edifilecomparisoncomparison"} />;
      },
    },
    {
      key: "Target Market",
      name: "Target Market" + postValueInfo,
      width: cellWidthSmall,
      formatter: TextFormatter,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Target Market"} data={data} section={"edifilecomparisoncomparison"} />;
      },
    },
    {
      key: "Intake Pallet ID",
      name: "Intake Pallet ID" + postValueInfo,
      width: cellWidthSmall,
      formatter: TextFormatter,
      filterRenderer: (e) => {
        return (
          <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Intake Pallet ID"} data={data} section={"edifilecomparisoncomparison"} />
        );
      },
    },
    {
      key: "Pallet Base Type",
      name: "Pallet Base Type" + postValueInfo,
      width: cellWidthSmall,
      formatter: TextFormatter,
      filterRenderer: (e) => {
        return (
          <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Pallet Base Type"} data={data} section={"edifilecomparisoncomparison"} />
        );
      },
    },
    {
      key: "Fail Safe ID",
      name: "Fail Safe ID" + postValueInfo,
      width: cellWidthSmall,
      formatter: TextFormatter,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Fail Safe ID"} data={data} section={"edifilecomparisoncomparison"} />;
      },
    },
    {
      key: "Organization",
      name: "Organization" + postValueInfo,
      width: cellWidthSmall,
      formatter: TextFormatter,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Organization"} data={data} section={"edifilecomparisoncomparison"} />;
      },
    },
    {
      key: "Sequence Number",
      name: "Sequence Number" + postValueInfo,
      width: cellWidthSmall,
      formatter: TextFormatter,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Sequence Number"} data={data} section={"edifilecomparisoncomparison"} />;
      },
    },
    {
      key: "Bay",
      name: "Bay" + postValueInfo,
      width: cellWidthSmall,
      formatter: TextFormatter,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Bay"} data={data} section={"edifilecomparisoncomparison"} />;
      },
    },
    {
      key: "Cold Date",
      name: "Cold Date" + postValueInfo,
      width: cellWidthSmall,
      formatter: TextFormatter,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Cold Date"} data={data} section={"edifilecomparisoncomparison"} />;
      },
    },
    {
      key: "Last Invoice",
      name: "Last Invoice" + postValueInfo,
      width: cellWidthSmall,
      formatter: TextFormatter,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Last Invoice"} data={data} section={"edifilecomparisoncomparison"} />;
      },
    },
    {
      key: "Cooler ID",
      name: "Cooler ID" + postValueInfo,
      width: cellWidthSmall,
      formatter: TextFormatter,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Cooler ID"} data={data} section={"edifilecomparisoncomparison"} />;
      },
    },
    {
      key: "Last Invoice Date",
      name: "Last Invoice Date" + postValueInfo,
      width: cellWidthSmall,
      formatter: TextFormatter,
      filterRenderer: (e) => {
        return (
          <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Last Invoice Date"} data={data} section={"edifilecomparisoncomparison"} />
        );
      },
    },
    {
      key: "Invoicing",
      name: "Invoicing" + postValueInfo,
      width: cellWidthSmall,
      formatter: TextFormatter,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Invoicing"} data={data} section={"edifilecomparisoncomparison"} />;
      },
    },
    {
      key: "Intake Type",
      name: "Intake Type" + postValueInfo,
      width: cellWidthSmall,
      formatter: TextFormatter,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Intake Type"} data={data} section={"edifilecomparisoncomparison"} />;
      },
    },
    {
      key: "Exported",
      name: "Exported" + postValueInfo,
      width: cellWidthSmall,
      formatter: TextFormatter,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Exported"} data={data} section={"edifilecomparisoncomparison"} />;
      },
    },
    {
      key: "PalTrak",
      name: "PalTrak" + postValueInfo,
      width: cellWidthSmall,
      formatter: TextFormatter,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"PalTrak"} data={data} section={"edifilecomparisoncomparison"} />;
      },
    },
    {
      key: "Rebuilt",
      name: "Rebuilt" + postValueInfo,
      width: cellWidthSmall,
      formatter: TextFormatter,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Rebuilt"} data={data} section={"edifilecomparisoncomparison"} />;
      },
    },
    {
      key: "Last Edit Date",
      name: "Last Edit Date" + postValueInfo,
      width: cellWidthSmall,
      formatter: TextFormatter,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Last Edit Date"} data={data} section={"edifilecomparisoncomparison"} />;
      },
    },
    {
      key: "Pallet Movement",
      name: "Pallet Movement" + postValueInfo,
      width: cellWidthSmall,
      formatter: TextFormatter,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Pallet Movement"} data={data} section={"edifilecomparisoncomparison"} />;
      },
    },
    {
      key: "Allocated Stock",
      name: "Allocated Stock" + postValueInfo,
      width: cellWidthSmall,
      formatter: TextFormatter,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Allocated Stock"} data={data} section={"edifilecomparisoncomparison"} />;
      },
    },
    {
      key: "Rejected",
      name: "Rejected" + postValueInfo,
      width: cellWidthSmall,
      formatter: TextFormatter,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Rejected"} data={data} section={"edifilecomparisoncomparison"} />;
      },
    },
    {
      key: "Transfer",
      name: "Transfer" + postValueInfo,
      width: cellWidthSmall,
      formatter: TextFormatter,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Transfer"} data={data} section={"edifilecomparisoncomparison"} />;
      },
    },
    {
      key: "Mini Pallet",
      name: "Mini Pallet" + postValueInfo,
      width: cellWidthSmall,
      formatter: TextFormatter,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Mini Pallet"} data={data} section={"edifilecomparisoncomparison"} />;
      },
    },
    {
      key: "SV",
      name: "SV" + postValueInfo,
      width: cellWidthSmall,
      formatter: TextFormatter,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"SV"} data={data} section={"edifilecomparisoncomparison"} />;
      },
    },
    {
      key: "Product Group",
      name: "Product Group" + postValueInfo,
      width: cellWidthSmall,
      formatter: TextFormatter,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Product Group"} data={data} section={"edifilecomparisoncomparison"} />;
      },
    },
    {
      key: "Product Char",
      name: "Product Char" + postValueInfo,
      width: cellWidthSmall,
      formatter: TextFormatter,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Product Char"} data={data} section={"edifilecomparisoncomparison"} />;
      },
    },
    {
      key: "Reject Reason",
      name: "Reject Reason" + postValueInfo,
      width: cellWidthSmall,
      formatter: TextFormatter,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Reject Reason"} data={data} section={"edifilecomparisoncomparison"} />;
      },
    },
    {
      key: "Mixed Ind",
      name: "Mixed Ind" + postValueInfo,
      width: cellWidthSmall,
      formatter: TextFormatter,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Mixed Ind"} data={data} section={"edifilecomparisoncomparison"} />;
      },
    },
    {
      key: "Weight",
      name: "Weight" + postValueInfo,
      width: cellWidthSmall,
      formatter: FormatterNumber2Decimals,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Weight"} data={data} section={"edifilecomparisoncomparison"} />;
      },
      summaryFormatter: SummaryNumber2Decimal,
    },
    {
      key: "Stock Pool",
      name: "Stock Pool" + postValueInfo,
      width: cellWidthSmall,
      formatter: TextFormatter,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Stock Pool"} data={data} section={"edifilecomparisoncomparison"} />;
      },
    },
    {
      key: "Country",
      name: "Country" + postValueInfo,
      width: cellWidthSmall,
      formatter: TextFormatter,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Country"} data={data} section={"edifilecomparisoncomparison"} />;
      },
    },
    {
      key: "SSCC",
      name: "SSCC" + postValueInfo,
      width: cellWidthSmall,
      formatter: TextFormatter,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"SSCC"} data={data} section={"edifilecomparisoncomparison"} />;
      },
    },
    {
      key: "Orig Intake Date",
      name: "Orig Intake Date" + postValueInfo,
      width: cellWidthSmall,
      formatter: TextFormatter,
      filterRenderer: (e) => {
        return (
          <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Orig Intake Date"} data={data} section={"edifilecomparisoncomparison"} />
        );
      },
    },
    {
      key: "PHC",
      name: "PHC" + postValueInfo,
      width: cellWidthSmall,
      formatter: TextFormatter,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"PHC"} data={data} section={"edifilecomparisoncomparison"} />;
      },
    },
    {
      key: "Sell By",
      name: "Sell By" + postValueInfo,
      width: cellWidthSmall,
      formatter: TextFormatter,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Sell By"} data={data} section={"edifilecomparisoncomparison"} />;
      },
    },
    {
      key: "Stock Value",
      name: "Stock Value" + postValueInfo,
      width: cellWidthSmall,
      formatter: TextFormatter,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Stock Value"} data={data} section={"edifilecomparisoncomparison"} />;
      },
    },
    {
      key: "Old Barcode",
      name: "Old Barcode" + postValueInfo,
      width: cellWidthSmall,
      formatter: TextFormatter,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Old Barcode"} data={data} section={"edifilecomparisoncomparison"} />;
      },
    },
    {
      key: "Trader ID",
      name: "Trader ID" + postValueInfo,
      width: cellWidthSmall,
      formatter: TextFormatter,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Trader ID"} data={data} section={"edifilecomparisoncomparison"} />;
      },
    },
    {
      key: "Consignee ID",
      name: "Consignee ID" + postValueInfo,
      width: cellWidthSmall,
      formatter: TextFormatter,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Consignee ID"} data={data} section={"edifilecomparisoncomparison"} />;
      },
    },
    {
      key: "Inspector Name",
      name: "Inspector Name" + postValueInfo,
      width: cellWidthSmall,
      formatter: TextFormatter,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Inspector Name"} data={data} section={"edifilecomparisoncomparison"} />;
      },
    },
    {
      key: "Inspection Point",
      name: "Inspection Point" + postValueInfo,
      width: cellWidthSmall,
      formatter: TextFormatter,
      filterRenderer: (e) => {
        return (
          <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Inspection Point"} data={data} section={"edifilecomparisoncomparison"} />
        );
      },
    },
    {
      key: "Inspection Date",
      name: "Inspection Date" + postValueInfo,
      width: cellWidthSmall,
      formatter: TextFormatter,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Inspection Date"} data={data} section={"edifilecomparisoncomparison"} />;
      },
    },
    {
      key: "Inspection Time",
      name: "Inspection Time" + postValueInfo,
      width: cellWidthSmall,
      formatter: TextFormatter,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Inspection Time"} data={data} section={"edifilecomparisoncomparison"} />;
      },
    },
    {
      key: "Inspection Report",
      name: "Inspection Report" + postValueInfo,
      width: cellWidthSmall,
      formatter: TextFormatter,
      filterRenderer: (e) => {
        return (
          <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Inspection Report"} data={data} section={"edifilecomparisoncomparison"} />
        );
      },
    },
    {
      key: "UserName",
      name: "UserName" + postValueInfo,
      width: cellWidthSmall,
      formatter: TextFormatter,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"UserName"} data={data} section={"edifilecomparisoncomparison"} />;
      },
    },
    {
      key: "dispatch customer id",
      name: "dispatch customer id" + postValueInfo,
      width: cellWidthSmall,
      formatter: TextFormatter,
      filterRenderer: (e) => {
        return (
          <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"dispatch customer id"} data={data} section={"edifilecomparisoncomparison"} />
        );
      },
    },
    {
      key: "Temp Tail",
      name: "Temp Tail" + postValueInfo,
      width: cellWidthSmall,
      formatter: TextFormatter,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Temp Tail"} data={data} section={"edifilecomparisoncomparison"} />;
      },
    },
    {
      key: "Batch",
      name: "Batch" + postValueInfo,
      width: cellWidthSmall,
      formatter: TextFormatter,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Batch"} data={data} section={"edifilecomparisoncomparison"} />;
      },
    },
    {
      key: "Orchard",
      name: "Orchard" + postValueInfo,
      width: cellWidthSmall,
      formatter: TextFormatter,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Orchard"} data={data} section={"edifilecomparisoncomparison"} />;
      },
    },
    {
      key: "Internal Barcode",
      name: "Internal Barcode" + postValueInfo,
      width: cellWidthSmall,
      formatter: TextFormatter,
      filterRenderer: (e) => {
        return (
          <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Internal Barcode"} data={data} section={"edifilecomparisoncomparison"} />
        );
      },
    },
    {
      key: "QC Flag",
      name: "QC Flag" + postValueInfo,
      width: cellWidthSmall,
      formatter: TextFormatter,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"QC Flag"} data={data} section={"edifilecomparisoncomparison"} />;
      },
    },
    {
      key: "QC User",
      name: "QC User" + postValueInfo,
      width: cellWidthSmall,
      formatter: TextFormatter,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"QC User"} data={data} section={"edifilecomparisoncomparison"} />;
      },
    },
    {
      key: "QC Blocked",
      name: "QC Blocked" + postValueInfo,
      width: cellWidthSmall,
      formatter: TextFormatter,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"QC Blocked"} data={data} section={"edifilecomparisoncomparison"} />;
      },
    },
    {
      key: "Order ID",
      name: "Order ID" + postValueInfo,
      width: cellWidthSmall,
      formatter: TextFormatter,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Order ID"} data={data} section={"edifilecomparisoncomparison"} />;
      },
    },
    {
      key: "Hatch",
      name: "Hatch" + postValueInfo,
      width: cellWidthSmall,
      formatter: TextFormatter,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Hatch"} data={data} section={"edifilecomparisoncomparison"} />;
      },
    },
    {
      key: "Deck",
      name: "Deck" + postValueInfo,
      width: cellWidthSmall,
      formatter: TextFormatter,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Deck"} data={data} section={"edifilecomparisoncomparison"} />;
      },
    },
    {
      key: "Orig Location Code",
      name: "Orig Location Code" + postValueInfo,
      width: cellWidthSmall,
      formatter: TextFormatter,
      filterRenderer: (e) => {
        return (
          <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Orig Location Code"} data={data} section={"edifilecomparisoncomparison"} />
        );
      },
    },
    {
      key: "Tax",
      name: "Tax" + postValueInfo,
      width: cellWidthSmall,
      formatter: TextFormatter,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Tax"} data={data} section={"edifilecomparisoncomparison"} />;
      },
    },
    {
      key: "Production ID",
      name: "Production ID" + postValueInfo,
      width: cellWidthSmall,
      formatter: TextFormatter,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Production ID"} data={data} section={"edifilecomparisoncomparison"} />;
      },
    },
    {
      key: "From Location Code",
      name: "From Location Code" + postValueInfo,
      width: cellWidthSmall,
      formatter: TextFormatter,
      filterRenderer: (e) => {
        return (
          <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"From Location Code"} data={data} section={"edifilecomparisoncomparison"} />
        );
      },
    },
    {
      key: "Verify",
      name: "Verify" + postValueInfo,
      width: cellWidthSmall,
      formatter: TextFormatter,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Verify"} data={data} section={"edifilecomparisoncomparison"} />;
      },
    },
    {
      key: "To Location Code",
      name: "To Location Code" + postValueInfo,
      width: cellWidthSmall,
      formatter: TextFormatter,
      filterRenderer: (e) => {
        return (
          <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"To Location Code"} data={data} section={"edifilecomparisoncomparison"} />
        );
      },
    },
    {
      key: "FruitTrak Internal Pack Code",
      name: "FruitTrak Internal Pack Code" + postValueInfo,
      width: cellWidthSmall,
      formatter: TextFormatter,
      filterRenderer: (e) => {
        return (
          <CustomAutoCompleteFilter
            {...e}
            multiSelection={true}
            currentFilters={filters}
            fieldname={"FruitTrak Internal Pack Code"}
            data={data}
            section={"edifilecomparisoncomparison"}
          />
        );
      },
    },
    {
      key: "Order No",
      name: "Order No" + postValueInfo,
      width: cellWidthSmall,
      formatter: TextFormatter,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Order No"} data={data} section={"edifilecomparisoncomparison"} />;
      },
    },
    {
      key: "Purchase Price",
      name: "Purchase Price" + postValueInfo,
      width: cellWidthSmall,
      formatter: TextFormatter,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Purchase Price"} data={data} section={"edifilecomparisoncomparison"} />;
      },
    },
    {
      key: "Seal Number",
      name: "Seal Number" + postValueInfo,
      width: cellWidthSmall,
      formatter: TextFormatter,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Seal Number"} data={data} section={"edifilecomparisoncomparison"} />;
      },
    },
    {
      key: "Advance Price",
      name: "Advance Price" + postValueInfo,
      width: cellWidthSmall,
      formatter: TextFormatter,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Advance Price"} data={data} section={"edifilecomparisoncomparison"} />;
      },
    },
    {
      key: "Load Date",
      name: "Load Date" + postValueInfo,
      width: cellWidthSmall,
      formatter: TextFormatter,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Load Date"} data={data} section={"edifilecomparisoncomparison"} />;
      },
    },
    {
      key: "Start Loading Time",
      name: "Start Loading Time" + postValueInfo,
      width: cellWidthSmall,
      formatter: TextFormatter,
      filterRenderer: (e) => {
        return (
          <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Start Loading Time"} data={data} section={"edifilecomparisoncomparison"} />
        );
      },
    },
    {
      key: "Local FOB",
      name: "Local FOB" + postValueInfo,
      width: cellWidthSmall,
      formatter: TextFormatter,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Local FOB"} data={data} section={"edifilecomparisoncomparison"} />;
      },
    },
    {
      key: "Pre Allocated",
      name: "Pre Allocated" + postValueInfo,
      width: cellWidthSmall,
      formatter: TextFormatter,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Pre Allocated"} data={data} section={"edifilecomparisoncomparison"} />;
      },
    },
    {
      key: "Insurance Value",
      name: "Insurance Value" + postValueInfo,
      width: cellWidthSmall,
      formatter: TextFormatter,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Insurance Value"} data={data} section={"edifilecomparisoncomparison"} />;
      },
    },
    {
      key: "Depot Stock  ID",
      name: "Depot Stock  ID" + postValueInfo,
      width: cellWidthSmall,
      formatter: TextFormatter,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Depot Stock  ID"} data={data} section={"edifilecomparisoncomparison"} />;
      },
    },
    {
      key: "Inner Pack",
      name: "Inner Pack" + postValueInfo,
      width: cellWidthSmall,
      formatter: TextFormatter,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Inner Pack"} data={data} section={"edifilecomparisoncomparison"} />;
      },
    },
    {
      key: "Temp Type",
      name: "Temp Type" + postValueInfo,
      width: cellWidthSmall,
      formatter: TextFormatter,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Temp Type"} data={data} section={"edifilecomparisoncomparison"} />;
      },
    },
    {
      key: "Inner Cartons",
      name: "Inner Cartons" + postValueInfo,
      width: cellWidthSmall,
      formatter: TextFormatter,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Inner Cartons"} data={data} section={"edifilecomparisoncomparison"} />;
      },
    },
    {
      key: "Pallet Status",
      name: "Pallet Status" + postValueInfo,
      width: cellWidthSmall,
      formatter: TextFormatter,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Pallet Status"} data={data} section={"edifilecomparisoncomparison"} />;
      },
    },
    {
      key: "Contract ID",
      name: "Contract ID" + postValueInfo,
      width: cellWidthSmall,
      formatter: TextFormatter,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Contract ID"} data={data} section={"edifilecomparisoncomparison"} />;
      },
    },
    {
      key: "Receiver",
      name: "Receiver" + postValueInfo,
      width: cellWidthSmall,
      formatter: TextFormatter,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Receiver"} data={data} section={"edifilecomparisoncomparison"} />;
      },
    },
    {
      key: "Target Price",
      name: "Target Price" + postValueInfo,
      width: cellWidthSmall,
      formatter: TextFormatter,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Target Price"} data={data} section={"edifilecomparisoncomparison"} />;
      },
    },
    {
      key: "Must GO",
      name: "Must GO" + postValueInfo,
      width: cellWidthSmall,
      formatter: TextFormatter,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Must GO"} data={data} section={"edifilecomparisoncomparison"} />;
      },
    },
    {
      key: "Advance Purchase",
      name: "Advance Purchase" + postValueInfo,
      width: cellWidthSmall,
      formatter: TextFormatter,
      filterRenderer: (e) => {
        return (
          <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Advance Purchase"} data={data} section={"edifilecomparisoncomparison"} />
        );
      },
    },
    {
      key: "Principal",
      name: "Principal" + postValueInfo,
      width: cellWidthSmall,
      formatter: TextFormatter,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Principal"} data={data} section={"edifilecomparisoncomparison"} />;
      },
    },
    {
      key: "Target Region",
      name: "Target Region" + postValueInfo,
      width: cellWidthSmall,
      formatter: TextFormatter,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Target Region"} data={data} section={"edifilecomparisoncomparison"} />;
      },
    },
    {
      key: "Target Country",
      name: "Target Country" + postValueInfo,
      width: cellWidthSmall,
      formatter: TextFormatter,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Target Country"} data={data} section={"edifilecomparisoncomparison"} />;
      },
    },
    {
      key: "QC Selected",
      name: "QC Selected" + postValueInfo,
      width: cellWidthSmall,
      formatter: TextFormatter,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"QC Selected"} data={data} section={"edifilecomparisoncomparison"} />;
      },
    },
    {
      key: "QC UserName",
      name: "QC UserName" + postValueInfo,
      width: cellWidthSmall,
      formatter: TextFormatter,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"QC UserName"} data={data} section={"edifilecomparisoncomparison"} />;
      },
    },
    {
      key: "Special Order",
      name: "Special Order" + postValueInfo,
      width: cellWidthSmall,
      formatter: TextFormatter,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Special Order"} data={data} section={"edifilecomparisoncomparison"} />;
      },
    },
    {
      key: "Destination Type CN",
      name: "Destination Type CN" + postValueInfo,
      width: cellWidthSmall,
      formatter: TextFormatter,
      filterRenderer: (e) => {
        return (
          <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Destination Type CN"} data={data} section={"edifilecomparisoncomparison"} />
        );
      },
    },
    {
      key: "Best Before",
      name: "Best Before" + postValueInfo,
      width: cellWidthSmall,
      formatter: TextFormatter,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Best Before"} data={data} section={"edifilecomparisoncomparison"} />;
      },
    },
    {
      key: "Display Until",
      name: "Display Until" + postValueInfo,
      width: cellWidthSmall,
      formatter: TextFormatter,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Display Until"} data={data} section={"edifilecomparisoncomparison"} />;
      },
    },
    {
      key: "Expiry Code",
      name: "Expiry Code" + postValueInfo,
      width: cellWidthSmall,
      formatter: TextFormatter,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Expiry Code"} data={data} section={"edifilecomparisoncomparison"} />;
      },
    },
    {
      key: "Orig PHC Location Code",
      name: "Orig PHC Location Code" + postValueInfo,
      width: cellWidthSmall,
      formatter: TextFormatter,
      filterRenderer: (e) => {
        return (
          <CustomAutoCompleteFilter
            {...e}
            multiSelection={true}
            currentFilters={filters}
            fieldname={"Orig PHC Location Code"}
            data={data}
            section={"edifilecomparisoncomparison"}
          />
        );
      },
    },
    {
      key: "PHC Location Code",
      name: "PHC Location Code" + postValueInfo,
      width: cellWidthSmall,
      formatter: TextFormatter,
      filterRenderer: (e) => {
        return (
          <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"PHC Location Code"} data={data} section={"edifilecomparisoncomparison"} />
        );
      },
    },
    {
      key: "Estimate Return",
      name: "Estimate Return" + postValueInfo,
      width: cellWidthSmall,
      formatter: TextFormatter,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Estimate Return"} data={data} section={"edifilecomparisoncomparison"} />;
      },
    },
    {
      key: "Purchase Pallet ID",
      name: "Purchase Pallet ID" + postValueInfo,
      width: cellWidthSmall,
      formatter: TextFormatter,
      filterRenderer: (e) => {
        return (
          <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Purchase Pallet ID"} data={data} section={"edifilecomparisoncomparison"} />
        );
      },
    },
    {
      key: "GTIN ID",
      name: "GTIN ID" + postValueInfo,
      width: cellWidthSmall,
      formatter: TextFormatter,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"GTIN ID"} data={data} section={"edifilecomparisoncomparison"} />;
      },
    },
    {
      key: "Season",
      name: "Season" + postValueInfo,
      width: cellWidthSmall,
      formatter: TextFormatter,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Season"} data={data} section={"edifilecomparisoncomparison"} />;
      },
    },
    {
      key: "Row",
      name: "Row" + postValueInfo,
      width: cellWidthSmall,
      formatter: TextFormatter,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Row"} data={data} section={"edifilecomparisoncomparison"} />;
      },
    },
    {
      key: "Position",
      name: "Position" + postValueInfo,
      width: cellWidthSmall,
      formatter: TextFormatter,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Position"} data={data} section={"edifilecomparisoncomparison"} />;
      },
    },
    {
      key: "Custom Field 1",
      name: "Custom Field 1" + postValueInfo,
      width: cellWidthSmall,
      formatter: TextFormatter,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Custom Field 1"} data={data} section={"edifilecomparisoncomparison"} />;
      },
    },
    {
      key: "Transaction Date",
      name: "Transaction Date" + postValueInfo,
      width: cellWidthSmall,
      formatter: TextFormatter,
      filterRenderer: (e) => {
        return (
          <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Transaction Date"} data={data} section={"edifilecomparisoncomparison"} />
        );
      },
    },
    {
      key: "Orig Farm Number",
      name: "Orig Farm Number" + postValueInfo,
      width: cellWidthSmall,
      formatter: TextFormatter,
      filterRenderer: (e) => {
        return (
          <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Orig Farm Number"} data={data} section={"edifilecomparisoncomparison"} />
        );
      },
    },
    {
      key: "Complexity",
      name: "Complexity" + postValueInfo,
      width: cellWidthSmall,
      formatter: TextFormatter,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Complexity"} data={data} section={"edifilecomparisoncomparison"} />;
      },
    },
    {
      key: "Intake Stock Value",
      name: "Intake Stock Value" + postValueInfo,
      width: cellWidthSmall,
      formatter: TextFormatter,
      filterRenderer: (e) => {
        return (
          <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Intake Stock Value"} data={data} section={"edifilecomparisoncomparison"} />
        );
      },
    },
    {
      key: "Gross Weight",
      name: "Gross Weight" + postValueInfo,
      width: cellWidthSmall,
      formatter: FormatterNumber2Decimals,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Gross Weight"} data={data} section={"edifilecomparisoncomparison"} />;
      },
      summaryFormatter: SummaryNumber2Decimal,
    },
    {
      key: "Orig Inspection Date",
      name: "Orig Inspection Date" + postValueInfo,
      width: cellWidthSmall,
      formatter: TextFormatter,
      filterRenderer: (e) => {
        return (
          <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Orig Inspection Date"} data={data} section={"edifilecomparisoncomparison"} />
        );
      },
    },
    {
      key: "UPN",
      name: "UPN" + postValueInfo,
      width: cellWidthSmall,
      formatter: TextFormatter,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"UPN"} data={data} section={"edifilecomparisoncomparison"} />;
      },
    },
    {
      key: "Actual Variety",
      name: "Actual Variety" + postValueInfo,
      width: cellWidthSmall,
      formatter: TextFormatter,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Actual Variety"} data={data} section={"edifilecomparisoncomparison"} />;
      },
    },
    {
      key: "PalletTreatment ID",
      name: "PalletTreatment ID" + postValueInfo,
      width: cellWidthSmall,
      formatter: TextFormatter,
      filterRenderer: (e) => {
        return (
          <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"PalletTreatment ID"} data={data} section={"edifilecomparisoncomparison"} />
        );
      },
    },
    {
      key: "Pooling Packaging Factor",
      name: "Pooling Packaging Factor" + postValueInfo,
      width: cellWidthSmall,
      formatter: TextFormatter,
      filterRenderer: (e) => {
        return (
          <CustomAutoCompleteFilter
            {...e}
            multiSelection={true}
            currentFilters={filters}
            fieldname={"Pooling Packaging Factor"}
            data={data}
            section={"edifilecomparisoncomparison"}
          />
        );
      },
    },
    {
      key: "Producer Deal Type",
      name: "Producer Deal Type" + postValueInfo,
      width: cellWidthSmall,
      formatter: TextFormatter,
      filterRenderer: (e) => {
        return (
          <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Producer Deal Type"} data={data} section={"edifilecomparisoncomparison"} />
        );
      },
    },
    {
      key: "Weighing Location",
      name: "Weighing Location" + postValueInfo,
      width: cellWidthSmall,
      formatter: TextFormatter,
      filterRenderer: (e) => {
        return (
          <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Weighing Location"} data={data} section={"edifilecomparisoncomparison"} />
        );
      },
    },
    {
      key: "Weighing Date Time",
      name: "Weighing Date Time" + postValueInfo,
      width: cellWidthSmall,
      formatter: TextFormatter,
      filterRenderer: (e) => {
        return (
          <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Weighing Date Time"} data={data} section={"edifilecomparisoncomparison"} />
        );
      },
    },
    {
      key: "Vitrax UID",
      name: "Vitrax UID" + postValueInfo,
      width: cellWidthSmall,
      formatter: TextFormatter,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Vitrax UID"} data={data} section={"edifilecomparisoncomparison"} />;
      },
    },
    {
      key: "Fruittrak Intake ID",
      name: "Fruittrak Intake ID" + postValueInfo,
      width: cellWidthSmall,
      formatter: TextFormatter,
      filterRenderer: (e) => {
        return (
          <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Fruittrak Intake ID"} data={data} section={"edifilecomparisoncomparison"} />
        );
      },
    },
    {
      key: "Phyto Verification",
      name: "Phyto Verification" + postValueInfo,
      width: cellWidthSmall,
      formatter: TextFormatter,
      filterRenderer: (e) => {
        return (
          <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Phyto Verification"} data={data} section={"edifilecomparisoncomparison"} />
        );
      },
    },
    {
      key: "Inner Carton Weight",
      name: "Inner Carton Weight" + postValueInfo,
      width: cellWidthSmall,
      formatter: TextFormatter,
      filterRenderer: (e) => {
        return (
          <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Inner Carton Weight"} data={data} section={"edifilecomparisoncomparison"} />
        );
      },
    },
    {
      key: "VAT Code",
      name: "VAT Code" + postValueInfo,
      width: cellWidthSmall,
      formatter: TextFormatter,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"VAT Code"} data={data} section={"edifilecomparisoncomparison"} />;
      },
    },
    {
      key: "PQI Number",
      name: "PQI Number" + postValueInfo,
      width: cellWidthSmall,
      formatter: TextFormatter,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"PQI Number"} data={data} section={"edifilecomparisoncomparison"} />;
      },
    },
    {
      key: "Pricing Sheet Applied Date",
      name: "Pricing Sheet Applied Date" + postValueInfo,
      width: cellWidthSmall,
      formatter: TextFormatter,
      filterRenderer: (e) => {
        return (
          <CustomAutoCompleteFilter
            {...e}
            multiSelection={true}
            currentFilters={filters}
            fieldname={"Pricing Sheet Applied Date"}
            data={data}
            section={"edifilecomparisoncomparison"}
          />
        );
      },
    },
    {
      key: "Pack Date",
      name: "Pack Date" + postValueInfo,
      width: cellWidthSmall,
      formatter: TextFormatter,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Pack Date"} data={data} section={"edifilecomparisoncomparison"} />;
      },
    },
  ].map((c) => ({ ...c, ...defaultColumnProperties }));

  let columnsArranged = [];
  columnsArranged = handleColumnsArrangement(columnsArranged, arrangement, columns);
  columnsArranged = handleColumnsWidth(columnsArranged, columnsWidth, columns);
  return columnsArranged;
};
