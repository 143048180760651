import { client, request } from "./_base";

const endpoint = "producersaleadjustmentdetail";

export type ProducerSaleAdjustmentDetailType = {
  id?: number;
  producersaleadjustment_id: number;
  producersaledetail_id: number;
  amount: number;
  cartons: number;
  created_at?: Date;
  updated_at?: Date;
};

export enum InvoiceType {
  Adjustment = -99,
}

export const producersaleadjustmentdetail = client(endpoint, (item) => ({
  ...item,
}));

export type ProducerSaleAdjustmentBySaleIdType = {
  saleadjustmentdetail_id: number;
  saleadjustmentdetail_amount: number;
  saleadjustmentdetail_cartons: number;
  saledetail_barcode: string;
  commodityCode: string;
  varietyCode: string;
  gradeCode: string;
  countCode: string;
  packCode: string;
  markCode: string;
  barcode_cartons: number;
  salesdetail_id: number;
};

export const getProducerSaleAdjustmentByAdjustmentId = async (adjustmentId: number): Promise<ProducerSaleAdjustmentBySaleIdType[]> => {
  const result = await request(`${endpoint}/ex/detailByAdjustmentId/${adjustmentId}`, {
    method: "GET",
  });
  const resp = await result.json();

  if (result.status == 500) {
    throw new Error(resp);
  }

  return resp.data;
};

export const getProducerSaleAdjustmentBySaleId = async (saleid: number): Promise<ProducerSaleAdjustmentBySaleIdType[]> => {
  const result = await request(`${endpoint}/ex/detailBySaleId/${saleid}`, { method: "GET" });
  const resp = await result.json();

  if (result.status == 500) {
    throw new Error(resp);
  }

  return resp.data;
};
