import React from "react";

import CustomAutoCompleteFilter from "../../lib/components/customdatagridfilter/CustomFilter";

import { handleColumnsArrangement, handleColumnsWidth } from "../../lib/helpers/grid";

const cellWidthTiny = 50;
const cellWidthSmall = 100;
const cellWidthSmallX = 130;
const cellWidthMedium = 200;
const cellWidthLarge = 300;

const defaultColumnProperties = {
  resizable: true,
  sortable: true,
};

let currentSpace = true;

export const DebtorsLinkedGridColumns = (data, filters, arrangement, columnsWidth) => {
  let postValue = "";

  if (data) {
    if (currentSpace) {
      postValue = " ";
    }
    currentSpace = !currentSpace;
  }

  const columns = [
    {
      key: "type",
      name: "Type" + postValue,
      width: cellWidthSmallX,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"type"} data={data} section={"debtorslinked"} />;
      },
    },
    {
      key: "sale_invoicenumber",
      name: "Invoice Number" + postValue,
      width: cellWidthSmallX,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"sale_invoicenumber"} data={data} section={"debtorslinked"} />;
      },
    },
    {
      key: "terms_code",
      name: "Terms Code" + postValue,
      width: cellWidthSmallX,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"terms_code"} data={data} section={"debtorslinked"} />;
      },
    },
    {
      key: "client_name",
      name: "Name" + postValue,
      width: cellWidthSmallX,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"client_name"} data={data} section={"debtorslinked"} />;
      },
    },
    {
      key: "client_code",
      name: "Code" + postValue,
      width: cellWidthSmallX,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"client_code"} data={data} section={"debtorslinked"} />;
      },
    },
  ].map((c) => ({ ...c, ...defaultColumnProperties }));

  let columnsArranged = [];
  columnsArranged = handleColumnsArrangement(columnsArranged, arrangement, columns);
  columnsArranged = handleColumnsWidth(columnsArranged, columnsWidth, columns);

  return columnsArranged;
};

const actions = (data) => {
  return null;
};

export default function getCellActions(column, row) {
  if (column.key != "actions") {
    return null;
  }
  const cellActions = {
    actions: actions(row),
  };
  return cellActions[column.key];
}
