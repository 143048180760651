import React from "react";

import CustomAutoCompleteFilter from "../../lib/components/customdatagridfilter/CustomFilter";

import { DateFormatter, FormatterNumber, FormatterNumber2Decimals } from "../../lib/components/Formatters";
import { SummaryNumber, SummaryNumber2Decimal, SummaryTotal } from "../../lib/components/SummaryFormatter";
import { handleColumnsArrangement, handleColumnsWidth } from "../../lib/helpers/grid";

const cellWidthSmall = 65;
const cellWidthMedium = 100;
const cellWidthLarge = 170;

const defaultColumnProperties = {
  filterable: true,
  resizable: true,
  sortable: true,
};

let currentSpace = true;

export const GridColumns = (data, filters, arrangement, columnsWidth) => {
  let postValue = "";

  if (data) {
    if (currentSpace) {
      postValue = " ";
    }
    currentSpace = !currentSpace;
  }

  const columns = [
    {
      key: "barcode",
      name: "Barcode" + postValue,
      width: cellWidthLarge,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"barcode"} data={data} section="barcodesplit" />;
      },
      summaryFormatter: SummaryTotal,
    },
    {
      key: "intakeWaybill",
      name: "Intake Waybill" + postValue,
      width: cellWidthMedium,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"intakeWaybill"} data={data} section="barcodesplit" />;
      },
    },
    {
      key: "loadout_reference",
      name: "Load Ref" + postValue,
      width: cellWidthMedium,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"loadout_reference"} data={data} section="barcodesplit" />;
      },
    },
    {
      key: "sellp",
      name: "Selling Price" + postValue,
      width: cellWidthSmall,
      formatter: FormatterNumber2Decimals,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"sellp"} data={data} section="barcodesplit" />;
      },
    },
    {
      key: "commodityCode",
      name: "Commodity" + postValue,
      width: cellWidthSmall,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"commodityCode"} data={data} section="barcodesplit" />;
      },
    },
    {
      key: "varietyCode",
      name: "Variety" + postValue,
      width: cellWidthSmall,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"varietyCode"} data={data} section="barcodesplit" />;
      },
    },
    {
      key: "producerId",
      name: "Producer" + postValue,
      width: cellWidthMedium,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"producerId"} data={data} section="barcodesplit" />;
      },
    },
    {
      key: "farmNumber",
      name: "PUC" + postValue,
      width: cellWidthSmall,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"farmNumber"} data={data} section="barcodesplit" />;
      },
    },
    {
      key: "gradeCode",
      name: "Grade" + postValue,
      width: cellWidthSmall,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"gradeCode"} data={data} section="barcodesplit" />;
      },
    },
    {
      key: "countCode",
      name: "Count" + postValue,
      width: cellWidthSmall,
      formatter: FormatterNumber,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"countCode"} data={data} section="barcodesplit" />;
      },
    },
    {
      key: "packCode",
      name: "Pack" + postValue,
      width: cellWidthSmall,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"packCode"} data={data} section="barcodesplit" />;
      },
    },
    {
      key: "markCode",
      name: "Mark" + postValue,
      width: cellWidthSmall,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"markCode"} data={data} section="barcodesplit" />;
      },
    },
    {
      key: "noCartons",
      name: "No Cartons" + postValue,
      width: cellWidthSmall,
      formatter: FormatterNumber,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"noCartons"} data={data} section="barcodesplit" />;
      },
      summaryFormatter: SummaryNumber,
    },
    {
      key: "palletSize",
      name: "Pallet Size" + postValue,
      width: cellWidthSmall,
      formatter: FormatterNumber2Decimals,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"palletSize"} data={data} section="barcodesplit" />;
      },
      summaryFormatter: SummaryNumber2Decimal,
    },
    {
      key: "orchard",
      name: "Orchard" + postValue,
      width: cellWidthSmall,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"orchard"} data={data} section="barcodesplit" />;
      },
    },
    {
      key: "grossWeight",
      name: "Gross Weight",
      width: cellWidthMedium,
      formatter: FormatterNumber2Decimals,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"grossWeight"} data={data} section="barcodesplit" />;
      },
      summaryFormatter: SummaryNumber2Decimal,
    },
    {
      key: "organization",
      name: "Org" + postValue,
      width: cellWidthSmall,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"organization"} data={data} section="barcodesplit" />;
      },
    },
    {
      key: "dateEntered",
      name: "Date Entered",
      width: cellWidthMedium,
      formatter: DateFormatter,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"dateEntered"} data={data} section="barcodesplit" />;
      },
    },
  ].map((c) => ({ ...c, ...defaultColumnProperties }));

  let columnsArranged = [];
  columnsArranged = handleColumnsArrangement(columnsArranged, arrangement, columns);
  columnsArranged = handleColumnsWidth(columnsArranged, columnsWidth, columns);
  return columnsArranged;
};

export default function getCellActions(column, row) {
  return null;
}
