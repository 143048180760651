import { client, request } from "./_base";

const endpoint = "inspectionactions";

export const inspectionactions = client(`${endpoint}`, (item) => ({
  ...item,
}));

export const actionsFindByInspectionId = async (id: number) => {
  const result = await request(`${endpoint}/ext/findByInspectionId/${id}`, {
    method: "GET",
  });
  const resp = await result.json();
  if (result.status == 500) {
    throw resp;
  }
  return resp.data;
};
