import { apiHeaders, client, request } from "./_base";

export type ProducerFixedPaidType = {
  id?: number;
  producerfixedpaidhead_id: number;
  producersale_id: number;
  paymentnumber: number;
  amount: number;
  credit: number;
  debit: number;
  created_at?: Date;
  updated_at?: Date;
};

const endpoint = "producerfixedpaid";

export const producerfixedpaid = client(endpoint, (item) => ({
  ...item,
}));

export const getProducerFixedPaidCalculated = async () => {
  return request(`${endpoint}/ext/getProducerFixedPaidCalculated`, {
    headers: apiHeaders(),
    method: "GET",
  }).then(async (result) => {
    if (result.status == 500) {
      const resp = await result.json();
      throw resp;
    }
    return result.json();
  });
};

export const reverseProducerFixedPayment = async (producerfixedpaid_id: number) => {
  return request(`${endpoint}/ext/reversePayment/${producerfixedpaid_id}`, {
    headers: apiHeaders(),
    method: "GET",
  }).then(async (result) => {
    if (result.status == 500) {
      const resp = await result.json();
      throw resp;
    }
    return result;
  });
};
