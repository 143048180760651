import React from "react";

import { withStyles, createStyles, Theme } from "@material-ui/core/styles";

import CustomAutoCompleteFilter from "../../../lib/components/customdatagridfilter/CustomFilter";

import numeral from "numeral";

import DeleteIcon from "@material-ui/icons/Delete";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";

import { CellActionsFormatter } from "../../../lib/helpers/CellActionsFormatter";
import TextEditor from "../../../lib/components/TextEditor";
import DropdownEditor from "../../../lib/components/DropdownEditor";
import { FormatterNumber2Decimals } from "../../../lib/components/Formatters";
import { SummaryNumber2Decimal } from "../../../lib/components/SummaryFormatter";

const cellWidthSmall = 65;
const cellWidthMedium = 100;
const cellWidthMediumX = 120;
const cellWidthLarge = 170;

const defaultColumnProperties = {
  filterable: true,
  resizable: true,
  sortable: true,
};

let currentSpace = true;

const VatTypeFormatter = ({ row, column }) => {
  const data = row[column.key];
  return <div style={{ textAlign: "right" }}>{data == -1 ? "-" : data}</div>;
};

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      textAlign: "right",
      float: "right",
      cursor: "pointer",
      width: "100%",
      justifyContent: "space-between",
      alignItems: "center",
    },
  });

const CustomHeaderUnstyled = (props) => {
  const { classes, column, onSort, sortColumn, sortDirection } = props;

  const handleSort = () => {
    switch (sortDirection) {
      case "":
        return onSort(column.key, "ASC");
      case "NONE":
        return onSort(column.key, "ASC");
      case "ASC":
        return onSort(column.key, "DESC");
      case "DESC":
        return onSort(column.key, "NONE");
      default:
        return onSort(column.key, "NONE");
    }
  };

  return (
    <div className={classes.root} onClick={handleSort}>
      {sortColumn == column.key ? (
        sortDirection == "ASC" ? (
          <ArrowDropUpIcon style={{ fontSize: "1.75rem" }} />
        ) : sortDirection == "DESC" ? (
          <ArrowDropDownIcon style={{ fontSize: "1.75rem" }} />
        ) : (
          <span></span>
        )
      ) : (
        <span></span>
      )}
      {column.name}
    </div>
  );
};

const CustomHeader = withStyles(styles)(CustomHeaderUnstyled);

const SummaryRow = ({ row, column }) => <div style={{ textAlign: "right", fontWeight: "bold" }}>{numeral(row[column.key]).format("0,0.00")}</div>;

export const GridColumns = (data, filters, arrangement, columnsWidth, vattypesComboData, handleRemove, handleEdit) => {
  let postValue = "";

  if (data) {
    if (currentSpace) {
      postValue = " ";
    }
    currentSpace = !currentSpace;
  }

  const columns = [
    {
      key: "actions",
      name: "Actions",
      width: cellWidthMedium,
      formatter: ({ row }) => {
        const actions = cellActions(row, handleRemove, handleEdit);
        return (
          <div style={{ display: "flex", justifyContent: "center", gap: "5px" }}>
            <CellActionsFormatter actions={actions} />
          </div>
        );
      },
      summaryFormatter: () => {
        return <div style={{ textAlign: "right", fontWeight: "bold" }}>TOTALS</div>;
      },
    },
    {
      key: "sequence",
      name: "Group" + postValue,
      width: cellWidthMedium,
      editable: true,
      editor: (props) => <TextEditor {...props} align="right" />,
      formatter: ({ row, column }) => <div style={{ textAlign: "right" }}>{row[column.key]}</div>,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"sequence"} data={data} section="creditorsTemplateDetailItem" />;
      },
    },
    {
      key: "code",
      name: "Code" + postValue,
      width: cellWidthLarge,
      editable: true,
      editor: (props) => <TextEditor {...props} />,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"code"} data={data} section="creditorsTemplateDetailItem" />;
      },
    },
    {
      key: "chargedescription",
      name: "Description" + postValue,
      width: cellWidthLarge,
      editable: true,
      editor: (props) => <TextEditor {...props} />,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"chargedescription"} data={data} section="creditorsTemplateDetailItem" />;
      },
    },
    {
      key: "rate",
      name: "Rate" + postValue,
      width: cellWidthMedium,
      editable: true,
      formatter: FormatterNumber2Decimals,
      summaryFormatter: SummaryNumber2Decimal,
      editor: (props) => <TextEditor {...props} align="right" />,
      headerRenderer: (event) => <CustomHeader {...event} />,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"rate"} data={data} section="creditorsTemplateDetailItem" />;
      },
    },
    {
      key: "units",
      name: "Units" + postValue,
      width: cellWidthMedium,
      editable: true,
      formatter: FormatterNumber2Decimals,
      summaryFormatter: SummaryNumber2Decimal,
      editor: (props) => <TextEditor {...props} align="right" />,
      headerRenderer: (event) => <CustomHeader {...event} />,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"units"} data={data} section="creditorsTemplateDetailItem" />;
      },
    },
    {
      key: "vat",
      name: "Vat" + postValue,
      width: cellWidthMedium,
      editable: true,
      formatter: FormatterNumber2Decimals,
      summaryFormatter: SummaryNumber2Decimal,
      editor: (props) => <DropdownEditor {...props} options={vattypesComboData} />,
      headerRenderer: (event) => <CustomHeader {...event} />,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"vat"} data={data} section="creditorsTemplateDetailItem" />;
      },
    },
    {
      key: "vatamount",
      name: "Vat Amount" + postValue,
      width: cellWidthMediumX,
      editable: false,
      formatter: FormatterNumber2Decimals,
      summaryFormatter: SummaryNumber2Decimal,
      headerRenderer: (event) => <CustomHeader {...event} />,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"vatamount"} data={data} section="creditorsTemplateDetailItem" />;
      },
    },
    {
      key: "total",
      name: "Total" + postValue,
      width: cellWidthMedium,
      editable: false,
      formatter: FormatterNumber2Decimals,
      summaryFormatter: SummaryNumber2Decimal,
      headerRenderer: (event) => <CustomHeader {...event} />,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"total"} data={data} section="creditorsTemplateDetailItem" />;
      },
    },
  ].map((c) => ({ ...c, ...defaultColumnProperties }));

  const columnsArranged = [];

  if (arrangement && arrangement.length > 0) {
    arrangement.map((item) => {
      const indexOfItem = columns.indexOf(columns.find((col) => col.name.trim() == item.toString().trim()));
      if (indexOfItem != -1) {
        columnsArranged.push(columns[indexOfItem]);
      }
    });
  } else {
    columns.map((colItem) => {
      columnsArranged.push(colItem);
    });
  }

  if (columnsWidth && columnsWidth.length > 0) {
    for (let index = 0; index < columnsArranged.length; index++) {
      const element = columnsArranged[index];
      const colItem = columnsWidth.find((colwitem) => colwitem.name.trim() == element.name.trim());
      if (colItem) {
        element.width = colItem.width;
      }
    }
  }

  return columnsArranged;
};

const cellActions = (row, handleRemove, handleCopy) => {
  return [
    {
      icon: (
        <span title="Copy">
          <FileCopyIcon style={{ cursor: "pointer", color: "#4a9de2" }} />
        </span>
      ),
      callback: (e) => {
        handleCopy(row);
      },
    },
    {
      icon: (
        <span title="Remove">
          <DeleteIcon style={{ cursor: "pointer", color: "red" }} />
        </span>
      ),
      callback: (e) => {
        handleRemove(row.id);
      },
    },
  ];
};
