import { client, request, apiHeaders } from "./_base";

const endpoint = "loadoutattachment";
export const loadoutattachment = client(endpoint, (item) => ({
  ...item,
}));

export const loadoutAttachmentByLoadoutID = async (id) => {
  return request(`${endpoint}/getByLoadoutId/${id}/`, {
    method: "GET",
  })
    .then(async (result) => {
      if (result.status == 500) {
        const resp = await result.json();
        throw resp;
      }
      return result.json();
    })
    .then(({ data }) => {
      return data;
    });
};
