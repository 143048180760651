import React from "react";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";

import { ucr } from "../../lib/api/ucrsetup";
import CellActions from "./ucrgridsetup";

import UCREditForm from "./ucreditform";

import { SnackContext } from "../../lib/context/SnackContext";
import { GenerateErrorMessage } from "../../lib/helpers/string_methods";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1),
      width: "100%",
      height: "100%",
    },
    inline: {
      display: "inline",
      position: "absolute",
      marginTop: "7px",
      marginLeft: "7px",
    },
  });

type UCRSetupProps = {} & WithStyles<typeof styles>;

export class UCRUnstyled extends React.Component<UCRSetupProps, any> {
  state = {
    classes: undefined,
    data: [],
    confirmRemoveID: undefined,
    confirmEditID: undefined,
    loading: true,
  };

  constructor(props) {
    super(props);
    this.state.classes = props.classes;
  }

  static contextType = SnackContext;
  context!: React.ContextType<typeof SnackContext>;

  componentDidMount() {
    this.loadData();
  }

  loadData = () => {
    ucr.all().then((data) => {
      this.setState({ loading: false, data: data, confirmEditID: data && data.length > 0 ? data[0].id : "0" }, () => {});
    });
  };

  handleCreate = () => {
    this.setState({ confirmEditID: "0" });
  };

  handleEdit = (id) => {
    this.setState({ confirmEditID: id });
  };

  handleEditClose = () => {
    this.setState({ confirmEditID: undefined });
  };

  handleEditConfirm = (e, item) => {
    e.preventDefault();

    try {
      const id = item["id"];
      delete item["id"];

      if (id == "0") {
        ucr.create({ data: { ...item } }).then(() => this.loadData());
      } else {
        ucr.update(id, { data: { ...item } }).then(() => this.loadData());
      }

      this.context.updateSnack({ show: true, color: "green", message: "Successfully updated UCR." });
    } catch (e) {
      const err = GenerateErrorMessage(e, "failed to retrieve data");
      this.context.updateSnack({ show: true, color: "red", message: err });
    }
  };

  handleRemove = async (id) => {
    this.setState({ confirmRemoveID: id });
  };

  handleRemoveClose = () => {
    this.setState({ confirmRemoveID: undefined });
  };

  handleRemoveConfirm = async () => {
    await ucr.remove(this.state.confirmRemoveID).then(() => {
      this.handleRemoveClose();
      this.loadData();
    });
  };

  getCellActions = (column, row) => {
    return CellActions(column, row, this.handleRemove, this.handleEdit);
  };

  render() {
    const { classes } = this.state;

    return (
      <div className={classes.root}>
        <UCREditForm onSubmit={this.handleEditConfirm} onClose={this.handleEditClose} id={this.state.confirmEditID} />
      </div>
    );
  }
}

export default withStyles(styles)(UCRUnstyled);
