import React, { useState, useEffect } from "react";

import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";

import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";

import { Form, Field } from "react-final-form";

import { getCreditorsReadyForCombo } from "../../../lib/api/creditors";
import { creditorsinvoicedetailitemstemplate } from "../../../lib/api/creditorsinvoicedetailitemstemplate";
import { LinearProgress } from "@material-ui/core";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      gap: `${theme.spacing(2)}px`,
      height: "100%",
      width: "400px",
      padding: `${theme.spacing(1)}px`,
    },
    fieldRow: {
      display: "grid",
      gridTemplateColumns: "100px 1fr",
      justifyContent: "center",
      alignContent: "center",
      gap: `${theme.spacing(2)}px`,
      width: "100%",
    },
    fieldRowLabel: {
      "& p": {
        float: "right",
      },
    },
    actionButtons: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-end",
      width: "100%",
      gap: `${theme.spacing(1)}px`,
    },
  });

type TemplateItem = {
  creditors_id: number;
  default: number;
  id: number;
  items: any[];
  templatedesc: string;
};

type TemplateFormProps = {
  handleClose(id: number): void;
  defaultData: TemplateItem;
} & WithStyles<typeof styles>;

const TemplateFormUnstyled: React.FC<TemplateFormProps> = ({ classes, handleClose, defaultData }) => {
  const [loading, setLoading] = useState(false);
  const [creditors, setCreditors] = useState([]);

  const loadData = async () => {
    const resp = await getCreditorsReadyForCombo();
    setCreditors(resp);
  };

  const handleSubmit = async (values: TemplateItem) => {
    setLoading(true);

    let id = values.id;

    const dataToUpdate = {
      data: {
        creditors_id: values.creditors_id,
        templatedesc: values.templatedesc,
        default: 0,
      },
    };

    if (!id || defaultData.templatedesc == "") {
      const resp = await creditorsinvoicedetailitemstemplate.create(dataToUpdate);
      id = resp[0];
    } else {
      await creditorsinvoicedetailitemstemplate.update(id, dataToUpdate);
    }

    handleClose(id);
    setLoading(false);
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <>
      {loading && <LinearProgress />}
      <Form
        initialValues={defaultData}
        onSubmit={(values: any) => {
          handleSubmit(values);
        }}
        render={({ handleSubmit }) => <CustomForm classes={classes} handleSubmit={handleSubmit} creditors={creditors} handleClose={handleClose} />}
      />
    </>
  );
};

const CustomForm = ({ classes, handleSubmit, creditors, handleClose }) => (
  <form onSubmit={handleSubmit} className={classes.root}>
    <FieldCombo classes={classes} field={"creditors_id"} name={"Creditor"} options={creditors} />
    <FieldText classes={classes} field={"templatedesc"} name={"Description"} />
    <div className={classes.actionButtons}>
      <Button type="button" variant="contained" color="primary" onClick={() => handleClose(undefined)}>
        Cancel
      </Button>
      <Button type="submit" variant="contained" color="secondary">
        Save
      </Button>
    </div>
  </form>
);

const FieldCombo = ({ classes, field, name, options }) => (
  <FormControl className={classes.fieldRow}>
    <div className={classes.fieldRowLabel}>
      <p>{name}:</p>
    </div>
    <Field name={field}>
      {({ input, meta }) => (
        <Select {...input} {...meta} fullWidth style={{ flexDirection: "unset" }}>
          {options.map((opt) => (
            <MenuItem key={opt.value} value={opt.value}>
              {opt.display}
            </MenuItem>
          ))}
        </Select>
      )}
    </Field>
  </FormControl>
);

const FieldText = ({ classes, field, name }) => (
  <FormControl className={classes.fieldRow}>
    <div className={classes.fieldRowLabel}>
      <p>{name}:</p>
    </div>
    <Field name={field}>{({ input, meta }) => <TextField {...input} {...meta} fullWidth />}</Field>
  </FormControl>
);

// const FieldCheckbox = ({ classes, field, name, disabled }) => (
//   <FormControl className={classes.fieldRow}>
//     <div className={classes.fieldRowLabel}>
//       <p>{name}:</p>
//     </div>
//     <Field name={field} type="checkbox">
//       {({ input, meta }) => (
//         <div>
//           <Checkbox {...input} {...meta} disabled={disabled} />
//         </div>
//       )}
//     </Field>
//   </FormControl>
// );

export default withStyles(styles)(TemplateFormUnstyled);
