export const ContainerTypes = {
  BB: "BB",
  C6: "C6",
  C12: "C12",
};

export const getContainerTypesByValue = (value: string) => {};

export const getContainerTypesReadyForCombo = () => {
  const result = [];
  Object.keys(ContainerTypes).map((st) => {
    result.push({ value: st, display: ContainerTypes[st] });
  });
  return result;
};
