import React from "react";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";

import Check from "@material-ui/icons/Check";
import Close from "@material-ui/icons/Close";
import Button from "@material-ui/core/Button";

import { pucs } from "../../lib/api/producerpuc";
import { Field, Form } from "react-final-form";
import { Input } from "final-form-material-ui";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1),
      width: "400px",
    },
    tableRow: {
      paddingTop: theme.spacing(1),
      paddingRight: theme.spacing(1) * 2,
      marginTop: theme.spacing(1),
      textAlign: "right",
    },
    formInputs: {
      paddingBottom: "5px",
      width: "100%",
    },
    footerWrapper: {
      display: "flex",
      justifyContent: "space-between",
      margin: "10px 0 -5px 0",
      gap: "10px",
    },
    footerContent: {
      display: "flex",
      alignItems: "center",
    },
  });

type PucsEditFormProps = {
  onSubmit: any;
  onClose: any;
  id: any;
  pucs: any[];
  producerId: number;
} & WithStyles<typeof styles>;

class PucsEditFormUnstyled extends React.Component<PucsEditFormProps, any> {
  state = {
    classes: undefined,
    item: { id: "0", producer_id: "0", code: "", production_area: "" },
    onSubmit: undefined,
    onClose: undefined,
    id: 0,
    pucExists: false,
  };

  constructor(props) {
    super(props);
    this.state.classes = props.classes;
    this.state.onSubmit = props.onSubmit;
    this.state.onClose = props.onClose;
    this.state.id = props.id;
  }

  componentDidMount() {
    if (this.state.id != 0) {
      this.getItem(this.state.id);
    }
  }

  getItem = (id) => {
    pucs.single(id).then((data) => {
      this.setState({ item: data });
    });
  };

  handleFocus = (event) => {
    event.target.select();
  };

  handleSubmit = (value) => {
    const exists = (this.props.pucs || []).find((item) => item.code.toLowerCase() == value.code.toLowerCase() && item.producer_id == this.props.producerId && item.id != value.id);

    if (exists) {
      this.setState({ pucExists: true });
    } else {
      this.setState({ pucExists: false });
      this.state.onSubmit(value);
    }
  };

  handleChange = (ev) => {};

  render() {
    const { classes } = this.state;

    return (
      <div className={classes.root}>
        <Form
          initialValues={this.state.item}
          onSubmit={(value) => this.handleSubmit(value)}
          render={({ handleSubmit }) => (
            <form id={this.state.item.id} onSubmit={handleSubmit}>
              <table style={{ marginLeft: "15px" }}>
                <tbody>
                  <tr className={classes.tableRow}>
                    <td className={classes.tableRow}>
                      <span>Code:</span>
                    </td>
                    <td className={classes.tableRow}>
                      <Field
                        component={Input}
                        type="text"
                        onFocus={this.handleFocus}
                        key="body_value_code"
                        name="code"
                        value={this.state.item.code}
                        formControlProps={{ className: classes.formInputs }}
                      />
                    </td>
                  </tr>
                  <tr className={classes.tableRow}>
                    <td className={classes.tableRow}>
                      <span>Production Area:</span>
                    </td>
                    <td className={classes.tableRow}>
                      <Field
                        component={Input}
                        type="text"
                        onFocus={this.handleFocus}
                        key="body_production_area"
                        name="production_area"
                        value={this.state.item.production_area}
                        formControlProps={{ className: classes.formInputs }}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
              <div className={classes.footerWrapper}>
                <div className={classes.footerContent}>{this.state.pucExists && <p style={{ color: "red" }}>A PUC with this code already exists for this Producer</p>}</div>
                <div className={classes.footerContent}>
                  <Button style={{ marginRight: "10px" }} color="secondary" variant="outlined" onClick={this.state.onClose}>
                    <Close />
                  </Button>
                  <Button type="submit" color="primary" variant="outlined">
                    <Check />
                  </Button>
                </div>
              </div>
            </form>
          )}
        />
      </div>
    );
  }
}

export default withStyles(styles)(PucsEditFormUnstyled);
