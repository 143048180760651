import { config } from "../../config";
import { client, apiHeaders, request } from "./_base";

export const counts = client("count", (item) => ({
  ...item,
  // id: item.count_id
}));

const endpoint = "count";

export const countFull = () => {
  return request(`count/extend/all`, {
    headers: apiHeaders(),
  }).then((result) => result.json());
};

export const countCodeDistinct = () => {
  return request(`count/extend/codedistinct`, {
    headers: apiHeaders(),
  }).then((result) => result.json());
};

export const distinctCodeByCommodity = (commodityId) => {
  return request(`count/extend/distinctCodesByCommodity/${commodityId}`, {
    headers: apiHeaders(),
  }).then((result) => result.json());
};

export const countFullById = async (id: number) => {
  const result = await request(`${endpoint}/ext/countFullById/${id}`);
  const resp = await result.json();

  if (result.status == 500) {
    throw new Error(resp);
  }

  return resp.data;
};
