export const AdvanceContractsPayoutTypes = {
  Cold: 1,
  Intake: 2,
  Shipment: 3,
};

export const getAdvanceContractsPayoutReadyForCombo = () => {
  const result = [];
  Object.keys(AdvanceContractsPayoutTypes).map((pt) => {
    result.push({ id: AdvanceContractsPayoutTypes[pt], value: pt });
  });
  return result;
};
