import React from "react";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";

import Grid from "../../lib/components/grid";
import { GridColumns } from "./gridsetup";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1),
      width: "100%",
      height: "100%",
      position: "relative",
    },
  });

type SellingTermsGridProps = {
  loading: boolean;
  data: any[];
  handleEdit(id: number): void;
  handleRemove(id: number): void;
} & WithStyles<typeof styles>;

class SellingTermsGridUnstyled extends React.Component<SellingTermsGridProps, any> {
  constructor(props) {
    super(props);
  }

  GridColumnsOverride = (data, filters, arrangement, columnsWidth) => {
    return GridColumns(data, filters, arrangement, columnsWidth, this.props.handleRemove, this.props.handleEdit);
  };

  render() {
    return <Grid loading={this.props.loading} data={this.props.data} GridColumns={this.GridColumnsOverride} clearFilters="sellingtermgrid" />;
  }
}

export default withStyles(styles)(SellingTermsGridUnstyled);
