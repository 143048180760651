import React, { useState, useEffect, Dispatch, SetStateAction } from "react";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";
import { Form, Field } from "react-final-form";

import Button from "@material-ui/core/Button";
import LinearProgress from "@material-ui/core/LinearProgress";

import { users } from "../lib/api/users";
import ResetPassword from "./modules/ResetPassword";
import { Checkbox } from "final-form-material-ui";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      width: "400px",
    },
    buttonCancel: {
      marginRight: `${theme.spacing(1) * 1.2}px`,
    },
    buttonReset: {
      color: "#3f51b5",
      backgroundColor: "#f5f5f5",
      marginRight: `${theme.spacing(1) * 1.2}px`,
    },
    buttonContainer: {
      display: "flex",
      flex: 1,
      flexDirection: "row",
      marginTop: `${theme.spacing(1) * 2}px`,
    },
    formContainer: {
      display: "flex",
      flexDirection: "column",
    },
    form: {},
    fieldContainer: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
    input: {
      fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
      fontSize: "1.15rem",
      height: "35px",
      outline: "none",
      width: "200px",
      borderLeft: "none",
      borderRight: "none",
      borderTop: "none",
      borderColor: "black",
      borderWidth: 1,
    },
    label: {
      paddingRight: `${theme.spacing(1) * 2}px`,
      "& > h3": {
        display: "inline-block",
        fontSize: `${theme.typography.fontSize}px`,
      },
    },
    labelContainer: {
      display: "flex",
      flex: 0.8,
      paddingRight: `${theme.spacing(1) * 2}px`,
    },
    inputContainer: {
      display: "flex",
      flex: 1,
      justifyContent: "flex-end",
      flexDirection: "row",
      alignItems: "center",
      paddingRight: "18px",
    },
    checkboxFieldContainer: {
      display: "flex",
      flex: 0.6,
    },
    checkboxRow: {
      display: "flex",
      flex: 1,
      justifyContent: "flex-start",
      alignItems: "center",
      flexDirection: "row",
      width: "170px",
      paddingRight: "25px",
    },
    formButtons: {
      display: "flex",
      flex: 1,
      justifyContent: "flex-end",
    },
    resetPasswordButton: {
      display: "flex",
      flex: 1,
      justifyContent: "flex-start",
    },
    saveButton: {
      color: "#ffff",
      backgroundColor: "green",
    },
  });

type EditUserProps = {
  id: number;
  setSelectedUserId: Dispatch<SetStateAction<boolean>>;
  setPermissionChanged: Dispatch<SetStateAction<boolean>>;
  handleSubmit: (fields: any) => Promise<void>;
} & WithStyles<typeof styles>;

const EditUserUnstyled: React.FC<EditUserProps> = (props) => {
  const { classes, id, handleSubmit, setSelectedUserId, setPermissionChanged } = props;

  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [handleResetPwd, setHandleResetPwd] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const resultUser = await users.single(id);
      setUser(resultUser);
      setLoading(false);
    };
    fetchData();
  }, [id]);

  const handleResetPassword = () => {
    setHandleResetPwd(true);
  };

  const handleParse = (value: any, name: string) => {
    setPermissionChanged(true);
    return value;
  };

  if (loading) {
    return (
      <div>
        <LinearProgress />
      </div>
    );
  }

  return (
    <>
      <div className={classes.root}>
        <Form
          initialValues={{
            username: user.username,
            email: user.email,
            admin: user.admin,
            active: user.active,
            can_adjust: user.can_adjust,
            stockalloc_permission: user.stockalloc_permission,
            consolidation_lock_permission: user.consolidation_lock_permission,
          }}
          onSubmit={(fields: any) => handleSubmit(fields)}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit} className={classes.form}>
              {handleResetPwd && <ResetPassword handleResetPwd={handleResetPwd} setHandleResetPwd={setHandleResetPwd} user={user} />}
              <div style={{ display: "flex", flexDirection: "column", fontSize: "1.15rem" }}>
                <div className={classes.fieldContainer}>
                  <div className={classes.labelContainer}>
                    <p>Username</p>
                  </div>
                  <div className={classes.inputContainer}>
                    <Field name="username" component="input" placeholder="First Name" className={classes.input} />
                  </div>
                </div>
                <div className={classes.fieldContainer}>
                  <div className={classes.labelContainer}>
                    <p>Email</p>
                  </div>
                  <div className={classes.inputContainer}>
                    <Field name="email" component="input" placeholder="Email" className={classes.input} />
                  </div>
                </div>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <div className={classes.checkboxFieldContainer}>
                    <div className={classes.labelContainer}>
                      <p>Admin</p>
                    </div>
                    <div className={classes.checkboxRow}>
                      <Field fullWidth name="admin" component={Checkbox} type="checkbox" margin="dense" parse={handleParse} />
                    </div>
                  </div>
                  <div className={classes.checkboxFieldContainer}>
                    <div className={classes.labelContainer}>
                      <p>Active</p>
                    </div>
                    <div className={classes.checkboxRow}>
                      <Field fullWidth name="active" component={Checkbox} type="checkbox" margin="dense" parse={handleParse} />
                    </div>
                  </div>
                  <div className={classes.checkboxFieldContainer}>
                    <div className={classes.labelContainer}>
                      <p>Stock Adjustment</p>
                    </div>
                    <div className={classes.checkboxRow}>
                      <Field fullWidth name="can_adjust" component={Checkbox} type="checkbox" margin="dense" parse={handleParse} />
                    </div>
                  </div>
                  <div className={classes.checkboxFieldContainer}>
                    <div className={classes.labelContainer}>
                      <p>Stock Allocation</p>
                    </div>
                    <div className={classes.checkboxRow}>
                      <Field fullWidth name="stockalloc_permission" component={Checkbox} type="checkbox" margin="dense" parse={handleParse} />
                    </div>
                  </div>
                  <div className={classes.checkboxFieldContainer}>
                    <div className={classes.labelContainer}>
                      <p>Consolidation Lock</p>
                    </div>
                    <div className={classes.checkboxRow}>
                      <Field fullWidth name="consolidation_lock_permission" component={Checkbox} type="checkbox" margin="dense" parse={handleParse} />
                    </div>
                  </div>
                </div>
              </div>
              <div className={classes.buttonContainer}>
                <div className={classes.resetPasswordButton}>
                  <Button variant="contained" className={classes.buttonReset} onClick={handleResetPassword}>
                    Reset password
                  </Button>
                </div>
                <div className={classes.formButtons}>
                  <Button variant="contained" color="secondary" className={classes.buttonCancel} onClick={() => setSelectedUserId(null)}>
                    CLOSE
                  </Button>
                  <Button className={classes.saveButton} type="submit" variant="contained">
                    SAVE
                  </Button>
                </div>
              </div>
            </form>
          )}
        />
      </div>
    </>
  );
};

export default withStyles(styles)(EditUserUnstyled);
