import { client, request } from "./_base";

const endpoint = "clientbilltypes";

export const clientbilltypes = client(endpoint, (item) => ({
  ...item,
}));

export const getClientBillTypesReadyForCombo = () => {
  return clientbilltypes.all().then((result) => {
    const ready = result
      .sort((a, b) => {
        if (a.billtype > b.billtype) return 1;
        if (a.billtype < b.billtype) return -1;
        return 1;
      })
      .map((ag) => {
        return { value: ag.billtype, display: ag.billtype, data: ag };
      });
    return ready;
  });
};
