import React, { useEffect, useRef } from "react";
import { withStyles, createStyles, Theme } from "@material-ui/core/styles";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      appearance: "none",
      boxSizing: "border-box",
      width: "100%",
      height: "100%",
      padding: "0px 6px 0 6px",
      border: "2px solid #ccc",
      verticalAlign: "top",
      color: "black",
      textAlign: "right",
      fontSize: "15px",
      "&:focus": {
        borderColor: "black",
        outline: "none",
      },
      "&::placeholder": {
        color: "#999",
        opacity: 1,
      },
    },
  });

const TextEditorUnstyled = ({ row, column, onRowChange, onClose, classes, align, maxTextLength = 0 }) => {
  const ref: any = useRef();

  useEffect(() => {
    if (ref.current) {
      const currentValue = String(row[column.key]);
      const newValue = ref.current.value;

      if (currentValue != newValue) {
        const valueSplit = ref.current.value.split("");
        onRowChange({ ...row, [column.key]: valueSplit[valueSplit.length - 1] });
      }

      (ref.current as any).select();
      (ref.current as any).focus();
    }
  }, [ref]);

  return (
    <input
      ref={ref}
      autoFocus
      value={row[column.key]}
      className={classes.root}
      style={{ textAlign: align || "left" }}
      onBlur={() => onClose(true)}
      onChange={(event) => {
        if (maxTextLength > 0 && (event.target.value || "").length > maxTextLength) {
          alert("Max text reached.");
        }
        onRowChange({ ...row, [column.key]: event.target.value });
      }}
    />
  );
};

export default withStyles(styles)(TextEditorUnstyled);
