import React, { createContext, useMemo, useState } from "react";

type EnquiryType = {
  enquiryValue: string;
  enquirySelectValue: number;
  enquiryOpen: boolean;
  toggleEnquiry(open: boolean): void;
  setEnquirySearchValue(value: any): void;
  setEnquirySelectSearchValue(value: number): void;
};

export const EnquiryContext = createContext<EnquiryType>({
  enquiryValue: "",
  enquirySelectValue: 1,
  enquiryOpen: false,
  toggleEnquiry: () => {},
  setEnquirySearchValue: () => {},
  setEnquirySelectSearchValue: () => {},
});

export const EnquiryProvider = ({ children }) => {
  const [enquiryOpen, setEnquiryOpen] = useState(false);

  const [enquiryValue, setEnquiryValue] = useState<string>("");
  const [enquirySelectValue, setEnquirySelectValue] = useState<number>(1);

  const setEnquirySearchValue = (value: any) => {
    setEnquiryValue(value);
  }

  const setEnquirySelectSearchValue = (value: number) => {
    setEnquirySelectValue(value);
  }

  const toggleEnquiry = (value: boolean) => {
    setEnquiryOpen(value);
  }

  const value = useMemo(() => ({
    enquiryValue,
    enquirySelectValue,
    enquiryOpen,
    setEnquirySearchValue,
    setEnquirySelectSearchValue,
    toggleEnquiry,
  }), [enquiryValue, enquirySelectValue, enquiryOpen]);

  return <EnquiryContext.Provider value={value}>{children}</EnquiryContext.Provider>;
};
