import React from "react";
import { withStyles, createStyles, Theme } from "@material-ui/core/styles";

import Grid from "../../lib/components/grid";
import { NewGridColumns } from "./barcodesplitnewgridsetup";

import Button from "@material-ui/core/Button";
import Toolbar from "@material-ui/core/Toolbar";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      height: "calc(100vh - 100px)",
      position: "relative",
    },
    gridWrapper: {
      position: "relative",
      height: "100%",
    },
    toolbar: {
      position: "absolute",
      left: 0,
      width: "100%",
    },
  });

type SummaryViewProps = {
  classes: any;
  newSplitBarcodes: any[];
  handleClose(): void;
};

class SummaryViewUnstyled extends React.Component<SummaryViewProps, {}> {
  constructor(props) {
    super(props);
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.gridWrapper}>
        <Toolbar className={classes.toolbar}>
          <Button onClick={this.props.handleClose} color="primary" variant="contained">
            close
          </Button>
        </Toolbar>
        <Grid
          clearFilters={"barcodesplitnew"}
          GridColumns={NewGridColumns}
          data={this.props.newSplitBarcodes}
          totalRowColumns={["noCartons", "grossWeight", "weight", "cartons_to_split", "new_weight", "palletSize"]}
        />
      </div>
    );
  }
}

export default withStyles(styles)(SummaryViewUnstyled);
