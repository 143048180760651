import React from "react";

import CustomAutoCompleteFilter from "../../lib/components/customdatagridfilter/CustomFilter";
import { handleColumnsArrangement, handleColumnsWidth } from "../../lib/helpers/grid";

const cellWidthTiny = 50;
const cellWidthSmallX = 90;
const cellWidthSmall = 115;
const cellWidthMedium = 150;
const cellWidthLarge = 350;

const defaultColumnProperties = {
  filterable: true,
  resizable: true,
  sortable: true,
};

let currentSpace = true;

export const GridColumns = (data, filters, arrangement, columnsWidth) => {
  let postValue = "";

  if (data) {
    if (currentSpace) {
      postValue = " ";
    }
    currentSpace = !currentSpace;
  }

  const columns = [
    {
      key: "client",
      name: "Client" + postValue,
      width: cellWidthSmall,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} option={data} currentFilters={filters} fieldname={"client"} data={data} section="errorsgrid" />;
      },
    },
    {
      key: "error",
      name: "Error Msg" + postValue,
      width: cellWidthLarge,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} option={data} currentFilters={filters} fieldname={"error"} data={data} section="errorsgrid" />;
      },
    },
  ].map((c) => ({
    ...c,
    ...defaultColumnProperties,
  }));

  let columnsArranged = [];
  columnsArranged = handleColumnsArrangement(columnsArranged, arrangement, columns);
  columnsArranged = handleColumnsWidth(columnsArranged, columnsWidth, columns);

  return columnsArranged;
};
