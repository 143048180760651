import React, { useMemo, forwardRef, useEffect } from "react";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";

import LinearProgress from "@material-ui/core/LinearProgress";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";

import { Row } from "react-data-grid";
import classNames from "classnames";
import { Field, Form } from "react-final-form";
import { TextField, Select } from "final-form-material-ui";
import ReactDatePicker, { registerLocale } from "react-datepicker";
import en from "date-fns/esm/locale/en-GB";
registerLocale("en-GB", en);
import toDate from "date-fns/toDate";
import format from "date-fns/format";
import numeral from "numeral";

import { SnackContext } from "../../../lib/context/SnackContext";
import Grid from "../../../lib/components/grid";
import Confirmation from "../../../lib/components/confirmation";
import { GridColumns } from "../paymentsfixed/producersfixedtablesetupmain";
import { producerfixedpaidheadGetLatestIdent } from "../../../lib/api/producerfixedpaidhead";
import { producerfixedpaid } from "../../../lib/api/producerfixedpaid";
import { getWeekByDate } from "../../../lib/api/week";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1),
      width: "100%",
      height: "100%",
    },
    container: {
      display: "grid",
      gridTemplateColumns: "repeat(1, 150px 350px)",
      gridTemplateRows: "repeat(4, 40px)",
      gridTemplateAreas: `
      "details"
      `,
      gridGap: "10px",
    },
    tableCellLabel: {
      width: "150px",
      borderBottom: "none",
      height: "50px",
      textAlign: "right",
      paddingTop: "5px",
    },
    tableCellLabelValue: {
      width: "350px",
      borderBottom: "none",
      height: "50px",
      textAlign: "left",
      paddingTop: "5px",
    },
    tableCellDetail: {
      width: "350px",
      borderBottom: "none",
      height: "50px",
    },
    tableCellsValueSelect: {
      textAlign: "left",
      width: "350px",
      borderBottom: "none",
      height: "50px",
      padding: 0,
      marginTop: "-14px",
    },
    detailTable: {
      width: "900px",
    },
    detailTableRowNormal: {
      backgroundColor: "white",
      padding: "0px",
    },
    boldRow: {
      fontWeight: "bold",
    },
    normalRow: {
      fontWeight: "normal",
    },
    greenRow: {
      color: "green",
    },
    blackRow: {
      color: "black",
    },
  });

type PaymentsFixedProps = {
  selectedRow: any;
  gridData: any;
  handlePayProcess: (formData, selectedRows) => void;
} & WithStyles<typeof styles>;

let rootSelected = [];

const PaymentsFixedUnstyled: React.FunctionComponent<PaymentsFixedProps> = (props) => {
  const { classes, selectedRow, gridData, handlePayProcess } = props;

  const { updateSnack } = React.useContext(SnackContext);

  const [loading, setLoading] = React.useState(false);
  const [confirmPaymentRemove, setConfirmPaymentRemove] = React.useState();

  const [paymentDate, setPaymentDate] = React.useState(selectedRow && selectedRow.paidAdjustments_head_id > 0 ? new Date(selectedRow.paidAdjustments_payment_date) : new Date());

  const [data, setData] = React.useState({
    amount: selectedRow && selectedRow.paidAdjustments_head_id > 0 ? selectedRow.paidAdjustments_amount : 0,
    currency_id: selectedRow && selectedRow.paidAdjustments_head_id > 0 ? selectedRow.dispatch_currency_id : 0,
    currency_code: selectedRow && selectedRow.paidAdjustments_head_id > 0 ? selectedRow.currency_code : "",
    ident: selectedRow && selectedRow.paidAdjustments_head_id > 0 ? selectedRow.paidAdjustments_ident : 0,
    makeanote: (selectedRow && selectedRow.paidAdjustments_makeanote) || "",
    id: 0,
    payment_date: selectedRow && selectedRow.paidAdjustments_head_id > 0 ? new Date(selectedRow.paidAdjustments_payment_date) : new Date(),
  });
  const [selected, setSelected] = React.useState([]);

  useEffect(() => {
    producerfixedpaidheadGetLatestIdent()
      .then((resultMaxIdent) => {
        if (resultMaxIdent && resultMaxIdent.data && resultMaxIdent.data.length > 0) {
          setData({ ...data, ident: resultMaxIdent.data[0].maxident, id: 0 });
        }
      })
      .catch((error) => {
        updateSnack({ show: true, message: "could not load", color: "red" });
      });
  }, []);

  useEffect(() => {
    if (selectedRow && selectedRow.paidAdjustments_head_id > 0) {
      gridData.map((item) => {
        if (item.paidAdjustments_head_id == selectedRow.paidAdjustments_head_id) {
          rootSelected.push(item);
        }
      });
      setSelected(rootSelected);
    }
  }, [selectedRow, gridData]);

  rootSelected = selected;
  const handleGridDoubleClick = (row, form) => {
    const idx = rootSelected.indexOf(row);
    if (idx == -1) {
      rootSelected.push(row);
      setSelected(rootSelected);
    } else {
      rootSelected = rootSelected.filter((item) => item !== row);
      setSelected(rootSelected);
      if (row.paidAdjustments_paid_id && row.paidAdjustments_paid_id != 0) {
        setConfirmPaymentRemove(row.paidAdjustments_paid_id);
      }
    }

    const resultTotal = rootSelected.reduce((acc, curr) => {
      acc += curr.paymentvalue;
      return acc;
    }, 0);
    form.change("amount", resultTotal);
    form.change("currency_id", row.dispatch_currency_id);
    form.change("currency_code", row.currency_code);
  };

  const handleConfirmPaymentRemove = (letsDoIt) => {
    if (letsDoIt) {
      producerfixedpaid.remove(confirmPaymentRemove).catch((error) => {
        updateSnack({ show: true, message: `could not remove payment with id ${confirmPaymentRemove}`, color: "red" });
      });
    }
    setConfirmPaymentRemove(undefined);
  };

  const rowRenderer = (props, form) => {
    const { row } = props;
    const idx = rootSelected.findIndex((item) => item == row);

    const handleDoubleClick = () => {
      if (row.paidAdjustments_head_id > 0) {
        if (idx != -1) {
          handleGridDoubleClick(row, form);
        }
      } else {
        handleGridDoubleClick(row, form);
      }
    };

    return (
      <Row
        {...props}
        className={classNames([row.paymentoutstanding != 0 ? classes.blackRow : classes.greenRow, idx != -1 ? classes.boldRow : classes.normalRow])}
        onDoubleClickCapture={handleDoubleClick}
      />
    );
  };

  const changePaymentDate = (value, form) => {
    setPaymentDate(value);
  };

  const gridHeight = useMemo(() => {
    const el = document.getElementById("producersfixedtable");
    return el ? el.clientHeight - 440 : 650;
  }, []);

  const generateIdent = (ident: string) => {
    return `PP${numeral(ident).format("0000")}`;
  };

  return (
    <div className={classes.root}>
      {loading ? (
        <LinearProgress />
      ) : (
        <div style={{ height: "100%" }}>
          {confirmPaymentRemove && (
            <Confirmation
              isOpen={true}
              handleClose={handleConfirmPaymentRemove}
              handleConfirm={() => {
                handleConfirmPaymentRemove(true);
              }}
              title="Remove entry from Payment?"
              body={`Would you like to remove previously added invoice from payment?`}
            ></Confirmation>
          )}
          <Form
            initialValues={{
              ...data,
              ident: generateIdent(selectedRow && selectedRow.paidAdjustments_head_id > 0 ? selectedRow.paidAdjustments_ident : data.ident),
            }}
            onSubmit={(values) => {
              handlePayProcess(
                { ...values, id: selectedRow && selectedRow.paidAdjustments_head_id > 0 ? selectedRow.paidAdjustments_head_id : 0, payment_date: paymentDate },
                rootSelected,
              );
            }}
            validate={(values) => {
              let errors = {};
              return errors;
            }}
            render={({ handleSubmit, form }) => (
              <form onSubmit={handleSubmit} style={{ position: "relative", height: "100%", display: "grid", gridTemplateRows: "min-content min-content 1fr" }}>
                <div style={{ paddingBottom: "10px" }}>
                  <Button name="submit" type="submit" variant="contained" color="primary" style={{ margin: "2px" }} disabled={false}>
                    accept
                  </Button>
                  <Button
                    name="close"
                    variant="contained"
                    color="secondary"
                    style={{ margin: "2px" }}
                    disabled={false}
                    onClick={() => {
                      handlePayProcess(undefined, undefined);
                    }}
                  >
                    Close
                  </Button>
                </div>
                <div className={classes.container}>
                  <TableFieldText classes={classes} field="ident" title="Ident" disabled={false} />
                  <TableFieldLabel classes={classes} field={form.getState().values["currency_code"]} title="Currency" disabled={false} />
                  <TableFieldCurrencyLabel classes={classes} field={form.getState().values["amount"]} title="Amount" disabled={false} />
                  <TableFieldText classes={classes} field="makeanote" title="Note" disabled={false} />
                  <TableFieldDate classes={classes} form={form} dateValue={paymentDate} changeDate={changePaymentDate} title="Payment Date" />
                  <TableFieldPaymentWeek classes={classes} title="Payment Week" date={paymentDate} />
                </div>
                <div style={{ height: gridHeight }}>
                  <Grid
                    loading={loading}
                    data={gridData}
                    getCellActions={(col, row) => {}}
                    GridColumns={GridColumns}
                    forceHeight={gridHeight}
                    rowRenderer={(props) => rowRenderer(props, form)}
                    clearFilters={"producersfixedpaymentmain"}
                  />
                </div>
              </form>
            )}
          />
        </div>
      )}
    </div>
  );
};

export default withStyles(styles)(PaymentsFixedUnstyled);

const TableFieldLabel: React.FunctionComponent<{ field: string; title: string; disabled: boolean } & WithStyles<typeof styles>> = (props) => {
  const { classes, field, title, disabled } = props;
  return (
    <>
      <span className={classes.tableCellLabel}>{`${title}:`}</span>
      <span className={classes.tableCellLabelValue}>{`${field}`}</span>
    </>
  );
};

const TableFieldCurrencyLabel: React.FunctionComponent<{ field: string; title: string; disabled: boolean } & WithStyles<typeof styles>> = (props) => {
  const { classes, field, title, disabled } = props;
  return (
    <>
      <span className={classes.tableCellLabel}>{`${title}:`}</span>
      <span className={classes.tableCellLabelValue} style={{ fontWeight: "bold" }}>{`${numeral(field).format("0,0.00")}`}</span>
    </>
  );
};

const TableFieldPaymentWeek: React.FunctionComponent<{ title: string; date: Date } & WithStyles<typeof styles>> = (props) => {
  const { classes, title, date } = props;

  const [paymentWeek, setPaymentWeek] = React.useState("");

  useEffect(() => {
    const formattedDate = format(date, "yyyy-MM-dd");

    getWeekByDate(formattedDate).then((weeks) => {
      if (weeks.length > 0) {
        setPaymentWeek(weeks[0].week);
      }
    });
  }, [date]);

  return (
    <>
      <span className={classes.tableCellLabel}>{`${title}:`}</span>
      <span className={classes.tableCellLabelValue}>{`${paymentWeek}`}</span>
    </>
  );
};

const TableFieldText: React.FunctionComponent<{ field: string; title: string; disabled: boolean } & WithStyles<typeof styles>> = (props) => {
  const { classes, field, title, disabled } = props;
  return (
    <>
      <span className={classes.tableCellLabel}>{`${title}:`}</span>
      <span className={classes.tableCellDetail}>
        <Field name={field} component={TextField} type="text" fullWidth={true} disabled={disabled} />
      </span>
    </>
  );
};

const CalenderCustomInput = forwardRef((props: any, ref: any) => {
  return (
    <Button name="CalenderCustomInput" variant="contained" color="primary" onClick={props.onClick} style={{ marginTop: "5px", width: "250px" }}>
      {props.value}
    </Button>
  );
});

const TableFieldDate: React.FC<{ dateValue: Date; title: string; form: any; changeDate: (value, form) => void } & WithStyles<typeof styles>> = (props) => {
  const { classes, dateValue, title, changeDate, form } = props;
  return (
    <>
      <span className={classes.tableCellLabel}>{`${title}:`}</span>
      <span className={classes.tableCellDetail}>
        <ReactDatePicker
          locale="en-GB"
          showWeekNumbers={true}
          selected={toDate(dateValue)}
          onChange={(value) => {
            changeDate(value, form);
          }}
          dateFormat="dd-MM-yyyy"
          placeholderText="click here to select a date"
          customInput={<CalenderCustomInput />}
        />
      </span>
    </>
  );
};

const TableFieldCombo: React.FunctionComponent<
  { classes: any; form: any; title: string; field: string; data: any; addEmptyValue: string; handleParse: (form, value) => void } & WithStyles<typeof styles>
> = (props) => {
  const { classes, form, title, field, data, addEmptyValue, handleParse } = props;
  return (
    <>
      <span className={classes.tableCellLabel}>{`${title}:`}</span>
      <span className={classes.tableCellDetail}>
        <Field
          name={field}
          component={Select}
          formControlProps={{ className: classes.tableCellsValueSelect }}
          parse={(value) => {
            if (handleParse) {
              handleParse(form, value);
            }
            return value;
          }}
        >
          {addEmptyValue && (
            <MenuItem key={`emptyvalueMenuItem`} value={-1}>
              {addEmptyValue}
            </MenuItem>
          )}
          {data &&
            data.map((item, index) => {
              return (
                <MenuItem key={`${item.value}${index}`} value={item.value}>
                  {item.display}
                </MenuItem>
              );
            })}
        </Field>
      </span>
    </>
  );
};

// export const RowFormatter = ({ row, column, selectedrows, onDoubleClickHandler }) => {
//   const formatterType = column.formatterType || 0;
//   const rowData = row;
//   const color = rowData.paymentoutstanding != 0 ? "black" : "green";
//   const idx = selectedrows.findIndex((item) => item == row);
//   let fontWeight = "normal";
//   if (idx != -1) {
//     fontWeight = "bold";
//   }
//   const style = { color, fontWeight };

//   switch (formatterType) {
//     case FormatterTypes.Number:
//       return (
//         <FormatterNumber
//           row={row}
//           column={column}
//           style={style}
//           onDoubleClickHandler={() => {
//             onDoubleClickHandler(rowData);
//           }}
//         />
//       );
//     case FormatterTypes.Number2Decimal:
//       return (
//         <FormatterNumber2Decimals
//           row={row}
//           column={column}
//           style={style}
//           onDoubleClickHandler={() => {
//             onDoubleClickHandler(rowData);
//           }}
//         />
//       );
//     case FormatterTypes.Date:
//       return (
//         <DateFormatter
//           row={row}
//           column={column}
//           style={style}
//           onDoubleClickHandler={() => {
//             onDoubleClickHandler(rowData);
//           }}
//         />
//       );
//     default:
//       return (
//         <TextFormatter
//           row={row}
//           column={column}
//           style={style}
//           onDoubleClickHandler={() => {
//             onDoubleClickHandler(rowData);
//           }}
//         />
//       );
//   }
// };
