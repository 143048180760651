export const get = (path: string | string[], obj: any, defaultValue: any) => {
  if (!Array.isArray(path)) {
    return get(path.split("."), obj, defaultValue);
  }
  if (path.length === 1) {
    return obj[path[0]] || defaultValue;
  }
  if (obj[path[0]]) {
    return get(path.slice(1), obj[path[0]], defaultValue);
  }
  return defaultValue;
};
