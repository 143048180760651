import React from "react";

import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import ViewIcon from "@material-ui/icons/Search";

import CustomAutoCompleteFilter from "../../lib/components/customdatagridfilter/CustomFilter";

import { TermsType } from "../../lib/api/terms";
import { TermsValueType } from "../../lib/api/termsvaluetype";
import { TermsDateType } from "../../lib/api/termsdatetype";
import { CellActionsFormatter } from "../../lib/helpers/CellActionsFormatter";
import { handleColumnsArrangement, handleColumnsWidth } from "../../lib/helpers/grid";

const cellWidthTiny = 50;
const cellWidthSmall = 100;
const cellWidthSmallBigger = 120;
const cellWidthMedium = 200;
const cellWidthLarge = 300;

const defaultColumnProperties = {
  resizable: true,
  sortable: true,
};

let currentSpace = true;
export const GridColumns = (data, filters, arrangement, columnsWidth, handleRemove, handleEdit, handleView, paymentTermsAssigned) => {
  let postValue = "";

  if (data) {
    if (currentSpace) {
      postValue = " ";
    }
    currentSpace = !currentSpace;
  }

  const columns = [
    {
      key: "actions",
      name: "Actions",
      width: cellWidthSmall,
      formatter: ({ row }) => {
        const actions = cellActions(row, handleRemove, handleEdit, handleView, paymentTermsAssigned);
        return (
          <div style={{ display: "flex", justifyContent: "center", gap: "5px" }}>
            <CellActionsFormatter actions={actions} />
          </div>
        );
      },
    },
    {
      key: "code",
      name: "Code" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"code"} data={data} section={"paymentterms"} />;
      },
    },
    {
      key: "description",
      name: "Description" + postValue,
      width: cellWidthSmallBigger,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"description"} data={data} section={"paymentterms"} />;
      },
    },
    {
      key: "details",
      name: "Details" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"details"} data={data} section={"paymentterms"} />;
      },
    },
    {
      key: "value1",
      name: "value1" + postValue,
      width: cellWidthSmall,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"value1"} data={data} section={"paymentterms"} />;
      },
    },
    {
      key: "valuetype1",
      name: "valuetype1" + postValue,
      width: cellWidthSmallBigger,
      filterable: true,
      formatter: formattertermsvaluetype1,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"valuetype1"} data={data} section={"paymentterms"} />;
      },
    },
    {
      key: "days1",
      name: "days1" + postValue,
      width: cellWidthSmall,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"days1"} data={data} section={"paymentterms"} />;
      },
    },
    {
      key: "datetype1",
      name: "datetype1" + postValue,
      width: cellWidthSmall,
      filterable: true,
      formatter: formattertermsdatetype1,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"datetype1"} data={data} section={"paymentterms"} />;
      },
    },
    {
      key: "value2",
      name: "value2" + postValue,
      width: cellWidthSmall,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"value2"} data={data} section={"paymentterms"} />;
      },
    },
    {
      key: "valuetype2",
      name: "valuetype2" + postValue,
      width: cellWidthSmallBigger,
      filterable: true,
      formatter: formattertermsvaluetype2,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"valuetype2"} data={data} section={"paymentterms"} />;
      },
    },
    {
      key: "days2",
      name: "days2" + postValue,
      width: cellWidthSmall,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"days2"} data={data} section={"paymentterms"} />;
      },
    },
    {
      key: "datetype2",
      name: "datetype2" + postValue,
      width: cellWidthSmall,
      filterable: true,
      formatter: formattertermsdatetype2,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"datetype2"} data={data} section={"paymentterms"} />;
      },
    },
    {
      key: "value3",
      name: "value3" + postValue,
      width: cellWidthSmall,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"value3"} data={data} section={"paymentterms"} />;
      },
    },
    {
      key: "valuetype3",
      name: "valuetype3" + postValue,
      width: cellWidthSmallBigger,
      filterable: true,
      formatter: formattertermsvaluetype3,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"valuetype3"} data={data} section={"paymentterms"} />;
      },
    },
    {
      key: "days3",
      name: "days3" + postValue,
      width: cellWidthSmall,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"days3"} data={data} section={"paymentterms"} />;
      },
    },
    {
      key: "datetype3",
      name: "datetype3" + postValue,
      width: cellWidthSmall,
      filterable: true,
      formatter: formattertermsdatetype3,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"datetype3"} data={data} section={"paymentterms"} />;
      },
    },
  ].map((c) => ({ ...c, ...defaultColumnProperties }));

  let columnsArranged = [];
  columnsArranged = handleColumnsArrangement(columnsArranged, arrangement, columns);
  columnsArranged = handleColumnsWidth(columnsArranged, columnsWidth, columns);

  return columnsArranged;
};

const cellActions = (data, handleRemove, handleEdit, handleView, paymentTermsAssigned) => {
  const actions = [];
  const linked = (paymentTermsAssigned || []).filter((row) => row.terms_id == data.id).length;

  if (linked > 0) {
    actions.push({
      icon: (
        <span title="View">
          <ViewIcon style={{ cursor: "pointer", color: "orange" }} />
        </span>
      ),
      callback: (e) => {
        handleView(data);
      },
    });
  } else {
    actions.push(
      {
        icon: (
          <span title="Edit">
            <EditIcon style={{ cursor: "pointer", color: "orange" }} />
          </span>
        ),
        callback: (e) => {
          handleEdit(data.id);
        },
      },
      {
        icon: (
          <span title="Remove">
            <DeleteIcon style={{ cursor: "pointer", color: "red" }} />
          </span>
        ),
        callback: (e) => {
          handleRemove(data.id);
        },
      },
    );
  }

  return actions;
};

const formattertermsvaluetype1 = (data) => {
  const rowdata: TermsType = data.row;
  if (rowdata.valuetype1) {
    const valuetype = TermsValueType[rowdata.valuetype1];
    return <div>{valuetype}</div>;
  }
  return <div></div>;
};

const formattertermsdatetype1 = (data) => {
  const rowdata: TermsType = data.row;
  if (rowdata.datetype1) {
    const valuetype = TermsDateType[rowdata.datetype1];
    return <div>{valuetype}</div>;
  }
  return <div></div>;
};

const formattertermsvaluetype2 = (data) => {
  const rowdata: TermsType = data.row;
  if (rowdata.valuetype2) {
    const valuetype = TermsValueType[rowdata.valuetype2];
    return <div>{valuetype}</div>;
  }
  return <div></div>;
};

const formattertermsdatetype2 = (data) => {
  const rowdata: TermsType = data.row;
  if (rowdata.datetype2) {
    const valuetype = TermsDateType[rowdata.datetype2];
    return <div>{valuetype}</div>;
  }
  return <div></div>;
};

const formattertermsvaluetype3 = (data) => {
  const rowdata: TermsType = data.row;
  if (rowdata.valuetype3) {
    const valuetype = TermsValueType[rowdata.valuetype3];
    return <div>{valuetype}</div>;
  }
  return <div></div>;
};

const formattertermsdatetype3 = (data) => {
  const rowdata: TermsType = data.row;
  if (rowdata.datetype3) {
    const valuetype = TermsDateType[rowdata.datetype3];
    return <div>{valuetype}</div>;
  }
  return <div></div>;
};
