import React from "react";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";

import LinearProgress from "@material-ui/core/LinearProgress";

import { batchStockDataUpdatePricingByReference, stockDetaildistinctReferencesSellingNull, stockDetailUpdatePricingByReferenceAnd } from "../lib/api/stockdetail";
import StockPricingUpdateGrid from "./stockpricingupdategrid";

import { SnackContext } from "../lib/context/SnackContext";
import { GenerateErrorMessage } from "../lib/helpers/string_methods";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      height: "100%",
    },
  });

type StockPricingUpdateProps = {
  updateData: any;
  selectedReferences: [];
  onRefresh: any;
  onBack: any;
  setContractSellingPrices: any;
  minGridHeightSecond: number;
} & WithStyles<typeof styles>;

class StockPricingUpdateUnstyled extends React.Component<StockPricingUpdateProps> {
  state = {
    updateData: [{}],
    selectedReferences: [],
    onRefresh: undefined,
    onBack: undefined,
    setContractSellingPrices: undefined,
    loading: false,
  };

  constructor(props) {
    super(props);
    this.state.updateData = props.updateData;
    this.state.selectedReferences = props.selectedReferences;
    this.state.onRefresh = props.onRefresh;
    this.state.onBack = props.onBack;
    this.state.setContractSellingPrices = props.setContractSellingPrices;
  }

  static contextType = SnackContext;
  context!: React.ContextType<typeof SnackContext>;

  componentDidUpdate(props) {
    this.state.updateData = props.updateData;
  }

  onAcceptPricing = async (updateData) => {
    this.setState({ loading: true });

    try {
      const dataToUpdate = updateData.map((data) => ({
        producerId: data.producerId,
        sellingprice: data.sellingprice,
        comment: data.comment,
        reference: data.reference,
        commodityCode: data.commodityCode,
        varietyCode: data.varietyCode,
        gradeCode: data.gradeCode,
        targetMarket: data.targetMarket,
        targetCountry: data.targetCountry,
        countCode: data.countCode,
        packCode: data.packCode,
        markCode: data.markCode,
      }));

      await batchStockDataUpdatePricingByReference({ stockDetail: dataToUpdate, references: this.state.selectedReferences });

    } catch (error) {
      const err = GenerateErrorMessage(error, "Error updating stock pricing");
      this.context.updateSnack({ show: true, color: "red", message: err });
    }

    this.setState({ loading: false });
    this.state.onRefresh();
  };

  onCancelPricing = async () => {
    this.state.onRefresh();
  };

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        {this.state.loading && <LinearProgress />}
        <StockPricingUpdateGrid
          minGridHeightSecond={this.props.minGridHeightSecond}
          setContractSellingPrices={this.state.setContractSellingPrices}
          updateData={this.state.updateData}
          onAcceptPricing={this.onAcceptPricing}
          onCancelPricing={this.onCancelPricing}
          onBack={this.state.onBack}
        />
      </div>
    );
  }
}

export default withStyles(styles)(StockPricingUpdateUnstyled);
