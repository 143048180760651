import React, { createContext, useState, useMemo } from "react";

type SnackType = {
  show: boolean;
  message: string;
  color: string;
};

const defaultSnack = {
  show: false,
  message: "",
  color: "green",
};

type Loading = {
  loading: boolean;
  loaded: boolean;
  error?: Error;
  updated_at?: number;
};

export type SnackContextState = {
  snack: SnackType;
  updateSnack(snack: Partial<SnackType>);
  loading: Loading;
  hydrate();
};

export const SnackContext = createContext<SnackContextState>({
  snack: defaultSnack,
  loading: { loading: false, loaded: false, error: null, updated_at: undefined },
  hydrate: () => {},
  updateSnack: () => {},
});

export const SnackProvider = ({ children }) => {
  const [snack, setSnack] = useState<SnackType>(defaultSnack);
  const [loading, setLoading] = useState<Loading>({ loading: false, loaded: false, error: null, updated_at: undefined });
  const value = useMemo(
    () => ({
      snack,
      loading,
      hydrate: async () => {
        try {
          setLoading({
            loading: true,
            loaded: false,
            error: null,
          });
          setLoading({
            loading: false,
            loaded: true,
            error: null,
          });
        } catch (e) {
          setLoading({
            loading: false,
            loaded: false,
            error: e,
          });
        }
      },
      updateSnack: async (snack) => {
        try {
          // await updateUser(user);
          setSnack(snack);
          setLoading({
            loading: false,
            loaded: true,
            updated_at: Date.now(),
          });
        } catch (e) {
          setLoading({
            loading: false,
            loaded: false,
            error: e,
          });
        }
      },
      setSnack,
    }),
    [snack, loading],
  );

  return <SnackContext.Provider value={value}>{children}</SnackContext.Provider>;
};
