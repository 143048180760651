import React from "react";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";

import { GridColumns } from "./creditorsinfodetailitemssetup";

import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Toolbar from "@material-ui/core/Toolbar";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

import IconAdd from "@material-ui/icons/Add";

import Grid from "../../lib/components/grid";
import { getVatTypesReadyForCombo } from "../../lib/api/vattypes";
import { getItemsTemplateDescComboRead } from "../../lib/api/creditorsinvoicedetailitemstemplate";
import { BarcodeInfoDetail, creditorsinvoice, CreditorsInvoiceType } from "../../lib/api/creditorsinvoice";

import numeral from "numeral";
import { Row } from "react-data-grid";

import { GridColumns as InvoiceGridColumns } from "./creditorsinfodetailgridsetup";
import { GridColumnsSales } from "./creditorsinfodetailgridsetupgrouped";
import Confirmation from "../../lib/components/confirmation";
import { SaleBarcodesByDispatchIDType, saleDispatchJoined } from "../../lib/api/sale";
import { getFinancialYearSelected } from "../../lib/api/week";
import { SnackContext } from "../../lib/context/SnackContext";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      height: "100%",
    },
    inline: {
      display: "inline",
      position: "absolute",
      marginTop: "7px",
      marginLeft: "7px",
    },
    saveButtonWrapper: {
      position: "absolute",
      height: "64px",
      width: "calc(100% - 180px)",
      display: "flex",
      justifyContent: "flex-end",
    },
    saveButton: {
      alignSelf: "center",
    },
    gridWrapper: {
      position: "relative",
    },
    gridToolbarWrapper: {
      position: "absolute",
      width: "100%",
      height: "65px",
      display: "flex",
      alignItems: "center",
    },
    totalRowStyle: {
      background: "#ddd",
      textAlign: "right",
      fontWeight: "bold",
      color: "black",
    },
    totalRowStyleFixed: {
      position: "sticky",
      bottom: 0,
      background: "#ddd",
      textAlign: "right",
      fontWeight: "bold",
      color: "black",
    },
    lastRowStyle: {
      display: "flex",
      flexDirection: "column-reverse",
      color: "black",
    },
    gridContainer: {
      display: "grid",
      gridTemplateColumns: "2fr 1fr",
    },
    toolbar: {
      position: "absolute",
      left: 0,
      top: 0,
      height: "60px",
      width: "100%",
      display: "flex",
      flexDirection: "row",
      gap: "10px",
    },
    boldRow: {
      fontWeight: "bold",
    },
    selectedRow: {
      color: "green",
      fontWeight: "bold",
    },
    normalRow: {
      color: "black",
      fontWeight: "normal",
    },
  });

type InfoDetailItemsProps = {
  data: any[];
  group: number;
  groupId: number;
  grouped: boolean;
  loading: boolean;
  invoiceid: number;
  gridHeight: number;
  barcodesDetail: any[];
  selectedDetailRecords: any[];
  setGridHeights: () => void;
  handleClearAll: () => void;
  handleCopy: (row: any) => void;
  handleAddRow: (rowidx) => void;
  handleTemplateSelected: (idx: number) => void;
  handleGroupedChanged: (value: boolean) => void;
  handleRemoveConfirm: (rowIndx: number) => void;
  handleChange: (property: string, value: any[]) => void;
  handleDetailChange: (detail: [SaleBarcodesByDispatchIDType], group: number) => void;
} & WithStyles<typeof styles>;

export class InfoDetailsUnstyled extends React.Component<InfoDetailItemsProps, any> {
  state = {
    classes: undefined,
    invoiceid: undefined,
    confirmRemoveID: undefined,
    confirmEditID: undefined,
    loading: true,
    handleAddRow: undefined,
    handleChange: undefined,
    vattypesComboArr: [],
    templateSelected: {},
    templateOptions: [],
    handleTemplateSelected: undefined,
    itemDetailFilter: undefined,
    handleCopy: undefined,
    handleClearAll: undefined,
    rowSelectedIdx: undefined,
    columnSelectedIdx: undefined,
    columnsLength: undefined,
    handleDetailChange: undefined,
    handleGroupedChanged: undefined,
    showAddBarcodesModal: false,
    salesData: [],
    salesLoading: false,
  };

  constructor(props) {
    super(props);
    this.state.classes = props.classes;
    this.state.invoiceid = props.invoiceid;
    this.state.handleChange = props.handleChange;
    this.state.handleAddRow = props.handleAddRow;
    this.state.handleTemplateSelected = props.handleTemplateSelected;
    this.state.handleCopy = props.handleCopy;
    this.state.handleClearAll = props.handleClearAll;
    this.state.handleDetailChange = props.handleDetailChange;
    this.state.handleGroupedChanged = props.handleGroupedChanged;

    this.state.itemDetailFilter = "creditorsinfoitemsfilter";
  }

  componentDidMount() {
    this.loadData().then(() => {
      this.props.setGridHeights();
    });
  }

  static contextType = SnackContext;
  context!: React.ContextType<typeof SnackContext>;

  loadData = async () => {
    this.setState({ loading: true });
    const vattypes = getVatTypesReadyForCombo();
    this.setState({ vattypesComboArr: vattypes, loading: false });
    if (this.state.invoiceid && this.state.invoiceid != 0) {
      await creditorsinvoice.single(this.state.invoiceid).then((result: CreditorsInvoiceType) => {
        getItemsTemplateDescComboRead(result.creditors_id).then((result) => {
          this.setState({ templateOptions: result }, () => {});
        });
      });
    }
  };

  handleRemove = async (id) => {
    this.props.handleRemoveConfirm(id);
  };

  handleCopy = (row) => {
    this.state.handleCopy(row);
  };

  handleKeyDown = (event, gridRef) => {
    const enterKey = 13;
    const tabKey = 9;

    if (event.which === enterKey || event.which === tabKey) {
      event.preventDefault();

      const tagName = event.target.tagName && event.target.tagName.toLowerCase();

      if (tagName != "div") {
        gridRef.selectCell({ idx: this.state.columnSelectedIdx, rowIdx: this.state.rowSelectedIdx }, true);
      }
      //
      else {
        if (this.state.columnSelectedIdx == 6) {
          if (this.state.rowSelectedIdx == this.props.data.length - 1) {
            this.state.handleAddRow();
          }
          //
          else {
            this.setState({ rowSelectedIdx: this.state.rowSelectedIdx + 1, columnSelectedIdx: 2 });
            setTimeout(() => {
              gridRef.selectCell({ idx: 2, rowIdx: this.state.rowSelectedIdx }, true);
            }, 200);
          }
        }
        //
        else {
          this.setState({ columnSelectedIdx: this.state.columnSelectedIdx + 1 });
          setTimeout(() => {
            gridRef.selectCell({ idx: this.state.columnSelectedIdx, rowIdx: this.state.rowSelectedIdx }, true);
          }, 200);
        }
      }
    }
  };

  handleSelectedRowChange = (row, column) => {
    this.setState({ rowSelectedIdx: row.rowIdx, columnSelectedIdx: column.selectedColumn, columnsLength: column.columnsLength - 1 }); // minus one because index starts at 0
  };

  GridColumnsOverride = (data, filters, columnArrangement, columnsWidth) => {
    return GridColumns(data, filters, columnArrangement, columnsWidth, this.state.vattypesComboArr, this.handleRemove, this.handleCopy);
  };

  handleTemplateSelected = (data) => {
    this.state.handleTemplateSelected(data.target.value);
  };

  // handleSelectGroup = (row) => {
  //   this.props.handleGroupSelect(row.group);
  // };

  rowRenderer = (props) => {
    const { classes } = this.state;
    const { row } = props;

    const fontStyle = this.props.groupId == row.group ? classes.boldRow : classes.normalRow;

    return <Row {...props} className={fontStyle} />;
  };

  handleSelectRecord = (row: SaleBarcodesByDispatchIDType) => {
    const selectedRecords = [...this.props.selectedDetailRecords];

    if (this.props.grouped) {
      const selected = this.props.barcodesDetail.reduce((arr, item: BarcodeInfoDetail) => {
        const exists = selectedRecords.find((record) => record.barcode === item.loadoutdetail_barcode);
        if (item.sale_invoicenumber == row.sale_invoicenumber && !exists) {
          arr.push({
            barcode_id: item.barcode_id,
            barcode: item.loadoutdetail_barcode,
            invoicenumber: item.sale_invoicenumber,
            group: 1,
            pack_code: item.barcode_packcode,
            grade_code: item.barcode_gradecode,
            mark_code: item.barcode_markcode,
            inventory_code: item.barcode_inventorycode,
            no_cartons: item.barcode_nocartons,
            pallet_size: item.barcode_palletsize,
            commodity_code: item.barcode_commoditycode,
            variety_code: item.barcode_varietycode,
            count_code: item.barcode_countcode,
            producer_code: item.producer_code,
            puc: item.puc,
            intake_waybill: item.intake_waybill,
          });
        }
        return arr;
      }, []);
      const newSelectedRecords = [...selectedRecords, ...selected];
      this.state.handleDetailChange(newSelectedRecords);
    } else {
      const selected = this.props.barcodesDetail.reduce((arr, item: BarcodeInfoDetail) => {
        const exists = selectedRecords.find((record) => record.barcode === item.loadoutdetail_barcode && record.barcode_id === item.barcode_id);
        if (item.loadoutdetail_barcode === row.loadoutdetail_barcode && !exists) {
          arr.push({
            barcode_id: item.barcode_id,
            barcode: item.loadoutdetail_barcode,
            invoicenumber: item.sale_invoicenumber,
            group: 1,
            pack_code: item.barcode_packcode,
            grade_code: item.barcode_gradecode,
            mark_code: item.barcode_markcode,
            inventory_code: item.barcode_inventorycode,
            no_cartons: item.barcode_nocartons,
            pallet_size: item.barcode_palletsize,
            commodity_code: item.barcode_commoditycode,
            variety_code: item.barcode_varietycode,
            count_code: item.barcode_countcode,
            producer_code: item.producer_code,
            puc: item.puc,
            intake_waybill: item.intake_waybill,
          });
        }
        return arr;
      }, []);
      const newSelectedRecords = [...selectedRecords, ...selected];
      this.state.handleDetailChange(newSelectedRecords);
    }
  };

  invoiceRowRenderer = (props, classes) => {
    const { row } = props;
    const selected = this.props.selectedDetailRecords.find((item) => item.barcode_id == row.barcode_id) ? classes.selectedRow : classes.normalRow;

    return <Row {...props} onDoubleClickCapture={() => this.handleSelectRecord(row)} className={selected} />;
  };

  invoiceGroupedRowRenderer = (props, classes) => {
    const { row } = props;
    const selected = this.props.selectedDetailRecords.find((item) => item.invoicenumber == row.sale_invoicenumber) ? classes.selectedRow : classes.normalRow;

    return <Row {...props} onDoubleClickCapture={() => this.handleSelectRecord(row)} className={selected} />;
  };

  handleOpenBarcodesModal = async () => {
    this.setState({ showAddBarcodesModal: true, salesLoading: true });

    if ((this.state.salesData || []).length == 0) {
      const sales = await saleDispatchJoined(getFinancialYearSelected()).catch((error) => {
        const err = error.data || "Error retrieving data";
        this.context.updateSnack({ show: true, color: "red", message: err });
      });
      const filteredSales = sales.filter((item) => item.sale_id && item.dispatch_dispatchdocs_id != "PRO-FORMA");
      this.setState({ salesData: filteredSales });
    }

    this.setState({ salesLoading: false });
  };

  handleCloseBarcodesModal = () => {
    this.setState({ showAddBarcodesModal: false });
  };

  render() {
    const { classes } = this.state;
    const infoDetailItemData = this.props.data.sort((a, b) => a.sequence - b.sequence);
    const totals = this.props.barcodesDetail.reduce(
      (obj, row) => {
        obj = {
          palletSum: obj["palletSum"] + row.barcode_palletsize,
          cartonSum: obj["cartonSum"] + row.barcode_nocartons,
        };
        return obj;
      },
      { palletSum: 0, cartonSum: 0 },
    );

    return (
      <div className={classes.root} id="secondary_grid">
        {this.state.loading ? (
          <div>
            <CircularProgress size={24} />
          </div>
        ) : (
          <div className={classes.gridWrapper}>
            <Toolbar className={classes.toolbar}>
              <Button variant="contained" type="button" color="primary" onClick={this.state.handleClearAll}>
                Clear All
              </Button>
              <Button variant="contained" type="button" color="primary" onClick={this.state.handleAddRow}>
                <IconAdd />
              </Button>
              <Select value={this.state.templateSelected} onChange={this.handleTemplateSelected} style={{ width: "200px", backgroundColor: "lightgray" }}>
                {this.state.templateOptions.map((item) => {
                  return (
                    <MenuItem key={`${item.id}`} value={item.id}>
                      {item.value}
                    </MenuItem>
                  );
                })}
              </Select>
              <Button variant="contained" color="primary" onClick={this.handleOpenBarcodesModal}>
                Add Shipment(s)
              </Button>
            </Toolbar>
            <Grid
              fill
              loading={this.state.loading}
              data={infoDetailItemData}
              GridColumns={this.GridColumnsOverride}
              handleChange={this.state.handleChange}
              handleGridKeyDown={this.handleKeyDown}
              selectedRowChange={this.handleSelectedRowChange}
              forceHeight={this.props.gridHeight}
              clearFilters={this.state.itemDetailFilter}
              rowRenderer={this.rowRenderer}
              totalRowColumns={["rate", "units", "vatamount", "total", "totalexclvat"]}
            />
            {/* <CreditorsTotals creditorsInvoiceId={this.state.invoiceid} creditorsInvoiceItems={this.props.data} horizontal={false} /> */}
          </div>
        )}
        <Confirmation
          isOpen={this.state.showAddBarcodesModal}
          handleClose={this.handleCloseBarcodesModal}
          handleConfirm={this.handleCloseBarcodesModal}
          title="Add Barcodes/Invoice"
          body={undefined}
        >
          <div style={{ width: "calc(100vw / 2)" }}>
            {this.state.salesLoading ? (
              <div style={{ backgroundColor: "white", height: `${this.props.gridHeight + 60}px` }}>
                <CircularProgress size={24} />
              </div>
            ) : (
              <div className={classes.gridWrapper}>
                <Toolbar className={classes.toolbar} style={{ display: "flex", flexDirection: "row", gap: "10px" }}>
                  {/* <div>
                    <Button variant="contained" color="primary" style={{ marginLeft: "5px", backgroundColor: "orange" }} onClick={this.handleLoadManualBarcodes}>
                      barcodes
                    </Button>
                  </div> */}
                  <div>
                    <FormControlLabel
                      label="Grouped"
                      control={
                        <Checkbox
                          checked={this.props.grouped}
                          onChange={(event) => this.state.handleGroupedChanged(event.target.checked)}
                          inputProps={{ "aria-label": "primary checkbox" }}
                        />
                      }
                    />
                    <span style={{ fontWeight: "bold", color: "#ed7d31" }}>
                      {totals.palletSum ? `${numeral(totals.palletSum).format("0")} Pallet(s) ` : ``}
                      {totals.cartonSum ? `| ${numeral(totals.cartonSum).format("0")} Carton(s)` : ``}
                    </span>
                  </div>
                </Toolbar>
                {this.props.grouped && (
                  <Grid
                    data={this.state.salesData}
                    showFilterChips={false}
                    loading={this.state.salesLoading}
                    GridColumns={GridColumnsSales}
                    forceHeight={this.props.gridHeight}
                    clearFilters={"creditorsinfosalesdetailfilter"}
                    rowRenderer={(props) => this.invoiceGroupedRowRenderer(props, classes)}
                    totalRowColumns={["barcode_nocartons", "barcode_palletsize", "total"]}
                  />
                )}
                {!this.props.grouped && (
                  <Grid
                    data={this.props.barcodesDetail}
                    showFilterChips={false}
                    GridColumns={InvoiceGridColumns}
                    loading={this.props.loading}
                    forceHeight={this.props.gridHeight}
                    clearFilters={"creditorsinfobarcodesdetailfilter"}
                    rowRenderer={(props) => this.invoiceRowRenderer(props, classes)}
                    totalRowColumns={["barcode_nocartons", "barcode_palletsize", "total"]}
                  />
                )}
              </div>
            )}
          </div>
        </Confirmation>
      </div>
    );
  }
}

export default withStyles(styles)(InfoDetailsUnstyled);
