import React from "react";

import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";

import { agent } from "../../lib/api/agent";

import Button from "@material-ui/core/Button";
import Check from "@material-ui/icons/Check";
import Checkbox from "@material-ui/core/Checkbox";
import Close from "@material-ui/icons/Close";
import Input from "@material-ui/core/Input";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1),
    },
    tableColDesc: {
      textAlign: "right",
      paddingRight: theme.spacing(1),
    },
    tableColValue: {
      textAlign: "left",
      width: "115px",
      paddingBottom: "15px",
    },
    select: {
      minWidth: 170,
      marginLeft: 5,
    },
    textField: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      marginBottom: "-16px",
    },
    tableRowFieldTitle: {
      paddingTop: theme.spacing(1),
      paddingRight: theme.spacing(1) * 2,
      marginTop: theme.spacing(1),
      textAlign: "right",
      minWidth: "145px",
    },
    tableRowFieldData: {
      paddingTop: theme.spacing(1),
      paddingRight: theme.spacing(1) * 2,
      marginTop: theme.spacing(1),
      textAlign: "left",
    },
    tableRowFieldInput: {
      width: "100%",
    },
  });

type AgentFormProps = {
  onSubmit: any;
  onClose: any;
  id: any;
} & WithStyles<typeof styles>;

class AgentFormPropsUnstyled extends React.Component<AgentFormProps, any> {
  state = {
    classes: undefined,
    item: {
      id: "0",
      agentname: "",
      note: "",
      default: 0,
    },
    onSubmit: undefined,
    onClose: undefined,
    id: 0,
  };

  constructor(props) {
    super(props);
    this.state.classes = props.classes;
    this.state.onSubmit = props.onSubmit;
    this.state.onClose = props.onClose;
    this.state.id = props.id;
  }

  loadData = async () => {
    await Promise.all([agent.all()]).then((results) => {
      this.setState({ agentData: results[0] }, () => { });
    });
  };

  componentWillMount() {
    this.loadData().then(() => {
      if (this.state.id != 0) {
        this.getItem(this.state.id);
      }
    });
  }

  getItem = (id) => {
    agent.single(id).then((data) => {
      this.setState({ item: data });
    });
  };

  handleSubmit = () => {
    this.state.onSubmit(this.state.item);
  };

  handleDefaultChanged = (ev) => {
    this.setState({ item: { ...this.state.item, default: ev.target.checked ? 1 : 0 } });
  }

  render() {
    const { classes } = this.state;
    return (
      <div className={classes.root}>
        <form
          id={this.state.item.id}
          onSubmit={(e) => {
            this.handleSubmit();
          }}
        >
          <table style={{ marginLeft: "15px" }}>
            <tbody>
              <tr>
                <td className={classes.tableRowFieldTitle}>
                  <span>Agent Name:</span>
                </td>
                <td className={classes.tableRowFieldData}>
                  <Input
                    fullWidth
                    name={"agentname"}
                    key={"agentname"}
                    value={this.state.item.agentname}
                    type="text"
                    className={classes.tableRowFieldInput}
                    onChange={(e) => {
                      this.setState({
                        item: { ...this.state.item, agentname: e.target.value },
                      });
                    }}
                  />
                </td>
              </tr>
              <tr>
                <td className={classes.tableRowFieldTitle} style={{ paddingBottom: "45px" }}>
                  <span>Note:</span>
                </td>
                <td className={classes.tableRowFieldData}>
                  <Input
                    multiline={true}
                    rowsMax={3}
                    rows={3}
                    fullWidth
                    name={"note"}
                    key={"note"}
                    value={this.state.item.note}
                    type="text"
                    className={classes.tableRowFieldInput}
                    onChange={(e) => {
                      this.setState({
                        item: { ...this.state.item, note: e.target.value },
                      });
                    }}
                  />
                </td>
              </tr>
              <tr>
                <td className={classes.tableRowFieldTitle}>
                  <span>Default:</span>
                </td>
                <td className={classes.tableRowFieldData}>
                  <Checkbox
                    checked={this.state.item.default === 1}
                    onChange={this.handleDefaultChanged}
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                  />
                </td>
              </tr>
            </tbody>
          </table>
          <div style={{ display: "flex", justifyContent: "flex-end", margin: "10px 0 -5px 0" }}>
            <Button style={{ marginRight: "10px" }} color="secondary" variant="outlined" onClick={this.state.onClose}>
              <Close />
            </Button>
            <Button type="submit" color="primary" variant="outlined" onClick={() => this.handleSubmit()}>
              <Check />
            </Button>
          </div>
        </form>
      </div>
    );
  }
}

export default withStyles(styles)(AgentFormPropsUnstyled);
