import React from "react";
import { useEffect, useContext } from "react";

import { history } from "./lib/history";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import CssBaseline from "@material-ui/core/CssBaseline";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";

import { whoami } from "./lib/api/_base";

import Arrival from "./arrival/arrival";
import AdhocInvoice from "./adhocinvoice/index";
import AdhocInvoiceEdit from "./adhocinvoice/edit/index";

import CreditorsSummary from "./creditors/summary/summarygrid";
import CreditorsInvoice from "./creditors/invoice/creditorsinvoice";
import CreditorsInfo from "./creditors/invoiceinfo/creditorsInfo";

import Dashboard from "./layout/dashboard";
import Dispatch from "./dispatch/dispatch";
import DebtorsFull from "./debtors/full/debtors";
import DebtorsSummary from "./debtors/summary/index";
import DebtorsFunds from "./debtors/funds/funds";
import DebtorsFundsSummary from "./debtors/funds/summary/summary";
import DebtorsAdhoc from "./debtors/full/adhoc/index";

import EDIImport from "./edi/import/import";
import EDIImportInfo from "./edi/importinfo/importinfo";
import EDITemplate from "./edi/template/template";
import EDISearch from "./edi/search/search";

import Finance from "./finance/finance";
import Consolidation from "./finance/consolidation/consolidation";

import Invoice from "./invoice/invoice";
import Instruction from "./instructions/instruction";
import InstructionTabbed from "./instructions/instructionTabbed";
import Inspections from "./inspections/inspections";
import { Login } from "./login";
import LoadOut from "./loadout/loadout";
import Maintenance from "./layout/maintenance";
import Notifications from "./notifications/notifications";
import Orders from "./orders/orders";

import StockAllocation from "./stockallocation/stockallocation";
import StockPricing from "./stockpricing/stockpricing";
import SystemStability from "./systemstability/systemstability";

import UserManagement from "./user-management/UserManagement";

import ProducersGroupedOnly from "./producers/advancepayments/grouped/producers";
import ProducersGrouped from "./producers/advancepayments/payments/producers";
import ProducersInvoice from "./producers/invoice/invoice";
import ProducersAdjustment from "./producers/adjustments/adjustments";
import ProducerInvoicesSummary from "./producers/invoice/summary/index";
import ProducerAdjustmentsSummary from "./producers/adjustments/summary/index";

import MGPIntergrationGrid from "./mgpintegration/index";

import { WSSProvider, WSSContext } from "./lib/context/WSSContext";
import { UserProvider } from "./lib/context/UserContext";
import { HydrateUser, HydrateApp } from "./lib/context/Hydrate";
import { SnackContext, SnackProvider } from "./lib/context/SnackContext";
import { EnquiryProvider } from "./lib/context/EnquiryContext";
import { FiltersProvider } from "./lib/context/FiltersContext";

import EDIFileComparison from "./maintenance/edifilecomparison/edifilecomparison";

import __MOCK__ from "./__mock__/templates";
import { SnackMessage } from "./lib/helpers/snackmessage";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      boxShadow: "none",
    },
  });

export type AppProps = {} & WithStyles<typeof styles>;

const LoginGaurd: React.FunctionComponent<any> = ({ match }) => {
  const { hydrate } = useContext(WSSContext);

  useEffect(() => {
    whoami()
      .then((user) => {
        if (!user.username) {
          throw new Error("Not logged in");
        }
        hydrate();
      })
      .catch((e) => {
        history.push("/login");
      });
  }, [history.location.pathname]);
  // change this null to undefined & reload does not work properly
  return null;
};

const Snack = () => {
  const { updateSnack, snack } = useContext(SnackContext);
  return <SnackMessage isOpen={snack.show} message={snack.message} color={snack.color} handleClose={() => updateSnack({ show: false })} />;
};

// class AppUnstyled extends React.Component<any, any> {
const AppUnstyled: React.FunctionComponent<AppProps> = ({ children, classes }) => {
  return (
    <div className={classes.root}>
      <CssBaseline />
      <Router history={history}>
        <>
          <Route component={LoginGaurd} />
          <SnackProvider>
            <UserProvider>
              <Snack />
              <Route component={HydrateUser} />
              <Switch>
                <Route path="/login" component={(props) => <Login {...props} />} />
                <WSSProvider>
                  <EnquiryProvider>
                    <Route component={HydrateApp} />
                    <Route
                      render={({ history }) => (
                        <FiltersProvider history={history}>
                          <Dashboard>
                            <Switch>
                              <Redirect to={"/orders"} from={"/"} exact />
                              <Route path="/arrival" component={(props) => <Arrival {...props} />} />
                              <Route path="/adhocinvoice/:id" component={(props) => <AdhocInvoiceEdit {...props} />} />
                              <Route path="/adhocinvoice" component={(props) => <AdhocInvoice {...props} />} />
                              <Route path="/creditors/summary" component={(props) => <CreditorsSummary {...props} />} />
                              <Route path="/creditors/invoice/:code" component={(props) => <CreditorsInvoice {...props} />} />
                              <Route path="/creditors/info/:id/:creditor" component={(props) => <CreditorsInfo {...props} />} />
                              <Route path="/creditors/info/:id" component={(props) => <CreditorsInfo {...props} />} />
                              <Route path="/dashboard" component={(props) => <Dashboard {...props} />} />
                              <Route path="/dispatch/:reference" component={(props) => <Dispatch {...props} />} />
                              <Route path="/dispatch" component={(props) => <Dispatch {...props} />} />
                              <Route path="/debtors/summary" component={(props) => <DebtorsSummary {...props} />} />
                              <Route path="/debtors/full" component={(props) => <DebtorsFull {...props} />} />
                              <Route path="/debtors/adhoc/:adhoc_id" component={(props) => <DebtorsAdhoc {...props} />} />
                              <Route path="/debtors/funds/:clients_code" component={(props) => <DebtorsFunds {...props} />} />
                              <Route path="/debtors/fundssummary" component={(props) => <DebtorsFundsSummary {...props} />} />
                              <Route path="/edi/fileImports/:id" component={(props) => <EDIImportInfo {...props} />} />
                              <Route path="/edi/fileImports" component={(props) => <EDIImport {...props} />} />
                              <Route path="/edi/template" component={(props) => <EDITemplate {...props} />} />
                              <Route path="/edi/search" component={(props) => <EDISearch {...props} />} />
                              <Route path="/finance/finance" exact component={(props) => <Finance {...props} />} />
                              <Route path="/finance/consolidation" exact component={(props) => <Consolidation {...props} />} />
                              <Route path="/invoice/:loadoutid" component={(props) => <Invoice {...props} />} />
                              <Route path="/invoice" component={(props) => <Invoice {...props} proforma={false} />} />
                              <Route path="/invoiceproforma/:loadoutid" component={(props) => <Invoice {...props} proforma={true} />} />
                              <Route path="/invoiceproforma" component={(props) => <Invoice {...props} proforma={true} />} />
                              <Route path="/invoicing" component={(props) => <MGPIntergrationGrid {...props} />} />
                              <Route path="/instruction/:id/edit" component={(props) => <Instruction {...props} />} />
                              <Route path="/instructiontabbed/:id/edit" component={(props) => <InstructionTabbed {...props} />} />
                              <Route path="/instruction/create" component={(props) => <Instruction {...props} />} />
                              <Route path="/inspection" component={(props) => <Inspections {...props} />} />
                              <Route path="/shipment" component={(props) => <LoadOut {...props} />} />
                              <Route path="/maintenance" component={(props) => <Maintenance {...props} />} />
                              <Route path="/notifications" component={(props) => <Notifications {...props} />} />
                              <Route path="/orders" component={(props) => <Orders {...props} />} />
                              <Route path="/producers/invoice/summary" component={(props) => <ProducerInvoicesSummary {...props} />} />
                              <Route path="/producers/invoice/:producercode" component={(props) => <ProducersInvoice {...props} />} />
                              <Route path="/producers/groupedonly" component={(props) => <ProducersGroupedOnly {...props} />} />
                              <Route path="/producers/grouped/:producerid" component={(props) => <ProducersGrouped {...props} />} />
                              <Route path="/producers/adjustments/summary" component={(props) => <ProducerAdjustmentsSummary {...props} />} />
                              <Route path="/producers/adjustments/:producercode" component={(props) => <ProducersAdjustment {...props} />} />
                              <Route path="/stockcomparison" component={(props) => <EDIFileComparison {...props} />} />
                              <Route path="/stockallocation" component={(props) => <StockAllocation {...props} />} />
                              <Route path="/stockpricing" component={(props) => <StockPricing {...props} />} />
                              <Route path="/systemstability" component={(props) => <SystemStability {...props} />} />
                              <Route path="/users" component={(props) => <UserManagement {...props} />} />
                              <Route path="/__mock__" component={(props) => <__MOCK__ {...props} />} />
                            </Switch>
                          </Dashboard>
                        </FiltersProvider>
                      )}
                    />
                  </EnquiryProvider>
                </WSSProvider>
              </Switch>
            </UserProvider>
          </SnackProvider>
        </>
      </Router>
    </div>
  );
};

export default withStyles(styles)(AppUnstyled);
