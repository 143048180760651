import { config } from "../../config";
import { client, apiHeaders, request } from "./_base";
import { getFinancialYearSelected } from "./week";

const endpoint = "salefundsallocation";

export type SaleFundsAllocationType = {
  id?: number;
  salefunds_id?: number;
  sale_id?: number;
  amount: number;
  paymentnumber?: number;
  created_at?: Date;
  updated_at?: Date;
};

export type AllocationList = {
  id?: number;
  amount: number;
  amountCredit: number;
  amountDebit: number;
  invoicenumber: string;
  makeanote: string;
  paymentnumber: number;
  posteddate: Date;
  sale_id: number;
  salefunds_id: number;
  orig_amount: number;
  amount_diff?: number;
  currIndx: number;
  dirty?: boolean;
  deleted?: boolean;
  is_overpaid?: number;
  overpaid_id?: number;
  overpaid_sale_id?: number;
  is_locked?: number;
};

export const SaleFundsAllocation = client(endpoint, (item) => ({
  ...item,
}));

export type SaleFundsAllocationBySaleFundsIdType = {
  id?: number;
  salefunds_id: number;
  sale_id: number;
  amount: number;
  paymentnumber: number;
};

export const getSaleFundsAllocationBySaleFundsId = async (salefundsid: number): Promise<{ data: AllocationList[] }> => {
  const result = await request(`${endpoint}/ex/getBySaleFundsId/${salefundsid}`, { method: "GET" });
  if (result.status == 500) {
    const resp = await result.json();
    throw resp;
  }
  return result.json();
};

export type SaleFundsAllocationBySaleIdType = {
  salefunds_id: number;
  salefunds_clients_id: number;
  salefunds_posteddate: Date;
  salefunds_amount: number;
  salefunds_status: number;
  salefunds_currency_id: number;
  salefunds_exchangerate: number;
  salefunds_makeanote: string;
  salefunds_bankcharges: number;
  salefunds_companydetailsbanking_id: number;
  banking_bank: string;
  banking_accountnumber: string;
  alloc_id: number;
  alloc_amount: number;
  alloc_paymentnumber: number;
  alloc_sale_id: number;
  clients_code: string;
  clients_name: string;
  currency: string;
};
export const getSaleFundsAllocationBySaleId = async (saleid: number): Promise<{ data: SaleFundsAllocationBySaleIdType[] }> => {
  const result = await request(`${endpoint}/ex/getBySaleId/${saleid}`, {
    method: "GET",
  });
  if (result.status == 500) {
    const resp = await result.json();
    throw resp;
  }
  return result.json();
};

export const removeSaleFundsAllocationBySaleFundsId = async (salefundsid) => {
  return request(`${endpoint}/ex/removeBySaleFundsId/${salefundsid}`, {
    method: "GET",
  })
    .then(async (result) => {
      if (result.status == 500) {
        const resp = await result.json();
        throw resp;
      }
      return result.json();
    })
    .then((data) => data);
};

type DeletedAllocationsType = { id: number; amount: number };
type OverpaidSalesList = { amount: number; total_amount: number; sale_id: number; overpaid_sale_id: number };

type SaleFundsAllocationList = {
  id?: number;
  salefunds_id: number;
  sale_id: number;
  amount: number;
  paymentnumber: number;
  overpaidToAllocate: OverpaidSalesList[];
};

export const allocateSaleFunds = async (salesToAllocate: SaleFundsAllocationList[], deletedAllocations: DeletedAllocationsType[]): Promise<void> => {
  const result = await request(`${endpoint}/ex/allocateSaleFunds`, {
    method: "POST",
    body: JSON.stringify({ deletedAllocations, salesToAllocate }),
  });
  if (result.status == 500) {
    const resp = await result.json();
    throw resp;
  }
};

export const getAllocationsBySaleIdAndPaymentNumber = async (sale_id: number, paymentnumber: number): Promise<AllocationList[]> => {
  const result = await request(`${endpoint}/ex/allocationsBySaleIdAndPaymentNumber?sale_id=${sale_id}&paymentnumber=${paymentnumber}`, {
    method: "GET",
  });
  const resp = await result.json();
  if (result.status == 500) {
    throw resp;
  }
  return resp.data;
};

export const allocationsExistBySalefundsId = async (salefunds_id: number): Promise<{ allocation_id: number }[]> => {
  const result = await request(`${endpoint}/ex/allocationsExistBySalefundsId/${salefunds_id}`, {
    method: "GET",
  });
  const resp = await result.json();
  if (result.status == 500) {
    throw resp;
  }
  return resp.data;
};

export type AllocationSummary = {
  invoicenumber: string;
  paymentnumber: number;
  posted_date: string;
  received_amount: number;
};

export const getAllocationsSummary = async (clients_code: string): Promise<AllocationSummary[]> => {
  const result = await request(`${endpoint}/ex/allocationsSummary?financial_year=${getFinancialYearSelected()}&clients_code=${clients_code}`, {
    method: "GET",
  });
  const resp = await result.json();
  if (result.status == 500) {
    throw resp;
  }
  return resp.data;
};
