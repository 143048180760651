import React, { useEffect } from "react";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";

import LinearProgress from "@material-ui/core/LinearProgress";

import Button from "@material-ui/core/Button";

import IconRemove from "@material-ui/icons/Delete";
import IconSave from "@material-ui/icons/Save";

import { updateStockByBarcodeID, updateStockWeightByBarcodeID } from "../lib/api/stock";
import { deleteByBarcode } from "../lib/api/loadoutdetail";
import { stockDetailDeleteByBarcode } from "../lib/api/stockdetail";

import Confirmation from "../lib/components/confirmation";
import { Form, Field } from "react-final-form";
import { TextField } from "final-form-material-ui";
import { isNumber } from "util";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1),
    },
    content: {
      width: "100%",
    },
    container: {
      paddingTop: "20px",
      paddingBottom: "10px",
      float: "right",
    },
    buttonRight: {
      marginRight: theme.spacing(1),
    },
    tableDescription: {
      width: "100px",
    },
    tableValue: {
      width: "100px",
    },
    tableAction: {
      width: "50px",
    },
  });

type LoadoutTableActionDialogProps = {
  row: any;
  handleClose: () => void;
} & WithStyles<typeof styles>;

export const LoadoutTableActionDialogUnstyled: React.FunctionComponent<LoadoutTableActionDialogProps> = (props) => {
  const { classes, row, handleClose } = props;

  const [loading, setLoading] = React.useState(false);
  const [removeRecord, setRemoveRecord] = React.useState(false);
  const [unallocateRecord, setUnallocateRecord] = React.useState(false);

  useEffect(() => {}, [row]);

  const handleRemoveSelectedRecord = () => {
    setRemoveRecord(true);
    setUnallocateRecord(false);
  };

  const handleRemoveSelectedRecordClose = () => {
    setRemoveRecord(false);
    setUnallocateRecord(false);
  };

  const handleRemoveSelectedRecordConfirm = () => {
    deleteByBarcode(row.barcode).then(() => {
      setUnallocateRecord(true);
    });
  };

  const handleUnAllocateClose = () => {
    setRemoveRecord(false);
    setUnallocateRecord(false);
  };

  const handleUnAllocateConfirm = async () => {
    await stockDetailDeleteByBarcode(row.barcode);
    await updateStockByBarcodeID(row.barcode_id, {
      "Consignee ID": "",
      "Ref No": "",
      userName: "",
      Allocated: 0,
      "Pre Allocated": 0,
    });
    setRemoveRecord(false);
    setUnallocateRecord(false);
  };

  const handleSubmit = (data) => {
    const toUpdate = {
      weight: data.weight,
      grossWeight: data.grossWeight,
      noCartons: data.noCartons,
      palletSize: data.palletSize,
    };

    updateStockWeightByBarcodeID(data.barcode_id, toUpdate).then(() => {
      handleClose();
    });
  };

  return (
    <div className={classes.root}>
      {removeRecord && (
        <Confirmation
          isOpen={true}
          handleClose={handleRemoveSelectedRecordClose}
          handleConfirm={handleRemoveSelectedRecordConfirm}
          title={`Remove ${row.barcode}?`}
          body={undefined}
        ></Confirmation>
      )}
      {unallocateRecord && (
        <Confirmation
          isOpen={true}
          handleClose={handleUnAllocateClose}
          handleConfirm={handleUnAllocateConfirm}
          title={`Stock Allocation`}
          body={`Do you want to remove the pre-allocation on selected stock items?`}
          fullscreen={false}
        ></Confirmation>
      )}
      {loading ? (
        <LinearProgress />
      ) : (
        <div className={classes.content}>
          <Form
            initialValues={{ ...row }}
            onSubmit={(values) => {
              const submitData = { ...values };
              handleSubmit(submitData);
            }}
            validate={(values) => {
              let errors = {};

              if (!/^(\d*\.)?\d+$/g.test(values["grossWeight"])) {
                errors = { ...errors, grossWeight: "not a valid number" };
              }
              if (!/^(\d*\.)?\d+$/g.test(values["weight"])) {
                errors = { ...errors, weight: "not a valid number" };
              }
              return errors;
            }}
            render={({ handleSubmit, form }) => (
              <form onSubmit={handleSubmit}>
                <table>
                  <tbody>
                    <tr>
                      <td className={classes.tableDescription}>Gross Weight:</td>
                      <td>
                        <Field name={"grossWeight"} component={TextField} type="text" fullWidth={true} />
                      </td>
                    </tr>
                    <tr>
                      <td className={classes.tableDescription}>Nett Weight:</td>
                      <td>
                        <Field name={"weight"} component={TextField} type="text" fullWidth={true} />
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div className={classes.container}>
                  <Button
                    variant="contained"
                    color="secondary"
                    className={classes.buttonRight}
                    startIcon={<IconRemove />}
                    onClick={() => {
                      handleRemoveSelectedRecord();
                    }}
                  >
                    Remove
                  </Button>
                  <Button type="submit" variant="contained" color="primary" className={classes.buttonRight} startIcon={<IconSave />}>
                    Save
                  </Button>
                  <Button name="close" variant="contained" color="secondary" style={{ margin: "2px" }} onClick={handleClose}>
                    Close
                  </Button>
                </div>
              </form>
            )}
          />
        </div>
      )}
    </div>
  );
};

export default withStyles(styles)(LoadoutTableActionDialogUnstyled);
