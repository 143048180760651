import jsPDF from "jspdf";

import { image as logo } from "./elements/logo";
import { PTSans } from "./elements/font";
import { ProducerType } from "../lib/api/producer";
import { ProducersFullGroupedType, ProducersFullType } from "../lib/api/producersfull";
import format from "date-fns/format";
import parseISO from "date-fns/parseISO";
import numeral from "numeral";
import { get } from "./helpers";
import { CompanyDetailsType } from "../lib/api/companydetails";
import { AdditionalDetails } from "./utils/Types";
import { AdhocDetail } from "../lib/api/produceradvancepaidadhocdetail";

enum Currency {
  ZAR = "R",
  EUR = "€",
  USD = "$",
  GBP = "£",
  YEN = "¥",
  CAD = "$",
}

let pdf,
  fromTop = 1,
  page = 1,
  fromLeft = 9.0,
  lastLinewritten = 0,
  pages = [],
  fullWidth = 0,
  fullHeight = 0;

const lineHeight = 0.5,
  grey = 210;

const setFontBold = () => {
  pdf.setFontStyle("bold");
  pdf.setFont("helvetica"); // set font
};

const setFontNormal = () => {
  pdf.setFontStyle("normal");
  pdf.setFont("PTSans"); // set font
};

const InitialisePDF = () => {
  pdf = new jsPDF({
    orientation: "portrait",
    unit: "cm",
  });

  fullWidth = pdf.internal.pageSize.getWidth();
  fullHeight = pdf.internal.pageSize.getHeight();
  page = 1;
  pages = [page];
  fromTop = 2;
  fromLeft = 1;

  pdf.addFileToVFS("PTSans.ttf", PTSans);
  pdf.addFont("PTSans.ttf", "PTSans", "normal");

  pdf.setFont("PTSans");
  page = 1;

  fromTop = 1;
  fromLeft = 1.2;

  pdf.setLineWidth(0.01);
};

const detailColumnHeaders = [
  { dataKey: "bar_intakewaybill", heading: "Waybill", width: 2, alignment: "left" },
  { dataKey: "barcode", heading: "Barcode", width: 3.5, alignment: "left" },
  { dataKey: "bar_CommodityCode", heading: "Comm", width: 1.4, alignment: "center" },
  { dataKey: "bar_VarietyCode", heading: "Var", width: 1.4, alignment: "center" },
  { dataKey: "bar_GradeCode", heading: "Grade", width: 1.4, alignment: "center" },
  { dataKey: "bar_CountCode", heading: "Count", width: 1.4, alignment: "center" },
  { dataKey: "bar_PackCode", heading: "Pack", width: 1.4, alignment: "center" },
  { dataKey: "bar_MarkCode", heading: "Mark", width: 1.4, alignment: "center" },
  { dataKey: "bar_NoCartons", heading: "Cartons", width: 1.4, alignment: "center" },
  { dataKey: "advance", heading: "Advance", width: 2.5, alignment: "center" },
  { dataKey: "total", heading: "Total", width: 2, alignment: "right" },
];

const detailRowHeaders = [
  { dataKey: "bar_intakewaybill", heading: "Waybill", width: 2, alignment: "left" },
  { dataKey: "barcode", heading: "Barcode", width: 3.5, alignment: "left" },
  { dataKey: "bar_CommodityCode", heading: "Comm", width: 1.4, alignment: "center" },
  { dataKey: "bar_VarietyCode", heading: "Var", width: 1.4, alignment: "center" },
  { dataKey: "bar_GradeCode", heading: "Grade", width: 1.4, alignment: "center" },
  { dataKey: "bar_CountCode", heading: "Count", width: 1.4, alignment: "center" },
  { dataKey: "bar_PackCode", heading: "Pack", width: 1.4, alignment: "center" },
  { dataKey: "bar_MarkCode", heading: "Mark", width: 1.4, alignment: "center" },
  { dataKey: "bar_NoCartons", heading: "Cartons", width: 1.4, alignment: "right" },
  { dataKey: "advance", heading: "Advance", width: 2.5, alignment: "center" },
  { dataKey: "total", heading: "Total", width: 2, alignment: "right" },
];

// const formatNumber = (value: string | number) => {
//   return numeral(value).format("0,0").toString();
// };

const formatNumberDecimals = (value: string | number) => {
  return numeral(value).format("0,0.00").toString();
};

const newPage = () => {
  pdf.addPage();

  page++;
  pages.push(page);
  pdf.setPage(page);
};

const addImage = (imageData: string, format: string, fromLeft: number, fromTop: number, width: number, height: number, alias?, compression?, rotation?) => {
  pdf.addImage(imageData, format, fromLeft, fromTop, width, height, alias, compression, rotation);
};

const newLine = (howFar: number = 0.5) => {
  fromTop = fromTop + howFar;
};

const headerColumn1 = () => {
  return 3.9;
};

const headerColumn2 = () => {
  return fullWidth - 6;
};

const printLineLeft = (label: string, value: string, posX?: number): void => {
  fromLeft = posX || 1.2;
  setFontBold();
  pdf.text(fromLeft, fromTop, label, "right");

  setFontNormal();
  pdf.text(fromLeft + 0.25, fromTop, value || "", "left");
};

const printLineRight = (label: string, value: string, posX?: number): void => {
  fromLeft = posX || 12.5;
  setFontBold();
  pdf.text(fromLeft, fromTop, label, "right");

  setFontNormal();
  pdf.text(fromLeft + 0.25, fromTop, value || "", "left");
};

const createHeader = (companyDetails: CompanyDetailsType) => {
  fromTop = 1;
  fromLeft = 1;

  const imageWidth = 5;
  const imageHeight = 1.5;

  addImage(logo, "PNG", (fullWidth - imageWidth) / 2 - 1, fromTop + 0.2, imageWidth, imageHeight);
  pdf.setFontSize(12);
  setFontBold();
  pdf.text("REMITTANCE ADVICE", fullWidth / 2 - 1, fromTop + 2.5, "center");
  pdf.text("ADVANCE PAYMENTS ON INTAKES", fullWidth / 2 - 1, fromTop + 3.1, "center");

  pdf.setFontSize(11);
  setFontBold();
  pdf.text(get("name", companyDetails, ""), fromLeft, fromTop, "left");

  setFontNormal();
  pdf.setFontSize(8);

  newLine(0.4);
  pdf.text(get("address1", companyDetails, ""), fromLeft, fromTop, "left");

  newLine(0.4);
  pdf.text(get("address2", companyDetails, ""), fromLeft, fromTop, "left");

  newLine(0.4);
  pdf.text(get("address3", companyDetails, ""), fromLeft, fromTop, "left");

  newLine(0.4);
  pdf.text(get("address4", companyDetails, ""), fromLeft, fromTop, "left");

  newLine(0.4);
  pdf.text(get("address5", companyDetails, ""), fromLeft, fromTop, "left");

  fromLeft = headerColumn2();
  fromTop = 1;

  pdf.text("Tel No:", fromLeft, fromTop, "right");
  pdf.text(get("telephoneNumber", companyDetails, ""), fromLeft + 0.2, fromTop, "left");

  newLine(0.4);
  pdf.text("Fax No:", fromLeft, fromTop, "right");
  pdf.text(get("faxNumber", companyDetails, ""), fromLeft + 0.2, fromTop, "left");

  newLine(0.4);
  pdf.text("E-mail:", fromLeft, fromTop, "right");
  pdf.text(get("email", companyDetails, ""), fromLeft + 0.2, fromTop, "left");

  newLine(0.4);
  pdf.text("Vat No:", fromLeft, fromTop, "right");
  pdf.text(get("vatno", companyDetails, ""), fromLeft + 0.2, fromTop, "left");

  newLine(0.4);
  pdf.text("Reg No:", fromLeft, fromTop, "right");
  pdf.text(get("regno", companyDetails, ""), fromLeft + 0.2, fromTop, "left");

  newLine(0.4);
  pdf.text("Customs Code:", fromLeft, fromTop, "right");
  pdf.text(get("customscode", companyDetails, ""), fromLeft + 0.2, fromTop, "left");

  newLine(1);
};

const addressLineArray = (address: string, arr = []) => {
  if (!address) {
    return null;
  }

  let words = (address || "").split(" ");

  if (address.length > 42) {
    let line = "";
    let rem = address.split(" ");

    for (let i = 0; i < words.length; i++) {
      const currLine = line + " " + words[i];

      if (currLine.length >= 42) {
        break;
      } else {
        rem.splice(0, 1);
        line = currLine.trim();
      }
    }

    arr.push(line);

    if (rem.length > 0) {
      return addressLineArray(rem.join(" "), arr);
    } else {
      return arr;
    }
  } else {
    return [...arr, words.join(" ")];
  }
};

const createProducerDetails = (header: ProducerType, additional_details: AdditionalDetails): void => {
  const clientAddress = header.physical_address ? header.physical_address.replace(/[\r\n]/g, " ") : "";
  const clientAddressArr = addressLineArray(clientAddress) || [];
  const LEFT_COL = headerColumn1();
  const RIGHT_COL = headerColumn2();

  const startRect = fromTop;
  newLine(1);

  printLineLeft("Producer :", header.code || "", LEFT_COL);
  printLineRight("Date :", format(additional_details.payment_date, "dd/MM/yyyy"), RIGHT_COL);
  newLine(0.4);

  printLineLeft("Address :", clientAddressArr.splice(0, 1), LEFT_COL);
  printLineRight("Remittance ID :", additional_details.idents, RIGHT_COL);
  newLine(0.4);

  printLineLeft("Name :", header.name || "", LEFT_COL);
  printLineRight("Description :", additional_details.makeanote, RIGHT_COL);
  newLine(0.4);

  printLineLeft("Tel :", header.producer_telephone || "", LEFT_COL);
  printLineRight("Amount:", additional_details.amounts, RIGHT_COL);
  newLine(0.4);

  printLineLeft("Finance Email :", header.finance_email || "", LEFT_COL);
  printLineRight("VAT No :", header.vat_no || "", RIGHT_COL);

  pdf.setLineWidth(0.01);
  fromLeft = 1;
  pdf.rect(fromLeft, startRect + 0.5, fullWidth - 2, fromTop - startRect, "S");

  fromTop += 0.5;
  lastLinewritten = fromTop;
};

const createAdvanceDetail = (selectedAdvance: ProducersFullGroupedType): void => {
  const startRect = fromTop;
  fromTop += 1;
  fromLeft = headerColumn1();

  setFontBold();
  pdf.text(fromLeft, fromTop, "Remittance ID :", "right");
  setFontNormal();
  pdf.text(fromLeft + 0.25, fromTop, selectedAdvance.produceradvancepaidhead_ident.toString(), "left");

  fromLeft = headerColumn2();
  setFontBold();
  pdf.text(fromLeft, fromTop, "Description :", "right");
  setFontNormal();
  pdf.text(fromLeft + 0.25, fromTop, selectedAdvance.produceradvancepaidhead_makeanote, "left");

  newLine(0.4);

  fromLeft = headerColumn1();
  setFontBold();
  pdf.text(fromLeft, fromTop, "Date :", "right");
  setFontNormal();
  const dateString = format(parseISO(selectedAdvance.produceradvancepaidhead_posteddate as any), "dd/MM/yyyy");
  pdf.text(fromLeft + 0.25, fromTop, dateString, "left");

  fromLeft = headerColumn2();
  setFontBold();
  pdf.text(fromLeft, fromTop, "Amount :", "right");
  setFontNormal();
  const currencyText = Currency[selectedAdvance.paid_currency].toString();
  pdf.text(fromLeft + 0.25, fromTop, currencyText, "left");

  const left = fromLeft + pdf.getTextDimensions(currencyText).w + 0.5;
  pdf.text(left, fromTop, formatNumberDecimals(selectedAdvance.sum_paidcurrencyValueTotal), "left");

  fromLeft = 1;
  pdf.setLineWidth(0.01);
  pdf.rect(fromLeft, startRect + 0.5, fullWidth - 2, fromTop - startRect, "S");

  lastLinewritten = fromTop;
};

const getAdvanceValue = (row: ProducersFullType) => {
  const currency = row.paid_currency.toLowerCase();
  if (row.wkcolddue_id) {
    return row[`accold_${currency}`];
  } else if (row.wkintakedue_id) {
    return row[`acintake_${currency}`];
  } else {
    return row[`acshipping_${currency}`];
  }
};

const getTotalValue = (row: ProducersFullType) => {
  const currency = row.paid_currency.toLowerCase();
  return row[`paid_${currency}`];
};

const createDetail = (detail: ProducersFullType[], selectedAdvance: ProducersFullGroupedType) => {
  const formattedDetail = detail.map((item) => ({
    bar_intakewaybill: item.bar_intakewaybill,
    barcode: item.barcode,
    bar_CommodityCode: item.bar_CommodityCode,
    bar_VarietyCode: item.bar_VarietyCode,
    bar_GradeCode: item.bar_GradeCode,
    bar_CountCode: item.bar_CountCode,
    bar_PackCode: item.bar_PackCode,
    bar_MarkCode: item.bar_MarkCode,
    bar_NoCartons: item.bar_NoCartons,
    advance: getAdvanceValue(item),
    total: getTotalValue(item),
    currency: item.paid_currency,
  }));

  const heightOfPage = pdf.internal.pageSize.getHeight();
  fromLeft = 1.5;
  fromTop = lastLinewritten + 2;

  // pdf detail header label
  pdf.setFontSize(12);
  setFontBold();
  pdf.text("Advance Payments", fromLeft, fromTop, "left");

  // cold week label
  pdf.setFontSize(10);
  fromTop += 0.75;
  pdf.text("Cold Week: ", fromLeft, fromTop, "left");

  // cold week detail
  setFontNormal();
  const left = pdf.getTextDimensions("Cold Week: ").w + 0.5;
  pdf.text(detail[0].weekcold_week, fromLeft + left, fromTop, "left");

  fromTop += 0.75;
  fromLeft = 1.2;
  pdf.setFontSize(8);

  // header columns
  for (let i = 0; i < detailColumnHeaders.length; i++) {
    const item = detailColumnHeaders[i];
    setFontBold();
    pdf.text(item.heading, fromLeft, fromTop, item.alignment);
    fromLeft += item.width;
  }

  // line under header
  pdf.setFillColor(grey);
  pdf.rect(1.2, fromTop + 0.15, 18, 0.01, "DF");

  fromTop += 0.25;

  // printing detail
  for (let i = 0; i < formattedDetail.length; i++) {
    const item = formattedDetail[i],
      keys = Object.keys(item).filter((col) => col != "currency");

    setFontNormal();
    fromLeft = 1.2;
    fromTop += 0.55;

    if (fromTop > heightOfPage - 2) {
      newPage();
      fromTop = 2;
    }

    for (let k = 0; k < keys.length; k++) {
      const col = detailRowHeaders[k];

      if (col.dataKey == "bar_intakewaybill") {
        setFontBold();
      } else {
        setFontNormal();
      }

      if (col.dataKey == "advance" || col.dataKey == "total") {
        const currencyText = Currency[item.currency].toString() + " ";
        pdf.text(currencyText + formatNumberDecimals(item[col.dataKey]), fromLeft, fromTop, col.alignment);
      } else {
        pdf.text(item[col.dataKey].toString(), fromLeft, fromTop, col.alignment);
      }

      fromLeft += col.width;
    }

    // subtotals row
    if (i === formattedDetail.length - 1) {
      fromTop += 0.5;

      pdf.setLineWidth(0.01);
      pdf.setFillColor(grey);
      pdf.rect(fullWidth / 2, fromTop, 8.5, 0.01, "DF");

      fromTop += 0.5;
      fromLeft = 1.2;

      for (let k = 0; k < keys.length; k++) {
        setFontNormal();
        const col = detailRowHeaders[k];

        if (col.dataKey == "bar_PackCode") {
          setFontBold();
          pdf.text("Sub Total: ", fromLeft, fromTop, col.alignment);
        }

        if (col.dataKey == "bar_NoCartons") {
          pdf.text(selectedAdvance.bar_NoCartons.toString(), fromLeft + 0.25, fromTop, col.alignment);
        }

        if (col.dataKey == "advance") {
          const currencyText = Currency[selectedAdvance.paid_currency].toString();
          pdf.text(currencyText, fromLeft, fromTop, col.alignment);
        }

        if (col.dataKey == "total") {
          pdf.text(formatNumberDecimals(selectedAdvance.sum_paidcurrencyValue), fromLeft, fromTop, col.alignment);
        }

        fromLeft += col.width;
      }

      pdf.setFillColor(grey);
      pdf.rect(fullWidth / 2, fromTop + 0.35, 8.5, 0.01, "DF");
    }
  }

  lastLinewritten = fromTop + 2;
};

const createFinalTotal = (advance: ProducersFullGroupedType, adhocPayments: AdhocDetail[]) => {
  fromTop += 1.5;

  const heightOfPage = pdf.internal.pageSize.getHeight();
  if (fromTop > heightOfPage - 2) {
    newPage();
    fromTop = 2;
  }

  pdf.setLineWidth(0.01);
  pdf.setFontSize(10);

  if (adhocPayments.length == 0) {
    (adhocPayments as any).push({ produceradvancepaidhead_makeanote: "N/A", sum_paidcurrencyValue: 0 });
  }

  adhocPayments.forEach((adhoc) => {
    setFontBold();
    const text = "AD-HOC DEDUCTION / PAYMENT: ";
    const textWidth = pdf.getTextDimensions(text).w;

    pdf.text(text, 1, fromTop, "left");
    pdf.rect(1, fromTop + 0.15, textWidth, 0.01, "S");

    setFontNormal();

    pdf.text(adhoc.note || "", fullWidth - 5.5, fromTop, "right");
    pdf.text(Currency[advance.dispatch_currencycode], fullWidth - 4.75, fromTop, "right");
    pdf.text(formatNumberDecimals(adhoc.amount), fullWidth - 2.2, fromTop, "right");

    pdf.rect(fullWidth / 2, fromTop + 0.3, 8.5, 0.01, "S");

    fromTop += 1;
  });

  fromTop += 0.25;

  pdf.setFontSize(12);
  setFontBold();
  pdf.text(`TOTAL: `, fullWidth - 5.25, fromTop, "right");

  setFontNormal();
  pdf.text(Currency[advance.dispatch_currencycode], fullWidth - 4.75, fromTop, "right");
  pdf.text(formatNumberDecimals(advance.sum_paidcurrencyValueTotal), fullWidth - 2.2, fromTop, "right");

  pdf.rect(fullWidth / 2, fromTop + 0.3, 8.5, 0.01, "S");
  pdf.rect(fullWidth / 2, fromTop + 0.4, 8.5, 0.01, "S");
};

export const producerAdvanceRemittance = ({
  companyDetails,
  header,
  detail,
  selectedAdvance,
  adhocPayments,
}: {
  companyDetails: CompanyDetailsType;
  header: ProducerType;
  detail: ProducersFullType[];
  selectedAdvance: ProducersFullGroupedType;
  adhocPayments: AdhocDetail[];
}): void => {
  const additional_details: AdditionalDetails = {
    makeanote: selectedAdvance.produceradvancepaidhead_makeanote,
    amounts: formatNumberDecimals(selectedAdvance.sum_paidcurrencyValueTotal),
    idents: selectedAdvance.produceradvancepaidhead_ident.toString(),
    currency: Currency[selectedAdvance.paid_currency].toString(),
    payment_date: new Date(selectedAdvance.produceradvancepaidhead_posteddate),
  };

  InitialisePDF();
  createHeader(companyDetails);
  createProducerDetails(header, additional_details);
  createAdvanceDetail(selectedAdvance);
  createDetail(detail, selectedAdvance);
  createFinalTotal(selectedAdvance, adhocPayments);

  let filename = `${selectedAdvance.producer_name}.pdf`;
  try {
    filename = `${format(new Date(selectedAdvance.produceradvancepaidhead_posteddate), "yyMMdd")}_${selectedAdvance.producer_name}_${
      selectedAdvance.produceradvancepaidhead_ident.toString() || ""
    }_${(selectedAdvance.produceradvancepaidhead_makeanote || "").trim().replaceAll(" ", "_")}.pdf`;
  } catch (error) {
    throw new Error(error);
  }

  pdf.save(filename);
};
