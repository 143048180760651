import React from "react";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";

import CircularProgress from "@material-ui/core/CircularProgress";

import Grid from "../lib/components/grid";
import { GridColumnsEdiDetail } from "./dispatchtableformcompareedidetail";
import { FindByDispatchIDBarcodeCompareType } from "../lib/api/edistockpalettesdispatched";
import { loadout } from "../lib/api/loadout";
import { loadoutDetailFull } from "../lib/api/loadoutdetail";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      height: "100%",
    },
    gridContainer: {
      display: "grid",
      gridTemplateColumns: "1fr 1fr",
      gridColumnGap: "20px",
    },
    gridHeaderWrapper: {
      position: "absolute",
      left: 0,
      height: "60px",
    },
    gridHeaderText: {
      marginLeft: "10px",
    },
  });

type DispatchAcceptDialogEdiDetailsProps = {
  selectedDispatch: any;
  dispatchPallets: FindByDispatchIDBarcodeCompareType[];
  dispatchPalletsLoadouts: { loadout_id: number; loadout_reference: string }[];
  handleForceReload: any;
  handleIssuesCount: any;
} & WithStyles<typeof styles>;

class DispatchAcceptDialogEdiDetailsUnstyled extends React.Component<DispatchAcceptDialogEdiDetailsProps, {}> {
  state = {
    vsData: [],
    vsDataMissingFromLoadout: [],
    loading: false,
    selectedRow: undefined,
    dispatchPallets: [],
    dispatchPalletsLoadouts: [],
    handleForceReload: undefined,
    handleIssuesCount: undefined,
    errorsDetail: [],
    selectedFixRow: undefined,
    selectedLoadout: undefined,
    stock: [],
  };

  constructor(props) {
    super(props);
    this.state.dispatchPallets = props.dispatchPallets;
    this.state.dispatchPalletsLoadouts = props.dispatchPalletsLoadouts;
    this.state.handleForceReload = props.handleForceReload;
    this.state.handleIssuesCount = props.handleIssuesCount;
  }

  componentDidMount() {
    this.setState({ loading: true });
    this.load().then(() => {
      this.setState({ loading: false });
    });
  }

  load = async () => {
    const loadout_id = this.props.selectedDispatch["loadout_id"];
    const lddata = await loadout.single(loadout_id);

    if (lddata) {
      const stockData = await loadoutDetailFull(lddata.id);
      const sortedStock = stockData.data
        .map((item) => ({
          status: 0,
          Type: "Stock",
          Barcode: item.barcode,
          NoCartons: item.noCartons,
          PalletSize: item.palletSize,
          CommodityCode: item.commodityCode,
          VarietyCode: item.varietyCode,
          PackCode: item.packCode,
          MarkCode: item.markCode,
          GradeCode: item.gradeCode,
          CountCode: item.countCode,
          InventoryCode: item.inventoryCode,
          PickingRef: item.PickingRef,
          loadoutId: item.loadout_id,
          Waybill: item.Waybill,
        }))
        .sort((a, b) => a.Barcode.localeCompare(b.Barcode));
      this.setState({ stock: sortedStock });
    } else {
      this.setState({ stock: [] });
    }

    const validationCols = [
      "Barcode",
      "CommodityCode",
      "VarietyCode",
      "PackCode",
      "MarkCode",
      "GradeCode",
      "CountCode",
      "InventoryCode",
      "sequenceNumber",
      "PalletSize",
      "NoCartons",
    ];

    const validatedLoadoutData = this.props.dispatchPallets.reduce((arr, row) => {
      const errors = validationCols.filter((col) => {
        let edi = row[`edi_${col}`];
        let bar = row[`bar_${col}`];

        if (isNaN(edi)) {
          edi = edi.trim();
        } else {
          edi = parseFloat(edi);
        }

        if (isNaN(bar)) {
          bar.trim();
        } else {
          bar = parseFloat(bar);
        }

        return edi != bar;
      });

      if (!row.loadout_id) {
        errors.push("Loadout Missing");
      }

      arr.push({
        ...row,
        errors,
        status: 0,
        Type: "EDI",
        Barcode: row.edi_Barcode,
        NoCartons: row.edi_NoCartons,
        PalletSize: row.edi_PalletSize,
        CommodityCode: row.edi_CommodityCode,
        VarietyCode: row.edi_VarietyCode,
        PackCode: row.edi_PackCode,
        MarkCode: row.edi_MarkCode,
        GradeCode: row.edi_GradeCode,
        CountCode: row.edi_CountCode,
        InventoryCode: row.edi_InventoryCode,
        barCommodityCode: row.bar_CommodityCode,
        barVarietyCode: row.bar_VarietyCode,
        barPackCode: row.bar_PackCode,
        barMarkCode: row.bar_MarkCode,
        barGradeCode: row.bar_GradeCode,
        barCountCode: row.bar_CountCode,
        barInventoryCode: row.bar_InventoryCode,
        barNoCartons: row.bar_NoCartons,
        barPalletSize: row.bar_PalletSize,
        detail: "",
        dispatchid: row.edi_dispatchid,
        barcode_id: row.bar_barcode_id,
        PickingRef: row.edi_PickingRef,
        barPickingRef: row.bar_PickingRef,
        barWaybill: row.bar_Waybill,
        Waybill: row.edi_Waybill,
      });
      return arr;
    }, []);

    const errorsCount = validatedLoadoutData.filter((r) => (r.errors || []).length > 0).length;

    this.state.handleIssuesCount(errorsCount);
    this.setState({ vsData: validatedLoadoutData.sort((a, b) => a.Barcode.localeCompare(b.Barcode)), selectedLoadout: lddata });
  };

  handleRefresh = () => {
    this.load();
  };

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        {this.state.loading ? (
          <CircularProgress />
        ) : (
          <div className={classes.gridContainer}>
            <div style={{ position: "relative" }}>
              <div className={classes.gridHeaderWrapper}>
                <h3 className={classes.gridHeaderText}>EDI</h3>
              </div>
              <Grid
                showFilterChips={false}
                loading={this.state.loading}
                data={this.state.vsData}
                GridColumns={(data, filters, columnArrangement, columnsWidth) =>
                  GridColumnsEdiDetail(data, filters, columnArrangement, columnsWidth, "dispatchsetupcompareedidetail")
                }
                handleRefresh={this.handleRefresh}
                clearFilters={"dispatchsetupcompareedidetail"}
                forceHeight={700}
                totalRowColumns={["NoCartons", "PalletSize"]}
              />
            </div>
            <div style={{ position: "relative" }}>
              <div className={classes.gridHeaderWrapper}>
                <h3 className={classes.gridHeaderText}>Loadout</h3>
              </div>
              <Grid
                showFilterChips={false}
                loading={this.state.loading}
                data={this.state.stock}
                GridColumns={(data, filters, columnArrangement, columnsWidth) =>
                  GridColumnsEdiDetail(data, filters, columnArrangement, columnsWidth, "dispatchsetupcompareedidetailselected")
                }
                clearFilters={"dispatchsetupcompareedidetailselected"}
                forceHeight={700}
                totalRowColumns={["NoCartons", "PalletSize"]}
              />
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default withStyles(styles)(DispatchAcceptDialogEdiDetailsUnstyled);
