import React from "react";
// import { withStyles, createStyles, WithStyles, Theme } from '@material-ui/core/styles';
import { Filters } from "react-data-grid-addons";

import IconDelete from "@material-ui/icons/Delete";
import IconEdit from "@material-ui/icons/Edit";
import { CellActionsFormatter } from "../../lib/helpers/CellActionsFormatter";

const cellWidthXSmall = 80;
const cellWidthSmall = 115;
const cellWidthMedium = 150;
const cellWidthLarge = 220;

const defaultColumnProperties = {
  // filterable: true,
  resizable: true,
  // sortable: true,
};

export const GridColumns = (maintenancemode, handleRemove, handleEdit) => {
  const columnsArr = [];

  if (maintenancemode) {
    columnsArr.push({
      key: "actions",
      name: "Actions",
      width: cellWidthXSmall,
      formatter: ({ row }) => {
        const actions = cellActions(row, handleRemove, handleEdit);
        return (
          <div style={{ display: "flex", justifyContent: "center", gap: "5px" }}>
            <CellActionsFormatter actions={actions} />
          </div>
        );
      },
    });
  }
  columnsArr.push({ key: "templatename", name: "Template", width: cellWidthLarge });
  columnsArr.push({ key: "variety_name", name: "Variety", width: cellWidthMedium });
  columnsArr.map((c) => ({ ...c, ...defaultColumnProperties }));

  return columnsArr;
};

const cellActions = (template, handleRemove, handleEdit) => {
  let actions = [
    {
      icon: (
        <span title="Delete Template">
          <IconDelete style={{ cursor: "pointer", color: "red" }} />
        </span>
      ),
      callback: (e) => {
        handleRemove(template.id);
      },
    },
    {
      icon: (
        <span title="Edit Template">
          <IconEdit style={{ cursor: "pointer", color: "orange" }} />
        </span>
      ),
      callback: (e) => {
        handleEdit(template);
      },
    },
  ];
  return actions;
};
