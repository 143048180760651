import { client } from "./_base";

export type FileImportTemplateType = {
  id?: number;
  templatename: string;
  templatespecialcharacter: string;
  templatehasheader: number;
  created_at?: Date;
  updated_at?: Date;
};
export const FileImportTemplate = client("fileimporttemplate", (item) => ({
  ...item,
}));
