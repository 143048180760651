import React, { useState, FC, useEffect } from "react";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";

import EstimatedCostsSetupHead from "./estimatedcostssetuphead";
import EstimatedCostsSetupDetail from "./estimatedcostssetupdetail";

import { EstimatedCostsData, execRefreshConsolidationCache } from "../../../lib/api/estimatedcost";
import { updateEstimatedCostSetup } from "../../../lib/api/estimatedcostsetup";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1),
    },
  });

type EstimatedCostsSetupProps = {
  currentData: EstimatedCostsData;
  data: any[];
  isDuplicate: boolean;
  setIsDuplicate(value: boolean): void;
  submitHeadData(values: any): Promise<number>;
} & WithStyles<typeof styles>;

const getSortedList = (str: string | string[]) => {
  if (!str) return [];
  if (typeof str == "object") {
    return str.map((s) => +s);
  }
  return str.split(",").map((s) => +s);
};

export const duplicateCheck = (row: any, data: any[], packSpecId: number) =>
  data.find(
    (item) =>
      item.id != row.id &&
      item.season == row.season &&
      item.commodity == row.commodity &&
      item.exchange_zar == row.exchange_zar &&
      item.exchange_cad == row.exchange_cad &&
      item.exchange_eur == row.exchange_eur &&
      item.exchange_gbp == row.exchange_gbp &&
      item.exchange_usd == row.exchange_usd &&
      item.container == row.container &&
      item.conventional == row.conventional &&
      item.pack_id == packSpecId &&
      getSortedList(item.port_id).find((port) => getSortedList(row.port_id).includes(port)) &&
      getSortedList(item.countries).find((country_id) => getSortedList(row.countries).includes(country_id) || getSortedList(row.countries).includes(-1)),
  );

const EstimatedCostsSetupUnstyled: FC<EstimatedCostsSetupProps> = (props) => {
  const { classes, currentData, data, isDuplicate, setIsDuplicate, submitHeadData } = props;

  const [estimatedcostid, setEstimatedcostid] = useState(currentData.original_id);
  const [packspec_id, setPackspec_id] = useState(currentData.pack_id);

  useEffect(() => {
    setEstimatedcostid(currentData.original_id);
    setPackspec_id(currentData.pack_id);
  }, [currentData.original_id, currentData.pack_id]);

  const submitSetupDetail = async (packSpecId: number, detail: any[]) => {
    const duplicate = duplicateCheck(currentData, data, packSpecId);

    if (!duplicate) {
      const id = await submitHeadData(currentData);

      const newDetail = detail.map((item) => ({
        estimatedcost_id: id,
        estimatedcostitem_id: item.estimatedcostitem_id,
        pack_id: packSpecId,
        value: item.value,
        variety: item.variety == "ALL" ? null : item.variety,
      }));

      await updateEstimatedCostSetup(newDetail, id);
      execRefreshConsolidationCache();
      setPackspec_id(packSpecId);
      setEstimatedcostid(id);
    }
  };

  return (
    <div className={classes.root}>
      <EstimatedCostsSetupHead
        packspec_id={packspec_id}
        currentHead={currentData}
        allData={data}
        setIsDuplicate={setIsDuplicate}
        isDuplicate={isDuplicate}
        submitHeadData={submitHeadData}
      />
      {estimatedcostid && estimatedcostid != 0 && estimatedcostid != -1 && (
        <EstimatedCostsSetupDetail estimatedcost_id={estimatedcostid} packspec_id={packspec_id} submitSetupDetail={submitSetupDetail} />
      )}
    </div>
  );
};

export default withStyles(styles)(EstimatedCostsSetupUnstyled);
