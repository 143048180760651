import jsPDF from "jspdf";

import moment from "moment";
import numeral from "numeral";
import autoTable from "jspdf-autotable";

import { image as logoImage } from "./elements/logo";
import { PTSans } from "./elements/font";
import { get } from "./helpers";
import { DealType } from "../lib/api/dealtype";
import { CompanyDetailsBankingType } from "../lib/api/companydetailsbanking";
import { CompanyDetailsType } from "../lib/api/companydetails";
import { SaleDispatchType, SaleHeaderForCombinedInvoicesType } from "../lib/api/sale";
import { SalePaymentsType } from "./printing";

let pdf: jsPDF,
  fullWidth = 0,
  fullHeight = 0,
  page = 1,
  pages = [],
  fromTop = 0,
  fromLeft = 0,
  lastLinewritten = 0;

const initialisePDF = () => {
  pdf = new jsPDF({
    orientation: "portrait",
    unit: "cm",
  });
  fullWidth = pdf.internal.pageSize.getWidth();
  fullHeight = pdf.internal.pageSize.getHeight();
  page = 1;
  pages = [page];
  fromTop = 0;
  fromLeft = 1;
};

let startSummaryLine = { startPos: 0, endPos: 0 };
let startBankingLine = { startPos: 0, endPos: 0 };

const columnHeaders = [
  { dataKey: "barcode_commodityname", header: "Commodity", width: 2, alignment: "left" },
  { dataKey: "barcode_varietyname", header: "Variety", width: 3.5, alignment: "left" },
  { dataKey: "barcode_gradecode", header: "Grade", width: 1.2, alignment: "center" },
  { dataKey: "barcode_countcode", header: "Count", width: 1.2, alignment: "center" },
  { dataKey: "barcode_markcode", header: "Mark", width: 1.2, alignment: "center" },
  { dataKey: "barcode_packcode", header: "Pack", width: 1.2, alignment: "center" },
  { dataKey: "barcode_nocartons", header: "Crtns", width: 1.2, alignment: "center" },
  { dataKey: "barcode_palletsize", header: "Plts", width: 1.2, alignment: "center" },
  { dataKey: "currency", header: "Curr", width: 1.2, alignment: "center" },
  { dataKey: "stockdetail_sellingprice", header: "Unit Price", width: 2, alignment: "right" },
  { dataKey: "total", header: "Subtotal", width: 2.5, alignment: "right" },
];

type ContainerInfo = { containerNo: string[]; sealNumber: string[] };

type CombinedInvoiceDataSetType = {
  companyDetails: {
    bank: CompanyDetailsBankingType;
    company: CompanyDetailsType;
  };
  details: SaleDispatchType[];
  header: SaleHeaderForCombinedInvoicesType;
  containerInfo: ContainerInfo;
  reportType: string;
  invoiceNumbersSelected: string[];
};

export const printCombinedInvoice = async (
  { details, companyDetails, header, containerInfo, reportType, invoiceNumbersSelected }: CombinedInvoiceDataSetType,
  payments: SalePaymentsType[],
): Promise<void> => {
  initialisePDF();

  pdf.addFileToVFS("PTSans.ttf", PTSans);
  pdf.addFont("PTSans.ttf", "PTSans", "normal");
  pdf.setFont("PTSans");
  pdf.setLineWidth(0.01);

  printHeader(companyDetails.company, header, containerInfo, reportType, invoiceNumbersSelected);
  printDetail(details);
  printRemarks(header);
  printInvoicingSummary(payments, header, details);
  printBankDetails(companyDetails.bank);
  drawSummaryBox();
  printTermsConditions();
  printFooter();

  let filename = "COMMERICIAL_INVOICE.pdf";

  try {
    const { invoicenumber, forwardagentref } = header;
    filename =
      invoiceNumbersSelected.length > 0
        ? `${invoicenumber}_${invoiceNumbersSelected.join("_")}_${forwardagentref ? `${forwardagentref}_` : ""}${"COMMERICIAL_INVOICE"}.pdf`
        : `${invoicenumber}_${forwardagentref ? `${forwardagentref}_` : ""}${"COMMERICIAL_INVOICE"}.pdf`;
  } catch (error) {
    throw error;
  }

  pdf.save(filename);
};

const addressLineArray = (address: string, charLength = 33, arr = []) => {
  const lineLength = charLength;

  if (!address || address == "") {
    return [];
  }

  let words = (address || "").split(" ");

  if (address.length > lineLength) {
    let line = "";
    let rem = address.split(" ");

    for (let i = 0; i < words.length; i++) {
      const currLine = line + " " + words[i];

      if (currLine.length >= lineLength) {
        break;
      } else {
        rem.splice(0, 1);
        line = currLine.trim();
      }
    }

    arr.push(line);

    if (rem.length > 0) {
      return addressLineArray(rem.join(" "), lineLength, arr);
    } else {
      return arr;
    }
  } else {
    return [...arr, words.join(" ")];
  }
};

const newPage = () => {
  pdf.addPage();

  page++;
  pages.push(page);
  pdf.setPage(page);
};

const addImage = (imageData: string, format: string, fromLeft: number, fromTop: number, width: number, height: number, alias?, compression?, rotation?) => {
  pdf.addImage(imageData, format, fromLeft, fromTop, width, height, alias, compression, rotation);
};

const newLine = (howFar: number = 0.5) => {
  fromTop = fromTop + howFar;
};

const setFontBold = () => {
  pdf.setFontStyle("bold");
  pdf.setFont("helvetica"); // set font
};

const setFontNormal = () => {
  pdf.setFontStyle("normal");
  pdf.setFont("PTSans"); // set font
};

const headerColumn1 = () => {
  return 3.4;
};
const headerColumn2 = () => {
  return fullWidth / 2 - 1.5;
};
const headerColumn3 = () => {
  return fullWidth - 3.6;
};

const printLine = (left: number, top: number, label: string, value: string, boldValue = false) => {
  setFontBold();
  pdf.text(label, left, top, "right");
  if (!boldValue) {
    setFontNormal();
  }
  pdf.text(value, left + 0.2, top, "left");
};

const printHeader = (
  companyDetails: CompanyDetailsType,
  header: SaleHeaderForCombinedInvoicesType,
  containerInfo: ContainerInfo,
  reportType: string,
  invoiceNumbersSelected: string[],
) => {
  fromTop = 1;
  fromLeft = 1;

  const isConsigneeReport = reportType.includes("CONSIGNEE") && !reportType.includes("CLIENT");
  const isClientAndConsigneeReport = reportType.includes("CLIENT_AND_CONSIGNEE");

  const clientAddress = isConsigneeReport ? get("consignee_address", header, "").replace(/[\r\n]/g, " ") : get("clientaddress", header, "").replace(/[\r\n]/g, " ");
  const clientAddressArr = addressLineArray(clientAddress);
  const issuedTo = isConsigneeReport ? get("consignee_name", header, "") : get("clientname", header, "");

  const shippedToAddress = get("consignee_address", header, "").replace(/[\r\n]/g, " ");
  const shippedToAddressArr = addressLineArray(shippedToAddress);

  const imageWidth = 5;
  const imageHeight = 1.5;

  addImage(logoImage, "PNG", (fullWidth - imageWidth) / 2 - 1, fromTop, imageWidth, imageHeight);
  pdf.setFontSize(11);
  setFontBold();
  pdf.text(get("name", companyDetails, ""), fromLeft, fromTop, "left");

  pdf.setFontSize(12);
  setFontBold();
  pdf.text("COMMERCIAL INVOICE", fullWidth / 2 - 1, fromTop + 2.5, "center");

  setFontNormal();
  pdf.setFontSize(8);

  newLine(0.4);
  pdf.text(get("address1", companyDetails, ""), fromLeft, fromTop, "left");

  newLine(0.4);
  pdf.text(get("address2", companyDetails, ""), fromLeft, fromTop, "left");

  newLine(0.4);
  pdf.text(get("address3", companyDetails, ""), fromLeft, fromTop, "left");

  newLine(0.4);
  pdf.text(get("address4", companyDetails, ""), fromLeft, fromTop, "left");

  newLine(0.4);
  pdf.text(get("address5", companyDetails, ""), fromLeft, fromTop, "left");

  fromLeft = headerColumn3() - 1;

  fromTop = 1;

  pdf.text("Tel No:", fromLeft, fromTop, "right");
  pdf.text(get("telephoneNumber", companyDetails, ""), fromLeft + 0.2, fromTop, "left");

  newLine(0.4);
  pdf.text("Fax No:", fromLeft, fromTop, "right");
  pdf.text(get("faxNumber", companyDetails, ""), fromLeft + 0.2, fromTop, "left");

  newLine(0.4);
  pdf.text("E-mail:", fromLeft, fromTop, "right");

  // Fix overlapping with long email text
  pdf.text(get("email", companyDetails, ""), fromLeft + 0.2, fromTop, "left");

  newLine(0.4);
  pdf.text("Vat No:", fromLeft, fromTop, "right");
  pdf.text(get("vatno", companyDetails, ""), fromLeft + 0.2, fromTop, "left");

  newLine(0.4);
  pdf.text("Reg No:", fromLeft, fromTop, "right");
  pdf.text(get("regno", companyDetails, ""), fromLeft + 0.2, fromTop, "left");

  newLine(0.4);
  pdf.text("Customs Code:", fromLeft, fromTop, "right");
  pdf.text(get("customscode", companyDetails, ""), fromLeft + 0.2, fromTop, "left");

  newLine(0.4);
  pdf.text("GGN / GLN No:", fromLeft, fromTop, "right");
  pdf.text(get("ggn_gln_no", companyDetails, ""), fromLeft + 0.2, fromTop, "left");

  const startRect = fromTop + 0.5;
  newLine(0.7);
  pdf.setFontSize(8);

  const shippedTo = get("consignee_name", header, "");

  //1
  newLine();
  printLine(headerColumn1(), fromTop, "Date:", moment(get("sale_date", header, "")).format("DD/MM/YYYY"));
  printLine(headerColumn3() - 1, fromTop, "Agent Ref:", get("forwardagentref", header, ""));

  //2
  newLine(0.4);
  printLine(
    headerColumn1(),
    fromTop,
    "Invoice No:",
    invoiceNumbersSelected.length > 0 ? `${get("invoicenumber", header, "")}_${invoiceNumbersSelected.join("_")}` : get("invoicenumber", header, ""),
  );
  printLine(headerColumn3() - 1, fromTop, "Vessel:", get("vessel_description", header, ""));

  //3
  newLine(0.4);
  printLine(headerColumn1(), fromTop, "Client Order No:", get("orderno", header, ""));
  printLine(headerColumn3() - 1, fromTop, "Voyage Number", get("shippingRef", header, ""));

  let issuedToAddressLineTop = fromTop + 0.8;
  let shippedToAddressLineTop = fromTop + 0.8;
  let containerInfoLineTop = fromTop + 0.8;

  const maxNameLength = isClientAndConsigneeReport ? 20 : 80;

  const issuedToArr = addressLineArray(issuedTo, 20).map((item, i) => ({ bold: true, label: i > 0 ? "" : "Issued To:", value: item }));
  const shippedToArr = addressLineArray(shippedTo, maxNameLength).map((item, i) => ({ bold: true, label: i > 0 ? "" : "Shipped To:", value: item }));
  const issueToAddress = (clientAddressArr || []).map((str) => ({ bold: false, label: "", value: str }));

  const middleContentAddress = (shippedToAddressArr || []).map((str) => ({ bold: false, label: "", value: str }));

  /* ISSUED TO */
  const issuedToExtraCnt = isClientAndConsigneeReport ? addressLineArray(shippedTo, maxNameLength).length - addressLineArray(issuedTo, maxNameLength).length : 0;
  const issuedToExtraArr = issuedToExtraCnt > 0 ? new Array(issuedToExtraCnt).fill(1).map(() => ({ bold: false, label: "", value: "" })) : [];

  const issuedToExtraAddCnt = isClientAndConsigneeReport ? middleContentAddress.length - issueToAddress.length : 0;
  const issuedToExtraAddArr = issuedToExtraAddCnt > 0 ? new Array(issuedToExtraAddCnt).fill(1).map(() => ({ bold: false, label: "", value: "" })) : [];

  issuedToArr.push(
    ...issuedToExtraArr,
    ...issueToAddress,
    ...issuedToExtraAddArr,
    { bold: false, label: "Tel No:", value: get("clientstelephone", header, "") },
    { bold: false, label: "Email:", value: get("clientsemail", header, "") },
  );

  for (let i = 0; i < issuedToArr.length; i++) {
    const row = issuedToArr[i];
    printLine(headerColumn1() - 0.05, issuedToAddressLineTop, row.label, row.value, row.bold);
    issuedToAddressLineTop += 0.4;
  }

  /* SHIPPED TO */
  if (isClientAndConsigneeReport) {
    const shippedToExtraCnt = addressLineArray(issuedTo, maxNameLength).length - addressLineArray(shippedTo, maxNameLength).length;
    const shippedToExtraArr = shippedToExtraCnt > 0 ? new Array(shippedToExtraCnt).fill(1).map(() => ({ bold: false, label: "", value: "" })) : [];

    const shippedToExtraAddCnt = issueToAddress.length - middleContentAddress.length;
    const shippedToExtraAddArr = shippedToExtraAddCnt > 0 ? new Array(shippedToExtraAddCnt).fill(1).map(() => ({ bold: false, label: "", value: "" })) : [];

    shippedToArr.push(
      ...shippedToExtraArr,
      ...middleContentAddress,
      ...shippedToExtraAddArr,
      { bold: false, label: "Tel No:", value: get("consignee_telephone", header, "") },
      { bold: false, label: "Email:", value: get("consignee_email", header, "") },
    );

    for (let i = 0; i < shippedToArr.length; i++) {
      const row = shippedToArr[i];
      printLine(headerColumn2() - 0.05, shippedToAddressLineTop, row.label, row.value, row.bold);
      shippedToAddressLineTop += 0.4;
    }
  }

  newLine(0.4);
  printLine(headerColumn3() - 1, fromTop, "POD:", get("portdischarge", header, ""));

  newLine(0.4);
  printLine(headerColumn3() - 1, fromTop, "Final Destination:", get("portfinal", header, ""));

  newLine(0.4);
  printLine(headerColumn3() - 1, fromTop, "ETD:", moment(get("etd", header, "")).format("DD/MM/YYYY"));

  newLine(0.4);
  printLine(headerColumn3() - 1, fromTop, "ETA:", moment(get("eta", header, "")).format("DD/MM/YYYY"));

  newLine(0.4);
  printLine(headerColumn3() - 1, fromTop, "Container No:", "Seal Number:", true);

  for (let i = 0; i < containerInfo.containerNo.length; i++) {
    newLine(0.4);
    setFontNormal();
    pdf.text(containerInfo.containerNo[i], headerColumn3() - 1, fromTop, "right");
    pdf.text(containerInfo.sealNumber[i], headerColumn3() - 1 + 0.2, fromTop, "left");
    containerInfoLineTop += 0.4;
  }

  fromLeft = 1;
  fromTop =
    issuedToAddressLineTop > containerInfoLineTop
      ? issuedToAddressLineTop > fromTop
        ? issuedToAddressLineTop - 0.2
        : fromTop
      : containerInfoLineTop > fromTop
      ? containerInfoLineTop - 0.2
      : fromTop;

  pdf.setLineWidth(0.01);
  pdf.rect(fromLeft, startRect + 0.15, fullWidth - 2, fromTop - startRect, "S");

  fromTop += 0.5;
};

const printDetail = (data: SaleDispatchType[]) => {
  fromLeft = 1;
  const startRect = fromTop + 0.25;

  const formattedData = data.map((item) => ({
    ...item,
    barcode_palletsize: numeral(item.barcode_palletsize).format("0,0.00"),
    barcode_nocartons: numeral(item.barcode_nocartons).format("0,0"),
    stockdetail_sellingprice: numeral(item.stockdetail_sellingprice).format("0,0.00"),
    total: numeral(item.total).format("0,0.00"),
  }));

  const { totalPallets, totalCartons, total } = data.reduce(
    (obj, item) => {
      obj["totalPallets"] = obj["totalPallets"] + parseFloat(item.barcode_palletsize.toString());
      obj["totalCartons"] = obj["totalCartons"] + parseFloat(item.barcode_nocartons.toString());
      obj["total"] = obj["total"] + parseFloat(item.total.toString());
      return obj;
    },
    {
      totalPallets: 0,
      totalCartons: 0,
      total: 0,
    },
  );

  autoTable(pdf, {
    theme: "plain",
    margin: [1, 1, 1, 1],
    tableLineColor: [0, 0, 0],
    tableLineWidth: 0.01,
    startY: startRect,
    columns: columnHeaders,
    body: formattedData,
    showFoot: "lastPage",
    styles: { fontSize: 8, fillColor: [255, 255, 255], textColor: [0, 0, 0], cellPadding: [0.15, 0.025, 0.15, 0.025] },
    columnStyles: {
      barcode_commodityname: { halign: "center", cellWidth: "auto" },
      barcode_varietyname: { halign: "center", cellWidth: "auto", overflow: "ellipsize" },
      barcode_nocartons: { halign: "center", cellWidth: "auto" },
      barcode_palletsize: { halign: "center", cellWidth: "auto" },
      barcode_gradecode: { halign: "center", cellWidth: "auto" },
      barcode_countcode: { halign: "center", cellWidth: "auto" },
      barcode_markcode: { halign: "center", cellWidth: "auto" },
      barcode_packcode: { halign: "center", cellWidth: "auto" },
      currency: { halign: "center", cellWidth: "auto" },
      stockdetail_sellingprice: { halign: "center", cellWidth: "auto" },
      total: { halign: "center", cellWidth: "auto" },
    },
    headStyles: { fontStyle: "bold", cellPadding: [0.15, 0.025, 0.15, 0.025], halign: "center" },
    bodyStyles: { fontSize: 8 },
    alternateRowStyles: { fillColor: [255, 255, 255] },
    footStyles: { textColor: [0, 0, 0], fontStyle: "bold", halign: "center", cellPadding: [0.15, 0.025, 0.15, 0.025] },
    foot: [
      {
        barcode_commodityname: "TOTAL",
        barcode_varietyname: "",
        barcode_gradecode: "",
        barcode_countcode: "",
        barcode_markcode: "",
        barcode_packcode: "",
        stockdetail_sellingprice: "",
        barcode_nocartons: numeral(totalCartons).format("0,0"),
        barcode_palletsize: numeral(totalPallets).format("0,0.00"),
        total: numeral(total).format("0,0.00"),
      },
    ],
    didDrawPage: (data) => {
      lastLinewritten = data.cursor.y;
      fromTop = data.cursor.y;
    },
    willDrawCell: (data) => {
      data.row.height = 0.5;
    },
  });

  pdf.setFillColor("#000");
  pdf.setLineWidth(0.001);
  pdf.rect(1, lastLinewritten - 0.45, fullWidth - 2, 0.001, "S");
};

const printRemarks = (headerData: SaleHeaderForCombinedInvoicesType) => {
  const dispatch_remarks = headerData.dispatch_remarks ? headerData.dispatch_remarks.split("\n").join(" ") : "N/A";
  fromLeft = 3;
  newLine(0.75);
  pdf.setFontSize(9);

  setFontBold();
  pdf.text("REMARKS: ", fromLeft, fromTop + 0.5, "right");

  setFontNormal();
  dispatch_remarks.split("\n").map((remark) => {
    if (remark.toString().length < 140) {
      const text = remark.toString();
      pdf.text(fromLeft + 0.2, fromTop + 0.5, text, null, null, "left");
      fromTop = fromTop + 0.44;
    } else {
      let wrapped = pdf.splitTextToSize(remark.toString(), 28 - 12);
      wrapped.map((text) => {
        pdf.text(fromLeft + 0.2, fromTop + 0.5, text, null, null, "left");
        fromTop = fromTop + 0.44;
      });
    }
  });

  pdf.setFontSize(8);
  lastLinewritten = fromTop + 1;
};

const printInvoicingSummary = (payments: SalePaymentsType[], headerDetail: SaleHeaderForCombinedInvoicesType, details: SaleDispatchType[]) => {
  if (lastLinewritten >= fullHeight - 4) {
    newPage();
    fromTop = 1;
  } else {
    fromTop = lastLinewritten;
  }

  startSummaryLine = { startPos: fromTop, endPos: 0 };

  const paymentTermsPrintPoint = 1.35;
  const incoTermPrintPoint = 6;

  const dueDatePrintPoint = 13;
  const amountPrintPoint = 19.8;
  const currencyPrintPoint = amountPrintPoint - 1 - pdf.getTextDimensions("AMOUNT").w;

  lastLinewritten = fromTop;

  if (lastLinewritten >= 24) {
    newPage();
    pdf.setLineWidth(0.01);
    fromTop = 2;
  } else {
    fromTop += 0.8;
  }

  const totalAmount = details.reduce((a, b) => (a += b.total), 0);

  setFontBold();

  pdf.text("PAYMENT TERMS:", paymentTermsPrintPoint, fromTop, "left");
  pdf.rect(paymentTermsPrintPoint, fromTop + 0.1, pdf.getTextDimensions("PAYMENT TERMS: ").w, 0.01, "S");
  pdf.text("", 0.5 + pdf.getTextDimensions("PAYMENT TERMS: ").w, fromTop, "left");

  pdf.text("SHIPPING TERM:", incoTermPrintPoint, fromTop, "left");
  pdf.rect(incoTermPrintPoint, fromTop + 0.1, pdf.getTextDimensions("SHIPPING TERM: ").w, 0.01, "S");

  pdf.text(DealType[headerDetail.client_dealtype] || "", incoTermPrintPoint + 0.3 + pdf.getTextDimensions("SHIPPING TERM: ").w, fromTop, "left");

  pdf.text("DUE DATE", dueDatePrintPoint, fromTop, "left");
  pdf.rect(dueDatePrintPoint, fromTop + 0.1, pdf.getTextDimensions("DUE DATE").w, 0.01, "S");

  pdf.text("CURRENCY", currencyPrintPoint, fromTop, "right");
  pdf.rect(currencyPrintPoint - pdf.getTextDimensions("CURRENCY").w, fromTop + 0.1, pdf.getTextDimensions("CURRENCY").w, 0.01, "S");

  pdf.text("AMOUNT", amountPrintPoint, fromTop, "right");
  pdf.rect(amountPrintPoint - pdf.getTextDimensions("AMOUNT").w, fromTop + 0.1, pdf.getTextDimensions("AMOUNT").w, 0.01, "S");

  setFontNormal();

  // PAYMENT TERM DESCRIPTION
  const firstPayment = payments[0] || { payment: "" };
  pdf.text(get("payment", firstPayment, ""), paymentTermsPrintPoint, fromTop + 0.55, "left");

  fromTop += 0.8;
  let boxLine = fromTop;

  [0, 1, 2].map((i) => {
    if (!payments || !payments[i]) return false;

    if (i % 2 == 0) {
      pdf.setFillColor(210);
      pdf.rect(1, boxLine, fullWidth - 2, 0.5, "F");
    }
    boxLine += 0.5;

    const payment = payments[i];

    setFontBold();
    pdf.text(get("paymentPeriod", payment, ""), paymentTermsPrintPoint, fromTop + 0.35, "left");
    setFontNormal();

    pdf.text(get("duedate", payment, ""), dueDatePrintPoint + pdf.getTextDimensions("DUE DATE").w / 2, fromTop + 0.35, "center");
    pdf.text(get("currency", payment, ""), currencyPrintPoint, fromTop + 0.35, "right");

    const amount = numeral(totalAmount / (payments.length || 1))
      .format("0,0.00")
      .toString();
    pdf.text(amount, amountPrintPoint, fromTop + 0.35, "right");

    fromTop += 0.5;
  });

  fromTop += 0.5;

  pdf.setLineWidth(0.01);
  pdf.rect(1, fromTop + 0.15, fullWidth - 2, 0, "S");

  fromTop += 0.5;
  setFontBold();
  pdf.text("TOTAL", paymentTermsPrintPoint, fromTop, "left");

  pdf.text(numeral(totalAmount).format("0,0.00").toString(), 19.8, fromTop, "right");

  pdf.rect(1, fromTop + 0.2, fullWidth - 2, 0, "S");

  lastLinewritten = fromTop;
  startSummaryLine = { ...startSummaryLine, endPos: lastLinewritten - startSummaryLine.startPos };
};

const bankDetailNewLine = () => {
  fromLeft = 5.5;
  newLine();
};

const bankDetailPrintText = (text, position, fromLeftAdd) => {
  if (position === "right") {
    bankDetailNewLine();
    setFontBold();
    pdf.text(text, fromLeft + fromLeftAdd, fromTop, position);
  } else {
    setFontNormal();
    pdf.text(text, fromLeft + fromLeftAdd + 0.2, fromTop, position);
  }
};

const printBankDetails = (bankDetails: CompanyDetailsBankingType) => {
  fromLeft = 1;
  if (lastLinewritten >= 24) {
    newPage();
    fromTop = 2;
  } else {
    fromTop = lastLinewritten;
  }

  startBankingLine = { startPos: lastLinewritten, endPos: 0 };

  fromTop += 0.5;

  pdf.setFontSize(10);
  newLine();
  setFontBold();
  pdf.text("BANKING DETAILS ", fromLeft + 0.25, fromTop, "left");
  pdf.rect(fromLeft + 0.25, fromTop + 0.1, pdf.getTextDimensions("BANKING DETAILS").w, 0.01, "S");

  fromTop += 0.25;
  const fromTopStart = fromTop;

  pdf.setFontSize(8);

  bankDetailPrintText("ACCOUNT HOLDER: ", "right", 0);
  bankDetailPrintText(!bankDetails || !bankDetails.accountholder ? "" : bankDetails.accountholder.toString(), "left", 0);

  bankDetailPrintText("ACCOUNT HOLDER ADDRESS: ", "right", 0);
  bankDetailPrintText(!bankDetails || !bankDetails.accountholderaddress ? "" : bankDetails.accountholderaddress.toString(), "left", 0);

  bankDetailPrintText("BANK: ", "right", 0);
  bankDetailPrintText(!bankDetails || !bankDetails.bank ? "" : bankDetails.bank.toString(), "left", 0);

  bankDetailPrintText("BANK ADDRESS: ", "right", 0);
  bankDetailPrintText(!bankDetails || !bankDetails.bankaddress ? "" : bankDetails.bankaddress.toString(), "left", 0);

  fromTop = fromTopStart;
  bankDetailPrintText("BRANCH CODE: ", "right", 11);
  bankDetailPrintText(!bankDetails || !bankDetails.branchcode ? "" : bankDetails.branchcode.toString(), "left", 11);

  bankDetailPrintText("ACCOUNT TYPE: ", "right", 11);
  bankDetailPrintText(!bankDetails || !bankDetails.accounttype ? "" : bankDetails.accounttype.toString(), "left", 11);

  bankDetailPrintText("ACCOUNT NUMBER: ", "right", 11);
  bankDetailPrintText(!bankDetails || !bankDetails.accountnumber ? "" : bankDetails.accountnumber.toString(), "left", 11);

  bankDetailPrintText("SWIFT CODE: ", "right", 11);
  bankDetailPrintText(!bankDetails || !bankDetails.swiftcode ? "" : bankDetails.swiftcode.toString(), "left", 11);

  fromLeft = 1;
  lastLinewritten = fromTop + 1.25;
  startBankingLine = { ...startBankingLine, endPos: fromTop - startBankingLine.startPos };
};

const drawSummaryBox = () => {
  pdf.setLineWidth(0.01);

  if (startSummaryLine.startPos > startBankingLine.startPos) {
    pdf.rect(1, startSummaryLine.startPos, fullWidth - 2, startSummaryLine.endPos + 0.5, "S");
    pdf.rect(1, startBankingLine.startPos + 1, fullWidth - 2, startBankingLine.endPos - 0.5, "S");
  } else {
    pdf.rect(1, startSummaryLine.startPos, fullWidth - 2, startSummaryLine.endPos + startBankingLine.endPos + 0.4, "S");
  }
};

const printFooter = () => {
  const pageCount = pdf.internal.getNumberOfPages();

  for (let index = 0; index < pageCount; index++) {
    pdf.setPage(index + 1);
    pdf.text(`Page ${index + 1} of ${pageCount}`, fullWidth - 1, fullHeight - 0.5, "right");
  }
};

const printTermsConditions = () => {
  const heightOfPage = pdf.internal.pageSize.getHeight();
  fromLeft = 1.2;
  fromTop = lastLinewritten + 0.5;

  if (fromTop > heightOfPage - 3) {
    newPage();
    fromTop = 2;
  }

  // The rectangle around the text
  pdf.setLineWidth(0.01);
  pdf.rect(1, fromTop - 0.5, fullWidth - 2, 2.25, "S");

  setFontBold();
  pdf.setFontSize(9);

  pdf.text("* Any quality deviation must be reported by e-mail within 48 hours of collecting the container.", fromLeft, fromTop, "left");

  newLine();
  pdf.text("* Claims will not be entertained if notice is given after 48 hours.", fromLeft, fromTop, "left");

  newLine();
  pdf.text("* Claims will only be entertained if the Impala Citrus claims procedure is adhered to, as set out in Trade Agreement.", fromLeft, fromTop, "left");

  newLine();
  pdf.text("* Interest at a rate of 2% per month will be charged on overdue invoices.", fromLeft, fromTop, "left");
};
