import React from "react";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";
import { Redirect } from "react-router-dom";

import Button from "@material-ui/core/Button";
import Badge from "@material-ui/core/Badge";
import LinearProgress from "@material-ui/core/LinearProgress";
import Tooltip from "@material-ui/core/Tooltip";

import ReactDataGrid, { Row } from "react-data-grid";
import "react-splitter-layout/lib/index";

import { DialogInformation } from "../../../lib/components/dialoginformation";
import Confirmation from "../../../lib/components/confirmation";
import GridColumnLayout from "../../../lib/components/gridlayout";
import ProducersFilterChips from "../../../lib/components/filterchips";
import { removeFromSticky, getStickyFilters } from "../../../lib/helpers/stickyfilters";
import { generateGridFilteredRows } from "../../../lib/helpers/generateGridFilteredRows";
import { summaryRows, handleFilterChange, handleSortChange } from "../../../lib/helpers/grid";

import "../../../lib/helpers/splitter.css";
import { generateSortFn } from "../../../lib/helpers/generateSortFN";
import { updateUserProfile } from "../../../lib/helpers/stickyfilters";

import { GridColumns } from "./producerstablesetupmain";

import Refresh from "@material-ui/icons/Refresh";

import { exportProducerIntakeAndShipments, ProducersFullGroupedOnlyType } from "../../../lib/api/producersfull";
import { DocumentsButton, DocumentsType } from "../../../lib/components/DocumentsButton";

import format from "date-fns/format";
import { getFinancialYearSelected } from "../../../lib/api/week";
import { SnackContext } from "../../../lib/context/SnackContext";
import { GenerateErrorMessage } from "../../../lib/helpers/string_methods";

import LayoutIcon from "@material-ui/icons/ViewColumn";
import { ClearFilterIcon } from "../../../icons/icons";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      height: "100%",
      width: "100%",
    },
    control: {
      paddingTop: "8px",
      paddingLeft: "0px",
      paddingBottom: "10px",
      float: "right",
    },
    controlLeft: {
      paddingTop: "8px",
      paddingLeft: "0px",
      paddingBottom: "10px",
      float: "left",
    },
  });

const excludeColumns = ["shippedCartons", "shippedPallets", "consignee_name", "paid_currency", "prodAdv", "advRem", "prodFinal", "finRem", "camend_status"];

type ProducersTableProps = {
  data: {}[];
  onReload: any;
} & WithStyles<typeof styles>;

class ProducersTableUnstyled extends React.Component<ProducersTableProps, {}> {
  state = {
    data: [{}],
    filters: {},
    sorting: {},
    onReload: undefined,
    selectedRow: undefined,
    dialoginformation: undefined,
    gridRef: undefined,
    minGridHeight: 0,
    columnsArranged: [],
    columnsWidth: [],
    clearedPhrase: false,
    loadingDetail: false,
    tableLayout: false,
    layoutItem: "producersgrouped",
    widthItem: "producersgroupedtablewidth",
    sortitem: "producersgroupedtablesorting",
    currentSortColumn: "",
    currentSortDirection: "",
    loadingPrint: false,
  };

  constructor(props) {
    super(props);
    this.state.data = props.data;
    this.state.onReload = props.onReload;
    this.state.gridRef = React.createRef();
  }

  static contextType = SnackContext;
  context!: React.ContextType<typeof SnackContext>;

  componentDidMount() {
    // this.state.gridRef.setState({ canFilter: true });

    const producertablelayout = localStorage.getItem(this.state.layoutItem);
    if (producertablelayout) {
      this.setState({ columnsArranged: JSON.parse(producertablelayout) }, () => {});
      updateUserProfile(JSON.parse(producertablelayout), this.state.layoutItem, "general");
    } else {
      const columnsToRender = GridColumns(undefined, undefined, undefined, undefined);
      const columnsArr = columnsToRender.map((column) => {
        return column.name;
      });

      this.setState({ columnsArranged: columnsArr }, () => {});
      updateUserProfile(columnsArr, this.state.layoutItem, "general");
    }

    const producertablewidth = localStorage.getItem(this.state.widthItem);
    if (producertablewidth) {
      this.setState({ columnsWidth: JSON.parse(producertablewidth) }, () => {});
      updateUserProfile(JSON.parse(producertablewidth), this.state.widthItem, "general");
    } else {
      const columnsToRender = GridColumns(undefined, undefined, undefined, undefined);
      const columnsArr = columnsToRender.map((column) => {
        return { name: column.name, width: column.width };
      });

      localStorage.setItem(this.state.widthItem, JSON.stringify(columnsArr));
      this.setState({ columnsWidth: columnsArr }, () => {});
      updateUserProfile(columnsArr, this.state.widthItem, "general");
    }

    const producertablesorting = localStorage.getItem(this.state.sortitem);
    if (producertablesorting) {
      let sortingV = JSON.parse(producertablesorting);

      this.setState({ sorting: sortingV });
    }
  }

  componentDidUpdate() {
    // if (this.state.gridRef && this.state.gridRef.grid) {
    //   this.state.gridRef.setState({ canFilter: true });
    // }
  }

  getDetail(count, largeNumber = false) {
    const text = {
      __html: `
      <span style="${largeNumber ? "font-size: 21px" : ""}">${count}</span> Record(s) 
      <span style="font-size: 28px; line-height:0; "></span> 
      `,
    };
    return <span dangerouslySetInnerHTML={text} />;
  }

  getCount = (list, propName) => {
    if (list.length > 0) {
      const value = list
        .map((item) => {
          return item[propName];
        })
        .reduce((acc, curr) => {
          return Number(acc) + Number(curr);
        });
      return Number.isInteger(value) ? value.toFixed(2) : value;
    } else {
      return 0;
    }
  };

  handleFilterChange = (filter) => {
    const newFilters = handleFilterChange(filter, this.state.filters);
    this.setState({ filters: newFilters }, () => {});
  };

  getRows = (rows, filters, sorting) => {
    const result = generateGridFilteredRows(rows, filters);

    const fieldSorterData = Object.keys(sorting).map((item) => {
      return { name: item, reverse: sorting[item] == "ASC" ? false : true };
    });

    result.sort(generateSortFn(fieldSorterData));

    return result;
  };

  getValidFilterValues = (rows, columnId) => {
    const filterValues = rows
      .map((r) => r[columnId])
      .filter((item, i, a) => {
        return i === a.indexOf(item);
      })
      .sort((a, b) => a > b);
    return filterValues;
  };

  handleAvailableProducersAdvancesDoubleClick = (row: ProducersFullGroupedOnlyType) => {
    this.setState({ loadingDetail: true, selectedRows: [] });
    this.setState({ loadingDetail: false, selectedRow: { ...row } });
  };

  handleSort = (sortColumn, sortDirection) => {
    const sortingState = handleSortChange(this.state.sorting, sortColumn, sortDirection);

    this.setState({ sorting: sortingState, currentSortColumn: sortColumn, currentSortDirection: sortDirection }, () => {
      localStorage.setItem(this.state.sortitem, JSON.stringify(sortingState));
      updateUserProfile(sortingState, this.state.sortitem, "general");
    });
  };

  handleRemoveSortEntry = (field) => {
    const collection = document.getElementsByClassName("pull-right");
    let i = 0;
    for (i = 0; i < collection.length; i++) {
      if (collection[i].innerHTML.length < 1) {
        continue;
      }
      (collection[i] as HTMLElement).innerHTML = "";
      break;
    }
    const sortingState = this.state.sorting;
    delete sortingState[field];
    this.setState({ sorting: sortingState }, () => {
      localStorage.setItem(this.state.sortitem, JSON.stringify(sortingState));
      updateUserProfile(sortingState, this.state.sortitem, "general");
    });
  };

  handleRemoveFilterEntry = (key, value) => {
    removeFromSticky(key, value, "producersfullgrouped");

    this.setState({ filters: {} });
  };

  clearFilters = (columnToClear = undefined) => {
    const section = "producersfullgrouped";
    const sticky = getStickyFilters(section);

    localStorage.setItem(`stickyfilters_${section}`, JSON.stringify([]));
    updateUserProfile(sticky, undefined, section);
    this.setState({ filters: {} });
  };

  getChipsHeight = () => {
    return (Object.keys(this.state.sorting).length > 0 ? 42 : 0) + (Object.keys(this.state.filters).length > 0 ? 42 * Object.keys(this.state.filters).length : 0);
  };

  handleInfoClose = () => {
    this.setState({ dialoginformation: undefined });
  };

  handleLayout = () => {
    this.setState({ tableLayout: true });
  };

  handleLayoutClose = () => {
    if (!this.phrase || this.phrase.length === 0) {
      this.setState({ tableLayout: false });
    } else {
      this.clearPhrase();
    }
  };

  handleLayoutConfirm = (columns) => {
    localStorage.setItem(this.state.layoutItem, JSON.stringify(columns));
    this.setState({ tableLayout: false, columnsArranged: columns });
  };

  handleLayoutSelected = () => {
    this.setState({ tableLayoutSelected: true });
  };

  handleLayoutCloseSelected = () => {
    this.setState({ tableLayoutSelected: false });
  };

  handleColumnResize = (index, width) => {
    const columnName = this.state.columnsArranged[index];
    const newArr = [...this.state.columnsWidth];
    const colwidthRecord = this.state.columnsWidth.findIndex((item) => item.name.trim() == columnName.trim());
    if (colwidthRecord >= 0) {
      newArr[colwidthRecord].width = width;
      localStorage.setItem(this.state.widthItem, JSON.stringify(newArr));
      updateUserProfile(newArr, this.state.widthItem, "general");
      this.setState({ columnsWidth: newArr });
    }
  };

  filterTerms = (filterObj, term = "filterTerm") => {
    let val = 0;

    Object.keys(filterObj).map((key) => {
      val += filterObj[key][term].length;
    });

    return val;
  };

  phrase = "";
  setSearchPhrase = (phrase) => {
    this.phrase = phrase;
  };

  clearPhrase = () => {
    this.setSearchPhrase("");
    this.setState({ clearedPhrase: !this.state.clearedPhrase });
  };

  rowRenderer = (props) => {
    const { row } = props;
    return <Row {...props} onDoubleClickCapture={() => this.handleAvailableProducersAdvancesDoubleClick(row)} />;
  };

  handleExport = async (shouldIncludeShipments = true) => {
    this.setState({ loadingPrint: true });
    try {
      const filename = shouldIncludeShipments ? `${format(new Date(), "yyMMdd")}_ALL_INTAKES_AND_SHIPMENTS` : `${format(new Date(), "yyMMdd")}_ALL_INTAKES`;
      await exportProducerIntakeAndShipments("all", getFinancialYearSelected(), shouldIncludeShipments, filename);
    } catch (error) {
      const err = GenerateErrorMessage(error, "Error printing report");
      this.context.updateSnack({ show: true, color: "red", message: err });
    }
    this.setState({ loadingPrint: false });
  };

  menuItems = [
    {
      title: "ALL INTAKES",
      icon: DocumentsType.EXCEL,
      action: () => this.handleExport(false),
    },
    {
      title: "ALL INTAKES AND SHIPMENTS",
      icon: DocumentsType.EXCEL,
      action: () => this.handleExport(),
    },
  ];

  render() {
    const { classes } = this.props;

    const filteredRows = this.getRows(this.state.data, this.state.filters, this.state.sorting);
    const columnsToRender = GridColumns(this.state.data, this.state.filters, this.state.columnsArranged, this.state.columnsWidth);
    const mainClientheight =
      document.getElementsByTagName("Main") && document.getElementsByTagName("Main").length > 0 && document.getElementsByTagName("Main")[0].clientHeight
        ? document.getElementsByTagName("Main")[0].clientHeight - 120
        : 0;

    const totalRowColumns = [
      "bar_NoCartons",
      "bar_PalletSize",
      "paid_zar",
      "paid_zarcartons",
      "paid_cad",
      "paid_cadcartons",
      "paid_eur",
      "paid_eurcartons",
      "paid_gbp",
      "paid_gbpcartons",
      "paid_usd",
      "paid_usdcartons",
    ];

    return (
      <div className={classes.root}>
        {this.state.loadingPrint && <LinearProgress color="secondary" />}
        {this.state.selectedRow && <Redirect to={`/producers/grouped/${this.state.selectedRow.producer_id}`} />}
        {this.state.tableLayout && (
          <Confirmation isOpen={this.state.tableLayout} handleClose={this.handleLayoutClose} handleConfirm={() => {}} title="Editing Grid Layout" body={undefined}>
            <GridColumnLayout
              layoutItem={this.state.layoutItem}
              onSubmit={this.handleLayoutConfirm}
              onClose={this.handleLayoutClose}
              columns={this.state.columnsArranged}
              setSearchPhrase={this.setSearchPhrase}
              clearPhrase={this.state.clearedPhrase}
            />
          </Confirmation>
        )}
        {this.state.dialoginformation && (
          <DialogInformation
            isOpen={true}
            handleClose={this.handleInfoClose}
            handleOK={this.handleInfoClose}
            title={this.state.dialoginformation.title}
            body={`${this.state.dialoginformation.body}`}
            showinput={false}
          />
        )}
        <div className={classes.controlLeft}>
          <DocumentsButton menuItems={this.menuItems} disabled={(filteredRows || []).length === 0} />
        </div>
        <div className={classes.control}>
          <Tooltip title="Clear Filters">
            <Button
              variant="contained"
              color="primary"
              onClick={() => this.clearFilters()}
              style={{ marginRight: "10px" }}
              disabled={Object.entries(this.state.filters).length === 0 && this.state.filters.constructor === Object}
            >
              <Badge badgeContent={this.filterTerms(this.state.filters)} color="secondary">
                <ClearFilterIcon />
              </Badge>
            </Button>
          </Tooltip>
          <Tooltip title="Refresh Data">
            <Button variant="contained" color="primary" onClick={this.state.onReload} style={{ marginRight: "10px" }}>
              <Refresh />
            </Button>
          </Tooltip>
          <Tooltip title="Grid Layout">
            <Button variant="contained" color="primary" onClick={this.handleLayout} style={{ marginRight: "10px" }}>
              <LayoutIcon />
            </Button>
          </Tooltip>
        </div>
        <div>
          <ReactDataGrid
            className="rdg-light"
            ref={(input) => (this.state.gridRef = input)}
            columns={columnsToRender}
            rows={filteredRows}
            enableFilterRow={true}
            rowHeight={30}
            headerRowHeight={35}
            style={{ position: "relative", height: mainClientheight, width: "100%", marginTop: "10px" }}
            onFiltersChange={(filter) => this.handleFilterChange(filter)}
            onSort={(sortColumn, sortDirection) => this.handleSort(sortColumn, sortDirection)}
            sortColumn={this.state.currentSortColumn}
            sortDirection={this.state.currentSortDirection as any}
            onColumnResize={this.handleColumnResize}
            summaryRows={summaryRows(filteredRows, totalRowColumns)}
            rowRenderer={this.rowRenderer}
          />
        </div>
        <div>
          <ProducersFilterChips
            columns={columnsToRender}
            filterData={this.state.filters}
            handleFilterClear={null}
            sortingData={this.state.sorting}
            removesortingentry={this.handleRemoveSortEntry}
            removeFilterEntry={this.handleRemoveFilterEntry}
          />
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(ProducersTableUnstyled);
