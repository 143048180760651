import React, { FC, useState, useEffect, useContext, forwardRef, useMemo, useRef } from "react";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";

import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import LinearProgress from "@material-ui/core/LinearProgress";

import Autocomplete from "@material-ui/lab/Autocomplete";

import numeral from "numeral";
import format from "date-fns/format";
import parseISO from "date-fns/parseISO";
import toDate from "date-fns/toDate";
import { Field, Form } from "react-final-form";
import ReactDatePicker from "react-datepicker";
import { FormApi } from "final-form";

import { FormattedCurrencyCombo, getCurrencyAllSortedMappedforCombo } from "../../../lib/api/currency";
import { getExchangeRateByDateAndCurrency } from "../../../lib/api/exchangerates";
import { SnackContext } from "../../../lib/context/SnackContext";
import { bankDetailsByCompanyAndCurrency } from "../../../lib/api/companydetailsbanking";
import { SaleFundsFullType } from "../../../lib/api/salefunds";
import BigNumber from "bignumber.js";
import { AllocationList } from "../../../lib/api/salefundsallocation";
import { GreenButton, LightGreenButton, RedButton } from "../../../lib/components/ColorButtons";
import { getClientsAllSortedMappedforComboID } from "../../../lib/api/clients";
import Confirmation from "../../../lib/components/confirmation";
import { getFinancialYearSelected, getFinancialYears } from "../../../lib/api/week";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: "white",
      paddingTop: "10px",
      paddingRight: "10px",
    },
    container: {
      display: "flex",
      flexWrap: "wrap",
    },
    tableCellLabel: {
      width: "150px",
      borderBottom: "none",
      height: "50px",
      textAlign: "right",
      paddingTop: "5px",
    },
    tableCellDetail: {
      width: "100%",
      borderBottom: "none",
      height: "50px",
    },
    tableContainer: {
      position: "relative",
      height: "100%",
      overflowY: "scroll",
      overflowX: "auto",
    },
    tableCellDetailDate: {
      marginTop: "-5px",
      width: "500px",
      borderBottom: "none",
      height: "50px",
    },
    gridActions: {
      display: "flex",
      justifyContent: "flex-end",
      flexDirection: "row",
      marginBottom: "10px",
      gap: theme.spacing(1),
    },
    loadingWrapper: {
      width: "550px",
    },
    formFieldContainer: {
      display: "grid",
      gridTemplateColumns: "150px 180px",
      gridGap: "10px",
    },
    formContainer: {
      display: "flex",
      flexDirection: "column",
      gap: "20px",
    },
  });

const formatCurr = (value: string): number => (value ? parseFloat(value.toString().replaceAll(",", "")) : 0);

type BankingOptionsFormatted = { value: number; display: string };

type FundsFormProps = {
  loadingDetail?: boolean;
  isOverpaidFund: boolean;
  selectedRecord: SaleFundsFullType;
  allocationList: AllocationList[];
  selectedAllocationList: AllocationList[];
  setAvailableFunds(available: number): void;
  handleProcess: (selectedRecord: SaleFundsFullType) => Promise<void>;
  setFormDirtyFields(formDirtyFields: { [index: string]: boolean }): void;
} & WithStyles<typeof styles>;

const FundsFormUnstyled: FC<FundsFormProps> = ({
  classes,
  loadingDetail,
  selectedRecord,
  allocationList,
  isOverpaidFund,
  selectedAllocationList,
  handleProcess,
  setAvailableFunds,
  setFormDirtyFields,
}) => {
  const [currencies, setCurrencies] = useState<FormattedCurrencyCombo[]>([]);
  const [bankingOptions, setBankingOptions] = useState<BankingOptionsFormatted[]>([]);
  const [clientsList, setClientsList] = useState<{ value: number; display: string }[]>([]);
  const [confirmSave, setConfirmSave] = useState<undefined | SaleFundsFullType>(undefined);
  const [financialYears, setFinancialYears] = useState([]);

  const [loading, setLoading] = useState(true);
  const [posteddate, setPosteddate] = useState(selectedRecord.id != 0 ? parseISO(selectedRecord.posteddate.toString()) : new Date());

  const loadData = async () => {
    setLoading(true);

    const [currenciesResult, clientsResult, years] = await Promise.all([getCurrencyAllSortedMappedforCombo(), getClientsAllSortedMappedforComboID(), getFinancialYears()]);
    const currencyFind = currenciesResult.find((curr) => curr.value == selectedRecord.currency_id);

    if (currencyFind) {
      const result = await bankDetailsByCompanyAndCurrency(1, currencyFind.display);
      const optionsMap = result.data.map((item) => ({ value: item.id, display: `${item.bank} (${item.accountnumber})` }));
      setBankingOptions(optionsMap);
    }

    setCurrencies(currenciesResult);
    setClientsList(clientsResult);
    setFinancialYears(years.map((item) => ({ display: item.financial_year.toString(), value: item.financial_year.toString() })));
    setLoading(false);
  };

  useEffect(() => {
    loadData();
  }, []);

  const handleResetForm = () => {
    setConfirmSave(undefined);
    setFormDirtyFields(undefined);
  };

  const handleProcessForm = async (values: SaleFundsFullType) => {
    // checks if there are any allocations made
    if (allocationList.filter((row) => row.dirty).length > 0) {
      setConfirmSave(values);
    } else {
      await handleProcess(values);
      handleResetForm();
    }
  };

  const handleConfirmSave = async () => {
    await handleProcess(confirmSave);
    handleResetForm();
  };

  if (loading) {
    return (
      <div className={classes.loadingWrapper}>
        <LinearProgress color="secondary" />
      </div>
    );
  }

  return (
    <div className={classes.root}>
      {confirmSave && (
        <Confirmation
          isOpen={true}
          handleClose={() => setConfirmSave(undefined)}
          handleConfirm={() => handleConfirmSave()}
          title={"Unsaved allocations"}
          body={"Warning: You have unsaved allocations. Do you want to discard them and save?"}
        />
      )}
      <Form
        keepDirtyOnReinitialize
        initialValues={{
          id: selectedRecord.id,
          clients_id: selectedRecord.clients_id,
          clients_code: selectedRecord.clients_code,
          clients_name: selectedRecord.clients_name,
          exchangerate: selectedRecord.exchangerate,
          amount: numeral(selectedRecord.amount).format("0,0.00"),
          bankcharges: numeral(selectedRecord.bankcharges).format("0,0.00"),
          available: numeral(selectedRecord.available).format("0,0.00"),
          currency_id: selectedRecord.currency_id,
          currency: selectedRecord.currency,
          companydetailsbanking_id: selectedRecord.companydetailsbanking_id,
          makeanote: selectedRecord.makeanote,
          posteddate: selectedRecord.posteddate ? format(parseISO(selectedRecord.posteddate.toString()), "dd-MMM-yyyy") : new Date(),
          totalamount: numeral(selectedRecord.totalamount).format("0,0.00"),
          allocated_year: selectedRecord.allocated_year ? selectedRecord.allocated_year : getFinancialYearSelected(),
        }}
        onSubmit={(values: any) => handleProcessForm(values)}
        render={({ form, handleSubmit }: any) => (
          <form onSubmit={handleSubmit}>
            <FormDetail
              form={form}
              classes={classes}
              loading={loadingDetail}
              posteddate={posteddate}
              currencies={currencies}
              clientsList={clientsList}
              bankingOptions={bankingOptions}
              selectedRecord={selectedRecord}
              isOverpaidFund={isOverpaidFund}
              selectedAllocationList={selectedAllocationList}
              handleProcess={handleProcess}
              setPosteddate={setPosteddate}
              setBankingOptions={setBankingOptions}
              setAvailableFunds={setAvailableFunds}
              setFormDirtyFields={setFormDirtyFields}
              financialYears={financialYears}
            />
          </form>
        )}
      />
    </div>
  );
};

type FormDetailProps = {
  form: FormApi;
  loading: boolean;
  posteddate: Date;
  selectedRecord: any;
  isOverpaidFund: boolean;
  currencies: FormattedCurrencyCombo[];
  selectedAllocationList: AllocationList[];
  bankingOptions: BankingOptionsFormatted[];
  clientsList: { value: number; display: string }[];
  financialYears: any[];
  setPosteddate(posteddate: Date): void;
  setAvailableFunds(available: number): void;
  setBankingOptions(bankingOptions: BankingOptionsFormatted[]): void;
  handleProcess: (selectedRecord: SaleFundsFullType) => Promise<void>;
  setFormDirtyFields(formDirtyFields: { [index: string]: boolean }): void;
} & WithStyles<typeof styles>;

const FormDetail: FC<FormDetailProps> = ({
  form,
  loading,
  classes,
  currencies,
  posteddate,
  clientsList,
  bankingOptions,
  selectedRecord,
  isOverpaidFund,
  financialYears,
  selectedAllocationList,
  handleProcess,
  setPosteddate,
  setBankingOptions,
  setAvailableFunds,
  setFormDirtyFields,
}) => {
  const { updateSnack } = useContext(SnackContext);

  const fieldAmountRef = useRef(null);
  const fieldForeignChargesRef = useRef(null);
  const fieldNotesRef = useRef(null);

  const newFund = useMemo(() => form.getState().initialValues.id == 0, [form]);

  // custom 'dirty' validation to exclude certain fields
  const dirty = useMemo(() => {
    const keys = Object.keys(form.getState().dirtyFields).filter((key) => !["available", "allocate"].includes(key));
    return keys.length > 0;
  }, [form.getState().dirtyFields]);

  const getCurrencyBanks = async (currId: number) => {
    const currencyFind = currencies.find((curr) => curr.value == currId);
    if (currencyFind) {
      const result = await bankDetailsByCompanyAndCurrency(1, currencyFind.display);
      const optionsMap = result.data.map((item) => ({ value: item.id, display: `${item.bank} (${item.accountnumber})` }));
      setBankingOptions(optionsMap);
    }
  };

  const onParseCurrency = async (form: FormApi, value) => {
    if (form.getState().values["posteddate"]) {
      const pdate = new Date(form.getState().values["posteddate"]);
      try {
        const pdateFormat = format(pdate, "yyyy-MM-dd");
        const currency = (currencies.find((curr) => curr.value === value) || { display: "" }).display;
        const data = await getExchangeRateByDateAndCurrency(currency, pdateFormat);
        form.change("exchangerate", data);
      } catch (error) {
        updateSnack({ show: true, message: "Cannot get exchange rates.", color: "red" });
      }
      await getCurrencyBanks(value);
    }
    return value;
  };

  const onChangePostedDate = async (form: FormApi, value) => {
    try {
      setPosteddate(value);
      const pdate = new Date(value);
      const pdateFormat = format(pdate, "yyyy-MM-dd");
      const data = await getExchangeRateByDateAndCurrency(form.getState().values["currency"], pdateFormat);
      form.change("posteddate", toDate(value));
      form.change("exchangerate", data);
    } catch (error) {
      updateSnack({ show: true, message: "Cannot get exchange rates.", color: "red" });
    }
  };

  const onParseBankCharges = (form: FormApi) => (event) => {
    // only apply "bankcharges" if field is dirty
    if (form.getState().dirtyFields.bankcharges) {
      const bankcharges = event.target.value;
      const amount = form.getState().values.amount;
      const newAmountAvailable = new BigNumber(formatCurr(amount)).plus(new BigNumber(formatCurr(bankcharges))).toNumber();
      form.change("available", numeral(newAmountAvailable).format("0,0.00"));
      form.change("bankcharges", numeral(bankcharges).format("0,0.00"));
    }
  };

  const onParseAmountReceived = (form: FormApi) => (event) => {
    if (form.getState().dirtyFields.amount) {
      const amount = event.target.value;
      const bankcharges = form.getState().values.bankcharges;
      const newAmountAvailable = new BigNumber(formatCurr(amount)).plus(new BigNumber(formatCurr(bankcharges))).toNumber();
      form.change("available", numeral(newAmountAvailable).format("0,0.00"));
      form.change("amount", numeral(amount).format("0,0.00"));
    }
  };

  const handleKeyDown = (event) => (ref) => {
    if (event.key == "Enter") {
      event.preventDefault();
      try {
        ref.current.children[0].children[0].focus();
      } catch (error) {
        updateSnack({ show: true, message: "Error selecting next field. Please try again", color: "red" });
      }
    }
  };

  const onParseClient = (form: FormApi, value: number) => {
    const client = clientsList.find((client) => client.value == value);
    form.change("clients_name", client.display);
  };

  const onParseAllocatedYear = (form: FormApi, value: number) => {
    form.change("allocated_year", value);
  };

  useEffect(() => {
    const available = formatCurr(form.getState().values["available"]);
    setAvailableFunds(available);
  }, [form.getState().values["available"]]);

  useEffect(() => {
    const { amount, bankcharges } = form.getState().values;
    const totalamount = new BigNumber(formatCurr(amount)).plus(new BigNumber(formatCurr(bankcharges))).toNumber();
    form.change("totalamount", numeral(totalamount).format("0,0.00"));
  }, [form.getState().values["amount"], form.getState().values["bankcharges"]]);

  useEffect(() => {
    const totalSelected = selectedAllocationList.reduce((tot, row) => {
      if (row.deleted) {
        tot += -row.orig_amount;
      } else if (row.dirty) {
        // user changes an allocation that has a record in the db
        if (row.id) {
          // we assign the amount to the available funds
          tot += -(row.orig_amount - row.amount);
        } else {
          tot += row.amount;
        }
      }
      return tot;
    }, 0);
    const calc = new BigNumber(selectedRecord.available).minus(new BigNumber(totalSelected)).toNumber();
    form.change("available", numeral(calc).format("0,0.00"));
  }, [selectedAllocationList]);

  useEffect(() => {
    const unusedColumns = ["available", "allocate", "clients_name"]; // these columns are disabled by default
    const keys = Object.keys(form.getState().dirtyFields).reduce((obj, key) => (!unusedColumns.includes(key) ? { ...obj, [key]: true } : obj), {});
    setFormDirtyFields(keys);
  }, [form.getState().dirtyFields]);

  return (
    <div className={classes.formContainer}>
      <div className={classes.gridActions}>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <RedButton type="button" variant="contained" onClick={() => handleProcess(undefined)}>
            Close
          </RedButton>
          <LightGreenButton type="submit" variant="contained" onClick={() => form.change("allocate", false)} disabled={!dirty}>
            Save and Close
          </LightGreenButton>
          <GreenButton type="submit" variant="contained" onClick={() => form.change("allocate", true)} disabled={!dirty}>
            Save and Open
          </GreenButton>
        </div>
      </div>
      <div className={classes.container}>
        {newFund ? (
          <TableFieldCombo
            required
            classes={classes}
            form={form}
            field="clients_id"
            title="Client Code"
            data={clientsList}
            onParse={(value) => onParseClient(form, value)}
            disabled={loading}
          />
        ) : (
          <TableFieldText classes={classes} field="clients_code" title="Client Code" required disabled />
        )}
        <TableFieldText classes={classes} field="clients_name" title="Client Name" required disabled />
        <TableFieldDate
          required
          classes={classes}
          field="posteddate"
          dateValue={posteddate}
          title="Date Received"
          changeDate={(value) => onChangePostedDate(form, value)}
          disabled={loading}
        />
        <TableFieldCombo
          required
          classes={classes}
          form={form}
          field="currency_id"
          title="Currency"
          data={currencies}
          onParse={(value) => onParseCurrency(form, value)}
          disabled={loading}
        />
        <TableFieldCombo required classes={classes} form={form} field="companydetailsbanking_id" title="Bank" data={bankingOptions} onParse={() => {}} disabled={loading} />
        {!isOverpaidFund && (
          <>
            <TableFieldText
              classes={classes}
              field="amount"
              title="Amount Received"
              required
              disabled={loading}
              onParse={onParseAmountReceived(form)}
              ref={fieldAmountRef}
              onKeyDown={(event) => handleKeyDown(event)(fieldForeignChargesRef)}
            />
            <TableFieldText
              classes={classes}
              field="bankcharges"
              title="Foreign Charges"
              disabled={loading}
              onParse={onParseBankCharges(form)}
              ref={fieldForeignChargesRef}
              onKeyDown={(event) => handleKeyDown(event)(fieldNotesRef)}
            />
            <TableFieldText classes={classes} field="totalamount" title="Total Amount" disabled={true} />
          </>
        )}
        <TableFieldText classes={classes} field="available" title="Available To Allocate" disabled={true} />
        <TableFieldText classes={classes} field="makeanote" title="Note" disabled={loading} ref={fieldNotesRef} />
        <TableFieldCombo
          required
          classes={classes}
          form={form}
          field="allocated_year"
          title="Allocated Year"
          data={financialYears}
          onParse={(value) => onParseAllocatedYear(form, value)}
          disabled={loading}
        />
        {/* ALLOCATE FIELD TO USE IN SUBMITTING OF FORM */}
        <Field hidden name="allocate" field="allocate" value={false} render={() => <></>} />
      </div>
    </div>
  );
};

export const FundsForm = withStyles(styles)(FundsFormUnstyled);

const validate = (value: any) => (value ? undefined : "Required");

type TableFieldTextProps = {
  field: string;
  title: string;
  disabled: boolean;
  onKeyDown?(event: any): void;
  required?: boolean;
  onParse?: (event) => void;
} & WithStyles<typeof styles>;

const TableFieldText = forwardRef((props: TableFieldTextProps, ref: any) => {
  const { classes, field, title, disabled, onParse, required, onKeyDown } = props;
  return (
    <div className={classes.formFieldContainer}>
      <div className={classes.tableCellLabel}>{title}:</div>
      <div className={classes.tableCellDetail}>
        <Field
          validate={required ? validate : undefined}
          name={field}
          type="text"
          fullWidth={true}
          disabled={disabled}
          render={({ input, meta }) => (
            <>
              <TextField {...input} ref={ref} onKeyDown={onKeyDown} fullWidth variant="standard" disabled={disabled} onBlur={onParse} error={meta.error && meta.touched} />
              {meta.error && meta.touched ? <span style={{ color: "red" }}>{meta.error}</span> : <></>}
            </>
          )}
        />
      </div>
    </div>
  );
});

const TableFieldCombo: React.FunctionComponent<
  { form: FormApi; title: string; field: string; data: any; onParse: (value) => any; required?: boolean; disabled?: boolean } & WithStyles<typeof styles>
> = (props) => {
  const { classes, form, title, field, data, onParse, required, disabled = false } = props;
  const [selectedValue, setSelectedValue] = useState({ display: "", value: "" });

  useEffect(() => {
    const value = form.getState().values[field];
    const exists = (data || []).find((item) => item.value == value);
    if (exists) {
      setSelectedValue(exists);
      onParse(value);
    }
  }, [form.getState().values[field]]);

  return (
    <div className={classes.formFieldContainer}>
      <div className={classes.tableCellLabel}>{title}:</div>
      <div className={classes.tableCellDetail}>
        <Field
          name={field}
          label={title}
          validate={required ? validate : undefined}
          render={({ meta, input }) => (
            <>
              <Autocomplete
                {...input}
                id={field}
                fullWidth
                value={selectedValue}
                onChange={(_, data) => {
                  const value = data ? data.value : "";
                  form.change(field, value);
                }}
                options={data}
                getOptionLabel={(option: any) => option.display}
                renderInput={(params) => <TextField {...params} variant="standard" />}
                disabled={disabled}
              />
              {meta.error && meta.touched ? <span style={{ color: "red" }}>{meta.error}</span> : <></>}
            </>
          )}
        />
      </div>
    </div>
  );
};

const CalenderCustomInput = forwardRef((props: any, ref: any) => {
  return (
    <Button name="CalenderCustomInput" variant="contained" color="primary" type="button" onClick={props.onClick} style={{ marginTop: "5px", width: "180px" }}>
      {props.value}
    </Button>
  );
});

const TableFieldDate: React.FunctionComponent<
  { field: string; dateValue: Date; title: string; disabled: boolean; changeDate(value): void; required?: boolean } & WithStyles<typeof styles>
> = (props) => {
  const { classes, field, dateValue, title, changeDate, disabled, required } = props;
  return (
    <div className={classes.formFieldContainer}>
      <div className={classes.tableCellLabel}>{title}:</div>
      <div className={classes.tableCellDetailDate}>
        <Field
          name={field}
          label={title}
          validate={required ? validate : undefined}
          render={({ input, meta }) => (
            <>
              <ReactDatePicker
                {...input}
                locale="en-GB"
                disabled={disabled}
                showWeekNumbers={true}
                selected={toDate(dateValue)}
                onChange={(value) => changeDate(value)}
                dateFormat={"dd MM yyyy"}
                placeholderText="click here to select a date"
                customInput={<CalenderCustomInput />}
              />
              {meta.error && meta.touched ? <span style={{ color: "red" }}>{meta.error}</span> : <></>}
            </>
          )}
        />
      </div>
    </div>
  );
};
