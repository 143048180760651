import { client } from "./_base";

export type CurrencyType = {
  id: number;
  code: string;
  name: string;
  local: boolean;
  active: number;
  CurrencyBanking?: any;
};

export const currency = client("currency", (item) => ({
  ...item,
}));

export type FormattedCurrencyCombo = {
  value: number;
  display: string;
  data: CurrencyType;
};

export const getCurrencyAllSortedMappedforCombo = async (): Promise<FormattedCurrencyCombo[]> => {
  const data = await currency.all();
  return data
    .sort((a: CurrencyType, b: CurrencyType) => {
      if (a.code > b.code) return 1;
      if (a.code < b.code) return -1;
      return 1;
    })
    .map((item: CurrencyType) => {
      return { value: item.id, display: item.code, data: item };
    });
};

export const getCurrencyIdByCode = async (code: string) => {
  const result: CurrencyType[] = await currency.all();
  const currencyItem = result.find((res) => res.code == code);
  return currencyItem ? currencyItem.id : 0;
};

export const getActiveCurrencies = async (): Promise<FormattedCurrencyCombo[]> => {
  const data = await currency.all();
  return data
    .sort((a: CurrencyType, b: CurrencyType) => {
      if (a.code > b.code) return 1;
      if (a.code < b.code) return -1;
      return 1;
    })
    .filter((currency: CurrencyType) => currency.active == 1)
    .map((item: CurrencyType) => {
      return { value: item.id, display: item.code, data: item };
    });
};
