import React from "react";
import { withStyles, createStyles, Theme, WithStyles } from "@material-ui/core/styles";

import { GreenButton, RedButton, OrangeButton } from "../../lib/components/ColorButtons";
import Grid from "../../lib/components/grid";
import { GroupedUnapprovedGridColumns } from "./groupedunapprovedgridsetup";
import { groupedFind } from "./groupedunapprovedtable";
import { ConsolidationDetail } from "../../lib/api/consolidation";

import Button from "@material-ui/core/Button";
import Toolbar from "@material-ui/core/Toolbar";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import { BigNumber } from "bignumber.js";
import { Row } from "react-data-grid";
import classnames from "classnames";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1),
      height: "calc(100vh - 100px)",
      width: "100%",
      position: "relative",
    },
    redRow: {
      color: "red",
    },
    greenRow: {
      color: "red",
    },
    boldRow: {
      fontWeight: "bold",
      cursor: "pointer",
    },
    normalRow: {
      fontWeight: "normal",
      cursor: "pointer",
    },
    toolbarWrapper: {
      position: "relative",
    },
    toolbar: {
      position: "absolute",
      left: 0,
      width: "100%",
    },
  });

type GroupPendingTableProps = {
  data: ConsolidationDetail[];
  filter: string;
  loading: boolean;
  mainGridHeight: number;
  selectedRows: ConsolidationDetail[];
  groupChecked: boolean;
  groupOnChange(): void;
  handleDetailRefresh(): void;
  handleOnAcceptPending(): void;
  handleApproveSelected(): void;
  handleUnapprovePendingSelected(): void;
  handlePendingGroupRowsSelected(rows: ConsolidationDetail[]): void;
  handleGroupUpdateSelectedData(rows: ConsolidationDetail[]): void;
  handleGroupRowsSelected(rows: ConsolidationDetail[]): void;
} & WithStyles<typeof styles>;

class GroupPendingTableUnstyled extends React.Component<GroupPendingTableProps, {}> {
  constructor(props) {
    super(props);
  }

  getGroupedUnapprovedData = (data: ConsolidationDetail[]) => {
    const groupedData = data.reduce((arr, row) => {
      const index = arr.findIndex((item) => groupedFind(row, item));

      if (index < 0) {
        arr.push({ ...row });
      } else {
        const exists = arr[index];
        arr[index] = {
          ...row,
          NoCartons: new BigNumber(exists.NoCartons).plus(new BigNumber(row.NoCartons)).toNumber(),
          palletSize: new BigNumber(exists.palletSize).plus(new BigNumber(row.palletSize)).toNumber(),
          final: new BigNumber(exists.final).plus(new BigNumber(row.final)).toNumber(),
        };
      }

      return arr;
    }, []);

    return groupedData;
  };

  handleRowSelect = (row) => {
    const selectedRows = [...this.props.selectedRows];
    const data = this.props.data.filter((item) => groupedFind(row, item));

    for (let i = 0; i < data.length; i++) {
      const item = data[i];
      const index = selectedRows.findIndex((sel) => sel.barcode_id == item.barcode_id);

      if (index < 0) {
        selectedRows.push(item);
      } else {
        selectedRows.splice(index, 1);
      }
    }

    this.props.handleGroupRowsSelected(selectedRows);
  };

  filteredRows;
  handleFilteredRows = (rows: any[]) => {
    this.filteredRows = rows;
  };

  handleSelectAllPendingRows = () => {
    const selected = [];
    this.filteredRows.forEach((item) => {
      selected.push(...this.props.data.filter((row) => groupedFind(row, item)));
    });
    this.props.handleGroupRowsSelected(selected);
  };

  handleClearWaitingSelected = () => {
    this.props.handleGroupRowsSelected([]);
  };

  rowRenderer = (props, classes) => {
    const { row } = props;
    const fontWeight = this.props.selectedRows.find((item) => groupedFind(row, item)) && classes.boldRow;
    const colorStyle = row.rowDirty == 1 ? classes.redRow : classes.blackRow;

    return <Row {...props} className={classnames(fontWeight, colorStyle)} />; // onDoubleClickCapture={() => this.handleRowSelect(row)}
  };

  handleGroupChanged = (column: string, rows: any[]) => {
    const selectedRows = this.props.selectedRows;

    const updated = this.props.data.map((item) => {
      const exists = rows.find((row) => groupedFind(row, item));
      if (exists) {
        const newRow = {
          ...item,
          exchangerate: exists.exchangerate,
          adjustment: exists.adjustment,
          comment: exists.comment,
          fobcost_final: exists.fobcost_final,
          coldcost_final: exists.coldcost_final,
          ppecb_final: exists.ppecb_final,
          cga_final: exists.cga_final,
          rowDirty: exists.rowDirty,
        };
        if (newRow.rowDirty) {
          selectedRows.push(newRow);
        }
        return newRow;
      }
      return item;
    });
    this.props.handleGroupUpdateSelectedData(updated);
    this.props.handleGroupRowsSelected(selectedRows);
  };

  handleAccept = () => {
    this.props.handleOnAcceptPending();
    this.setState({ selectedGroupRows: [] });
  };

  handleProcess = () => {
    this.props.handleApproveSelected();
    this.setState({ selectedGroupRows: [] });
  };

  handleUnapproveSelected = () => {
    this.props.handleUnapprovePendingSelected();
    this.setState({ selectedGroupRows: [] });
  };

  render() {
    const { classes } = this.props;
    const groupedData: any[] = this.getGroupedUnapprovedData(this.props.data);

    return (
      <div className={classes.toolbarWrapper}>
        <Toolbar className={classes.toolbar}>
          <Button type="button" style={{ marginRight: "10px" }} color="primary" variant="outlined" onClick={this.props.handleOnAcceptPending}>
            Save
          </Button>
          <GreenButton type="button" variant="contained" color="primary" onClick={this.props.handleApproveSelected} disabled={this.props.selectedRows.length === 0}>
            Approve Selected
          </GreenButton>
          <OrangeButton type="button" variant="contained" color="primary" onClick={this.handleSelectAllPendingRows}>
            Select All Rows
          </OrangeButton>
          <OrangeButton type="button" variant="contained" color="primary" onClick={this.handleClearWaitingSelected} disabled={this.props.selectedRows.length === 0}>
            Clear Selected
          </OrangeButton>
          <RedButton type="button" variant="contained" color="primary" onClick={this.props.handleUnapprovePendingSelected} disabled={this.props.selectedRows.length === 0}>
            Unapprove Selected
          </RedButton>
          <FormControlLabel
            control={<Switch inputProps={{ "aria-label": "primary checkbox" }} checked={this.props.groupChecked} onChange={this.props.groupOnChange} />}
            label="Group"
          />
        </Toolbar>
        <Grid
          fill={true}
          data={groupedData}
          loading={this.props.loading}
          forceHeight={this.props.mainGridHeight}
          clearFilters={"consolidationselected"}
          handleFilteredRows={this.handleFilteredRows}
          handleRefresh={this.props.handleDetailRefresh}
          rowRenderer={(props) => this.rowRenderer(props, classes)}
          handleChange={(column, rows) => this.handleGroupChanged(column, rows)}
          GridColumns={(data, filters, columnArrangement, columnsWidth) =>
            GroupedUnapprovedGridColumns(data, filters, columnArrangement, columnsWidth, this.handleRowSelect, this.props.selectedRows, "consolidationselected")
          }
          totalRowColumns={["NoCartons", "palletSize"]}
          averageRowColumns={[
            "roe_etd",
            "exchangerate",
            "sellingprice",
            "sale_debit",
            "sale_credit",
            "sellingprice_final",
            "foreigncost",
            "nettsellprice",
            "ld_fob",
            "rtgc",
            "rtgczar",
            "ld_dip",
            "diff_rtg_dip",
            "adjustment",
            "comment",
            "fobcost_final",
            "coldcost_final",
            "ppecb_final",
            "cga_final",
            "margin",
            "totalcost",
          ]}
        />
      </div>
    );
  }
}

export default withStyles(styles)(GroupPendingTableUnstyled);
