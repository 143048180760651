import { client, apiHeaders, request } from "./_base";
import { config } from "../../config";

const endpoint = "producerphattachment";

export type ProducerPHAttachmentType = {
  id: number;
  producerph_id: number;
  name: string;
  document: string;
};
export const producerphattachment = client(endpoint, (item) => ({
  ...item,
}));

export const getProducerPHAttachmentAllById = (producerphid: number) =>
  request(`${endpoint}/ext/fullbyid/${producerphid}`, {
    headers: apiHeaders(),
  })
    .then(async (result) => {
      if (result.status == 500) {
        const resp = await result.json();
        throw resp;
      }
      return result.json();
    })
    .then(({ data }) => {
      return data;
    });
