import React, { FC, useEffect } from "react";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";

import Button from "@material-ui/core/Button";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

import { Form, Field } from "react-final-form";
import { Checkbox, TextField } from "final-form-material-ui";
import { FormApi } from "final-form";
import format from "date-fns/format";

import PUCS from "../producerspucs/pucs";
import Packhouse from "../producerspackhouse/packhouse";
import ProducerFinance from "../producersfinance/producersfinance";
import ProducerPH from "../producersph/producerph";
import AdvanceContract from "../advancecontracts/advancecontract";

import TabPanel from "../../lib/components/TabPanel";
import { getProducerByProducerId, ProducerType, upsertProducer } from "../../lib/api/producer";
import { DialogInformation } from "../../lib/components/dialoginformation";
import { GenerateErrorMessage } from "../../lib/helpers/string_methods";
import { SnackContext } from "../../lib/context/SnackContext";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      backgroundColor: theme.palette.background.paper,
      display: "flex",
      minHeight: "750px",
      padding: theme.spacing(1),
    },
    tabs: {
      borderRight: `1px solid ${theme.palette.divider}`,
      minWidth: "135px",
    },
    tabsLabel: {
      width: "150px",
    },
    tableRowFieldTitle: {
      paddingTop: theme.spacing(1),
      paddingRight: theme.spacing(1) * 2,
      marginTop: theme.spacing(1),
      textAlign: "right",
      minWidth: "175px",
      verticalAlign: "top",
    },
    tableRowFieldData: {
      paddingTop: theme.spacing(1),
      paddingRight: theme.spacing(1) * 2,
      marginTop: theme.spacing(1),
      textAlign: "left",
    },
    tableRowFieldInput: {
      width: "100%",
    },
    tabWidth: {
      width: "800px",
    },
    fieldTitlePaddingTop: {
      paddingTop: "50px",
      paddingRight: theme.spacing(1) * 2,
      marginTop: theme.spacing(1),
      textAlign: "right",
      minWidth: "175px",
      verticalAlign: "top",
    },
    fieldDataPaddingTop: {
      paddingTop: "50px",
      paddingRight: theme.spacing(1) * 2,
      marginTop: theme.spacing(1),
      textAlign: "left",
    },
  });

enum SelectedTab {
  Producer = 0,
  PUC = 1,
  Packhouse = 2,
  Finance = 3,
  Contracts = 4,
  Treatment = 5,
}

type ProducersEditFormProps = {
  onSubmit: any;
  onClose: any;
  id: any;
} & WithStyles<typeof styles>;

class ProducersEditFormUnstyled extends React.Component<ProducersEditFormProps, any> {
  state = {
    classes: undefined,
    value: SelectedTab.Producer,
    item: { id: "0", name: "", code: "", solasmethod2: 0, active: 1 as any },
    onSubmit: undefined,
    onClose: undefined,
    selectedpucid: undefined,
    id: 0,
    producerFormDirty: false,
    showSavePrompt: false,
    loading: true,
    unsavedChangesPrompt: undefined,
  };

  constructor(props) {
    super(props);
    this.state.classes = props.classes;
    this.state.onSubmit = props.onSubmit;
    this.state.onClose = props.onClose;
    this.state.id = props.id;
  }

  static contextType = SnackContext;
  context!: React.ContextType<typeof SnackContext>;

  componentDidMount() {
    if (this.state.id != 0) {
      this.getItem(this.state.id);
    }
  }

  getItem = async (id: number) => {
    this.setState({ loading: true });
    try {
      const item = await getProducerByProducerId(id);
      this.setState({ item: item.data[0] });
    } catch (error) {
      const err = GenerateErrorMessage(error, "Failed to get producer");
      this.context.updateSnack({ show: true, color: "red", message: err });
    }
    this.setState({ loading: false });
  };

  handleRefresh = () => {
    if (this.state.id != 0) {
      this.getItem(this.state.id);
    }
  };

  handleUpdateProducer = async () => {
    try {
      await upsertProducer({
        id: this.state.id,
        updated_at: new Date(),
        created_at: new Date(),
      });
    } catch (error) {
      const err = GenerateErrorMessage(error, "Failed to update producer");
      this.context.updateSnack({ show: true, color: "red", message: err });
    }
    this.handleRefresh();
  };

  handleFocus = (event) => {
    event.target.select();
  };

  handleChangeTab = (event: React.ChangeEvent<{}>, newValue: number) => {
    if (this.state.producerFormDirty) {
      this.setState({ showSavePrompt: true });
    } else {
      this.setState({ value: newValue, selectedpucid: undefined });
    }
  };

  handleCloseSavePrompt = () => {
    this.setState({ showSavePrompt: false });
  };

  handleFormDirty = (dirty: boolean) => {
    this.setState({ producerFormDirty: dirty });
  };

  render() {
    const { classes } = this.state;

    return (
      <div className={classes.root}>
        {this.state.showSavePrompt && (
          <DialogInformation
            isOpen
            showinput={false}
            handleOK={this.handleCloseSavePrompt}
            handleClose={this.handleCloseSavePrompt}
            title={"Unsaved changes"}
            body={"Please save your changes before continuing"}
          />
        )}
        <Tabs
          style={{ minWidth: "150px" }}
          orientation="vertical"
          variant="scrollable"
          value={this.state.value}
          onChange={this.handleChangeTab}
          aria-label="Vertical tabs example"
          className={classes.tabs}
        >
          <Tab label="Producer" className={classes.tabsLabel} />
          <Tab label="PUC/Orchards" className={classes.tabsLabel} />
          <Tab label="Packhouse" className={classes.tabsLabel} />
          <Tab label="Finance" className={classes.tabsLabel} />
          <Tab label="Contracts" className={classes.tabsLabel} />
          <Tab label="Treatment" className={classes.tabsLabel} />
        </Tabs>
        <TabPanel value={this.state.value} index={SelectedTab.Producer}>
          <div className={classes.tabWidth}>
            <ProducerEdit classes={classes} item={this.state.item} onClose={this.state.onClose} onSubmit={this.state.onSubmit} handleFormDirty={this.handleFormDirty} />
          </div>
        </TabPanel>
        <TabPanel value={this.state.value} index={SelectedTab.PUC}>
          <div className={classes.tabWidth}>
            <PUCS producerid={Number(this.state.item.id)} handleRefresh={this.handleUpdateProducer} />
          </div>
        </TabPanel>
        <TabPanel value={this.state.value} index={SelectedTab.Packhouse}>
          <div className={classes.tabWidth}>
            <Packhouse producerid={Number(this.state.item.id)} handleRefresh={this.handleUpdateProducer} />
          </div>
        </TabPanel>
        <TabPanel value={this.state.value} index={SelectedTab.Finance}>
          <div className={classes.tabWidth}>
            <ProducerFinance classes={classes} producerid={Number(this.state.item.id)} handleRefresh={this.handleUpdateProducer} handleChangeFinanceDirty={this.handleFormDirty} />
          </div>
        </TabPanel>
        <TabPanel value={this.state.value} index={SelectedTab.Contracts}>
          <div className={classes.tabWidth}>
            <AdvanceContract classes={classes} producerid={Number(this.state.id)} handleUpdateProducer={this.handleUpdateProducer} />
          </div>
        </TabPanel>
        <TabPanel value={this.state.value} index={SelectedTab.Treatment}>
          <div className={classes.tabWidth}>
            <ProducerPH classes={classes} producerid={Number(this.state.item.id)} handleRefresh={this.handleUpdateProducer} />
          </div>
        </TabPanel>
      </div>
    );
  }
}

export default withStyles(styles)(ProducersEditFormUnstyled);

type ProducerEditProps = {
  onSubmit(item: ProducerType): void;
  onClose(): void;
  item: Partial<ProducerType>;
  handleFormDirty(dirty: boolean): void;
} & WithStyles<typeof styles>;

const ProducerEdit: FC<ProducerEditProps> = ({ classes, onSubmit, onClose, item, handleFormDirty }) => {
  return (
    <Form
      initialValues={item}
      onSubmit={onSubmit}
      validate={(values) => {
        let errors = {};
        if (values["producer_telephone"] && values["producer_telephone"].length > 0 && !/^(\(?\+?\d{1,2}\)?)?\s?(\d\d|0)[0-9]{8}/g.test(values["producer_telephone"])) {
          errors = { ...errors, producer_telephone: "not a valid number" };
        }
        if (values["producer_mobile"] && values["producer_mobile"].length > 0 && !/^(\(?\+?\d{1,2}\)?)?\s?(\d\d|0)[0-9]{8}/g.test(values["producer_mobile"])) {
          errors = { ...errors, producer_mobile: "not a valid number" };
        }
        if (values["sales_phone"] && values["sales_phone"].length > 0 && !/^(\(?\+?\d{1,2}\)?)?\s?(\d\d|0)[0-9]{8}/g.test(values["sales_phone"])) {
          errors = { ...errors, sales_phone: "not a valid number" };
        }
        if (values["logistics_phone"] && values["logistics_phone"].length > 0 && !/^(\(?\+?\d{1,2}\)?)?\s?(\d\d|0)[0-9]{8}/g.test(values["logistics_phone"])) {
          errors = { ...errors, sales_phone: "not a valid number" };
        }
        if (values["quality_phone"] && values["quality_phone"].length > 0 && !/^(\(?\+?\d{1,2}\)?)?\s?(\d\d|0)[0-9]{8}/g.test(values["quality_phone"])) {
          errors = { ...errors, quality_phone: "not a valid number" };
        }
        if (values["finance_phone"] && values["finance_phone"].length > 0 && !/^(\(?\+?\d{1,2}\)?)?\s?(\d\d|0)[0-9]{8}/g.test(values["finance_phone"])) {
          errors = { ...errors, finance_phone: "not a valid number" };
        }
        if (values["sales_email"] && values["sales_email"].length > 0 && !/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g.test(values["sales_email"])) {
          errors = { ...errors, sales_email: "not a valid email" };
        }
        if (values["logistics_email"] && values["logistics_email"].length > 0 && !/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g.test(values["logistics_email"])) {
          errors = { ...errors, sales_email: "not a valid email" };
        }
        if (values["quality_email"] && values["quality_email"].length > 0 && !/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g.test(values["quality_email"])) {
          errors = { ...errors, quality_email: "not a valid email" };
        }
        if (values["finance_email"] && values["finance_email"].length > 0 && !/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g.test(values["finance_email"])) {
          errors = { ...errors, finance_email: "not a valid email" };
        }
        return errors;
      }}
      render={({ handleSubmit, form }) => (
        <form onSubmit={handleSubmit} style={{ position: "relative" }}>
          <DialogInformation isOpen={false} showinput={false} handleOK={null} handleClose={null} title={"Unsaved changes"} body={"Please save your changes before continuing"} />
          <div style={{ display: "flex", justifyContent: "flex-end", width: "100%", alignItems: "baseline", gap: "10px" }}>
            {item.last_updated_user && (
              <p>
                Last updated by <strong style={{ textTransform: "capitalize" }}>{item.last_updated_user}</strong> on{" "}
                <strong>{format(new Date(item.updated_at.toString().replaceAll("Z", "")), "dd MMM yyyy HH:mm")}</strong>
              </p>
            )}
            <Button color="secondary" variant="outlined" onClick={onClose}>
              Cancel
            </Button>
            <Button disabled={!form.getState().dirty} type="submit" color="primary" variant="outlined">
              Save
            </Button>
          </div>
          <FormDetail classes={classes} form={form} handleFormDirty={handleFormDirty} />
        </form>
      )}
    />
  );
};

type FormDetailProps = {
  form: FormApi;
  handleFormDirty(dirty: boolean): void;
} & WithStyles<typeof styles>;

const FormDetail: FC<FormDetailProps> = ({ classes, form, handleFormDirty }) => {
  useEffect(() => {
    handleFormDirty(form.getState().dirty);
  }, [form.getState().dirty]);

  return (
    <table style={{ width: "70%" }}>
      <tbody>
        <FormCheckbox classes={classes} field="active" label="Active" />
        <FormTextField classes={classes} field="code" label="Producer Code" required />
        <FormTextField classes={classes} field="name" label="Producer Name" required />
        <FormTextField classes={classes} field="reg_no" label="Company Reg No" required />
        <FormTextField classes={classes} field="vat_no" label="Vat No" />
        <FormTextField classes={classes} field="physical_address" label="Physical Address" multiLine={4} />
        <FormTextField classes={classes} field="postal_address" label="Postal Address" multiLine={4} />
        <FormTextField classes={classes} field="producer_telephone" label="Telephone Number" />
        <FormTextField classes={classes} field="producer_mobile" label="Cell Number" />
        <FormTextField classes={classes} field="sales_person" label="Sales Person" paddingTop />
        <FormTextField classes={classes} field="sales_phone" label="Sales Contact No" />
        <FormTextField classes={classes} field="sales_email" label="Sales e-mail" />
        <FormTextField classes={classes} field="logistics_person" label="Logistics Person" paddingTop />
        <FormTextField classes={classes} field="logistics_phone" label="Logistics Contact No" />
        <FormTextField classes={classes} field="logistics_email" label="Logistics e-mail" />
        <FormTextField classes={classes} field="quality_person" label="Quality Person" paddingTop />
        <FormTextField classes={classes} field="quality_phone" label="Quality Contact No" />
        <FormTextField classes={classes} field="quality_email" label="Quality e-mail" />
        <FormTextField classes={classes} field="finance_person" label="Finance Person" paddingTop />
        <FormTextField classes={classes} field="finance_phone" label="Finance Contact No" />
        <FormTextField classes={classes} field="finance_email" label="Finance e-mail" />
        <FormCheckbox classes={classes} field="solasmethod2" label="Solas Method 2" paddingTop />
        <FormTextField classes={classes} field="vat_no" label="Vat No" />
        <FormTextField classes={classes} field="fax_no" label="Fax No" />
      </tbody>
    </table>
  );
};

type FormTextFieldProps = {
  label: string;
  field: string;
  required?: boolean;
  multiLine?: number;
  paddingTop?: boolean;
} & WithStyles<typeof styles>;

const FormTextField: FC<FormTextFieldProps> = ({ classes, label, field, required = false, multiLine = 0, paddingTop = false }) => {
  const tableRowFieldTitle = paddingTop ? classes.fieldTitlePaddingTop : classes.tableRowFieldTitle;
  const tableRowFieldData = paddingTop ? classes.fieldDataPaddingTop : classes.tableRowFieldData;
  return (
    <tr>
      <td className={tableRowFieldTitle}>
        <span>{label}:</span>
      </td>
      <td className={tableRowFieldData}>
        <Field
          fullWidth
          name={field}
          rows={multiLine}
          required={required}
          rowsMax={multiLine}
          component={TextField}
          multiline={!!multiLine}
          key={`body_value_${field}`}
          className={classes.tableRowFieldInput}
        />
      </td>
    </tr>
  );
};

type FormCheckboxProps = {
  label: string;
  field: string;
  paddingTop?: boolean;
} & WithStyles<typeof styles>;

const FormCheckbox: FC<FormCheckboxProps> = ({ classes, label, field, paddingTop = false }) => {
  const tableRowFieldTitle = paddingTop ? classes.fieldTitlePaddingTop : classes.tableRowFieldTitle;
  return (
    <tr>
      <td className={tableRowFieldTitle}>
        <span>{label}:</span>
      </td>
      <td>
        <Field name={field} component={Checkbox} type="checkbox" style={{ padding: 0, paddingTop: paddingTop ? "50px" : "5px" }} />
      </td>
    </tr>
  );
};
