import React from "react";

import CustomAutoCompleteFilter from "../../../lib/components/customdatagridfilter/CustomFilter";

import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import FileCopyIcon from "@material-ui/icons/FileCopy";

import numeral from "numeral";
import { CellActionsFormatter } from "../../../lib/helpers/CellActionsFormatter";
import { handleColumnsArrangement, handleColumnsWidth } from "../../../lib/helpers/grid";

const cellWidthAction = 120;
const cellWidthMedium = 100;
const cellWidthLarge = 170;
const cellWidthHuge = 400;
const cellWidthXtraSmall = 70;

const defaultColumnProperties = {
  filterable: true,
  resizable: true,
  sortable: true,
  editable: true,
};

let currentSpace = true;

const NumberFormatter = ({ row, column }) => {
  const data = row[column.key];
  return <div style={{ textAlign: "right" }}>{numeral(data).format("0,0.00")}</div>;
};

export const GridColumns = (data, filters, arrangement, columnsWidth, handleRemove, handleEdit, handleCopy) => {
  let postValue = "";

  if (data) {
    if (currentSpace) {
      postValue = " ";
    }
    currentSpace = !currentSpace;
  }

  const columns = [
    {
      key: "actions",
      name: "Actions",
      width: cellWidthAction,
      formatter: ({ row }) => {
        const actions = cellActions(row, handleRemove, handleEdit, handleCopy);
        return (
          <div style={{ display: "flex", justifyContent: "center", gap: "5px" }}>
            <CellActionsFormatter actions={actions} />
          </div>
        );
      },
    },
    {
      key: "creditorcode",
      name: "Creditor" + postValue,
      width: cellWidthLarge,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"creditorcode"} data={data} section="creditorstemplatesummary" />;
      },
    },
    {
      key: "templatedesc",
      name: "Template Description" + postValue,
      width: cellWidthHuge,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"templatedesc"} data={data} section="creditorstemplatesummary" />;
      },
    },
    {
      key: "total",
      name: "Total" + postValue,
      width: cellWidthMedium,
      formatter: NumberFormatter,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"total"} data={data} section="creditorstemplatesummary" />;
      },
    },
  ].map((c) => ({ ...c, ...defaultColumnProperties }));

  let columnsArranged = [];
  columnsArranged = handleColumnsArrangement(columnsArranged, arrangement, columns);
  columnsArranged = handleColumnsWidth(columnsArranged, columnsWidth, columns);

  return columnsArranged;
};

const cellActions = (row, handleRemove, handleEdit, handleCopy) => {
  return [
    {
      icon: (
        <span title="Copy">
          <FileCopyIcon style={{ cursor: "pointer", color: "#4a9de2" }} />
        </span>
      ),
      callback: (e) => {
        handleCopy(row.id);
      },
    },
    {
      icon: (
        <span title="Edit">
          <EditIcon style={{ cursor: "pointer", color: "orange" }} />
        </span>
      ),
      callback: (e) => {
        handleEdit(row.id);
      },
    },
    {
      icon: (
        <span title="Remove">
          <DeleteIcon style={{ cursor: "pointer", color: "red" }} />
        </span>
      ),
      callback: (e) => {
        handleRemove(row.id);
      },
    },
  ];
};
