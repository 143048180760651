import React, { useState, useEffect, FC, useContext, useMemo } from "react";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";

import { InvoicesForCombinedType, getInvoicesForCombined, getSaleHeaderForCombinedInvoices, saleByDispatchId } from "../../lib/api/sale";
import { SnackContext } from "../../lib/context/SnackContext";
import { GenerateErrorMessage } from "../../lib/helpers/string_methods";
import CombinedInvoicesGrid from "./grid";

import Select from "@material-ui/core/Select";

import { groupBy } from "lodash";

import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";
import { DocumentsButton, DocumentsType } from "../../lib/components/DocumentsButton";
import { getPayments, groupDataByFruitSpec } from "../../reports/printing";
import { Reports } from "../../lib/types";
import { companyDetails } from "../../lib/api/companydetails";
import { getCompanyBankingDetailById } from "../../lib/api/companydetailsbanking";
import { printCombinedInvoice } from "../../reports/combinedinvoice";
import { LinearProgress } from "@material-ui/core";

const styles = (theme: Theme) =>
  createStyles({
    container: {
      width: "800px",
      display: "flex",
      flexDirection: "column",
      gap: "1rem",
    },
    selectContainer: {
      display: "flex",
      flexDirection: "row",
      gap: "1rem",
    },
    toolbarWrapper: {
      position: "relative",
    },
    toolbar: {
      position: "absolute",
      left: 0,
      top: 0,
      height: "60px",
      width: "100%",
      display: "flex",
      flexDirection: "row",
      gap: "10px",
    },
  });

export enum ReportType {
  CLIENT = "CLIENT",
  CONSIGNEE_INVOICE = "COMMERCIAL_INVOICE_CONSIGNEE",
  CLIENT_AND_CONSIGNEE = "CLIENT_AND_CONSIGNEE",
}

const filterFields = ["client", "vessel", "pod", "final_destination", "payment_terms"];

type CombinedInvoiceProps = {} & WithStyles<typeof styles>;

const CombinedInvoiceUnstyled: FC<CombinedInvoiceProps> = ({ classes }) => {
  const { updateSnack } = useContext(SnackContext);

  const [loading, setLoading] = useState(true);
  const [printLoading, setPrintLoading] = useState(false);
  const [invoices, setInvoices] = useState<InvoicesForCombinedType[]>([]);

  const [selected, setSelected] = useState({ final_destination: "", pod: "", payment_terms: "", client: "", vessel: "" });
  const [selectedInvoices, setSelectedInvoices] = useState<string[]>([]);

  const loadData = async () => {
    setLoading(true);
    try {
      const data = await getInvoicesForCombined();
      setInvoices(data);
    } catch (error) {
      const err = GenerateErrorMessage(error, "Error getting invoices");
      updateSnack({ show: true, color: "red", message: err });
    }
    setLoading(false);
  };

  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    setSelectedInvoices([]);
  }, [selected]);

  const handleChangeSelected = (value: any, field: string) => {
    const fIndx = filterFields.findIndex((val) => val === field);
    const fields = filterFields.reduce((obj, val, i) => ({ ...obj, [val]: i > fIndx ? undefined : val === field ? value : selected[val] }), {
      client: selected["client"],
      vessel: selected["vessel"],
      pod: selected["pod"],
      final_destination: selected["final_destination"],
      payment_terms: selected["payment_terms"],
    });
    setSelected(fields);
  };

  const handleSelectInvoice = (invoicenumber: string) => {
    if (!selectedInvoices.includes(invoicenumber)) {
      setSelectedInvoices([...selectedInvoices, invoicenumber]);
    } else {
      setSelectedInvoices(selectedInvoices.filter((inv) => inv !== invoicenumber));
    }
  };

  const handleSelectAllInvoices = () => {
    setSelectedInvoices(selectedInvoices.length > 0 ? [] : filteredInvoices.map((item) => item.sale_invoicenumber));
  };

  const formattedGroupBy = (data: any[], key: string) =>
    Object.keys(groupBy(data, key) || {})
      .map((k) => ({ value: k, display: k }))
      .sort((a, b) => a.display.localeCompare(b.display));

  const clientList = useMemo(() => {
    return formattedGroupBy(invoices, "client");
  }, [invoices]);

  const vesselList = useMemo(() => {
    const filteredInvoices = invoices.filter((item) => item.client == selected.client);
    return formattedGroupBy(filteredInvoices, "vessel");
  }, [invoices, selected.client]);

  const podList = useMemo(() => {
    const filteredInvoices = invoices.filter((item) => item.client == selected.client && item.vessel == selected.vessel);
    return formattedGroupBy(filteredInvoices, "pod");
  }, [invoices, selected.client, selected.vessel]);

  const finalDestinationsList = useMemo(() => {
    const filteredInvoices = invoices.filter((item) => item.client == selected.client && item.vessel == selected.vessel && item.pod == selected.pod);
    return formattedGroupBy(filteredInvoices, "final_destination");
  }, [invoices, selected.client, selected.vessel, selected.pod]);

  const paymentTermsList = useMemo(() => {
    const filteredInvoices = invoices.filter((item) => item.client == selected.client && item.final_destination == selected.final_destination && item.pod == selected.pod);
    return formattedGroupBy(filteredInvoices, "payment_terms");
  }, [invoices, selected.client, selected.pod, selected.final_destination]);

  const filteredInvoices = useMemo(() => {
    return (invoices || [])
      .filter(
        (item) =>
          item.client == selected.client &&
          item.vessel == (selected.vessel ? selected.vessel : item.vessel) &&
          item.pod == (selected.pod ? selected.pod : item.pod) &&
          item.final_destination == (selected.final_destination ? selected.final_destination : item.final_destination) &&
          item.payment_terms == (selected.payment_terms ? selected.payment_terms : item.payment_terms),
      )
      .sort((a, b) => a.sale_invoicenumber.localeCompare(b.sale_invoicenumber));
  }, [invoices, selected]);

  const handlePrintPDF = async (reportType: string) => {
    setPrintLoading(true);
    try {
      const saleHeader = await getSaleHeaderForCombinedInvoices(selectedInvoices);
      const dispatchIds = saleHeader.map((sale) => sale.dispatch_id).join(",");

      const { sale_id, currency, statement_account_id, dispatch_id } = saleHeader[0];
      const containerInfo = { containerNo: saleHeader.map((h) => h.containerno), sealNumber: saleHeader.map((h) => h.sealnumber) };

      if (!sale_id) {
        throw { data: "No Sale record linked" };
      }

      if (!dispatch_id) {
        throw { data: "No Dispatch record linked" };
      }

      const [companyDetailsResult, bankResult, saleTerms, rows] = await Promise.all([
        companyDetails.all(),
        getCompanyBankingDetailById(statement_account_id),
        getPayments(Reports.Invoice, currency, sale_id, false),
        saleByDispatchId(dispatchIds),
      ]);

      if ((bankResult.data || []).length === 0) {
        throw { data: "Banking detail not found" };
      }

      const bank = (bankResult.data || []).length > 0 ? bankResult.data[0] : undefined;
      const company = !companyDetailsResult ? undefined : companyDetailsResult[0];
      const groupedRows = groupDataByFruitSpec(rows);
      const invoiceNumbersSelected: string[] = saleHeader.reduce((acc, sale, idx) => {
        if (idx !== 0) {
          acc.push(sale.invoicenumber.slice(-2));
        }
        return acc;
      }, []);

      printCombinedInvoice({ details: groupedRows, companyDetails: { bank, company }, header: saleHeader[0], containerInfo, reportType, invoiceNumbersSelected }, saleTerms);
    } catch (error) {
      const err = GenerateErrorMessage(error, "Error printing Combined Invoice");
      updateSnack({ show: true, message: err, color: "red" });
    }
    setPrintLoading(false);
  };

  const handlePrintExcel = (reportType: ReportType) => {
    window.location.href = `/api/sale/ext/exportCombinedInvoice/${selectedInvoices.join(",")}/${reportType}`;
  };

  const menuItems = [
    {
      icon: DocumentsType.PDF,
      title: "CLIENT",
      options: [
        {
          title: "PDF",
          icon: DocumentsType.PDF,
          action: () => handlePrintPDF(ReportType.CLIENT),
        },
        {
          title: "EXCEL",
          icon: DocumentsType.EXCEL,
          action: () => handlePrintExcel(ReportType.CLIENT),
        },
      ],
    },
    {
      icon: DocumentsType.PDF,
      title: "CONSIGNEE",
      options: [
        {
          title: "PDF",
          icon: DocumentsType.PDF,
          action: () => handlePrintPDF(ReportType.CONSIGNEE_INVOICE),
        },
        {
          title: "EXCEL",
          icon: DocumentsType.EXCEL,
          action: () => handlePrintExcel(ReportType.CONSIGNEE_INVOICE),
        },
      ],
    },
    {
      icon: DocumentsType.PDF,
      title: "CLIENT & CONSIGNEE",
      options: [
        {
          title: "PDF",
          icon: DocumentsType.PDF,
          action: () => handlePrintPDF(ReportType.CLIENT_AND_CONSIGNEE),
        },
        {
          title: "EXCEL",
          icon: DocumentsType.EXCEL,
          action: () => handlePrintExcel(ReportType.CLIENT_AND_CONSIGNEE),
        },
      ],
    },
  ];

  return (
    <div className={classes.container}>
      {printLoading ? <LinearProgress color="secondary" /> : <div style={{ height: "5px" }}></div>}
      <div className={classes.selectContainer}>
        <SelectField title="Client" value={selected.client} data={clientList} handleChange={handleChangeSelected} disabled={clientList.length == 0} />
        <SelectField title="Vessel" value={selected.vessel} data={vesselList} handleChange={handleChangeSelected} disabled={vesselList.length === 0 || selected.client == ""} />
        <SelectField
          title="POD"
          value={selected.pod}
          data={podList}
          handleChange={handleChangeSelected}
          disabled={podList.length == 0 || selected.client == "" || selected.vessel == ""}
        />
        <SelectField
          title="Final Destination"
          value={selected.final_destination}
          data={finalDestinationsList}
          handleChange={handleChangeSelected}
          disabled={finalDestinationsList.length === 0 || selected.client == "" || selected.vessel == "" || selected.pod == ""}
        />
        <SelectField
          title="Payment Terms"
          value={selected.payment_terms}
          data={paymentTermsList}
          handleChange={handleChangeSelected}
          disabled={paymentTermsList.length == 0 || selected.client == "" || selected.final_destination == "" || selected.pod == ""}
        />
      </div>
      <div className={classes.toolbarWrapper}>
        <Toolbar className={classes.toolbar}>
          <Button color="secondary" variant="contained" onClick={handleSelectAllInvoices} disabled={filteredInvoices.length === 0}>
            {selectedInvoices.length > 0 ? "Deselect All" : "Select All"}
          </Button>
          <DocumentsButton menuItems={menuItems} disabled={selectedInvoices.length === 0} />
        </Toolbar>
        <CombinedInvoicesGrid data={filteredInvoices} loading={loading} selectedInvoices={selectedInvoices} setSelectedInvoices={handleSelectInvoice} />
      </div>
    </div>
  );
};

export const CombinedInvoice = withStyles(styles)(CombinedInvoiceUnstyled);

type SelectFieldProps = {
  value: string;
  title: string;
  disabled: boolean;
  data: { value: any; display: string }[];
  handleChange(value: any, field: string): void;
};

const SelectField: FC<SelectFieldProps> = ({ title, value, data, disabled, handleChange }) => {
  return (
    <FormControl style={{ width: "200px" }}>
      <InputLabel>{title}</InputLabel>
      <Select
        fullWidth
        value={value}
        disabled={disabled}
        onChange={(event: React.ChangeEvent<{ value: unknown }>) => handleChange(event.target.value, title.toString().toLowerCase().split(" ").join("_"))}
      >
        {(data || []).map((item) => (
          <MenuItem value={item.value}>{item.display}</MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
