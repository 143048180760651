import React from "react";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";
import Grid from "../../../lib/components/grid";
import { GridColumns } from "./gridsetup";
import { producerFixedInvoiceSummary } from "../../../lib/api/producersale";
import { getFinancialYearSelected } from "../../../lib/api/week";
import { Row } from "react-data-grid";

const styles = (theme: Theme) =>
  createStyles({
    root: {},
  });

type Props = { history: any } & WithStyles<typeof styles>;

class FixedProducerInvoiceSummaryUnstyled extends React.Component<Props, {}> {
  constructor(props) {
    super(props);
  }

  state = {
    loading: true,
    data: [],
  };

  componentDidMount() {
    this.loadData();
  }

  loadData = async () => {
    this.setState({ loading: true });
    const resp = await producerFixedInvoiceSummary(getFinancialYearSelected());
    this.setState({ loading: false, data: resp });
  };

  handleSelectProducer = (producercode: string) => {
    this.props.history.push(`/producers/invoice/${producercode}`);
  };

  rowRenderer = (props) => {
    const { row } = props;
    return <Row {...props} onDoubleClick={() => this.handleSelectProducer(row.producer_code)} />;
  };

  render() {
    return (
      <div>
        <Grid
          loading={this.state.loading}
          clearFilters="fixedproducerinvoicesummary"
          data={this.state.data}
          GridColumns={GridColumns}
          rowRenderer={this.rowRenderer}
          totalRowColumns={["totalNoCartons", "totalSales", "totalOutstanding"]}
        />
      </div>
    );
  }
}

export default withStyles(styles)(FixedProducerInvoiceSummaryUnstyled);
