import React, { useEffect, useState, useContext, useMemo } from "react";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";

import LinearProgress from "@material-ui/core/LinearProgress";
import Toolbar from "@material-ui/core/Toolbar";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";

import { SnackContext } from "../../../lib/context/SnackContext";
import Confirmation from "../../../lib/components/confirmation";
import { getSaleFundsFull, SaleFundsFullType } from "../../../lib/api/salefunds";
import { AllocationList, allocateSaleFunds, getSaleFundsAllocationBySaleFundsId } from "../../../lib/api/salefundsallocation";
import { getDebtorsFullOustandingByClientsId, DebtorsFullType } from "../../../lib/api/debtorsfull";
import { GenerateErrorMessage } from "../../../lib/helpers/string_methods";
import { getFinancialYearSelected } from "../../../lib/api/week";
import { GreenButton } from "../../../lib/components/ColorButtons";
import { getOverpaidSalesMappingBySaleIds } from "../../../lib/api/salefundsoverpaid";
import { DialogInformation } from "../../../lib/components/dialoginformation";

import AllocationTable from "./allocationtable";
import { FundsForm } from "./fundsform";
import SaleAllocationsTable from "./saleallocations/saleallocationstable";
import AllocationsGrid from "./allocationsgrid/index";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1),
      position: "relative",
      height: "100%",
    },
    container: {
      display: "grid",
      gridTemplateColumns: "repeat(1, 150px 350px)",
      gridTemplateRows: "repeat(8, 40px)",
      gridTemplateAreas: `
      "details"
      `,
      gridGap: "5px",
    },
    tableCellLabel: {
      width: "150px",
      borderBottom: "none",
      height: "50px",
      textAlign: "right",
      paddingTop: "5px",
    },
    tableCellDetail: {
      width: "350px",
      borderBottom: "none",
      height: "50px",
    },
    tableContainer: {},
    formContainer: {
      position: "relative",
      height: "100%",
      display: "grid",
      gridTemplateRows: "min-content 1fr",
      overflow: "hidden",
    },
  });

const formatRow = (row: DebtorsFullType, payment: number) => ({
  ...row,
  // salefunds_id: (row.salefunds_id || "").split(",").map((id) => +id),
  groupedTotal: 0,
  header: false,
  total_allocated: "",
  paymentnumber: payment,
  date_overpaid: new Date(),
  paymentcolumn: `PMT ${payment}`,
  amountDebit: row[`amount${payment}Debit`],
  amountCredit: row[`amount${payment}Credit`],
  amountRemaining: row[`remaining${payment}`],
  sale_total: row[`sale_payment${payment}amount`],
  amountReceived: row[`terms_received${payment}`],
  portdistcharge_code_special: row[`sale_payment${payment}date`],
  total_outstandingwithadjustments: row[`sale_payment${payment}amount_remain`],
});

const headerRow = (row: DebtorsFullType, maxPaymentNumber: number, dueDates: string[]) => ({
  sale_invoicenumber: row.sale_invoicenumber,
  sale_total: "DUE",
  amountDebit: "",
  amountCredit: "",
  header: true,
  groupedTotal: row.sale_total,
  groupedDebit: row.amountDebit,
  groupedCredit: row.amountCredit,
  groupedTotalOutstanding: row.total_outstandingwithadjustments,
  groupedAllocated: row.total_allocated,
  groupedOverpaid: row.overpaid,
  total_outstandingwithadjustments: 0,
  // Expected result: Keep the header row visible on the grid
  // Solution: Populate the column with all possible values seperated by a comma
  //            our filter will detect the row based on the column value
  paymentcolumn: new Array(maxPaymentNumber)
    .fill(1)
    .map((_, indx) => `PMT ${indx + 1}`)
    .join(", "),
  portdistcharge_code_special: dueDates.join(", "),
});

const getIdsFromSearch = (history): number[] => {
  try {
    const [_, saleIdStr] = history.location.search.split("=");
    const sale_ids = saleIdStr.split(",");
    return sale_ids.map((id: string) => parseInt(id));
  } catch (error) {
    return [];
  }
};

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && (
        <Box p={3}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
};

const a11yProps = (index: any) => {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
};

type AdjustmentProps = {
  history: any;
  selectedRecord: SaleFundsFullType;
  handleEditAllocation: (selectedRecord: any) => void;
  handleProcessSaleFunds: (selectedRecord: any) => Promise<void>;
} & WithStyles<typeof styles>;

const AdjustmentUnstyled: React.FunctionComponent<AdjustmentProps> = (props) => {
  const { classes, selectedRecord, handleProcessSaleFunds, history } = props;

  const { updateSnack } = useContext(SnackContext);

  const [loading, setLoading] = useState(true);
  const [refresh, setRefresh] = useState(true);
  const [outStandingDetail, setOutStandingDetail] = useState<DebtorsFullType[]>([]);
  const [record, setRecord] = useState<SaleFundsFullType>({ ...selectedRecord, available: 0 });
  const [allocationList, setAllocationList] = useState<AllocationList[]>([]);
  const [groupedInvoiceDetail, setGroupedInvoiceDetail] = useState({});
  const [confirmApplyAvail, toggleConfirmApplyAvail] = useState<boolean>(false);
  const [overpaidSalesMapping, setOverpaidSalesMapping] = useState<any[]>([]);
  const [tabValue, setTabValue] = useState(0);
  const [selectedForAlloc, setSelectedForAlloc] = useState<AllocationList[]>([]);
  const [viewSaleAllocations, toggleViewSaleAllocations] = useState<{ sale_id: number; paymentnumber: number }>(undefined);
  const [availableFunds, setAvailableFunds] = useState<number>(record.available);
  const [formDirtyFields, setFormDirtyFields] = useState<{ [index: string]: boolean }>(undefined);
  const [showFormDirtyWarning, setShowFormDirtyWarning] = useState(false);

  const isOverpaidFund = useMemo(() => {
    const sale_ids = getIdsFromSearch(history);
    return sale_ids.length > 0;
  }, [history]);

  useEffect(() => {
    loadData();
  }, [selectedRecord, refresh]);

  const loadData = async () => {
    setLoading(true);

    try {
      if (selectedRecord.id) {
        const sale_ids = getIdsFromSearch(history);

        const [result, salefund, overpaidSales] = await Promise.all([
          getSaleFundsAllocationBySaleFundsId(selectedRecord.id),
          getSaleFundsFull(selectedRecord.id),
          getOverpaidSalesMappingBySaleIds(sale_ids),
        ]);

        const saleFundResult: SaleFundsFullType = salefund.data[0];
        const resultOutstanding: DebtorsFullType[] = await getDebtorsFullOustandingByClientsId(saleFundResult.clients_id, getFinancialYearSelected());

        const { groupedInvoiceDetail, outstandingDetail } = resultOutstanding.reduce(
          (obj, row) => {
            let maxPaymentNumber = 0; // find the max payment number to populate the paymentcolumn
            const dueDates = []; // populate all possible due dates for each invoice (shipment)

            obj.groupedInvoiceDetail[row.sale_invoicenumber] = { ...row, amountReceived: row.total_allocated };

            const isOnePaymentTerm = row.sale_terms_value1 == 100 && parseInt(row.sale_payment1ValueType) == 0;

            if (row.sale_terms_value1 || row.sale_payment1ValueType) {
              dueDates.push(row.sale_payment1date);
              maxPaymentNumber++;
              obj.outstandingDetail.push(formatRow(row, 1));
            }
            if (!isOnePaymentTerm && (row.sale_terms_value2 || row.sale_payment2ValueType)) {
              dueDates.push(row.sale_payment2date);
              maxPaymentNumber++;
              obj.outstandingDetail.push(formatRow(row, 2));
            }
            if (!isOnePaymentTerm && (row.sale_terms_value3 || row.sale_payment3ValueType)) {
              dueDates.push(row.sale_payment3date);
              maxPaymentNumber++;
              obj.outstandingDetail.push(formatRow(row, 3));
            }
            obj.outstandingDetail.push(headerRow(row, maxPaymentNumber, dueDates));
            return obj;
          },
          { outstandingDetail: [], groupedInvoiceDetail: {} },
        );

        const sortedOutstandingDetail = outstandingDetail.sort((a, b) => {
          if (a.header && !b.header) {
            return -1; // Put headers before data
          } else if (!a.header && b.header) {
            return 1; // Put data after headers
          } else {
            return 0; // Maintain the relative order of headers and data
          }
        });

        const selectedAllocations: AllocationList[] = result.data.reduce((arr, item) => {
          if ((sale_ids.length > 0 && (!item.is_overpaid || !sale_ids.includes(item.overpaid_sale_id))) || (sale_ids.length <= 0 && item.is_overpaid)) return arr;

          const exists = arr.find((row) => row.sale_id == item.sale_id && row.paymentnumber == item.paymentnumber);
          if (!exists) {
            arr.push({ ...item, orig_amount: item.amount, amount_diff: 0, currIndx: 0 });
          } else {
            arr.push({ ...item, orig_amount: item.amount, amount_diff: 0, currIndx: exists.currIndx + 1 });
          }
          return arr;
        }, [] as AllocationList[]);

        const overpaidTotal = overpaidSales.reduce((a, b) => a + b.overpaid_amount, 0);
        setRecord({
          ...saleFundResult,
          totalamount: sale_ids.length > 0 ? overpaidTotal : saleFundResult.totalamount,
          amount: sale_ids.length > 0 ? overpaidTotal : saleFundResult.amount,
          available: sale_ids.length > 0 ? overpaidTotal : saleFundResult.available,
        });

        setSelectedForAlloc(selectedAllocations);
        setAllocationList(result.data);
        setGroupedInvoiceDetail(groupedInvoiceDetail);
        setOutStandingDetail(sortedOutstandingDetail);
        setOverpaidSalesMapping(overpaidSales);
      }
    } catch (error) {
      const err = GenerateErrorMessage(error, "Error loading data");
      updateSnack({ show: true, color: "red", message: err });
    }

    setLoading(false);
  };

  const applyAvailable = (item: DebtorsFullType & { salefunds_id: number[]; amountReceived: number }) => {
    try {
      const hasAllocations = selectedForAlloc.filter((selected) => selected.sale_id == item.sale_id && selected.paymentnumber == item.paymentnumber);
      const newSelectedRow = {
        amount: availableFunds > item.amountRemaining ? item.amountRemaining : availableFunds,
        amountCredit: item.amountCredit,
        amountDebit: item.amountDebit,
        amountReceived: item.amountReceived,
        invoicenumber: item.sale_invoicenumber,
        makeanote: "",
        paymentnumber: item.paymentnumber,
        posteddate: new Date(),
        sale_id: item.sale_id,
        salefunds_id: record.id,
        orig_amount: item.amountRemaining,
        amount_diff: 0,
        currIndx: 0,
        dirty: true,
        deleted: false,
      };

      // no allocations for this fund, add to the selected list
      if (hasAllocations.length == 0) {
        // Block user from allocating shipments when there are dirty form fields
        const dirtyFields = Object.keys(formDirtyFields);
        if (dirtyFields.length > 0) {
          setShowFormDirtyWarning(true);
          return;
        }

        if (availableFunds <= 0) {
          throw { data: "No available funds to allocate" };
        }
        setSelectedForAlloc([...selectedForAlloc, newSelectedRow]);
      } else {
        // seperate the different states the data can be in
        const { filteredClean, additionalSaleAllocations, origAllocation } = selectedForAlloc.reduce(
          ({ filteredClean, additionalSaleAllocations, origAllocation }, row) => {
            if (row.dirty && row.sale_id == item.sale_id && row.paymentnumber == item.paymentnumber) {
              additionalSaleAllocations.push(row);
              if (row.id) {
                origAllocation.push({ ...row, amount: row.orig_amount, amount_diff: 0, deleted: false, dirty: false });
              }
            } else {
              filteredClean.push(row);
            }
            return { filteredClean, additionalSaleAllocations, origAllocation };
          },
          {
            filteredClean: [],
            additionalSaleAllocations: [],
            origAllocation: [],
          },
        );

        // there are allocations but we want to allocate another
        if (additionalSaleAllocations.length == 0) {
          // Block user from allocating shipments when there are dirty form fields
          const dirtyFields = Object.keys(formDirtyFields);
          if (dirtyFields.length > 0) {
            setShowFormDirtyWarning(true);
            return;
          }

          if (availableFunds <= 0) {
            throw { data: "No available funds to allocate" };
          }
          setSelectedForAlloc([...selectedForAlloc, { ...newSelectedRow, currIndx: hasAllocations.at(-1).currIndx + 1 }]);
        } else {
          // remove the allocation from the list but keep the original allocations from db
          setSelectedForAlloc([...filteredClean, ...origAllocation]);
        }
      }
    } catch (error) {
      const err = GenerateErrorMessage(error, "Error allocation funds");
      updateSnack({ show: true, color: "orange", message: err });
    }
  };

  const applyOverpaidSales = (amountToAllocate: number, sale_id: number, paymentnumber: number, availableOverpaidSales: any[]): any[] => {
    const available = amountToAllocate,
      allocatedFunds = [],
      availableFunds = availableOverpaidSales.filter((item) => item.overpaid_amount > 0);

    for (let i = 0; i < availableFunds.length; i++) {
      const currentFund = availableFunds[i];

      if (currentFund.overpaid_amount <= amountToAllocate) {
        allocatedFunds.push({
          sale_id,
          total_amount: available,
          amount: currentFund.overpaid_amount,
          overpaid_sale_id: currentFund.id,
          paymentnumber: paymentnumber,
          salefunds_id: currentFund.salefunds_id,
          overpaid_id: currentFund.overpaid_id,
        });
        amountToAllocate -= currentFund.overpaid_amount;
        // make overpaid 0 because it has been used up
        currentFund.overpaid_amount = 0;
      } else {
        allocatedFunds.push({
          sale_id,
          total_amount: available,
          amount: amountToAllocate,
          overpaid_sale_id: currentFund.id,
          paymentnumber: paymentnumber,
          salefunds_id: currentFund.salefunds_id,
          overpaid_id: currentFund.overpaid_id,
        });
        currentFund.overpaid_amount -= amountToAllocate;
        break;
      }
    }

    return allocatedFunds;
  };

  const handleProcessAllocation = () => {
    toggleConfirmApplyAvail(true);
  };

  const handleConfirmApplyAvail = async (makePayment: boolean) => {
    if (!makePayment) {
      toggleConfirmApplyAvail(undefined);
      return;
    }

    try {
      if (selectedForAlloc.filter((item) => item.dirty).length == 0) throw { data: "No sales to allocate." };

      const { deletedAllocations, updateAllocations, newAllocations } = selectedForAlloc.reduce(
        ({ deletedAllocations, updateAllocations, newAllocations }, item) => {
          if (!item.dirty) return { deletedAllocations, updateAllocations, newAllocations };

          if (item.deleted) {
            deletedAllocations.push({ id: item.id, amount: item.orig_amount });
          } else if (item.id) {
            updateAllocations.push({
              id: item.id,
              salefunds_id: record.id,
              sale_id: item.sale_id,
              amount: item.amount,
              paymentnumber: item.paymentnumber,
              orig_amount: item.orig_amount,
            });
          } else {
            newAllocations.push({
              salefunds_id: record.id,
              sale_id: item.sale_id,
              amount: item.amount,
              paymentnumber: item.paymentnumber,
            });
          }
          return { deletedAllocations, updateAllocations, newAllocations };
        },
        {
          deletedAllocations: [],
          updateAllocations: [],
          newAllocations: [],
        },
      );

      const salesToAllocate = [];
      const allocations = [...updateAllocations, ...newAllocations];
      const deletedAmount = deletedAllocations.reduce((tot, row) => (tot += row.amount), 0);
      const updatedAmount = updateAllocations.reduce((tot, row) => (tot += row.orig_amount), 0);
      let available = record.available + deletedAmount + updatedAmount;

      let availableOverpaidSales = overpaidSalesMapping.reduce((arr, overpaid) => {
        const selected = selectedForAlloc.find((item) => item.is_overpaid && item.overpaid_id === overpaid.overpaid_id && item.currIndx == overpaid.currIndx);
        arr.push({ ...overpaid, overpaid_amount: selected ? selected.amount : overpaid.overpaid_amount });
        return arr;
      }, []);

      for (let i = 0; i < allocations.length; i++) {
        const currentSale = allocations[i];
        const { amount, sale_id, paymentnumber } = currentSale;

        // initiate overpaidAllocations
        currentSale.overpaidToAllocate = [];

        if (overpaidSalesMapping.length > 0) {
          // take the current sale amount and allocate overpaid sales
          const overpaidSalesList = applyOverpaidSales(amount, sale_id, paymentnumber, availableOverpaidSales);
          currentSale.overpaidToAllocate.push(...overpaidSalesList);
        }

        if (currentSale.amount <= available) {
          salesToAllocate.push(currentSale);
          available -= currentSale.amount;
        } else {
          salesToAllocate.push({ ...currentSale, amount: available });
          break;
        }
      }

      await allocateSaleFunds(salesToAllocate, deletedAllocations);
      setRefresh(!refresh);
      // setSelectedForAlloc([]);
    } catch (err) {
      const errorMessage = GenerateErrorMessage(err, "Cannot apply available.");
      updateSnack({ show: true, color: "red", message: errorMessage });
    }
    toggleConfirmApplyAvail(undefined);
  };

  const handleTabValueChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabValue(newValue);
  };

  const handleViewSaleAllocations = (row: DebtorsFullType) => {
    toggleViewSaleAllocations({ sale_id: row.sale_id, paymentnumber: row.paymentnumber });
  };

  const handleCloseViewSaleAllocation = () => {
    toggleViewSaleAllocations(undefined);
  };

  const handleConfirmFormWarning = () => {
    setShowFormDirtyWarning(false);
  };

  const handleRefresh = () => {
    setRefresh(!refresh);
  };

  const disableProcessButton = useMemo(() => !selectedForAlloc.find((selected) => selected.dirty), [selectedForAlloc]);

  return (
    <div id={"adjustmentfundsmain"} className={classes.root}>
      {confirmApplyAvail && (
        <Confirmation
          isOpen={true}
          handleClose={() => handleConfirmApplyAvail(false)}
          handleConfirm={() => handleConfirmApplyAvail(true)}
          title={"Confirm Payment"}
          body={<p>Are you sure you want to allocate the selected sales?</p>}
        />
      )}
      {showFormDirtyWarning && (
        <DialogInformation
          isOpen
          showinput={false}
          handleOK={handleConfirmFormWarning}
          handleClose={handleConfirmFormWarning}
          title="Unsaved Form values."
          body="Warning. You have unsaved form values. Please submit the form values before allocating shipments."
        />
      )}
      {viewSaleAllocations && (
        <Confirmation isOpen body={""} title={"Allocations"} handleClose={handleCloseViewSaleAllocation} handleConfirm={handleCloseViewSaleAllocation}>
          <SaleAllocationsTable
            saleAllocation={viewSaleAllocations}
            handleClose={handleCloseViewSaleAllocation}
            selectedForAlloc={selectedForAlloc}
            handleChangeSelectedForAlloc={setSelectedForAlloc}
          />
        </Confirmation>
      )}
      <div style={{ position: "relative", height: "100%" }}>
        {loading && <LinearProgress />}
        <div>
          <div>
            <FundsForm
              loadingDetail={loading}
              selectedRecord={record}
              isOverpaidFund={isOverpaidFund}
              allocationList={selectedForAlloc}
              selectedAllocationList={selectedForAlloc}
              setAvailableFunds={setAvailableFunds}
              handleProcess={handleProcessSaleFunds}
              setFormDirtyFields={setFormDirtyFields}
            />
          </div>
          {record.id > 0 ? (
            <div>
              <AppBar position="static">
                <Toolbar>
                  <Tabs value={tabValue} onChange={handleTabValueChange} aria-label="simple tabs example">
                    <Tab label="Shipments" {...a11yProps(0)} />
                    <Tab label="Allocations" {...a11yProps(1)} />
                  </Tabs>
                  <Box style={{ display: "flex", flex: 1, justifyContent: "flex-end", gap: "10px" }}>
                    <GreenButton onClick={handleProcessAllocation} variant="contained" style={{ margin: "2px" }} disabled={disableProcessButton}>
                      Process
                    </GreenButton>
                  </Box>
                </Toolbar>
              </AppBar>
              <TabPanel value={tabValue} index={0}>
                {loading ? (
                  <CircularProgress color="secondary" />
                ) : (
                  <AllocationTable
                    record={record}
                    loading={loading}
                    data={outStandingDetail}
                    applyAvailable={applyAvailable}
                    selectedForAlloc={selectedForAlloc}
                    groupedInvoiceDetail={groupedInvoiceDetail}
                    handleViewSaleAllocations={handleViewSaleAllocations}
                  />
                )}
              </TabPanel>
              <TabPanel value={tabValue} index={1}>
                <AllocationsGrid allocationList={allocationList} setAllocationList={setAllocationList} handleRefresh={handleRefresh} clients_code={selectedRecord.clients_code} />
              </TabPanel>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
};

export default withStyles(styles)(AdjustmentUnstyled);
