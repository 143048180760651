import { client, request } from "./_base";

const table = "clearingagent";
export type ClearingAgentType = {
  id: number;
  code: string;
  name: string;
  physical_address: string;
  postal_address: string;
  telephone: string;
  mobile: string;
  email: string;
  created_at: Date;
  updated_at: Date;
};

export const clearingagent = client(table, (item) => item);

export type ClearingAgentForConsigneeType = {
  id: number;
  code: string;
  name: string;
  physical_address: string;
  postal_address: string;
  telephone: string;
  mobile: string;
  email: string;
  created_at: Date;
  updated_at: Date;
  consigneeclearingagent_id?: number;
  consignees_codes: string;
  consignees_names: string;
};

export const allClearingAgentWithConsignee = async (): Promise<ClearingAgentForConsigneeType[]> => {
  const result = await request(`${table}/ext/allClearingAgentWithConsignee`, {
    method: "GET",
  });
  const resp = await result.json();
  if (result.status == 500) {
    throw resp;
  }
  return resp.data;
};

export const ClearingAgentForConsignee = async (consigneeId: number): Promise<ClearingAgentForConsigneeType[]> => {
  const result = await request(`${table}/ext/forConsignee/${consigneeId}`, {
    method: "GET",
  });
  const resp = await result.json();
  if (result.status == 500) {
    throw resp;
  }
  return resp.data;
};

export const AvailableToConsignee = async (consigneeId: number): Promise<ClearingAgentForConsigneeType[]> => {
  const result = await request(`${table}/ext/availableToConsignee/${consigneeId}`, {
    method: "GET",
  });
  const resp = await result.json();
  if (result.status == 500) {
    throw resp;
  }
  return resp.data;
};

export const getClearingAgentReadyForCombo = async (consigneeId: number) => {
  const result: ClearingAgentForConsigneeType[] = await ClearingAgentForConsignee(consigneeId);
  return result
    .sort((a, b) => {
      if (a.code > b.code) return 1;
      if (a.code < b.code) return -1;
      return 1;
    })
    .map((mapitem) => {
      return { value: mapitem.id, display: mapitem.name, data: mapitem };
    });
};
