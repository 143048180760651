import { apiHeaders, client, request } from "./_base";

export type ProducerAdvancePaidHeadType = {
  id?: number;
  ident: number;
  makeanote: string;
  amount: number;
  adhoc: number;
  week_id: number;
  currency_id: number;
  posteddate: Date;
  created_at?: Date;
};

export const produceradvancepaidhead = client("produceradvancepaidhead", (item) => ({
  ...item,
}));

export const produceradvancepaidheadGetLatestIdent = async () =>
  request(`produceradvancepaidhead/ext/getlatestident`, {
    headers: apiHeaders(),
    method: "GET",
  }).then(async (result) => {
    if (result.status == 500) {
      const resp = await result.json();
      throw resp;
    }
    return result.json();
  });

export const produceradvancepaidheadgetPaidByProducerID = async (producerid, financialyear) =>
  request(`produceradvancepaidhead/ext/getPaidByProducerID/${producerid}/${financialyear}`, {
    headers: apiHeaders(),
    method: "GET",
  }).then(async (result) => {
    if (result.status == 500) {
      const resp = await result.json();
      throw resp;
    }
    return result.json();
  });
