import React from "react";

import CustomAutoCompleteFilter from "../../../lib/components/customdatagridfilter/CustomFilter";
import { DateFormatter, FormatterNumber2Decimals } from "../../../lib/components/Formatters";
import { handleColumnsArrangement, handleColumnsWidth } from "../../../lib/helpers/grid";
import RightAlignHeaderColumn from "../../../lib/components/gridhelper/RightAlignHeaderColumn";

import Checkbox from "@material-ui/core/Checkbox";
import { SummaryNumber2Decimal } from "../../../lib/components/SummaryFormatter";

const cellWidthTiny = 50;
const cellWidthSmall = 100;
const cellWidthMedium = 130;
// const cellWidthLarge = 160;

const defaultColumnProperties = {
  resizable: true,
  sortable: false,
};

let currentSpace = true;
export const GridColumns = (data, filters, arrangement, columnsWidth, applyAvailable, groupedInvoiceDetail, selectedForAlloc, record, handleViewSaleAllocations) => {
  let postValue = "";

  if (data) {
    if (currentSpace) {
      postValue = " ";
    }
    currentSpace = !currentSpace;
  }

  const columnsArr = [
    {
      key: "sale_invoicenumber",
      name: "Invoice" + postValue,
      width: cellWidthSmall,
      filterable: true,
      filterRenderer: (e) => (
        <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"sale_invoicenumber"} data={data} section="fundallocationtable" />
      ),
    },
    {
      key: "forwardagentref",
      name: "Forward Agent Ref" + postValue,
      width: cellWidthSmall,
      filterable: true,
      formatter: () => <></>,
      groupFormatter: ({ groupKey, column }) => <>{groupedInvoiceDetail[groupKey][column.key]}</>,
      filterRenderer: (e) => (
        <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"forwardagentref"} data={data} section="fundallocationtable" />
      ),
    },
    {
      key: "vessel_code",
      name: "Vessel" + postValue,
      width: cellWidthMedium,
      filterable: true,
      formatter: () => <></>,
      groupFormatter: ({ groupKey, column }) => <>{groupedInvoiceDetail[groupKey][column.key]}</>,
      filterRenderer: (e) => <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"vessel_code"} data={data} section="fundallocationtable" />,
    },
    {
      key: "dispatch_containerno",
      name: "Container" + postValue,
      width: cellWidthSmall,
      filterable: true,
      formatter: () => <></>,
      groupFormatter: ({ groupKey, column }) => <>{groupedInvoiceDetail[groupKey][column.key]}</>,
      filterRenderer: (e) => (
        <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"dispatch_containerno"} data={data} section="fundallocationtable" />
      ),
    },
    {
      key: "paymentcolumn",
      name: "Payment" + postValue,
      width: cellWidthSmall,
      filterable: true,
      filterRenderer: (e) => (
        <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"paymentcolumn"} data={data} section="fundallocationtable" />
      ),
      formatter: ({ row, column }) => {
        if (row.header) return <div></div>;
        return <div>{row[column.key]}</div>;
      },
    },
    {
      key: "portdistcharge_code_special",
      name: "Due Date" + postValue,
      width: cellWidthMedium,
      filterable: true,
      formatter: ({ row, column }) => {
        if (row.header) {
          return <div style={{ fontWeight: "bold" }}>DUE DATE</div>;
        }
        return <DateFormatter row={row} column={column} />;
      },
      filterRenderer: (e) => (
        <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"portdistcharge_code_special"} data={data} section="fundallocationtable" />
      ),
    },
    {
      key: "currency_code",
      name: "Currency" + postValue,
      width: cellWidthTiny,
      filterable: true,
      formatter: () => <></>,
      groupFormatter: ({ groupKey, column }) => <>{groupedInvoiceDetail[groupKey][column.key]}</>,
      filterRenderer: (e) => (
        <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"currency_code"} data={data} section="fundallocationtable" />
      ),
    },
    {
      key: "sale_total",
      name: "Invoice Total" + postValue,
      width: cellWidthMedium,
      filterable: true,
      headerRenderer: RightAlignHeaderColumn,
      formatter: ({ row, column }) => {
        if (row.header) {
          return <div style={{ fontWeight: "bold", textAlign: "right" }}>DUE</div>;
        }
        return <FormatterNumber2Decimals row={row} column={column} />;
      },
      groupFormatter: ({ groupKey, column }) => <FormatterNumber2Decimals row={groupedInvoiceDetail[groupKey]} column={column} />,
      filterRenderer: (e) => <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"sale_total"} data={data} section="fundallocationtable" />,
      summaryFormatter: SummaryNumber2Decimal,
    },
    {
      key: "amountDebit",
      name: "Debit" + postValue,
      width: cellWidthMedium,
      filterable: true,
      formatter: (props) => (props.row.header ? <></> : <FormatterNumber2Decimals {...props} />),
      headerRenderer: RightAlignHeaderColumn,
      groupFormatter: ({ groupKey, column }) => <FormatterNumber2Decimals row={groupedInvoiceDetail[groupKey]} column={column} />,
      filterRenderer: (e) => <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"amountDebit"} data={data} section="fundallocationtable" />,
      summaryFormatter: SummaryNumber2Decimal,
    },
    {
      key: "amountCredit",
      name: "Credit" + postValue,
      width: cellWidthMedium,
      filterable: true,
      headerRenderer: RightAlignHeaderColumn,
      formatter: (props) => (props.row.header ? <></> : <FormatterNumber2Decimals {...props} />),
      groupFormatter: ({ groupKey, column }) => <FormatterNumber2Decimals row={groupedInvoiceDetail[groupKey]} column={column} />,
      filterRenderer: (e) => (
        <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"amountCredit"} data={data} section="fundallocationtable" />
      ),
      summaryFormatter: SummaryNumber2Decimal,
    },
    {
      key: "total_outstandingwithadjustments",
      name: "Total Outstanding" + postValue,
      width: cellWidthMedium,
      filterable: true,
      headerRenderer: RightAlignHeaderColumn,
      groupFormatter: ({ groupKey, column }) => <FormatterNumber2Decimals row={groupedInvoiceDetail[groupKey]} column={column} />,
      filterRenderer: (e) => (
        <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"total_outstandingwithadjustments"} data={data} section="fundallocationtable" />
      ),
      formatter: (props) => (props.row.header ? <></> : <FormatterNumber2Decimals {...props} />),
      summaryFormatter: SummaryNumber2Decimal,
    },
    {
      key: "amountReceived",
      name: "Allocated" + postValue,
      width: cellWidthMedium,
      filterable: true,
      headerRenderer: RightAlignHeaderColumn,
      formatter: ({ row, column }) => {
        if (row.header) {
          return <div style={{ fontWeight: "bold", textAlign: "right" }}>RECEIVED</div>;
        }
        const filtered = selectedForAlloc.filter(
          (selectedRow) => selectedRow.sale_id == row.sale_id && selectedRow.paymentnumber == row.paymentnumber && selectedRow.salefunds_id == record.id,
        );
        const totalSelected = filtered.reduce((tot, selectedRow) => {
          if (selectedRow.id) {
            // since this amount is in both the "selectedForAlloc" and the "row.amountReceived", we can remove it.
            tot -= selectedRow.orig_amount;

            if (!selectedRow.deleted) {
              // if the user made a change to the amount, add that to our sum
              if (selectedRow.amount_diff != 0) {
                tot += selectedRow.amount;
              } else {
                // add the default value
                tot += selectedRow.orig_amount;
              }
            }
          } else {
            // add the value to the new allocation (not in the db)
            tot += selectedRow.amount;
          }
          return tot;
        }, 0);
        const isSelected = filtered.length > 0;

        let rowTotal = 0;

        if (row.amountReceived < 0) {
          // if credit+debit is greater than the sale total, rowTotal = remaining
          if (Math.abs(row.adjustment_total) > row.sale_totalfinal) {
            rowTotal = row.amountReceived;
          }
          // if credit+debit is less than sale total, but greater than sale payment
          else if (Math.abs(row.adjustment_total) > row.sale_total) {
            if (filtered == 0) {
              rowTotal = 0;
            }
            // if amount allocated to sale
            else if (filtered.find((row) => row.id)) {
              rowTotal = row.amountReceived;
            }
          }
        } else {
          rowTotal = row.amountReceived;
        }

        return (
          <div style={{ cursor: "pointer" }} onDoubleClick={isSelected ? () => handleViewSaleAllocations(row) : () => {}}>
            <FormatterNumber2Decimals
              column={column}
              row={{ ...row, amountReceived: isSelected ? rowTotal + totalSelected : row.amountReceived }}
              style={{ fontWeight: isSelected ? "bold" : "normal" }}
            />
          </div>
        );
      },
      groupFormatter: ({ groupKey, column }) => <FormatterNumber2Decimals row={groupedInvoiceDetail[groupKey]} column={column} />,
      filterRenderer: (e) => (
        <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"amountReceived"} data={data} section="fundallocationtable" />
      ),
      summaryFormatter: (props) => <SummaryNumber2Decimal {...props} column={{ ...props.column, key: "groupedAllocated" }} />,
    },
    {
      key: "overpaid",
      name: "Overpaid" + postValue,
      width: cellWidthMedium,
      filterable: true,
      headerRenderer: RightAlignHeaderColumn,
      formatter: ({ row }) => {
        if (row.header) {
          return <div style={{ fontWeight: "bold", textAlign: "center" }}>ALLOCATE</div>;
        }
        // overpaid amount has been allocated to payment (payment is settled)
        if (row.allocatedoverpaidsales_amount > 0) {
          return (
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%", cursor: "pointer" }}>
              <Checkbox style={{ padding: 0 }} disabled={true} checked={true} />
            </div>
          );
        }
        const selected = selectedForAlloc.filter(
          (selectedRow) => selectedRow.dirty && selectedRow.sale_id == row.sale_id && selectedRow.paymentnumber == row.paymentnumber && selectedRow.salefunds_id == record.id,
        );
        return (
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%", cursor: "pointer" }}>
            <Checkbox
              style={{ padding: 0 }}
              onChange={() => applyAvailable(row)}
              disabled={(row.amountRemaining == 0 && (selected || []).length == 0) || row.is_locked}
              checked={row.amountRemaining == 0 || (selected || []).length > 0}
            />
          </div>
        );
      },
      groupFormatter: ({ groupKey, column }) => <FormatterNumber2Decimals row={groupedInvoiceDetail[groupKey]} column={column} />,
      filterRenderer: (e) => <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"overpaid"} data={data} section="fundallocationtable" />,
      summaryFormatter: SummaryNumber2Decimal,
    },
  ].map((c) => ({ ...c, ...defaultColumnProperties }));

  let columnsArranged = [];
  columnsArranged = handleColumnsArrangement(columnsArranged, arrangement, columnsArr);
  columnsArranged = handleColumnsWidth(columnsArranged, columnsWidth, columnsArr);

  return columnsArranged;
};
