import React from "react";

import IconButton from "@material-ui/core/IconButton";
import Snackbar from "@material-ui/core/Snackbar";
import CloseIcon from "@material-ui/icons/Close";

export const SnackMessage = ({ isOpen, handleClose, message, color }) => {
  return (
    <>
      <Snackbar
        style={{ backgroundColor: `${color}` }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={isOpen}
        // autoHideDuration={0}
        onClose={handleClose}
        ContentProps={{
          "aria-describedby": "message-id",
          style: { backgroundColor: `${color}` },
        }}
        message={<span id="message-id">{message}</span>}
        action={[
          <IconButton key="close" aria-label="Close" color="inherit" onClick={handleClose}>
            <CloseIcon />
          </IconButton>,
        ]}
      />
    </>
  );
};
