import React from "react";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";

import CircularProgress from "@material-ui/core/CircularProgress";

import InspectionsFullView from "./inspectionsfull";
import InspectionMarketImages from "./inspectionmarketimages";
import { InspectionEdit } from "./inspectionedit";
import { GridColumns } from "./inspectionsgridsetup";
import Reports from "./reports";

import Grid from "../lib/components/grid";
import { findBy, inspections } from "../lib/api/inspection";
import Confirmation from "../lib/components/confirmation";

import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";

import { format } from "date-fns";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1),
      width: "100%",
    },
    inline: {
      display: "inline",
    },
    wrapper: {
      position: "relative",
    },
    toolbar: {
      position: "absolute",
      left: 0,
      top: "8px",
      height: "60px",
      width: "100%",
      display: "flex",
      flexDirection: "row",
      gap: "10px",
      alignItems: "center",
    },
  });

type InspectionsProps = {} & WithStyles<typeof styles>;

export class InspectionsUnstyled extends React.Component<InspectionsProps, any> {
  state = {
    classes: undefined,
    data: [],
    selectedInspection: undefined,
    removeInspection: undefined,
    loading: true,
    marketInspection: undefined,
    editInspection: undefined,
    export: false,
  };

  constructor(props) {
    super(props);
    this.state.classes = props.classes;
  }

  componentDidMount() {
    this.loadData();
  }

  loadData = async () => {
    const data = await findBy();
    this.setState({ loading: false, data: data.filter((item) => item.status != 1 && item.status != 3) }, () => {});
  };

  handleView = async (inspection) => {
    this.setState({ selectedInspection: inspection });
  };

  handleEdit = (inspection) => {
    this.setState({ editInspection: inspection.id });
  };

  GridColumnsOvveride = (column, row, arrangement, columnsWidth) => {
    return GridColumns(column, row, arrangement, columnsWidth, this.handleView, this.handleRemove, this.handleShowMarketImages, this.handleEdit);
  };

  handleCloseInspectionFull = () => {
    this.setState({ selectedInspection: undefined });
  };

  handleRemove = (inspection) => {
    this.setState({ removeInspection: inspection });
  };

  handleRemoveClose = () => {
    this.setState({ removeInspection: undefined });
  };

  handleRemoveConfirm = async () => {
    this.setState({ removeInspection: undefined });
    await inspections.update(this.state.removeInspection.id, {
      data: {
        status: 1,
      },
    });
    this.loadData();
  };

  handleShowMarketImages = (inspection) => {
    this.setState({ marketInspection: inspection });
  };

  handleClose = () => {
    this.setState({ marketInspection: undefined });
  };

  handleCloseEdit = () => {
    this.setState({ editInspection: undefined });
  };

  handleExport = (bool: boolean) => {
    this.setState({ export: bool });
  };

  render() {
    const { classes } = this.state;
    return this.state.loading ? (
      <CircularProgress />
    ) : (
      <div className={classes.root}>
        <div className={classes.wrapper}>
          <Toolbar className={classes.toolbar}>
            <Button variant="contained" color="primary" onClick={() => this.handleExport(true)}>
              Export
            </Button>
          </Toolbar>
          <Grid
            loading={this.state.loading}
            data={this.state.data}
            GridColumns={this.GridColumnsOvveride}
            exportName={`${format(new Date(), "yyMMdd")} QC INSPECTIONS`}
            rowHeight={30}
            clearFilters={"inspectionsgridsetup"}
          />
        </div>
        {this.state.marketInspection && <InspectionMarketImages handleClose={this.handleClose} inspection={this.state.marketInspection} />}
        {this.state.selectedInspection && <InspectionsFullView isOpen={true} handleClose={this.handleCloseInspectionFull} inspection={this.state.selectedInspection} />}
        {this.state.editInspection && (
          <Confirmation isOpen disableBackdropClick handleClose={this.handleCloseEdit} handleConfirm={this.handleCloseEdit} title="Inspection Notes" body={""}>
            <InspectionEdit inspectionId={this.state.editInspection} handleClose={this.handleCloseEdit} />
          </Confirmation>
        )}
        {this.state.export && (
          <Confirmation isOpen handleClose={() => this.handleExport(false)} handleConfirm={() => this.handleExport(false)} title={"Export QC Inspection Reports"} body={undefined}>
            <Reports onClose={() => this.handleExport(false)} />
          </Confirmation>
        )}
        {this.state.removeInspection && (
          <Confirmation
            isOpen={this.state.removeInspection ? true : false}
            handleClose={this.handleRemoveClose}
            handleConfirm={this.handleRemoveConfirm}
            title="Remove selected Inspection?"
            body="Are you sure you want to REMOVE the selected Inspection Record?"
          ></Confirmation>
        )}
      </div>
    );
  }
}

export default withStyles(styles)(InspectionsUnstyled);
