import { client, request, apiHeaders } from "./_base";

const endpoint = "edistockpalletsdispatched";

export const edistockpalletsdispatched = client(endpoint, (item) => ({
  ...item,
}));

export const findByDispatchID = (dispatchid: number) =>
  request(`${endpoint}/ext/findByDispatchID/${dispatchid}`, {
    headers: apiHeaders(),
  }).then((result) => result.json());

export type FindByDispatchIDBarcodeCompareType = {
  edi_Barcode: string;
  edi_NoCartons: string;
  edi_CommodityCode: string;
  edi_VarietyCode: string;
  edi_PackCode: string;
  edi_MarkCode: string;
  edi_GradeCode: string;
  edi_CountCode: string;
  edi_InventoryCode: string;
  edi_PalletSize: string;
  bar_Barcode: string;
  bar_NoCartons: string;
  bar_CommodityCode: string;
  bar_VarietyCode: string;
  bar_PackCode: string;
  bar_MarkCode: string;
  bar_GradeCode: string;
  bar_CountCode: string;
  bar_InventoryCode: string;
  bar_PalletSize: string;
  bar_barcode_id: number;
  loadoutdetail_id: number;
  loadout_id: number;
  loadout_reference: string;
  edi_dispatchid: string;
  edi_PickingRef: string;
  bar_PickingRef: string;
  edi_Waybill: string;
  bar_Waybill: string;
  bar_sequenceNumber: number;
  edi_sequenceNumber: number;
};

export const findByDispatchIDBarcodeCompare = (dispatchid: number) =>
  request(`${endpoint}/ext/findByDispatchIDBarcodeCompare/${dispatchid}`, {
    headers: apiHeaders(),
  }).then((result) => result.json());

export type FindByDispatchIDBarcodeMissingFromLoadoutType = {
  loadout_id: number;
  loadout_reference: string;
  Barcode: string;
  NoCartons: string;
  CommodityCode: string;
  VarietyCode: string;
  PackCode: string;
  MarkCode: string;
  GradeCode: string;
  CountCode: string;
  InventoryCode: string;
  PalletSize: string;
  PickingRef: string;
};

export const findByDispatchIDBarcodeMissingFromLoadout = (dispatchid: number) =>
  request(`${endpoint}/ext/findByDispatchIDBarcodeMissingFromLoadout/${dispatchid}`, {
    headers: apiHeaders(),
  }).then((result) => result.json());

export const removeBarcodeByDetail = async (row: any) => {
  const content = JSON.stringify(row);
  return await request(`${endpoint}/ext/removeBarcodeFromDispatchDetail`, {
    method: "PUT",
    headers: apiHeaders(content),
    body: content,
  }).then(async (result) => {
    return result;
  });
};

export const createBarcodeByDetail = async (row) => {
  const content = JSON.stringify(row);
  return await request(`${endpoint}/ext/createBarcodeByDetail`, {
    method: "POST",
    headers: apiHeaders(content),
    body: content,
  }).then(async (result) => {
    return result;
  });
};
