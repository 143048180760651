import { client, request, apiHeaders } from "./_base";
import { getFinancialYearSelected } from "./week";
import format from "date-fns/format";

export type LoadoutType = {
  id: number;
  reference: string;
  exportNotificationReference: string;
  dateLoading: Date;
  portLoading_id: number;
  portDischarge_id: number;
  portFinal_id: number;
  shipping_id: number;
  vessel_id: number;
  voyageNumber: string;
  specialinstruction;
  coldroom_id: number;
  agent_id: number;
  etd: Date;
  eta: Date;
  phytclean;
  created_at?: Date;
  updated_at?: Date;
  dispatch_id: number;
  consignee: string;
  spec: number;
  loadingdocs: number;
  collected: Date;
  notes: string;
  updated_eta: Date;
};

const endpoint = "loadout";

export const loadout = client(endpoint, (item) => ({
  ...item,
}));

export const releaseDispatch = async (id) => {
  const result = await request(`${endpoint}/releaseDispatch/${id}`, {
    method: "POST",
  });

  if (result.status == 500) {
    const resp = await result.json();

    if (resp.message.originalError) {
      throw { data: resp.message.originalError.info.message };
    }

    throw { data: resp.message };
  }
};

export const clearEmptyLoadouts = async () => {
  await request(`${endpoint}/ext/clearEmptyLoadouts`, {
    method: "GET",
  })
    .then(async (result) => {
      if (result.status == 500) {
        const resp = await result.json();
        throw resp;
      }
      return result.json();
    })
    .then(({ data }) => {});
};

export const loadoutByReference = async (reference: string): Promise<{ data: LoadoutType[] }> => {
  const result = await request(`${endpoint}/ext/loadoutByReference/${reference}`, {
    method: "GET",
  });
  if (result.status == 500) {
    const resp = await result.json();
    throw resp;
  }
  return result.json();
};

export const loadoutByReferenceAndFinYear = async (reference: string): Promise<{ data: LoadoutType[] }> => {
  const result = await request(`${endpoint}/ext/loadoutByReferenceAndFinYear?reference=${reference}&financial_year=${getFinancialYearSelected()}`, {
    method: "GET",
  });
  if (result.status == 500) {
    const resp = await result.json();
    throw resp;
  }
  return result.json();
};

export const createDispatchFromLoadout = async (loadoutid) => {
  return request(`${endpoint}/ext/dispatchfromloadout/${loadoutid}`, {
    method: "GET",
  }).then(async (result) => {
    if (result.status == 500) {
      const resp = await result.json();
      throw resp;
    }
    return result;
  });
};

export const getLastLoadout = async () => {
  return await request(`${endpoint}/ext/lastLoadout`, {
    headers: apiHeaders(),
  }).then(async (result) => {
    if (result.status == 500) {
      const resp = await result.json();
      throw resp;
    }
    return result.json();
  });
};

export const printClientShippingReport = async (financial_year: number, selectedClient: string) => {
  const result = await request(`${endpoint}/ext/printClientShippingReport?financial_year=${financial_year}&client_code=${selectedClient}`, {
    headers: apiHeaders(),
  });
  const blob = await result.blob();
  const url = window.URL.createObjectURL(blob);

  let a = document.createElement("a");

  a.href = url;
  a.download = `${format(new Date(), "yyMMdd")} ${selectedClient} SHIPPING REPORT.xlsx`;
  a.click();
};

export const handleExportShipmentsData = async (data: any[]) => {
  const result = await request(`${endpoint}/ext/exportShipmentsData`, {
    headers: apiHeaders(),
    method: "POST",
    body: JSON.stringify(data),
  });

  const blob = await result.blob();
  const url = window.URL.createObjectURL(blob);

  let a = document.createElement("a");

  a.href = url;
  a.download = `SHIPPINGS.xlsx`;
  a.click();
};

export const getClientsWithShipmentsForCurrentYear = async (): Promise<{ clients_code: string; clients_name: string }[]> => {
  const result = await request(`${endpoint}/ext/getClientsWithShipmentsForCurrentYear?financial_year=${getFinancialYearSelected()}`, {
    headers: apiHeaders(),
  });
  const resp = await result.json();
  if (result.status == 500) {
    throw resp;
  }
  return resp.data;
};

export const printLoadoutReport = async (loadout_ids: number[], data: any[]) => {
  const result = await request(`${endpoint}/ext/exportExcel`, {
    headers: apiHeaders(),
    method: "POST",
    body: JSON.stringify({ loadout_ids, data }),
  });
  if (result.status == 500) {
    throw result.status;
  }

  const fileName = `${format(new Date(), "yyMMdd")}_LOADOUT_DETAIL.xlsx`;
  const blob = await result.blob();
  const url = window.URL.createObjectURL(blob);

  let a = document.createElement("a");

  a.href = url;
  a.download = fileName;
  a.click();
};
