import React from "react";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";

import Grid from "../../lib/components/grid";
import { DialogInformation } from "../../lib/components/dialoginformation";
import { isNullOrUndef } from "../../lib/helpers/isNullOrUndef";

import { getProducersFullByProducerCode, getProducersFullInvoice, ProducersFullInvoiceType } from "../../lib/api/producersfullinvoice";
import { producersaleadjustment } from "../../lib/api/producersaleadjustment";
import { producersaleadjustmentdetail, ProducerSaleAdjustmentBySaleIdType } from "../../lib/api/producersaleadjustmentdetail";
import { getFinancialYearSelected } from "../../lib/api/week";

import { GridColumns } from "./adjustmentsgridsetup";
import Adjustment from "./adjustment/adjustment";
import { Row } from "react-data-grid";

import CircularProgress from "@material-ui/core/CircularProgress";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1),
      height: "calc(100vh - 100px)",
      width: "100%",
      position: "relative",
    },
    inline: {
      display: "inline",
      position: "absolute",
      marginTop: "7px",
      marginLeft: "7px",
    },
    toolbar: {
      position: "absolute",
      left: 0,
      top: 0,
      height: "60px",
      width: "100%",
      display: "flex",
      flexDirection: "row",
      gap: "10px",
    },
    gridWrapper: {
      position: "relative",
    },
  });

type DebtorsFullProps = { history: any; match: any } & WithStyles<typeof styles>;

export class DebtorsFullUnstyled extends React.Component<DebtorsFullProps, any> {
  state = {
    classes: undefined,
    data: [],
    loading: true,
    message: undefined,
    selectedRecord: undefined,
    producer_code: "",
  };

  constructor(props) {
    super(props);
    this.state.classes = props.classes;
    this.state.producer_code = props.match.params.producercode;
  }

  componentDidMount() {
    this.loadData().then(() => {
      this.setState({ loading: false });
    });
  }

  loadData = () => {
    this.setState({ loading: true });
    return getProducersFullByProducerCode(getFinancialYearSelected(), this.state.producer_code).then((result) => {
      if (result) {
        const dataSorted = result.sort((a, b) => a.dispatch_invoicenumber.localeCompare(b.dispatch_invoicenumber) || a.document_type.localeCompare(b.document_type));
        this.setState({ data: dataSorted }, () => {});
      }
    });
  };

  handleAdjust = (selectedRecord: ProducersFullInvoiceType) => {
    this.setState({
      selectedRecord: {
        ...selectedRecord,
      },
    });
  };

  handleAdjustProcess = async (data, detail) => {
    let id = 0;
    // refactor required
    if (data && !isNullOrUndef(data.sale_id)) {
      const dataToPush = {
        data: {
          producersale_id: data.sale_id,
          adjustdate: new Date(data.sale_date),
          type: data.saleadjustment_type,
          typeapply: data.saleadjustment_typeapply,
          creditorinvoicenumber: data.saleadjustment_creditorinvoicenumber,
          status: 0,
        },
      };

      if (data.dispatchdocs_id != -99) {
        await producersaleadjustment.create(dataToPush).then((result) => {
          id = result[0];
        });
      } else {
        await producersaleadjustment.update(data.sale_dispatch_id, dataToPush).then(() => {});
        id = data.sale_dispatch_id;
      }

      let proportionalAmount = undefined;
      if (data.saleadjustment_typeapply == 1) {
        const result = detail.reduce((prev, current) => {
          if (!current) return 0;
          return prev + current.barcode_cartons;
        }, 0);
        proportionalAmount = data.saleadjustment_amount / result;
      }
      detail.map(async (ditem: ProducerSaleAdjustmentBySaleIdType) => {
        const dataDetailToPush = {
          data: {
            producersaleadjustment_id: id,
            producersaledetail_id: ditem.salesdetail_id,
            cartons: data.saleadjustment_typeapply == 1 ? ditem.barcode_cartons : ditem.saleadjustmentdetail_cartons,
            amount: data.saleadjustment_typeapply == 1 ? proportionalAmount : ditem.saleadjustmentdetail_amount,
          },
        };

        if (isNullOrUndef(ditem.saleadjustmentdetail_id) || ditem.saleadjustmentdetail_id == 0) {
          await producersaleadjustmentdetail.create(dataDetailToPush);
        } else {
          await producersaleadjustmentdetail.update(ditem.saleadjustmentdetail_id, dataDetailToPush);
        }
      });

      this.loadData().then(() => {
        this.setState({ loading: false });
      });
    }
    this.setState({ selectedRecord: undefined });
  };

  handleRemoveAdjustment = (row: ProducersFullInvoiceType) => {
    this.setState({ loading: true });
    producersaleadjustment.remove(row.sale_dispatch_id).then(() => {
      this.loadData().then(() => {
        this.setState({ loading: false });
      });
    });
  };

  handleInformationClose = () => {
    this.setState({ message: undefined });
  };

  rowRenderer = (props) => {
    const { row } = props;

    const handleDoubleClickHandler = () => {
      if (row.document_type != "1") {
        this.handleAdjust(row);
      }
    };

    return <Row {...props} onDoubleClickCapture={handleDoubleClickHandler} />;
  };

  handleBack = () => {
    this.props.history.push("/producers/adjustments/summary");
  };

  render() {
    const { classes } = this.state;

    return (
      <div className={classes.root}>
        {this.state.loading && <CircularProgress />}
        {!this.state.selectedRecord && this.state.loading == false && (
          <div className={classes.gridWrapper}>
            <Toolbar className={classes.toolbar}>
              <Button color="secondary" variant="contained" onClick={this.handleBack} style={{ marginRight: "10px" }}>
                Back
              </Button>
            </Toolbar>
            <Grid
              showFilterChips={true}
              loading={this.state.loading}
              data={this.state.data}
              GridColumns={(data, filters, columnArrangement, columnsWidth) =>
                GridColumns(data, filters, columnArrangement, columnsWidth, this.handleAdjust, this.handleRemoveAdjustment)
              }
              clearFilters={"producersadjustments"}
              rowRenderer={this.rowRenderer}
              handleRefresh={() => {
                this.setState({ loading: true }, () => {
                  this.loadData().then(() => {
                    this.setState({ loading: false });
                  });
                });
              }}
              totalRowColumns={[
                "sale_total",
                "amountCredit",
                "amountDebit",
                "sale_totalfinal",
                "salefundsallocation_amount",
                "sale_payment1amount",
                "sale_payment1amount_remain",
                "sale_payment2amount",
                "sale_payment2amount_remain",
                "sale_payment3amount",
                "sale_payment3amount_remain",
              ]}
            />
          </div>
        )}
        {this.state.selectedRecord && <Adjustment selectedRecord={this.state.selectedRecord} handleProcess={this.handleAdjustProcess} />}
        {this.state.message && (
          <DialogInformation
            isOpen={true}
            handleClose={this.handleInformationClose}
            handleOK={this.handleInformationClose}
            title={"Feedback"}
            body={`${this.state.message}`}
            showinput={false}
          />
        )}
      </div>
    );
  }
}

export default withStyles(styles)(DebtorsFullUnstyled);
