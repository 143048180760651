import React from "react";
import CustomAutoCompleteFilter from "../../lib/components/customdatagridfilter/CustomFilter";

import TextEditor from "../../lib/components/TextEditor";
import { SummaryNumber, SummaryNumber2Decimal, SummaryTotal } from "../../lib/components/SummaryFormatter";
import { CenterAlignColumnHeader, CenterTextFormatter, FormatterNumber, FormatterNumber2Decimals } from "../../lib/components/Formatters";
import { EnquiryLink, Selector } from "../../lib/components/EnquiryLink";
import { handleColumnsArrangement, handleColumnsWidth } from "../../lib/helpers/grid";

const cellWidthSmallX = 90;
const cellWidthSmall = 115;
const cellWidthMedium = 150;
const cellWidthLarge = 220;

const defaultColumnProperties = {
  filterable: true,
  resizable: true,
  sortable: true,
};

let currentSpace = true;

export const GridColumnsPallets = (data, filters, arrangement, columnsWidth, sellingEditable, section) => {
  let postValue = "";

  if (data) {
    if (currentSpace) {
      postValue = " ";
    }
    currentSpace = !currentSpace;
  }

  const columns = [
    {
      key: "loadoutdetail_barcode",
      name: "Barcode" + postValue,
      width: cellWidthLarge,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} option={data} currentFilters={filters} fieldname={"loadoutdetail_barcode"} data={data} section={section} />;
      },
      formatter: (props) => <EnquiryLink {...props} selector={Selector.Barcode} />,
      summaryFormatter: SummaryTotal,
    },
    {
      key: "cold_week",
      name: "Cold Week" + postValue,
      width: cellWidthSmall,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"cold_week"} data={data} section={section} />;
      },
    },
    {
      key: "loadout_reference",
      name: "Reference" + postValue,
      width: cellWidthSmall,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"loadout_reference"} data={data} section={section} />;
      },
    },
    {
      key: "barcode_commoditycode",
      name: "Commodity" + postValue,
      width: cellWidthSmallX,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"barcode_commoditycode"} data={data} section={section} />;
      },
    },
    {
      key: "barcode_varietycode",
      name: "Variety" + postValue,
      width: cellWidthSmallX,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"barcode_varietycode"} data={data} section={section} />;
      },
    },
    {
      key: "barcode_packcode",
      name: "Pack" + postValue,
      width: cellWidthSmallX,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"barcode_packcode"} data={data} section={section} />;
      },
    },
    {
      key: "barcode_markcode",
      name: "Mark" + postValue,
      width: cellWidthSmallX,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"barcode_markcode"} data={data} section={section} />;
      },
    },
    {
      key: "barcode_gradecode",
      name: "Grade" + postValue,
      width: cellWidthSmallX,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"barcode_gradecode"} data={data} section={section} />;
      },
      formatter: CenterTextFormatter,
      headerRenderer: CenterAlignColumnHeader,
    },
    {
      key: "barcode_countcode",
      name: "Count" + postValue,
      width: cellWidthSmallX,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"barcode_countcode"} data={data} section={section} />;
      },
      formatter: CenterTextFormatter,
      headerRenderer: CenterAlignColumnHeader,
    },
    {
      key: "barcode_inventorycode",
      name: "Inventory" + postValue,
      width: cellWidthSmallX,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"barcode_inventorycode"} data={data} section={section} />;
      },
      formatter: CenterTextFormatter,
      headerRenderer: CenterAlignColumnHeader,
    },
    {
      key: "barcode_nocartons",
      name: "No Cartons" + postValue,
      width: cellWidthSmallX,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"barcode_nocartons"} data={data} section={section} />;
      },
      formatter: FormatterNumber,
      summaryFormatter: SummaryNumber,
    },
    {
      key: "barcode_palletsize",
      name: "Pallet Size" + postValue,
      width: cellWidthSmallX,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"barcode_palletsize"} data={data} section={section} />;
      },
      formatter: FormatterNumber2Decimals,
      summaryFormatter: SummaryNumber2Decimal,
    },
    // {
    //   key: "stockdetail_producerprice",
    //   name: "Producer Price" + postValue,
    //   width: cellWidthSmall,
    //   filterRenderer: (e) => {
    //     return (
    //       <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"stockdetail_producerprice"} data={data} section={section} />
    //     );
    //   },
    //   formatter: FormatterNumber2Decimals,
    //   summaryFormatter: SummaryNumber2Decimal,
    // },
    {
      key: "stockdetail_sellingprice",
      name: "Producer Price" + postValue,
      width: cellWidthSmall,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"stockdetail_sellingprice"} data={data} section={section} />;
      },
      editable: sellingEditable,
      editor: (props) => <TextEditor {...props} align="right" />,
      editorOptions: {
        editOnClick: true,
      },
      formatter: (props) => <FormatterNumber2Decimals {...props} style={{ color: props.row.sale_id ? "black" : "red" }} />,
      summaryFormatter: SummaryNumber2Decimal,
    },

    {
      key: "total",
      name: "Total" + postValue,
      width: cellWidthSmall,
      filterable: false,
      formatter: FormatterNumber2Decimals,
      summaryFormatter: SummaryNumber2Decimal,
    },
    {
      key: "comment",
      name: "Comment" + postValue,
      width: cellWidthSmall,
      filterable: false,
    },
    {
      key: "producer_code",
      name: "Producer" + postValue,
      width: cellWidthSmall,
      filterable: false,
    },
  ].map((c) => ({ ...c, ...defaultColumnProperties }));

  let columnsArranged = [];
  columnsArranged = handleColumnsArrangement(columnsArranged, arrangement, columns);
  columnsArranged = handleColumnsWidth(columnsArranged, columnsWidth, columns);

  return columnsArranged;
};
