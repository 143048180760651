import React, { useEffect, useRef } from "react";
import { withStyles, createStyles, Theme } from "@material-ui/core/styles";
import { isNullOrUndef } from "../helpers/isNullOrUndef";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      appearance: "none",
      boxSizing: "border-box",
      width: "100%",
      height: "100%",
      padding: "0px 6px 0 6px",
      border: "2px solid #ccc",
      verticalAlign: "top",
      color: "black",
      textAlign: "right",
      fontSize: "15px",
      "&:focus": {
        borderColor: "black",
        outline: "none",
      },
      "&::placeholder": {
        color: "#999",
        opacity: 1,
      },
    },
  });

const DropDownEditorUnstyled = ({ classes, row, column, onRowChange, options, valueKey = "id", displayKey = valueKey }) => {
  // if ((isNullOrUndef(row[column.key]) || row[column.key].length == 0) && options[0].value != "CLEAR") {
  //   onRowChange({ ...row, [column.key]: options[0].value }, true);
  // }

  return (
    <select autoFocus value={row[column.key]} onChange={(event) => onRowChange({ ...row, [column.key]: event.target.value }, true)} className={classes.root}>
      <option key={""} value={""}></option>
      {options.map((option) => (
        <option key={option.id} value={option[valueKey]}>
          {option[displayKey]}
        </option>
      ))}
    </select>
  );
};

export default withStyles(styles)(DropDownEditorUnstyled);
