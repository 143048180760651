import React, { FC, useEffect, useState } from "react";
import { AllocationSummaryGrid } from "./grid";
import { getAllocationsSummary } from "../../../lib/api/salefundsallocation";

type AllocationSummaryProps = {
  clients_code: string;
};

export const AllocationSummary: FC<AllocationSummaryProps> = ({ clients_code }) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<any[]>([]);

  const loadData = async () => {
    setLoading(true);
    const result = await getAllocationsSummary(clients_code);
    setData(result);
    setLoading(false);
  };

  useEffect(() => {
    loadData();
  }, []);

  return <AllocationSummaryGrid data={data} loading={loading} />;
};
