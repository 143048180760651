import React from "react";

import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";

import CustomAutoCompleteFilter from "../../lib/components/customdatagridfilter/CustomFilter";
import { agent } from "../../lib/api/agent";
import { CellActionsFormatter } from "../../lib/helpers/CellActionsFormatter";

// import numeral from "numeral";

const cellWidthSmall = 80;
const cellWidthSmallBig = 140;
const cellWidthLarge = 350;

const defaultColumnProperties = {
  resizable: true,
  sortable: true,
};

// const formatterCurrency = (data) => {
//   return <div>{`${numeral(data.value).format("0.00")}`}</div>;
// };

const DefaultFormatter = ({ row }) => {
  const value = row.default && row.default > 0 ? "True" : "False";
  return <div>{value}</div>;
};

let currentSpace = true;
export const GridColumns = (data, filters, handleRemove, handleEdit) => {
  let postValue = "";

  if (data) {
    if (currentSpace) {
      postValue = " ";
    }
    currentSpace = !currentSpace;
  }

  return [
    {
      key: "actions",
      name: "Actions",
      width: cellWidthSmall,
      formatter: ({ row }) => {
        const actions = cellActions(row, handleRemove, handleEdit);
        return (
          <div style={{ display: "flex", justifyContent: "center", gap: "5px" }}>
            <CellActionsFormatter actions={actions} />
          </div>
        );
      },
    },
    {
      key: "agentname",
      name: "Agent Name" + postValue,
      width: cellWidthSmallBig,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"agentname"} data={data} section={"agentgridsetup"} />;
      },
    },
    {
      key: "note",
      name: "Note" + postValue,
      width: cellWidthLarge,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"note"} data={data} section={"agentgridsetup"} />;
      },
    },
    {
      key: "default",
      name: "Default" + postValue,
      width: cellWidthLarge,
      filterable: true,
      formatter: DefaultFormatter,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"default"} data={data} section={"agentgridsetup"} />;
      },
    },
  ].map((c) => ({ ...c, ...defaultColumnProperties }));
};

const cellActions = (agentMaintenance, handleRemove, handleEdit) => {
  return [
    {
      icon: (
        <span title="Edit">
          <EditIcon style={{ cursor: "pointer", color: "orange" }} />
        </span>
      ),
      callback: (e) => {
        handleEdit(agentMaintenance.id);
      },
    },
    {
      icon: (
        <span title="Remove">
          <DeleteIcon style={{ cursor: "pointer", color: "red" }} />
        </span>
      ),
      callback: (e) => {
        handleRemove(agentMaintenance.id);
      },
    },
  ];
};
