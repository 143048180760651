import { client, request } from "./_base";

const endpoint = "barcodesplit";

export enum BarcodeExists {
  DoesNotExist = 0,
  Exists = 1,
}

export type barcodesplitType = {
  id?: number;
  barcode: string;
  weight: number;
  cartons: number;
  barcode_id: number;
  updateweight: number;
  sequence: number;
  pallet_size: number;
  created_at?: number;
  updated_at?: number;
};

export const barcodesplit = client(endpoint, (item) => ({
  ...item,
}));

export const barcodessplitProcess = () => {
  return request(`${endpoint}/ext/process`, {
    method: "GET",
  }).then((result) => {
    if (result.status == 500) {
      throw result.statusText;
    }
    return result.json();
  });
};

export const barcodeExists = (barcode: string) => {
  return request(`${endpoint}/ext/barcodeexists/${barcode}`, {
    method: "GET",
  }).then((result) => {
    if (result.status == 500) {
      throw result.statusText;
    }
    return result.json();
  });
};

export const deleteBarcodesByID = (barcodes: string[]) => {
  return request(`${endpoint}/ext/deleteBarcodesByID/${JSON.stringify(barcodes)}`, {
    method: "DELETE",
  }).then((result) => {
    if (result.status == 500) {
      throw result.statusText;
    }
    return;
  });
};
