import { client, request } from "./_base";
import { config } from "../../config";

const endpoint = "templatedetail";

// extended methods
export const templatesdetailfull = () => {
  return request(`${endpoint}/ex/full`, {
    method: "GET"
  })
    .then(result => result.json())
    .then(({ data }) => {
      return data;
    });
};

export const templatesdetailbytemplateid = (templateid) => {
  return request(`${endpoint}/ex/bytemplateid/${templateid}`, {
    method: "GET"
  })
    .then(result => result.json())
    .then(({ data }) => {
      return data;
    });
};


export const templatesdetail = {
  ...client(endpoint, item => ({
    ...item,
  })),
  templatesdetailfull: templatesdetailfull,
  templatesdetailbytemplateid: templatesdetailbytemplateid,
};

