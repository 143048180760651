import React, { useEffect } from "react";
import ArrivalExportMenu from "./arrivalExportMenu";
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import IconCheck from "@material-ui/icons/Check";
import IconClose from "@material-ui/icons/Close";

import Confirmation from "../../lib/components/confirmation";
import { isNullOrUndef } from "../../lib/helpers/isNullOrUndef";

export const ArrivalActions = withStyles((theme: Theme) =>
  createStyles({
    buttonActions: {
      marginRight: "10px",
    },
  }),
)(({ handleClose, handleSubmitLocal, handleExportExcel, handleExportPDF, status, classes, claim }: any) => {
  const [progressConfirmationOpen, setProgressConfirmationOpen] = React.useState(false);
  const [completeConfirmationOpen, setCompleteConfirmationOpen] = React.useState(false);
  const arrivalStatus = {
    inProgress: 1,
    completed: 2,
  };
  const [claimStatus, setClaimStatus] = React.useState(isNullOrUndef(claim) || claim == 0 ? false : true);

  useEffect(() => {});

  return (
    <>
      <Button color="secondary" variant="outlined" onClick={handleClose} className={classes.buttonActions}>
        <IconClose />
      </Button>
      <Button
        type="submit"
        color="primary"
        variant="outlined"
        onClick={() => (arrivalStatus.inProgress == status ? handleSubmitLocal(false, claimStatus) : setProgressConfirmationOpen(true))}
        className={classes.buttonActions}
      >
        <IconCheck />
      </Button>
      <Button
        type="submit"
        color="primary"
        variant="outlined"
        onClick={() => (arrivalStatus.completed == status ? handleSubmitLocal(true, claimStatus) : setCompleteConfirmationOpen(true))}
        className={classes.buttonActions}
      >
        complete
      </Button>
      {progressConfirmationOpen && (
        <Confirmation
          isOpen={progressConfirmationOpen}
          handleClose={() => setProgressConfirmationOpen(false)}
          handleConfirm={() => handleSubmitLocal(false, claimStatus)}
          title="Confirmation"
          body="Are you sure you want to set as in-progress?"
        ></Confirmation>
      )}
      {completeConfirmationOpen && (
        <Confirmation
          isOpen={completeConfirmationOpen}
          handleClose={() => setCompleteConfirmationOpen(false)}
          handleConfirm={() => handleSubmitLocal(true, claimStatus)}
          title="Confirmation"
          body="Are you sure you want to complete QC Arrival?"
        ></Confirmation>
      )}
      <ArrivalExportMenu handleExportExcel={handleExportExcel} handleExportPDF={handleExportPDF} />
      <FormControlLabel
        control={
          <Checkbox
            checked={claimStatus}
            onChange={(e) => {
              setClaimStatus(!claimStatus);
            }}
            value="primary"
            inputProps={{ "aria-label": "primary checkbox" }}
            style={{ marginLeft: "10px" }}
          />
        }
        label="Claim"
      />
    </>
  );
});
