import React from "react";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";
import { Redirect } from "react-router-dom";

import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";

import Grid from "../../lib/components/grid";

import { Row } from "react-data-grid";
import "react-splitter-layout/lib/index";
import "../../lib/custom/splitter.css";

import Splitter from "m-react-splitters";
import "m-react-splitters/lib/splitters.css";

import numeral from "numeral";

import { DialogInformation } from "../../lib/components/dialoginformation";
import { ColumnsFileImportHeader } from "./importinfogridsetup";
import { ColumnsFileImportHeaderInfo } from "./importinfogridsetupdetail";
import { updateFileImportHeaderInfo, execProcessIntake, execProcessDispatch } from "../../lib/api/FileImport";
import { VarietiesByCommodityMappedForComboValue } from "../../lib/api/variety";
import { getProducerReadyForComboMappedForComboValue } from "../../lib/api/producer";
import { SnackContext } from "../../lib/context/SnackContext";
import { isNullOrUndef } from "../../lib/helpers/isNullOrUndef";
import { GenerateErrorMessage } from "../../lib/helpers/string_methods";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      height: "100%",
      width: "100%",
    },
    control: {
      paddingTop: "8px",
      paddingLeft: "0px",
      float: "right",
      marginBottom: "10px",
    },
    selectedHeader: {
      color: "white",
      backgroundColor: theme.palette.primary.main,
    },
    toolbar: {
      display: "flex",
      justifyContent: "space-between",
      position: "absolute",
      height: "60px",
      width: "calc(100% - 300px)",
      right: "300px",
      top: 0,
    },
  });

type ImportInfoGridProps = {
  data: {}[];
  datadetail: {}[];
  commoditiesCombo: any[];
  dataDetailValidation: any[];
  handleReload(): void;
} & WithStyles<typeof styles>;

class ImportInfoGridUnstyled extends React.Component<ImportInfoGridProps, {}> {
  state = {
    data: [],
    dataDetail: [],
    handleReload: undefined,
    handleReImport: undefined,
    handleReject: undefined,
    handleOpen: undefined,
    dialoginformation: undefined,
    minGridHeight: 0,
    minGridHeightSecond: 0,
    redirectBack: undefined,
    updatedInfoRows: [],
    updating: false,
    commoditiesCombo: [],
    varietiesByCommodity: [],
    dataDetailValidation: [],
    tableLayout: false,
    clearedPhrase: false,
    producersCombo: [],
    currentRow: undefined,
    producersComboObj: {},
    commoditiesComboObj: {},
  };

  constructor(props) {
    super(props);
    this.state.data = props.data;
    this.state.dataDetail = props.datadetail;
    this.state.handleReload = props.handleReload;
    this.state.handleReImport = props.handleReImport;
    this.state.handleReject = props.handleReject;
    this.state.handleOpen = props.handleOpen;
    this.state.commoditiesCombo = props.commoditiesCombo;
    this.state.dataDetailValidation = props.dataDetailValidation;
  }

  static contextType = SnackContext;
  context!: React.ContextType<typeof SnackContext>;

  componentDidMount() {
    this.setGridHeights();
  }

  getVarietiesByCommodity = async (commdityCode) => {
    try {
      if (commdityCode && commdityCode.length != 0) {
        const varieties = await VarietiesByCommodityMappedForComboValue(commdityCode).catch((error) => {
          const err = GenerateErrorMessage(error, "Error retrieving data - getVarietiesByCommodity");
          this.context.updateSnack({ show: true, color: "red", message: err });
        });
        if (varieties.length <= 0) {
          this.setState({ commoditiesComboObj: { ...this.state.commoditiesComboObj, [commdityCode]: [{ id: "", value: "", display: "" }] } });
        } else {
          this.setState({ commoditiesComboObj: { ...this.state.commoditiesComboObj, [commdityCode]: varieties } });
        }
      }
    } catch (error) {
      const err = GenerateErrorMessage(error, "Error retrieving data - getVarietiesByCommodity");
      this.context.updateSnack({ show: true, color: "red", message: err });
    }
  };

  getProducersByPuc = async (puc) => {
    try {
      const producers = await getProducerReadyForComboMappedForComboValue(puc);
      if (producers.length <= 0) {
        this.setState({ producersComboObj: { ...this.state.producersComboObj, [puc]: [{ id: "", value: "", data: [] }] } });
      } else {
        this.setState({ producersComboObj: { ...this.state.producersComboObj, [puc]: producers } });
      }
    } catch (error) {
      this.context.updateSnack({ show: true, color: "red", message: error });
    }
  };

  handleFileImportRowClick = (row) => {
    if (!this.state.currentRow || this.state.currentRow.CommodityCode != row.CommodityCode || this.state.currentRow.FarmNumber != row.FarmNumber) {
      const producers = this.state.producersComboObj[row.FarmNumber];
      const varieties = this.state.commoditiesComboObj[row.CommodityCode];
      this.setState({ producersCombo: producers, varietiesByCommodity: varieties, currentRow: row });
    }
  };

  RowRenderer = (props) => {
    return <Row {...props} onDoubleClickCapture={() => this.handleFileImportRowClick(props.row)} />;
  };

  HeaderInfoRowRenderer = (props) => {
    const farmNumber = props.row.FarmNumber;
    const CommodityCode = props.row.CommodityCode;
    const producersComboObj = this.state.producersComboObj;
    const commoditiesComboObj = this.state.commoditiesComboObj;

    if (!producersComboObj[farmNumber]) {
      producersComboObj[farmNumber] = farmNumber;
      this.getProducersByPuc(farmNumber);
    }

    if (!commoditiesComboObj[CommodityCode]) {
      commoditiesComboObj[CommodityCode] = CommodityCode;
      this.getVarietiesByCommodity(CommodityCode);
    }

    return <Row {...props} onDoubleClickCapture={() => this.handleFileImportRowClick(props.row)} />;
  };

  setGridHeights = () => {
    const primarysplit = document.getElementsByClassName("splitter horizontal");
    this.setState({
      minGridHeight: primarysplit[0].children[0].clientHeight - 70,
      minGridHeightSecond: primarysplit[0].children[2].clientHeight - 250,
    });
  };

  handleInfoClose = () => {
    if (this.state.dialoginformation.back == "1") {
      this.handleBack();
    }
    this.setState({ dialoginformation: undefined });
  };

  handleBack = () => {
    this.setState({ redirectBack: true });
  };

  postUpdatedRows = async () => {
    this.setState({ updating: true });
    try {
      const updatedInfoRowsArrs = this.state.updatedInfoRows
        .filter((item) => item.rowId)
        .map((item) => {
          delete item["edivalidationerrors"];
          return item;
        });
      await updateFileImportHeaderInfo(updatedInfoRowsArrs);
      this.setState({ updatedInfoRows: [], updating: false });
      this.state.handleReload();
    } catch (error) {
      this.context.updateSnack({ show: true, color: "red", message: error });
    }
  };

  handleUpdateInfoRows = (rowId, updated, infoArr) => {
    const index = infoArr.findIndex((item) => parseInt(item.rowId) === parseInt(rowId));

    if (index != -1) {
      infoArr[index] = { ...infoArr[index], ...updated };
    } else {
      infoArr.push({ rowId, ...updated });
    }
  };

  onGridRowsDetailUpdated = (_, rows, data) => {
    const dataRows = this.state.dataDetail;

    const updated = rows.reduce((arr, row) => {
      const rIndex = arr.findIndex((item) => item.id === row.id);

      if (rIndex >= 0) {
        if (data.column.key == "CommodityCode") {
          row = { ...row, VarietyCode: "" };
        }
        if (data.column.key == "ProducerID") {
          const findProducer = this.state.producersCombo.find((prod) => prod.data.code == row.ProducerID);
          if (findProducer) {
            row = { ...row, ProducerName: findProducer.data.name };
          }
        }

        arr[rIndex] = { ...arr[rIndex], ...row };
      }
      return arr;
    }, dataRows);

    const updatedInfoRows = rows.reduce((arr, row) => {
      const rIndex = arr.findIndex((item) => item.rowId === row.id);

      row["rowId"] = row.id;
      row["tableName"] = "FileImportHeaderInfo";

      delete row["id"];

      if (rIndex >= 0) {
        if (data.column.key == "ProducerID") {
          const findProducer = this.state.producersCombo.find((prod) => prod.data.code == row.ProducerID);
          if (findProducer) {
            row = { ...row, ProducerName: findProducer.data.name };
          }
        }
        arr[rIndex] = { ...arr[rIndex], ...row };
      } else {
        arr.push({ ...row });
      }
      return arr;
    }, this.state.updatedInfoRows);

    this.setState({ dataDetail: updated, updatedInfoRows });
  };

  onGridRowsUpdated = (_, rows, data) => {
    const column = data.column.key;
    const columnsList = ColumnsFileImportHeaderInfo(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined).map((item) => item.key);

    const index = data.indexes[0];
    const rowUpdated = rows[index];

    const arr = [...this.state.updatedInfoRows];
    const dataRows = this.state.data.slice();

    for (let i = 0; i < rows.length; i++) {
      const id = dataRows[i].id;
      const row = columnsList.includes(column) ? rowUpdated : rows[i];

      let updated = { [column]: row[column] };

      this.handleUpdateInfoRows(id, { ...updated, tableName: "FileImportHeader" }, arr);
      dataRows[i] = { ...dataRows[i], ...updated };
    }

    if (columnsList.includes(column)) {
      const rowsDetail = this.handleSharedColumnUpdate({ [column]: rowUpdated[column] }, arr);

      this.setState({ data: dataRows, dataDetail: rowsDetail, updatedInfoRows: arr });
    } //
    else {
      this.setState({ data: dataRows, updatedInfoRows: arr });
    }
  };

  handleSharedColumnUpdate = (updated, arr) => {
    const rowsDetail = this.state.dataDetail.slice();
    for (let i = 0; i <= this.state.dataDetail.length - 1; i++) {
      this.handleUpdateInfoRows(rowsDetail[i].id, { ...updated, tableName: "FileImportHeaderInfo" }, arr);
      rowsDetail[i] = { ...rowsDetail[i], ...updated };
    }
    return rowsDetail;
  };

  process = async () => {
    try {
      if (this.state.dataDetail && this.state.dataDetail.length > 0) {
        await this.processIntake();

        if (!isNullOrUndef(this.state.dataDetail[0].FileType) && this.state.dataDetail[0].FileType.toLowerCase() != "intake") {
          await execProcessDispatch(parseInt(this.state.dataDetail[0].FileImport_id));
        }
      }
      this.setState({ dialoginformation: { title: "Processed", body: "Data has been processed", back: "1" } });
    } catch (error) {
      const err = GenerateErrorMessage(error, "Error processing intake");
      this.context.updateSnack({ show: true, color: "red", message: err });
    }
  };

  processIntake = async () => {
    try {
      await execProcessIntake(parseInt(this.state.dataDetail[0].FileImport_id));
    } catch (error) {
      throw error;
    }
  };

  getCount = (list, propName) => {
    if (list.length > 0) {
      const value = list.reduce((acc, curr) => Number(acc) + Number(curr[propName]), 0);
      return Number.isInteger(value) ? value.toFixed(2) : value;
    } else {
      return 0;
    }
  };

  render() {
    const { classes } = this.props;

    if (this.state.redirectBack) return <Redirect to={`/edi/fileImports`} />;

    return (
      <div className={classes.root}>
        {this.state.dialoginformation && (
          <DialogInformation
            isOpen={true}
            handleClose={this.handleInfoClose}
            handleOK={this.handleInfoClose}
            title={this.state.dialoginformation.title}
            body={`${this.state.dialoginformation.body}`}
            showinput={false}
          />
        )}
        <div className={classes.control}>
          <Button color="secondary" variant="contained" onClick={this.postUpdatedRows} style={{ marginRight: "10px" }} disabled={this.state.updatedInfoRows.length <= 0}>
            save
            {this.state.updating && <CircularProgress color="primary" size={14} style={{ marginLeft: "10px" }} />}
          </Button>
          <Button color="primary" variant="contained" onClick={this.handleBack} style={{ marginRight: "10px" }}>
            back
          </Button>
        </div>
        <div id="main_splitter_div" style={{ height: "100%", width: "100%", display: "grid" }}>
          <Splitter
            postPoned
            dispatchResize
            position="horizontal"
            primaryPaneMaxHeight="calc(100% - 150px)"
            primaryPaneHeight="calc(100% - 800px)"
            primaryPaneWidth="100%"
            onDragFinished={() => this.setGridHeights()}
          >
            <div id="primary_grid" style={{ marginLeft: "1px" }}>
              {/* <Toolbar></Toolbar> */}
              <Grid
                fill
                data={this.state.data}
                rowRenderer={this.RowRenderer}
                clearFilters={"fileimportinfo"}
                GridColumns={ColumnsFileImportHeader}
                forceHeight={this.state.minGridHeight}
                handleChange={this.onGridRowsUpdated}
                totalRowColumns={["TotalCartons", "TotalPallets"]}
                countRowColumns={["FormattedDate"]}
              />
            </div>
            <div id="secondary_grid">
              <div className={classes.selectedHeader}>
                <Typography style={{ margin: "20px" }}>
                  <span style={{ fontSize: "21px" }}>{this.state.dataDetail.length}</span> Record(s) <span style={{ fontSize: "28px", lineHeight: 0 }}> | </span>
                  <span style={{ fontSize: "21px" }}>{numeral(this.getCount(this.state.dataDetail, "PalletSize")).format("0")}</span> Pallet(s){" "}
                  <span style={{ fontSize: "28px", lineHeight: 0 }}> | </span>
                  <span style={{ fontSize: "21px" }}>{numeral(this.getCount(this.state.dataDetail, "NoCartons")).format("0")}</span> Carton(s)
                </Typography>
              </div>
              <div style={{ position: "relative" }}>
                <Toolbar className={classes.toolbar}>
                  <></>
                  <div>
                    {this.state.data.length > 0 && this.state.data[0].FileType != "INTAKE" && (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => this.processIntake()}
                        style={{ marginRight: "10px" }}
                        disabled={this.state.dataDetailValidation.length > 0}
                      >
                        import stock
                      </Button>
                    )}
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => this.process()}
                      style={{ marginRight: "10px" }}
                      disabled={this.state.dataDetailValidation.length > 0}
                    >
                      Process
                    </Button>
                  </div>
                </Toolbar>
                <Grid
                  fill
                  showFilterChips
                  data={this.state.dataDetail}
                  clearFilters={"fileimportinfodetail"}
                  rowRenderer={this.HeaderInfoRowRenderer}
                  countRowColumns={["edivalidationerrors"]}
                  handleChange={this.onGridRowsDetailUpdated}
                  forceHeight={this.state.minGridHeightSecond}
                  totalRowColumns={["PalletSize", "NoCartons", "GrossWeight", "Weight"]}
                  GridColumns={(data, filters, arrangement, columnsWidth) =>
                    ColumnsFileImportHeaderInfo(
                      data,
                      filters,
                      arrangement,
                      columnsWidth,
                      this.state.commoditiesCombo,
                      this.state.varietiesByCommodity,
                      this.state.dataDetailValidation,
                      this.state.producersCombo,
                    )
                  }
                />
              </div>
            </div>
          </Splitter>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(ImportInfoGridUnstyled);
