import { apiHeaders, client, request } from "./_base";

export type CreditorsInvoiceDetailItemType = {
  id?: number;
  creditorsinvoice_id: number;
  sequence: number;
  group: number;
  code: string;
  chargedescription: string;
  rate: number;
  units: number;
  vat: number;
};

const endpoint = "creditorsinvoicedetailitems";

export const creditorsinvoicedetailitems = client(`${endpoint}`, (item) => ({
  ...item,
}));

export const createBatchDetailItems = async (data) => {
  const content = JSON.stringify(data);
  return request(`${endpoint}/ext/createBatch`, {
    method: "POST",
    headers: apiHeaders(content),
    body: content,
  }).then(async (result) => {
    if (result.status == 500) {
      const resp = await result.json();
      throw resp;
    }
    return result.json();
  });
};

export const creditorsinvoicedetailitemsFull = async () => {
  return await request(`${endpoint}/ext/full`)
    .then(async (result) => {
      if (result.status == 500) {
        const resp = await result.json();
        throw resp;
      }
      return result.json();
    })
    .then(({ data }) => data);
};

export const creditorsinvoicedetailitemsFullByInvoiceGroup = async (invoiceid, group) => {
  return await request(`${endpoint}/ext/fullByInvoiceGroup/${invoiceid}/${group}`)
    .then(async (result) => {
      if (result.status == 500) {
        const resp = await result.json();
        throw resp;
      }
      return result.json();
    })
    .then(({ data }) => data);
};

export const creditorsinvoicedetailitemsClearByInvoice = async (invoiceid) => {
  return await request(`${endpoint}/ext/clearByInvoiceID/${invoiceid}`)
    .then(async (result) => {
      if (result.status == 500) {
        const resp = await result.json();
        throw resp;
      }
      return result.json();
    })
    .then(({ data }) => data);
};

export const creditorsinvoicedetailitemsFullByInvoiceId = async (invoiceid) => {
  return await request(`${endpoint}/ext/fullByInvoiceId/${invoiceid}`)
    .then(async (result) => {
      if (result.status == 500) {
        const resp = await result.json();
        throw resp;
      }
      return result.json();
    })
    .then(({ data }) => data);
};

export const creditorsinvoicedetailitemsPreview = async (invoiceid) => {
  return await request(`${endpoint}/ext/preview/${invoiceid}`)
    .then(async (result) => {
      if (result.status == 500) {
        const resp = await result.json();
        throw resp;
      }
      return result.json();
    })
    .then(({ data }) => data);
};
