import React, { forwardRef, useEffect, useState } from "react";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";

import LinearProgress from "@material-ui/core/LinearProgress";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import MenuItem from "@material-ui/core/MenuItem";
import RadioGroup from "@material-ui/core/RadioGroup";

import { DebtorsFullType } from "../../../lib/api/debtorsfull";
import { getSaleAdjustmentApplyTypeReadyForCombo } from "../../../lib/api/saleadjustmenttypeapply";

import AdjustmentGrid from "./adjustmentgrid";

import { Field, Form } from "react-final-form";
import { TextField, Select, Radio } from "final-form-material-ui";

import numeral from "numeral";
import toDate from "date-fns/toDate";

import ReactDatePicker from "react-datepicker";
import {
  getProducerSaleAdjustmentByAdjustmentId,
  getProducerSaleAdjustmentBySaleId,
  InvoiceType,
  ProducerSaleAdjustmentBySaleIdType,
} from "../../../lib/api/producersaleadjustmentdetail";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1),
      width: "600px",
      height: "100%",
    },
    divActions: {
      float: "right",
    },
    container: {
      display: "grid",
      gridTemplateColumns: "repeat(1, 150px 350px)",
      gridTemplateRows: "repeat(4, 40px)",
      gridTemplateAreas: `
      "details"
      `,
      gridGap: "10px",
    },
    tableCellLabel: {
      width: "150px",
      borderBottom: "none",
      height: "50px",
      textAlign: "right",
      paddingTop: "5px",
    },
    tableCellLabelValue: {
      width: "350px",
      borderBottom: "none",
      height: "50px",
      textAlign: "left",
      paddingTop: "5px",
    },
    tableCellDetail: {
      width: "350px",
      borderBottom: "none",
      height: "50px",
    },
    tableCellsValueSelect: {
      textAlign: "left",
      width: "350px",
      borderBottom: "none",
      height: "50px",
      padding: 0,
      marginTop: "-14px",
    },
    tableCellDetailDate: {
      marginTop: "-5px",
      width: "350px",
      borderBottom: "none",
      height: "50px",
    },
  });

type AdjustmentProps = {
  selectedRecord: DebtorsFullType;
  handleProcess: (data, detail) => void;
} & WithStyles<typeof styles>;

const AdjustmentUnstyled: React.FunctionComponent<AdjustmentProps> = (props) => {
  const { classes, selectedRecord, handleProcess } = props;

  const [loading, setLoading] = useState(true);
  const [applyType, setApplyType] = useState(getSaleAdjustmentApplyTypeReadyForCombo());
  const [detail, setDetail] = useState<ProducerSaleAdjustmentBySaleIdType[]>([]);
  const [saleDate, setSaleDate] = useState(selectedRecord.sale_date ? new Date(selectedRecord.sale_date) : new Date());

  const loadData = async () => {
    if (selectedRecord.adjustment_id && selectedRecord.adjustment_id !== InvoiceType.Adjustment) {
      return await getProducerSaleAdjustmentByAdjustmentId(selectedRecord.adjustment_id);
    }
    return await getProducerSaleAdjustmentBySaleId(selectedRecord.sale_id);
  };

  useEffect(() => {
    setLoading(true);
    loadData().then((data) => {
      handleDetailUpdate(data);
    });

    setLoading(false);
  }, [selectedRecord]);

  const changeDateInvoice = (value, form) => {
    setSaleDate(new Date(value));
    form.change("sale_date", toDate(value));
  };

  const handleDetailUpdate = (detail) => {
    setDetail(detail);
  };

  return (
    <div className={classes.root}>
      {loading ? (
        <LinearProgress />
      ) : (
        <div>
          <Form
            initialValues={{
              ...selectedRecord,
              saleadjustment_type: selectedRecord.document_type == "2" ? "debit" : "credit",
              saleadjustment_typeapply: selectedRecord.document_typeapply,
              document_sourcetotal: numeral(selectedRecord.document_sourcetotal).format("0,0.00"),
              saleadjustment_amount: numeral(selectedRecord.document_type != "1" ? selectedRecord.sale_total : 0).format("0.00"),
            }}
            onSubmit={(values) => {
              handleProcess({ ...values, saleadjustment_type: values["saleadjustment_type"] == "debit" ? 0 : 1 }, detail);
            }}
            render={({ handleSubmit, form }) => (
              <form onSubmit={handleSubmit}>
                <div style={{ paddingBottom: "10px" }}>
                  <Button name="submit" type="submit" variant="contained" color="primary" style={{ margin: "2px" }} disabled={false}>
                    Save And Close
                  </Button>
                  <Button
                    name="close"
                    variant="contained"
                    color="secondary"
                    style={{ margin: "2px" }}
                    disabled={false}
                    onClick={() => {
                      handleProcess(undefined, undefined);
                    }}
                  >
                    Close
                  </Button>
                </div>
                <AdjustmentTypeGroup />
                <div style={{ display: "grid", gridTemplateRows: "1fr", gridTemplateColumns: "repeat(2, 1fr)" }}>
                  <div className={classes.container}>
                    <TableFieldText classes={classes} field="dispatch_invoicenumber" title="Sales Invoice Number" disabled={true} />

                    <TableFieldText classes={classes} field="document_sourcetotal" title="Sale Invoice Total" disabled={true} />
                    <TableFieldText classes={classes} field="vessel_code" title="Vessel" disabled={true} />
                    <TableFieldDate classes={classes} dateValue={new Date(selectedRecord.loadout_etd)} title="ETD" changeDate={(value) => changeDateInvoice(value, form)} />
                    <TableFieldDate classes={classes} dateValue={new Date(selectedRecord.loadout_eta)} title="ETA" changeDate={(value) => changeDateInvoice(value, form)} />
                  </div>
                  <div className={classes.container}>
                    <TableFieldDate classes={classes} dateValue={saleDate} title="Adjust Date" changeDate={(value) => changeDateInvoice(value, form)} />
                    <TableFieldText classes={classes} field="sale_invoicenumber" title="Invoice Number" disabled={true} />
                    <TableFieldText classes={classes} field="document_sourcetotal" title="Producer Invoice Total" disabled={true} />
                    <TableFieldCombo classes={classes} field="saleadjustment_typeapply" title="Debit/Credit Type" data={applyType} addEmptyValue={undefined} />
                    <TableFieldText classes={classes} field="saleadjustment_creditorinvoicenumber" title="Creditor Invoice Number" disabled={false} />
                    <TableFieldText classes={classes} field="saleadjustment_amount" title="Debit/Credit Amount" disabled={true} />
                  </div>
                </div>
                <div>
                  {form.getState().values.saleadjustment_typeapply == 2 && (
                    <AdjustmentGrid
                      sale_id={selectedRecord.sale_id}
                      adjustment_id={selectedRecord.dispatchdocs_id == -99 ? selectedRecord.sale_dispatch_id : -99}
                      handleDetailUpdate={(detail) => {
                        const newTotal = detail.reduce((prev, current) => {
                          if (!current) return 0;
                          return prev + current.saleadjustmentdetail_cartons * current.saleadjustmentdetail_amount;
                        }, 0.0);
                        form.change("saleadjustment_amount", newTotal);
                        handleDetailUpdate(detail);
                      }}
                    />
                  )}
                </div>
                <div className={classes.divActions}></div>
              </form>
            )}
          />
        </div>
      )}
    </div>
  );
};

export default withStyles(styles)(AdjustmentUnstyled);

const CalenderCustomInput = forwardRef((props: any, ref: any) => {
  return (
    <Button name="CalenderCustomInput" variant="contained" color="primary" onClick={props.onClick} style={{ marginTop: "5px", width: "250px" }}>
      {props.value}
    </Button>
  );
});

const TableFieldDate: React.FunctionComponent<{ dateValue: Date; title: string; changeDate: (value) => void } & WithStyles<typeof styles>> = (props) => {
  const { classes, dateValue, title, changeDate } = props;
  return (
    <>
      <span className={classes.tableCellLabel}>{`${title}:`}</span>
      <span className={classes.tableCellDetailDate}>
        <ReactDatePicker
          locale="en-GB"
          showWeekNumbers={true}
          selected={toDate(dateValue)}
          onChange={(value) => {
            changeDate(value);
          }}
          dateFormat="dd-MM-yyyy"
          placeholderText="click here to select a date"
          customInput={<CalenderCustomInput />}
        />
      </span>
    </>
  );
};

const TableFieldLabel: React.FunctionComponent<{ field: string; title: string; disabled: boolean } & WithStyles<typeof styles>> = (props) => {
  const { classes, field, title, disabled } = props;
  return (
    <>
      <span className={classes.tableCellLabel}>{`${title}:`}</span>
      <span className={classes.tableCellLabelValue}>{`${field}`}</span>
    </>
  );
};

const TableFieldText: React.FunctionComponent<{ field: string; title: string; disabled: boolean } & WithStyles<typeof styles>> = (props) => {
  const { classes, field, title, disabled } = props;
  return (
    <>
      <span className={classes.tableCellLabel}>{`${title}:`}</span>
      <span className={classes.tableCellDetail}>
        <Field name={field} component={TextField} type="text" fullWidth={true} disabled={disabled} />
      </span>
    </>
  );
};

const TableFieldCombo: React.FunctionComponent<{ classes: any; title: string; field: string; data: any; addEmptyValue: string } & WithStyles<typeof styles>> = (props) => {
  const { classes, title, field, data, addEmptyValue } = props;
  return (
    <>
      <span className={classes.tableCellLabel}>{`${title}:`}</span>
      <span className={classes.tableCellDetail}>
        <Field name={field} component={Select} formControlProps={{ className: classes.tableCellsValueSelect }}>
          {addEmptyValue && (
            <MenuItem key={`emptyvalueMenuItem`} value={-1}>
              {addEmptyValue}
            </MenuItem>
          )}
          {data &&
            data.map((item, index) => {
              return (
                <MenuItem key={`${item.value}${index}`} value={item.value}>
                  {item.display}
                </MenuItem>
              );
            })}
        </Field>
      </span>
    </>
  );
};

const AdjustmentTypeGroup = () => (
  <FormControl style={{ float: "left" }}>
    <RadioGroup>
      <FormControlLabel control={<Field type="radio" name="saleadjustment_type" value={"debit"} component={Radio} />} label="Debit" />
      <FormControlLabel control={<Field type="radio" name="saleadjustment_type" value={"credit"} component={Radio} />} label="Credit" />
    </RadioGroup>
  </FormControl>
);
