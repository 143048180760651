import { apiHeaders, client, request } from "./_base";

export type ProducerAdvancePaidType = {
  id?: number;
  barcode_id: number;
  advancecontracts_id: number;
  currency_id: number;
  cad: number;
  eur: number;
  gbp: number;
  usd: number;
  zar: number;
  created_at?: Date;
  produceradvancepaidhead_id: number;
};

const endpoint = "produceradvancepaid";

export const produceradvancepaid = client(endpoint, (item) => ({
  ...item,
}));

export type ProducerAdvancePaidBySaleIDType = {
  barcode: string;
  bar_producerid: number;
  paid_barcode_id: number;
  paid_currency_id: number;
  currency_code: string;
  paid_amount: number;
  paidhead_ident: number;
  paidhead_makeanote: string;
  week: string;
};

export const produceradvancepaidGetBySaleId = async (saleid: number) => {
  const result = await request(`${endpoint}/ext/getBySaleId/${saleid}`, {
    headers: apiHeaders(),
    method: "GET",
  });
  if (result.status == 500) {
    const resp = await result.json();
    throw resp;
  }
  return result.json();
};

export const producerAdvanceByContractId = async (contractId: number) => {
  const result = await request(`${endpoint}/ext/getByContractId/${contractId}`, {
    headers: apiHeaders(),
    method: "GET",
  });
  const resp = await result.json();
  if (result.status == 500) {
    throw resp;
  }
  return resp.data;
};
