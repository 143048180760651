import React from "react";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";

import AppBar from "@material-ui/core/AppBar";
import Badge from "@material-ui/core/Badge";
import Button from "@material-ui/core/Button";
import LinearProgress from "@material-ui/core/LinearProgress";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import TextField from "@material-ui/core/TextField";
import Toolbar from "@material-ui/core/Toolbar";

import BigNumber from "bignumber.js";
import { Row } from "react-data-grid";
import { Field, Form } from "react-final-form";

import SummaryView from "./summaryview";
import { GridColumns } from "./barcodesplitgridsetup";
import { EditGridColumns } from "./barcodespliteditgridsetup";
import { NewGridColumns } from "./barcodesplitnewgridsetup";

import "react-splitter-layout/lib/index";
import Splitter from "m-react-splitters";
import "m-react-splitters/lib/splitters.css";
import "../../lib/custom/splitter.css";

import Grid from "../../lib/components/grid";
import { SnackContext } from "../../lib/context/SnackContext";
import { stock } from "../../lib/api/stock";
import { barcodesplit, barcodessplitProcess, barcodesplitType, barcodeExists, BarcodeExists, deleteBarcodesByID } from "../../lib/api/barcodesplit";
import { OrangeButton, RedButton } from "../../lib/components/ColorButtons";
import ConfirmationDialog from "../../lib/components/confirmation";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      height: "calc(100vh - 100px)",
      position: "relative",
    },
    gridWrapper: {
      position: "relative",
      height: "100%",
    },
    gridToolbar: {
      position: "absolute",
      left: 0,
      top: 0,
      width: "100%",
      height: "60px",
      paddingLeft: "10px",
      paddingRight: "10px",
      display: "flex",
      alignItems: "center",
    },
    totalRowStyle: {
      background: "#ddd",
      textAlign: "right",
      fontWeight: "bold",
      color: "black",
    },
    totalRowStyleFixed: {
      position: "sticky",
      bottom: 0,
      background: "#ddd",
      textAlign: "right",
      fontWeight: "bold",
      color: "black",
    },
    lastRowStyle: {
      display: "flex",
      flexDirection: "column-reverse",
      color: "black",
    },
    boldRow: {
      fontWeight: "bold",
    },
    editRow: {
      "& div:nth-child(19)": {
        fontWeight: "bold",
        color: "green",
      },
      "& div:nth-child(20)": {
        fontWeight: "bold",
        color: "green",
      },
    },
    orangeRow: {
      "& div": {
        color: "orange",
      },
    },
    toolbarWrapper: {
      position: "relative",
    },
    toolbar: {
      position: "absolute",
      left: 0,
      width: "100%",
    },
  });

type BarcodeSplitProps = {} & WithStyles<typeof styles>;

const availableItems_tabIndex = 0;
const selectedItems_tabIndex = 1;

export class BarcodeSplitUnstyled extends React.Component<BarcodeSplitProps, any> {
  state = {
    classes: undefined,
    stock: [],
    loading: true,
    selectedRows: [],
    tabIndex: 0,
    newSplitBarcodes: [],
    minGridHeight: 0,
    minGridHeightSecond: 0,
    rowSelectedIdx: undefined,
    columnSelectedIdx: undefined,
    columnsLength: undefined,
    isOpen: false,
    formRef: undefined,
    showSummaryView: false,
    summaryData: [],
    submittingNewStock: false,
    barcodeExistsConfirmationOpen: false,
    newBarcode: "",
    newGrossWeight: "",
    existingBarcodeData: [],
    confirmDelete: false,
  };

  constructor(props) {
    super(props);
    this.state.classes = props.classes;
    this.state.formRef = React.createRef();
  }

  static contextType = SnackContext;
  context!: React.ContextType<typeof SnackContext>;

  componentDidMount() {
    this.loadData();
  }

  loadData = async () => {
    this.setState({ loading: true });
    return await stock.all().then((stockData) => {
      stockData.map((item) => {
        if (item.qx_deleted == "0") {
          return { ...item, age: item.age ? item.age : 0, ageAtLocation: item.ageAtLocation ? item.ageAtLocation : 0, exists: false };
        }
        return { ...item, exists: false };
      });
      this.setState({ stock: stockData, loading: false }, () => {
        this.setGridHeights();
      });
    });
  };

  a11yProps(index: any) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  handleTabChange = (event: React.ChangeEvent<{}>, newTabIndexValue: number) => {
    this.setState({ tabIndex: newTabIndexValue });
  };

  calculatePalletSize = (data, row) => {
    const total_cartons = data.reduce((res, item) => {
      if (!isNaN(item.cartons_to_split)) {
        res = res + parseFloat(item.cartons_to_split);
      }
      return res;
    }, 0);
    const cartons = new BigNumber(1).dividedBy(new BigNumber(total_cartons));
    const result = new BigNumber(row.cartons_to_split).times(cartons).toNumber();
    return result;
  };

  calculateGrossWeight = (data, row) => {
    const total_cartons = data.reduce((res, item) => {
      res = new BigNumber(res)
        .plus(new BigNumber(item.noCartons || 0))

        .toNumber();
      return res;
    }, 0);

    const cartons = new BigNumber(row.newWeight || 0).dividedBy(new BigNumber(total_cartons));
    const result = new BigNumber(row.noCartons || 0).times(cartons).toNumber();
    return result;
  };

  handleRowSelect = (row) => {
    const exists = this.state.selectedRows.find((item) => item.barcode == row.barcode);
    if (exists) {
      const selectedRowsFiltered = this.state.selectedRows.filter((item) => item.barcode != row.barcode);
      // Need to do seperate functions (filter & map) to calculate new pallet sizes
      const newSplitBarcodesFiltered = this.state.newSplitBarcodes.filter((item) => item.old_barcode != row.barcode);
      const newSplitBarcodesData = newSplitBarcodesFiltered.map((item) => ({
        ...item,
        palletSize: this.calculatePalletSize(newSplitBarcodesFiltered, item),
        grossWeight: this.calculateGrossWeight(newSplitBarcodesFiltered, item),
      }));

      this.setState({ selectedRows: selectedRowsFiltered, newSplitBarcodes: newSplitBarcodesData });
      this.handleApplyBarcode({ barcode: this.state.newBarcode, newWeight: this.state.newGrossWeight }, newSplitBarcodesData);
    } else {
      const rowData = this.state.stock
        .filter((item) => item.barcode == row.barcode)
        .map((item) => ({
          ...item,
          exists: item.barcode == row.barcode ? row.exists : item.exists,
        }));

      const data = [...this.state.selectedRows, ...rowData].map((item) => ({ ...item, original_noCartons: item.noCartons }));
      const newSplitRow = rowData.map((item) => {
        return {
          ...item,
          barcode: this.state.newBarcode,
          old_barcode: item.barcode,
          noCartons: item.exists ? item.noCartons : "",
          grossWeight: item.exists ? item.grossWeight : "",
          palletSize: item.exists ? item.palletSize : "",
          newWeight: this.state.newGrossWeight,
        };
      });
      const newSplitBarcodesData = [...this.state.newSplitBarcodes, ...newSplitRow];
      this.setState({ selectedRows: data, newSplitBarcodes: newSplitBarcodesData });
      this.handleApplyBarcode({ barcode: this.state.newBarcode, newWeight: this.state.newGrossWeight }, newSplitBarcodesData);
    }
  };

  clearSelected = () => {
    this.setState({ selectedRows: [], newSplitBarcodes: [], tabIndex: 0 });
  };

  handleChange = (property, values: any[]) => {
    const selectedRowsData = values.map((item) => {
      if (item.exists) {
        return {
          ...item,
          new_weight: 0,
          cartons_to_split: 0,
          noCartons: item.noCartons,
          grossWeight: item.grossWeight,
        };
      } else {
        return {
          ...item,
          grossWeight: item.new_weight || item.grossWeight,
          noCartons: new BigNumber(item.original_noCartons).minus(new BigNumber(item.cartons_to_split || 0)).toNumber(),
        };
      }
    });

    const newSplitBarcodesData = values.map((row) => ({
      ...row,
      old_barcode: row.barcode,
      barcode: this.state.newBarcode,
      newWeight: this.state.newGrossWeight,
      noCartons: row.exists ? row.noCartons : row.cartons_to_split,
      cartons_to_split: row.exists ? row.noCartons : row.cartons_to_split,
    }));

    const calculated = newSplitBarcodesData.map((row) => ({
      ...row,
      palletSize: this.calculatePalletSize(newSplitBarcodesData, row),
      grossWeight: this.calculateGrossWeight(newSplitBarcodesData, row),
    }));

    this.setState({ newSplitBarcodes: calculated, selectedRows: selectedRowsData });
  };

  setGridHeights = () => {
    const primarysplit = document.getElementsByClassName("splitter horizontal");
    this.setState({ minGridHeight: primarysplit[0].children[0].clientHeight - 70, minGridHeightSecond: primarysplit[0].children[2].clientHeight - 120 });
  };

  handleApplyBarcode = (values: { barcode: string; newWeight: any }, splitRows: any[]) => {
    const data = splitRows.map((row) => ({
      ...row,
      barcode: values.barcode,
      grossWeight: this.calculateGrossWeight(splitRows, { ...row, newWeight: values.newWeight }),
    }));
    this.setState({ newSplitBarcodes: data });
  };

  handleSelectedRowChange = (row, column) => {
    this.setState({ rowSelectedIdx: row.rowIdx, columnSelectedIdx: column.selectedColumn, columnsLength: column.columnsLength - 1 }); // minus one because index starts at 0
  };

  handleKeyDown = (event, gridRef) => {
    const enterKey = 13;
    const tabKey = 9;

    if (event.which === enterKey || event.which === tabKey) {
      event.preventDefault();

      const lastEditableColumn = this.state.columnsLength; // minus two because last two rows are not editable
      const tagName = event.target.tagName && event.target.tagName.toLowerCase();

      if (tagName == "div") {
        gridRef.selectCell({ idx: this.state.columnSelectedIdx, rowIdx: this.state.rowSelectedIdx }, true);
      }
      //
      else {
        if (this.state.columnSelectedIdx == lastEditableColumn) {
          this.setState({ rowSelectedIdx: this.state.rowSelectedIdx + 1, columnSelectedIdx: 16 });
          setTimeout(() => {
            gridRef.selectCell({ idx: 16, rowIdx: this.state.rowSelectedIdx }, true);
          }, 200);
        }
        //
        else {
          this.setState({ columnSelectedIdx: this.state.columnSelectedIdx + 1 });
          setTimeout(() => {
            gridRef.selectCell({ idx: this.state.columnSelectedIdx, rowIdx: this.state.rowSelectedIdx }, true);
          }, 200);
        }
      }
    }
  };

  resetForm = () => {
    if (this.state.formRef) {
      this.state.formRef.reset();
      this.state.formRef.resetFieldState("barcode");
      this.state.formRef.resetFieldState("new_weight");
    }
    this.setState({ newBarcode: undefined, newGrossWeight: undefined, existingBarcodeData: [] });
  };

  handleGenerateStock = async () => {
    this.setState({ loading: true, summaryData: this.state.newSplitBarcodes, submittingNewStock: true });
    const promiseArr = [];

    this.state.newSplitBarcodes.map((item, idx) => {
      const dataToPush: { data: barcodesplitType } = {
        data: {
          barcode: item.barcode,
          weight: item.grossWeight,
          cartons: item.exists ? 0 : item.noCartons,
          barcode_id: item.barcode_id,
          updateweight: item.exists ? item.grossWeight : item.new_weight,
          sequence: idx + 1,
          pallet_size: item.palletSize,
        },
      };

      promiseArr.push(
        new Promise((res, rej) => {
          barcodesplit
            .create(dataToPush)
            .then(() => {
              res("ok");
            })
            .catch((err) => {
              rej(err);
            });
        }),
      );
    });

    Promise.all(promiseArr)
      .then(() => {
        barcodessplitProcess()
          .then(() => {
            this.setState({ showSummaryView: true, selectedRows: [], newSplitBarcodes: [], tabIndex: 0, submittingNewStock: false });
            this.resetForm();
            this.loadData();
          })
          .catch((err) => {
            this.context.updateSnack({ show: true, color: "red", message: err });
          });
      })
      .catch((err) => {
        this.context.updateSnack({ show: true, color: "red", message: err });
      })
      .finally(() => {
        this.setState({ selectedRows: [], newSplitBarcodes: [], tabIndex: 0, loading: false });
      });
  };

  rowRenderer = (props, classes) => {
    const { row } = props;
    const fontWeight = this.state.selectedRows && this.state.selectedRows.find((item) => item.barcode == row.barcode) && classes.boldRow;

    return <Row {...props} className={fontWeight} onDoubleClickCapture={() => this.handleRowSelect({ ...row, exists: false })} />;
  };

  editRowRenderer = (props, classes) => {
    const { row } = props;
    const existsStyle = !row.exists ? classes.editRow : classes.orangeRow;
    return <Row {...props} className={existsStyle} />;
  };

  newBarcodesRowRenderer = (props, classes) => {
    const { row } = props;
    const existsStyle = row.exists && classes.orangeRow;
    return <Row {...props} className={existsStyle} />;
  };

  availableFilteredRows: any[];
  handleAvailableFilteredRows = (rows: any[]) => {
    this.availableFilteredRows = rows;
  };

  availableSelectAll = () => {
    const { selectedRows, newSplitRows } = this.availableFilteredRows.reduce(({ selectedRows, newSplitRows }, item) => {
      selectedRows.push({ ...item, original_noCartons: item.noCartons });
      newSplitRows.push({
        ...item,
        barcode: this.state.newBarcode,
        old_barcode: item.barcode,
        noCartons: "",
        grossWeight: "",
        palletSize: "",
        newWeight: this.state.newGrossWeight,
      })
      return { selectedRows, newSplitRows };
    }, {
      selectedRows: [],
      newSplitRows: []
    });

    this.setState({ selectedRows: [...this.state.selectedRows, ...selectedRows], newSplitBarcodes: [...this.state.newSplitBarcodes, ...newSplitRows] });
  };

  clearAvailableSelectedRows = () => {
    this.setState({ selectedRows: [], newSplitBarcodes: [], existingBarcodeData: [] });
  };

  submitGenerateStock = () => {
    const noCartonsToSplit = this.state.newSplitBarcodes.filter((item) => !item.noCartons || item.noCartons == 0);

    if (noCartonsToSplit.length > 0) {
      this.setState({ isOpen: true });
    } else {
      this.handleGenerateStock();
    }
  };

  handleCloseConfirmation = () => {
    this.setState({ isOpen: false });
  };

  handleConfirmConfirmation = () => {
    this.handleCloseConfirmation();
    this.handleGenerateStock();
  };

  handleCloseSummaryView = () => {
    this.setState({ showSummaryView: false, summaryData: [] });
  };

  handleCloseBarcodeExistsConfirmation = () => {
    this.handleApplyBarcode({ barcode: this.state.newBarcode, newWeight: this.state.newGrossWeight }, this.state.newSplitBarcodes);
    this.setState({ barcodeExistsConfirmationOpen: false });
  };

  handleConfirmBarcodeExistsConfirmation = () => {
    this.state.existingBarcodeData.forEach((row) => {
      this.handleRowSelect(row);
    });
    this.setState({ barcodeExistsConfirmationOpen: false, existingBarcodeData: [] });
  };

  fruitSpecCompare = (itemA, itemB) => {
    if (
      itemA["barcode"] == itemB["barcode"] &&
      itemA["commodityCode"] == itemB["commodityCode"] &&
      itemA["varietyCode"] == itemB["varietyCode"] &&
      itemA["producerId"] == itemB["producerId"] &&
      itemA["farmNumber"] == itemB["farmNumber"] &&
      itemA["gradeCode"] == itemB["gradeCode"] &&
      itemA["countCode"] == itemB["countCode"] &&
      itemA["packCode"] == itemB["packCode"] &&
      itemA["markCode"] == itemB["markCode"] &&
      itemA["noCartons"] == itemB["noCartons"] &&
      itemA["organization"] == itemB["organization"]
    ) {
      return true;
    }
    return false;
  };

  handleDeleteSelectedBarcodes = () => {
    this.setState({ confirmDelete: true });
  };

  handleCloseConfirmDelete = () => {
    this.setState({ confirmDelete: false });
  };

  handleConfirmDeleteSelectedBarcodes = async () => {
    try {
      this.setState({ loading: true, confirmDelete: false });
      const barcodesToDelete = this.state.selectedRows.map((barcode) => barcode.barcode);
      await deleteBarcodesByID(barcodesToDelete);
      this.setState({ selectedRows: [], newSplitBarcodes: [] });
      await this.loadData();
    } catch (error) {
      this.context.updateSnack({ show: true, color: "red", message: error });
    } finally {
      this.setState({ loading: false, confirmDelete: false });
    }
  };

  render() {
    const { classes } = this.state;

    return (
      <div className={classes.root}>
        {this.state.submittingNewStock || this.state.loading ? <LinearProgress color="secondary" style={{ marginBottom: "5px" }} /> : <div style={{ height: "10px" }}></div>}
        <ConfirmationDialog
          isOpen={this.state.isOpen}
          handleClose={this.handleCloseConfirmation}
          handleConfirm={this.handleConfirmConfirmation}
          title="Generate Stock Confirmation"
          body={"There are data with no cartons to split. Is this correct?"}
        />
        <ConfirmationDialog
          isOpen={this.state.barcodeExistsConfirmationOpen}
          handleClose={this.handleCloseBarcodeExistsConfirmation}
          handleConfirm={this.handleConfirmBarcodeExistsConfirmation}
          title="Barcode exists"
          body={"Data with this barcode exists, would you like to add this to your current list?"}
        />
        {this.state.confirmDelete && (
          <ConfirmationDialog
            isOpen={true}
            handleClose={this.handleCloseConfirmDelete}
            handleConfirm={this.handleConfirmDeleteSelectedBarcodes}
            title="Confirm Delete"
            body={"Are you sure you want to delete the selected barcodes?"}
          />
        )}
        <ConfirmationDialog
          fullscreen
          body={""}
          disableBackdropClick
          isOpen={this.state.showSummaryView}
          handleClose={this.handleCloseSummaryView}
          handleConfirm={this.handleCloseSummaryView}
          title="New Barcodes Split Summary"
        >
          <SummaryView newSplitBarcodes={this.state.summaryData} handleClose={this.handleCloseSummaryView} />
        </ConfirmationDialog>
        <div id="main_splitter_div" style={{ height: "100%", width: "100%", display: "grid" }}>
          <Splitter
            position="horizontal"
            primaryPaneMaxHeight="calc(100% - 150px)"
            primaryPaneHeight="calc(100% - 600px)"
            dispatchResize={true}
            postPoned={true}
            onDragFinished={(e) => {
              this.setGridHeights();
            }}
          >
            <div className={classes.gridWrapper}>
              <div className={classes.gridToolbar}>
                <Form
                  onSubmit={async (values: any) => {
                    this.setState({ newBarcode: values.barcode, newGrossWeight: values.new_weight });

                    const newBarcodeExists = this.state.stock
                      .filter((item) => {
                        if (item.barcode.toString().trim() == values.barcode.toString().trim()) {
                          return item;
                        }
                      })
                      .reduce((arr, item) => {
                        const itemInList = this.state.newSplitBarcodes.find((row) => this.fruitSpecCompare(row, item));
                        if (!itemInList) {
                          arr.push({ ...item, exists: true });
                        }
                        return arr;
                      }, []);

                    if (newBarcodeExists.length > 0) {
                      this.setState({ barcodeExistsConfirmationOpen: true, existingBarcodeData: newBarcodeExists });
                    } else {
                      this.handleApplyBarcode({ barcode: values.barcode, newWeight: values.new_weight }, this.state.newSplitBarcodes);
                    }
                  }}
                  render={({ handleSubmit, form }: any) => (
                    <form ref={(this.state.formRef = form)} onBlur={handleSubmit} style={{ display: "flex" }}>
                      <div style={{ marginRight: "10px" }}>
                        <Field
                          name="barcode"
                          validate={(value) => (value ? undefined : "Barcode ID is required")}
                          render={({ input, meta }) => (
                            <div style={{ display: "flex", flexDirection: "column" }}>
                              <TextField
                                {...input}
                                variant="filled"
                                placeholder="New Barcode ID"
                                inputProps={{ style: { padding: "10px 12px 10px", width: "250px" } }}
                                InputLabelProps={{ style: { transform: "translate(12px, 12px) scale(1)" } }}
                              />
                              {meta.touched && meta.error && <span style={{ color: "red", fontSize: "13px" }}>{meta.error}</span>}
                            </div>
                          )}
                        />
                      </div>
                      <div>
                        <Field
                          name="new_weight"
                          render={({ input }) => (
                            <TextField
                              {...input}
                              variant="filled"
                              placeholder="Gross Weight"
                              inputProps={{ style: { padding: "10px 12px 10px", width: "250px" } }}
                              InputLabelProps={{ style: { transform: "translate(12px, 12px) scale(1)" } }}
                            />
                          )}
                        />
                      </div>
                      <div>
                        <Button
                          color="primary"
                          variant="contained"
                          onClick={this.submitGenerateStock}
                          disabled={this.state.loading || this.state.newSplitBarcodes.length === 0}
                          style={{ float: "right", marginLeft: "10px" }}
                        >
                          Generate Stock
                        </Button>
                      </div>
                    </form>
                  )}
                />
              </div>
              <Grid
                clearFilters={"barcodesplitnew"}
                GridColumns={NewGridColumns}
                loading={this.state.loading}
                data={this.state.newSplitBarcodes}
                forceHeight={this.state.minGridHeight}
                rowRenderer={(props) => this.newBarcodesRowRenderer(props, classes)}
                totalRowColumns={["noCartons", "grossWeight", "weight", "cartons_to_split", "new_weight", "palletSize"]}
              />
            </div>
            <div style={{ position: "relative", height: "100%" }}>
              <AppBar position="static">
                <Tabs value={this.state.tabIndex} onChange={this.handleTabChange} aria-label="Stock Allocation">
                  <Tab label="Available" {...this.a11yProps(availableItems_tabIndex)} />
                  <Tab
                    label={
                      <Badge badgeContent={this.state.selectedRows.length} color="secondary">
                        Selected
                      </Badge>
                    }
                    {...this.a11yProps(selectedItems_tabIndex)}
                  />
                </Tabs>
              </AppBar>
              <div hidden={this.state.tabIndex == 0 ? false : true} className={classes.toolbarWrapper}>
                <Toolbar className={classes.toolbar}>
                  <OrangeButton onClick={this.availableSelectAll} variant="contained" disabled={this.state.stock.length === 0}>
                    Select All
                  </OrangeButton>
                  <OrangeButton onClick={this.clearAvailableSelectedRows} variant="contained" disabled={this.state.selectedRows.length === 0}>
                    Clear Selected
                  </OrangeButton>
                  <RedButton onClick={this.handleDeleteSelectedBarcodes} variant="contained" disabled={this.state.selectedRows.length === 0}>
                    Delete Selected
                  </RedButton>
                </Toolbar>
                <Grid
                  clearFilters={"barcodesplit"}
                  data={this.state.stock}
                  GridColumns={GridColumns}
                  loading={this.state.loading}
                  forceHeight={this.state.minGridHeightSecond}
                  totalRowColumns={["noCartons", "grossWeight", "palletSize"]}
                  rowRenderer={(props) => this.rowRenderer(props, classes)}
                  handleFilteredRows={this.handleAvailableFilteredRows}
                />
              </div>
              <div hidden={this.state.tabIndex == 1 ? false : true}>
                <div className={classes.gridWrapper}>
                  <div className={classes.gridToolbar}>
                    <OrangeButton variant="contained" onClick={this.clearSelected} style={{ float: "right" }} disabled={this.state.selectedRows.length === 0}>
                      Clear Selected
                    </OrangeButton>
                  </div>
                  <Grid
                    fill
                    clearFilters={"barcodesplitedit"}
                    GridColumns={EditGridColumns}
                    loading={this.state.loading}
                    data={this.state.selectedRows}
                    handleChange={this.handleChange}
                    forceHeight={this.state.minGridHeightSecond}
                    selectedRowChange={this.handleSelectedRowChange}
                    handleGridKeyDown={this.handleKeyDown}
                    rowRenderer={(props) => this.editRowRenderer(props, classes)}
                    totalRowColumns={["noCartons", "grossWeight", "weight", "cartons_to_split", "new_weight", "palletSize"]}
                  />
                </div>
              </div>
            </div>
          </Splitter>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(BarcodeSplitUnstyled);
