import { client, request } from "./_base";

export type CreditorsType = {
  id: number;
  code: string;
  name: string;
  physical_address: string;
  postal_address: string;
  telephone: string;
  email: string;
  vat_number: number;
  creditorsgroupsub_id: number;
  contact_person: string;
  currency_id: number;
  payment_term_id: number;
  consolidationapply: number;
};

const endpoint = "creditors";

export const creditors = client(`${endpoint}`, (item) => ({
  ...item,
}));

export type CreditorsFullType = {
  id: number;
  code: string;
  name: string;
  physical_address: string;
  postal_address: string;
  telephone: string;
  email: string;
  vat_number: number;
  creditorsgroupsub_id: number;
  creditorsgroupsub_group: string;
  creditorsgroupsub_description: string;
  contact_person: string;
  currency_id: number;
  currency_code: string;
  consolidationapply: number;
};
export const creditorsFull = async () => {
  return await request(`${endpoint}/ext/full`)
    .then(async (result) => {
      if (result.status == 500) {
        const resp = await result.json();
        throw resp;
      }
      return result.json();
    })
    .then(({ data }) => data);
};

export const getCreditorsReadyForCombo = async () => {
  return await request(`${endpoint}/ext/activeCreditorsFull`)
    .then(async (result) => {
      if (result.status == 500) {
        const resp = await result.json();
        throw resp;
      }
      return result.json();
    })
    .then((result) => {
      const ready: [CreditorsType] = result.data
        .sort((a, b) => a.name.localeCompare(b.name))
        .map((cred) => {
          return { value: cred.id, display: cred.name, data: cred };
        });
      return ready;
    });
};

export const getCreditorByCode = async (creditor_code: string) => {
  const result = await request(`${endpoint}/ext/getCreditorByCode/${creditor_code}`);
  const resp = await result.json();
  if (result.status == 500) {
    throw resp;
  }
  return resp.data;
};

export const getCreditorById = async (creditor_id: number) => {
  const result = await request(`${endpoint}/ext/getCreditorById/${creditor_id}`);
  const resp = await result.json();
  if (result.status == 500) {
    throw resp;
  }
  return resp.data;
};
