import { string } from "prop-types";
import { client, request } from "./_base";

const endpoint = "consolidationdataqueue";

export const consolidation = client(endpoint, (item) => ({
  ...item,
}));

export const getCount = async () => {
  return await request(`${endpoint}/ext/count`)
    .then((result) => {
      if (result.status == 500) {
        throw result.statusText;
      }
      return result.json();
    })
    .then(({ data }) => data);
};
