import React from "react";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";

import Grid from "../../lib/components/grid";
import Confirmation from "../../lib/components/confirmation";

import { getProducerPucsGroupedOrchardsByProducerId, pucs } from "../../lib/api/producerpuc";
import { GridColumns } from "./pucsgridsetup";

import Tooltip from "@material-ui/core/Tooltip";
import Button from "@material-ui/core/Button";
import Toolbar from "@material-ui/core/Toolbar";

import IconAdd from "@material-ui/icons/Add";

import PucsEditForm from "./pucseditform";
import { orchards } from "../../lib/api/producerorchard";

import OrchardsEditForm from "../producersorchards/orchardseditform";
import { Row } from "react-data-grid";
import { GenerateErrorMessage } from "../../lib/helpers/string_methods";
import { upsertProducer } from "../../lib/api/producer";
import { SnackContext } from "../../lib/context/SnackContext";
import { whoami } from "../../lib/api/_base";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1),
      width: "700px",
      height: "100%",
      position: "relative",
      paddingTop: 0,
      marginTop: "-3px",
    },
    inline: {
      display: "inline",
      position: "absolute",
      marginTop: "7px",
      marginLeft: "7px",
    },
    pucs_table: {
      position: "relative",
    },
    clearfix: {
      clear: "both",
    },
    headerStyle: {
      fontSize: "18px",
      fontWeight: "bold",
      margin: "5px",
    },
    toolbar: {
      position: "absolute",
      left: 0,
      top: 0,
      height: "60px",
      width: "100%",
      display: "flex",
      flexDirection: "row",
      gap: "10px",
    },
    greyRow: {
      backgroundColor: "#f6f6f6",
    },
    normalRow: {
      backgroundColor: "transparent",
      fontWeight: "bold",
    },
  });

type PucsProps = {
  producerid: any;
  handleRefresh: () => void;
} & WithStyles<typeof styles>;

export class PucsUnstyled extends React.Component<PucsProps, any> {
  state = {
    classes: undefined,
    data: [],
    confirmRemovePucID: undefined,
    confirmEditPucID: undefined,
    producerid: undefined,
    loading: true,
    selectedPUCID: undefined,
    allPucs: [],
    allOrchards: [],
    confirmRemoveOrchardID: undefined,
    confirmEditOrchardID: undefined,
  };

  constructor(props) {
    super(props);
    this.state.classes = props.classes;

    if (props.producerid) {
      this.state.producerid = props.producerid;
    }
  }

  static contextType = SnackContext;
  context!: React.ContextType<typeof SnackContext>;

  componentDidMount() {
    this.loadData();
  }

  componentWillReceiveProps(props) {
    this.setState({ producerid: props.producerid }, () => {
      this.loadData();
    });
  }

  loadData = async () => {
    this.setState({ loading: true });

    const pucsGrouped = await getProducerPucsGroupedOrchardsByProducerId(this.props.producerid).then((data) => {
      return data.map((item) => ({ ...item, producerpuc_code: item.producerorchard_id ? "" : item.producerpuc_code }));
    });

    const allPucs = await pucs.all().then((data) => {
      return data.sort((a, b) => {
        if (a.code > b.code) return 1;
        if (a.code < b.code) return -1;
        return 1;
      });
    });

    const allOrchards = await orchards.all();

    this.setState({ loading: false, data: pucsGrouped, allPucs: allPucs, allOrchards: allOrchards });
  };

  handleCreatePuc = () => {
    this.setState({ confirmEditPucID: "0" });
  };

  handleEditPuc = (id) => {
    this.setState({ confirmEditPucID: id });
  };

  handleEditPucClose = () => {
    this.setState({ confirmEditPucID: undefined });
  };

  handleEditPucConfirm = async (item) => {
    const id = item["id"];
    delete item["id"];

    item.producer_id = this.props.producerid;

    if (id == "0") {
      await pucs.create({ data: { ...item } });
    } else {
      await pucs.update(id, { data: { ...item } });
    }
    this.loadData();
    this.handleEditPucClose();
    this.props.handleRefresh();
  };

  handlePucRemove = async (id) => {
    this.setState({ confirmRemovePucID: id });
  };

  handlePucRemoveClose = () => {
    this.setState({ confirmRemovePucID: undefined });
  };

  handlePucRemoveConfirm = async () => {
    await pucs.remove(this.state.confirmRemovePucID).then(() => {
      this.handlePucRemoveClose();
      this.loadData();
    });
  };

  handleCreateOrchard = () => {
    this.setState({ confirmEditOrchardID: "0" });
  };

  handleOrchardEdit = (id) => {
    this.setState({ confirmEditOrchardID: id });
  };

  handleOrchardEditClose = () => {
    this.setState({ confirmEditOrchardID: undefined });
  };

  handleOrchardEditConfirm = async (item) => {
    const id = item["id"];
    const dataToSend = {
      data: {
        puc_id: parseInt(item.puc_id),
        code: item.code,
        variety_id: parseInt(item.variety_id) || 0,
        ha: parseInt(item.ha) || 0,
      },
    };
    if (id == 0) {
      await orchards.create(dataToSend).then(() => this.loadData());
    } else {
      await orchards.update(id, dataToSend).then(() => this.loadData());
    }
    this.handleOrchardEditClose();
    this.props.handleRefresh();
  };

  handleOrchardRemove = async (id) => {
    this.setState({ confirmRemoveOrchardID: id });
  };

  handleOrchardRemoveClose = () => {
    this.setState({ confirmRemoveOrchardID: undefined });
  };

  handleOrchardRemoveConfirm = async () => {
    await orchards.remove(this.state.confirmRemoveOrchardID).then(() => {
      this.handleOrchardRemoveClose();
      this.loadData();
    });
  };

  handleEditRow = (pucId: number, orchardId: number) => {
    this.setState({ confirmEditPucID: pucId, confirmEditOrchardID: orchardId });
  };

  handleRemoveRow = (pucId: number, orchardId: number) => {
    this.setState({ confirmRemovePucID: pucId, confirmRemoveOrchardID: orchardId });
  };

  GridColumnsOverride = (column, row) => {
    return GridColumns(column, row, this.handleRemoveRow, this.handleEditRow);
  };

  handleSelectedPUCChange = (puc) => {
    this.setState({ selectedPUCID: puc.id });
  };

  rowRenderer = (props) => {
    const { classes } = this.state;
    const { row } = props;
    const rowColor = row.producerorchard_id ? classes.greyRow : classes.normalRow;

    return <Row {...props} className={rowColor} />;
  };

  render() {
    const { classes } = this.state;

    return (
      <div className={classes.root}>
        {this.state.producerid && this.state.producerid == "0" ? (
          <div>New Producer. Cannot add puc</div>
        ) : (
          <div>
            <div className={classes.pucs_table}>
              <Toolbar className={classes.toolbar}>
                <Tooltip title="Create new PUC">
                  <div>
                    <Button variant="contained" color="primary" onClick={this.handleCreatePuc}>
                      <IconAdd /> PUC
                    </Button>
                  </div>
                </Tooltip>
                <Tooltip title="Create new ORCHARD">
                  <div>
                    <Button variant="contained" color="primary" onClick={this.handleCreateOrchard} disabled={this.state.data.length === 0}>
                      <IconAdd /> ORCHARD
                    </Button>
                  </div>
                </Tooltip>
              </Toolbar>
              {!this.state.loading && (
                <Grid
                  selectedRowChange={this.handleSelectedPUCChange}
                  loading={this.state.loading}
                  data={this.state.data}
                  GridColumns={this.GridColumnsOverride}
                  clearFilters={"pucsgridsetup"}
                  rowRenderer={this.rowRenderer}
                  forceHeight={700}
                />
              )}
            </div>
            {this.state.confirmRemovePucID && (
              <Confirmation
                isOpen={this.state.confirmRemovePucID ? true : false}
                handleClose={this.handlePucRemoveClose}
                handleConfirm={this.handlePucRemoveConfirm}
                title="Remove selected PUC?"
                body="Are you sure you want to REMOVE the selected PUC Record?"
              ></Confirmation>
            )}
            {this.state.confirmEditPucID && (
              <Confirmation
                isOpen={this.state.confirmEditPucID ? true : false}
                handleClose={this.handleEditPucClose}
                handleConfirm={() => {}}
                title={this.state.confirmEditPucID == "0" ? "Creating PUC" : "Editing PUC"}
                body={undefined}
              >
                <PucsEditForm
                  onSubmit={this.handleEditPucConfirm}
                  onClose={this.handleEditPucClose}
                  id={this.state.confirmEditPucID}
                  pucs={this.state.allPucs}
                  producerId={this.props.producerid}
                />
              </Confirmation>
            )}
            {this.state.confirmRemoveOrchardID && (
              <Confirmation
                isOpen={true}
                handleClose={this.handleOrchardRemoveClose}
                handleConfirm={this.handleOrchardRemoveConfirm}
                title="Remove selected Orchard?"
                body="Are you sure you want to REMOVE the selected ORCHARD Record?"
              ></Confirmation>
            )}
            {this.state.confirmEditOrchardID && (
              <Confirmation
                isOpen={true}
                handleClose={this.handleOrchardEditClose}
                handleConfirm={() => {}}
                title={this.state.confirmEditOrchardID == "0" ? "Creating Orchard" : "Editing Orchard"}
                body={undefined}
              >
                <OrchardsEditForm
                  onSubmit={this.handleOrchardEditConfirm}
                  onClose={this.handleOrchardEditClose}
                  id={this.state.confirmEditOrchardID}
                  allOrchards={this.state.allOrchards}
                  availablePucs={this.state.allPucs.filter((puc) => puc.producer_id === this.state.producerid)}
                  handleRefresh={this.props.handleRefresh}
                />
              </Confirmation>
            )}
          </div>
        )}
      </div>
    );
  }
}

export default withStyles(styles)(PucsUnstyled);
