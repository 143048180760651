import React from "react";

import CustomAutoCompleteFilter from "../../../lib/components/customdatagridfilter/CustomFilter";
import { DateFormatter, FormatterNumber, FormatterNumber2Decimals, FormatterRecon, TextFormatter } from "../../../lib/components/Formatters";
import { SummaryNumber, SummaryNumber2Decimal, SummaryTotal } from "../../../lib/components/SummaryFormatter";
import { handleColumnsArrangement, handleColumnsWidth } from "../../../lib/helpers/grid";

const cellWidthActionSmall = 30;
const cellWidthAction = 50;
const cellWidthMedium = 120;
const cellWidthLarge = 200;

const defaultColumnProperties = {
  resizable: true,
  sortable: true,
};

let currentSpace = true;

export const GridColumns = (data, filters, arrangement, columnsWidth) => {
  let postValue = "";

  if (data) {
    if (currentSpace) {
      postValue = " ";
    }
    currentSpace = !currentSpace;
  }

  const columns = [
    {
      key: "invoicenumber",
      name: "Invoice Number" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"invoicenumber"} data={data} section="producerfinalinfo" />;
      },
    },
    {
      key: "idents",
      name: "Payment Idents" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"idents"} data={data} section="producerfinalinfo" />;
      },
    },
    {
      key: "amounts",
      name: "Payment Amounts" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"amounts"} data={data} section="producerfinalinfo" />;
      },
    },
    {
      key: "dispatch_id",
      name: "Dispatch ID" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"dispatch_id"} data={data} section="producerfinalinfo" />;
      },
    },
    {
      key: "ata",
      name: "ATA" + postValue,
      width: cellWidthMedium,
      filterable: true,
      formatter: DateFormatter,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"ata"} data={data} section="producerfinalinfo" />;
      },
    },
    {
      key: "IntakeWaybill",
      name: "Intake Waybill" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"IntakeWaybill"} data={data} section="producerfinalinfo" />;
      },
    },
    {
      key: "currency",
      name: "Currency" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"currency"} data={data} section="producerfinalinfo" />;
      },
    },
    {
      key: "sellingprice",
      name: "Selling Price" + postValue,
      width: cellWidthMedium,
      filterable: true,
      formatter: FormatterNumber2Decimals,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"sellingprice"} data={data} section="producerfinalinfo" />;
      },
      summaryFormatter: SummaryNumber2Decimal,
    },
    {
      key: "sellingpricefinal",
      name: "Selling Price Final" + postValue,
      width: cellWidthMedium,
      filterable: true,
      formatter: FormatterNumber2Decimals,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"sellingpricefinal"} data={data} section="producerfinalinfo" />;
      },
      summaryFormatter: SummaryNumber2Decimal,
    },
    {
      key: "foreigncost",
      name: "Foreign Cost" + postValue,
      width: cellWidthMedium,
      filterable: true,
      formatter: FormatterNumber2Decimals,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"foreigncost"} data={data} section="producerfinalinfo" />;
      },
      summaryFormatter: SummaryNumber2Decimal,
    },
    {
      key: "nettsellprice",
      name: "Nett Sell Price" + postValue,
      width: cellWidthMedium,
      filterable: true,
      formatter: FormatterNumber2Decimals,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"nettsellprice"} data={data} section="producerfinalinfo" />;
      },
      summaryFormatter: SummaryNumber2Decimal,
    },
    {
      key: "roe_eta",
      name: "ROE ETA" + postValue,
      width: cellWidthMedium,
      filterable: true,
      formatter: FormatterNumber2Decimals,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"roe_eta"} data={data} section="producerfinalinfo" />;
      },
      summaryFormatter: SummaryNumber2Decimal,
    },
    {
      key: "roe_etd",
      name: "ROE ETD" + postValue,
      width: cellWidthMedium,
      filterable: true,
      formatter: FormatterNumber2Decimals,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"roe_etd"} data={data} section="producerfinalinfo" />;
      },
      summaryFormatter: SummaryNumber2Decimal,
    },
    {
      key: "exchangerate",
      name: "Exchange Rate" + postValue,
      width: cellWidthMedium,
      filterable: true,
      formatter: (props) => {
        const { row } = props;
        let color = row.camend_final != 0 && row.camend_final != row.final ? "red" : "black";
        return <FormatterNumber2Decimals {...props} style={{ color: color != "red" ? "green" : color }} />;
      },
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"exchangerate"} data={data} section="producerfinalinfo" />;
      },
      summaryFormatter: SummaryNumber2Decimal,
    },
    {
      key: "adjustment",
      name: "Adjustment" + postValue,
      width: cellWidthMedium,
      filterable: true,
      formatter: (props) => {
        const { row } = props;
        let color = row.camend_final != 0 && row.camend_final != row.final ? "red" : "black";
        return <FormatterNumber2Decimals {...props} style={{ color: color != "red" ? "green" : color }} />;
      },
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"adjustment"} data={data} section="producerfinalinfo" />;
      },
      summaryFormatter: SummaryNumber2Decimal,
    },
    {
      key: "sellpricezar",
      name: "Sell Price ZAR" + postValue,
      width: cellWidthMedium,
      filterable: true,
      formatter: FormatterNumber2Decimals,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"sellpricezar"} data={data} section="producerfinalinfo" />;
      },
      summaryFormatter: SummaryNumber2Decimal,
    },
    {
      key: "fobcost",
      name: "Fob Cost" + postValue,
      width: cellWidthMedium,
      filterable: true,
      formatter: FormatterNumber2Decimals,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"fobcost"} data={data} section="producerfinalinfo" />;
      },
      summaryFormatter: SummaryNumber2Decimal,
    },
    {
      key: "fobcost_est",
      name: "Fob Cost Estimate" + postValue,
      width: cellWidthMedium,
      filterable: true,
      formatter: FormatterNumber2Decimals,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"fobcost_est"} data={data} section="producerfinalinfo" />;
      },
      summaryFormatter: SummaryNumber2Decimal,
    },
    {
      key: "fobcost_final",
      name: "Fob Cost Final" + postValue,
      width: cellWidthMedium,
      filterable: true,
      formatter: (props) => {
        const { row } = props;
        let color = row.camend_final != 0 && row.camend_final != row.final ? "red" : "black";
        return <FormatterNumber2Decimals {...props} style={{ color: color != "red" ? "green" : color }} />;
      },
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"fobcost_final"} data={data} section="producerfinalinfo" />;
      },
      summaryFormatter: SummaryNumber2Decimal,
    },
    {
      key: "coldcost",
      name: "Cold Cost" + postValue,
      width: cellWidthMedium,
      filterable: true,
      formatter: FormatterNumber2Decimals,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"coldcost"} data={data} section="producerfinalinfo" />;
      },
      summaryFormatter: SummaryNumber2Decimal,
    },
    {
      key: "coldcost_est",
      name: "Cold Cost Estimate" + postValue,
      width: cellWidthMedium,
      filterable: true,
      formatter: FormatterNumber2Decimals,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"coldcost_est"} data={data} section="producerfinalinfo" />;
      },
      summaryFormatter: SummaryNumber2Decimal,
    },
    {
      key: "coldcost_final",
      name: "Cold Cost Used" + postValue,
      width: cellWidthMedium,
      filterable: true,
      formatter: (props) => {
        const { row } = props;
        let color = row.camend_final != 0 && row.camend_final != row.final ? "red" : "black";
        return <FormatterNumber2Decimals {...props} style={{ color: color != "red" ? "green" : color }} />;
      },
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"coldcost_final"} data={data} section="producerfinalinfo" />;
      },
      summaryFormatter: SummaryNumber2Decimal,
    },
    {
      key: "margin",
      name: "Margin" + postValue,
      width: cellWidthMedium,
      filterable: true,
      formatter: FormatterNumber2Decimals,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"margin"} data={data} section="producerfinalinfo" />;
      },
      summaryFormatter: SummaryNumber2Decimal,
    },
    {
      key: "totalcost",
      name: "Total Cost" + postValue,
      width: cellWidthMedium,
      filterable: true,
      formatter: FormatterNumber2Decimals,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"totalcost"} data={data} section="producerfinalinfo" />;
      },
      summaryFormatter: SummaryNumber2Decimal,
    },
    {
      key: "rtgc",
      name: "RTGC" + postValue,
      width: cellWidthMedium,
      filterable: true,
      formatter: FormatterNumber2Decimals,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"rtgc"} data={data} section="producerfinalinfo" />;
      },
      summaryFormatter: SummaryNumber2Decimal,
    },
    {
      key: "rtgp",
      name: "RTGP" + postValue,
      width: cellWidthMedium,
      filterable: true,
      formatter: FormatterNumber2Decimals,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"rtgp"} data={data} section="producerfinalinfo" />;
      },
      summaryFormatter: SummaryNumber2Decimal,
    },
    {
      key: "advance",
      name: "Advance" + postValue,
      width: cellWidthMedium,
      filterable: true,
      formatter: FormatterNumber2Decimals,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"advance"} data={data} section="producerfinalinfo" />;
      },
      summaryFormatter: SummaryNumber2Decimal,
    },
    {
      key: "final",
      name: "Final" + postValue,
      width: cellWidthMedium,
      filterable: true,
      formatter: (props) => {
        const { row } = props;
        let color = row.camend_final != 0 && row.camend_final != row.final ? "red" : "black";
        return <FormatterNumber2Decimals {...props} style={{ color: color != "red" ? "green" : color }} />;
      },
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"final"} data={data} section="producerfinalinfo" />;
      },
    },
  ].map((c) => ({ ...c, ...defaultColumnProperties }));

  let columnsArranged = [];
  columnsArranged = handleColumnsArrangement(columnsArranged, arrangement, columns);
  columnsArranged = handleColumnsWidth(columnsArranged, columnsWidth, columns);

  return columnsArranged;
};
