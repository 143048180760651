import { client, request } from "./_base";

export type CreditorsImportTemplateDetailType = {
  id?: number;
  creditorsimporttemplate_id: number;
  colname: string;
  colposition: number;
  created_at?: Date;
  updated_at?: Date;
};

const endpoint = "creditorsimporttemplatedetail";

export const creditorsimporttemplate = client(`${endpoint}`, (item) => ({
  ...item,
}));

export const findCreditorsImportTemplateById = async (id: number) => {
  return await request(`${endpoint}/ext/findCreditorsImportTemplateById/${id}`)
    .then(async (result) => {
      if (result.status == 500) {
        const resp = await result.json();
        throw resp;
      }
      return result.json();
    })
    .then(({ data }) => data);
};

export const findCreditorsImportTemplateDetailByCreditorsId = async (creditors_id: number) => {
  return await request(`${endpoint}/ext/findCreditorsImportTemplateDetailByCreditorsId/${creditors_id}`)
    .then(async (result) => {
      if (result.status == 500) {
        const resp = await result.json();
        throw resp;
      }
      return result.json();
    })
    .then(({ data }) => data);
};

export const updateCreditorsImportTemplateDetail = (data: CreditorsImportTemplateDetailType[]): Promise<any> =>
  request(`${endpoint}/ext/update-columns`, {
    method: "POST",
    body: JSON.stringify(data),
  });

export const getCreditorsImportTemplatesByCreditorId = async (creditor_id: number) => {
  return await request(`${endpoint}/ext/getCreditorsImportTemplatesByCreditorId/${creditor_id}`)
    .then(async (result) => {
      if (result.status == 500) {
        const resp = await result.json();
        throw resp;
      }
      return result.json();
    })
    .then(({ data }) => data);
};

export const findCreditorsImportTemplateDetailByTemplateId = async (template_id: number) => {
  return await request(`${endpoint}/ext/findCreditorsImportTemplateDetailByTemplateId/${template_id}`)
    .then(async (result) => {
      if (result.status == 500) {
        const resp = await result.json();
        throw resp;
      }
      return result.json();
    })
    .then(({ data }) => data);
};
