import { client, request } from "./_base";

export type CreditorsImportTemplateType = {
  id?: number;
  creditors_id: number;
  templatename: string;
  templatespecialcharacter: string;
  templatehasheader: number;
  created_at?: Date;
  updated_at?: Date;
};

const endpoint = "creditorsimporttemplate";

export const creditorsimporttemplate = client(`${endpoint}`, (item) => ({
  ...item,
}));

export const getAllCreditorsImportTemplates = async () => {
  return await request(`${endpoint}/ext/getAllCreditorsImportTemplates`)
    .then(async (result) => {
      if (result.status == 500) {
        const resp = await result.json();
        throw resp;
      }
      return result.json();
    })
    .then(({ data }) => data);
};

export const checkCreditorsImportTemplateExists = async (creditors_id: number) => {
  return await request(`${endpoint}/ext/checkCreditorsImportTemplateExists/${creditors_id}`)
    .then(async (result) => {
      if (result.status == 500) {
        const resp = await result.json();
        throw resp;
      }
      return result.json();
    })
    .then(({ data }) => data);
};
