import React from "react";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";

import Button from "@material-ui/core/Button";
import Check from "@material-ui/icons/Check";
import Close from "@material-ui/icons/Close";
import Input from "@material-ui/core/Input";

import { temperaturecodes } from "../../lib/api/temperaturecodes";


const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1),
      width: "100%",
    },
    tableRow: {
      paddingTop: theme.spacing(1),
      paddingRight: theme.spacing(1) * 2,
      marginTop: theme.spacing(1),
      textAlign: "right",
    },
  });

type TemperatureCodesFormProps = {
  onSubmit: any;
  onClose: any;
  id: any;
} & WithStyles<typeof styles>;

class TemperatureCodesFormUnstyled extends React.Component<TemperatureCodesFormProps, any> {
  state = {
    classes: undefined,
    item: { id: "0", code: "", description: "", temperature: 0 },
    onSubmit: undefined,
    onClose: undefined,
    id: 0,
  };

  constructor(props) {
    super(props);
    this.state.classes = props.classes;
    this.state.onSubmit = props.onSubmit;
    this.state.onClose = props.onClose;
    this.state.id = props.id;
  }

  componentDidMount() {
    if (this.state.id != 0) {
      this.getItem(this.state.id);
    }
  }

  getItem = (id) => {
    temperaturecodes.single(id).then((data) => {
      this.setState({ item: data });
    });
  };

  handleFocus = (event) => {
    event.target.select();
  };

  handleSubmit = (e) => {
    this.state.onSubmit(e, this.state.item);
  };

  handleOnChange = (field, value) => {
    this.setState({
      item: { ...this.state.item, [field]: value },
    });
  };

  render() {
    const { classes } = this.state;

    return (
      <div className={classes.root}>
        <form
          id={this.state.item.id}
          onSubmit={(e) => {
            this.handleSubmit(e);
          }}
        >
          <table style={{ marginLeft: "15px" }}>
            <tbody>
              <tr className={classes.tableRow}>
                <td className={classes.tableRow}>
                  <span>Code:</span>
                </td>
                <td className={classes.tableRow}>{TableInputText("code", this.state.item.code, this.handleFocus, this.handleOnChange)}</td>
              </tr>
              <tr className={classes.tableRow}>
                <td className={classes.tableRow}>
                  <span>Description:</span>
                </td>
                <td className={classes.tableRow}>{TableInputText("description", this.state.item.description, this.handleFocus, this.handleOnChange)}</td>
              </tr>
              <tr className={classes.tableRow}>
                <td className={classes.tableRow}>
                  <span>Temperature:</span>
                </td>
                <td className={classes.tableRow}>{TableInputText("temperature", this.state.item.temperature, this.handleFocus, this.handleOnChange)}</td>
              </tr>
            </tbody>
          </table>
          <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "22px", marginRight: "15px" }}>
            <Button style={{ marginRight: "10px" }} color="secondary" variant="outlined" onClick={this.state.onClose}>
              <Close />
            </Button>
            <Button type="submit" color="primary" variant="outlined" onClick={this.handleSubmit}>
              <Check />
            </Button>
          </div>
        </form>
      </div>
    );
  }
}

export default withStyles(styles)(TemperatureCodesFormUnstyled);

const TableInputText = (field, value, handleFocus, handleOnChange, isMultiLine = false, maxRows = 0, rows = 0) => {
  return (
    <Input
      style={{ width: "100%" }}
      multiline={isMultiLine}
      rowsMax={maxRows}
      rows={rows}
      onFocus={handleFocus}
      key={`body_value_${field}`}
      name={field}
      value={value}
      onChange={(e) => {
        handleOnChange(field, e.target.value);
      }}
    />
  );
};
