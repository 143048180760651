import format from "date-fns/format";
import parseISO from "date-fns/parseISO";
import { DateFormatter, FormatterNumber, FormatterNumber2Decimals, FormatterNumber2DecimalsNegative, FormatterNumber3Decimals } from "../components/Formatters";
import { summaryRows } from "./grid";
import numeral from "numeral";

const WIDTH_MULTIPLIER = 8.75;

export const dynamicWidthsSummary = (dataColumns, totalRowColumns) => {
  const rows = summaryRows(dataColumns, totalRowColumns)[0];
  const maxWidths = {};

  for (let key in rows) {
    const value = rows[key].toString();
    if (!maxWidths[key]) {
      maxWidths[key] = value.length * 10;
      continue;
    }
    if (maxWidths[key] < value.length * 10) {
      maxWidths[key] = value.length * 10;
    }
  }
  return maxWidths;
};

export const dynamicWidths = (dataColumns: any[], formattedColumns: any[]) => {
  return (dataColumns || []).reduce((obj, row) => {
    for (let i = 0; i < formattedColumns.length; i++) {
      const col = formattedColumns[i];
      let value = row[col.key];

      if (col.formatter && value) {
        if (col.formatter == DateFormatter) {
          if (value != null && value != "") {
            value = format(parseISO(value), "dd-MM-yyyy");
          }
        } else if (col.formatter == FormatterNumber) {
          value = numeral(value).format("0,0");
        } else if (col.formatter == FormatterNumber2Decimals) {
          value = numeral(value).format("0,0.00");
        } else if (col.formatter == FormatterNumber2DecimalsNegative) {
          const formatted = numeral(value).format("0,0.00");
          value = `-${formatted}`;
        } else if (col.formatter == FormatterNumber3Decimals) {
          value = numeral(value).format("0,0.000");
        }
      }

      const colWidth = (value || "").toString().trim().length * WIDTH_MULTIPLIER;

      if (colWidth >= (obj[col] || 0)) {
        obj[col] = colWidth > 450 ? 450 : colWidth < 80 ? 80 : colWidth;
      }
    }
    return obj;
  }, {});
};
