import React from "react";

import NoteIcon from "@material-ui/icons/Notes";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import LockIcon from "@material-ui/icons/Lock";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import ArchiveIcon from "@material-ui/icons/Archive";

import LockedIcon from "@material-ui/icons/Lock";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";

import moment from "moment";

import CustomAutoCompleteFilter from "../lib/components/customdatagridfilter/CustomFilter";

import { printInstructionPDF } from "../reports/printing";
import { CellActionsFormatter } from "../lib/helpers/CellActionsFormatter";
import { DocumentsType } from "../lib/components/DocumentsButton";
import { handleColumnsArrangement, handleColumnsWidth } from "../lib/helpers/grid";

const lockedFormatter = (data) => {
  return (
    <div>
      {" "}
      {data.row.locked ? (
        <div>
          <LockedIcon style={{ color: "green" }} />{" "}
        </div>
      ) : null}
    </div>
  );
};

const fixedFormatter = (data) => {
  const { row, column } = data;

  return (
    <div>
      {" "}
      {row[column.key] == 1 ? (
        <div>
          <CheckIcon style={{ color: "green" }} />{" "}
        </div>
      ) : (
        <div>
          <CloseIcon style={{ color: "red" }} />{" "}
        </div>
      )}
    </div>
  );
};

const weekFormatter = (data) => {
  return <div>{`${data.row.week} (${moment(data.row.period_start).format("DD-MM-YYYY")})`}</div>;
};

const USDFormatter = (data) => {
  return <div style={{ textAlign: "right" }}>{data.row.exchange.usd}</div>;
};

const CADFormatter = (data) => {
  return <div style={{ textAlign: "right" }}>{data.row.exchange.cad}</div>;
};

const EURFormatter = (data) => {
  return <div style={{ textAlign: "right" }}>{data.row.exchange.eur}</div>;
};

const GBPFormatter = (data) => {
  return <div style={{ textAlign: "right" }}>{data.row.exchange.gbp}</div>;
};

const cellWidthTiny = 40;
const cellWidthTinyBigger = 60;
const cellWidthSmall = 90;
const cellWidthSmallBig = 140;
const cellWidthSmallBigger = 190;
const cellWidthMedium = 200;
const cellWidthMediumBigger = 230;
const cellWidthXtrLarge = 300;

const defaultColumnProperties = {
  resizable: true,
  sortable: true,
};

const OrderNumberFormatter = (props) => {
  const { row } = props;
  const ordernum = row.ordernum;
  let value = ordernum;

  if (ordernum.includes("001")) {
    value = `${ordernum.substr(2, 4)}_${ordernum.substr(6, 3)}_${ordernum.substr(12, 3)}_${ordernum.substr(15, 1)}`;
  } else {
    value = `${ordernum.substr(2, 4)}_${ordernum.substr(6, 3)}_${ordernum.substr(9, 3)}_${ordernum.substr(12, 1)}`;
  }

  return <div>{value}</div>;
};

let currentSpace = true;

export const GridColumns = (data, filters, arrangement = undefined, columnsWidth = undefined, handleRevision, handleCopy, handleLock, handleRemove, handleEdit, section) => {
  let postValue = "";

  if (data) {
    if (currentSpace) {
      postValue = " ";
    }
    currentSpace = !currentSpace;
  }

  let columns = [
    {
      key: "actions",
      name: "Actions",
      width: cellWidthMediumBigger,
      formatter: ({ row: order }) => {
        const actions = getActions(order, handleRevision, handleLock, handleRemove, handleCopy, handleEdit);
        return <CellActionsFormatter actions={actions} />;
      },
    },
    { key: "locked", name: "", width: cellWidthTiny, formatter: lockedFormatter },
    {
      key: "week",
      name: "Week" + postValue,
      width: cellWidthMedium,
      formatter: weekFormatter,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"week"} data={data} section={section} />;
      },
    },
    {
      key: "ordernum",
      name: "Order Number" + postValue,
      width: cellWidthSmallBig,
      filterable: true,
      formatter: OrderNumberFormatter,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"ordernum"} data={data} section={section} />;
      },
    },
    {
      key: "revision",
      name: "Rev",
      width: cellWidthTiny,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"revision"} data={data} section={section} />;
      },
    },
    {
      key: "farm",
      name: "Producer" + postValue,
      width: cellWidthXtrLarge,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"farm"} data={data} section={section} />;
      },
    },
    {
      key: "commodityConcatenated",
      name: "Commodity" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"commodityConcatenated"} data={data} section={section} />;
      },
    },
    {
      key: "varietyConcatenated",
      name: "Variety" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"varietyConcatenated"} data={data} section={section} />;
      },
    },
    {
      key: "groupnumberindicator",
      name: "Group" + postValue,
      width: cellWidthSmall,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"groupnumberindicator"} data={data} section={section} />;
      },
    },
    {
      key: "fixed",
      name: "Fixed",
      width: cellWidthTinyBigger,
      formatter: fixedFormatter,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"fixed"} data={data} section={section} />;
      },
    },
    {
      key: "exworks",
      name: "ExWorks",
      width: cellWidthTinyBigger,
      formatter: fixedFormatter,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"exworks"} data={data} section={section} />;
      },
    },
    {
      key: "local",
      name: "Local Currency",
      width: cellWidthTinyBigger,
      formatter: fixedFormatter,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"local"} data={data} section={section} />;
      },
    },
    {
      key: "exchange.usd",
      name: "USD",
      width: cellWidthSmall,
      formatter: USDFormatter,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"exchange.usd"} data={data} section={section} />;
      },
    },
    {
      key: "exchange.cad",
      name: "CAD",
      width: cellWidthSmall,
      formatter: CADFormatter,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"exchange.cad"} data={data} section={section} />;
      },
    },
    {
      key: "exchange.eur",
      name: "EUR",
      width: cellWidthSmall,
      formatter: EURFormatter,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"exchange.eur"} data={data} section={section} />;
      },
    },
    {
      key: "exchange.gbp",
      name: "GBP",
      width: cellWidthSmall,
      formatter: GBPFormatter,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"exchange.gbp"} data={data} section={section} />;
      },
    },
  ];

  columns = columns.map((c) => ({ ...c, ...defaultColumnProperties }));

  let columnsArranged = [];
  columnsArranged = handleColumnsArrangement(columnsArranged, arrangement, columns);
  columnsArranged = handleColumnsWidth(columnsArranged, columnsWidth, columns);

  return columnsArranged;
};

const getActions = (order, handleRevision, handleLock, handleRemove, handleCopy, handleEdit) => {
  return !order.locked
    ? [
        {
          icon: (
            <span title="Edit order">
              <EditIcon style={{ cursor: "pointer", color: "orange", width: "35px" }} />
            </span>
          ),
          callback: (e) => {
            handleEdit(order.id, order.groupnumber);
          },
        },
        {
          icon: (
            <span title="Revise order">
              <NoteIcon style={{ cursor: "pointer", color: "#4a9de2", width: "35px" }} />
            </span>
          ),
          callback: (e) => {
            handleRevision(order.id, order.groupnumber);
          },
        },
        {
          icon: (
            <span title="Copy order">
              <FileCopyIcon style={{ cursor: "pointer", color: "#4a9de2", width: "35px" }} />
            </span>
          ),
          callback: (e) => {
            handleCopy(order.id, order.groupnumber);
          },
        },
        {
          icon: (
            <span title="Documents">
              <ArchiveIcon style={{ cursor: "pointer", color: "#4a9de2", width: "35px" }} />
            </span>
          ),
          actions: [
            {
              text: "PACK INSTRUCTION",
              icon: DocumentsType.PDF,
              callback: () => {
                printInstructionPDF(order.id);
              },
            },
            {
              text: "PACK INSTRUCTION",
              icon: DocumentsType.EXCEL,
              callback: () => {
                window.location.href = `/api/piinstruction/extend/exportInstructionsExcelSingle/${order.id}`;
              },
            },
            {
              text: "ALL PACK INSTRUCTIONS",
              icon: DocumentsType.EXCEL,
              callback: () => {
                window.location.href = `/api/piinstruction/extend/exportInstructionsExcel/${order.id}`;
              },
            },
          ],
        },
        {
          icon: (
            <span title="Delete order">
              <DeleteIcon style={{ cursor: "pointer", color: "red", width: "35px" }} />
            </span>
          ),
          callback: (e) => {
            handleRemove(order.id, order.groupnumber);
          },
        },
        {
          icon: (
            <span title="Lock order">
              <LockIcon style={{ cursor: "pointer", color: "#4a9de2", width: "35px" }} />
            </span>
          ),
          callback: (e) => {
            handleLock(order.id, order.groupnumber);
          },
        },
      ]
    : [
        {
          icon: (
            <span title="Documents">
              <ArchiveIcon style={{ cursor: "pointer", color: "#4a9de2", width: "35px" }} />
            </span>
          ),
          actions: [
            {
              text: "PACK INSTRUCTION",
              icon: DocumentsType.PDF,
              callback: () => {
                printInstructionPDF(order.id);
              },
            },
            {
              text: "PACK INSTRUCTION",
              icon: DocumentsType.EXCEL,
              callback: () => {
                window.location.href = `/api/piinstruction/extend/exportInstructionsExcelSingle/${order.id}`;
              },
            },
            {
              text: "ALL PACK INSTRUCTIONS",
              icon: DocumentsType.EXCEL,
              callback: () => {
                window.location.href = `/api/piinstruction/extend/exportInstructionsExcel/${order.id}`;
              },
            },
          ],
        },
        {
          icon: (
            <span title="Copy order">
              <FileCopyIcon style={{ cursor: "pointer", color: "#4a9de2", width: "35px" }} />
            </span>
          ),
          callback: (e) => {
            handleCopy(order.id, order.groupnumber);
          },
        },
        {
          icon: (
            <span title="Delete order">
              <DeleteIcon style={{ cursor: "pointer", color: "red", width: "35px" }} />
            </span>
          ),
          callback: (e) => {
            handleRemove(order.id, order.groupnumber);
          },
        },
      ];
};
