import React from "react";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";

import Check from "@material-ui/icons/Check";
import Close from "@material-ui/icons/Close";

import Button from "@material-ui/core/Button";
import Input from "@material-ui/core/Input";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";

import { Field, Form } from "react-final-form";

import { creditorsgroup } from "../../lib/api/creditorsgroup";

const validate = (value) => (value ? undefined : "Required");

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1),
      width: "350px",
    },
    tableRow: {
      paddingTop: theme.spacing(1),
      paddingRight: theme.spacing(1) * 2,
      marginTop: theme.spacing(1),
      textAlign: "right",
      width: "100px",
    },
    tableSelect: {
      paddingTop: theme.spacing(1),
      paddingRight: theme.spacing(1) * 2,
      marginTop: theme.spacing(1),
      width: "100%",
    },
    tableInput: {
      width: "230px",
      float: "left",
    },
  });

type CreditorsGroupEditFormProps = {
  onSubmit: any;
  onClose: any;
  id: any;
} & WithStyles<typeof styles>;

class CreditorsGroupEditFormUnstyled extends React.Component<CreditorsGroupEditFormProps, any> {
  state = {
    classes: undefined,
    item: { id: "0", group: "", description: "" },
    onSubmit: undefined,
    onClose: undefined,
    id: 0,
  };

  constructor(props) {
    super(props);
    this.state.classes = props.classes;
    this.state.onSubmit = props.onSubmit;
    this.state.onClose = props.onClose;
    this.state.id = props.id;
  }

  componentWillMount() {
    if (this.state.id != 0) {
      this.getItem(this.state.id);
    }
  }

  getItem = (id) => {
    creditorsgroup.single(id).then((data) => {
      this.setState({ item: data });
    });
  };

  handleFocus = (event) => {
    event.target.select();
  };

  handleSubmit = (e) => {
    this.state.onSubmit(e, this.state.item);
  };

  handleOnChange = (field, value) => {
    this.setState({
      item: { ...this.state.item, [field]: value },
    });
  };

  render() {
    const { classes } = this.state;

    return (
      <div className={classes.root}>
        <Form
          initialValues={this.state.item}
          onSubmit={(e) => {
            this.handleSubmit(e);
          }}
          render={({ handleSubmit, form, values }) => (
            <form onSubmit={handleSubmit}>
              <table style={{ marginLeft: "15px" }}>
                <tbody>
                  <tr className={classes.tableRow}>
                    <td className={classes.tableRow}>
                      <span>Group:</span>
                    </td>
                    <td className={classes.tableRow}>{TableInputText("group", this.state.item.group, this.handleFocus, this.handleOnChange)}</td>
                  </tr>
                  <tr className={classes.tableRow}>
                    <td className={classes.tableRow}>
                      <span>Description:</span>
                    </td>
                    <td className={classes.tableRow}>{TableInputText("description", this.state.item.description, this.handleFocus, this.handleOnChange)}</td>
                  </tr>
                </tbody>
              </table>
              <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "22px", marginRight: "15px" }}>
                <Button style={{ marginRight: "10px" }} color="secondary" variant="outlined" onClick={this.state.onClose}>
                  <Close />
                </Button>
                <Button type="submit" color="primary" variant="outlined">
                  <Check />
                </Button>
              </div>
            </form>
          )}
        />
      </div>
    );
  }
}

export default withStyles(styles)(CreditorsGroupEditFormUnstyled);

const TableInputText = (field, value, handleFocus, handleOnChange, isMultiLine = false, maxRows = 0, rows = 0) => {
  return (
    <Input
      style={{ width: "200px" }}
      multiline={isMultiLine}
      rowsMax={maxRows}
      rows={rows}
      onFocus={handleFocus}
      key={`body_value_${field}`}
      name={field}
      value={value}
      onChange={(e) => {
        handleOnChange(field, e.target.value);
      }}
    />
  );
};

const TableFieldCombo: React.FunctionComponent<
  {
    classes: any;
    title: string;
    field: string;
    data: any;
    values: any;
    form: any;
    required: boolean;
    handleupdate: any;
  } & WithStyles<typeof styles>
> = (props) => {
  const { classes, title, field, data, values, form, required, handleupdate } = props;

  const handleChange = (event) => {
    handleupdate(field, event.target.value);
    form.change(field, event.target.value);
  };

  return (
    <tr>
      <td className={classes.tableRow}>{`${title}:`}</td>
      <td className={classes.tableSelect}>
        <Field
          validate={required && validate}
          name={field}
          render={({ input, meta }) => (
            <>
              <Select {...input} id="select" value={values[field]} onChange={handleChange} style={{ width: "100%" }}>
                {data &&
                  data.map((item, index) => (
                    <MenuItem key={`${item.value}${index}`} value={item.value}>
                      {item.display}
                    </MenuItem>
                  ))}
              </Select>
              {meta.error && meta.touched && <span style={{ color: "#A52A2A" }}>{meta.error}</span>}
            </>
          )}
        />
      </td>
    </tr>
  );
};
