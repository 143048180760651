import React from "react";
import CustomAutoCompleteFilter from "../../lib/components/customdatagridfilter/CustomFilter";

import { CellActionsFormatter } from "../../lib/helpers/CellActionsFormatter";

import Tooltip from "@material-ui/core/Tooltip";

import ArchiveIcon from "@material-ui/icons/Archive";
import IconBrightness from "@material-ui/icons/Brightness1";

import { SaleDispatchJoinedType } from "../../lib/api/sale";
import { StatusTypes } from "../../lib/types";
import { isNullOrUndef } from "../../lib/helpers/isNullOrUndef";
import { DateFormatter, FormatterNumber, FormatterNumber2Decimals } from "../../lib/components/Formatters";
import { SummaryNumber, SummaryNumber2Decimal, SummaryTotal } from "../../lib/components/SummaryFormatter";
import { EnquiryLink, Selector } from "../../lib/components/EnquiryLink";

const cellWidthSmallX = 80;
const cellWidthMedium = 150;

const defaultColumnProperties = {
  resizable: true,
  sortable: true,
};

let currentSpace = true;

enum FormatterTypes {
  Text = 0,
  Number = 1,
  Number2Decimals = 2,
  Date = 3,
  Terms = 4,
  Status = 5,
  Actions = 6,
}

const StatusFormatter = ({ row }) => {
  const datarow: SaleDispatchJoinedType = { ...row };

  if (datarow.camend_status != 0) {
    return (
      <div>
        <Tooltip title={StatusTypes.INVOICED}>
          <IconBrightness style={{ color: "red", display: "block", margin: "auto", border: "1px solid #333", borderRadius: "100%" }} />
        </Tooltip>
      </div>
    );
  }

  if (row.status == StatusTypes.INVOICED) {
    return (
      <div>
        <Tooltip title={`${StatusTypes.INVOICED}`}>
          <IconBrightness style={{ color: "green", display: "block", margin: "auto", border: "1px solid #333", borderRadius: "100%" }} />
        </Tooltip>
      </div>
    );
  }
  return (
    <div>
      <Tooltip title={`${StatusTypes.NEW}`}>
        <IconBrightness style={{ color: "yellow", display: "block", margin: "auto", border: "1px solid #333", borderRadius: "100%" }} />
      </Tooltip>
    </div>
  );
};

const TermsFormatter = ({ row, column }) => {
  const data = row[column.key];
  return <div>{isNullOrUndef(data) ? "CUSTOM" : data}</div>;
};

export const GridColumnsSales = (data, filters, arrangement = undefined, columnsWidth = undefined) => {
  let postValue = "";

  if (data) {
    if (currentSpace) {
      postValue = " ";
    }
    currentSpace = !currentSpace;
  }

  const columns = [
    {
      key: "actions",
      name: "Actions",
      width: cellWidthSmallX,
      formatter: ({ row }) => {
        const actions = cellActions(row);
        return (
          <div style={{ textAlign: "center" }}>
            <CellActionsFormatter actions={actions} />
          </div>
        );
      },
      summaryFormatter: SummaryTotal,
    },
    {
      key: "status",
      name: "#S" + postValue,
      width: cellWidthSmallX,
      formatter: StatusFormatter,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"status"} data={data} section="producerinvoice" />;
      },
      filterable: true,
    },
    // {
    //   key: "dispatch_loaddate",
    //   name: "Load Date" + postValue,
    //   width: cellWidthMedium,
    //   filterRenderer: (e) => {
    //     return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"dispatch_loaddate"} data={data} section="producerinvoice" />;
    //   },
    //   formatter: dateFormatter,
    //   filterable: true,
    // },
    {
      key: "sale_invoicenumber",
      name: "Producer Invoice" + postValue,
      width: cellWidthMedium,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"sale_invoicenumber"} data={data} section="producerinvoice" />;
      },
      filterable: true,
    },
    {
      key: "dispatch_invoicenumber",
      name: "Dispatch Invoice" + postValue,
      width: cellWidthMedium,
      formatter: (props) => <EnquiryLink {...props} selector={Selector.InvoiceNumber} />,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"dispatch_invoicenumber"} data={data} section="producerinvoice" />;
      },
      filterable: true,
    },
    {
      key: "sale_date",
      name: "Invoice Date" + postValue,
      width: cellWidthMedium,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"sale_date"} data={data} section="producerinvoice" />;
      },
      filterable: true,
      formatter: DateFormatter,
    },
    {
      key: "shipment_week",
      name: "Shipment Week" + postValue,
      width: cellWidthMedium,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"shipment_week"} data={data} section="producerinvoice" />;
      },
      filterable: true,
    },
    // {
    //   key: "sale_ucr",
    //   name: "UCR" + postValue,
    //   width: cellWidthMedium,
    //   filterRenderer: (e) => {
    //     return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"sale_ucr"} data={data} section="producerinvoice" />;
    //   },
    //   filterable: true,
    // },
    {
      key: "producer_name",
      name: "Producer" + postValue,
      width: cellWidthMedium,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"producer_name"} data={data} section="producerinvoice" />;
      },
      filterable: true,
    },
    // {
    //   key: "loadout_consignee",
    //   name: "Client" + postValue,
    //   width: cellWidthMedium,
    //   filterRenderer: (e) => {
    //     return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"loadout_consignee"} data={data} section="producerinvoice" />;
    //   },
    //   filterable: true,
    // },
    {
      key: "terms_description",
      name: "Terms" + postValue,
      width: cellWidthMedium,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"terms_description"} data={data} section="producerinvoice" />;
      },
      formatter: TermsFormatter,
      filterable: true,
    },
    {
      key: "salecurr_code",
      name: "Currency" + postValue,
      width: cellWidthMedium,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"salecurr_code"} data={data} section="producerinvoice" />;
      },
      filterable: true,
    },
    {
      key: "sale_exchangerate",
      name: "ROE" + postValue,
      width: cellWidthMedium,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"sale_exchangerate"} data={data} section="producerinvoice" />;
      },
      filterable: true,
      formatter: FormatterNumber2Decimals,
    },
    // {
    //   key: "users_username",
    //   name: "Trader" + postValue,
    //   width: cellWidthMedium,
    //   filterRenderer: (e) => {
    //     return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"users_username"} data={data} section="producerinvoice" />;
    //   },
    //   filterable: true,
    // },

    {
      key: "barcode_nocartons",
      name: "Total Cartons" + postValue,
      width: cellWidthMedium,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"barcode_nocartons"} data={data} section="producerinvoice" />;
      },
      filterable: true,
      formatter: FormatterNumber,
      summaryFormatter: SummaryNumber,
    },
    {
      key: "barcode_palletsize",
      name: "Total Pallets" + postValue,
      width: cellWidthMedium,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"barcode_palletsize"} data={data} section="producerinvoice" />;
      },
      filterable: false,
      formatter: FormatterNumber2Decimals,
      summaryFormatter: SummaryNumber2Decimal,
    },
    {
      key: "total",
      name: "Total Sale" + postValue,
      width: cellWidthMedium,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"total"} data={data} section="producerinvoice" />;
      },
      filterable: false,
      formatter: FormatterNumber2Decimals,
      summaryFormatter: SummaryNumber2Decimal,
    },
    {
      key: "orderno",
      name: "Order No" + postValue,
      width: cellWidthMedium,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"orderno"} data={data} section="producerinvoice" />;
      },
      filterable: true,
    },
    {
      key: "vessel_code",
      name: "Vessel" + postValue,
      width: cellWidthMedium,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"vessel_code"} data={data} section="producerinvoice" />;
      },
      filterable: true,
    },
    {
      key: "target_market",
      name: "Target Market" + postValue,
      width: cellWidthMedium,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"target_market"} data={data} section="producerinvoice" />;
      },
      filterable: true,
    },
    {
      key: "portfinal_code",
      name: "Destination" + postValue,
      width: cellWidthMedium,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"portfinal_code"} data={data} section="producerinvoice" />;
      },
      filterable: true,
    },
    {
      key: "portdistcharge_code",
      name: "Discharge" + postValue,
      width: cellWidthMedium,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"portdistcharge_code"} data={data} section="producerinvoice" />;
      },
      filterable: true,
    },
    {
      key: "loadout_voyageNumber",
      name: "Shipping No" + postValue,
      width: cellWidthMedium,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"loadout_voyageNumber"} data={data} section="producerinvoice" />;
      },
      filterable: true,
    },
    {
      key: "dispatch_containerno",
      name: "Container No" + postValue,
      width: cellWidthMedium,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"dispatch_containerno"} data={data} section="producerinvoice" />;
      },
      filterable: true,
    },
    {
      key: "dispatch_sealnumber",
      name: "Seal No" + postValue,
      width: cellWidthMedium,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"dispatch_sealnumber"} data={data} section="producerinvoice" />;
      },
      filterable: true,
    },
    {
      key: "loadout_etd",
      name: "ETD" + postValue,
      width: cellWidthMedium,
      formatter: DateFormatter,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"loadout_etd"} data={data} section="producerinvoice" />;
      },
      filterable: true,
    },
    {
      key: "loadout_eta",
      name: "ETA" + postValue,
      width: cellWidthMedium,
      formatter: DateFormatter,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"loadout_eta"} data={data} section="producerinvoice" />;
      },
      filterable: true,
    },
    {
      key: "shippingCode",
      name: "Shipping Line" + postValue,
      width: cellWidthMedium,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"shippingCode"} data={data} section="producerinvoice" />;
      },
      filterable: true,
    },
    {
      key: "portloading_code",
      name: "Port of Loading" + postValue,
      width: cellWidthMedium,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"portloading_code"} data={data} section="producerinvoice" />;
      },
      filterable: true,
    },
  ].map((c) => ({ ...c, ...defaultColumnProperties }));

  const columnsArranged = [];
  if (arrangement && arrangement.length > 0) {
    arrangement.map((item) => {
      const indexOfItem = columns.indexOf(columns.find((col) => col.name.trim() == item.toString().trim()));
      if (indexOfItem != -1) {
        columnsArranged.push(columns[indexOfItem]);
      }
    });
  } else {
    columns.map((colItem) => {
      columnsArranged.push(colItem);
    });
  }

  if (columnsWidth && columnsWidth.length > 0) {
    for (let index = 0; index < columnsArranged.length; index++) {
      const element = columnsArranged[index];
      const colItem = columnsWidth.find((colwitem) => colwitem.name.trim() == element.name.trim());
      if (colItem) {
        element.width = colItem.width;
      }
    }
  }

  return columnsArranged;
};

const cellActions = (row) => {
  return [
    {
      icon: (
        <span title="Documents">
          <ArchiveIcon style={{ cursor: "pointer", marginTop: "5px", color: "#4a9de2" }} />
        </span>
      ),
      actions: [
        {
          text: "PRINT INVOICE PDF",
          callback: () => {
            // handlePrintInvoice(invoice.sale_dispatch_id);
          },
        },
        {
          text: "PRINT PACKING LIST PDF",
          callback: () => {
            // handlePrintPackingListPDF(invoice.sale_dispatch_id);
          },
        },
        {
          text: "PRINT PACKING LIST EXCEL",
          callback: () => {
            window.location.href = `/api/sale/ext/exportPackingList/${row.sale_dispatch_id}`;
          },
        },
      ],
    },
  ];
};
