import React from "react";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";

import FormControl from "@material-ui/core/FormControl";
import NativeSelect from "@material-ui/core/NativeSelect";
import FormHelperText from "@material-ui/core/FormHelperText";

type ComboSelectionProps = {
  classes: any;
  name: string;
  selected: any;
  setSelected: any;
  data: any;
  addNone?: boolean;
  compact?: any;
} & WithStyles;

const ComboSelectionUnstyled: React.SFC<ComboSelectionProps> = (props) => {
  const { name, selected, setSelected, data, compact = false, addNone = true, classes } = props;

  return (
    <div>
      <FormControl className={classes.comboselectionform}>
        <NativeSelect
          value={selected}
          onChange={(e) => {
            setSelected(e.target.value);
          }}
          name={name}
          className={classes.dropDown}
        >
          {addNone && (
            <option key={`${name}none`} value="">
              None
            </option>
          )}
          {data.map((item) => {
            return (
              <option key={`${name}${item.id}`} value={item.code}>
                {item.name}
              </option>
            );
          })}
        </NativeSelect>
        {!compact && <FormHelperText>{`SELECT ${name.toUpperCase()}`}</FormHelperText>}
      </FormControl>
    </div>
  );
};

export const ComboSelection = ComboSelectionUnstyled;
