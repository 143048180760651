import React, { FC, useMemo } from "react";
import { createStyles, Theme, WithStyles, withStyles } from "@material-ui/core/styles";

import { MenuItemsType } from "./DocumentsButton";
import { MenuListItemMultiple, MenuListItemSingle, MenuListItemOptions } from "./MenuListItem";

type MenuListProps = {
  menuItem: MenuItemsType;
  index: number;
} & WithStyles<typeof styles>;

const MenuListUnstyled: FC<MenuListProps> = ({ menuItem, index }) => {
  const hasChildren = useMemo(() => (menuItem.children || []).length > 0, [menuItem]);

  const hasOptions = useMemo(() => (menuItem.options || []).length > 0, [menuItem]);

  if (hasOptions) {
    return <MenuListItemOptions menuItem={menuItem} index={index} />;
  }

  if (hasChildren) {
    return <MenuListItemMultiple menuItem={menuItem} index={index} />;
  }

  return <MenuListItemSingle menuItem={menuItem} index={index} />;
};

const styles = (theme: Theme) =>
  createStyles({
    root: {},
  });

export const MenuList = withStyles(styles)(MenuListUnstyled);
