import React from "react";

import CustomAutoCompleteFilter from "../../../lib/components/customdatagridfilter/CustomFilter";
import { DateFormatter, FormatterNumber, FormatterNumber2Decimals } from "../../../lib/components/Formatters";
import { SummaryNumber, SummaryNumber2Decimal, SummaryTotal } from "../../../lib/components/SummaryFormatter";
import { handleColumnsArrangement, handleColumnsWidth } from "../../../lib/helpers/grid";

import Checkbox from "@material-ui/core/Checkbox";

import IconInfo from "@material-ui/icons/Info";

const cellWidthTiny = 40;
const cellWidthSmall = 80;
const cellWidthMedium = 100;
const cellWidthLarge = 150;

const defaultColumnProperties = {
  resizable: true,
  sortable: true,
};

let currentSpace = true;

export const GridColumns = (data, filters, arrangement, columnsWidth, checked, isIntake, handleToggle, handleInvoiceInfoClick) => {
  let postValue = "";

  if (data) {
    if (currentSpace) {
      postValue = " ";
    }
    currentSpace = !currentSpace;
  }

  const columns: any = [
    {
      key: "actions",
      name: " ",
      width: cellWidthSmall,
      formatter: ({ row }) => {
        const labelId = `checkbox-list-label-${row.sale_invoicenumber}`;
        return (
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%", height: "100%" }} onClick={() => handleToggle(row)}>
            <Checkbox
              edge="start"
              checked={checked.find((item) => item && item.sale_invoicenumber == row.sale_invoicenumber)}
              tabIndex={-1}
              disableRipple
              inputProps={{ "aria-labelledby": labelId }}
              style={{ padding: 0 }}
            />
          </div>
        );
      },
    },
    {
      key: "originalIntakeWaybill",
      name: "Orig Waybill" + postValue,
      width: cellWidthLarge,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname="originalIntakeWaybill" data={data} section="produceradvancepaydetail" />;
      },
    },
    {
      key: "sale_invoicenumber",
      name: isIntake ? "Waybill" : "Invoice" + postValue,
      width: cellWidthLarge,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"sale_invoicenumber"} data={data} section="produceradvancepaydetail" />;
      },
      formatter: ({ row, column }) => {
        return (
          <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "flex-start" }}>
            {row[column.key]}
            <IconInfo style={{ cursor: "pointer", color: "blue", marginLeft: "5px", marginTop: "2px" }} onClick={() => handleInvoiceInfoClick(isIntake, row.sale_invoicenumber)} />
          </div>
        );
      },
    },
    {
      key: "totalValueShow",
      name: "Total" + postValue,
      width: cellWidthMedium,
      filterable: true,
      formatter: FormatterNumber2Decimals,
      summaryFormatter: SummaryNumber2Decimal,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname="totalValueShow" data={data} section="produceradvancepaydetail" />;
      },
    },
    {
      key: "total_cartons",
      name: "Cartons" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname="total_cartons" data={data} section="produceradvancepaydetail" />;
      },
      summaryFormatter: SummaryNumber,
      formatter: FormatterNumber,
    },
    {
      key: "total_palletsize",
      name: "Pallets" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname="total_palletsize" data={data} section="produceradvancepaydetail" />;
      },
      summaryFormatter: SummaryNumber2Decimal,
      formatter: FormatterNumber2Decimals,
    },
  ];

  if (!isIntake) {
    columns.push(
      {
        key: "dispatch_currencycode",
        name: "Currency" + postValue,
        width: cellWidthMedium,
        filterable: true,
        filterRenderer: (e) => {
          return (
            <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname="dispatch_currencycode" data={data} section="produceradvancepaydetail" />
          );
        },
      },
      {
        key: "clients_name",
        name: "Client" + postValue,
        width: cellWidthLarge,
        filterable: true,
        filterRenderer: (e) => {
          return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname="clients_name" data={data} section="produceradvancepaydetail" />;
        },
      },
      {
        key: "vessel_code",
        name: "Vessel" + postValue,
        width: cellWidthMedium,
        filterable: true,
        filterRenderer: (e) => {
          return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname="vessel_code" data={data} section="produceradvancepaydetail" />;
        },
      },
      {
        key: "dispatch_containerno",
        name: "Container" + postValue,
        width: cellWidthMedium,
        filterable: true,
        filterRenderer: (e) => {
          return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname="dispatch_containerno" data={data} section="produceradvancepaydetail" />;
        },
      },
      {
        key: "loadout_etd",
        name: "ETD" + postValue,
        width: cellWidthLarge,
        filterable: true,
        filterRenderer: (e) => {
          return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname="loadout_etd" data={data} section="produceradvancepaydetail" />;
        },
        formatter: DateFormatter,
      },
      {
        key: "loadout_eta",
        name: "ETA" + postValue,
        width: cellWidthLarge,
        filterable: true,
        filterRenderer: (e) => {
          return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname="loadout_eta" data={data} section="produceradvancepaydetail" />;
        },
        formatter: DateFormatter,
      },
      {
        key: "portDischarge",
        name: "Port of Discharge" + postValue,
        width: cellWidthMedium,
        filterable: true,
        filterRenderer: (e) => {
          return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname="portDischarge" data={data} section="produceradvancepaydetail" />;
        },
      },
    );
  } else {
    columns.push(
      {
        key: "bar_OrigIntakeDate",
        name: "Doc Date" + postValue,
        width: cellWidthLarge,
        filterable: true,
        filterRenderer: (e) => {
          return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname="bar_OrigIntakeDate" data={data} section="produceradvancepaydetail" />;
        },
        formatter: DateFormatter,
      },
      {
        key: "intakeWeek",
        name: "Intake Week" + postValue,
        width: cellWidthLarge,
        filterable: true,
        filterRenderer: (e) => {
          return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname="intakeWeek" data={data} section="produceradvancepaydetail" />;
        },
      },
      {
        key: "weekcold_week",
        name: "Cold Week" + postValue,
        width: cellWidthLarge,
        filterable: true,
        filterRenderer: (e) => {
          return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname="weekcold_week" data={data} section="produceradvancepaydetail" />;
        },
      },
    );
  }

  const defaultColumns = columns.map((c) => ({ ...c, ...defaultColumnProperties }));

  let columnsArranged = [];
  columnsArranged = handleColumnsArrangement(columnsArranged, arrangement, defaultColumns);
  columnsArranged = handleColumnsWidth(columnsArranged, columnsWidth, defaultColumns);

  return columnsArranged;
};
