import { client, request } from "./_base";

export type ProducerPucType = {
  id?: number;
  producer_id: number;
  code: string;
  created_at?: Date;
  updated_at?: Date;
};

const endpoint = "producerpuc";

export const pucs = client(endpoint, (item) => ({
  ...item,
}));

export const pucsByProducer = (producerid: number) => {
  return pucs.all().then((result: [ProducerPucType]) => {
    return result.filter((item: ProducerPucType) => item.producer_id == producerid);
  });
};

export const getPucsByProducerReadyForCombo = async (producerid: number) => {
  return await pucsByProducer(producerid).then((result: [ProducerPucType]) => {
    const ready = result
      .sort((a, b) => {
        if (a.code > b.code) return 1;
        if (a.code < b.code) return -1;
        return 1;
      })
      .map((puc) => {
        return { value: puc.id, display: puc.code, data: puc };
      });
    return ready;
  });
};

export const getProducerPucsGroupedOrchardsByProducerId = async (producer_id: any) => {
  return request(`${endpoint}/ext/pucsByProducerId/${producer_id}`, {
    method: "GET",
  })
    .then(async (result) => {
      if (result.status == 500) {
        const resp = await result.json();
        throw resp;
      }
      return result.json();
    })
    .then(({ data }) => {
      return data;
    });
};
