import { client, request, apiHeaders } from "./_base";

const endpoint = "consolidationamenddetails";

export type ConsolidationAmendDetailsType = {
  id?: number;
  consolidationamend_id?: number;
  barcode_id: number;
  roe?: number;
  fob?: number;
  cold?: number;
  ppecb?: number;
  cga?: number;
  adjustment?: number;
  commission?: number;
  created_at?: Date;
  updated_at?: Date;
  status?: number;
  final?: number;
  comment?: string;
  sati: number;
  transport: number;
  royalties: number;
  invoicenumber?: string;
};

export const consolidationamenddetails = client(endpoint, (item) => ({
  ...item,
}));
