import React from "react";

import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";

import Button from "@material-ui/core/Button";
import orange from "@material-ui/core/colors/orange";

import { Row } from "react-data-grid";

import { GridColumns } from "./invoiceLinesGridSetup";
import InvoiceLinesInfoDialog from "./invoiceLinesInfoDialog";

import Grid from "../lib/components/grid";
import Confirmation from "../lib/components/confirmation";

import { SnackContext } from "../lib/context/SnackContext";
import { PIInvoiceLines } from "../lib/api/sale";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      // paddingTop: theme.spacing(1) * 2,
      position: "relative",
      height: "100%",
      width: "100%",
    },
    buttonWrapper: {
      position: "absolute",
      top: "15px",
      left: "10px",
    },
    row: {
      padding: 0,
    },
    exportButton: {
      color: "white",
      backgroundColor: orange[700],
      "&:hover": {
        backgroundColor: orange[900],
      },
    },
  });

type InvoiceLinesProps = {
  SelectedInvoiceLines: any;
  submitPrices: any;
  handleUpdateAccepted: (invoiceItem, value) => void;
  clearAudit: any;
  handleRowsChanged: (rows) => void;
} & WithStyles<typeof styles>;

let SelectedInvoiceLinesFiltered = [];
class InvoiceLinesUnstyled extends React.Component<InvoiceLinesProps, any> {
  state = {
    classes: undefined,
    data: [],
    handleUpdateAccepted: undefined,
    handleRowsChanged: undefined,
    message: undefined,
    status: undefined,
    invoiceItem: undefined,
    sorting: {},
    clearAudit: undefined,
    submitPrices: undefined,
    open: false,
    loading: false,
    cellSelected: { idx: 0, rowIdx: 0 },
  };

  constructor(props) {
    super(props);
    this.state.classes = props.classes;
    this.state.handleUpdateAccepted = props.handleUpdateAccepted;
    this.state.clearAudit = props.clearAudit;
    this.state.submitPrices = props.submitPrices;
    this.state.handleRowsChanged = props.handleRowsChanged;
  }

  static contextType = SnackContext;
  context!: React.ContextType<typeof SnackContext>;

  handleClickOpen = (row) => {
    const message = "Current Target Price - " + row.target + "\n" + "Current MGP Price - " + row.mgp + "\n" + row.match.substring(3);
    const status = row.match[0];
    const invoiceItem = row;
    this.setState({ message, status, invoiceItem, open: true });
  };

  handleClose = () => {
    this.setState({ invoiceItem: undefined, open: false });
  };

  handleAcceptAll = () => {
    this.state.handleUpdateAccepted(SelectedInvoiceLinesFiltered);
  };

  onGridRowsUpdated = async (column: string, rows: PIInvoiceLines[]) => {
    const newRows = this.props.SelectedInvoiceLines.map((item) => {
      const exist = rows.find((existItem) => existItem.barcode == item.barcode && existItem.sequenceNumber == item.sequenceNumber);
      if (exist) {
        return exist;
      } else {
        return item;
      }
    });
    this.state.handleRowsChanged(newRows);
  };

  handleRowChange = (selectedRow: any, columns: { selectedColumn: number; columnsLength: number }) => {
    this.setState({ cellSelected: { idx: columns.selectedColumn, rowIdx: selectedRow.rowIdx } });
  };

  handleKeyDown = (event, gridRef) => {
    const enterKey = 13;
    const tabKey = 9;

    if (event.which === enterKey || event.which === tabKey) {
      event.preventDefault();

      const element = event.target.nodeName;

      if (element === "DIV") {
        if (this.state.cellSelected.idx === 19) {
          gridRef.selectCell({ idx: 20, rowIdx: this.state.cellSelected.rowIdx }, true);
          this.setState({ cellSelected: { idx: 20, rowIdx: this.state.cellSelected.rowIdx } });
        } else {
          gridRef.selectCell({ idx: 19, rowIdx: this.state.cellSelected.rowIdx + 1 }, true);
          this.setState({ cellSelected: { idx: 19, rowIdx: this.state.cellSelected.rowIdx + 1 } });
        }
      }
    }
  };

  handleLinesFilteredRows = (fRows: any[]) => {
    if (fRows && fRows.length > 0) {
      SelectedInvoiceLinesFiltered = fRows;
    } else {
      SelectedInvoiceLinesFiltered = [...this.props.SelectedInvoiceLines];
    }
  };

  RowRenderer = (props) => {
    return <Row {...props} />;
  };

  render() {
    const { classes } = this.state;

    return (
      <div className={classes.root}>
        {this.state.invoiceItem && (
          <Confirmation title={"PI Info"} isOpen={true} handleClose={this.handleClose} handleConfirm={this.handleClose} body={undefined}>
            <InvoiceLinesInfoDialog
              key={`invoicelinesinfodialog`}
              message={this.state.message}
              status={this.state.status}
              mclass={this.state.invoiceItem ? this.state.invoiceItem.mclass : 0}
              mcount={this.state.invoiceItem ? this.state.invoiceItem.mcount : 0}
              mcarton={this.state.invoiceItem ? this.state.invoiceItem.mcarton : 0}
              mweek={this.state.invoiceItem ? this.state.invoiceItem.mweek : 0}
              mbrand={this.state.invoiceItem ? this.state.invoiceItem.mbrand : 0}
            />
          </Confirmation>
        )}
        <div>
          <Grid
            data={this.props.SelectedInvoiceLines}
            GridColumns={(data, filters, arrangement, columnsWidth) => GridColumns(data, filters, arrangement, columnsWidth, this.state.handleUpdateAccepted, this.handleClickOpen)}
            rowRenderer={this.RowRenderer}
            loading={this.state.loading}
            clientHeight={10}
            clearFilters={"integrationinvoicelines"}
            handleChange={(column, rows) => this.onGridRowsUpdated(column, rows)}
            selectedRowChange={this.handleRowChange}
            handleGridKeyDown={this.handleKeyDown}
            fill={true}
            handleFilteredRows={this.handleLinesFilteredRows}
            totalRowColumns={["noCartons", "palletSize", "noBarcodes"]}
          />
        </div>
        <div className={classes.buttonWrapper}>
          <Button
            key="clearAuditInvoices"
            variant="contained"
            color="secondary"
            onClick={() => this.state.clearAudit(SelectedInvoiceLinesFiltered)}
            style={{ marginRight: "10px" }}
          >
            Clear Audit (RESET)
          </Button>
          <Button key="acceptAll" variant="contained" onClick={() => this.handleAcceptAll()} style={{ marginRight: "10px" }} className={classes.exportButton}>
            Accept All
          </Button>
          <Button key="submitInvoices" variant="contained" color="primary" onClick={() => this.state.submitPrices(SelectedInvoiceLinesFiltered)}>
            Submit
          </Button>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(InvoiceLinesUnstyled);
