import React from "react";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";

import Grid from "../../lib/components/grid";
import { CreditorsInvoiceType } from "../../lib/api/creditorsinvoice";

import { GridColumns } from "./creditorsinfopreviewsetup";
import CreditorsTotals from "./creditorsTotals";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1),
      width: "100%",
      height: "100%",
      position: "relative",
    },
    inline: {
      display: "inline",
      position: "absolute",
      marginTop: "7px",
      marginLeft: "7px",
    },
    totalRow: {
      backgroundColor: "#dddddd",
      color: "black",
      position: "sticky",
      bottom: 0,
      fontWeight: "bold",
      "& div": {
        backgroundColor: "#dddddd",
      },
      "& .react-grid-Row:hover .react-grid-Cell, .react-grid-Row.row-context-menu .react-grid-Cell": {
        backgroundColor: "#dddddd",
      },
    },
  });

type CreditorsInfoPreviewProps = {
  gridHeight: number;
  creditorInvoice: CreditorsInvoiceType;
  creditorInvoiceItems: any;
} & WithStyles<typeof styles>;

export class CreditorsInfoPreviewUnstyled extends React.Component<CreditorsInfoPreviewProps, any> {
  state = {
    classes: undefined,
    data: [],
    creditorInvoice: undefined,
    confirmRemoveID: undefined,
    confirmEditID: undefined,
    loading: true,
    dataFilterItem: undefined,
  };

  constructor(props) {
    super(props);
    this.state.classes = props.classes;
    this.state.creditorInvoice = props.creditorInvoice;

    this.state.dataFilterItem = "creditorsInfoPreview";
  }

  componentDidMount() {
    this.loadData();
  }

  loadData = async () => {
    if (this.state.creditorInvoice && this.state.creditorInvoice.currency_id) {
      const infoDetailItems = [...this.props.creditorInvoiceItems].sort((a, b) => a.group - b.group || a.sequence - b.sequence);

      this.setState(
        {
          loading: false,
          data: infoDetailItems,
        },
        () => {},
      );
    }
  };

  render() {
    const { classes } = this.state;
    return (
      <div className={classes.root}>
        <Grid
          loading={this.state.loading}
          data={this.state.data}
          GridColumns={GridColumns}
          clearFilters={this.state.dataFilterItem}
          forceHeight={this.props.gridHeight}
          totalRowColumns={["rate", "units", "totalexcl", "totalvat", "totalincl", "totalexclROE", "totalvatROE", "totalinclROE"]}
        />
        <CreditorsTotals creditorsInvoiceId={this.props.creditorInvoice.id} creditorsInvoiceItems={this.state.data} horizontal={true} />
      </div>
    );
  }
}

export default withStyles(styles)(CreditorsInfoPreviewUnstyled);
