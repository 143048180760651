import { client, apiHeaders, request } from "./_base";

export const clientsshipping = client("clientsshipping", (item) => ({
  ...item,
}));

export const shipsByClientID = (clients_id: number) => {
  return request(`clientsshipping/ext/full/${clients_id}`, {
    headers: apiHeaders(),
  }).then(async (result) => {
    if (result.status == 500) {
      const resp = await result.json();
      throw resp;
    }
    return result.json();
  });
};
