import { client } from "./_base";

export const mgps = client("mgp", item => ({
  ...item,
}));

export const mgpsAllSortedMappedforCombo = async () => {
  const result = await mgps.all().then(data => {
    return data
      .sort((a, b) => {
        if (a.code > b.code) return 1;
        if (a.code < b.code) return -1;
        return 1;
      })
      .map(c => {
        return { id: c.code, value: c.code };
      });
  });
  return result;
}