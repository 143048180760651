import React, { useMemo, useState, useEffect } from "react";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";

import Tooltip from "@material-ui/core/Tooltip";
import Button from "@material-ui/core/Button";

import IconAdd from "@material-ui/icons/Add";

import Grid from "../../lib/components/grid";
import Confirmation from "../../lib/components/confirmation";

import { consignee, ConsigneeforClient, ConsigneeAvailableToClient, withConcatenatedClearingAgents } from "../../lib/api/consignee";
import { consigneeclearingagent } from "../../lib/api/consigneeclearingagent";
import { clientsConsignee } from "../../lib/api/clientsconsignee";

import { GridColumns } from "./consigneegridsetup";

import ConsigneeEditForm from "./consigneeEditform";
import _ from "lodash";

const styles = (theme: Theme) => {
  return createStyles({
    root: {
      padding: theme.spacing(1),
      width: "100%",
      height: "100%",
      position: "relative",
    },
    inline: {
      display: "inline",
      position: "absolute",
      marginTop: "7px",
      marginLeft: "7px",
    },
  });
};

export interface Consignees {
  id: number;
  code: string;
  name: string;
  physical_address: string;
  postal_address: string;
  telephone: null | string;
  mobile: null | string;
  email: string;
  target_id: number | null;
  active: number;
  destinationtype: number;
  created_at: string;
  updated_at: string;
  region: Region | null;
  market: null | string;
  country: null | string;
  currency: Currency | null;
  clearingAgents: null | string;
  client_codes: null | string;
  client_names: null | string;
}

export enum Currency {
  CAD = "CAD",
  Eur = "EUR",
  Gbp = "GBP",
  Usd = "USD",
}

export enum Region {
  Afr = "AFR",
  Asa = "ASA",
  Eoc = "EOC",
  Eun = "EUN",
  Mea = "MEA",
  Nam = "NAM",
  Rus = "RUS",
  Sea = "SEA",
  Uki = "UKI",
}

type ConsigneeProps = {
  clientsId?: number;
  clientIdToExclude?: number;
  selectedConsignee?: any;
  noDelete?: boolean;
  forceHeight?: number;
} & WithStyles<typeof styles>;

const ConsigneeUnstyled: React.FunctionComponent<ConsigneeProps> = (props) => {
  const { classes, clientsId, selectedConsignee, noDelete, clientIdToExclude, forceHeight } = props;

  const [data, setData] = React.useState([]);
  const [confirmRemoveID, setConfirmRemoveID] = React.useState(undefined);
  const [confirmEditID, setConfirmEditID] = React.useState(undefined);
  const [loading, setloading] = React.useState(true);
  const [reload, setReload] = useState(false);

  const consigneesGroupedByCode: Consignees | {} = useMemo(() => _.groupBy(data, (client) => client.code), [data]);

  useEffect(() => {
    loadData();
  }, [reload]);

  const loadData = async () => {
    setloading(true);
    try {
      if (clientIdToExclude) {
        const data = await ConsigneeAvailableToClient(clientIdToExclude);
        const dataSorted = data.sort((a, b) => {
          if (a.code > b.code) return 1;
          if (a.code < b.code) return -1;
          return 1;
        });
        setData(dataSorted);
      } else if (clientsId) {
        const data = await ConsigneeforClient(clientsId, "");
        const dataSorted = data.sort((a, b) => {
          if (a.code > b.code) return 1;
          if (a.code < b.code) return -1;
          return 1;
        });
        setData(dataSorted);
      } else {
        const data = await withConcatenatedClearingAgents();
        const dataSorted = data.data.sort((a, b) => {
          if (a.code > b.code) return 1;
          if (a.code < b.code) return -1;
          return 1;
        });
        setData(dataSorted);
      }
    } catch (error) {}
    setloading(false);
  };

  const handleCreate = () => {
    setConfirmEditID("0");
  };

  const handleEdit = (id) => {
    setConfirmEditID(id);
  };

  const handleEditClose = () => {
    setConfirmEditID(undefined);
    loadData();
  };

  const handleEditConfirm = (item) => {
    // TODO: cleanup
    const id = item["id"];
    delete item["id"];
    delete item["market"];
    delete item["region"];

    if (id == "0") {
      consignee.create({ data: { ...item, code: item.code.toString().toUpperCase() } }).then((newId) => {
        if (clientsId) {
          const newData = {
            clients_id: clientsId,
            consignee_id: newId[0],
          };
          clientsConsignee.create({ data: newData });
        }
        clearingAgents.map((ca) => {
          const newData = {
            consignee_id: newId[0],
            clearingagent_id: ca.id,
          };

          consigneeclearingagent.create({ data: { ...newData } });
        });
      });
    } else {
      consignee.update(id, { data: { ...item, code: item.code.toString().toUpperCase() } });

      clearingAgents.map((ca) => {
        const newData = {
          consignee_id: id,
          clearingagent_id: ca.id,
        };
        if (!ca.consigneeclearingagent_id) {
          consigneeclearingagent.create({ data: { ...newData } });
        }
      });
    }
    handleEditClose();
    setReload(!reload);
  };

  const handleRemove = async (id) => {
    setConfirmRemoveID(id);
  };

  const handleRemoveClose = () => {
    setConfirmRemoveID(undefined);
  };

  const handleRemoveConfirm = async () => {
    await consignee.remove(confirmRemoveID);
    handleRemoveClose();
    loadData();
  };

  const clearingAgents = [];
  const updateClearingAgents = (assigned) => {
    clearingAgents.splice(0);
    clearingAgents.push(...assigned);
  };

  return (
    <div className={classes.root}>
      <div className={classes.inline}>
        <Tooltip title="Create new Consignee">
          <div className={classes.inline}>
            <Button variant="contained" color="primary" onClick={handleCreate} style={{ marginBottom: "10px" }}>
              <IconAdd />
            </Button>
          </div>
        </Tooltip>
      </div>
      <Grid
        loading={loading}
        data={data}
        GridColumns={(column, row, arrangement, columnsWidth) => GridColumns(column, row, arrangement, columnsWidth, handleRemove, handleEdit, noDelete)}
        selectedRowChange={selectedConsignee}
        clearFilters={"consignee"}
        forceHeight={forceHeight}
      />
      {confirmRemoveID && (
        <Confirmation
          isOpen={confirmRemoveID ? true : false}
          handleClose={handleRemoveClose}
          handleConfirm={handleRemoveConfirm}
          title="Remove selected Consignee?"
          body="Are you sure you want to REMOVE the selected Consignee Record?"
        ></Confirmation>
      )}
      {confirmEditID && (
        <Confirmation
          isOpen={confirmEditID ? true : false}
          handleClose={handleEditClose}
          handleConfirm={() => {}}
          title={confirmEditID == "0" ? "Creating Consignee" : "Editing Consignee"}
          body={undefined}
        >
          <ConsigneeEditForm
            onSubmit={handleEditConfirm}
            onClose={handleEditClose}
            id={confirmEditID}
            updateAssignedClearingAgents={updateClearingAgents}
            consigneesGroupedByCode={consigneesGroupedByCode}
          />
        </Confirmation>
      )}
    </div>
  );
};

export default withStyles(styles)(ConsigneeUnstyled);
