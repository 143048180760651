import React, { useEffect, useMemo } from "react";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";
import { ReactSortable } from "react-sortablejs";
import { AvailableToConsignee, ClearingAgentForConsignee } from "../../lib/api/clearingagent";
import { consigneeclearingagent } from "../../lib/api/consigneeclearingagent";
import TextField from "@material-ui/core/TextField";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1),
      paddingTop: theme.spacing(2),
    },
    container: {
      display: "grid",
      gridGap: "10px",
    },
    Item: {
      background: "#e6e9f7",
      borderRadius: "5px",
      padding: "5px",
      margin: "5px",
      "&:hover": {
        cursor: "move",
      },
    },
    activeShow: {
      height: "10px",
      width: "10px",
      display: "inline-block",
      borderRadius: "100%",
      margin: "1px",
      marginRight: "4px",
    },
  });

type ConsigneeClearingAgentProps = {
  consignee_id: number;
  TopDown?: boolean;
  dataChangedEvent?: any;
  updateAssignedClearingAgents: any;
} & WithStyles<typeof styles>;

const ConsigneeClearingAgentUnstyled: React.FunctionComponent<ConsigneeClearingAgentProps> = (props) => {
  const { classes, consignee_id, TopDown, dataChangedEvent, updateAssignedClearingAgents } = props;

  const [clearingAgentsForConsignee, setClearingAgentsForConsignee] = React.useState([]);
  const [availableClearingAgentsOrg, setAvailableClearingAgentsOrg] = React.useState([]);
  const [availableClearingAgents, setAvailableClearingAgents] = React.useState([]);
  const [search, setSearch] = React.useState("");

  const [availableDropZone, setAvailableDropZone] = React.useState(false);
  const [consigneesDropZone, setClearingAgentsDropZone] = React.useState(false);

  useEffect(() => {
    reload();
  }, [dataChangedEvent]);

  useEffect(() => {
    const availableList = availableClearingAgentsOrg.filter((al) => {
      if (!clearingAgentsForConsignee.find((ca) => ca.id == al.id)) {
        return al;
      }
    });
    const result = availableList.filter((clearAgent) => clearAgent.name.toLowerCase().includes(search));
    if (clearingAgentsForConsignee && clearingAgentsForConsignee.length > 0) {
      const resultFiltered = result.filter((item) => clearingAgentsForConsignee.find((con) => con.id != item.id));
      setAvailableClearingAgents(resultFiltered);
    } else {
      setAvailableClearingAgents(result);
    }
  }, [search]);

  const reload = () => {
    AvailableToConsignee(consignee_id).then((result) => {
      const resultSorted = result.sort((a, b) => a.name.localeCompare(b.name));
      setAvailableClearingAgents(resultSorted);
      setAvailableClearingAgentsOrg(resultSorted);
    });
    ClearingAgentForConsignee(consignee_id).then((result) => {
      const resultSorted = result.sort((a, b) => a.name.localeCompare(b.name));
      setClearingAgentsForConsignee(resultSorted);
    });
  };

  const handleOnEnd = async (event) => {
    setAvailableDropZone(false);
    setClearingAgentsDropZone(false);
    updateAssignedClearingAgents(
      clearingAgentsForConsignee.map((item) => {
        return { id: item.id, consigneeclearingagent_id: item.consigneeclearingagent_id };
      }),
    );

    const dataid = event.item.getAttribute("data-id").split("_");
    const itemid = dataid[2];
    const key = dataid[0];

    if (key.includes("ClearingAgents-For-Consignee")) {
      await consigneeclearingagent.remove(itemid);
    }
  };

  const green = "#00c400";
  const red = "#e61f1f";

  return (
    <div className={classes.root}>
      <div className={classes.container} style={{ gridTemplateColumns: !TopDown ? `500px 500px auto` : undefined }}>
        <h4>{`Clearing Agents for this Consignee: ${clearingAgentsForConsignee.length}`}</h4>
        <div style={{ height: "200px", overflowY: "auto" }}>
          <ReactSortable
            animation={150}
            key="ClearingAgents-For-Consignee"
            style={{
              minHeight: "110px",
              border: !consigneesDropZone ? "0" : "2px dashed #3f51b5",
              borderRadius: "5px",
            }}
            list={clearingAgentsForConsignee}
            setList={setClearingAgentsForConsignee}
            onEnd={handleOnEnd}
            onStart={() => setAvailableDropZone(true)}
            group="clearingagents"
          >
            {clearingAgentsForConsignee.length > 0 &&
              clearingAgentsForConsignee.map((item, idx) => (
                <div
                  className={classes.Item}
                  id={`ClearingAgents-For-Consignee_${idx}_${item.consigneeclearingagent_id}`}
                  key={`ClearingAgents-For-Consignee_${idx}_${item.consigneeclearingagent_id}`}
                >
                  <span style={{ background: `${item.active ? green : red}` }} className={classes.activeShow}></span>
                  {item.name}
                </div>
              ))}
          </ReactSortable>
        </div>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <h4 style={{ flexGrow: 4, marginTop: "25px" }}>{`Available Clearing Agents: ${availableClearingAgents.length}`}</h4>
          <TextField
            style={{ flexGrow: 6, width: "45%", paddingRight: "10px" }}
            id="standard-search"
            label="Search..."
            type="search"
            fullWidth
            onChange={(e) => setSearch(e.target.value.toLowerCase())}
          />
        </div>
        <div style={{ height: "350px", overflowY: "auto" }}>
          <ReactSortable
            animation={150}
            emptyInsertThreshold={50}
            key="Available-ClearingAgents"
            style={{
              minHeight: "110px",
              border: !availableDropZone ? "0" : "2px dashed #3f51b5",
              borderRadius: "5px",
            }}
            onEnd={handleOnEnd}
            list={availableClearingAgents}
            setList={setAvailableClearingAgents}
            onStart={() => setClearingAgentsDropZone(true)}
            group="clearingagents"
          >
            {availableClearingAgents.length > 0 &&
              availableClearingAgents.map((item, idx) => (
                <div className={classes.Item} id={`Available-ClearingAgents_${idx}_${item.id}`} key={`Available-ClearingAgents_${idx}_${item.id}`}>
                  <span style={{ background: `${item.active ? green : red}` }} className={classes.activeShow}></span>
                  {item.name}
                </div>
              ))}
          </ReactSortable>
        </div>
      </div>
    </div>
  );
};

export default withStyles(styles)(ConsigneeClearingAgentUnstyled);
