import React from "react";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";

import Check from "@material-ui/icons/Check";
import Close from "@material-ui/icons/Close";
import Button from "@material-ui/core/Button";

import { packhouse } from "../../lib/api/producerpackhouse";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1),
    },
    tableRow: {
      paddingTop: theme.spacing(1),
      paddingRight: theme.spacing(1) * 2,
      marginTop: theme.spacing(1),
      textAlign: "right",
    },
  });

type PackhouseEditFormProps = {
  onSubmit: any;
  onClose: any;
  id: any;
} & WithStyles<typeof styles>;

class PackhouseEditFormUnstyled extends React.Component<PackhouseEditFormProps, any> {
  state = {
    classes: undefined,
    item: { id: "0", producer_id: "0", description: "", code: "" },
    onSubmit: undefined,
    onClose: undefined,
    id: 0,
  };

  constructor(props) {
    super(props);
    this.state.classes = props.classes;
    this.state.onSubmit = props.onSubmit;
    this.state.onClose = props.onClose;
    this.state.id = props.id;
  }

  componentDidMount() {
    if (this.state.id != 0) {
      this.getItem(this.state.id);
    }
  }

  getItem = (id) => {
    packhouse.single(id).then((data) => {
      this.setState({ item: data });
    });
  };

  handleFocus = (event) => {
    event.target.select();
  };

  handleSubmit = (e) => {
    this.state.onSubmit(e, this.state.item);
  };

  render() {
    const { classes } = this.state;

    return (
      <div className={classes.root}>
        <form
          id={this.state.item.id}
          onSubmit={(e) => {
            this.handleSubmit(e);
          }}
        >
          <table style={{ marginLeft: "15px" }}>
            <tbody>
              <tr className={classes.tableRow}>
                <td className={classes.tableRow}>
                  <span>Code:</span>
                </td>
                <td className={classes.tableRow}>
                  <input
                    onFocus={this.handleFocus}
                    key={"body_value_code"}
                    name={"code"}
                    value={this.state.item.code}
                    onChange={(e) => {
                      this.setState({
                        item: { ...this.state.item, code: e.target.value },
                      });
                    }}
                  />
                </td>
              </tr>

              <tr className={classes.tableRow}>
                <td className={classes.tableRow}>
                  <span>Description:</span>
                </td>
                <td className={classes.tableRow}>
                  <input
                    onFocus={this.handleFocus}
                    key={"body_value_code"}
                    name={"description"}
                    value={this.state.item.description}
                    onChange={(e) => {
                      this.setState({
                        item: { ...this.state.item, description: e.target.value },
                      });
                    }}
                  />
                </td>
              </tr>
            </tbody>
          </table>
          <div style={{ display: "flex", justifyContent: "flex-end", margin: "10px 0 -5px 0" }}>
            <Button style={{ marginRight: "10px" }} color="secondary" variant="outlined" onClick={this.state.onClose}>
              <Close />
            </Button>
            <Button type="submit" color="primary" variant="outlined" onClick={this.handleSubmit}>
              <Check />
            </Button>
          </div>
        </form>
      </div>
    );
  }
}

export default withStyles(styles)(PackhouseEditFormUnstyled);
