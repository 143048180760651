import React from "react";

import IconDelete from "@material-ui/icons/Close";

import TextEditor from "../../../../lib/components/TextEditor";
import { CellActionsFormatter } from "../../../../lib/helpers/CellActionsFormatter";
import { SummaryNumber2Decimal } from "../../../../lib/components/SummaryFormatter";
import CustomAutoCompleteFilter from "../../../../lib/components/customdatagridfilter/CustomFilter";
import RightAlignHeaderColumn from "../../../../lib/components/gridhelper/RightAlignHeaderColumn";
import { DateFormatter, FormatterNumber2Decimals } from "../../../../lib/components/Formatters";
import { handleColumnsArrangement, handleColumnsWidth } from "../../../../lib/helpers/grid";

const cellWidthAction = 50;
const cellWidthSmall = 100;
const cellWidthMedium = 130;
const cellWidthLarge = 200;

let currentSpace = true;

export const GridColumns = (data, filters, arrangement, columnsWidth, handleRemoveAllocation) => {
  let postValue = "";

  if (data) {
    if (currentSpace) {
      postValue = " ";
    }
    currentSpace = !currentSpace;
  }

  const columns = [
    {
      key: "actions",
      name: "Actions",
      width: cellWidthAction,
      formatter: ({ row }) => {
        const actions = cellActions(row, handleRemoveAllocation);
        return (
          <div style={{ display: "flex", justifyContent: "center", gap: "5px" }}>
            <CellActionsFormatter actions={actions} />
          </div>
        );
      },
    },
    {
      key: "invoicenumber",
      name: "Invoice" + postValue,
      width: cellWidthSmall,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"invoicenumber"} data={data} section="allocationlist" />;
      },
    },
    {
      key: "posteddate",
      name: "Posted Date" + postValue,
      width: cellWidthSmall,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"posteddate"} data={data} section="allocationlist" />;
      },
      formatter: DateFormatter,
    },
    {
      key: "due_date",
      name: "Due Date" + postValue,
      width: cellWidthSmall,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"due_date"} data={data} section="allocationlist" />;
      },
      formatter: DateFormatter,
    },
    {
      key: "due_amount",
      name: "Due Amount" + postValue,
      width: cellWidthSmall,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"due_amount"} data={data} section="allocationlist" />;
      },
      formatter: FormatterNumber2Decimals,
      headerRenderer: RightAlignHeaderColumn,
      summaryFormatter: SummaryNumber2Decimal,
    },
    {
      key: "amount",
      name: "Received Amount" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"amount"} data={data} section="allocationlist" />;
      },
      editor: (props) => <TextEditor {...props} align="right" />,
      editable: true,
      formatter: FormatterNumber2Decimals,
      summaryFormatter: SummaryNumber2Decimal,
      headerRenderer: RightAlignHeaderColumn,
    },
  ];

  let columnsArranged = [];
  columnsArranged = handleColumnsArrangement(columnsArranged, arrangement, columns);
  columnsArranged = handleColumnsWidth(columnsArranged, columnsWidth, columns);

  return columnsArranged;
};

const cellActions = (row, handleRemoveFundAllocation) => {
  if (row.is_locked) return [];

  return [
    {
      icon: (
        <span title="Remove Allocation">
          <IconDelete style={{ cursor: "pointer" }} />
        </span>
      ),
      callback: (e) => handleRemoveFundAllocation(row),
    },
  ];
};
