import React from "react";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";

import LinearProgress from "@material-ui/core/LinearProgress";
import IconButton from "@material-ui/core/IconButton";
import FilterList from "@material-ui/icons/ClearAll";

import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import Fab from "@material-ui/core/Fab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Badge from "@material-ui/core/Badge";

import IconClose from "@material-ui/icons/Close";

import orange from "@material-ui/core/colors/orange";

import { Form } from "react-final-form";

import ReactDataGrid from "react-data-grid";
import numeral from "numeral";

import DispatchAcceptForm from "./invoiceAcceptForm";
import { GridColumnsPallets } from "../invoicetablesetuppallets";
import { GridColumnsGroupedFruitSpec } from "./invoiceTableSetupGroupedFruitSpec";

import Confirmation from "../../../lib/components/confirmation";
import { generateSortFn } from "../../../lib/helpers/generateSortFN";
import { clearFiltersForColumns, updateUserProfile } from "../../../lib/helpers/stickyfilters";
import { isNullOrUndef } from "../../../lib/helpers/isNullOrUndef";
import { isEquivalentObject } from "../../../lib/helpers/string_methods";

import { ProducerSaleDispatchJoinedType, producerSaleBarcodesByDispatchID, ProducerSaleBarcodesByDispatchIDType } from "../../../lib/api/producersale";

import { handleFilterChange, handleSortChange, summaryRows, handleGridFill } from "../../../lib/helpers/grid";

import BigNumber from "bignumber.js";
import { generateGridFilteredRows } from "../../../lib/helpers/generateGridFilteredRows";
import { currency } from "../../../lib/api/currency";
import { red } from "@material-ui/core/colors";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      height: "100%",
    },
    container: {
      display: "grid",
      gridTemplateColumns: "100%",
      gridTemplateRows: "250px auto 40px 45px",
      gridTemplateAreas: `
      "header"
      "details"
      "totals"
      "actions"
      `,
      gridGap: "10px",
      height: "calc(100vh - 180px)",
    },
    divHeader: {
      gridArea: "header",
    },
    divDetails: {
      gridArea: "details",
      paddingLeft: "5px",
      width: "calc(100vw - 200px)",
    },
    divTotals: {
      gridArea: "totals",
      width: "calc(100vw - 194px)",
    },
    divActions: {
      gridArea: "actions",
    },
    printButton: {
      margin: "2px",
      color: "white",
      backgroundColor: orange[700],
      "&:hover": {
        backgroundColor: orange[900],
      },
    },
    redText: {
      color: red[500],
    },
    blackText: {
      color: "#000",
    },
  });

type AcceptProps = {
  handleClose: any;
  onSubmit: any;
  defaultData: ProducerSaleDispatchJoinedType;
} & WithStyles<typeof styles>;

export class AcceptUnstyled extends React.Component<AcceptProps, any> {
  state = {
    handleClose: undefined,
    onSubmit: undefined,
    defaultData: undefined,
    loading: true,
    saleDetails: [{}],
    filters: {},
    groupedFilters: {},
    groupedFruitSpecAcceptGrid: "producerinvoicesGroupedFruitSpec",
    gridHeight: 250,
    cellSelected: undefined,
    cellSelectedGrouped: undefined,
    invoiceTotal: 0,
    gridRefInvoiceAccept: undefined,
    gridRefInvoiceGroupedAccept: undefined,
    updateFruitSpecSelling: false,
    dirty: false,
    confirmDirty: false,
    hideLoaded: false,
    sorting: {},
    sortingGrouped: {},
    palletGridWidthItem: "producerpalletGridtablewidth",
    groupedFruitSpecWidthItem: "producergroupedFruitSpectablewidth",
    palletGridColumnsWidth: [],
    groupedFruitSpecGridColumnsWidth: [],
    invoicesPalletsGrid: "producerInvoicesPallets",
    groupedFruitSpecGrid: "producerinvoicesGroupedFruitSpec",
    currentSortColumn: "",
    currentSortDirection: "",
    currentSortColumnGrouped: "",
    currentSortDirectionGrouped: "",
    rowSelectedIdx: undefined,
    rowSelectedGroupedIdx: undefined,
    currencies: [],
  };

  constructor(props) {
    super(props);
    this.state.gridRefInvoiceAccept = React.createRef();
    this.state.gridRefInvoiceGroupedAccept = React.createRef();
    this.state.handleClose = props.handleClose;
    this.state.onSubmit = props.onSubmit;
    this.state.defaultData = props.defaultData;
  }

  setGridHeights = () => {
    const mainClientheight = document.getElementById("divDetails");
    if (mainClientheight) {
      this.setState({ gridHeight: mainClientheight && mainClientheight.clientHeight && mainClientheight.clientHeight > 0 ? mainClientheight.clientHeight : 250 });
    }
  };

  componentDidMount() {
    this.loadSaleDetails().then(() => {
      this.setGridHeights();
    });

    const palletGridColumnWidths = localStorage.getItem(this.state.palletGridWidthItem);
    if (palletGridColumnWidths) {
      this.setState({ palletGridColumnsWidth: JSON.parse(palletGridColumnWidths) }, () => {});
      updateUserProfile(JSON.parse(palletGridColumnWidths), this.state.palletGridWidthItem, "general");
    } else {
      const columnsToRender = GridColumnsPallets(undefined, undefined, undefined, undefined, false, undefined);
      const columnsArr = columnsToRender.map((column) => {
        return { name: column.name, width: column.width };
      });
      localStorage.setItem(this.state.palletGridWidthItem, JSON.stringify(columnsArr));
      this.setState({ palletGridColumnsWidth: columnsArr }, () => {});
      updateUserProfile(columnsArr, this.state.palletGridWidthItem, "general");
    }

    const groupedFruitSpecGridColumnsWidth = localStorage.getItem(this.state.groupedFruitSpecWidthItem);
    if (groupedFruitSpecGridColumnsWidth) {
      this.setState({ groupedFruitSpecGridColumnsWidth: JSON.parse(groupedFruitSpecGridColumnsWidth) }, () => {});
      updateUserProfile(JSON.parse(groupedFruitSpecGridColumnsWidth), this.state.groupedFruitSpecWidthItem, "general");
    } else {
      const columnsToRender = GridColumnsGroupedFruitSpec(undefined, undefined, undefined, undefined);
      const columnsArr = columnsToRender.map((column) => {
        return { name: column.name, width: column.width };
      });
      localStorage.setItem(this.state.groupedFruitSpecWidthItem, JSON.stringify(columnsArr));
      this.setState({ groupedFruitSpecGridColumnsWidth: columnsArr }, () => {});
      updateUserProfile(columnsArr, this.state.groupedFruitSpecWidthItem, "general");
    }
  }

  handlePalletGridColumnResize = (index, width) => {
    const newArr = [...this.state.palletGridColumnsWidth];
    newArr[index].width = width;
    localStorage.setItem(this.state.palletGridWidthItem, JSON.stringify(newArr));
    updateUserProfile(newArr, this.state.palletGridWidthItem, "general");
    this.setState({ palletGridColumnsWidth: newArr });
  };

  handleGroupedFruitSpecGridColumnResize = (index, width) => {
    const newArr = [...this.state.groupedFruitSpecGridColumnsWidth];
    newArr[index].width = width;
    localStorage.setItem(this.state.groupedFruitSpecWidthItem, JSON.stringify(newArr));
    updateUserProfile(newArr, this.state.groupedFruitSpecWidthItem, "general");
    this.setState({ groupedFruitSpecGridColumnsWidth: newArr });
  };

  loadSaleDetails = async () => {
    this.setState({ loading: true });
    const [data, currencies] = await Promise.all([producerSaleBarcodesByDispatchID(this.state.defaultData.dispatch_id, this.state.defaultData.producer_code), currency.all()]);
    const totalled = data.map((dataItem: ProducerSaleBarcodesByDispatchIDType) => {
      return { ...dataItem, total: dataItem.barcode_nocartons * (dataItem.stockdetail_sellingprice ? dataItem.stockdetail_sellingprice : 0) };
    });
    const invoiceTotal = totalled.reduce((a, b) => a + b["total"], 0);
    this.setState({ saleDetails: data, invoiceTotal: invoiceTotal, loading: false, currencies });
  };

  handleKeyDown = (event) => {
    const enterKey = 13,
      tabKey = 9,
      targetColumn = 12,
      groupedTargetColumn = 9;

    if (event.which === enterKey || event.which === tabKey) {
      event.preventDefault();

      const tagName = event.target.tagName && event.target.tagName.toLowerCase();

      if (!this.state.updateFruitSpecSelling) {
        if (tagName != "div") {
          this.state.gridRefInvoiceAccept.selectCell({ idx: this.state.cellSelected, rowIdx: this.state.rowSelectedIdx }, true);
        } else {
          setTimeout(() => {
            this.state.gridRefInvoiceAccept.selectCell({ idx: targetColumn, rowIdx: this.state.rowSelectedIdx }, true);
          }, 200);
          this.setState({ rowSelectedIdx: this.state.rowSelectedIdx + 1 });
        }
      } else {
        if (tagName != "div") {
          this.state.gridRefInvoiceGroupedAccept.selectCell({ idx: this.state.cellSelectedGrouped, rowIdx: this.state.rowSelectedIdx }, true);
        } else {
          setTimeout(() => {
            this.state.gridRefInvoiceGroupedAccept.selectCell({ idx: groupedTargetColumn, rowIdx: this.state.rowSelectedIdx }, true);
          }, 200);
          this.setState({ rowSelectedIdx: this.state.rowSelectedIdx + 1 });
        }
      }
    }
  };

  handleFruitSpecSellingUpdate = () => {
    this.setState({ updateFruitSpecSelling: !this.state.updateFruitSpecSelling }, () => {});
  };

  handleCloseClick = () => {
    if (this.state.dirty) {
      this.setState({ confirmDirty: true });
    } else {
      this.state.handleClose();
    }
  };

  handleCloseClickConfirm = () => {
    this.setState({ confirmDirty: false, dirty: false }, () => {
      this.state.handleClose();
    });
  };

  handleCloseClickClose = () => {
    this.setState({ confirmDirty: false }, () => {});
  };

  getGroupedFruitSpec = (arr) => {
    const keyProps = ["barcode_commoditycode", "barcode_varietycode", "barcode_packcode", "barcode_markcode", "barcode_gradecode", "barcode_countcode", "barcode_inventorycode"];
    const kvArray = arr.map((entry) => {
      const key = keyProps.map((k) => entry[k]).join("|");
      return [key, entry];
    });

    const map = new Map(kvArray);
    const mapArr = Array.from(map.values());
    const mapArrNew = mapArr.map((item) => {
      item["new_palletsize"] = arr.reduce((acc, curr) => {
        if (
          curr["barcode_commoditycode"] == item["barcode_commoditycode"] &&
          curr["barcode_varietycode"] == item["barcode_varietycode"] &&
          curr["barcode_packcode"] == item["barcode_packcode"] &&
          curr["barcode_markcode"] == item["barcode_markcode"] &&
          curr["barcode_gradecode"] == item["barcode_gradecode"] &&
          curr["barcode_countcode"] == item["barcode_countcode"] &&
          curr["barcode_inventorycode"] == item["barcode_inventorycode"]
        ) {
          acc += Number(curr["barcode_palletsize"]);
        }
        return acc;
      }, 0);
      item["new_nocartons"] = arr.reduce((acc, curr) => {
        if (
          curr["barcode_commoditycode"] == item["barcode_commoditycode"] &&
          curr["barcode_varietycode"] == item["barcode_varietycode"] &&
          curr["barcode_packcode"] == item["barcode_packcode"] &&
          curr["barcode_markcode"] == item["barcode_markcode"] &&
          curr["barcode_gradecode"] == item["barcode_gradecode"] &&
          curr["barcode_countcode"] == item["barcode_countcode"] &&
          curr["barcode_inventorycode"] == item["barcode_inventorycode"]
        ) {
          acc += Number(curr["barcode_nocartons"]);
        }
        return acc;
      }, 0);
      return item;
    });
    return mapArrNew;
  };

  handleFilterChange = (filter) => {
    const newFilters = handleFilterChange(filter, this.state.filters);
    this.setState({ filters: newFilters });
  };

  handleGroupedFilterChange = (filter) => {
    const newFilters = handleFilterChange(filter, this.state.groupedFilters);
    this.setState({ groupedFilters: newFilters });
  };

  getRows = (rows, filters, sorting) => {
    const result = generateGridFilteredRows(rows, filters).filter((item) => {
      return item;
    });

    const fieldSorterData = Object.keys(sorting).map((item) => {
      return { name: item, reverse: sorting[item] == "ASC" ? false : true };
    });

    result.sort(generateSortFn(fieldSorterData));

    return result;
  };

  handleSort = (sortColumn, sortDirection) => {
    const sortingState = handleSortChange(this.state.sorting, sortColumn, sortDirection);
    this.setState({ sorting: sortingState, currentSortColumn: sortColumn, currentSortDirection: sortDirection }, () => {});
  };

  handleSortGrouped = (sortColumn, sortDirection) => {
    const sortingState = handleSortChange(this.state.sortingGrouped, sortColumn, sortDirection);
    this.setState({ sortingGrouped: sortingState, currentSortColumnGrouped: sortColumn, currentSortDirectionGrouped: sortDirection }, () => {});
  };

  clearPalletGridFilters = () => {
    const keys = Object.keys(this.state.filters);
    clearFiltersForColumns(keys, this.state.invoicesPalletsGrid);
    this.setState({ filters: {} }, () => {
      this.setGridHeights();
    });
  };

  clearGroupedFruitSpecFilters = () => {
    const keys = Object.keys(this.state.groupedFilters);
    clearFiltersForColumns(keys, this.state.groupedFruitSpecAcceptGrid);
    this.setState({ groupedFilters: {} }, () => {
      this.setGridHeights();
    });
  };

  filterTerms = (filterObj, term = "filterTerm") => {
    let val = 0;

    Object.keys(filterObj).map((key) => {
      val += filterObj[key][term].length;
    });

    return val;
  };

  handleRowsUpdated = (data, grouped = false) => {
    const rows = this.state.saleDetails;

    const newRows = rows.map((row) => {
      const exists = data.find((dataRow) => {
        if (grouped) {
          if (
            row["barcode_commoditycode"] == dataRow.barcode_commoditycode &&
            row["barcode_varietycode"] == dataRow.barcode_varietycode &&
            row["barcode_packcode"] == dataRow.barcode_packcode &&
            row["barcode_markcode"] == dataRow.barcode_markcode &&
            row["barcode_gradecode"] == dataRow.barcode_gradecode &&
            row["barcode_countcode"] == dataRow.barcode_countcode &&
            row["barcode_inventorycode"] == dataRow.barcode_inventorycode
          ) {
            return { ...row, stockdetail_sellingprice: dataRow.stockdetail_sellingprice };
          }
        } else {
          if (
            row["loadoutdetail_barcode"] == dataRow.loadoutdetail_barcode && // this is the difference
            row["barcode_commoditycode"] == dataRow.barcode_commoditycode &&
            row["barcode_varietycode"] == dataRow.barcode_varietycode &&
            row["barcode_packcode"] == dataRow.barcode_packcode &&
            row["barcode_markcode"] == dataRow.barcode_markcode &&
            row["barcode_gradecode"] == dataRow.barcode_gradecode &&
            row["barcode_countcode"] == dataRow.barcode_countcode &&
            row["barcode_inventorycode"] == dataRow.barcode_inventorycode
          ) {
            return { ...row, stockdetail_sellingprice: dataRow.stockdetail_sellingprice };
          }
        }
      });
      if (exists) {
        return { ...row, stockdetail_sellingprice: exists.stockdetail_sellingprice };
      }
      return row;
    });

    const totalled = newRows.map((dataItem: ProducerSaleBarcodesByDispatchIDType) => {
      return {
        ...dataItem,
        total: new BigNumber(dataItem.barcode_nocartons)
          .times(new BigNumber(dataItem.stockdetail_sellingprice || 0))
          .decimalPlaces(2)
          .toNumber(),
      };
    });

    const invoiceTotal = totalled.reduce((a, b) => a + b["total"], 0);

    this.setState({ saleDetails: totalled, invoiceTotal: invoiceTotal });
  };

  getCurrency = (curr_id: number) => {
    if (!curr_id) return "";

    const currency = this.state.currencies.find((curr) => curr.id == curr_id) || { code: "" };
    return currency.code;
  };

  render() {
    const { classes } = this.props;
    const filteredRows = this.getRows(this.state.saleDetails, this.state.filters, this.state.sorting);
    const filteredRowsGrouped = this.getRows(this.getGroupedFruitSpec(this.state.saleDetails), this.state.groupedFilters, this.state.sortingGrouped);
    const columnsToRender = GridColumnsPallets(this.state.saleDetails, this.state.filters, true, this.state.palletGridColumnsWidth, true, this.state.invoicesPalletsGrid);
    const columnsToRenderGrouped = GridColumnsGroupedFruitSpec(
      this.state.saleDetails,
      this.state.groupedFilters,
      this.state.groupedFruitSpecGridColumnsWidth,
      this.state.groupedFruitSpecGrid,
    );

    const totalRows = ["barcode_nocartons", "barcode_palletsize", "stockdetail_sellingprice", "stockdetail_producerprice", "total"];
    const totalRowsGrouped = ["stockdetail_sellingprice", "stockdetail_producerprice"];

    return (
      <div className={classes.root}>
        <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
          <h3 style={{ flexGrow: 98 }}>Generate Invoice</h3>
          <div style={{ flexGrow: 1, height: "39px", marginTop: "10px" }}>
            {!this.state.updateFruitSpecSelling && this.filterTerms(this.state.filters) != 0 && (
              <Button
                variant="contained"
                color="primary"
                onClick={() => this.clearPalletGridFilters()}
                disabled={Object.entries(this.state.filters).length === 0 && this.state.filters.constructor === Object}
              >
                <Tooltip title="Remove all filters" placement="top-start">
                  <Badge badgeContent={this.filterTerms(this.state.filters)} color="secondary">
                    Clear Filters
                  </Badge>
                </Tooltip>
              </Button>
            )}
            {this.state.updateFruitSpecSelling && this.filterTerms(this.state.groupedFilters) != 0 && (
              <Button
                variant="contained"
                color="primary"
                onClick={() => this.clearGroupedFruitSpecFilters()}
                disabled={Object.entries(this.state.groupedFilters).length === 0 && this.state.groupedFilters.constructor === Object}
              >
                <Tooltip title="Remove all filters" placement="top-start">
                  <Badge badgeContent={this.filterTerms(this.state.groupedFilters)} color="secondary">
                    Clear Filters
                  </Badge>
                </Tooltip>
              </Button>
            )}
          </div>
          <IconButton aria-label="close" color="secondary" style={{ flexGrow: 1 }} onClick={this.handleCloseClick}>
            <IconClose />
          </IconButton>
        </div>
        {this.state.loading ? (
          <LinearProgress />
        ) : (
          <div style={{ textAlign: "left" }}>
            <div>
              <Form
                initialValues={{
                  ...this.state.defaultData,
                  sale_terms_id: isNullOrUndef(this.state.defaultData.sale_terms_id) ? -1 : this.state.defaultData.sale_terms_id,
                  sale_invoicenumber: isNullOrUndef(this.state.defaultData.sale_id) ? "" : this.state.defaultData.sale_invoicenumber,
                }}
                onSubmit={(values) => {
                  const submitData = { ...values };
                  this.state.onSubmit(submitData, this.state.saleDetails);
                }}
                validate={(values) => {
                  let errors = {};
                  if (
                    !isEquivalentObject(
                      { ...values },
                      { ...this.state.defaultData, sale_terms_id: isNullOrUndef(this.state.defaultData.sale_terms_id) ? -1 : this.state.defaultData.sale_terms_id },
                    )
                  ) {
                    if (!this.state.dirty) {
                      this.setState({ dirty: true });
                    }
                  }
                  if (isNullOrUndef(values["salecurr_id"]) || values["salecurr_id"] == "0") {
                    errors = { ...errors, salecurr_id: "Please select a currency" };
                  }
                  if (isNullOrUndef(values["sale_invoicenumber"]) || values["sale_invoicenumber"].length == 0) {
                    errors = { ...errors, sale_invoicenumber: "Please enter an invoice number" };
                  }
                  return errors;
                }}
                render={({ handleSubmit, form, values }: any) => (
                  <form onSubmit={handleSubmit} onKeyPress={this.handleKeyDown}>
                    <div className={classes.container}>
                      <div className={classes.divHeader}>
                        <DispatchAcceptForm values={values} form={form} />
                      </div>
                      <div id="divDetails" className={classes.divDetails}>
                        {!this.state.updateFruitSpecSelling && (
                          <>
                            <div style={{ position: "fixed", marginTop: "-20px", right: "63px", zIndex: 9 }}>
                              {!this.state.updateFruitSpecSelling && this.filterTerms(this.state.filters) != 0 && (
                                <Fab
                                  color="secondary"
                                  onClick={() => this.clearPalletGridFilters()}
                                  disabled={Object.entries(this.state.filters).length === 0 && this.state.filters.constructor === Object}
                                >
                                  <Tooltip title="Remove all filters" placement="top-start">
                                    <Badge badgeContent={this.filterTerms(this.state.filters)} color="primary">
                                      <FilterList />
                                    </Badge>
                                  </Tooltip>
                                </Fab>
                              )}
                            </div>
                            <ReactDataGrid
                              className="rdg-light"
                              ref={(input) => (this.state.gridRefInvoiceAccept = input)}
                              columns={columnsToRender}
                              rows={filteredRows}
                              enableFilterRow={true}
                              rowHeight={30}
                              headerRowHeight={35}
                              onFill={handleGridFill}
                              style={{ position: "relative", height: this.state.gridHeight }}
                              onFiltersChange={(filter) => this.handleFilterChange(filter)}
                              onSort={this.handleSort}
                              sortColumn={this.state.currentSortColumn}
                              sortDirection={this.state.currentSortDirection as any}
                              onColumnResize={this.handlePalletGridColumnResize}
                              onRowsChange={(rows) => this.handleRowsUpdated(rows)}
                              summaryRows={summaryRows(filteredRows, totalRows)}
                              onSelectedCellChange={({ idx, rowIdx }) => {
                                this.setState({ rowSelectedIdx: rowIdx, cellSelected: idx });
                              }}
                            />
                          </>
                        )}
                        {this.state.updateFruitSpecSelling && (
                          <>
                            <div style={{ position: "fixed", marginTop: "-20px", right: "63px", zIndex: 9 }}>
                              {this.state.updateFruitSpecSelling && this.filterTerms(this.state.groupedFilters) != 0 && (
                                <Fab
                                  color="secondary"
                                  onClick={() => this.clearGroupedFruitSpecFilters()}
                                  disabled={Object.entries(this.state.groupedFilters).length === 0 && this.state.groupedFilters.constructor === Object}
                                >
                                  <Tooltip title="Remove all filters" placement="top-start">
                                    <Badge badgeContent={this.filterTerms(this.state.groupedFilters)} color="primary">
                                      <FilterList />
                                    </Badge>
                                  </Tooltip>
                                </Fab>
                              )}
                            </div>
                            <ReactDataGrid
                              className="rdg-light"
                              ref={(input) => (this.state.gridRefInvoiceGroupedAccept = input)}
                              columns={columnsToRenderGrouped}
                              rows={filteredRowsGrouped}
                              enableFilterRow={true}
                              rowHeight={30}
                              headerRowHeight={35}
                              onFill={handleGridFill}
                              style={{ position: "relative", height: this.state.gridHeight }}
                              onFiltersChange={(filter) => this.handleGroupedFilterChange(filter)}
                              onSort={this.handleSortGrouped}
                              sortColumn={this.state.currentSortColumnGrouped}
                              sortDirection={this.state.currentSortDirectionGrouped as any}
                              // onColumnResize={this.handlePalletGridColumnResize}
                              onRowsChange={(rows) => this.handleRowsUpdated(rows, true)}
                              summaryRows={summaryRows(filteredRows, totalRowsGrouped)}
                              onSelectedCellChange={({ idx, rowIdx }) => {
                                this.setState({ rowSelectedIdx: rowIdx, cellSelected: idx });
                              }}
                            />
                            {/* <ReactDataGrid
                              ref={(input) => (this.state.gridRefInvoiceGroupedAccept = input)}
                              columns={columnsToRenderGrouped}
                              rowGetter={(i) => filteredRowsGrouped[i]}
                              rowsCount={filteredRowsGrouped.length}
                              onColumnResize={this.handleGroupedFruitSpecGridColumnResize}
                              enableRowSelect={null}
                              rowScrollTimeout={null}
                              minHeight={this.state.gridHeight}
                              onAddFilter={(filter) => {
                                this.handleGroupedFilterChange(filter);
                              }}
                              onRemoveFilter={() => {}}
                              onClearFilters={() => this.setState({ filters: [{}] })}
                              onGridSort={(sortColumn, sortDirection) => {
                                this.handleSort(sortColumn, sortDirection);
                              }}
                              getValidFilterValues={(columnKey) => this.getValidFilterValues(filteredRowsGrouped, columnKey)}
                              onGridMultipleColumnsSort={this.handleSort}
                              onGridKeyDown={this.handleKeyDownGrouped.bind(this)}
                              onCellSelected={this.handleCellSelectedGrouped}
                              onGridRowsUpdated={this.onGridRowsUpdatedGrouped}
                              enableCellSelect={true}
                            /> */}
                          </>
                        )}
                      </div>
                      <div className={classes.divTotals}>
                        <div style={{ float: "left" }}>
                          <Typography component="div">
                            <Box fontWeight="fontWeightBold" m={1} fontSize={24}>
                              {`Total: ${this.getCurrency(form.getState().values["salecurr_id"])} ${numeral(this.state.invoiceTotal).format("0,0.00")}`}
                            </Box>
                          </Typography>
                        </div>
                        <div style={{ float: "right" }}>
                          <FormControlLabel
                            control={<Checkbox checked={this.state.updateFruitSpecSelling} onChange={this.handleFruitSpecSellingUpdate} name="checkedB" color="primary" />}
                            label="SHOW GROUPED FRUITSPEC GRID"
                          />
                        </div>
                      </div>
                      <div className={classes.divActions}>
                        <div style={{ float: "right", marginRight: "50px" }}>
                          {/* <ReportMenu printHandler={(reportTypeRequested) => this.state.onSubmit({ ...values }, this.state.saleDetails, true, reportTypeRequested)} /> */}
                          <Button name="submit" type="submit" variant="contained" color="primary" style={{ margin: "2px" }} disabled={false}>
                            Save And Close
                          </Button>
                          <Button name="close" variant="contained" color="secondary" style={{ margin: "2px" }} onClick={this.handleCloseClick}>
                            Back
                          </Button>
                        </div>
                      </div>
                    </div>
                  </form>
                )}
              />
            </div>
          </div>
        )}
        {this.state.confirmDirty && (
          <Confirmation
            isOpen={true}
            handleClose={this.handleCloseClickClose}
            handleConfirm={this.handleCloseClickConfirm}
            title="Close with saving?"
            body="Are you sure you want to close without saving?"
          ></Confirmation>
        )}
      </div>
    );
  }
}

export default withStyles(styles)(AcceptUnstyled);
