import React from "react";

import CustomAutoCompleteFilter from "../lib/components/customdatagridfilter/CustomFilter";
import { EnquiryLink, Selector } from "../lib/components/EnquiryLink";
import { DateFormatter, FormatterNumber, FormatterNumber2Decimals } from "../lib/components/Formatters";
import { SummaryNumber, SummaryNumber2Decimal, SummaryTotal } from "../lib/components/SummaryFormatter";
import { handleColumnsArrangement, handleColumnsWidth } from "../lib/helpers/grid";

const cellWidthSmall = 65;
const cellWidthMedium = 100;
const cellWidthLarge = 170;
const cellWidthLargeX = 190;

const defaultColumnProperties = {
  filterable: true,
  resizable: true,
  sortable: true,
};

let currentSpace = true;

export const GridColumns = (data, filters, arrangement, columnsWidth) => {
  let postValue = "";

  if (data) {
    if (currentSpace) {
      postValue = " ";
    }
    currentSpace = !currentSpace;
  }

  const columns = [
    {
      key: "reference",
      name: "Load Ref" + postValue,
      width: cellWidthMedium,
      filterRenderer: (e) => {
        return (
          <CustomAutoCompleteFilter
            {...e}
            multiSelection={true}
            currentFilters={filters}
            fieldname={"reference"}
            data={data}
            section="stockalloc"
            includeempty={true}
            enableAutoFilter={true}
          />
        );
      },
    },
    {
      key: "locationCode",
      name: "Location" + postValue,
      width: cellWidthSmall,
      filterRenderer: (e) => {
        return (
          <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"locationCode"} data={data} section="stockalloc" enableAutoFilter={true} />
        );
      },
    },
    {
      key: "age",
      name: "Age" + postValue,
      width: cellWidthSmall,
      formatter: FormatterNumber,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"age"} data={data} section="stockalloc" enableAutoFilter={true} />;
      },
    },
    {
      key: "ageAtLocation",
      name: "Age@Loc" + postValue,
      width: cellWidthSmall,
      formatter: FormatterNumber,
      filterRenderer: (e) => {
        return (
          <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"ageAtLocation"} data={data} section="stockalloc" enableAutoFilter={true} />
        );
      },
    },
    {
      key: "inspectionAge",
      name: "Insp Age",
      width: cellWidthSmall,
      formatter: FormatterNumber,
      filterRenderer: (e) => {
        return (
          <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"inspectionAge"} data={data} section="stockalloc" enableAutoFilter={true} />
        );
      },
    },
    {
      key: "targetMarket",
      name: "TM" + postValue,
      width: cellWidthSmall,
      filterRenderer: (e) => {
        return (
          <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"targetMarket"} data={data} section="stockalloc" enableAutoFilter={true} />
        );
      },
    },
    {
      key: "targetCountry",
      name: "TC" + postValue,
      width: cellWidthSmall,
      filterRenderer: (e) => {
        return (
          <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"targetCountry"} data={data} section="stockalloc" enableAutoFilter={true} />
        );
      },
    },
    {
      key: "targetRegion",
      name: "TR" + postValue,
      width: cellWidthSmall,
      filterRenderer: (e) => {
        return (
          <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"targetRegion"} data={data} section="stockalloc" enableAutoFilter={true} />
        );
      },
    },
    {
      key: "commodityCode",
      name: "Commodity" + postValue,
      width: cellWidthSmall,
      filterRenderer: (e) => {
        return (
          <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"commodityCode"} data={data} section="stockalloc" enableAutoFilter={true} />
        );
      },
    },
    {
      key: "varietyCode",
      name: "Variety" + postValue,
      width: cellWidthSmall,
      filterRenderer: (e) => {
        return (
          <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"varietyCode"} data={data} section="stockalloc" enableAutoFilter={true} />
        );
      },
    },
    {
      key: "gradeCode",
      name: "Grade" + postValue,
      width: cellWidthSmall,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"gradeCode"} data={data} section="stockalloc" enableAutoFilter={true} />;
      },
    },
    {
      key: "countCode",
      name: "Count" + postValue,
      width: cellWidthSmall,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"countCode"} data={data} section="stockalloc" enableAutoFilter={true} />;
      },
    },
    {
      key: "packCode",
      name: "Pack" + postValue,
      width: cellWidthSmall,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"packCode"} data={data} section="stockalloc" enableAutoFilter={true} />;
      },
    },
    {
      key: "markCode",
      name: "Mark" + postValue,
      width: cellWidthSmall,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"markCode"} data={data} section="stockalloc" enableAutoFilter={true} />;
      },
    },
    {
      key: "inventoryCode",
      name: "Inv Code" + postValue,
      width: cellWidthSmall,
      filterRenderer: (e) => {
        return (
          <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"inventoryCode"} data={data} section="stockalloc" enableAutoFilter={true} />
        );
      },
    },
    {
      key: "noCartons",
      name: "no Cartons" + postValue,
      width: cellWidthSmall,
      formatter: FormatterNumber,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"noCartons"} data={data} section="stockalloc" enableAutoFilter={true} />;
      },
      summaryFormatter: SummaryNumber,
    },
    {
      key: "palletSize",
      name: "PalletSize" + postValue,
      width: cellWidthSmall,
      formatter: FormatterNumber2Decimals,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"palletSize"} data={data} section="stockalloc" enableAutoFilter={true} />;
      },
      summaryFormatter: SummaryNumber2Decimal,
    },
    {
      key: "producerId",
      name: "Producer" + postValue,
      width: cellWidthMedium,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"producerId"} data={data} section="stockalloc" enableAutoFilter={true} />;
      },
    },
    {
      key: "farmNumber",
      name: "PUC" + postValue,
      width: cellWidthSmall,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"farmNumber"} data={data} section="stockalloc" enableAutoFilter={true} />;
      },
    },
    {
      key: "orchard",
      name: "Orchard" + postValue,
      width: cellWidthSmall,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"orchard"} data={data} section="stockalloc" enableAutoFilter={true} />;
      },
    },
    {
      key: "grossWeight",
      name: "Gross Weight",
      width: cellWidthSmall,
      formatter: FormatterNumber2Decimals,
      filterRenderer: (e) => {
        return (
          <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"grossWeight"} data={data} section="stockalloc" enableAutoFilter={true} />
        );
      },
      summaryFormatter: SummaryNumber2Decimal,
    },
    {
      key: "weight",
      name: "Net Weight",
      width: cellWidthSmall,
      formatter: FormatterNumber2Decimals,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"weight"} data={data} section="stockalloc" enableAutoFilter={true} />;
      },
      summaryFormatter: SummaryNumber2Decimal,
    },
    {
      key: "barcode",
      name: "Barcode" + postValue,
      width: cellWidthLargeX,
      formatter: (props) => <EnquiryLink {...props} selector={Selector.Barcode} />,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"barcode"} data={data} section="stockalloc" enableAutoFilter={true} />;
      },
      summaryFormatter: SummaryTotal,
    },
    {
      key: "coldDate",
      name: "Cold Date",
      width: cellWidthSmall,
      formatter: DateFormatter,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"coldDate"} data={data} section="stockalloc" enableAutoFilter={true} />;
      },
    },
    {
      key: "clientName",
      name: "Client Name" + postValue,
      width: cellWidthSmall,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"clientName"} data={data} section="stockalloc" enableAutoFilter={true} />;
      },
    },
    {
      key: "consigneeName",
      name: "Consignee Name" + postValue,
      width: cellWidthSmall,
      filterRenderer: (e) => {
        return (
          <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"consigneeName"} data={data} section="stockalloc" enableAutoFilter={true} />
        );
      },
    },
    {
      key: "pod_name",
      name: "POD" + postValue,
      width: cellWidthMedium,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"pod_name"} data={data} section="stockalloc" enableAutoFilter={true} />;
      },
    },
    {
      key: "port_name",
      name: "Final Destination" + postValue,
      width: cellWidthMedium,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"port_name"} data={data} section="stockalloc" enableAutoFilter={true} />;
      },
    },
    {
      key: "po",
      name: "PO Number" + postValue,
      width: cellWidthMedium,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"po"} data={data} section="stockalloc" enableAutoFilter={true} />;
      },
    },
    {
      key: "sellp",
      name: "Selling Price" + postValue,
      width: cellWidthSmall,
      formatter: FormatterNumber2Decimals,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"sellp"} data={data} section="stockalloc" enableAutoFilter={true} />;
      },
      summaryFormatter: SummaryNumber2Decimal,
    },
    {
      key: "loadout_vesseldescription",
      name: "Vessel" + postValue,
      width: cellWidthSmall,
      filterable: true,
      filterRenderer: (e) => {
        return (
          <CustomAutoCompleteFilter
            {...e}
            multiSelection={true}
            currentFilters={filters}
            fieldname={"loadout_vesseldescription"}
            data={data}
            section="stockalloc"
            enableAutoFilter={true}
          />
        );
      },
    },
    {
      key: "tempcode",
      name: "Temp Code" + postValue,
      width: cellWidthMedium,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"tempcode"} data={data} section="stockalloc" enableAutoFilter={true} />;
      },
    },
    {
      key: "containerno",
      name: "Container" + postValue,
      width: cellWidthSmall,
      filterRenderer: (e) => {
        return (
          <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"containerno"} data={data} section="stockalloc" enableAutoFilter={true} />
        );
      },
    },
    {
      key: "pickingRef",
      name: "Picking Ref" + postValue,
      width: cellWidthSmall,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"pickingRef"} data={data} section="stockalloc" enableAutoFilter={true} />;
      },
    },
    {
      key: "intakeWaybill",
      name: "Intake Waybill" + postValue,
      width: cellWidthLarge,
      formatter: (props) => <EnquiryLink {...props} selector={Selector.IntakeWaybill} />,
      filterRenderer: (e) => {
        return (
          <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"intakeWaybill"} data={data} section="stockalloc" enableAutoFilter={true} />
        );
      },
    },
    {
      key: "phc",
      name: "phc" + postValue,
      width: cellWidthSmall,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"phc"} data={data} section="stockalloc" enableAutoFilter={true} />;
      },
    },
    {
      key: "inspectionDate",
      name: "Inspection Date",
      width: cellWidthMedium,
      formatter: DateFormatter,
      filterRenderer: (e) => {
        return (
          <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"inspectionDate"} data={data} section="stockalloc" enableAutoFilter={true} />
        );
      },
    },
    {
      key: "originalIntakeDate",
      name: "Org Intake Date",
      width: cellWidthMedium,
      formatter: DateFormatter,
      filterRenderer: (e) => {
        return (
          <CustomAutoCompleteFilter
            {...e}
            multiSelection={true}
            currentFilters={filters}
            fieldname={"originalIntakeDate"}
            data={data}
            section="stockalloc"
            enableAutoFilter={true}
          />
        );
      },
    },
    {
      key: "purchasePrice",
      name: "Purchase Price",
      width: cellWidthSmall,
      formatter: FormatterNumber2Decimals,
      filterRenderer: (e) => {
        return (
          <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"purchasePrice"} data={data} section="stockalloc" enableAutoFilter={true} />
        );
      },
    },
    {
      key: "temperature",
      name: "Temperature",
      width: cellWidthSmall,
      formatter: FormatterNumber,
      filterRenderer: (e) => {
        return (
          <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"temperature"} data={data} section="stockalloc" enableAutoFilter={true} />
        );
      },
    },
    {
      key: "userName",
      name: "UserName" + postValue,
      width: cellWidthSmall,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"userName"} data={data} section="stockalloc" enableAutoFilter={true} />;
      },
    },
    {
      key: "traderid",
      name: "Trader" + postValue,
      width: cellWidthSmall,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"traderid"} data={data} section="stockalloc" enableAutoFilter={true} />;
      },
    },
    {
      key: "organization",
      name: "Org" + postValue,
      width: cellWidthSmall,
      filterRenderer: (e) => {
        return (
          <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"organization"} data={data} section="stockalloc" enableAutoFilter={true} />
        );
      },
    },
    {
      key: "dateEntered",
      name: "Date Entered",
      width: cellWidthMedium,
      formatter: DateFormatter,
      filterRenderer: (e) => {
        return (
          <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"dateEntered"} data={data} section="stockalloc" enableAutoFilter={true} />
        );
      },
    },
    {
      key: "phyto_verification",
      name: "Phyto Verification",
      width: cellWidthMedium,
      filterRenderer: (e) => {
        return (
          <CustomAutoCompleteFilter
            {...e}
            multiSelection={true}
            currentFilters={filters}
            fieldname={"phyto_verification"}
            data={data}
            section="stockalloc"
            enableAutoFilter={true}
          />
        );
      },
    },
    {
      key: "sscc",
      name: "SSCC" + postValue,
      width: cellWidthSmall,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"sscc"} data={data} section="stockalloc" enableAutoFilter={true} />;
      },
    },
    {
      key: "originalIntakeWaybill",
      name: "Orig Intake Waybill" + postValue,
      width: cellWidthLarge,
      filterRenderer: (e) => {
        return (
          <CustomAutoCompleteFilter
            {...e}
            multiSelection={true}
            currentFilters={filters}
            fieldname={"originalIntakeWaybill"}
            data={data}
            section="stockalloc"
            enableAutoFilter={true}
          />
        );
      },
    },
    {
      key: "loadout_specialinstruction",
      name: "Special Instruction" + postValue,
      width: cellWidthLargeX,
      filterRenderer: (e) => {
        return (
          <CustomAutoCompleteFilter
            {...e}
            multiSelection={true}
            currentFilters={filters}
            fieldname={"loadout_specialinstruction"}
            data={data}
            section="stockalloc"
            enableAutoFilter={true}
          />
        );
      },
    },
  ].map((c) => ({ ...c, ...defaultColumnProperties }));

  let columnsArranged = [];
  columnsArranged = handleColumnsArrangement(columnsArranged, arrangement, columns);
  columnsArranged = handleColumnsWidth(columnsArranged, columnsWidth, columns);

  return columnsArranged;
};
