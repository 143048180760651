import { apiHeaders, client, request } from "./_base";

export type ProducerFixedPaidHeadType = {
  id?: number;
  ident: number;
  makeanote: string;
  amount: number;
  currency_id: number;
  created_at?: Date;
  updated_at?: Date;
  payment_date: Date;
};

export const producerfixedpaidhead = client("producerfixedpaidhead", (item) => ({
  ...item,
}));

export const producerfixedpaidheadGetLatestIdent = async () =>
  request(`producerfixedpaidhead/ext/getlatestident`, {
    headers: apiHeaders(),
    method: "GET",
  }).then(async (result) => {
    if (result.status == 500) {
      const resp = await result.json();
      throw resp;
    }
    return result.json();
  });
