import React from "react";

import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";
import ReactDataGrid from "react-data-grid";

import Button from "@material-ui/core/Button";

import { TemplatesDetailSelectedColumns } from "./templatesDetailSetup";

const styles = (theme: Theme) =>
  createStyles({
    root: {},
  });

type TemplatesDetailProps = {
  data: any;
  handleClearSelected: any;
  handleAddTemplateLines: any;
} & WithStyles<typeof styles>;

class TemplatesDetailUnstyled extends React.Component<TemplatesDetailProps, any> {
  state = {
    rows: undefined,
    handleClearSelected: undefined,
    handleAddTemplateLines: undefined,
    height: -1,
  };

  constructor(props) {
    super(props);
    this.state.rows = props.data;
    this.state.handleClearSelected = props.handleClearSelected;
    this.state.handleAddTemplateLines = props.handleAddTemplateLines;
  }

  componentDidMount = async () => {};

  componentWillReceiveProps = async (props) => {
    this.setState({ rows: props.data });
  };

  render() {
    return (
      <div>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          style={{ backgroundColor: "green", margin: "2px" }}
          onClick={() => {
            this.state.handleAddTemplateLines(this.state.rows);
          }}
        >
          add template lines
        </Button>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          style={{ backgroundColor: "orange", margin: "2px" }}
          onClick={() => {
            this.state.handleClearSelected();
          }}
        >
          clear selected
        </Button>
        <ReactDataGrid
          className={"rdg-light"}
          columns={TemplatesDetailSelectedColumns()}
          rows={this.state.rows}
          style={{ height: document.getElementById("container_selectiondetail") != null ? document.getElementById("container_selectiondetail").clientHeight - 50 : 500 }}
        />
      </div>
    );
  }
}

export default withStyles(styles)(TemplatesDetailUnstyled);
