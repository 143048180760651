import React, { FC, useEffect } from "react";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";

import { Form, Field } from "react-final-form";
import { TextField, Radio, Select } from "final-form-material-ui";

import Button from "@material-ui/core/Button";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Check from "@material-ui/icons/Check";
import Settings from "@material-ui/icons/Settings";

import PaymentTerms from "../../maintenance/terms/terms";

import { SnackContext } from "../../lib/context/SnackContext";

import { DialogInformation } from "../../lib/components/dialoginformation";
import { producerfinance, ProducerFinanceType } from "../../lib/api/producerfinance";
import { getProducerShipmentInfo, getTermsAllSortedMappedforCombo } from "../../lib/api/terms";
import Confirmation from "../../lib/components/confirmation";
import { producerfinanceByProducerId } from "../../lib/api/producerfinance";
import { GenerateErrorMessage } from "../../lib/helpers/string_methods";
import EstimatedCostGroup from "./estimatedcostgroupsgrid";
import { FormApi } from "final-form";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      backgroundColor: theme.palette.background.paper,
      display: "flex",
      minHeight: "750px",
      padding: theme.spacing(1),
    },
    inline: {
      display: "inline",
      position: "absolute",
      marginTop: "7px",
      marginLeft: "7px",
    },
    radioCell: {
      width: "350px",
      textAlign: "left",
    },
    headerDescription: {
      width: "150px",
      textAlign: "right",
      paddingRight: theme.spacing(2),
      whiteSpace: "nowrap",
    },
    tableRowFieldData: {
      textAlign: "left",
      width: "450px",
      "& select": {
        whiteSpace: "normal",
        height: "100%",
      },
      "& .MuiInput-formControl": {
        marginTop: 0,
      },
    },
    checkboxStyle: {
      paddingTop: "10px",
    },
    tabs: {},
    tabsLabel: {},
    tableRowFieldTitle: {},
    tableRowTextAreaFieldTitle: {},
    tableRowFieldInput: {},
    tabWidth: {},
    warningText: {
      lineHeight: "0",
      color: "orange",
      whiteSpace: "nowrap",
      fontSize: "0.85rem",
      textAlign: "center",
    },
  });

type ProducerFinanceProps = {
  producerid: any;
  handleRefresh: () => void;
  handleChangeFinanceDirty(dirty: boolean): void;
} & WithStyles<typeof styles>;

export class ProducerFinanceUnstyled extends React.Component<ProducerFinanceProps, any> {
  state = {
    classes: undefined,
    producerid: undefined,
    data: {
      id: 0,
      producer_id: 0,
      purchaseterms: "0",
      dealtype: "0",
      payment: "0",
      commission: "0",
      terms_id: 0,
    },
    loading: true,
    submitComplete: false,
    producerstermsData: undefined,
    termsMaintenanceOpen: false,
    producerShipmentInfo: [],
    handleChangeFinanceDirty: () => {},
  };

  constructor(props) {
    super(props);
    this.state.classes = props.classes;

    if (props.handleChangeFinanceDirty) {
      this.state.handleChangeFinanceDirty = props.handleChangeFinanceDirty;
    }

    if (props.producerid) {
      this.state.producerid = props.producerid;
    }
  }

  static contextType = SnackContext;
  context!: React.ContextType<typeof SnackContext>;

  componentDidMount() {
    this.loadData();
  }

  componentWillReceiveProps(props) {
    this.setState({ producerid: props.producerid }, () => {
      this.loadData();
    });
  }

  loadData = async () => {
    const [termsData, producerShipmentInfo] = await Promise.all([getTermsAllSortedMappedforCombo(1), getProducerShipmentInfo(this.props.producerid)]);
    this.setState({ producerstermsData: termsData, producerShipmentInfo });

    const producerid = await producerfinanceByProducerId(this.state.producerid).catch((error) => {
      const err = GenerateErrorMessage(error, "failed to retrieve data");
      this.context.updateSnack({ show: true, color: "red", message: err });
    });
    if (producerid && producerid.data.length > 0) {
      await this.setState(
        {
          data: {
            id: producerid.data[0].id,
            producer_id: producerid.data[0].producer_id,
            purchaseterms: producerid.data[0].purchaseterms.toString(),
            dealtype: producerid.data[0].dealtype.toString(),
            payment: producerid.data[0].payment.toString(),
            commission: producerid.data[0].commission.toString(),
            terms_id: producerid.data[0].terms_id,
          },
          loading: false,
        },
        () => {},
      );
    } else {
      this.setState({ data: { ...this.state.data, producer_id: this.state.producerid }, loading: false });
    }
  };

  onSubmit = (values) => {
    const id = values.id;
    const datatomanage: { data: ProducerFinanceType } = {
      data: {
        producer_id: values.producer_id,
        purchaseterms: values.purchaseterms,
        dealtype: values.dealtype,
        payment: values.payment,
        commission: values.commission,
        terms_id: values.terms_id == 0 ? null : values.terms_id,
      },
    };

    if (id == 0) {
      producerfinance
        .create(datatomanage)
        .then(() => {
          this.setState({ submitComplete: true });
          this.loadData();
        })
        .catch((error) => {
          const err = GenerateErrorMessage(error, "Error creating data");
          this.context.updateSnack({ show: true, color: "red", message: err });
        });
    } else {
      producerfinance
        .update(id, datatomanage)
        .then(() => {
          this.setState({ submitComplete: true });
          this.loadData();
        })
        .catch((error) => {
          const err = GenerateErrorMessage(error, "Error updating data");
          this.context.updateSnack({ show: true, color: "red", message: err });
        });
    }
    this.props.handleRefresh();
  };

  handleSubmitComplete = () => {
    this.setState({ submitComplete: false });
  };

  handleEditDone = async () => {
    // The header / toolbar just above the grid with buttons dissappears
    // for %$%^$ knows why when vessel maintenance is closed...this fixes that.
    //HACK START
    const elements = document.getElementsByClassName("react-grid-Toolbar");
    for (let index = 0; index < elements.length; index++) {
      const element = elements[index] as HTMLElement;
      element.style.display = "initial";
    }
    //HACK END
    const termsData = await getTermsAllSortedMappedforCombo(1);
    this.setState({ producerstermsData: termsData, termsMaintenanceOpen: false });
  };

  handleOpenTermsMaintenance = () => {
    this.setState({ termsMaintenanceOpen: true });
  };

  render() {
    const { classes } = this.state;

    return (
      <div className={classes.root}>
        <Form
          initialValues={{ ...this.state.data }}
          onSubmit={this.onSubmit}
          render={({ handleSubmit, form, values }: any) => (
            <form onSubmit={handleSubmit} style={{ position: "relative" }}>
              <DialogInformation
                isOpen={false}
                showinput={false}
                handleOK={null}
                handleClose={null}
                title={"Unsaved changes"}
                body={"Please save your changes before continuing"}
              />
              <FormDetail
                classes={classes}
                form={form}
                values={values}
                handleChangeFinanceDirty={this.state.handleChangeFinanceDirty}
                producerShipmentInfo={this.state.producerShipmentInfo}
                producerstermsData={this.state.producerstermsData}
                handleOpenTermsMaintenance={this.handleOpenTermsMaintenance}
                producerid={this.state.producerid}
              />
              <div style={{ display: "flex", justifyContent: "flex-end", margin: "10px 0 -5px 0", paddingTop: "5px", paddingBottom: "5px" }}>
                <Button disabled={!form.getState().dirty} type="submit" color="primary" variant="outlined">
                  <Check />
                </Button>
              </div>
            </form>
          )}
        />
        {this.state.termsMaintenanceOpen && (
          <Confirmation
            fullscreen={true}
            isOpen={this.state.termsMaintenanceOpen}
            handleClose={this.handleEditDone}
            handleConfirm={() => {}}
            title={"Loadout - Editing Terms"}
            body={undefined}
          >
            <PaymentTerms termstype={1} handleOnClose={this.handleEditDone} />
          </Confirmation>
        )}
        {this.state.submitComplete && (
          <DialogInformation
            isOpen={true}
            handleClose={this.handleSubmitComplete}
            handleOK={this.handleSubmitComplete}
            title={"Submit Complete!"}
            body={`Data has been submitted`}
            showinput={false}
          />
        )}
      </div>
    );
  }
}

type FormDetailProps = {
  form: FormApi;
  values: any;
  handleChangeFinanceDirty(dirty: boolean): void;
  producerShipmentInfo: any;
  producerstermsData: any;
  handleOpenTermsMaintenance: any;
  producerid: any;
} & WithStyles<typeof styles>;

const FormDetail: FC<FormDetailProps> = ({ classes, form, values, handleChangeFinanceDirty, producerShipmentInfo, producerstermsData, handleOpenTermsMaintenance, producerid }) => {
  useEffect(() => {
    handleChangeFinanceDirty(form.getState().dirty);
  }, [form.getState().dirty]);

  return (
    <>
      <table>
        <tbody>
          <tr>
            <td className={classes.headerDescription}>
              <label>Purchase Terms:</label>
            </td>
            <td>
              <table>
                <tbody>
                  <tr>
                    <td className={classes.radioCell}>
                      <RadioGroup row>
                        <FormControlLabel label="ExWorks" control={<Field name="purchaseterms" component={Radio} type="radio" value="0" />} />
                        <FormControlLabel label="DIP" control={<Field name="purchaseterms" component={Radio} type="radio" value="1" />} />
                      </RadioGroup>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
          <tr>
            <td className={classes.headerDescription}>
              <label>Purchase Deal Type:</label>
            </td>
            <td>
              <table>
                <tbody>
                  <tr>
                    <td className={classes.radioCell}>
                      <RadioGroup row>
                        <FormControlLabel label="FIXED" control={<Field name="dealtype" component={Radio} type="radio" value="0" />} />
                        <FormControlLabel label="MGP" control={<Field name="dealtype" component={Radio} type="radio" value="1" />} />
                        <FormControlLabel label="CONSIGNMENT" control={<Field name="dealtype" component={Radio} type="radio" value="2" />} />
                      </RadioGroup>
                      {producerShipmentInfo > 0 && <p className={classes.warningText}>Warning: There are invoiced shipments using this deal type</p>}
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
          <tr>
            <td className={classes.headerDescription}>
              <label>Payment:</label>
            </td>
            <td>
              <table>
                <tbody>
                  <tr>
                    <td className={classes.radioCell}>
                      <RadioGroup row>
                        <FormControlLabel label="Local" control={<Field name="payment" component={Radio} type="radio" value="0" />} />
                        <FormControlLabel label="Foreign" control={<Field name="payment" component={Radio} type="radio" value="1" />} />
                      </RadioGroup>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
          <tr>
            <td className={classes.headerDescription}>
              <label>Commission%:</label>
            </td>
            <td>
              <Field style={{ width: "435px" }} required name="commission" component={TextField} type="text" />
            </td>
          </tr>
          <tr>
            <td className={classes.headerDescription}>
              <label>Fixed Producer Payment Terms:</label>
            </td>
            <td>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <div style={{ width: "450px" }}>
                  <Field
                    fullWidth
                    name={"terms_id"}
                    component={Select}
                    formControlProps={{
                      className: classes.tableRowFieldData,
                    }}
                    native
                    disabled={values.dealtype != "0"}
                  >
                    <option key={`none_1`} value={0}>
                      {`NONE`}
                    </option>
                    {producerstermsData &&
                      producerstermsData.map((item, index) => {
                        return (
                          <option key={`${item.value}${index}`} value={item.value}>
                            {item.display}
                          </option>
                        );
                      })}
                  </Field>
                </div>
                <Button size="small" color="primary" onClick={handleOpenTermsMaintenance}>
                  <Settings />
                </Button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      {producerid && <EstimatedCostGroup producerid={producerid} />}
    </>
  );
};

export default withStyles(styles)(ProducerFinanceUnstyled);
