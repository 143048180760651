import React from "react";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";

import { MaterialCheckBoxComponent } from "../../lib/helpers/materialcomponents";

import Check from "@material-ui/icons/Check";
import Close from "@material-ui/icons/Close";

import Button from "@material-ui/core/Button";

import { clearingagent } from "../../lib/api/clearingagent";

import { Form, Field } from "react-final-form";
import { TextField } from "final-form-material-ui";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1),
      width: "480px",
    },
    tableRow: {
      paddingTop: theme.spacing(1),
      paddingRight: theme.spacing(1) * 2,
      marginTop: theme.spacing(1),
      textAlign: "right",
    },
    tableRowTextAreaFieldTitle: {
      paddingRight: theme.spacing(1) * 2,
      marginTop: theme.spacing(1),
      textAlign: "right",
      minWidth: "175px",
      verticalAlign: "top",
      paddingTop: "7px",
    },
    tableRowFieldInput: {
      width: "270px",
      float: "left",
    },
  });

type ClearingAgentEditFormProps = {
  onSubmit: any;
  onClose: any;
  id: any;
} & WithStyles<typeof styles>;

class ClearingAgentEditFormUnstyled extends React.Component<ClearingAgentEditFormProps, any> {
  state = {
    classes: undefined,
    item: { id: "0", code: "", name: "", physical_address: "", postal_address: "", telephone: "", mobile: "", email: "" },
    onSubmit: undefined,
    onClose: undefined,
    id: 0,
  };

  constructor(props) {
    super(props);
    this.state.classes = props.classes;
    this.state.onSubmit = props.onSubmit;
    this.state.onClose = props.onClose;
    this.state.id = props.id;
  }

  componentDidMount() {
    if (this.state.id != 0) {
      this.getItem(this.state.id);
    }
  }

  getItem = (id) => {
    clearingagent.single(id).then((data) => {
      this.setState({ item: data });
    });
  };

  handleFocus = (event) => {
    event.target.select();
  };

  handleSubmit = (e) => {
    this.state.onSubmit(e, this.state.item);
  };

  handleOnChange = (field, value) => {
    this.setState({
      item: { ...this.state.item, [field]: value },
    });
  };

  render() {
    const { classes } = this.state;

    return (
      <div className={classes.root}>
        <Form
          initialValues={{ ...this.state.item }}
          onSubmit={this.state.onSubmit}
          validate={(values) => {
            let errors = {};

            if (values["telephone"] && values["telephone"].length > 0 && !/^\+?(\d\d|0)[0-9]{9}/g.test(values["telephone"])) {
              errors = { ...errors, telephone: "not a valid number" };
            }
            if (values["mobile"] && values["mobile"].length > 0 && !/^\+?(\d\d|0)[0-9]{9}/g.test(values["mobile"])) {
              errors = { ...errors, mobile: "not a valid number" };
            }
            if (values["email"] && values["email"].length > 0 && !/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g.test(values["email"])) {
              errors = { ...errors, email: "not a valid email" };
            }
            return errors;
          }}
          render={({ handleSubmit, form }) => (
            <form onSubmit={handleSubmit}>
              <table style={{ marginRight: "100px" }}>
                <tbody>
                  <tr className={classes.tableRow}>
                    <td className={classes.tableRow}>
                      <span>Code:</span>
                    </td>
                    <td className={classes.tableRow}>
                      <Field fullWidth required name="code" component={TextField} type="text" className={classes.tableRowFieldInput} />
                    </td>
                  </tr>
                  <tr className={classes.tableRow}>
                    <td className={classes.tableRow}>
                      <span>Name:</span>
                    </td>
                    <td className={classes.tableRow}>
                      <Field fullWidth required name="name" component={TextField} type="text" className={classes.tableRowFieldInput} />
                    </td>
                  </tr>
                  <tr>
                    <td className={classes.tableRowTextAreaFieldTitle}>
                      <span>Physical Address:</span>
                    </td>
                    <td className={classes.tableRowFieldData}>
                      <Field
                        name={"physical_address"}
                        component={TextField}
                        fullWidth
                        style={{ resize: "vertical" }}
                        className={classes.tableRowFieldInput}
                        multiline={true}
                        rowsMax="4"
                        rows="4"
                        key={"body_value_physical_address"}
                      />
                    </td>
                  </tr>

                  <tr>
                    <td className={classes.tableRowTextAreaFieldTitle}>
                      <span>Postal Address:</span>
                    </td>
                    <td className={classes.tableRowFieldData}>
                      <Field
                        name={"postal_address"}
                        component={TextField}
                        fullWidth
                        style={{ resize: "vertical" }}
                        className={classes.tableRowFieldInput}
                        multiline={true}
                        rowsMax="4"
                        rows="4"
                        key={"body_value_postal_address"}
                      />
                    </td>
                  </tr>
                  <tr className={classes.tableRow}>
                    <td className={classes.tableRow}>
                      <span>Telephone:</span>
                    </td>
                    <td className={classes.tableRow}>
                      <Field fullWidth name="telephone" component={TextField} type="text" className={classes.tableRowFieldInput} />
                    </td>
                  </tr>
                  <tr className={classes.tableRow}>
                    <td className={classes.tableRow}>
                      <span>Mobile:</span>
                    </td>
                    <td className={classes.tableRow}>
                      <Field fullWidth name="mobile" component={TextField} type="text" className={classes.tableRowFieldInput} />
                    </td>
                  </tr>
                  <tr className={classes.tableRow}>
                    <td className={classes.tableRow}>
                      <span>Email:</span>
                    </td>
                    <td className={classes.tableRow}>
                      <Field fullWidth name="email" component={TextField} type="text" className={classes.tableRowFieldInput} />
                    </td>
                  </tr>

                  <tr>
                    <td className={classes.tableRow}>
                      <span>Active:</span>
                    </td>
                    <td className={classes.tableRowFieldData}>
                      <Field name="active" component={MaterialCheckBoxComponent} type="checkbox" style={{ margin: 0, padding: 0, marginLeft: "-18px", marginTop: "14px" }} />
                    </td>
                  </tr>
                </tbody>
              </table>
              <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "22px", marginRight: "15px" }}>
                <Button style={{ marginRight: "10px" }} color="secondary" variant="outlined" onClick={this.state.onClose}>
                  <Close />
                </Button>
                <Button disabled={!form.getState().dirty} type="submit" color="primary" variant="outlined">
                  <Check />
                </Button>
              </div>
            </form>
          )}
        />
      </div>
    );
  }
}

export default withStyles(styles)(ClearingAgentEditFormUnstyled);
