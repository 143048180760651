import React from "react";

import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";

import InvoiceLines from "../invoicing/invoiceLines";
import { handleBatchAcceptInvoiceAudit, handleBatchRemoveInvoiceAudit, InvoiceLinesStatus, updateSalePalletPrices } from "../lib/api/invoiceItemsAudit";
import Confirmation from "../lib/components/confirmation";
import { SnackContext } from "../lib/context/SnackContext";
import { getFinancialYearSelected } from "../lib/api/week";
import { getInvoiceIntegrationByInvoice, getInvoiceNumbersForIntegration, PIInvoiceLines, PIInvoiceNumbers } from "../lib/api/sale";

import LinearProgress from "@material-ui/core/LinearProgress";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";

import "react-splitter-layout/lib/index";
import "../lib/custom/splitter.css";
import Splitter from "m-react-splitters";
import "m-react-splitters/lib/splitters.css";

import Grid from "../lib/components/grid";
import { GridColumns } from "./gridsetup";
import { OrangeButton } from "../lib/components/ColorButtons";
import { format, parseISO } from "date-fns";

import numeral from "numeral";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      height: "100%",
      width: "100%",
    },
    appBar: {
      position: "relative",
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
    splitterWrapper: {
      position: "relative",
      height: "calc(100% - 80px)",
      width: "100%",
      display: "grid",
      gap: "20px",
    },
    splitter: {
      gap: "10px",
    },
    toolbar: {
      position: "absolute",
      left: 0,
      width: "100%",
      top: "2px",
    },
    toolbarRight: {
      position: "absolute",
      left: "400px",
      gap: "10px",
      zIndex: 1000,
      top: "2px",
    },
    toolbarWrapper: {
      position: "relative",
    },
  });

type MGPIntergrationGridProps = {} & WithStyles<typeof styles>;

class MGPIntergrationGridUnstyled extends React.Component<MGPIntergrationGridProps, {}> {
  constructor(props) {
    super(props);
  }

  state = {
    data: [],
    clearAudit: [],
    loading: false,
    loadingLines: false,
    invoiceNumbersData: [],
    selectedRows: [],
    gridHeight: 900,
  };

  static contextType = SnackContext;
  context!: React.ContextType<typeof SnackContext>;

  loadData = async () => {
    this.setState({ loading: true });

    const result = await getInvoiceNumbersForIntegration(getFinancialYearSelected());

    this.setState({
      invoiceNumbersData: result.map((item) => ({
        ...item,
        mergedFormatted: item.merged || item.accepted ? "Locked" : "Not Locked",
        sale_date: format(parseISO(item.sale_date as any), "MM yyyy"),
      })),
      loading: false,
      loadingLines: false,
    });
    this.setGridHeights();
  };

  handleReloadInvoices = async () => {
    if (this.state.selectedRows.length === 0) return;

    this.setState({ loadingLines: true });

    const list = this.state.selectedRows.map((item) => item.sale_invoicenumber).join(",");
    const result = await getInvoiceIntegrationByInvoice(list);

    this.setState({ data: result, loadingLines: false });
  };

  componentDidMount = () => {
    this.loadData();
  };

  handleClearAudit = (rows: any[]) => {
    this.setState({ clearAudit: rows });
  };

  handleClearAuditClose = () => {
    this.setState({ clearAudit: [] });
  };

  handleClearAuditConfirm = async () => {
    this.setState({ loading: true });

    const ids = this.state.clearAudit.map((invoiceLine) => invoiceLine.audid);
    await handleBatchRemoveInvoiceAudit(ids);

    this.handleClearAuditClose();

    this.setState({ loading: false });
    this.context.updateSnack({ show: true, color: "green", message: "Successfully cleared audit" });

    this.handleReloadInvoices();
    this.loadData();
  };

  handleUpdateAccepted = async (invoiceItems: PIInvoiceLines[]) => {
    this.setState({ loading: true });

    const formattedData = invoiceItems.map((invoiceItem) => ({
      id: invoiceItem.audid,
      invoiceid: invoiceItem.invoiceId,
      match: invoiceItem.match,
      fob: numeral(invoiceItem.fob).format("0.000"),
      mgp: numeral(invoiceItem.dip).format("0.000"),
      barcode: invoiceItem.barcode,
      accepted: invoiceItem.accepted == InvoiceLinesStatus.Accepted ? null : InvoiceLinesStatus.Accepted,
      merged: invoiceItem.merged,
      producerId: invoiceItem.producerId,
      commodity: invoiceItem.commodity,
      varietyCode: invoiceItem.varietyCode,
      gradeCode: invoiceItem.gradeCode,
      packCode: invoiceItem.packCode,
      countCode: invoiceItem.countCode,
      markCode: invoiceItem.markCode,
      sequenceNumber: invoiceItem.sequenceNumber,
      intakeId: invoiceItem.intakeId,
      barcode_id: invoiceItem.barcode_id,
    }));

    await handleBatchAcceptInvoiceAudit(formattedData);

    this.setState({ loading: false });
    this.context.updateSnack({ show: true, color: "Green", message: "Invoice(s) accepted" });

    await this.handleReloadInvoices();
    await this.loadData();
  };

  handleRowsChanged = (rows) => {
    this.setState({ data: rows });
  };

  submitPrices = async (invoicelines: PIInvoiceLines[]) => {
    this.setState({ loading: true });
    try {
      const updateData = invoicelines.reduce((arr, item) => {
        if (item.accepted != InvoiceLinesStatus.Accepted && item.merged != InvoiceLinesStatus.Merged) {
          arr.push({
            id: item.audid,
            invoiceid: item.invoiceId,
            match: item.match,
            fob: numeral(item.fob).format("0.000"),
            mgp: numeral(item.dip).format("0.000"),
            accepted: InvoiceLinesStatus.Accepted,
            merged: InvoiceLinesStatus.Merged,
            producerId: item.producerId,
            commodity: item.commodity,
            varietyCode: item.varietyCode,
            gradeCode: item.gradeCode,
            packCode: item.packCode,
            countCode: item.countCode,
            markCode: item.markCode,
            intakeId: item.intakeId,
            sequenceNumber: item.sequenceNumber,
            barcode: item.barcode,
            barcode_id: item.barcode_id,
          });
        }
        return arr;
      }, []);
      await updateSalePalletPrices(updateData);
      this.context.updateSnack({ show: true, color: "green", message: "Packing instruction prices submitted successfully" });

      await this.handleReloadInvoices();
      await this.loadData();
    } catch (error) {
      this.context.updateSnack({ show: true, color: "error", message: "Error submitting pricing" });
    }
    this.setState({ loading: false });
  };

  setGridHeights = () => {
    const primarysplit = document.getElementById("main_splitter_div");
    this.setState({ gridHeight: primarysplit.clientHeight - 60 });
  };

  handleCheckboxSelect = async (rowData: PIInvoiceNumbers) => {
    this.setState({ loadingLines: true });
    const exists = this.state.selectedRows.find((selected) => selected.sale_invoicenumber == rowData.sale_invoicenumber);

    if (exists) {
      const filteredRows = this.state.selectedRows.filter((sr) => sr.sale_invoicenumber != rowData.sale_invoicenumber);
      const filteredLines = this.state.data.filter((item) => item.invoiceNumber != rowData.sale_invoicenumber);

      this.setState({ selectedRows: filteredRows, data: filteredLines });
    } else {
      const result = await getInvoiceIntegrationByInvoice(rowData.sale_invoicenumber);
      this.setState({ selectedRows: [...this.state.selectedRows, rowData], data: [...this.state.data, ...result] });
    }

    this.setState({ loadingLines: false });
  };

  gridColumnsOverride = (data, filters, columnArrangement, columnsWidth) => {
    return GridColumns(data, filters, columnArrangement, columnsWidth, this.state.selectedRows, this.handleCheckboxSelect);
  };

  handleClearSelected = () => {
    this.setState({ data: [], selectedRows: [] });
  };

  filteredRows;
  handleFilteredRows = (rows: any[]) => {
    this.filteredRows = rows;
  };

  filteredDataRows;
  handleFilteredDataRows = (rows: any[]) => {
    this.filteredDataRows = rows;
  };

  handleSelectAll = async () => {
    this.setState({ selectedRows: this.filteredRows, loadingLines: true });

    const list = this.filteredRows.map((item) => item.sale_invoicenumber).join(",");
    const result = await getInvoiceIntegrationByInvoice(list);

    this.setState({ data: result, loadingLines: false });
  };

  render() {
    const { classes } = this.props;

    return (
      <>
        {this.state.loading && <LinearProgress color="secondary" />}
        {this.state.loadingLines && <LinearProgress color="secondary" />}
        {this.state.clearAudit.length > 0 && (
          <Confirmation
            isOpen={true}
            handleClose={this.handleClearAuditClose}
            handleConfirm={this.handleClearAuditConfirm}
            title={`Clear Audit`}
            body={`Are you sure you would like to Clear the Audit details?`}
          ></Confirmation>
        )}
        <div id="main_splitter_div" className={classes.splitterWrapper}>
          <Splitter
            position="vertical"
            primaryPaneWidth="20%"
            primaryPaneMinWidth="20%"
            primaryPaneMaxWidth="50%"
            dispatchResize={true}
            postPoned={true}
            onDragFinished={this.setGridHeights}
            className={classes.splitter}
          >
            <div className={classes.toolbarWrapper}>
              <Toolbar className={classes.toolbar}>
                <OrangeButton variant="contained" onClick={this.handleSelectAll} disabled={(this.state.invoiceNumbersData || []).length === 0}>
                  Select All
                </OrangeButton>
                <OrangeButton variant="contained" onClick={this.handleClearSelected} disabled={(this.state.selectedRows || []).length === 0}>
                  Clear Selected
                </OrangeButton>
              </Toolbar>
              <Grid
                hideDownloadAndLayout
                forceHeight={this.state.gridHeight}
                loading={this.state.loading}
                clearFilters={"mgpintegration"}
                data={this.state.invoiceNumbersData}
                GridColumns={this.gridColumnsOverride}
                handleFilteredRows={this.handleFilteredRows}
              />
            </div>
            <div className={classes.toolbarWrapper}>
              <Toolbar className={classes.toolbarRight}>
                <Button onClick={this.handleReloadInvoices} variant="contained" color="secondary">
                  Reload
                </Button>
                <OrangeButton onClick={this.handleClearSelected} variant="contained" color="primary">
                  clear selected
                </OrangeButton>
              </Toolbar>
              <InvoiceLines
                SelectedInvoiceLines={this.state.data}
                clearAudit={this.handleClearAudit}
                handleRowsChanged={this.handleRowsChanged}
                handleUpdateAccepted={this.handleUpdateAccepted}
                submitPrices={this.submitPrices}
              />
            </div>
          </Splitter>
        </div>
      </>
    );
  }
}

export default withStyles(styles)(MGPIntergrationGridUnstyled);
