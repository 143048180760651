import { client, apiHeaders, request } from "./_base";

// farm  api exports
export const identities = client("identities", (item) => ({
  ...item,
}));

export const register = async (data: { data: { username: string; password: string; admin: number } }): Promise<void> => {
  const content = JSON.stringify(data);
  const result = await request(`register`, {
    method: "POST",
    headers: apiHeaders(content),
    body: content,
  });
  if (result.status == 500) {
    const resp = await result.json();
    throw resp;
  }
};
