import React from "react";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";

import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

import { SnackContext } from "../lib/context/SnackContext";

import LoadoutDetailGrid from "./loadoutdetail/loadoutdetailgrid";
import BarcodeGrid from "./barcode/barcodegrid";
import StockDetailGrid from "./stockdetail/stockdetailgrid";
import ConsolidationGrid from "./consolidation/consolidationgrid";
import FileImportHeaderInfoGrid from "./fileimportheaderinfo/fileimportheaderinfogrid";
import BarcodePalletSizeGrid from "./barcodepalletsize/barcodepalletsizegrid";
import StockDuplicationGrid from "./stockduplication/stockduplicationgrid";
import InvoiceVsDetailsGrid from "./invoicevsdetails/invoicevsdetailsgrid";

function a11yProps(index) {
  return {
    id: `systeminfo-tab-${index}`,
    "aria-controls": `systeminfo-tabpanel-${index}`,
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1),
    },
  });

type SystemStabilityProps = {} & WithStyles<typeof styles>;

const SystemStabilityUnstyled: React.FunctionComponent<SystemStabilityProps> = (props) => {
  const { classes } = props;
  const [value, setValue] = React.useState(0);

  const { updateSnack } = React.useContext(SnackContext);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Tabs value={value} onChange={handleChange} aria-label="System Info">
          <Tab label="Loadout Detail" {...a11yProps(0)} />
          <Tab label="Barcode" {...a11yProps(1)} />
          <Tab label="Stock Detail" {...a11yProps(2)} />
          <Tab label="FileImport HeaderInfo" {...a11yProps(3)} />
          <Tab label="Barcode Pallet Size" {...a11yProps(4)} />
          <Tab label="Stock Duplication" {...a11yProps(5)} />
          <Tab label="Consolidation" {...a11yProps(6)} />
          <Tab label="Invoice VS Details" {...a11yProps(7)} />
        </Tabs>
      </AppBar>

      <TabPanel value={value} index={0}>
        <LoadoutDetailGrid />
      </TabPanel>

      <TabPanel value={value} index={1}>
        <BarcodeGrid />
      </TabPanel>

      <TabPanel value={value} index={2}>
        <StockDetailGrid />
      </TabPanel>

      <TabPanel value={value} index={3}>
        <FileImportHeaderInfoGrid />
      </TabPanel>

      <TabPanel value={value} index={4}>
        <BarcodePalletSizeGrid />
      </TabPanel>

      <TabPanel value={value} index={5}>
        <StockDuplicationGrid />
      </TabPanel>

      <TabPanel value={value} index={6}>
        <ConsolidationGrid />
      </TabPanel>

      <TabPanel value={value} index={7}>
        <InvoiceVsDetailsGrid />
      </TabPanel>
    </div>
  );
};

export default withStyles(styles)(SystemStabilityUnstyled);
