import React from "react";

import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";

import CustomAutoCompleteFilter from "../../lib/components/customdatagridfilter/CustomFilter";
import { CellActionsFormatter } from "../../lib/helpers/CellActionsFormatter";
import { DateFormatter } from "../../lib/components/Formatters";

const cellWidthSmall = 80;
const cellWidthSmallBig = 140;
const cellWidthMedium = 200;

const defaultColumnProperties = {
  resizable: true,
  sortable: true,
};

let currentSpace = true;
export const GridColumns = (data, filters, handleRemove, handleEdit) => {
  let postValue = "";

  if (data) {
    if (currentSpace) {
      postValue = " ";
    }
    currentSpace = !currentSpace;
  }

  return [
    {
      key: "actions",
      name: "Actions",
      width: cellWidthSmall,
      formatter: ({ row }) => {
        const actions = cellActions(row, handleRemove, handleEdit);
        return (
          <div style={{ display: "flex", justifyContent: "center", gap: "5px" }}>
            <CellActionsFormatter actions={actions} />
          </div>
        );
      },
    },
    {
      key: "week",
      name: "Week" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"week"} data={data} section={"weeksgridsetup"} />;
      },
    },
    {
      key: "period_start",
      name: "Period Start" + postValue,
      width: cellWidthSmallBig,
      filterable: true,
      formatter: DateFormatter,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"period_start"} data={data} section={"weeksgridsetup"} />;
      },
    },
    {
      key: "period_end",
      name: "Period End" + postValue,
      width: cellWidthSmallBig,
      filterable: true,
      formatter: DateFormatter,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"period_end"} data={data} section={"weeksgridsetup"} />;
      },
    },
    {
      key: "financial_year",
      name: "Financial Year" + postValue,
      width: cellWidthSmallBig,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"financial_year"} data={data} section={"weeksgridsetup"} />;
      },
    },
  ].map((c) => ({ ...c, ...defaultColumnProperties }));
};

const cellActions = (order, handleRemove, handleEdit) => {
  return [
    {
      icon: (
        <span title="Edit">
          <EditIcon style={{ cursor: "pointer", color: "orange" }} />
        </span>
      ),
      callback: (e) => {
        handleEdit(order.id);
      },
    },
    {
      icon: (
        <span title="Remove">
          <DeleteIcon style={{ cursor: "pointer", color: "red" }} />
        </span>
      ),
      callback: (e) => {
        handleRemove(order.id);
      },
    },
  ];
};
