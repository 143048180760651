import React, { useState, useContext, FC, useEffect } from "react";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";

import Button from "@material-ui/core/Button";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import LinearProgress from "@material-ui/core/LinearProgress";
import Modal from "@material-ui/core/Modal";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

import ReactDatePicker from "react-datepicker";
import toDate from "date-fns/toDate";
import format from "date-fns/format";
import filesaver from "file-saver";
import JSZip from "jszip";
import LZString from "lz-string";

import { SnackContext } from "../lib/context/SnackContext";
import { GenerateErrorMessage } from "../lib/helpers/string_methods";
import { PDFIcon } from "../lib/components/MenuListItem";
import { getFinancialYearSelected } from "../lib/api/week";
import { getProducerReadyForComboMapped } from "../lib/api/producer";
import { InspectionReportType, getInspectionsByProducerAndDate } from "../lib/api/inspection";

import { handlePrint } from "../reports/inspectionPDF";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
      gap: "1rem",
    },
    header: {
      display: "flex",
      flexDirection: "row",
      gap: "1rem",
    },
    actions: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-end",
      gap: "1rem",
    },
    datePickerWrapper: {
      display: "flex",
      flexDirection: "row",
      gap: "1rem",
      alignItems: "center",
    },
    modalWrapper: {
      position: "absolute",
      left: "45%",
      top: "50%",
      transform: "translate(-50%, -50%)",
    },
    formControl: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      gap: "1rem",
    },
  });

type StatementProps = {
  onClose(): void;
} & WithStyles<typeof styles>;

const ReportsUnstyled: FC<StatementProps> = (props) => {
  const { classes } = props;

  const { updateSnack } = useContext(SnackContext);

  const [loading, setLoading] = useState(false);
  const [dateFrom, setDateFrom] = useState(new Date(new Date(`1 Feb ${getFinancialYearSelected()}`).toDateString()));
  const [dateTo, setDateTo] = useState(new Date(new Date().toDateString()));
  const [includeMarketingImages, toggleIncludeMarketingImages] = useState(false);
  const [includeInspectionReport, toggleInspectionReport] = useState(true);

  const [producerList, setProducerList] = useState([]);
  const [selectedProducer, setSelectedProducer] = useState("");

  const loadData = async () => {
    setLoading(true);
    try {
      const producers = await getProducerReadyForComboMapped();
      setProducerList(producers);
    } catch (error) {
      const err = GenerateErrorMessage(error, "Failed to get data");
      updateSnack({ show: true, color: "red", message: err });
    }
    setLoading(false);
  };

  useEffect(() => {
    loadData();
  }, []);

  const changeAdjustDate = (value: Date, type: string = "from") => {
    if (type == "from") {
      setDateFrom(new Date(value.toDateString()));
    } else {
      setDateTo(new Date(value.toDateString()));
    }
  };

  const handlePrintPdf = async () => {
    setLoading(true);
    try {
      if (!selectedProducer || selectedProducer === "") {
        throw { data: "Please select a Producer" };
      }
      if (!includeInspectionReport && !includeMarketingImages) {
        throw { data: "Please select Inspection Report or Marketing Images" };
      }

      const zip = new JSZip();
      const inspections = await getInspectionsByProducerAndDate(selectedProducer, format(dateTo, "yyyy-MM-dd"), format(dateFrom, "yyyy-MM-dd"), includeMarketingImages);

      const groupedInspections: { [index: string]: InspectionReportType } = inspections.reduce((obj, row) => {
        const inspectionactions_images = row.inspectionactions_field === "general" ? JSON.parse(row.inspectionactions_images || "[]").map((img) => img.base64) : [];
        const inspectionnotes_images = JSON.parse(row.inspectionnotes_images || "[]");

        delete row["inspectionactions_images"];
        delete row["inspectionnotes_images"];

        if (!obj[row.inspection_number]) {
          obj[row.inspection_number] = row;
        }

        obj[row.inspection_number] = {
          ...obj[row.inspection_number],
          images: [...(obj[row.inspection_number]["images"] || []), ...inspectionactions_images, ...inspectionnotes_images],
        };

        return obj;
      }, {});

      for (const inspection_number of Object.keys(groupedInspections)) {
        const inspection = groupedInspections[inspection_number];

        if (includeInspectionReport) {
          const raw = await handlePrint(inspection, false, false, true);
          zip.file(`${inspection.ordernum}/${inspection.ordernum}_${inspection.inspection_number}_Inspection_Report.pdf`, raw);
        }

        if (includeMarketingImages) {
          let index = 0;

          for (const image of inspection.images) {
            index++;
            const imgB64StringDCompressed = image.includes("base64") ? image : LZString.decompress(image);
            const imageName = `${inspection.ordernum}/Marketing Images/${inspection.ordernum}_${index}_${inspection.inspection_number}.${imgB64StringDCompressed
              .toString()
              .substring(imgB64StringDCompressed.indexOf("/") + 1, imgB64StringDCompressed.indexOf(";"))}`;

            zip.file(imageName, imgB64StringDCompressed.split("base64,").pop().toString(), { base64: true });
          }
        }
      }

      const content = await zip.generateAsync({ type: "blob", mimeType: "application/zip" });
      filesaver.saveAs(new Blob([content], { type: "application/zip" }), "REPORTS.zip");
    } catch (error) {
      const err = GenerateErrorMessage(error, "Failed to export reports");
      updateSnack({ show: true, color: "red", message: err });
    }
    setLoading(false);
  };

  return (
    <div className={classes.root}>
      {loading ? <LinearProgress /> : <div style={{ height: "5px" }}></div>}
      <div>
        <TableFieldCombo classes={classes} selectedValue={selectedProducer} handleChange={setSelectedProducer} data={producerList} title="Producer" />
      </div>
      <div className={classes.header}>
        <TableFieldDate classes={classes} dateValue={dateFrom} title="From" changeDate={(value) => changeAdjustDate(value, "from")} disabled={loading} />
        <TableFieldDate classes={classes} dateValue={dateTo} title="To" changeDate={(value) => changeAdjustDate(value, "to")} disabled={loading} />
      </div>
      <div>
        <FormControlLabel
          control={
            <Checkbox checked={includeInspectionReport} disabled={loading} onChange={(event) => toggleInspectionReport(event.target.checked)} name="exclude" color="primary" />
          }
          label="Include QC Inspection Reports"
        />
        <FormControlLabel
          control={
            <Checkbox checked={includeMarketingImages} disabled={loading} onChange={(event) => toggleIncludeMarketingImages(event.target.checked)} name="exclude" color="primary" />
          }
          label="Include Market Images"
        />
      </div>
      <div className={classes.actions}>
        <Button variant="contained" onClick={handlePrintPdf} disabled={loading}>
          <PDFIcon /> <span style={{ marginLeft: "1rem" }}>PDF EXPORT</span>
        </Button>
      </div>
    </div>
  );
};

export default withStyles(styles)(ReportsUnstyled);

const TableFieldCombo: React.FunctionComponent<
  { classes: any; selectedValue: any; data: any[]; title: string; handleChange(selectedValue: any): void; disabled?: boolean } & WithStyles<typeof styles>
> = (props) => {
  const { classes, selectedValue, handleChange, data, title, disabled = false } = props;
  return (
    <FormControl className={classes.formControl}>
      <label id={`input-label-${title}`}>{title}:</label>
      <Select fullWidth labelId={`id-label-${title}`} id={`id-${title}`} value={selectedValue} onChange={(ev) => handleChange(ev.target.value)} style={{ margin: "-10px" }}>
        {data.map((item) => (
          <MenuItem value={item.value}>{item.display}</MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

const TableFieldDate: React.FunctionComponent<{ dateValue: Date; title: string; changeDate: (value) => void; disabled: boolean } & WithStyles<typeof styles>> = (props) => {
  const { classes, dateValue, title, changeDate, disabled } = props;
  const [open, setOpen] = useState(false);

  const handleChange = (value: Date) => {
    changeDate(value);
    setOpen(!open);
  };

  return (
    <div className={classes.datePickerWrapper}>
      <label>{title}:</label>
      <div>
        <Button onClick={() => setOpen(!open)} disabled={disabled} variant="contained" color="primary" style={{ marginTop: "5px", width: "250px" }}>
          {format(dateValue, "dd-MM-yyyy")}
        </Button>
      </div>
      <Modal open={open} onClick={() => setOpen(false)}>
        <div className={classes.modalWrapper}>
          <ReactDatePicker
            open
            locale="en-GB"
            showWeekNumbers
            customInput={<></>}
            disabled={disabled}
            showPopperArrow={false}
            dateFormat="dd-MM-yyyy"
            placeholderText="click here to select a date"
            onChange={handleChange}
            selected={toDate(dateValue)}
          />
        </div>
      </Modal>
    </div>
  );
};
