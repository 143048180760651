import React, { useEffect, useMemo, useState, useCallback } from "react";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";

import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";

import Check from "@material-ui/icons/Check";
import Close from "@material-ui/icons/Close";
import SettingsIcon from "@material-ui/icons/Settings";

// import { FormApi } from "final-form";
import { Form, Field } from "react-final-form";
import { TextField, Select, Checkbox } from "final-form-material-ui";
import ReactDatePicker, { registerLocale } from "react-datepicker";
import en from "date-fns/esm/locale/en-GB";
registerLocale("en-GB", en);

import MaintLabTestType from "../labteststype/labtesttype";

// import LabTestImageDropZone from "./labtestsImageDropZone";
import LabTestsAttachmentDialog from "./labtestsAttachmentDialog";

import { labtest, LabTestType } from "../../lib/api/labtest";
import { getProducerReadyForCombo } from "../../lib/api/producer";
import { getPucsByProducerReadyForCombo } from "../../lib/api/producerpuc";
import { getOrchardsByPucReadyForCombo } from "../../lib/api/producerorchard";
import { varieties, VarietiesByCommodityMappedForCombo, VarietyType } from "../../lib/api/variety";
import { commoditiesAllSortedMappedforCombo } from "../../lib/api/commodity";
import { getLabTestTypeReadyForCombo } from "../../lib/api/labtesttype";
import { isEquivalentObject } from "../../lib/helpers/string_methods";
import Confirmation from "../../lib/components/confirmation";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1),
      paddingTop: theme.spacing(2),
      width: "900px",
      height: "550px",
    },
    formContainer: {
      display: "flex",
      flexDirection: "row",
    },
    formStyle: {
      flexGrow: 1,
    },
    formButtonsContainer: {
      display: "flex",
      justifyContent: "flex-end",
      marginTop: "20px",
      marginRight: "28px",
    },
    formAttachments: {
      flexGrow: 1,
      overflow: "auto",
      minWidth: "400px",
    },
    tableRowFieldTitle: {
      paddingTop: theme.spacing(1),
      paddingRight: theme.spacing(1) * 2,
      marginTop: theme.spacing(1),
      textAlign: "right",
      minWidth: "175px",
    },
    tableRowFieldData: {
      paddingTop: theme.spacing(0.5),
      textAlign: "left",
      width: "250px",
    },
    tableRowFieldCombo: {
      textAlign: "left",
      width: "250px",
    },
    tableRowFieldCalender: {
      padding: theme.spacing(0.5),
      marginTop: theme.spacing(1),
      textAlign: "left",
      width: "250px",
    },
    tableRowFieldCheck: {
      marginTop: "5px",
      textAlign: "left",
      width: "50px",
    },
    comboFieldContainer: {
      display: "flex",
      flexDirection: "row",
    },
    comboFieldWithButton: {
      textAlign: "left",
      flexGrow: 1,
      maxWidth: "196.98px",
    },
    comboFieldButton: {
      height: "47.96px",
    },
    maintLabTestTypeContainer: {
      display: "flex",
      flexDirection: "column",
      width: "900px",
      height: "600px",
    },
    mainLabTestTypeClosebutton: {
      position: "absolute",
      top: "-50px",
      right: "22px",
    },
  });

type LabTestEditFormProps = {
  onSubmit: any;
  onClose: any;
  id: any;
} & WithStyles<typeof styles>;

const LabTestEditFormUnstyled: React.FunctionComponent<LabTestEditFormProps> = (props) => {
  const { classes, onSubmit, onClose, id } = props;

  const [item, setItem] = React.useState<LabTestType>({
    id: 0,
    sampledate: new Date(),
    sampledateend: new Date(),
    lab: "",
    producer_id: 0,
    puc_id: 0,
    orchard_id: 0,
    commodity_id: "",
    variety_id: 0,
    labtesttype_id: 0,
    approved: 0,
  });
  const [producers, setProducers] = React.useState([]);
  const [pucs, setPucs] = React.useState([]);
  const [commodities, setCommodities] = React.useState([]);
  const [files, setFiles] = React.useState([]);
  const [testTypes, setTestTypes] = useState([]);
  const [showManageTypes, setShowManageTypes] = useState(false);

  const [orchards, setOrchards] = React.useState([]);

  useMemo(async () => {
    if (item.puc_id) {
      const result = await getOrchardsByPucReadyForCombo(item.puc_id);
      setOrchards(result);
      setItem({ ...item, orchard_id: result && result.length > 0 ? result[0].value : 0 });
    }
  }, [item.puc_id]);

  const [varietiesd, setVarietiesd] = React.useState([]);

  useMemo(async () => {
    if (item.commodity_id) {
      const result = await VarietiesByCommodityMappedForCombo(item.commodity_id);
      setVarietiesd(result);
    }
  }, [item.commodity_id]);

  useEffect(() => {
    labtest.single(id).then((data: LabTestType) => {
      if (data) {
        let datanew = { ...data, sampledate: new Date(data.sampledate), sampledateend: new Date(data.sampledateend) };
        if (data.variety_id) {
          varieties.single(data.variety_id).then((result: VarietyType) => {
            setItem({ ...datanew, commodity_id: result.commodity_id });
          });
        } else {
          setItem({ ...datanew });
        }
      }
    });

    getProducerReadyForCombo().then((result) => {
      setProducers(result);
    });
    commoditiesAllSortedMappedforCombo().then((result) => {
      setCommodities(result);
    });
    getLabTestTypeReadyForCombo().then((result) => {
      setTestTypes(result);
    });
  }, [id]);

  const onChangeSampleDate = (start, end) => {
    setItem({ ...item, sampledate: start, sampledateend: end });
  };

  const handleChangeAttachment = (files: []) => {
    setFiles(files);
  };

  const onManageTypeOpen = () => {
    setShowManageTypes(!showManageTypes);
  };

  const onManageTypeClose = async () => {
    await getLabTestTypeReadyForCombo().then((result) => {
      setTestTypes(result);
    });
    onManageTypeOpen();
  };

  return (
    <div className={classes.root}>
      <Form
        initialValues={{
          ...item,
        }}
        onSubmit={(values: LabTestType) => {
          onSubmit(values, files);
        }}
        validate={(values: LabTestType) => {
          let errors = {};

          if (values.producer_id.toString() != item.producer_id.toString()) {
            getPucsByProducerReadyForCombo(values.producer_id).then((result) => {
              setPucs(result);
              const pucid = values.puc_id && values.puc_id != 0 ? values.puc_id : result && result.length > 0 ? result[0].value : 0;
              setItem({ ...values, producer_id: values.producer_id, puc_id: pucid });
            });
          }
          if (values.puc_id.toString() != item.puc_id.toString()) setItem({ ...values });
          if (!isEquivalentObject(values, item)) setItem({ ...values });

          if (values.lab === "") errors = { ...errors, lab: "Invalid Lab. Please enter a Lab" };
          if (values.producer_id === 0) errors = { ...errors, producer_id: "Invalid Producers. Please select a Producer" };
          if (values.puc_id === 0) errors = { ...errors, puc_id: "Invalid PUC. Please select a PUC value/ID" };
          if (values.orchard_id === 0) errors = { ...errors, orchard_id: "Invalid Orchard. Please select an Orchard" };
          if (values.commodity_id === "") errors = { ...errors, commodity_id: "Invalid Commodity. Please select a Commodity" };
          if (values.variety_id === 0) errors = { ...errors, variety_id: "Invalid Variety. Please select a Variety" };
          if (values.labtesttype_id === 0) errors = { ...errors, labtesttype_id: "Invalid Labtest Type. Please select a Labtest Type" };

          return errors;
        }}
        render={({ handleSubmit, form, values }) => {
          return (
            <form
              id={id}
              onSubmit={(values) => {
                handleSubmit(values);
              }}
            >
              <div className={classes.formContainer}>
                <div className={classes.formStyle}>
                  <table>
                    <tbody>
                      <TableFieldDatePicker
                        classes={classes}
                        fieldStart={values.sampledate}
                        fieldEnd={values.sampledateend}
                        title="Sample Valid Start"
                        onChange={onChangeSampleDate}
                        startSelection={true}
                      />
                      <TableFieldDatePicker
                        classes={classes}
                        fieldStart={values.sampledate}
                        fieldEnd={values.sampledateend}
                        title="Sample Valid End"
                        onChange={onChangeSampleDate}
                        startSelection={false}
                      />
                      <TableFieldText classes={classes} field="lab" title="Lab" disabled={false} multiline={false} required={false} />
                      <TableFieldCombo classes={classes} title="Producer" field="producer_id" data={producers} />
                      <TableFieldCombo classes={classes} title="PUC" field="puc_id" data={pucs} />
                      <TableFieldCombo classes={classes} title="Orchard" field="orchard_id" data={orchards} />
                      <TableFieldCombo classes={classes} title="Commodity" field="commodity_id" data={commodities} />
                      <TableFieldCombo classes={classes} title="Variety" field="variety_id" data={varietiesd} />
                      <TableFieldCombo classes={classes} title="Type" field="labtesttype_id" data={testTypes} onManageHandler={onManageTypeOpen} />
                      <TableFieldCheck classes={classes} title="Approved" field="approved" disabled={false} />
                    </tbody>
                  </table>
                  <div className={classes.formButtonsContainer}>
                    <Button style={{ marginRight: "10px" }} color="secondary" variant="outlined" onClick={onClose}>
                      <Close />
                    </Button>
                    <Button type="submit" color="primary" variant="outlined">
                      <Check />
                    </Button>
                  </div>
                </div>
                <div className={classes.formAttachments}>
                  <LabTestsAttachmentDialog labtestid={id} handleChange={handleChangeAttachment} />
                </div>
              </div>
            </form>
          );
        }}
      />
      {showManageTypes && (
        <Confirmation isOpen={showManageTypes ? true : false} handleClose={onManageTypeOpen} handleConfirm={() => {}} title={"Manage Lab Test Types"} body={undefined}>
          <div className={classes.maintLabTestTypeContainer}>
            <div className={classes.mainLabTestTypeClosebutton}>
              <Button variant="contained" color="secondary" onClick={onManageTypeClose}>
                <Close />
              </Button>
            </div>
            <MaintLabTestType />
          </div>
        </Confirmation>
      )}
    </div>
  );
};
export default withStyles(styles)(LabTestEditFormUnstyled);

export const TableFieldCheck: React.FunctionComponent<{ field: string; title: string; disabled: boolean } & WithStyles<typeof styles>> = (props) => {
  const { classes, title, field, disabled } = props;

  return (
    <tr>
      <td className={classes.tableRowFieldTitle}>
        <span>{`${title}: `}</span>
      </td>
      <td>
        <Field name={field} key={`body_value_${field}`} component={Checkbox} type="checkbox" className={classes.tableRowFieldCheck} disabled={disabled} />
      </td>
    </tr>
  );
};

export const TableFieldText: React.FunctionComponent<{ field: string; title: string; disabled: boolean; multiline: boolean; required: boolean } & WithStyles<typeof styles>> = (
  props,
) => {
  const { classes, title, field, disabled, multiline, required } = props;

  return (
    <tr>
      <td className={classes.tableRowFieldTitle}>
        <span>{`${title}: `}</span>
      </td>
      <td>
        <Field
          name={field}
          key={`body_value_${field}`}
          component={TextField}
          type="text"
          className={classes.tableRowFieldData}
          multiline={multiline}
          rows={multiline ? "4" : "1"}
          required={required}
          disabled={disabled}
        />
      </td>
    </tr>
  );
};

export const TableFieldDatePicker: React.FunctionComponent<
  { fieldStart: Date; fieldEnd: Date; title: string; onChange: any; startSelection: boolean } & WithStyles<typeof styles>
> = (props) => {
  const { classes, title, fieldStart, fieldEnd, onChange, startSelection } = props;

  return (
    <tr>
      <td className={classes.tableRowFieldTitle}>
        <span>{`${title}: `}</span>
      </td>
      <td>
        {startSelection ? (
          <ReactDatePicker
            className={classes.tableRowFieldCalender}
            selected={fieldStart}
            onChange={(value) => {
              onChange(value, fieldEnd);
            }}
            locale="en-GB"
            showWeekNumbers={true}
            dateFormat="dd-MM-yyyy"
            startDate={fieldStart}
            endDate={fieldEnd}
            selectsStart
          />
        ) : (
          <ReactDatePicker
            className={classes.tableRowFieldCalender}
            selected={fieldEnd}
            onChange={(value) => {
              onChange(fieldStart, value);
            }}
            locale="en-GB"
            showWeekNumbers={true}
            dateFormat="dd-MM-yyyy"
            startDate={fieldStart}
            endDate={fieldEnd}
            selectsEnd
          />
        )}
      </td>
    </tr>
  );
};

export const TableFieldCombo: React.FunctionComponent<{ classes: any; title: string; field: string; data: any; onManageHandler?: any } & WithStyles<typeof styles>> = (props) => {
  const { classes, title, field, data, onManageHandler } = props;

  return (
    <tr>
      <td className={classes.tableRowFieldTitle}>{`${title}:`}</td>
      <td>
        <div className={classes.comboFieldContainer}>
          <Field
            name={field}
            component={Select}
            formControlProps={{
              className: onManageHandler ? classes.comboFieldWithButton : classes.tableRowFieldCombo,
            }}
            native
          >
            <option key={`none-1`} value={0}>
              {`NONE`}
            </option>
            {data &&
              data.length > 0 &&
              data.map((item, index) => {
                return (
                  <option key={`${item.value}${index}`} value={item.value}>
                    {item.display}
                  </option>
                );
              })}
          </Field>
          {onManageHandler && (
            <Button variant="contained" color="primary" className={classes.comboFieldButton} onClick={onManageHandler}>
              <SettingsIcon />
            </Button>
          )}
        </div>
      </td>
    </tr>
  );
};
