import React from "react";
import CustomAutoCompleteFilter from "../../lib/components/customdatagridfilter/CustomFilter";

import { SummaryTotal, SummaryNumber, SummaryNumber2Decimal } from "../../lib/components/SummaryFormatter";
import { EnquiryLink, Selector } from "../../lib/components/EnquiryLink";
import { isNullOrUndef } from "../../lib/helpers/isNullOrUndef";
import { DateFormatter, FormatterNumber, FormatterNumber2Decimals } from "../../lib/components/Formatters";
import { handleColumnsArrangement, handleColumnsWidth } from "../../lib/helpers/grid";

const cellWidthSmallX = 80;
const cellWidthMedium = 150;

const defaultColumnProperties = {
  resizable: true,
  sortable: true,
};

let currentSpace = true;

const TermsFormatter = ({ row }) => {
  return <div>{isNullOrUndef(row.terms_description) ? "CUSTOM" : row.terms_description}</div>;
};

export const GridColumnsSales = (data, filters, arrangement = undefined, columnsWidth = undefined) => {
  let postValue = "";

  if (data) {
    if (currentSpace) {
      postValue = " ";
    }
    currentSpace = !currentSpace;
  }

  const columns = [
    {
      key: "sale_invoicenumber",
      name: "Invoice Number" + postValue,
      width: cellWidthMedium,
      filterRenderer: (e) => {
        return (
          <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"sale_invoicenumber"} data={data} section="creditorsinfosalesdetailfilter" />
        );
      },
      filterable: true,
      formatter: (props) => <EnquiryLink {...props} selector={Selector.InvoiceNumber} />,
      summaryFormatter: SummaryTotal,
    },
    {
      key: "dispatch_forwardagentref",
      name: "Forward Ref" + postValue,
      width: cellWidthMedium,
      filterRenderer: (e) => {
        return (
          <CustomAutoCompleteFilter
            {...e}
            multiSelection={true}
            currentFilters={filters}
            fieldname={"dispatch_forwardagentref"}
            data={data}
            section="creditorsinfosalesdetailfilter"
          />
        );
      },
      filterable: true,
    },
    {
      key: "vessel_code",
      name: "Vessel" + postValue,
      width: cellWidthMedium,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"vessel_code"} data={data} section="creditorsinfosalesdetailfilter" />;
      },
      filterable: true,
    },
    {
      key: "dispatch_containerno",
      name: "Container No" + postValue,
      width: cellWidthMedium,
      filterRenderer: (e) => {
        return (
          <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"dispatch_containerno"} data={data} section="creditorsinfosalesdetailfilter" />
        );
      },
      filterable: true,
    },
    {
      key: "dispatch_dispatchdocs_id",
      name: "Dispatch" + postValue,
      width: cellWidthMedium,
      filterRenderer: (e) => {
        return (
          <CustomAutoCompleteFilter
            {...e}
            multiSelection={true}
            currentFilters={filters}
            fieldname={"dispatch_dispatchdocs_id"}
            data={data}
            section="creditorsinfosalesdetailfilter"
          />
        );
      },
      filterable: true,
    },
    {
      key: "dispatch_loaddate",
      name: "Load Date" + postValue,
      width: cellWidthMedium,
      filterRenderer: (e) => {
        return (
          <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"dispatch_loaddate"} data={data} section="creditorsinfosalesdetailfilter" />
        );
      },
      formatter: DateFormatter,
      filterable: true,
    },
    {
      key: "sale_ucr",
      name: "UCR" + postValue,
      width: cellWidthMedium,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"sale_ucr"} data={data} section="creditorsinfosalesdetailfilter" />;
      },
      filterable: true,
    },
    {
      key: "producer_code",
      name: "Producer" + postValue,
      width: cellWidthMedium,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"producer_code"} data={data} section="creditorsinfosalesdetailfilter" />;
      },
      filterable: true,
    },
    {
      key: "loadout_consignee",
      name: "Client" + postValue,
      width: cellWidthMedium,
      filterRenderer: (e) => {
        return (
          <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"loadout_consignee"} data={data} section="creditorsinfosalesdetailfilter" />
        );
      },
      filterable: true,
    },
    {
      key: "terms_description",
      name: "Terms" + postValue,
      width: cellWidthMedium,
      filterRenderer: (e) => {
        return (
          <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"terms_description"} data={data} section="creditorsinfosalesdetailfilter" />
        );
      },
      formatter: TermsFormatter,
      filterable: true,
    },
    {
      key: "currency_code",
      name: "Currency" + postValue,
      width: cellWidthMedium,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"currency_code"} data={data} section="creditorsinfosalesdetailfilter" />;
      },
      filterable: true,
    },
    {
      key: "users_username",
      name: "Trader" + postValue,
      width: cellWidthMedium,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"users_username"} data={data} section="creditorsinfosalesdetailfilter" />;
      },
      filterable: true,
    },

    {
      key: "barcode_nocartons",
      name: "Total Cartons" + postValue,
      width: cellWidthMedium,
      formatter: FormatterNumber,
      filterRenderer: (e) => {
        return (
          <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"barcode_nocartons"} data={data} section="creditorsinfosalesdetailfilter" />
        );
      },
      filterable: true,
      summaryFormatter: SummaryNumber,
    },
    {
      key: "barcode_palletsize",
      name: "Total Pallets" + postValue,
      width: cellWidthMedium,
      formatter: FormatterNumber2Decimals,
      filterRenderer: (e) => {
        return (
          <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"barcode_palletsize"} data={data} section="creditorsinfosalesdetailfilter" />
        );
      },
      filterable: false,
      summaryFormatter: SummaryNumber2Decimal,
    },
    {
      key: "total",
      name: "Total Sale" + postValue,
      width: cellWidthMedium,
      formatter: FormatterNumber2Decimals,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"total"} data={data} section="creditorsinfosalesdetailfilter" />;
      },
      filterable: false,
      summaryFormatter: SummaryNumber2Decimal,
    },
    {
      key: "orderno",
      name: "Order No" + postValue,
      width: cellWidthMedium,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"orderno"} data={data} section="creditorsinfosalesdetailfilter" />;
      },
      filterable: true,
    },
    {
      key: "sale_exchangerate",
      name: "Exchange Rate" + postValue,
      width: cellWidthMedium,
      formatter: FormatterNumber2Decimals,
      filterRenderer: (e) => {
        return (
          <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"sale_exchangerate"} data={data} section="creditorsinfosalesdetailfilter" />
        );
      },
      filterable: true,
    },
    {
      key: "target_market",
      name: "Target Market" + postValue,
      width: cellWidthMedium,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"target_market"} data={data} section="creditorsinfosalesdetailfilter" />;
      },
      filterable: true,
    },
    {
      key: "portfinal_code",
      name: "Destination" + postValue,
      width: cellWidthMedium,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"portfinal_code"} data={data} section="creditorsinfosalesdetailfilter" />;
      },
      filterable: true,
    },
    {
      key: "portdistcharge_code",
      name: "Discharge" + postValue,
      width: cellWidthMedium,
      filterRenderer: (e) => {
        return (
          <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"portdistcharge_code"} data={data} section="creditorsinfosalesdetailfilter" />
        );
      },
      filterable: true,
    },
    {
      key: "loadout_voyageNumber",
      name: "Shipping No" + postValue,
      width: cellWidthMedium,
      filterRenderer: (e) => {
        return (
          <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"loadout_voyageNumber"} data={data} section="creditorsinfosalesdetailfilter" />
        );
      },
      filterable: true,
    },
    {
      key: "dispatch_sealnumber",
      name: "Seal No" + postValue,
      width: cellWidthMedium,
      filterRenderer: (e) => {
        return (
          <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"dispatch_sealnumber"} data={data} section="creditorsinfosalesdetailfilter" />
        );
      },
      filterable: true,
    },
    {
      key: "loadout_etd",
      name: "ETD" + postValue,
      width: cellWidthMedium,
      formatter: DateFormatter,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"loadout_etd"} data={data} section="creditorsinfosalesdetailfilter" />;
      },
      filterable: true,
    },

    {
      key: "shipment_week",
      name: "Shipment Week" + postValue,
      width: cellWidthMedium,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"shipment_week"} data={data} section="creditorsinfosalesdetailfilter" />;
      },
      filterable: true,
    },
    {
      key: "loadout_eta",
      name: "ETA" + postValue,
      width: cellWidthMedium,
      formatter: DateFormatter,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"loadout_eta"} data={data} section="creditorsinfosalesdetailfilter" />;
      },
      filterable: true,
    },
    {
      key: "shippingCode",
      name: "Shipping Line" + postValue,
      width: cellWidthMedium,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"shippingCode"} data={data} section="creditorsinfosalesdetailfilter" />;
      },
      filterable: true,
    },
    {
      key: "portloading_code",
      name: "Port of Loading" + postValue,
      width: cellWidthMedium,
      filterRenderer: (e) => {
        return (
          <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"portloading_code"} data={data} section="creditorsinfosalesdetailfilter" />
        );
      },
      filterable: true,
    },
    {
      key: "agent_agentname",
      name: "Forwarding Agent Ref" + postValue,
      width: cellWidthMedium,
      filterRenderer: (e) => {
        return (
          <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"agent_agentname"} data={data} section="creditorsinfosalesdetailfilter" />
        );
      },
      filterable: true,
    },
  ].map((c) => ({ ...c, ...defaultColumnProperties }));

  let columnsArranged = [];
  columnsArranged = handleColumnsArrangement(columnsArranged, arrangement, columns);
  columnsArranged = handleColumnsWidth(columnsArranged, columnsWidth, columns);

  return columnsArranged;
};
