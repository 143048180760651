import React from "react";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";
import { Redirect } from "react-router-dom";

import LinearProgress from "@material-ui/core/LinearProgress";
import CircularProgress from "@material-ui/core/CircularProgress";

import { updateLoadoutByBarcode } from "../lib/api/loadoutdetail";
import { loadout } from "../lib/api/loadout";
import { stockDetailUpdateByBarcodeID } from "../lib/api/stockdetail";
import { loadoutattachment } from "../lib/api/loadoutattachment";

import LoadoutTable from "./loadouttable";
import AcceptDialog from "./loadoutAcceptDialog";
import AttachmentDialog from "./loadoutAttachmentDialog";

import { SnackContext } from "../lib/context/SnackContext";

import numeral from "numeral";
import { agent } from "../lib/api/agent";
import { getSaleDetailByReference } from "../lib/api/sale";
import { getFinancialYearSelected } from "../lib/api/week";

import format from "date-fns/format";
import { getAllShippingData } from "../lib/api/stock";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      overflow: "hidden",
      width: "100%",
      height: "calc(100% - 65px)",
    },
  });

type LoadoutProps = {
  reference: string;
  onForward: () => void;
} & WithStyles<typeof styles>;

const formatDate = (date: Date | string) => {
  if (!date || date.toString().length == 0) {
    return "";
  }
  return format(new Date(date), "dd MMM yyyy");
};

class LoadoutUnstyled extends React.Component<LoadoutProps> {
  state = {
    stock: [{}],
    loading: true,
    selectedLoadouts: undefined,
    selectedData: undefined,
    selectedUpdating: false,
    snackshow: undefined,
    snackmessage: undefined,
    snackcolor: undefined,
    loadoutAttachmentID: undefined,
    includeNew: true,
    includeLoaded: true,
    includeDispatched: true,
    openDispatchRequested: false,
    requestOpenDispatch: false,
    forwardedReference: undefined,
    onForward: undefined,
  };

  constructor(props) {
    super(props);
    this.state.forwardedReference = props.reference;
    this.state.onForward = props.onForward;
  }

  static contextType = SnackContext;
  context!: React.ContextType<typeof SnackContext>;

  componentDidMount() {
    this.loadStock();
  }

  loadStock = async () => {
    this.setState({ loading: true });
    return await getAllShippingData(getFinancialYearSelected()).then(async (result) => {
      if (result) {
        const resultData = result.map((item) => ({
          ...item,
          palletSize: item.palletSize ? numeral(item.palletSize).format("0,0.00") : item.palletSize,
          dateLoading_formatted: formatDate(item.dateLoading),
          loadout_eta_formatted: formatDate(item.loadout_eta),
          loadout_etd_formatted: formatDate(item.loadout_etd),
          loadout_updatedeta_formatted: formatDate(item.loadout_updatedeta),
          sale_ata_formatted: formatDate(item.sale_ata),
          sale_date_formatted: formatDate(item.sale_date),
          clients_code_alt: item.clients_code,
        }));
        this.setState({ stock: resultData, loading: false });

        const selectedLoadout = [];

        if (this.state.forwardedReference) {
          const findLoadout = result.find((item) => item.reference == this.state.forwardedReference);
          if (findLoadout) {
            selectedLoadout.push(findLoadout);
            await getSaleDetailByReference(this.state.forwardedReference).then((data) => {
              this.onAccept(
                data.filter((item) => item.loadout_reference != " "),
                selectedLoadout,
              );
            });
          }
        }
      }
    });
  };

  onAccept = (data, loadouts) => {
    if (data.length > 0) {
      this.setState({ selectedData: data, selectedUpdating: true, selectedLoadouts: loadouts });
    }
  };

  onAcceptProcess = async (data) => {
    // TODO: recreate into a sql transaction
    const dataWIP = this.state.selectedData;

    const loadoutsArr = [...this.state.selectedLoadouts];
    const loadoutsArrPromise = [];

    loadoutsArr.map((lo, index) => {
      if (this.state.openDispatchRequested) {
        this.setState({ forwardedReference: lo.reference });
      }
      loadoutsArrPromise.push(
        new Promise(async (resolve, reject) => {
          const agents = await agent.all();
          const defaultAgent = agents.find((agent) => agent.default === 1);

          const info = {
            data: {
              reference: lo.reference,
              exportNotificationReference: data.exportNotificationReference,
              voyageNumber: data.voyageNumber,
              portLoading_id: data.portLoading_id,
              portDischarge_id: data.portDischarge_id,
              portFinal_id: data.portFinal_id,
              shipping_id: data.shipping_id,
              vessel_id: data.vessel_id,
              dateLoading: data.dateLoading,
              specialinstruction: data.specialinstruction,
              coldroom_id: data.coldroom_id,
              etd: data.etd,
              eta: data.eta,
              updated_eta: data.loadout_updatedeta,
              phytclean: data.phytclean,
              consignee: dataWIP[0].consigneeId,
              agent_id: data.agent_id || defaultAgent ? defaultAgent.id : null,
              notes: data.notes,
              collected: data.collected,
            },
          };
          if (lo.loadout_id == 0) {
            loadout.create(info).then((result) => {
              loadoutsArr[index] = { ...info.data, loadout_id: result[0] };
              resolve("ok");
            });
          } else {
            loadout.update(lo.loadout_id, info).then((result) => {
              resolve("ok");
            });
          }
        }),
      );
    });

    Promise.all(loadoutsArrPromise).then((result) => {
      const promiseAcceptArr = [];
      const uniqueDataWIP = [];
      dataWIP.map((item) => {
        if (uniqueDataWIP.filter((udw) => udw.barcode == item.barcode).length == 0) {
          uniqueDataWIP.push(item);
        }
      });

      for (let index = 0; index < uniqueDataWIP.length; index++) {
        const item = uniqueDataWIP[index];
        const loadid = loadoutsArr.find((lo) => lo.reference == item.loadout_reference);

        if (loadid && loadid.loadout_id) {
          if (item.barcode.toLowerCase() == "totals") {
            continue;
          }
          const updateInfo = {
            data: {
              barcode: item.barcode,
              loadout_id: loadid.loadout_id,
            },
          };

          promiseAcceptArr.push(
            new Promise((resolve, reject) => {
              updateLoadoutByBarcode(updateInfo)
                .then(() => {
                  const updateInfoStock = {
                    tempcode: data.temp,
                  };
                  stockDetailUpdateByBarcodeID(item.barcode_id, updateInfoStock)
                    .then(() => {
                      resolve("ok");
                    })
                    .catch(() => {
                      reject("bad");
                    });
                })
                .catch(() => {
                  const err = "Could not create LoadOut";
                  this.context.updateSnack({ show: true, color: "red", message: err });
                  reject("bad");
                });
            }),
          );
        }
      }
      Promise.all(promiseAcceptArr).then((data) => {
        this.setState({ selectedData: undefined, selectedUpdating: false }, () => {
          this.onReload(this.state.includeNew, this.state.includeLoaded, this.state.includeDispatched);
          if (this.state.onForward) {
            this.state.onForward();
          }
        });

        this.setState({ requestOpenDispatch: this.state.openDispatchRequested });
      });
    });
  };

  onAcceptCancel = () => {
    this.setState({ selectedData: undefined, selectedUpdating: false, selectedLoadouts: undefined });
    if (this.state.onForward) {
      this.state.onForward();
    }
  };

  handleAttachment = (id) => {
    this.setState({ loadoutAttachmentID: id }, () => {});
  };

  handleAttachmentClose = () => {
    this.setState({ loadoutAttachmentID: undefined });
    this.loadStock();
  };

  handleAttachmentAccept = (files) => {
    files.map((file) => {
      if (!file.id) {
        loadoutattachment.create({ data: { ...file, loadoutid: this.state.loadoutAttachmentID } });
      }
    });
    this.setState({ loadoutAttachmentID: undefined });
    this.loadStock();
  };

  onReload = async (includeNew: boolean, includeLoaded: boolean, includeDispatched: boolean) => {
    return await this.loadStock();
  };

  handleChangedDispatchRequested = () => {
    this.setState({ openDispatchRequested: !this.state.requestOpenDispatch });
  };

  render() {
    const { classes } = this.props;

    if (this.state.requestOpenDispatch) return <Redirect to={`/dispatch/${this.state.forwardedReference}`} />;
    else
      return (
        <div className={classes.root}>
          {this.state.loading && <CircularProgress />}
          {this.state.loading && (
            <div>
              <LinearProgress />
            </div>
          )}
          {this.state.selectedUpdating && (
            <AcceptDialog
              isOpen={this.state.selectedUpdating}
              handleClose={this.onAcceptCancel}
              onSubmit={this.onAcceptProcess}
              defaultData={this.state.selectedData[0]}
              handleChangedDispatchRequested={this.handleChangedDispatchRequested}
              hasForwardedRef={this.state.forwardedReference ? true : false}
            />
          )}
          {this.state.loadoutAttachmentID && (
            <AttachmentDialog attachmentid={this.state.loadoutAttachmentID} handleClose={this.handleAttachmentClose} handleAccept={this.handleAttachmentAccept} />
          )}
          {this.state.loading == false && !this.state.forwardedReference && (
            <LoadoutTable data={this.state.stock} onReload={this.onReload} handleAttachment={this.handleAttachment} />
          )}
        </div>
      );
  }
}

export default withStyles(styles)(LoadoutUnstyled);
