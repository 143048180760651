import React, { forwardRef, useState } from "react";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";

import Button from "@material-ui/core/Button";
import Toolbar from "@material-ui/core/Toolbar";
import LinearProgress from "@material-ui/core/LinearProgress";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Menu from "@material-ui/core/Menu";

import { withRouter } from "react-router-dom";
import JSZip from "jszip";
import filesaver from "file-saver";
import format from "date-fns/format";
import BigNumber from "bignumber.js";

import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import Grid from "../../lib/components/grid";
import { getSaleFundsSummary, SaleFundsSummaryType } from "../../lib/api/salefunds";
import { getFinancialYearSelected } from "../../lib/api/week";

import { GridColumnsSummary } from "./gridsetup";

import { SnackContext } from "../../lib/context/SnackContext";
import { GenerateErrorMessage } from "../../lib/helpers/string_methods";
import { getDebtorsFullOustandingByClientsCodeDateRange } from "../../lib/api/debtorsfull";
import { handlePrintStatement } from "../../reports/printing";
import { LoadingDialog } from "../../lib/components/LoadingDialog";
import { DocumentsButton, DocumentsType } from "../../lib/components/DocumentsButton";

import { exportPrintPDF } from "../../reports/debtorFundsSummaryPDF";
import { companyDetails } from "../../lib/api/companydetails";
import Confirmation from "../../lib/components/confirmation";
import { MenuListItemSingle } from "../../lib/components/MenuListItem";
import { ErrorsGrid } from "./errorsgrid";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1),
      width: "100%",
      height: "calc(100% - 200px)",
      position: "relative",
    },
    gridWrapper: {
      position: "relative",
    },
    toolbar: {
      position: "absolute",
      left: 0,
      top: 0,
      height: "60px",
      width: "100%",
    },
    tableCellLabel: {
      width: "150px",
      borderBottom: "none",
      height: "50px",
      textAlign: "right",
      paddingTop: "5px",
    },
    tableCellLabelValue: {
      width: "350px",
      borderBottom: "none",
      height: "50px",
      textAlign: "left",
      paddingTop: "5px",
    },
    tableCellDetail: {
      width: "350px",
      borderBottom: "none",
      height: "50px",
    },
    tableCellsValueSelect: {
      textAlign: "left",
      width: "350px",
      borderBottom: "none",
      height: "50px",
      padding: 0,
      marginTop: "-14px",
    },
    bulkDateRange: {
      display: "grid",
      gridTemplateRows: "1fr 1fr",
      gap: theme.spacing(1),
      width: "550px",
    },
    bulkDateContainer: {
      display: "flex",
      flexDirection: "row",
      width: "100%",
      justifyContent: "space-between",
    },
    bulkDateButton: {
      padding: "6px 16px",
      gridColumn: "2/3",
      display: "flex",
      alignSelf: "flex-end",
      justifySelf: "flex-end",
    },
    calendarOpen: {
      height: "130px",
    },
    calendarClose: {
      height: "100%",
    },
    footer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-end",
      marginTop: `10px`,
    },
    button: {
      marginTop: "5px",
      marginLeft: "5px",
      borderBottom: "none",
    },
  });

type DebtorFundsSummaryProps = {
  match: any;
  location: any;
  history: any;
} & WithStyles<typeof styles>;

type DebtorFundsSummaryState = {
  data: SaleFundsSummaryType[];
  loading: boolean;
  showStatementsRange: boolean;
  adjustDate: Date;
  dateFrom: Date;
  dateTo: Date;
  gridHeight: number;
  showLoadingDialog: boolean;
  excludeZeroBalancesOnStatements: boolean;
  printDetails: { clientscode: string; clientsname: string };
  anchorEl: any;
  reportErrors: { client: string; error: string }[];
};

const CalenderCustomInput = forwardRef((props: any, ref: any) => (
  <Button name="CalenderCustomInput" onClick={props.onClick} disabled={props.disabled} variant="contained" color="primary" style={{ marginTop: "5px", width: "250px" }}>
    {props.value}
  </Button>
));

const CustomDatePicker = ({ classes, label, customDate, setDate }) => {
  const [open, setOpen] = useState(false);
  return (
    <div>
      <span className={classes.tableCellLabel}>{label}: </span>
      <span className={classes.tableCellDetail}>
        <div className={open ? classes.calendarOpen : classes.calendarClose}>
          <ReactDatePicker
            locale="en-GB"
            showWeekNumbers={true}
            selected={customDate}
            onChange={(newValue) => setDate(newValue)}
            dateFormat="dd-MM-yyyy"
            placeholderText="click here to select a date"
            customInput={<CalenderCustomInput />}
            popperModifiers={{
              offset: {
                enabled: true,
                offset: "0, 10",
              },
            }}
            onCalendarOpen={() => setOpen(true)}
            onCalendarClose={() => setOpen(false)}
          />
        </div>
      </span>
    </div>
  );
};

class DebtorFundsSummary extends React.Component<DebtorFundsSummaryProps, any> {
  state: DebtorFundsSummaryState = {
    data: [],
    loading: true,
    showStatementsRange: false,
    dateFrom: new Date(new Date(`1 Feb ${getFinancialYearSelected()}`).toDateString()),
    dateTo: new Date(),
    adjustDate: new Date(),
    gridHeight: 500,
    showLoadingDialog: false,
    excludeZeroBalancesOnStatements: false,
    printDetails: undefined,
    anchorEl: null,
    reportErrors: [],
  };

  constructor(props) {
    super(props);
  }

  static contextType = SnackContext;
  context!: React.ContextType<typeof SnackContext>;

  loadData = async () => {
    this.setState({ loading: true });
    const result = await getSaleFundsSummary(getFinancialYearSelected());
    this.setState({ loading: false, data: result.data });
  };

  componentDidMount() {
    this.loadData().then(() => {
      const mainClientheight = document.getElementById("divDetails");
      this.setState({ gridHeight: mainClientheight && mainClientheight.clientHeight });
    });
  }

  handleGeneratePdf = async () => {
    this.setState({ showLoadingDialog: true });

    try {
      const dateFrom = this.state.dateFrom;
      const dateTo = this.state.dateTo;

      const statementData = await getDebtorsFullOustandingByClientsCodeDateRange("all", {
        dateFrom: format(this.state.dateFrom, "yyyy-MM-dd"),
        dateTo: format(this.state.dateTo, "yyyy-MM-dd"),
      });

      if (statementData.length === 0) {
        this.setState({ showLoadingDialog: false });
        this.context.updateSnack({ show: true, color: "red", message: "No data to print for selected year." });
        return;
      }

      const zip = new JSZip();

      const groupedByClient = statementData.reduce((obj, row) => {
        const balance = new BigNumber(row.sale_payment1amount_remain)
          .plus(new BigNumber(row.sale_payment2amount_remain))
          .plus(new BigNumber(row.sale_payment3amount_remain))
          .toNumber();

        // skip shipment if balance is zero
        if (this.state.excludeZeroBalancesOnStatements && balance == 0) {
          return obj;
        }

        obj[row.clients_code] = [...(obj[row.clients_code] || []), row];

        return obj;
      }, {});

      const clientKeys = Object.keys(groupedByClient);
      let clientCode = null;

      for (const key of clientKeys) {
        clientCode = key;
        const data = groupedByClient[key].sort((a, b) => a.sale_invoicenumber > b.sale_invoicenumber);

        if (data.length === 0) continue;

        try {
          const raw = await handlePrintStatement(data, dateTo, dateFrom, key, false, true);

          zip.file(`${format(new Date(), "yyMMdd")}_${key}_STATEMENT.pdf`, raw);
        } catch (error) {
          this.setState({ reportErrors: [...this.state.reportErrors, { client: clientCode, error: GenerateErrorMessage(error, "Error printing invoice.") }] });
        }
      }

      await zip.generateAsync({ type: "blob", mimeType: "application/zip" }).then(function (content) {
        filesaver.saveAs(new Blob([content], { type: "application/zip" }), "REPORTS.zip");
      });
    } catch (error) {
      this.context.updateSnack({ show: true, color: "red", message: `Error printing statements.` });
    }

    this.setState({ showLoadingDialog: false, showStatementsRange: undefined });
  };

  handleCloseUploadDialog = () => {
    this.setState({ showLoadingDialog: false, showStatementsRange: undefined });
  };

  handleShowStatementsRange = (accept: boolean = false) => {
    this.setState({ showStatementsRange: accept, printDetails: undefined });
  };

  toggleExcludeZeroOnStatements = (checked) => {
    this.setState({ excludeZeroBalancesOnStatements: checked });
  };

  handleGenerateEdiFile = () => {
    window.location.href = `/api/salefunds/ext/exportDebtorsAgeAnalysis?finyear=${getFinancialYearSelected()}`;
  };

  handleGeneratePDFExport = async () => {
    const company = await companyDetails.all();

    try {
      exportPrintPDF(company[0], this.state.data);
    } catch (error) {
      const err = GenerateErrorMessage(error, "Error printing Invoice");
      this.context.updateSnack({ show: true, color: "red", message: err });
    }
  };

  setDateFrom = (dateFrom: Date) => {
    this.setState({ dateFrom });
  };

  setDateTo = (dateTo: Date) => {
    this.setState({ dateTo });
  };

  handlePrintPDF = async (row: SaleFundsSummaryType) => {
    this.setState({ printDetails: { clientscode: row.clients_code, clientsname: row.clients_name }, showStatementsRange: true });
  };

  menuItems = [
    {
      title: "AGE ANALYSIS",
      options: [
        {
          title: "AGE ANALYSIS PDF",
          icon: DocumentsType.PDF,
          action: this.handleGeneratePDFExport,
        },
        {
          title: "AGE ANALYSIS EXCEL",
          icon: DocumentsType.EXCEL,
          action: this.handleGenerateEdiFile,
        },
      ],
    },
    {
      title: "BULK STATEMENT RUN",
      action: this.handleShowStatementsRange,
      icon: DocumentsType.PDF,
    },
  ];

  handlePrint = async (type: DocumentsType) => {
    this.setState({ loading: true });
    try {
      const { dateTo, dateFrom, excludeZeroBalancesOnStatements } = this.state;
      const {
        printDetails: { clientscode },
      } = this.state;

      if (type == DocumentsType.EXCEL) {
        window.location.href = `/api/debtorsfull/ext/printExcelStatement?excludeZeroBalances=${excludeZeroBalancesOnStatements ? 1 : 0}&dateTo=${format(
          dateTo,
          "yyyy-MM-dd",
        )}&dateFrom=${format(dateFrom, "yyyy-MM-dd")}&clientsCode=${clientscode}&financial_year=${getFinancialYearSelected()}`;
      } else {
        const data = await getDebtorsFullOustandingByClientsCodeDateRange(clientscode, { dateFrom: format(dateFrom, "yyyy-MM-dd"), dateTo: format(dateTo, "yyyy-MM-dd") });
        const formattedData = data.map((item) => ({
          ...item,
          payment_type: item.payment_type == "ADHOC/DEBIT" ? "DEBIT" : item.payment_type == "ADHOC/CREDIT" ? "CREDIT" : item.payment_type,
        }));
        await handlePrintStatement(formattedData, dateTo, dateFrom, clientscode, excludeZeroBalancesOnStatements);
      }
    } catch (error) {
      const err = GenerateErrorMessage(error, "Error printing Invoice");
      this.context.updateSnack({ show: true, color: "red", message: err });
    }
    this.setState({ loading: false });
  };

  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  handlePrintPurchaseAndHistory = (clients_code: string) => {
    window.location.href = `/api/salefunds/ext/exportDebtorsFundsHistory?clients_code=${clients_code}&finyear=${getFinancialYearSelected()}`;
  };

  handleCloseErrorsGrid = () => {
    this.setState({ reportErrors: [] });
  };

  render() {
    const { classes } = this.props;

    return (
      <div id="divDetails" className={classes.root}>
        {this.state.loading && <LinearProgress />}
        {this.state.reportErrors.length > 0 && !this.state.showLoadingDialog && (
          <Confirmation isOpen handleClose={() => {}} handleConfirm={() => {}} title={"Bulk Statement Errors"} body={undefined}>
            <ErrorsGrid data={this.state.reportErrors} handleClose={this.handleCloseErrorsGrid} />
          </Confirmation>
        )}
        {this.state.showStatementsRange && (
          <Confirmation
            isOpen={true}
            handleClose={() => this.handleShowStatementsRange(false)}
            handleConfirm={() => this.handleShowStatementsRange(false)}
            title={this.state.printDetails ? `${this.state.printDetails.clientsname} Statement run` : "Bulk Statement run"}
            body={undefined}
          >
            <div className={classes.bulkDateRange}>
              <div className={classes.bulkDateContainer}>
                <CustomDatePicker classes={classes} label="From" customDate={this.state.dateFrom} setDate={this.setDateFrom} />
                <CustomDatePicker classes={classes} label="To" customDate={this.state.dateTo} setDate={this.setDateTo} />
              </div>
              <div className={classes.bulkDateContainer} style={{ alignItems: "flex-end" }}>
                <div>
                  <FormControlLabel
                    control={
                      <Checkbox
                        disabled={this.state.loading}
                        checked={this.state.excludeZeroBalancesOnStatements}
                        onChange={(event) => this.toggleExcludeZeroOnStatements(event.target.checked)}
                        name="exclude"
                        color="primary"
                      />
                    }
                    label="Exclude Zero Balances on Statements"
                  />
                </div>
                {this.state.printDetails ? (
                  <div className={classes.footer}>
                    <Menu id="simple-menu" anchorEl={this.state.anchorEl} keepMounted open={Boolean(this.state.anchorEl)} onClose={this.handleClose}>
                      <MenuListItemSingle index={0} menuItem={{ action: () => this.handlePrint(DocumentsType.PDF), title: "STATEMENT", icon: DocumentsType.PDF }} />
                      <MenuListItemSingle index={1} menuItem={{ action: () => this.handlePrint(DocumentsType.EXCEL), title: "STATEMENT", icon: DocumentsType.EXCEL }} />
                    </Menu>
                    <Button variant="contained" color="primary" onClick={this.handleClick} className={classes.button} disabled={this.state.loading}>
                      Export
                    </Button>
                  </div>
                ) : (
                  <Button variant="contained" color="primary" className={classes.bulkDateButton} onClick={this.handleGeneratePdf}>
                    Export
                  </Button>
                )}
              </div>
            </div>
          </Confirmation>
        )}
        {this.state.showLoadingDialog && (
          <LoadingDialog isOpen={true} handleOK={this.handleCloseUploadDialog} title={"Generating PDFs"} loading={true} errorMsg={""} successMsg={"Please wait"} />
        )}
        <div className={classes.gridWrapper}>
          <Toolbar className={classes.toolbar}>
            <DocumentsButton menuItems={this.menuItems} disabled={this.state.loading} title="REPORTS" />
          </Toolbar>
          <Grid
            loading={this.state.loading}
            data={this.state.data}
            GridColumns={(column, row, arrangement, columnsWidth, totalRowColumns) =>
              GridColumnsSummary(column, row, arrangement, columnsWidth, totalRowColumns, this.handlePrintPDF, this.handlePrintPurchaseAndHistory)
            }
            clearFilters={"debtorssummary"}
            forceHeight={this.state.gridHeight}
            handleRefresh={this.loadData}
            totalRowColumns={[
              "salesTotal",
              "amountPaidTotal",
              "totalOutstanding",
              "bankchargesTotal",
              "totalAmount",
              "availableTotal",
              "current_period",
              "thirty_days",
              "sixty_days",
              "ninety_days",
              "ninetyplus_days",
              "amountOverpaid",
              "ageAnalysisOutstanding",
              "previousYearBalance",
            ]}
            countRowColumns={["clients_code"]}
          />
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(withRouter(DebtorFundsSummary));
