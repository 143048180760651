import { client } from "./_base";

export type Commodity = {
  id: number;
  code: string;
  name: string;
};

export const commodities = client<Commodity>("commodity", (item) => ({
  ...item,
}));

export const commoditiesAllSortedMappedforCombo = async () => {
  return commodities.all().then((data) => {
    const result = data
      .sort((a, b) => {
        return a.name.localeCompare(b.name);
      })
      .map((c) => {
        return { value: c.code, display: `${c.name} (${c.code})`, id: c.id };
      });

    return result;
  });
};

export const commoditiesAllSortedMappedforComboById = async () => {
  const data = await commodities.all();
  return data.sort((a, b) => a.name.localeCompare(b.name)).map((c) => ({ value: c.id, display: `${c.name} (${c.code})` }));
};
