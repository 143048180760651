import React from "react";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";

import Button from "@material-ui/core/Button";

import { Field, Form } from "react-final-form";
import { TextField as MTextField, Checkbox } from "final-form-material-ui";

import { MaterialSelectComponent } from "../../lib/helpers/materialcomponents";
import { coldrooms } from "../../lib/api/coldroom";
import { getPortsLoadingForCombo } from "../../lib/api/port";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1),
      width: "100%",
    },
    formDetail: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(1),
    },
    tableRow: {
      whiteSpace: "nowrap",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      gap: theme.spacing(1),
    },
    rowLabel: {
      width: "150px",
      textAlign: "right",
    },
    rowField: {
      width: "250px",
      margin: 0,
    },
    rowFieldCheckbox: {},
  });

type ColdroomEditFormProps = {
  onSubmit: any;
  onClose: any;
  id: number;
} & WithStyles<typeof styles>;

class ColdroomEditFormUnstyled extends React.Component<ColdroomEditFormProps, any> {
  state = {
    onSubmit: undefined,
    onClose: undefined,
    coldroom: { active: false, id: "0", code: "", location_id: "", name: "", address1: "", address2: "", address3: "", address4: "", address5: "", telephoneNumber: "", email: "" },
    ports: [],
  };

  constructor(props) {
    super(props);
    this.state.onSubmit = props.onSubmit;
    this.state.onClose = props.onClose;
  }

  componentDidMount(): void {
    if (this.props.id) {
      this.getItem(this.props.id);
    }
  }

  getItem = async (id: number) => {
    const [coldroom, ports] = await Promise.all([coldrooms.single(id), getPortsLoadingForCombo()]);
    this.setState({ coldroom: coldroom ? coldroom : this.state.coldroom, ports });
  };

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <Form
          initialValues={{ ...this.state.coldroom }}
          onSubmit={this.state.onSubmit}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <div className={classes.formDetail}>
                <TextField classes={classes} name="code" label="Code" />
                <TextField classes={classes} name="location_id" label="Location ID" />
                <TextField classes={classes} name="name" label="Name" />
                <ComboTextField classes={classes} name="port_id" label="Port" data={this.state.ports} />
                <TextField classes={classes} name="address1" label="Address 1" />
                <TextField classes={classes} name="address2" label="Address 2" />
                <TextField classes={classes} name="address3" label="Address 3" />
                <TextField classes={classes} name="address4" label="Address 4" />
                <TextField classes={classes} name="address5" label="Address 5" />
                <TextField classes={classes} name="telephoneNumber" label="Telephone Number" />
                <TextField classes={classes} name="email" label="Email" />
                <CheckboxField classes={classes} name="active" label="Active" />
              </div>
              <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "20px" }}>
                <Button style={{ marginRight: "10px" }} type="button" color="secondary" variant="outlined" onClick={this.state.onClose}>
                  Close
                </Button>
                <Button type="submit" color="primary" variant="outlined">
                  Save
                </Button>
              </div>
            </form>
          )}
        />
      </div>
    );
  }
}

export default withStyles(styles)(ColdroomEditFormUnstyled);

const TextField = ({ classes, name, label }) => (
  <div className={classes.tableRow}>
    <label className={classes.rowLabel}>{label}:</label>
    <Field fullWidth className={classes.rowField} name={name} component={MTextField} type="text" margin="dense" />
  </div>
);

const ComboTextField = ({ classes, name, label, data }) => (
  <div className={classes.tableRow}>
    <label className={classes.rowLabel}>{label}:</label>
    <Field fullWidth name={name} label={label} childdata={data} component={(props) => <MaterialSelectComponent {...props} className={classes.rowField} />} />
  </div>
);

const CheckboxField = ({ classes, name, label }) => (
  <div className={classes.tableRow}>
    <label className={classes.rowLabel}>{label}:</label>
    <Field fullWidth className={classes.rowFieldCheckbox} name={name} component={Checkbox} type="checkbox" margin="dense" />
  </div>
);
