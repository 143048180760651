import React from "react";

import { EnquiryContext } from "../../lib/context/EnquiryContext";
import { CellActionsFormatter } from "../helpers/CellActionsFormatter";

export enum Selector {
  Barcode = 1,
  InvoiceNumber = 2,
  IntakeWaybill = 3,
}

export const EnquiryLink: React.FC<{ column: any; row: any; selector: Selector; style?: any }> = ({ column, row, selector, style }) => {
  const { setEnquirySearchValue, setEnquirySelectSearchValue, toggleEnquiry } = React.useContext(EnquiryContext);

  const actions = [
    {
      icon: "🔗",
      actions: [
        {
          text: "✍️ Search Enquiry",
          callback: () => {
            setEnquirySearchValue(row[column.key]);
            toggleEnquiry(true);
            setEnquirySelectSearchValue(selector);
          },
        },
      ],
    },
  ];

  return (
    <div style={{ display: "flex", justifyContent: "space-between", ...style }}>
      <div>{row[column.key]}</div>
      <CellActionsFormatter actions={actions} />
    </div>
  );
};
