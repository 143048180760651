import React from "react";
import CustomAutoCompleteFilter from "../../../lib/components/customdatagridfilter/CustomFilter";
import { EnquiryLink, Selector } from "../../../lib/components/EnquiryLink";

import { FormatterNumber, FormatterNumber2Decimals } from "../../../lib/components/Formatters";
import { SummaryNumber, SummaryNumber2Decimal, SummaryTotal } from "../../../lib/components/SummaryFormatter";
import TextEditor from "../../../lib/components/TextEditor";

const cellWidthSmallX = 90;
const cellWidthSmall = 115;
const cellWidthMedium = 150;
const cellWidthLarge = 220;

const defaultColumnProperties = {
  filterable: true,
  resizable: true,
  sortable: true,
};

let currentSpace = true;

export const GridColumnsAdjustment = (data, filters, columnsWidth, editable) => {
  let postValue = "";

  if (data) {
    if (currentSpace) {
      postValue = " ";
    }
    currentSpace = !currentSpace;
  }

  const columns = [
    {
      key: "saledetail_barcode",
      name: "Barcode" + postValue,
      width: cellWidthLarge,
      filterRenderer: (e) => {
        return (
          <CustomAutoCompleteFilter
            {...e}
            multiSelection={true}
            option={data}
            currentFilters={filters}
            fieldname={"saledetail_barcode"}
            data={data}
            section={"produceradjustmentadjust"}
          />
        );
      },
      formatter: (props) => <EnquiryLink {...props} selector={Selector.Barcode} />,
      summaryFormatter: SummaryTotal,
    },
    {
      key: "commodityCode",
      name: "Commodity" + postValue,
      width: cellWidthSmallX,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"commodityCode"} data={data} section={"produceradjustmentadjust"} />;
      },
    },
    {
      key: "varietyCode",
      name: "Variety" + postValue,
      width: cellWidthSmallX,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"varietyCode"} data={data} section={"produceradjustmentadjust"} />;
      },
    },
    {
      key: "packCode",
      name: "Pack" + postValue,
      width: cellWidthSmallX,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"packCode"} data={data} section={"produceradjustmentadjust"} />;
      },
    },
    {
      key: "markCode",
      name: "Mark" + postValue,
      width: cellWidthSmallX,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"markCode"} data={data} section={"produceradjustmentadjust"} />;
      },
    },
    {
      key: "gradeCode",
      name: "Grade" + postValue,
      width: cellWidthSmallX,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"gradeCode"} data={data} section={"produceradjustmentadjust"} />;
      },
    },
    {
      key: "countCode",
      name: "Count" + postValue,
      width: cellWidthSmallX,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"countCode"} data={data} section={"produceradjustmentadjust"} />;
      },
    },
    {
      key: "barcode_cartons",
      name: "No Cartons" + postValue,
      width: cellWidthSmallX,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"barcode_cartons"} data={data} section={"produceradjustmentadjust"} />;
      },
      formatter: FormatterNumber,
      summaryFormatter: SummaryNumber,
    },
    {
      key: "palletSize",
      name: "Pallets" + postValue,
      width: cellWidthSmallX,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"palletSize"} data={data} section={"produceradjustmentadjust"} />;
      },
      formatter: FormatterNumber2Decimals,
      summaryFormatter: SummaryNumber2Decimal,
    },
    {
      key: "saleadjustmentdetail_cartons",
      name: "Cartons" + postValue,
      width: cellWidthSmallX,
      filterRenderer: (e) => {
        return (
          <CustomAutoCompleteFilter
            {...e}
            multiSelection={true}
            currentFilters={filters}
            fieldname={"saleadjustmentdetail_cartons"}
            data={data}
            section={"produceradjustmentadjust"}
          />
        );
      },
      formatter: FormatterNumber,
      editor: (props) => <TextEditor {...props} align="right" />,
      editable: editable,
      summaryFormatter: SummaryNumber,
    },
    {
      key: "saleadjustmentdetail_amount",
      name: "Amount/ctn" + postValue,
      width: cellWidthSmallX,
      filterRenderer: (e) => {
        return (
          <CustomAutoCompleteFilter
            {...e}
            multiSelection={true}
            currentFilters={filters}
            fieldname={"saleadjustmentdetail_amount"}
            data={data}
            section={"produceradjustmentadjust"}
          />
        );
      },
      formatter: FormatterNumber2Decimals,
      editor: (props) => <TextEditor {...props} align="right" />,
      editable: editable,
      summaryFormatter: SummaryNumber2Decimal,
    }].map((c) => ({ ...c, ...defaultColumnProperties }));


  if (columnsWidth && columnsWidth.length > 0) {
    for (let column of columns) {
      const colItem = columnsWidth.find((colwitem) => colwitem.name.trim() == column.name.trim());
      if (colItem) {
        column.width = colItem.width;
      }
    }
  }
  return columns;
};
