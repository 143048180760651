import React, { useEffect, useMemo, useState, FC, useContext } from "react";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";

import LinearProgress from "@material-ui/core/LinearProgress";
import Button from "@material-ui/core/Button";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

import { Form } from "react-final-form";
import { format } from "date-fns";
import numeral from "numeral";
import BigNumber from "bignumber.js";
import { FormApi } from "final-form";

import { SnackContext } from "../../../lib/context/SnackContext";
import { isNullOrUndef } from "../../../lib/helpers/isNullOrUndef";
import { TableFieldText, TableFieldLabel, TableFieldCombo, TableFieldDate } from "../../../lib/components/FormTableComponents";
import { ProducersFullGroupedType, ProducersFullType } from "../../../lib/api/producersfull";
import { produceradvancepaidheadgetPaidByProducerID } from "../../../lib/api/produceradvancepaidhead";
import { FormattedCurrencyCombo, getCurrencyAllSortedMappedforCombo } from "../../../lib/api/currency";
import { getWeeksReadyForComboMappedForComboValue, weeks, getWeekByDate, getFinancialYearSelected, WeeksCombo } from "../../../lib/api/week";
import { EnquiryContext } from "../../../lib/context/EnquiryContext";
import { Selector } from "../../../lib/components/EnquiryLink";
import DetailGrid from "./detailgrid";
import HistoryGrid from "./historygrid";
import Confirmation from "../../../lib/components/confirmation";
import SelectAdhocGrid from "./selectadhocgrid";
import { ProducerAdvancePaidAdHocGetAdHocRemainingType, produceradvancepaidadhocGetAdHocRemainingValue } from "../../../lib/api/produceradvancepaidadhoc";
import { SelectedInvoices } from "../payments/producers";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1),
      width: "100%",
      height: "100%",
    },
    divActions: {
      float: "right",
    },
    container: {
      display: "grid",
      gridTemplateColumns: "150px 350px",
      gridTemplateRows: "repeat(9, 40px)",
      gridTemplateAreas: `
      "details"
      `,
      gridGap: "10px",
    },
    tableCellLabel: {
      width: "150px",
      borderBottom: "none",
      height: "50px",
      textAlign: "right",
      paddingTop: "5px",
    },
    tableCellLabelValue: {
      width: "350px",
      borderBottom: "none",
      height: "50px",
      textAlign: "left",
      paddingTop: "5px",
    },
    tableCellDetail: {
      width: "350px",
      borderBottom: "none",
      height: "50px",
    },
    tableCellsValueSelect: {
      textAlign: "left",
      width: "350px",
      borderBottom: "none",
      height: "50px",
      padding: 0,
      marginTop: "-14px",
    },
    tableCellDetailDate: {
      marginTop: "-5px",
      width: "350px",
      borderBottom: "none",
      height: "50px",
    },
    detailTable: {
      width: "900px",
    },
    detailTableRowNormal: {
      backgroundColor: "white",
      padding: "0px",
    },
  });

type ProducerAdvancePayProps = {
  data: ProducersFullType[];
  handleProcessPay: (values, invoicesSelected, isIntake) => void;
  advances: ProducersFullGroupedType[];
  paymentdate: Date;
  paymentident: number;
} & WithStyles<typeof styles>;

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const ProducerAdvancePayUnstyled: FC<ProducerAdvancePayProps> = (props) => {
  const { classes, data, handleProcessPay, advances, paymentdate, paymentident } = props;

  const [selectedCurrency, setSelectedCurrency] = useState<number>(0);
  const [checked, setChecked] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currencyComboData, setCurrencyComboData] = useState<FormattedCurrencyCombo[]>([]);
  const [weekComboData, setWeekComboData] = useState<WeeksCombo[]>([]);
  const [producersPaidData, setProducersPaidData] = useState([]);
  const [producersPaidDataFiltered, setProducersPaidDataFiltered] = useState([]);
  const [tabValue, setTableValue] = useState<number>(0);
  const [selectAdhoc, toggleSelectAdhoc] = useState<boolean>(false);
  const [paymentDate, setPaymentDate] = useState<Date>(isNullOrUndef(paymentdate) ? new Date() : new Date(paymentdate));
  const [invoiceTotals, setInvoiceTotals] = useState([]);
  const [finalForm, setFinalForm] = useState(undefined);
  const [isIntake, setIsIntake] = useState(true);
  const [selectedAdHocs, setSelectedAdHocs] = useState<ProducerAdvancePaidAdHocGetAdHocRemainingType[]>([]);
  const [adhocData, setAdhocData] = useState<ProducerAdvancePaidAdHocGetAdHocRemainingType[]>([]);
  const [totals, setTotals] = useState({
    totalZAR: 0,
    totalColdZAR: 0,
    totalIntakeZAR: 0,
    totalShipZAR: 0,
    totalCAD: 0,
    totalColdCAD: 0,
    totalIntakeCAD: 0,
    totalShipCAD: 0,
    totalEUR: 0,
    totalColdEUR: 0,
    totalIntakeEUR: 0,
    totalShipEUR: 0,
    totalGBP: 0,
    totalColdGBP: 0,
    totalIntakeGBP: 0,
    totalShipGBP: 0,
    totalUSD: 0,
    totalColdUSD: 0,
    totalIntakeUSD: 0,
    totalShipUSD: 0,
  });

  const { updateSnack } = useContext(SnackContext);
  const { setEnquirySearchValue, setEnquirySelectSearchValue, toggleEnquiry } = useContext(EnquiryContext);

  const selected = useMemo(() => {
    const firstAdvance = advances[0];
    return {
      ...firstAdvance,
      produceradvancepaidhead_id: firstAdvance.produceradvancepaidhead_id || 0,
      week_id: isNullOrUndef(firstAdvance.wkcolddue_id)
        ? isNullOrUndef(firstAdvance.wkintakedue_id)
          ? isNullOrUndef(firstAdvance.wkshippingdue_id)
            ? 1
            : firstAdvance.wkshippingdue_id
          : firstAdvance.wkintakedue_id
        : firstAdvance.wkcolddue_id,
    };
  }, [advances]);

  const producerId = useMemo(() => {
    if (!data || data.length == 0) return undefined;
    return data[0].producer_id;
  }, [data]);

  const loadAdhocData = async (producer_id: number, currency_id: number) => {
    const adhocDataResult = await produceradvancepaidadhocGetAdHocRemainingValue(producer_id, currency_id);
    const selectedAdhocResult = adhocDataResult.filter((row) => row.produceradvancepaidhead_id == selected.produceradvancepaidhead_id);
    setAdhocData(adhocDataResult);
    setSelectedAdHocs(selectedAdhocResult);
  };

  const loadData = async () => {
    setLoading(true);
    try {
      const [currencies, weeks, producerPaid] = await Promise.all([
        getCurrencyAllSortedMappedforCombo(),
        getWeeksReadyForComboMappedForComboValue(),
        produceradvancepaidheadgetPaidByProducerID(selected.producer_id, getFinancialYearSelected()),
      ]);

      const currencyId = currencies.find((curr) => curr.display == selected.dispatch_currencycode).value;

      setCurrencyComboData(currencies);
      setWeekComboData(weeks);
      setProducersPaidData(producerPaid);
      setProducersPaidDataFiltered(producerPaid);
      setSelectedCurrency(currencyId);

      await loadAdhocData(producerId, currencyId);

      const getTotalsResult = getTotals(!(data[0].acshipping_id && data[0].acshipping_id != 0));
      setChecked([...getTotalsResult.invTotals]);
      setInvoiceTotals([...getTotalsResult.invTotals]);

      if (data && data.length > 0) {
        setIsIntake(!(data[0].acshipping_id && data[0].acshipping_id != 0));
      }
    } catch (error) {
      updateSnack({ show: true, message: "Could not load", color: "red" });
    }
    setLoading(false);
  };

  useEffect(() => {
    loadData();
  }, [data]);

  useEffect(() => {
    const totalsResult = getTotals(!(data[0].acshipping_id && data[0].acshipping_id != 0));
    if (finalForm) {
      handleChangeCurrency(finalForm, finalForm.getState().values["currency"], totalsResult.totalReduced);
      setFinalForm(undefined);
    }
  }, [checked]);

  useEffect(() => {
    const weekId = selected.wkcolddue_id || selected.wkintakedue_id || selected.wkshippingdue_id || 1;
    if (isNullOrUndef(paymentdate)) {
      weeks.single(weekId).then((weekitem) => {
        setPaymentDate(new Date(weekitem.period_start));
      });
    }
  }, [paymentdate]);

  const getTotals = (isIntake: boolean) => {
    const invTotals = [];
    let newArrayIndex = 0;

    // *** CLEAN UP REQUIRED ***
    const totalReduced = data.reduce(
      (acc, curr) => {
        let getWaybillIndex = -1;
        let currentIndex = newArrayIndex;

        if (isIntake) {
          getWaybillIndex = invTotals.findIndex((item) => curr.bar_intakewaybill === item.sale_invoicenumber);
          currentIndex = getWaybillIndex != -1 ? getWaybillIndex : newArrayIndex;
          if (getWaybillIndex == -1) {
            newArrayIndex += 1;
            invTotals[currentIndex] = { sale_invoicenumber: curr.bar_intakewaybill, total_cartons: 0, total_palletsize: 0 };
          }
        } else {
          if (!invTotals[curr.sale_invoicenumber]) {
            invTotals[curr.sale_invoicenumber] = { sale_invoicenumber: curr.sale_invoicenumber, total_cartons: 0, total_palletsize: 0 };
          }
        }

        curr = CheckValuesNullFromTotalsCurr(curr);

        const row = {
          originalIntakeWaybill: curr.bar_originalIntakewaybill,
          intakeWeek: curr.weekintake_week,
          sale_invoicenumber: isIntake ? curr.bar_intakewaybill : curr.sale_invoicenumber,
          total_cartons: invTotals[isIntake ? currentIndex : curr.sale_invoicenumber].total_cartons + curr.bar_NoCartons,
          total_palletsize: invTotals[isIntake ? currentIndex : curr.sale_invoicenumber].total_palletsize + curr.bar_PalletSize,
          dispatch_currencycode: curr.paid_currency,
          clients_name: curr.clients_name,
          vessel_code: curr.vessel_code,
          dispatch_containerno: curr.dispatch_containerno,
          loadout_eta: curr.loadout_eta,
          loadout_etd: curr.loadout_etd,
          bar_OrigIntakeDate: curr.bar_OrigIntakeDate,
          weekcold_week: curr.weekcold_week,
          totalValueZAR:
            (isNullOrUndef(invTotals[isIntake ? currentIndex : curr.sale_invoicenumber].totalValueZAR)
              ? 0
              : invTotals[isIntake ? currentIndex : curr.sale_invoicenumber].totalValueZAR) +
            curr.coldCartonValueZAR +
            curr.intakeCartonValueZAR +
            curr.shippingCartonValueZAR,
          totalValueCAD:
            (isNullOrUndef(invTotals[isIntake ? currentIndex : curr.sale_invoicenumber].totalValueCAD)
              ? 0
              : invTotals[isIntake ? currentIndex : curr.sale_invoicenumber].totalValueCAD) +
            curr.coldCartonValueCAD +
            curr.intakeCartonValueCAD +
            curr.shippingCartonValueCAD,
          totalValueEUR:
            (isNullOrUndef(invTotals[isIntake ? currentIndex : curr.sale_invoicenumber].totalValueEUR)
              ? 0
              : invTotals[isIntake ? currentIndex : curr.sale_invoicenumber].totalValueEUR) +
            curr.coldCartonValueEUR +
            curr.intakeCartonValueEUR +
            curr.shippingCartonValueEUR,
          totalValueGBP:
            (isNullOrUndef(invTotals[isIntake ? currentIndex : curr.sale_invoicenumber].totalValueGBP)
              ? 0
              : invTotals[isIntake ? currentIndex : curr.sale_invoicenumber].totalValueGBP) +
            curr.coldCartonValueGBP +
            curr.intakeCartonValueGBP +
            curr.shippingCartonValueGBP,
          totalValueUSD:
            (isNullOrUndef(invTotals[isIntake ? currentIndex : curr.sale_invoicenumber].totalValueUSD)
              ? 0
              : invTotals[isIntake ? currentIndex : curr.sale_invoicenumber].totalValueUSD) +
            curr.coldCartonValueUSD +
            curr.intakeCartonValueUSD +
            curr.shippingCartonValueUSD,
        };

        invTotals[isIntake ? currentIndex : curr.sale_invoicenumber] = { ...row, totalValueShow: row[`totalValue${row.dispatch_currencycode}`] };

        if (
          (checked && checked.length > 0 && isIntake && checked.filter((item) => curr.bar_intakewaybill == item.sale_invoicenumber).length > 0) ||
          (checked && checked.length > 0 && !isIntake && checked.filter((item) => curr.sale_invoicenumber == item.sale_invoicenumber).length > 0)
        ) {
          acc.totalColdZAR += curr.coldCartonValueZAR;
          acc.totalIntakeZAR += curr.intakeCartonValueZAR;
          acc.totalShipZAR += curr.shippingCartonValueZAR;
          acc.totalColdCAD += curr.coldCartonValueCAD;
          acc.totalIntakeCAD += curr.intakeCartonValueCAD;
          acc.totalShipCAD += curr.shippingCartonValueCAD;
          acc.totalColdEUR += curr.coldCartonValueEUR;
          acc.totalIntakeEUR += curr.intakeCartonValueEUR;
          acc.totalShipEUR += curr.shippingCartonValueEUR;
          acc.totalColdGBP += curr.coldCartonValueGBP;
          acc.totalIntakeGBP += curr.intakeCartonValueGBP;
          acc.totalShipGBP += curr.shippingCartonValueGBP;
          acc.totalColdUSD += curr.coldCartonValueUSD;
          acc.totalIntakeUSD += curr.intakeCartonValueUSD;
          acc.totalShipUSD += curr.shippingCartonValueUSD;
        } else {
          if (curr.paid_currency == "ZAR") {
            acc.totalZAR += curr.coldCartonValueZAR;
            acc.totalZAR += curr.intakeCartonValueZAR;
            acc.totalZAR += curr.shippingCartonValueZAR;
          } else if (curr.paid_currency == "CAD") {
            acc.totalCAD += curr.coldCartonValueCAD;
            acc.totalCAD += curr.intakeCartonValueCAD;
            acc.totalCAD += curr.shippingCartonValueCAD;
          } else if (curr.paid_currency == "EUR") {
            acc.totalEUR += curr.coldCartonValueEUR;
            acc.totalEUR += curr.intakeCartonValueEUR;
            acc.totalEUR += curr.shippingCartonValueEUR;
          } else if (curr.paid_currency == "GBP") {
            acc.totalGBP += curr.coldCartonValueGBP;
            acc.totalGBP += curr.intakeCartonValueGBP;
            acc.totalGBP += curr.shippingCartonValueGBP;
          } else if (curr.paid_currency == "USD") {
            acc.totalUSD += curr.coldCartonValueUSD;
            acc.totalUSD += curr.intakeCartonValueUSD;
            acc.totalUSD += curr.shippingCartonValueUSD;
          }
        }

        return acc;
      },
      {
        totalZAR: 0,
        totalColdZAR: 0,
        totalIntakeZAR: 0,
        totalShipZAR: 0,
        totalCAD: 0,
        totalColdCAD: 0,
        totalIntakeCAD: 0,
        totalShipCAD: 0,
        totalEUR: 0,
        totalColdEUR: 0,
        totalIntakeEUR: 0,
        totalShipEUR: 0,
        totalGBP: 0,
        totalColdGBP: 0,
        totalIntakeGBP: 0,
        totalShipGBP: 0,
        totalUSD: 0,
        totalColdUSD: 0,
        totalIntakeUSD: 0,
        totalShipUSD: 0,
      },
    );

    setTotals({ ...totalReduced });
    return { invTotals, totalReduced };
  };

  const CheckValuesNullFromTotalsCurr = (curr) => {
    if (isNullOrUndef(curr.coldCartonValueUSD)) {
      curr.coldCartonValueUSD = 0;
    }
    if (isNullOrUndef(curr.intakeCartonValueUSD)) {
      curr.intakeCartonValueUSD = 0;
    }
    if (isNullOrUndef(curr.shippingCartonValueUSD)) {
      curr.shippingCartonValueUSD = 0;
    }
    if (isNullOrUndef(curr.coldCartonValueZAR)) {
      curr.coldCartonValueZAR = 0;
    }
    if (isNullOrUndef(curr.intakeCartonValueZAR)) {
      curr.intakeCartonValueZAR = 0;
    }
    if (isNullOrUndef(curr.shippingCartonValueZAR)) {
      curr.shippingCartonValueZAR = 0;
    }
    if (isNullOrUndef(curr.coldCartonValueCAD)) {
      curr.coldCartonValueCAD = 0;
    }
    if (isNullOrUndef(curr.intakeCartonValueCAD)) {
      curr.intakeCartonValueCAD = 0;
    }
    if (isNullOrUndef(curr.shippingCartonValueCAD)) {
      curr.shippingCartonValueCAD = 0;
    }
    if (isNullOrUndef(curr.coldCartonValueEUR)) {
      curr.coldCartonValueEUR = 0;
    }
    if (isNullOrUndef(curr.intakeCartonValueEUR)) {
      curr.intakeCartonValueEUR = 0;
    }
    if (isNullOrUndef(curr.shippingCartonValueEUR)) {
      curr.shippingCartonValueEUR = 0;
    }
    if (isNullOrUndef(curr.coldCartonValueGBP)) {
      curr.coldCartonValueGBP = 0;
    }
    if (isNullOrUndef(curr.intakeCartonValueGBP)) {
      curr.intakeCartonValueGBP = 0;
    }
    if (isNullOrUndef(curr.shippingCartonValueGBP)) {
      curr.shippingCartonValueGBP = 0;
    }
    return curr;
  };

  const handleChangeCurrency = async (form: FormApi, value: number, totalsToUse: any) => {
    const result = getPayAmountForCurrency(value, totalsToUse);
    form.change("pay_amount", numeral(result.toString()).format("0,0.00"));
    form.change("pay_amountcalculated", numeral(result.toString()).format("0,0.00"));
    form.change("adhocRemaining", []);
    setSelectedAdHocs([]);

    const paidData = producersPaidData.filter((prodItem) => prodItem.currency_id == value);
    setProducersPaidDataFiltered(paidData);
    setSelectedCurrency(value);
  };

  const getPayAmountForCurrency = (currencyid: number, newTotals: any) => {
    const totalsToUse = newTotals ? newTotals : totals;
    const currencyselected = currencyComboData.find((curr) => curr.value == currencyid);
    if (!isNullOrUndef(currencyselected) && !isNullOrUndef(currencyselected.display)) {
      if (currencyselected.display == "ZAR") {
        return totalsToUse.totalColdZAR + totalsToUse.totalIntakeZAR + totalsToUse.totalShipZAR;
      }
      if (currencyselected.display == "CAD") {
        return totalsToUse.totalColdCAD + totalsToUse.totalIntakeCAD + totalsToUse.totalShipCAD;
      }
      if (currencyselected.display == "EUR") {
        return totalsToUse.totalColdEUR + totalsToUse.totalIntakeEUR + totalsToUse.totalShipEUR;
      }
      if (currencyselected.display == "GBP") {
        return totalsToUse.totalColdGBP + totalsToUse.totalIntakeGBP + totalsToUse.totalShipGBP;
      }
      if (currencyselected.display == "USD") {
        return totalsToUse.totalColdUSD + totalsToUse.totalIntakeUSD + totalsToUse.totalShipUSD;
      }
      return 0;
    }
    return 0;
  };

  const changePaymentDate = (value: Date, form: FormApi) => {
    setPaymentDate(value);
    getWeekByDate(format(value, "yyyy-MM-dd")).then((week) => {
      if (week.length > 0) {
        form.change("week_id", week[0].id);
      }
    });
  };

  const handleToggle = (row: any, form: FormApi) => {
    setFinalForm(form);

    const newChecked = checked.filter((item) => item && item.sale_invoicenumber != row.sale_invoicenumber);

    if (newChecked.length == checked.length) {
      newChecked.push(row);
    }

    setChecked(newChecked);
  };

  const handleInvoiceInfoClick = (isInvoice, searchV) => {
    setEnquirySearchValue(searchV);
    toggleEnquiry(true);
    setEnquirySelectSearchValue(Selector.IntakeWaybill);
  };

  const handleTabChange = (event, newValue) => {
    setTableValue(newValue);
  };

  const handleCloseSelectAdhoc = () => {
    toggleSelectAdhoc(!selectAdhoc);
  };

  const handleSelectAdhoc = (row: ProducerAdvancePaidAdHocGetAdHocRemainingType) => {
    const exists = selectedAdHocs.find((item) => item.adhoc_id == row.adhoc_id);

    if (!!exists) {
      const filtered = selectedAdHocs.filter((item) => item.adhoc_id != row.adhoc_id);
      setSelectedAdHocs(filtered);
    } else {
      const adhocs = [...selectedAdHocs];
      adhocs.push(row);
      setSelectedAdHocs(adhocs);
    }
  };

  return (
    <div className={classes.root}>
      {loading ? (
        <LinearProgress />
      ) : (
        <div style={{ height: "100%" }}>
          {selectAdhoc && (
            <Confirmation isOpen={true} handleClose={handleCloseSelectAdhoc} handleConfirm={handleCloseSelectAdhoc} title="Select Adhoc" body={undefined}>
              <SelectAdhocGrid data={adhocData} selectedData={selectedAdHocs} handleSelect={handleSelectAdhoc} selected={selected} />
            </Confirmation>
          )}
          <Form
            initialValues={{
              adhoc: selected.adhoc_id,
              adhocRemaining: 0,
              pay_amount: 0,
              pay_amountcalculated: 0,
              week_id: selected.produceradvancepaidhead_week_id || selected.week_id,
              currency: selectedCurrency,
              currencySuggested: selected.paid_currency || selected.dispatch_currencycode,
              paymentident: selected.produceradvancepaidhead_ident || paymentident,
              makeanote: selected.produceradvancepaidhead_makeanote,
            }}
            onSubmit={(values) => {
              handleProcessPay(
                { ...values, paymentdate: paymentDate, produceradvancepaidhead_id: selected.produceradvancepaidhead_id, adhocRemaining: selectedAdHocs },
                checked,
                isIntake,
              );
            }}
            validate={(values) => {
              let errors = {};
              if (values["currency"] == 0) {
                errors = { ...errors, currency: "Please select a currency" };
              }
              return errors;
            }}
            render={({ handleSubmit, form }) => (
              <form onSubmit={handleSubmit} style={{ height: "100%" }}>
                <div style={{ paddingBottom: "10px" }}>
                  <Button name="submit" type="submit" variant="contained" color="primary" style={{ margin: "2px" }} disabled={false}>
                    Save
                  </Button>
                  <Button
                    name="close"
                    variant="contained"
                    color="secondary"
                    style={{ margin: "2px" }}
                    disabled={false}
                    onClick={() => {
                      handleProcessPay(undefined, undefined, undefined);
                    }}
                  >
                    Close
                  </Button>
                </div>
                <div style={{ display: "grid", gridTemplateRows: "1fr", gridTemplateColumns: "650px 1fr", height: "100%" }}>
                  <FormHead
                    form={form}
                    checked={checked}
                    classes={classes}
                    paymentDate={paymentDate}
                    changePaymentDate={changePaymentDate}
                    setPaymentDate={setPaymentDate}
                    weekComboData={weekComboData}
                    currencyComboData={currencyComboData}
                    handleChangeCurrency={handleChangeCurrency}
                    getPayAmountForCurrency={getPayAmountForCurrency}
                    selectedAdHocs={selectedAdHocs}
                    toggleSelectAdhoc={toggleSelectAdhoc}
                  />
                  <div style={{ height: "100%" }}>
                    <AppBar position="static">
                      <Tabs value={tabValue} onChange={handleTabChange} aria-label="some tabs">
                        <Tab label="Invoice" {...a11yProps(0)} />
                        <Tab label="History" {...a11yProps(1)} />
                      </Tabs>
                    </AppBar>
                    <TabPanel value={tabValue} index={0}>
                      <DetailGrid
                        form={form}
                        checked={checked}
                        isIntake={isIntake}
                        handleCheckboxToggle={handleToggle}
                        handleInvoiceInfoClick={handleInvoiceInfoClick}
                        data={invoiceTotals.sort((a, b) => a.sale_invoicenumber.localeCompare(b.sale_invoicenumber))}
                      />
                    </TabPanel>
                    <TabPanel value={tabValue} index={1}>
                      <HistoryGrid data={producersPaidDataFiltered} />
                    </TabPanel>
                  </div>
                </div>
                <div className={classes.divActions}></div>
              </form>
            )}
          />
        </div>
      )}
    </div>
  );
};

const toNumber = (value: string | number) => parseFloat(value.toString().replaceAll(",", ""));

type FormHeadProps = {
  form: FormApi;
  weekComboData: WeeksCombo[];
  paymentDate: Date;
  checked: SelectedInvoices[];
  currencyComboData: FormattedCurrencyCombo[];
  selectedAdHocs: ProducerAdvancePaidAdHocGetAdHocRemainingType[];
  setPaymentDate: (date: Date) => void;
  toggleSelectAdhoc: (bool: boolean) => void;
  changePaymentDate: (date: Date, form: FormApi) => void;
  getPayAmountForCurrency: (currencyid: number, newTotals: any) => number;
  handleChangeCurrency: (form: FormApi, value: number, totalsToUse: any) => void;
} & WithStyles<typeof styles>;

const FormHead: FC<FormHeadProps> = ({
  classes,
  form,
  weekComboData,
  paymentDate,
  checked,
  currencyComboData,
  selectedAdHocs,
  setPaymentDate,
  toggleSelectAdhoc,
  changePaymentDate,
  getPayAmountForCurrency,
  handleChangeCurrency,
}) => {
  const handleParseText2Decimals = (form: FormApi, value: string | number) => {
    return numeral(value).format("0,0.00");
  };

  const handleParseAdHoc = (form: FormApi, value: string | number) => {
    const calcAmount = toNumber(form.getState().values["pay_amount"]) - toNumber(value);
    form.change("pay_amountcalculated", numeral(calcAmount.toString()).format("0,0.00"));
    return value;
  };

  const handleParseWeek = async (form: FormApi, value: string) => {
    const week = await weeks.single(value);
    setPaymentDate(new Date(week.period_start));
    return value;
  };

  const handleParseEmpty = (form: FormApi, value: string) => value;

  useEffect(() => {
    const currencyId = form.getState().values.currency || 0;
    const result = getPayAmountForCurrency(currencyId, undefined);

    form.change("pay_amount", numeral(result.toString()).format("0,0.00"));
    form.change("pay_amountcalculated", numeral(result.toString()).format("0,0.00"));
  }, [checked]);

  useEffect(() => {
    const adhoc = selectedAdHocs.reduce((sum, item) => new BigNumber(sum).plus(new BigNumber(item.paidamount)).toNumber(), 0);
    form.change("adhoc", numeral(adhoc).format("0,0.00"));
  }, [selectedAdHocs]);

  useEffect(() => {
    const values = form.getState().values;
    const amountCalculated = new BigNumber(toNumber(values.adhoc)).plus(new BigNumber(toNumber(values.pay_amount))).toNumber();
    form.change("pay_amountcalculated", numeral(amountCalculated).format("0,0.00"));
  }, [form.getState().values.adhoc]);

  return (
    <div className={classes.container}>
      <TableFieldText classes={classes} form={form} field="paymentident" title="Ident" disabled={false} handleParse={handleParseEmpty} />
      <TableFieldDate classes={classes} form={form} dateValue={paymentDate} changeDate={changePaymentDate} title="Posted Date" />
      <TableFieldCombo classes={classes} form={form} field="week_id" title="Payment Week" data={weekComboData} addEmptyValue={undefined} handleParse={handleParseWeek} />
      <TableFieldLabel classes={classes} field={form.getState().values["currencySuggested"].toString()} title="Pallet Currency" disabled={true} />
      <TableFieldCombo
        classes={classes}
        form={form}
        field="currency"
        title="Pay Currency"
        data={currencyComboData}
        addEmptyValue={undefined}
        handleParse={(form, value) => handleChangeCurrency(form, value, undefined)}
      />
      <TableFieldText classes={classes} form={form} field="pay_amount" title="Amount" disabled={true} handleParse={handleParseText2Decimals} />
      <TableAdhocField classes={classes} toggleSelectAdhoc={toggleSelectAdhoc} />
      <TableFieldText classes={classes} form={form} field="adhoc" title="Ad Hoc Amount" disabled={true} handleParse={handleParseAdHoc} />
      <TableFieldLabel classes={classes} field={form.getState().values["pay_amountcalculated"].toString()} title="Actual Amount" disabled={true} />
      <TableFieldText classes={classes} form={form} field="makeanote" title="Note" disabled={false} handleParse={handleParseEmpty} />
    </div>
  );
};

type TableAdhocFieldProps = {
  toggleSelectAdhoc: (bool: boolean) => void;
} & WithStyles<typeof styles>;

const TableAdhocField: FC<TableAdhocFieldProps> = ({ classes, toggleSelectAdhoc }) => {
  return (
    <>
      <span className={classes.tableCellLabel}>Ad Hoc Remaining:</span>
      <span className={classes.tableCellDetail}>
        <Button variant="contained" color="primary" onClick={() => toggleSelectAdhoc(true)}>
          Select Adhocs
        </Button>
      </span>
    </>
  );
};

export default withStyles(styles)(ProducerAdvancePayUnstyled);
