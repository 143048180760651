import React from "react";

import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";

import { GridColumns } from "./linkedshipmentsgridsetup";
import Grid from "../../../lib/components/grid";

import Toolbar from "@material-ui/core/Toolbar";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(2),
      width: "100%",
      position: "relative",
      // "& .rdg-header-row": {
      //   "& .rdg-cell:nth-child(8)": {
      //     backgroundColor: "hotpink",
      //   },
      // },
    },
    gridWrapper: {
      position: "relative",
    },
    toolbar: {
      position: "absolute",
      left: 0,
      top: 0,
      height: "60px",
      width: "100%",
      display: "flex",
      flexDirection: "row",
      gap: "10px",
    },
  });

type LinkedShipments = {
  data: any[];
  adhocId: number;
  handleGridUpdate(grid: string, data: any[]): void;
} & WithStyles<typeof styles>;

class LinkedShipmentsUnstyled extends React.Component<LinkedShipments, {}> {
  constructor(props) {
    super(props);
  }

  state = {
    minimize: true,
  };

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <div className={classes.gridWrapper}>
          <Toolbar className={classes.toolbar}>
            <FormControlLabel
              control={<Checkbox checked={this.state.minimize} onChange={(event) => this.setState({ minimize: !this.state.minimize })} name="isAccountSale" />}
              label="Minimize"
            />
          </Toolbar>
          <Grid
            fill
            loading={false}
            forceHeight={500}
            data={this.props.data}
            GridColumns={(data, filters, arrangement, columnsWidth) => GridColumns(data, filters, arrangement, columnsWidth, this.props.adhocId, this.state.minimize)}
            clearFilters={"debtorslinkedshipments"}
            handleChange={this.props.handleGridUpdate}
            totalRowColumns={[
              "sale_total",
              "amountCredit",
              "amountDebit",
              "total_outstandingwithadjustments",
              "sale_payment1date",
              "sale_payment1amount",
              "sale_payment1amount_remain",
              "sale_payment2date",
              "sale_payment2amount",
              "sale_payment2amount_remain",
              "sale_payment3date",
              "sale_payment3amount",
              "sale_payment3amount_remain",
              "salefundsallocation_amount",
              "overpaid",
              "adhoc_adjustment",
            ]}
          />
        </div>
      </div>
    );
  }
}

export const LinkedShipments = withStyles(styles)(LinkedShipmentsUnstyled);
