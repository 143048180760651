import { image as logoImage } from "./elements/logo";
import { Check } from "./elements/Check";
import { Cancel } from "./elements/Cancel";
import { PTSans } from "./elements/font";

import { findByGroupNumber } from "../lib/api/inspection";
import { piorder } from "../lib/api/piorder";
import { actionsFindByInspectionId } from "../lib/api/inspectionactions";
import { varieties, varietyFull } from "../lib/api/variety";
import { weeks } from "../lib/api/week";
import { ordersFindByInspectionId } from "../lib/api/inspectionorders";
import { isNullOrUndefined } from "util";
import { companyDetails } from "../lib/api/companydetails";
import LZString from "lz-string";

import jsPDF from "jspdf";
import { get } from "./helpers";
import format from "date-fns/format";
import parseISO from "date-fns/parseISO";
import toDate from "date-fns/toDate";

let pdf;
let page = 1;
let pages;

let reportTitle;
let orderSummaryExists;
let orderNotesExists;
let titlePrinted;

let fromTop = 1;
let fromLeft = 9.0;
let fullWidth = 0,
  fullHeight = 0;

const lineHeight = 0.7;
const spacer = 2.5;
const imageSpacer = 4.9;

// We know that 1 inch is equal to 2.54 cm.
// So there are 96 pixels per 2.54 cm.
// Then, 1 centimeter = (96 / 2.54) px, therefore,
// there are 38 pixels in one centimeter.
const pixelsInCM = 38;
const maxImageHeight = 250; // pixels
const maxImageWidth = 345; // pixels

const white = "#FFFFFF";
const red = "#FF0000";
const orange = "#FFA500";
const green = "#00FF00";

const orderHeaders = {
  fruitspec: "FruitSpec",
  general: "General",
  weight: "Weight",
  firmness: "Firmness",
  buttons: "Buttons",
  color: "Color",
  blemish: "Blemish",
  skintexture: "Texture",
  granulation: "Granulation",
  grading: "Grading",
  sizing: "Sizing",
};

const inspectionActionHeaders = {
  labelcountry: "Country Lbl.",
  labelconsignee: "Consignee Lbl.",
  postharvestlabel: "PH Lbl.",
  postharvesttreatment: "PH Treatment",
  facility: "Facility",
};

const setFontBold = () => {
  pdf.setFontStyle("bold");
  pdf.setFont("helvetica"); // set font
};

const setFontNormal = () => {
  pdf.setFontStyle("normal");
  pdf.setFont("PTSans"); // set font
};

const printLine = (label, value, currentX, currentY) => {
  pdf.text(currentX, currentY, `${label}`, null, null, "right");
  pdf.text(currentX, currentY, value, null, null, "left");
};

const wrapBold = (fn, ...args) => {
  setFontBold();
  fn(...args);
  setFontNormal();
};

const newLine = (howFar: number = 0.5) => {
  fromTop += howFar;
};

let headerColumn1 = 0,
  headerColumn2 = 0,
  headerColumn3 = 0;

const updateHeaderCols = () => {
  headerColumn1 = 1.2;
  headerColumn2 = fullWidth / 2 - 1;
  headerColumn3 = fullWidth - 3.6;
};

const InitialisePDF = () => {
  pdf = new jsPDF({
    orientation: "l",
    unit: "cm",
  });

  pdf.addFileToVFS("PTSans.ttf", PTSans);
  pdf.addFont("PTSans.ttf", "PTSans", "normal");

  fullWidth = pdf.internal.pageSize.getWidth();
  fullHeight = pdf.internal.pageSize.getHeight();
  updateHeaderCols();

  pdf.setFont("PTSans");
  page = 1;

  orderSummaryExists = false;
  orderNotesExists = false;

  fromTop = 1;
  fromLeft = 1.2;

  pdf.setLineWidth(0.01);

  titlePrinted = false;
  reportTitle = "";
  pages = [];

  borderAroundPage();
};

const borderAroundPage = () => {
  pdf.setLineWidth(0.01);
  pdf.rect(0.25, 0.25, fullWidth - 0.5, fullHeight - 0.5, "S");
};

const newPage = () => {
  pdf.addPage();
  if (page == 1) {
    pages = [1];
  }
  page = page + 1;
  pages.push(page);
  pdf.setPage(page);

  fromTop = 1;
  fromLeft = 1.2;

  borderAroundPage();
};

const printPageDetails = (inspection) => {
  pdf.setFontSize(11);
  pdf.setPage(1);

  for (let i = 1; i <= page; i++) {
    pdf.setPage(i);
    pdf.text(28.5, 20.2, `${inspection.piorder_ordernum} | Page ${i} of ${pages.length == 0 ? 1 : pages.length}`, null, null, "right");
  }
};

export const handlePrint = async (inspection, printgroup = false, printFilters = undefined, returnRaw: boolean = false) => {
  InitialisePDF();

  if (printgroup) {
    const result = await findByGroupNumber(inspection.groupnumberfull);

    for (let index in result) {
      if (Number(index) > 0) {
        newPage();
      }

      orderSummaryExists = false;
      orderNotesExists = false;
      await printDocument(result[index], printFilters);
    }
  } else {
    await printDocument(inspection, printFilters);
  }

  printPageDetails(inspection);

  if (returnRaw) {
    return pdf.output("blob");
  }
  pdf.save(`${inspection.producer_code}(${inspection.producer_name})_${inspection.piorder_ordernum}.pdf`);
};

const printDocument = async (inspection, printFilters) => {
  const p1 = piorder.single(inspection.piorder);
  const p2 = p1.then((single) => varieties.single(single.variety));
  const p3 = p1.then((single) => weeks.single(single.week));

  const [orders, actions, variety, details, varietySingle, weekSingle] = await Promise.all([
    ordersFindByInspectionId(inspection.id),
    actionsFindByInspectionId(inspection.id),
    varietyFull(),
    companyDetails.all(),
    p2,
    p3,
  ]);

  const currVariety = variety.data.find((commodity) => varietySingle.commodity_id == commodity.commodity_id && varietySingle.code == commodity.code);
  await printDocumentDetail(inspection, currVariety, weekSingle, orders, actions, printFilters, details[0]);
};

const printDocumentDetail = async (inspection, variety, week, orders, actions, printFilters, companyDetails) => {
  await new Promise(async (resolve) => {
    printHeader(inspection, companyDetails);

    fromLeft = 1.2;
    pdf.line(fromLeft, fromTop, fullWidth - 1, fromTop, "S");
    newLine(0.6);

    pdf.setFontSize(13);
    wrapBold(() => pdf.text("QC INSPECTION REPORT", fullWidth / 2 - 0.5, fromTop, "center"));
    pdf.setFontSize(10);

    newLine(0.6);

    printInspectionSummary(inspection, variety, week);

    fromLeft = 1.2;
    pdf.line(fromLeft, fromTop, fullWidth - 1, fromTop, "S");

    newLine(0.6);

    const sortedOrders = orders.sort((a, b) => {
      if (a.count > b.count) return 1;
      if (a.count < b.count) return -1;
      return 1;
    });

    sortedOrders.map((order) => {
      Object.keys(orderHeaders).map((key) => {
        if (order[key] && (!printFilters || printFilters.checkedOrdersSummary[order.id])) {
          orderSummaryExists = true;
        }
      });
    });

    if (orderSummaryExists) {
      pdf.setFontSize(13);
      wrapBold(() => pdf.text("SUMMARY", fullWidth / 2 - 0.5, fromTop, "center"));

      newLine(0.6);
      fromLeft = 1.2;

      sortedOrders.map((order) => {
        if (!printFilters || printFilters.checkedOrdersSummary[order.id]) {
          printOrderSummary(order);
        }
      });
    }

    orders.map((order) => {
      actions.map((action) =>
        Object.keys(orderHeaders).map((key) => {
          if (
            ((action.field == key && action.piinstruction_id == order.piinstruction_id) || action.piinstruction_id == 0) &&
            (!printFilters || printFilters.checkedActions[action.id])
          ) {
            orderNotesExists = true;
          }
        }),
      );
    });

    if (fromTop != 1) {
      fromTop += 1;
    }

    printActionDetails(inspection, sortedOrders, actions, printFilters).then(() => {
      resolve("ok");
    });
  });
};

const setAddressLine = (property, companyDetails) => {
  newLine(0.4);
  pdf.text(get(property, companyDetails, ""), fromLeft, fromTop);
};

const printHeader = (inspection, companyDetails) => {
  fromTop = 1;
  fromLeft = headerColumn1;

  pdf.setFontSize(11);
  wrapBold(() => pdf.text(get("name", companyDetails, ""), fromLeft, fromTop, "left"));
  pdf.setFontSize(9);

  setAddressLine("address2", companyDetails);
  setAddressLine("address3", companyDetails);
  setAddressLine("address4", companyDetails);
  setAddressLine("address5", companyDetails);

  fromLeft = headerColumn2;

  const imageWidth = 5;
  const imageHeight = 1.5;

  pdf.addImage(logoImage, fromLeft - 2, 1, imageWidth, imageHeight);
  fromLeft = headerColumn3 - 1;
  fromTop = 1;

  wrapBold(() => pdf.text("Tel No:", fromLeft, fromTop, "right"));
  pdf.text(get("telephoneNumber", companyDetails, ""), fromLeft + 0.2, fromTop, "left");

  newLine();
  wrapBold(() => pdf.text("Fax No:", fromLeft, fromTop, "right"));
  pdf.text(get("faxNumber", companyDetails, ""), fromLeft + 0.2, fromTop, "left");

  newLine();
  wrapBold(() => pdf.text("E-mail:", fromLeft, fromTop, "right"));
  pdf.text(get("email", companyDetails, ""), fromLeft + 0.2, fromTop, "left");

  newLine();
  wrapBold(() => pdf.text("Vat No:", fromLeft, fromTop, "right"));
  pdf.text(get("vatno", companyDetails, ""), fromLeft + 0.2, fromTop, "left");

  newLine();
  wrapBold(() => pdf.text("Reg No:", fromLeft, fromTop, "right"));
  pdf.text(get("regno", companyDetails, ""), fromLeft + 0.2, fromTop, "left");

  newLine();
  wrapBold(() => pdf.text("Customs Code:", fromLeft, fromTop, "right"));
  pdf.text(get("customscode", companyDetails, ""), fromLeft + 0.2, fromTop, "left");

  newLine();
};

const addIconImageLine = (label: string, property: string, inspection, fromLeft: number) => {
  printLine(label, "", fromLeft, fromTop);
  const img = inspection[property] ? Check.toString() : Cancel.toString();
  pdf.addImage(img, img.toString().substring(11, 15).toUpperCase(), fromLeft, fromTop - 0.5, 0.7, 0.7);
};

const printInspectionSummary = (inspection, variety, week) => {
  const internals = inspection.brix && inspection.acid ? `${inspection.brix}:${inspection.acid}  -  ${(inspection.brix / inspection.acid).toFixed(2)}` : "";
  const juice = inspection.juice ? inspection.juice.toString() + " %" : "";

  pdf.setFontSize(9);

  fromLeft = headerColumn1 + 2;

  printLine("Producer: ", inspection.producer_name, fromLeft, fromTop);
  printLine("Week: ", week.week, headerColumn2 + 1, fromTop);
  addIconImageLine("Facility: ", "facility", inspection, headerColumn3);
  newLine(0.6);

  printLine("PUC: ", inspection.puc_code ? inspection.puc_code.toString() : "", fromLeft, fromTop);
  addIconImageLine("Special Market: ", "specialmarket", inspection, headerColumn2 + 1);
  addIconImageLine("PH Labels: ", "postharvestlabel", inspection, headerColumn3);
  newLine(0.6);

  printLine("Orchard: ", inspection.orchard_code ? inspection.orchard_code.toString() : "", fromLeft, fromTop);
  printLine("Packed Market: ", inspection.packedmarket, headerColumn2 + 1, fromTop);
  addIconImageLine("Consignee Label: ", "labelconsignee", inspection, headerColumn3);
  newLine(0.6);

  printLine("Picking Ref: ", inspection.pickref ? inspection.pickref.toString() : "", fromLeft, fromTop);
  printLine("Packed Country: ", inspection.packedcountry, headerColumn2 + 1, fromTop);
  addIconImageLine("Country Label: ", "labelcountry", inspection, headerColumn3);
  newLine(0.6);

  printLine("Commodity: ", variety.commodity_name || "", fromLeft, fromTop);
  printLine("Internals: ", internals, headerColumn2 + 1, fromTop);
  addIconImageLine("Lab Test: ", "labtest", inspection, headerColumn3);
  newLine(0.6);

  printLine("Variety: ", `${variety.name}(${variety.code})`, fromLeft, fromTop);
  printLine("Juice: ", juice, headerColumn2 + 1, fromTop);
  addIconImageLine("Degreen: ", "degreen", inspection, headerColumn3);

  newLine(lineHeight);

  printLine("Inspected: ", format(toDate(Number(inspection.inspectiondate)), "E, dd-MMM-yyyy HH:mm a"), headerColumn3 - 2, fromTop);
  newLine(0.6);
  printLine("Completed: ", format(new Date((inspection.created_at || "").replace("Z", "")), "E, dd MMM yyyy HH:mm a"), headerColumn3 - 2, fromTop);
  newLine(0.6);
  printLine("Printed: ", format(new Date(), "E, dd-MMM-yyyy HH:mm a"), headerColumn3 - 2, fromTop);
  newLine(0.6);
};

const getOrderHeading = (order) => `${order.target_market} | ${order.pack_carton} | ${order.pack_code} | ${order.count} | ${order.class} | ${order.inventory_code}`;

const printOrderSummary = (order) => {
  // if (fromTop > fullHeight - 4) {
  //   newPage();
  //   fromTop = 2;
  // }

  orderSummaryExists = false;

  Object.keys(orderHeaders).map((key) => {
    if (order[key]) orderSummaryExists = true;
  });

  if (!orderSummaryExists) return;

  wrapBold(() => {
    const orderTitle = getOrderHeading(order);
    pdf.setFontSize(11);
    pdf.text(fromLeft, fromTop, orderTitle, null, null, "left");
    newLine(0.6);
  });

  Object.keys(orderHeaders).map((key) => {
    PrintRatingValue(orderHeaders[key], order[key], 1.9);
  });

  fromLeft = 1.2;
  fromTop += 2.5;
};

const PrintRatingValue = (label, value, spacer = 3) => {
  pdf.text(fromLeft, fromTop, label, null, null, "left");

  pdf.setFontSize(14);

  const img = value == 0 ? Cancel.toString() : value == 1 ? Check.toString() : "N/A";
  threeStateValue.includes(label) ? pdf.setFillColor(ratingToColor(value)) : pdf.setFillColor(boolToColor(value));
  threeStateValue.includes(label)
    ? pdf.rect(fromLeft, fromTop + lineHeight / 2, 1, 1, "F")
    : img == "N/A"
    ? pdf.text(fromLeft + 0.2, fromTop + 1, "N/A", null, null, "left")
    : pdf.addImage(img, img.toString().substring(11, 15).toUpperCase(), fromLeft - 0.2, fromTop, 1.5, 1.5);
  threeStateValue.includes(label) ? pdf.text(fromLeft + 0.4, fromTop + lineHeight + lineHeight / 2, value ? value.toString() : "0", null, null, "left") : null;

  fromLeft = fromLeft + (spacer == 1.9 && label == "Granulation" ? 2.5 : spacer == 1.9 && label == "Carton Lbl." ? 2.2 : spacer);
};

const threeStateValue = ["Color", "Blemish", "Texture", "Granulation"];

const calculateAspectRatioFit = (srcWidth: number, srcHeight: number, maxWidth: number, maxHeight: number) => {
  const ratio = Math.min(maxWidth / srcWidth, maxHeight / srcHeight);

  return { width: srcWidth * ratio, height: srcHeight * ratio };
};

const writeImage = async (imageDec, key, header, width = 5, height = 5) => {
  const dimensions = calculateAspectRatioFit(width, height, maxImageWidth / pixelsInCM, maxImageHeight / pixelsInCM);

  //max images in row
  if (fromLeft >= fullWidth || fromLeft + dimensions.width >= fullWidth) {
    fromLeft = 1;
    fromTop = fromTop + 7;
  }

  //Continued Label
  if (fromTop > 13 || fromTop + dimensions.height >= 20.3) {
    newPage();

    pdf.text(fromLeft, fromTop, "..." + header[key], null, null, "left");
    fromTop += lineHeight;
  }

  pdf.addImage(imageDec, imageDec.toString().substring(11, 15).toUpperCase(), fromLeft, fromTop, dimensions.width, dimensions.height);

  // border around images
  pdf.setLineWidth(0.01);
  pdf.rect(fromLeft - 0.01, fromTop - 0.01, dimensions.width + 0.02, dimensions.height + 0.02, "S");

  fromLeft = fromLeft + dimensions.width + 0.5;
};

const ratingToColor = (ratingValue) => {
  switch (ratingValue) {
    case 1:
      return red;
    case 2:
      return orange;
    case 3:
      return green;
    default:
      return white;
  }
};

const boolToColor = (ratingValue) => {
  switch (ratingValue) {
    case 0:
      return red;
    case 1:
      return green;
    case 2:
      return orange;
    default:
      return white;
  }
};

const printActionDetails = async (inspection, orders, actions, printFilters) => {
  writeInspectionActions(inspection, actions, printFilters);
  writeOrderActions(orders, actions, printFilters);
};

const writeInspectionActions = (inspection, actions, printFilters) => {
  let pageSkipped = false;
  actions.map((action) => {
    if (printFilters && !printFilters.checkedActions[action.id]) {
      return false;
    }
    Object.keys(inspectionActionHeaders).map((key) => {
      if (action.field == key && (action.images.toString().length > 0 || (action.comments && action.comments.trim()))) {
        pdf.setFontSize(12);
        if (action.images.toString().length == 0 && fromTop >= 20) {
          newPage();
          pageSkipped = true;
        } else if (action.images.toString().length > 0 && fromTop >= 15) {
          newPage();
          pageSkipped = true;
        }

        if (!titlePrinted) {
          !pageSkipped ? newPage() : undefined;
          pdf.setFontSize(17);
          pdf.text(fromLeft, fromTop, "Inspection Notes", null, null, "left");
          fromTop += 1;
          titlePrinted = !titlePrinted;
        }
        fromTop += lineHeight;

        pdf.setFontSize(13);
        if (fromTop >= 16) {
          newPage();
        } else if (
          action.images.toString().length > 3 &&
          JSON.parse(action.images).filter((im) => {
            const imageDec = im.base64 ? (im.base64.includes("base64") ? im.base64 : LZString.decompress(im.base64)) : im.includes("base64") ? im : LZString.decompress(im);
            if (imageDec && (!printFilters || printFilters.checkedImages[imageDec])) return im;
          }).length > 0 &&
          fromTop + lineHeight >= 13
        ) {
          newPage();
        }

        pdf.setFontSize(14);
        wrapBold(() => {
          PrintRatingValue(inspectionActionHeaders[key], inspection[key]);
          pdf.setLineWidth(0.01);
          pdf.rect(1.2, fromTop + 0.1, pdf.getTextDimensions(inspectionActionHeaders[key]).w, 0.01, "S");
        });

        pdf.setFontSize(10);
        pdf.text(fromLeft + 1, fromTop, "Comment:", null, null, "left");
        pdf.text(
          fromLeft + 3,
          fromTop,
          !isNullOrUndefined(action.comments) && action.comments != "" ? action.comments.toString().replace(/\n/gm, " ") : "None.",
          null,
          null,
          "left",
        );

        fromTop = fromTop + lineHeight / 2;

        if (action.images.toString().length > 0 && !Array.isArray(action.images)) {
          action.images = JSON.parse(action.images);
        }

        let addedImage = false;
        if (action.images.toString().length > 0) {
          action.images.map(async (image) => {
            // const imagebase = image.base64 ? image.base64 : image;
            const imageDec = image.base64
              ? image.base64.includes("base64")
                ? image.base64
                : LZString.decompress(image.base64)
              : image.includes("base64")
              ? image
              : LZString.decompress(image);

            if (printFilters && !printFilters.checkedImages[imageDec]) {
              return false;
            }

            const imageWidth = image.base64 ? image.width : 5;
            const imageHeight = image.base64 ? image.height : 5;

            if (imageDec && (!printFilters || isNullOrUndefined(printFilters.checkedImages[imageDec]) || printFilters.checkedImages[imageDec])) {
              writeImage(imageDec, key, inspectionActionHeaders, imageWidth, imageHeight);
              addedImage = true;
            }
          });
        }

        fromLeft = 1.2;
        fromTop += addedImage ? 7 : lineHeight * 2;
      }
    });
  });
};

const writeOrderActions = async (orders, actions, printFilters) => {
  let writeLine;
  pdf.setLineWidth(1);

  orders.map(async (order) => {
    writeLine = false;
    if (printFilters && !printFilters.checkedOrdersDetail[order.id]) {
      return false;
    }
    orderNotesExists = false;

    actions.map((action) =>
      Object.keys(orderHeaders).map((key) => {
        if (action.field == key && action.piinstruction_id == order.piinstruction_id && (!printFilters || printFilters.checkedActions[action.id])) {
          orderNotesExists = true;
        }
      }),
    );

    if (!orderNotesExists) {
      return;
    }

    newPage();

    fromTop += lineHeight;

    if (!titlePrinted) {
      pdf.setFontSize(17);
      pdf.text(fromLeft, fromTop, "Inspection Notes", null, null, "left");
      fromTop += 1;
      titlePrinted = !titlePrinted;
      fromTop += 1;
    }

    wrapBold(() => {
      const orderTitle = getOrderHeading(order);
      pdf.setFontSize(18);
      pdf.text(fromLeft, fromTop, orderTitle, null, null, "left");
    });

    Object.keys(orderHeaders).map(async (key) => {
      //Actions
      const actionsFiltered = actions.filter((a) => {
        if (a.field.toLowerCase() == key) {
          return a;
        }
      });

      actionsFiltered.map(async (action) => {
        if (printFilters && !printFilters.checkedActions[action.id]) {
          return false;
        }
        Object.keys(orderHeaders).map(async (key) => {
          if (action.field == key && action.piinstruction_id == order.piinstruction_id) {
            writeLine = true;

            pdf.setFontSize(13);
            fromTop += lineHeight;
            if (fromTop >= 16) {
              newPage();
            } else if (
              action.images.toString().length > 3 &&
              JSON.parse(action.images).filter((im) => {
                const imageDec = im.base64 ? (im.base64.includes("base64") ? im.base64 : LZString.decompress(im.base64)) : im.includes("base64") ? im : LZString.decompress(im);
                if (imageDec && (!printFilters || printFilters.checkedImages[imageDec])) return im;
              }).length > 0 &&
              fromTop + lineHeight >= 13
            ) {
              newPage();
            }

            fromTop += lineHeight;

            pdf.setFontSize(14);
            wrapBold(() => {
              PrintRatingValue(orderHeaders[key], order[key]);
              pdf.setLineWidth(0.01);
              pdf.rect(1.2, fromTop + 0.1, pdf.getTextDimensions(orderHeaders[key]).w, 0.01, "S");
            });

            pdf.setFontSize(10);
            pdf.text(fromLeft + 1, fromTop, "Comment:", null, null, "left");
            pdf.text(fromLeft + 3, fromTop, action.comments && action.comments.trim() ? action.comments.toString().replace(/\n/gm, " ") : "None.", null, null, "left");
            // const multipleLines = Math.floor( action.comments.toString().replace(/\n/gm," ").length / 136);
            fromTop = fromTop + lineHeight / 2; //* (multipleLines >= 1 ? multipleLines : 1) / 136);

            if (action.images.toString().length > 0 && action.images.constructor !== Array) {
              action.images = JSON.parse(action.images);
            }

            let addedImage = false;
            if (action.images.toString().length > 0) {
              // action.images.map(async (img) => {
              action.images.map(async (image) => {
                const imageDec = image.base64
                  ? image.base64.includes("base64")
                    ? image.base64
                    : LZString.decompress(image.base64)
                  : image.includes("base64")
                  ? image
                  : LZString.decompress(image);

                const imageWidth = image.base64 ? image.width : 5;
                const imageHeight = image.base64 ? image.height : 5;

                if (printFilters && !printFilters.checkedImages[imageDec]) {
                  return false;
                }
                if (imageDec && (!printFilters || isNullOrUndefined(printFilters.checkedImages[imageDec]) || printFilters.checkedImages[imageDec])) {
                  writeImage(imageDec, key, orderHeaders, imageWidth, imageHeight);
                  addedImage = true;
                }
              });
            }

            fromLeft = 1.2;

            addedImage ? (fromTop = fromTop + 7) : (fromTop = fromTop + lineHeight * 2);
          }
        });
      });
    });
    if (writeLine) {
      pdf.setFontSize(11);
      if (fromTop > 18) {
        newPage();
      }

      wrapBold(() => {
        const orderTitle = getOrderHeading(order);
        pdf.text(28.5, fromTop + lineHeight / 2, orderTitle, null, null, "right");
      });

      pdf.line(fromLeft, fromTop + (lineHeight + lineHeight / 2), 28.5, fromTop + (lineHeight + lineHeight / 2), "S");
      pdf.setFontSize(10);
    }
  });
};
