import { client, request } from "./_base";

interface InspectionType {
  id: number;
  number: string;
  inspectiondate: string;
  producer: number;
  puc: number;
  orchard: number;
  pickref: string;
  specialmarket: number;
  piorder: number;
  firmness: number;
  buttons: number;
  brix: number;
  acid: number;
  packedmarket: string;
  proposedmarket: string;
  postharvestlabel: number;
  postharvesttreatment: number;
  labelcarton: number;
  labelconsignee: number;
  labelcountry: number;
  facility: number;
  juice: number;
  username: string;
  created_at: Date;
  updated_at: Date;
  packedcountry: string;
  variety_id: string;
  week_id: string;
  groupnumber: string;
  status: number;
  editUser: string;
  labtest: number;
  degreen: number;
  flag: string;
}

export const inspections = client("inspection", (item) => ({
  ...item,
}));

const endpoint = "inspection";

export const findByNumber = (number) => {
  return request(`inspection/ext/findByNumber/${number}`, {
    method: "GET",
  })
    .then(async (result) => {
      if (result.status == 500) {
        const resp = await result.json();
        throw resp;
      }
      return result.json();
    })
    .then(({ data }) => {
      return data;
    });
};

export const findByGroupNumber = (number) => {
  return request(`inspection/ext/findByGroupNumber/${number}`, {
    method: "GET",
  })
    .then(async (result) => {
      if (result.status == 500) {
        const resp = await result.json();
        throw resp;
      }
      return result.json();
    })
    .then(({ data }) => {
      return data;
    });
};

export const findBy = (id = 0, producerid = 0, limit = 1000) => {
  return request(`inspection/ext/inspectionFull/${id}/${producerid}/${limit}`, {
    method: "GET",
  })
    .then(async (result) => {
      if (result.status == 500) {
        const resp = await result.json();
        throw resp;
      }
      return result.json();
    })
    .then(({ data }) => {
      return data;
    });
};

export const inspectionPiOrder = () => {
  return request(`${endpoint}/ext/inspectionPiOrder`, {
    method: "GET",
  })
    .then(async (result) => {
      if (result.status == 500) {
        const resp = await result.json();
        throw resp;
      }
      return result.json();
    })
    .then(({ data }) => {
      return data;
    });
};

type InspectionNote = {
  id?: number;
  images: any;
  comment: string;
  created_at?: Date;
  updated_at?: Date;
  inspection_id: number;
};

export const getInspectionNotes = async (inspectionId: number): Promise<InspectionNote> => {
  const result = await request(`${endpoint}/ext/getInspectionNotes/${inspectionId}`, {
    method: "GET",
  });
  const resp = await result.json();
  if (result.status == 500) {
    throw resp;
  }
  return resp.data[0];
};

export const upsertInspectionNote = async (inspectionNote: InspectionNote): Promise<void> => {
  const result = await request(`${endpoint}/ext/upsertInspectionNote`, {
    method: "POST",
    body: JSON.stringify(inspectionNote),
  });
  if (result.status == 500) {
    const resp = await result.json();
    throw resp;
  }
};

export interface InspectionReportType extends InspectionType {
  groupnumberfull: string;
  groupnumbercount: string;
  producer_code: string;
  producer_name: string;
  puc_code: string;
  orchard_code: string;
  piorder_ordernum: string;
  variety_code: string;
  ordernum: string;
  inspection_number: string;
  inspectionactions_field: string;
  inspectionactions_images?: string;
  inspectionnotes_images?: string;
  images?: string[];
}

export const getInspectionsByProducerAndDate = async (
  producer_code: string,
  dateTo: string,
  dateFrom: string,
  includeMarketingImages: boolean,
): Promise<InspectionReportType[]> => {
  const result = await request(
    `${endpoint}/ext/getInspectionsByProducerAndDate?producer_code=${producer_code}&dateTo=${dateTo}&dateFrom=${dateFrom}&includeMarketingImages=${includeMarketingImages}`,
    {
      method: "GET",
    },
  );
  const resp = await result.json();
  if (result.status == 500) {
    throw resp;
  }
  return resp.data;
};
