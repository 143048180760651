import React from "react";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";

import StockPricingReference from "./stockpricingreferences";
import StockPricingUpdate from "./stockpricingupdate";

import { getStockGroupedByReference } from "../lib/api/stock";

// import Button from "@material-ui/core/Button";
import LinearProgress from "@material-ui/core/LinearProgress";

import "react-splitter-layout/lib/index";
import "../lib/custom/splitter.css";
import Splitter from "m-react-splitters";
import "m-react-splitters/lib/splitters.css";

const styles = (theme: Theme) =>
  createStyles({
    container: {
      width: "100%",
      height: "calc(100% - 100px)",
    },
    wrapper: {
      height: "100%",
      display: "grid",
    },
  });

type StockPricingProps = {
  onBack: any;
} & WithStyles<typeof styles>;

class StockPricingUnstyled extends React.Component<StockPricingProps> {
  state = {
    updateData: [],
    selectedReferences: undefined,
    loading: false,
    refresh: false,
    onBack: undefined,
    minGridHeight: 300,
    minGridHeightSecond: 300,
  };

  constructor(props) {
    super(props);
    this.state.onBack = props.onBack;
  }

  componentDidMount() {
    this.setGridHeights();
  }

  onSelectedChanged = async (selectedData) => {
    this.setState({ loading: true });
    const mapped = selectedData.map((item) => item.reference);
    const result = await getStockGroupedByReference(mapped);
    this.setState({ updateData: result.data, selectedReferences: mapped, loading: false }, () => {});
  };

  setContractSellingPrices = () => {
    const data = this.state.updateData;
    data.map((row) => {
      row.sellingprice = row.contractSellPrice ? row.contractSellPrice : row.sellingprice;
    });
    this.setState({ updateData: data });
    return data;
  };

  onRefresh = async () => {
    this.setState({ refresh: true }, () => {
      this.onSelectedChanged([]).then(() => {
        this.setState({ refresh: false });
      });
    });
  };

  setGridHeights = () => {
    const primarysplit = document.getElementsByClassName("splitter horizontal");
    this.setState({ minGridHeight: primarysplit[0].children[0].clientHeight - 80, minGridHeightSecond: primarysplit[0].children[2].clientHeight - 70 });
  };

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.container}>
        {this.state.loading && <LinearProgress />}
        <div id="main_splitter_div" style={{ height: "100%", width: "100%", display: "grid" }}>
          <Splitter
            position="horizontal"
            // primaryPaneMaxHeight="calc(100% - 500px)"
            // primaryPaneHeight="calc(100% / 3)"
            primaryPaneWidth="100%"
            dispatchResize={true}
            postPoned={true}
            onDragFinished={(e) => {
              this.setGridHeights();
            }}
          >
            <StockPricingReference onSelectedChanged={this.onSelectedChanged} onDataUpdated={this.state.refresh} minGridHeight={this.state.minGridHeight} />
            {!this.state.loading && (
              <StockPricingUpdate
                updateData={this.state.updateData}
                selectedReferences={this.state.selectedReferences}
                onRefresh={this.onRefresh}
                onBack={this.state.onBack}
                setContractSellingPrices={this.setContractSellingPrices}
                minGridHeightSecond={this.state.minGridHeightSecond}
              />
            )}
          </Splitter>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(StockPricingUnstyled);
