import { apiHeaders, request } from "./_base";

const endpoint = "enquiry";

export const getEnquiryDataByBarcode = (body: { value: string; selector: number }) =>
  request(`${endpoint}/ext/allByBarcode`, {
    method: "POST",
    headers: apiHeaders(),
    body: JSON.stringify(body),
  }).then(async (result) => {
    if (result.status == 500) {
      const resp = await result.json();
      throw resp;
    }
    return result.json();
  });
