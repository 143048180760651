import React, { useEffect } from "react";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";

import { SnackContext } from "../lib/context/SnackContext";
import { handlePrintPackingListPDF, handlePrintInvoice } from "../reports/printing";

import { GenerateErrorMessage } from "../lib/helpers/string_methods";
// const err = GenerateErrorMessage(error, "");

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1),
    },
  });

type XXXProps = {} & WithStyles<typeof styles>;

const XXXUnstyled: React.FunctionComponent<XXXProps> = (props) => {
  const { classes } = props;
  const { updateSnack } = React.useContext(SnackContext);

  useEffect(() => {
    // handlePrintPackingListPDF("4429").catch((error) => {
    // handlePrintPackingListPDF("4169").catch((error) => {
    handlePrintInvoice("4169").catch((error) => {
      const err = GenerateErrorMessage(error, "Error retrieving data");
      updateSnack({ show: true, color: "red", message: err });
    });
  }, []);

  return <div className={classes.root}>HELLO __MOCK__</div>;
};

export default withStyles(styles)(XXXUnstyled);

// import React from 'react';
// import { withStyles, createStyles, WithStyles, Theme } from '@material-ui/core/styles';
// import { SnackContext } from "../../lib/context/SnackContext";

// const styles = (theme: Theme) => createStyles({
//   root: {
//     padding: theme.spacing(1),
//   },
// });

// type XXXProps = {
// } & WithStyles<typeof styles>;

// export class XXXUnstyled extends React.Component<XXXProps, any> {
//   state = {
//     classes: undefined,
//   }

// static contextType = SnackContext;
// context!: React.ContextType<typeof SnackContext>;
// const err = GenerateErrorMessage(error, "failed to retrieve data");
// this.context.updateSnack({ show: true, color: "red", message: err });

//   constructor(props) {
//     super(props);
//     this.state.classes = props.classes;
//   }

//   render() {
//     return (
//       <div className={this.state.classes.root}>
//         HELLO
//       </div>
//     )
//   }
// }

// export default withStyles(styles)(XXXUnstyled);
