import React from "react";
import CustomAutoCompleteFilter from "../../../lib/components/customdatagridfilter/CustomFilter";
import { FormatterNumber2Decimals } from "../../../lib/components/Formatters";
import { SummaryNumber2Decimal } from "../../../lib/components/SummaryFormatter";

const cellWidthSmallX = 80;
const cellWidthMedium = 150;
const cellWidthLarge = 200;

const defaultColumnProperties = {
  resizable: true,
  sortable: true,
};

let currentSpace = true;

export const GridColumns = (data, filters, arrangement, columnsWidth) => {
  let postValue = "";

  if (data) {
    if (currentSpace) {
      postValue = " ";
    }
    currentSpace = !currentSpace;
  }

  const columns = [
    {
      key: "producercode",
      name: "Producer Code" + postValue,
      width: cellWidthMedium,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"producercode"} data={data} section="adjustmentproducerinvoicesummary" />;
      },
      filterable: true,
    },
    {
      key: "producerName",
      name: "Producer Name" + postValue,
      width: cellWidthLarge,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"producerName"} data={data} section="adjustmentproducerinvoicesummary" />;
      },
      filterable: true,
    },
    {
      key: "noCartonsTotal",
      name: "Total Cartons" + postValue,
      width: cellWidthLarge,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"noCartonsTotal"} data={data} section="adjustmentproducerinvoicesummary" />;
      },
      filterable: true,
      formatter: FormatterNumber2Decimals,
      summaryFormatter: SummaryNumber2Decimal,
    },
    {
      key: "saleTotal",
      name: "Total Sale Value" + postValue,
      width: cellWidthLarge,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"saleTotal"} data={data} section="adjustmentproducerinvoicesummary" />;
      },
      filterable: true,
      formatter: FormatterNumber2Decimals,
      summaryFormatter: SummaryNumber2Decimal,
    },
    {
      key: "amountCreditTotal",
      name: "Total Credit Note Value" + postValue,
      width: cellWidthLarge,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"amountCreditTotal"} data={data} section="adjustmentproducerinvoicesummary" />;
      },
      filterable: true,
      formatter: FormatterNumber2Decimals,
      summaryFormatter: SummaryNumber2Decimal,
    },
    {
      key: "amountDebitTotal",
      name: "Total Debit Note Value" + postValue,
      width: cellWidthLarge,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"amountDebitTotal"} data={data} section="adjustmentproducerinvoicesummary" />;
      },
      filterable: true,
      formatter: FormatterNumber2Decimals,
      summaryFormatter: SummaryNumber2Decimal,
    },
  ].map((c) => ({ ...c, ...defaultColumnProperties }));

  const columnsArranged = [];
  if (arrangement && arrangement.length > 0) {
    arrangement.map((item) => {
      const indexOfItem = columns.indexOf(columns.find((col) => col.name.trim() == item.toString().trim()));
      if (indexOfItem != -1) {
        columnsArranged.push(columns[indexOfItem]);
      }
    });
  } else {
    columns.map((colItem) => {
      columnsArranged.push(colItem);
    });
  }

  if (columnsWidth && columnsWidth.length > 0) {
    for (let index = 0; index < columnsArranged.length; index++) {
      const element = columnsArranged[index];
      const colItem = columnsWidth.find((colwitem) => colwitem.name.trim() == element.name.trim());
      if (colItem) {
        element.width = colItem.width;
      }
    }
  }

  return columnsArranged;
};
