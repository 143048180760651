import React from "react";

import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";

import CustomAutoCompleteFilter from "../../lib/components/customdatagridfilter/CustomFilter";
import { CellActionsFormatter } from "../../lib/helpers/CellActionsFormatter";
import { handleColumnsArrangement, handleColumnsWidth } from "../../lib/helpers/grid";

const cellWidthSmall = 80;
const cellWidthSmallBig = 140;
const cellWidthMedium = 200;

const defaultColumnProperties = {
  resizable: true,
  sortable: true,
};

export const costEstimatedTypeMap = {
  0: "ZAR Per Carton",
  1: "Royalty Per Carton / FOB",
  2: "Royalty Percentage / FOB",
};

let currentSpace = true;

export const GridColumns = (data, filters, arrangement = undefined, columnsWidth = undefined, handleRemove, handleEdit) => {
  let postValue = "";

  if (data) {
    if (currentSpace) {
      postValue = " ";
    }
    currentSpace = !currentSpace;
  }

  const columns = [
    {
      key: "actions",
      name: "Actions",
      width: cellWidthSmall,
      formatter: ({ row }) => {
        const actions = cellActions(row, handleRemove, handleEdit);
        return (
          <div style={{ display: "flex", justifyContent: "center", gap: "5px" }}>
            <CellActionsFormatter actions={actions} />
          </div>
        );
      },
    },
    {
      key: "costdescription",
      name: "Description" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"costdescription"} data={data} section={"estimatedcostitemgridsetup"} />;
      },
    },
    {
      key: "type",
      name: "Type" + postValue,
      width: cellWidthMedium,
      filterable: true,
      formatter: TypeFormatter,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"type"} data={data} section={"estimatedcostitemgridsetup"} />;
      },
    },
    {
      key: "group_name",
      name: "Group" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"group_name"} data={data} section={"estimatedcostitemgridsetup"} />;
      },
    },
  ].map((c) => ({ ...c, ...defaultColumnProperties }));

  let columnsArranged = [];
  columnsArranged = handleColumnsArrangement(columnsArranged, arrangement, columns);
  columnsArranged = handleColumnsWidth(columnsArranged, columnsWidth, columns);

  return columnsArranged;
};

const TypeFormatter = ({ row, column }) => {
  const data = row[column.key];
  return <div>{`${costEstimatedTypeMap[data]} (${data})`}</div>;
};

const cellActions = (item, handleRemove, handleEdit) => {
  return [
    {
      icon: (
        <span title="Edit">
          <EditIcon style={{ cursor: "pointer", color: "orange" }} />
        </span>
      ),
      callback: (e) => {
        handleEdit(item.id);
      },
    },
    {
      icon: (
        <span title="Remove">
          <DeleteIcon style={{ cursor: "pointer", color: "red" }} />
        </span>
      ),
      callback: (e) => {
        handleRemove(item.id);
      },
    },
  ];
};
