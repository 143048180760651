import { isNullOrUndef } from "./isNullOrUndef";

export function generateSortFn(props) {
  return function (a, b) {
    for (var i = 0; i < props.length; i++) {
      var prop = props[i];
      var name = prop.name;
      var reverse = prop.reverse;
      if (name == "Barcode" || name == "barcode") {
        if (a[name] < b[name]) return reverse ? 1 : -1;
        if (a[name] > b[name]) return reverse ? -1 : 1;
      } else if (isNaN(a[name]) || isNaN(b[name])) {
        if (a[name] < b[name]) return reverse ? 1 : -1;
        if (a[name] > b[name]) return reverse ? -1 : 1;
      } else {
        if (Number(a[name]) < Number(b[name])) return reverse ? 1 : -1;
        if (Number(a[name]) > Number(b[name])) return reverse ? -1 : 1;
      }
    }
    return 0;
  };
}

export const sortAlphaNumFunc = (props) => {
  return function (a, b) {
    for (var i = 0; i < props.length; i++) {
      var prop = props[i];
      var name = prop.name;
      var reverse = prop.reverse;
      if (isNullOrUndef(a[name])) {
        a[name] = "";
        a["label"] = "";
      }
      if (isNullOrUndef(b[name])) {
        b[name] = "";
        b["label"] = "";
      }
      var aA = a[name].toString().replace(reA, "");
      var bA = b[name].toString().replace(reA, "");
      if (aA === bA) {
        var aN = parseInt(a[name].toString().replace(reN, ""), 10);
        var bN = parseInt(b[name].toString().replace(reN, ""), 10);
        return aN === bN ? 0 : aN > bN ? (reverse ? -1 : 1) : reverse ? 1 : -1;
      } else {
        return aA > bA ? (reverse ? -1 : 1) : reverse ? 1 : -1;
      }
    }
    return 0;
  };
};

var reA = /[^a-zA-Z]/g;
var reN = /[^0-9]/g;

export const sortAlphaNum = (a, b) => {
  if (a.value === null) {
    a.value = "";
    a.label = "";
  }
  if (b.value === null) {
    b.value = "";
    b.label = "";
  }
  var aA = a.value.toString().replace(reA, "");
  var bA = b.value.toString().replace(reA, "");
  if (aA === bA) {
    var aN = parseInt(a.value.toString().replace(reN, ""), 10);
    var bN = parseInt(b.value.toString().replace(reN, ""), 10);
    return aN === bN ? 0 : aN > bN ? 1 : -1;
  } else {
    return aA > bA ? 1 : -1;
  }
};
