import React from "react";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";

import { withRouter } from "react-router-dom";

import Toolbar from "@material-ui/core/Toolbar";

import { Row } from "react-data-grid";

import Grid from "../../lib/components/grid";
import { GridColumns } from "./invoicegridsetup";
import { creditorsinvoiceFull, CreditorsInvoiceFullType, CreditorsInvoiceDocumentType } from "../../lib/api/creditorsinvoice";
import { getFinancialYearSelected } from "../../lib/api/week";
import { CreditorsInvoiceType } from "../../lib/api/creditorsinvoicetype";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      height: "calc(100% - 200px)",
      position: "relative",
    },
    gridWrapper: {
      position: "relative",
    },
    toolbar: {
      position: "absolute",
      left: 0,
      top: 0,
      height: "60px",
      width: "100%",
    },
    redRow: {
      color: "red",
    },
    greenRow: {
      color: "green",
    },
  });

type Props = {} & WithStyles<typeof styles>;

class CreditorsSummaryInvoices extends React.Component<Props, any> {
  constructor(props) {
    super(props);
  }

  state = {
    data: [],
    loading: true,
    gridHeight: 500,
  };

  loadData = async () => {
    this.setState({ loading: true });
    const result: CreditorsInvoiceFullType[] = await creditorsinvoiceFull(getFinancialYearSelected());
    const grouped = result.reduce((obj, row) => {
      const id =
        `${row.creditorsinvoice_type}_` +
        (row.creditorsinvoice_type == CreditorsInvoiceDocumentType.Invoice ? `${row.creditorsinvoice_id}` : `${row.creditorsinvoiceadjustment_id}`);
      obj[id] = {
        ...row,
        saleinvoicenumbers: `${obj[id] ? obj[id].saleinvoicenumbers + ", " : ""}${row.sale_invoicenumber || ""}`,
        consolidationapply: CreditorsInvoiceType[row.consolidationapply],
      };
      return obj;
    }, {});
    const data = Object.values(grouped).sort((a: CreditorsInvoiceFullType, b: CreditorsInvoiceFullType) => {
      if (new Date(b.invoicedate) > new Date(a.invoicedate)) return 1;
      if (new Date(b.invoicedate) < new Date(a.invoicedate)) return -1;
      return 0;
    });
    this.setState({ loading: false, data });
  };

  componentDidMount() {
    this.loadData().then(() => {
      const mainClientheight = document.getElementById("divDetails");
      this.setState({ gridHeight: mainClientheight && mainClientheight.clientHeight - 50 });
    });
  }

  rowRenderer = (props, classes) => {
    const { row } = props;
    const color = row.document_type == 3 ? classes.redRow : row.document_type == 2 && classes.greenRow;

    return <Row {...props} className={color} />;
  };

  render() {
    const { classes } = this.props;

    return (
      <div id="divDetails" className={classes.root}>
        <div className={classes.gridWrapper}>
          <Toolbar className={classes.toolbar}></Toolbar>
          <Grid
            loading={this.state.loading}
            data={this.state.data}
            GridColumns={GridColumns}
            clearFilters={"creditorsinvoices"}
            forceHeight={this.state.gridHeight}
            handleRefresh={this.loadData}
            rowRenderer={(props) => this.rowRenderer(props, classes)}
            totalRowColumns={["validate_totalexcl", "validate_vat", "validate_total"]}
          />
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(withRouter(CreditorsSummaryInvoices));
