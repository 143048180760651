import { client, request, apiHeaders } from "./_base";
import { format } from "date-fns";

const endpoint = "arrival";

export const arrivals = client(endpoint, (item) => ({
  ...item,
}));

export const arrivalDumpDataExcel = (data, excludeConsignee, financialyear) => {
  const content = JSON.stringify(data);
  return request(`${endpoint}/ext/dumpExcelData/${excludeConsignee}/${financialyear}`, {
    method: "POST",
    headers: apiHeaders(content),
    body: content,
  }).then((result) => {
    result.blob().then((blob) => {
      const fileName = `${format(new Date(), "yyMMdd")}${excludeConsignee ? "_PRODUCER" : ""}_ARRIVAL_SUMMARY.xlsx`;
      let url = window.URL.createObjectURL(blob);
      let a = document.createElement("a");
      a.href = url;
      a.download = fileName;
      a.click();
    });
  });
};

// export const arrivalDetailedExportData = (excludeConsignee) => {
//   return request(`${endpoint}/ext/detailedExportData/${excludeConsignee}`, {
//     method: "GET",
//     headers: apiHeaders(),
//   }).then((result) => {
//     result.blob().then((blob) => {
//       const fileName = `${format(new Date(), "yyMMdd")}${excludeConsignee ? "_PRODUCER" : ""}_ARRIVAL_DETAIL.xlsx`;
//       let url = window.URL.createObjectURL(blob);
//       let a = document.createElement("a");
//       a.href = url;
//       a.download = fileName;
//       a.click();
//     });
//   });
// };

// export const arrivalProducerDetailedExportData = () => {
//   return request(`${endpoint}/ext/producerDetailedExportData`, {
//     method: "GET",
//     headers: apiHeaders(),
//   }).then((result) => {
//     result.blob().then((blob) => {
//       const fileName = `${format(new Date(), "yyMMdd")}_PRODUCER_ARRIVAL_DETAIL.xlsx`;
//       let url = window.URL.createObjectURL(blob);
//       let a = document.createElement("a");
//       a.href = url;
//       a.download = fileName;
//       a.click();
//     });
//   });
// };
