import React, { forwardRef, useEffect, useState } from "react";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";

import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import CircularProgress from "@material-ui/core/CircularProgress";

import ReactDatePicker, { registerLocale } from "react-datepicker";
import en from "date-fns/esm/locale/en-GB";
registerLocale("en-GB", en);
import toDate from "date-fns/toDate";
import addDays from "date-fns/addDays";
import isSameMonth from "date-fns/isSameMonth";
import lastDayOfMonth from "date-fns/lastDayOfMonth";
import format from "date-fns/format";
import numeral from "numeral";
import { Field } from "react-final-form";
import { TextField, Select } from "final-form-material-ui";
import BigNumber from "bignumber.js";
import { FormApi } from "final-form";

import { CreditorsInvoiceExtendedType } from "../../lib/api/creditorsinvoice";
import { getCreditorsReadyForCombo, creditors, CreditorsType, getCreditorById } from "../../lib/api/creditors";
import { getCurrencyAllSortedMappedforCombo } from "../../lib/api/currency";
import { isNullOrUndef } from "../../lib/helpers/isNullOrUndef";
import { forDate } from "../../lib/api/exchangerates";
import { getcreditorsPaymentTermsCombo } from "../../lib/api/creditorspaymentterms";
import { getDefaultCreditorsInvoiceDetailItemsTemplate } from "../../lib/api/creditorsinvoicedetailitemstemplate";
import { getCreditorsInvoiceTypeReadyForCombo } from "../../lib/api/creditorsinvoicetype";
import { getFinancialYears } from "../../lib/api/week";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1),
      display: "grid",
      gridTemplateColumns: "max-content max-content",
      gridColumnGap: "50px",
    },
    container: {
      display: "grid",
      gridTemplateColumns: "150px 250px",
      gridGap: "10px",
    },
    tableCellLabel: {
      width: "150px",
      borderBottom: "none",
      height: "50px",
      textAlign: "right",
      paddingTop: "5px",
    },
    tableCellDetail: {
      width: "250px",
      borderBottom: "none",
      height: "50px",
    },
    tableCellDetailDate: {
      marginTop: "-5px",
      width: "150px",
      borderBottom: "none",
      height: "50px",
    },
    tableCellsValueSelect: {
      textAlign: "left",
      width: "250px",
      borderBottom: "none",
      height: "50px",
      padding: 0,
      marginTop: "-14px",
    },
    tableCellFormat: {
      width: "250px",
      borderBottom: "none",
      height: "50px",
      "& input": {
        textAlign: "right",
        paddingRight: "8px",
        paddingLeft: "8px",
      },
    },
  });

type CreditorsInfoHeadProps = { values: any; form: any; handleSetDefaultTemplateItems(data: any): void } & WithStyles<typeof styles>;

const CreditorsInfoHeadUnstyled: React.FunctionComponent<CreditorsInfoHeadProps> = (props) => {
  const { classes, values, form, handleSetDefaultTemplateItems } = props;
  const valuesTyped: CreditorsInvoiceExtendedType = { ...values };

  const [creditorsData, setCreditorsData] = useState([]);
  const [currency, setCurrency] = useState([]);
  const [dateInvoice, setDateInvoice] = useState(isNullOrUndef(valuesTyped && valuesTyped.invoicedate) ? new Date() : new Date(values.invoicedate));
  const [dueDate, setDueDate] = useState(isNullOrUndef(valuesTyped && valuesTyped.due_date) ? new Date() : new Date(values.due_date));
  const [paymentTerms, setPaymentTerms] = useState([]);
  const [consolidationapplyComboData, setConsolidationapplyComboData] = useState([]);
  const [currencyLoading, setCurrencyLoading] = useState(false);
  const [financialYears, setFinancialYears] = useState([]);

  const loadData = async () => {
    const [creditorsResult, currencyResult, paymentTermsResult, years] = await Promise.all([
      getCreditorsReadyForCombo(),
      getCurrencyAllSortedMappedforCombo(),
      getcreditorsPaymentTermsCombo(),
      getFinancialYears(),
    ]);
    setCreditorsData(creditorsResult);
    setCurrency(currencyResult);
    setPaymentTerms(paymentTermsResult);
    setFinancialYears(years.map((item) => ({ display: item.financial_year.toString(), value: item.financial_year.toString() })));

    const resultConsolComboData = getCreditorsInvoiceTypeReadyForCombo();
    setConsolidationapplyComboData(resultConsolComboData);
  };

  useEffect(() => {
    loadData();
  }, [valuesTyped.id]);

  useEffect(() => {
    setDateInvoice(isNullOrUndef(valuesTyped && valuesTyped.invoicedate) ? new Date() : new Date(values.invoicedate));
  }, [valuesTyped.invoicedate]);

  useEffect(() => {
    if (!isNullOrUndef(form.getState().values.creditors_id)) {
      creditors.single(form.getState().values.creditors_id).then((result: CreditorsType) => {
        form.change("payment_term_id", result.payment_term_id);
        handleUpdateDueDate(result.payment_term_id, dateInvoice);
        if (isNullOrUndef(form.getState().values.currency_id)) {
          form.change("currency_id", result.currency_id);
        }
      });
    }
  }, [form.getState().values.creditors_id]);

  useEffect(() => {
    const existCurr = currency.find((curr) => curr.data.id == form.getState().values.currency_id);
    if (existCurr && existCurr.data.code == "ZAR") {
      form.change("roe", 1);
    }
  }, [form.getState().values.currency_id]);

  useEffect(() => {
    const loadCurrency = async () => {
      setCurrencyLoading(true);

      const currencyId = form.getState().values.currency_id;
      if (currencyId && currency.length > 0 && valuesTyped.id == 0) {
        const code = currency.find((c) => c.value === currencyId).display;
        await forDate(format(dateInvoice, "yyyy-MM-dd")).then((data) => {
          const keys = Object.keys(data.rates);
          const rate = numeral(data.rates[keys[0]][code]).format("0,0.000");
          form.change("roe", rate);
        });
      }

      setCurrencyLoading(false);
    };

    loadCurrency();
  }, [dateInvoice, form.getState().values.currency_id]);

  const handleUpdateDueDate = (paymentTerm: any, date: any) => {
    const formattedDate = toDate(date);

    if (paymentTerm && date) {
      if (parseInt(paymentTerm) === 1) {
        // Nett Stat
        const duedate = addDays(formattedDate, 30);
        setDueDate(duedate);
        form.change("due_date", duedate);
      } else if (parseInt(paymentTerm) === 2) {
        // Standard
        const duedate = addDays(formattedDate, 30);
        if (isSameMonth(formattedDate, duedate)) {
          setDueDate(duedate);
          form.change("due_date", duedate);
        } else {
          const lastdayofmonth = lastDayOfMonth(duedate);
          setDueDate(lastdayofmonth);
          form.change("due_date", lastdayofmonth);
        }
      } else if (parseInt(paymentTerm) === 3) {
        // 7 Days
        const duedate = addDays(formattedDate, 7);
        setDueDate(duedate);
        form.change("due_date", duedate);
      } else if (parseInt(paymentTerm) === 4) {
        // 10 days
        const duedate = addDays(formattedDate, 10);
        setDueDate(duedate);
        form.change("due_date", duedate);
      } else {
        setDueDate(formattedDate);
        form.change("due_date", formattedDate);
      }
    } else {
      setDueDate(formattedDate);
      form.change("due_date", formattedDate);
    }
  };

  const changeDateInvoice = (value) => {
    setDateInvoice(value);
    form.change("invoicedate", toDate(value));
    handleUpdateDueDate(form.getState().values.payment_term_id, value);
  };

  const changeDueDate = (value) => {};

  const onParseEmpty = (form, value) => {
    return value;
  };

  const onParseCreditorsChange = (form: FormApi, value: number) => {
    if (valuesTyped && valuesTyped.id == 0) {
      getCreditorById(value).then(([result]) => {
        if (result) {
          form.change("group", result.group);
          form.change("subgroup", result.subgroup);
          // form.change("consolidationapply", result.consolidationapply);
          form.change("currency_id", result.currency_id);
        }
      });
    }
    return value;
  };

  useEffect(() => {
    const totalExcl = (form.getState().values.validate_totalexcl || "0").replace(/[, ]+/g, "");
    const totalVat = (form.getState().values.validate_vat || "0").replace(/[, ]+/g, "");

    const totalVatable = new BigNumber(totalVat).dividedBy(0.15);
    const totalNonVatable = new BigNumber(totalExcl).minus(totalVatable).decimalPlaces(2).toFixed(2);
    const formattedTotalVatable = totalVatable.decimalPlaces(2).toFixed(2);

    const total = new BigNumber(totalExcl).plus(new BigNumber(totalVat)).decimalPlaces(2).toFixed(2);

    form.change("validate_total", numeral(total).format("0,0.00"));
    form.change("validate_vatable", numeral(formattedTotalVatable).format("0,0.00"));
    form.change("validate_non_vatable", numeral(totalNonVatable).format("0,0.00"));
  }, [form.getState().values.validate_totalexcl, form.getState().values.validate_vat]);

  useEffect(() => {
    const creditorsId = form.getState().values.creditors_id;
    if (!valuesTyped || valuesTyped.id == 0) {
      getDefaultCreditorsInvoiceDetailItemsTemplate(creditorsId).then((result) => {
        handleSetDefaultTemplateItems(result);
      });
    }
  }, [form.getState().values.creditors_id]);

  return (
    <div className={classes.root}>
      <div>
        <TableFieldDate classes={classes} dateValue={dateInvoice} title="Invoice Date" changeDate={changeDateInvoice} disabled={false} />
        <TableFieldCombo
          classes={classes}
          form={form}
          field="allocated_year"
          title="Allocated Year"
          data={financialYears}
          addEmptyValue={undefined}
          disabled={false}
          onParse={onParseEmpty}
        />
        <TableFieldCombo
          form={form}
          classes={classes}
          field="creditors_id"
          title="Creditor"
          data={creditorsData}
          addEmptyValue={undefined}
          disabled={false}
          onParse={onParseCreditorsChange}
        />
        <TableFieldCombo form={form} classes={classes} field="currency_id" title="Currency" data={currency} addEmptyValue={undefined} disabled={false} onParse={onParseEmpty} />
        <div style={{ display: "flex", flexDirection: "row", gap: "10px" }}>
          <TableFieldText classes={classes} field="roe" title="ROE" disabled={false} numberFormat />
          {currencyLoading && <CircularProgress size={15} />}
        </div>
        <TableFieldDate classes={classes} dateValue={dueDate} title="Due Date" changeDate={changeDueDate} disabled />
        <TableFieldCombo form={form} classes={classes} field="payment_term_id" title="Payment Term" data={paymentTerms} addEmptyValue={undefined} disabled onParse={onParseEmpty} />
        <TableFieldText classes={classes} field="group" title="Group" disabled />
        <TableFieldText classes={classes} field="subgroup" title="Sub Group" disabled />
        <TableFieldCombo
          form={form}
          classes={classes}
          field="consolidationapply"
          title="Consolidation Apply"
          data={consolidationapplyComboData}
          addEmptyValue={undefined}
          disabled={false}
          onParse={onParseEmpty}
        />
        <TableFieldText classes={classes} field="invoicenumber" title="Invoice Number" disabled={false} />
        <TableFieldText classes={classes} field="validate_totalexcl" title="Total Excl" disabled={false} numberFormat format />
        <TableFieldText classes={classes} field="validate_vat" title="Total Vat" disabled={false} numberFormat format />
        <TableFieldText classes={classes} field="validate_total" title="Total" disabled numberFormat />
        <TableFieldText classes={classes} field="validate_vatable" title="Vatable" disabled numberFormat />
        <TableFieldText classes={classes} field="validate_non_vatable" title="Non Vatable" disabled numberFormat />
      </div>
    </div>
  );
};

export default withStyles(styles)(CreditorsInfoHeadUnstyled);

const TableFieldText: React.FunctionComponent<{ field: string; title: string; disabled: boolean; format?: boolean; numberFormat?: boolean } & WithStyles<typeof styles>> = (
  props,
) => {
  const { classes, field, title, disabled, numberFormat = false, format = false } = props;
  return (
    <div className={classes.container}>
      <span className={classes.tableCellLabel}>{`${title}:`}</span>
      {numberFormat ? (
        <span className={classes.tableCellFormat}>
          <Field name={field} component={TextField} type="text" fullWidth disabled={disabled} format={(value, name) => numeral(value).format("0,0.00")} formatOnBlur={format} />
        </span>
      ) : (
        <span className={classes.tableCellDetail}>
          <Field name={field} component={TextField} type="text" fullWidth disabled={disabled} />
        </span>
      )}
    </div>
  );
};

const CalenderCustomInput = forwardRef((props: any, ref: any) => (
  <Button name="CalenderCustomInput" variant="contained" color="primary" onClick={props.onClick} style={{ marginTop: "5px", width: "250px" }}>
    {props.value}
  </Button>
));

const TableFieldDate: React.FunctionComponent<{ dateValue: Date; title: string; disabled: boolean; changeDate: (value) => void } & WithStyles<typeof styles>> = (props) => {
  const { classes, dateValue, title, changeDate, disabled } = props;
  return (
    <div className={classes.container}>
      <span className={classes.tableCellLabel}>{`${title}:`}</span>
      <span className={classes.tableCellDetailDate}>
        <ReactDatePicker
          locale="en-GB"
          showWeekNumbers
          selected={toDate(dateValue)}
          onChange={(value) => {
            changeDate(value);
          }}
          disabled={disabled}
          dateFormat="dd-MM-yyyy"
          placeholderText="click here to select a date"
          customInput={<CalenderCustomInput />}
        />
      </span>
    </div>
  );
};

const TableFieldCombo: React.FunctionComponent<
  { form: any; classes: any; title: string; field: string; data: any; addEmptyValue: string; disabled: boolean; onParse: (form, value) => any } & WithStyles<typeof styles>
> = (props) => {
  const { form, classes, title, field, data, addEmptyValue, disabled, onParse } = props;
  return (
    <div className={classes.container}>
      <span className={classes.tableCellLabel}>{`${title}:`}</span>
      <span className={classes.tableCellDetail}>
        <Field name={field} component={Select} formControlProps={{ className: classes.tableCellsValueSelect }} disabled={disabled} parse={(value) => onParse(form, value)}>
          {addEmptyValue && (
            <MenuItem key={`emptyvalueMenuItem`} value={-1}>
              {addEmptyValue}
            </MenuItem>
          )}
          {data &&
            data.map((item, index) => {
              return (
                <MenuItem key={`${item.value}${index}`} value={item.value}>
                  {item.display}
                </MenuItem>
              );
            })}
        </Field>
      </span>
    </div>
  );
};
