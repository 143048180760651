import React from "react";

import CustomAutoCompleteFilter from "../../lib/components/customdatagridfilter/CustomFilter";

import DeleteIcon from "@material-ui/icons/Delete";
import FileCopyIcon from "@material-ui/icons/FileCopy";

import { CellActionsFormatter } from "../../lib/helpers/CellActionsFormatter";
import { FormatterNumber2Decimals, FormatterNumber3Decimals } from "../../lib/components/Formatters";
import TextEditor from "../../lib/components/TextEditor";
import DropdownEditor from "../../lib/components/DropdownEditor";
import { SummaryNumber2Decimal, SummaryNumber3Decimal, SummaryTotal } from "../../lib/components/SummaryFormatter";
import { handleColumnsArrangement, handleColumnsWidth } from "../../lib/helpers/grid";
import RightAlignHeaderColumn from "../../lib/components/gridhelper/RightAlignHeaderColumn";

const cellWidthSmall = 65;
const cellWidthMedium = 100;
const cellWidthLarge = 170;

const defaultColumnProperties = {
  filterable: true,
  resizable: true,
  sortable: true,
};

let currentSpace = true;

const VatTypeFormatter = ({ row, column }) => {
  const data = row[column.key];
  return <div style={{ textAlign: "right" }}>{data == -1 ? "-" : data}</div>;
};

export const GridColumns = (data, filters, arrangement, columnsWidth, vattypesComboData, handleRemove, handleCopy) => {
  let postValue = "";

  if (data) {
    if (currentSpace) {
      postValue = " ";
    }
    currentSpace = !currentSpace;
  }

  const columns = [
    {
      key: "actions",
      name: "Actions",
      width: cellWidthMedium,
      formatter: ({ row }) => {
        const actions = cellActions(row, handleRemove, handleCopy);
        return (
          <div style={{ display: "flex", justifyContent: "center", gap: "5px" }}>
            <CellActionsFormatter actions={actions} />
          </div>
        );
      },
      summaryFormatter: SummaryTotal,
    },
    {
      key: "group",
      name: "Group" + postValue,
      width: cellWidthMedium,
      editable: true,
      editor: (props) => <TextEditor {...props} align="right" />,
      formatter: ({ row, column }) => <div style={{ textAlign: "right" }}>{row[column.key]}</div>,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"group"} data={data} section="creditorsinfoitemsfilter" />;
      },
      // editable: true,
      // editor: (props) => <TextEditor {...props} />,
    },
    // {
    //   key: "sequence",
    //   name: "Sequence" + postValue,
    //   width: cellWidthMedium,
    //   editable: true,
    //   filterRenderer: (e) => {
    //     return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"sequence"} data={data} section="creditorsinfoitemsfilter" />;
    //   },
    // },
    {
      key: "code",
      name: "Code" + postValue,
      width: cellWidthLarge,
      editable: true,
      editor: (props) => <TextEditor {...props} />,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"code"} data={data} section="creditorsinfoitemsfilter" />;
      },
    },
    {
      key: "chargedescription",
      name: "Description" + postValue,
      width: cellWidthLarge,
      editable: true,
      editor: (props) => <TextEditor {...props} />,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"chargedescription"} data={data} section="creditorsinfoitemsfilter" />;
      },
    },
    {
      key: "rate",
      name: "Rate" + postValue,
      width: cellWidthMedium,
      editable: true,
      formatter: FormatterNumber3Decimals,
      editor: (props) => <TextEditor {...props} align="right" />,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"rate"} data={data} section="creditorsinfoitemsfilter" />;
      },
      summaryFormatter: SummaryNumber3Decimal,
      headerRenderer: RightAlignHeaderColumn,
    },
    {
      key: "units",
      name: "Units" + postValue,
      width: cellWidthMedium,
      editable: true,
      formatter: FormatterNumber2Decimals,
      editor: (props) => <TextEditor {...props} align="right" />,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"units"} data={data} section="creditorsinfoitemsfilter" />;
      },
      summaryFormatter: SummaryNumber2Decimal,
      headerRenderer: RightAlignHeaderColumn,
    },
    {
      key: "vat",
      name: "Vat" + postValue,
      width: cellWidthMedium,
      editable: true,
      editor: (props) => <DropdownEditor {...props} options={vattypesComboData} />,
      formatter: VatTypeFormatter,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"vat"} data={data} section="creditorsinfoitemsfilter" />;
      },
      headerRenderer: RightAlignHeaderColumn,
    },
    {
      key: "vatamount",
      name: "Vat Amount" + postValue,
      width: cellWidthMedium,
      formatter: FormatterNumber2Decimals,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"vatamount"} data={data} section="creditorsinfoitemsfilter" />;
      },
      summaryFormatter: SummaryNumber2Decimal,
      headerRenderer: RightAlignHeaderColumn,
    },
    {
      key: "totalexclvat",
      name: "Total Ex Vat" + postValue,
      width: cellWidthMedium,
      formatter: FormatterNumber2Decimals,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"totalexclvat"} data={data} section="creditorsinfoitemsfilter" />;
      },
      summaryFormatter: SummaryNumber2Decimal,
      headerRenderer: RightAlignHeaderColumn,
    },
    {
      key: "total",
      name: "Total" + postValue,
      width: cellWidthMedium,
      formatter: FormatterNumber2Decimals,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"total"} data={data} section="creditorsinfoitemsfilter" />;
      },
      summaryFormatter: SummaryNumber2Decimal,
      headerRenderer: RightAlignHeaderColumn,
    },
  ].map((c) => ({ ...c, ...defaultColumnProperties }));

  let columnsArranged = [];
  columnsArranged = handleColumnsArrangement(columnsArranged, arrangement, columns);
  columnsArranged = handleColumnsWidth(columnsArranged, columnsWidth, columns);

  return columnsArranged;
};

const cellActions = (row, handleRemove, handleCopy) => {
  return [
    {
      icon: (
        <span title="Copy">
          <FileCopyIcon style={{ cursor: "pointer", color: "#4a9de2" }} />
        </span>
      ),
      callback: (e) => {
        handleCopy(row);
      },
    },
    {
      icon: (
        <span title="Remove">
          <DeleteIcon style={{ cursor: "pointer", color: "red" }} />
        </span>
      ),
      callback: (e) => {
        handleRemove(row.id);
      },
    },
  ];
};
