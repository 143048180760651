import React from "react";

import Typography from "@material-ui/core/Typography";

import { Route } from "react-router-dom";

import AdvanceContracts from "../maintenance/advancecontracts/advancecontract";
import Agent from "../maintenance/agent/agent";
import Brands from "../maintenance/brands/brands";
import BarcodeSplit from "../maintenance/barcodesplit/barcodesplit";
import Classes from "../maintenance/classes/classes";
import Clients from "../maintenance/clients/clients";
import ClientsContracts from "../maintenance/clientscontracts/clientscontracts";
import ClientBillTypes from "../maintenance/clientbilltypes/clientbilltypes";
import Consignee from "../maintenance/consignee/consignee";
import ClearingAgent from "../maintenance/clearingagent/clearingagent";
import Coldrooms from "../maintenance/coldrooms/coldrooms";
import Commodities from "../maintenance/commodities/commodities";
import CompanyDetails from "../maintenance/companydetails/companydetails";
// import Costest from "../maintenance/costest/costest";
import CostEstimationCountries from "../maintenance/costestimation/costestimation";
import Counts from "../maintenance/counts/counts";
import Creditors from "../maintenance/creditors/creditors";
import CreditorsImportTemplateSummary from "../maintenance/creditors/importtemplate/templatesummary";
import CreditorsTemplateSummary from "../maintenance/creditors/templatesummary/creditorstemplatesummary";
import Currency from "../maintenance/currency/currency";
import CreditorsGroup from "../maintenance/creditorsgroup/creditorsgroup";
import DispatchMerge from "../maintenance/dispatchmerge/dispatchmerge";
import EDIFileComparison from "../maintenance/edifilecomparison/edifilecomparison";
import EstimatedCost from "../maintenance/estimatedcost/estimatedcost";
import EstimatedCostItem from "../maintenance/estimatedcostitem/estimatedcostitem";
import EstimatedCostItemGroups from "../maintenance/estimatedcostitemgroups/estimatedcostitemgroups";
import Exchange from "../maintenance/exchange/exchange";
import Inventory from "../maintenance/inventory/inventory";
import LabTests from "../maintenance/labtests/labtests";
import LabTestsType from "../maintenance/labteststype/labtesttype";
import Mgps from "../maintenance/mgps/mgps";
import Pack from "../maintenance/pack/pack";
import PalletBase from "../maintenance/palletbase/palletbase";
import PHTreatment from "../maintenance/phtreatments/phtreatments";
import PHLabel from "../maintenance/phlabels/phlabels";
import Port from "../maintenance/port/port";
import Producers from "../maintenance/producers/producers";
import PIOrder from "../maintenance/orders/orders";
import ProducerPHType from "../maintenance/producersphtype/producerphtype";
import Qclabelreq from "../maintenance/qclabelreq/qclabelreq";
import SpecialMarket from "../maintenance/specialmarkets/specialmarkets";
import ShippingLine from "../maintenance/shippingline/shippingline";
import SellingTerms from "../maintenance/sellingterms/index";
import Template from "../maintenance/templates/templates";
import TemperatureCodes from "../maintenance/temperaturecodes/temperaturecodes";
import Targets from "../maintenance/targets/targets";
import Terms from "../maintenance/terms/terms";
import UCR from "../maintenance/ucrsetup/ucr";
import Varieties from "../maintenance/varieties/varieties";
import Vessel from "../maintenance/vessel/vessel";
import Weeks from "../maintenance/weeks/weeks";

import IconNaturePeople from "@material-ui/icons/NaturePeople";
import IconWidgets from "@material-ui/icons/Widgets";
import IconHowToReg from "@material-ui/icons/Assignment";
import IconAttachMoney from "@material-ui/icons/AttachMoney";
import IconLoadout from "@material-ui/icons/DirectionsBoat";
import EDI from "@material-ui/icons/ImportExport";
import IconMaintenance from "@material-ui/icons/Settings";
import IconPeople from "@material-ui/icons/People";
import IconAccountBalance from "@material-ui/icons/AccountBalance";
import IconMail from "@material-ui/icons/Mail";
import IconMoneyOff from "@material-ui/icons/MoneyOff";
import IconFinance from "@material-ui/icons/MonetizationOn";

import StockAdjustment from "../maintenance/stockadjustment/stockadjustment";

import { IconOrder, IconDispatch, IconBoat } from "./CustomIcons";

export const NavigationList = [
  { name: "Pack Instructions", path: "orders", icon: IconOrder, closeDrawer: true, forceOpen: false },
  { name: "QC Inspections", path: "inspection", icon: IconHowToReg, closeDrawer: false, forceOpen: false },
  { name: "Stock", path: "", icon: IconWidgets, closeDrawer: false, forceOpen: true },
  { name: "Dispatches", path: "dispatch", icon: IconDispatch, closeDrawer: true, forceOpen: false },
  { name: "Shipments", path: "shipment", icon: IconLoadout, closeDrawer: true, forceOpen: false },
  { name: "Debtors", path: "", icon: IconAccountBalance, closeDrawer: false, forceOpen: true },
  { name: "Creditor Invoices", path: "creditors/summary", icon: IconMoneyOff, closeDrawer: false, forceOpen: false },
  { name: "Producers", path: "", icon: IconNaturePeople, closeDrawer: false, forceOpen: true },
  { name: "Arrivals", path: "arrival", icon: IconBoat, closeDrawer: false, forceOpen: false },
  { name: "EDI", path: "", icon: EDI, closeDrawer: false, forceOpen: true },
  { name: "Finance", path: "", icon: IconFinance, closeDrawer: false, forceOpen: true },
  { name: "Maintenance", path: "", icon: IconMaintenance, closeDrawer: false, forceOpen: true },
  { name: "User Management", path: "users", icon: IconPeople, closeDrawer: false, forceOpen: false },
  { name: "Notifications", path: "notifications", icon: IconMail, closeDrawer: false, forceOpen: false },
];

export const SubNavigationList = [
  { name: "Financial Overview", section: "finance", path: "finance/finance", closeDrawer: true },
  { name: "Financial Consolidation", section: "finance", path: "finance/consolidation", closeDrawer: true },
  { name: "EDI Enquiry", section: "edi", path: "edi/fileImports", closeDrawer: true },
  { name: "EDI Template", section: "edi", path: "edi/template", closeDrawer: true },
  { name: "EDI Search", section: "edi", path: "edi/search", closeDrawer: true },
  { name: "Summary", section: "debtors", path: "debtors/summary", closeDrawer: true },
  { name: "Proforma Invoices", section: "debtors", path: "invoiceproforma", closeDrawer: true },
  { name: "Commercial Invoices", section: "debtors", path: "invoice", closeDrawer: true },
  { name: "Debit & Credit Notes", section: "debtors", path: "debtors/full", closeDrawer: true },
  { name: "Incoming Funds", section: "debtors", path: "debtors/fundssummary", closeDrawer: true },
  { name: "PI Price Integration", section: "debtors", path: "invoicing", closeDrawer: true },
  // { name: "Adhoc Client Invoice", section: "debtors", path: "adhocinvoice", closeDrawer: true },
  { name: "Fixed Producer Invoices", section: "producers", path: "producers/invoice/summary", closeDrawer: true },
  { name: "Producers Debits & Credits", section: "producers", path: "producers/adjustments/summary", closeDrawer: true },
  { name: "Producer Payments", section: "producers", path: "producers/groupedonly", closeDrawer: true },
  { name: "Stock Comparison", section: "stock", path: "stockcomparison", closeDrawer: true },
  { name: "Stock Allocation", section: "stock", path: "stockallocation", closeDrawer: true },
  { name: "Stock Pricing", section: "stock", path: "stockpricing", closeDrawer: true },
];

const MAINTENANCE = "/maintenance";
export const MaintenanceList = [
  { name: "Barcode Split", path: "barcodesplit", section: "root" },
  { name: "EDIFileComparison", path: "edifilecomparison", section: "root" },
  { name: "Forwarding Agent", path: "Forwarding Agent", section: "root" },
  { name: "Company Details", path: "CompanyDetails", section: "root" },
  { name: "orders", path: "orders", section: "root" },
  { name: "Stock Adjustment", path: "Stock Adjustment", section: "root" },
  { name: "targets", path: "targets", section: "root" },
  { name: "templates", path: "templates", section: "root" },
  { name: "UCR Setup", path: "UCR Setup", section: "root" },
  { name: "Dispatch Merge", path: "Dispatch Merge", section: "root" },

  { name: "Brands (Mark)", path: "brands", section: "master listing" },
  { name: "Classes (Grades)", path: "classes", section: "master listing" },
  { name: "commodities", path: "commodities", section: "master listing" },
  { name: "counts", path: "counts", section: "master listing" },
  { name: "Locations / Coldrooms", path: "coldrooms", section: "master listing" },
  { name: "Pallet Bases", path: "palletbases", section: "master listing" },
  { name: "Ports", path: "port", section: "master listing" },
  { name: "Shipping Lines", path: "shippinglines", section: "master listing" },
  { name: "Temperatures", path: "temperatures", section: "master listing" },
  { name: "varieties", path: "varieties", section: "master listing" },
  { name: "Vessels", path: "vessel", section: "master listing" },

  { name: "clients", path: "clients", section: "client" },
  { name: "clientsContracts", path: "clientscontracts", section: "client" },
  { name: "consignee", path: "consignee", section: "client" },
  { name: "clearingAgent", path: "clearingAgent", section: "client" },
  { name: "Payment Terms", path: "Payment Terms", section: "client" },
  { name: "bill types", path: "bill types", section: "client" },
  { name: "Selling Terms", path: "Selling Terms", section: "client" },

  { name: "EstimatedCost", path: "estimatedcost", section: "estimated cost" },
  { name: "EstimatedCostItems", path: "estimatedcostitems", section: "estimated cost" },
  { name: "EstimatedCostGroups", path: "estimatedcostgroups", section: "estimated cost" },
  { name: "CostCountries", path: "costcountries", section: "estimated cost" },

  { name: "creditors", path: "creditors", section: "creditors" },
  { name: "Creditors Invoice Template", path: "Creditors Invoice Template", section: "creditors" },
  { name: "creditors Groups", path: "creditors Groups", section: "creditors" },
  { name: "CreditorsImportTemplate", path: "CreditorsImportTemplate", section: "creditors" },

  { name: "Lab Tests", path: "Lab Tests", section: "quality control" },
  { name: "Label Requirements", path: "Label Requirements", section: "quality control" },
  { name: "PH Label", path: "PH Label", section: "quality control" },
  { name: "PH Treatment", path: "PH Treatment", section: "quality control" },
  { name: "Special Market", path: "Special Market", section: "quality control" },

  { name: "Lab Tests Type", path: "Lab Tests Type", section: "vault" },
  { name: "Producer PH Type", path: "Producer PH Type", section: "vault" },

  { name: "invoice", path: "invoice", section: "invoice" },

  { name: "producers", path: "producers", section: "producer" },
  { name: "AdvanceContracts", path: "AdvanceContracts", section: "producer" },
  { name: "Terms", path: "Terms", section: "producer" },

  { name: "currency", path: "currency", section: "company details" },
  { name: "inventory", path: "inventory", section: "company details" },
  { name: "MGPS", path: "mgps", section: "company details" },
  { name: "Pack", path: "pack", section: "company details" },
  { name: "Rates of Exchange", path: "exchange", section: "company details" },
  { name: "weeks", path: "weeks", section: "company details" },
];

const MaintenanceContent = () => (
  <>
    <Route key={`maintenance_route`} exact={true} path={MAINTENANCE} render={() => <Typography variant="h3" color="inherit" />} />

    {MaintenanceList.map((item) => {
      if ((item.path || "").replace(/\s/g, "").toLowerCase() === "edifilecomparison") {
        return <Route key={`${item.path}_route`} exact={true} path={`${MAINTENANCE}/edifilecomparison/:id?`} component={(props) => <EDIFileComparison {...props} />} />;
      }

      return (
        <Route
          key={`${item.path}_route`}
          path={`${MAINTENANCE}/${item.path}`}
          render={() => {
            switch ((item.path || "").toString().replaceAll(" ", "").toLowerCase()) {
              case "advancecontracts": {
                return <AdvanceContracts handleUpdateProducer={() => {}} />;
              }
              case "barcodesplit": {
                return <BarcodeSplit />;
              }
              case "dispatchmerge": {
                return <DispatchMerge />;
              }
              case "brands": {
                return <Brands />;
              }
              case "classes": {
                return <Classes />;
              }
              case "clients": {
                return <Clients />;
              }
              case "clientscontracts": {
                return <ClientsContracts />;
              }
              case "consignee": {
                return <Consignee />;
              }
              case "clearingagent": {
                return <ClearingAgent />;
              }
              case "commodities": {
                return <Commodities />;
              }
              case "companydetails": {
                return <CompanyDetails />;
              }
              case "counts": {
                return <Counts />;
              }
              case "creditors": {
                return <Creditors />;
              }
              case "creditorsinvoicetemplate": {
                return <CreditorsTemplateSummary />;
              }
              case "creditorsimporttemplate": {
                return <CreditorsImportTemplateSummary />;
              }
              case "creditorsgroups": {
                return <CreditorsGroup />;
              }
              case "currency": {
                return <Currency />;
              }
              case "costcountries": {
                return <CostEstimationCountries />;
              }
              case "estimatedcost": {
                return <EstimatedCost />;
              }
              case "estimatedcostitems": {
                return <EstimatedCostItem />;
              }
              case "estimatedcostgroups": {
                return <EstimatedCostItemGroups />;
              }
              case "exchange": {
                return <Exchange />;
              }
              case "forwardingagent": {
                return <Agent />;
              }
              case "inventory": {
                return <Inventory />;
              }
              case "labelrequirements": {
                return <Qclabelreq />;
              }
              case "coldrooms": {
                return <Coldrooms />;
              }
              case "labtests": {
                return <LabTests />;
              }
              case "labteststype": {
                return <LabTestsType />;
              }
              case "mgps": {
                return <Mgps />;
              }
              case "orders": {
                return <PIOrder />;
              }
              case "phlabel": {
                return <PHLabel />;
              }
              case "palletbases": {
                return <PalletBase />;
              }
              case "pack": {
                return <Pack />;
              }
              case "phtreatment": {
                return <PHTreatment />;
              }
              case "port": {
                return <Port />;
              }
              case "producers": {
                return <Producers />;
              }
              case "producerphtype": {
                return <ProducerPHType />;
              }
              case "sellingterms": {
                return <SellingTerms />;
              }
              case "specialmarket": {
                return <SpecialMarket />;
              }
              case "shippinglines": {
                return <ShippingLine />;
              }
              case "stockadjustment": {
                return <StockAdjustment />;
              }
              case "targets": {
                return <Targets />;
              }
              case "templates": {
                return <Template maintenancemode={true} />;
              }
              case "temperatures": {
                return <TemperatureCodes />;
              }
              case "paymentterms": {
                return <Terms termstype={0} />;
              }
              case "billtypes": {
                return <ClientBillTypes />;
              }
              case "terms": {
                return <Terms termstype={1} />;
              }
              case "ucrsetup": {
                return <UCR />;
              }
              case "varieties": {
                return <Varieties />;
              }
              case "vessel": {
                return <Vessel />;
              }
              case "weeks": {
                return <Weeks />;
              }
              default: {
                return <CompanyDetails />;
              }
            }
          }}
        />
      );
    })}
  </>
);

export default MaintenanceContent;
