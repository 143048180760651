export const CreditorsInvoiceType = {
  1: "CARTON",
  2: "PALLET",
  3: "CONTAINER",
};

export const getCreditorsInvoiceTypeReadyForCombo = () => {
  const result = [];
  Object.keys(CreditorsInvoiceType).map((dt) => {
    result.push({ value: dt, display: CreditorsInvoiceType[dt] });
  });
  return result;
};
