import React from "react";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";

import Grid from "../../lib/components/grid";
import Confirmation from "../../lib/components/confirmation";

import { phtreatment } from "../../lib/api/phtreatment";
import { GridColumns } from "./phtreatmentsgridsetup";

import Tooltip from "@material-ui/core/Tooltip";
import Button from "@material-ui/core/Button";

import IconAdd from "@material-ui/icons/Add";

import PHTreatmentsEditForm from "./phtreatmentseditform";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1),
      width: "100%",
      height: "100%",
      position: "relative",
    },
    inline: {
      display: "inline",
      position: "absolute",
      marginTop: "7px",
      marginLeft: "7px",
    },
  });

type PHTreatmentsProps = {} & WithStyles<typeof styles>;

export class PHTreatmentsUnstyled extends React.Component<PHTreatmentsProps, any> {
  state = {
    classes: undefined,
    data: [],
    confirmRemoveID: undefined,
    confirmEditID: undefined,
    loading: true,
  };

  constructor(props) {
    super(props);
    this.state.classes = props.classes;
  }

  componentDidMount() {
    this.loadData();
  }

  loadData = () => {
    phtreatment.all().then((data) => {
      const dataSorted = data.sort((a, b) => {
        if (a.commoditycode > b.commoditycode) return 1;
        if (a.commoditycode < b.commoditycode) return -1;
        return 1;
      });
      this.setState({ loading: false, data: dataSorted });
    });
  };

  handleCreate = () => {
    this.setState({ confirmEditID: "0" });
  };

  handleEdit = (id) => {
    this.setState({ confirmEditID: id });
  };

  handleEditClose = () => {
    this.setState({ confirmEditID: undefined });
  };

  handleEditConfirm = (e, item) => {
    e.preventDefault();

    const id = item["id"];
    delete item["id"];

    if (id == "0") {
      phtreatment.create({ data: { ...item } }).then(() => this.loadData());
    } else {
      phtreatment.update(id, { data: { ...item } }).then(() => this.loadData());
    }
    this.handleEditClose();
  };

  handleRemove = async (id) => {
    this.setState({ confirmRemoveID: id });
  };

  handleRemoveClose = () => {
    this.setState({ confirmRemoveID: undefined });
  };

  handleRemoveConfirm = async () => {
    await phtreatment.remove(this.state.confirmRemoveID).then(() => {
      this.handleRemoveClose();
      this.loadData();
    });
  };

  GridColumnsOverride = (column, row) => {
    return GridColumns(column, row, this.handleRemove, this.handleEdit);
  };

  render() {
    const { classes } = this.state;

    return (
      <div className={classes.root}>
        <div>
          <div className={classes.inline}>
            <Tooltip title="Create new PHTreatment">
              <div className={classes.inline}>
                <Button variant="contained" color="primary" onClick={this.handleCreate} style={{ marginBottom: "10px" }}>
                  <IconAdd />
                </Button>
              </div>
            </Tooltip>
          </div>
          <Grid loading={this.state.loading} data={this.state.data} GridColumns={this.GridColumnsOverride} clearFilters={"phtreatmentsgridsetup"} />
          {this.state.confirmRemoveID && (
            <Confirmation
              isOpen={this.state.confirmRemoveID ? true : false}
              handleClose={this.handleRemoveClose}
              handleConfirm={this.handleRemoveConfirm}
              title="Remove selected PHTreatment?"
              body="Are you sure you want to REMOVE the selected PHTreatment Record?"
            ></Confirmation>
          )}
          {this.state.confirmEditID && (
            <Confirmation
              isOpen={this.state.confirmEditID ? true : false}
              handleClose={this.handleEditClose}
              handleConfirm={() => {}}
              title={this.state.confirmEditID == "0" ? "Creating PHTreatment" : "Editing PHTreatment"}
              body={undefined}
            >
              <PHTreatmentsEditForm onSubmit={this.handleEditConfirm} onClose={this.handleEditClose} id={this.state.confirmEditID} />
            </Confirmation>
          )}
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(PHTreatmentsUnstyled);
