import React from "react";
import { NavLink } from "react-router-dom";

import CustomAutoCompleteFilter from "../../../lib/components/customdatagridfilter/CustomFilter";
import { DateFormatter, FormatterNumber, FormatterNumber2Decimals, FormatterRecon, TextFormatter } from "../../../lib/components/Formatters";
import { SummaryNumber, SummaryNumber2Decimal, SummaryTotal } from "../../../lib/components/SummaryFormatter";
import { handleColumnsArrangement, handleColumnsWidth } from "../../../lib/helpers/grid";

const cellWidthActionSmall = 30;
const cellWidthAction = 50;
const cellWidthMedium = 120;
const cellWidthLarge = 200;

const defaultColumnProperties = {
  resizable: true,
  sortable: true,
};

let currentSpace = true;

export const GridColumns = (data, filters, arrangement, columnsWidth) => {
  let postValue = "";

  if (data) {
    if (currentSpace) {
      postValue = " ";
    }
    currentSpace = !currentSpace;
  }

  const columns = [
    {
      key: "producer_id",
      name: "Producer ID" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"producer_id"} data={data} section="produceradvanceinfo" />;
      },
      summaryFormatter: SummaryTotal,
    },
    {
      key: "producer_name",
      name: "Producer Name" + postValue,
      width: cellWidthLarge,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"producer_name"} data={data} section="produceradvanceinfo" />;
      },
    },
    {
      key: "dispatch_currencycode",
      name: "Currency" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"dispatch_currencycode"} data={data} section="produceradvanceinfo" />;
      },
    },
    {
      key: "wkcolddue_week",
      name: "Cold Due Week" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"wkcolddue_week"} data={data} section="produceradvanceinfo" />;
      },
    },
    {
      key: "wkintakedue_week",
      name: "Intake Due Week" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"wkintakedue_week"} data={data} section="produceradvanceinfo" />;
      },
    },
    {
      key: "wkshippingdue_week",
      name: "Shipping Due Week" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"wkshippingdue_week"} data={data} section="produceradvanceinfo" />;
      },
    },
    {
      key: "bar_PalletSize",
      name: "Total Pallets" + postValue,
      width: cellWidthMedium,
      filterable: true,
      formatter: FormatterNumber2Decimals,
      summaryFormatter: SummaryNumber2Decimal,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"bar_PalletSize"} data={data} section="produceradvanceinfo" />;
      },
    },
    {
      key: "bar_NoCartons",
      name: "Total Cartons" + postValue,
      width: cellWidthMedium,
      filterable: true,
      formatter: FormatterNumber,
      summaryFormatter: SummaryNumber,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"bar_NoCartons"} data={data} section="produceradvanceinfo" />;
      },
    },
    {
      key: "paid_currency",
      name: "Paid CURR" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"paid_currency"} data={data} section="produceradvanceinfo" />;
      },
    },
    {
      key: "sum_paidcurrencyValue",
      name: "Total Value" + postValue,
      width: cellWidthMedium,
      filterable: true,
      formatter: FormatterNumber2Decimals,
      summaryFormatter: SummaryNumber2Decimal,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"sum_paidcurrencyValue"} data={data} section="produceradvanceinfo" />;
      },
    },
    {
      key: "adhocdetail_remainingamount",
      name: "AD-HOC +/-" + postValue,
      width: cellWidthMedium,
      filterable: true,
      formatter: FormatterNumber2Decimals,
      summaryFormatter: SummaryNumber2Decimal,
      filterRenderer: (e) => {
        return (
          <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"adhocdetail_remainingamount"} data={data} section="produceradvanceinfo" />
        );
      },
    },
    {
      key: "sum_paidcurrencyValueTotal",
      name: "Paid Total" + postValue,
      width: cellWidthMedium,
      filterable: true,
      formatter: FormatterNumber2Decimals,
      summaryFormatter: SummaryNumber2Decimal,
      filterRenderer: (e) => {
        return (
          <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"sum_paidcurrencyValueTotal"} data={data} section="produceradvanceinfo" />
        );
      },
    },
    {
      key: "produceradvancepaidhead_ident",
      name: "Payment Ident" + postValue,
      width: cellWidthMedium,
      filterable: true,
      formatter: FormatterRecon,
      filterRenderer: (e) => {
        return (
          <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"produceradvancepaidhead_ident"} data={data} section="produceradvanceinfo" />
        );
      },
    },
    {
      key: "produceradvancepaidhead_makeanote",
      name: "Payment Note" + postValue,
      width: cellWidthLarge,
      filterable: true,
      filterRenderer: (e) => {
        return (
          <CustomAutoCompleteFilter
            {...e}
            multiSelection={true}
            currentFilters={filters}
            fieldname={"produceradvancepaidhead_makeanote"}
            data={data}
            section="produceradvanceinfo"
          />
        );
      },
    },
    {
      key: "produceradvancepaidhead_posteddate",
      name: "Posted Date" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return (
          <CustomAutoCompleteFilter
            {...e}
            multiSelection={true}
            currentFilters={filters}
            fieldname={"produceradvancepaidhead_posteddate"}
            data={data}
            section="produceradvanceinfo"
          />
        );
      },
      formatter: DateFormatter,
    },
    {
      key: "paphweek_week",
      name: "Posted Week" + postValue,
      width: cellWidthLarge,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"paphweek_week"} data={data} section="produceradvanceinfo" />;
      },
    },
  ].map((c) => ({ ...c, ...defaultColumnProperties }));

  let columnsArranged = [];
  columnsArranged = handleColumnsArrangement(columnsArranged, arrangement, columns);
  columnsArranged = handleColumnsWidth(columnsArranged, columnsWidth, columns);

  return columnsArranged;
};
