import React from "react";
import { NavLink } from "react-router-dom";

import CustomAutoCompleteFilter from "../../../lib/components/customdatagridfilter/CustomFilter";
import { FormatterNumber, FormatterNumber2Decimals, TextFormatter } from "../../../lib/components/Formatters";
import RightAlignHeaderColumn from "../../../lib/components/gridhelper/RightAlignHeaderColumn";
import { SummaryNumber2Decimal, SummaryTotal } from "../../../lib/components/SummaryFormatter";
import { handleColumnsArrangement, handleColumnsWidth } from "../../../lib/helpers/grid";

const cellWidthActionSmall = 30;
const cellWidthAction = 50;
const cellWidthMedium = 120;
const cellWidthLarge = 200;

const defaultColumnProperties = {
  resizable: true,
  sortable: true,
};

let currentSpace = true;

export const ForeignCostInfoGridColumns = (data, filters, arrangement, columnsWidth) => {
  let postValue = "";

  if (data) {
    if (currentSpace) {
      postValue = " ";
    }
    currentSpace = !currentSpace;
  }

  const columns = [
    {
      key: "creditors_code",
      name: "Creditor" + postValue,
      width: cellWidthMedium,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"creditors_code"} data={data} section="foreigncostinfo" />;
      },
      filterable: true,
      summaryFormatter: SummaryTotal,
    },
    {
      key: "invoicenumber",
      name: "Invoice Number" + postValue,
      width: cellWidthMedium,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"invoicenumber"} data={data} section="foreigncostinfo" />;
      },
      formatter: (props) => {
        const { row } = props;
        if (row.id && row.id > 0) {
          return (
            <NavLink to={`/creditors/info/${row.id}`} target={"_blank"}>
              <TextFormatter {...props} />
            </NavLink>
          );
        }
        else {
          return <TextFormatter {...props} />
        }
      },
      filterable: true,
    },
    {
      key: "items_cost",
      name: "Cost" + postValue,
      width: cellWidthMedium,
      formatter: FormatterNumber2Decimals,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"items_cost"} data={data} section="foreigncostinfo" />;
      },
      filterable: true,
      summaryFormatter: SummaryNumber2Decimal,
      headerRenderer: RightAlignHeaderColumn
    },
    {
      key: "pallets",
      name: "Pallet Size" + postValue,
      width: cellWidthMedium,
      formatter: FormatterNumber,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"items_cartons"} data={data} section="foreigncostinfo" />;
      },
      filterable: true,
      summaryFormatter: SummaryNumber2Decimal,
      headerRenderer: RightAlignHeaderColumn
    },
    {
      key: "items_cartons",
      name: "Cartons" + postValue,
      width: cellWidthMedium,
      formatter: FormatterNumber,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"items_cartons"} data={data} section="foreigncostinfo" />;
      },
      filterable: true,
      summaryFormatter: SummaryNumber2Decimal,
      headerRenderer: RightAlignHeaderColumn
    },
    {
      key: "cost_ctn",
      name: "COST/CTN" + postValue,
      width: cellWidthMedium,
      formatter: FormatterNumber2Decimals,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"cost_ctn"} data={data} section="foreigncostinfo" />;
      },
      filterable: true,
      summaryFormatter: SummaryNumber2Decimal,
      headerRenderer: RightAlignHeaderColumn
    },
    {
      key: "cost_plt",
      name: "COST/PLT" + postValue,
      width: cellWidthMedium,
      formatter: FormatterNumber2Decimals,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"cost_plt"} data={data} section="foreigncostinfo" />;
      },
      filterable: true,
      summaryFormatter: SummaryNumber2Decimal,
      headerRenderer: RightAlignHeaderColumn
    },
  ].map((c) => ({ ...c, ...defaultColumnProperties }));

  let columnsArranged = [];
  columnsArranged = handleColumnsArrangement(columnsArranged, arrangement, columns);
  columnsArranged = handleColumnsWidth(columnsArranged, columnsWidth, columns);

  return columnsArranged;
};
