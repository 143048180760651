import React, { createContext, useState, useMemo } from "react";
import { whoami } from "../api/_base";

import { config } from "../../config";

type typeWSS = {
  wss: any;
  message: string;
  notificationCount: number;
};

const defaultWSS = {
  wss: undefined,
  message: "",
  notificationCount: 0,
};

type Loading = {
  loading: boolean;
  loaded: boolean;
  error?: Error;
  updated_at?: number;
};

export type WSSContextState = {
  wss: typeWSS;
  update(wss: Partial<typeWSS>);
  loading: Loading;
  hydrate();
};

export const WSSContext = createContext<WSSContextState>({
  wss: defaultWSS,
  loading: { loading: false, loaded: false, error: null, updated_at: undefined },
  hydrate: () => {},
  update: () => {},
});

export const WSSProvider = ({ children }) => {
  const [wss, setWSS] = useState<typeWSS>(defaultWSS);
  const [loading, setLoading] = useState<Loading>({ loading: false, loaded: false, error: null, updated_at: undefined });
  const value = useMemo(
    () => ({
      wss,
      loading,
      hydrate: async () => {
        try {
          setLoading({
            loading: true,
            loaded: false,
            error: null,
          });
          connectWSS(setWSS, wss);
          setLoading({
            loading: false,
            loaded: true,
            error: null,
          });
        } catch (e) {
          setLoading({
            loading: false,
            loaded: false,
            error: e,
          });
        }
      },
      update: async (wss) => {
        console.log("WSSContext-update");
        try {
          setLoading({
            loading: false,
            loaded: true,
            updated_at: Date.now(),
          });
        } catch (e) {
          setLoading({
            loading: false,
            loaded: false,
            error: e,
          });
        }
      },
      setWSS,
    }),
    [wss, loading],
  );

  return <WSSContext.Provider value={value}>{children}</WSSContext.Provider>;
};

const connectWSS = (setWSS, wss) => {
  const URL = `${config.API_ADDRESS.replace("http", "ws")}/api/`; // `ws://vulcan.test/api/`
  return whoami().then((user) => {
    console.log("connectWSS-user->", user);
    const ws = new WebSocket(`${URL}${user.username}`);
    ws.onopen = () => {
      // on connecting, do nothing but log it to the console
      console.log("WSS Provider-connected to WS");
    };
    ws.onmessage = (evt) => {
      // on receiving a message, add it to the list of messages
      const message = JSON.parse(evt.data);
      console.log("WSS Provider-message received->", message);
      setWSS({ ...wss, message: message[0], notificationCount: message[1] });
    };
    ws.onclose = () => {
      console.log("WSS Provider-disconnected");
      alert("Messaging has been disconnected. Please log Off to reconnect.");
      setWSS({ ...wss, message: undefined });
      // setWSS({ ...wss, wss: undefined });
      // ws = new WebSocket(`${URL}${user.username}`);
      // automatically try to reconnect on connection loss
    };
    setWSS({ wss: ws, message: "", notificationCount: 0 });
    return ws;
  });
};
