import React, { useEffect, useMemo, useState } from "react";
import { getStickyFilters } from "../helpers/stickyfilters";

type FilterValue = {
  label: string;
  value: string;
  sortOrder: number;
};

type Options = {
  section: string;
  activeColumn: string;
};

type FiltersType = {
  openFilters: string;
  options: Options;
  filterOptions: any[];
  selectedFilters: { [section: string]: FilterValue[] };
  localFilters: { [section: string]: FilterValue[] };
  reload: boolean;
  toggleFilters(value: string): void;
  setFilterOptions(filters: any[]): void;
  setSelectedFilters(filters: any): void;
  setOptions(options: Options): void;
  setLocalFilters(values: any): void;
  setReload(value: boolean): void;
};

export const FiltersContext = React.createContext<FiltersType>({
  options: { activeColumn: undefined, section: undefined },
  openFilters: "",
  filterOptions: [],
  selectedFilters: {},
  localFilters: {},
  reload: false,
  toggleFilters: () => {},
  setFilterOptions: () => {},
  setSelectedFilters: () => {},
  setOptions: () => {},
  setLocalFilters: () => {},
  setReload: () => {},
});

export const FiltersProvider = ({ children, history }) => {
  const [openFilters, toggleFilters] = useState<string>("");
  const [options, setOptions] = useState<Options>({ activeColumn: undefined, section: undefined });

  const [filterOptions, setFilterOptions] = useState([]);

  const [selectedFilters, setSelectedFilters] = useState<{ [section: string]: FilterValue[] }>({});
  const [localFilters, setLocalFilters] = useState<{ [section: string]: FilterValue[] }>({});

  const [reload, setReload] = useState<boolean>(false);

  useEffect(() => {
    let obj = {};
    const localStorageFilterKeys = Object.keys(localStorage).filter((item) => item.includes("stickyfilters_") && !item.includes("stickyfilters_general"));

    localStorageFilterKeys.forEach((key) => {
      const section = key.replace("stickyfilters_", "");
      const filters = getStickyFilters(section);

      if (filters) {
        filters.forEach((filter) => {
          const field = filter.field;
          obj[`${section}_${field}`] = filter.value;
        });
      }
    });

    setLocalFilters(obj);
    setSelectedFilters({ ...obj });
  }, [history.location.pathname, reload]);

  const value = useMemo(
    () => ({
      openFilters,
      toggleFilters,
      filterOptions,
      setFilterOptions,
      selectedFilters,
      setSelectedFilters,
      options,
      setOptions,
      localFilters,
      setLocalFilters,
      reload,
      setReload,
    }),
    [openFilters, filterOptions, selectedFilters, options, localFilters, reload],
  );

  return <FiltersContext.Provider value={value}>{children}</FiltersContext.Provider>;
};
