import React from "react";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";

import { Row } from "react-data-grid";
import classNames from "classnames";

import Grid from "../../lib/components/grid";
import { DialogInformation } from "../../lib/components/dialoginformation";
import Confirmation from "../../lib/components/confirmation";
import { DebtorsFullType, getDebtorsFullByFinancialYear } from "../../lib/api/debtorsfull";
import { saleadjustment, upsertSaleAdjustment } from "../../lib/api/saleadjustment";
import { getSaleAdjustmentBySaleIdType } from "../../lib/api/saleadjustmentdetail";
import { getFinancialYearSelected } from "../../lib/api/week";
import { GridColumns } from "./debtorsgridsetup";
import { GridColumns as AdhocGridColumns } from "./adhocdetailgridsetup";
import Adjustment from "./adjustment/adjustment";
import { DocumentsButton, DocumentsType } from "../../lib/components/DocumentsButton";
import { Reports } from "../../lib/types";
import { handlePrintDebitCreditNote, handlePrintDebtorsAdhocAdjustment } from "../../reports/printing";
import { SnackContext } from "../../lib/context/SnackContext";
import { GenerateErrorMessage } from "../../lib/helpers/string_methods";
import { OrangeButton } from "../../lib/components/ColorButtons";
import { getSaleAdjustmentAdhocFull, saleadjustmentadhoc } from "../../lib/api/saleadjustmentadhoc";

import Toolbar from "@material-ui/core/Toolbar";
import LinearProgress from "@material-ui/core/LinearProgress";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

const a11yProps = (index: number) => {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
};

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1),
      // minWidth: "100px",
      height: "calc(100vh - 100px)",
      width: "100%",
      position: "relative",
    },
    inline: {
      display: "inline",
      position: "absolute",
      marginTop: "7px",
      marginLeft: "7px",
    },
    orangeRow: {
      color: "orange",
    },
    greenRow: {
      color: "green",
    },
    redRow: {
      color: "red",
    },
    gridWrapper: {
      position: "relative",
    },
    toolbar: {
      position: "absolute",
      left: 0,
      top: 0,
      height: "60px",
      width: "100%",
      display: "flex",
      flexDirection: "row",
    },
    boldRow: {
      fontWeight: "bold",
    },
    normalRow: {
      fontWeight: "normal",
    },
  });

type DebtorsFullProps = {
  history: any;
} & WithStyles<typeof styles>;

export class DebtorsFullUnstyled extends React.Component<DebtorsFullProps, any> {
  state = {
    classes: undefined,
    data: [],
    loading: true,
    message: undefined,
    selectedRecord: undefined,
    selectedRemove: undefined,
    rowSelected: undefined,
    printing: false,
    adhocDetail: [],
    tabValue: 0,
    confirmRemoveAdhoc: undefined,
    selectedAdhoc: undefined,
    printAdhocLoading: false,
  };

  constructor(props) {
    super(props);
    this.state.classes = props.classes;
  }

  static contextType = SnackContext;
  context!: React.ContextType<typeof SnackContext>;

  componentDidMount() {
    this.loadData();
  }

  loadData = async () => {
    this.setState({ loading: true });
    const [result, adhocResult] = await Promise.all([getDebtorsFullByFinancialYear(getFinancialYearSelected()), getSaleAdjustmentAdhocFull()]);
    if (result) {
      const dataSorted = result.sort((a, b) => a.sale_invoicenumber.localeCompare(b.sale_invoicenumber) || a.document_type.localeCompare(b.document_type));
      this.setState({ data: dataSorted });
    }
    this.setState({ adhocDetail: adhocResult, loading: false });
  };

  handleAdjust = (selectedRecord) => {
    const parentRow = this.state.data.find((row) => row.sale_id === selectedRecord.sale_id && row.document_type === "1");
    this.setState({
      selectedRecord: {
        ...selectedRecord,
        sale_ucr: parentRow.sale_ucr,
        clients_code: parentRow.clients_code,
        sale_ata: parentRow.sale_ata,
      },
    });
  };

  handleRefresh = async () => {
    this.setState({ rowSelected: undefined });
    await this.loadData();
  };

  handleAdjustProcess = async (data, detail) => {
    // if user presses exit button on form, close form
    if (!data && !detail) {
      this.setState({ selectedRecord: undefined });
      // this.handleRefresh(); // refresh is not needed as nothing changed.
      return;
    }

    try {
      let proportionalAmount = undefined;

      if (data.saleadjustment_typeapply == 1) {
        const result = detail.reduce((prev, current) => {
          if (!current) return 0;
          return prev + current.barcode_cartons;
        }, 0);
        proportionalAmount = data.saleadjustment_amount / result;
      }

      const saleAdjustmentDetail = detail.map((ditem: getSaleAdjustmentBySaleIdType) => ({
        id: ditem.saleadjustmentdetail_id,
        saledetail_id: ditem.salesdetail_id,
        cartons: proportionalAmount ? ditem.barcode_cartons : ditem.saleadjustmentdetail_cartons,
        amount: proportionalAmount ? proportionalAmount : ditem.saleadjustmentdetail_amount,
      }));

      await upsertSaleAdjustment({
        saleAdjustment: {
          id: data.dispatchdocs_id == -99 ? data.adjustment_id : null,
          created_at: data.dispatchdocs_id == -99 ? data.sale_created_at : new Date(), // manually set created_at date, because server gives time 2 hours ahead
          updated_at: new Date(),
          sale_id: data.sale_id,
          adjustdate: new Date(data.sale_date),
          type: data.saleadjustment_type,
          typeapply: data.saleadjustment_typeapply,
          status: 0,
          is_claim: data.is_claim,
        },
        sale: {
          sale_id: data.sale_id,
          accsalereceived: data.accsalereceived,
        },
        saleAdjustmentDetail,
      });

      this.setState({ selectedRecord: undefined });
      this.handleRefresh();
    } catch (error) {
      const err = GenerateErrorMessage(error, "Error creating Sale Adjustment");
      this.context.updateSnack({ show: true, color: "red", message: err });
    }
  };

  handleRemoveAdjustment = (row: DebtorsFullType) => {
    this.setState({ selectedRemove: row });
  };

  handleRemoveAdjustmentConfirm = async (row: DebtorsFullType) => {
    this.setState({ selectedRemove: undefined });
    if (row) {
      await saleadjustment.remove(row.sale_dispatch_id);
      this.loadData();
    }
  };

  GridColumnsOverride = (data, filters, columnArrangement, columnsWidth) => {
    return GridColumns(data, filters, columnArrangement, columnsWidth, this.handleAdjust, this.handleRemoveAdjustment);
  };

  handleInformationClose = () => {
    this.setState({ message: undefined });
  };

  handleDoubleClick = (row) => {
    if (this.state.rowSelected && row.sale_dispatch_id === this.state.rowSelected.sale_dispatch_id) {
      this.setState({ rowSelected: undefined });
    } else {
      this.setState({ rowSelected: row });
    }
  };

  rowRenderer = (props, classes) => {
    const { row } = props;
    const color = row.is_claim == 1 ? classes.orangeRow : row.document_type == "3" ? classes.redRow : row.document_type == "2" && classes.greenRow;
    const bold =
      this.state.rowSelected && this.state.rowSelected.sale_id === row.sale_id && this.state.rowSelected.sale_dispatch_id === row.sale_dispatch_id
        ? classes.boldRow
        : classes.normalRow;

    return <Row {...props} onDoubleClickCapture={() => (row.document_type > 1 ? this.handleDoubleClick(row) : {})} className={classNames(color, bold)} />;
  };

  handlePrint = async (reportType: Reports) => {
    this.setState({ printing: true });

    if (
      [
        Reports.CreditNote,
        Reports.DebitNote,
        Reports.CreditNoteConsignee,
        Reports.DebitNoteConsignee,
        Reports.CreditNoteClientAndConsignee,
        Reports.DebitNoteClientAndConsignee,
      ].includes(reportType)
    ) {
      await handlePrintDebitCreditNote(this.state.rowSelected, reportType).catch((error) => {
        const err = GenerateErrorMessage(error, "Error printing Invoice");
        this.context.updateSnack({ show: true, color: "red", message: err });
      });
    } else {
      // reportType, dispatch_id, sale_id, dispatchdocs_id, sale_dispatch_id
      const dispatch_id = this.state.rowSelected.saleadjustment_dispatch_id,
        sale_id = this.state.rowSelected.sale_id,
        dispatchdocs_id = this.state.rowSelected.dispatchdocs_id,
        sale_dispatch_id = this.state.rowSelected.sale_dispatch_id;

      window.location.href = `/api/sale/ext/exportCreditDebitInvoice?reportType=${reportType}&dispatch_id=${dispatch_id}&sale_id=${sale_id}&dispatchdocs_id=${dispatchdocs_id}&sale_dispatch_id=${sale_dispatch_id}`;
    }

    this.setState({ printing: false });
  };

  getMenuItems = () => {
    return [
      {
        title: "CREDIT NOTE",
        disabled: this.state.rowSelected && (this.state.rowSelected.sale_type == 0 || !this.state.rowSelected.saleadjustment_ident),
        children: [
          {
            icon: DocumentsType.PDF,
            title: "CLIENT",
            options: [
              {
                title: "CLIENT INVOICE PDF",
                icon: DocumentsType.PDF,
                action: () => this.handlePrint(Reports.CreditNote),
              },
              {
                title: "CLIENT INVOICE EXCEL",
                icon: DocumentsType.EXCEL,
                action: () => this.handlePrint(Reports.CreditNoteExcel),
              },
            ],
          },
          {
            icon: DocumentsType.PDF,
            title: "CONSIGNEE",
            options: [
              {
                title: "CONSIGNEE INVOICE PDF",
                icon: DocumentsType.PDF,
                action: () => this.handlePrint(Reports.CreditNoteConsignee),
              },
              {
                title: "CONSIGNEE INVOICE EXCEL",
                icon: DocumentsType.EXCEL,
                action: () => this.handlePrint(Reports.CreditNoteConsigneeExcel),
              },
            ],
          },
          {
            icon: DocumentsType.PDF,
            title: "CLIENT & CONSIGNEE",
            options: [
              {
                title: "CLIENT & CONSIGNEE INVOICE PDF",
                icon: DocumentsType.PDF,
                action: () => this.handlePrint(Reports.CreditNoteClientAndConsignee),
              },
              {
                title: "CLIENT & CONSIGNEE INVOICE EXCEL",
                icon: DocumentsType.EXCEL,
                action: () => this.handlePrint(Reports.CreditNoteClientAndConsigneeExcel),
              },
            ],
          },
        ],
      },
      {
        title: "DEBIT NOTE",
        disabled: this.state.rowSelected && (this.state.rowSelected.sale_type == 1 || !this.state.rowSelected.saleadjustment_ident),
        children: [
          {
            icon: DocumentsType.PDF,
            title: "CLIENT",
            options: [
              {
                title: "CLIENT PACKING LIST PDF",
                icon: DocumentsType.PDF,
                action: () => this.handlePrint(Reports.DebitNote),
              },
              {
                title: "CLIENT PACKING LIST EXCEL",
                icon: DocumentsType.EXCEL,
                action: () => this.handlePrint(Reports.DebitNoteExcel),
              },
            ],
          },
          {
            icon: DocumentsType.PDF,
            title: "CONSIGNEE",
            options: [
              {
                title: "CONSIGNEE PACKING LIST PDF",
                icon: DocumentsType.PDF,
                action: () => this.handlePrint(Reports.DebitNoteConsignee),
              },
              {
                title: "CONSIGNEE PACKING LIST EXCEL",
                icon: DocumentsType.EXCEL,
                action: () => this.handlePrint(Reports.DebitNoteConsigneeExcel),
              },
            ],
          },
          {
            icon: DocumentsType.PDF,
            title: "CLIENT & CONSIGNEE",
            options: [
              {
                title: "CLIENT & CONSIGNEE INVOICE PDF",
                icon: DocumentsType.PDF,
                action: () => this.handlePrint(Reports.DebitNoteClientAndConsignee),
              },
              {
                title: "CLIENT & CONSIGNEE INVOICE EXCEL",
                icon: DocumentsType.EXCEL,
                action: () => this.handlePrint(Reports.DebitNoteClientAndConsigneeExcel),
              },
            ],
          },
        ],
      },
    ];
  };

  handleCreateAdhoc = (edit: number) => {
    this.props.history.push(`/debtors/adhoc/${edit}`);
  };

  handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    this.setState({ tabValue: newValue });
  };

  handleRemoveAdhoc = (saleadjustmentadhoc_id: number) => {
    this.setState({ confirmRemoveAdhoc: saleadjustmentadhoc_id });
  };

  handleConfirmRemoveAdhoc = async (adhoc_id: number) => {
    this.setState({ confirmRemoveAdhoc: undefined });

    if (adhoc_id) {
      try {
        await saleadjustmentadhoc.remove(adhoc_id);
        await this.loadData();
      } catch (error) {
        const err = GenerateErrorMessage(error, "Error removing Adhoc");
        this.context.updateSnack({ show: true, color: "red", message: err });
      }
    }
  };

  handleSelectAdhocRow = (selectedAdhoc: any) => {
    if (this.state.selectedAdhoc == selectedAdhoc) {
      this.setState({ selectedAdhoc: undefined });
    } else {
      this.setState({ selectedAdhoc });
    }
  };

  handleAdhocRowRenderer = (props, classes) => {
    const { row } = props;
    const rowStyle = this.state.selectedAdhoc && row.saleadjustmentadhoc_id == this.state.selectedAdhoc.saleadjustmentadhoc_id ? classes.boldRow : classes.normalRow;
    return <Row {...props} className={rowStyle} onDoubleClick={() => this.handleSelectAdhocRow(row)} />;
  };

  handlePrintAdhoc = async () => {
    this.setState({ printAdhocLoading: true });
    try {
      await handlePrintDebtorsAdhocAdjustment(this.state.selectedAdhoc);
    } catch (error) {
      const err = GenerateErrorMessage(error, "Error printing Adhoc document");
      this.context.updateSnack({ show: true, color: "red", message: err });
    }
    this.setState({ printAdhocLoading: false });
  };

  adhocDocumentItems = [
    {
      title: "ADHOC",
      options: [
        {
          title: "ADHOC PDF",
          icon: DocumentsType.PDF,
          action: () => this.handlePrintAdhoc(),
        },
        // {
        //   title: "ADHOC EXCEL",
        //   icon: DocumentsType.EXCEL,
        //   action: () => this.handlePrintAdhoc(),
        // },
      ],
    },
  ];

  render() {
    const { classes } = this.state;

    return (
      <div className={classes.root}>
        {this.state.printing && <LinearProgress color="secondary" />}
        {!this.state.selectedRecord && (
          <div>
            <AppBar position="static">
              <Tabs value={this.state.tabValue} onChange={this.handleTabChange} aria-label="debtors funds and adhoc">
                <Tab label="Full" {...a11yProps(0)} />
                <Tab label="Adhoc" {...a11yProps(1)} />
              </Tabs>
            </AppBar>
            <TabPanel value={this.state.tabValue} index={0}>
              <div className={classes.gridWrapper}>
                <Toolbar className={classes.toolbar}>
                  <DocumentsButton menuItems={this.getMenuItems()} disabled={!this.state.rowSelected} />
                </Toolbar>
                <Grid
                  loading={this.state.loading}
                  data={this.state.data}
                  GridColumns={this.GridColumnsOverride}
                  handleRefresh={this.handleRefresh}
                  forceHeight={800}
                  rowRenderer={(props) => this.rowRenderer(props, classes)}
                  clearFilters={"debtorsfull"}
                  showFilterChips={true}
                  totalRowColumns={[
                    "sale_total",
                    "amountCredit",
                    "amountDebit",
                    "sale_totalfinal",
                    "salefundsallocation_amount",
                    "sale_payment1amount",
                    "sale_payment2amount",
                    "sale_payment3amount",
                    "total_outstanding",
                  ]}
                />
              </div>
            </TabPanel>
            <TabPanel value={this.state.tabValue} index={1}>
              {this.state.printAdhocLoading && <LinearProgress color="secondary" />}
              {this.state.confirmRemoveAdhoc && (
                <Confirmation
                  isOpen={true}
                  handleClose={() => this.handleConfirmRemoveAdhoc(undefined)}
                  handleConfirm={() => this.handleConfirmRemoveAdhoc(this.state.confirmRemoveAdhoc)}
                  title={"Confirm delete"}
                  body={"Are you sure you want to remove this?"}
                />
              )}
              <div className={classes.gridWrapper}>
                <Toolbar className={classes.toolbar}>
                  <OrangeButton variant="contained" type="button" onClick={() => this.handleCreateAdhoc(0)}>
                    Create Adhoc
                  </OrangeButton>
                  <DocumentsButton menuItems={this.adhocDocumentItems} disabled={!this.state.selectedAdhoc} />
                </Toolbar>
                <Grid
                  loading={this.state.loading}
                  data={this.state.adhocDetail}
                  totalRowColumns={["amount", "available"]}
                  showFilterChips={true}
                  clearFilters={"debtorsfulladhoc"}
                  rowRenderer={(props) => this.handleAdhocRowRenderer(props, classes)}
                  GridColumns={(data, filters, arrangement, width) => AdhocGridColumns(data, filters, arrangement, width, this.handleCreateAdhoc, this.handleRemoveAdhoc)}
                />
              </div>
            </TabPanel>
          </div>
        )}
        {this.state.selectedRecord && <Adjustment selectedRecord={this.state.selectedRecord} handleProcess={this.handleAdjustProcess} />}
        {this.state.message && (
          <DialogInformation
            isOpen={true}
            handleClose={this.handleInformationClose}
            handleOK={this.handleInformationClose}
            title={"Feedback"}
            body={`${this.state.message}`}
            showinput={false}
          />
        )}
        {this.state.selectedRemove && (
          <Confirmation
            isOpen={true}
            handleClose={() => this.handleRemoveAdjustmentConfirm(undefined)}
            handleConfirm={() => this.handleRemoveAdjustmentConfirm(this.state.selectedRemove)}
            title={"Remove Selected"}
            body={`Are you sure you want to remove selected Adjustment?`}
          />
        )}
      </div>
    );
  }
}

export default withStyles(styles)(DebtorsFullUnstyled);
