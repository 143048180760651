import { apiHeaders, client, request } from "./_base";

export type ProducerFinalPaidAdHocType = {
  id?: number;
  producer_id: number;
  week_id: number;
  currency_id: number;
  paidamount: number;
  note: string;
  created_at?: Date;
};

const endpoint = "producerfinalpaidadhoc";

export const producerfinalpaidadhoc = client(endpoint, (item) => ({
  ...item,
}));

export const producerfinalpaidadhocGetAdHocRemainingValue = async (producer_id) =>
  request(`${endpoint}/ext/GetAdHocRemainingValue/${producer_id}`, {
    headers: apiHeaders(),
    method: "GET",
  })
    .then(async (result) => {
      if (result.status == 500) {
        const resp = await result.json();
        throw resp;
      }
      return result.json();
    })
    .then((result) => {
      return result.data;
    });