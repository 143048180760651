import React from "react";

import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import CheckIcon from "@material-ui/icons/Check";
import CrossIcon from "@material-ui/icons/Close";

import CustomAutoCompleteFilter from "../../lib/components/customdatagridfilter/CustomFilter";
import { CellActionsFormatter } from "../../lib/helpers/CellActionsFormatter";

const cellWidthTiny = 40;
const cellWidthSmall = 80;
const cellWidthMedium = 200;

const defaultColumnProperties = {
  resizable: true,
  sortable: true,
};

let currentSpace = true;
export const GridColumns = (data, filters, handleRemove, handleEdit) => {
  let postValue = "";

  if (data) {
    if (currentSpace) {
      postValue = " ";
    }
    currentSpace = !currentSpace;
  }

  return [
    {
      key: "actions",
      name: "Actions",
      width: cellWidthSmall,
      formatter: ({ row }) => {
        const actions = cellActions(row, handleRemove, handleEdit);
        return (
          <div style={{ display: "flex", justifyContent: "center", gap: "5px" }}>
            <CellActionsFormatter actions={actions} />
          </div>
        );
      },
    },
    {
      key: "active",
      name: "Active" + postValue,
      width: cellWidthTiny,
      filterable: true,
      formatter: ({ row }) => (
        <div style={{ display: "flex", justifyContent: "center", height: "100%" }}>
          {row.active ? <CheckIcon style={{ color: "green" }} /> : <CrossIcon style={{ color: "red" }} />}
        </div>
      ),
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"active"} data={data} section={"coldroomsgridsetup"} />;
      },
    },
    {
      key: "code",
      name: "Code" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"code"} data={data} section={"coldroomsgridsetup"} />;
      },
    },
    {
      key: "location_id",
      name: "Location ID" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"location_id"} data={data} section={"coldroomsgridsetup"} />;
      },
    },
    {
      key: "name",
      name: "Name" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"name"} data={data} section={"coldroomsgridsetup"} />;
      },
    },
    {
      key: "port",
      name: "Port" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"port"} data={data} section={"coldroomsgridsetup"} />;
      },
    },
    {
      key: "address1",
      name: "Address1" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"address1"} data={data} section={"coldroomsgridsetup"} />;
      },
    },
    {
      key: "address2",
      name: "Address2" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"address2"} data={data} section={"coldroomsgridsetup"} />;
      },
    },
    {
      key: "address3",
      name: "Address3" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"address3"} data={data} section={"coldroomsgridsetup"} />;
      },
    },
    {
      key: "address4",
      name: "Address4" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"address4"} data={data} section={"coldroomsgridsetup"} />;
      },
    },
    {
      key: "address5",
      name: "Address5" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"address5"} data={data} section={"coldroomsgridsetup"} />;
      },
    },
    {
      key: "telephoneNumber",
      name: "Telephone" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"telephoneNumber"} data={data} section={"coldroomsgridsetup"} />;
      },
    },
    {
      key: "email",
      name: "Email" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"email"} data={data} section={"coldroomsgridsetup"} />;
      },
    },
  ].map((c) => ({ ...c, ...defaultColumnProperties }));
};

const cellActions = (order, handleRemove, handleEdit) => {
  return [
    {
      icon: (
        <span title="Edit">
          <EditIcon style={{ cursor: "pointer", color: "orange" }} />
        </span>
      ),
      callback: (e) => {
        handleEdit(order.id);
      },
    },
    {
      icon: (
        <span title="Remove">
          <DeleteIcon style={{ cursor: "pointer", color: "red" }} />
        </span>
      ),
      callback: (e) => {
        handleRemove(order.id);
      },
    },
  ];
};
