import React from "react";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";

import Check from "@material-ui/icons/Check";
import Close from "@material-ui/icons/Close";

import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";

import { inspectionlabelrequirements } from "../../lib/api/inspectionlabelrequirement";

import { target } from "../../lib/api/target";

import { Field, Form } from "react-final-form";
import { Select, Checkbox } from "final-form-material-ui";

import { MaterialCheckBoxComponent } from "../../lib/helpers/materialcomponents";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1),
      marginLeft: "10px",
      height: "270px",
      width: "270px",
    },
    tableRowFieldTitle: {
      paddingTop: theme.spacing(1),
      paddingRight: theme.spacing(1) * 2,
      marginTop: theme.spacing(1),
      textAlign: "right",
      minWidth: "150px",
    },
    tableRowFieldData: {
      textAlign: "left",
      width: "100px",
      paddingBottom: "5px",
    },
    tableRowFieldDataCheck: {
      textAlign: "left",
      width: "100px",
      paddingTop: "5px",
      // padding: 0,
      // marginTop: "5px",
      // marginLeft: "-18px",
    },
  });

type qcLabelReqFormProps = {
  onSubmit: any;
  onClose: any;
  id: any;
} & WithStyles<typeof styles>;

class qcLabelReqFormUnstyled extends React.Component<qcLabelReqFormProps, any> {
  state = {
    classes: undefined,
    item: { id: "0", market: "", country: "", requiredconsignee: false, requiredcountry: false },
    onSubmit: undefined,
    onClose: undefined,
    id: 0,
    targets: [],
    targetsMarkets: [],
    targetsCounties: [],
  };

  constructor(props) {
    super(props);
    this.state.classes = props.classes;
    this.state.onSubmit = props.onSubmit;
    this.state.onClose = props.onClose;
    this.state.id = props.id;
  }

  componentDidMount() {
    this.loadData();
    if (this.state.id != 0) {
      this.getItem(this.state.id);
    }
  }

  loadData = () => {
    target.all().then((targets) => {
      const reduceTM = targets
        .reduce((unique, item) => (unique.includes(item.market) ? unique : [...unique, item.market]), [])
        .sort((a, b) => {
          if (a > b) return 1;
          if (a < b) return -1;
          return 1;
        });
      const tm = reduceTM.map((tmitem) => {
        return { value: tmitem, display: tmitem };
      });
      this.setState({ targets: targets, targetsMarkets: tm }, () => {});
    });
  };

  getItem = (id) => {
    inspectionlabelrequirements.single(id).then((data) => {
      this.setState({ item: data }, () => {
        this.handleMarketSelect(data.market);
      });
    });
  };

  handleFocus = (event) => {
    event.target.select();
  };

  handleSubmitMain = (e) => {
    this.state.onSubmit(e);
  };

  handleMarketSelect = (market) => {
    const reduceTC = this.state.targets
      .filter((item) => {
        if (item.market == market) {
          return item;
        }
      })
      .reduce((unique, item) => (unique.includes(item.country) ? unique : [...unique, item.country]), [])
      .sort((a, b) => {
        if (a > b) return 1;
        if (a < b) return -1;
        return 1;
      });
    const tc = reduceTC.map((tcitem) => {
      return { value: tcitem, display: tcitem };
    });
    this.setState({ targetsCounties: tc });
  };

  render() {
    const { classes } = this.state;

    return (
      <div>
        <div className={classes.root}>
          <Form
            initialValues={{ ...this.state.item }}
            onSubmit={this.handleSubmitMain}
            render={({ handleSubmit, form, submitting, pristine, values }) => (
              <form onSubmit={handleSubmit}>
                <table>
                  <tbody>
                    <TableFieldCombo classes={classes} field="market" title="Market" combodata={this.state.targetsMarkets} />
                    <TableFieldCombo classes={classes} field="country" title="Country" combodata={this.state.targetsCounties} />
                    <TableFieldCheckbox classes={classes} field="requiredconsignee" title="Req Consignee" />
                    <TableFieldCheckbox classes={classes} field="requiredcountry" title="Req Country" />
                  </tbody>
                </table>
                <div style={{ display: "flex", justifyContent: "flex-end", margin: "20px 0px -20px 0" }}>
                  <Button style={{ marginRight: "10px" }} color="secondary" variant="outlined" onClick={this.state.onClose}>
                    <Close />
                  </Button>
                  <Button type="submit" color="primary" variant="outlined">
                    <Check />
                  </Button>
                </div>
              </form>
            )}
          />
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(qcLabelReqFormUnstyled);

export const TableFieldCombo: React.FunctionComponent<{ classes: any; field: string; title: string; combodata: any } & WithStyles<typeof styles>> = (props) => {
  const { classes, title, field, combodata } = props;

  return (
    <tr>
      <td className={classes.tableRowFieldTitle}>
        <span>{`${title}: `}</span>
      </td>
      <td>
        <Field name={field} component={Select} formControlProps={{ className: classes.tableRowFieldData }}>
          {combodata.map((item, idx) => {
            return (
              <MenuItem key={`${idx}-${item.value}`} value={item.value}>
                {item.display}
              </MenuItem>
            );
          })}
        </Field>
      </td>
    </tr>
  );
};

export const TableFieldCheckbox: React.FunctionComponent<{ field: string; title: string } & WithStyles<typeof styles>> = (props) => {
  const { classes, title, field } = props;
  return (
    <tr>
      <td className={classes.tableRowFieldTitle}>
        <span>{`${title}: `}</span>
      </td>
      <td>
        <Field name={field} component={Checkbox} type="checkbox" style={{ marginLeft: "-13px", marginTop: "5px" }} />
      </td>
    </tr>
  );
};

//
