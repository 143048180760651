import { apiHeaders, client, request } from "./_base";

import { ProducerAdvancePaidType } from "./produceradvancepaid";
import { ProducerAdvancePaidAdHocDetailType } from "./produceradvancepaidadhocdetail";
import { ProducerAdvancePaidHeadType } from "./produceradvancepaidhead";

export type ProducerAdvancePaidAdHocType = {
  id?: number;
  producer_id?: number;
  week_id?: number;
  currency_id?: number;
  paidamount: number;
  note?: string;
  created_at?: Date;
};

const endpoint = "produceradvancepaidadhoc";
export const produceradvancepaidadhoc = client(endpoint, (item) => ({
  ...item,
}));

export type ProducerAdvancePaidAdHocGetAdHocRemainingType = {
  adhoc_id: number;
  producer_id: number;
  week_id: number;
  currency_id: number;
  paidamount: number;
  note: string;
  created_at?: Date;
  amount: number;
  remaining: number;
  week: string;
  adhocdetail_id: number;
  produceradvancepaidhead_id: number;
  currency_code: string;
  produceradvancepaidhead_makeanote: string;
  produceradvancepaidhead_ident: string;
};

export const produceradvancepaidadhocGetAdHocRemainingValue = async (producer_id: number, currency_id: number): Promise<ProducerAdvancePaidAdHocGetAdHocRemainingType[]> => {
  const result = await request(`${endpoint}/ext/GetAdHocRemainingValue?producer_id=${encodeURIComponent(producer_id)}&currency_id=${encodeURIComponent(currency_id)}`, {
    headers: apiHeaders(),
    method: "GET",
  });
  const resp = await result.json();

  if (result.status == 500) {
    throw new Error(resp);
  }

  return resp.data;
};

export const processAdhocDetail = async (
  paidHead: ProducerAdvancePaidHeadType,
  adhocRemainingDetail: ProducerAdvancePaidAdHocDetailType[],
  advancePaidDetail: ProducerAdvancePaidType[],
): Promise<void> => {
  const content = JSON.stringify({ paidHead, adhocRemainingDetail, advancePaidDetail });
  const result = await request(`${endpoint}/ext/processAdhocDetail`, {
    method: "POST",
    headers: apiHeaders(content),
    body: content,
  });
  if (result.status == 500) {
    const resp = await result.json();
    throw new Error(resp);
  }
};
