import { client, request } from "./_base";

const endpoint = "advancecontractscommodities";

export const advanceContractscommodities = client(endpoint, (item) => ({
  ...item,
}));

export const advancecontractscommoditiesFull = (advancecontractsid) => {
  return request(`${endpoint}/ext/full/${advancecontractsid}`, {
    method: "GET",
  })
    .then(async (result) => {
      if (result.status == 500) {
        const resp = await result.json();
        throw resp;
      }
      return result.json();
    })
    .then(({ data }) => {
      return data[0];
    });
};
