import React from "react";

import DeleteIcon from "@material-ui/icons/Delete";
import IconEdit from "@material-ui/icons/Edit";

import CustomAutoCompleteFilter from "../../lib/components/customdatagridfilter/CustomFilter";
import { DebtorsFullType } from "../../lib/api/debtorsfull";
import { CellActionsFormatter } from "../../lib/helpers/CellActionsFormatter";
import { DateFormatter, FormatterNumber2Decimals, RightAlignColumnHeader } from "../../lib/components/Formatters";
import { EnquiryLink, Selector } from "../../lib/components/EnquiryLink";
import { handleColumnsArrangement, handleColumnsWidth } from "../../lib/helpers/grid";
import { SummaryNumber2Decimal } from "../../lib/components/SummaryFormatter";
import { Triangle } from "./triangle";
import IconBrightness from "@material-ui/icons/Brightness1";
import Tooltip from "@material-ui/core/Tooltip";

const cellWidthTiny = 40;
const cellWidthSmall = 80;
const cellWidthMedium = 120;
const cellWidthLarge = 200;

const defaultColumnProperties = {
  resizable: true,
  sortable: true,
};

let currentSpace = true;
export const GridColumns = (data, filters, arrangement = undefined, columnsWidth = undefined, handleAdjust, handleRemove) => {
  let postValue = "";

  if (data) {
    if (currentSpace) {
      postValue = " ";
    }
    currentSpace = !currentSpace;
  }

  const ActionsFormatter = ({ row }) => {
    const actions = cellActions(row, handleAdjust, handleRemove);

    return (
      <div style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "5px", marginTop: "2px" }}>
        <CellActionsFormatter actions={actions} />
      </div>
    );
  };

  const columns = [
    {
      key: "status",
      name: "#A" + postValue,
      width: cellWidthTiny,
      formatter: ActionsFormatter,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"status"} data={data} section="debtorsfull" />;
      },
      filterable: true,
    },
    {
      key: "sale_typename",
      name: "Sale Type" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"sale_typename"} data={data} section="debtorsfull" />;
      },
    },
    {
      key: "sale_date",
      name: "Date" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"sale_date"} data={data} section="debtorsfull" />;
      },
      formatter: DateFormatter,
    },
    {
      key: "sale_invoicenumber",
      name: "Invoice Number" + postValue,
      width: cellWidthMedium,
      filterable: true,
      formatter: (props) => <EnquiryLink {...props} selector={Selector.Barcode} />,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"sale_invoicenumber"} data={data} section="debtorsfull" />;
      },
    },
    {
      key: "saleadjustment_ident",
      name: "Credit/Debit Note No" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"saleadjustment_ident"} data={data} section="debtorsfull" />;
      },
    },
    {
      key: "clients_code",
      name: "Client Code" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"clients_code"} data={data} section="debtorsfull" />;
      },
    },
    {
      key: "clients_name",
      name: "Client Name" + postValue,
      width: cellWidthLarge,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"clients_name"} data={data} section="debtorsfull" />;
      },
    },
    {
      key: "vessel_description",
      name: "Vessel" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"vessel_description"} data={data} section="debtorsfull" />;
      },
    },
    {
      key: "dispatch_containerno",
      name: "Container No" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"dispatch_containerno"} data={data} section="debtorsfull" />;
      },
    },
    {
      key: "loadout_etd",
      name: "ETD" + postValue,
      width: cellWidthMedium,
      formatter: DateFormatter,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"loadout_etd"} data={data} section="debtorsfull" />;
      },
      filterable: true,
    },
    {
      key: "updated_eta",
      name: "Updated ETA" + postValue,
      width: cellWidthMedium,
      formatter: DateFormatter,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"updated_eta"} data={data} section="debtorsfull" />;
      },
      filterable: true,
    },
    {
      key: "currency_code",
      name: "Currency" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"currency"} data={data} section="debtorsfull" />;
      },
    },
    {
      key: "sale_total",
      name: "Sale Total" + postValue,
      width: cellWidthSmall,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"sale_total"} data={data} section="debtorsfull" />;
      },
      formatter: FormatterNumber2Decimals,
      summaryFormatter: SummaryNumber2Decimal,
      headerRenderer: RightAlignColumnHeader,
    },
    {
      key: "amountDebit",
      name: "Debit" + postValue,
      width: cellWidthSmall,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"amountDebit"} data={data} section="debtorsfull" />;
      },
      formatter: FormatterNumber2Decimals,
      summaryFormatter: SummaryNumber2Decimal,
      headerRenderer: RightAlignColumnHeader,
    },
    {
      key: "amountCredit",
      name: "Credit" + postValue,
      width: cellWidthSmall,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"amountCredit"} data={data} section="debtorsfull" />;
      },
      formatter: FormatterNumber2Decimals,
      summaryFormatter: SummaryNumber2Decimal,
      headerRenderer: RightAlignColumnHeader,
    },
    {
      key: "sale_totalfinal",
      name: "Nett Total" + postValue,
      width: cellWidthSmall,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"sale_totalfinal"} data={data} section="debtorsfull" />;
      },
      formatter: FormatterNumber2Decimals,
      summaryFormatter: SummaryNumber2Decimal,
      headerRenderer: RightAlignColumnHeader,
    },
    {
      key: "adjustment_user",
      name: "User" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"adjustment_user"} data={data} section="debtorsfull" />;
      },
    },
    {
      key: "sale_created_at",
      name: "Created" + postValue,
      width: 150,
      filterable: true,
      formatter: ({ row, column }) => <DateFormatter row={row} column={column} includeTime />,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"sale_created_at"} data={data} section="debtorsfull" />;
      },
    },
    {
      key: "sale_updated_at",
      name: "Updated" + postValue,
      width: 150,
      filterable: true,
      formatter: ({ row, column }) => <DateFormatter row={row} column={column} includeTime />,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"sale_updated_at"} data={data} section="debtorsfull" />;
      },
    },
  ].map((c) => ({ ...c, ...defaultColumnProperties }));

  let columnsArranged = [];
  columnsArranged = handleColumnsArrangement(columnsArranged, arrangement, columns);
  columnsArranged = handleColumnsWidth(columnsArranged, columnsWidth, columns);
  return columnsArranged;
};

const cellActions = (row: DebtorsFullType, handleAdjust, handleRemove) => {
  const actions = [];
  if (row.camend_status != 0) {
    actions.push({
      icon: (
        <div title="Processed">
          <Tooltip title="Processed">
            <IconBrightness style={{ color: "red", display: "block", margin: "auto", border: "1px solid #333", borderRadius: "100%" }} />
          </Tooltip>
        </div>
      ),
      callback: () => {},
    });
  }
  if (row.document_type == "1" && row.camend_status == 0) {
    actions.push({
      icon: (
        <div style={{ cursor: "pointer" }}>
          <Tooltip title="Adjust">
            <Triangle />
          </Tooltip>
        </div>
      ),
      callback: () => handleAdjust(row),
    });
  }
  if (row.document_type != "1" && row.camend_status == 0) {
    actions.push(
      {
        icon: (
          <div>
            <Tooltip title="Remove Adjustment">
              <DeleteIcon style={{ cursor: "pointer", color: "red" }} />
            </Tooltip>
          </div>
        ),
        callback: () => {
          handleRemove(row);
        },
      },
      {
        icon: (
          <div>
            <Tooltip title="Edit">
              <IconEdit style={{ cursor: "pointer", color: "orange" }} />
            </Tooltip>
          </div>
        ),
        callback: () => handleAdjust(row),
      },
    );
  }
  return actions;
};
