import React from "react";

import Grid from "../lib/components/grid";
import { GridColumns } from "./financeconsolidationgridsetup";

type FinanceConsolidationGridProps = {
  data: any;
  loading: boolean;
  gridHeight: number;
  handleRefresh(): void;
};

class FinanceConsolidationGrid extends React.Component<FinanceConsolidationGridProps, {}> {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Grid
        fill={true}
        data={this.props.data}
        loading={this.props.loading}
        clearFilters={"financeconsolidationgrid"}
        forceHeight={this.props.gridHeight}
        handleRefresh={() => this.props.handleRefresh()}
        GridColumns={GridColumns}
        totalRowColumns={[
          "palletSize",
          "NoCartons",
          "sellingprice_final",
          "sale_credit_total",
          "sale_debit_total",
          "foreigncost",
          "adjustment",
          "totalcalcfobcost",
          "nettsellprice",
          "ld_fob",
          "roe_eta",
          "roe_etd",
          "local_costs",
          "rtgp",
          "advance",
          "producerFinal",
          "rtgc",
          "final",
        ]}
      />
    );
  }
}

export default FinanceConsolidationGrid;
