import React, { useMemo, forwardRef, useEffect } from "react";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";

import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";

import ReactDatePicker, { registerLocale } from "react-datepicker";

import { Field, Form } from "react-final-form";
import { TextField, Select } from "final-form-material-ui";

import toDate from "date-fns/toDate";
import { format } from "date-fns";

const styles = (theme: Theme) =>
  createStyles({
    tableCellLabel: {
      width: "150px",
      borderBottom: "none",
      height: "50px",
      textAlign: "right",
      paddingTop: "5px",
    },
    tableCellDetail: {
      width: "350px",
      borderBottom: "none",
      height: "50px",
    },
    tableCellLabelValue: {
      width: "350px",
      borderBottom: "none",
      height: "50px",
      textAlign: "left",
      paddingTop: "5px",
    },
    tableCellsValueSelect: {
      textAlign: "left",
      width: "350px",
      borderBottom: "none",
      height: "50px",
      padding: 0,
      marginTop: "-14px",
    },
  });

const CalenderCustomInput = forwardRef((props: any, ref: any) => {
  return (
    <Button name="CalenderCustomInput" variant="contained" color="primary" onClick={props.onClick} style={{ marginTop: "5px", width: "250px" }}>
      {props.value}
    </Button>
  );
});

export const TableFieldDate: React.FC<{ dateValue: Date; title: string; form: any; changeDate: (value, form) => void } & WithStyles<typeof styles>> = (props) => {
  const { classes, dateValue, title, changeDate, form } = props;
  return (
    <>
      <span className={classes.tableCellLabel}>{`${title}:`}</span>
      <span className={classes.tableCellDetail}>
        <ReactDatePicker
          locale="en-GB"
          showWeekNumbers={true}
          selected={toDate(dateValue)}
          onChange={(value) => {
            changeDate(value, form);
          }}
          dateFormat="dd-MM-yyyy"
          placeholderText="click here to select a date"
          customInput={<CalenderCustomInput />}
        />
      </span>
    </>
  );
};

export const TableFieldCombo: React.FunctionComponent<
  { classes: any; form: any; title: string; field: string; data: any; addEmptyValue: string; handleParse: (form, value) => void } & WithStyles<typeof styles>
> = (props) => {
  const { classes, form, title, field, data, addEmptyValue, handleParse } = props;
  return (
    <>
      <span className={classes.tableCellLabel}>{`${title}:`}</span>
      <span className={classes.tableCellDetail}>
        <Field
          name={field}
          component={Select}
          formControlProps={{ className: classes.tableCellsValueSelect }}
          parse={(value) => {
            if (handleParse) {
              handleParse(form, value);
            }
            return value;
          }}
        >
          {addEmptyValue && (
            <MenuItem key={`emptyvalueMenuItem`} value={-1}>
              {addEmptyValue}
            </MenuItem>
          )}
          {data &&
            data.map((item, index) => {
              return (
                <MenuItem key={`${item.value}${index}`} value={item.value}>
                  {item.display}
                </MenuItem>
              );
            })}
        </Field>
      </span>
    </>
  );
};

export const TableFieldText: React.FunctionComponent<
  { field: string; form: any; title: string; disabled: boolean; handleParse: (form, value) => {} } & WithStyles<typeof styles>
> = (props) => {
  const { classes, form, field, title, disabled, handleParse } = props;
  return (
    <>
      <span className={classes.tableCellLabel}>{`${title}:`}</span>
      <span className={classes.tableCellDetail}>
        <Field
          name={field}
          component={TextField}
          type="text"
          fullWidth={true}
          disabled={disabled}
          parse={(value) => {
            if (handleParse) {
              handleParse(form, value);
            }
            return value;
          }}
        />
      </span>
    </>
  );
};

export const TableFieldLabel: React.FunctionComponent<{ field: string; title: string; disabled: boolean } & WithStyles<typeof styles>> = (props) => {
  const { classes, field, title, disabled } = props;
  return (
    <>
      <span className={classes.tableCellLabel}>{`${title}:`}</span>
      <span className={classes.tableCellLabelValue}>{`${field}`}</span>
    </>
  );
};
