import React, { useState, useEffect } from "react";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";

import Box from "@material-ui/core/Box";

import { CreditorsInvoiceDetailItemType } from "../../lib/api/creditorsinvoicedetailitems";
import { currency } from "../../lib/api/currency";
import { creditorsinvoice } from "../../lib/api/creditorsinvoice";
import { isNullOrUndef } from "../../lib/helpers/isNullOrUndef";

import numeral from "numeral";

type CreditorsTotalsProps = {
  creditorsInvoiceId: number;
  creditorsInvoiceItems: any[];
  horizontal: boolean;
} & WithStyles<typeof styles>;

const styles = (theme: Theme) =>
  createStyles({
    box: {
      display: "grid",
      gridTemplateColumns: "180px 1fr",
      "& > :first-child": {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
      },
    },
    totalsGrid: {
      height: "300px",
      display: "grid",
      // gridTemplateColumns: "350px 350px",
      gridGap: "20px",
      padding: "20px",
    },
  });

export const CreditorsTotalsUnstyled: React.FC<CreditorsTotalsProps> = ({ classes, creditorsInvoiceId, creditorsInvoiceItems, horizontal }) => {
  const [creditorInvoice, setCreditorInvoice] = useState({ validate_totalexcl: 0, validate_vat: "0.00" });
  const [creditorCurrency, setCreditorCurrency] = useState({ code: "", name: "" });
  const [creditorTotals, setCreditorTotals] = useState({
    totalexcl_orig: 0,
    totalvat_orig: 0,
    totalincl_orig: 0,
    totalexcl_convt: 0,
    totalvat_convt: 0,
    totalincl_convt: 0,
  });

  const loadData = async () => {
    const creditorInvoiceResult = await creditorsinvoice.single(creditorsInvoiceId);
    const currencies = await currency.all();
    const creditorCurrencyResult = currencies.find((currency) => currency.id === creditorInvoiceResult.currency_id);
    const infoDetailItems = creditorsInvoiceItems.sort((a, b) => a.group - b.group || a.sequence - b.sequence);

    infoDetailItems.map((dataItem: CreditorsInvoiceDetailItemType) => {
      dataItem["totalexcl"] = dataItem.rate * dataItem.units;
      dataItem["totalvat"] = (dataItem.rate - (dataItem.rate * (100 + (dataItem.vat < 0 ? 0 : dataItem.vat))) / 100) * -1 * dataItem.units;
      dataItem["totalincl"] = ((dataItem.rate * (100 + (dataItem.vat < 0 ? 0 : dataItem.vat))) / 100) * dataItem.units;
      dataItem["ROE"] = creditorInvoiceResult.roe;
      dataItem["totalexclROE"] = dataItem.rate * dataItem.units * creditorInvoiceResult.roe;
      dataItem["totalvatROE"] = (dataItem.rate - (dataItem.rate * (100 + (dataItem.vat < 0 ? 0 : dataItem.vat))) / 100) * -1 * dataItem.units * creditorInvoiceResult.roe;
      dataItem["totalinclROE"] = ((dataItem.rate * (100 + (dataItem.vat < 0 ? 0 : dataItem.vat))) / 100) * dataItem.units * creditorInvoiceResult.roe;
      return dataItem;
    });

    const creditorTotalsResult = infoDetailItems.reduce(
      (obj, row) => {
        obj["totalexcl_orig"] = obj["totalexcl_orig"] + row.totalexcl;
        obj["totalincl_orig"] = obj["totalincl_orig"] + row.totalincl;
        obj["totalvat_orig"] = obj["totalvat_orig"] + row.totalvat;
        obj["totalexcl_convt"] = obj["totalexcl_convt"] + row.totalexclROE;
        obj["totalincl_convt"] = obj["totalincl_convt"] + row.totalinclROE;
        obj["totalvat_convt"] = obj["totalvat_convt"] + row.totalvatROE;
        return obj;
      },
      { totalexcl_orig: 0, totalincl_orig: 0, totalvat_orig: 0, totalexcl_convt: 0, totalincl_convt: 0, totalvat_convt: 0 },
    );

    setCreditorInvoice(creditorInvoiceResult);
    setCreditorTotals(creditorTotalsResult);
    setCreditorCurrency(creditorCurrencyResult);
  };

  useEffect(() => {
    loadData();
  }, [creditorsInvoiceId, creditorsInvoiceItems]);

  return (
    <div className={classes.totalsGrid} style={{ gridTemplateColumns: horizontal ? "350px 350px" : "350px" }}>
      <div>
        <span style={{ fontWeight: "bold", fontSize: "xx-large", paddingLeft: "10px" }}>Invoice Value</span>
        <TotalBox classes={classes} currency={`${creditorCurrency.code} Excl`} value={creditorInvoice.validate_totalexcl} color={true} />
        <TotalBox classes={classes} currency={`${creditorCurrency.code} Vat`} value={creditorInvoice.validate_vat} color={true} />
        <TotalBox classes={classes} currency={`${creditorCurrency.code} Incl`} value={creditorInvoice.validate_totalexcl + creditorInvoice.validate_vat} />
      </div>
      <div>
        <span style={{ fontWeight: "bold", fontSize: "xx-large", paddingLeft: "10px" }}>Detail Value</span>
        <TotalBox
          classes={classes}
          currency={`${creditorCurrency.code} Excl`}
          value={creditorTotals.totalexcl_orig}
          color={numeral(creditorTotals.totalexcl_orig).format("0.00") == numeral(creditorInvoice.validate_totalexcl).format("0.00")}
        />
        <TotalBox
          classes={classes}
          currency={`${creditorCurrency.code} Vat`}
          value={creditorTotals.totalvat_orig}
          color={numeral(creditorTotals.totalvat_orig).format("0.00") == numeral(creditorInvoice.validate_vat).format("0.00")}
        />
        <TotalBox classes={classes} currency={`${creditorCurrency.code} Incl`} value={creditorTotals.totalincl_orig} />
      </div>
      {/* <div>
        <TotalBox classes={classes} currency={"ZAR Excl"} value={creditorTotals.totalexcl_convt} />
        <TotalBox classes={classes} currency={"ZAR Vat"} value={creditorTotals.totalvat_convt} />
        <TotalBox classes={classes} currency={"ZAR Incl"} value={creditorTotals.totalincl_convt} />
      </div> */}
    </div>
  );
};

type TotalBoxType = {
  classes: any;
  currency: string;
  value: any;
  color?: boolean | null;
};

const TotalBox: React.FC<TotalBoxType> = ({ classes, currency, value, color = null }) => {
  return (
    <Box m={1} fontSize={24} fontWeight="fontWeightBold" className={classes.box} style={{ color: isNullOrUndef(color) ? "black" : color ? "green" : "orange" }}>
      <span>
        Total {currency} <span>:</span>
      </span>
      <span style={{ textAlign: "right" }}>{`${numeral(value).format("0,0.00")}`}</span>
    </Box>
  );
};

export default withStyles(styles)(CreditorsTotalsUnstyled);
