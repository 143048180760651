import React from "react";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";

import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      width: "700px",
    },
    header: {
      fontSize: `${theme.typography.fontSize * 2}px`,
      padding: `${theme.spacing(1) * 4}px`,
      textAlign: "center",
    },
  });

type NotAuthProps = {} & WithStyles<typeof styles>;

const NotAuthUnstyled: React.SFC<NotAuthProps> = (props) => {
  const { classes } = props;

  return (
    <Paper className={classes.root}>
      <Typography variant="h3" className={classes.header}>
        You are not authorised to view this page
      </Typography>
    </Paper>
  );
};

export default withStyles(styles)(NotAuthUnstyled);
