import React, { FC, forwardRef, useCallback, useEffect, useState } from "react";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";
import Grid from "../../lib/components/grid";
import { GridColumns } from "./exchangegridsetup";
import { getExchangeRateFromLast30Days } from "../../lib/api/exchangerates";
import Button from "@material-ui/core/Button";
import ReactDatePicker from "react-datepicker";
import Toolbar from "@material-ui/core/Toolbar";
import { getFinancialYearSelected } from "../../lib/api/week";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1),
      width: "100%",
      height: "100%",
      position: "relative",
    },
    inline: {
      display: "inline",
      position: "absolute",
      marginTop: "7px",
      marginLeft: "7px",
    },
    formField: {
      display: "grid",
      gridTemplateColumns: "34px 300px",
      gap: `${theme.spacing(2)}px`,
      textAlign: "right",
      "& p": {
        fontSize: "16px",
      },
      "& div": {
        marginTop: "3px",
      },
    },
    toolbar: {
      display: "flex",
      flexDirection: "row",
      gap: "10px",
      position: "absolute",
      left: 0,
      top: 0,
      alignItems: "center",
    },
  });

type ExchangeProps = {} & WithStyles<typeof styles>;

const CalenderCustomInput = forwardRef((props: any, ref: any) => {
  return (
    <Button name="CalenderCustomInput" variant="contained" color="primary" onClick={props.onClick} style={{ marginTop: "5px", width: "165px", minHeight: "36px" }}>
      Select a month
    </Button>
  );
});

export const ExchangeUnstyled: FC<ExchangeProps> = (props) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedMonth, setSelectedMonth] = useState<Date>();

  const { classes } = props;

  const handleChangeDate = useCallback((value) => {
    setSelectedMonth(value);
  }, []);

  useEffect(() => {
    loadData(selectedMonth);
  }, [selectedMonth]);

  const loadData = async (selectedMonth: Date) => {
    const data = await getExchangeRateFromLast30Days(selectedMonth);

    try {
      const formatted = data.reduce((acc, curr) => {
        const quotes = curr.quotes;
        return Object.keys(quotes).reduce((acc, item) => {
          acc[item] = { ...(acc[item] || {}), [curr.source]: quotes[item][`${curr.source}ZAR`], date: item };
          return acc;
        }, acc);
      }, {});

      setData(Object.values(formatted).sort((a: any, b: any) => (a.date > b.date ? -1 : 1)));
      setLoading(false);
    } catch (error) {}
  };

  return (
    <div className={classes.root}>
      {!loading && (
        <Toolbar className={classes.toolbar}>
          <div className={classes.formField}>
            <div>
              <ReactDatePicker
                locale="en-GB"
                showWeekNumbers={true}
                selected={null}
                onChange={handleChangeDate}
                dateFormat="dd-MM-yyyy"
                placeholderText="click here to select a date"
                showMonthYearPicker
                maxDate={new Date()}
                minDate={new Date(getFinancialYearSelected() - 1, 12, 1)}
                customInput={<CalenderCustomInput />}
              />
            </div>
          </div>
        </Toolbar>
      )}
      <Grid loading={loading} data={data} getCellActions={() => []} GridColumns={GridColumns} clearFilters={"exchangegridsetup"} />
    </div>
  );
};

export default withStyles(styles)(ExchangeUnstyled);
