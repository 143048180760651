import React from "react";
import { withStyles, createStyles, Theme } from "@material-ui/core/styles";

import Grid from "../../../lib/components/grid";
import { GridColumns } from "./templatesummarygridsetup";
import { creditorsimporttemplate, CreditorsImportTemplateType, getAllCreditorsImportTemplates } from "../../../lib/api/creditorsimporttemplate";
import Confirmation from "../../../lib/components/confirmation";
import { CreditorsImportTemplateDetailType, updateCreditorsImportTemplateDetail } from "../../../lib/api/creditorsimporttemplatedetail";

import CreditorsImportTemplateCreate from "./templatecreate";

import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import IconAdd from "@material-ui/icons/Add";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      // padding: theme.spacing(1),
      width: "100%",
      height: "calc(100vh - 100px)",
    },
    inline: {
      display: "inline",
      position: "absolute",
      marginTop: "7px",
      marginLeft: "7px",
    },
  });

type CreditorsImportTemplateSummaryProps = {
  classes: any;
};

class CreditorsImportTemplateSummary extends React.Component<CreditorsImportTemplateSummaryProps, {}> {
  constructor(props) {
    super(props);
  }

  state = {
    loading: true,
    data: [],
    redirectCreate: false,
    selectedTemplate: undefined,
    confirmDelete: undefined,
  };

  loadData = async () => {
    getAllCreditorsImportTemplates().then((data) => {
      this.setState({ data });
    });
  };

  componentDidMount = () => {
    this.setState({ loading: true });
    this.loadData().then(() => {
      this.setState({ loading: false });
    });
  };

  handleEditProcess = async (data: CreditorsImportTemplateType, templateDetailColumns: CreditorsImportTemplateDetailType[]) => {
    let newid = 0;
    let dataToUpdate: { data: CreditorsImportTemplateType };

    if (data) {
      dataToUpdate = {
        data: {
          creditors_id: data.creditors_id,
          templatename: data.templatename ? data.templatename : "",
          templatehasheader: data.templatehasheader ? 1 : 0,
          templatespecialcharacter: data.templatespecialcharacter ? data.templatespecialcharacter : "",
        },
      };

      if (data.id && data.id != 0) {
        newid = data.id;
        await creditorsimporttemplate.update(data.id, dataToUpdate);
      } else {
        const resultFileImportCreate = await creditorsimporttemplate.create(dataToUpdate);
        newid = resultFileImportCreate[0];
      }
    }

    if (templateDetailColumns.length > 0) {
      const columns = templateDetailColumns.map((item) => ({ ...item, creditorsimporttemplate_id: newid }));
      await updateCreditorsImportTemplateDetail(columns);
    }

    this.loadData().then(() => {
      this.setState({ redirectCreate: false, selectedTemplate: undefined });
    });
  };

  handleRemove = (rowId) => {
    this.setState({ confirmDelete: rowId });
  };

  handleEdit = (template: any) => {
    this.setState({ selectedTemplate: template });
  };

  handleCreate = () => {
    this.setState({ redirectCreate: true });
  };

  handleCloseForm = () => {
    this.setState({ redirectCreate: undefined, selectedTemplate: undefined });
  };

  handleConfirmRemove = async () => {
    await creditorsimporttemplate.remove(this.state.confirmDelete);
    await this.loadData();
    this.setState({ confirmDelete: undefined });
  };

  handleCloseRemove = () => {
    this.setState({ confirmDelete: undefined });
  };

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        {this.state.confirmDelete && (
          <Confirmation
            isOpen={true}
            handleClose={this.handleCloseRemove}
            handleConfirm={this.handleConfirmRemove}
            title={`Remove selected template?`}
            body={`Are you sure you want to REMOVE the selected template entry?`}
          />
        )}
        {this.state.redirectCreate && <CreditorsImportTemplateCreate handleProcess={this.handleEditProcess} handleCloseForm={this.handleCloseForm} />}
        {this.state.selectedTemplate && (
          <CreditorsImportTemplateCreate selectedTemplate={this.state.selectedTemplate} handleProcess={this.handleEditProcess} handleCloseForm={this.handleCloseForm} />
        )}
        {!this.state.redirectCreate && !this.state.selectedTemplate && (
          <div>
            <div className={classes.inline}>
              <Tooltip title="Create new Creditors">
                <div className={classes.inline}>
                  <Button variant="contained" color="primary" onClick={this.handleCreate} style={{ marginBottom: "10px" }}>
                    <IconAdd />
                  </Button>
                </div>
              </Tooltip>
            </div>
            <Grid
              loading={this.state.loading}
              data={this.state.data}
              clearFilters={"creditorsimporttemplatesummary"}
              GridColumns={(data, filters, arrangement) => GridColumns(data, filters, arrangement, this.handleRemove, this.handleEdit)}
            />
          </div>
        )}
      </div>
    );
  }
}

export default withStyles(styles)(CreditorsImportTemplateSummary);
