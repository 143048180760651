import { client } from "./_base";

export const creditorsgroup = client("creditorsgroup", (item) => ({
  ...item,
}));

export const creditorsgroupAllSortedMappedforCombo = async () => {
  const result = await creditorsgroup.all().then((data) => {
    return data
      .sort((a, b) => {
        if (a.group > b.group) return 1;
        if (a.group <= b.group) return -1;
        return 1;
      })
      .map((item) => {
        return { value: item.id, display: `${item.group} - ${item.description}` };
      });
  });
  return result;
};
