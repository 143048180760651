import React from "react";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";

import { GridColumns } from "./clientsgridsetup";
import ClientsEditView from "./clientseditview";
import Grid from "../../lib/components/grid";
import Confirmation from "../../lib/components/confirmation";
import { clients, withConcatenatedConsignees } from "../../lib/api/clients";
import { DialogInformation } from "../../lib/components/dialoginformation";
import { SnackContext } from "../../lib/context/SnackContext";
import { GenerateErrorMessage } from "../../lib/helpers/string_methods";

import Tooltip from "@material-ui/core/Tooltip";
import Button from "@material-ui/core/Button";

import IconAdd from "@material-ui/icons/Add";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1),
      minWidth: "100px",
      height: "100%",
      position: "relative",
    },
    inline: {
      display: "inline",
      position: "absolute",
      marginTop: "7px",
      marginLeft: "7px",
    },
  });

type ClientsProps = {} & WithStyles<typeof styles>;

export class ClientsUnstyled extends React.Component<ClientsProps, any> {
  state = {
    classes: undefined,
    data: [],
    confirmRemoveID: undefined,
    confirmEditID: undefined,
    loading: true,
    message: undefined,
  };

  constructor(props) {
    super(props);
    this.state.classes = props.classes;
  }

  static contextType = SnackContext;
  context!: React.ContextType<typeof SnackContext>;

  componentDidMount() {
    this.loadData();
  }

  loadData = async () => {
    const data = await withConcatenatedConsignees();
    const dataSorted = data.data.sort((a, b) => {
      if (a.code > b.code) return 1;
      if (a.code < b.code) return -1;
      return 1;
    });
    this.setState({ loading: false, data: dataSorted });
  };

  handleCreate = () => {
    this.setState({ confirmEditID: "0" });
  };

  handleEdit = (id) => {
    this.setState({ confirmEditID: id });
  };

  handleEditClose = () => {
    this.setState({ confirmEditID: undefined });
  };

  handleRemove = async (id) => {
    this.setState({ confirmRemoveID: id });
  };

  handleRemoveClose = () => {
    this.setState({ confirmRemoveID: undefined });
  };

  handleRemoveConfirm = async () => {
    try {
      await clients.remove(this.state.confirmRemoveID);
    } catch (error) {
      const err = GenerateErrorMessage(error, "Error removing data");
      this.context.updateSnack({ show: true, color: "red", message: err });
    }
    this.handleRemoveClose();
    this.loadData();
  };

  handleInformationClose = () => {
    this.setState({ message: undefined });
  };

  render() {
    const { classes } = this.state;

    return (
      <div className={classes.root}>
        {this.state.confirmRemoveID && (
          <Confirmation
            isOpen={this.state.confirmRemoveID ? true : false}
            handleClose={this.handleRemoveClose}
            handleConfirm={this.handleRemoveConfirm}
            title="Remove selected Client?"
            body="Are you sure you want to REMOVE the selected Client Record?"
          ></Confirmation>
        )}
        {this.state.confirmEditID && (
          <Confirmation isOpen handleClose={this.handleEditClose} handleConfirm={() => {}} title="Create/edit Client" body={undefined}>
            <ClientsEditView onClose={this.handleEditClose} setClientId={this.handleEdit} clientId={this.state.confirmEditID} reloadData={this.loadData} />
          </Confirmation>
        )}
        {this.state.message && (
          <DialogInformation
            isOpen={true}
            handleClose={this.handleInformationClose}
            handleOK={this.handleInformationClose}
            title={"Feedback"}
            body={`${this.state.message}`}
            showinput={false}
          />
        )}
        <div>
          <div className={classes.inline}>
            <Tooltip title="Create new Client">
              <div className={classes.inline}>
                <Button variant="contained" color="primary" onClick={this.handleCreate} style={{ marginBottom: "10px" }}>
                  <IconAdd />
                </Button>
              </div>
            </Tooltip>
          </div>
          <Grid
            data={this.state.data}
            GridColumns={(data, filters, arrangement, columnsWidth) => GridColumns(data, filters, arrangement, columnsWidth, this.handleRemove, this.handleEdit)}
            loading={this.state.loading}
            clearFilters={"clientsgridsetup"}
            handleRefresh={this.loadData}
          />
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(ClientsUnstyled);
