import { client, request } from "./_base";

export type FileImportTemplateDetailType = {
  id?: number;
  FileImportTemplate_id: number;
  colname: string;
  colposition: string;
  created_at?: Date;
  updated_at?: Date;
  delete?: number;
};

const endpoint = "fileimporttemplatedetail";

export const FileImportTemplateDetail = client(endpoint, (item) => ({
  ...item,
}));

export const findByTemplateId = (id) => {
  return request(`${endpoint}/ex/findByFileImportTemplateId/${id}`, {
    method: "GET",
  })
    .then(async (result) => {
      if (result.status == 500) {
        const resp = await result.json();
        throw resp;
      }
      return result.json();
    })
    .then(({ data }) => {
      return data;
    });
};

export const updateTemplateDetailColumns = (data: FileImportTemplateDetailType[]): Promise<any> =>
  request(`${endpoint}/ex/update-columns`, {
    method: "POST",
    body: JSON.stringify(data),
  });
