import React from "react";

import CustomAutoCompleteFilter from "../../lib/components/customdatagridfilter/CustomFilter";
import { DateFormatter, FormatterNumber2Decimals } from "../../lib/components/Formatters";
import { handleColumnsArrangement, handleColumnsWidth } from "../../lib/helpers/grid";

import IconInfo from "@material-ui/icons/Info";
import IconCheck from "@material-ui/icons/Check";

const cellWidthTiny = 40;
const cellWidthSmall = 100;
const cellWidthMedium = 180;
const cellWidthMediumX = 220;
const cellWidthLarge = 250;

const defaultColumnProperties = {
  resizable: true,
  sortable: true,
};

let currentSpace = true;

const isNumber = (value: any) => !isNaN(parseFloat(value));

export const GridColumns = (
  data,
  filters,
  arrangement = undefined,
  columnsWidth = undefined,
  handleForeignCostInfo,
  handleAdvanceRemittanceInfo,
  handleFinalRemittanceInfo,
  handleLocalCostInfo,
  handleImpalaFeeInfo,
  handleAdvanceSelect,
) => {
  let postValue = "";

  if (data) {
    if (currentSpace) {
      postValue = " ";
    }
    currentSpace = !currentSpace;
  }

  const columnsCount = Object.keys(data[0] || {}).filter(
    (key) => !["barcode_id", "final_ident", "advance_ident", "producer_id", "zar", "camend_status", "invoicenumber"].includes(key),
  );

  const columns = columnsCount.map((col, indx) => ({
    key: col,
    name: "" + postValue,
    width: indx == 0 ? cellWidthMediumX : cellWidthLarge,
    filterable: true,
    frozen: indx == 0,
    formatter: ({ row, column }) => {
      if (indx > 0) {
        if (row.column == "Processed") {
          return (
            <div style={{ display: "flex", flexDirection: "row", columnGap: "10px", justifyContent: "flex-end" }}>
              {row.camend_status === 3 ? <IconCheck style={{ color: "green" }} /> : <></>}
              {row[column.key] == null ? <div></div> : <FormatterNumber2Decimals row={row} column={column} />}
            </div>
          );
        }
        if ((row.column || "").toLowerCase().includes("date")) {
          return <DateFormatter column={column} row={row} style={{ textAlign: "right" }} />;
        }

        if (isNumber(row[column.key])) {
          if (row.column == "Foreign Costs" && parseFloat(row[column.key]) > 0) {
            return (
              <div
                title="Info"
                onClick={() => {
                  handleForeignCostInfo(row);
                }}
                style={{ display: "grid", gridTemplateColumns: "min-content 1fr" }}
              >
                <IconInfo style={{ color: "blue", float: "left" }} />
                <FormatterNumber2Decimals row={row} column={column} />
              </div>
            );
          }
          if (row.column == "Advance" && parseFloat(row[column.key]) > 0) {
            return (
              <div
                onClick={() => {
                  handleAdvanceSelect(row);
                }}
                style={{ display: "grid", gridTemplateColumns: "min-content 1fr" }}
              >
                <IconInfo style={{ color: "blue", float: "left" }} />
                <FormatterNumber2Decimals column={column} row={row} />
              </div>
            );
          }
          if (row.column == "Local Costs" && parseFloat(row[column.key]) > 0) {
            return (
              <div
                onClick={() => {
                  handleLocalCostInfo(row);
                }}
                style={{ display: "grid", gridTemplateColumns: "min-content 1fr", gap: "5px" }}
              >
                <IconInfo style={{ color: "blue", float: "left" }} />
                <div style={{ display: "grid", gridTemplateColumns: "1fr min-content 1fr" }}>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <span>R </span>
                    <FormatterNumber2Decimals column={{ key: "zar" }} row={row} />
                  </div>
                  <div style={{ width: "1px", background: "#cecece" }}></div>
                  <FormatterNumber2Decimals column={column} row={row} />
                </div>
              </div>
            );
          }
          if (row.column == "Impala Fee" && parseFloat(row[column.key]) > 0) {
            return (
              <div
                onClick={() => {
                  handleImpalaFeeInfo(row);
                }}
                style={{ display: "grid", gridTemplateColumns: "min-content 1fr", gap: "5px" }}
              >
                <IconInfo style={{ color: "blue", float: "left" }} />
                <div style={{ display: "grid", gridTemplateColumns: "1fr min-content 1fr" }}>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <span>R </span>
                    <FormatterNumber2Decimals column={{ key: "zar" }} row={row} />
                  </div>
                  <div style={{ width: "1px", background: "#cecece" }}></div>
                  <FormatterNumber2Decimals column={column} row={row} />
                </div>
              </div>
            );
          }
          if (row.column == "Advance Remittance" && parseFloat(row[column.key]) > 0) {
            return (
              <div title="Info" onClick={() => handleAdvanceRemittanceInfo(row[column.key])}>
                <IconInfo style={{ color: "blue", float: "left" }} />
                <div style={{ textAlign: indx === 0 ? "left" : "right" }}>{row[column.key]}</div>
              </div>
            );
          }
          if (row.column == "Final Remittance" && parseFloat(row[column.key]) > 0) {
            return (
              <div title="Info" onClick={() => handleFinalRemittanceInfo(row[column.key])}>
                <IconInfo style={{ color: "blue", float: "left" }} />
                <div style={{ textAlign: indx === 0 ? "left" : "right" }}>{row[column.key]}</div>
              </div>
            );
          }

          if (row.zar) {
            return (
              <div style={{ display: "grid", gridTemplateColumns: "24px 1fr min-content 1fr", gap: "5px" }}>
                <div></div>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span>R </span>
                  <FormatterNumber2Decimals column={{ key: "zar" }} row={row} />
                </div>
                <div style={{ width: "1px", background: "#cecece" }}></div>
                <FormatterNumber2Decimals column={column} row={row} />
              </div>
            );
          }
          return <FormatterNumber2Decimals column={column} row={row} />;
        }
      }
      return <div style={{ fontWeight: indx === 0 ? "bold" : "inherit", textAlign: indx === 0 ? "left" : "right" }}>{row[column.key]}</div>;
    },
    filterRenderer: (e) => {
      return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={col} data={data} section="financesummarygrid" />;
    },
    ...defaultColumnProperties,
  }));

  let columnsArranged = [];
  columnsArranged = handleColumnsArrangement(columnsArranged, arrangement, columns);
  columnsArranged = handleColumnsWidth(columnsArranged, columnsWidth, columns);

  return columnsArranged;
};

//  const columns = [
//  {
//    key: "producer_id",
//    name: "Producer" + postValue,
//    width: cellWidthSmall,
//    filterable: true,
//    filterRenderer: (e) => {
//      return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"producer_id"} data={data} section="financesummarygrid" />;
//    },
//  },
//    {
//      key: "cartons",
//      name: "No Cartons" + postValue,
//      width: cellWidthSmall,
//      filterable: true,
//      filterRenderer: (e) => {
//        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"cartons"} data={data} section="financesummarygrid" />;
//      },
//      formatter: FormatterNumber,
//      summaryFormatter: SummaryNumber,
//    },
//    {
//      key: "palletsize",
//      name: "Pallet Size" + postValue,
//      width: cellWidthSmall,
//      filterable: true,
//      filterRenderer: (e) => {
//        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"palletsize"} data={data} section="financesummarygrid" />;
//      },
//      formatter: FormatterNumber2Decimals,
//      summaryFormatter: SummaryNumber2Decimal,
//    },
//    {
//      key: "sale_total",
//      name: "Sale Total" + postValue,
//      width: cellWidthSmall,
//      filterable: true,
//      filterRenderer: (e) => {
//        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"sale_total"} data={data} section="financesummarygrid" />;
//      },
//      formatter: FormatterNumber2Decimals,
//      summaryFormatter: SummaryNumber2Decimal,
//    },
//    {
//      key: "sale_debit",
//      name: "Debit Note" + postValue,
//      width: cellWidthSmall,
//      filterable: true,
//      filterRenderer: (e) => {
//        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"sale_debit"} data={data} section="financesummarygrid" />;
//      },
//      formatter: FormatterNumber2Decimals,
//      summaryFormatter: SummaryNumber2Decimal,
//    },
//    {
//      key: "sale_credit",
//      name: "Credit Note" + postValue,
//      width: cellWidthSmall,
//      filterable: true,
//      filterRenderer: (e) => {
//        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"sale_credit"} data={data} section="financesummarygrid" />;
//      },
//      formatter: FormatterNumber2Decimals,
//      summaryFormatter: SummaryNumber2Decimal,
//    },
//    {
//      key: "foreigncost",
//      name: "Foreign Cost" + postValue,
//      width: cellWidthSmall,
//      filterable: true,
//      filterRenderer: (e) => {
//        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"foreigncost"} data={data} section="financesummarygrid" />;
//      },
//      formatter: (props) => {
//        const { row } = props;
//        if (Number(row.foreigncost) != 0) {
//          return (
//            <span
//              title="Info"
//              onClick={() => {
//                handleForeignCostInfo(row.barcode_id);
//              }}
//            >
//              <IconInfo style={{ color: "blue", float: "left" }} />
//              <FormatterNumber2Decimals {...props} />
//            </span>
//          );
//        }
//        return <FormatterNumber2Decimals {...props} />;
//      },
//      summaryFormatter: SummaryNumber2Decimal,
//    },
//    {
//      key: "nettsaletotal",
//      name: "Net Sale Total" + postValue,
//      width: cellWidthSmall,
//      filterable: true,
//      filterRenderer: (e) => {
//        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"nettsaletotal"} data={data} section="financesummarygrid" />;
//      },
//      formatter: FormatterNumber2Decimals,
//      summaryFormatter: SummaryNumber2Decimal,
//    },
//    {
//      key: "local_cost",
//      name: "Local Costs" + postValue,
//      width: cellWidthSmall,
//      filterable: true,
//      filterRenderer: (e) => {
//        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"local_cost"} data={data} section="financesummarygrid" />;
//      },
//      formatter: FormatterNumber2Decimals,
//      summaryFormatter: SummaryNumber2Decimal,
//    },
//    {
//      key: "impala_fee",
//      name: "Impala Fee" + postValue,
//      width: cellWidthSmall,
//      filterable: true,
//      filterRenderer: (e) => {
//        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"impala_fee"} data={data} section="financesummarygrid" />;
//      },
//      formatter: FormatterNumber2Decimals,
//      summaryFormatter: SummaryNumber2Decimal,
//    },
//    {
//      key: "available",
//      name: "Available For Distribution" + postValue,
//      width: cellWidthSmall,
//      filterable: true,
//      filterRenderer: (e) => {
//        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"available"} data={data} section="financesummarygrid" />;
//      },
//      formatter: FormatterNumber2Decimals,
//      summaryFormatter: SummaryNumber2Decimal,
//    },
//    {
//      key: "currency",
//      name: "Currency" + postValue,
//      width: cellWidthSmall,
//      filterable: true,
//      filterRenderer: (e) => {
//        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"currency"} data={data} section="financesummarygrid" />;
//      },
//    },
//    {
//      key: "nettsaletotal_zar",
//      name: "Net Sale Total ZAR" + postValue,
//      width: cellWidthSmall,
//      filterable: true,
//      filterRenderer: (e) => {
//        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"nettsaletotal_zar"} data={data} section="financesummarygrid" />;
//      },
//      formatter: FormatterNumber2Decimals,
//      summaryFormatter: SummaryNumber2Decimal,
//    },
//    {
//      key: "local_cost_zar",
//      name: "Local Cost ZAR" + postValue,
//      width: cellWidthSmall,
//      filterable: true,
//      filterRenderer: (e) => {
//        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"local_cost_zar"} data={data} section="financesummarygrid" />;
//      },
//      formatter: FormatterNumber2Decimals,
//      summaryFormatter: SummaryNumber2Decimal,
//    },
//    {
//      key: "impala_fee_zar",
//      name: "Impala Fee ZAR" + postValue,
//      width: cellWidthSmall,
//      filterable: true,
//      filterRenderer: (e) => {
//        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"impala_fee_zar"} data={data} section="financesummarygrid" />;
//      },
//      formatter: FormatterNumber2Decimals,
//      summaryFormatter: SummaryNumber2Decimal,
//    },
//    {
//      key: "available_zar",
//      name: "Available For Distribution ZAR" + postValue,
//      width: cellWidthSmall,
//      filterable: true,
//      filterRenderer: (e) => {
//        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"available_zar"} data={data} section="financesummarygrid" />;
//      },
//      formatter: FormatterNumber2Decimals,
//      summaryFormatter: SummaryNumber2Decimal,
//    },
//    {
//      key: "producer_advance",
//      name: "Producer Advance" + postValue,
//      width: cellWidthSmall,
//      filterable: true,
//      filterRenderer: (e) => {
//        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"producer_advance"} data={data} section="financesummarygrid" />;
//      },
//      formatter: FormatterNumber2Decimals,
//      summaryFormatter: SummaryNumber2Decimal,
//    },
//    {
//      key: "advance_ident",
//      name: "Advance Ident" + postValue,
//      width: cellWidthSmall,
//      filterable: true,
//      filterRenderer: (e) => {
//        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"advance_ident"} data={data} section="financesummarygrid" />;
//      },
//    },
//    {
//      key: "producer_final",
//      name: "Producer Final" + postValue,
//      width: cellWidthSmall,
//      filterable: true,
//      filterRenderer: (e) => {
//        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"producer_final"} data={data} section="financesummarygrid" />;
//      },
//      formatter: FormatterNumber2Decimals,
//      summaryFormatter: SummaryNumber2Decimal,
//    },
//    {
//      key: "final_ident",
//      name: "Final Ident" + postValue,
//      width: cellWidthSmall,
//      filterable: true,
//      filterRenderer: (e) => {
//        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"final_ident"} data={data} section="financesummarygrid" />;
//      },
//    },
//    {
//      key: "producer_total",
//      name: "Producer Total" + postValue,
//      width: cellWidthSmall,
//      filterable: true,
//      filterRenderer: (e) => {
//        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"producer_total"} data={data} section="financesummarygrid" />;
//      },
//      formatter: FormatterNumber2Decimals,
//      summaryFormatter: SummaryNumber2Decimal,
//    },
//    {
//      key: "final",
//      name: "Finalized" + postValue,
//      width: cellWidthSmall,
//      filterable: true,
//      filterRenderer: (e) => {
//        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"final"} data={data} section="financesummarygrid" />;
//      },
//      formatter: FormatterNumber2Decimals,
//      summaryFormatter: SummaryNumber2Decimal,
//    },
//  ].map((c) => ({ ...c, ...defaultColumnProperties }));
