import React from "react";

import CustomAutoCompleteFilter from "../../../../lib/components/customdatagridfilter/CustomFilter";
import TextEditor from "../../../../lib/components/TextEditor";
import { DateFormatter, FormatterNumber, FormatterNumber2Decimals } from "../../../../lib/components/Formatters";
import { SummaryNumber2Decimal } from "../../../../lib/components/SummaryFormatter";
import { handleColumnsArrangement, handleColumnsWidth } from "../../../../lib/helpers/grid";
import RightAlignHeaderColumn from "../../../../lib/components/gridhelper/RightAlignHeaderColumn";

import Checkbox from "@material-ui/core/Checkbox";
import Tooltip from "@material-ui/core/Tooltip";

const cellWidthAction = 50;
const cellWidthSmall = 100;
const cellWidthMedium = 130;
const cellWidthLarge = 200;

let currentSpace = true;
export const GridColumnsAllocationList = (data, filters, arrangement, columnsWidth, removeAllocationIds, handleSelectRemoveAllocation) => {
  let postValue = "";

  if (data) {
    if (currentSpace) {
      postValue = " ";
    }
    currentSpace = !currentSpace;
  }

  const columns = [
    {
      key: "actions",
      name: "Actions",
      width: cellWidthAction,
      formatter: ({ row }) => (
        <Tooltip title={row.is_locked ? "LOCKED - Shipment has been processed" : "Select to remove a shipment"}>
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%", cursor: "pointer" }}>
            <Checkbox style={{ padding: 0 }} disabled={row.is_locked} checked={removeAllocationIds.includes(row.id)} onChange={() => handleSelectRemoveAllocation(row.id)} />
          </div>
        </Tooltip>
      ),
    },
    {
      key: "invoicenumber",
      name: "Invoice" + postValue,
      width: cellWidthSmall,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"invoicenumber"} data={data} section="allocationlist" />;
      },
    },
    {
      key: "posteddate",
      name: "Posted Date" + postValue,
      width: cellWidthSmall,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"posteddate"} data={data} section="allocationlist" />;
      },
      formatter: DateFormatter,
    },
    {
      key: "paymentnumber",
      name: "Payment Number" + postValue,
      width: cellWidthSmall,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"paymentnumber"} data={data} section="allocationlist" />;
      },
      formatter: FormatterNumber,
    },
    {
      key: "due_date",
      name: "Due Date" + postValue,
      width: cellWidthSmall,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"due_date"} data={data} section="allocationlist" />;
      },
      formatter: DateFormatter,
    },
    {
      key: "due_amount",
      name: "Due Amount" + postValue,
      width: cellWidthSmall,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"due_amount"} data={data} section="allocationlist" />;
      },
      formatter: FormatterNumber2Decimals,
      headerRenderer: RightAlignHeaderColumn,
    },
    {
      key: "amount",
      name: "Received Amount" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"amount"} data={data} section="allocationlist" />;
      },
      editor: (props) => <TextEditor {...props} align="right" />,
      formatter: FormatterNumber2Decimals,
      summaryFormatter: SummaryNumber2Decimal,
      headerRenderer: RightAlignHeaderColumn,
    },
  ];

  let columnsArranged = [];
  columnsArranged = handleColumnsArrangement(columnsArranged, arrangement, columns);
  columnsArranged = handleColumnsWidth(columnsArranged, columnsWidth, columns);

  return columnsArranged;
};
