import React from "react";

import IconCategory from "@material-ui/icons/Category";
import IconRemove from "@material-ui/icons/Remove";
import IconLock from "@material-ui/icons/Lock";
import IconEdit from "@material-ui/icons/Edit";
import IconBrightness from "@material-ui/icons/Brightness1";
import Tooltip from "@material-ui/core/Tooltip";

import CustomAutoCompleteFilter from "../../lib/components/customdatagridfilter/CustomFilter";
import { CellActionsFormatter } from "../../lib/helpers/CellActionsFormatter";
import { DateFormatter, FormatterNumber2Decimals } from "../../lib/components/Formatters";
import { EnquiryLink, Selector } from "../../lib/components/EnquiryLink";

import { isPast } from "date-fns";
import { SummaryNumber2Decimal } from "../../lib/components/SummaryFormatter";

const cellWidthTiny = 40;
const cellWidthSmall = 80;
const cellWidthMedium = 120;
const cellWidthLarge = 200;

const defaultColumnProperties = {
  resizable: true,
  sortable: true,
};

const StatusFormatter = ({ column, row }) => {
  let isDue = false;

  if (row.document_type != "1") {
    return <></>;
  }

  if (row.sale_payment1amount_remain > 0 && isPast(new Date(row.sale_payment1date))) {
    isDue = true;
  } //
  else if (row.sale_payment2amount_remain > 0 && isPast(new Date(row.sale_payment2date))) {
    isDue = true;
  } //
  else if (row.sale_payment3amount_remain > 0 && isPast(new Date(row.sale_payment3date))) {
    isDue = true;
  }

  if (isDue) {
    return (
      <div style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "100%" }}>
        <Tooltip title={`Payment is due`}>
          <IconBrightness style={{ color: "red", border: "1px solid #333", borderRadius: "100%", marginTop: "2px" }} />
        </Tooltip>
      </div>
    );
  } else {
    return (
      <div style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "100%" }}>
        <Tooltip title={`Payment is not due`}>
          <IconBrightness style={{ color: "green", border: "1px solid #333", borderRadius: "100%", marginTop: "2px" }} />
        </Tooltip>
      </div>
    );
  }
};

let currentSpace = true;
export const GridColumns = (data, filters, arrangement = undefined, columnsWidth = undefined, handleAdjust, handleRemove) => {
  let postValue = "";

  if (data) {
    if (currentSpace) {
      postValue = " ";
    }
    currentSpace = !currentSpace;
  }

  const columns = [
    {
      key: "actions",
      name: "#A",
      width: cellWidthTiny,
      formatter: ({ row }) => {
        const actions = [];
        if (row.document_type == "1") {
          actions.push({
            icon: (
              <span title="Adjust" style={{ color: "#4a9de2" }}>
                <IconCategory style={{ cursor: "pointer", marginTop: "5px" }} />
              </span>
            ),
            callback: () => {
              handleAdjust(row);
            },
          });
        }
        if (row.document_type != "1") {
          if (row.payment_locked) {
            actions.push({
              icon: (
                <span title="Payment Made" style={{ color: "#4a9de2" }}>
                  <IconLock style={{ cursor: "pointer", marginTop: "5px", color: "red" }} />
                </span>
              ),
              callback: () => {},
            });
          } else {
            actions.push(
              {
                icon: (
                  <span title="Remove Adjustment" style={{ color: "#4a9de2" }}>
                    <IconRemove style={{ cursor: "pointer", marginTop: "5px", color: "red" }} />
                  </span>
                ),
                callback: () => {
                  handleRemove(row);
                },
              },
              {
                icon: (
                  <span title="Edit">
                    <IconEdit style={{ cursor: "pointer", marginLeft: "5px", marginTop: "5px", color: "orange" }} />
                  </span>
                ),
                callback: () => {
                  handleAdjust(row);
                },
              },
            );
          }
        }
        return (
          <div style={{ textAlign: "center" }}>
            <CellActionsFormatter actions={actions} />
          </div>
        );
      },
    },
    {
      key: "status",
      name: "#S" + postValue,
      width: cellWidthSmall,
      formatter: StatusFormatter,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"status"} data={data} section="invoice" />;
      },
      filterable: true,
    },
    {
      key: "sale_typename",
      name: "Sale Type" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"sale_typename"} data={data} section={"producersadjustments"} />;
      },
    },
    {
      key: "sale_id",
      name: "Sale id" + postValue,
      width: cellWidthSmall,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"sale_id"} data={data} section={"producersadjustments"} />;
      },
    },
    {
      key: "saleadjustment_creditorinvoicenumber",
      name: "Invoice Number" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return (
          <CustomAutoCompleteFilter
            {...e}
            multiSelection={true}
            currentFilters={filters}
            fieldname={"saleadjustment_creditorinvoicenumber"}
            data={data}
            section={"producersadjustments"}
          />
        );
      },
    },
    {
      key: "dispatch_invoicenumber",
      name: "Dispatch Invoice" + postValue,
      width: cellWidthMedium,
      filterable: true,
      formatter: (props) => <EnquiryLink {...props} selector={Selector.Barcode} />,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"dispatch_invoicenumber"} data={data} section={"producersadjustments"} />;
      },
    },
    {
      key: "producercode",
      name: "Producer" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"producercode"} data={data} section={"producersadjustments"} />;
      },
    },
    {
      key: "sale_date",
      name: "Date" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"sale_date"} data={data} section={"producersadjustments"} />;
      },
      formatter: DateFormatter,
    },
    {
      key: "dispatchdocs_id",
      name: "Dispatch" + postValue,
      width: cellWidthSmall,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"dispatchdocs_id"} data={data} section={"producersadjustments"} />;
      },
    },
    {
      key: "terms_code",
      name: "Selling Term" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"terms_code"} data={data} section={"producersadjustments"} />;
      },
    },
    {
      key: "currency_code",
      name: "Currency" + postValue,
      width: cellWidthSmall,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"currency"} data={data} section={"producersadjustments"} />;
      },
    },
    {
      key: "sale_total",
      name: "Sale Total" + postValue,
      width: cellWidthSmall,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"sale_total"} data={data} section={"producersadjustments"} />;
      },
      formatter: FormatterNumber2Decimals,
      summaryFormatter: SummaryNumber2Decimal,
    },
    {
      key: "amountCredit",
      name: "Credit" + postValue,
      width: cellWidthSmall,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"amountCredit"} data={data} section={"producersadjustments"} />;
      },
      formatter: FormatterNumber2Decimals,
      summaryFormatter: SummaryNumber2Decimal,
    },
    {
      key: "amountDebit",
      name: "Debit" + postValue,
      width: cellWidthSmall,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"amountDebit"} data={data} section={"producersadjustments"} />;
      },
      formatter: FormatterNumber2Decimals,
      summaryFormatter: SummaryNumber2Decimal,
    },
    {
      key: "sale_totalfinal",
      name: "Nett Total" + postValue,
      width: cellWidthSmall,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"sale_totalfinal"} data={data} section={"producersadjustments"} />;
      },
      formatter: FormatterNumber2Decimals,
      summaryFormatter: SummaryNumber2Decimal,
    },
    {
      key: "salefundsallocation_amount",
      name: "Funds" + postValue,
      width: cellWidthSmall,
      filterable: true,
      filterRenderer: (e) => {
        return (
          <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"salefundsallocation_amount"} data={data} section={"producersadjustments"} />
        );
      },
      formatter: FormatterNumber2Decimals,
      summaryFormatter: SummaryNumber2Decimal,
    },
    {
      key: "sale_payment1amount",
      name: "Payment 1" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"sale_payment1amount"} data={data} section={"producersadjustments"} />;
      },
      formatter: FormatterNumber2Decimals,
      summaryFormatter: SummaryNumber2Decimal,
    },
    {
      key: "sale_payment1amount_remain",
      name: "Payment 1 Remain" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return (
          <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"sale_payment1amount_remain"} data={data} section={"producersadjustments"} />
        );
      },
      formatter: FormatterNumber2Decimals,
      summaryFormatter: SummaryNumber2Decimal,
    },
    {
      key: "sale_payment1date",
      name: "Due 1" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"sale_payment1date"} data={data} section={"producersadjustments"} />;
      },
      formatter: DateFormatter,
    },
    {
      key: "sale_payment2amount",
      name: "Payment 2" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"sale_payment2amount"} data={data} section={"producersadjustments"} />;
      },
      formatter: FormatterNumber2Decimals,
      summaryFormatter: SummaryNumber2Decimal,
    },
    {
      key: "sale_payment2amount_remain",
      name: "Payment 2 Remain" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return (
          <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"sale_payment2amount_remain"} data={data} section={"producersadjustments"} />
        );
      },
      formatter: FormatterNumber2Decimals,
      summaryFormatter: SummaryNumber2Decimal,
    },
    {
      key: "sale_payment2date",
      name: "Due 2" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"sale_payment2date"} data={data} section={"producersadjustments"} />;
      },
      formatter: DateFormatter,
    },
    {
      key: "sale_payment3amount",
      name: "Payment 3" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"sale_payment3amount"} data={data} section={"producersadjustments"} />;
      },
      formatter: FormatterNumber2Decimals,
      summaryFormatter: SummaryNumber2Decimal,
    },
    {
      key: "sale_payment3amount_remain",
      name: "Payment 3 Remain" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return (
          <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"sale_payment3amount_remain"} data={data} section={"producersadjustments"} />
        );
      },
      formatter: FormatterNumber2Decimals,
      summaryFormatter: SummaryNumber2Decimal
    },
    {
      key: "sale_payment3date",
      name: "Due 3" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"sale_payment3date"} data={data} section={"producersadjustments"} />;
      },
      formatter: DateFormatter,
    },
    {
      key: "sale_exchangerate",
      name: "Exchange Rate" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"sale_exchangerate"} data={data} section={"producersadjustments"} />;
      },
      formatter: FormatterNumber2Decimals,
    },
    {
      key: "sale_created_at",
      name: "Created At" + postValue,
      width: cellWidthSmall,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"sale_created_at"} data={data} section={"producersadjustments"} />;
      },
      formatter: DateFormatter,
    },
    {
      key: "sale_updated_at",
      name: "Updated At" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"sale_updated_at"} data={data} section={"producersadjustments"} />;
      },
      formatter: DateFormatter,
    },
  ].map((c) => ({ ...c, ...defaultColumnProperties }));

  const columnsArranged = [];
  if (arrangement && arrangement.length > 0) {
    arrangement.map((item) => {
      const indexOfItem = columns.indexOf(columns.find((col) => col.name.trim() == item.toString().trim()));
      if (indexOfItem != -1) {
        columnsArranged.push(columns[indexOfItem]);
      }
    });
  } else {
    columns.map((colItem) => {
      columnsArranged.push(colItem);
    });
  }

  if (columnsWidth && columnsWidth.length > 0) {
    for (let index = 0; index < columnsArranged.length; index++) {
      const element = columnsArranged[index];
      const colItem = columnsWidth.find((colwitem) => colwitem.name.trim() == element.name.trim());
      if (colItem) {
        element.width = colItem.width;
      }
    }
  }

  return columns;
};
