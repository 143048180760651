import { client } from "./_base";

export const classes = client("class", item => ({
  ...item,
}));

export const classesAllSortedMappedforCombo = async () => {
  const result = await classes.all().then(data => {
    return data
      .sort((a, b) => { 
      if (Number(a.code) > Number(b.code)) return 1;
      if (Number(a.code) < Number(b.code)) return -1;
      return 1;
    })
    .map(item => {
      return { id: item.id, value: `${item.code}` }
    });
  });
  return result;
}