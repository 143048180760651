import React, { useEffect, useState } from "react";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";

import DetailGrid, { DetailGridType } from "./edit/detailgrid";
import AdhocInvoiceGrid from "./grid";

import Confirmation from "../lib/components/confirmation";
import { getAdhocInvoiceDetailByAdhocId } from "../lib/api/adhoc_invoice_detail";
import { IAdhocInvoice, adhocInvoice, getAdhocInvoiceFull } from "../lib/api/adhoc_invoice";

import Splitter from "m-react-splitters";

import LinearProgress from "@material-ui/core/LinearProgress";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      overflow: "hidden",
      width: "100%",
      height: "calc(100% - 65px)",
    },
    toolbarWrapper: {
      position: "relative",
    },
    toolbar: {
      position: "absolute",
      left: 0,
      width: "100%",
    },
    mainSplitterDiv: {
      height: "100%",
      width: "100%",
      display: "grid",
    },
  });

type AdhocInvoiceProps = {
  history: any;
} & WithStyles<typeof styles>;

const AdhocInvoiceUnstyled: React.FC<AdhocInvoiceProps> = ({ classes, history }) => {
  const [data, setData] = useState<IAdhocInvoice[]>([]);
  const [confirmRemoveId, setConfirmRemoveId] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(true);
  const [adhocDetails, setAdhocdetails] = useState([]);
  const [selectedAdhocId, setSelectedAdhocId] = useState<number>(0);
  const [minGridHeight, setMinGridHeight] = useState(300);
  const [minGridHeightSecond, setMinGridHeightSecond] = useState(300);

  const loadData = async () => {
    setLoading(true);
    const adhocInvoiceData = await getAdhocInvoiceFull();
    setData(adhocInvoiceData.data);
    setGridHeights();
    setLoading(false);
  };

  const handleSelectRow = async (row: IAdhocInvoice) => {
    setSelectedAdhocId(row.id);
    const details = await getAdhocInvoiceDetailByAdhocId(row.id);
    setAdhocdetails(details.data);
  };

  const handleRemove = (adhoc_invoiceid: number) => {
    setConfirmRemoveId(adhoc_invoiceid);
  };

  const handleCloseDeleteConfirm = () => {
    setConfirmRemoveId(0);
  };

  const handleConfirmDelete = async () => {
    setLoading(true);
    await adhocInvoice.remove(confirmRemoveId);
    setConfirmRemoveId(0);
    loadData();
  };

  const setGridHeights = () => {
    const primarysplit = document.getElementsByClassName("splitter horizontal");
    setMinGridHeight(primarysplit[0].children[0].clientHeight - 90);
    setMinGridHeightSecond(primarysplit[0].children[2].clientHeight - 90);
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <div className={classes.root}>
      {loading && <LinearProgress color="secondary" style={{ marginBottom: "5px" }} />}
      {confirmRemoveId > 0 && (
        <Confirmation
          isOpen={true}
          handleClose={handleCloseDeleteConfirm}
          handleConfirm={handleConfirmDelete}
          title={`Remove Adhoc Invoice`}
          body={`Are you sure you want to remove this Adhoc Invoice?`}
        ></Confirmation>
      )}
      <div id="main_splitter_div" className={classes.mainSplitterDiv}>
        <Splitter
          postPoned
          dispatchResize
          position="horizontal"
          primaryPaneWidth="100%"
          primaryPaneHeight="calc(100% - 500px)"
          primaryPaneMaxHeight="calc(100% - 300px)"
          onDragFinished={(e) => setGridHeights()}
        >
          <AdhocInvoiceGrid
            data={data}
            loading={loading}
            history={history}
            handleRefresh={loadData}
            handleRemove={handleRemove}
            selectedAdhocId={selectedAdhocId}
            handleSelectRow={handleSelectRow}
            gridHeight={minGridHeight}
          />
          <DetailGrid
            documentDetail={data}
            selectedDetail={adhocDetails}
            gridHeight={minGridHeightSecond}
            handleRowChange={setAdhocdetails}
            gridType={DetailGridType.GenerateReport}
            handleClearRow={() => setAdhocdetails([])}
          />
        </Splitter>
      </div>
    </div>
  );
};

export default withStyles(styles)(AdhocInvoiceUnstyled);
