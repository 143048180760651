import React, { useEffect, useState } from "react";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";

import LinearProgress from "@material-ui/core/LinearProgress";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";

import CloseIcon from "@material-ui/icons/Close";

import Confirmation from "../../lib/components/confirmation";
import { FileImportTemplateType } from "../../lib/api/FileImportTemplate";
import { FileImportTemplateDetailType, findByTemplateId } from "../../lib/api/FileImportTemplateDetail";
import { FileImportTemplateDetailColumns } from "../../lib/api/FileImportHeaderInfo";

import { Field, Form } from "react-final-form";
import { TextField } from "final-form-material-ui";

import Select from "react-select";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1),
      // width: "600px",
      height: "100%",
    },
    divActions: {
      float: "right",
    },
    container: {
      paddingTop: "10px",
    },
    tableCellLabel: {
      width: "200px",
      borderBottom: "none",
      height: "50px",
      textAlign: "right",
      paddingTop: "5px",
    },
    selectLabel: {
      width: "70px",
      borderBottom: "none",
      textAlign: "right",
      paddingTop: "5px",
    },
    formDetail: {},
    tableCellDetail: {
      display: "flex",
      flexDirection: "row",
      marginTop: "20px",
    },
    tableCellsValueSelect: {
      textAlign: "left",
      width: "100%",
      borderBottom: "none",
      height: "50px",
      padding: 0,
      marginTop: "-14px",
    },
    tableCellDetailDate: {
      marginTop: "-5px",
      width: "350px",
      borderBottom: "none",
      height: "50px",
    },
    formGridDetail: {
      marginTop: "30px",
      display: "flex",
      flexDirection: "row",
      rowGap: "50px",
      columnGap: "25px",
      flexWrap: "wrap",
      paddingBottom: "50px",
    },
    clearSelect: {
      position: "absolute",
      right: "45px",
      top: "7px",
      cursor: "pointer",
      color: "#999",
      "&:hover": {
        color: "#777",
      },
    },
    selectWrapper: {
      display: "grid",
      gridTemplateColumns: "70px 230px",
      gridGap: "20px",
    },
  });

const ColumnName = (index) => {
  const ordA = "A".charCodeAt(0);
  const ordZ = "Z".charCodeAt(0);
  const len = ordZ - ordA + 1;

  let name = "";
  while (index >= 0) {
    name = String.fromCharCode((index % len) + ordA) + name;
    index = Math.floor(index / len) - 1;
  }

  return name;
};

type FileImportTemplateProps = {
  selectedRecord: FileImportTemplateType;
  handleProcess: (data, templateDetailColumns: any[], reloadSelectedRecord?) => Promise<number>;
} & WithStyles<typeof styles>;

const FileImportUnstyled: React.FunctionComponent<FileImportTemplateProps> = (props) => {
  const { classes, selectedRecord, handleProcess } = props;

  const [hasHeader, toggleHasHeader] = useState<boolean>(selectedRecord.templatehasheader === 1 ? true : false || false);
  const [loading, setLoading] = useState(true);
  const [columnsAvailable, setColumnsAvailable] = useState([]);
  const [templateDetails, setTemplateDetails] = useState([]);
  const [rows, setRows] = useState([]);
  const [version, setVersion] = useState<number>(0);
  const [confirm, setConfirm] = useState<boolean>(false);

  useEffect(() => {
    if (selectedRecord.id) {
      findByTemplateId(selectedRecord.id).then((result) => {
        setTemplateDetails(result);
      });
    }

    setLoading(false);
  }, [selectedRecord]);

  useEffect(() => {
    const resultColsAvailable = FileImportTemplateDetailColumns.sort((a, b) => a.localeCompare(b)).reduce((arr, item) => {
      if (item != "FileImport_id" && item != "id") {
        arr.push({ value: item, label: item, data: item });
      }
      return arr;
    }, []);

    const availableColumns = resultColsAvailable.filter((row) => !rows.find((res) => row.label == res.label));

    setColumnsAvailable(availableColumns);
  }, [rows]);

  useEffect(() => {
    const rowData = new Array(66).fill(1).reduce((arr, _, indx) => {
      const item = templateDetails.find((item) => item.colposition === indx + 1);
      if (item) {
        arr.push({ id: item.id, colname: item.colname, colposition: item.colposition, label: item.colname });
      } else {
        arr.push({ id: undefined, colname: "", colposition: indx + 1, label: "" });
      }
      return arr;
    }, []);
    setRows(rowData);
  }, [templateDetails]);

  const handleHasHeaderChange = (event: any) => {
    toggleHasHeader(event.target.checked);
  };

  const handleColumnSelect = (row: any, colposition: any) => {
    setVersion(version + 1);
    const exists = templateDetails.find((item) => parseInt(item.colposition) === parseInt(colposition));

    if (exists) {
      const update: FileImportTemplateDetailType = { ...exists, colname: row.value, colposition, delete: row.value ? 0 : exists.id ? 1 : 0 };
      const filtered = templateDetails.filter((item) => item.colposition != exists.colposition);
      setTemplateDetails([...filtered, update]);
    } else {
      const create: FileImportTemplateDetailType = { id: undefined, FileImportTemplate_id: undefined, colposition: colposition, colname: row.value, delete: 0 };
      setTemplateDetails([...templateDetails, create]);
    }
  };

  const handleClearValue = (colposition) => {
    setVersion(version + 1);
    const data = templateDetails.map((row) => {
      if (row.colposition == colposition) {
        return { ...row, colname: "" };
      }
      return row;
    });
    setTemplateDetails(data);
  };

  const handleCloseForm = () => {
    if (version > 0) {
      setConfirm(true);
    } else {
      handleProcess(undefined, []);
    }
  };

  const handleConfirmation = () => {
    handleProcess(undefined, []);
  };

  const handleConfirmationClose = () => {
    setConfirm(false);
  };

  return (
    <div className={classes.root}>
      {confirm && (
        <Confirmation
          isOpen={true}
          handleClose={handleConfirmationClose}
          handleConfirm={handleConfirmation}
          title={`Discard changes`}
          body={`Are you sure you want to discard your changes?`}
        />
      )}
      {loading ? (
        <LinearProgress />
      ) : (
        <div>
          <Form
            initialValues={{
              ...selectedRecord,
            }}
            onSubmit={(values: any) => {
              const templateDetailColumns = version > 0 ? templateDetails : [];
              handleProcess(
                {
                  ...values,
                  id: selectedRecord ? selectedRecord.id : undefined,
                  templatehasheader: hasHeader ? 1 : 0,
                },
                templateDetailColumns,
              );
            }}
            validate={(values) => {
              let errors = {};
              return errors;
            }}
            render={({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <div style={{ paddingBottom: "10px" }}>
                  <Button name="submit" type="submit" variant="contained" color="primary" style={{ margin: "2px" }} disabled={false}>
                    Save And Close
                  </Button>
                  <Button name="close" variant="contained" color="secondary" style={{ margin: "2px" }} disabled={false} onClick={handleCloseForm}>
                    Close
                  </Button>
                </div>
                <div className={classes.container}>
                  <div className={classes.formDetail}>
                    <TableFieldText classes={classes} field="templatename" title="Template Name" disabled={false} />
                    <TableFieldCheckbox classes={classes} title="Has Header" disabled={false} state={hasHeader} setState={handleHasHeaderChange} />
                    <TableFieldText classes={classes} field="templatespecialcharacter" title="Exclude Special Characters" disabled={false} />
                  </div>
                  <div className={classes.formGridDetail}>
                    {rows.map((row, indx) => (
                      <TableFieldCombo
                        row={row}
                        classes={classes}
                        data={templateDetails}
                        colposition={indx + 1}
                        columns={columnsAvailable}
                        title={ColumnName(indx)}
                        onChange={handleColumnSelect}
                        handleClearValue={handleClearValue}
                      />
                    ))}
                  </div>
                </div>
                <div className={classes.divActions}></div>
              </form>
            )}
          />
        </div>
      )}
    </div>
  );
};

export default withStyles(styles)(FileImportUnstyled);

const TableFieldText: React.FunctionComponent<{ field: string; title: string; disabled: boolean } & WithStyles<typeof styles>> = (props) => {
  const { classes, field, title, disabled } = props;
  return (
    <div style={{ display: "grid", gridTemplateColumns: "200px 300px", gridGap: "10px" }}>
      <div className={classes.tableCellLabel}>{`${title}:`}</div>
      <Field name={field} component={TextField} type="text" fullWidth={true} disabled={disabled} />
    </div>
  );
};

const TableFieldCheckbox: React.FunctionComponent<
  {
    title: string;
    state: boolean;
    disabled: boolean;
    setState: (event: any) => void;
  } & WithStyles<typeof styles>
> = (props) => {
  const { classes, title, state, setState, disabled } = props;

  return (
    <div style={{ display: "grid", gridTemplateColumns: "200px min-content", gridGap: "10px" }}>
      <span className={classes.tableCellLabel}>{`${title}:`}</span>
      <Checkbox checked={state} onChange={setState} disabled={disabled} />
    </div>
  );
};

const TableFieldCombo: React.FunctionComponent<
  {
    row: any;
    data: any;
    classes: any;
    columns: any;
    title: string;
    colposition: number;
    onChange(colname: any, colposition: any): void;
    handleClearValue(colposition: any): void;
  } & WithStyles<typeof styles>
> = (props) => {
  const { row, classes, title, data, onChange, colposition, columns, handleClearValue } = props;

  return (
    <div className={classes.selectWrapper}>
      <span className={classes.selectLabel}>{`${title}:`}</span>
      <div style={{ position: "relative" }}>
        <Select options={columns} value={row} onChange={(data) => onChange(data, colposition)} placeholder="Select..." />
        <CloseIcon className={classes.clearSelect} onClick={() => handleClearValue(colposition)} />
      </div>
    </div>
  );
};
