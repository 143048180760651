import React from "react";

import CustomAutoCompleteFilter from "../../../lib/components/customdatagridfilter/CustomFilter";
import { FormatterNumber, FormatterNumber2Decimals, DateFormatter } from "../../../lib/components/Formatters";
import { SummaryTotal, SummaryNumber2Decimal } from "../../../lib/components/SummaryFormatter";
import { handleColumnsArrangement, handleColumnsWidth } from "../../../lib/helpers/grid";

import numeral from "numeral";

const cellWidthTiny = 40;
const cellWidthSmall = 80;
const cellWidthMedium = 100;
const cellWidthLarge = 200;

const defaultColumnProperties = {
  resizable: true,
  sortable: true,
};

const IdentFormatter = ({ row, column }) => {
  const data = row[column.key];

  if (!data) {
    return <div></div>;
  }

  return <div>PP{numeral(data).format("0000")}</div>;
};

let currentSpace = true;
export const GridColumns = (data, filters, arrangement = undefined, columnsWidth = undefined) => {
  let postValue = "";

  if (data) {
    if (currentSpace) {
      postValue = " ";
    }
    currentSpace = !currentSpace;
  }

  const columns = [
    {
      key: "producercode",
      name: "Producer ID" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"producercode"} data={data} section="producersfixedpaymentmain" />;
      },
      summaryFormatter: SummaryTotal,
    },
    {
      key: "sale_invoicenumber",
      name: "Invoice" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"sale_invoicenumber"} data={data} section="producersfixedpaymentmain" />;
      },
    },
    {
      key: "dispatch_invoicenumber",
      name: "Sale Invoice" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return (
          <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"dispatch_invoicenumber"} data={data} section="producersfixedpaymentmain" />
        );
      },
    },
    {
      key: "sale_date",
      name: "Invoice Date" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"sale_date"} data={data} section="producersfixedpaymentmain" />;
      },
      formatter: DateFormatter,
    },
    {
      key: "dispatch_containerno",
      name: "Container" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return (
          <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"dispatch_containerno"} data={data} section="producersfixedpaymentmain" />
        );
      },
    },
    {
      key: "vessel_code",
      name: "Vessel" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"vessel_code"} data={data} section="producersfixedpaymentmain" />;
      },
    },
    {
      key: "loadout_etd",
      name: "ETD" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"loadout_etd"} data={data} section="producersfixedpaymentmain" />;
      },
      formatter: DateFormatter,
    },
    {
      key: "loadout_eta",
      name: "ETA" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"loadout_eta"} data={data} section="producersfixedpaymentmain" />;
      },
      formatter: DateFormatter,
    },
    {
      key: "terms_code",
      name: "Terms" + postValue,
      width: cellWidthLarge,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"terms_code"} data={data} section="producersfixedpaymentmain" />;
      },
    },
    {
      key: "currency_code",
      name: "Currency" + postValue,
      width: cellWidthSmall,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"currency_code"} data={data} section="producersfixedpaymentmain" />;
      },
    },
    {
      key: "sale_total",
      name: "Total" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"sale_total"} data={data} section="producersfixedpaymentmain" />;
      },
      formatter: FormatterNumber2Decimals,
      summaryFormatter: SummaryNumber2Decimal,
    },
    {
      key: "amountCredit",
      name: "Credit" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"amountCredit"} data={data} section="producersfixedpaymentmain" />;
      },
      formatter: FormatterNumber2Decimals,
      summaryFormatter: SummaryNumber2Decimal,
    },
    {
      key: "amountDebit",
      name: "Debit" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"amountDebit"} data={data} section="producersfixedpaymentmain" />;
      },
      formatter: FormatterNumber2Decimals,
      summaryFormatter: SummaryNumber2Decimal,
    },
    {
      key: "sale_totalfinal_sum",
      name: "Total Final" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"sale_totalfinal_sum"} data={data} section="producersfixedpaymentmain" />;
      },
      formatter: FormatterNumber2Decimals,
      summaryFormatter: SummaryNumber2Decimal,
    },
    {
      key: "paymentnumber",
      name: "Pmnt Num" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"paymentnumber"} data={data} section="producersfixedpaymentmain" />;
      },
      formatter: FormatterNumber,
    },
    {
      key: "paymentdue",
      name: "Due Date" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"paymentdue"} data={data} section="producersfixedpaymentmain" />;
      },
      formatter: DateFormatter,
    },
    {
      key: "paymentvalue",
      name: "Amount Due" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"paymentvalue"} data={data} section="producersfixedpaymentmain" />;
      },
      formatter: FormatterNumber2Decimals,
      summaryFormatter: SummaryNumber2Decimal,
    },
    {
      key: "paymentoutstanding",
      name: "Outstanding" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"paymentoutstanding"} data={data} section="producersfixedpaymentmain" />;
      },
      formatter: FormatterNumber2Decimals,
      summaryFormatter: SummaryNumber2Decimal,
    },
    {
      key: "paidAdjustments_payment_date",
      name: "Payment Date" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return (
          <CustomAutoCompleteFilter
            {...e}
            multiSelection={true}
            currentFilters={filters}
            fieldname={"paidAdjustments_payment_date"}
            data={data}
            section="producersfixedpaymentmain"
          />
        );
      },
      formatter: DateFormatter,
    },
    {
      key: "paidAdjustments_ident",
      name: "Remittance" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return (
          <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"paidAdjustments_ident"} data={data} section="producersfixedpaymentmain" />
        );
      },
      formatter: IdentFormatter,
    },
    {
      key: "paidAdjustments_amount",
      name: "Amount Paid" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return (
          <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"paidAdjustments_amount"} data={data} section="producersfixedpaymentmain" />
        );
      },
      formatter: FormatterNumber2Decimals,
      summaryFormatter: SummaryNumber2Decimal,
    },
    {
      key: "paidAdjustments_makeanote",
      name: "Note" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return (
          <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"paidAdjustments_makeanote"} data={data} section="producersfixedpaymentmain" />
        );
      },
    },
  ].map((c) => ({ ...c, ...defaultColumnProperties }));

  let columnsArranged = [];
  columnsArranged = handleColumnsArrangement(columnsArranged, arrangement, columns);
  columnsArranged = handleColumnsWidth(columnsArranged, columnsWidth, columns);

  return columnsArranged;
};
