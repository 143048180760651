import React from "react";

import Grid from "../../../lib/components/grid";
import { GridColumns } from "./gridsetup";

type AllocationSummaryGridProps = {
  data: any[];
  loading: boolean;
};

export class AllocationSummaryGrid extends React.Component<AllocationSummaryGridProps, {}> {
  constructor(props) {
    super(props);
  }

  state = {};

  render() {
    return (
      <Grid
        forceHeight={700}
        data={this.props.data}
        GridColumns={GridColumns}
        loading={this.props.loading}
        clearFilters="allocationsummarygrid"
        totalRowColumns={["received_amount"]}
      />
    );
  }
}
