import React, { FC, useState } from "react";
import { createStyles, Theme, WithStyles, withStyles } from "@material-ui/core/styles";

import { DocumentsType, MenuItemsType, StyledMenu } from "./DocumentsButton";

import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";
import SvgIcon from "@material-ui/core/SvgIcon";

import grey from "@material-ui/core/colors/grey";

import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { MenuList } from "./MenuList";
import Popover from "@material-ui/core/Popover";
import Divider from "@material-ui/core/Divider";

type MenuListProps = {
  menuItem: MenuItemsType;
  index: number;
} & WithStyles<typeof styles>;

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: "grid",
      gridTemplateColumns: "50px 1fr 50px",
      gap: "10px",
    },
    optionSelector: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      "& div": {
        // padding: "2px",
        "&:hover": {
          backgroundColor: grey[400],
        },
      },
    },
  });

const MenuListItemMultipleUnstyled: FC<MenuListProps> = ({ menuItem, index, classes }) => {
  const [anchorEl, setAnchorEl] = useState(undefined);

  const handleOpenList = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(undefined);
  };

  return (
    <>
      <StyledMenuItem key={`export-${index}`} onClick={handleOpenList} className={classes.root} disabled={menuItem.disabled}>
        <RenderIcon icon={menuItem.icon} />
        <ListItemText primary={menuItem.title} />
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <ArrowForwardIosIcon />
        </div>
      </StyledMenuItem>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        {menuItem.children.map((menuItemChild, childIndex) => (
          <MenuList key={`menulistiem-${childIndex}`} menuItem={menuItemChild} index={childIndex * 10} />
        ))}
      </Popover>
    </>
  );
};

const MenuListItemSingleUnstyled: FC<MenuListProps> = ({ menuItem, index, classes }) => (
  <StyledMenuItem key={`export-${index}`} onClick={menuItem.action} className={classes.root} disabled={menuItem.disabled}>
    <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
      <RenderIcon icon={menuItem.icon} />
    </div>
    <ListItemText primary={menuItem.title} />
    <span></span>
  </StyledMenuItem>
);

const MenuListItemOptionsUnstyled: FC<MenuListProps> = ({ menuItem, index, classes }) => (
  <MenuItem key={`export-${index}`} className={classes.root} disabled={menuItem.disabled}>
    <div className={classes.optionSelector}>
      {menuItem.options.map((item, i) => (
        <div onClick={item.action}>
          <RenderIcon icon={item.icon} />
        </div>
      ))}
    </div>
    <ListItemText primary={menuItem.title} />
    <span></span>
  </MenuItem>
);

const StyledMenuItem = withStyles((theme) => ({
  root: {
    // padding: "5px 10px 5px 10px",
    "&:focus": {
      backgroundColor: grey[300],
    },
  },
}))(MenuItem);

export const MenuListItemMultiple = withStyles(styles)(MenuListItemMultipleUnstyled);

export const MenuListItemSingle = withStyles(styles)(MenuListItemSingleUnstyled);

export const MenuListItemOptions = withStyles(styles)(MenuListItemOptionsUnstyled);

export const RenderIcon = ({ icon }) => {
  if (!icon) {
    return <div></div>;
  }

  return icon == DocumentsType.EXCEL ? <ExcelIcon /> : icon == DocumentsType.PDF ? <PDFIcon /> : <DocumentIcon />;
};

// --- ICONS ---
export const ExcelIcon = () => (
  <SvgIcon>
    <svg xmlns="http://www.w3.org/2000/svg" strokeWidth="3" stroke="#007732" enable-background="new 0 0 128 128" viewBox="0 0 128 128">
      <path fill="#007732" d="M80,96h-8.3l-8-13l-8,13H48l11.4-17.7L48.7,61.7h8l7.4,12.3l7.2-12.3h7.8l-10.8,17L80,96z" />
      <path
        fill="#007732"
        d="M104,126H24c-5.5,0-10-4.5-10-10V12c0-5.5,4.5-10,10-10h40.7c2.7,0,5.2,1,7.1,2.9l39.3,39.3     c1.9,1.9,2.9,4.4,2.9,7.1V116C114,121.5,109.5,126,104,126z M24,6c-3.3,0-6,2.7-6,6v104c0,3.3,2.7,6,6,6h80c3.3,0,6-2.7,6-6V51.3     c0-1.6-0.6-3.1-1.8-4.2L68.9,7.8C67.8,6.6,66.3,6,64.7,6H24z"
      />
    </svg>
  </SvgIcon>
);

export const PDFIcon = () => (
  <SvgIcon>
    <svg xmlns="http://www.w3.org/2000/svg" strokeWidth="3" stroke="#fd4233" enable-background="new 0 0 128 128" viewBox="0 0 128 128">
      <path
        fill="#fd4233"
        d="M104,126H24c-5.514,0-10-4.486-10-10V12c0-5.514,4.486-10,10-10h40.687      c2.671,0,5.183,1.041,7.07,2.929l39.314,39.314c1.889,1.889,2.929,4.399,2.929,7.07V116C114,121.514,109.514,126,104,126z M24,6      c-3.309,0-6,2.691-6,6v104c0,3.309,2.691,6,6,6h80c3.309,0,6-2.691,6-6V51.313c0-1.579-0.641-3.125-1.757-4.242L68.929,7.757      C67.796,6.624,66.289,6,64.687,6H24z"
      />
      <path
        fill="#ff402f"
        d="M95.21,80.32c-0.07-0.51-0.48-1.15-0.92-1.58c-1.26-1.24-4.03-1.89-8.25-1.95      c-2.86-0.03-6.3,0.22-9.92,0.73c-1.62-0.93-3.29-1.95-4.6-3.18c-3.53-3.29-6.47-7.86-8.31-12.89c0.12-0.47,0.22-0.88,0.32-1.3      c0,0,1.98-11.28,1.46-15.1c-0.07-0.52-0.12-0.67-0.26-1.08l-0.17-0.44c-0.54-1.25-1.6-2.57-3.26-2.5L60.32,41H60.3      c-1.86,0-3.36,0.95-3.76,2.36c-1.2,4.44,0.04,11.09,2.29,19.69l-0.58,1.4c-1.61,3.94-3.63,7.9-5.41,11.39l-0.23,0.45      c-1.88,3.67-3.58,6.79-5.13,9.43l-1.59,0.84c-0.12,0.06-2.85,1.51-3.49,1.89c-5.43,3.25-9.03,6.93-9.63,9.85      c-0.19,0.94-0.05,2.13,0.92,2.68l1.54,0.78c0.67,0.33,1.38,0.5,2.1,0.5c3.87,0,8.36-4.82,14.55-15.62      c7.14-2.32,15.28-4.26,22.41-5.32c5.43,3.05,12.11,5.18,16.33,5.18c0.75,0,1.4-0.07,1.92-0.21c0.81-0.22,1.49-0.68,1.91-1.3      C95.27,83.76,95.43,82.06,95.21,80.32z M36.49,99.33c0.7-1.93,3.5-5.75,7.63-9.13c0.26-0.21,0.9-0.81,1.48-1.37      C41.28,95.72,38.39,98.46,36.49,99.33z M60.95,43c1.24,0,1.95,3.13,2.01,6.07c0.06,2.94-0.63,5-1.48,6.53      c-0.71-2.26-1.05-5.82-1.05-8.15C60.43,47.45,60.38,43,60.95,43z M53.65,83.14c0.87-1.55,1.77-3.19,2.69-4.92      c2.25-4.25,3.67-7.57,4.72-10.3c2.1,3.82,4.72,7.07,7.79,9.67c0.39,0.32,0.8,0.65,1.22,0.98C63.82,79.8,58.41,81.31,53.65,83.14      z M93.08,82.79c-0.38,0.23-1.47,0.37-2.17,0.37c-2.26,0-5.07-1.03-9-2.72c1.51-0.11,2.9-0.17,4.14-0.17      c2.27,0,2.94-0.01,5.17,0.56C93.44,81.4,93.47,82.55,93.08,82.79z"
      />
    </svg>
  </SvgIcon>
);

const DocumentIcon = () => (
  <SvgIcon>
    <svg xmlns="http://www.w3.org/2000/svg" strokeWidth="3" stroke="#000" enable-background="new 0 0 90 90" viewBox="0 0 90 90">
      <path d="M79.2 32.4L57.4 10.6c-.4-.4-.9-.6-1.4-.6H24c-2.2 0-4 1.8-4 4v72c0 2.2 1.8 4 4 4h52c2.2 0 4-1.8 4-4V33.7c-.1-.6-.4-.9-.8-1.3zM58 16.8L73.2 32H58V16.8zM76 86H24V14h30v20c0 1.1.9 2 2 2h20v50z" />
      <path fill="#000" d="M384-790V894h-1784V-790H384m8-8h-1800V902H392V-798z" />
    </svg>
  </SvgIcon>
);
