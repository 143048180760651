import React from "react";

import CustomAutoCompleteFilter from "../lib/components/customdatagridfilter/CustomFilter";
import { DateFormatter, FormatterNumber } from "../lib/components/Formatters";
import { SummaryNumber2Decimal } from "../lib/components/SummaryFormatter";

const cellWidthSmall = 65;
const cellWidthMedium = 100;
const cellWidthLarge = 170;

const defaultColumnProperties = {
  filterable: true,
  resizable: true,
  sortable: true,
};

let currentSpace = true;
export const EnquiryEdiHeaderGridColumns = (data, filters, arrangement, columnsWidth) => {
  let postValue = "";

  if (data) {
    if (currentSpace) {
      postValue = " ";
    }
    currentSpace = !currentSpace;
  }

  const columns = [
    {
      key: "filename",
      name: "Filename" + postValue,
      width: cellWidthLarge,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"filename"} data={data} section={"enquiryediheadergrid"} />;
      },
    },
    {
      key: "Barcode",
      name: "Barcode" + postValue,
      width: cellWidthLarge,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Barcode"} data={data} section={"enquiryediheadergrid"} />;
      },
    },
    {
      key: "Date",
      name: "Date" + postValue,
      width: cellWidthMedium,
      formatter: ({ row, column }) => (row[column.key] ? <DateFormatter row={row} column={column} includeTime /> : null),
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Date"} data={data} section={"enquiryediheadergrid"} />;
      },
    },
    {
      key: "Account",
      name: "Account" + postValue,
      width: cellWidthMedium,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Account"} data={data} section={"enquiryediheadergrid"} />;
      },
    },
    {
      key: "Channel",
      name: "Channel" + postValue,
      width: cellWidthMedium,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Channel"} data={data} section={"enquiryediheadergrid"} />;
      },
    },
    {
      key: "Season",
      name: "Season" + postValue,
      width: cellWidthMedium,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Season"} data={data} section={"enquiryediheadergrid"} />;
      },
    },
    {
      key: "InspectionDate",
      name: "Inspection Date" + postValue,
      width: cellWidthMedium,
      formatter: DateFormatter,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"InspectionDate"} data={data} section={"enquiryediheadergrid"} />;
      },
    },
    {
      key: "InspectionPoint",
      name: "Inspection Point" + postValue,
      width: cellWidthMedium,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"InspectionPoint"} data={data} section={"enquiryediheadergrid"} />;
      },
    },
    {
      key: "InspectorName",
      name: "Inspector Name" + postValue,
      width: cellWidthMedium,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"InspectorName"} data={data} section={"enquiryediheadergrid"} />;
      },
    },
    {
      key: "CommodityCode",
      name: "Commodity Code" + postValue,
      width: cellWidthMedium,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"CommodityCode"} data={data} section={"enquiryediheadergrid"} />;
      },
    },
    {
      key: "VarietyCode",
      name: "Variety Code" + postValue,
      width: cellWidthMedium,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"VarietyCode"} data={data} section={"enquiryediheadergrid"} />;
      },
    },
    {
      key: "PackCode",
      name: "Pack Code" + postValue,
      width: cellWidthMedium,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"PackCode"} data={data} section={"enquiryediheadergrid"} />;
      },
    },
    {
      key: "GradeCode",
      name: "Grade Code" + postValue,
      width: cellWidthMedium,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"GradeCode"} data={data} section={"enquiryediheadergrid"} />;
      },
    },
    {
      key: "MarkCode",
      name: "Mark Code" + postValue,
      width: cellWidthMedium,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"MarkCode"} data={data} section={"enquiryediheadergrid"} />;
      },
    },
    {
      key: "InventoryCode",
      name: "Inventory Code" + postValue,
      width: cellWidthMedium,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"InventoryCode"} data={data} section={"enquiryediheadergrid"} />;
      },
    },
    {
      key: "PickingRef",
      name: "Picking Ref" + postValue,
      width: cellWidthMedium,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"PickingRef"} data={data} section={"enquiryediheadergrid"} />;
      },
    },
    {
      key: "CountCode",
      name: "Count Code" + postValue,
      width: cellWidthMedium,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"CountCode"} data={data} section={"enquiryediheadergrid"} />;
      },
    },
    {
      key: "NoCartons",
      name: "No Cartons" + postValue,
      width: cellWidthMedium,
      formatter: SummaryNumber2Decimal,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"NoCartons"} data={data} section={"enquiryediheadergrid"} />;
      },
    },
    {
      key: "PalletSize",
      name: "Pallet Size" + postValue,
      width: cellWidthMedium,
      formatter: SummaryNumber2Decimal,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"PalletSize"} data={data} section={"enquiryediheadergrid"} />;
      },
    },
    {
      key: "FarmNumber",
      name: "Farm Number" + postValue,
      width: cellWidthMedium,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"FarmNumber"} data={data} section={"enquiryediheadergrid"} />;
      },
    },
    {
      key: "Organization",
      name: "Organization" + postValue,
      width: cellWidthMedium,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Organization"} data={data} section={"enquiryediheadergrid"} />;
      },
    },
    {
      key: "Transport",
      name: "Transport" + postValue,
      width: cellWidthMedium,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Transport"} data={data} section={"enquiryediheadergrid"} />;
      },
    },
    {
      key: "SequenceNumber",
      name: "Sequence Number" + postValue,
      width: cellWidthMedium,
      formatter: FormatterNumber,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"SequenceNumber"} data={data} section={"enquiryediheadergrid"} />;
      },
    },
    {
      key: "WaybillDocument",
      name: "Waybill Document" + postValue,
      width: cellWidthMedium,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"WaybillDocument"} data={data} section={"enquiryediheadergrid"} />;
      },
    },
    {
      key: "GrossWeight",
      name: "Gross Weight" + postValue,
      width: cellWidthMedium,
      formatter: SummaryNumber2Decimal,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"GrossWeight"} data={data} section={"enquiryediheadergrid"} />;
      },
    },
    {
      key: "loadout_reference",
      name: "Load Ref" + postValue,
      width: cellWidthMedium,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"loadout_reference"} data={data} section={"enquiryediheadergrid"} />;
      },
    },
  ].map((c) => ({ ...c, ...defaultColumnProperties }));

  const columnsArranged = [];
  if (arrangement && arrangement.length > 0) {
    arrangement.map((item) => {
      const indexOfItem = columns.indexOf(columns.find((col) => col.name.trim() == item.toString().trim()));
      if (indexOfItem != -1) {
        columnsArranged.push(columns[indexOfItem]);
      }
    });
  } else {
    columns.map((colItem) => {
      columnsArranged.push(colItem);
    });
  }

  if (columnsWidth && columnsWidth.length > 0) {
    for (let index = 0; index < columnsArranged.length; index++) {
      const element = columnsArranged[index];
      const colItem = columnsWidth.find((colwitem) => colwitem.name.trim() == element.name.trim());
      if (colItem) {
        element.width = colItem.width;
      }
    }
  }

  return columnsArranged;
};
