import React, { useEffect, useState, FC, useContext, useMemo } from "react";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";

import { Field, Form } from "react-final-form";
import { TextField, Select } from "final-form-material-ui";

import { targetsAll } from "../../lib/api/target";
import { getPortReadyForCombo } from "../../lib/api/port";
import { clientsshipping, shipsByClientID } from "../../lib/api/clientsshipping";
import LinearProgress from "@material-ui/core/LinearProgress";
import { GenerateErrorMessage } from "../../lib/helpers/string_methods";
import { SnackContext } from "../../lib/context/SnackContext";

import Button from "@material-ui/core/Button";

import IconClose from "@material-ui/icons/Close";
import IconCheck from "@material-ui/icons/Check";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1),
      paddingTop: theme.spacing(2),
    },
    tableRowFieldTitle: {
      paddingTop: theme.spacing(1),
      paddingRight: theme.spacing(1) * 2,
      marginTop: theme.spacing(1),
      textAlign: "right",
      minWidth: "175px",
    },
    tableRowFieldData: {
      textAlign: "left",
      width: "400px",
    },
    tableRowFieldDataText: {
      paddingTop: theme.spacing(0.5),
      textAlign: "left",
      width: "400px",
    },
    actionButtons: {
      display: "flex",
      justifyContent: "flex-end",
      gap: theme.spacing(1),
      flexDirection: "row",
      position: "fixed",
      right: "50px",
    },
  });

type ShippingData = {
  id: number;
  clients_id: number;
  market: string;
  portdischarge: number;
  portfinal: number;
  region: string;
  registered_country: string;
  target_id: number;
};

const defaultShipping = {
  id: undefined,
  clients_id: undefined,
  market: undefined,
  portdischarge: undefined,
  portfinal: undefined,
  region: undefined,
  registered_country: undefined,
  target_id: undefined,
};

type ClientEditShippingFormProps = {
  clientId: number;
  handleClose(): void;
  toggleDirty(isDirty: boolean): void;
} & WithStyles<typeof styles>;

const ClientEditShippingFormUnstyled: FC<ClientEditShippingFormProps> = ({ classes, clientId, toggleDirty, handleClose }) => {
  const { updateSnack } = useContext(SnackContext);

  const [loading, setLoading] = useState(true);
  const [shippingData, setShippingData] = useState<ShippingData>(defaultShipping);
  const [targetsList, setTargetsList] = useState([]);
  const [ports, setPorts] = useState([]);

  const loadData = async () => {
    setLoading(true);
    try {
      const [ports, targets, _] = await Promise.all([getPortReadyForCombo(), targetsAll(), loadShippingData()]);
      setPorts(ports);
      setTargetsList(targets.data);
    } catch (error) {
      const err = GenerateErrorMessage(error, "Failed to get shipping data");
      updateSnack({ color: "red", message: err, show: true });
    }
    setLoading(false);
  };

  const loadShippingData = async () => {
    const shipping = await shipsByClientID(clientId);
    setShippingData((shipping.data || [])[0]);
  };

  const handleSubmit = async (values) => {
    setLoading(true);
    try {
      const data = {
        data: {
          clients_id: clientId,
          registered_country: values.registered_country,
          target_id: values.target_id,
          portdischarge: values.portdischarge,
          portfinal: values.portfinal,
        },
      };
      if (values.id) {
        await clientsshipping.update(values.id, data);
      } else {
        await clientsshipping.create(data);
      }
      updateSnack({ color: "green", message: "Successfully submitted data", show: true });
    } catch (error) {
      const err = GenerateErrorMessage(error, "Failed to create/update shipping details");
      updateSnack({ color: "red", message: err, show: true });
    }
    await loadShippingData();
    setLoading(false);
  };

  useEffect(() => {
    loadData();
  }, [clientId]);

  if (loading) return <LinearProgress color="secondary" />;

  return (
    <div className={classes.root}>
      <Form
        onSubmit={handleSubmit}
        initialValues={{ ...shippingData }}
        render={({ form, handleSubmit }) => (
          <form onSubmit={handleSubmit} style={{ position: "relative" }}>
            <div className={classes.actionButtons}>
              <Button color="secondary" variant="outlined" onClick={handleClose} type="button">
                <IconClose />
              </Button>
              <Button disabled={!form.getState().dirty || clientId == 0} type="submit" color="primary" variant="outlined">
                <IconCheck />
              </Button>
            </div>
            {clientId == 0 && form.getState().dirty && <p style={{ color: "red", lineHeight: "10px" }}>Please ensure the CLIENT form is completed before continuing.</p>}
            <FormDetail classes={classes} targets={targetsList} form={form} ports={ports} toggleDirty={toggleDirty} clientId={clientId} />
          </form>
        )}
      />
    </div>
  );
};

const FormDetail = ({ classes, targets, form, ports, toggleDirty, clientId }) => {
  const targetsRegions = useMemo(() => {
    return targets.reduce((arr, target) => {
      if (!arr.find((r) => r.value === target.region)) {
        arr.push({ id: target.region, value: target.region, display: target.region });
      }
      return arr;
    }, []);
  }, [targets]);

  const targetsMarkets = useMemo(() => {
    return targets.reduce((arr, target) => {
      if (target.region === form.getState().values.region && !arr.find((m) => m.value === target.market)) {
        arr.push({ id: target.market, value: target.market, display: target.market });
      }
      return arr;
    }, []);
  }, [form.getState().values.region]);

  const targetsCountries = useMemo(() => {
    return targets.reduce((arr, target) => {
      if (target.region === form.getState().values.region && target.market === form.getState().values.market && !arr.find((m) => m.value === target.market)) {
        arr.push({ id: target.id, value: target.id, display: target.country, target_id: target.id });
      }
      return arr;
    }, []);
  }, [form.getState().values.region, form.getState().values.market]);

  useEffect(() => {
    if (clientId != 0) {
      toggleDirty(form.getState().dirty);
    }
  }, [form.getState().dirty]);

  return (
    <table>
      <tbody>
        <TableFieldText classes={classes} field="registered_country" title="Registered Country" disabled={false} multiline={false} required={false} />
        <TableFieldCombo classes={classes} field="region" title="Region" data={targetsRegions} form={form} />
        <TableFieldCombo classes={classes} field="market" title="Market" data={targetsMarkets} form={form} />
        <TableFieldCombo classes={classes} field="target_id" title="Country" data={targetsCountries} form={form} />
        <TableFieldCombo classes={classes} field="portdischarge" title="Port of Discharge" data={ports} />
        <TableFieldCombo classes={classes} field="portfinal" title="Final Destination" data={ports} />
      </tbody>
    </table>
  );
};

export default withStyles(styles)(ClientEditShippingFormUnstyled);

const TableFieldText: React.FunctionComponent<{ field: string; title: string; disabled: boolean; multiline: boolean; required: boolean } & WithStyles<typeof styles>> = (props) => {
  const { classes, title, field, disabled, multiline, required } = props;

  return (
    <tr>
      <td className={classes.tableRowFieldTitle} style={{ verticalAlign: "top", paddingTop: "13px" }}>
        <span>{`${title}: `}</span>
      </td>
      <td className={classes.tableRowFieldDataText}>
        <Field
          name={field}
          key={`body_value_${field}`}
          component={TextField}
          type="text"
          className={classes.tableRowFieldDataText}
          multiline={multiline}
          rows={multiline ? "4" : "1"}
          required={required}
          disabled={disabled}
        />
      </td>
    </tr>
  );
};

const TableFieldCombo: React.FunctionComponent<
  {
    classes: any;
    title: string;
    field: string;
    data: any;
    form?: any;
  } & WithStyles<typeof styles>
> = ({ classes, title, field, data, form }) => {
  return (
    <tr>
      <td className={classes.tableRowFieldTitle}>{`${title}:`}</td>
      <td className={classes.tableRowFieldData}>
        <Field
          name={field}
          component={Select as any}
          formControlProps={{
            className: classes.tableRowFieldData,
          }}
          native
          parse={(value) => {
            if (field == "region") {
              form.change("market", undefined);
              form.change("target_id", undefined);
            }
            if (field == "market") {
              form.change("target_id", undefined);
            }
            return value;
          }}
        >
          <option key={`none_1`} value={0}>
            {`NONE`}
          </option>
          {(data || []).map((item, index) => (
            <option key={`${item.value}${index}`} value={item.value}>
              {item.display}
            </option>
          ))}
        </Field>
      </td>
    </tr>
  );
};
