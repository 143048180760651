import { client, apiHeaders, request } from "./_base";

type Target = {
  id?: number;
  active: number;
  region: string;
  market: string;
  country: string;
  currency: string;
  costestimation_id: number;
  description: string;
};

// todo api exports
export const target = client<Target>("target", (item) => ({
  ...item,
}));

export const targetsSorted = (): Promise<{ data: Target[] }> =>
  request(`target/extend/targetssorted`, {
    headers: apiHeaders(),
  }).then((result) => result.json());

export const targetsAll = () =>
  request(`target/extend/all`, {
    headers: apiHeaders(),
  }).then((result) => result.json());

export const targetsDistinctRegions = (): Promise<{ data: { region: string }[] }> =>
  request(`target/extend/distinctregions`, {
    headers: apiHeaders(),
  }).then((result) => result.json());

export const targetsDistinctMarkets = (): Promise<{ data: { market: string }[] }> =>
  request(`target/extend/distinctmarkets`, {
    headers: apiHeaders(),
  }).then((result) => result.json());

export const targetsDistinctCountries = (): Promise<{ data: { country: string }[] }> =>
  request(`target/extend/distinctcountries`, {
    headers: apiHeaders(),
  }).then((result) => result.json());

export const targetsDistinctMarketsCountries = (): Promise<{ data: { market: string; country: string }[] }> =>
  request(`target/extend/distinctmarketscountries`, {
    headers: apiHeaders(),
  }).then((result) => result.json());
