import React, { useCallback } from "react";

import { Row, Cell } from "react-data-grid";
import { useDrag, useDrop } from "react-dnd";

import OpenWithIcon from "@material-ui/icons/OpenWith";

export const DraggableRowRenderer = ({ props, classes, onRowReorder, oddRow, underscore }) => {
  const dropRef = React.useRef(null);
  const dragRef = React.useRef(null);

  const [_, drag, preview] = useDrag({
    item: { index: props.rowIdx, type: "ROW_DRAG" },
    collect: (monitor) => ({ isDragging: monitor.isDragging() }),
    type: "ROW_DRAG",
  });

  const [{ isOver }, drop] = useDrop({
    accept: "ROW_DRAG",
    drop({ index, type }) {
      if (type === "ROW_DRAG") {
        onRowReorder(index, props.rowIdx);
      }
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  preview(drop(dropRef));
  drag(dragRef);

  const classnameUsing = isOver ? classes.rowDragging : oddRow ? classes.rowOdd : classes.rowNormal;
  const classnameUsingUnderscore = underscore ? classes.rowUnderScore : classes.rowNormal;

  return (
    <Row ref={dropRef} className={[classnameUsing, classnameUsingUnderscore]} {...props}>
      {props.viewportColumns.map((column) => {
        if (column.key === "drag") {
          column.formatter = () => (
            <div ref={dragRef} style={{ cursor: "grab", width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
              <OpenWithIcon style={{ height: "1rem", width: "1rem" }} />
            </div>
          );
        }
        return <Cell {...column} />;
      })}
    </Row>
  );
};
