type DefaultFilter = { key: string; forceApply: boolean; filter: string };

export const defaultFilters: DefaultFilter[] = [
  { key: "stickyfilters_clientsgridsetup", forceApply: false, filter: JSON.stringify([{ read: 1, field: "active", value: [{ label: "Active", value: "Active" }] }]) },
  { key: "stickyfilters_consignee", forceApply: false, filter: JSON.stringify([{ read: 1, field: "active", value: [{ label: "Active", value: "Active" }] }]) },
];

export const applyDefaultFilters = () => {
  for (const { key, filter, forceApply } of defaultFilters) {
    // check if localStorage has not already been set for one of the filters
    // forceApply - Force the filters to be applied even if it's already set
    if (!window.localStorage.getItem(key) || forceApply) {
      window.localStorage.setItem(key, filter);
    }
  }
};
