import React from "react";

import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import CopyIcon from "@material-ui/icons/FileCopy";
import InfoIcon from "@material-ui/icons/Info";

import CustomAutoCompleteFilter from "../../lib/components/customdatagridfilter/CustomFilter";
import { AdvanceContractsPayoutTypes } from "../../lib/api/advancecontractspayouttypes";

import numeral from "numeral";
import { CellActionsFormatter } from "../../lib/helpers/CellActionsFormatter";

const cellWidthSmall = 120;
const cellWidthSmallBig = 140;
const cellWidthMedium = 200;

const defaultColumnProperties = {
  resizable: true,
  sortable: true,
};

const FormatterCurrency = ({ row, column }) => {
  const data = row[column.key];
  return <div style={{ textAlign: "right" }}>{numeral(data).format("0.00")}</div>;
};

const formatterPayout = (data) => {
  const nameValue = Object.keys(AdvanceContractsPayoutTypes).find((item) => AdvanceContractsPayoutTypes[item] === data);
  return <div>{`${nameValue}`}</div>;
};

let currentSpace = true;
export const GridColumns = (data, filters, arrangement, columnsWidth, handleRemove, handleEdit, handleCopy, handleInfo) => {
  let postValue = "";

  if (data) {
    if (currentSpace) {
      postValue = " ";
    }
    currentSpace = !currentSpace;
  }

  const columns = [
    {
      key: "actions",
      name: "Actions",
      width: cellWidthSmall,
      formatter: ({ row }) => {
        const actions = cellActions(row, handleRemove, handleEdit, handleCopy, handleInfo);
        return (
          <div style={{ display: "flex", justifyContent: "center", gap: "5px" }}>
            <CellActionsFormatter actions={actions} />
          </div>
        );
      },
    },
    {
      key: "producer_code",
      name: "Producer" + postValue,
      width: cellWidthSmallBig,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"producer_code"} data={data} section="advancecontractgrid" />;
      },
    },
    {
      key: "producer_name",
      name: "Producer Name" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"producer_name"} data={data} section="advancecontractgrid" />;
      },
    },
    {
      key: "weekfrom",
      name: "From Week" + postValue,
      width: cellWidthSmallBig,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"weekfrom"} data={data} section="advancecontractgrid" />;
      },
    },
    {
      key: "weekto",
      name: "To Week" + postValue,
      width: cellWidthSmallBig,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"weekto"} data={data} section="advancecontractgrid" />;
      },
    },
    {
      key: "commodities",
      name: "Commodities" + postValue,
      width: cellWidthSmallBig,
      filterable: true,
      // formatter: (data) => {
      //   this.state.commidities.map((x) => x.code == data.value).name;
      // },
      formatter: ({ row }) => {
        if (row.commodities) {
          return <div>{row.commodities}</div>;
        }
        return <div>ALL COMMODITIES</div>;
      },
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"commodities"} data={data} section="advancecontractgrid" />;
      },
    },
    {
      key: "varieties",
      name: "Varieties" + postValue,
      width: cellWidthSmallBig,
      filterable: true,
      formatter: ({ row }) => {
        if (row.varieties) {
          return <div>{row.varieties}</div>;
        }
        return <div>ALL VARIETIES</div>;
      },
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"varieties"} data={data} section="advancecontractgrid" />;
      },
    },
    {
      key: "grades",
      name: "Grades" + postValue,
      width: cellWidthSmall,
      filterable: true,
      formatter: ({ row }) => {
        if (row.grades) {
          return <div>{row.grades}</div>;
        }
        return <div>ALL GRADES</div>;
      },
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"grades"} data={data} section="advancecontractgrid" />;
      },
    },
    {
      key: "packcodes",
      name: "Pack Code" + postValue,
      width: cellWidthSmall,
      filterable: true,
      formatter: ({ row }) => {
        if (row.packcodes && row.packcodes != " ") {
          return <div>{row.packcodes}</div>;
        }
        return <div>ALL PACK CODES</div>;
      },
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"packcodes"} data={data} section="advancecontractgrid" />;
      },
    },
    {
      key: "cad",
      name: "CAD" + postValue,
      width: cellWidthSmall,
      formatter: FormatterCurrency,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"cad"} data={data} section="advancecontractgrid" />;
      },
    },
    {
      key: "eur",
      name: "EUR" + postValue,
      width: cellWidthSmall,
      formatter: FormatterCurrency,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"eur"} data={data} section="advancecontractgrid" />;
      },
    },
    {
      key: "gbp",
      name: "GBP" + postValue,
      width: cellWidthSmall,
      formatter: FormatterCurrency,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"gbp"} data={data} section="advancecontractgrid" />;
      },
    },
    {
      key: "usd",
      name: "USD" + postValue,
      width: cellWidthSmall,
      formatter: FormatterCurrency,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"usd"} data={data} section="advancecontractgrid" />;
      },
    },
    {
      key: "zar",
      name: "ZAR" + postValue,
      width: cellWidthSmall,
      formatter: FormatterCurrency,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"zar"} data={data} section="advancecontractgrid" />;
      },
    },
    {
      key: "payoutSource",
      name: "Payout Source" + postValue,
      width: cellWidthSmallBig,
      formatter: ({ row }) => formatterPayout(row.payoutSource),
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"payoutSource"} data={data} section="advancecontractgrid" />;
      },
    },
    {
      key: "payoutLeadNumber",
      name: "LeadNumber" + postValue,
      width: cellWidthSmallBig,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"payoutLeadNumber"} data={data} section="advancecontractgrid" />;
      },
    },
  ].map((c) => ({ ...c, ...defaultColumnProperties }));

  const columnsArranged = [];

  if (arrangement && arrangement.length > 0) {
    arrangement.map((item) => {
      const indexOfItem = columns.indexOf(columns.find((col) => col.name.trim() == item.toString().trim()));
      if (indexOfItem != -1) {
        columnsArranged.push(columns[indexOfItem]);
      }
    });
  } else {
    columns.map((colItem) => {
      columnsArranged.push(colItem);
    });
  }

  if (columnsWidth && columnsWidth.length > 0) {
    for (let index = 0; index < columnsArranged.length; index++) {
      const element = columnsArranged[index];
      const colItem = columnsWidth.find((colwitem) => colwitem.name.trim() == element.name.trim());
      if (colItem) {
        element.width = colItem.width;
      }
    }
  }

  return columnsArranged;
};

const cellActions = (row, handleRemove, handleEdit, handleCopy, handleInfo) => {
  const actionsArr = [
    {
      icon: (
        <span title="Copy">
          <CopyIcon style={{ cursor: "pointer", color: "blue" }} />
        </span>
      ),
      callback: (e) => {
        handleCopy(row.id);
      },
    },
  ];

  if (!row.linked) {
    actionsArr.push(
      {
        icon: (
          <span title="Edit">
            <EditIcon style={{ cursor: "pointer", color: "orange" }} />
          </span>
        ),
        callback: (e) => {
          handleEdit(row.id);
        },
      },
      {
        icon: (
          <span title="Remove">
            <DeleteIcon style={{ cursor: "pointer", color: "red" }} />
          </span>
        ),
        callback: (e) => {
          handleRemove(row.id);
        },
      },
    );
  } else {
    actionsArr.push({
      icon: (
        <span title="Info">
          <InfoIcon style={{ cursor: "pointer", color: "grey" }} />
        </span>
      ),
      callback: (e) => {
        handleInfo(row);
      },
    });
  }

  return actionsArr;
};
