import React from "react";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";

import { GridColumns } from "./creditorsitemtemplateitemssetup";

import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import IconAdd from "@material-ui/icons/Add";

import Grid from "../../../lib/components/grid";
import Confirmation from "../../../lib/components/confirmation";
import { getVatTypesReadyForCombo } from "../../../lib/api/vattypes";
import BigNumber from "bignumber.js";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      height: "100%",
    },
    inline: {
      display: "inline",
      position: "absolute",
      marginTop: "7px",
      marginLeft: "7px",
    },
    saveButtonWrapper: {
      position: "absolute",
      height: "64px",
      width: "calc(100% - 180px)",
      display: "flex",
      justifyContent: "flex-end",
    },
    saveButton: {
      alignSelf: "center",
    },
    gridWrapper: {
      position: "relative",
    },
    gridToolbarWrapper: {
      position: "absolute",
      width: "100%",
      height: "65px",
      display: "flex",
      alignItems: "center",
    },
    addButton: {
      marginLeft: "5px",
    },
    totalRowStyle: {
      background: "#ddd",
      textAlign: "right",
      fontWeight: "bold",
      color: "black",
    },
    totalRowStyleFixed: {
      position: "sticky",
      bottom: 0,
      background: "#ddd",
      textAlign: "right",
      fontWeight: "bold",
      color: "black",
    },
    lastRowStyle: {
      display: "flex",
      flexDirection: "column-reverse",
      color: "black",
    },
  });

type CreditorsItemTemplateItemsProps = {
  data: any[];
  handleAddRow: () => void;
  handleCopy: (row: any) => void;
  handleRemoveConfirm: (rowIndx: number) => void;
  handleChange: (property: string, value: any[]) => void;
} & WithStyles<typeof styles>;

export class CreditorsItemTemplatePropsItemsUnstyled extends React.Component<CreditorsItemTemplateItemsProps, any> {
  state = {
    data: undefined,
    classes: undefined,
    confirmRemoveID: undefined,
    loading: true,
    handleAddRow: undefined,
    handleChange: undefined,
    vattypesComboArr: [],
    handleCopy: undefined,
    dataFromGrid: undefined,
    rowSelectedIdx: undefined,
    columnSelectedIdx: undefined,
    columnsLength: undefined,
  };

  constructor(props) {
    super(props);
    this.state.data = props.data;
    this.state.classes = props.classes;
    this.state.handleChange = props.handleChange;
    this.state.handleAddRow = props.handleAddRow;
    this.state.handleCopy = props.handleCopy;
    this.loadData();
  }

  componentDidMount() {
    this.loadData();
  }

  componentDidUpdate(previousProps) {
    if (previousProps.data !== this.props.data) {
      this.setState(
        {
          data: this.props.data,
        },
        () => {
          this.loadData();
        },
      );
    }
  }

  loadData = () => {
    this.setState({ loading: true });
    const vattypes = getVatTypesReadyForCombo();
    this.setState({ vattypesComboArr: vattypes, loading: false });
  };

  handleRemove = async (id) => {
    this.setState({ confirmRemoveID: id });
  };

  handleConfirmDelete = () => {
    this.props.handleRemoveConfirm(this.state.confirmRemoveID);
    this.handleRemoveClose();
  };

  handleRemoveClose = () => {
    this.setState({ confirmRemoveID: undefined });
  };

  handleCopy = (row) => {
    this.state.handleCopy(row);
  };

  handleKeyDown = (event, gridRef) => {
    const enterKey = 13;
    const tabKey = 9;

    if (event.which === enterKey || event.which === tabKey) {
      event.preventDefault();

      const lastEditableColumn = this.state.columnsLength - 2; // minus two because last two rows are not editable
      const tagName = event.target.tagName && event.target.tagName.toLowerCase();

      if (tagName == "div") {
        gridRef.selectCell({ idx: this.state.columnSelectedIdx, rowIdx: this.state.rowSelectedIdx }, true);
      }
      //
      else {
        if (this.state.columnSelectedIdx == lastEditableColumn) {
          if (this.state.rowSelectedIdx == this.state.data.length - 1) {
            this.state.handleAddRow();
          }
          //
          else {
            this.setState({ rowSelectedIdx: this.state.rowSelectedIdx + 1, columnSelectedIdx: 2 });
            setTimeout(() => {
              gridRef.selectCell({ idx: 2, rowIdx: this.state.rowSelectedIdx }, true);
            }, 200);
          }
        }
        //
        else {
          this.setState({ columnSelectedIdx: this.state.columnSelectedIdx + 1 });
          setTimeout(() => {
            gridRef.selectCell({ idx: this.state.columnSelectedIdx, rowIdx: this.state.rowSelectedIdx }, true);
          }, 200);
        }
      }
    }
  };

  handleSelectedRowChange = (row, column) => {
    this.setState({ rowSelectedIdx: row.rowIdx, columnSelectedIdx: column.selectedColumn, columnsLength: column.columnsLength - 1 }); // minus one because index starts at 0
  };

  GridColumnsOverride = (data, filters, arrangement, columnsWidth) => {
    return GridColumns(data, filters, arrangement, columnsWidth, this.state.vattypesComboArr, this.handleRemove, this.handleCopy);
  };

  render() {
    const { classes } = this.state;
    const TotalColumns = ["rate", "units", "vatamount", "total"];

    return (
      <div className={classes.root}>
        {this.state.loading ? (
          <div>
            <CircularProgress size={24} />
          </div>
        ) : (
          <div className={classes.gridWrapper}>
            <div className={classes.gridToolbarWrapper}>
              <Button className={classes.addButton} variant="contained" type="button" color="primary" onClick={this.state.handleAddRow}>
                <IconAdd />
              </Button>
            </div>
            <Grid
              loading={this.state.loading}
              data={this.state.data}
              GridColumns={this.GridColumnsOverride}
              handleChange={(property: string, values: any[]) => {
                this.state.handleChange(property, values);
              }}
              selectedRowChange={this.handleSelectedRowChange}
              forceHeight={500}
              clearFilters={"creditorsTemplateDetailItem"}
              fill={true}
              totalRowColumns={["rate", "units", "vatamount", "total"]}
              handleGridKeyDown={this.handleKeyDown}
            />
            {this.state.confirmRemoveID && (
              <Confirmation
                isOpen={this.state.confirmRemoveID ? true : false}
                handleClose={this.handleRemoveClose}
                handleConfirm={this.handleConfirmDelete}
                title="Remove selected Item?"
                body="Are you sure you want to REMOVE the selected Item Record?"
              ></Confirmation>
            )}
          </div>
        )}
      </div>
    );
  }
}

export default withStyles(styles)(CreditorsItemTemplatePropsItemsUnstyled);
