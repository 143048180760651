import React from "react";

import IconEdit from "@material-ui/icons/Edit";
import IconRemove from "@material-ui/icons/Delete";

import CustomAutoCompleteFilter from "../../../lib/components/customdatagridfilter/CustomFilter";
import { DateFormatter, FormatterNumber2Decimals } from "../../../lib/components/Formatters";
import { SummaryNumber2Decimal } from "../../../lib/components/SummaryFormatter";
import { handleColumnsArrangement, handleColumnsWidth } from "../../../lib/helpers/grid";

import Checkbox from "@material-ui/core/Checkbox";

const cellWidthAction = 50;
const cellWidthTiny = 70;
const cellWidthSmall = 100;
const cellWidthMedium = 130;
const cellWidthLarge = 200;

let currentSpace = true;
export const GridColumns = (data, filters, arrangement, columnsWidth, selectedSalesIds, handleSelectSale, handleEditOverpaid, handleRemoveOverpaid) => {
  let postValue = "";

  if (data) {
    if (currentSpace) {
      postValue = " ";
    }
    currentSpace = !currentSpace;
  }

  const columns = [
    {
      key: "actions",
      name: "Actions",
      width: cellWidthAction,
      formatter: ({ row }) => {
        return (
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%", gap: "2px" }}>
            {row.allocated_shipment ? (
              <>
                <IconRemove onClick={() => handleRemoveOverpaid(row.salefundsallocation_id)} style={{ cursor: "pointer", color: "red" }} />
                <IconEdit onClick={() => handleEditOverpaid(row)} style={{ cursor: "pointer", color: "orange" }} />
              </>
            ) : (
              <Checkbox
                style={{ padding: 0 }}
                disabled={row.available_to_allocate <= 0}
                checked={(selectedSalesIds || []).includes(row.id)}
                onChange={() => handleSelectSale(row.id)}
              />
            )}
          </div>
        );
      },
    },
    {
      key: "invoicenumber",
      name: "Shipment" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"invoicenumber"} data={data} section="overpaidgrid" />;
      },
    },
    {
      key: "date_allocated",
      name: "Date Allocated" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"date_allocated"} data={data} section="overpaidgrid" />;
      },
      formatter: DateFormatter,
    },
    {
      key: "currency_code",
      name: "Currency" + postValue,
      width: cellWidthTiny,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"currency_code"} data={data} section="overpaidgrid" />;
      },
    },
    {
      key: "overpaid_amount",
      name: "Overpaid" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"overpaid_amount"} data={data} section="overpaidgrid" />;
      },
      formatter: FormatterNumber2Decimals,
      summaryFormatter: SummaryNumber2Decimal,
    },
    {
      key: "available_to_allocate",
      name: "Avail to Allocate" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"available_to_allocate"} data={data} section="overpaidgrid" />;
      },
      formatter: FormatterNumber2Decimals,
      summaryFormatter: SummaryNumber2Decimal,
    },
    {
      key: "allocated_shipment",
      name: "Allocated To" + postValue,
      width: cellWidthLarge,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"sales_linked"} data={data} section="overpaidgrid" />;
      },
    },
  ];

  let columnsArranged = [];
  columnsArranged = handleColumnsArrangement(columnsArranged, arrangement, columns);
  columnsArranged = handleColumnsWidth(columnsArranged, columnsWidth, columns);

  return columnsArranged;
};
