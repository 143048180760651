import React from "react";

import CustomAutoCompleteFilter from "../../lib/components/customdatagridfilter/CustomFilter";
import { FormatterNumber2Decimals } from "../../lib/components/Formatters";
import RightAlignHeaderColumn from "../../lib/components/gridhelper/RightAlignHeaderColumn";
import { SummaryNumber2Decimal } from "../../lib/components/SummaryFormatter";
import { handleColumnsArrangement, handleColumnsWidth } from "../../lib/helpers/grid";

const cellWidthSmallX = 90;
const cellWidthSmall = 115;
const cellWidthMedium = 150;
const cellWidthLarge = 220;

const defaultColumnProperties = {
  filterable: true,
  resizable: true,
  sortable: true,
};

let currentSpace = true;

export const GridColumnsSummary = (data, filters, arrangement, columnsWidth) => {
  let postValue = "";

  if (data) {
    if (currentSpace) {
      postValue = " ";
    }
    currentSpace = !currentSpace;
  }

  const columns = [
    {
      key: "creditor_code",
      name: "Code" + postValue,
      width: cellWidthSmall,
      filterRenderer: (e) => {
        return (
          <CustomAutoCompleteFilter {...e} multiSelection={true} option={data} currentFilters={filters} fieldname={"creditor_code"} data={data} section="creditorsinvoicesummary" />
        );
      },
    },
    {
      key: "creditor_name",
      name: "Name" + postValue,
      width: cellWidthMedium,
      filterRenderer: (e) => {
        return (
          <CustomAutoCompleteFilter {...e} multiSelection={true} option={data} currentFilters={filters} fieldname={"creditor_name"} data={data} section="creditorsinvoicesummary" />
        );
      },
    },
    {
      key: "currency_code",
      name: "Currency" + postValue,
      width: cellWidthMedium,
      filterRenderer: (e) => {
        return (
          <CustomAutoCompleteFilter {...e} multiSelection={true} option={data} currentFilters={filters} fieldname={"currency_code"} data={data} section="creditorsinvoicesummary" />
        );
      },
    },
    {
      key: "totalExcl",
      name: "Total Excl" + postValue,
      width: cellWidthMedium,
      headerRenderer: RightAlignHeaderColumn,
      filterRenderer: (e) => {
        return (
          <div style={{ height: "100%", width: "100%", display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
            <CustomAutoCompleteFilter {...e} multiSelection={true} option={data} currentFilters={filters} fieldname={"totalExcl"} data={data} section="creditorsinvoicesummary" />
          </div>
        );
      },
      formatter: FormatterNumber2Decimals,
      summaryFormatter: SummaryNumber2Decimal,
    },
    {
      key: "totalVat",
      name: "Total Vat" + postValue,
      width: cellWidthMedium,
      headerRenderer: RightAlignHeaderColumn,
      filterRenderer: (e) => {
        return (
          <div style={{ height: "100%", width: "100%", display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
            <CustomAutoCompleteFilter {...e} multiSelection={true} option={data} currentFilters={filters} fieldname={"totalVat"} data={data} section="creditorsinvoicesummary" />
          </div>
        );
      },
      formatter: FormatterNumber2Decimals,
      summaryFormatter: SummaryNumber2Decimal,
    },
    {
      key: "total",
      name: "Total" + postValue,
      width: cellWidthMedium,
      headerRenderer: RightAlignHeaderColumn,
      filterRenderer: (e) => {
        return (
          <div style={{ height: "100%", width: "100%", display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
            <CustomAutoCompleteFilter {...e} multiSelection={true} option={data} currentFilters={filters} fieldname={"total"} data={data} section="creditorsinvoicesummary" />
          </div>
        );
      },
      formatter: FormatterNumber2Decimals,
      summaryFormatter: SummaryNumber2Decimal,
    }
  ].map((c) => ({
    ...c,
    ...defaultColumnProperties,
  }));

  let columnsArranged = [];
  columnsArranged = handleColumnsArrangement(columnsArranged, arrangement, columns);
  columnsArranged = handleColumnsWidth(columnsArranged, columnsWidth, columns);

  return columnsArranged;
};
