import React, { FC, useEffect, useState } from "react";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";

import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";

import OverpaidGrid from "./overpaidgrid";
import { SaleFundsOverpaidMappingType, getOverpaidSalesMapping } from "../../../lib/api/salefundsoverpaid";
import { getFinancialYearSelected } from "../../../lib/api/week";
import Confirmation from "../../../lib/components/confirmation";
import { SaleFundsAllocation } from "../../../lib/api/salefundsallocation";

const styles = (theme: Theme) =>
  createStyles({
    root: {},
    gridWrapper: {
      position: "relative",
    },
    toolbar: {
      position: "absolute",
      left: 0,
      top: 0,
      height: "60px",
      width: "100%",
      display: "flex",
      flexDirection: "row",
      gap: "10px",
    },
  });

type OverpaidFundsProps = {
  history: any;
  clients_code: string;
} & WithStyles<typeof styles>;

const OverpaidFundsUnstyled: FC<OverpaidFundsProps> = ({ classes, clients_code, history }) => {
  const [data, setData] = useState<SaleFundsOverpaidMappingType[]>([]);
  const [selectedSalesIds, setSelectedSalesIds] = useState<number[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [confirmRemoveID, setConfirmRemoveID] = useState<number>();

  const loadData = async () => {
    setLoading(true);
    const result = await getOverpaidSalesMapping(clients_code, getFinancialYearSelected());
    setData(result);
    setLoading(false);
  };

  useEffect(() => {
    loadData();
  }, []);

  const handleSelectSale = (sale_id: number) => {
    if (selectedSalesIds.includes(sale_id)) {
      setSelectedSalesIds(selectedSalesIds.filter((id) => id != sale_id));
    } else {
      setSelectedSalesIds([...selectedSalesIds, sale_id]);
    }
  };

  const handleAllocateSales = () => {
    const overpaidRow = data.find((item) => item.id == selectedSalesIds[0]);
    const list: string[] = (history.location.pathname || "").split("/");
    const clients_code = list.at(-1);
    history.push(`/debtors/funds/${clients_code}?sale_ids=${selectedSalesIds.join(",")}&salefunds_id=${overpaidRow.salefunds_id}`);
  };

  const handleEditOverpaid = (row: SaleFundsOverpaidMappingType) => {
    history.push(`/debtors/funds/${clients_code}?sale_ids=${row.id}&salefunds_id=${row.salefunds_id}`);
  };

  const handleRemoveOverpaid = (salefundsallocation_id: number) => {
    setConfirmRemoveID(salefundsallocation_id);
  };

  const handleRemoveClose = () => {
    setConfirmRemoveID(undefined);
  };

  const handleRemoveConfirm = async () => {
    // if we remove the salefundsallocation, it cascade removes the overpaidsalemapping record ;)
    await SaleFundsAllocation.remove(confirmRemoveID);
    handleRemoveClose();
    await loadData();
  };

  return (
    <div>
      {confirmRemoveID && (
        <Confirmation
          isOpen={true}
          handleClose={handleRemoveClose}
          handleConfirm={handleRemoveConfirm}
          title="Remove selected Allocation?"
          body="Are you sure you want to REMOVE the Allocation?"
        ></Confirmation>
      )}
      <div className={classes.gridWrapper}>
        <Toolbar className={classes.toolbar}>
          <Button variant="contained" color="primary" disabled={selectedSalesIds.length === 0} onClick={handleAllocateSales}>
            Allocate
          </Button>
        </Toolbar>
        <OverpaidGrid
          data={data}
          loading={loading}
          selectedSalesIds={selectedSalesIds}
          handleSelectSale={handleSelectSale}
          handleEditOverpaid={handleEditOverpaid}
          handleRemoveOverpaid={handleRemoveOverpaid}
        />
      </div>
    </div>
  );
};

export default withStyles(styles)(OverpaidFundsUnstyled);
