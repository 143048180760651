import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";

import LinearProgress from "@material-ui/core/LinearProgress";

import OrdersGrid from "./ordersgrid";
import { OrdersCreate } from "./ordersCreate";

import Confirmation from "../lib/components/confirmation";
import { guid } from "../lib/helpers/GetRandomGuid";

import numeral from "numeral";

//endpoints api
import { commodities } from "../lib/api/commodity";
import { varieties } from "../lib/api/variety";
import { weeksOrdered, getFinancialYearSelected } from "../lib/api/week";
import { piorder, piorderFull, removeWithInstructions, getOrders, getOrdersByGroupNumber } from "../lib/api/piorder";
import { piinstructions, FindByOrderId } from "../lib/api/piinstruction";
import { getActiveProducers } from "../lib/api/producer";

import { getNextOrderNumber } from "./_shared";

import { SnackContext } from "../lib/context/SnackContext";

import { DownloadPackInstruction } from "../lib/helpers/Files";

import { CleanUpInstructionObject, CleanUpOrderObject, copyOrder } from "./_shared";

import { GenerateErrorMessage } from "../lib/helpers/string_methods";

const styles = (theme: Theme) =>
  createStyles({
    root: {},
  });

type OrdersContainerProps = {} & WithStyles<typeof styles>;

export class OrdersContainer extends React.Component<OrdersContainerProps> {
  state = {
    loading: true,
    progress: false,
    orders: [],
    ordersFiltered: [],
    filterValues: {
      farm: "",
      variety: "",
      week: "",
    },
    redirectPath: "",
    createNewOrder: false,
    producers: undefined,
    varieties: undefined,
    commodities: undefined,
    weeks: undefined,
    confirmCopy: false,
    confirmCopyId: undefined,
    confirmCopyGroup: undefined,
    confirmRemove: false,
    confirmRemoveId: undefined,
    confirmRemoveGroup: undefined,
    confirmRevision: false,
    confirmRevisionId: undefined,
    confirmRevisionGroup: undefined,
    confirmLock: false,
    confirmLockId: undefined,
    confirmLockGroup: undefined,
    previousYearOrders: [],
    previousYearOrdersFiltered: [],
  };

  constructor(props) {
    super(props);
    this.state.redirectPath = "";
  }

  static contextType = SnackContext;
  context!: React.ContextType<typeof SnackContext>;

  componentDidMount() {
    this.loadData().then(() => {
      this.setState({ loading: false }, () => {});
    });
  }

  loadData = async () => {
    try {
      const [resultProducers, resultCommodities, resultVarieties, resultWeeks, piorders, piordersPrevYear] = await Promise.all([
        getActiveProducers(),
        commodities.all(),
        varieties.all(),
        weeksOrdered(getFinancialYearSelected()),
        piorderFull(getFinancialYearSelected()),
        piorderFull(`${getFinancialYearSelected() - 1}`),
      ]);

      const result = piorders.data.map((item) => ({ ...item, exchange: JSON.parse(item.exchange) }));
      const resultPreviousYear = piordersPrevYear.data.map((item) => ({ ...item, exchange: JSON.parse(item.exchange) }));

      this.setState({
        orders: result,
        ordersFiltered: result,
        previousYearOrders: resultPreviousYear,
        previousYearOrdersFiltered: resultPreviousYear,
        weeks: resultWeeks.data,
        producers: resultProducers,
        varieties: resultVarieties.sort((a, b) => a.name > b.name),
        commodities: resultCommodities.sort((a, b) => a.name > b.name),
      });
    } catch (error) {
      const err = GenerateErrorMessage(error, "failed to retrieve data");
      this.context.updateSnack({ show: true, color: "red", message: err });
    }
  };

  checkIfLoading = () => {
    if (
      // !this.state.farms ||
      !this.state.producers ||
      !this.state.varieties ||
      !this.state.commodities ||
      !this.state.weeks
    ) {
      this.setState({ loading: true });
    } else {
      this.setState({ loading: false });
    }
  };

  // EVENTS
  handleEdit = (id, groupnumber) => {
    // this.setState({ redirectPath: `/instruction/${id}/edit` });
    if (groupnumber && groupnumber != 0) {
      this.setState({ redirectPath: `/instructiontabbed/${groupnumber}/edit` });
    } else {
      this.setState({ redirectPath: `/instruction/${id}/edit` });
    }
  };

  handleCopy = async (id, groupnumber) => {
    this.setState({ confirmCopy: true, confirmCopyId: id, confirmCopyGroup: groupnumber });
  };

  handleCopyClose = async () => {
    this.setState({
      confirmCopy: false,
      confirmCopyId: undefined,
      confirmCopyGroup: undefined,
    });
  };

  handleCopyConfirm = async () => {
    const id = this.state.confirmCopyId;
    const groupnumber = this.state.confirmCopyGroup;

    this.setState({
      loading: true,
      confirmCopy: false,
      confirmCopyId: undefined,
      confirmCopyGroup: undefined,
    });

    if (groupnumber && groupnumber != 0) {
      const newGroupNumber = guid();
      getOrdersByGroupNumber(groupnumber).then((resultByGroupNumber) => {
        if (resultByGroupNumber && resultByGroupNumber.data && resultByGroupNumber.data.length > 0) {
          const promiseArr = [];
          resultByGroupNumber.data.map((groupItem) => {
            promiseArr.push(
              new Promise((res, rej) => {
                copyOrder(groupItem.id, 0, false, newGroupNumber)
                  .then(() => {
                    res("ok");
                  })
                  .catch((error) => {
                    rej("bad");
                    const err = GenerateErrorMessage(error, "Order could not be copied");
                    this.context.updateSnack({ show: true, color: "red", message: err });
                  });
              }),
            );

            Promise.all(promiseArr).then((result) => {
              this.setState({ loading: false, redirectPath: `/instructiontabbed/${newGroupNumber}/edit` });
            });
          });
        }
      });
    } else {
      copyOrder(id)
        .then((res) => {
          this.setState({ loading: false, redirectPath: `/instruction/${res}/edit` });
        })
        .catch((error) => {
          this.setState({
            loading: false,
          });
          const err = GenerateErrorMessage(error, "Order could not be copied");
          this.context.updateSnack({ show: true, color: "red", message: err });
        });
    }
  };

  handleCreate = () => {
    this.setState({ createNewOrder: true });
  };

  handleCreateSubmit = async (e) => {
    const orderData = {
      data: { ...e, revision: 1 },
    };

    if ("farm" in orderData.data == false || orderData.data.farm == "NONE") {
      const err = "Farm is required. Please select a farm.";
      this.context.updateSnack({ show: true, color: "Firebrick", message: err });
      return false;
    }

    if ("variety" in orderData.data == false || orderData.data.variety == "NONE") {
      const err = "Variety is required. Please select a variety.";
      this.context.updateSnack({ show: true, color: "Firebrick", message: err });
      return false;
    }

    if ("week" in orderData.data == false || orderData.data.week == "NONE") {
      const err = "Week is required. Please select a week.";
      this.context.updateSnack({ show: true, color: "Firebrick", message: err });
      return false;
    }

    if ("commission" in orderData.data && isNaN(orderData.data.commission)) {
      const err = "Commission must be a number or decimal.";
      this.context.updateSnack({ show: true, color: "Firebrick", message: err });
      return false;
    }

    delete orderData.data["commodity"];

    orderData.data.exchange.usd = numeral(orderData.data.exchange.usd).format("0.00").toString();
    orderData.data.exchange.cad = numeral(orderData.data.exchange.cad).format("0.00").toString();
    orderData.data.exchange.eur = numeral(orderData.data.exchange.eur).format("0.00").toString();
    orderData.data.exchange.gbp = numeral(orderData.data.exchange.gbp).format("0.00").toString();

    orderData.data.exchange = JSON.stringify(orderData.data.exchange);

    // add order number
    orderData.data.ordernum = await getNextOrderNumber(this.state.weeks, this.state.varieties, orderData.data.farm, orderData.data.week, orderData.data.variety, undefined);
    // orderData.data.ordernum += "1";

    //validate orderdata
    if (orderData.data.farm && orderData.data.variety && orderData.data.week) {
      piorder.create(orderData).then((result) => {
        if (result.name && result.name.toLowerCase() == "error") {
          const err = "Order could not be created.";
          this.context.updateSnack({ show: true, color: "red", message: err });
        } else {
          const maxid = result[0];
          this.setState({ loading: false, redirectPath: `/instruction/${maxid}/edit` });
        }
      });
    }
  };

  handleCreateClose = () => {
    this.setState({ createNewOrder: false });
  };

  handleRemoveConfirm = async () => {
    const id = this.state.confirmRemoveId;
    const groupnumber = this.state.confirmRemoveGroup;

    this.setState({
      confirmRemove: false,
      confirmRemoveId: undefined,
      confirmRemoveGroup: undefined,
      loading: true,
    });

    if (groupnumber && groupnumber != 0) {
      getOrdersByGroupNumber(groupnumber).then((resultByGroupNumber) => {
        if (resultByGroupNumber && resultByGroupNumber.data && resultByGroupNumber.data.length > 0) {
          const promiseArr = [];
          resultByGroupNumber.data.map((groupItem) => {
            promiseArr.push(
              new Promise((res, rej) => {
                removeWithInstructions(groupItem.id).then(() => {
                  res("ok");
                });
              }),
            );

            Promise.all(promiseArr).then((result) => {
              this.loadData().then(() => {
                this.setState({ loading: false }, () => {});
              });
            });
          });
        }
      });
    } else {
      try {
        await removeWithInstructions(id);
        await this.loadData();
      } catch (error) {
        const err = GenerateErrorMessage(error, "An inspection is related to this order");
        this.context.updateSnack({
          show: true,
          color: "red",
          message: err,
        });
      } finally {
        this.setState({ loading: false }, () => {});
      }
    }
  };

  handleRemoveClose = () => {
    this.setState({ confirmRemove: false, confirmRemoveId: undefined, confirmRemoveGroup: undefined });
  };

  handleRemove = async (id, groupnumber) => {
    this.setState({ confirmRemove: true, confirmRemoveId: id, confirmRemoveGroup: groupnumber });
  };

  handleRevisionConfirm = async () => {
    const id = this.state.confirmRevisionId;
    const groupnumber = this.state.confirmRevisionGroup;
    const newGroupNumber = guid();

    this.setState({
      loading: true,
      confirmRevision: false,
      confirmRevisionId: undefined,
      confirmRevisionGroup: undefined,
    });

    if (groupnumber && groupnumber != 0) {
      getOrdersByGroupNumber(groupnumber).then((resultByGroupNumber) => {
        if (resultByGroupNumber && resultByGroupNumber.data && resultByGroupNumber.data.length > 0) {
          const promiseArr = [];
          resultByGroupNumber.data.map((groupItem) => {
            promiseArr.push(
              new Promise((res, rej) => {
                piorder.single(groupItem.id).then((data) => {
                  piorder.update(data.id, { data: { locked: true } });
                  data = CleanUpOrderObject(data);
                  const orderData = {
                    data: { ...data, revision: groupItem.revision + 1, groupnumber: newGroupNumber },
                  };
                  const promiseInstructionArr = [];
                  piorder.create(orderData).then((resultCreate) => {
                    const maxid = resultCreate[0];
                    FindByOrderId(groupItem.id).then((data) => {
                      data.map((item) => {
                        promiseInstructionArr.push(
                          new Promise((res, rej) => {
                            item["piorder_id"] = maxid;
                            delete item["id"];
                            item = CleanUpInstructionObject(item);
                            const instructionData = {
                              data: { ...item },
                            };
                            piinstructions.create(instructionData).then(() => {
                              res("ok");
                            });
                          }),
                        );
                      });
                    });
                    Promise.all(promiseInstructionArr).then(() => {
                      res("ok");
                    });
                  });
                });
              }),
            );

            Promise.all(promiseArr).then((result) => {
              this.loadData() // If locked, reload data
                .then(() => {
                  this.handleEdit(0, newGroupNumber);
                });
            });
          });
        }
      });
    } else {
      await piorder.single(id).then((data) => {
        piorder.update(data.id, { data: { locked: true } });
        data = CleanUpOrderObject(data);

        const orderData = {
          data: { ...data, revision: data.revision + 1 },
        };
        piorder.create(orderData).then((resultCreate) => {
          if (resultCreate.name && resultCreate.name.toLowerCase() == "error") {
            const err = "Revision for the order could not be created";
            this.context.updateSnack({ show: true, color: "red", message: err });
          } else {
            const maxid = resultCreate[0];
            this.loadData().then(() => {
              FindByOrderId(id).then((data) => {
                data.map((item) => {
                  item["piorder_id"] = maxid;
                  delete item["id"];
                  item = CleanUpInstructionObject(item);
                  const instructionData = {
                    data: { ...item },
                  };
                  piinstructions.create(instructionData).then(() => this.handleEdit(maxid, 0));
                });
              });
            });
          }
        });
      });
    }
  };

  handleRevisionClose = () => {
    this.setState({
      loading: false,
      confirmRevision: false,
      confirmRevisionId: undefined,
      confirmRevisionGroup: undefined,
    });
  };

  handleRevision = async (id, groupnumber) => {
    this.setState({
      loading: false,
      confirmRevision: true,
      confirmRevisionId: id,
      confirmRevisionGroup: groupnumber,
    });
  };

  handleLockConfirm = async () => {
    const id = this.state.confirmLockId;
    const groupnumber = this.state.confirmLockGroup;

    this.setState({
      loading: true,
      confirmLock: false,
      confirmLockId: undefined,
      confirmLockGroup: undefined,
    });

    if (groupnumber && groupnumber != 0) {
      getOrdersByGroupNumber(groupnumber).then((resultByGroupNumber) => {
        if (resultByGroupNumber && resultByGroupNumber.data && resultByGroupNumber.data.length > 0) {
          const promiseArr = [];
          resultByGroupNumber.data.map((groupItem) => {
            promiseArr.push(
              new Promise((res, rej) => {
                piorder
                  .update(id, { data: { locked: true } })
                  .then(() => {})
                  .then(() => {
                    res("ok");
                  });
              }),
            );

            Promise.all(promiseArr).then((result) => {
              this.loadData() // If locked, reload data
                .then(() => {
                  this.handleLockClose(); // loading --> false to stop loading after locked is true
                });
            });
          });
        }
      });
    } else {
      piorder.update(id, { data: { locked: true } }).then(() => {
        this.loadData() // If locked, reload data
          .then(() => {
            this.handleLockClose(); // loading --> false to stop loading after locked is true
          });
      });
    }
  };

  handleLockClose = () => {
    this.setState({
      loading: false,
      confirmLock: false,
      confirmLockId: undefined,
      confirmLockGroup: undefined,
    });
  };

  handleLock = async (id, groupnumber) => {
    this.setState({
      loading: false,
      confirmLock: true,
      confirmLockId: id,
      confirmLockGroup: groupnumber,
    });
  };

  handleExport = async (tabValue) => {
    this.setState({ progress: true });
    const list = tabValue == 1 ? this.state.previousYearOrdersFiltered : this.state.ordersFiltered;
    const idList = list.map((order) => order.id);
    getOrders(idList).then((result) => {
      DownloadPackInstruction(result.data).then(() => {
        this.setState({ progress: false, downloaded: true });
      });
    });
  };

  render() {
    if (this.state.redirectPath.length > 0) {
      const redirectTo = this.state.redirectPath;
      this.setState({ redirectPath: "" }, () => {});
      return <Redirect to={redirectTo} />;
    } else if (this.state.loading) {
      return (
        <div>
          <LinearProgress />
        </div>
      );
    } else {
      return (
        <div style={{ padding: "0" }}>
          {this.state.createNewOrder && (
            <div>
              <OrdersCreate
                handleClose={this.handleCreateClose}
                isOpen={this.state.createNewOrder}
                onSubmit={this.handleCreateSubmit}
                producers={this.state.producers}
                varieties={this.state.varieties}
                commodities={this.state.commodities}
                weeks={this.state.weeks}
              />
            </div>
          )}
          {this.state.confirmCopy && (
            <Confirmation
              isOpen={this.state.confirmCopy}
              handleClose={this.handleCopyClose}
              handleConfirm={this.handleCopyConfirm}
              title="Copy selected order?"
              body="Are you sure you want to COPY the selected order?"
            ></Confirmation>
          )}
          {this.state.confirmRemove && (
            <Confirmation
              isOpen={this.state.confirmRemove}
              handleClose={this.handleRemoveClose}
              handleConfirm={this.handleRemoveConfirm}
              title="Remove selected order?"
              body="Are you sure you want to REMOVE the selected order?"
            ></Confirmation>
          )}
          {this.state.confirmRevision && (
            <Confirmation
              isOpen={this.state.confirmRevision}
              handleClose={this.handleRevisionClose}
              handleConfirm={this.handleRevisionConfirm}
              title="Create a Revision for selected order?"
              body="Are you sure you want to REVISE the selected order?"
            ></Confirmation>
          )}
          {this.state.confirmLock && (
            <Confirmation
              isOpen={this.state.confirmLock}
              handleClose={this.handleLockClose}
              handleConfirm={this.handleLockConfirm}
              title="Do you want to Lock the selected order?"
              body="Are you sure you want to LOCK the selected order?"
            ></Confirmation>
          )}
          <Orders
            orders={this.state.ordersFiltered}
            progress={this.state.progress}
            handleEdit={this.handleEdit}
            handleCopy={this.handleCopy}
            handleCreate={this.handleCreate}
            handleRemove={this.handleRemove}
            handleRevision={this.handleRevision}
            handleLock={this.handleLock}
            handleExport={this.handleExport}
            previousYearOrders={this.state.previousYearOrders}
          />
        </div>
      );
    }
  }
}
export default OrdersContainer;

const Orders = ({ orders, progress, handleEdit, handleCopy, handleCreate, handleRemove, handleRevision, handleLock, handleExport, previousYearOrders }) => {
  return (
    <div>
      <Switch>
        <Route
          exact={true}
          path="/orders"
          component={() => {
            return (
              <div>
                <OrdersDashboard
                  orders={orders}
                  progress={progress}
                  handleEdit={handleEdit}
                  handleCreate={handleCreate}
                  handleCopy={handleCopy}
                  handleRemove={handleRemove}
                  handleRevision={handleRevision}
                  handleLock={handleLock}
                  handleExport={handleExport}
                  previousYearOrders={previousYearOrders}
                />
              </div>
            );
          }}
        />
      </Switch>
    </div>
  );
};

const OrdersDashboard = withStyles((theme: Theme) =>
  createStyles({
    orderButtons: {
      marginLeft: "10px",
      marginBottom: "10px",
    },
    inline: {
      display: "inline",
    },
    root: {
      marginTop: "-10px",
    },
  }),
)(({ orders, progress, handleEdit, handleCopy, handleCreate, handleRemove, handleRevision, handleLock, handleExport, previousYearOrders, classes }: any) => (
  <div className={classes.root}>
    <OrdersGrid
      orders={orders}
      handleEdit={handleEdit}
      handleCopy={handleCopy}
      handleRemove={handleRemove}
      handleRevision={handleRevision}
      handleLock={handleLock}
      handleCreate={handleCreate}
      handleExport={handleExport}
      progress={progress}
      previousYearOrders={previousYearOrders}
    />
    <div style={{ marginTop: "5px" }}></div>
  </div>
));
