import React from "react";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";

import Grid from "../../lib/components/grid";
import Confirmation from "../../lib/components/confirmation";

import { vessel } from "../../lib/api/vessel";
import { GridColumns } from "./vesselgridsetup";

import Tooltip from "@material-ui/core/Tooltip";
import Button from "@material-ui/core/Button";
import Close from "@material-ui/icons/Close";

import IconAdd from "@material-ui/icons/Add";

import VesselEditForm from "./vesseleditform";

import { SnackContext } from "../../lib/context/SnackContext";
import { GenerateErrorMessage } from "../../lib/helpers/string_methods";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1),
      width: "100%",
      height: "100%",
      position: "relative",
    },
    inline: {
      display: "inline",
      position: "absolute",
      marginTop: "7px",
      marginLeft: "7px",
    },
    Close: {
      display: "flex",
      justifyContent: "flex-end",
      margin: "10px 0px -5px",
      // paddingTop: "5px",
      // paddingBottom: "5px",
      position: "fixed",
      top: "10px",
      right: "15px",
    },
  });

type VesselsProps = { handleOnClose?: any } & WithStyles<typeof styles>;

export class VesselsUnstyled extends React.Component<VesselsProps, any> {
  state = {
    classes: undefined,
    data: [],
    confirmRemoveID: undefined,
    confirmEditID: undefined,
    loading: true,
    handleOnClose: undefined,
  };

  constructor(props) {
    super(props);
    this.state.classes = props.classes;
    this.state.handleOnClose = props.handleOnClose;
  }

  static contextType = SnackContext;
  context!: React.ContextType<typeof SnackContext>;

  componentDidMount() {
    this.loadData();
  }

  loadData = async () => {
    const data = await vessel.all();
    const dataSorted = data.sort((a, b) => (a.description > b.description ? 1 : a.description < b.description ? -1 : 1));
    this.setState({ loading: false, data: dataSorted });
  };

  handleCreate = () => {
    this.setState({ confirmEditID: "0" });
  };

  handleEdit = (id) => {
    this.setState({ confirmEditID: id });
  };

  handleEditClose = () => {
    this.setState({ confirmEditID: undefined });
  };

  handleSavedEvent = () => {
    this.setState({ confirmEditID: undefined });
    this.loadData();
  };

  handleRemove = async (id) => {
    this.setState({ confirmRemoveID: id });
  };

  handleRemoveClose = () => {
    this.setState({ confirmRemoveID: undefined });
  };

  handleRemoveConfirm = async () => {
    try {
      await vessel.remove(this.state.confirmRemoveID);
    } catch (error) {
      const err = GenerateErrorMessage(error, "Error removing data");
      this.context.updateSnack({ show: true, color: "red", message: err });
    }
    this.handleRemoveClose();
    this.loadData();
  };

  GridColumnsOverride = (column, row) => {
    return GridColumns(column, row, this.handleRemove, this.handleEdit);
  };

  render() {
    const { classes } = this.state;

    return (
      <div className={classes.root}>
        <div className={classes.inline}>
          {this.state.handleOnClose && (
            <div className={classes.Close}>
              <Button style={{ marginRight: "10px", backgroundColor: "white" }} color="secondary" variant="outlined" onClick={this.state.handleOnClose}>
                <Close />
              </Button>
            </div>
          )}
          <Tooltip title="Create new Vessel">
            <div className={classes.inline}>
              <Button variant="contained" color="primary" onClick={this.handleCreate} style={{ marginBottom: "10px" }}>
                <IconAdd />
              </Button>
            </div>
          </Tooltip>
        </div>
        <Grid loading={this.state.loading} data={this.state.data} GridColumns={this.GridColumnsOverride} clearFilters={"vesselgridsetup"} />
        {this.state.confirmRemoveID && (
          <Confirmation
            isOpen={this.state.confirmRemoveID ? true : false}
            handleClose={this.handleRemoveClose}
            handleConfirm={this.handleRemoveConfirm}
            title="Remove selected Vessel?"
            body="Are you sure you want to REMOVE the selected Vessel Record?"
          ></Confirmation>
        )}
        {this.state.confirmEditID && (
          <Confirmation
            isOpen={this.state.confirmEditID ? true : false}
            handleClose={this.handleEditClose}
            handleConfirm={() => {}}
            title={this.state.confirmEditID == "0" ? "Creating Vessel" : "Editing Vessel"}
            body={undefined}
          >
            <VesselEditForm onSubmittedEvent={this.handleSavedEvent} onClose={this.handleEditClose} vessel_id={this.state.confirmEditID} />
          </Confirmation>
        )}
      </div>
    );
  }
}

export default withStyles(styles)(VesselsUnstyled);
