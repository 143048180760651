import React from "react";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";

import LinearProgress from "@material-ui/core/LinearProgress";

import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

import {
  getProducerSaleAdjustmentByAdjustmentId,
  getProducerSaleAdjustmentBySaleId,
  ProducerSaleAdjustmentBySaleIdType,
  InvoiceType,
} from "../../../lib/api/producersaleadjustmentdetail";
import { GridColumnsAdjustment } from "./adjustmentgridsetup";
import { GridColumnsAdjustmentGrouped } from "./adjustmentgridsetupgrouped";
import Grid from "../../../lib/components/grid";

import numeral from "numeral";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      height: "100%",
    },
    divDetails: {
      gridArea: "details",
      paddingLeft: "5px",
      width: "calc(100vw - 200px)",
      position: "relative",
    },
    divTotals: {
      gridArea: "totals",
      width: "calc(100vw - 194px)",
    },
  });

type AdjustmentGridProps = {
  sale_id: number;
  adjustment_id: number;
  handleDetailUpdate: (detail) => void;
} & WithStyles<typeof styles>;

export class AdjustmentGrid extends React.Component<AdjustmentGridProps, any> {
  state = {
    sale_id: undefined,
    adjustment_id: undefined,
    handleDetailUpdate: undefined,
    loading: true,
    adjustmentDetail: [{}],
    adjustmentTotal: 0,
    gridHeight: 650,
    cellSelected: undefined,
    cellSelectedGrouped: undefined,
    updateFruitSpec: false,
    currentRow: undefined,
    currentCol: undefined,
  };

  constructor(props) {
    super(props);
    this.state.sale_id = props.sale_id;
    this.state.adjustment_id = props.adjustment_id;
    this.state.handleDetailUpdate = props.handleDetailUpdate;
  }

  componentDidMount() {
    this.loadAdjustmentDetails();
  }

  loadDetail = async () => {
    if (this.state.adjustment_id !== InvoiceType.Adjustment) {
      return await getProducerSaleAdjustmentByAdjustmentId(this.state.adjustment_id);
    }
    return await getProducerSaleAdjustmentBySaleId(this.state.sale_id);
  };

  loadAdjustmentDetails = async () => {
    this.setState({ loading: true });

    const data = await this.loadDetail();
    const total = data.reduce(
      (total: number, dataItem: ProducerSaleAdjustmentBySaleIdType) =>
        (total += dataItem.saleadjustmentdetail_cartons * (dataItem.saleadjustmentdetail_amount ? dataItem.saleadjustmentdetail_amount : 0)),
      0,
    );
    this.setState({ adjustmentDetail: data, adjustmentTotal: total, loading: false });
    this.state.handleDetailUpdate(data);
  };

  handleFruitSpecSellingUpdate = () => {
    this.setState({ updateFruitSpec: !this.state.updateFruitSpec });
  };

  getGroupedFruitSpec = (arr) => {
    const keyProps = ["commodityCode", "varietyCode", "packCode", "markCode", "gradeCode", "countCode"];
    const kvArray = arr.map((entry) => {
      const key = keyProps.map((k) => entry[k]).join("|");
      return [key, entry];
    });

    const map = new Map(kvArray);
    return Array.from(map.values());
  };

  clearGridValues = () => {
    const data = this.state.adjustmentDetail.map((rowItem: ProducerSaleAdjustmentBySaleIdType) => ({
      ...rowItem,
      saleadjustmentdetail_cartons: 0,
      saleadjustmentdetail_amount: 0,
    }));
    this.setState({ adjustmentDetail: data, adjustmentTotal: 0.0 });
    this.state.handleDetailUpdate(data);
  };

  handleKeyDown = (event, gridRef, grouped = false) => {
    const enterKey = 13;
    const tabKey = 9;

    if (event.which === enterKey || event.which === tabKey) {
      event.preventDefault();

      const tagName = event.target.tagName && event.target.tagName.toLowerCase();

      if (tagName != "div") {
        gridRef.selectCell({ idx: this.state.currentCol, rowIdx: this.state.currentRow }, true);
      }
      //
      else {
        if (grouped) {
          this.setState({ currentRow: this.state.currentRow + 1, currentCol: 6 });
          setTimeout(() => {
            gridRef.selectCell({ idx: 6, rowIdx: this.state.currentRow }, true);
          }, 200);
        }
        //
        else {
          if (this.state.currentCol == 9) {
            this.setState({ currentRow: this.state.currentRow + 1, currentCol: 8 });
            setTimeout(() => {
              gridRef.selectCell({ idx: 8, rowIdx: this.state.currentRow }, true);
            }, 200);
          }
          //
          else {
            this.setState({ currentCol: this.state.currentCol + 1 });
            setTimeout(() => {
              gridRef.selectCell({ idx: this.state.currentCol, rowIdx: this.state.currentRow }, true);
            }, 200);
          }
        }
      }
    }
  };

  handleRowsUpdated = (grid, data, column, grouped = false) => {
    const rows = this.state.adjustmentDetail;

    const newRows: any[] = rows.map((row) => {
      const exists = data.find((dataRow) => {
        if (grouped) {
          if (
            row["commodityCode"] == dataRow["commodityCode"] &&
            row["varietyCode"] == dataRow["varietyCode"] &&
            row["packCode"] == dataRow["packCode"] &&
            row["markCode"] == dataRow["markCode"] &&
            row["gradeCode"] == dataRow["gradeCode"] &&
            row["countCode"] == dataRow["countCode"]
          ) {
            const rowAmount = Number(row["saleadjustmentdetail_amount"]);
            const updatedAmount = Number(dataRow["saleadjustmentdetail_amount"]);

            return {
              ...row,
              saleadjustmentdetail_amount: dataRow["saleadjustmentdetail_amount"],
              saleadjustmentdetail_cartons: rowAmount != updatedAmount ? row["barcode_cartons"] : row["saleadjustmentdetail_cartons"],
            };
          }
        } else {
          if (
            row["salesdetail_id"] == dataRow["salesdetail_id"] &&
            row["saledetail_barcode"] == dataRow["saledetail_barcode"] &&
            row["commodityCode"] == dataRow["commodityCode"] &&
            row["varietyCode"] == dataRow["varietyCode"] &&
            row["packCode"] == dataRow["packCode"] &&
            row["markCode"] == dataRow["markCode"] &&
            row["gradeCode"] == dataRow["gradeCode"] &&
            row["countCode"] == dataRow["countCode"]
          ) {
            return { ...row, saleadjustmentdetail_amount: dataRow["saleadjustmentdetail_amount"], saleadjustmentdetail_cartons: dataRow["saleadjustmentdetail_cartons"] };
          }
        }
      });
      if (exists) {
        return { ...row, saleadjustmentdetail_amount: exists["saleadjustmentdetail_amount"], saleadjustmentdetail_cartons: exists["saleadjustmentdetail_cartons"] };
      }
      return row;
    });

    const invoiceTotal = newRows.reduce((a, b) => a + b.saleadjustmentdetail_cartons * (b.saleadjustmentdetail_amount ? b.saleadjustmentdetail_amount : 0), 0);

    this.setState({ adjustmentDetail: newRows, adjustmentTotal: invoiceTotal }, () => {});
    this.state.handleDetailUpdate(newRows);
  };

  handleSelectedRowChange = (selectedRow: any, colums: { selectedColumn: number; columnsLength: number }) => {
    this.setState({ currentRow: selectedRow.rowIdx, currentCol: colums.selectedColumn });
  };

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
          <div style={{ display: "flex", flexDirection: "row", flexGrow: 1, height: "39px", marginTop: "10px" }}>
            <div>
              <Button name="clearvalues" variant="contained" color="primary" style={{ margin: "2px" }} disabled={false} onClick={this.clearGridValues}>
                clear values
              </Button>
            </div>
          </div>
        </div>
        {this.state.loading ? (
          <LinearProgress />
        ) : (
          <div style={{ textAlign: "left" }}>
            <div id="divDetails" className={classes.divDetails}>
              {!this.state.updateFruitSpec && (
                <Grid
                  fill
                  clearFilters={"produceradjustmentadjust"}
                  data={this.state.adjustmentDetail}
                  forceHeight={this.state.gridHeight}
                  handleGridKeyDown={this.handleKeyDown}
                  handleChange={this.handleRowsUpdated}
                    totalRowColumns={["barcode_cartons", "palletSize", "saleadjustmentdetail_cartons", "saleadjustmentdetail_amount"]}
                  GridColumns={(data, filters, columnArrangement, columnsWidth) => GridColumnsAdjustment(data, filters, columnsWidth, true)}
                  selectedRowChange={this.handleSelectedRowChange}
                />
              )}
              {this.state.updateFruitSpec && (
                <Grid
                  fill
                  clearFilters={"produceradjustmentsadjustgrouped"}
                  data={this.getGroupedFruitSpec(this.state.adjustmentDetail)}
                  forceHeight={this.state.gridHeight}
                  handleGridKeyDown={this.handleKeyDown}
                  handleChange={(grid, row, column) => this.handleRowsUpdated(grid, row, column, true)}
                  totalRowColumns={["saleadjustmentdetail_amount"]}
                  GridColumns={(data, filters, columnArrangement, columnsWidth) => GridColumnsAdjustmentGrouped(data, filters, columnsWidth)}
                  selectedRowChange={this.handleSelectedRowChange}
                />
              )}
            </div>
            <div className={classes.divTotals}>
              <div style={{ float: "left" }}>
                <Typography component="div">
                  <Box fontWeight="fontWeightBold" m={1} fontSize={24}>
                    {`Total: ${numeral(this.state.adjustmentTotal).format("0,0.00")}`}
                  </Box>
                </Typography>
              </div>
              <div style={{ float: "right" }}>
                <FormControlLabel
                  control={<Checkbox checked={this.state.updateFruitSpec} onChange={this.handleFruitSpecSellingUpdate} name="checkedB" color="primary" />}
                  label="SHOW GROUPED FRUITSPEC GRID"
                />
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default withStyles(styles)(AdjustmentGrid);
