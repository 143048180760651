export const DestinationType = {
  1: "DEPOT",
  3: "CUSTOMER",
};

export const getDestinationTypeReadyForCombo = () => {
  const result = [];
  Object.keys(DestinationType).map((dt) => {
    result.push({ value: dt, display: DestinationType[dt] });
  });
  return result;
};
