import React from "react";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";

import Templates from "./templates";

// import Slide from "@material-ui/core/Slide";
// function Transition(props) {
//   return <Slide direction="up" {...props} />;
// }

export const TemplatesDialog = ({ isOpen, title, handleClose, handleConfirm, varietyid }) => {
  return (
    <>
      <Dialog
        open={isOpen}
        // TransitionComponent={Transition}
        onClose={handleClose}
        fullScreen={true}
        // fullWidth={true}
        // aria-labelledby="alert-slide-dialog-title"
        // aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent>
          <Button variant="contained" color="secondary" style={{ margin: "2px" }} onClick={handleClose}>
            close
          </Button>

          <Templates maintenancemode={false} handleAddTemplateLines={handleConfirm} varietyid={varietyid} />
        </DialogContent>
      </Dialog>
    </>
  );
};
