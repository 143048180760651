import React, { forwardRef, useCallback, useEffect, useMemo, useState } from "react";

import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";

import { IAdhocInvoice, adhocInvoice } from "../../lib/api/adhoc_invoice";
import { GreenButton, RedButton } from "../../lib/components/ColorButtons";
import { getClientsAllSortedMappedforComboID } from "../../lib/api/clients";
import DetailGrid, { DetailGridType } from "./detailgrid";
import { getAdhocInvoiceDetailByAdhocId, IAdhocInvoiceDetail, processAdhocInvoiceAndDetailSave } from "../../lib/api/adhoc_invoice_detail";

import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import LinearProgress from "@material-ui/core/LinearProgress";

import { TextField, Select } from "final-form-material-ui";
import { Field, Form } from "react-final-form";
import ReactDatePicker from "react-datepicker";
import toDate from "date-fns/toDate";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      height: "100%",
      position: "relative",
    },
    toolbarWrapper: {
      position: "relative",
    },
    toolbar: {
      position: "absolute",
      left: 0,
      width: "100%",
    },
    formBody: {
      display: "flex",
      flexDirection: "row",
      paddingTop: `${theme.spacing(2)}px`,
      backgroundColor: "#fff",
      padding: "20px",
      borderRadius: "10px",
      gap: `${theme.spacing(4)}px`,
    },
    formRow: {
      display: "flex",
      flexDirection: "column",
    },
    formField: {
      display: "grid",
      gridTemplateColumns: "150px 300px",
      gap: `${theme.spacing(2)}px`,
      textAlign: "right",
      "& p": {
        fontSize: "16px",
      },
      "& div": {
        marginTop: "3px",
      },
    },
    formSelect: {
      textAlign: "left",
      width: "300px",
      height: "50px",
      padding: 0,
      marginTop: "-14px",
    },
  });

type AdhocInvoiceProps = {
  history: any;
} & WithStyles<typeof styles>;

const AdhocInvoiceEditUnstyled: React.FC<AdhocInvoiceProps> = ({ classes, history }) => {
  const [adhocInvoiceHead, setAdhocInvoiceHead] = useState<IAdhocInvoice>();
  const [adhocInvoiceDetail, setAdhocInvoiceDetail] = useState<IAdhocInvoiceDetail[]>([]);
  const [clientsData, setClientsData] = useState<any[]>([]);
  const [submitting, setSubmitting] = useState<boolean>(false);

  const loadAdhocInvoiceHead = async (headId: number) => {
    const [clientsResult] = await Promise.all([getClientsAllSortedMappedforComboID()]);
    if (headId && headId > 0) {
      const [head, detail] = await Promise.all([adhocInvoice.single(headId), getAdhocInvoiceDetailByAdhocId(headId)]);
      setAdhocInvoiceHead(head);
      setAdhocInvoiceDetail(detail.data);
    }
    setClientsData(clientsResult);
  };

  useEffect(() => {
    const [_, path, id] = history.location.pathname.split("/");
    loadAdhocInvoiceHead(Number(id));
  }, [history]);

  const handleAddDetailRow = () => {
    const generateId = Math.floor(Math.random() * 999999) + 100000;
    setAdhocInvoiceDetail([...adhocInvoiceDetail, { id: generateId, description: "", quantity: 0, unit_price: 1 }]);
  };

  const handleRowChange = (rows: IAdhocInvoiceDetail[]) => {
    setAdhocInvoiceDetail(rows.map((item) => ({ ...item, sub_total: item.quantity * item.unit_price })));
  };

  const handleProcessSave = async (formValues: IAdhocInvoice) => {
    setSubmitting(true);
    const head: { data: IAdhocInvoice } = {
      data: {
        date: formValues.date || new Date(),
        client_orderno: formValues.client_orderno,
        reference: formValues.reference,
        invoice_number: formValues.invoice_number,
        clients_id: formValues.clients_id,
        duedate: formValues.duedate || new Date(),
      },
    };
    await processAdhocInvoiceAndDetailSave(head, formValues, adhocInvoiceDetail);
    setSubmitting(false);
    handleClose();
  };

  const handleClose = () => {
    history.push(`/adhocinvoice`);
  };

  return (
    <div>
      {submitting && <LinearProgress color="secondary" style={{ marginBottom: "5px" }} />}
      <Form
        onSubmit={handleProcessSave}
        initialValues={{ ...adhocInvoiceHead }}
        render={({ handleSubmit, values, form }: any) => (
          <form onSubmit={handleSubmit}>
            <div>
              <GreenButton variant="contained" type="submit">
                Save and close
              </GreenButton>
              <RedButton variant="contained" type="button" onClick={handleClose}>
                Close
              </RedButton>
            </div>
            <div className={classes.formBody}>
              <div className={classes.formRow}>
                <TableFieldCombo classes={classes} field="clients_id" title="Issued To" data={clientsData} addEmptyValue={undefined} />
                <TableFieldDate classes={classes} field="date" form={form} title="Date" isClearable={false} />
                <TableFieldDate classes={classes} field="duedate" form={form} title="Due Date" isClearable={false} />
              </div>
              <div className={classes.formRow}>
                <TableFieldText classes={classes} field="invoice_number" title="Invoice Number" disabled={false} />
                <TableFieldText classes={classes} field="reference" title="Reference" disabled={false} />
                <TableFieldText classes={classes} field="client_orderno" title="Client Order No" disabled={false} />
              </div>
            </div>
            <DetailGrid
              gridType={DetailGridType.AddLine}
              selectedDetail={adhocInvoiceDetail}
              documentDetail={[]}
              handleAddRow={handleAddDetailRow}
              handleRowChange={handleRowChange}
              gridHeight={600}
            />
          </form>
        )}
      />
    </div>
  );
};

const TableFieldText: React.FunctionComponent<{ field: string; title: string; disabled: boolean } & WithStyles<typeof styles>> = (props) => {
  const { classes, field, title, disabled } = props;
  return (
    <div className={classes.formField}>
      <p>{title}:</p>
      <div>
        <Field name={field} component={TextField} type="text" fullWidth={true} disabled={disabled} />
      </div>
    </div>
  );
};

const CalenderCustomInput = forwardRef((props: any, ref: any) => {
  return (
    <Button name="CalenderCustomInput" variant="contained" color="primary" onClick={props.onClick} style={{ marginTop: "5px", width: "300px", minHeight: "36px" }}>
      {props.value}
    </Button>
  );
});

const TableFieldDate: React.FunctionComponent<{ field: string; title: string; isClearable: boolean; form: any } & WithStyles<typeof styles>> = (props) => {
  const { classes, field, title, isClearable, form } = props;

  const handleChangeDate = useCallback((value) => form.change(field, value), [field, form]);
  const dateValue = useMemo(() => (form.getState().values[field] ? new Date(form.getState().values[field]) : new Date()), [form, field]);

  return (
    <div className={classes.formField}>
      <p>{title}:</p>
      <div>
        <ReactDatePicker
          isClearable={isClearable}
          locale="en-GB"
          showWeekNumbers={true}
          selected={dateValue ? toDate(dateValue) : new Date()}
          onChange={handleChangeDate}
          dateFormat="dd-MM-yyyy"
          placeholderText="click here to select a date"
          customInput={<CalenderCustomInput />}
        />
      </div>
    </div>
  );
};

const TableFieldCombo: React.FunctionComponent<{ classes: any; title: string; field: string; data: any; addEmptyValue: string; disabled?: boolean } & WithStyles<typeof styles>> = (
  props,
) => {
  const { classes, title, field, data, addEmptyValue, disabled = false } = props;
  return (
    <div className={classes.formField}>
      <p>{title}:</p>
      <div>
        <Field name={field} disabled={disabled} component={Select} formControlProps={{ className: classes.formSelect }}>
          {addEmptyValue && (
            <MenuItem key={"emptyvalueMenuItem"} value={-1}>
              {addEmptyValue}
            </MenuItem>
          )}
          {(data || []).map((item, index) => (
            <MenuItem key={`${item.value}${index}`} value={item.value}>
              {item.display}
            </MenuItem>
          ))}
        </Field>
      </div>
    </div>
  );
};

export default withStyles(styles)(AdhocInvoiceEditUnstyled);
