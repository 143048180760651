import React, { Component } from "react";

import format from "date-fns/format";

import Grid from "../../../../lib/components/grid";
import { GridColumnsAllocationList } from "./gridsetup";
import { AllocationList } from "../../../../lib/api/salefundsallocation";

type Props = {
  data: AllocationList[];
  clients_code: string;
  removeAllocationIds: number[];
  handleSelectRemoveAllocation(id: number): void;
  handleChange(property: string, value: any[]): void;
};

export class AllocationGridTable extends Component<Props, {}> {
  constructor(props) {
    super(props);
  }

  state = {};

  generateExportName = () => {
    return `${format(new Date(), "yyMMdd")}_${this.props.clients_code}`;
  };

  render() {
    return (
      <Grid
        rowHeight={30}
        loading={false}
        forceHeight={300}
        data={this.props.data}
        handleChange={this.props.handleChange}
        clearFilters={"allocationlist"}
        exportName={this.generateExportName()}
        totalRowColumns={["amount", "amountCredit", "amountDebit"]}
        GridColumns={(data, filters, arrangement, columnsWidth) =>
          GridColumnsAllocationList(data, filters, arrangement, columnsWidth, this.props.removeAllocationIds, this.props.handleSelectRemoveAllocation)
        }
      />
    );
  }
}
