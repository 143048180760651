import React, { useEffect, useState } from "react";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";

import Close from "@material-ui/icons/Close";
import Check from "@material-ui/icons/Check";
import Button from "@material-ui/core/Button";

import { weeks } from "../../lib/api/week";

import ReactDatePicker, { registerLocale } from "react-datepicker";
import { format } from "date-fns";
import en from "date-fns/esm/locale/en-GB";
registerLocale("en-GB", en);

import { Form, Field } from "react-final-form";
import { TextField } from "final-form-material-ui";
import Modal from "@material-ui/core/Modal";
import Input from "@material-ui/core/Input";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1),
      width: "420px",
      overflow: "visible",
    },
    tableRow: {
      paddingTop: theme.spacing(1),
      paddingRight: theme.spacing(1) * 2,
      marginTop: "5%",
      textAlign: "right",
    },
    inputs: {
      paddingBottom: "2px",
      paddingTop: "2px",
      width: "200px",
      textAlign: "left",
    },
    modal: {
      marginTop: "14.6%",
      marginLeft: "42.5%",
    },
    modalDatePicker: {
      width: "280px",
      height: "305px",
      paddingBottom: "50px",
      paddingTop: "20px",
      paddingLeft: "20px",
      backgroundColor: "white",
    },
  });

type WeeksEditFormProps = {
  onSubmit: any;
  onClose: any;
  id: any;
} & WithStyles<typeof styles>;

class WeeksEditFormUnstyled extends React.Component<WeeksEditFormProps, any> {
  state = {
    classes: undefined,
    item: { id: "0", week: "", period_start: new Date(), period_end: new Date(), financial_year: undefined },
    onSubmit: undefined,
    onClose: undefined,
    id: undefined,
  };

  constructor(props) {
    super(props);
    this.state.classes = props.classes;
    this.state.onSubmit = props.onSubmit;
    this.state.onClose = props.onClose;
    this.state.id = props.id;
  }

  componentDidMount() {
    if (this.state.id != "0") {
      this.getItem(this.state.id);
    }
  }

  getItem = (id) => {
    weeks.single(id).then((data) => {
      this.setState({ item: data });
    });
  };

  handleFocus = (event) => {
    event.target.select();
  };

  onChangeDate = (field, period) => {
    if (field != "period_start") return this.setState({ item: { ...this.state.item, period_end: period } });
    return this.setState({ item: { ...this.state.item, period_start: period } });
  };

  render() {
    const { classes } = this.state;

    return (
      <div className={classes.root}>
        <Form
          initialValues={{ ...this.state.item }}
          onSubmit={(value) => this.state.onSubmit(value)}
          render={({ handleSubmit, form, submitting, pristine, values }) => (
            <form id={values.id} onSubmit={handleSubmit}>
              <table style={{ marginLeft: "15%" }}>
                <tbody>
                  <TableFieldDatePicker classes={classes} field="period_start" title="Period Start" values={values} handleChange={this.onChangeDate} />
                  <TableFieldDatePicker classes={classes} field="period_end" title="Period End" values={values} handleChange={this.onChangeDate} />
                  <TableFieldText classes={classes} field="week" title="Week" disabled={false} multiline={false} required={false} value={this.state.item.week} />
                  <TableFieldText
                    classes={classes}
                    field="financial_year"
                    title="Finanical Year"
                    disabled={false}
                    multiline={false}
                    required={false}
                    value={this.state.item.financial_year}
                  />
                </tbody>
              </table>
              <div style={{ display: "flex", justifyContent: "flex-end", margin: "10px 0 -5px 0" }}>
                <Button style={{ marginRight: "10px" }} color="secondary" variant="outlined" onClick={this.state.onClose}>
                  <Close />
                </Button>
                <Button type="submit" color="primary" variant="outlined">
                  <Check />
                </Button>
              </div>
            </form>
          )}
        />
      </div>
    );
  }
}

export default withStyles(styles)(WeeksEditFormUnstyled);

const TableFieldText: React.FunctionComponent<
  { field: string; title: string; disabled: boolean; multiline: boolean; required: boolean; value: string } & WithStyles<typeof styles>
> = (props) => {
  const { classes, title, field, disabled, multiline, required, value } = props;

  return (
    <tr>
      <td className={classes.tableRow} style={{ verticalAlign: "top", paddingTop: "13px" }}>
        <span>{`${title}: `}</span>
      </td>
      <td className={classes.inputs}>
        <Field
          value={value}
          name={field}
          key={`body_value_${field}`}
          component={TextField}
          type="text"
          className={classes.inputs}
          multiline={multiline}
          rows={multiline ? "4" : "1"}
          required={required}
          disabled={disabled}
        />
      </td>
    </tr>
  );
};

const TableFieldDatePicker: React.FC<{ field: string; title: string; values: any; handleChange: any } & WithStyles<typeof styles>> = (props) => {
  const { classes, field, title, values, handleChange } = props;

  const [currentPeriod, setCurrentPeriod] = useState(new Date());
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setCurrentPeriod(new Date(values[field]));
  }, [values]);

  return (
    <tr className={classes.tableRow}>
      <td className={classes.tableRow}>
        <span>{`${title} :`}</span>
      </td>
      <td className={classes.tableRow}>
        <Input value={format(currentPeriod, "yyyy-MM-dd")} name={field} type="text" className={classes.inputs} onClick={() => setOpen(!open)} />
        <Modal className={classes.modal} aria-labelledby="simple-modal-title" aria-describedby="simple-modal-description" open={open} onClose={() => setOpen(!open)}>
          <div className={classes.modalDatePicker}>
            <ReactDatePicker
              locale="en-GB"
              showWeekNumbers={true}
              name={field}
              open={true}
              className={classes.inputs}
              selected={currentPeriod}
              selectsStart
              startDate={currentPeriod}
              endDate={new Date()}
              onChange={(value) => {
                setCurrentPeriod(value);
                handleChange(field, value);
                setOpen(!open);
              }}
              dateFormat="dd-MM-yyyy"
            />
          </div>
        </Modal>
      </td>
    </tr>
  );
};
