import React from "react";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";

import CellActions, { GridColumns } from "./clientscontractsgridsetup";
import Tooltip from "@material-ui/core/Tooltip";
import Button from "@material-ui/core/Button";
import IconAdd from "@material-ui/icons/Add";

import ClientsContractsForm from "./clientscontractsform";

import Grid from "../../lib/components/grid";
import Confirmation from "../../lib/components/confirmation";
import { batchInsertOrCreateClientContracts, clientsContracts, clientscontractsFull, ClientsContractsFullType } from "../../lib/api/clientscontracts";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1),
      width: "100%",
      height: "100%",
      position: "relative",
    },
    inline: {
      display: "inline",
      position: "absolute",
      marginTop: "7px",
      marginLeft: "7px",
    },
  });

type ClientsContractsProps = {} & WithStyles<typeof styles>;

export class ClientsContractsUnstyled extends React.Component<ClientsContractsProps, any> {
  state = {
    classes: undefined,
    data: [],
    confirmRemoveID: undefined,
    confirmEditID: undefined,
    loading: true,
    confirmDuplicateID: undefined,
  };

  constructor(props) {
    super(props);
    this.state.classes = props.classes;
  }

  componentDidMount() {
    this.loadData();
  }

  loadData = async () => {
    const result = await clientscontractsFull();
    this.setState({ loading: false, data: result });
  };

  handleCreate = () => {
    this.setState({ confirmEditID: "0" });
  };

  handleEdit = (id) => {
    this.setState({ confirmEditID: id });
  };

  handleEditClose = () => {
    this.setState({ confirmEditID: undefined });
  };

  handleEditConfirm = async (item: ClientsContractsFullType) => {
    await batchInsertOrCreateClientContracts(item);
    this.handleDuplicateClose();
    this.handleEditClose();
    this.loadData();
  };

  handleRemove = async (id) => {
    this.setState({ confirmRemoveID: id });
  };

  handleRemoveClose = () => {
    this.setState({ confirmRemoveID: undefined });
  };

  handleRemoveConfirm = async () => {
    await clientsContracts.remove(this.state.confirmRemoveID);
    this.handleRemoveClose();
    this.loadData();
  };

  handleDuplicate = (id: number) => {
    this.setState({ confirmDuplicateID: id });
  };

  handleConfirmDuplicate = (item: ClientsContractsFullType) => {
    this.handleEditConfirm({ ...item, id: "0" });
  };

  handleDuplicateClose = () => {
    this.setState({ confirmDuplicateID: undefined });
  };

  getCellActions = (column, row) => {
    return CellActions(column, row, this.handleRemove, this.handleEdit, this.handleDuplicate);
  };

  render() {
    const { classes } = this.state;

    return (
      <div className={classes.root}>
        <div className={classes.inline}>
          <Tooltip title="Create new Client Contract">
            <div className={classes.inline}>
              <Button variant="contained" color="primary" onClick={this.handleCreate} style={{ marginBottom: "10px" }}>
                <IconAdd />
              </Button>
            </div>
          </Tooltip>
        </div>
        <Grid
          loading={this.state.loading}
          data={this.state.data}
          GridColumns={(column, row, arrangement, columnsWidth) => GridColumns(column, row, arrangement, columnsWidth, this.handleRemove, this.handleEdit, this.handleDuplicate)}
          clearFilters={"clientscontractsgridsetup"}
        />
        {this.state.confirmRemoveID && (
          <Confirmation
            isOpen={this.state.confirmRemoveID ? true : false}
            handleClose={this.handleRemoveClose}
            handleConfirm={this.handleRemoveConfirm}
            title="Remove selected Client Contract?"
            body="Are you sure you want to REMOVE the selected Client Contract Record?"
          ></Confirmation>
        )}
        {this.state.confirmEditID && (
          <Confirmation
            isOpen={this.state.confirmEditID ? true : false}
            handleClose={this.handleEditClose}
            handleConfirm={() => {}}
            title={this.state.confirmEditID == "0" ? "Creating Client Contract" : "Editing Client Contract"}
            body={undefined}
          >
            <ClientsContractsForm onSubmit={this.handleEditConfirm} onClose={this.handleEditClose} id={this.state.confirmEditID} />
          </Confirmation>
        )}
        {this.state.confirmDuplicateID && (
          <Confirmation
            isOpen={this.state.confirmDuplicateID ? true : false}
            handleClose={this.handleDuplicateClose}
            handleConfirm={() => {}}
            title={"Copy Client Contract"}
            body={undefined}
          >
            <ClientsContractsForm onSubmit={this.handleConfirmDuplicate} onClose={this.handleDuplicateClose} id={this.state.confirmDuplicateID} isCopied />
          </Confirmation>
        )}
      </div>
    );
  }
}

export default withStyles(styles)(ClientsContractsUnstyled);
