import React from "react";

import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";

import "react-splitter-layout/lib/index";
import "../../lib/custom/splitter.css";
import Splitter from "m-react-splitters";
import "m-react-splitters/lib/splitters.css";

import Grid from "../../lib/components/grid";
import { ColumnsEDIFileComparisonCurrent } from "./edifilecomparisoncurrentgridsetup";
import { getBarcodeComparisonData, getCurrentBarcodeData, getStockByBarcodes, getStockData } from "../../lib/api/edifilecomparison";

import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";

import { ColumnsEDIFileComparison } from "./edifilecomparisongridsetup";
import Confirmation from "../../lib/components/confirmation";
import PSFileList from "./psfilelist";
import { Row } from "react-data-grid";

import format from "date-fns/format";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1),
      width: "100%",
      height: "calc(100vh - 100px)",
      position: "relative",
    },
    splitterWrapper: {
      position: "relative",
      height: "100%",
      width: "100%",
      display: "grid",
      gap: "20px",
    },
    splitter: {
      gap: "10px",
    },
    toolbar: {
      position: "absolute",
      left: 0,
      width: "100%",
      gap: "10px",
    },
    toolbarWrapper: {
      position: "relative",
    },
    errorRow: {
      backgroundColor: "#D0342C",
      color: "white",
      "&:hover": {
        backgroundColor: "#D0342C",
      },
    },
  });

type EDIFileComparisonProps = {
  match: any;
  history: any;
} & WithStyles<typeof styles>;

class EDIFileComparisonUnstyled extends React.Component<EDIFileComparisonProps, {}> {
  constructor(props) {
    super(props);
  }

  state = {
    loading: true,
    comparisonLoading: false,
    currentData: [],
    comparisonData: [],
    gridHeight: 500,
    openSelect: false,
  };

  componentDidMount() {
    const id = this.props.match.params.id;
    if (id) {
      this.loadFileData(parseInt(id));
    } else {
      this.loadData();
    }
    this.setGridHeights();
  }

  loadFileData = async (id: number) => {
    this.setState({ loading: true });
    const [comparisonBarcodeData, currentBarcodeData] = await Promise.all([getBarcodeComparisonData(id), getCurrentBarcodeData(id)]);
    const { comparisonStock, currentStock } = this.getValidation(currentBarcodeData.data, comparisonBarcodeData.data);

    this.setState({
      loading: false,
      currentData: currentStock,
      comparisonData: comparisonStock,
      comparisonLoading: false,
    });
  };

  loadData = async () => {
    this.setState({ loading: true });
    const currentBarcodeData = await getStockData();
    this.setState({ loading: false, currentData: currentBarcodeData.data });
  };

  handleComparison = (data: any[], dataSet) => {
    const validationCols = [
      "Barcode",
      "Farm Number",
      "Commodity Code",
      "Variety Code",
      "Pack Code",
      "Mark Code",
      "Grade Code",
      "Count Code",
      "Location Code",
      "No Cartons",
      "Pallet Size",
      "Orig Intake Waybill",
    ];

    return data.reduce((arr, item) => {
      const key = `${item.Barcode}_${parseInt(item["Sequence Number"])}`;
      const currentItem = dataSet[key];

      if (!currentItem) {
        arr.push({ ...item, edicomparisonerror: "Missing" });
        return arr;
      }

      const cols = [];
      validationCols.forEach((col) => {
        if (col == "No Cartons" || col == "Pallet Size") {
          if (parseFloat(currentItem[col]) != parseFloat(item[col])) {
            cols.push(col);
          }
        } else if (currentItem[col].toString().trim() != item[col].toString().trim()) {
          cols.push(col);
        }
      });

      if (cols.length === 0) {
        arr.push(item);
      } else {
        const filtered = cols.filter((item) => !(item == "No Cartons" && currentItem["Location Code"].toUpperCase() == "SCHOONB"));
        arr.push({ ...item, edicomparisonerror: filtered.join(", ") });
      }

      return arr;
    }, []);
  };

  getValidation = (currentStockData: any[], comparisonData: any[]) => {
    const comparisonDataKeySet = comparisonData.reduce((obj, item) => {
      obj[`${item.Barcode}_${parseInt(item["Sequence Number"])}`] = item;
      return obj;
    }, {});

    const currentDataKeySet = currentStockData.reduce((obj, item) => {
      obj[`${item.Barcode}_${parseInt(item["Sequence Number"])}`] = item;
      return obj;
    }, {});

    const comparisonStock = this.handleComparison(comparisonData, currentDataKeySet);
    const currentStock = this.handleComparison(currentStockData, comparisonDataKeySet);

    return { currentStock, comparisonStock };
  };

  setGridHeights = () => {
    const primarysplit = document.getElementById("main_splitter_div");
    this.setState({ gridHeight: primarysplit.clientHeight - 70 });
  };

  GridColumnsOvverideCurrent = (data, filters, columnArrangement, columnsWidth) => {
    return ColumnsEDIFileComparisonCurrent(data, filters, columnArrangement, columnsWidth);
  };

  GridColumnsOvverideComparison = (data, filters, columnArrangement, columnsWidth) => {
    return ColumnsEDIFileComparison(data, filters, columnArrangement, columnsWidth);
  };

  filteredCurrentStock = [];
  handleFilteredRows = (rows) => {
    this.filteredCurrentStock = rows;
  };

  loadSelectedFileData = async (id: number) => {
    this.setState({ loading: true });

    const [comparisonBarcodeData, currentBarcodeData] = await Promise.all([getBarcodeComparisonData(id), getCurrentBarcodeData(id)]);
    const { currentStock, comparisonStock } = this.getValidation(currentBarcodeData.data, comparisonBarcodeData.data);

    this.setState({
      loading: false,
      currentData: currentStock,
      comparisonData: comparisonStock,
      comparisonLoading: false,
    });
  };

  compareSelectedFile = async (fileImportId: number) => {
    this.handleCloseSelectFile();
    await this.loadSelectedFileData(fileImportId);
  };

  handleSelectFile = () => {
    this.setState({ openSelect: true });
  };

  handleCloseSelectFile = () => {
    this.setState({ openSelect: false });
  };

  comparisonRowRenderer = (props, classes) => {
    const { row } = props;
    const styles = (row.edicomparisonerror || "").includes("Missing") ? classes.errorRow : {};
    return <Row {...props} className={styles} />;
  };

  currentRowRenderer = (props, classes) => {
    const { row } = props;
    const styles = (row.edicomparisonerror || "").includes("Missing") ? classes.errorRow : {};
    return <Row {...props} className={styles} />;
  };

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <Confirmation
          isOpen={this.state.openSelect}
          handleClose={this.handleCloseSelectFile}
          handleConfirm={this.handleCloseSelectFile}
          title={`Select PS File`}
          body={``}
          fullscreen={false}
        >
          <PSFileList compareSelectedFile={this.compareSelectedFile} />
        </Confirmation>
        <div id="main_splitter_div" className={classes.splitterWrapper}>
          <Splitter position="vertical" primaryPaneWidth="50%" dispatchResize={true} postPoned={true} onDragFinished={this.setGridHeights} className={classes.splitter}>
            <div className={classes.toolbarWrapper}>
              <Toolbar className={classes.toolbar}>
                <h3>Current System Data</h3>
                <Button color="primary" variant="contained" onClick={this.handleSelectFile}>
                  Select File
                </Button>
              </Toolbar>
              <Grid
                loading={this.state.loading}
                data={this.state.currentData}
                forceHeight={this.state.gridHeight}
                GridColumns={this.GridColumnsOvverideCurrent}
                clearFilters={"edifilecomparisoncurrent"}
                handleFilteredRows={this.handleFilteredRows}
                totalRowColumns={["No Cartons", "Pallet Size", "Weight", "Gross Weight"]}
                exportName={`${format(new Date(), "yyMMdd")}_STOCK_COMPARISON`}
                rowRenderer={(props) => this.currentRowRenderer(props, classes)}
              />
            </div>
            <div className={classes.toolbarWrapper}>
              <Toolbar className={classes.toolbar}>
                <h3>PS File Data</h3>
              </Toolbar>
              <Grid
                loading={this.state.comparisonLoading}
                data={this.state.comparisonData}
                GridColumns={this.GridColumnsOvverideComparison}
                clearFilters={"edifilecomparisoncomparison"}
                forceHeight={this.state.gridHeight}
                totalRowColumns={["No Cartons", "Pallet Size", "Weight", "Gross Weight"]}
                rowRenderer={(props) => this.comparisonRowRenderer(props, classes)}
                exportName={`${format(new Date(), "yyMMdd")}_STOCK_COMPARISON_LOCATION`}
              />
            </div>
          </Splitter>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(EDIFileComparisonUnstyled);
