import { client, apiHeaders, request } from "./_base";

export const estimatedcostsetups = client("estimatedcostsetup", (item) => ({
  ...item,
}));

export const estimatedcostsetupsFull = async (estimatedcost_id: number = 0, packspec_id: number = 0) => {
  const result = await request(`estimatedcostsetup/ext/full/${estimatedcost_id}/${packspec_id}`, {
    headers: apiHeaders(),
  });
  const resp = await result.json();
  if (result.status == 500) {
    throw resp;
  }
  return resp.data;
};

export const estimatedcostsetupssummary = (variety) => {
  return request(`estimatedcostsetup/ext/summary/${variety}`, {
    headers: apiHeaders(),
  }).then(async (result) => {
    if (result.status == 500) {
      const resp = await result.json();
      throw resp;
    }
    return result.json();
  });
};

type EstimatedCostSetup = {
  estimatedcost_id: number;
  estimatedcostitem_id: number;
  pack_id: number;
  value: any;
  variety: string;
};

export const updateEstimatedCostSetup = async (result: EstimatedCostSetup[], estimatedCostId: number) => {
  return request(`estimatedcostsetup/ext/updateEstimatedCostSetup/${estimatedCostId}`, {
    headers: apiHeaders(),
    method: "POST",
    body: JSON.stringify(result),
  }).then(async (result) => {
    if (result.status == 500) {
      const resp = await result.json();
      throw resp;
    }
    return result;
  });
};
