import React, { useEffect, useState } from "react";

import { withStyles, createStyles, Theme, WithStyles } from "@material-ui/core/styles";

import { Field, Form } from "react-final-form";
import { TextField, Select } from "final-form-material-ui";

import LinearProgress from "@material-ui/core/LinearProgress";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import MenuItem from "@material-ui/core/MenuItem";

import CloseIcon from "@material-ui/icons/Close";

import ReactSelect from "react-select";
import { getCreditorsReadyForCombo } from "../../../lib/api/creditors";
import { findCreditorsImportTemplateById } from "../../../lib/api/creditorsimporttemplatedetail";
import Confirmation from "../../../lib/components/confirmation";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1),
      height: "100%",
    },
    divActions: {
      float: "right",
    },
    container: {
      height: "100%",
      display: "grid",
      gridTemplateRows: "min-content 1fr",
      gap: "20px",
    },
    tableCellLabel: {
      width: "200px",
      borderBottom: "none",
      height: "50px",
      textAlign: "right",
      paddingTop: "5px",
    },
    selectLabel: {
      width: "70px",
      borderBottom: "none",
      textAlign: "right",
      paddingTop: "5px",
    },
    formDetail: {
      marginTop: `${theme.spacing(1)}px`,
    },
    tableCellDetail: {
      display: "flex",
      flexDirection: "row",
      marginTop: "20px",
    },
    tableCellsValueSelect: {
      textAlign: "left",
      width: "100%",
      borderBottom: "none",
      height: "50px",
      padding: 0,
      marginTop: "-14px",
    },
    tableCellDetailDate: {
      marginTop: "-5px",
      width: "350px",
      borderBottom: "none",
      height: "50px",
    },
    formGridDetail: {
      height: "calc(100% - 50px)",
      display: "flex",
      placeContent: "flex-start",
      flexDirection: "row",
      flexWrap: "wrap",
      gap: `${theme.spacing(1)}px`,
      overflow: "auto",
    },
    clearSelect: {
      position: "absolute",
      right: "45px",
      top: "7px",
      cursor: "pointer",
      color: "#999",
      "&:hover": {
        color: "#777",
      },
    },
    selectWrapper: {
      display: "grid",
      gridTemplateColumns: "70px 230px",
      gridGap: "20px",
      height: "50px",
    },
    selector: {
      position: "relative",
    },
  });

const columns = [
  "Location",
  "Pallet",
  "Organization",
  "Document",
  "Prono",
  "VSCode",
  "VSName",
  "Port Of Discharge",
  "Container",
  "Consec",
  "Seal Number",
  "Booking Ref",
  "Agent",
  "Target Market",
  "Cargo Type",
  "Date Recorded",
  "Date Loaded",
  "Store Days",
  "Shipment Mode",
  "Shipment Type",
  "Storage",
  "Handling",
];

const ColumnName = (index) => {
  const ordA = "A".charCodeAt(0);
  const ordZ = "Z".charCodeAt(0);
  const len = ordZ - ordA + 1;

  let name = "";
  while (index >= 0) {
    name = String.fromCharCode((index % len) + ordA) + name;
    index = Math.floor(index / len) - 1;
  }

  return name;
};

type CreditorsImportTemplateCreateProps = {
  classes: any;
  selectedTemplate?: any;
  handleCloseForm(): void;
  handleProcess(data: any, templateDetailColumns: any[]): Promise<void>;
};

const CreditorsImportTemplateCreateUnstyled: React.FC<CreditorsImportTemplateCreateProps> = ({ classes, selectedTemplate, handleProcess, handleCloseForm }) => {
  const [confirmClose, setConfirmClose] = useState<boolean>(false);
  const [creditorsData, setCreditorsData] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const [hasHeader, toggleHasHeader] = useState<boolean>(false);
  const [columnsAvailable, setColumnsAvailable] = useState([]);
  const [templateDetails, setTemplateDetails] = useState([]);
  const [rows, setRows] = useState([]);
  const [version, setVersion] = useState(0);

  useEffect(() => {
    getCreditorsReadyForCombo().then((result) => {
      setCreditorsData(result);
    });
  }, []);

  useEffect(() => {
    if (selectedTemplate && selectedTemplate.id) {
      loadData();
    }
  }, [selectedTemplate]);

  const loadData = async () => {
    setLoading(true);
    const templateDetail = await findCreditorsImportTemplateById(selectedTemplate.id);

    toggleHasHeader(Boolean(selectedTemplate.templatehasheader));
    setTemplateDetails(templateDetail);
    setLoading(false);
  };

  useEffect(() => {
    const availableColumns = columns.map((col) => ({ value: col, label: col, data: col }));
    setColumnsAvailable(availableColumns);
  }, [rows]);

  useEffect(() => {
    const rowData = new Array(55).fill(1).reduce((arr, _, indx) => {
      const item = templateDetails.find((item) => item.colposition == indx + 1);
      if (item) {
        arr.push({ id: item.id, colname: item.colname, colposition: item.colposition, label: item.colname });
      } else {
        arr.push({ id: undefined, colname: "", colposition: indx + 1, label: "" });
      }
      return arr;
    }, []);
    setRows(rowData);
  }, [templateDetails]);

  const handleHasHeaderChange = (event: any, form: any) => {
    toggleHasHeader(event.target.checked);
    form.change("templatehasheader", event.target.checked);
  };

  const handleColumnSelect = (row: any, colposition: any) => {
    setVersion(version + 1);
    const exists = templateDetails.find((item) => parseInt(item.colposition) === parseInt(colposition));

    if (exists) {
      const update = { ...exists, colname: row.value, colposition, delete: row.value ? 0 : exists.id ? 1 : 0 };
      const filtered = templateDetails.filter((item) => item.colposition != exists.colposition);
      setTemplateDetails([...filtered, update]);
    } else {
      const create = { id: undefined, creditorsimportemplate_id: undefined, colposition: colposition, colname: row.value, delete: 0 };
      setTemplateDetails([...templateDetails, create]);
    }
  };

  const handleClearValue = (colposition) => {
    setVersion(version + 1);
    const data = templateDetails.map((row) => {
      if (row.colposition == colposition) {
        return { ...row, colname: "" };
      }
      return row;
    });
    setTemplateDetails(data);
  };

  const resetData = () => {
    setVersion(0);
    setTemplateDetails([]);
    setRows([]);
  };

  const handleClose = () => {
    if (version > 0) {
      setConfirmClose(true);
    } else {
      resetData();
      handleCloseForm();
    }
  };

  const handleCloseConfirmation = () => {
    setConfirmClose(false);
  };

  const handleConfirmConfirmation = () => {
    resetData();
    handleCloseForm();
  };

  useEffect(() => {
    const filteredAvailableCols = columnsAvailable.filter((col) => templateDetails.find((temp) => temp.colname == col.label));
    setColumnsAvailable(filteredAvailableCols);
  }, [templateDetails]);

  return (
    <div className={classes.root}>
      {confirmClose && (
        <Confirmation
          isOpen={true}
          handleClose={handleCloseConfirmation}
          handleConfirm={handleConfirmConfirmation}
          title={`Discard changes`}
          body={`Are you sure you want to discard your changes?`}
        />
      )}
      {loading ? <LinearProgress color="secondary" /> : <div style={{ height: "5px" }} />}
      <Form
        initialValues={{
          ...selectedTemplate,
        }}
        onSubmit={async (values: any) => {
          await handleProcess(values, templateDetails);
          setVersion(0);
        }}
        render={({ handleSubmit, form }) => (
          <form onSubmit={handleSubmit} style={{ height: "100%" }}>
            <div style={{ paddingBottom: "10px" }}>
              <Button name="submit" type="submit" variant="contained" color="primary" style={{ margin: "2px" }} disabled={false}>
                Save And Close
              </Button>
              <Button name="close" variant="contained" color="secondary" style={{ margin: "2px" }} disabled={false} onClick={handleClose}>
                Close
              </Button>
            </div>
            <div className={classes.container}>
              <div className={classes.formDetail}>
                <TableFieldCombo classes={classes} field="creditors_id" title="Creditor" data={creditorsData} disabled={false} />
                <TableFieldText classes={classes} field="templatename" title="Template Name" disabled={false} />
                <TableFieldCheckbox classes={classes} form={form} title="Has Header" disabled={false} state={hasHeader} setState={handleHasHeaderChange} />
                <TableFieldText classes={classes} field="templatespecialcharacter" title="Exclude Special Characters" disabled={false} />
              </div>
              <div className={classes.formGridDetail}>
                {rows.map((row, indx) => (
                  <TableFieldComboItem
                    row={row}
                    classes={classes}
                    colposition={indx + 1}
                    columns={columnsAvailable}
                    title={ColumnName(indx)}
                    onChange={handleColumnSelect}
                    handleClearValue={handleClearValue}
                  />
                ))}
              </div>
            </div>
          </form>
        )}
      />
    </div>
  );
};

const TableFieldText: React.FunctionComponent<{ field: string; title: string; disabled: boolean } & WithStyles<typeof styles>> = (props) => {
  const { classes, field, title, disabled } = props;
  return (
    <div style={{ display: "grid", gridTemplateColumns: "200px 300px", gridGap: "10px" }}>
      <div className={classes.tableCellLabel}>{`${title}:`}</div>
      <Field name={field} component={TextField} type="text" fullWidth={true} disabled={disabled} />
    </div>
  );
};

const TableFieldCheckbox: React.FunctionComponent<
  {
    form: any;
    title: string;
    state: boolean;
    disabled: boolean;
    setState: (event: any, form: any) => void;
  } & WithStyles<typeof styles>
> = (props) => {
  const { classes, title, state, setState, disabled, form } = props;

  return (
    <div style={{ display: "grid", gridTemplateColumns: "200px min-content", gridGap: "10px" }}>
      <span className={classes.tableCellLabel}>{`${title}:`}</span>
      <Checkbox checked={state} onChange={(event) => setState(event, form)} disabled={disabled} />
    </div>
  );
};

const TableFieldCombo: React.FunctionComponent<{ classes: any; title: string; field: string; data: any; disabled: boolean } & WithStyles<typeof styles>> = (props) => {
  const { classes, title, field, data, disabled } = props;
  return (
    <div style={{ display: "grid", gridTemplateColumns: "200px 300px", gridGap: "10px" }}>
      <span className={classes.tableCellLabel}>{`${title}:`}</span>
      <Field name={field} component={Select} formControlProps={{ className: classes.tableCellsValueSelect }} disabled={disabled}>
        {data &&
          data.map((item, index) => {
            return (
              <MenuItem key={`${item.value}${index}`} value={item.value}>
                {item.display}
              </MenuItem>
            );
          })}
      </Field>
    </div>
  );
};

const TableFieldComboItem: React.FunctionComponent<
  {
    row: any;
    classes: any;
    columns: any;
    title: string;
    colposition: number;
    onChange(colname: any, colposition: any): void;
    handleClearValue(colposition: any): void;
  } & WithStyles<typeof styles>
> = (props) => {
  const { row, classes, title, onChange, colposition, columns, handleClearValue } = props;

  return (
    <div className={classes.selectWrapper}>
      <span className={classes.selectLabel}>{`${title}:`}</span>
      <div className={classes.selector}>
        <ReactSelect options={columns} value={row} onChange={(data) => onChange(data, colposition)} placeholder="Select..." />
        <CloseIcon className={classes.clearSelect} onClick={() => handleClearValue(colposition)} />
      </div>
    </div>
  );
};

export default withStyles(styles)(CreditorsImportTemplateCreateUnstyled);
