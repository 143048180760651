import React, { FC, useContext, useEffect, useState } from "react";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";

import Confirmation from "../../lib/components/confirmation";
import SellingTermsGrid from "./grid";
import SellingTermsForm from "./form";

import CircularProgress from "@material-ui/core/CircularProgress";
import Tooltip from "@material-ui/core/Tooltip";
import Button from "@material-ui/core/Button";
import IconAdd from "@material-ui/icons/Add";

import { SellingTermsApi, SellingTermsType } from "../../lib/api/dealtype";
import { SnackContext } from "../../lib/context/SnackContext";
import { GenerateErrorMessage } from "../../lib/helpers/string_methods";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1),
      width: "100%",
      height: "100%",
      position: "relative",
    },
    inline: {
      display: "inline",
      position: "absolute",
      marginTop: "7px",
      marginLeft: "7px",
    },
  });

type SellingTermsProps = {} & WithStyles<typeof styles>;

const SellingTermsUnstyled: FC<SellingTermsProps> = ({ classes }) => {
  const { updateSnack } = useContext(SnackContext);

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [confirmRemoveID, setConfirmRemoveID] = useState<number>(undefined);
  const [confirmEditID, setConfirmEditID] = useState<string | number>(undefined);

  const loadData = async (): Promise<void> => {
    setLoading(true);
    try {
      const result = await SellingTermsApi.all();
      setData(result);
    } catch (error) {
      const err = GenerateErrorMessage(error, "Error getting data");
      updateSnack({ show: true, color: "red", message: err });
    }
    setLoading(false);
  };

  const handleCreate = async (): Promise<void> => {
    setConfirmEditID("0");
  };

  const handleEdit = (id: number) => {
    setConfirmEditID(id);
  };

  const handleRemove = (id: number) => {
    setConfirmRemoveID(id);
  };

  const handleRemoveClose = () => {
    setConfirmRemoveID(undefined);
  };

  const handleRemoveConfirm = async () => {
    try {
      await SellingTermsApi.remove(confirmRemoveID);
    } catch (error) {
      const err = GenerateErrorMessage(error, "Error removing Selling Term");
      updateSnack({ show: true, color: "red", message: err });
    }
    handleRemoveClose();
    await loadData();
  };

  const handleEditClose = () => {
    setConfirmEditID(undefined);
  };

  const handleEditConfirm = async (values: SellingTermsType) => {
    setLoading(true);
    try {
      const id = values.id;
      if (id) {
        delete values["id"];
        await SellingTermsApi.update(id, { data: values });
      } else {
        delete values["id"];
        await SellingTermsApi.create({ data: values });
      }
      setConfirmEditID(undefined);
    } catch (error) {
      const err = GenerateErrorMessage(error, "Error submitting Selling Term");
      updateSnack({ show: true, color: "red", message: err });
    }
    setConfirmEditID(undefined);
    setLoading(false);
    await loadData();
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <div className={classes.root}>
      {loading && <CircularProgress size={15} />}
      <div className={classes.inline}>
        <Tooltip style={{ zIndex: 0 }} title="Create new Selling Term">
          <div className={classes.inline}>
            <Button variant="contained" color="primary" onClick={handleCreate} style={{ marginBottom: "10px" }}>
              <IconAdd />
            </Button>
          </div>
        </Tooltip>
      </div>
      <SellingTermsGrid data={data} loading={loading} handleEdit={handleEdit} handleRemove={handleRemove} />
      {confirmRemoveID && (
        <Confirmation
          isOpen
          handleClose={handleRemoveClose}
          handleConfirm={handleRemoveConfirm}
          title="Remove selected Selling Term?"
          body="Are you sure you want to REMOVE the selected Selling Term?"
        ></Confirmation>
      )}
      {confirmEditID && (
        <Confirmation isOpen handleClose={handleEditClose} handleConfirm={() => {}} title={"Selling Term"} body={undefined}>
          <SellingTermsForm handleProcess={handleEditConfirm} handleClose={handleEditClose} id={confirmEditID} />
        </Confirmation>
      )}
    </div>
  );
};

export default withStyles(styles)(SellingTermsUnstyled);
