import { image as logoImage } from "./elements/logo";

import { get } from "./helpers";
import { PTSans } from "./elements/font";
import { CompanyDetailsBankingType } from "../lib/api/companydetailsbanking";

import jsPDF from "jspdf";
import numeral from "numeral";
import { format } from "date-fns";
import parseISO from "date-fns/parseISO";
import autoTable from "jspdf-autotable";
import { UnallocatedFundsType } from "../lib/api/debtorsfull";
import { AgeAnalysisType, StatementData } from "./printing";
import { CompanyDetailsType } from "../lib/api/companydetails";
import { ClientsType } from "../lib/api/clients";
import { AdhocTransactionType } from "../lib/api/saleadjustmentadhoc";
import BigNumber from "bignumber.js";

let pdf;
let pages;
let page;
let fromTop;
let fromLeft;
let lastLinewritten = 0;

const initialisePDF = () => {
  pdf = new jsPDF({
    orientation: "portrait",
    unit: "cm",
  });
  fullWidth = pdf.internal.pageSize.getWidth();
  fullHeight = pdf.internal.pageSize.getHeight();
  page = 1;
  pages = [page];
  fromTop = 2;
  fromLeft = 1;
};

let fullWidth;
let fullHeight;

const newPage = () => {
  pdf.addPage();

  page++;
  pages.push(page);
  pdf.setPage(page);
};

const addImage = (imageData: string, format: string, fromLeft: number, fromTop: number, width: number, height: number, alias?, compression?, rotation?) => {
  pdf.addImage(imageData, format, fromLeft, fromTop, width, height, alias, compression, rotation);
};

const newLine = (howFar: number = 0.5) => {
  fromTop = fromTop + howFar;
};

const setFontBold = () => {
  pdf.setFontStyle("bold");
  pdf.setFont("helvetica"); // set font
};

const setFontNormal = () => {
  pdf.setFontStyle("normal");
  pdf.setFont("PTSans"); // set font
};

const statementHeaders = [
  { dataKey: "sale_invoicenumber", header: "Invoice Number" },
  { dataKey: "vessel_description", header: "Vessel" },
  { dataKey: "dispatch_containerno", header: "Container" },
  { dataKey: "loadout_eta", header: "ETA" },
  { dataKey: "sale_paymentdate", header: "Due" },
  { dataKey: "sale_paymentamount", header: "Inv Ttl" },
  { dataKey: "amountDebit", header: "Debit" },
  { dataKey: "amountCredit", header: "Credit" },
  { dataKey: "sale_paymentamountpaid", header: "Payments" },
  { dataKey: "sale_paymentamount_remain", header: "Outstanding" },
];

const ageAnalysisHeaders = [
  { dataKey: "current", heading: "CURRENT", width: 3, alignment: "center" },
  { dataKey: "30days", heading: "1-30 days", width: 3, alignment: "center" },
  { dataKey: "60days", heading: "31-60 days", width: 3, alignment: "center" },
  { dataKey: "90days", heading: "61-90 Days", width: 3, alignment: "center" },
  { dataKey: "120days", heading: "91+ Days", width: 3, alignment: "center" },
  { dataKey: "currency", heading: "", width: 0, alignment: "center" },
  { dataKey: "total_due", heading: "TOTAL OUTSTANDING", width: 5.6, alignment: "right" },
];

const unallocatedFundsHeaders = [
  { dataKey: "sale_paymentdate", heading: "Date", width: 3, alignment: "left" },
  { dataKey: "sale_paymentnote", heading: "Description", width: 3, alignment: "left" },
  { dataKey: "sale_currency", heading: "", width: 13.8, alignment: "right" },
  { dataKey: "sale_paymentamount", heading: "Value", width: 1.8, alignment: "right" },
];

const adhocTransactionsHeaders = [
  { dataKey: "date", heading: "Date", width: 3, alignment: "left" },
  { dataKey: "document_no", heading: "Document No", width: 3, alignment: "left" },
  { dataKey: "type", heading: "Type", width: 3, alignment: "left" },
  { dataKey: "description", heading: "Description", width: 2, alignment: "left" },
  { dataKey: "currency", heading: "", width: 8.8, alignment: "right" },
  { dataKey: "amount", heading: "Amount", width: 1.8, alignment: "right" },
];

const addressLineArray = (address: string, arr = []) => {
  if (!address) {
    return null;
  }

  let words = (address || "").split(" ");

  if (address.length > 30) {
    let line = "";
    let rem = address.split(" ");

    for (let i = 0; i < words.length; i++) {
      const currLine = line + " " + words[i];

      if (currLine.length >= 30) {
        break;
      } else {
        rem.splice(0, 1);
        line = currLine.trim();
      }
    }

    arr.push(line);

    if (rem.length > 0) {
      return addressLineArray(rem.join(" "), arr);
    } else {
      return arr;
    }
  } else {
    return [...arr, words.join(" ")];
  }
};

export const PrintStatement = async (
  header: ClientsType,
  companyDetails: CompanyDetailsType,
  dataDetail: StatementData[],
  dateTo: Date,
  dateFrom: Date,
  bankingDetails: CompanyDetailsBankingType,
  ageAnalysisData: AgeAnalysisType[],
  unallocatedFunds: UnallocatedFundsType[],
  returnRaw: boolean = false,
  adhocTransactions: AdhocTransactionType[],
) => {
  initialisePDF();

  pdf.addFileToVFS("PTSans.ttf", PTSans);
  pdf.addFont("PTSans.ttf", "PTSans", "normal");
  pdf.setFont("PTSans");
  pdf.setLineWidth(0.01);

  printHeader(header, companyDetails, dateTo, dateFrom);
  printHeaderDetail(header, dateTo, dateFrom);
  printDetail(dataDetail, ageAnalysisData);
  printAdhocTransactions(adhocTransactions, ageAnalysisData);
  printUnallocatedFunds(unallocatedFunds, ageAnalysisData[0].currency);
  printTotalOutstanding(adhocTransactions, unallocatedFunds, dataDetail, ageAnalysisData);

  // added spacing between sections
  lastLinewritten += 0.75;

  printAgeAnalysis(ageAnalysisData);
  printBankDetails(bankingDetails);
  printTermsConditions();

  printFooter();

  // return pdf.output("datauristring");
  if (returnRaw) {
    return pdf.output("blob");
  }

  pdf.save(`${format(new Date(), "yyMMdd")}_${header.name}_Statement.pdf`);
};

const headerColumn1 = () => {
  return 3.9;
};
const headerColumn2 = () => {
  return fullWidth / 2 - 2;
};
const headerColumn3 = () => {
  return fullWidth - 6;
};

const printHeader = (header, companyDetails, dateTo: Date, dateFrom: Date) => {
  fromTop = 1;
  fromLeft = 1;

  const imageWidth = 5;
  const imageHeight = 1.5;
  // let fromRight = fullWidth - 0.7 - pdf.getTextDimensions(`Customs Code: ${!companyDetails ? "" : companyDetails.customscode ? companyDetails.customscode : ""}`).w / (96 / 38);

  addImage(logoImage, "PNG", (fullWidth - imageWidth) / 2 - 1, fromTop + 0.2, imageWidth, imageHeight);
  pdf.setFontSize(12);
  setFontBold();
  pdf.text("STATEMENT", fullWidth / 2 - 1, fromTop + 2.5, "center");

  pdf.setFontSize(11);
  setFontBold();
  pdf.text(get("name", companyDetails, ""), fromLeft, fromTop, "left");

  setFontNormal();
  pdf.setFontSize(8);

  newLine(0.4);
  pdf.text(get("address1", companyDetails, ""), fromLeft, fromTop, "left");

  newLine(0.4);
  pdf.text(get("address2", companyDetails, ""), fromLeft, fromTop, "left");

  newLine(0.4);
  pdf.text(get("address3", companyDetails, ""), fromLeft, fromTop, "left");

  newLine(0.4);
  pdf.text(get("address4", companyDetails, ""), fromLeft, fromTop, "left");

  newLine(0.4);
  pdf.text(get("address5", companyDetails, ""), fromLeft, fromTop, "left");

  fromLeft = headerColumn3();
  fromTop = 1;

  pdf.text("Tel No:", fromLeft, fromTop, "right");
  pdf.text(get("telephoneNumber", companyDetails, ""), fromLeft + 0.2, fromTop, "left");

  newLine(0.4);
  pdf.text("Fax No:", fromLeft, fromTop, "right");
  pdf.text(get("faxNumber", companyDetails, ""), fromLeft + 0.2, fromTop, "left");

  newLine(0.4);
  pdf.text("E-mail:", fromLeft, fromTop, "right");
  pdf.text(get("email", companyDetails, ""), fromLeft + 0.2, fromTop, "left");

  newLine(0.4);
  pdf.text("Vat No:", fromLeft, fromTop, "right");
  pdf.text(get("vatno", companyDetails, ""), fromLeft + 0.2, fromTop, "left");

  newLine(0.4);
  pdf.text("Reg No:", fromLeft, fromTop, "right");
  pdf.text(get("regno", companyDetails, ""), fromLeft + 0.2, fromTop, "left");

  newLine(0.4);
  pdf.text("Customs Code:", fromLeft, fromTop, "right");
  pdf.text(get("customscode", companyDetails, ""), fromLeft + 0.2, fromTop, "left");

  newLine(1);
};

const generateStatementNumber = (header) => {
  const clientCode = `${get("code", header, "").replace(/\d.*/g, "")}`;
  const year = format(new Date(), "yy");
  const statement_count = numeral(parseInt(get("statement_count", header, 0)) + 1).format("000");

  return `${clientCode}${year}${statement_count}`;
};

const printHeaderDetail = (header, dateTo, dateFrom) => {
  const clientAddress = header.physical_address ? header.physical_address.replace(/[\r\n]/g, " ") : "";
  const clientAddressArr = addressLineArray(clientAddress) || [];

  const startRect = fromTop;
  newLine();

  const statementNumber = generateStatementNumber(header);

  setFontBold();
  fromLeft = headerColumn1();
  pdf.text(`Client: `, fromLeft, fromTop, "right");
  setFontBold();
  pdf.setFontSize(9);
  pdf.text(get("name", header, ""), fromLeft + 0.2, fromTop, "left");

  newLine(0.4);

  pdf.setFontSize(8);
  setFontBold();
  fromLeft = headerColumn1();
  pdf.text(`Statement Number: `, fromLeft, fromTop, "right");
  setFontNormal();
  pdf.text(statementNumber, fromLeft + 0.2, fromTop, "left");

  setFontBold();
  fromLeft = headerColumn2() + 1;
  pdf.text(`From: `, fromLeft, fromTop, "right");
  setFontNormal();
  pdf.text(`${format(dateFrom, "dd MMM yyyy")}`, fromLeft + 0.2, fromTop, "left");

  pdf.setFontSize(8);
  fromLeft = headerColumn3();
  setFontBold();
  pdf.text("Tel:", fromLeft, fromTop, "right");
  setFontNormal();
  pdf.text(`${get("client_telephone", header, "")}`, fromLeft + 0.2, fromTop, "left");

  newLine(0.4);
  setFontBold();
  fromLeft = headerColumn1();
  pdf.text(`To: `, fromLeft, fromTop, "right");
  setFontNormal();
  pdf.text(clientAddressArr.splice(0, 1), fromLeft + 0.2, fromTop, "left");

  setFontBold();
  fromLeft = headerColumn2() + 1;
  pdf.text(`To: `, fromLeft, fromTop, "right");
  setFontNormal();
  pdf.text(`${format(dateTo, "dd MMM yyyy")}`, fromLeft + 0.2, fromTop, "left");

  fromLeft = headerColumn3();
  setFontBold();
  pdf.text("Email:", fromLeft, fromTop, "right");
  setFontNormal();
  pdf.text(`${get("finance_email", header, "")}`, fromLeft + 0.2, fromTop, "left");

  newLine(0.4);
  setFontNormal();
  fromLeft = headerColumn1() + 0.2;
  pdf.text(clientAddressArr.splice(0, 1), fromLeft, fromTop, "left");

  fromLeft = headerColumn3();
  setFontBold();
  pdf.text("Sales Person:", fromLeft, fromTop, "right");
  setFontNormal();
  pdf.text(`${get("sales_person", header, "N/A")}`, fromLeft + 0.2, fromTop, "left");

  if (clientAddressArr.length > 0) {
    newLine(0.4);
    setFontNormal();
    fromLeft = headerColumn1() + 0.2;
    pdf.text(clientAddressArr.splice(0, 1), fromLeft, fromTop, "left");
  }

  if (clientAddressArr.length > 0) {
    newLine(0.4);
    setFontNormal();
    fromLeft = headerColumn1() + 0.2;
    pdf.text(clientAddressArr.splice(0, 1), fromLeft, fromTop, "left");
  }

  fromLeft = 1;
  pdf.setLineWidth(0.01);
  pdf.setDrawColor(0, 0, 0);
  pdf.rect(fromLeft, startRect, fullWidth - 2, fromTop - startRect + 0.2, "S");

  newLine();
};

const printDetail = (data: StatementData[], ageAnalysisData: AgeAnalysisType[]) => {
  fromLeft = 1.25;
  fromTop += 0.85;
  lastLinewritten = fromTop;

  pdf.setFontSize(10);
  setFontBold();
  pdf.text("SHIPMENTS", fromLeft, fromTop - 0.25, "left");

  let startRect = fromTop;
  fromTop += 0.25;

  const dataDetail = data.reduce((arr, item) => {
    if (item.adhocType < 2 && !item.unallocated_payment && item.sale_invoicenumber != "TOTAL") {
      arr.push({
        appendLine: item.appendLine,
        adhoc_payment: item.adhoc_payment,
        sale_invoicenumber: item.sale_invoicenumber,
        vessel_description: item.vessel_description,
        dispatch_containerno: item.dispatch_containerno,
        loadout_eta: item.loadout_eta != "" ? format(parseISO(item.loadout_eta), "dd/MM/yyyy") : "",
        sale_paymentdate: item.sale_paymentdate != "" ? format(parseISO(item.sale_paymentdate), "dd/MM/yyyy") : "",
        sale_paymentamount: numeral(item.sale_paymentamount).format("0,0.00"),
        amountDebit: numeral(item.amountDebit).format("0,0.00"),
        amountCredit: numeral(item.amountCredit).format("0,0.00"),
        sale_paymentamountpaid: numeral(item.sale_paymentamountpaid).format("0,0.00"),
        sale_paymentamount_remain: numeral(item.sale_paymentamount_remain).format("0,0.00"),
      });
    }
    return arr;
  }, []);

  const totalRow = data.find((item) => item.sale_invoicenumber == "TOTAL");

  autoTable(pdf, {
    theme: "plain",
    margin: [1, 1, 1, 1],
    startY: lastLinewritten,
    columns: statementHeaders,
    body: dataDetail,
    showFoot: "lastPage",
    styles: { fontSize: 8, fillColor: [255, 255, 255], textColor: [0, 0, 0], cellPadding: [0.25, 0.025, 0.25, 0, 0, 0], lineWidth: 0 },
    columnStyles: {
      sale_invoicenumber: { halign: "center" },
      vessel_description: { halign: "center" },
      dispatch_containerno: { halign: "center" },
      loadout_eta: { halign: "center" },
      sale_paymentdate: { halign: "center" },
      sale_paymentamount: { halign: "right" },
      amountDebit: { halign: "right" },
      amountCredit: { halign: "right" },
      sale_paymentamountpaid: { halign: "right" },
      sale_paymentamount_remain: { halign: "right", cellPadding: [0.25, 0.2, 0.15, 0.2] },
    },
    headStyles: {
      fontStyle: "bold",
      cellPadding: [0.15, 0.025, 0.15, 0.025],
      halign: "center",
    },
    bodyStyles: { fontSize: 7.85 },
    alternateRowStyles: { fillColor: [255, 255, 255] },
    footStyles: { textColor: [0, 0, 0], fontStyle: "bold", halign: "right" },
    foot: [
      {
        sale_invoicenumber: "TOTAL",
        vessel_description: "",
        dispatch_containerno: "",
        loadout_eta: "",
        sale_paymentdate: ageAnalysisData[0].currency,
        sale_paymentamount: numeral(totalRow.sale_paymentamount).format("0,0.00"),
        amountDebit: numeral(totalRow.amountDebit).format("0,0.00"),
        amountCredit: numeral(totalRow.amountCredit).format("0,0.00"),
        sale_paymentamountpaid: numeral(totalRow.sale_paymentamountpaid).format("0,0.00"),
        sale_paymentamount_remain: numeral(totalRow.sale_paymentamount_remain).format("0,0.00"),
      },
    ],
    didDrawPage: (data) => {
      lastLinewritten = data.cursor.y;
      fromTop = data.cursor.y;

      if (data.pageNumber > 1) {
        startRect = 1;
        page = data.pageNumber;
        pages.push(data.pageNumber);
        pdf.setPage(data.pageNumber);
      }

      pdf.setLineWidth(0.01);
      pdf.setDrawColor(0, 0, 0);
      pdf.rect(1, startRect, fullWidth - 2, fromTop - startRect + 0.25, "S");
    },
    willDrawCell: (data) => {
      if (data.row.raw["appendLine"]) {
        data.row.height = 0.75;
      } else {
        data.row.height = 0.5;
      }
      if (data.row.section === "foot" && data.column.index === 0) {
        data.cell.styles.halign = "left";
        data.cell.styles.cellPadding = [0.25, 0.25, 0.25, 0.25];
      }

      if (data.cell && data.column.dataKey === "sale_paymentamount_remain") {
        if (data.cell.section === "head") {
          data.cell.styles.cellPadding = [0.15, 0.15, 0.15, 0.025];
        }

        if (data.cell.section === "foot") {
          data.cell.styles.cellPadding = [0.25, 0.15, 0.15, 0.025];
        }
      }
    },
    didParseCell: (data) => {
      if (data.cell && data.cell.section === "head") {
        const rightAlignHeaders = ["Inv Ttl", "Debit", "Credit", "Payments", "Outstanding"];

        if (rightAlignHeaders.includes(data.cell.raw.toString())) {
          data.cell.styles.halign = "right";
        }
      }
    },
  });

  pdf.setLineWidth(0.01);
  pdf.setDrawColor(0, 0, 0);
  pdf.rect(1, fromTop - 0.5, fullWidth - 2, 0.001, "S");
};

const bankDetailNewLine = () => {
  fromLeft = 6;
  newLine();
};

const bankDetailPrintText = (text, position) => {
  if (position === "right") {
    bankDetailNewLine();
    setFontBold();
    pdf.text(text, fromLeft, fromTop, position);
  } else {
    setFontNormal();
    pdf.text(text, fromLeft + 0.2, fromTop, position);
  }
};

const printBankDetails = (bankDetails: CompanyDetailsBankingType) => {
  fromLeft = 1.3;
  if (lastLinewritten >= 24) {
    newPage();
    fromTop = 2;
  } else {
    fromTop = lastLinewritten;
  }

  const startRect = fromTop;

  fromTop += 0.25;

  pdf.setFontSize(10);
  newLine();
  setFontBold();
  pdf.text("BANKING DETAILS ", fromLeft, fromTop, "left");

  pdf.setFontSize(8);

  bankDetailPrintText("ACCOUNT HOLDER: ", "right");
  bankDetailPrintText(!bankDetails || !bankDetails.accountholder ? "" : bankDetails.accountholder.toString(), "left");

  bankDetailPrintText("ACCOUNT HOLDER ADDRESS: ", "right");
  bankDetailPrintText(!bankDetails || !bankDetails.accountholderaddress ? "" : bankDetails.accountholderaddress.toString(), "left");

  bankDetailPrintText("BANK: ", "right");
  bankDetailPrintText(!bankDetails || !bankDetails.bank ? "" : bankDetails.bank.toString(), "left");

  bankDetailPrintText("BANK ADDRESS: ", "right");
  bankDetailPrintText(!bankDetails || !bankDetails.bankaddress ? "" : bankDetails.bankaddress.toString(), "left");

  bankDetailPrintText("BRANCH CODE: ", "right");
  bankDetailPrintText(!bankDetails || !bankDetails.branchcode ? "" : bankDetails.branchcode.toString(), "left");

  bankDetailPrintText("ACCOUNT TYPE: ", "right");
  bankDetailPrintText(!bankDetails || !bankDetails.accounttype ? "" : bankDetails.accounttype.toString(), "left");

  bankDetailPrintText("ACCOUNT NUMBER: ", "right");
  bankDetailPrintText(!bankDetails || !bankDetails.accountnumber ? "" : bankDetails.accountnumber.toString(), "left");

  bankDetailPrintText("SWIFT CODE: ", "right");
  bankDetailPrintText(!bankDetails || !bankDetails.swiftcode ? "" : bankDetails.swiftcode.toString(), "left");

  lastLinewritten = fromTop;
  fromLeft = 1;

  pdf.setLineWidth(0.01);
  pdf.setDrawColor(0, 0, 0);
  pdf.rect(fromLeft, startRect + 0.25, fullWidth - 2, fromTop - startRect, "S");
};

const printFooter = () => {
  const pageCount = pdf.internal.getNumberOfPages();

  for (let index = 0; index < pageCount; index++) {
    pdf.setPage(index + 1);
    pdf.text(`Page ${index + 1} of ${pageCount}`, fullWidth - 1, fullHeight - 0.5, "right");
  }
};

const printTotalOutstanding = (adhocTransactions: AdhocTransactionType[], unallocated: UnallocatedFundsType[], dataDetail: StatementData[], ageAnalysisData: AgeAnalysisType[]) => {
  const heightOfPage = pdf.internal.pageSize.getHeight();
  fromLeft = 1.2;
  fromTop = lastLinewritten + 0.25;

  if (fromTop > heightOfPage - 3) {
    newPage();
    fromTop = 2;
  }

  const startRect = fromTop;
  fromTop += 0.5;

  const adhocTransactionsTotal = adhocTransactions.reduce((tot, row) => (tot += row.amount * -1), 0);
  const unallocatedTotal = unallocated.reduce((tot, row) => (tot += row.available), 0);
  const statementTotal = dataDetail.find((item) => item.sale_invoicenumber == "TOTAL");

  const total = new BigNumber(statementTotal.sale_paymentamount_remain).minus(new BigNumber(adhocTransactionsTotal)).minus(new BigNumber(unallocatedTotal)).toNumber();
  const formattedTotal = numeral(total).format("0,0.00");

  pdf.setFontSize(10);
  setFontBold();
  pdf.text(`GRAND TOTAL OUTSTANDING:`, fromLeft, fromTop, "left");
  pdf.text(ageAnalysisData[0].currency, fullWidth - 1.5 - pdf.getTextDimensions(formattedTotal).w, fromTop, "right");
  pdf.text(formattedTotal, fullWidth - 1.18, fromTop, "right");

  // The rectangle around the text
  pdf.setLineWidth(0.07);
  pdf.setDrawColor(0, 0, 0);
  pdf.rect(1, startRect - 0.25, fullWidth - 2, fromTop - startRect + 0.75, "S");

  lastLinewritten = fromTop + 0.75;
};

const printAdhocTransactions = (adhocTransactions: AdhocTransactionType[], ageAnalysisData: AgeAnalysisType[]) => {
  const heightOfPage = pdf.internal.pageSize.getHeight();
  fromLeft = 1.2;
  fromTop = lastLinewritten + 1.15;

  if (fromTop > heightOfPage - 3) {
    newPage();
    fromTop = 2;
  }

  const filteredData = adhocTransactions.map((item) => ({
    amount: numeral(item.amount.toString()).format("0,0.00"),
    date: format(new Date(item.adhoc_date), "dd/MM/yyyy"),
    description: item.description,
    type: item.type,
    currency: "",
    document_no: item.document_no,
  }));

  if (filteredData.length > 0) {
    filteredData.push({
      amount: numeral(adhocTransactions.reduce((tot, item) => (tot += item.amount), 0)).format("0,0.00"),
      description: "",
      document_no: "",
      type: "",
      date: "TOTAL",
      currency: ageAnalysisData[0].currency,
    });
  }

  pdf.setFontSize(10);
  setFontBold();
  pdf.text("AD HOC TRANSACTIONS", fromLeft, fromTop, "left");

  fromTop += 0.5;
  const startRect = fromTop;

  if ((filteredData || []).length === 0) {
    pdf.setFontSize(8);
    setFontNormal();
    pdf.text("No adhoc transactions to show", 1.25, fromTop + 0.15, "left");
  } else {
    fromLeft = 1.25;
    pdf.setFontSize(8);
    setFontNormal();

    for (let i = 0; i < adhocTransactionsHeaders.length; i++) {
      const item = adhocTransactionsHeaders[i];
      setFontBold();

      if (i > 0) {
        fromLeft += item.width;
        pdf.text(item.heading, fromLeft, fromTop + 0.2, item.alignment);
      } else {
        pdf.text(item.heading, fromLeft, fromTop + 0.2, item.alignment);
      }
    }

    fromLeft = 1.25;
    fromTop += 0.25;

    for (let i = 0; i < filteredData.length; i++) {
      const item = filteredData[i],
        keys = Object.keys(item);

      setFontNormal();
      fromLeft = 1.25;
      fromTop += 0.5;

      if (item.date == "TOTAL") {
        pdf.setLineWidth(0.01);
        pdf.setDrawColor(0, 0, 0);
        pdf.rect(1, fromTop - 0.25, fullWidth - 2, 0.001, "S");

        setFontBold();
        fromTop += 0.25;
      }

      for (let k = 0; k < keys.length; k++) {
        const headerCol = adhocTransactionsHeaders[k];

        if (!headerCol) continue;

        if (k > 0) {
          fromLeft += headerCol.width;
        }

        pdf.text((item[headerCol.dataKey] || "").toString(), fromLeft, fromTop, headerCol.alignment);
      }
    }
  }

  // The rectangle around the text
  pdf.setLineWidth(0.01);
  pdf.setDrawColor(0, 0, 0);
  pdf.rect(1, startRect - 0.25, fullWidth - 2, fromTop - startRect + 0.6, "S");

  lastLinewritten = fromTop + 1;
};

const printUnallocatedFunds = (unallocated: UnallocatedFundsType[], currency: string) => {
  const heightOfPage = pdf.internal.pageSize.getHeight();

  fromLeft = 1.2;
  fromTop = lastLinewritten + 0.5;

  if (fromTop > heightOfPage - 1) {
    newPage();
    fromTop = 2;
  }

  pdf.setFontSize(10);
  setFontBold();
  pdf.text("UNALLOCATED FUNDS", fromLeft, fromTop, "left");

  const filteredData = unallocated.map((item) => ({
    sale_paymentamount: numeral(item.available.toString()).format("0,0.00"),
    sale_paymentdate: format(new Date(item.sale_date), "dd/MM/yyyy"),
    sale_paymentnote: item.payment_description,
    sale_currency: currency,
  }));

  if (filteredData.length > 0) {
    filteredData.push({
      sale_paymentamount: numeral(unallocated.reduce((tot, item) => (tot += item.available), 0)).format("0,0.00"),
      sale_paymentdate: "TOTAL",
      sale_paymentnote: "",
      sale_currency: currency,
    });
  }

  fromTop += 0.5;
  let startRect = fromTop;

  if ((filteredData || []).length === 0) {
    pdf.setFontSize(8);
    setFontBold();
    pdf.text("TOTAL", fromLeft, fromTop + 0.3, "left");
    pdf.text(currency, fullWidth - 1.5 - pdf.getTextDimensions("0.00").w, fromTop + 0.3, "right");
    pdf.text("0.00", fullWidth - 1.18, fromTop + 0.3, "right");

    // The rectangle around the text
    pdf.setLineWidth(0.01);
    pdf.setDrawColor(0, 0, 0);
    pdf.rect(1, fromTop - 0.25, fullWidth - 2, 0.8, "S");
  } else {
    fromLeft = 1.25;
    pdf.setFontSize(8);
    setFontNormal();

    for (let i = 0; i < unallocatedFundsHeaders.length; i++) {
      const item = unallocatedFundsHeaders[i];
      setFontBold();

      if (i > 0) {
        fromLeft += item.width;
        pdf.text(item.heading, fromLeft, fromTop + 0.2, item.alignment);
      } else {
        pdf.text(item.heading, fromLeft, fromTop + 0.2, item.alignment);
      }
    }

    fromLeft = 1.25;
    fromTop += 0.25;

    for (let i = 0; i < filteredData.length; i++) {
      const item = filteredData[i],
        keys = Object.keys(item);

      setFontNormal();
      fromLeft = 1.25;
      fromTop += 0.5;

      if (item.sale_paymentdate == "TOTAL") {
        pdf.setLineWidth(0.01);
        pdf.setDrawColor(0, 0, 0);
        pdf.rect(1, fromTop - 0.25, fullWidth - 2, 0.001, "S");

        setFontBold();
        fromTop += 0.25;
      }

      for (let k = 0; k < keys.length; k++) {
        const headerCol = unallocatedFundsHeaders[k];

        if (!headerCol) continue;

        if (k > 0) {
          fromLeft += headerCol.width;
        }

        pdf.text((item[headerCol.dataKey] || "").toString(), fromLeft, fromTop, headerCol.alignment);
      }
    }

    pdf.setLineWidth(0.01);
    pdf.setDrawColor(0, 0, 0);
    pdf.rect(1, startRect - 0.25, fullWidth - 2, fromTop - startRect + 0.6, "S");

    lastLinewritten = fromTop + 1;
  }

  lastLinewritten = fromTop + 1;
};

const printAgeAnalysis = (ageAnalysisData: any[]) => {
  const heightOfPage = pdf.internal.pageSize.getHeight();
  fromLeft = 1.2;
  fromTop = lastLinewritten + 0.5;

  if (fromTop > heightOfPage - 3) {
    newPage();
    fromTop = 2;
  }

  pdf.setFontSize(10);
  setFontBold();
  pdf.text("AGE ANALYSIS ", fromLeft, fromTop, "left");

  fromTop += 0.5;
  pdf.setFontSize(8);
  setFontNormal();

  // The rectangle around the text
  pdf.setLineWidth(0.01);
  pdf.setDrawColor(0, 0, 0);
  pdf.rect(1, fromTop - 0.25, fullWidth - 2, 1.5, "S");

  fromLeft = 2.25;

  for (let i = 0; i < ageAnalysisHeaders.length; i++) {
    const item = ageAnalysisHeaders[i];
    setFontBold();

    if (i > 0) {
      fromLeft += item.width;
      pdf.text(item.heading, fromLeft, fromTop + 0.2, item.alignment);
    } else {
      pdf.text(item.heading, fromLeft, fromTop + 0.2, item.alignment);
    }
  }

  fromLeft = 2.25;

  for (let i = 0; i < ageAnalysisData.length; i++) {
    const item = ageAnalysisData[i],
      keys = Object.keys(item);

    setFontNormal();
    fromLeft = 2.25;
    fromTop += 0.75;

    for (let k = 0; k < keys.length; k++) {
      const key = keys[k],
        headerCol = ageAnalysisHeaders[k];

      if (headerCol.dataKey == "currency") {
        continue;
      }

      if (k > 0) {
        fromLeft += headerCol.width;
      }

      if (headerCol.dataKey == "total_due") {
        pdf.text(item["currency"] || "", fromLeft - 3, fromTop, "left");
        setFontBold();
      }

      pdf.text(numeral(item[headerCol.dataKey].toString()).format("0,0.00"), fromLeft, fromTop, headerCol.alignment);
    }
  }

  lastLinewritten = fromTop + 0.25;
};

const printTermsConditions = () => {
  const heightOfPage = pdf.internal.pageSize.getHeight();
  fromLeft = 1.2;
  fromTop = lastLinewritten + 0.75;

  if (fromTop > heightOfPage - 4) {
    newPage();
    fromTop = 2;
  }

  // The rectangle around the text
  pdf.setLineWidth(0.01);
  pdf.setDrawColor(0, 0, 0);
  pdf.rect(1, fromTop - 0.5, fullWidth - 2, 2.25, "S");

  setFontBold();
  pdf.setFontSize(9);

  pdf.text("* Any quality deviation must be reported by e-mail within 48 hours of collecting the container.", fromLeft, fromTop, "left");

  newLine();
  pdf.text("* Claims will not be entertained if notice is given after 48 hours.", fromLeft, fromTop, "left");

  newLine();
  pdf.text("* Claims will only be entertained if the Impala Citrus claims procedure is adhered to, as set out in Trade Agreement.", fromLeft, fromTop, "left");

  newLine();
  pdf.text("* Interest at a rate of 2% per month will be charged on overdue accounts.", fromLeft, fromTop, "left");
};
