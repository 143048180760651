import { apiHeaders, client, request } from "./_base";

export type ProducerFinalPaidType = {
  id?: number;
  barcode_id: number;
  producerfinalpaidhead_id: number;
  invoicenumber: number;
  created_at?: Date;
};

const endpoint = "producerfinalpaid";

export const producerfinalpaid = client(endpoint, (item) => ({
  ...item,
}));

export const getProducerFinalPaidCalculated = async () => {
  return request(`${endpoint}/ext/getProducerFinalPaidCalculated`, {
    headers: apiHeaders(),
    method: "GET",
  }).then(async (result) => {
    if (result.status == 500) {
      const resp = await result.json();
      throw resp;
    }
    return result.json();
  });
};
