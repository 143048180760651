import React from "react";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";

import OrdersCreateNew from "./ordersCreateNew";

// farms: undefined,
// commodities: undefined,
// varietiesFiltered: undefined,

export class OrdersCreate extends React.Component<any, any> {
  state = {
    isOpen: undefined,
    handleClose: undefined,
    onSubmit: undefined,
    producers: undefined,
    weeks: undefined,
    varieties: undefined,
    formRecord: {
      exworks: null,
      local: null,
      week: 32,
      commission: 7,
      fixed: null,
      exchange: { usd: "1.00", cad: "1.00", eur: "1.00", gbp: "1.00" },
    },
  };

  constructor(props) {
    super(props);
    this.state.isOpen = props.isOpen;
    this.state.handleClose = props.handleClose;
    this.state.onSubmit = props.onSubmit;
    this.state.producers = props.producers;
    this.state.weeks = props.weeks;
    this.state.varieties = props.varieties;
  }

  // this.state.farms = props.farms;
  // this.state.varietiesFiltered = props.varieties;
  // this.state.commodities = props.commodities;

  // onClickCommodity = (e) => {
  //   const id = e.target.value;
  //   if (id) {
  //     this.setState({
  //       varietiesFiltered: this.state.varieties.filter((v) => v.commodity_id == e.target.value),
  //     });
  //   }
  // };

  render() {
    return (
      <div>
        <Dialog fullScreen={false} open={this.state.isOpen} onClose={this.state.handleClose} aria-labelledby="responsive-dialog-title" maxWidth="xl">
          <DialogTitle id="responsive-dialog-title">{"Create Order"}</DialogTitle>
          <DialogContent>
            <OrdersCreateNew onSubmit={this.state.onSubmit} producers={this.state.producers} weeks={this.state.weeks} formRecord={this.state.formRecord} />
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}

// onClickCommodity={this.onClickCommodity}
// farms={this.state.farms}
// varieties={this.state.varietiesFiltered}
// commodities={this.state.commodities}
