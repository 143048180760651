import React from "react";
import CustomAutoCompleteFilter from "../../../lib/components/customdatagridfilter/CustomFilter";

import { FormatterNumber, FormatterNumber2Decimals } from "../../../lib/components/Formatters";
import { SummaryNumber2Decimal, SummaryTotal } from "../../../lib/components/SummaryFormatter";
import TextEditor from "../../../lib/components/TextEditor";

const cellWidthSmallX = 150;
const cellWidthSmall = 220;

const defaultColumnProperties = {
  filterable: true,
  resizable: true,
  sortable: true,
};

let currentSpace = true;

export const GridColumnsAdjustmentGrouped = (data, filters, columnsWidth) => {
  let postValue = "";

  if (data) {
    if (currentSpace) {
      postValue = " ";
    }
    currentSpace = !currentSpace;
  }

  const columns = [];

  columns.push({
    key: "commodityCode",
    name: "Commodity" + postValue,
    width: cellWidthSmallX,
    filterRenderer: (e) => {
      return (
        <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"commodityCode"} data={data} section={"produceradjustmentsadjustgrouped"} />
      );
    },
    summaryFormatter: SummaryTotal,
  });
  columns.push({
    key: "varietyCode",
    name: "Variety" + postValue,
    width: cellWidthSmallX,
    filterRenderer: (e) => {
      return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"varietyCode"} data={data} section={"produceradjustmentsadjustgrouped"} />;
    },
  });
  columns.push({
    key: "packCode",
    name: "Pack" + postValue,
    width: cellWidthSmallX,
    filterRenderer: (e) => {
      return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"packCode"} data={data} section={"produceradjustmentsadjustgrouped"} />;
    },
  });
  columns.push({
    key: "markCode",
    name: "Mark" + postValue,
    width: cellWidthSmallX,
    filterRenderer: (e) => {
      return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"markCode"} data={data} section={"produceradjustmentsadjustgrouped"} />;
    },
  });
  columns.push({
    key: "gradeCode",
    name: "Grade" + postValue,
    width: cellWidthSmallX,
    filterRenderer: (e) => {
      return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"gradeCode"} data={data} section={"produceradjustmentsadjustgrouped"} />;
    },
  });
  columns.push({
    key: "countCode",
    name: "Count" + postValue,
    width: cellWidthSmallX,
    filterRenderer: (e) => {
      return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"countCode"} data={data} section={"produceradjustmentsadjustgrouped"} />;
    },
    formatter: FormatterNumber,
  });
  columns.push({
    key: "saleadjustmentdetail_amount",
    name: "Amount/ctn" + postValue,
    width: cellWidthSmall,
    filterRenderer: (e) => {
      return (
        <CustomAutoCompleteFilter
          {...e}
          multiSelection={true}
          currentFilters={filters}
          fieldname={"saleadjustmentdetail_amount"}
          data={data}
          section={"produceradjustmentsadjustgrouped"}
        />
      );
    },
    formatter: FormatterNumber2Decimals,
    editable: true,
    editor: (props) => <TextEditor {...props} align="right" />,
    summaryFormatter: SummaryNumber2Decimal,
  });

  const columnsDefault = columns.map((c) => ({ ...c, ...defaultColumnProperties }));

  if (columnsWidth && columnsWidth.length > 0) {
    for (let index = 0; index < columnsDefault.length; index++) {
      const element = columnsDefault[index];
      const colItem = columnsWidth.find((colwitem) => colwitem.name.trim() == element.name.trim());
      if (colItem) {
        element.width = colItem.width;
      }
    }
  }

  return columnsDefault;
};
