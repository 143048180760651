import React from "react";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";

import Tooltip from "@material-ui/core/Tooltip";
import Button from "@material-ui/core/Button";

import IconAdd from "@material-ui/icons/Add";

import { GridColumns } from "./producerphdetailgridsetup";
import ProducerPHDetailEditForm from "./producerphdetaileditform";

import Grid from "../../lib/components/grid";
import Confirmation from "../../lib/components/confirmation";
import { producerphdetail, ProducerPHDetailType, getProducerPHDetailFullById } from "../../lib/api/producerphdetail";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1),
      width: "700px",
      height: "100%",
      position: "relative",
      paddingTop: 0,
      marginTop: "-3px",
    },
    inline: {
      display: "inline",
      position: "absolute",
      marginTop: "7px",
      marginLeft: "7px",
    },
    grid: {
      // marginTop: "-10px",
      width: "100%",
      float: "left",
    },
  });

type ProducerPHDetailProps = {
  producerphid: any;
} & WithStyles<typeof styles>;

export class ProducerPHUnstyled extends React.Component<ProducerPHDetailProps, any> {
  state = {
    classes: undefined,
    data: [],
    confirmRemoveID: undefined,
    confirmEditID: undefined,
    producerphid: undefined,
    loading: true,
  };

  constructor(props) {
    super(props);
    this.state.classes = props.classes;
    this.state.producerphid = props.producerphid;
  }

  componentDidMount() {
    this.loadData();
  }

  componentWillReceiveProps(props) {
    this.setState({ producerphid: props.producerphid }, () => {
      this.loadData();
    });
  }

  loadData = () => {
    getProducerPHDetailFullById(this.state.producerphid).then((data) => {
      this.setState({ data: data, loading: false });
    });
  };

  handleCreate = () => {
    this.setState({ confirmEditID: "0" });
  };

  handleEdit = (id) => {
    this.setState({ confirmEditID: id });
  };

  handleEditClose = () => {
    this.setState({ confirmEditID: undefined });
  };

  handleEditConfirm = async (item, files) => {
    let id = item["id"];
    const orgid = item["id"];
    const producerphV: ProducerPHDetailType = {
      producerph_id: this.state.producerphid,
      commoditycode: item.commoditycode,
      exportmarket: item.exportmarket,
      mrl: item.mrl,
      activeingredient: item.activeingredient,
      product: item.product,
      waterapplication100: item.waterapplication100,
      waxapplication100: item.waxapplication100,
      comments: item.comments,
    };

    if (id == "0") {
      await producerphdetail.create({ data: { ...producerphV } }).then((result) => (id = result[0]));
    } else {
      await producerphdetail.update(id, { data: { ...producerphV } });
    }

    this.loadData();
    await this.handleEditClose();
  };

  handleRemove = async (id) => {
    this.setState({ confirmRemoveID: id });
  };

  handleRemoveClose = () => {
    this.setState({ confirmRemoveID: undefined });
  };

  handleRemoveConfirm = async () => {
    await producerphdetail.remove(this.state.confirmRemoveID).then(() => {
      this.handleRemoveClose();
      this.loadData();
    });
  };

  GridColumnsOverride = (column, row) => {
    return GridColumns(column, row, this.handleRemove, this.handleEdit);
  };

  render() {
    const { classes } = this.state;

    return (
      <div className={classes.root}>
        {this.state.producerphid && this.state.producerphid == "0" ? (
          <div>New Producer. Cannot add Packhouse</div>
        ) : (
          <div>
            <div className={classes.inline}>
              <Tooltip title="Create new Packhouse">
                <div className={classes.inline}>
                  <Button variant="contained" color="primary" onClick={this.handleCreate} style={{ marginBottom: "10px" }}>
                    <IconAdd />
                  </Button>
                </div>
              </Tooltip>
            </div>
            <div style={{ width: "600px" }}>
              <Grid loading={this.state.loading} data={this.state.data} GridColumns={this.GridColumnsOverride} clearFilters={"producerphdetailgridsetup"} />
            </div>
            {this.state.confirmRemoveID && (
              <Confirmation
                isOpen={this.state.confirmRemoveID ? true : false}
                handleClose={this.handleRemoveClose}
                handleConfirm={this.handleRemoveConfirm}
                title="Remove selected Packhouse?"
                body="Are you sure you want to REMOVE the selected Packhouse Record?"
              ></Confirmation>
            )}
            {this.state.confirmEditID && (
              <Confirmation
                isOpen={this.state.confirmEditID ? true : false}
                handleClose={this.handleEditClose}
                handleConfirm={() => {}}
                title={this.state.confirmEditID == "0" ? "Creating Packhouse" : "Editing Packhouse"}
                body={undefined}
              >
                <ProducerPHDetailEditForm onSubmit={this.handleEditConfirm} onClose={this.handleEditClose} id={this.state.confirmEditID} />
              </Confirmation>
            )}
          </div>
        )}
      </div>
    );
  }
}

export default withStyles(styles)(ProducerPHUnstyled);
