import React from "react";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";

import LinearProgress from "@material-ui/core/LinearProgress";

import { stockDetaildistinctReferencesSellingNull, stockDetaildistinctReferences } from "../lib/api/stockdetail";
import StockPricingReferencesGrid from "./stockpricingreferencesgrid";

import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

const styles = (theme: Theme) =>
  createStyles({
    root: {},
    toolbar: {
      position: "absolute",
      float: "left",
      left: "10px",
      top: "10px",
    },
  });

type StockPricingReferencesProps = {
  onSelectedChanged: any;
  onDataUpdated: any;
  minGridHeight: number;
} & WithStyles<typeof styles>;

const StockPricingReferencesUnstyled: React.SFC<StockPricingReferencesProps> = (props) => {
  const { onSelectedChanged, onDataUpdated, classes } = props;
  const [references, setReferences] = React.useState([{}]);
  const [loading, setLoading] = React.useState(true);
  const [showAll, setShowAll] = React.useState(false);

  React.useEffect(() => {
    setShowAll(false);
    loadReferences(false);
  }, [onDataUpdated]);

  const loadReferences = async (showallval) => {
    setLoading(true);
    if (!showallval) {
      const data = await stockDetaildistinctReferencesSellingNull();
      const datafiltered = data.filter((item) => item.reference.trim() != "");
      setReferences(datafiltered);
    } else {
      const data = await stockDetaildistinctReferences();
      const datafiltered = data.filter((item) => item.reference.trim() != "");
      setReferences(datafiltered);
    }
    setLoading(false);
  };

  const handleShowall = () => {
    loadReferences(!showAll);
    setShowAll(!showAll);
  };

  return (
    <div>
      {loading && (
        <div>
          <LinearProgress />
        </div>
      )}
      {!loading && (
        <div>
          <div className={classes.toolbar}>
            <FormControlLabel control={<Checkbox checked={showAll} onChange={() => handleShowall()} value="showall" />} label="Show All" />
          </div>
          <StockPricingReferencesGrid data={references} onSelectedChanged={onSelectedChanged} minGridHeight={props.minGridHeight} />
        </div>
      )}
    </div>
  );
};

export default withStyles(styles)(StockPricingReferencesUnstyled);
