import React from "react";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";

// import Tooltip from "@material-ui/core/Tooltip";
// import Button from "@material-ui/core/Button";

// import IconAdd from "@material-ui/icons/Add";

import Grid from "../../lib/components/grid";
import Confirmation from "../../lib/components/confirmation";

import { piorder, piorderFull } from "../../lib/api/piorder";
import { GridColumns } from "./ordersgridsetup";

import OrdersEditForm from "./orderseditform";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1),
      width: "100%",
      height: "100%",
      position: "relative",
    },
    inline: {
      display: "inline",
      position: "absolute",
      marginTop: "7px",
      marginLeft: "7px",
    },
  });

type OrdersProps = {} & WithStyles<typeof styles>;

export class OrdersUnstyled extends React.Component<OrdersProps, any> {
  state = {
    classes: undefined,
    data: [],
    confirmRemoveID: undefined,
    confirmEditID: undefined,
  };

  constructor(props) {
    super(props);
    this.state.classes = props.classes;
  }

  componentDidMount() {
    this.loadData();
  }

  loadData = () => {
    piorderFull().then((data) => {
      const dataSorted = data.data.sort((a, b) => {
        if (a.code > b.code) return 1;
        if (a.code < b.code) return -1;
        return 1;
      });
      this.setState({ data: dataSorted });
    });
  };

  handleEdit = (id) => {
    this.setState({ confirmEditID: id });
  };

  handleEditClose = () => {
    this.loadData();
    this.setState({ confirmEditID: undefined });
  };

  handleEditConfirm = (e, item) => {
    e.preventDefault();

    const id = item["id"];
    delete item["id"];

    piorder.update(id, { data: { ...item } }).then(() => this.loadData());

    this.handleEditClose();
  };

  GridColumnsOverride = (column, row) => {
    return GridColumns(column, row, () => {}, this.handleEdit);
  };

  render() {
    const { classes } = this.state;

    return (
      <div className={classes.root}>
        <Grid data={this.state.data} GridColumns={this.GridColumnsOverride} clearFilters={"ordersgridsetup"} />
        {this.state.confirmEditID && (
          <Confirmation isOpen={this.state.confirmEditID ? true : false} handleClose={this.handleEditClose} handleConfirm={() => {}} title={"Editing Order"} body={undefined}>
            <OrdersEditForm onSubmit={this.handleEditConfirm} onClose={this.handleEditClose} id={this.state.confirmEditID} />
          </Confirmation>
        )}
      </div>
    );
  }
}

export default withStyles(styles)(OrdersUnstyled);
