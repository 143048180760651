import React from "react";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";

import Grid from "../../lib/components/grid";
import Confirmation from "../../lib/components/confirmation";

import { estimatedcostitemFull, estimatedcostitems } from "../../lib/api/estimatedcostitem";
import { GridColumns } from "./estimatedcostitemgridsetup";

import Tooltip from "@material-ui/core/Tooltip";
import Button from "@material-ui/core/Button";
import IconAdd from "@material-ui/icons/Add";

import EstimatedCostItemEditForm from "./estimatedcostitemeditform";

import { SnackContext } from "../../lib/context/SnackContext";
import { GenerateErrorMessage } from "../../lib/helpers/string_methods";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1),
      width: "100%",
      height: "100%",
    },
    inline: {
      display: "inline",
      position: "absolute",
      marginTop: "7px",
      marginLeft: "7px",
    },
  });

type EstimatedCostItemProps = {} & WithStyles<typeof styles>;

export class EstimatedCostItemUnstyled extends React.Component<EstimatedCostItemProps, any> {
  state = {
    classes: undefined,
    data: [],
    confirmRemoveID: undefined,
    confirmEditID: undefined,
    loading: true,
  };

  constructor(props) {
    super(props);
    this.state.classes = props.classes;
  }

  static contextType = SnackContext;
  context!: React.ContextType<typeof SnackContext>;

  componentDidMount() {
    this.loadData();
  }

  loadData = () => {
    estimatedcostitemFull()
      .then((result) => {
        const dataSorted = result.data.sort((a, b) => {
          if (a.name > b.name) return 1;
          if (a.name < b.name) return -1;
          return 1;
        });
        this.setState({ loading: false, data: dataSorted });
      })
      .catch((error) => {
        const err = GenerateErrorMessage(error, "failed to retrieve data");
        this.context.updateSnack({ show: true, color: "red", message: err });
      });
  };

  handleCreate = () => {
    this.setState({ confirmEditID: "0" });
  };

  handleEdit = (id) => {
    this.setState({ confirmEditID: id });
  };

  handleEditClose = () => {
    this.setState({ confirmEditID: undefined });
  };

  handleEditConfirm = (e, item) => {
    e.preventDefault();

    const id = item["id"];
    delete item["id"];

    if (id == "0") {
      estimatedcostitems.create({ data: { ...item } }).then(() => this.loadData());
    } else {
      estimatedcostitems.update(id, { data: { ...item } }).then(() => this.loadData());
    }
    this.handleEditClose();
  };

  handleRemove = async (id) => {
    this.setState({ confirmRemoveID: id });
  };

  handleRemoveClose = () => {
    this.setState({ confirmRemoveID: undefined });
  };

  handleRemoveConfirm = async () => {
    await estimatedcostitems.remove(this.state.confirmRemoveID).then(() => {
      this.handleRemoveClose();
      this.loadData();
    });
  };

  GridColumnsOverride = (column, row, arrangement, columnsWidth) => {
    return GridColumns(column, row, arrangement, columnsWidth, this.handleRemove, this.handleEdit);
  };

  render() {
    const { classes } = this.state;

    return (
      <div className={classes.root}>
        <div className={classes.inline}>
          <Tooltip title="Create new Estimated Cost Item">
            <div className={classes.inline}>
              <Button variant="contained" color="primary" onClick={this.handleCreate} style={{ marginBottom: "10px" }}>
                <IconAdd />
              </Button>
            </div>
          </Tooltip>
        </div>
        <Grid loading={this.state.loading} data={this.state.data} GridColumns={this.GridColumnsOverride} clearFilters={"estimatedcostitemgridsetup"} />
        {this.state.confirmRemoveID && (
          <Confirmation
            isOpen={this.state.confirmRemoveID ? true : false}
            handleClose={this.handleRemoveClose}
            handleConfirm={this.handleRemoveConfirm}
            title="Remove selected Estimated Cost Item?"
            body="Are you sure you want to REMOVE the selected Estimated Cost Item Record?"
          ></Confirmation>
        )}
        {this.state.confirmEditID && (
          <Confirmation
            isOpen={this.state.confirmEditID ? true : false}
            handleClose={this.handleEditClose}
            handleConfirm={() => {}}
            title={this.state.confirmEditID == "0" ? "Creating Estimated Cost Item" : "Editing Estimated Cost Item"}
            body={undefined}
          >
            <EstimatedCostItemEditForm onSubmit={this.handleEditConfirm} onClose={this.handleEditClose} id={this.state.confirmEditID} />
          </Confirmation>
        )}
      </div>
    );
  }
}

export default withStyles(styles)(EstimatedCostItemUnstyled);
