import { client } from "./_base";

export const DealType = {
  1: "CIF",
  2: "DIP",
  3: "ExWorks",
  4: "FOB",
  5: "CFR",
};

export const getDealTypeByValue = (value: string) => {};

export const getDealTypeReadyForCombo = () => {
  const result = [];
  Object.keys(DealType).map((dt) => {
    result.push({ value: dt, display: DealType[dt] });
  });
  return result;
};

/* 
  For now, we will only be using the "Selling Terms" in the database for the "Client" module.
  TODO: update all tables affected by "Selling Terms" to use the foreign id
*/

const endpoint = "sellingterms";

export type SellingTermsType = {
  id?: number;
  code: string;
  description: string;
  created_at?: Date;
  updated_at?: Date;
};

export const SellingTermsApi = client<SellingTermsType>(endpoint, (item) => ({
  ...item,
}));

export const getSellingTermsFormatted = async () => {
  const result = await SellingTermsApi.all();
  return result.map((item) => ({ value: item.id, display: item.code }));
};
