import React from "react";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";
import { NavLink } from "react-router-dom";

import Check from "@material-ui/icons/Check";
import Close from "@material-ui/icons/Close";

import Button from "@material-ui/core/Button";

const styles = (theme: Theme) =>
  createStyles({
    root: {},
    rule: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      textAlign: "left",
    },
    good: {
      color: "green",
      marginRight: "5px",
    },
    bad: {
      color: "red",
      marginRight: "5px",
    },
  });

type InvoiceLinesInfoDialogProps = {
  message: any;
  status: any;
  mclass: any;
  mcount: any;
  mcarton: any;
  mweek: any;
  mbrand: any;
} & WithStyles<typeof styles>;

const InvoiceLinesInfoDialogUnstyled: React.FunctionComponent<InvoiceLinesInfoDialogProps> = (props) => {
  const { message, status, mclass, mcount, mcarton, mweek, mbrand, classes } = props;
  const orderId = message.substring(message.indexOf("["));
  const messageClean = orderId.indexOf("[") >= 0 ? message.replace(orderId, "") : message;
  const messageArr = messageClean.split("\n");

  return (
    <div>
      <span hidden={orderId.indexOf("[") == -1} style={{ marginBottom: "20px", paddingBottom: "20px" }}>
        <NavLink to={`/instruction/${orderId.replace("[", "").replace("]", "").replace(" FIXED", "")}/edit`} activeClassName="selected" target={"_blank"}>
          <Button
            key={`viewOrder${orderId}`}
            variant="contained"
            color="primary"
            // onClick={() => {
            //   window.open(`instruction/${orderId.replace("[", "").replace("]", "").replace(" FIXED", "")}/edit`);
            // }}
          >
            {messageArr[messageArr.length - 1].replace("Week Ignored", "Best Match")}
          </Button>
        </NavLink>
        <br />
        <br />
      </span>
      <span className={classes.rule}>
        {status != "9" ? (
          <Check key={`InvoiceLinesInfoDialogUnstyledDCC1`} className={classes.good} />
        ) : (
          <Close key={`InvoiceLinesInfoDialogUnstyledDCC11`} className={classes.bad} />
        )}
        Farm
      </span>
      <span className={classes.rule}>
        {status == "1" && <Check key={`InvoiceLinesInfoDialogUnstyledDCC2`} className={classes.good} />}
        {status == "2" &&
          (mweek == 1 ? (
            <Check key={`InvoiceLinesInfoDialogUnstyledDCC4`} className={classes.good} />
          ) : (
            <Close key={`InvoiceLinesInfoDialogUnstyledDCC44`} className={classes.bad} />
          ))}
        {status != "1" && status != "2" && <Close key={`InvoiceLinesInfoDialogUnstyledDCC22`} className={classes.bad} />}
        Week
      </span>
      <span className={classes.rule}>
        {status != "9" ? (
          <Check key={`InvoiceLinesInfoDialogUnstyledDCC3`} className={classes.good} />
        ) : (
          <Close key={`InvoiceLinesInfoDialogUnstyledDCC33`} className={classes.bad} />
        )}
        Variety
      </span>
      <span className={classes.rule}>
        {status != "3" && status != "9" ? (
          <Check key={`InvoiceLinesInfoDialogUnstyledDCC7`} className={classes.good} />
        ) : (
          <Close key={`InvoiceLinesInfoDialogUnstyledDCC77`} className={classes.bad} />
        )}
        Shipping Market
      </span>
      <span className={classes.rule}>
        {status == "1" && <Check key={`InvoiceLinesInfoDialogUnstyledDCC4`} className={classes.good} />}
        {status == "2" &&
          (mclass == 1 ? (
            <Check key={`InvoiceLinesInfoDialogUnstyledDCC4`} className={classes.good} />
          ) : (
            <Close key={`InvoiceLinesInfoDialogUnstyledDCC44`} className={classes.bad} />
          ))}
        {status != "1" && status != "2" && <Close key={`InvoiceLinesInfoDialogUnstyledDCC44`} className={classes.bad} />}
        Class
      </span>
      <span className={classes.rule}>
        {status == "1" && <Check key={`InvoiceLinesInfoDialogUnstyledDCC5`} className={classes.good} />}
        {status == "2" &&
          (mcarton == "1" ? (
            <Check key={`InvoiceLinesInfoDialogUnstyledDCC5`} className={classes.good} />
          ) : (
            <Close key={`InvoiceLinesInfoDialogUnstyledDCC55`} className={classes.bad} />
          ))}
        {status != "1" && status != "2" && <Close key={`InvoiceLinesInfoDialogUnstyledDCC55`} className={classes.bad} />}
        Packing
      </span>
      <span className={classes.rule}>
        {status == "1" && <Check key={`InvoiceLinesInfoDialogUnstyledDCC6`} className={classes.good} />}
        {status == "2" &&
          (mbrand == 1 ? (
            <Check key={`InvoiceLinesInfoDialogUnstyledDCC6`} className={classes.good} />
          ) : (
            <Close key={`InvoiceLinesInfoDialogUnstyledDCC66`} className={classes.bad} />
          ))}
        {status != "1" && status != "2" && <Close key={`InvoiceLinesInfoDialogUnstyledDCC66`} className={classes.bad} />}
        Mark Code
      </span>
      <span className={classes.rule}>
        {status == "1" && <Check key={`InvoiceLinesInfoDialogUnstyledDCC6`} className={classes.good} />}
        {status == "2" &&
          (mcount == 1 ? (
            <Check key={`InvoiceLinesInfoDialogUnstyledDCC6`} className={classes.good} />
          ) : (
            <Close key={`InvoiceLinesInfoDialogUnstyledDCC66`} className={classes.bad} />
          ))}
        {status != "1" && status != "2" && <Close key={`InvoiceLinesInfoDialogUnstyledDCC66`} className={classes.bad} />}
        Count Code
      </span>
    </div>
  );
};

export default withStyles(styles)(InvoiceLinesInfoDialogUnstyled);
