import React from "react";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";

import Grid from "../../../lib/components/grid";
import { GridColumns } from "./historygridsetup";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      height: "100%",
    },
  });

type HistoryGridUnstyledProps = {
  classes: any;
  data: any[];
} & WithStyles<typeof styles>;

class HistoryGridUnstyled extends React.Component<HistoryGridUnstyledProps, {}> {
  constructor(props) {
    super(props);
  }

  state = {
    loading: false,
    gridRef: undefined,
    gridHeight: 700,
  };

  GridColumnsOverride = (data: any, filters: any, columnArrangement?: any, columnsWidth?: any) => {
    return GridColumns(data, filters, columnArrangement, columnsWidth);
  };

  render() {
    // const { classes } = this.props;

    return (
      <div>
        <Grid
          loading={this.state.loading}
          data={this.props.data}
          GridColumns={this.GridColumnsOverride}
          forceHeight={this.state.gridHeight}
          clearFilters={"produceradvancepayhistory"}
          totalRowColumns={["amount"]}
        />
      </div>
    );
  }
}

export default withStyles(styles)(HistoryGridUnstyled);
