import React from "react";

import CustomAutoCompleteFilter from "../../lib/components/customdatagridfilter/CustomFilter";
import { FormatterNumber, FormatterNumber2Decimals } from "../../lib/components/Formatters";
import { SummaryTotal, SummaryNumber, SummaryNumber2Decimal } from "../../lib/components/SummaryFormatter";
import { EnquiryLink, Selector } from "../../lib/components/EnquiryLink";
import { handleColumnsArrangement, handleColumnsWidth } from "../../lib/helpers/grid";

const cellWidthTiny = 40;
const cellWidthSmall = 80;
const cellWidthMedium = 120;
const cellWidthLarge = 200;

const defaultColumnProperties = {
  resizable: true,
  sortable: true,
};

let currentSpace = true;
export const GridColumns = (data, filters, arrangement = undefined, columnsWidth = undefined) => {
  let postValue = "";

  if (data) {
    if (currentSpace) {
      postValue = " ";
    }
    currentSpace = !currentSpace;
  }

  const columns = [
    {
      key: "loadoutdetail_barcode",
      name: "Barcode" + postValue,
      width: cellWidthLarge,
      filterable: true,
      formatter: (props) => <EnquiryLink {...props} selector={Selector.Barcode} />,
      filterRenderer: (e) => {
        return (
          <CustomAutoCompleteFilter
            {...e}
            multiSelection={true}
            currentFilters={filters}
            fieldname={"loadoutdetail_barcode"}
            data={data}
            section="creditorsinfobarcodesdetailfilter"
          />
        );
      },
      summaryFormatter: SummaryTotal,
    },
    {
      key: "barcode_nocartons",
      name: "No Cartons" + postValue,
      width: cellWidthMedium,
      filterable: true,
      formatter: FormatterNumber,
      filterRenderer: (e) => {
        return (
          <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"barcode_nocartons"} data={data} section="creditorsinfobarcodesdetailfilter" />
        );
      },
      summaryFormatter: SummaryNumber,
    },
    {
      key: "barcode_commoditycode",
      name: "Commodity Code" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return (
          <CustomAutoCompleteFilter
            {...e}
            multiSelection={true}
            currentFilters={filters}
            fieldname={"barcode_commoditycode"}
            data={data}
            section="creditorsinfobarcodesdetailfilter"
          />
        );
      },
    },
    {
      key: "barcode_varietycode",
      name: "Variety Code" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return (
          <CustomAutoCompleteFilter
            {...e}
            multiSelection={true}
            currentFilters={filters}
            fieldname={"barcode_varietycode"}
            data={data}
            section="creditorsinfobarcodesdetailfilter"
          />
        );
      },
    },
    {
      key: "barcode_packcode",
      name: "Pack Code" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return (
          <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"barcode_packcode"} data={data} section="creditorsinfobarcodesdetailfilter" />
        );
      },
    },
    {
      key: "barcode_markcode",
      name: "Mark Code" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return (
          <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"barcode_markcode"} data={data} section="creditorsinfobarcodesdetailfilter" />
        );
      },
    },
    {
      key: "barcode_gradecode",
      name: "Grade Code" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return (
          <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"barcode_gradecode"} data={data} section="creditorsinfobarcodesdetailfilter" />
        );
      },
    },
    {
      key: "barcode_countcode",
      name: "Count Code" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return (
          <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"barcode_countcode"} data={data} section="creditorsinfobarcodesdetailfilter" />
        );
      },
    },
    {
      key: "barcode_inventorycode",
      name: "Inventory Code" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return (
          <CustomAutoCompleteFilter
            {...e}
            multiSelection={true}
            currentFilters={filters}
            fieldname={"barcode_inventorycode"}
            data={data}
            section="creditorsinfobarcodesdetailfilter"
          />
        );
      },
    },
    {
      key: "barcode_farm",
      name: "Farm" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return (
          <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"barcode_farm"} data={data} section="creditorsinfobarcodesdetailfilter" />
        );
      },
    },
    {
      key: "barcode_palletsize",
      name: "Pallet Size" + postValue,
      width: cellWidthMedium,
      filterable: true,
      formatter: FormatterNumber2Decimals,
      filterRenderer: (e) => {
        return (
          <CustomAutoCompleteFilter
            {...e}
            multiSelection={true}
            currentFilters={filters}
            fieldname={"barcode_palletsize"}
            data={data}
            section="creditorsinfobarcodesdetailfilter"
          />
        );
      },
      summaryFormatter: SummaryNumber2Decimal,
    },
    // {
    //   key: "barcode_id",
    //   name: "barcode_id" + postValue,
    //   width: cellWidthMedium,
    //   filterable: true,
    //   filterRenderer: (e) => {
    //     return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"barcode_id"} data={data} section="creditorsinfobarcodesdetailfilter" />;
    //   },
    // },
    {
      key: "dispatch_id",
      name: "Dispatch ID" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"dispatch_id"} data={data} section="creditorsinfobarcodesdetailfilter" />;
      },
    },
    {
      key: "dispatch_containerno",
      name: "Container" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return (
          <CustomAutoCompleteFilter
            {...e}
            multiSelection={true}
            currentFilters={filters}
            fieldname={"dispatch_containerno"}
            data={data}
            section="creditorsinfobarcodesdetailfilter"
          />
        );
      },
    },
    {
      key: "dispatch_containertype",
      name: "Container Type" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return (
          <CustomAutoCompleteFilter
            {...e}
            multiSelection={true}
            currentFilters={filters}
            fieldname={"dispatch_containertype"}
            data={data}
            section="creditorsinfobarcodesdetailfilter"
          />
        );
      },
    },
    {
      key: "dispatch_sealnumber",
      name: "Seal Number" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return (
          <CustomAutoCompleteFilter
            {...e}
            multiSelection={true}
            currentFilters={filters}
            fieldname={"dispatch_sealnumber"}
            data={data}
            section="creditorsinfobarcodesdetailfilter"
          />
        );
      },
    },
    {
      key: "dispatch_consignee_id",
      name: "Consignee" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return (
          <CustomAutoCompleteFilter
            {...e}
            multiSelection={true}
            currentFilters={filters}
            fieldname={"dispatch_consignee_id"}
            data={data}
            section="creditorsinfobarcodesdetailfilter"
          />
        );
      },
    },
    {
      key: "dispatch_clearingagent_id",
      name: "Clearingagent" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return (
          <CustomAutoCompleteFilter
            {...e}
            multiSelection={true}
            currentFilters={filters}
            fieldname={"dispatch_clearingagent_id"}
            data={data}
            section="creditorsinfobarcodesdetailfilter"
          />
        );
      },
    },
    // {
    //   key: "sale_id",
    //   name: "sale_id" + postValue,
    //   width: cellWidthMedium,
    //   filterable: true,
    //   filterRenderer: (e) => {
    //     return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"sale_id"} data={data} section="creditorsinfobarcodesdetailfilter" />;
    //   },
    // },
    {
      key: "sale_invoicenumber",
      name: "Invoice Number" + postValue,
      width: cellWidthLarge,
      filterable: true,
      formatter: (props) => <EnquiryLink {...props} selector={Selector.InvoiceNumber} />,
      filterRenderer: (e) => {
        return (
          <CustomAutoCompleteFilter
            {...e}
            multiSelection={true}
            currentFilters={filters}
            fieldname={"sale_invoicenumber"}
            data={data}
            section="creditorsinfobarcodesdetailfilter"
          />
        );
      },
    },
    {
      key: "loadout_exportNotificationReference",
      name: "Export Notification" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return (
          <CustomAutoCompleteFilter
            {...e}
            multiSelection={true}
            currentFilters={filters}
            fieldname={"loadout_exportNotificationReference"}
            data={data}
            section="creditorsinfobarcodesdetailfilter"
          />
        );
      },
    },
    {
      key: "loadout_reference",
      name: "Reference" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return (
          <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"loadout_reference"} data={data} section="creditorsinfobarcodesdetailfilter" />
        );
      },
    },
    {
      key: "currency",
      name: "Currency" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"currency"} data={data} section="creditorsinfobarcodesdetailfilter" />;
      },
    },
    // {
    //   key: "comment",
    //   name: "Comment" + postValue,
    //   width: cellWidthMedium,
    //   filterable: true,
    //   filterRenderer: (e) => {
    //     return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"comment"} data={data} section="creditorsinfobarcodesdetailfilter" />;
    //   },
    // },
    {
      key: "producer_code",
      name: "Producer" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return (
          <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"producer_code"} data={data} section="creditorsinfobarcodesdetailfilter" />
        );
      },
    },
    {
      key: "stockdetail_sellingprice",
      name: "Selling Price" + postValue,
      width: cellWidthMedium,
      filterable: true,
      formatter: FormatterNumber2Decimals,
      filterRenderer: (e) => {
        return (
          <CustomAutoCompleteFilter
            {...e}
            multiSelection={true}
            currentFilters={filters}
            fieldname={"stockdetail_sellingprice"}
            data={data}
            section="creditorsinfobarcodesdetailfilter"
          />
        );
      },
    },
    {
      key: "dispatch_forwardagentref",
      name: "Fowarding Agent Ref" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return (
          <CustomAutoCompleteFilter
            {...e}
            multiSelection={true}
            currentFilters={filters}
            fieldname={"dispatch_forwardagentref"}
            data={data}
            section="creditorsinfobarcodesdetailfilter"
          />
        );
      },
    },
    {
      key: "total",
      name: "Total" + postValue,
      width: cellWidthMedium,
      filterable: true,
      formatter: FormatterNumber2Decimals,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"total"} data={data} section="creditorsinfobarcodesdetailfilter" />;
      },
      summaryFormatter: SummaryNumber2Decimal,
    },
  ].map((c) => ({ ...c, ...defaultColumnProperties }));

  let columnsArranged = [];
  columnsArranged = handleColumnsArrangement(columnsArranged, arrangement, columns);
  columnsArranged = handleColumnsWidth(columnsArranged, columnsWidth, columns);

  return columnsArranged;
};
