import React from "react";
import CustomAutoCompleteFilter from "../lib/components/customdatagridfilter/CustomFilter";

import IconBrightness from "@material-ui/icons/Brightness1";
import Tooltip from "@material-ui/core/Tooltip";

import { isNullOrUndef } from "../lib/helpers/isNullOrUndef";
import { handleColumnsArrangement, handleColumnsWidth } from "../lib/helpers/grid";
import { StatusTypes } from "../lib/types";

import { SummaryNumber, SummaryNumber2Decimal } from "../lib/components/SummaryFormatter";
import { DateFormatter, FormatterNumber, FormatterNumber2Decimals } from "../lib/components/Formatters";
import { CheckboxSelect } from "../lib/components/CheckboxSelect";

import { SaleDispatchJoinedType } from "../lib/api/sale";

const cellWidthSmallX = 80;
const cellWidthMedium = 150;

const defaultColumnProperties = {
  resizable: true,
  sortable: true,
};

const StatusFormatter = ({ row }) => {
  const datarow: SaleDispatchJoinedType = { ...row };

  if (datarow.camend_status != 0) {
    return (
      <div>
        <Tooltip title={`${datarow.camend_status_text}`}>
          <IconBrightness style={{ color: "red", display: "block", margin: "auto", border: "1px solid #333", borderRadius: "100%" }} />
        </Tooltip>
      </div>
    );
  } else if (datarow["status"] == StatusTypes.INVOICED) {
    return (
      <div>
        <Tooltip title={`${StatusTypes.INVOICED}`}>
          <IconBrightness style={{ color: "green", display: "block", margin: "auto", border: "1px solid #333", borderRadius: "100%" }} />
        </Tooltip>
      </div>
    );
  } else {
    return (
      <div>
        <Tooltip title={`${StatusTypes.NEW}`}>
          <IconBrightness style={{ color: "yellow", display: "block", margin: "auto", border: "1px solid #333", borderRadius: "100%" }} />
        </Tooltip>
      </div>
    );
  }
};

const TermsFormatter = ({ row, column }) => {
  const data = row[column.key];
  return <div>{isNullOrUndef(data) ? "CUSTOM" : data}</div>;
};

let currentSpace = true;
export const GridColumnsProforma = (data, filters, arrangement = undefined, columnsWidth = undefined, selectedRows, handleCheckboxSelect) => {
  let postValue = "";

  if (data) {
    if (currentSpace) {
      postValue = " ";
    }
    currentSpace = !currentSpace;
  }

  let columns = [
    {
      key: "check",
      name: "#C" + postValue,
      width: cellWidthSmallX,
      formatter: ({ row }) => {
        return (
          <div style={{ display: "flex", float: "left" }}>
            <div style={{ marginTop: "-8px" }}>
              <CheckboxSelect row={row} selectedRows={selectedRows} handleCheckboxSelect={handleCheckboxSelect} valueKey={"sale_invoicenumber"} />
            </div>
          </div>
        );
      },
    },
    {
      key: "status",
      name: "#S" + postValue,
      width: cellWidthSmallX,
      formatter: StatusFormatter,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"status"} data={data} section={"invoiceProForma"} />;
      },
      filterable: true,
    },
    {
      key: "dispatch_dispatchdocs_id",
      name: "Dispatch" + postValue,
      width: cellWidthMedium,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"dispatch_dispatchdocs_id"} data={data} section={"invoiceProForma"} />;
      },
      filterable: true,
    },
    {
      key: "loadout_reference",
      name: "Load Ref" + postValue,
      width: cellWidthMedium,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"loadout_reference"} data={data} section={"invoiceProForma"} />;
      },
      filterable: true,
    },
    {
      key: "dispatch_loaddate",
      name: "Load Date" + postValue,
      width: cellWidthMedium,
      formatter: DateFormatter,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"dispatch_loaddate"} data={data} section={"invoiceProForma"} />;
      },
      filterable: true,
    },
    {
      key: "barcode_nocartons",
      name: "Total Cartons" + postValue,
      width: cellWidthMedium,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"barcode_nocartons"} data={data} section={"invoiceProForma"} />;
      },
      filterable: true,
      formatter: FormatterNumber,
      summaryFormatter: SummaryNumber,
    },
    {
      key: "barcode_palletsize",
      name: "Total Pallets" + postValue,
      width: cellWidthMedium,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"barcode_palletsize"} data={data} section={"invoiceProForma"} />;
      },
      filterable: false,
      formatter: FormatterNumber2Decimals,
      summaryFormatter: SummaryNumber2Decimal,
    },
    {
      key: "sale_invoicenumber",
      name: "Invoice Number" + postValue,
      width: cellWidthMedium,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"sale_invoicenumber"} data={data} section={"invoiceProForma"} />;
      },
      filterable: true,
    },
    {
      key: "shipment_week",
      name: "Sales Week" + postValue,
      width: cellWidthMedium,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"shipment_week"} data={data} section={"invoiceProForma"} />;
      },
      filterable: true,
    },
    {
      key: "sale_date",
      name: "Invoice Date" + postValue,
      width: cellWidthMedium,
      formatter: DateFormatter,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"sale_date"} data={data} section={"invoiceProForma"} />;
      },
      filterable: true,
    },
    {
      key: "currency_code",
      name: "Currency" + postValue,
      width: cellWidthMedium,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"currency_code"} data={data} section={"invoiceProForma"} />;
      },
      filterable: true,
    },
    {
      key: "total",
      name: "Total Sale" + postValue,
      width: cellWidthMedium,
      formatter: FormatterNumber2Decimals,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"total"} data={data} section={"invoiceProForma"} />;
      },
      filterable: false,
      summaryFormatter: SummaryNumber2Decimal,
    },
    {
      key: "loadout_consignee",
      name: "Client" + postValue,
      width: cellWidthMedium,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"loadout_consignee"} data={data} section={"invoiceProForma"} />;
      },
      filterable: true,
    },
    {
      key: "consignee_code",
      name: "Consignee" + postValue,
      width: cellWidthMedium,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"consignee_code"} data={data} section={"invoiceProForma"} />;
      },
      filterable: true,
    },
    {
      key: "terms_description",
      name: "Terms" + postValue,
      width: cellWidthMedium,
      formatter: TermsFormatter,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"terms_description"} data={data} section={"invoiceProForma"} />;
      },
      filterable: true,
    },
    {
      key: "shippingCode",
      name: "Shipping Line" + postValue,
      width: cellWidthMedium,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"shippingCode"} data={data} section={"invoiceProForma"} />;
      },
      filterable: true,
    },
    {
      key: "vessel_code",
      name: "Vessel" + postValue,
      width: cellWidthMedium,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"vessel_code"} data={data} section={"invoiceProForma"} />;
      },
      filterable: true,
    },
    {
      key: "dispatch_containerno",
      name: "Container No" + postValue,
      width: cellWidthMedium,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"dispatch_containerno"} data={data} section={"invoiceProForma"} />;
      },
      filterable: true,
    },
    {
      key: "portloading_description",
      name: "Port of Loading" + postValue,
      width: cellWidthMedium,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"portloading_description"} data={data} section={"invoiceProForma"} />;
      },
      filterable: true,
    },
    {
      key: "portdistcharge_description",
      name: "Port of Discharge" + postValue,
      width: cellWidthMedium,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"portdistcharge_description"} data={data} section={"invoiceProForma"} />;
      },
      filterable: true,
    },
    {
      key: "loadout_etd",
      name: "ETD" + postValue,
      width: cellWidthMedium,
      formatter: DateFormatter,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"loadout_etd"} data={data} section={"invoiceProForma"} />;
      },
      filterable: true,
    },
    {
      key: "loadout_eta",
      name: "ETA" + postValue,
      width: cellWidthMedium,
      formatter: DateFormatter,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"loadout_eta"} data={data} section={"invoiceProForma"} />;
      },
      filterable: true,
    },
    {
      key: "producer_codes",
      name: "Producer(s)" + postValue,
      width: cellWidthMedium,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"producer_codes"} data={data} section={"invoiceProForma"} />;
      },
      filterable: true,
    },
    {
      key: "users_username",
      name: "Trader" + postValue,
      width: cellWidthMedium,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"users_username"} data={data} section={"invoiceProForma"} />;
      },
      filterable: true,
    },
  ];

  columns = columns.map((c) => ({ ...c, ...defaultColumnProperties }));

  let columnsArranged = [];
  columnsArranged = handleColumnsArrangement(columnsArranged, arrangement, columns);
  columnsArranged = handleColumnsWidth(columnsArranged, columnsWidth, columns);

  return columnsArranged;
};
