import BigNumber from "bignumber.js";

export const getGridTotals = (data: any[], columns: string[]) => {
  return data.reduce((obj, row) => {
    return columns.reduce((obj, col) => {
      obj[col] = new BigNumber(obj[col] || 0).plus(new BigNumber(row[col] || 0)).toNumber();
      return obj;
    }, obj);
  }, {});
};

export const summaryRows = (data: any[], columns: string[]): any[] => {
  let summaryRows = {};

  if (data.length == 0) {
    columns.forEach((col) => {
      summaryRows[col] = 0;
    });
  } else {
    summaryRows = getGridTotals(data, columns);
  }

  return [summaryRows];
};

export const getGridAverage = (data: any[], columns: string[]) => {
  return data.reduce((obj, row) => {
    return columns.reduce((obj, col) => {
      obj[col] = new BigNumber(obj[col] || 0).plus(new BigNumber(row[col]).dividedBy(data.length)).toNumber();
      return obj;
    }, obj);
  }, {});
};

export const summaryAverageRows = (data: any[], columns: string[]): any[] => {
  let average = {};

  if (data.length == 0) {
    columns.forEach((col) => {
      average[col] = 0;
    });
  } else {
    average = getGridAverage(data, columns);
  }

  return [average];
};

const getGridCount = (data, columns) => {
  return data.reduce((obj, row) => {
    return columns.reduce((obj, col) => {
      if (!obj[col]) {
        obj[col] = 0
      } 
        obj[col] += 1

      return obj;
    }, obj);
  }, {});
}

export const summaryCountRows = (data: any[], columns: string[]) => {
  let count = {};

  if (data.length == 0) {
    columns.forEach((col) => {
      count[col] = 0;
    });
  } else {
    count = getGridCount(data, columns)
  }

  return [count];
}

export const handleFilterChange = (filters, currentFilters) => {
  const filterKey = Object.keys(filters)[0];
  const filterItem = filters[filterKey];

  const newFilters = { ...currentFilters };

  if (filterItem.filterTerm && filterItem.filterTerm.length > 0) {
    newFilters[filterItem.column.key] = filterItem;
  } else {
    delete newFilters[filterItem.column.key];
  }

  return newFilters;
};

export const handleSortChange = (sorting, sortColumn, sortDirection) => {
  const sortingState = { ...sorting };

  if (sortingState[sortColumn] != undefined && sortDirection == "NONE") {
    delete sortingState[sortColumn];
  } else {
    sortingState[sortColumn] = sortDirection;
  }
  return sortingState;
};

export const handleGridFill = ({ columnKey, sourceRow, targetRows }) => {
  return targetRows.map((row) => ({ ...row, [columnKey]: sourceRow[columnKey], rowUpdated: 1 }));
};

export const handleColumnsArrangement = (columnsArranged, arrangement, columns) => {
  if (arrangement && arrangement.length > 0) {
    arrangement.map((item) => {
      const indexOfItem = columns.indexOf(columns.find((col) => col && col.name && col.name.trim() == item.toString().trim()));
      if (indexOfItem != -1) {
        columnsArranged.push(columns[indexOfItem]);
      }
    });
  } else {
    columns.map((colItem) => {
      columnsArranged.push(colItem);
    });
  }

  return columnsArranged;
};

export const handleColumnsWidth = (columnsArranged, columnsWidth, columns) => {
  if (columnsWidth && columnsWidth.length > 0) {
    for (let index = 0; index < columnsArranged.length; index++) {
      const element = columnsArranged[index];
      const colItem = columnsWidth.find((colwitem) => colwitem && colwitem.name && colwitem.name.trim() == element.name.trim());
      if (colItem) {
        element.width = colItem.width;
      }
    }
  }

  return columnsArranged;
};
