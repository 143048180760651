import React from "react";

import CustomAutoCompleteFilter from "../../lib/components/customdatagridfilter/CustomFilter";
import { AdvanceContractsPayoutTypes } from "../../lib/api/advancecontractspayouttypes";

import numeral from "numeral";

import { isNullOrUndef } from "../../lib/helpers/isNullOrUndef";

const cellWidthSmall = 120;
const cellWidthSmallBig = 140;
const cellWidthMedium = 200;

const defaultColumnProperties = {
  resizable: true,
  sortable: true,
};

const FormatterCurrency = ({ row, column }) => {
  const data = row[column.key];
  return <div style={{ textAlign: "right" }}>{`${numeral(data).format("0.00")}`}</div>;
};

const FormatterRecon = ({ row, column }) => {
  const data = row[column.key];

  let reconvalue = "";
  if (!isNullOrUndef(data)) {
    reconvalue = "000000" + data;
    reconvalue = reconvalue.slice(reconvalue.length - 6);
    reconvalue = "PR" + reconvalue;
  }
  return <div style={{ textAlign: "left" }}>{reconvalue}</div>;
};

let currentSpace = true;

export const LinkedGridColumns = (data, filters, arrangement, columnsWidth) => {
  let postValue = "";

  if (data) {
    if (currentSpace) {
      postValue = " ";
    }
    currentSpace = !currentSpace;
  }

  const columns = [
    {
      key: "producer_name",
      name: "Name" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"producer_name"} data={data} section={"advancecontractslinked"} />;
      },
    },
    {
      key: "producer_code",
      name: "Code" + postValue,
      width: cellWidthSmall,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"producer_code"} data={data} section={"advancecontractslinked"} />;
      },
    },
    {
      key: "paid_amount",
      name: "Amount" + postValue,
      width: cellWidthSmall,
      filterable: true,
      formatter: FormatterCurrency,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"paid_amount"} data={data} section={"advancecontractslinked"} />;
      },
    },
    {
      key: "ident",
      name: "Payment Ident" + postValue,
      width: cellWidthSmall,
      filterable: true,
      formatter: FormatterRecon,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"ident"} data={data} section={"advancecontractslinked"} />;
      },
    },
    {
      key: "makeanote",
      name: "Payment Note" + postValue,
      width: cellWidthSmallBig,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"makeanote"} data={data} section={"advancecontractslinked"} />;
      },
    },
  ].map((c) => ({ ...c, ...defaultColumnProperties }));

  const columnsArranged = [];

  if (arrangement && arrangement.length > 0) {
    arrangement.map((item) => {
      const indexOfItem = columns.indexOf(columns.find((col) => col.name.trim() == item.toString().trim()));
      if (indexOfItem != -1) {
        columnsArranged.push(columns[indexOfItem]);
      }
    });
  } else {
    columns.map((colItem) => {
      columnsArranged.push(colItem);
    });
  }

  if (columnsWidth && columnsWidth.length > 0) {
    for (let index = 0; index < columnsArranged.length; index++) {
      const element = columnsArranged[index];
      const colItem = columnsWidth.find((colwitem) => colwitem.name.trim() == element.name.trim());
      if (colItem) {
        element.width = colItem.width;
      }
    }
  }

  return columnsArranged;
};

export default function getLinkedCellActions(column, row) {
  return null;
}
