import React from "react";
import { Redirect } from "react-router-dom";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";

import LinearProgress from "@material-ui/core/LinearProgress";
import Toolbar from "@material-ui/core/Toolbar";
import Tooltip from "@material-ui/core/Tooltip";
import Button from "@material-ui/core/Button";
import IconAdd from "@material-ui/icons/Add";

import Grid from "../../lib/components/grid";
import { DialogInformation } from "../../lib/components/dialoginformation";
import Confirmation from "../../lib/components/confirmation";
import { creditorsinvoice, creditorsinvoiceFullByCreditor, CreditorsInvoiceFullType, CreditorsInvoiceDocumentType } from "../../lib/api/creditorsinvoice";

import { GridColumns } from "./creditorsinvoicegridsetup";
import CreditorInvoiceAdjustment from "./creditorsinvoiceadjustment";
import { creditorsinvoiceadjustment } from "../../lib/api/creditorsinvoiceadjustment";

import { SnackContext } from "../../lib/context/SnackContext";
import { Row } from "react-data-grid";
import { withRouter } from "react-router-dom";

import { GenerateErrorMessage } from "../../lib/helpers/string_methods";
import { getFinancialYearSelected } from "../../lib/api/week";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1),
      width: "100%",
      height: "100%",
      position: "relative",
    },
    gridWrapper: {
      position: "relative",
    },
    inline: {
      display: "flex",
      flexDirection: "row",
      gap: "10px",
      position: "absolute",
      left: 0,
      top: 0,
      alignItems: "center",
    },
    redRow: {
      color: "red",
    },
    greenRow: {
      color: "green",
    },
  });

type CreditorsInvoiceFullProps = { match: any; location: any; history: any } & WithStyles<typeof styles>;
export class CreditorsInvoiceUnstyled extends React.Component<CreditorsInvoiceFullProps, any> {
  state = {
    classes: undefined,
    data: [],
    loading: true,
    message: undefined,
    selectedRecord: undefined,
    confirmDelete: undefined,
    adjustRecord: undefined,
    confirmDeleteAdjustment: undefined,
    creditor_code: "",
  };

  constructor(props) {
    super(props);
    this.state.creditor_code = props.match.params.code;
    this.state.classes = props.classes;
  }

  static contextType = SnackContext;
  context!: React.ContextType<typeof SnackContext>;

  componentDidMount() {
    this.loadData();
  }

  loadData = async () => {
    this.setState({ loading: true });

    try {
      const result = await creditorsinvoiceFullByCreditor(getFinancialYearSelected(), this.state.creditor_code);
      // grouping invoice numbers
      const grouped = result.reduce((obj, row) => {
        const id =
          `${row.creditorsinvoice_type}_` +
          (row.creditorsinvoice_type == CreditorsInvoiceDocumentType.Invoice ? `${row.creditorsinvoice_id}` : `${row.creditorsinvoiceadjustment_id}`);
        obj[id] = {
          ...row,
          saleinvoicenumbers: `${obj[id] ? obj[id].saleinvoicenumbers + ", " : ""}${row.sale_invoicenumber || ""}`,
        };

        return obj;
      }, {});
      const data = Object.values(grouped).sort((a: CreditorsInvoiceFullType, b: CreditorsInvoiceFullType) => a.invoicenumber.localeCompare(b.invoicenumber));

      this.setState({ data });
    } catch (error) {
      const err = GenerateErrorMessage(error, "failed to retrieve data");
      this.context.updateSnack({ show: true, color: "red", message: err });
    }

    this.setState({ loading: false });
  };

  handleEdit = (selectedRecord?: CreditorsInvoiceFullType) => {
    if (selectedRecord && selectedRecord.creditorsinvoice_id) {
      if (selectedRecord.creditorsinvoice_type == CreditorsInvoiceDocumentType.Invoice) {
        this.setState({ selectedRecord });
      } else {
        this.setState({ adjustRecord: selectedRecord });
      }
    } else {
      this.setState({ selectedRecord: { creditorsinvoice_id: 0 } });
    }
  };

  handleRemove = (row: CreditorsInvoiceFullType) => {
    if (row.creditorsinvoice_type == CreditorsInvoiceDocumentType.Invoice) {
      this.setState({ confirmDelete: row });
    } else {
      this.setState({ confirmDeleteAdjustment: row });
    }
  };

  handleRemoveClose = () => {
    this.setState({ confirmDelete: undefined, confirmDeleteAdjustment: undefined });
  };

  handleRemoveConfirm = async () => {
    try {
      if (this.state.confirmDelete) {
        await creditorsinvoice.remove(this.state.confirmDelete.creditorsinvoice_id);
      } else {
        await creditorsinvoiceadjustment.remove(this.state.confirmDeleteAdjustment.creditorsinvoiceadjustment_id);
      }
    } catch (error) {
      const err = GenerateErrorMessage(error, "Error removing data");
      this.context.updateSnack({ show: true, color: "red", message: err });
    }

    this.setState({ confirmDelete: undefined, confirmDeleteAdjustment: undefined });
    await this.loadData();
  };

  handleAdjust = (row: CreditorsInvoiceFullType) => {
    this.setState({ adjustRecord: row });
  };

  GridColumnsOverride = (column, row, arrangement, columnsWidth) => {
    return GridColumns(column, row, arrangement, columnsWidth, this.handleEdit, this.handleRemove, this.handleAdjust);
  };

  handleInformationClose = () => {
    this.setState({ message: undefined });
  };

  handleAdjustmentProcess = async (values: CreditorsInvoiceFullType & { amount: number; vat: number }) => {
    this.setState({ loading: true });

    if (values) {
      try {
        const id = values.creditorsinvoiceadjustment_id;

        const dataToPush = {
          data: {
            status: 0,
            amount: values.amount,
            vat: values.vat,
            adjustdate: values.adjustdate,
            creditorsinvoice_id: values.creditorsinvoice_id,
            type: values.creditorsinvoiceadjustment_type,
            credit_note: values.credit_note,
          },
        };

        if (id) {
          await creditorsinvoiceadjustment.update(id, dataToPush);
        } else {
          await creditorsinvoiceadjustment.create(dataToPush);
        }

        await this.loadData();
      } catch (error) {
        const err = GenerateErrorMessage(error, "Error updating data");
        this.context.updateSnack({ show: true, color: "red", message: err });
      }
    }
    this.setState({ adjustRecord: undefined, selectedRow: undefined, loading: false });
  };

  rowRenderer = (props, classes) => {
    const { row } = props;
    const color =
      row.creditorsinvoice_type == CreditorsInvoiceDocumentType.Credit ? classes.redRow : row.creditorsinvoice_type == CreditorsInvoiceDocumentType.Debit && classes.greenRow;

    return <Row {...props} className={color} />;
  };

  handleGoBack = () => {
    this.props.history.push("/creditors/summary");
  };

  render() {
    const { classes } = this.state;

    return (
      <div className={classes.root}>
        {this.state.loading && <LinearProgress color="secondary" />}
        {this.state.selectedRecord && <Redirect to={`/creditors/info/${this.state.selectedRecord.creditorsinvoice_id}?creditors_code=${this.state.creditor_code}`} />}
        {this.state.adjustRecord && <CreditorInvoiceAdjustment adjustRecord={this.state.adjustRecord} handleProcess={this.handleAdjustmentProcess} loading={this.state.loading} />}
        {!this.state.adjustRecord && (
          <div className={classes.gridWrapper}>
            <Toolbar className={classes.inline}>
              <Tooltip style={{ zIndex: 0 }} title="Create new Creditors Invoice">
                <Button variant="contained" color="primary" onClick={() => this.handleEdit()}>
                  <IconAdd />
                </Button>
              </Tooltip>
              <Tooltip style={{ zIndex: 0 }} title="Return to summary page">
                <Button variant="contained" color="secondary" onClick={this.handleGoBack}>
                  Back
                </Button>
              </Tooltip>
            </Toolbar>
            <Grid
              loading={this.state.loading}
              data={this.state.data}
              GridColumns={this.GridColumnsOverride}
              handleRefresh={() => this.loadData()}
              clearFilters={"creditorsinvoicegridsetup"}
              rowRenderer={(props) => this.rowRenderer(props, classes)}
              totalRowColumns={["validate_totalexcl", "validate_vat", "validate_total"]}
            />
          </div>
        )}
        {this.state.message && (
          <DialogInformation
            isOpen={true}
            handleClose={this.handleInformationClose}
            handleOK={this.handleInformationClose}
            title={"Feedback"}
            body={`${this.state.message}`}
            showinput={false}
          />
        )}
        {this.state.confirmDelete && (
          <Confirmation
            isOpen={true}
            handleClose={this.handleRemoveClose}
            handleConfirm={this.handleRemoveConfirm}
            title={"Confirm Remove Invoice?"}
            body={`Are you sure you want to remove Creditors Invoice?`}
          />
        )}
        {this.state.confirmDeleteAdjustment && (
          <Confirmation
            isOpen={true}
            handleClose={this.handleRemoveClose}
            handleConfirm={this.handleRemoveConfirm}
            title={"Confirm Remove Invoice Adjustment?"}
            body={`Are you sure you want to remove this Creditors Invoice Adjustment?`}
          />
        )}
      </div>
    );
  }
}

export default withStyles(styles)(withRouter(CreditorsInvoiceUnstyled));
