import React, { FC, useEffect } from "react";

import { Form, Field } from "react-final-form";
import { TextField, Select } from "final-form-material-ui";

import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Check from "@material-ui/icons/Check";
import Close from "@material-ui/icons/Close";
import MenuItem from "@material-ui/core/MenuItem";

import { port } from "../../lib/api/port";
import { targetsSorted } from "../../lib/api/target";

import { DialogInformation } from "../../lib/components/dialoginformation";
import { MaterialCheckBoxComponent } from "../../lib/helpers/materialcomponents";
import { capitalizeString } from "../../lib/helpers/Capitalize";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1),
    },
    tableRowFieldTitle: {
      paddingTop: theme.spacing(1),
      paddingRight: theme.spacing(1) * 2,
      marginTop: theme.spacing(1),
      textAlign: "right",
      minWidth: "145px",
    },
    tableRowFieldData: {
      paddingTop: theme.spacing(1),
      paddingRight: theme.spacing(1) * 2,
      marginTop: theme.spacing(1),
      textAlign: "left",
    },
    tableRowFieldInput: {
      width: "310px",
    },
  });

type PortDetailProps = {
  editPort_id: number;
  onClose: any;
  onSubmittedEvent: any;
} & WithStyles<typeof styles>;

const PortDetailUnstyled: React.SFC<PortDetailProps> = (props) => {
  const { classes, editPort_id, onClose, onSubmittedEvent } = props;
  const [portData, setPortData] = React.useState({});
  const [targetsList, setTargetsList] = React.useState([]);
  const [targetRegions, setTargetRegions] = React.useState([]);
  const [targetMarkets, setTargetMarkets] = React.useState([]);
  const [targetCountries, setTargetCountries] = React.useState([]);
  const [message, setMessage] = React.useState(undefined);
  const [newPortId, setNewPortId] = React.useState(0);

  useEffect(() => {
    loadData();
  }, [editPort_id]);

  const loadData = async (requestedId = undefined) => {
    const newData = {
      id: undefined,
      code: undefined,
      description: undefined,
      target_id: undefined,
      region: "",
      market: "",
      loading: 0,
    };

    await port.single(requestedId ? requestedId : newPortId != 0 ? newPortId : editPort_id).then(async (portDetail) => {
      if (!portDetail) {
        setPortData(newData);
      }
      await targetsSorted().then((result) => {
        setTargetsList(result.data);
        const regions = [];
        Promise.all(
          result.data.map((target) => {
            const targetrexist = regions.filter((region) => {
              if (region.value == target.region) {
                return region;
              }
            });
            if (targetrexist.length == 0) {
              regions.push({ id: regions.length, value: target.region });
            }
          }),
        ).then(async () => {
          const detail = portDetail ? portDetail : newData;

          setTargetRegions(regions);

          detail.region = "";
          detail.market = "";
          const regionFilter = result.data.filter((data) => data.id == detail.target_id);
          if (regionFilter && regionFilter.length > 0) {
            detail.region = regionFilter[0].region;
          }

          const marketFilter = result.data.filter((data) => data.id == detail.target_id);
          if (marketFilter && marketFilter.length > 0) {
            detail.market = marketFilter[0].market;
          }

          filterMarkets(detail.region, result.data);

          filterCountries(detail.market, result.data);

          setPortData(detail);
        });
      });
    });
  };

  const onSubmit = async (portData, reactFinalForm) => {
    let id = portData["id"];

    delete portData["region"];
    delete portData["market"];
    delete portData["id"];

    if (id == undefined) {
      await port.create({ data: { ...portData, code: portData.code.toUpperCase(), description: capitalizeString(portData.description) } });
    } else {
      await port.update(id, { data: { ...portData, code: portData.code.toUpperCase(), description: capitalizeString(portData.description) } });
    }
    onSubmittedEvent();
  };

  const filterMarkets = (region, list = undefined) => {
    let targets = list ? list : targetsList;
    if (region) {
      targets = targets.filter((target) => {
        if (target.region == region) {
          return target;
        }
      });
    }

    const targetsM = [];

    targets.map((target: any) => {
      const indexM = targetsM.filter((item) => {
        return item.value == target.market;
      });
      if (indexM.length === 0) {
        targetsM.push({ id: targetsM.length, value: target.market });
      }
    });

    const markets = targetsM.sort((a, b) => {
      if (a.value < b.value) return -1;
      return 1;
    });
    setTargetCountries([]);
    setTargetMarkets(markets);
  };

  const filterCountries = (market, list = undefined) => {
    let targets = list ? list : targetsList;
    if (market) {
      targets = targets.filter((target) => {
        if (target.market == market) {
          return target;
        }
      });
    }

    const targetsC = [];

    targets.map((target: any) => {
      const indexC = targetsC.filter((item) => {
        return item.value == target.country;
      });
      if (indexC.length === 0) {
        targetsC.push({ id: targetsC.length, value: target.country, market: target.market, region: target.region, target_id: target.id });
      }
    });

    const countries = targetsC.sort((a, b) => {
      if (a.value < b.value) return -1;
      return 1;
    });

    setTargetCountries(countries);
  };

  const handleInformationClose = () => {
    setMessage(undefined);
  };

  return (
    <div style={{ marginTop: "-4px" }} className={classes.root}>
      <Form
        initialValues={{ ...portData }}
        onSubmit={onSubmit}
        render={({ handleSubmit, form, dirty }: any) => (
          <form onSubmit={handleSubmit}>
            <table>
              <tbody>
                <tr>
                  <td className={classes.tableRowFieldTitle}>
                    <span>Code:</span>
                  </td>
                  <td className={classes.tableRowFieldData}>
                    <Field fullWidth name="code" component={TextField} type="text" className={classes.tableRowFieldInput} disabled={form.getState().values.id} />
                  </td>
                </tr>
                <tr>
                  <td className={classes.tableRowFieldTitle}>
                    <span>Target Region:</span>
                  </td>
                  <td style={{ paddingBottom: "5px" }}>
                    <Field
                      name="region"
                      component={Select}
                      className={classes.tableRowFieldInput}
                      parse={(value) => {
                        filterMarkets(value);
                        return value;
                      }}
                    >
                      {targetRegions.map((region, index) => (
                        <MenuItem key={index} value={region.value}>
                          {region.value}
                        </MenuItem>
                      ))}
                    </Field>
                  </td>
                </tr>

                <tr>
                  <td className={classes.tableRowFieldTitle}>
                    <span>Target Market:</span>
                  </td>
                  <td style={{ paddingBottom: "5px" }}>
                    <Field
                      name="market"
                      component={Select}
                      className={classes.tableRowFieldInput}
                      parse={(value) => {
                        filterCountries(value);
                        return value;
                      }}
                    >
                      {targetMarkets.map((market, index) => (
                        <MenuItem key={index} value={market.value}>
                          {market.value}
                        </MenuItem>
                      ))}
                    </Field>
                  </td>
                </tr>

                <tr>
                  <td className={classes.tableRowFieldTitle}>
                    <span>Target Country:</span>
                  </td>
                  <td style={{ paddingBottom: "5px" }}>
                    <Field
                      name="target_id"
                      component={Select}
                      className={classes.tableRowFieldInput}
                      parse={(value) => {
                        const target = targetsList.filter((target) => target.id == value)[0];
                        form.getState().values.region = target.region;
                        form.getState().values.market = target.market;

                        portData["region"] = target.region;
                        portData["market"] = target.market;
                        portData["target_id"] = value;
                        return value;
                      }}
                    >
                      {targetCountries.map((country, index) => (
                        <MenuItem key={index} value={country.target_id}>
                          {country.value}
                        </MenuItem>
                      ))}
                    </Field>
                  </td>
                </tr>
                <tr>
                  <td className={classes.tableRowFieldTitle}>
                    <span>Description:</span>
                  </td>
                  <td className={classes.tableRowFieldData}>
                    <Field fullWidth name="description" component={TextField} type="text" className={classes.tableRowFieldInput} />
                  </td>
                </tr>
                <tr>
                  <td className={classes.tableRowFieldTitle}>
                    <span>Port of Loading:</span>
                  </td>
                  <td className={classes.tableRowFieldData}>
                    <Field name={"loading"} component={MaterialCheckBoxComponent} type="checkbox" style={{ marginLeft: "-18px" }} />
                  </td>
                </tr>
              </tbody>
            </table>
            <div style={{ display: "flex", justifyContent: "flex-end", margin: "20px 0px 0px 0px", paddingTop: "5px", paddingBottom: "5px" }}>
              <Button style={{ marginRight: "10px" }} color="secondary" variant="outlined" onClick={onClose}>
                <Close />
              </Button>
              <Button disabled={!dirty} style={{ marginRight: "16px" }} type="submit" color="primary" variant="outlined">
                <Check />
              </Button>
            </div>
          </form>
        )}
      />
      {message && (
        <DialogInformation isOpen={true} handleClose={handleInformationClose} handleOK={handleInformationClose} title={"Feedback"} body={`${message}`} showinput={false} />
      )}
    </div>
  );
};

export default withStyles(styles)(PortDetailUnstyled);
