import React, { useEffect } from "react";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";

import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import Slide from "@material-ui/core/Slide";
import Paper from "@material-ui/core/Paper";
import Checkbox, { CheckboxProps } from "@material-ui/core/Checkbox";
import Tooltip from "@material-ui/core/Tooltip";

import IconClose from "@material-ui/icons/Close";
import IconCheck from "@material-ui/icons/Check";
import IconPrint from "@material-ui/icons/Print";
import IconDownload from "@material-ui/icons/PhotoLibrary";
import IconBlock from "@material-ui/icons/Block";
import IconMail from "@material-ui/icons/Mail";

import { format } from "date-fns";
import toDate from "date-fns/toDate";
import parseISO from "date-fns/parseISO";

import { ordersFindByInspectionId } from "../lib/api/inspectionorders";
import { actionsFindByInspectionId } from "../lib/api/inspectionactions";
import { notify } from "../lib/api/notify";

import { handlePrint } from "../reports/inspectionPDF";
import JSZip from "jszip";
import filesaver from "file-saver";
import LZString from "lz-string";
import { isNullOrUndefined } from "util";
import Confirmation from "../lib/components/confirmation";
import NotificationsAddNewForm from "../notifications/notificationsaddnewform";

const red = "#FF0000";
const green = "#00a700";

const styles = (theme: Theme) => {
  return createStyles({
    root: {
      padding: theme.spacing(1),
    },
    appBar: {
      position: "relative",
    },
    title: {
      color: "white",
      marginLeft: theme.spacing(1) * 2,
    },
    table: {
      margin: theme.spacing(1) * 2,
    },
    print: {
      margin: theme.spacing(1) * 1,
    },
  });
};

const PrintCheckbox = withStyles({
  root: {
    display: "flex",
    padding: 0,
    marginRight: "4px",
    color: "#F44336", // Red: Excluded
    "&$checked": {
      color: "#388E3C", // Green: Included
    },
  },
  checked: {},
})((props: CheckboxProps) => <Checkbox color="default" {...props} />);

type InspectionsFullProps = {
  isOpen: boolean;
  handleClose: () => void;
  inspection: any;
} & WithStyles<typeof styles>;

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

const InspectionsFullUnstyled: React.SFC<InspectionsFullProps> = (props) => {
  const { isOpen, handleClose, inspection, classes } = props;

  const [orders, setOrders] = React.useState([]);
  const [actions, setActions] = React.useState([]);

  const [checkedOrdersSummary, setCheckedOrderSummary] = React.useState({});
  const [checkedOrdersDetail, setCheckedOrdersDetail] = React.useState({});
  const [checkedActions, setCheckedActions] = React.useState({});
  const [checkedImages, setCheckedImages] = React.useState({});

  const [notification, setNotification] = React.useState(undefined);

  useEffect(() => {
    const loadData = async () => {
      await loadInspectionOrders();
      await loadInspectionActions();
    };
    loadData();
  }, [inspection]);

  const loadInspectionOrders = async () => {
    ordersFindByInspectionId(inspection.id).then((data) => {
      setOrders(data);
    });
  };

  const loadInspectionActions = async () => {
    actionsFindByInspectionId(inspection.id).then((data) => {
      setActions(data);
    });
  };

  const handlePrintPDF = async () => {
    const printFilters = {
      checkedOrdersSummary: checkedOrdersSummary,
      checkedOrdersDetail: checkedOrdersDetail,
      checkedActions: checkedActions,
      checkedImages: checkedImages,
    };

    handlePrint(inspection, false, printFilters); //, orders, actions, variety, week);
  };

  const handleDownloadImages = () => {
    const zip = new JSZip();
    actions.map((action) => {
      if (action.images.toString().length === 0) {
        return false;
      }
      if (action.images.constructor !== Array) {
        action.images = JSON.parse(action.images);
      }

      action.images.map((imgB64String, idx) => {
        const imgB64StringDCompressed = imgB64String.base64
          ? imgB64String.base64.includes("base64")
            ? imgB64String.base64
            : LZString.decompress(imgB64String.base64)
          : imgB64String.includes("base64")
          ? imgB64String
          : LZString.decompress(imgB64String);

        // const  = imgB64String.includes("base64") ? imgB64String : LZString.decompress(imgB64String);
        const imageName = `${inspection.piorder_ordernum}_${action.field.toUpperCase()}_${idx}_${inspection.number}.${imgB64StringDCompressed
          .toString()
          .substring(imgB64StringDCompressed.indexOf("/") + 1, imgB64StringDCompressed.indexOf(";"))}`;
        zip.file(imageName, imgB64StringDCompressed.split("base64,").pop().toString(), { base64: true });
      });
    });
    const fileName = `Inspection_Images_${inspection.number}.zip`;
    zip.generateAsync({ type: "blob", mimeType: "application/zip" }).then(function (content) {
      filesaver.saveAs(new Blob([content], { type: "application/zip" }), fileName);
    });
  };

  const handleOrderSummaryChecked = (event, order) => {
    setCheckedOrderSummary({ ...checkedOrdersSummary, [order.id]: event.target.checked });
  };

  const handleOrdersDetailChecked = (event, order) => {
    setCheckedOrdersDetail({ ...checkedOrdersDetail, [order.id]: event.target.checked });
  };

  const handleActionsChecked = (event, action) => {
    setCheckedActions({ ...checkedActions, [action.id]: event.target.checked });
  };

  const handleImagesChecked = (event, image) => {
    setCheckedImages({ ...checkedImages, [image]: event.target.checked });
  };

  const handleNotification = () => {
    setNotification(inspection.number);
  };

  const handleNotificationConfirm = (e, item) => {
    setNotification(undefined);
    const newItem = { data: { ...item, type: 1, typeref: inspection.number } };
    notify.create(newItem);
  };

  const handleNotificationClose = () => {
    setNotification(undefined);
  };

  return (
    <div className={classes.root}>
      {notification && (
        <Confirmation isOpen={true} handleClose={handleNotificationClose} handleConfirm={() => {}} title={"Creating Notification"} body={undefined}>
          <NotificationsAddNewForm reloadData={() => {}} onClose={handleNotificationClose} onSubmit={handleNotificationConfirm} locked={true} />
        </Confirmation>
      )}
      <Dialog fullScreen open={isOpen} onClose={handleClose}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton color="inherit" onClick={handleClose} aria-label="Close">
              <IconClose />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              Inspection Summary
            </Typography>

            <IconButton color="inherit" onClick={handlePrintPDF} className={classes.print} aria-label="Print">
              <IconPrint />
            </IconButton>

            <IconButton color="inherit" onClick={handleDownloadImages} className={classes.print} aria-label="Download zipped images">
              <IconDownload />
            </IconButton>
            <IconButton color="inherit" onClick={handleNotification} className={classes.print} aria-label="Create Notification">
              <IconMail />
            </IconButton>
          </Toolbar>
        </AppBar>
        <table className={classes.table}>
          <tbody>
            {tableRow("Instruction: ", inspection.piorder_ordernum)}
            {tableRow("Producer: ", inspection.producer_name)}
            {tableRow("PUC: ", inspection.puc_code)}
            {tableRow("Orchard: ", inspection.orchard_code)}
            {tableRow("Pick Ref: ", inspection.pickref)}
            {tableRow("Special Market: ", inspection.specialmarket == 0 ? <IconClose style={{ color: red }} /> : <IconCheck style={{ color: green }} />)}
            {tableRow("Packed Market: ", inspection.packedmarket)}
            {tableRow("Packed Country: ", inspection.packedcountry)}
            {tableRow("Lab Test: ", inspection.labtest == 0 ? <IconClose style={{ color: red }} /> : inspection.labtest == 1 ? <IconCheck style={{ color: green }} /> : "N/A")}
            {tableRow(
              "Internals: ",
              `${inspection.brix} : ${inspection.acid}  -  ${!isNaN(inspection.brix / inspection.acid) ? (inspection.brix / inspection.acid).toFixed(2) : 0}`,
            )}
            {tableRow("Juice: ", `${inspection.juice} %`)}
            {tableRow("Facility: ", inspection.facility == 0 ? <IconClose style={{ color: red }} /> : <IconCheck style={{ color: green }} />)}
            {tableRow("Degreen: ", inspection.degreen == 0 ? <IconClose style={{ color: red }} /> : inspection.degreen == 1 ? <IconCheck style={{ color: green }} /> : "N/A")}
            {tableRow("PH Treatment: ", inspection.postharvesttreatment == 0 ? <IconClose style={{ color: red }} /> : <IconCheck style={{ color: green }} />)}
            {tableRow("PH Labels: ", inspection.postharvestlabel == 0 ? <IconClose style={{ color: red }} /> : <IconCheck style={{ color: green }} />)}
            {tableRow(
              "Consignee Label: ",
              inspection.labelconsignee == 0 ? <IconClose style={{ color: red }} /> : inspection.labelconsignee == 1 ? <IconCheck style={{ color: green }} /> : "N/A",
            )}
            {tableRow(
              "Country Label: ",
              inspection.labelcountry == 0 ? <IconClose style={{ color: red }} /> : inspection.labelcountry == 1 ? <IconCheck style={{ color: green }} /> : "N/A",
            )}
            {tableRow("Inspection Date: ", format(toDate(Number(inspection.inspectiondate)), "dd-MMM-yyyy HH:mm"))}
            {tableRow("Completed Date: ", format(parseISO(inspection.created_at), "dd-MMM-yyyy HH:mm"))}
          </tbody>
        </table>
        <div>
          {orders.map((order) => {
            return tableRowOrder(order, actions, handleOrderSummaryChecked, { ...checkedOrdersSummary, [order.id]: !!checkedOrdersSummary[order.id] });
          })}
        </div>
        <div>
          {tableRowOrderActionsMain(inspection, actions, handleActionsChecked, checkedActions, handleImagesChecked, checkedImages)}
          {orders.map((order) => {
            return tableRowOrderActions(
              order,
              actions,
              handleOrdersDetailChecked,
              { ...checkedOrdersDetail, [order.id]: !!checkedOrdersDetail[order.id] },
              handleActionsChecked,
              checkedActions,
              handleImagesChecked,
              checkedImages,
            );
          })}
        </div>
      </Dialog>
    </div>
  );
};

export default withStyles(styles)(InspectionsFullUnstyled);

const tableRow = (label, value) => {
  // const cellWidth = (value == "NO" || value == "YES") ? "60px" : "50%";
  return (
    <tr>
      <td style={{ textAlign: "right", width: "150px" }}>
        <Typography>{label}</Typography>
      </td>
      <td style={{ textAlign: "left", paddingLeft: "5px" }}>{value}</td>
    </tr>
  );
};

const tableRowBit = (label, value) => {
  const valueColor = value == 0 ? "red" : value == 1 ? "green" : "gray";
  return (
    <>
      <td style={{ width: "110px" }}>
        <Typography variant="subtitle2">{label}</Typography>
        <div style={{ textAlign: "center", width: "50px", backgroundColor: valueColor }}>{value == 0 ? <IconClose /> : value == 1 ? <IconCheck /> : <IconBlock />}</div>
      </td>
    </>
  );
};

const tableRowValue = (label, value) => {
  const valueColor = value == 1 ? "red" : value == 2 ? "orange" : value == 3 ? "green" : "gray";
  return (
    <>
      <td key={`${label}${value}`} style={{ width: "110px" }}>
        <Typography variant="subtitle2">{label}</Typography>
        <div style={{ textAlign: "center", width: "50px", height: "30px", backgroundColor: valueColor }}>{value ? value : 0}</div>
      </td>
    </>
  );
};

const tableRowActions = (action, detail = undefined, isBitField, handleActionsChecked, checkedActions, handleImagesChecked, checkedImages, orderExcluded = undefined) => {
  if (action) {
    if (action.images.toString().length > 0 && action.images.constructor !== Array) {
      action.images = JSON.parse(action.images);
    }
    isNullOrUndefined(checkedActions[action.id]) ? (checkedActions[action.id] = true) : undefined;
    const valueColor = !detail ? undefined : detail[action.field] == 1 ? "red" : detail[action.field] == 2 ? "orange" : detail[action.field] == 3 ? "green" : "gray";
    let checkTooltip = `${!checkedActions[action.id] ? "Excluded" : "Included"}, click to ${!checkedActions[action.id] ? "include in" : "exclude from"} print PDF`;
    return (
      <table style={{ display: "flex", width: "100%", background: !checkedActions[action.id] ? "#f0f0f0" : "" }}>
        <tbody>
          <tr>
            <td style={{ height: "100px" }}>
              <div style={{ float: "left", display: "inline" }}>
                {isBitField && detail[action.field] == 0 && <IconClose style={{ marginBottom: "-7px", width: 60, height: 60, color: red }} />}
                {isBitField && detail[action.field] == 1 && <IconCheck style={{ marginBottom: "-7px", width: 60, height: 60, color: green }} />}
                {isBitField && detail[action.field] == 2 && (
                  <div style={{ verticalAlign: "middle", textAlign: "center", height: "50px", marginRight: "5px", width: "55px", backgroundColor: valueColor }}>
                    <div style={{ paddingTop: "15px" }}>N/A</div>
                  </div>
                )}

                {!isBitField && (
                  <div style={{ verticalAlign: "middle", textAlign: "center", height: "50px", marginRight: "5px", width: "55px", backgroundColor: valueColor }}>
                    <div style={{ paddingTop: "15px" }}>{detail[action.field]}</div>
                  </div>
                )}
              </div>
              <div style={{ float: "left", display: "inline" }}>
                <div style={{ display: "flex" }}>
                  {!orderExcluded && (
                    <Tooltip title={checkTooltip}>
                      <PrintCheckbox checked={checkedActions[action.id]} onChange={(e) => handleActionsChecked(e, action)} icon={<IconPrint />} checkedIcon={<IconPrint />} />
                    </Tooltip>
                  )}
                  <Typography variant="subtitle1" style={{ fontWeight: "bold" }}>
                    {action.field.toString().toUpperCase()}
                    <br />
                  </Typography>
                </div>
                <span style={{ fontSize: "12px" }}>Comment: {action.comments}</span>
              </div>
            </td>
          </tr>

          <tr key={`images${action.id}`} style={{ textAlign: "left", display: "inline" }}>
            <td colSpan={9}>
              {action.images &&
                action.images.length > 0 &&
                action.images.map((image, idx) => {
                  const imageBaseString = image.base64 ? image.base64 : image;
                  isNullOrUndefined(checkedImages[imageBaseString]) ? (checkedImages[imageBaseString] = true) : undefined;
                  return (
                    <div
                      key={`imagelist${idx}`}
                      style={{
                        display: "inline-block",
                        marginLeft: "5px",
                        padding: "4px",
                        background: !checkedImages[imageBaseString] || !checkedActions[action.id] || orderExcluded ? "#e2e2e2" : "",
                      }}
                    >
                      <img
                        style={{ filter: !checkedImages[imageBaseString] || !checkedActions[action.id] || orderExcluded ? "grayscale(100%)" : "" }}
                        key={`${image}image${idx}`}
                        src={imageBaseString.includes("base64") ? imageBaseString : LZString.decompress(imageBaseString)}
                        alt="captured"
                        width="150px"
                      />
                      {(isNullOrUndefined(orderExcluded) || orderExcluded == false) && checkedActions[action.id] && (
                        <Tooltip title={checkTooltip} style={{ height: "26px" }}>
                          <PrintCheckbox
                            checked={checkedImages[imageBaseString]}
                            onChange={(e) => handleImagesChecked(e, imageBaseString)}
                            icon={<IconPrint />}
                            checkedIcon={<IconPrint />}
                            style={{ height: "26px", width: "26px", marginTop: "-29px", marginLeft: "82%", background: "#ffffff" }}
                          />
                        </Tooltip>
                      )}
                    </div>
                  );
                })}
            </td>
          </tr>
        </tbody>
      </table>
    );
  } else {
    return undefined;
  }
};

const returnOrderActionByField = (actions, piinstruction_id, field) => {
  const actionFind = actions.find((act) => act.field == (field ? field : act.field) && act.piinstruction_id == piinstruction_id);
  return actionFind;
};

const tableRowOrder = (order, actions, handleOrdersSummarisedChecked, checkedOrdersSummarised) => {
  let checkTooltip = `${!checkedOrdersSummarised[order.id] ? "Excluded" : "Included"}, click to ${!checkedOrdersSummarised[order.id] ? "include in" : "exclude from"} print PDF`;
  return (
    <Paper key={order.id} style={{ paddingLeft: "5px", background: !checkedOrdersSummarised[order.id] ? "#f0f0f0" : "" }}>
      <div style={{ display: "flex" }}>
        <Tooltip title={checkTooltip}>
          <PrintCheckbox
            checked={checkedOrdersSummarised[order.id]}
            onChange={(e) => handleOrdersSummarisedChecked(e, order, actions)}
            icon={<IconPrint />}
            checkedIcon={<IconPrint />}
          />
        </Tooltip>
        <Typography variant="h6" gutterBottom>
          <div key={`${order.id}${actions.field}`}>
            {`${order.target_market ? " | " + order.target_market : ""}
              ${order.target_country ? " | " + order.target_country : ""}
              ${order.pack_carton ? " | " + order.pack_carton : ""}
              ${order.pack_code ? " | " + order.pack_code : ""}
              ${order.count ? " | " + order.count.split("/").join(" | ") : ""}
              ${order.class ? " | " + order.class : ""}
              ${order.inventory_description ? " | " + order.inventory_description : ""}
              ${order.inventory ? " | " + order.inventory : ""}
            `}
          </div>
        </Typography>
      </div>
      <table key={`bits${order.id}`} style={{ paddingBottom: "10px" }}>
        <tbody>
          <tr>
            {tableRowBit("FRUIT SPEC", order.fruitspec)}
            {tableRowBit("WEIGHT", order.weight)}
            {tableRowBit("GENERAL", order.general)}
          </tr>
          <tr>
            {tableRowBit("FIRMNESS", order.firmness)}
            {tableRowBit("BUTTONS", order.buttons)}
            {tableRowValue("COLOR", order.color)}
          </tr>
          <tr>
            {tableRowValue("BLEMISH", order.blemish)}
            {tableRowValue("TEXTURE", order.skintexture)}
            {tableRowValue("GRANULATION", order.granulation)}
          </tr>
          <tr>
            {tableRowBit("GRADING", order.grading)}
            {tableRowBit("SIZING", order.sizing)}
          </tr>
        </tbody>
      </table>
      <table>
        <tbody>
          <tr>
            <td>
              <br />
              <Typography variant="subtitle2">{`PACKED MARKET : `}</Typography>
            </td>
            <td>
              <br />
              <Typography variant="subtitle2">{`${order.proposedmarket}`}</Typography>
            </td>
          </tr>
          <tr>
            <td>
              <br />
              <Typography variant="subtitle2">{`PACKED COUNTRY : `}</Typography>
            </td>
            <td>
              <br />
              <Typography variant="subtitle2">{`${order.proposedcountry}`}</Typography>
            </td>
          </tr>
          <tr>
            <td>
              <br />
            </td>
          </tr>
        </tbody>
      </table>
    </Paper>
  );
};

const tableRowOrderActionsMain = (inspection, actions, handleActionsChecked, checkedActions, handleImagesChecked, checkedImages) => {
  return (
    <Paper key={`mainOrderActions`} style={{ paddingLeft: "5px" }}>
      {actions.map((action) => {
        isNullOrUndefined(checkedActions[action.id]) ? (checkedActions[action.id] = true) : undefined;
        if (action.piinstruction_id == 0) {
          return (
            <div key={`bits${action.id}`} style={{ paddingBottom: "10px", display: "flex", width: "100%", background: !checkedActions[action.id] ? "#f0f0f0" : "" }}>
              {tableRowActions(action, inspection, true, handleActionsChecked, checkedActions, handleImagesChecked, checkedImages)}
            </div>
          );
        }
      })}
    </Paper>
  );
};

const tableRowOrderActions = (order, actions, handleOrdersDetailChecked, checkedOrdersDetail, handleActionsChecked, checkedActions, handleImagesChecked, checkedImages) => {
  let checkTooltip = `${!checkedOrdersDetail[order.id] ? "Excluded" : "Included"}, click to ${!checkedOrdersDetail[order.id] ? "include in" : "exclude from"} print PDF`;
  return (
    <Paper key={order.id} style={{ paddingLeft: "5px", background: !checkedOrdersDetail[order.id] ? "#f0f0f0" : "" }}>
      {returnOrderActionByField(actions, order.piinstruction_id, undefined) && (
        <>
          <div style={{ display: "flex" }}>
            <Tooltip title={checkTooltip}>
              <PrintCheckbox
                checked={checkedOrdersDetail[order.id]}
                onChange={(e) => handleOrdersDetailChecked(e, order, actions)}
                icon={<IconPrint />}
                checkedIcon={<IconPrint />}
              />
            </Tooltip>
            <Typography variant="h6" gutterBottom>
              <div key={order.id}>
                {actions.length > 0 &&
                  `${order.target_market ? " | " + order.target_market : ""}${order.target_country ? " | " + order.target_country : ""}${
                    order.pack_carton ? " | " + order.pack_code : ""
                  }${order.pack_code ? " | " + order.pack_code : ""}${order.count ? " | " + order.count.split("/").join(" | ") : ""}${order.class ? " | " + order.class : ""}${
                    order.inventory_description ? " | " + order.inventory_description : ""
                  }`}
              </div>
            </Typography>
          </div>

          <>
            {tableRowActions(
              returnOrderActionByField(actions, order.piinstruction_id, "fruitspec"),
              order,
              true,
              handleActionsChecked,
              checkedActions,
              handleImagesChecked,
              checkedImages,
              !checkedOrdersDetail[order.id],
            )}
          </>
          <>
            {tableRowActions(
              returnOrderActionByField(actions, order.piinstruction_id, "inventory"),
              order,
              true,
              handleActionsChecked,
              checkedActions,
              handleImagesChecked,
              checkedImages,
              !checkedOrdersDetail[order.id],
            )}
          </>
          <>
            {tableRowActions(
              returnOrderActionByField(actions, order.piinstruction_id, "weight"),
              order,
              true,
              handleActionsChecked,
              checkedActions,
              handleImagesChecked,
              checkedImages,
              !checkedOrdersDetail[order.id],
            )}
          </>
          <>
            {tableRowActions(
              returnOrderActionByField(actions, order.piinstruction_id, "labelcarton"),
              order,
              true,
              handleActionsChecked,
              checkedActions,
              handleImagesChecked,
              checkedImages,
              !checkedOrdersDetail[order.id],
            )}
          </>
          <>
            {tableRowActions(
              returnOrderActionByField(actions, order.piinstruction_id, "general"),
              order,
              true,
              handleActionsChecked,
              checkedActions,
              handleImagesChecked,
              checkedImages,
              !checkedOrdersDetail[order.id],
            )}
          </>
          <>
            {tableRowActions(
              returnOrderActionByField(actions, order.piinstruction_id, "firmness"),
              order,
              true,
              handleActionsChecked,
              checkedActions,
              handleImagesChecked,
              checkedImages,
              !checkedOrdersDetail[order.id],
            )}
          </>
          <>
            {tableRowActions(
              returnOrderActionByField(actions, order.piinstruction_id, "buttons"),
              order,
              true,
              handleActionsChecked,
              checkedActions,
              handleImagesChecked,
              checkedImages,
              !checkedOrdersDetail[order.id],
            )}
          </>
          <>
            {tableRowActions(
              returnOrderActionByField(actions, order.piinstruction_id, "color"),
              order,
              false,
              handleActionsChecked,
              checkedActions,
              handleImagesChecked,
              checkedImages,
              !checkedOrdersDetail[order.id],
            )}
          </>
          <>
            {tableRowActions(
              returnOrderActionByField(actions, order.piinstruction_id, "blemish"),
              order,
              false,
              handleActionsChecked,
              checkedActions,
              handleImagesChecked,
              checkedImages,
              !checkedOrdersDetail[order.id],
            )}
          </>
          <>
            {tableRowActions(
              returnOrderActionByField(actions, order.piinstruction_id, "skintexture"),
              order,
              false,
              handleActionsChecked,
              checkedActions,
              handleImagesChecked,
              checkedImages,
              !checkedOrdersDetail[order.id],
            )}
          </>
          <>
            {tableRowActions(
              returnOrderActionByField(actions, order.piinstruction_id, "granulation"),
              order,
              false,
              handleActionsChecked,
              checkedActions,
              handleImagesChecked,
              checkedImages,
              !checkedOrdersDetail[order.id],
            )}
          </>
          <>
            {tableRowActions(
              returnOrderActionByField(actions, order.piinstruction_id, "grading"),
              order,
              true,
              handleActionsChecked,
              checkedActions,
              handleImagesChecked,
              checkedImages,
              !checkedOrdersDetail[order.id],
            )}
          </>
          <>
            {tableRowActions(
              returnOrderActionByField(actions, order.piinstruction_id, "shape"),
              order,
              true,
              handleActionsChecked,
              checkedActions,
              handleImagesChecked,
              checkedImages,
              !checkedOrdersDetail[order.id],
            )}
          </>
          <>
            {tableRowActions(
              returnOrderActionByField(actions, order.piinstruction_id, "sizing"),
              order,
              true,
              handleActionsChecked,
              checkedActions,
              handleImagesChecked,
              checkedImages,
              !checkedOrdersDetail[order.id],
            )}
          </>
        </>
      )}
    </Paper>
  );
};
