import React from "react";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";

import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";

import Grid from "../../lib/components/grid";
import { DialogInformation } from "../../lib/components/dialoginformation";

import { SaleBarcodesByDispatchIDType } from "../../lib/api/sale";

import { GridColumnsSelected } from "./creditorsinfodetailgridsetupselected";

import { Row } from "react-data-grid";

import { SnackContext } from "../../lib/context/SnackContext";
import Toolbar from "@material-ui/core/Toolbar";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      height: "100%",
    },
    container: {
      display: "grid",
      gridTemplateColumns: "1fr",
    },
    gridWrapper: {
      position: "relative",
    },
    gridButtons: {
      position: "absolute",
      width: "calc(100% - 450px)",
      height: "68px",
      display: "flex",
      alignItems: "center",
      marginLeft: "5px",
      justifyContent: "space-between",
    },
    gridButtonsLeft: {
      height: "100%",
      display: "flex",
      alignItems: "center",
    },
    totalRowStyle: {
      background: "#ddd",
      textAlign: "right",
      fontWeight: "bold",
      color: "black",
    },
    totalRowStyleFixed: {
      position: "sticky",
      bottom: 0,
      background: "#ddd",
      textAlign: "right",
      fontWeight: "bold",
      color: "black",
    },
    lastRowStyle: {
      display: "flex",
      flexDirection: "column-reverse",
      color: "black",
    },
    normalRow: {
      color: "black",
      fontWeight: "normal",
    },
    toolbar: {
      position: "absolute",
      left: 0,
      top: 0,
      height: "60px",
      width: "100%",
      display: "flex",
      flexDirection: "row",
      gap: "10px",
    },
  });

type CreditorsInfoDetailProps = {
  gridHeight: number;
  creditorsinvoiceid: number;
  selectedDetailRecords: any[];
  dataDetailItems: any[];
  setGridHeights: () => void;
  handleGroupedChanged: (value: boolean) => void;
  handleDetailChange: (detail: [SaleBarcodesByDispatchIDType], group: number) => void;
} & WithStyles<typeof styles>;

export class CreditorsInfoDetailUnstyled extends React.Component<CreditorsInfoDetailProps, any> {
  state = {
    classes: undefined,
    message: undefined,
    creditorsinvoiceid: undefined,
    handleDetailChange: undefined,
    loadManualBarcodes: undefined,
    selectedDetailFilterItem: "creditorsinfoselecteddetailfilter",
    handleGroupedChanged: undefined,
    showAddBarcodesModal: false,
  };

  constructor(props) {
    super(props);
    this.state.classes = props.classes;
    this.state.creditorsinvoiceid = props.creditorsinvoiceid;
    this.state.handleDetailChange = props.handleDetailChange;
    this.state.handleGroupedChanged = props.handleGroupedChanged;
  }

  static contextType = SnackContext;
  context!: React.ContextType<typeof SnackContext>;

  handleInformationClose = () => {
    this.setState({ message: undefined });
  };

  handleUnSelectRecord = (row: SaleBarcodesByDispatchIDType) => {
    const newSelectedRecords = [];
    this.props.selectedDetailRecords.map((filterRecord) => {
      if (filterRecord.group != row.group) {
        newSelectedRecords.push(filterRecord);
      } else {
        if (filterRecord.barcode_id != row.barcode_id) {
          newSelectedRecords.push(filterRecord);
        }
      }
    });
    this.state.handleDetailChange(newSelectedRecords);
  };

  handleClearSelectedDetail = () => {
    this.state.handleDetailChange([]);
  };

  selectedRowRenderer = (props) => {
    const { row } = props;
    return <Row {...props} onDoubleClickCapture={() => this.handleUnSelectRecord(row)} />;
  };

  render() {
    const { classes } = this.state;

    return (
      <div className={classes.root} id="secondary_grid">
        <div className={classes.container}>
          <div className={classes.gridWrapper}>
            <Toolbar className={classes.toolbar}>
              <Button variant="contained" color="primary" onClick={this.handleClearSelectedDetail} disabled={this.props.selectedDetailRecords.length === 0}>
                Clear All
              </Button>
            </Toolbar>
            <Grid
              showFilterChips={false}
              GridColumns={GridColumnsSelected}
              forceHeight={this.props.gridHeight}
              clearFilters={this.state.selectedDetailFilterItem}
              data={this.props.selectedDetailRecords}
              rowRenderer={this.selectedRowRenderer}
              totalRowColumns={["no_cartons", "pallet_size", "totalpercarton", "totalperpallet", "totalpercarton_exvat", "totalperpallet_exvat"]}
            />
          </div>
        </div>
        {this.state.message && (
          <DialogInformation
            isOpen={true}
            handleClose={this.handleInformationClose}
            handleOK={this.handleInformationClose}
            title={"Feedback"}
            body={`${this.state.message}`}
            showinput={false}
          />
        )}
      </div>
    );
  }
}

export default withStyles(styles)(CreditorsInfoDetailUnstyled);
