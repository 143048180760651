import React from "react";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";

import Grid from "../../lib/components/grid";
import { GridColumns } from "./gridsetup";
import { InvoicesForCombinedType } from "../../lib/api/sale";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1),
      width: "100%",
      height: "100%",
      position: "relative",
    },
  });

type CombinedInvoicesGridProps = {
  loading: boolean;
  selectedInvoices: string[];
  data: InvoicesForCombinedType[];
  setSelectedInvoices(selectedInvoices: string): void;
} & WithStyles<typeof styles>;

class CombinedInvoicesGridUnstyled extends React.Component<CombinedInvoicesGridProps, any> {
  constructor(props) {
    super(props);
  }

  GridColumnsOverride = (data, filters, arrangement, columnsWidth) => {
    return GridColumns(data, filters, arrangement, columnsWidth, this.props.selectedInvoices, this.props.setSelectedInvoices);
  };

  render() {
    return <Grid loading={this.props.loading} data={this.props.data} GridColumns={this.GridColumnsOverride} clearFilters="combinedinvoicesgrid" forceHeight={500} />;
  }
}

export default withStyles(styles)(CombinedInvoicesGridUnstyled);
