import React from "react";

import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";
import Checkbox from "@material-ui/core/Checkbox";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      height: "100%",
    },
  });

type CheckboxSelectProps = {
  row: any;
  classes: any;
  valueKey: string;
  selectedRows: any[];
  handleCheckboxSelect: (row: any) => void;
};

const CheckboxSelectUnstyled: React.FC<CheckboxSelectProps> = ({ classes, row, selectedRows, handleCheckboxSelect, valueKey }) => {
  const checked = React.useMemo(() => {
    return Boolean(selectedRows.find((item) => item[valueKey] == row[valueKey]));
  }, [selectedRows]);

  return (
    <div className={classes.root}>
      <Checkbox color="default" checked={checked} onChange={() => handleCheckboxSelect(row)} inputProps={{ "aria-label": "primary checkbox" }} />
    </div>
  );
};

export const CheckboxSelect = withStyles(styles)(CheckboxSelectUnstyled);
