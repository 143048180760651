import React from "react";

import DeleteIcon from "@material-ui/icons/Delete";

import CustomAutoCompleteFilter from "../../lib/components/customdatagridfilter/CustomFilter";
import { CellActionsFormatter } from "../../lib/helpers/CellActionsFormatter";
import { handleColumnsArrangement, handleColumnsWidth } from "../../lib/helpers/grid";
import { TextEditor } from "react-data-grid";
import { FormatterNumber2Decimals, RightAlignColumnHeader } from "../../lib/components/Formatters";
import { SummaryNumber2Decimal } from "../../lib/components/SummaryFormatter";

const cellWidthSmall = 80;
const cellWidthSmallBig = 140;
const cellWidthMedium = 220;
const cellWidthLarge = 350;

const defaultColumnProperties = {
  resizable: true,
  sortable: true,
};

let currentSpace = true;
export const GridColumns = (data, filters, arrangement = undefined, columnsWidth = undefined, handleRemove) => {
  let postValue = "";

  if (data) {
    if (currentSpace) {
      postValue = " ";
    }
    currentSpace = !currentSpace;
  }

  const columns = [
    {
      key: "actions",
      name: "Actions",
      width: cellWidthSmall,
      formatter: ({ row }) => {
        const actions = cellActions(row, handleRemove);
        return (
          <div style={{ display: "flex", justifyContent: "center", gap: "5px" }}>
            <CellActionsFormatter actions={actions} />
          </div>
        );
      },
    },
    {
      key: "description",
      name: "Description" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"description"} data={data} section={"adhoc_invoice_detail"} />;
      },
      editable: true,
      editor: (props) => <TextEditor {...props} />,
    },
    {
      key: "quantity",
      name: "Quantity" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"quantity"} data={data} section={"adhoc_invoice_detail"} />;
      },
      editable: true,
      editor: (props) => <TextEditor {...props} align="right" />,
      formatter: FormatterNumber2Decimals,
      headerRenderer: RightAlignColumnHeader,
    },
    {
      key: "unit_price",
      name: "Unit Price" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"unit_price"} data={data} section={"adhoc_invoice_detail"} />;
      },
      editable: true,
      editor: (props) => <TextEditor {...props} align="right" />,
      formatter: FormatterNumber2Decimals,
      headerRenderer: RightAlignColumnHeader,
    },
    {
      key: "sub_total",
      name: "Subtotal" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"sub_total"} data={data} section={"adhoc_invoice_detail"} />;
      },
      formatter: FormatterNumber2Decimals,
      headerRenderer: RightAlignColumnHeader,
      summaryFormatter: SummaryNumber2Decimal,
    },
  ].map((c) => ({ ...c, ...defaultColumnProperties }));

  let columnsArranged = [];
  columnsArranged = handleColumnsArrangement(columnsArranged, arrangement, columns);
  columnsArranged = handleColumnsWidth(columnsArranged, columnsWidth, columns);

  return columnsArranged;
};

const cellActions = (adhocDetail, handleRemove) => {
  return [
    {
      icon: (
        <span title="Remove">
          <DeleteIcon style={{ cursor: "pointer", color: "red" }} />
        </span>
      ),
      callback: (e) => {
        handleRemove(adhocDetail.id);
      },
    },
  ];
};
