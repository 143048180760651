import React from "react";

import CustomAutoCompleteFilter from "../../lib/components/customdatagridfilter/CustomFilter";

import { DateFormatter, FormatterNumber2Decimals } from "../../lib/components/Formatters";
import { SummaryNumber2Decimal } from "../../lib/components/SummaryFormatter";
import { handleColumnsArrangement, handleColumnsWidth } from "../../lib/helpers/grid";
import RightAlignHeaderColumn from "../../lib/components/gridhelper/RightAlignHeaderColumn";

const cellWidthAction = 50;
const cellWidthSmall = 100;
const cellWidthMedium = 130;
const cellWidthLarge = 200;

let currentSpace = true;

export const GridColumns = (data, filters, arrangement, columnsWidth) => {
  let postValue = "";

  if (data) {
    if (currentSpace) {
      postValue = " ";
    }
    currentSpace = !currentSpace;
  }

  const columns = [
    {
      key: "invoicenumber",
      name: "Invoice" + postValue,
      width: cellWidthSmall,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"invoicenumber"} data={data} section="allocationlist" />;
      },
    },
    {
      key: "posteddate",
      name: "Posted Date" + postValue,
      width: cellWidthSmall,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"posteddate"} data={data} section="allocationlist" />;
      },
      formatter: DateFormatter,
    },
    {
      key: "due_date",
      name: "Due Date" + postValue,
      width: cellWidthSmall,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"due_date"} data={data} section="allocationlist" />;
      },
      formatter: DateFormatter,
    },
    {
      key: "due_amount",
      name: "Due Amount" + postValue,
      width: cellWidthSmall,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"due_amount"} data={data} section="allocationlist" />;
      },
      formatter: FormatterNumber2Decimals,
      headerRenderer: RightAlignHeaderColumn,
    },
    {
      key: "amount",
      name: "Received Amount" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"amount"} data={data} section="allocationlist" />;
      },
      formatter: FormatterNumber2Decimals,
      summaryFormatter: SummaryNumber2Decimal,
      headerRenderer: RightAlignHeaderColumn,
    },
    {
      key: "vessel",
      name: "Vessel" + postValue,
      width: cellWidthSmall,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"vessel"} data={data} section="allocationlist" />;
      },
    },
    {
      key: "loadout_etd",
      name: "ETD" + postValue,
      width: cellWidthSmall,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"loadout_etd"} data={data} section="allocationlist" />;
      },
      formatter: DateFormatter,
    },
    {
      key: "loadout_updated_eta",
      name: "Updated ETA" + postValue,
      width: cellWidthSmall,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"loadout_updated_eta"} data={data} section="allocationlist" />;
      },
      formatter: DateFormatter,
    },
    {
      key: "containerno",
      name: "Container No" + postValue,
      width: cellWidthSmall,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"containerno"} data={data} section="allocationlist" />;
      },
    },
  ];

  let columnsArranged = [];
  columnsArranged = handleColumnsArrangement(columnsArranged, arrangement, columns);
  columnsArranged = handleColumnsWidth(columnsArranged, columnsWidth, columns);

  return columnsArranged;
};
