import React from "react";

import DeleteIcon from "@material-ui/icons/Delete";
import IconEdit from "@material-ui/icons/Edit";

import CustomAutoCompleteFilter from "../../lib/components/customdatagridfilter/CustomFilter";
import { CellActionsFormatter } from "../../lib/helpers/CellActionsFormatter";
import { DateFormatter, FormatterNumber2Decimals } from "../../lib/components/Formatters";
import { handleColumnsArrangement, handleColumnsWidth } from "../../lib/helpers/grid";
import { SummaryNumber2Decimal } from "../../lib/components/SummaryFormatter";
import { SaleAdjustmentAdhocFullType } from "../../lib/api/saleadjustmentadhoc";

const cellWidthTiny = 40;
const cellWidthSmall = 80;
const cellWidthMedium = 120;
const cellWidthLarge = 200;

const defaultColumnProperties = {
  resizable: true,
  sortable: true,
};

let currentSpace = true;
export const GridColumns = (data, filters, arrangement, columnsWidth, handleAdjust, handleRemove) => {
  let postValue = "";

  if (data) {
    if (currentSpace) {
      postValue = " ";
    }
    currentSpace = !currentSpace;
  }

  const columns = [
    {
      key: "actions",
      name: "#A",
      width: cellWidthTiny,
      formatter: ({ row }) => {
        const actions = cellActions(row, handleAdjust, handleRemove);
        return (
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "5px", marginTop: "2px" }}>
            <CellActionsFormatter actions={actions} />
          </div>
        );
      },
    },
    {
      key: "clients_code",
      name: "Client Code" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"clients_code"} data={data} section="debtorsfulladhoc" />;
      },
    },
    {
      key: "clients_name",
      name: "Client Name" + postValue,
      width: cellWidthLarge,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"clients_name"} data={data} section="debtorsfulladhoc" />;
      },
    },
    {
      key: "date",
      name: "Date Created" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"date"} data={data} section="debtorsfulladhoc" />;
      },
      formatter: DateFormatter,
    },
    {
      key: "duedate",
      name: "Due Date" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"duedate"} data={data} section="debtorsfulladhoc" />;
      },
      formatter: DateFormatter,
    },
    {
      key: "document_no",
      name: "Document No" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"document_no"} data={data} section="debtorsfulladhoc" />;
      },
    },
    {
      key: "type",
      name: "Type" + postValue,
      width: cellWidthSmall,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"type"} data={data} section="debtorsfulladhoc" />;
      },
    },
    {
      key: "amount",
      name: "Total Amount" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"amount"} data={data} section="debtorsfulladhoc" />;
      },
      formatter: FormatterNumber2Decimals,
      summaryFormatter: SummaryNumber2Decimal,
    },
    {
      key: "available",
      name: "Available for Allocation" + postValue,
      width: cellWidthLarge,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"available"} data={data} section="debtorsfulladhoc" />;
      },
      formatter: FormatterNumber2Decimals,
      summaryFormatter: SummaryNumber2Decimal,
    },
    {
      key: "shipments",
      name: "Shipments Linked" + postValue,
      width: cellWidthLarge,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"shipments"} data={data} section="debtorsfulladhoc" />;
      },
    },
    // {
    //   key: "description",
    //   name: "Description" + postValue,
    //   width: cellWidthLarge,
    //   filterable: true,
    //   filterRenderer: (e) => {
    //     return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"description"} data={data} section="debtorsfulladhoc" />;
    //   },
    // },
  ].map((c) => ({ ...c, ...defaultColumnProperties }));

  let columnsArranged = [];
  columnsArranged = handleColumnsArrangement(columnsArranged, arrangement, columns);
  columnsArranged = handleColumnsWidth(columnsArranged, columnsWidth, columns);

  return columns;
};

const cellActions = (row: SaleAdjustmentAdhocFullType, handleAdjust, handleRemove) => {
  return [
    {
      icon: (
        <span title="Remove Adhoc">
          <DeleteIcon style={{ cursor: "pointer", color: "red" }} />
        </span>
      ),
      callback: () => handleRemove(row.saleadjustmentadhoc_id),
    },
    {
      icon: (
        <span title="Edit Adhoc">
          <IconEdit style={{ cursor: "pointer", color: "orange" }} />
        </span>
      ),
      callback: () => handleAdjust(row.saleadjustmentadhoc_id),
    },
  ];
};
