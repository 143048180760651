import React from "react";

import CustomAutoCompleteFilter from "../../lib/components/customdatagridfilter/CustomFilter";
import format from "date-fns/format";
import { FormatterNumber, FormatterNumber2Decimals } from "../../lib/components/Formatters";

const cellWidthTiny = 50;
const cellWidthSmall = 115;
const cellWidthMedium = 150;
const cellWidthLarge = 200;

const defaultColumnProperties = {
  resizable: true,
  sortable: true,
  filterable: true,
};

let currentSpace = true;

const StatusTypes = {
  New: { style: {}, text: "New" },
  Imported: { style: { color: "orange" }, text: "Imported" },
  Processed: { style: { color: "green" }, text: "Processed" },
  Rejected: { style: { color: "red" }, text: "Rejected" },
};

const StatusCell = (status: string) => {
  const statusVal = StatusTypes[status];
  return <div style={statusVal.style}>{statusVal.text}</div>;
};

const DateFormatter = (date: string, time = false) => {
  let newDate = new Date(date.replace("Z", ""));
  return <div>{time ? format(newDate, "dd MMM yyyy HH:mm") : format(newDate, "dd MMM yyyy")}</div>;
};

export const GridColumns = (data, filters, arrangement, columnsWidth) => {
  let postValueInfo = "";

  if (data) {
    if (currentSpace) {
      postValueInfo = " ";
    }
    currentSpace = !currentSpace;
  }

  const columns = [
    {
      key: "filename",
      name: "Filename" + postValueInfo,
      width: cellWidthMedium,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"filename"} data={data} section={"psfilelist"} />;
      },
    },
    {
      key: "formattedDate",
      name: "Timestamp" + postValueInfo,
      width: cellWidthMedium,
      formatter: ({ row }) => DateFormatter(row.timestamp, true),
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"formattedDate"} data={data} section={"psfilelist"} orderDescending />;
      },
    },
    {
      key: "status",
      name: "Status" + postValueInfo,
      width: cellWidthSmall,
      formatter: ({ row }) => StatusCell(row.status),
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"status"} data={data} section={"psfilelist"} />;
      },
    },
    {
      key: "TotalCartons",
      name: "TotalCartons" + postValueInfo,
      width: cellWidthSmall,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"TotalCartons"} data={data} section={"psfilelist"} />;
      },
      formatter: FormatterNumber,
    },
    {
      key: "TotalPallets",
      name: "TotalPallets" + postValueInfo,
      width: cellWidthSmall,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"TotalPallets"} data={data} section={"psfilelist"} />;
      },
      formatter: FormatterNumber2Decimals,
    },
    {
      key: "locationcode",
      name: "Location Code" + postValueInfo,
      width: cellWidthMedium,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"locationcode"} data={data} section={"psfilelist"} />;
      },
    },
  ].map((c) => ({ ...c, ...defaultColumnProperties }));

  const columnsArranged = [];

  if (arrangement && arrangement.length > 0) {
    arrangement.map((item) => {
      const indexOfItem = columns.indexOf(columns.find((col) => col.name.trim() == item.toString().trim()));
      if (indexOfItem != -1) {
        columnsArranged.push(columns[indexOfItem]);
      }
    });
  } else {
    columns.map((colItem) => {
      columnsArranged.push(colItem);
    });
  }

  if (columnsWidth && columnsWidth.length > 0) {
    for (let index = 0; index < columnsArranged.length; index++) {
      const element = columnsArranged[index];
      const colItem = columnsWidth.find((colwitem) => colwitem.name.trim() == element.name.trim());
      if (colItem) {
        element.width = colItem.width;
      }
    }
  }

  return columnsArranged;
};
