import jsPDF from "jspdf";

import { image as logo } from "./elements/logo";
import { PTSans } from "./elements/font";
import { ProducerType } from "../lib/api/producer";
import { ProducersFullGroupedType, ProducersFullType } from "../lib/api/producersfull";
import format from "date-fns/format";
import numeral from "numeral";
import { isNullOrUndef } from "../lib/helpers/isNullOrUndef";
import { get } from "./helpers";
import { CompanyDetailsType } from "../lib/api/companydetails";
import { AdhocDetail } from "../lib/api/produceradvancepaidadhocdetail";

enum Currency {
  ZAR = "R",
  EUR = "€",
  USD = "$",
  GBP = "£",
  YEN = "¥",
  CAD = "$",
}

let pdf,
  fromTop = 1,
  page = 1,
  fromLeft = 9.0,
  lastLinewritten = 0,
  pages = [],
  fullWidth = 0,
  fullHeight = 0;

const lineHeight = 0.5,
  grey = 210;

const setFontBold = () => {
  pdf.setFontStyle("bold");
  pdf.setFont("helvetica"); // set font
};

const setFontNormal = () => {
  pdf.setFontStyle("normal");
  pdf.setFont("PTSans"); // set font
};

const InitialisePDF = () => {
  pdf = new jsPDF({
    orientation: "portrait",
    unit: "cm",
  });

  fullWidth = pdf.internal.pageSize.getWidth();
  fullHeight = pdf.internal.pageSize.getHeight();
  page = 1;
  pages = [page];
  fromTop = 2;
  fromLeft = 1;

  pdf.addFileToVFS("PTSans.ttf", PTSans);
  pdf.addFont("PTSans.ttf", "PTSans", "normal");

  pdf.setFont("PTSans");
  page = 1;

  fromTop = 1;
  fromLeft = 1.2;

  pdf.setLineWidth(0.01);
};

const detailColumnHeaders = [
  { dataKey: "bar_intakewaybill", heading: "Waybill", width: 2, alignment: "left" },
  { dataKey: "barcode", heading: "Barcode", width: 2.8, alignment: "left" },
  { dataKey: "weekcold_week", heading: "Cold Week", width: 1.8, alignment: "center" },
  { dataKey: "bar_CommodityCode", heading: "Comm", width: 1, alignment: "center" },
  { dataKey: "bar_VarietyCode", heading: "Var", width: 1, alignment: "center" },
  { dataKey: "bar_GradeCode", heading: "Grade", width: 1, alignment: "center" },
  { dataKey: "bar_CountCode", heading: "Count", width: 1, alignment: "center" },
  { dataKey: "bar_PackCode", heading: "Pack", width: 1, alignment: "center" },
  { dataKey: "bar_MarkCode", heading: "Mark", width: 1, alignment: "center" },
  { dataKey: "bar_NoCartons", heading: "Crtns", width: 1.2, alignment: "center" },
  { dataKey: "bar_PalletSize", heading: "Plts", width: 1.2, alignment: "center" },
  { dataKey: "dispatch_currencycode", heading: "Curr", width: 1, alignment: "center" },
  { dataKey: "advanceCarton", heading: "Adv/Ctn", width: 1.1, alignment: "center" },
  { dataKey: "total", heading: "Adv/Plt", width: 1.5, alignment: "right" },
];

const formatNumberDecimals = (value: string | number) => {
  return numeral(value).format("0,0.00").toString();
};

const newPage = () => {
  pdf.addPage();

  page++;
  pages.push(page);
  pdf.setPage(page);
};

const addImage = (imageData: string, format: string, fromLeft: number, fromTop: number, width: number, height: number, alias?, compression?, rotation?) => {
  pdf.addImage(imageData, format, fromLeft, fromTop, width, height, alias, compression, rotation);
};

const newLine = (howFar: number = 0.5) => {
  fromTop = fromTop + howFar;
};

const headerColumn1 = () => {
  return 3.9;
};
const headerColumn2 = () => {
  return fullWidth / 2;
};
const headerColumn3 = () => {
  return fullWidth - 6;
};
const headerColumnSpecial = () => {
  return fullWidth - 4;
};

const addressLineArray = (address: string, arr = []) => {
  if (!address) {
    return null;
  }

  let words = (address || "").split(" ");

  if (address.length > 42) {
    let line = "";
    let rem = address.split(" ");

    for (let i = 0; i < words.length; i++) {
      const currLine = line + " " + words[i];

      if (currLine.length >= 42) {
        break;
      } else {
        rem.splice(0, 1);
        line = currLine.trim();
      }
    }

    arr.push(line);

    if (rem.length > 0) {
      return addressLineArray(rem.join(" "), arr);
    } else {
      return arr;
    }
  } else {
    return [...arr, words.join(" ")];
  }
};

const getAdvanceValue = (row: ProducersFullType) => {
  const currency = row.paid_currency.toLowerCase();
  return isNullOrUndef(row[`acintake_${currency}`]) ? row[`acshipping_${currency}`] : row[`acintake_${currency}`];
};

const getTotalValue = (row: ProducersFullType) => {
  const currency = row.paid_currency.toLowerCase();
  return row[`paid_${currency}`];
};

const createHeader = (companyDetails: CompanyDetailsType) => {
  fromTop = 1;
  fromLeft = 1;

  const imageWidth = 5;
  const imageHeight = 1.5;

  addImage(logo, "PNG", (fullWidth - imageWidth) / 2 - 1, fromTop + 0.2, imageWidth, imageHeight);
  pdf.setFontSize(12);
  setFontBold();
  pdf.text("REMITTANCE ADVICE", fullWidth / 2 - 1, fromTop + 2.5, "center");
  pdf.text("ADVANCE PAYMENT ON SHIPMENT", fullWidth / 2 - 1, fromTop + 3.1, "center");

  pdf.setFontSize(11);
  setFontBold();
  pdf.text(get("name", companyDetails, ""), fromLeft, fromTop, "left");

  setFontNormal();
  pdf.setFontSize(8);

  newLine(0.4);
  pdf.text(get("address1", companyDetails, ""), fromLeft, fromTop, "left");

  newLine(0.4);
  pdf.text(get("address2", companyDetails, ""), fromLeft, fromTop, "left");

  newLine(0.4);
  pdf.text(get("address3", companyDetails, ""), fromLeft, fromTop, "left");

  newLine(0.4);
  pdf.text(get("address4", companyDetails, ""), fromLeft, fromTop, "left");

  newLine(0.4);
  pdf.text(get("address5", companyDetails, ""), fromLeft, fromTop, "left");

  fromLeft = headerColumn3();
  fromTop = 1;

  pdf.text("Tel No:", fromLeft, fromTop, "right");
  pdf.text(get("telephoneNumber", companyDetails, ""), fromLeft + 0.2, fromTop, "left");

  newLine(0.4);
  pdf.text("Fax No:", fromLeft, fromTop, "right");
  pdf.text(get("faxNumber", companyDetails, ""), fromLeft + 0.2, fromTop, "left");

  newLine(0.4);
  pdf.text("E-mail:", fromLeft, fromTop, "right");
  pdf.text(get("email", companyDetails, ""), fromLeft + 0.2, fromTop, "left");

  newLine(0.4);
  pdf.text("Vat No:", fromLeft, fromTop, "right");
  pdf.text(get("vatno", companyDetails, ""), fromLeft + 0.2, fromTop, "left");

  newLine(0.4);
  pdf.text("Reg No:", fromLeft, fromTop, "right");
  pdf.text(get("regno", companyDetails, ""), fromLeft + 0.2, fromTop, "left");

  newLine(0.4);
  pdf.text("Customs Code:", fromLeft, fromTop, "right");
  pdf.text(get("customscode", companyDetails, ""), fromLeft + 0.2, fromTop, "left");

  newLine(1);
};

const printTextRow = (label: string, text: string, pos = headerColumn1()) => {
  fromLeft = pos;
  setFontBold();
  pdf.text(fromLeft, fromTop, label, "right");
  setFontNormal();
  pdf.text(fromLeft + 0.25, fromTop, text || "", "left");
};

const createProducerDetails = (header: ProducerType, selectedAdvance: ProducersFullGroupedType) => {
  const clientAddress = header.physical_address ? header.physical_address.replace(/[\r\n]/g, " ") : "";
  const clientAddressArr = addressLineArray(clientAddress) || [];

  const startRect = fromTop;
  fromTop += 1;

  printTextRow("Producer :", header.name);
  printTextRow("Date :", format(new Date(selectedAdvance.produceradvancepaidhead_posteddate), "dd/MM/yyyy"), headerColumn3());

  newLine(0.4);

  printTextRow("", clientAddressArr.splice(0, 1));
  printTextRow("Remittance ID :", `${selectedAdvance.produceradvancepaidhead_ident}`, headerColumn3());

  newLine(0.4);

  printTextRow("", clientAddressArr.splice(0, 1));
  printTextRow("Description :", `${selectedAdvance.produceradvancepaidhead_makeanote || "N/A"}`, headerColumn3());

  newLine(0.4);

  printTextRow("", clientAddressArr.splice(0, 1));
  printTextRow("Amount :", `${Currency[selectedAdvance.paid_currency]} ${numeral(selectedAdvance.sum_paidcurrencyValueTotal).format("0,0.00")}`, headerColumn3());

  newLine(0.4);

  printTextRow("Vat No: ", header.vat_no);

  newLine(0.4);

  printTextRow("Email: ", header.finance_email);

  fromLeft = 1;
  pdf.setLineWidth(0.01);
  pdf.rect(fromLeft, startRect + 0.5, fullWidth - 2, fromTop - startRect, "S");

  fromTop += 0.5;
  lastLinewritten = fromTop;
};

const createShipmentDetail = (detail: ProducersFullType[]) => {
  fromTop += 1;
  fromLeft = headerColumn1();
  let startRect = fromTop;

  const formattedData = detail.reduce((obj, row) => {
    obj[row.sale_invoicenumber] = {
      invoicenumber: row.sale_invoicenumber,
      vessel: row.vessel_description,
      shipmentWeek: row.weekshipping_week,
      containerNo: row.dispatch_containerno,
      shipMarket: row.targetFinal_market,
      voyageNo: row.loadout_voyageNumber,
      finalDestination: row.portDischarge_description,
      etd: row.loadout_etd,
      barcodes: [...((obj[row.sale_invoicenumber] || { barcodes: [] }).barcodes || []), row],
    };
    return obj;
  }, {});

  const keys = Object.keys(formattedData);

  for (let i = 0; i < keys.length; i++) {
    if (fromTop >= fullHeight - 5) {
      newPage();
      fromTop = 2;
      startRect = 1.5;
    }

    startRect = fromTop - 0.5;

    const key = keys[i],
      row = formattedData[key],
      barcodeData: ProducersFullType[] = row.barcodes;

    printTextRow("Shipment Ref :", row.invoicenumber);
    printTextRow("Vessel :", row.vessel, headerColumn2());
    newLine(0.4);

    printTextRow("Shipment Week :", row.shipmentWeek);
    printTextRow("Container No :", row.containerNo, headerColumn2());
    newLine(0.4);

    printTextRow("Shipped Market :", row.shipMarket);
    printTextRow("Voyage No :", row.voyageNo, headerColumn2());
    newLine(0.4);

    printTextRow("Final Destination :", row.finalDestination);
    printTextRow("ETD :", format(new Date(row.etd), "dd/MM/yyyy"), headerColumn2());

    newLine(1);
    fromLeft = 1.1;

    for (let j = 0; j < detailColumnHeaders.length; j++) {
      const headerCol = detailColumnHeaders[j],
        printPoint = headerCol.alignment == "left" ? fromLeft : headerCol.alignment == "center" ? fromLeft + headerCol.width / 2 : fromLeft + headerCol.width;

      setFontBold();
      pdf.text(headerCol.heading, printPoint, fromTop, headerCol.alignment);

      fromLeft += headerCol.width;
    }

    // print line under header columns
    pdf.setLineWidth(0.01);
    pdf.rect(1, fromTop + 0.2, fullWidth - 2, 0.001, "S");

    fromTop += 0.1;

    for (let j = 0; j < barcodeData.length; j++) {
      const row = barcodeData[j];
      fromLeft = 1.1;

      if (fromTop >= fullHeight - 3) {
        pdf.setLineWidth(0.01);
        pdf.rect(1, startRect, fullWidth - 2, fromTop - startRect + 0.2, "S");

        newPage();
        fromTop = 2;
        startRect = 1.5;
      } else {
        fromTop += 0.5;
      }

      for (let k = 0; k < detailColumnHeaders.length; k++) {
        const headerCol = detailColumnHeaders[k],
          printPoint = headerCol.alignment == "left" ? fromLeft : headerCol.alignment == "center" ? fromLeft + headerCol.width / 2 : fromLeft + headerCol.width;

        let value = row[headerCol.dataKey];

        if (headerCol.dataKey === "bar_PalletSize") {
          value = numeral(value).format("0,0.00");
        }
        if (headerCol.dataKey === "advanceCarton") {
          value = numeral(row[`acshipping_${row.dispatch_currencycode.toLowerCase()}`]).format("0,0.00");
        }
        if (headerCol.dataKey === "total") {
          value = numeral(row[`shippingCartonValue${row.dispatch_currencycode}`]).format("0,0.00");
        }

        setFontNormal();
        pdf.text(`${value}`, printPoint, fromTop, headerCol.alignment);

        fromLeft += headerCol.width;
      }
    }

    // print line above subtotal row
    pdf.setLineWidth(0.01);
    pdf.rect(1, fromTop + 0.2, fullWidth - 2, 0.001, "S");

    newLine(0.7);
    fromLeft = 1.1;

    const subTotals = barcodeData.reduce(
      (obj, row) => {
        obj.bar_NoCartons = obj.bar_NoCartons + parseFloat(row.bar_NoCartons);
        obj.bar_PalletSize = obj.bar_PalletSize + parseFloat(row.bar_PalletSize);
        obj.total = obj.total + row[`shippingCartonValue${row.dispatch_currencycode}`];
        return obj;
      },
      {
        bar_NoCartons: 0,
        bar_PalletSize: 0,
        total: 0,
      },
    );

    for (let j = 0; j < detailColumnHeaders.length; j++) {
      const headerCol = detailColumnHeaders[j],
        printPoint = headerCol.alignment == "left" ? fromLeft : headerCol.alignment == "center" ? fromLeft + headerCol.width / 2 : fromLeft + headerCol.width;

      setFontBold();
      if (headerCol.dataKey === "bar_intakewaybill") {
        pdf.text("SUBTOTAL", printPoint + 0.1, fromTop, headerCol.alignment);
      }
      if (headerCol.dataKey === "advanceCarton") {
        pdf.text(barcodeData[0].dispatch_currencycode, printPoint, fromTop, headerCol.alignment);
      }
      if (headerCol.dataKey === "bar_NoCartons") {
        pdf.text(numeral(subTotals[headerCol.dataKey]).format("0,0"), printPoint, fromTop, headerCol.alignment);
      }
      if (["bar_PalletSize", "total"].includes(headerCol.dataKey)) {
        pdf.text(numeral(subTotals[headerCol.dataKey]).format("0,0.00"), printPoint, fromTop, headerCol.alignment);
      }

      fromLeft += headerCol.width;
    }

    pdf.setLineWidth(0.01);
    pdf.rect(1, startRect, fullWidth - 2, fromTop - startRect + 0.2, "S");
    newLine(0.5);
    fromTop += 0.5;
  }
};

const printTotalLine = (value: string, alignment: string, left: number, bold = true) => {
  if (bold) {
    setFontBold();
  } else {
    setFontNormal();
  }

  fromLeft = left;
  pdf.text(fromLeft, fromTop, value, alignment);
};

const createTotalSection = (selectedAdvance: ProducersFullGroupedType, adhocPayments: AdhocDetail[]) => {
  pdf.setFontSize(9);
  newLine(1);

  const startRect = fromTop - 0.5;

  printTotalLine("TOTAL PRODUCER ADVANCE PAYMENT:", "left", 1.2);
  printTotalLine(`${numeral(selectedAdvance.bar_NoCartons).format("0,0")}`, "right", fullWidth - 6.25);
  printTotalLine(`${numeral(selectedAdvance.bar_PalletSize).format("0,0.00")}`, "right", fullWidth - 5);
  printTotalLine(`${Currency[selectedAdvance.paid_currency]}`, "right", fullWidth - 4);
  printTotalLine(`${numeral(selectedAdvance.sum_paidcurrencyValue).format("0,0.00")}`, "right", fullWidth - 2);

  // newLine(0.75);

  pdf.setLineWidth(0.01);
  pdf.rect(1, fromTop + 0.2, fullWidth - 2, 0.001, "S");

  adhocPayments.forEach((adhoc) => {
    newLine(0.75);

    setFontBold();
    printTotalLine("ADDITIONAL AD-HOC PAYMENT / (DEDUCTION): ", "left", 1.2);

    setFontNormal();
    printTotalLine(adhoc.note || "", "right", fullWidth - 5);
    printTotalLine(`${Currency[selectedAdvance.paid_currency]}`, "right", fullWidth - 4);
    printTotalLine(formatNumberDecimals(adhoc.amount), "right", fullWidth - 2);
  });

  // print line above total row
  pdf.setLineWidth(0.01);
  pdf.rect(1, fromTop + 0.3, fullWidth - 2, 0.001, "S");

  newLine(0.75);

  printTotalLine("TOTAL PAID TO PRODUCER:", "left", 1.2);
  printTotalLine(`${Currency[selectedAdvance.paid_currency]}`, "right", fullWidth - 4);
  printTotalLine(`${numeral(selectedAdvance.sum_paidcurrencyValueTotal).format("0,0.00")}`, "right", fullWidth - 2);

  pdf.setLineWidth(0.01);
  pdf.rect(1, startRect, fullWidth - 2, fromTop - startRect + 0.2, "S");
};

export const producerAdvancedShipment = ({
  companyDetails,
  header,
  detail,
  selectedAdvance,
  adhocPayments,
}: {
  companyDetails: CompanyDetailsType;
  header: ProducerType;
  detail: ProducersFullType[];
  selectedAdvance: ProducersFullGroupedType;
  adhocPayments: AdhocDetail[];
}) => {
  InitialisePDF();

  createHeader(companyDetails);
  createProducerDetails(header, selectedAdvance);
  createShipmentDetail(detail);
  createTotalSection(selectedAdvance, adhocPayments);

  let filename = `${selectedAdvance.producer_name}.pdf`;
  try {
    const { produceradvancepaidhead_posteddate, producer_name, produceradvancepaidhead_ident, produceradvancepaidhead_makeanote } = selectedAdvance;
    filename = `${format(new Date(produceradvancepaidhead_posteddate), "yyMMdd")}_${producer_name}_${produceradvancepaidhead_ident.toString() || ""}_${(
      produceradvancepaidhead_makeanote || ""
    )
      .trim()
      .replaceAll(" ", "_")}.pdf`;
  } catch (error) {
    throw new Error(error);
  }

  pdf.save(filename);
};
