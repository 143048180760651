import React, { useState, useCallback } from "react";
import { createStyles, Theme } from "@material-ui/core/styles";

import { ResetPasswordConfirm } from "./ResetPasswordConfirm";
import { SetBooleanFunc, User } from "./Types";
import { validatePassword } from "../utils/user";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Slide from "@material-ui/core/Slide";
import DialogActions from "@material-ui/core/DialogActions";
import FormControl from "@material-ui/core/FormControl";
import makeStyles from "@material-ui/core/styles/makeStyles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    form: {
      width: "400px",
      display: "flex",
      justifySelf: "center",
    },
    footerButtons: {
      display: "flex",
      justifyContent: "flex-end",
      gap: theme.spacing(1),
    },
    input: {
      color: "#3f51b5",
      fontSize: "20px",
    },
    container: {
      display: "flex",
      width: "100%",
    },
    dialogContainer: {
      display: "flex",
      width: "350px",
      flexDirection: "column",
    },
  }),
);

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

type ResetPasswordProps = {
  handleResetPwd: boolean;
  setHandleResetPwd: SetBooleanFunc;
  user: User;
};

// TODO: use formik with validation instead of state management

const ResetPassword = ({ handleResetPwd, setHandleResetPwd, user }: ResetPasswordProps) => {
  const classes = useStyles();
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [enteredPassword, setEnteredPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordIsStrong, setPasswordIsStrong] = useState(false);

  const [passwordMessage, setPasswordMessage] = useState("");
  const [confirmPasswordMessage, setConfirmPasswordMessage] = useState("");
  const [showPassword, toggleShowPassword] = useState(false);

  const handleResetPassword = (e) => {
    e.preventDefault();
  };

  const handleClose = (event: {}, reason: "backdropClick" | "escapeKeyDown"): void => {
    setHandleResetPwd(false);
  };

  const handleResetRequest = useCallback(
    (enteredPassword, confirmPassword, passwordIsStrong) => {
      if (enteredPassword == confirmPassword && passwordIsStrong) {
        setPasswordMessage("");
        setConfirmPasswordMessage("");
        setShowConfirmDialog(true);
      } else {
        if (enteredPassword == "" || confirmPassword == "") {
          setPasswordMessage("Please enter a valid password");
        } else if (passwordMessage === "") {
          setPasswordMessage("Entered passwords need to match");
        }
      }
    },
    [passwordMessage],
  );

  const handleValidatePassword = useCallback(
    (enteredPassword: string) => {
      const [isValid, message] = validatePassword(enteredPassword);
      setConfirmPasswordMessage("");
      setPasswordMessage(message);
      setPasswordIsStrong(isValid);
    },
    [enteredPassword],
  );

  const validateConfirmPassword = useCallback(
    (enteredConfirm: string, enteredPassword: string) => {
      // check if password is validate before showing mismatch error
      if (passwordIsStrong) {
        if (enteredConfirm != enteredPassword) {
          setConfirmPasswordMessage("Entered passwords need to match");
          setPasswordMessage("");
        } else {
          setConfirmPasswordMessage("");
        }
      }
    },
    [confirmPassword, passwordMessage],
  );

  return (
    <div className={classes.form}>
      {showConfirmDialog && (
        <ResetPasswordConfirm
          enteredPassword={enteredPassword}
          showConfirmDialog={showConfirmDialog}
          setShowConfirmDialog={setShowConfirmDialog}
          user={user}
          setHandleResetPwd={setHandleResetPwd}
        />
      )}
      <form onSubmit={handleResetPassword}>
        <Dialog TransitionComponent={Transition} open={handleResetPwd} onClose={handleClose} PaperProps={{}}>
          <DialogContent>
            <div className={classes.dialogContainer}>
              <div>
                <h1>RESET PASSWORD</h1>
              </div>
              <div>
                <FormControl fullWidth>
                  <TextField
                    type={showPassword ? "text" : "password"}
                    variant="outlined"
                    InputProps={{ className: classes.input }}
                    label="Enter password"
                    margin="dense"
                    id="standard-adornment-amount"
                    helperText={passwordMessage}
                    error={!passwordIsStrong && passwordMessage != ""}
                    onChange={(e) => {
                      setEnteredPassword(e.target.value);
                      handleValidatePassword(e.target.value);
                    }}
                  />
                </FormControl>
              </div>
              <div>
                <FormControl fullWidth>
                  <TextField
                    type={showPassword ? "text" : "password"}
                    variant="outlined"
                    InputProps={{ className: classes.input }}
                    label="Confirm password"
                    margin="dense"
                    helperText={confirmPasswordMessage}
                    error={!passwordIsStrong && confirmPasswordMessage != ""}
                    onChange={(e) => {
                      setConfirmPassword(e.target.value);
                      validateConfirmPassword(e.target.value, enteredPassword);
                    }}
                  />
                </FormControl>
              </div>
              <div>
                <FormControlLabel control={<Checkbox checked={showPassword} onChange={() => toggleShowPassword(!showPassword)} name="show-password" />} label="Show password" />
              </div>
            </div>
          </DialogContent>
          <DialogActions>
            <div className={classes.footerButtons}>
              <Button variant="contained" color="secondary" onClick={() => setHandleResetPwd(false)}>
                Close
              </Button>
              <Button variant="contained" color="primary" onClick={() => handleResetRequest(enteredPassword, confirmPassword, passwordIsStrong)}>
                Confirm
              </Button>
            </div>
          </DialogActions>
        </Dialog>
      </form>
    </div>
  );
};

export default ResetPassword;
