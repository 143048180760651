import React from "react";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";

import Toolbar from "@material-ui/core/Toolbar";

import { GridColumns } from "./saleallocationgridsetup";

import Grid from "../../../../lib/components/grid";
import { PinkButton } from "../../../../lib/components/ColorButtons";
import { AllocationList } from "../../../../lib/api/salefundsallocation";

const styles = (theme: Theme) =>
  createStyles({
    root: {},
    toolbar: {
      position: "absolute",
      left: 0,
      top: 0,
      height: "60px",
      width: "100%",
      display: "flex",
      flexDirection: "row",
      gap: "10px",
    },
  });

type Props = {
  saleAllocation: { sale_id: number; paymentnumber: number };
  selectedForAlloc: AllocationList[];
  handleClose(): void;
  handleChangeSelectedForAlloc(data: AllocationList[]): void;
} & WithStyles<typeof styles>;

class SaleAllocationsTableUnstyled extends React.Component<Props, {}> {
  constructor(props) {
    super(props);
  }

  state = {
    data: [],
  };

  componentDidMount(): void {
    this.loadData();
  }

  loadData = () => {
    const { sale_id, paymentnumber } = this.props.saleAllocation;
    const selectedAllocations = this.props.selectedForAlloc.filter((item) => !item.deleted && item.sale_id == sale_id && item.paymentnumber == paymentnumber);
    this.setState({ data: selectedAllocations });
  };

  handleChange = (grid: string, row: AllocationList[], column: any) => {
    const updated = this.state.data.map((item) => {
      const newRow = row.find((r) => r.sale_id == item.sale_id && r.paymentnumber == item.paymentnumber && item.currIndx == r.currIndx);
      if (newRow) {
        const newAmount = parseFloat(newRow.amount.toString());
        return { ...item, amount: newAmount, amount_diff: newAmount - item.orig_amount };
      }
      return item;
    });
    this.handleUpdateShipments(updated);
    this.setState({ data: updated });
  };

  handleUpdateShipments = (updatedData: AllocationList[]) => {
    const updatedSelectedForAlloc = updatedData.reduce((arr, row) => {
      const index = arr.findIndex((item) => item.sale_id == row.sale_id && item.paymentnumber == row.paymentnumber && item.currIndx == row.currIndx);
      if (index >= 0) {
        arr[index] = { ...arr[index], amount: row.amount, dirty: true, amount_diff: row.amount_diff };
      } else {
        arr.push(row);
      }
      return arr;
    }, this.props.selectedForAlloc);
    this.props.handleChangeSelectedForAlloc([...updatedSelectedForAlloc]);
  };

  handleClearAllAllocations = () => {
    const filteredDeleted = this.props.selectedForAlloc.reduce((arr, selected: AllocationList) => {
      if (selected.is_locked) return arr; // skip allocation if shipment is locked

      const index = this.state.data.findIndex((row) => selected.sale_id == row.sale_id && selected.paymentnumber == row.paymentnumber && selected.currIndx == row.currIndx);

      if (index >= 0) {
        if (selected.id) {
          arr.push({ ...this.state.data[index], deleted: true, dirty: true, amount: 0, amount_diff: selected.orig_amount });
        }
      } else {
        arr.push(selected);
      }
      return arr;
    }, []);
    this.props.handleChangeSelectedForAlloc([...filteredDeleted]);
    this.setState({ data: this.props.selectedForAlloc.filter((alloc) => alloc.is_locked) });
  };

  handleRemoveFundsAllocation = (deletedRow) => {
    const { selectedForAlloc } = this.props;

    const filteredAllocations = selectedForAlloc.reduce((arr, selected: AllocationList) => {
      if (selected.sale_id == deletedRow.sale_id && selected.paymentnumber == deletedRow.paymentnumber && selected.currIndx == deletedRow.currIndx) {
        if (selected.id) {
          arr.push({ ...deletedRow, deleted: true, dirty: true, amount: 0, amount_diff: selected.orig_amount });
        }
      } else {
        arr.push(selected);
      }
      return arr;
    }, []);

    const filteredData = this.state.data.filter(
      (selected) => !(selected.sale_id == deletedRow.sale_id && selected.paymentnumber == deletedRow.paymentnumber && selected.currIndx == deletedRow.currIndx),
    );
    this.props.handleChangeSelectedForAlloc([...filteredAllocations]);
    this.setState({ data: filteredData });
  };

  render() {
    const { classes } = this.props;
    return (
      <div style={{ height: "400px", width: "900px", position: "relative" }}>
        <Toolbar className={classes.toolbar}>
          <h3>ALLOCATIONS</h3>
          <PinkButton variant="contained" onClick={() => this.handleClearAllAllocations()}>
            Remove All Allocations
          </PinkButton>
        </Toolbar>
        <Grid
          data={this.state.data}
          loading={false}
          rowHeight={30}
          forceHeight={300}
          handleChange={this.handleChange}
          clearFilters={"saleallocationstable"}
          GridColumns={(data, filters, arrangement, columnsWidth) => GridColumns(data, filters, arrangement, columnsWidth, this.handleRemoveFundsAllocation)}
          totalRowColumns={["due_amount", "amount"]}
        />
      </div>
    );
  }
}

export default withStyles(styles)(SaleAllocationsTableUnstyled);
