import React, { useEffect, useState } from "react";

import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";

import Grid from "../../lib/components/grid";
import { GridColumns } from "./consolidationgridsetup";
import { systemstabilityconsolidation } from "../../lib/api/systemstability";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1),
    },
  });

type ConsolidationGridProps = {} & WithStyles<typeof styles>;

const ConsolidationGridUnstyled: React.FC<ConsolidationGridProps> = () => {
  const [data, setData] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const loadData = async () => {
    setLoading(true);
    const result = await systemstabilityconsolidation();
    setData(result);
    setLoading(false);
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <div>
      <Grid data={data} loading={loading} GridColumns={GridColumns} clearFilters={"systemstabilityconsolidation"} handleRefresh={loadData} />
    </div>
  );
};

export default withStyles(styles)(ConsolidationGridUnstyled);
