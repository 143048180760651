import React, { useEffect } from "react";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Slide from "@material-ui/core/Slide";
import MenuItem from "@material-ui/core/MenuItem";

import { Form, Field } from "react-final-form";
import { TextField, Checkbox, Radio, Select } from "final-form-material-ui";

// import { MaterialSelectNativeComponent } from "../../lib/helpers/materialcomponents";

function Transition(props) {
  return <Slide direction="up" {...props} />;
}
const styles = (theme: Theme) =>
  createStyles({
    root: {},
    label: {
      paddingRight: theme.spacing(1),
    },
  });

type TemplatesMasterCrudProps = {
  onSubmit: any;
  onClose: () => void;
  formRecord: any;
  varietiesData: any;
  commoditiesData: any;
} & WithStyles<typeof styles>;

const TemplatesMasterCrudUnstyled: React.SFC<TemplatesMasterCrudProps> = (props) => {
  const { onSubmit, onClose, formRecord, varietiesData, commoditiesData, classes } = props;

  const [formRecordData, setFormRecordData] = React.useState(formRecord);
  const [commodities, setCommodities] = React.useState(commoditiesData);
  const [varieties, setVarieties] = React.useState(varietiesData);
  const [varietiesFiltered, setVarietiesFiltered] = React.useState([]);
  const [loading, setLoading] = React.useState(true);

  useEffect(() => {
    onClickCommodity(formRecord.commodity_code);
  }, [formRecord]);

  const onClickCommodity = (value) => {
    if (value) {
      const resultFiltered = varieties.filter((v) => v.data.commodity_id == value);
      setVarietiesFiltered(resultFiltered);
    }
  };

  return (
    <>
      {commodities && varieties && (
        <Dialog open={true} TransitionComponent={Transition} onClose={onClose} aria-labelledby="alert-slide-dialog-title" aria-describedby="alert-dialog-slide-description">
          <DialogTitle id="form-dialog-slide-title">{`EDIT Template "${formRecordData.templatename}"`}</DialogTitle>

          <DialogContent>
            <Form
              initialValues={{ ...formRecordData }}
              onSubmit={onSubmit}
              render={({ handleSubmit, form, submitting, pristine, values }) => (
                <form onSubmit={handleSubmit} style={{ width: "450px" }}>
                  <table>
                    <tbody>
                      <tr>
                        <td>
                          <label className={classes.label}>templatename: </label>
                        </td>
                        <td>
                          <Field fullWidth name="templatename" component={TextField} type="text" />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <label>Commodity: </label>
                        </td>
                        <td>
                          {/* <Field
                            onClick={(e) => {
                              onClickCommodity(e);
                            }}
                            name="commodity_code"
                            component={MaterialSelectNativeComponent}
                            type="text"
                            label="commodity"
                            childdata={commodities}
                          /> */}
                          <Field
                            name="commodity_code"
                            component={Select}
                            onClick={(e) => {
                              onClickCommodity(e.target.value);
                            }}
                          >
                            {commodities.map((commItem) => {
                              return (
                                <MenuItem key={commItem.value} value={commItem.value}>
                                  {commItem.display}
                                </MenuItem>
                              );
                            })}
                          </Field>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <label>Variety: </label>
                        </td>
                        <td>
                          {/* <Field name="variety_id" component={MaterialSelectNativeComponent} type="text" label="variety" childdata={varietiesFiltered} /> */}
                          <Field name="variety_id" component={Select}>
                            {varietiesFiltered.map((varItem) => {
                              return (
                                <MenuItem key={varItem.value} value={varItem.value}>
                                  {varItem.display}
                                </MenuItem>
                              );
                            })}
                          </Field>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div style={{ paddingTop: "5px", float: "right" }}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="secondary"
                      style={{ margin: "2px" }}
                      onClick={() => {
                        onClose();
                      }}
                    >
                      cancel
                    </Button>
                    <Button type="submit" variant="contained" color="primary" style={{ margin: "2px" }}>
                      accept
                    </Button>
                  </div>
                </form>
              )}
            />
          </DialogContent>
        </Dialog>
      )}
    </>
  );
};

export default withStyles(styles)(TemplatesMasterCrudUnstyled);
