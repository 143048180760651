import { config } from "../../config";
import { client, apiHeaders, request } from "./_base";

const endpoint = "producersale";

export type ProducerSaleType = {
  id: number;
  dispatch_id: number;
  date: Date;
  invoicenumber: string;
  ucr: string;
  trader_id: string;
  terms_id: number;
  ata: Date;
  notifyparty_id: number;
  payment1amount: number;
  payment1date: Date;
  payment2amount: number;
  payment2date: Date;
  payment3amount: number;
  payment3date: Date;
  exchangerate: number;
  type: number;
  clients_id: number;
  created_at: Date;
  updated_at: Date;
};
export const producersale = client(endpoint, (item) => ({
  ...item,
}));

export type ProducerSaleDispatchJoinedType = {
  dispatch_id: number;
  dispatch_dispatchdocs_id: string;
  dispatch_loaddate: string;
  sale_id: number;
  sale_date: Date;
  sale_dispatch_id: number;
  sale_exchangerate: number;
  sale_invoicenumber: string;
  dispatch_invoicenumber: string;
  sale_ucr: string;
  sale_trader_id: number;
  sale_terms_id: number;
  sale_created_at: Date;
  sale_updated_at: Date;
  sale_ata: Date;
  sale_notifyparty_id: number;
  loadout_consignee: string;
  dispatch_dealtype: number;
  dispatch_sellingterm: number;
  dispatch_currency_id: number;
  terms_description: string;
  currency_code: string;
  clients_name: string;
  users_username: string;
  agent_id: number;
  agent_agentname: string;
  loadout_id: number;
  loadout_exportNotificationReference: string;
  loadout_voyageNumber: string;
  vessel_id: number;
  vessel_code: string;
  dispatch_containerno: string;
  dispatch_containertype: string;
  dispatch_sealnumber: string;
  dispatch_consignee_id: number;
  dispatch_clearingagent_id: number;
  portloading_id: number;
  portloading_code: string;
  portloading_description: string;
  portdistcharge_id: number;
  portdistcharge_code: string;
  portdistcharge_description: string;
  portfinal_id: number;
  portfinal_code: string;
  portfinal_description: string;
  loadout_etd: Date;
  loadout_eta: Date;
  sale_payment1amount: number;
  sale_payment1date: Date;
  sale_payment2amount: number;
  sale_payment2date: Date;
  sale_payment3amount: number;
  sale_payment3date: Date;
  sale_type: string;
  dispatch_forwardagentref: string;
  producer_code: string;
  producer_name: string;
  producer_termsid: number;
  salecurr_id: number;
  salecurr_code: string;
  shipment_week: string;
};
export const producerSaleDispatchJoined = async (financialyear = 2020, producer_code: string) =>
  await request(`${endpoint}/ext/saleDispatchJoined?financialyear=${financialyear}&producer_code=${producer_code}`, {
    headers: apiHeaders(),
  })
    .then(async (result) => {
      if (result.status == 500) {
        const resp = await result.json();
        throw resp;
      }
      return result.json();
    })
    .then(({ data }) => {
      return data;
    });

export type ProducerSaleBarcodesByDispatchIDType = {
  dispatch_id: number;
  sale_id: number;
  sale_invoicenumber: string;
  loadout_exportNotificationReference: string;
  loadout_reference: string;
  loadoutdetail_barcode: string;
  barcode_nocartons: number;
  barcode_commoditycode: string;
  barcode_varietycode: string;
  barcode_packcode: string;
  barcode_markcode: string;
  barcode_gradecode: string;
  barcode_countcode: string;
  barcode_inventorycode: string;
  barcode_farm: string;
  barcode_palletsize: string;
  barcode_id: number;
  currency: string;
  comment: string;
  producer_code: string;
  stockdetail_sellingprice: number;
  stockdetail_producerprice: number;
  total: number;
  group?: number;
  barcode?: string;
  dispatch_containerno: string;
  dispatch_containertype: string;
  dispatch_sealnumber: string;
  dispatch_consignee_id: string;
  dispatch_clearingagent_id: string;
};
export const producerSaleBarcodesByDispatchID = async (dispatchid: number, producercode: string) =>
  await request(`${endpoint}/ext/saleBarcodesByDispatchID/${dispatchid}/${producercode}`, {
    headers: apiHeaders(),
  })
    .then(async (result) => {
      if (result.status == 500) {
        const resp = await result.json();
        throw resp;
      }
      return result.json();
    })
    .then(({ data }) => {
      return data;
    });

export const producerFixedInvoiceSummary = async (financial_year: number) =>
  await request(`${endpoint}/ext/producerFixedInvoiceSummary/${financial_year}`, {
    headers: apiHeaders(),
  })
    .then(async (result) => {
      if (result.status == 500) {
        const resp = await result.json();
        throw resp;
      }
      return result.json();
    })
    .then(({ data }) => {
      return data;
    });
