import { client, apiHeaders, request } from "./_base";
import { config } from "../../config";

export type WeekType = {
  id?: number;
  week: string;
  period_start: Date;
  period_end: Date;
  created_at: Date;
  updated_at: Date;
  financial_year: number;
};

// todo api exports
export const weeks = client("week", (item) => ({
  ...item,
}));

export const weeksOrdered = async (financialyear: string): Promise<{ data: WeekType[] }> => {
  const result = await request(`week/extend/weekOrdered/${financialyear}`, {
    headers: apiHeaders(),
  });
  const resp = await result.json();
  if (result.status == 500) {
    throw new Error(resp);
  }
  return resp;
};

export const updateWeeksForYear = (year) => {
  return request(`week/ext/updateWeeksForYear/${year}`, {
    headers: apiHeaders(),
  }).then((result) => {
    if (result.status != 200) {
      throw result;
    }
    result.json();
  });
};

export type WeeksCombo = {
  value: number;
  display: string;
  data: WeekType;
};

export const getWeeksReadyForComboMappedForComboValue = async (): Promise<WeeksCombo[]> => {
  const result = await weeksOrdered("0");
  return result.data.map((wk) => {
    return { value: wk.id, display: wk.week, data: wk };
  });
};

export const getWeekByDate = async (date) => {
  const result = await request(`week/ext/weekByDate?date=${date}`, {
    headers: apiHeaders(),
  });
  const resp = await result.json();
  if (result.status == 500) {
    throw resp;
  }
  return resp.data;
};

export const getWeeksFinancialYears = () => {
  return request(`week/ext/weeksFinancialYears`, {
    headers: apiHeaders(),
  })
    .then(async (result) => {
      if (result.status == 500) {
        const resp = await result.json();
        throw resp;
      }
      return result.json();
    })
    .then(({ data }) => {
      return data;
    });
};

export const getFinancialYearSelected = () => {
  return JSON.parse(localStorage.getItem(`financialyear`));
};

export const getWeekIdByDate = (date: string) => {
  return request(`week/ext/weekByDate?date=${date}`, {
    headers: apiHeaders(),
  })
    .then(async (result) => {
      if (result.status == 500) {
        const resp = await result.json();
        throw resp;
      }
      return result.json();
    })
    .then(({ data }) => {
      if (data.length == 0) {
        return 0;
      }
      return data[0].id;
    });
};

export const getPeriodByDate = (date) => {
  return request(`week/ext/getPeriodByDate?date=${date}`, {
    headers: apiHeaders(),
  })
    .then(async (result) => {
      if (result.status == 500) {
        const resp = await result.json();
        throw resp;
      }
      return result.json();
    })
    .then(({ data }) => {
      return data;
    });
};

export const getFinancialYears = () => {
  return request(`financialyears/ext/getFinancialYears`, {
    headers: apiHeaders(),
  })
    .then(async (result) => {
      if (result.status == 500) {
        const resp = await result.json();
        throw resp;
      }
      return result.json();
    })
    .then(({ data }) => {
      return data;
    });
};
