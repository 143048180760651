import React, { useMemo } from "react";
import { createStyles, withStyles, WithStyles, Theme } from "@material-ui/core/styles";

import { Reports } from "../lib/types";
import { DocumentsButton, DocumentsType } from "../lib/components/DocumentsButton";

const ITEM_HEIGHT = 58;

const styles = (theme: Theme) =>
  createStyles({
    button: {
      margin: theme.spacing(1) / 2,
    },
    rightIcon: {
      marginLeft: theme.spacing(1),
    },
  });

type PrintMenuProps = { style?: any; printHandler: any } & WithStyles<typeof styles>;

const PrintMenu: React.FunctionComponent<PrintMenuProps> = (props) => {
  const { classes, style, printHandler } = props;

  const handleClose = (requestedReportType?: Reports) => {
    if (requestedReportType) {
      printHandler(requestedReportType);
    }
  };

  const menuItems = useMemo(() => {
    return [
      {
        title: "INVOICE",
        children: [
          {
            icon: DocumentsType.PDF,
            title: "CLIENT",
            options: [
              {
                title: "CLIENT INVOICE PDF",
                icon: DocumentsType.PDF,
                action: () => handleClose(Reports.Invoice),
              },
              {
                title: "CLIENT INVOICE EXCEL",
                icon: DocumentsType.EXCEL,
                action: () => handleClose(Reports.InvoiceExcel),
              },
            ],
          },
          // {
          //   icon: DocumentsType.PDF,
          //   title: "CONSIGNEE",
          //   options: [
          //     {
          //       title: "CONSIGNEE INVOICE PDF",
          //       icon: DocumentsType.PDF,
          //       action: () => handleClose(Reports.ConsigneeInvoice),
          //     },
          //     {
          //       title: "CONSIGNEE INVOICE EXCEL",
          //       icon: DocumentsType.EXCEL,
          //       action: () => handleClose(Reports.ConsigneeInvoiceExcel),
          //     },
          //   ],
          // },
          // {
          //   icon: DocumentsType.PDF,
          //   title: "CLIENT & CONSIGNEE",
          //   options: [
          //     {
          //       title: "CLIENT & CONSIGNEE INVOICE PDF",
          //       icon: DocumentsType.PDF,
          //       action: () => handleClose(Reports.ClientAndConsigneeInvoice),
          //     },
          //     {
          //       title: "CLIENT & CONSIGNEE INVOICE EXCEL",
          //       icon: DocumentsType.EXCEL,
          //       action: () => handleClose(Reports.ClientAndConsigneeInvoiceExcel),
          //     },
          //   ],
          // },
        ],
      },
      {
        title: "PACKING LIST",
        children: [
          {
            icon: DocumentsType.PDF,
            title: "CLIENT",
            options: [
              {
                title: "CLIENT PACKING LIST PDF",
                icon: DocumentsType.PDF,
                action: () => handleClose(Reports.PackingListPDF),
              },
              {
                title: "CLIENT PACKING LIST EXCEL",
                icon: DocumentsType.EXCEL,
                action: () => handleClose(Reports.PackingListExcel),
              },
            ],
          },
          // {
          //   icon: DocumentsType.PDF,
          //   title: "CONSIGNEE",
          //   options: [
          //     {
          //       title: "CONSIGNEE PACKING LIST PDF",
          //       icon: DocumentsType.PDF,
          //       action: () => handleClose(Reports.PackingListConsigneePDF),
          //     },
          //     {
          //       title: "CONSIGNEE PACKING LIST EXCEL",
          //       icon: DocumentsType.EXCEL,
          //       action: () => handleClose(Reports.PackingListConsigneeExcel),
          //     },
          //   ],
          // },
          // {
          //   icon: DocumentsType.PDF,
          //   title: "CLIENT & CONSIGNEE",
          //   options: [
          //     {
          //       title: "CLIENT & CONSIGNEE PACKING LIST INVOICE PDF",
          //       icon: DocumentsType.PDF,
          //       action: () => handleClose(Reports.PackingListClientAndConsigneePDF),
          //     },
          //     {
          //       title: "CLIENT & CONSIGNEE PACKING LIST INVOICE EXCEL",
          //       icon: DocumentsType.EXCEL,
          //       action: () => handleClose(Reports.PackingListClientAndConsigneeConsigneeExcel),
          //     },
          //   ],
          // },
        ],
      },
      {
        title: "ADDENDUM",
        action: () => handleClose(Reports.AddendumExcel),
        icon: DocumentsType.EXCEL,
      },
    ];
  }, []);

  return (
    <span style={{ ...style }}>
      <DocumentsButton menuItems={menuItems} disabled={false} />
    </span>
  );
};

export default withStyles(styles)(PrintMenu);
