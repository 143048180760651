import React from "react";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";

import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";

import { templates, templatesfull, templatesfullbyvar } from "../../lib/api/template";
import { templatesdetail, templatesdetailbytemplateid } from "../../lib/api/templatedetail";

import Confirmation from "../../lib/components/confirmation";

import { commoditiesAllSortedMappedforCombo } from "../../lib/api/commodity";
import { varietyAllSortedMappedforCombo } from "../../lib/api/variety";

import { targetsSorted } from "../../lib/api/target";
import { classesAllSortedMappedforCombo } from "../../lib/api/classes";
import { counts } from "../../lib/api/counts";
import { coldroomsAllSortedMappedforCombo } from "../../lib/api/coldroom";
import { brandsAllSortedMappedforCombo } from "../../lib/api/brand";
import { inventoriesAllSortedMappedforCombo } from "../../lib/api/inventory";
import { mgpsAllSortedMappedforCombo } from "../../lib/api/mgp";

import TemplatesMaster from "./templatesMaster";
import TemplatesMasterCrud from "./templatesMasterCrud";
import TemplatesDetail from "./templatesDetail";
import TemplatesDetailSelected from "./templatesDetailSelected";
import { getPalletSpecPackByVariety } from "../../lib/api/pack";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: "grid",
      width: "calc(100vw - 150px)",
      height: "calc(100vh - 150px)",
      // backgroundColor: "red",
    },
    container: {
      display: "grid",
      width: "inherit",
      height: "inherit",
      // backgroundColor: "purple",
      gridTemplateAreas: `
         "template templatedetail"
         "summary summary"
       `,
      gridTemplateColumns: "450px 1fr",
      gridTemplateRows: "4fr 7fr",
      position: "absolute",
    },
    containermaintenance: {
      display: "grid",
      width: "inherit",
      height: "inherit",
      // backgroundColor: "pink",
      gridTemplateAreas: `
         "template templatedetail"
       `,
      gridTemplateColumns: "500px 1fr",
      gridTemplateRows: "10fr",
    },
    template: {
      padding: theme.spacing(1),
      gridArea: "template",
      // backgroundColor: "pink",
    },
    templatedetail: {
      padding: theme.spacing(1),
      gridArea: "templatedetail",
      width: "100%",
      // backgroundColor: "lightyellow",
    },
    summary: {
      padding: theme.spacing(1),
      position: "absolute",
      gridArea: "summary",
      width: "inherit",
      height: "100%",
      // backgroundColor: "lightblue",
    },
    fullWidthHeigh: {
      width: "100%",
      height: "100%",
    },
  });

type TemplatesProps = {
  maintenancemode: boolean;
  handleAddTemplateLines?: any;
  varietyid?: any;
} & WithStyles<typeof styles>;

export class TemplatesUnstyled extends React.Component<TemplatesProps, any> {
  state = {
    maintenancemode: undefined,
    varietiesData: undefined,
    commoditiesData: undefined,
    targets: undefined,
    classes: undefined,
    counts: undefined,
    coldrooms: undefined,
    brands: undefined,
    inventories: undefined,
    packspec: undefined,
    mgps: undefined,
    // loading: true,
    templateData: [],
    templateDataLoading: true,
    templateDataCurrentRow: undefined,
    templateDetailData: [],
    templateDetailDataLoading: true,
    selectedTemplateDetailLines: [],
    confirmRemoveOpen: false,
    confirmRemoveID: undefined,
    confirmEditRow: undefined,
    handleAddTemplateLines: undefined,
    varietyid: undefined,
  };

  constructor(props) {
    super(props);
    this.state.classes = props.classes;
    this.state.maintenancemode = props.maintenancemode;
    this.state.handleAddTemplateLines = props.handleAddTemplateLines;
    this.state.varietyid = props.varietyid;
  }

  componentDidMount = () => {
    this.loadData();
  };

  loadData = async () => {
    this.setState({ templateDataLoading: true, templateDetailDataLoading: true });
    const resultTemplateData = this.state.varietyid ? await templatesfullbyvar(this.state.varietyid) : await templatesfull();
    const resultVarietyData = await varietyAllSortedMappedforCombo();
    const resultCommodityData = await commoditiesAllSortedMappedforCombo();
    this.setState({ templateData: resultTemplateData, varietiesData: resultVarietyData, commoditiesData: resultCommodityData }, () => {
      this.setState({ templateDataLoading: false });
    });

    const [resultTarget, resultClasses, resultCounts, resultColdrooms, resultBrands, resultInventories, resultMGP] = await Promise.all([
      targetsSorted(),
      classesAllSortedMappedforCombo(),
      counts.all(),
      coldroomsAllSortedMappedforCombo(),
      brandsAllSortedMappedforCombo(),
      inventoriesAllSortedMappedforCombo(),
      mgpsAllSortedMappedforCombo(),
    ]);
    this.setState(
      {
        targets: resultTarget.data,
        classes: resultClasses,
        counts: resultCounts,
        coldrooms: resultColdrooms,
        brands: resultBrands,
        inventories: resultInventories,
        mgps: resultMGP,
      },
      () => {
        this.setState({ templateDetailDataLoading: false });
      },
    );
  };

  handleRemoveTemplate = (templateid) => {
    this.setState({ confirmRemoveID: templateid, confirmRemoveOpen: true });
  };

  handleRemoveTemplateClose = () => {
    this.setState({ confirmRemoveID: undefined });
  };

  handleRemoveTemplateConfirm = () => {
    templates.remove(this.state.confirmRemoveID).then(() => {
      this.loadData().then(() => {
        this.setState({ confirmRemoveID: undefined, templateDetailData: [] });
      });
    });
  };

  handleEditTemplate = (row) => {
    this.setState({ confirmEditRow: row }, () => {});
  };

  handleEditTemplateClose = () => {
    this.setState({ confirmEditRow: undefined });
  };

  handleEditTemplateConfirm = (row) => {
    const id = row.id;
    const updateData = {
      data: {
        templatename: row.templatename,
        variety_id: row.variety_id,
      },
    };
    if (id) {
      templates.update(id, updateData).then(() => {
        this.loadData().then(() => {
          this.setState({ confirmEditRow: undefined });
        });
      });
    } else {
      templates.create(updateData).then(() => {
        this.loadData().then(() => {
          this.setState({ confirmEditRow: undefined });
        });
      });
    }
  };

  handleCreateTemplate = () => {
    this.setState({ confirmEditRow: {} }, () => {});
  };

  handleSaveTemplateDetail = async (rows) => {
    this.setState({ templateDetailDataLoading: true });
    try {
      const updated = rows.map((item) => {
        const id = item.id;
        const updateData = {
          data: {
            template_id: item.template_id,
            groupline: item.groupline,
            volume: item.volume,
            target_id: item.target_id,
            class: item.class,
            count: item.count,
            countconstraint: item.countconstraint,
            coldroom: item.coldroom,
            brand: item.brand,
            inventory: item.inventory,
            mgp: item.mgp,
            pack_id: item.pack_id,
          },
        };
        return templatesdetail.update(id, updateData);
      });
      await Promise.all(updated);
    } catch (error) {
      console.log(error);
    }
    // this.setState({ templateData})
    this.handleMasterClick(this.state.templateDataCurrentRow);
  };

  handleCreateTemplateDetail = async () => {
    if (this.state.templateDataCurrentRow.id != undefined) {
      const createData = {
        data: {
          template_id: this.state.templateDataCurrentRow.id,
          count: "",
          volume: "ONBEPERK",
        },
      };
      await templatesdetail.create(createData);
      this.handleMasterClick(this.state.templateDataCurrentRow);
    }
  };

  handleRemoveTemplateDetail = (id) => {
    this.setState({ templateDetailDataLoading: true });
    templatesdetail.remove(id).then(() => {
      this.handleMasterClick(this.state.templateDataCurrentRow);
    });
  };

  handleTemplateDetailSelectedAdd = (rows) => {
    const currRows = [...this.state.selectedTemplateDetailLines].map((line) => {
      const item = { ...line };
      delete item["rowIdx"];
      return item;
    });

    const newRows = [...rows];
    let groupnum = 1;
    if (this.state.selectedTemplateDetailLines.length > 0) {
      groupnum = Number(this.state.selectedTemplateDetailLines[this.state.selectedTemplateDetailLines.length - 1].groupnum) + 1;
    }
    newRows.map((row) => {
      const item = { ...row };

      delete item["rowIdx"];

      item.templatename = this.state.templateDataCurrentRow.templatename;
      item.groupnum = groupnum;

      // item.random = guid();
      currRows.push({ ...item });
    });
    this.setState({ selectedTemplateDetailLines: currRows }, () => {});
  };

  handleClearSelected = () => {
    this.setState({ selectedTemplateDetailLines: [] });
  };

  handleMasterClick = async (row) => {
    this.setState({ templateDetailDataLoading: true, templateDataCurrentRow: row });

    const id = row.id;
    const varietyid = row.variety_id;

    const [packspec, data] = await Promise.all([getPalletSpecPackByVariety(varietyid), templatesdetailbytemplateid(id)]);

    const indexed = data.map((item, i) => ({ ...item, rowIdx: i }));
    this.setState({ templateDetailData: indexed, packspec: packspec, templateDetailDataLoading: false });
  };

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        {this.state.confirmEditRow && (
          <TemplatesMasterCrud
            onSubmit={this.handleEditTemplateConfirm}
            onClose={this.handleEditTemplateClose}
            varietiesData={this.state.varietiesData}
            commoditiesData={this.state.commoditiesData}
            formRecord={{ ...this.state.confirmEditRow }}
          />
        )}
        {this.state.confirmRemoveID && (
          <Confirmation
            isOpen={this.state.confirmRemoveOpen}
            handleClose={this.handleRemoveTemplateClose}
            handleConfirm={this.handleRemoveTemplateConfirm}
            title="Remove selected template?"
            body="Are you sure you want to REMOVE the selected Template?"
          ></Confirmation>
        )}
        <div className={this.state.maintenancemode ? classes.containermaintenance : classes.container}>
          <div id="mastertemplate_div" className={classes.template}>
            {this.state.templateDataLoading && <CircularProgress />}
            {!this.state.templateDataLoading && (
              <div id="templatesMasterMain" className={classes.fullWidthHeigh}>
                {this.state.maintenancemode && (
                  <Button variant="contained" color="primary" style={{ margin: "2px" }} onClick={this.handleCreateTemplate}>
                    add new template
                  </Button>
                )}
                {!this.state.maintenancemode && <div style={{ height: "41px", width: "100%" }}></div>}
                <TemplatesMaster
                  maintenancemode={this.state.maintenancemode}
                  templateData={this.state.templateData}
                  handleRemoveTemplate={this.handleRemoveTemplate}
                  handleEditTemplate={this.handleEditTemplate}
                  handleMasterClick={this.handleMasterClick}
                />
              </div>
            )}
          </div>
          <div className={classes.templatedetail}>
            {this.state.templateDetailDataLoading && <CircularProgress />}
            {!this.state.templateDetailDataLoading && this.state.templateDataCurrentRow && (
              <div id="templatesDetailMain" className={classes.fullWidthHeigh}>
                <TemplatesDetail
                  maintenancemode={this.state.maintenancemode}
                  data={this.state.templateDetailData}
                  targets={this.state.targets}
                  counts={this.state.counts}
                  classesArr={this.state.classes}
                  packspecs={this.state.packspec}
                  coldrooms={this.state.coldrooms}
                  brands={this.state.brands}
                  inventories={this.state.inventories}
                  mgps={this.state.mgps}
                  handleRemove={this.handleRemoveTemplateDetail}
                  handleAdd={this.handleCreateTemplateDetail}
                  handleSave={this.handleSaveTemplateDetail}
                  handleTemplateDetailSelectedAdd={this.handleTemplateDetailSelectedAdd}
                />
              </div>
            )}
          </div>
          {!this.state.maintenancemode && (
            <div id="container_selectiondetail" hidden={this.state.maintenancemode} className={classes.summary}>
              {!this.state.templateDetailDataLoading && this.state.templateDataCurrentRow && (
                <TemplatesDetailSelected
                  data={this.state.selectedTemplateDetailLines}
                  handleClearSelected={this.handleClearSelected}
                  handleAddTemplateLines={this.state.handleAddTemplateLines}
                />
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(TemplatesUnstyled);
