import React, { useEffect } from "react";

import { Form, Field } from "react-final-form";
import { TextField } from "final-form-material-ui";

import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Check from "@material-ui/icons/Check";
import Close from "@material-ui/icons/Close";

import { vessel, getVesselByCode } from "../../lib/api/vessel";

import { SnackContext } from "../../lib/context/SnackContext";
import { GenerateErrorMessage } from "../../lib/helpers/string_methods";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1),
    },
    tableRowFieldTitle: {
      paddingTop: theme.spacing(1),
      paddingRight: theme.spacing(1) * 2,
      marginTop: theme.spacing(1),
      textAlign: "right",
      minWidth: "145px",
    },
    tableRowFieldData: {
      paddingTop: theme.spacing(1),
      paddingRight: theme.spacing(1) * 2,
      marginTop: theme.spacing(1),
      textAlign: "left",
    },
    tableRowFieldInput: {
      width: "100%",
    },
  });

type VesselDetailProps = {
  vessel_id: number | string;
  onClose: any;
  onSubmittedEvent: any;
} & WithStyles<typeof styles>;

const formatField = (value: string, removewhitespace: boolean = false) => (removewhitespace ? value.replaceAll(" ", "").toUpperCase() : value.toUpperCase());

const VesselDetailUnstyled: React.SFC<VesselDetailProps> = (props) => {
  const { classes, vessel_id, onClose, onSubmittedEvent } = props;
  const { updateSnack } = React.useContext(SnackContext);

  const [vesselData, setVesselData] = React.useState({});

  useEffect(() => {
    loadData();
  }, [vessel_id]);

  const loadData = async () => {
    const newData = {
      id: undefined,
      vesselType: undefined,
      code: undefined,
      description: undefined,
    };

    if (!vessel_id) {
      setVesselData(newData);
    } else {
      const result = await vessel.single(vessel_id);
      setVesselData(result);
    }
  };

  const onSubmitted = () => {
    onSubmittedEvent();
  };

  const onSubmit = async (vesselData, reactFinalForm) => {
    let id = vesselData["id"];
    delete vesselData["id"];

    // check if code is duplicated
    const [result] = await getVesselByCode(vesselData["code"]);

    if (result && id !== result.id) {
      updateSnack({ show: true, color: "red", message: "Vessel code is duplicated" });
      return;
    }

    try {
      const { code, description } = vesselData;
      const formData = { data: { ...vesselData, code: formatField(code, true), description: formatField(description) } };

      if (!id) {
        await vessel.create(formData);
      } else {
        await vessel.update(id, formData);
      }
    } catch (error) {
      const err = GenerateErrorMessage(error, "Error creating/updating Vessel");
      updateSnack({ show: true, color: "red", message: err });
    }
    onSubmitted();
    loadData();
  };

  return (
    <div style={{ marginTop: "-4px" }} className={classes.root}>
      <Form
        initialValues={{ ...vesselData }}
        onSubmit={onSubmit}
        render={({ handleSubmit, form }) => (
          <form onSubmit={handleSubmit}>
            <table>
              <tbody>
                <tr>
                  <td className={classes.tableRowFieldTitle}>
                    <span>Vessel Type:</span>
                  </td>
                  <td className={classes.tableRowFieldData}>
                    <Field fullWidth name="vesselType" component={TextField} type="text" className={classes.tableRowFieldInput} disabled={parseInt(vessel_id.toString())} />
                  </td>
                </tr>
                <tr>
                  <td className={classes.tableRowFieldTitle}>
                    <span>Code:</span>
                  </td>
                  <td className={classes.tableRowFieldData}>
                    <Field fullWidth name="code" component={TextField} type="text" className={classes.tableRowFieldInput} />
                  </td>
                </tr>
                <tr>
                  <td className={classes.tableRowFieldTitle}>
                    <span>Description:</span>
                  </td>
                  <td className={classes.tableRowFieldData}>
                    <Field fullWidth name="description" component={TextField} type="text" className={classes.tableRowFieldInput} />
                  </td>
                </tr>
              </tbody>
            </table>
            <div style={{ display: "flex", justifyContent: "flex-end", margin: "10px 0 -5px 0", paddingTop: "5px", paddingBottom: "5px" }}>
              <Button style={{ marginRight: "10px" }} color="secondary" variant="outlined" onClick={onClose}>
                <Close />
              </Button>
              <Button disabled={!form.getState().dirty} style={{ marginRight: "16px" }} type="submit" color="primary" variant="outlined">
                <Check />
              </Button>
            </div>
          </form>
        )}
      />
    </div>
  );
};
export default withStyles(styles)(VesselDetailUnstyled);
