import { client } from "./_base";

export type CompanyDetailsType = {
  id?: number;
  name: string;
  regno: string;
  vatno: string;
  telephoneNumber: string;
  faxNumber: string;
  address1: string;
  address2: string;
  address3: string;
  address4: string;
  address5: string;
  email: string;
  customscode: string;
  consoldefault_fob: number;
  consoldefault_cold: number;
  ggn_gln_no: string;
};
export const companyDetails = client("companydetails", (item) => ({
  ...item,
}));
