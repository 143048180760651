import { client } from "./_base";

export const temperaturecodes = client("temperaturecodes", (item) => ({
  ...item,
}));

export const getTemperatureReadyForCombo = () => {
  return temperaturecodes
    .all()
    .then((result) =>
      result
        .sort((a, b) => (a.description > b.description ? 1 : a.description < b.description ? -1 : 1))
        .map((tempcode) => ({ id: tempcode.code, value: tempcode.code, display: `${tempcode.code} - ${tempcode.description}`, data: tempcode })),
    );
};
