import React from "react";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";

import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import Toolbar from "@material-ui/core/Toolbar";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

import IconAdd from "@material-ui/icons/Add";

import { withRouter } from "react-router-dom";
import { Row } from "react-data-grid";

import Grid from "../../lib/components/grid";
import { getCreditorsFullSummary, CreditorsInvoiceSummaryType, creditorsinvoiceFull } from "../../lib/api/creditorsinvoice";
import { GridColumnsSummary } from "./summarygridsetup";
import { GridColumns } from "../invoice/creditorsinvoicegridsetup";
import { ExportDataCSV } from "../../lib/helpers/Files";
import CreditorsSummaryInvoices from "./invoicesgrid";

import format from "date-fns/format";
import { getFinancialYearSelected } from "../../lib/api/week";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      height: "calc(100% - 200px)",
      position: "relative",
    },
    gridWrapper: {
      position: "relative",
    },
    toolbar: {
      position: "absolute",
      left: 0,
      top: 0,
      height: "60px",
      width: "100%",
    },
  });

type CreditorsInvoiceSummaryProps = {
  match: any;
  location: any;
  history: any;
} & WithStyles<typeof styles>;

type State = {
  data: CreditorsInvoiceSummaryType[];
  loading: boolean;
  gridHeight: number;
  tabIndex: number;
};

const formatDate = (date: Date | string) => {
  if (!date || date.toString().length == 0) {
    return "";
  }
  return format(new Date(date), "dd MMM yyyy");
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

class CreditorsInvoiceSummary extends React.Component<CreditorsInvoiceSummaryProps, any> {
  state: State = {
    data: [],
    loading: true,
    gridHeight: 500,
    tabIndex: 0,
  };

  constructor(props) {
    super(props);
  }

  loadData = async () => {
    this.setState({ loading: true });
    const result = await getCreditorsFullSummary(getFinancialYearSelected());
    this.setState({ loading: false, data: result });
  };

  componentDidMount() {
    this.loadData().then(() => {
      const mainClientheight = document.getElementById("divDetails");
      this.setState({ gridHeight: mainClientheight && mainClientheight.clientHeight - 50 });
    });
  }

  handleSelectCreditor = (row: CreditorsInvoiceSummaryType) => {
    this.props.history.push(`/creditors/invoice/${row.creditor_code}`);
  };

  handleCreateNewInvoice = () => {
    this.props.history.push(`/creditors/info/0`);
  };

  rowRenderer = (props) => {
    const { row } = props;
    return <Row {...props} onDoubleClick={() => this.handleSelectCreditor(row)} />;
  };

  handleExportFullGrid = async () => {
    const data = await creditorsinvoiceFull(getFinancialYearSelected());
    const columns = GridColumns(
      data,
      {},
      [],
      [],
      () => {},
      () => {},
      () => {},
    );
    const formattedData = data.map((item) => ({
      ...item,
      invoicedate_formatted: formatDate(item.invoicedate),
      due_date_formatted: formatDate(item.due_date),
      adjustdate_formatted: formatDate(item.adjustdate),
    }));
    await ExportDataCSV("CREDITORS_FULL.xlsx", formattedData, columns);
  };

  generateExportName = () => {
    return `${format(new Date(), "yyMMdd")}_CREDITOR_SUMMARY`;
  };

  handleChange = (event, newValue) => {
    this.setState({ tabIndex: newValue });
  };

  render() {
    const { classes } = this.props;

    return (
      <div id="divDetails" className={classes.root}>
        <AppBar position="static">
          <Tabs value={this.state.tabIndex} onChange={this.handleChange} aria-label="simple tabs example">
            <Tab label="Summary" {...a11yProps(0)} />
            <Tab label="Invoices" {...a11yProps(1)} />
          </Tabs>
        </AppBar>
        <TabPanel value={this.state.tabIndex} index={0}>
          <div className={classes.gridWrapper}>
            <Toolbar className={classes.toolbar}>
              <Tooltip title="Create new Creditors Invoice">
                <Button variant="contained" color="primary" onClick={this.handleCreateNewInvoice}>
                  <IconAdd />
                </Button>
              </Tooltip>
              <Tooltip title="Export all">
                <Button variant="contained" color="primary" onClick={this.handleExportFullGrid} style={{ marginLeft: "10px" }}>
                  Export All
                </Button>
              </Tooltip>
            </Toolbar>
            <Grid
              loading={this.state.loading}
              data={this.state.data}
              GridColumns={GridColumnsSummary}
              clearFilters={"creditorsinvoicesummary"}
              forceHeight={this.state.gridHeight}
              handleRefresh={this.loadData}
              totalRowColumns={["totalExcl", "totalVat", "total"]}
              rowRenderer={this.rowRenderer}
              exportName={this.generateExportName()}
            />
          </div>
        </TabPanel>
        <TabPanel value={this.state.tabIndex} index={1}>
          <CreditorsSummaryInvoices />
        </TabPanel>
      </div>
    );
  }
}

export default withStyles(styles)(withRouter(CreditorsInvoiceSummary));
