import React, { useContext, useEffect, useState } from "react";

import { createStyles, Theme, WithStyles, withStyles } from "@material-ui/core/styles";

import AppBar from "@material-ui/core/AppBar";
import Dialog from "@material-ui/core/Dialog";
import IconButton from "@material-ui/core/IconButton";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";

import IconClose from "@material-ui/icons/Close";
import IconDownload from "@material-ui/icons/PhotoLibrary";

import { getInspectionNotes } from "../lib/api/inspection";
import { SnackContext } from "../lib/context/SnackContext";
import { GenerateErrorMessage } from "../lib/helpers/string_methods";
import { actionsFindByInspectionId } from "../lib/api/inspectionactions";

import JSZip from "jszip";
import filesaver from "file-saver";
import LZString from "lz-string";

const styles = (theme: Theme) =>
  createStyles({
    root: {},
    appBar: {
      position: "relative",
    },
    title: {
      color: "white",
      marginLeft: theme.spacing(1) * 2,
    },
    print: {
      margin: theme.spacing(1) * 1,
    },
    imagesWrapper: {
      display: "flex",
      flexDirection: "row",
      width: "100vw",
      height: "100%",
      overflowY: "scroll",
      flexWrap: "wrap",
      padding: `${theme.spacing(1) * 2}px`,
      gap: `${theme.spacing(1) * 2}px`,
      "& img": {
        height: "400px",
      },
    },
    inspectionnote: {
      paddingLeft: `${theme.spacing(1) * 2}px`,
      paddingRight: `${theme.spacing(1) * 2}px`,
      "& span": {
        fontWeight: "normal",
      },
    },
  });

type InpsectionMarketImagesProps = {
  inspection: any;
  handleClose: () => void;
} & WithStyles<typeof styles>;

const InpsectionMarketImagesUnstyled: React.FC<InpsectionMarketImagesProps> = ({ classes, inspection, handleClose }) => {
  const { updateSnack } = useContext(SnackContext);

  const [images, setImages] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [inspectionNote, setInspectionNote] = useState({ comment: "", images: [] });

  const loadImages = async () => {
    setLoading(true);

    try {
      const [actions, inspectionNoteResult] = await Promise.all([actionsFindByInspectionId(inspection.id), getInspectionNotes(inspection.id)]);
      const actionImages = actions.reduce((arr, item) => {
        if (item.field === "general") {
          const itemImages = JSON.parse(item.images || "[]").map((image) => (image.base64 ? image.base64 : image));
          arr.push(...itemImages);
        }
        return arr;
      }, []);
      setImages(actionImages);

      if (inspectionNoteResult) {
        setInspectionNote({ ...inspectionNoteResult, images: JSON.parse(inspectionNoteResult.images) });
      }
    } catch (error) {
      const err = GenerateErrorMessage(error, "Error loading data");
      updateSnack({ show: true, color: "red", message: err });
    }

    setLoading(false);
  };

  useEffect(() => {
    loadImages();
  }, [inspection]);

  const handleDownloadImages = () => {
    const zip = new JSZip();
    const allImages = [...images, ...inspectionNote.images];

    try {
      for (let idx = 0; idx < allImages.length; idx++) {
        const image = allImages[idx];
        const imgB64StringDCompressed = image.includes("base64") ? image : LZString.decompress(image);

        const imageName = `${inspection.piorder_ordernum}_${idx}_${inspection.number}.${imgB64StringDCompressed
          .toString()
          .substring(imgB64StringDCompressed.indexOf("/") + 1, imgB64StringDCompressed.indexOf(";"))}`;
        zip.file(imageName, imgB64StringDCompressed.split("base64,").pop().toString(), { base64: true });
      }

      const fileName = `Market_Images_${inspection.number}.zip`;
      zip.generateAsync({ type: "blob", mimeType: "application/zip" }).then(function (content) {
        filesaver.saveAs(new Blob([content], { type: "application/zip" }), fileName);
      });
    } catch (error) {
      const err = GenerateErrorMessage(error, "Unable to export Marketing Images");
      updateSnack({ show: true, color: "red", message: err });
    }
  };

  return (
    <div className={classes.root}>
      <Dialog fullScreen open={true} onClose={handleClose}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton color="inherit" onClick={handleClose} aria-label="Close">
              <IconClose />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              Market Images
            </Typography>
            <IconButton color="inherit" onClick={handleDownloadImages} className={classes.print} aria-label="Download zipped images">
              <IconDownload />
            </IconButton>
          </Toolbar>
        </AppBar>
        {!loading && (
          <div className={classes.inspectionnote}>
            <h2>
              Inspection comment: <span>{inspectionNote.comment != "" && inspectionNote.comment ? inspectionNote.comment : "N/A"}</span>
            </h2>
          </div>
        )}
        <div className={classes.imagesWrapper}>
          {!loading && images.length === 0 && <h2>No images available.</h2>}
          {[...images, ...inspectionNote.images].map((image, idx) => (
            <img key={`image${idx}`} src={image.includes("base64") ? image : LZString.decompress(image)} alt="captured" />
          ))}
        </div>
      </Dialog>
    </div>
  );
};

export default withStyles(styles)(InpsectionMarketImagesUnstyled);
