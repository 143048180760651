import React from "react";
import CustomAutoCompleteFilter from "../../../lib/components/customdatagridfilter/CustomFilter";

import TextEditor from "../../../lib/components/TextEditor";
import { FormatterNumber, FormatterNumber2Decimals } from "../../../lib/components/Formatters";

const cellWidthSmallX = 100;
const cellWidthSmall = 180;

const defaultColumnProperties = {
  filterable: true,
  resizable: true,
  sortable: true,
};

let currentSpace = true;

export const GridColumnsGroupedFruitSpec = (data, filters, columnsWidth, section) => {
  let postValue = "";

  if (data) {
    if (currentSpace) {
      postValue = " ";
    }
    currentSpace = !currentSpace;
  }

  const columns = [
    {
      key: "barcode_commoditycode",
      name: "Commodity" + postValue,
      width: cellWidthSmallX,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"barcode_commoditycode"} data={data} section={section} />;
      },
    },
    {
      key: "barcode_varietycode",
      name: "Variety" + postValue,
      width: cellWidthSmallX,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"barcode_varietycode"} data={data} section={section} />;
      },
    },
    {
      key: "barcode_packcode",
      name: "Pack" + postValue,
      width: cellWidthSmallX,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"barcode_packcode"} data={data} section={section} />;
      },
    },
    {
      key: "barcode_markcode",
      name: "Mark" + postValue,
      width: cellWidthSmallX,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"barcode_markcode"} data={data} section={section} />;
      },
    },
    {
      key: "barcode_gradecode",
      name: "Grade" + postValue,
      width: cellWidthSmallX,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"barcode_gradecode"} data={data} section={section} />;
      },
    },
    {
      key: "barcode_countcode",
      name: "Count" + postValue,
      width: cellWidthSmallX,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"barcode_countcode"} data={data} section={section} />;
      },
      formatter: FormatterNumber,
    },
    {
      key: "barcode_inventorycode",
      name: "Inventory" + postValue,
      width: cellWidthSmallX,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"barcode_inventorycode"} data={data} section={section} />;
      },
    },
    {
      key: "new_palletsize",
      name: "PalletSize" + postValue,
      width: cellWidthSmallX,
      formatter: FormatterNumber2Decimals,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"new_palletsize"} data={data} section={section} />;
      },
    },
    {
      key: "new_nocartons",
      name: "No Cartons" + postValue,
      width: cellWidthSmallX,
      formatter: FormatterNumber,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"new_nocartons"} data={data} section={section} />;
      },
    },
    {
      key: "stockdetail_sellingprice",
      name: "Producer Price" + postValue,
      width: cellWidthSmall,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"stockdetail_sellingprice"} data={data} section={section} />;
      },
      formatter: (props) => <FormatterNumber2Decimals {...props} style={{ color: props.row.sale_id ? "black" : "red" }} />,
      editable: true,
      editor: (props) => <TextEditor {...props} align="right" />,
      editorOptions: {
        editOnClick: true,
      },
    },
    {
      key: "stockdetail_producerprice",
      name: "Selling Price" + postValue,
      width: cellWidthSmall,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"stockdetail_producerprice"} data={data} section={section} />;
      },
      formatter: FormatterNumber2Decimals,
      // editable: true,
      // editor: (props) => <TextEditor {...props} align="right" />,
    },
  ];

  const columnsDefault = columns.map((c) => ({ ...c, ...defaultColumnProperties }));

  if (columnsWidth && columnsWidth.length > 0) {
    for (let index = 0; index < columnsDefault.length; index++) {
      const element = columnsDefault[index];
      const colItem = columnsWidth.find((colwitem) => colwitem.name.trim() == element.name.trim());
      if (colItem) {
        element.width = colItem.width;
      }
    }
  }

  return columnsDefault;
};
