import React from "react";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";

import Input from "@material-ui/core/Input";

import Check from "@material-ui/icons/Check";
import Close from "@material-ui/icons/Close";

import Button from "@material-ui/core/Button";

import { phtreatment } from "../../lib/api/phtreatment";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1),
      width: "450px",
    },
    tableRow: {
      paddingTop: theme.spacing(1),
      paddingRight: theme.spacing(1) * 2,
      marginTop: theme.spacing(1),
      textAlign: "right",
    },
    codeInput: {
      width: "100%",
      float: "left",
    },
  });

type PHTreatmentsEditFormProps = {
  onSubmit: any;
  onClose: any;
  id: any;
} & WithStyles<typeof styles>;

class PHTreatmentsEditFormUnstyled extends React.Component<PHTreatmentsEditFormProps, any> {
  state = {
    classes: undefined,
    item: {
      id: "0",
      commoditycode: "",
      exportmarket: "",
      mrl: "",
      activeingredient: "",
      product: "",
      waterapplication100: "",
      waxapplication100: "",
      comments: "",
    },
    onSubmit: undefined,
    onClose: undefined,
  };

  constructor(props) {
    super(props);
    this.state.classes = props.classes;
    this.state.onSubmit = props.onSubmit;
    this.state.onClose = props.onClose;
    if (props.id != 0) {
      this.getItem(props.id);
    }
  }

  getItem = (id) => {
    phtreatment.single(id).then((data) => {
      this.setState({ item: data });
    });
  };

  handleFocus = (event) => {
    event.target.select();
  };

  handleSubmit = (e) => {
    this.state.onSubmit(e, this.state.item);
  };

  render() {
    const { classes } = this.state;

    return (
      <div className={classes.root}>
        <form
          id={this.state.item.id}
          onSubmit={(e) => {
            this.handleSubmit(e);
          }}
        >
          <table style={{ marginLeft: "10%" }}>
            <tbody>
              <tr className={classes.tableRow}>
                <td className={classes.tableRow}>
                  <span>Commodity Code:</span>
                </td>
                <td className={classes.tableRow}>
                  <Input
                    className={classes.codeInput}
                    onFocus={this.handleFocus}
                    key={"body_value_commoditycode"}
                    name={"commoditycode"}
                    value={this.state.item.commoditycode}
                    onChange={(e) => {
                      this.setState({
                        item: { ...this.state.item, commoditycode: e.target.value },
                      });
                    }}
                  />
                </td>
              </tr>
              <tr className={classes.tableRow}>
                <td className={classes.tableRow}>
                  <span>Export Market:</span>
                </td>
                <td className={classes.tableRow}>
                  <Input
                    className={classes.codeInput}
                    onFocus={this.handleFocus}
                    key={"body_value_exportmarket"}
                    name={"exportmarket"}
                    value={this.state.item.exportmarket}
                    onChange={(e) => {
                      this.setState({
                        item: { ...this.state.item, exportmarket: e.target.value },
                      });
                    }}
                  />
                </td>
              </tr>
              <tr className={classes.tableRow}>
                <td className={classes.tableRow}>
                  <span>MRL:</span>
                </td>
                <td className={classes.tableRow}>
                  <Input
                    className={classes.codeInput}
                    onFocus={this.handleFocus}
                    key={"body_value_mrl"}
                    name={"mrl"}
                    value={this.state.item.mrl}
                    onChange={(e) => {
                      this.setState({
                        item: { ...this.state.item, mrl: e.target.value },
                      });
                    }}
                  />
                </td>
              </tr>
              <tr className={classes.tableRow}>
                <td className={classes.tableRow}>
                  <span>Active Ingredient:</span>
                </td>
                <td className={classes.tableRow}>
                  <Input
                    className={classes.codeInput}
                    onFocus={this.handleFocus}
                    key={"body_value_activeingredient"}
                    name={"activeingredient"}
                    value={this.state.item.activeingredient}
                    onChange={(e) => {
                      this.setState({
                        item: { ...this.state.item, activeingredient: e.target.value },
                      });
                    }}
                  />
                </td>
              </tr>
              <tr className={classes.tableRow}>
                <td className={classes.tableRow}>
                  <span>Product:</span>
                </td>
                <td className={classes.tableRow}>
                  <Input
                    className={classes.codeInput}
                    onFocus={this.handleFocus}
                    key={"body_value_product"}
                    name={"product"}
                    value={this.state.item.product}
                    onChange={(e) => {
                      this.setState({
                        item: { ...this.state.item, product: e.target.value },
                      });
                    }}
                  />
                </td>
              </tr>
              <tr className={classes.tableRow}>
                <td className={classes.tableRow}>
                  <span>Water Application 100:</span>
                </td>
                <td className={classes.tableRow}>
                  <Input
                    className={classes.codeInput}
                    onFocus={this.handleFocus}
                    key={"body_value_waterapplication100"}
                    name={"waterapplication100"}
                    value={this.state.item.waterapplication100}
                    onChange={(e) => {
                      this.setState({
                        item: { ...this.state.item, waterapplication100: e.target.value },
                      });
                    }}
                  />
                </td>
              </tr>
              <tr className={classes.tableRow}>
                <td className={classes.tableRow}>
                  <span>Wax Application 100:</span>
                </td>
                <td className={classes.tableRow}>
                  <Input
                    className={classes.codeInput}
                    onFocus={this.handleFocus}
                    key={"body_value_waxapplication100"}
                    name={"waxapplication100"}
                    value={this.state.item.waxapplication100}
                    onChange={(e) => {
                      this.setState({
                        item: { ...this.state.item, waxapplication100: e.target.value },
                      });
                    }}
                  />
                </td>
              </tr>
              <tr className={classes.tableRow}>
                <td className={classes.tableRow}>
                  <span>Comments:</span>
                </td>
                <td className={classes.tableRow}>
                  <Input
                    className={classes.codeInput}
                    onFocus={this.handleFocus}
                    key={"body_value_comment"}
                    name={"comments"}
                    value={this.state.item.comments ? this.state.item.comments : ""}
                    onChange={(e) => {
                      this.setState({
                        item: { ...this.state.item, comments: e.target.value },
                      });
                    }}
                  />
                </td>
              </tr>
            </tbody>
          </table>
          <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "20px", marginRight: "14px" }}>
            <Button style={{ marginRight: "10px" }} color="secondary" variant="outlined" onClick={this.state.onClose}>
              <Close />
            </Button>
            <Button type="submit" color="primary" variant="outlined" onClick={this.handleSubmit}>
              <Check />
            </Button>
          </div>
        </form>
      </div>
    );
  }
}

export default withStyles(styles)(PHTreatmentsEditFormUnstyled);
