import React from "react";
import { createStyles, withStyles, WithStyles, Theme } from "@material-ui/core/styles";

import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";

import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";

import Print from "@material-ui/icons/Print";
import GetApp from "@material-ui/icons/GetApp";

const ITEM_HEIGHT = 58;

const styles = (theme: Theme) => createStyles({});

type PrintMenuProps = {
  handleExportExcel: () => any;
  handleExportPDF: () => any;
} & WithStyles<typeof styles>;

class PrintMenu extends React.Component<PrintMenuProps, any> {
  state = {
    anchorEl: null,
    handleExportExcel: undefined,
    handleExportPDF: undefined,
  };

  constructor(props: any) {
    super(props);
    this.state.handleExportExcel = props.handleExportExcel;
    this.state.handleExportPDF = props.handleExportPDF;
  }

  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleExcelRequested = () => {
    this.handleClose();
    this.state.handleExportExcel();
  };

  handlePDFRequested = () => {
    this.handleClose();
    this.state.handleExportPDF();
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  render() {
    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);
    return (
      <>
        <Button type="submit" color="primary" variant="outlined" aria-label="More" aria-owns={open ? "long-menu" : undefined} aria-haspopup="true" onClick={this.handleClick}>
          <GetApp />
        </Button>
        <Menu
          id="long-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={() => {
            this.handleClose();
          }}
          PaperProps={{
            style: {
              maxHeight: ITEM_HEIGHT * 4.5,
              width: 250,
            },
          }}
        >
          <MenuItem
            key={`menuitem-pdf`}
            onClick={() => {
              this.handlePDFRequested();
            }}
          >
            <ListItemIcon>
              <Print style={{ color: "orange" }} />
            </ListItemIcon>
            <ListItemText primary={"PRINT PDF"} />
          </MenuItem>

          <MenuItem
            key={`menuitem-excel`}
            onClick={() => {
              this.handleExcelRequested();
            }}
          >
            <ListItemIcon>
              <Print style={{ color: "orange" }} />
            </ListItemIcon>
            <ListItemText primary={"PRINT EXCEL"} />
          </MenuItem>
        </Menu>
      </>
    );
  }
}

export default withStyles(styles)(PrintMenu);
