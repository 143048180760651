import React from "react";

import CustomAutoCompleteFilter from "../../../lib/components/customdatagridfilter/CustomFilter";
import { FormatterNumber2Decimals } from "../../../lib/components/Formatters";
import { SummaryNumber2Decimal } from "../../../lib/components/SummaryFormatter";
import { handleColumnsArrangement, handleColumnsWidth } from "../../../lib/helpers/grid";

import Checkbox from "@material-ui/core/Checkbox";
import { ProducerAdvancePaidAdHocGetAdHocRemainingType } from "../../../lib/api/produceradvancepaidadhoc";

const cellWidthTiny = 40;
const cellWidthSmall = 80;
const cellWidthMedium = 100;
const cellWidthLarge = 150;
const cellWidthXLarge = 200;

const defaultColumnProperties = {
  resizable: true,
  sortable: true,
};

let currentSpace = true;

export const GridColumns = (
  data: ProducerAdvancePaidAdHocGetAdHocRemainingType[],
  filters,
  arrangement,
  columnsWidth,
  checked: ProducerAdvancePaidAdHocGetAdHocRemainingType[],
  handleToggle: (selected: ProducerAdvancePaidAdHocGetAdHocRemainingType) => void,
  produceradvancepaidhead_id: number,
) => {
  let postValue = "";

  if (data) {
    if (currentSpace) {
      postValue = " ";
    }
    currentSpace = !currentSpace;
  }

  const columns: any = [
    {
      key: "actions",
      name: " ",
      width: cellWidthSmall,
      formatter: ({ row }: { row: ProducerAdvancePaidAdHocGetAdHocRemainingType }) => {
        if (row.produceradvancepaidhead_id && row.produceradvancepaidhead_id != produceradvancepaidhead_id) return <div></div>;

        return (
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%", height: "100%" }} onClick={() => handleToggle(row)}>
            <Checkbox
              edge="start"
              disableRipple
              style={{ padding: 0 }}
              checked={!!checked.find((item) => item.adhoc_id == row.adhoc_id)}
              inputProps={{ "aria-labelledby": `checkbox-list-label-${row.adhoc_id}` }}
            />
          </div>
        );
      },
    },
    {
      key: "currency_code",
      name: "Currency" + postValue,
      width: cellWidthSmall,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname="currency_code" data={data} section="selectadhocgrid" />;
      },
    },
    {
      key: "paidamount",
      name: "Amount" + postValue,
      width: cellWidthLarge,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname="paidamount" data={data} section="selectadhocgrid" />;
      },
      formatter: FormatterNumber2Decimals,
      summaryFormatter: SummaryNumber2Decimal,
    },
    {
      key: "note",
      name: "Adhoc Note" + postValue,
      width: cellWidthXLarge,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname="note" data={data} section="selectadhocgrid" />;
      },
    },
    {
      key: "week",
      name: "Payment Week" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname="week" data={data} section="selectadhocgrid" />;
      },
    },
    {
      key: "produceradvancepaidhead_makeanote",
      name: "Paid Note" + postValue,
      width: cellWidthXLarge,
      filterable: true,
      filterRenderer: (e) => {
        return (
          <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname="produceradvancepaidhead_makeanote" data={data} section="selectadhocgrid" />
        );
      },
    },
    {
      key: "produceradvancepaidhead_ident",
      name: "Paid Ident" + postValue,
      width: cellWidthSmall,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname="produceradvancepaidhead_ident" data={data} section="selectadhocgrid" />;
      },
    },
  ];

  const defaultColumns = columns.map((c) => ({ ...c, ...defaultColumnProperties }));

  let columnsArranged = [];
  columnsArranged = handleColumnsArrangement(columnsArranged, arrangement, defaultColumns);
  columnsArranged = handleColumnsWidth(columnsArranged, columnsWidth, defaultColumns);

  return columnsArranged;
};
