import { client, request } from "./_base";
import { config } from "../../config";

// userProfiles api exports
const endpoint = "userProfiles";
export const userProfiles = client(endpoint, (item) => ({
  ...item,
}));

export const userProfileRemoveByUserID = (userid) => {
  return request(`${endpoint}/ex/removeByUserId/${userid}`, {
    method: "DELETE",
  })
    .then(async (result) => {
      if (result.status == 500) {
        const resp = await result.json();
        throw resp;
      }
      return result.json();
    })
    .then(({ data }) => {
      return data;
    });
};
