import React, { useEffect, useMemo, useState, useCallback } from "react";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";

import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";

import Check from "@material-ui/icons/Check";
import Close from "@material-ui/icons/Close";

// import { FormApi } from "final-form";
import { Form, Field } from "react-final-form";
import { TextField, Select, Checkbox } from "final-form-material-ui";
import ReactDatePicker from "react-datepicker";

import { producerphdetail, ProducerPHDetailType } from "../../lib/api/producerphdetail";
import { commoditiesAllSortedMappedforCombo } from "../../lib/api/commodity";
import { targetsDistinctMarkets } from "../../lib/api/target";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1),
      paddingTop: theme.spacing(2),
      width: "700px",
      height: "550px",
    },
    tableRowFieldTitle: {
      paddingTop: theme.spacing(1),
      paddingRight: theme.spacing(1) * 2,
      marginTop: theme.spacing(1),
      textAlign: "right",
      minWidth: "100px",
    },
    tableRowFieldData: {
      paddingTop: theme.spacing(0.5),
      textAlign: "left",
      width: "200px",
    },
    tableRowFieldCombo: {
      textAlign: "left",
      width: "200px",
    },
    tableRowFieldCalender: {
      padding: theme.spacing(0.5),
      marginTop: theme.spacing(1),
      textAlign: "left",
      width: "200px",
    },
  });

type ProducerPHDetailEditFormProps = {
  onSubmit: any;
  onClose: any;
  id: any;
} & WithStyles<typeof styles>;

const ProducerPHDetailEditFormUnstyled: React.FunctionComponent<ProducerPHDetailEditFormProps> = (props) => {
  const { classes, onSubmit, onClose, id } = props;

  const [item, setItem] = React.useState<ProducerPHDetailType>({
    id: 0,
    producerph_id: 0,
    commoditycode: "",
    exportmarket: "",
    mrl: "",
    activeingredient: "",
    product: "",
    waterapplication100: "",
    waxapplication100: "",
    comments: "",
  });

  const [commodities, setCommodities] = useState([]);
  const [markets, setMarkets] = useState([]);

  useEffect(() => {
    producerphdetail.single(id).then((data: ProducerPHDetailType) => {
      if (data) {
        setItem({ ...data });
      }
    });
    commoditiesAllSortedMappedforCombo().then((result) => {
      setCommodities(result);
    });
    targetsDistinctMarkets().then((result) => {
      const resultReadyForCombo = result.data.map((item) => {
        return { value: item.market, display: item.market };
      });
      setMarkets(resultReadyForCombo);
    });
  }, [id]);

  return (
    <div className={classes.root}>
      <Form
        initialValues={{
          ...item,
        }}
        onSubmit={(values: ProducerPHDetailType) => {
          onSubmit(values);
        }}
        validate={(values: ProducerPHDetailType) => {
          let errors = {};
          return errors;
        }}
        render={({ handleSubmit, form, values }) => {
          return (
            <form
              id={id}
              onSubmit={(values) => {
                handleSubmit(values);
              }}
            >
              <div style={{ display: "flex", flexDirection: "row" }}>
                <div style={{ flexGrow: 1 }}>
                  <table>
                    <tbody>
                      <TableFieldCombo classes={classes} title="Commodity" field="commoditycode" data={commodities} />
                      <TableFieldCombo classes={classes} title="Export Market" field="exportmarket" data={markets} />
                      <TableFieldText classes={classes} field="mrl" title="MRL" disabled={false} multiline={false} required={false} />
                      <TableFieldText classes={classes} field="activeingredient" title="Active Ingredient" disabled={false} multiline={false} required={false} />
                      <TableFieldText classes={classes} field="product" title="Product" disabled={false} multiline={false} required={false} />
                      <TableFieldText classes={classes} field="waterapplication100" title="Water Appl. 100" disabled={false} multiline={false} required={false} />
                      <TableFieldText classes={classes} field="waxapplication100" title="Wax Appl. 100" disabled={false} multiline={false} required={false} />
                      <TableFieldText classes={classes} field="comments" title="Comments" disabled={false} multiline={false} required={false} />
                    </tbody>
                  </table>
                  <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "20px", marginRight: "28px" }}>
                    <Button style={{ marginRight: "10px" }} color="secondary" variant="outlined" onClick={onClose}>
                      <Close />
                    </Button>
                    <Button type="submit" color="primary" variant="outlined">
                      <Check />
                    </Button>
                  </div>
                </div>
              </div>
            </form>
          );
        }}
      />
    </div>
  );
};
export default withStyles(styles)(ProducerPHDetailEditFormUnstyled);

export const TableFieldText: React.FunctionComponent<{ field: string; title: string; disabled: boolean; multiline: boolean; required: boolean } & WithStyles<typeof styles>> = (
  props,
) => {
  const { classes, title, field, disabled, multiline, required } = props;

  return (
    <tr>
      <td className={classes.tableRowFieldTitle}>
        <span>{`${title}: `}</span>
      </td>
      <td>
        <Field
          name={field}
          key={`body_value_${field}`}
          component={TextField}
          type="text"
          className={classes.tableRowFieldData}
          multiline={multiline}
          rows={multiline ? "4" : "1"}
          required={required}
          disabled={disabled}
        />
      </td>
    </tr>
  );
};

export const TableFieldCombo: React.FunctionComponent<{ classes: any; title: string; field: string; data: any } & WithStyles<typeof styles>> = (props) => {
  const { classes, title, field, data } = props;

  return (
    <tr>
      <td className={classes.tableRowFieldTitle}>{`${title}:`}</td>
      <td>
        <Field
          name={field}
          component={Select}
          formControlProps={{
            className: classes.tableRowFieldCombo,
          }}
          native
        >
          <option key={`none-1`} value={0}>
            {`NONE`}
          </option>
          {data &&
            data.length > 0 &&
            data.map((item, index) => {
              return (
                <option key={`${item.value}${index}`} value={item.value}>
                  {item.display}
                </option>
              );
            })}
        </Field>
      </td>
    </tr>
  );
};
