import React from "react";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";

import Check from "@material-ui/icons/Check";
import Close from "@material-ui/icons/Close";

import Button from "@material-ui/core/Button";

import { pack } from "../../lib/api/pack";
import Input from "@material-ui/core/Input";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1),
      width: "100%",
    },
    tableRow: {
      paddingTop: theme.spacing(1),
      paddingRight: theme.spacing(1) * 2,
      marginTop: theme.spacing(1),
      textAlign: "right",
    },
  });

type PackEditFormProps = {
  onSubmit: any;
  onClose: any;
  id: any;
} & WithStyles<typeof styles>;

class PackEditFormUnstyled extends React.Component<PackEditFormProps, any> {
  state = {
    classes: undefined,
    item: {
      id: "0",
      "Commodity ID": "",
      code: "",
      "No Cartons": 0,
      Description: "",
      "Size Table": "",
      packagingweight: 0,
      "Gross Weight": 0,
      "Nett Weight": 0,
      "Standard Carton": 0,
      Height: 0,
      Width: 0,
      Depth: 0,
    },
    onSubmit: undefined,
    onClose: undefined,
    id: 0,
  };

  constructor(props) {
    super(props);
    this.state.classes = props.classes;
    this.state.onSubmit = props.onSubmit;
    this.state.onClose = props.onClose;
    this.state.id = props.id;
  }

  componentDidMount() {
    if (this.state.id != 0) {
      this.getItem(this.state.id);
    }
  }

  getItem = (id) => {
    pack.single(id).then((data) => {
      this.setState({ item: data });
    });
  };

  handleFocus = (event) => {
    event.target.select();
  };

  handleSubmit = (e) => {
    this.state.onSubmit(e, this.state.item);
  };

  handleOnChange = (field, value) => {
    this.setState({
      item: { ...this.state.item, [field]: value },
    });
  };

  render() {
    const { classes } = this.state;

    return (
      <div className={classes.root}>
        <form
          id={this.state.item.id}
          onSubmit={(e) => {
            this.handleSubmit(e);
          }}
        >
          <table style={{ marginLeft: "15px" }}>
            <tbody>
              <tr className={classes.tableRow}>
                <td className={classes.tableRow}>
                  <span>Commodity ID:</span>
                </td>
                <td className={classes.tableRow}>{TableInputText("Commodity ID", this.state.item["Commodity ID"], this.handleFocus, this.handleOnChange)}</td>
              </tr>
              <tr className={classes.tableRow}>
                <td className={classes.tableRow}>
                  <span>Code:</span>
                </td>
                <td className={classes.tableRow}>{TableInputText("code", this.state.item.code, this.handleFocus, this.handleOnChange)}</td>
              </tr>
              <tr className={classes.tableRow}>
                <td className={classes.tableRow}>
                  <span>No Cartons:</span>
                </td>
                <td className={classes.tableRow}>{TableInputText("No Cartons", this.state.item["No Cartons"], this.handleFocus, this.handleOnChange)}</td>
              </tr>
              <tr className={classes.tableRow}>
                <td className={classes.tableRow}>
                  <span>Description:</span>
                </td>
                <td className={classes.tableRow}>{TableInputText("Description", this.state.item["Description"], this.handleFocus, this.handleOnChange)}</td>
              </tr>
              <tr className={classes.tableRow}>
                <td className={classes.tableRow}>
                  <span>Packaging Weight:</span>
                </td>
                <td className={classes.tableRow}>{TableInputText("packagingweight", this.state.item["packagingweight"], this.handleFocus, this.handleOnChange)}</td>
              </tr>
              <tr className={classes.tableRow}>
                <td className={classes.tableRow}>
                  <span>Gross Weight:</span>
                </td>
                <td className={classes.tableRow}>{TableInputText("Gross Weight", this.state.item["Gross Weight"], this.handleFocus, this.handleOnChange)}</td>
              </tr>
              <tr className={classes.tableRow}>
                <td className={classes.tableRow}>
                  <span>Nett Weight:</span>
                </td>
                <td className={classes.tableRow}>{TableInputText("Nett Weight", this.state.item["Nett Weight"], this.handleFocus, this.handleOnChange)}</td>
              </tr>
              <tr className={classes.tableRow}>
                <td className={classes.tableRow}>
                  <span>Standard Carton:</span>
                </td>
                <td className={classes.tableRow}>{TableInputText("Standard Carton", this.state.item["Standard Carton"], this.handleFocus, this.handleOnChange)}</td>
              </tr>
              <tr className={classes.tableRow}>
                <td className={classes.tableRow}>
                  <span>Height:</span>
                </td>
                <td className={classes.tableRow}>{TableInputText("Height", this.state.item["Height"], this.handleFocus, this.handleOnChange)}</td>
              </tr>
              <tr className={classes.tableRow}>
                <td className={classes.tableRow}>
                  <span>Width:</span>
                </td>
                <td className={classes.tableRow}>{TableInputText("Width", this.state.item["Width"], this.handleFocus, this.handleOnChange)}</td>
              </tr>
              <tr className={classes.tableRow}>
                <td className={classes.tableRow}>
                  <span>Depth:</span>
                </td>
                <td className={classes.tableRow}>{TableInputText("Depth", this.state.item["Depth"], this.handleFocus, this.handleOnChange)}</td>
              </tr>
            </tbody>
          </table>
          <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "22px", marginRight: "15px" }}>
            <Button style={{ marginRight: "10px" }} color="secondary" variant="outlined" onClick={this.state.onClose}>
              <Close />
            </Button>
            <Button type="submit" color="primary" variant="outlined" onClick={this.handleSubmit}>
              <Check />
            </Button>
          </div>
        </form>
      </div>
    );
  }
}

export default withStyles(styles)(PackEditFormUnstyled);

const TableInputText = (field, value, handleFocus, handleOnChange, isMultiLine = false, maxRows = 0, rows = 0) => {
  return (
    <Input
      style={{ width: "100%" }}
      multiline={isMultiLine}
      rowsMax={maxRows}
      rows={rows}
      onFocus={handleFocus}
      key={`body_value_${field}`}
      name={field}
      value={value}
      onChange={(e) => {
        handleOnChange(field, e.target.value);
      }}
    />
  );
};
