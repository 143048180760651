import React, { useEffect } from "react";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";

import Button from "@material-ui/core/Button";
// import InputAdornment from "@material-ui/core/InputAdornment";

import { Form } from "react-final-form";
// import { TextField, Checkbox, Radio, Select } from "final-form-material-ui";

import { GetCurrentExchangeRate } from "../lib/api/exchangerates";
import { producerfinanceByProducerCode } from "../lib/api/producerfinance";

import moment from "moment";
import numeral from "numeral";

import { OrderHeader } from "../instructions/instructionordersHeader";
import { isNullOrUndef } from "../lib/helpers/isNullOrUndef";

const styles = (theme: Theme) =>
  createStyles({
    root: {},
  });

type OrdersCreateNewProps = {
  onSubmit: any;
  producers: any;
  weeks: any;
  formRecord: any;
} & WithStyles<typeof styles>;

// onClickCommodity: any;
// varieties: any;
// commodities: any;

const OrdersCreateNewUnstyled: React.SFC<OrdersCreateNewProps> = (props) => {
  const { onSubmit, producers, weeks, formRecord } = props;
  const [formRecordData, setFormRecordData] = React.useState(formRecord);
  const [loading, setLoading] = React.useState(false);

  // useEffect(() => {}, [formRecordData]);

  const producersMapped = producers
    .sort((a, b) => {
      if (a.name > b.name) return 1;
      if (a.name < b.name) return -1;
      return 1;
    })
    .map((producer) => {
      return { value: producer.code, display: `${producer.name}` };
    });

  const weeksMapped = weeks
    .sort((a, b) => {
      if (a.week > b.week) return -1;
      if (a.week < b.week) return 1;
      return 1;
    })
    .map((week) => {
      return { value: week.id, display: week.week + " (" + moment(week.period_start).format("DD-MM-YYYY") + ")" };
    });
  const weekCurrentArr = weeks.filter((week) => {
    return moment(week.period_start) > moment();
  });

  const weekCurrent = weekCurrentArr.length > 0 ? weekCurrentArr[weekCurrentArr.length - 1] : 0;

  const handleChangeFarm = (value) => {
    if (value) {
      producerfinanceByProducerCode(value).then((result) => {
        if (result && result.data && result.data.length > 0) {
          const resultExplode = { ...result.data[0] };
          const newFormRecordData = {
            ...formRecordData,
            farm: value,
            commission: resultExplode.commission,
            exworks: resultExplode.purchaseterms && resultExplode.purchaseterms == 1 ? "false" : "true",
            fixed: resultExplode.dealtype && resultExplode.dealtype == 1 ? "false" : "true",
            local: resultExplode.payment && resultExplode.payment == 1 ? "false" : "true",
          };
          setFormRecordData({ ...newFormRecordData });
        }
      });
    }
  };

  return (
    <div>
      <div>
        {loading && <span>Loading DATA</span>}
        {!loading && (
          <Form
            initialValues={{ ...formRecordData, week: weekCurrent.id }}
            onSubmit={onSubmit}
            validate={(values) => {
              let errors = {};

              if (isNullOrUndef(values["farm"])) {
                errors = { ...errors, farm: "required" };
              }
              if (isNullOrUndef(values["exworks"])) {
                errors = { ...errors, exworks: "required" };
              }
              if (isNullOrUndef(values["fixed"])) {
                errors = { ...errors, fixed: "required" };
              }
              if (isNullOrUndef(values["local"])) {
                errors = { ...errors, local: "required" };
              }
              if (isNullOrUndef(values["commodity"])) {
                errors = { ...errors, commodity: "required" };
              }
              if (isNullOrUndef(values["variety"])) {
                errors = { ...errors, variety: "required" };
              }
              return errors;
            }}
            render={({ handleSubmit, form }) => (
              <form onSubmit={handleSubmit}>
                <OrderHeader
                  producersMapped={producersMapped}
                  variety={undefined}
                  weeksMapped={weeksMapped}
                  exchange={formRecordData.exchange}
                  handleSetExchange={() => {}}
                  groupMaster={true}
                  handleChangeFarm={handleChangeFarm}
                  form={form}
                />
                <div style={{ paddingTop: "5px", float: "right" }}>
                  <Button type="submit" variant="contained" color="primary" style={{ margin: "2px" }} disabled={Object.keys(form.getState().errors).length != 0}>
                    create order
                  </Button>
                </div>
              </form>
            )}
          />
        )}
      </div>
    </div>
  );
};

export default withStyles(styles)(OrdersCreateNewUnstyled);
