// import { config } from "../../config";
import { client, apiHeaders, request } from "./_base";

// todo api exports
export const piorder = client("piorder", (item) => ({
  ...item,
  // id: item.order_id
}));

export const piorderFull = (financialyear = "0") =>
  request(`piorder/extend/all/${financialyear}`, {
    headers: apiHeaders(),
  }).then(async (result) => {
    if (result.status == 500) {
      const resp = await result.json();
      throw resp;
    }
    return result.json();
  });

export const removeWithInstructions = (id) =>
  request(`piorder/removeWithIns/${id}`, {
    headers: apiHeaders(),
    method: "DELETE",
  }).then(async (result) => {
    if (result.status == 500) {
      const resp = await result.json();
      throw resp;
    }
    return result.json();
  });

export const getOrders = (idList) =>
  request(`piorder/getOrders/${idList}`, {
    headers: apiHeaders(),
  }).then(async (result) => {
    if (result.status == 500) {
      const resp = await result.json();
      throw resp;
    }
    return result.json();
  });

export const getNextAvailableOrderNumber = (ordernumber) =>
  request(`piorder/getNextAvailableOrderNumber/${ordernumber}`, {
    headers: apiHeaders(),
  }).then(async (result) => {
    if (result.status == 500) {
      const resp = await result.json();
      throw resp;
    }
    return result.json();
  });

export const getOrdersByGroupNumber = (groupnumber) =>
  request(`piorder/ext/getOrdersByGroupNumber/${groupnumber}`, {
    headers: apiHeaders(),
  }).then(async (result) => {
    if (result.status == 500) {
      const resp = await result.json();
      throw resp;
    }
    return result.json();
  });

export const getOrderDetailById = (orderId: number) =>
  request(`piorder/ext/orderHeaderDetailById/${orderId}`, {
    headers: apiHeaders(),
  }).then(async (result) => {
    if (result.status == 500) {
      const resp = await result.json();
      throw resp;
    }
    return result.json();
  });