import { reverse } from "dns";

export function GenerateComboReadyData(
  array,
  sortFieldName: string,
  valueField: string,
  displayField: string,
  extendedDescription: boolean = false,
  reverseSorting: boolean = false,
) {
  const result = !array
    ? []
    : array
        .sort((a, b) => {
          if (a[sortFieldName] > b[sortFieldName]) return 1 * (reverseSorting ? -1 : 1);
          if (a[sortFieldName] < b[sortFieldName]) return -1 * (reverseSorting ? -1 : 1);
          return 1;
        })
        .map((v) => {
          if (extendedDescription) {
            return { value: v[valueField], display: v[valueField] + " - " + v[displayField], data: v };
          } else {
            return { value: v[valueField], display: v[displayField], data: v };
          }
        });

  return result;
}
