import React, { useEffect } from "react";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";

import Button from "@material-ui/core/Button";
import LinearProgress from "@material-ui/core/LinearProgress";
import IconButton from "@material-ui/core/IconButton";

import IconAttachFile from "@material-ui/icons/AttachFile";
import IconDelete from "@material-ui/icons/Delete";

import { Dropzone } from "../../lib/helpers/Dropzone";
import { labtestattachment, LabTestAttachmentType, labtestattachmentByLoadoutID } from "../../lib/api/labtestattachment";

import LZString from "lz-string";
// const jsPDF = require("jspdf");

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1),
      paddingTop: theme.spacing(2),
    },
    closebutton: {
      marginBottom: theme.spacing(1),
    },
    floatingProgress: {
      position: "absolute",
      width: "300px",
      height: "3px",
      textAlign: "center",
      fontSize: "20px",
      background: "white",
      opacity: 1,
      borderRadius: "0 0 5px 0",
    },
    fileContainer: {
      padding: theme.spacing(1),
    },
    fileItem: {
      margin: theme.spacing(1),
      cursor: "pointer",
    },
  });

type LabTestAttachmentProps = {
  labtestid: number;
  handleChange: (files) => void;
} & WithStyles<typeof styles>;

const LabTestAttachmentUnstyled: React.FunctionComponent<LabTestAttachmentProps> = (props) => {
  const { classes, labtestid, handleChange } = props;
  const [loading, setLoading] = React.useState(false);
  const [files, setFiles] = React.useState([]);

  useEffect(() => {
    loadData();
  }, [labtestid]);

  const loadData = () => {
    labtestattachmentByLoadoutID(labtestid).then((result: [LabTestAttachmentType]) => {
      const newFiles = [...result];
      const unsavedFiles = files.filter((file) => file.id == undefined);
      setFiles([...unsavedFiles, ...newFiles]);
    });
  };

  const onDrop = React.useCallback(
    (acceptedFiles) => {
      setLoading(true);
      const validFiles = [];
      acceptedFiles.map((file: any) => {
        if (file.type == "application/pdf") {
          validFiles.push(file);
        }
      });

      Promise.all(
        Array.from(validFiles).map((file: any) => {
          return base64(file);
        }),
      ).then((newFiles) => {
        const filesarr = [...files, ...newFiles];
        setFiles(filesarr);
        handleChange(filesarr);
        setLoading(false);
      });
    },
    [files],
  );

  const base64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = function(event) {
        resolve({ name: file["name"], document: LZString.compress(event.target["result"]) });
      };
      reader.readAsDataURL(file);
    });
  };

  const previewFile = (file) => {
    const base64string = LZString.decompress(file.document);
    const arr = base64string.split(",");

    const binary = atob(arr[1]);
    const len = binary.length;
    const buffer = new ArrayBuffer(len);
    const view = new Uint8Array(buffer);
    for (var i = 0; i < len; i++) {
      view[i] = binary.charCodeAt(i);
    }
    const blob1 = new Blob([view], { type: "application/pdf" });
    const url = window.URL.createObjectURL(blob1);

    const a = document.createElement("a");
    document.body.appendChild(a);
    a.href = url;
    a.setAttribute("download", file.name);
    a.click();
    window.URL.revokeObjectURL(url);
  };

  const removeDocument = (id, name) => {
    if (id && id != 0) {
      labtestattachment.remove(id).then(() => {
        loadData();
      });
    } else {
      const filesFiltered = files.filter((file) => file.name != name);
      setFiles(filesFiltered);
    }
  };

  return (
    <div className={classes.root}>
      {loading && <LinearProgress className={classes.floatingProgress} />}
      {!loading && (
        <div>
          <Dropzone multiple onDrop={onDrop} file={files} />
        </div>
      )}
      <div className={classes.fileContainer}>
        {files &&
          files.map((file, idx) => {
            return (
              <div className={classes.fileItem} key={idx}>
                <div
                  style={{ float: "left" }}
                  onClick={() => {
                    previewFile(file);
                  }}
                >
                  <IconAttachFile style={{ color: "red" }} />
                  <span>{`${file.name.toString().substring(1, 30)}${file.name.toString().length > 30 && " ..."}`}</span>
                </div>
                <div>
                  <IconButton
                    aria-label="delete"
                    onClick={() => {
                      removeDocument(file.id, file.name);
                    }}
                  >
                    <IconDelete />
                  </IconButton>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default withStyles(styles)(LabTestAttachmentUnstyled);
