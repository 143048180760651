import React from "react";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";

import IconAdd from "@material-ui/icons/Add";

import Grid from "../../lib/components/grid";
import { DialogInformation } from "../../lib/components/dialoginformation";
import Confirmation from "../../lib/components/confirmation";

import { FileImportTemplate, FileImportTemplateType } from "../../lib/api/FileImportTemplate";

import { GridColumns } from "./templategridsetup";
import FundsEditForm from "./templateeditform";
import { updateTemplateDetailColumns } from "../../lib/api/FileImportTemplateDetail";
import { Row } from "react-data-grid";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1),
      height: "calc(100vh - 100px)",
      width: "100%",
      position: "relative",
    },
    inline: {
      display: "inline",
      position: "absolute",
      marginTop: "7px",
      marginLeft: "7px",
    },
  });

type TemplateProps = {} & WithStyles<typeof styles>;

export class TemplateUnstyled extends React.Component<TemplateProps, any> {
  state = {
    classes: undefined,
    data: [],
    loading: true,
    message: undefined,
    selectedRecord: undefined,
    selectedRecordAllocate: undefined,
    removeRecord: undefined,
  };

  constructor(props) {
    super(props);
    this.state.classes = props.classes;
  }

  componentDidMount() {
    this.setState({ loading: true });
    this.loadData().then(() => {
      this.setState({ loading: false });
    });
  }

  loadData = async () => {
    return FileImportTemplate.all().then((result: [FileImportTemplateType]) => {
      if (result) {
        const dataSorted = result.sort((a, b) => a.templatename.localeCompare(b.templatename));
        this.setState({ data: dataSorted });
      }
    });
  };

  handleCreate = () => {
    this.setState({ selectedRecord: { id: 0 } });
  };

  handleEdit = (selectedRecord: FileImportTemplateType) => {
    this.setState({ selectedRecord: { ...selectedRecord } });
  };

  handleEditProcess = async (data: FileImportTemplateType, templateDetailColumns: any[], reloadSelectedRecord: boolean = false) => {
    let newid = 0;
    let dataToUpdate: { data: FileImportTemplateType };

    this.setState({ loading: true });

    if (data) {
      dataToUpdate = {
        data: {
          templatename: data.templatename ? data.templatename : "",
          templatehasheader: data.templatehasheader,
          templatespecialcharacter: data.templatespecialcharacter ? data.templatespecialcharacter : "",
        },
      };

      if (data.id && data.id != 0) {
        newid = data.id;
        await FileImportTemplate.update(data.id, dataToUpdate);
      } else {
        const resultFileImportCreate = await FileImportTemplate.create(dataToUpdate);
        newid = resultFileImportCreate[0];
      }
    }

    if (templateDetailColumns.length > 0) {
      const columns = templateDetailColumns.map((item) => ({ ...item, FileImportTemplate_id: newid }));
      await updateTemplateDetailColumns(columns);
    }

    this.loadData().then(() => {
      if (reloadSelectedRecord) {
        this.setState({ selectedRecord: { ...dataToUpdate.data, id: newid }, loading: false });
      } else {
        this.setState({ selectedRecord: undefined, loading: false });
      }
    });

    return newid;
  };

  handleRemoveTemplateEntry = (row: FileImportTemplateType) => {
    this.setState({ removeRecord: row });
  };

  handleRemoveTemplateConfirm = () => {
    FileImportTemplate.remove(this.state.removeRecord.id).then(() => {
      this.loadData().then(() => {
        this.setState({ removeRecord: undefined, loading: false });
      });
    });
  };

  handleRemoveTemplateClose = () => {
    this.setState({ removeRecord: undefined });
  };

  handleAllocationProcess = async (data: any) => {
    if (data) {
    }
    this.loadData().then(() => {
      this.setState({ selectedRecordAllocate: undefined, loading: false });
    });
  };

  handleInformationClose = () => {
    this.setState({ message: undefined });
  };

  GridColumnsOverride = (column, row) => {
    return GridColumns(column, row, this.handleEdit, this.handleRemoveTemplateEntry);
  };

  render() {
    const { classes } = this.state;

    return (
      <div className={classes.root}>
        {this.state.loading && <LinearProgress />}
        {this.state.removeRecord && (
          <Confirmation
            isOpen={true}
            handleClose={this.handleRemoveTemplateClose}
            handleConfirm={this.handleRemoveTemplateConfirm}
            title="Remove selected template?"
            body="Are you sure you want to REMOVE the selected template entry?"
          ></Confirmation>
        )}
        {!this.state.selectedRecord && !this.state.selectedRecordAllocate && (
          <div className={classes.inline}>
            <Tooltip style={{ zIndex: 0 }} title="Create new Template">
              <div className={classes.inline}>
                <Button variant="contained" color="primary" onClick={this.handleCreate} style={{ marginBottom: "10px" }}>
                  <IconAdd />
                </Button>
              </div>
            </Tooltip>
          </div>
        )}
        {!this.state.selectedRecord && !this.state.selectedRecordAllocate && (
          <Grid
            loading={this.state.loading}
            data={this.state.data}
            GridColumns={this.GridColumnsOverride}
            handleRefresh={() => {
              this.setState({ loading: true }, () => {
                this.loadData().then(() => {
                  this.setState({ loading: false });
                });
              });
            }}
            clearFilters={"editemplategrid"}
          />
        )}
        {this.state.selectedRecord && <FundsEditForm selectedRecord={this.state.selectedRecord} handleProcess={this.handleEditProcess} />}
        {/* {this.state.selectedRecordAllocate && <Allocation handleProcess={this.handleAllocationProcess} selectedRecord={this.state.selectedRecordAllocate} />} */}
        {this.state.message && (
          <DialogInformation
            isOpen={true}
            handleClose={this.handleInformationClose}
            handleOK={this.handleInformationClose}
            title={"Feedback"}
            body={`${this.state.message}`}
            showinput={false}
          />
        )}
      </div>
    );
  }
}

export default withStyles(styles)(TemplateUnstyled);
