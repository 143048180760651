import { config } from "../../config";
import { client, apiHeaders, request } from "./_base";

const endpoint = "labtesttype";

export type LabTestTypeType = {
  id?: number;
  detail: string;
  created_at?: Date;
  updated_at?: Date;
};

export const labtesttype = client(endpoint, (item) => ({
  ...item,
}));

export const getLabTestTypeReadyForCombo = () => {
  return labtesttype.all().then((result) => {
    const ready = result
      .sort((a, b) => {
        if (a.detail > b.detail) return 1;
        if (a.detail < b.detail) return -1;
        return 1;
      })
      .map((item) => {
        return { value: item.id, display: item.detail, data: item };
      });
    return ready;
  });
};
