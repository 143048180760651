import { client, request } from "./_base";

const endpoint = "clientsContractsVarieties";

export const clientsContractsVarieties = client(endpoint, (item) => ({
  ...item,
}));

export const clientscontractsVarietiesFull = async (clientscontractid) => {
  const result = await request(`${endpoint}/ext/full/${clientscontractid}`, {
    method: "GET",
  });
  const resp = await result.json();
  if (result.status == 500) {
    throw resp;
  }
  return resp.data;
};
