import React, { FC, useEffect, useState } from "react";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";

import Splitter from "m-react-splitters";
import "m-react-splitters/lib/splitters.css";

import Box from "@material-ui/core/Box";
import Confirmation from "../lib/components/confirmation";
import IconButton from "@material-ui/core/IconButton";

import IconClose from "@material-ui/icons/Close";
import Dispatchmerge from "../maintenance/dispatchmerge/dispatchmerge";
import LinearProgress from "@material-ui/core/LinearProgress";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

import { DispatcheddocsFullType } from "../lib/api/dispatcheddocs";
import { findByDispatchID } from "../lib/api/edistockpalettesdispatched";
import DispatchGridTable from "./dispatchtablegrid";
import DispatchGridTableSelected from "./dispatchtablegridselected";
import { DialogInformation } from "../lib/components/dialoginformation";

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && (
        <Box p={3}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

const styles = (theme: Theme) =>
  createStyles({
    root: {
      height: "100%",
      width: "100%",
    },
    tabpanel: {
      height: "100%",
      position: "relative",
      "& > :first-child": {
        height: "100%",
        padding: "0",
        "& > :first-child": {
          height: "100%",
        },
      },
    },
  });

type DispatchTableProps = {
  data: {}[];
  passedReference: any;
  onReload(): void;
  onRelease(dispatch: any): void;
  onRemoveDispath(dispatch_id: number): void;
  onAccept(data: any[], dispatch: any): void;
} & WithStyles<typeof styles>;

const DispatchTableUnstyled: FC<DispatchTableProps> = ({ data, onReload, onAccept, onRelease, onRemoveDispath, classes }) => {
  const [tabValue, setTabValue] = useState(0);

  const [loading, setLoading] = useState(false);
  const [printPdfLoading, setPrintPdfLoading] = useState(false);
  const [loadingDetail, setLoadingDetail] = useState(false);
  const [showDispatchMerge, toggleDispatchMerge] = useState(false);

  const [gridHeights, setGridHeights] = useState({ minGridHeight: 300, minGridHeightSecond: 300 });
  const [selectedDispatch, setSelectedDispatch] = useState(undefined);
  const [selectedRows, setSelectedRows] = useState([]);
  const [dialogInformation, setDialogInformation] = useState(undefined);

  const handleSetGridHeights = () => {
    const primarysplit = document.getElementsByClassName("splitter horizontal");
    setGridHeights({ minGridHeight: primarysplit[0].children[0].clientHeight - 110, minGridHeightSecond: primarysplit[0].children[2].clientHeight - 80 });
  };

  useEffect(() => {
    handleSetGridHeights();
  }, []);

  const toggleMergeDispatch = () => {
    if (showDispatchMerge) {
      onReload();
    }
    toggleDispatchMerge(!showDispatchMerge);
  };

  const handleTabChange = (_, newValue: number) => {
    setTabValue(newValue);
  };

  const handleAvailableDispatchDoubleClick = async (row: DispatcheddocsFullType) => {
    setLoadingDetail(true);

    if (row.sale_type == 0) {
      const data = await findByDispatchID(row["Dispatch ID"]);

      setSelectedDispatch(row);
      setSelectedRows([...data.data]);
      setLoadingDetail(false);
      return;
    }
    if (row.sale_id == 0 && row.sale_type == 1) {
      setLoading(false);
      setLoadingDetail(false);
      setSelectedDispatch(undefined);
      setSelectedRows([]);
      setDialogInformation({ title: "Pro-Forma Invoice!", body: "Record is a Pro-Forma invoice. Updating the Dispatch will merge the Pro-Forma and Invoice the record." });

      const data = await findByDispatchID(row["Dispatch ID"]);

      setSelectedDispatch(row);
      setSelectedRows([...data.data]);
      setLoadingDetail(false);
      return;
    }
  };

  const getSpecialData = (data) => {
    return data.filter((item) => {
      if (item["Dispatch Doc Ref"] === "Totals") return item;
      if (item["Vessel"] && item["Vessel"].length > 0) return item;
      return null;
    });
  };

  const handleInfoClose = () => {
    setDialogInformation(undefined);
  };

  const selectedClear = () => {
    setSelectedRows([]);
    setSelectedDispatch(undefined);
  };

  return (
    <div className={classes.root}>
      {printPdfLoading && <LinearProgress color="secondary" />}
      {showDispatchMerge && (
        <Confirmation isOpen fullscreen body={undefined} makeHeaderUppercase title="Merge Dispatches" handleClose={toggleMergeDispatch} handleConfirm={toggleMergeDispatch}>
          <div>
            <IconButton color="secondary" onClick={toggleMergeDispatch} aria-label="Close" style={{ position: "absolute", right: "10px", top: "10px" }}>
              <IconClose />
            </IconButton>
            <Dispatchmerge />
          </div>
        </Confirmation>
      )}
      {dialogInformation && (
        <DialogInformation isOpen handleOK={handleInfoClose} body={dialogInformation.body} handleClose={handleInfoClose} title={dialogInformation.title} showinput={false} />
      )}
      <div id="main_splitter_div" style={{ height: "100%", width: "100%", display: "grid" }}>
        <Splitter
          postPoned
          dispatchResize
          position="horizontal"
          primaryPaneWidth="100%"
          primaryPaneMaxHeight="calc(100% - 100px)"
          primaryPaneHeight="calc(100% - 350px)"
          onDragFinished={(e) => handleSetGridHeights()}
        >
          <div id="primary_grid">
            <AppBar position="static">
              <Tabs value={tabValue} onChange={handleTabChange} aria-label="Dispatch Allocation">
                <Tab label={"Shipments"} {...a11yProps(0)} />
                <Tab label={"All Data"} {...a11yProps(1)} />
              </Tabs>
              <TabPanel className={classes.tabpanel} hidden={tabValue === 1}>
                <DispatchGridTable
                  data={getSpecialData(data)}
                  loading={loading}
                  handleRefresh={onReload}
                  selectedDispatch={selectedDispatch}
                  gridHeight={gridHeights.minGridHeight}
                  toggleMergeDispatch={toggleMergeDispatch}
                  handleAvailableDispatchDoubleClick={handleAvailableDispatchDoubleClick}
                />
              </TabPanel>
              <TabPanel className={classes.tabpanel} hidden={tabValue === 0}>
                <DispatchGridTable
                  isSpecial
                  data={data}
                  loading={loading}
                  handleRefresh={onReload}
                  selectedDispatch={selectedDispatch}
                  gridHeight={gridHeights.minGridHeight}
                  toggleMergeDispatch={toggleMergeDispatch}
                  handleAvailableDispatchDoubleClick={handleAvailableDispatchDoubleClick}
                />
              </TabPanel>
            </AppBar>
          </div>
          <div id="secondary_grid">
            <DispatchGridTableSelected
              data={selectedRows}
              onAccept={onAccept}
              onRelease={onRelease}
              loading={loadingDetail}
              selectedRows={selectedRows}
              selectedClear={selectedClear}
              onRemoveDispath={onRemoveDispath}
              selectedDispatch={selectedDispatch}
              gridHeight={gridHeights.minGridHeightSecond}
              handleAvailableDispatchDoubleClick={handleAvailableDispatchDoubleClick}
            />
          </div>
        </Splitter>
      </div>
    </div>
  );
};

export default withStyles(styles)(DispatchTableUnstyled);
