import React, { FC, useContext, useEffect, useState } from "react";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";

import TextField from "@material-ui/core/TextField";
import LinearProgress from "@material-ui/core/LinearProgress";
import Button from "@material-ui/core/Button";

import IconClose from "@material-ui/icons/Close";
import IconCheck from "@material-ui/icons/Check";

import { Field, Form } from "react-final-form";

import { MaterialCheckBoxComponent } from "../../lib/helpers/materialcomponents";
import { clients, clientsByCode } from "../../lib/api/clients";
import { GenerateErrorMessage } from "../../lib/helpers/string_methods";
import { SnackContext } from "../../lib/context/SnackContext";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1),
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      position: "relative",
    },
    tableRowFieldTitle: {
      paddingTop: theme.spacing(1),
      paddingRight: theme.spacing(1) * 2,
      marginTop: theme.spacing(1),
      textAlign: "right",
      minWidth: "175px",
    },
    tableRowFieldData: {
      paddingTop: theme.spacing(0.5),
      textAlign: "left",
      width: "400px",
    },
    actionButtons: {
      display: "flex",
      justifyContent: "flex-end",
      gap: theme.spacing(1),
      flexDirection: "row",
      position: "fixed",
      right: "50px",
    },
  });

type ClientEditFormProps = {
  clientId: number;
  isDirty: boolean;
  handleClose(): void;
  reloadData(): Promise<void>;
  toggleDirty(isDirty: boolean): void;
  setClientId(clientId: number): void;
} & WithStyles<typeof styles>;

const ClientEditFormUnstyled: FC<ClientEditFormProps> = ({ classes, clientId, reloadData, setClientId, handleClose, toggleDirty }) => {
  const { updateSnack } = useContext(SnackContext);

  const [clientData, setClientData] = useState({ id: 0, code: undefined, name: undefined, active: 1 });
  const [loading, setLoading] = useState(true);

  const loadData = async () => {
    setLoading(true);
    if (clientId != 0) {
      const data = await clients.single(clientId);
      setClientData(data);
    }
    setLoading(false);
  };

  useEffect(() => {
    loadData();
  }, [clientId]);

  const handleSubmit = async (values) => {
    setLoading(true);
    try {
      delete values["id"]; // remove id
      const dataToUpdate = {
        data: {
          ...values,
          code: (values["code"] || "").trim(),
        },
      };
      if (clientId == 0) {
        const result = await clients.create(dataToUpdate);
        setClientId(result[0]);
      } else {
        await clients.update(clientId, dataToUpdate);
      }
      updateSnack({ color: "green", message: "Successfully submitted data", show: true });
      reloadData(); // reload grid
    } catch (error) {
      const err = GenerateErrorMessage(error, "Failed to create/update client");
      updateSnack({ color: "red", message: err, show: true });
    }
    await loadData();
    setLoading(false);
  };

  if (loading) return <LinearProgress color="secondary" />;

  return (
    <Form
      initialValues={{ ...clientData }}
      onSubmit={(values) => handleSubmit(values)}
      validate={async (values) => {
        let errors = {};

        if ((values["code"] || "").length > 9) {
          errors = { ...errors, code: "Client code cannot exceed 9 characters" };
        }
        if ((values["code"] || "").length > 0) {
          const result = await clientsByCode(values["code"] || "");

          if (result.data.length > 0) {
            errors = { ...errors, code: "Client code exists" };
          }
        }
        if (values["client_telephone"] && values["client_telephone"].length > 0 && !/^(\(?\+?\d{1,3}\)?)?\s?(\d{2}|0)?\d{8}$/g.test(values["client_telephone"])) {
          errors = { ...errors, client_telephone: "not a valid number" };
        }
        if (values["client_mobile"] && values["client_mobile"].length > 0 && !/^(\(?\+?\d{1,3}\)?)?\s?(\d{2}|0)?\d{8}$/g.test(values["client_mobile"])) {
          errors = { ...errors, client_mobile: "not a valid number" };
        }
        if (values["sales_phone"] && values["sales_phone"].length > 0 && !/^(\(?\+?\d{1,3}\)?)?\s?(\d{2}|0)?\d{8}$/g.test(values["sales_phone"])) {
          errors = { ...errors, sales_phone: "not a valid number" };
        }
        if (values["logistics_phone"] && values["logistics_phone"].length > 0 && !/^(\(?\+?\d{1,3}\)?)?\s?(\d{2}|0)?\d{8}$/g.test(values["logistics_phone"])) {
          errors = { ...errors, logistics_phone: "not a valid number" };
        }
        if (values["quality_phone"] && values["quality_phone"].length > 0 && !/^(\(?\+?\d{1,3}\)?)?\s?(\d{2}|0)?\d{8}$/g.test(values["quality_phone"])) {
          errors = { ...errors, quality_phone: "not a valid number" };
        }
        if (values["finance_phone"] && values["finance_phone"].length > 0 && !/^(\(?\+?\d{1,3}\)?)?\s?(\d{2}|0)?\d{8}$/g.test(values["finance_phone"])) {
          errors = { ...errors, finance_phone: "not a valid number" };
        }
        if (
          values["sales_email"] &&
          values["sales_email"].length > 0 &&
          !/^[\w-\.]+@([\w-]+\.)+[\w-]{2,5}$|^[\w-\.]+@([\w-]+\.)+[\w-]{2,5} (\<.*\>\;$|\<.*\>$)/g.test(values["sales_email"])
        ) {
          errors = { ...errors, sales_email: "not a valid email" };
        }
        if (
          values["logistics_email"] &&
          values["logistics_email"].length > 0 &&
          !/^[\w-\.]+@([\w-]+\.)+[\w-]{2,5}$|^[\w-\.]+@([\w-]+\.)+[\w-]{2,5} (\<.*\>\;$|\<.*\>$)/g.test(values["logistics_email"])
        ) {
          errors = { ...errors, logistics_email: "not a valid email" };
        }
        if (
          values["quality_email"] &&
          values["quality_email"].length > 0 &&
          !/^[\w-\.]+@([\w-]+\.)+[\w-]{2,5}$|^[\w-\.]+@([\w-]+\.)+[\w-]{2,5} (\<.*\>\;$|\<.*\>$)/g.test(values["quality_email"])
        ) {
          errors = { ...errors, quality_email: "not a valid email" };
        }
        if (
          values["finance_email"] &&
          values["finance_email"].length > 0 &&
          !/^[\w-\.]+@([\w-]+\.)+[\w-]{2,5}$|^[\w-\.]+@([\w-]+\.)+[\w-]{2,5} (\<.*\>\;$|\<.*\>$)/g.test(values["finance_email"])
        ) {
          errors = { ...errors, finance_email: "not a valid email" };
        }
        return errors;
      }}
      render={({ form, handleSubmit: submit }) => (
        <form onSubmit={submit}>
          <FormDetail classes={classes} handleClose={handleClose} form={form} toggleDirty={toggleDirty} />
        </form>
      )}
    />
  );
};

const FormDetail = ({ classes, handleClose, form, toggleDirty }) => {
  useEffect(() => {
    toggleDirty(form.getState().dirty);
  }, [form.getState().dirty]);

  return (
    <div className={classes.root}>
      <div className={classes.actionButtons}>
        <Button color="secondary" variant="outlined" onClick={handleClose} type="button">
          <IconClose />
        </Button>
        <Button disabled={!form.getState().dirty} type="submit" color="primary" variant="outlined">
          <IconCheck />
        </Button>
      </div>
      <table>
        <tbody>
          <FormCheckbox classes={classes} field="active" label="Active" />
          <TableFieldText classes={classes} field="code" title="Client Code" disabled={false} multiline={false} required={true} form={form} />
          <TableFieldText classes={classes} field="name" title="Client Name" disabled={false} multiline={false} required={true} form={form} />
          <TableFieldText classes={classes} field="physical_address" title="Physical Address" disabled={false} multiline={true} required={false} form={form} />
          <TableFieldText classes={classes} field="postal_address" title="Postal Address" disabled={false} multiline={true} required={false} form={form} />
          <TableFieldText classes={classes} field="client_telephone" title="Telephone Number" disabled={false} multiline={false} required={false} form={form} />
          <TableFieldText classes={classes} field="client_mobile" title="Cell Number" disabled={false} multiline={false} required={false} form={form} />
          <TableFieldText classes={classes} field="sales_person" title="Sales Person" disabled={false} multiline={false} required={false} form={form} />
          <TableFieldText classes={classes} field="sales_phone" title="Sales Contact No" disabled={false} multiline={false} required={false} form={form} />
          <TableFieldText classes={classes} field="sales_email" title="Sales e-mail" disabled={false} multiline={false} required={false} form={form} />
        </tbody>
      </table>
      <table>
        <tbody>
          <TableFieldText classes={classes} field="logistics_person" title="Logistics Person" disabled={false} multiline={false} required={false} form={form} />
          <TableFieldText classes={classes} field="logistics_phone" title="Logistics Contact No" disabled={false} multiline={false} required={false} form={form} />
          <TableFieldText classes={classes} field="logistics_email" title="Logistics e-mail" disabled={false} multiline={false} required={false} form={form} />
          <TableFieldText classes={classes} field="quality_person" title="Quality Person" disabled={false} multiline={false} required={false} form={form} />
          <TableFieldText classes={classes} field="quality_phone" title="Quality Contact No" disabled={false} multiline={false} required={false} form={form} />
          <TableFieldText classes={classes} field="quality_email" title="Quality e-mail" disabled={false} multiline={false} required={false} form={form} />
          <TableFieldText classes={classes} field="finance_person" title="Finance Person" disabled={false} multiline={false} required={false} form={form} />
          <TableFieldText classes={classes} field="finance_phone" title="Finance Contact No" disabled={false} multiline={false} required={false} form={form} />
          <TableFieldText classes={classes} field="finance_email" title="Finance e-mail" disabled={false} multiline={false} required={false} form={form} />
        </tbody>
      </table>
    </div>
  );
};

export default withStyles(styles)(ClientEditFormUnstyled);

export const TableFieldText: FC<{ field: string; title: string; disabled: boolean; multiline: boolean; required: boolean; form: any } & WithStyles<typeof styles>> = (props) => {
  const { classes, title, field, disabled, multiline, required, form } = props;
  return (
    <tr>
      <td className={classes.tableRowFieldTitle} style={{ verticalAlign: "top", paddingTop: "13px" }}>
        <span>{`${title}: `}</span>
      </td>
      <td className={classes.tableRowFieldData}>
        <Field fullWidth name={field} disabled={disabled}>
          {(props) => (
            <>
              <TextField
                fullWidth
                multiline={multiline}
                rows={multiline ? "4" : "1"}
                name={props.input.name}
                value={props.input.value}
                onChange={props.input.onChange}
                className={classes.tableRowFieldData}
                disabled={disabled}
                required={required}
              />
              <span style={{ color: "red" }}>{props.meta.error && props.meta.touched ? props.meta.error : ""}</span>
            </>
          )}
        </Field>
      </td>
    </tr>
  );
};

type FormCheckboxProps = {
  label: string;
  field: string;
} & WithStyles<typeof styles>;

const FormCheckbox: FC<FormCheckboxProps> = ({ classes, label, field }) => (
  <tr>
    <td className={classes.tableRowFieldTitle}>
      <span>{label}:</span>
    </td>
    <td>
      <Field name={field} component={MaterialCheckBoxComponent as any} type="checkbox" style={{ margin: 0, padding: 0, marginLeft: "-18px" }} />
    </td>
  </tr>
);
