import React, { useMemo } from "react";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Chip from "@material-ui/core/Chip";

const styles = (theme: Theme) =>
  createStyles({
    root: {},
    chip: {
      margin: "5px",
      minWidth: "76px",
    },
    key: {
      marginLeft: "5px",
      width: "100%",
      fontSize: "15px",
      marginTop: "-5px",
    },
    // titleCell: {
    //   textAlign: "right",
    //   padding: "0px 0px 0px 0px",
    // },
    chipCell: {
      padding: "0px 0px 0px 0px",
      width: "50px",
      backgroundColor: "purple",
    },
    table: {
      width: "50px",
    },

    chipParentDiv: {
      maxHeight: "84px",
      height: "auto",
      overflowY: "scroll",
    },
    chipContainer: {
      display: "grid",
      gridTemplateColumns: "1fr 9fr",
      // display: "flex",
      width: "100%",
    },
    gridChip1: {
      // maxWidth: "12%",
      // width: "auto",
      paddingTop: "12px",
      textAlign: "right",
    },
    gridChip2: {
      // marginLeft: "5px",
      // width: "auto",
      // maxWidth: "88%",
      fontSize: "15px",
    },
  });

type FilterChipsProps = {
  columns: any[];
  filterData: any;
  handleFilterClear: any;
  sortingData: any;
  removesortingentry: any;
  removeFilterEntry: any;
} & WithStyles<typeof styles>;

const FilterChipsUnstyled: React.FunctionComponent<FilterChipsProps> = (props) => {
  const { filterData, handleFilterClear, sortingData, removesortingentry, removeFilterEntry, classes, columns } = props;

  const groupedColumns = useMemo(() => {
    return (columns || []).reduce((obj, row) => {
      obj[row.key] = row.name;
      return obj;
    }, {});
  }, [columns]);

  return (
    <>
      {Object.keys(sortingData).length > 0 && (
        <Paper>
          <div className={classes.chipParentDiv}>
            <div className={classes.chipContainer}>
              <div className={classes.gridChip1}>{Object.keys(sortingData).length > 0 && <span className={classes.key}>SORTING:</span>}</div>
              <div className={classes.gridChip2}>
                {Object.keys(sortingData).map((item) => (
                  <Chip
                    key={`sorting${item}`}
                    color="secondary"
                    className={classes.chip}
                    onDelete={() => removesortingentry(item)}
                    label={`${groupedColumns[item] || item} ${sortingData[item]}`}
                  />
                ))}
              </div>
            </div>
          </div>
        </Paper>
      )}
      {Object.keys(filterData).length > 0 && (
        <Paper>
          <div className={classes.chipParentDiv}>
            {Object.keys(filterData).map((key, index) => {
              if (key != "0") {
                return (
                  <div key={`${key}_GridItem`} className={classes.chipContainer}>
                    <div className={classes.gridChip1}>
                      {(filterData[key].filterTerm || []).length > 0 && (
                        <span key={`${key}_${index}_span`} className={classes.key}>
                          {" "}
                          {(groupedColumns[key] || key).toUpperCase()}:
                        </span>
                      )}
                    </div>
                    <div className={classes.gridChip2}>
                      {(filterData[key].filterTerm || []).map((filter, index) => (
                        <Chip
                          key={`${key}${index}${filter.value}`}
                          color="primary"
                          label={filter.label}
                          className={classes.chip}
                          onDelete={() => removeFilterEntry(key, filter.value)}
                        />
                      ))}
                    </div>
                  </div>
                );
              }
            })}
          </div>
        </Paper>
      )}
    </>
  );
};

export default withStyles(styles)(FilterChipsUnstyled);
