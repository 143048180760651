import React, { useMemo, useState, useEffect, useRef, FC, useContext } from "react";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";

import IconClearAll from "@material-ui/icons/ClearAll";
import IconCheck from "@material-ui/icons/Check";
import IconAddCircle from "@material-ui/icons/AddCircle";

import orange from "@material-ui/core/colors/orange";
import { FiltersContext } from "../../context/FiltersContext";

import { FixedSizeList as List } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
import { addStickyFilters } from "../../helpers/stickyfilters";
import classNames from "classnames";
import { OrangeButton, RedButton } from "../ColorButtons";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1),
    },
    divFilters: {
      display: "grid",
      gridTemplateColumns: "repeat(2, 250px)",
    },
    divOptions: {
      height: "400px",
      overflow: "hidden",
      borderStyle: "solid",
      borderWidth: "thin",
      borderColor: "blue",
    },
    optionItem: {
      color: "black",
      backgroundColor: "white",
      "&:hover": {
        color: "white",
        backgroundColor: orange[900],
      },
      cursor: "pointer",
      display: "flex",
      alignItems: "center",
      gap: "3px",
      whiteSpace: "nowrap",
    },
    filterContainer: {
      display: "flex",
      flexDirection: "row",
      padding: "2px",
    },
    removeFilterButton: {
      flexGrow: 5,
      padding: 0,
      color: "red",
    },
    currentFilterValue: {
      flexGrow: 95,
    },
    specialRow: {
      fontWeight: "bold",
      fontStyle: "italic",
    },
    actionsButton: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      marginTop: "5px",
    },
  });

type FiltersListProps = {
  classes: any;
} & WithStyles<typeof styles>;

const FiltersList: FC<FiltersListProps> = ({ classes }) => {
  const { filterOptions, selectedFilters, setSelectedFilters, options, setLocalFilters, toggleFilters } = useContext(FiltersContext);

  const [localFilterOptions, setLocalFilterOptions] = useState<any[]>([]);
  const [search, setSearch] = useState<string>("");

  const searchRef = useRef(null);

  const key = useMemo(() => {
    return `${options.section}_${options.activeColumn}`;
  }, [options]);

  const selections = useMemo(() => {
    return selectedFilters[key] || [];
  }, [selectedFilters, key]);

  const availableFilters = useMemo(() => {
    return (localFilterOptions || []).filter((filter) => filter.value !== "" && filter.value !== "NON_BLANK");
  }, [localFilterOptions]);

  const handleSelectFilter = (filterOption) => {
    let filters = selectedFilters[key];

    if (filters && filters.length > 0) {
      if (!filters.find((item) => item.value == filterOption.value)) {
        filters.push(filterOption);
      } else {
        const filtered = filters.filter((item) => item.value != filterOption.value);
        filters = filtered;
      }
      setLocalFilters({ [key]: filters });
      setSelectedFilters({ ...selectedFilters, [key]: filters });
    } else {
      if (filterOption.value == "NON_BLANK") {
        filters = filterOptions.slice(1);
      } else if (filterOption.value == "") {
        filters = filterOptions.slice(0, 1);
      } else {
        filters = [filterOption];
      }
      setLocalFilters({ [key]: filters });
      setSelectedFilters({ ...selectedFilters, [key]: filters });
    }

    addStickyFilters(options.activeColumn, { read: 1, field: options.activeColumn, value: filters }, options.section);
  };

  useEffect(() => {
    if (!search || search.length == 0) {
      setLocalFilterOptions(filterOptions);
    } else {
      const searchArray = search.split("\n").filter((item) => item.length > 0);
      const filtered = searchArray.map((searchQuery) => filterOptions.filter((option) => option.label.toString().toLocaleLowerCase().includes(searchQuery))).flat(2);
      setLocalFilterOptions(filtered);
    }
  }, [search]);

  const addSearch = (value) => {
    setSearch(value.toString().toLocaleLowerCase());
  };

  const clearFilters = () => {
    setLocalFilters({ [key]: [] });
    setSelectedFilters({ ...selectedFilters, [key]: [] });
    addStickyFilters(options.activeColumn, { read: 1, field: options.activeColumn, value: [] }, options.section);
  };

  const selectAllFilters = (unselect?: boolean) => {
    if (unselect) {
      setLocalFilters({ [key]: [] });
      setSelectedFilters({ ...selectedFilters, [key]: [] });
    } else {
      setLocalFilters({ [key]: availableFilters });
      setSelectedFilters({ ...selectedFilters, [key]: availableFilters });
    }
  };

  useEffect(() => {
    if (searchRef.current) {
      /* This auto focuses on the search so that the user does not need to click the search bar after opening to start searching */
      /* implemented try/catch to not break screen if it doesnt find the element */
      /* this will not remove any functionality, the user will still be able to focus the search if this breaks */
      try {
        searchRef.current.children[1].children[0].focus();
      } catch (error) {
        console.log("Cannot find search input - error => ", error);
      }
    }
  }, []);

  return (
    <div className={classes.root}>
      <div>
        <div className={classes.divFilters}></div>
        <div style={{ paddingTop: "5px", paddingBottom: "5px" }}>
          <TextField multiline ref={searchRef} id="standard-search" label="Search field" type="search" fullWidth onChange={(e) => addSearch(e.target["value"])} />
        </div>
        <div className={classes.divOptions}>
          <AutoSizer style={{ marginTop: "2px" }}>
            {({ height, width }) => (
              <List
                itemCount={localFilterOptions.length}
                height={height - 5}
                itemSize={28}
                width={width - 4}
                itemData={{ classes, selections, handleSelectFilter, options: localFilterOptions }}
              >
                {Row}
              </List>
            )}
          </AutoSizer>
        </div>
        <div>
          <span>{`Records: ${localFilterOptions.length}`}</span>
        </div>
        <div className={classes.actionsButton}>
          <div>
            {availableFilters.length === selections.length ? (
              <Button name="close" variant="contained" color="secondary" onClick={() => selectAllFilters(true)} disabled={selections.length === 0}>
                Unselect All
              </Button>
            ) : (
              <Button name="close" variant="contained" color="secondary" onClick={() => selectAllFilters()} disabled={availableFilters.length === 0}>
                Select All
              </Button>
            )}
          </div>
          <div>
            <OrangeButton name="clear" variant="contained" type="button" onClick={clearFilters}>
              <IconClearAll />
              {` clear`}
            </OrangeButton>
            <RedButton name="close" variant="contained" onClick={() => toggleFilters("")}>
              Close
            </RedButton>
          </div>
        </div>
      </div>
    </div>
  );
};

const Row: FC<{ index; style; data }> = ({ index, style, data }) => {
  const { classes, selections, handleSelectFilter, options } = data;
  const option = options[index];

  return (
    <div
      style={style}
      key={`options-${index}`}
      onClick={() => handleSelectFilter(option)}
      className={classNames(classes.optionItem, option.value == "" || option.value == "NON_BLANK" ? classes.specialRow : "")}
    >
      {selections.find((item) => item.label == option.label && item.value == option.value) ? (
        <IconCheck key={`options_icon_check_${index}`} fontSize="small" style={{ padding: "3px", color: "green" }} />
      ) : (
        <IconAddCircle key={`options_icon_circle_${index}`} fontSize="small" style={{ padding: "3px" }} />
      )}
      {option.label && option.label}
    </div>
  );
};

export default withStyles(styles)(FiltersList);
