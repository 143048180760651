import { config } from "../../config";
import { client, apiHeaders, request } from "./_base";

const endpoint = "labtest";

export type LabTestType = {
  id?: number;
  sampledate: Date;
  sampledateend: Date;
  lab: string;
  producer_id: number;
  puc_id: number;
  orchard_id: number;
  commodity_id?: string;
  variety_id: number;
  labtesttype_id: number;
  approved: number;
  created_at?: Date;
  updated_at?: Date;
};
export const labtest = client(endpoint, (item) => ({
  ...item,
}));

export type LabTestFullType = {
  id: string;
  sampledate: Date;
  sampledateend: Date;
  lab: string;
  producer_id: number;
  producer_code: string;
  producer_name: string;
  puc_id: number;
  producerpuc_code: string;
  orchard_id: number;
  producerorchard_code: string;
  variety_id: number;
  variety_code: string;
  variety_name: string;
  labtesttype_id: number;
  labtesttype_detail: number;
  approved: number;
};
export const labtestFull = () =>
  request(`${endpoint}/ext/full`, {
    headers: apiHeaders(),
  }).then(async (result) => {
    if (result.status == 500) {
      const resp = await result.json();
      throw resp;
    }
    return result.json();
  });
