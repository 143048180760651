import { apiHeaders, client, request } from "./_base";

export type CreditorsInvoiceType = {
  id: number;
  creditorsinvoice_id: number;
  group: string;
  barcode_id: number;
};

const endpoint = "creditorsinvoicedetail";

export const creditorsinvoicedetail = client(`${endpoint}`, (item) => ({
  ...item,
}));

export const createBatchDetail = async (data) => {
  const content = JSON.stringify(data);
  return request(`${endpoint}/ext/createBatch`, {
    method: "POST",
    headers: apiHeaders(content),
    body: content,
  }).then(async (result) => {
    if (result.status == 500) {
      const resp = await result.json();
      throw resp;
    }
    return result.json();
  });
};

export const creditorsinvoicedetailFull = async () => {
  return await request(`${endpoint}/ext/full`)
    .then(async (result) => {
      if (result.status == 500) {
        const resp = await result.json();
        throw resp;
      }
      return result.json();
    })
    .then(({ data }) => data);
};

export const creditorsinvoicedetailFullByInvoice = async (invoiceid) => {
  return await request(`${endpoint}/ext/fullByInvoice/${invoiceid}`)
    .then(async (result) => {
      if (result.status == 500) {
        const resp = await result.json();
        throw resp;
      }
      return result.json();
    })
    .then(({ data }) => data);
};

export const creditorsinvoicedetailClearByInvoice = async (invoiceid) => {
  return await request(`${endpoint}/ext/clearByInvoiceID/${invoiceid}`).then(async (result) => {
    if (result.status == 500) {
      const resp = await result.json();
      throw resp;
    }
    return result;
  });
};

export type CreditorsInvoiceDetailBySaleIDType = {
  sd_barcodeid: number;
  sd_barcode: string;
  bar_nocartons: string;
  sd_commodityCode: string;
  sd_varietyCode: string;
  sd_gradeCode: string;
  sd_countCode: string;
  sd_packCode: string;
  sd_markCode: string;
  ratePerCarton: number;
  creditValue: number;
};
export const creditorsinvoicedetailBySaleId = async (saleid) => {
  return await request(`${endpoint}/ext/bysaleid/${saleid}`)
    .then(async (result) => {
      if (result.status == 500) {
        const resp = await result.json();
        throw resp;
      }
      return result.json();
    })
    .then(({ data }) => data);
};
